import React, { useState, useContext, useEffect, useCallback } from 'react';
import './index.scss';
import '../log.scss';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { useNet } from '@/utils';
import {
    IUserLogSearchData,
    IEntityListData,
    IUserLogData,
    IOpDetailData,
} from './store';
import moment from 'moment';
import { LayoutStandardCrud, TableLv2 } from '@/components';
import { DatePicker, Select, Spin, Icon } from 'antd';
import _ from 'lodash';
import { widgets } from '../../../demo2/form/options';
import { AntButton } from '../../../../components/antd/index';
import UserSelector from '../../../../components/common/user-selector/index';

const RangePicker = DatePicker.RangePicker;
const UserLogPage: React.FC = props => {
    const store = useContext(StoreContext);
    const userLogStore = store.getDefaultUserLogStore();
    const settingStore = store.getSettingDefaultStore();
    const { customerInfo, isCustomerInfoLoading } = settingStore;
    const {
        getEntityActionTypeList,
        getUserLogData,
        userLogData,
        getUserLogEntityList,
        defaultUserLogPagination,
        searchEntityValueList,
        entityListData,
        entityValueListData,
        entityActionTypeListData,
        entityActionTypeFetchLoading,
        entityListLoading,
        entityValueFetchLoading,
    } = userLogStore;
    const [searchLogData, setSearchLogData] = useState<IUserLogSearchData>({
        beginDate: moment()
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment()
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
    });
    const [entityValueSearch, setEntityValueSearch] = useState<
        string | undefined
    >(''); // 对象值搜索
    const [selectedEntity, setSelectedEntity] = useState<IEntityListData>();
    const [error, loadingUserLog, reloadUserLog] = useNet(() =>
        getUserLogData(searchLogData),
    );
    useEffect(() => {
        reloadUserLog();
    }, [defaultUserLogPagination.pagenum, defaultUserLogPagination.pagesize]);
    useEffect(() => {
        getUserLogEntityList();
    }, []);
    const fetchSearchValue = useCallback(
        v => {
            searchEntityValueList(searchLogData.entityDesc as string, v);
        },
        [searchLogData.entityDesc],
    );
    // 时间选择变更
    const onActionSearchTimeChange = (
        dates: any,
        dateStrings: [string, string],
    ) => {
        setSearchLogData({
            ...searchLogData,
            beginDate: dateStrings[0],
            endDate: dateStrings[1],
        });
    };
    // 对象变更
    const onEntitySelectedChange = (v: any) => {
        console.log(v);
        setSearchLogData({
            ...searchLogData,
            entityCode: v,
        });
        getEntityActionTypeList(v);
        setEntityValueSearch(undefined); // 清除搜索
    };
    //
    const renderUserLogPageFilter = () => {
        return (
            <div className="log-filter-content">
                <div className="log-filter-item">
                    <label htmlFor="time">时间：</label>
                    <RangePicker
                        name="time"
                        value={[
                            moment(searchLogData.beginDate),
                            moment(searchLogData.endDate),
                        ]}
                        showTime={true}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={onActionSearchTimeChange}
                        style={{ width: '400px' }}
                        allowClear={false}
                    />
                    <label htmlFor="name" style={{ marginLeft: '20px' }}>
                        操作者：
                    </label>
                    <UserSelector
                        style={{ width: '200px' }}
                        name="user"
                        value={searchLogData.userId}
                        mode="default"
                        placeholder="请选择用户"
                        allowClear={true}
                        clearIcon={
                            <Icon
                                type="close-circle"
                                onClick={() => {
                                    setSearchLogData({
                                        ...searchLogData,
                                        userId: '',
                                    });
                                }}
                            />
                        }
                        onChange={e => {
                            setSearchLogData({
                                ...searchLogData,
                                userId: e as string,
                            });
                        }}
                        loading={isCustomerInfoLoading}
                        userDocs={customerInfo as any}
                    />
                </div>
                <div className="log-filter-item">
                    <label htmlFor="entity">对象：</label>
                    <Select
                        allowClear={true}
                        placeholder={'对象'}
                        clearIcon={
                            <Icon
                                type="close-circle"
                                onClick={() => {
                                    setSearchLogData({
                                        ...searchLogData,
                                        entityCode: '',
                                    });
                                }}
                            />
                        }
                        value={searchLogData.entityCode}
                        onSelect={onEntitySelectedChange}
                        loading={entityListLoading}
                        style={{ width: '200px' }}
                    >
                        {_.map(entityListData, item => {
                            return (
                                <Select.Option
                                    key={item.entityCode}
                                    value={item.entityCode}
                                >
                                    {item.entityName}
                                </Select.Option>
                            );
                        })}
                    </Select>
                    <Select
                        allowClear={true}
                        disabled={!searchLogData.entityCode}
                        clearIcon={
                            <Icon
                                type="close-circle"
                                onClick={() => {
                                    setSearchLogData({
                                        ...searchLogData,
                                        entityDesc: '',
                                    });
                                }}
                            />
                        }
                        placeholder={'对象值'}
                        filterOption={false}
                        showSearch={true}
                        style={{ width: '200px', marginLeft: '10px' }}
                        value={searchLogData.entityDesc}
                        notFoundContent={
                            entityValueFetchLoading ? (
                                <Spin size="small" />
                            ) : null
                        }
                        onChange={(v: any) => {
                            setSearchLogData({
                                ...searchLogData,
                                entityDesc: v,
                            });
                        }}
                        onSearch={fetchSearchValue}
                    >
                        {_.map(entityValueListData, item => {
                            return (
                                <Select.Option
                                    value={item.entityCode}
                                    key={item.entityCode}
                                >
                                    {item.entityName}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                <div className="log-filter-item">
                    <label htmlFor="actionType">行为：</label>
                    <Select
                        allowClear={true}
                        clearIcon={
                            <Icon
                                type="close-circle"
                                onClick={() => {
                                    setSearchLogData({
                                        ...searchLogData,
                                        actionType: '',
                                    });
                                }}
                            />
                        }
                        placeholder={'具体行为'}
                        disabled={!searchLogData.entityCode}
                        id="actionType"
                        value={searchLogData.actionType}
                        onSelect={(v: any) => {
                            setSearchLogData({
                                ...searchLogData,
                                actionType: v,
                            });
                        }}
                        loading={entityActionTypeFetchLoading}
                        style={{ width: '200px' }}
                    >
                        {_.map(entityActionTypeListData, item => {
                            return (
                                <Select.Option
                                    key={item.operatorCode}
                                    value={item.operatorCode}
                                >
                                    {item.operatorName}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                <AntButton
                    type="primary"
                    onClick={() => {
                        reloadUserLog();
                    }}
                    style={{
                        width: '80px',
                        marginLeft: 0,
                        marginTop: '10px',
                    }}
                >
                    查询
                </AntButton>
            </div>
        );
    };
    return (
        <LayoutStandardCrud
            className={'user-log-content'}
            title={'用户操作日志'}
            message={null}
            popups={null}
            multipleOps={null}
            globalOps={null}
            filters={renderUserLogPageFilter()}
            dataTable={
                <TableLv2<IUserLogData>
                    closeRowSelection={true}
                    loading={loadingUserLog}
                    dataSource={userLogData}
                    pagination={{
                        ...defaultUserLogPagination.paginationForUI,
                        total: defaultUserLogPagination.amount,
                    }}
                    onPaginationChange={
                        defaultUserLogPagination.setPaginationByUI
                    }
                    columns={[
                        {
                            title: '操作者',
                            key: 'operatorUserName',
                            dataIndex: 'operatorUserName',
                            width: '70px',
                        },
                        {
                            title: '行为',
                            key: 'operatorName',
                            dataIndex: 'operatorName',
                            width: '70px',
                        },
                        {
                            title: '对象',
                            key: 'entityCode',
                            dataIndex: 'entityCode',
                            width: '120px',
                            render: v => {
                                const existingEntity = _.find(
                                    entityListData,
                                    item => {
                                        return v === item.entityCode;
                                    },
                                );
                                return existingEntity
                                    ? existingEntity.entityName
                                    : v;
                            },
                        },
                        {
                            title: '对象值',
                            key: 'entityIdDesc',
                            dataIndex: 'entityIdDesc',
                            width: '150px',
                        },
                        {
                            title: '时间',
                            key: 'operatorTime',
                            dataIndex: 'operatorTime',
                            width: '150px',
                            render: v => {
                                return v
                                    ? moment(v).format('YYYY-MM-DD HH:mm:ss')
                                    : v;
                            },
                        },
                        {
                            title: 'IP',
                            key: 'operatorIp',
                            dataIndex: 'operatorIp',
                            width: '150px',
                        },
                        {
                            title: '操作内容',
                            key: 'opLogItemDtoList',
                            width: '200px',
                            render: (v: IUserLogData) => {
                                return (
                                    <div className="setting-user-log-content">
                                        <p>
                                            编辑【{v.entityDesc}】【
                                            {v.entityIdDesc || ''}】
                                        </p>
                                        {_.map(
                                            v?.opLogItemDtoList || [],
                                            log => {
                                                return (
                                                    <p>
                                                        将【
                                                        {log.entityFieldDesc}
                                                        】从【{log.beforeValue}
                                                        】更新为【
                                                        {log.afterValue}】
                                                    </p>
                                                );
                                            },
                                        )}
                                    </div>
                                );
                            },
                        },
                    ]}
                />
            }
        />
    );
};
export default observer(UserLogPage);
