import React, { useEffect, useState } from 'react';
import CustomerDrawer from '@/components/common/customer-drawer';
import { Button } from 'antd';
const CrmDrawerExample: React.FC<{}> = props => {
    const [crmDrawerVis, setCrmDrawerVis] = useState(false);
    return (
        <div>
            {/* <Button
                onClick={() => {
                    setCrmDrawerVis(true);
                }}
            >
                客户抽屉
            </Button>
            <CustomerDrawer
                cid={3}
                drawerVis={crmDrawerVis}
                setDrawerVis={setCrmDrawerVis}
            /> */}
        </div>
    );
};

export default CrmDrawerExample;
