import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import {
    getAutoOnChangeHandlerOriginal,
    IWidgetItem,
    embedDataToWidgets,
    IFormLV2Props,
    FormLV2,
} from '@/components';
import { IDataSelectFormData } from '@/pages/om/data-manage/import/store';
import _ from 'lodash';
import { message, Button, Icon, Spin } from 'antd';
import XLSX from 'xlsx';
import './index.less';
import { toJS } from 'mobx';
import { defaultAxios, arrToJson } from '@/utils';
import moment from 'moment';
import { ParamsEditorModal } from '@/components/common/params-editor';
import { DataManageImportStore } from '@/pages/om/data-manage/import/store';
import {
    calcuSumMoney,
    getExportParamBlackListByTargetType,
} from '../../../utills';
export interface IDataChooseCompProps {
    nextStep: () => void;
    store: DataManageImportStore;
    onSubmit: (data: any[]) => Promise<boolean>;
    info: {
        targetYear: string;
        targetType: string;
        typeCn: string;
    };
}
export interface IObjMetaData {
    constraint: {
        constraintItemList: any[];
        objFullMeta: any[];
        objSaveField: {
            fieldName: string;
        };
        fieldConstraintType: 'item_only' | 'item' | 'obj'; // item_only单选 item多选 obj关联对象
    };
    fieldShowType: 'input' | 'number' | 'date' | 'datetime';
    fieldComment: string; // 字段名称
    fieldName: string;
    groupKey: string;
    groupName: string;
    groupId: number;
    newFieldMetaItem: {
        id: number;
        fieldBind: string;
        fieldCode: string;
        fieldComment: string; // 字段名称
        fieldName: string;
        isEdit: 1 | 0;
        isRequired: 1 | 0;
        fieldType: string; // 字段类型
        options: {
            label: string; // 文本
            value: any; // 值
            selected: boolean; // 是否选中（修改时初始值）
            id: number;
            bizId: number;
            parentId: number;
        }[];
        relationObject: {
            filterName: any;
            findFields: string[]; // （筛选展示）/请求的字段列表
            objectCode: string; // 实体code
            showField: string; // 列表展示的字段
        };
    };
}
const DataChooseComp: React.FC<IDataChooseCompProps> = props => {
    const {
        nextStep,
        store: dmiStore,
        onSubmit,
        info: { targetType, targetYear, typeCn },
    } = props;
    const store = useContext(StoreContext);
    const {
        dataSelectFormData,
        setDataSelectFormData,
        sheetReadLoading,
        readSheet,
        fetchDataChooseMetaData,
        canEditMetaData,
        allMetaData,
        fetchMetaDataLoading,
        sheetJSON,

        setFinalUploadJSON,
        uploadJSONData,
        uploadLoading,
        clearUploadData,
    } = dmiStore;

    const [submitHandleLoading, setSubmitHandleLoading] = useState(false);

    useEffect(() => {
        if (
            dataSelectFormData.entityType &&
            dataSelectFormData.entityType !== 'lead'
        ) {
            fetchDataChooseMetaData();
        }
    }, [dataSelectFormData.entityType]);

    useEffect(() => {
        // 来新活儿了
        if (sheetJSON.length > 0) {
            (async () => {
                setSubmitHandleLoading(true);
                const isSuccess = await onSubmit(
                    makeImportJson(toJS(sheetJSON)),
                );
                setSubmitHandleLoading(false);

                // 如果说不成功，就nextStep，会展示
                if (!isSuccess) {
                    nextStep();
                    return;
                }
                message.success('导入成功');
                clearUploadData();
            })();
        }
    }, [sheetJSON]);

    const onDataChange = (
        key: keyof IDataSelectFormData,
        value: any,
        widget: any,
    ) => {
        getAutoOnChangeHandlerOriginal(
            dataSelectFormData,
            setDataSelectFormData,
        )(key, value, widget);
        // }
    };
    const clearFile = () => {
        setDataSelectFormData({
            ...dataSelectFormData,
            file: undefined,
        });
    };
    const groupCanEditMetaData = arrToJson(canEditMetaData, 'fieldName');
    const makeExampleExcel = (keyArray: string[]) => {
        const aoaExample = [
            _.map(keyArray, key => {
                const existingMeta = groupCanEditMetaData[key];
                if (existingMeta) {
                    return existingMeta.fieldComment;
                } else {
                    return undefined;
                }
            }).filter(Boolean),
        ];

        // 添加id值
        if (!aoaExample[0].includes('目标等级')) aoaExample[0].unshift('id');

        const sheet = XLSX.utils.aoa_to_sheet(aoaExample);
        openDownloadDialog(
            sheet2blob(sheet),
            `${typeCn}业绩目标导入模板_${moment().format('DD_HH_ss')}.csv`,
        );
    };

    const makeImportJson = (sheetJSON: any) => {
        // 将渲染的数据和原数据反译为后端需要的json
        const allMetaDataMap = arrToJson(allMetaData, 'fieldComment');
        const finalJson = _.map(sheetJSON, json => {
            const row: any = {
                target_type: +targetType,
                target_year: +targetYear,
            };
            let nameByIdKey = '';
            console.log('json', json);

            _.forEach(json, (rowItem, rowKey) => {
                if (rowKey === '__rowNum__') {
                    return;
                }
                const finalKey = allMetaDataMap?.[rowKey]?.fieldName;

                if (finalKey?.includes('id')) nameByIdKey = finalKey;

                if (finalKey) {
                    row[finalKey] = rowItem;
                }
            });

            // 现在需要把target_xxx_id = json['id']，除了全国业绩目标
            console.log('nameByIdKey', nameByIdKey, json['id']);

            if (json['id'] && nameByIdKey) {
                const temp = row[nameByIdKey];
                row[nameByIdKey.slice(0, -2) + 'name'] = temp;
                row[nameByIdKey] = json['id'];
            }

            return _.assign(row, calcuSumMoney(row));
        });
        return finalJson;
    };

    const sheet2blob = (sheet: any, sheetName?: any) => {
        sheetName = sheetName || 'sheet1';
        const workbook: {
            SheetNames: any;
            Sheets: any;
        } = {
            SheetNames: [sheetName],
            Sheets: {},
        };
        workbook.Sheets[sheetName] = sheet; // 生成excel的配置项
        const wopts: {
            bookType: any;
            bookSST: boolean;
            type: any;
        } = {
            bookType: 'csv', // 要生成的文件类型
            bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
            type: 'binary',
        };
        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([s2ab(wbout)], {
            type: 'application/octet-stream',
        }); // 字符串转ArrayBuffer
        function s2ab(s: any) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i != s.length; ++i) {
                // tslint:disable-next-line:no-bitwise
                view[i] = s.charCodeAt(i) & 0xff;
            }
            return buf;
        }
        return blob;
    };
    function openDownloadDialog(url: any, saveName: any) {
        if (typeof url == 'object' && url instanceof Blob) {
            url = URL.createObjectURL(url); // 创建blob地址
        }
        const aLink = document.createElement('a');
        aLink.href = url;
        aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
        let event;
        if (window.MouseEvent) {
            event = new MouseEvent('click');
        } else {
            event = document.createEvent('MouseEvents');
            event.initMouseEvent(
                'click',
                true,
                false,
                window,
                0,
                0,
                0,
                0,
                0,
                false,
                false,
                false,
                false,
                0,
                null,
            );
        }
        aLink.dispatchEvent(event);
    }
    const widgets: Array<IWidgetItem<IDataSelectFormData>> = [
        {
            key: 'file',
            widgetKey: 'inputUpload',
            widgetClassName: 'dmi-data-choose-upload-content',
            afterWidget: (
                <>
                    <span className="dmi-data-choose-upload-btn">选择文件</span>
                    {dataSelectFormData.file &&
                    dataSelectFormData.file.length ? (
                        <div className="dmi-data-choose-upload-text">
                            <span>{dataSelectFormData.file?.[0].name}</span>
                            <Icon onClick={clearFile} type="close" />
                        </div>
                    ) : null}
                </>
            ),
            inputUploadOptions: {
                className: 'dmi-data-choose-upload',
                accept: '.xlsx, .xls, .csv',
            },
        },
    ];
    embedDataToWidgets<IDataSelectFormData>(widgets, dataSelectFormData);
    const dataChooseFormProps: IFormLV2Props<IDataSelectFormData> = {
        data: dataSelectFormData,
        formOptions: {
            layout: 'vertical',
        },
        widgets,
        onChange: onDataChange,
        classNameLv2: 'lv2-datachoose-form',
        actionWidgets: [
            {
                key: 'download',
                option: {
                    btnText: '下载模版',
                    type: 'primary',
                    disabled: !dataSelectFormData.entityType,
                    loading: fetchMetaDataLoading,
                    clickActionType: 'download',
                    className: 'dmi-download-btn',
                },
            },
            {
                key: 'submit',
                option: {
                    btnText: '确认上传',
                    type: 'primary',
                    loading: sheetReadLoading || submitHandleLoading,
                },
            },
        ],
        onInteract: (type, data, value) => {},
        onAction: async (actionType, data, isPass, validateResult) => {
            if (actionType === 'download') {
                makeExampleExcel(
                    _.difference(
                        allMetaData.map(item => item.fieldName),
                        getExportParamBlackListByTargetType(targetType),
                    ),
                );
            }
            if (actionType === 'submit') {
                if (isPass) {
                    readSheet();
                } else {
                    message.error('数据不正确，请检查后进行下一步操作');
                }
            }
        },
        formItemOptions: [
            {
                key: 'entityType',
                label: '对象类型',
                required: true,
                className: 'dmi-form-entitytype',
            },
            {
                key: 'importType',
                label: '导入类型',
                required: true,
                className: 'dmi-form-importtype',
            },
            {
                key: 'file',
                label: '上传文件',
                required: true,
                className: 'dmi-form-file',
            },
        ],
        validateResult: {},
        validateMode: 'instantly',
        validators: {
            file: data => {
                if (!data.file || !data.file.length) {
                    return {
                        status: 'fail',
                        msg: '请选择文件',
                    };
                }
                if (data.file[0].size > 3 * 1024 * 1024) {
                    return {
                        status: 'fail',
                        msg: '上传的文件不能超过3M大小',
                    };
                }
                return {
                    status: 'success',
                    msg: '',
                };
            },
        },
    };
    return (
        <>
            {/* 这里把所有字段当作模版即可，无须选择 */}

            <FormLV2<IDataSelectFormData> {...dataChooseFormProps}>
                <>
                    <div className="data-choose-tips">
                        <p>
                            导入只支持编码为GB
                            18030与UTF-8的文件，无特殊需求时，请勿修改文件模版编码
                        </p>
                        <p>单次上传的文件行数不能超过3000条,大小不能超过3M</p>
                    </div>
                </>
            </FormLV2>
        </>
    );
};

export default observer(DataChooseComp);
