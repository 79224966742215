import { defaultAxios } from '@/utils';
import { message } from 'antd';

// 今日团队数据
export const getTodayMessage: (
    orgId: number | string,
) => Promise<any> = async orgId => {
    const [d, e] = await defaultAxios.get('/crm/workbench/todayMessage', {
        orgId,
    });
    if (d === null || d.data === null || e) {
        if (e.message.includes('timeout')) {
            message.error('请求超时，请刷新页面');
        }
        return null;
    }
    return d.data;
};

//
export const setTodayMessageRead: (
    orgId: number | string,
    messageType: number,
) => Promise<any> = async (orgId, messageType) => {
    const [d, e] = await defaultAxios.get(
        '/crm/workbench/setTodayMessageRead',
        {
            orgId,
            messageType,
        },
    );
    if (d === null || d.data === null || e) {
        if (e.message.includes('timeout')) {
            message.error('请求超时，请刷新页面');
        }
        return null;
    }
    return d.data;
};

// 回款日历数据
export const getPayBackCalendar: (
    orgId: number | string,
    startTimeStr: string,
    endTimeStr: string,
) => Promise<any> = async (orgId, startTimeStr, endTimeStr) => {
    const [d, e] = await defaultAxios.get('/crm/workbench/getPayBackCalendar', {
        orgId,
        startTimeStr,
        endTimeStr,
    });
    if (d === null || d.data === null || e) {
        if (e.message.includes('timeout')) {
            message.error('请求超时，请刷新页面');
        }
        return null;
    }
    return d.data;
};
