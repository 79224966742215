import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Select } from 'antd';
import { StoreContext } from '@/stores';
import { homeRangeOptions } from '../../store';
import { EChartsOption } from 'echarts';
import _ from 'lodash';
import './index.less';
import CompositeLiveChartsComponents from '@/pages/analysis/dashboard/composite-live/component/composite-live-charts';
import { TableLv2 } from '@/components';
import { trans2DotTwo } from '@/pages/analysis/dashboard/composite-live/component/achievement-situation';

const HomePerformanceCityGroupRank: React.FC = props => {
    const store = useContext(StoreContext);
    const newHomeStore = store.getDefaultHomeNewStore();
    const {
        homeNewFilterData,
        setHomeNewFilterData,
        homeNewSelectedData,
        homeNewCityRankData,
        homeNewCityRankLoading,
        homeNewGroupRankData,
        homeNewGroupRankLoading,
        getHomeNewCityRankData,
        getHomeNewGroupRankData,
    } = newHomeStore;
    const { selectedLevel, selectedId } = homeNewSelectedData;
    const isGroup = selectedLevel === 1;
    useEffect(() => {
        if (isGroup) {
            getHomeNewGroupRankData();
        } else {
            getHomeNewCityRankData();
        }
    }, [homeNewSelectedData, homeNewFilterData.range]);
    const originRenderData = isGroup
        ? homeNewGroupRankData
        : homeNewCityRankData;
    const originDataLoading = isGroup
        ? homeNewGroupRankLoading
        : homeNewCityRankLoading;
    const rankChartsOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'line', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        legend: {
            show: false,
        },
        grid: {
            left: '5%',
            right: '5%',
            bottom: '5%',
            top: '5%',
            width: '90%',
            height: '90%',
            containLabel: true,
        },
        yAxis: {
            type: 'category',
            data: (_.map(
                isGroup ? homeNewGroupRankData : homeNewCityRankData,
                item => item.orgName,
            ) as string[]).reverse(),
        },
        xAxis: {
            type: 'value',
            min: 0,
            max: 100,
            interval: 20,
            alignTicks: true,
            // axisLine: {
            //     show: false
            // },
            axisLabel: {
                formatter: '{value}  %',
            },
        },
        series: [
            {
                name: isGroup ? '小组' : '城市',
                type: 'bar',
                color: '#0052FF',
                tooltip: {
                    valueFormatter: (value: any) => {
                        return value + ' %';
                    },
                },
                // label: {
                //     show: true
                // },
                // emphasis: {
                //     focus: 'series'
                // },
                data: (_.map(
                    isGroup ? homeNewGroupRankData : homeNewCityRankData,
                    item => item.achievementFinishRate,
                ) as number[]).reverse(),
            },
        ],
    };
    return (
        <div className="home-new-item-content">
            <div className="home-new-item-header">
                <span className="h-n-i-title">
                    业绩目标完成情况
                    <span className="h-n-i-tips">
                        （注意：此处数据非实时数据，是截止昨日24时的数据）
                    </span>
                </span>
                {/* <AntButton>生成报告</AntButton> */}
                <div className="h-n-i-filter">
                    <div className="h-n-i-item">
                        <label htmlFor="">时间</label>
                        <Select
                            value={homeNewFilterData.range}
                            onChange={(value: any) => {
                                setHomeNewFilterData({
                                    ...homeNewFilterData,
                                    range: value,
                                });
                            }}
                            style={{
                                minWidth: '120px',
                            }}
                        >
                            {_.map(homeRangeOptions, option => {
                                return (
                                    <Select.Option value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
            </div>
            <div className="h-n-performance-city-group-body">
                <div className="h-n-performance-city-group-rank">
                    <div className="h-n-performance-city-group-rank-charts-content">
                        <CompositeLiveChartsComponents
                            chartsOptions={rankChartsOptions}
                            loading={originDataLoading}
                            // extraRight={'单位：万元'}
                            title={isGroup ? '小组排名' : '城市排名'}
                            chartsClassName="h-n-performance-city-group-rank-charts"
                        />
                    </div>
                    <TableLv2<any>
                        loading={originDataLoading}
                        className="h-n-performance-city-group-rank-table"
                        closeRowSelection={true}
                        pagination={false}
                        dataSource={originRenderData}
                        scroll={{
                            y: '250px',
                        }}
                        columns={[
                            {
                                title: '排名',
                                render: (
                                    record: any,
                                    t: any,
                                    index: number,
                                ) => {
                                    if (index < 3) {
                                        return (
                                            <div
                                                style={{
                                                    color: '#FFF',
                                                    backgroundImage: `url(${
                                                        process.env.PUBLIC_URL
                                                    }/imgs/home/groupTop${index +
                                                        1}.png)`,
                                                    backgroundRepeat:
                                                        'no-repeat',
                                                    backgroundSize: 'contain',
                                                    backgroundPosition:
                                                        'center',
                                                    width: '16px',
                                                    height: '17px',
                                                    lineHeight: '14px',
                                                    textAlign: 'center',
                                                    fontSize: '12px',
                                                    margin: '0 auto',
                                                }}
                                            >
                                                {index + 1}
                                            </div>
                                        );
                                    } else {
                                        return index + 1;
                                    }
                                },
                                width: '60px',
                                align: 'center',
                            },
                            {
                                title: isGroup ? '小组' : '城市',
                                dataIndex: 'orgName',
                                align: 'center',
                                render: (v, r) => {
                                    if (
                                        r.orgId ===
                                        homeNewSelectedData.selectedId
                                    ) {
                                        return (
                                            <span
                                                style={{
                                                    color: '#0052ff',
                                                }}
                                            >
                                                {v}
                                            </span>
                                        );
                                    } else {
                                        return v;
                                    }
                                },
                            },
                            {
                                title: '目标完成率',
                                dataIndex: 'achievementFinishRate',
                                align: 'center',
                                render: (value: any, record: any) => {
                                    return value
                                        ? trans2DotTwo(value) + '%'
                                        : '-';
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default observer(HomePerformanceCityGroupRank);
