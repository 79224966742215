import React from 'react';
import { DTable, isMultipleCell } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CCAdminListStore } from '../store';
import { runInAction } from 'mobx';
import { applyCustomConfToCellsSelector } from '@/components/common/form/advanced-filters';
import { tryRefresh } from '@/utils';

const DemoTable: React.FC<{
    defaultStore: CCAdminListStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const { defaultMSelect } = defaultStore;
    const { mSeletedData, setMSelectedData } = defaultMSelect;

    const dataTable = (
        <>
            <DTable
                onColumnsUpdated={() => tryRefresh(['sys_call_center_user'])}
                sceneCode={'crm_call_center'}
                defaultStore={defaultStore}
                options={{ withActionPermCheck: false }}
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [
                        {
                            actionKey: 'update',
                            actionCn: '修改',
                        },
                        {
                            actionKey: 'delete',
                            actionCn: '删除',
                        },
                    ];
                    props.onAction = (key, data) => {
                        if (key === 'update') {
                            runInAction(() => {
                                defaultStore.setAction('update', {
                                    id: data.id,
                                });
                            });
                        } else if (key === 'delete') {
                            // 其他动作
                            runInAction(() => {
                                defaultStore.setAction('delete');
                                defaultStore.setMutatingData(data);
                            });
                        } else if (_.startsWith(key, 'reveal-obj/')) {
                            // 关联对象都可以点击
                        }
                    };
                    props.closeRowSelection = false;
                    props.selectedItems = mSeletedData;
                    props.onSelectedDataChange = setMSelectedData;
                    props.widthInfoKey = 'call-center-admin-list-default';

                    return props;
                }}
                cellsSelectorTransformer={baiscCellsSelector => {
                    // 详情可点击
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'name',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'ActionParam';
                            nextCellSelector.inputs.push({
                                paramName: 'name',
                            });
                            return nextCellSelector;
                        },
                    );
                    return baiscCellsSelector;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
