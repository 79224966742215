import React, { useState, useContext, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import './index.scss';
import { AntButton } from '@/components';
import { Spin, Icon, Modal, message, Input } from 'antd';
import SettingMenu from '../../../components/common/setting-menu/index';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet } from '@/utils';
import FRMActionBody from './frm-action-body';
import { AntDrawer } from '@/components/antd/drawer';
import DrawTitle from '../../../components/common/drawer/drawer-title/index';

export interface IFunctionRoleManagementProps {}
const FunctionRoleManagement: React.FC<IFunctionRoleManagementProps> = () => {
    const store = useContext(StoreContext);
    const frmStore = store.getDefaultFRMStore();
    const {
        fetchFRMRoleListData,
        FRMRoleListData,
        updateFRMRoleLoading,
        deleteFRMRoleData,
        createFRMRole,
    } = frmStore;
    const [createNameInputValue, setCreateNameInputValue] = useState<string>();
    const [validateCreateRole, setValidateCreateRole] = useState<{
        [key: string]: {
            status: 'error' | 'success';
            msg: string;
        };
    }>({
        functionRoleName: {
            status: 'success',
            msg: '',
        },
    });
    const drmRoleDataFetch = useCallback(fetchFRMRoleListData, []);
    const [error, roleLoading, roleReload] = useNet(drmRoleDataFetch);
    const [selectedData, setSelectedData] = useState<any>({});
    const [modalVis, setModalVis] = useState<boolean>(false);
    const [isSaveClick, setIsSaveClick] = useState(false); // 纯粹的副作用控制内部上传动作
    const onMenuActionDispatch = (actionKey: string, data: any) => {
        switch (actionKey) {
            case 'edit':
                break;
            case 'delete':
                Modal.confirm({
                    content: (
                        <span>
                            该操作将删除
                            {
                                <span style={{ fontWeight: 500 }}>
                                    「{data?.functionRoleName}」
                                </span>
                            }
                            ，删除后该角色数据将不能再使用，请确认
                        </span>
                    ),
                    onOk: async () => {
                        const [d, e] = await deleteFRMRoleData(data);
                        if (d && !d.msg) {
                            if (data.id === selectedData.id) {
                                setSelectedData({});
                            }
                            message.success('删除成功');
                            roleReload();
                        } else {
                            message.error(d?.msg || '删除失败');
                        }
                    },
                });
                break;
            default:
                break;
        }
    };
    return (
        <div className="pa-content drm-content">
            {
                <Modal
                    className="drm-modal-content"
                    title="新建数据角色"
                    onCancel={() => {
                        setModalVis(false);
                    }}
                    onOk={async () => {
                        if (!createNameInputValue) {
                            setValidateCreateRole({
                                ...setValidateCreateRole,
                                functionRoleName: {
                                    status: 'error',
                                    msg: '请填写角色姓名',
                                },
                            });
                            return;
                        }
                        const [d, e] = await createFRMRole(
                            createNameInputValue,
                        );
                        if (d) {
                            message.success('新增功能角色成功');
                            roleReload();
                            setCreateNameInputValue(undefined);
                            setModalVis(false);
                        } else {
                            message.error('新增功能角色失败');
                        }
                    }}
                    visible={modalVis}
                >
                    <div className="frm-action-body">
                        <div className="pa-table-item">
                            <label
                                htmlFor="name"
                                className="pa-table-item-label"
                            >
                                角色名称<span className="required-mark">*</span>
                            </label>
                            <Input
                                className={
                                    validateCreateRole.functionRoleName
                                        .status === 'error'
                                        ? 'has-error'
                                        : ''
                                }
                                placeholder="请输入角色名称"
                                style={{ width: '300px' }}
                                id="name"
                                onChange={e => {
                                    setCreateNameInputValue(e.target.value);
                                }}
                                onFocus={() => {
                                    setValidateCreateRole({
                                        ...setValidateCreateRole,
                                        functionRoleName: {
                                            status: 'success',
                                            msg: '',
                                        },
                                    });
                                }}
                                value={createNameInputValue}
                            />
                        </div>
                    </div>
                </Modal>
            }
            <div className="pa-title">
                功能角色管理
                <span className="pa-title-description">
                    控制用户可以使用哪些功能，设置完成后请在「组织架构」中为人员分配角色
                </span>
                <div className="pa-title-action-bar">
                    <AntButton
                        type="primary"
                        onClick={() => {
                            setModalVis(true);
                        }}
                    >
                        新建功能角色
                    </AntButton>
                </div>
            </div>
            <div className="pa-body">
                <Spin spinning={roleLoading}>
                    <div className="pa-menu-content">
                        <SettingMenu
                            menuItemsData={FRMRoleListData}
                            uniqueKeyIndex={'id'}
                            titleIndex={'functionRoleName'}
                            actionOptions={[
                                {
                                    actionCn: <Icon type="delete" />,
                                    actionKey: 'delete',
                                },
                            ]}
                            onAction={onMenuActionDispatch}
                            onSelect={(selectedKey: string, data: any) => {
                                setSelectedData(data);
                            }}
                        />
                    </div>
                </Spin>
                <div className="pa-table-content">
                    {!_.isEmpty(selectedData) && (
                        <>
                            <FRMActionBody
                                selectedData={selectedData}
                                saveBtnClassName={'update-pa-btn'}
                                isDrawer={false}
                                reloadRoleList={roleReload}
                            />
                            <div className="save-bar"></div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
export default observer(FunctionRoleManagement);
