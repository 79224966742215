import React, { useEffect, useState } from 'react';
import Upload, { UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { message, Icon, Tooltip, Modal } from 'antd';
import {
    getCommonStatus,
    ICommonProps,
} from '@/components/common/form/widgets/common';
import _ from 'lodash';
import { useEventListener } from 'ahooks';
import { IconAddImage } from './icon';
import { uploadFile } from '../api';

export type UploadValueType = any[] | null;
export interface IUploadOptions extends UploadProps {
    useWaterMark?: boolean;
    waterMarkStr?: string | null;
    lint?: string;
    fileExtWhitelist?: string[];
    limit?: number;
    overlimitLint?: string;
    maxCount?: number;
}

export interface IWidgetUploadProps<T = any> extends ICommonProps<T> {
    value?: UploadValueType;
    options: IUploadOptions;
    onChange?: (value?: UploadValueType) => void;
    style?: React.CSSProperties;
}

const protocol = 'https:';
const clearUrl = (url: string) => {
    if (_.startsWith(url, protocol)) {
        return url;
    }
    return protocol + '//' + url.split('//')[1];
};

function getBase64(file: File | Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const UploadImage: React.FC<IWidgetUploadProps> = ({
    value,
    options,
    onChange,
    status,
    data,
    statusExtraData,
    style,
}) => {
    const [removed, setRemoved] = useState<{ [uid: string]: boolean }>({});
    const [loading, setLoading] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState<any[]>([]);

    const { maxCount = 8 } = options;

    const parsedFile = (file: UploadFile<any>) => {
        if (file.response === 'Server ok') {
            // 原上传文件
            return file;
        }

        // 新上传文件
        const response = { ...(file.response || {}) };
        if (response.status === 'error' || !response.url) {
            return null;
        }
        return {
            uid:
                file.uid || (response.name || file.fileName) + ':' + Date.now(),
            name: response.name || file.fileName,
            status: response.status || 'error',
            response:
                !response.status || response.status === 'error'
                    ? 'Server Error'
                    : 'Server ok', // custom error message to show
            url: clearUrl(response.url || ''),
        };
    };

    // 监听粘贴事件
    useEventListener('paste', async event => {
        let items = event.clipboardData?.items;
        if (!items || fileList.length >= maxCount) return;
        // 这是一个类数组对象 不是数组，不能用map和forEach遍历
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const pasteFile = item?.getAsFile();

            if (pasteFile && item.type.match(/^image\//i)) {
                // 获取文件对象
                const response = await uploadFile(pasteFile);
                const key = Date.now();
                const fileObject = {
                    ...pasteFile,
                    uid: key,
                    originFileObj: pasteFile,
                    percent: 100,
                    key: key,
                    response: response,
                    status: response.status,
                    thumbUrl: await getBase64(pasteFile),
                };

                setFileList([...fileList, fileObject]);
            }
        }
    });

    useEffect(() => {
        const parsedInfo = fileList
            .filter(item => !removed[item.uid] && item.status === 'done')
            .map(item => parsedFile(item))
            .filter(Boolean);

        const nextFlist = _.uniqBy(parsedInfo, 'url');
        const value = nextFlist.length
            ? nextFlist.map(item => item?.url)
            : undefined;
        onChange && onChange(value);
    }, [fileList]);

    const { disabled } = getCommonStatus(status, data, statusExtraData);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }

    let lint = '附件要求:20M以内,支持文件格式为jpg,png,jpeg。';
    if (options.lint) {
        lint = options.lint;
    }

    let fileExtWhitelist = ['jpg', 'png', 'jpeg'];
    if (options.fileExtWhitelist) {
        fileExtWhitelist = options.fileExtWhitelist;
    }

    const handlePreview = async (file: UploadFile<any>) => {
        if (!file.url && !file.preview && file.originFileObj) {
            file.preview = (await getBase64(file.originFileObj)) as any;
        }

        setPreviewVisible(true);
        setPreviewImage(file.url || file.preview || '');
    };

    const handlePreviewCancel = () => {
        setPreviewVisible(false);
    };

    return (
        <div style={style}>
            <Upload
                name={'file'}
                action={
                    '/bff/api/rest/upload' +
                    (!!options.useWaterMark ? '?useWaterMark=1' : '')
                }
                data={
                    !!options.useWaterMark && !!options.waterMarkStr
                        ? { str: options.waterMarkStr }
                        : {}
                }
                listType="picture-card"
                onPreview={handlePreview}
                fileList={fileList}
                beforeUpload={file => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const { size } = file;
                            const limit = options.limit;
                            if (limit && size && size > limit) {
                                message.error(
                                    options.overlimitLint || '文件大小超出限制',
                                );
                                return reject();
                            }

                            const isGood = fileExtWhitelist.some(extName =>
                                _.endsWith(file.name, extName),
                            );
                            if (!isGood) {
                                message.error(file.name + ' : 文件格式不支持');
                                return reject();
                            }
                            resolve();
                        }, 1);
                    });
                }}
                onChange={info => {
                    if (info.file.status !== 'uploading') {
                        setLoading(false);
                    } else {
                        setLoading(true);
                    }
                    if (info.file.status === 'done') {
                        if (info.file.response?.status === 'error') {
                            message.error(`${info.file.name} 上传失败`);
                        } else {
                            message.success(`${info.file.name} 上传成功`);
                        }
                    } else if (info.file.status === 'error') {
                        message.error(`${info.file.name} 上传失败`);
                    }
                    setFileList([...info.fileList]);
                }}
                multiple={false}
                {...options}
            >
                {fileList.length < maxCount && <IconAddImage />}
            </Upload>
            <div style={{ fontSize: 12, color: '#AFB1BC' }}>
                支持点击/拖拽/粘贴(Ctrl+V)
                <Tooltip title={lint}>
                    <Icon
                        style={{
                            marginLeft: 12,
                            fontSize: 16,
                            color: '#0052ff',
                        }}
                        type="exclamation-circle"
                    />
                </Tooltip>
            </div>
            <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handlePreviewCancel}
            >
                <img
                    alt="preview"
                    style={{ width: '100%' }}
                    src={previewImage}
                />
            </Modal>
        </div>
    );
};
