import React from 'react';
import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/interface';

const { MonthPicker } = DatePicker;

const SelectDatePicker: React.FC<DatePickerProps> = props => {
    const { mode, ...rest } = props;
    if (mode === 'month') {
        return <MonthPicker {...rest} />;
    }
    return <DatePicker {...rest} />;
};

export default SelectDatePicker;
