import React from 'react';
import { JobBaseStore } from '../../../utils';
import { ActionCancel } from './actions/cancel';
import { ActionExport } from '@/pages/sales-follow-up/activation-record/list/components/export';

export const LeadActions: React.FC<{ defaultStore: JobBaseStore }> = ({
    defaultStore,
}) => {
    return (
        <>
            <ActionCancel defaultStore={defaultStore} />
            <ActionExport defaultStore={defaultStore} />
        </>
    );
};
