import React, { useMemo } from 'react';
import _ from 'lodash';

import './index.less';
import { useShowMore } from '../../hooks';
import moment from 'moment';

interface WarningInfoCardProps {
    cardKey: string;
    icon: React.ReactNode;
    title: string;
    subtext: string;
    extraRight?: React.ReactNode;
    subheader?: string;
    childrenType?: string;
    data: any[];
    showCount?: number;
    renderChildren?: (data: any, clickState?: any, setClickState?: any) => any;
    clickState?: any;
    setClickState?: (data: any) => void;
    messageType?: number;
    readMethod?: (messageType: number) => void;
    isRead?: number;
}

const WarningInfoCard: React.FC<WarningInfoCardProps> = props => {
    const {
        cardKey,
        icon,
        title,
        subtext,
        subheader,
        extraRight = null,
        showCount = 3,
        data = [],
        childrenType,
        renderChildren = () => {},
        clickState = {},
        setClickState = () => {},
        messageType,
        readMethod,
        isRead,
    } = props;

    const length = useMemo(() => {
        return data.filter((item: any) => {
            const key = `${moment().format('YYYY-MM-DD')}-${item.primaryKey}`;
            return !clickState[key];
        }).length;
    }, [data, clickState]);

    const target = (showAll: boolean) => (
        <span style={{ color: '#0052FF', cursor: 'pointer' }}>
            {showAll ? '收起' : '查看更多'}
        </span>
    );
    const { returnData, returnTarget } = useShowMore({
        showCount,
        data,
        target,
    });
    const children =
        childrenType === 'table'
            ? renderChildren({ dataSource: returnData })
            : returnData.map(item =>
                  renderChildren(item, clickState, setClickState),
              );
    return (
        <div className="warning-card-container">
            <div className="warning-card-header">
                <span className="warning-card-header-icon">{icon}</span>
                <span className="warning-card-header-title">
                    {`${title}`}
                    {childrenType !== 'table' && (
                        <>
                            <span
                                style={{ color: '#0052FF' }}
                            >{` ${length} `}</span>
                            {`条`}
                        </>
                    )}
                </span>
                <span className="warning-card-header-subtext">{subtext}</span>
                <span className="warning-card-header-extra">{extraRight}</span>
            </div>
            <div className="warning-card-body">
                {subheader && length !== 0 && (
                    <div className="warning-card-body-subheader">
                        {subheader}
                    </div>
                )}
                <div className="warning-card-body-children">{children}</div>
            </div>
            <div className="warning-card-footer">
                {returnTarget}
                {messageType && readMethod && (
                    <span
                        style={{
                            color: isRead ? '#AFB1BC' : '#0052FF',
                            marginLeft: '16px',
                            cursor: 'pointer',
                            pointerEvents: isRead ? 'none' : 'all',
                        }}
                        onClick={() => {
                            readMethod(messageType);
                        }}
                    >
                        标为已读
                    </span>
                )}
            </div>
        </div>
    );
};

export default WarningInfoCard;
