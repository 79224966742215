import React, { useState, useEffect } from 'react';
import './index.less';
import { defaultAxios } from '@/utils';
import { message } from 'antd';
import CompositeLiveChartsComponents from '../../composite-live-charts';
import { EChartsOption } from 'echarts';
import moment from 'moment';
interface IPerformanceSalesPWProps {}
interface IPerformanceSalesPWData {
    income_month: string;
    new_sale_eff: number; // 新客
    old_sale_eff: number; // 老客
    sale_eff: number; // 人效
}
const PerformanceSalesPW: React.FC<IPerformanceSalesPWProps> = props => {
    const [performanceSalesPWData, setPerformanceSalesPWData] = useState<
        IPerformanceSalesPWData[]
    >([]);
    const [performanceSalesPWLoading, setPerformanceSalesPWLoading] = useState(
        false,
    );
    const fetchPerformanceSalesPWData = async () => {
        const url = '/crm/data/query/list_income_people_m';
        const payloadData = {};
        setPerformanceSalesPWLoading(true);
        const [d, e] = await defaultAxios.put(url, payloadData);
        setPerformanceSalesPWLoading(false);
        if (d === null || d.data === null) {
            message.error('请求人效数据失败～');
            return [null, e];
        }
        if (d.data && Array.isArray(d.data)) {
            setPerformanceSalesPWData(d.data || []);
        }
    };
    useEffect(() => {
        fetchPerformanceSalesPWData();
    }, []);
    const performanceSalesPWOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '15%',
            width: '100%',
            height: '70%',
            containLabel: true,
        },
        legend: {
            bottom: '0',
            height: '10%',
            itemWidth: 10,
            itemHeight: 10,
            icon: 'rect',
            itemGap: 20,
            textStyle: {
                color: '#6E727A',
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: performanceSalesPWData.map(item => {
                return moment(item.income_month, 'YYYYMM').format('MMMYYYY');
            }),
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: '整体',
                type: 'line',
                symbol: 'none',
                color: '#76D98D',
                data: performanceSalesPWData.map(item => {
                    return Math.round(item.sale_eff / 100) / 100;
                }),
            },
            {
                name: '老客',
                type: 'line',
                symbol: 'none',
                color: '#0052FF',
                // label: {
                //     show: true
                // },
                // emphasis: {
                //     focus: 'series'
                // },
                data: performanceSalesPWData.map(item => {
                    return Math.round(item.old_sale_eff / 100) / 100;
                }),
            },
            {
                name: '新客',
                type: 'line',
                symbol: 'none',
                color: '#F5A623',
                // label: {
                //     show: true
                // },
                // emphasis: {
                //     focus: 'series'
                // },
                data: performanceSalesPWData.map(item => {
                    return Math.round(item.new_sale_eff / 100) / 100;
                }),
            },
        ],
    };
    return (
        <div className="performance-sales-pw-content">
            <div className="p-s-pw-header">
                <span className="p-s-pw-title">销售管理-人效变化趋势</span>
                {/* <span className="p-s-pw-time">
                    （更新时间：
                    {moment()
                        .startOf('day')
                        .hour(1)
                        .format('YYYY-MM-DD HH:mm')}
                    ）
                </span> */}
                <div className="p-s-pw-charts-content">
                    <div className="p-s-pw-charts-row">
                        <CompositeLiveChartsComponents
                            chartsClassName="p-s-pw-charts-content"
                            loading={performanceSalesPWLoading}
                            chartsOptions={performanceSalesPWOptions}
                            title={'销售人效（新老客）'}
                            extraRight={'单位：万元'}
                        />
                        <CompositeLiveChartsComponents
                            chartsClassName="p-s-pw-charts-content"
                            loading={false}
                            chartsOptions={performanceSalesPWOptions}
                            title={'销售人效（KA/精英）'}
                            // extraRight={'单位：万元'}
                            showPre={true}
                        />
                        <CompositeLiveChartsComponents
                            chartsClassName="p-s-pw-charts-content"
                            loading={false}
                            chartsOptions={{}}
                            title={'销售人效（司龄）'}
                            showPre={true}
                            // extraRight={'单位：万元'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PerformanceSalesPW;
