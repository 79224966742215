import React from 'react';

import './index.less';

interface TopBoardProps {
    top3: Top3[];
}
interface Top3 {
    city_name: string;
    income: number;
}

const TopBoard: React.FC<TopBoardProps> = ({ top3 }) => {
    return (
        <div className="top-board">
            {top3?.map((item, index) => (
                <div className={`top-board-card no${index + 1}`} key={index}>
                    <div className="top-board-card-value">
                        {Math.round(item.income)}w
                    </div>
                    <div className="top-board-card-title">{item.city_name}</div>
                </div>
            ))}
        </div>
    );
};

export default React.memo(TopBoard);
