import { defaultAxios } from '@/utils';

// 获取回款目标达成数据
export const queryIncomeBoard: (
    orgId: string | number,
) => Promise<any> = async orgId => {
    const [d, e] = await defaultAxios.put('/crm/data/query/income_board', {
        orgId,
        version: 2,
    });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
};

// 获取战况数据
export const queryWarSituation: (
    orgId: string | number,
) => Promise<any> = async orgId => {
    const [d, e] = await defaultAxios.put('/crm/data/query/war_situation', {
        orgId,
        version: 2,
    });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
};
