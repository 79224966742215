import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { SGrid } from '@/components/layouts';
import { Alert } from 'antd';
import { observer } from '@/containers/hoc';

const GridDemo: React.FC<RouteComponentProps> = ({}) => {
    return (
        <SGrid
            layout={[
                [6, 6, 6, 6],
                [6, 6, 6, 6],
            ]}
        >
            <Alert message="a" />
            <Alert message="b" />
            <Alert message="c" />
            <Alert message="d" />
            <Alert message="e" />
            <Alert message="f" />
            <Alert message="g" />
            <Alert message="h" />
        </SGrid>
    );
};

export default observer(GridDemo);
