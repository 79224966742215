import {
    TPaginationStoreInstance,
    PaginationStore,
} from '../../../../stores/utils/index';
import React from 'react';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import { defaultAxios } from '@/utils';
import { Modal } from 'antd';
import { createDefaultInstanceGetterA } from '@/stores/utilsA';
export interface ISalesDetailAnaData {
    leadOwner?: string; // 线索所有人
    immediateSuperior?: string; // 直属s上级
    city?: string; // 城市
    area?: string; // 区域
    leadChannel?: string; // 线索渠道
    leadCount?: number; // 线索数量
    followedLeadCount?: number; // 跟进线索数量
    followedLeadRate?: number; // 线索跟进率
    transformOptCount?: number; // 转商机数
    winCount?: number; // 赢单量
    transformOptMoneyCount?: number; // 赢单金额
}
export type TGetSalesDetailAnaReturnType = NetResponse<{
    data: ISalesDetailAnaData[];
    msg: string;
    code: number;
}>;
export class SalesDetailStore {
    constructor() {
        makeAutoObservable(this);
    }
    public salesDetailAnaData: ISalesDetailAnaData[] = [];
    // public defaultSalesDetailAnaPagination: TPaginationStoreInstance = new PaginationStore();
    public salesListLoading: boolean = false;
    public setSalesListLoading = (bool: boolean) => {
        this.salesListLoading = bool;
    };
    public salesListData: any[] = [];
    public getSalesList = async () => {
        this.salesListLoading = true;
        const url = '/bff/api/rest/ana-lead/sales-detail/sales-list';
        const [d, e] = await defaultAxios.get(url);
        this.salesListLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data)) {
            this.salesListData = data;
        }
        const error = e as Error | null;
        return [data, error];
    };
    public getSalesDetailData: (
        searchData: any,
    ) => Promise<TGetSalesDetailAnaReturnType> = async searchData => {
        const finalSearchData = {
            ...searchData,
            start: moment(searchData.beginDate).valueOf(),
            end: moment(searchData.endDate).valueOf(),
            // ...this.defaultSalesDetailAnaPagination.paginationArgs,
        };
        const url = '/bff/api/rest/ana-lead/sales-detail/fetch';
        const [d, e] = await defaultAxios.get(url, finalSearchData);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data)) {
            this.salesDetailAnaData = data;
            // this.defaultSalesDetailAnaPagination.amount = 1;
        }
        // if (data && Array.isArray(data?.data)) {
        //     this.salesDetailAnaData = data?.data;
        //     this.defaultSalesDetailAnaPagination.amount = data?.totalSize || 0;
        // }
        const error = e as Error | null;
        return [data, error];
    };
    public exportSalesDetailData = async (searchData: any) => {
        if (this.salesDetailAnaData.length > 50000) {
            Modal.error({
                title: '导出提示',
                content: (
                    <p>
                        最多支持导出50000条（当前条件共
                        {this.salesDetailAnaData.length}条，请更换筛选条件再导出
                    </p>
                ),
            });
            return;
        }
        const finalSearchData = {
            ...searchData,
            userIdList: searchData.userId,
            start: moment(searchData.beginDate).valueOf(),
            end: moment(searchData.endDate).valueOf(),
        };
        const url = `${
            window.location.origin
        }/bff/api/rest/ana-lead/sales-detail/export?q=${encodeURIComponent(
            JSON.stringify(finalSearchData),
        )}`;
        window.open(url, '_blank');
        return;
    };
}

export const getDefaultSalesDetailStore = createDefaultInstanceGetterA(
    SalesDetailStore,
);
