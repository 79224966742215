import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Link } from '@reach/router';
import { Menu, Badge, Icon } from 'antd';
import _ from 'lodash';
import './dsmenu.scss';
import { IRouteLayout } from '@/configs/route';
import { IPermMap } from '@/stores/perm';
import { MenuStatisticEE, defaultAxios, isNetSuccess } from '@/utils';

const SubMenu = Menu.SubMenu;

// TODO: 收起的时候hover不显示菜单
const DsMenu: React.FC<RouteComponentProps & {
    routeLayout: IRouteLayout;
    menuPerm: IPermMap;
    collapsed: boolean;
    parentRouteKey: string;
    setCollapsed: (bool: boolean) => void;
}> = ({
    children,
    routeLayout,
    menuPerm,
    location,
    collapsed,
    parentRouteKey,
    setCollapsed,
}) => {
    const { pathname = '/' } = location || {};

    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [openKeys, setOpenKeys] = useState<string[]>([]);

    useEffect(() => {
        const nextSelectedKeys = [];
        const nextOpenKeys = [];
        for (const menu of routeLayout) {
            // 三级路由
            if (menu.children && menu.children.length > 0) {
                const posibleOpenKey = menu.key;
                for (const subMenu of menu.children) {
                    const matched =
                        pathname ===
                        '/' + parentRouteKey + menu.schema + subMenu.schema;
                    if (matched && subMenu.visible !== false) {
                        nextSelectedKeys.push(subMenu.key);
                        nextOpenKeys.push(posibleOpenKey);
                        break;
                    }
                }
            } else {
                const matched = pathname === '/' + parentRouteKey + menu.schema;
                if (matched && menu.visible !== false) {
                    nextSelectedKeys.push(menu.key);
                }
            }
        }
        setSelectedKeys(nextSelectedKeys);
        if (!collapsed) {
            setOpenKeys(nextOpenKeys);
        }
    }, [routeLayout, pathname, collapsed]);
    useEffect(() => {
        if (collapsed) {
            setOpenKeys([]);
        }
    }, [collapsed]);
    const [menuStatistic, setMenuStatistic] = useState<any>({});
    useEffect(() => {
        const refreshMenuStatistic = () => {
            defaultAxios
                .get('/bff/api/rest/home/menu-statistic')
                .then(([d, e]) => {
                    if (isNetSuccess(d, e) && d?.data) {
                        setMenuStatistic(d?.data);
                    }
                });
        };
        refreshMenuStatistic();
        MenuStatisticEE.on('refresh', refreshMenuStatistic);
        return () => {
            MenuStatisticEE.removeListener('refresh', refreshMenuStatistic);
        };
    }, []);

    return routeLayout.length ? (
        <div className="ds-menu-content">
            <div className="ds-menu-body">
                <Menu
                    selectedKeys={selectedKeys}
                    // openKeys={collapsed ? [] : openKeys}
                    openKeys={openKeys}
                    mode="inline"
                    inlineCollapsed={collapsed}
                    onOpenChange={nextOpen => {
                        setOpenKeys(_.difference(nextOpen, openKeys));
                    }}
                    className="nav-left-menu"
                    style={{
                        width: collapsed ? 81 : 220,
                        transition: 'width 400ms',
                    }}
                >
                    {_.map(routeLayout, menu => {
                        const {
                            icon,
                            cn,
                            key,
                            schema,
                            children,
                            visible = true,
                            forceShow,
                        } = menu;
                        const statistic = menuStatistic[key] || undefined;
                        const permItem = menuPerm[key] || { visible: false };
                        if (!forceShow && (!visible || !permItem.visible)) {
                            return null;
                        }

                        if (children && children.length > 0) {
                            return (
                                <SubMenu
                                    key={key}
                                    title={
                                        <span>
                                            {icon}
                                            {collapsed ? null : (
                                                <span>{cn}</span>
                                            )}
                                            {/* {statistic !== undefined && (
                                                <span>
                                                    <Badge
                                                        style={{
                                                            transform: 'scale(0.7)',
                                                            transformOrigin: 'center',
                                                        }}
                                                        count={statistic}
                                                        overflowCount={99}
                                                    />
                                                </span>
                                            )} */}
                                        </span>
                                    }
                                >
                                    {_.map(children, child => {
                                        const {
                                            icon,
                                            cn,
                                            key,
                                            visible = true,
                                            schema: schemaInner,
                                            forceShow,
                                        } = child;
                                        const permItem = menuPerm[key] || {
                                            visible: false,
                                        };
                                        if (
                                            !forceShow &&
                                            (!visible || !permItem.visible)
                                        ) {
                                            return null;
                                        }

                                        const statistic =
                                            menuStatistic[key] || undefined;

                                        return (
                                            <Menu.Item key={key}>
                                                <Link
                                                    to={
                                                        '/' +
                                                        parentRouteKey +
                                                        schema +
                                                        schemaInner
                                                    }
                                                >
                                                    <span>
                                                        {icon}
                                                        <span>{cn}</span>
                                                        {/* {statistic !== undefined && (
                                                            <span>
                                                                <Badge
                                                                    style={{
                                                                        transform:
                                                                            'scale(0.7)',
                                                                        transformOrigin:
                                                                            'center',
                                                                    }}
                                                                    count={statistic}
                                                                    overflowCount={99}
                                                                />
                                                            </span>
                                                        )} */}
                                                    </span>
                                                </Link>
                                            </Menu.Item>
                                        );
                                    })}
                                </SubMenu>
                            );
                        }
                        return (
                            <Menu.Item key={key}>
                                <Link to={'/' + parentRouteKey + schema}>
                                    <span>
                                        {icon}
                                        <span>{cn}</span>
                                    </span>
                                </Link>
                            </Menu.Item>
                        );
                    })}
                </Menu>
                <div className="menu-trigger"></div>
            </div>
            <div
                onClick={() => {
                    setCollapsed(!collapsed);
                }}
                className="left-fixed-trigger"
            >
                {collapsed ? (
                    <Icon type="menu-unfold" />
                ) : (
                    <Icon type="menu-fold" />
                )}
            </div>
        </div>
    ) : null;
};

export default DsMenu;
