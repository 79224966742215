import { gql } from '@/utils';

export const BookBasic = gql`
    fragment BookBasic on Book {
        id
        title
    }
`;

export const AuthorBasic = gql`
    fragment AuthorBasic on Author {
        id
        name
        gender
    }
`;

export const CustomerBasic = (keys: string[]) => `
    fragment CustomerBasic on Customer {
        ${keys.join('\n')}
    }
`;
