import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import './index.less';
import _ from 'lodash';
import { Icon, Steps, Button } from 'antd';
import { BlockLoading2 } from '@/components';
import DataChoose from './data-choose';
import { defaultStore } from '@/containers/hoc/call-center';
const { Step } = Steps;

const AchievementImport: React.FC<RouteComponentProps & {
    entityCode: string;
    onSubmit: (data: any[]) => Promise<boolean>;
    info: {
        targetYear: string;
        targetType: string;
        typeCn: string;
        mCreateResult: {
            loading: boolean;
            isSuccess: boolean;
            msg: string;
        };
    };
}> = ({ entityCode, onSubmit, info }) => {
    const store = useContext(StoreContext);
    const [DMIStore] = useState(new store.DataManageImportStore());

    const {
        fetchDataImportConfig,
        dataManageImportConfig,
        configFetchLoading,
        dataSelectFormData,
        clearUploadData,
    } = DMIStore;
    useEffect(() => {
        fetchDataImportConfig();
    }, []);

    useEffect(() => {
        dataSelectFormData.entityType = entityCode;
    }, [entityCode]);

    const [stepCurrent, setStepCurrent] = useState(0);
    const nextStep = () => {
        setStepCurrent(stepCurrent + 1);
    };
    const stepRenderData = [
        {
            title: '选择数据',
            key: 'data',
            icon: <Icon type="file" />,
            content: (
                <DataChoose
                    nextStep={nextStep}
                    store={DMIStore}
                    onSubmit={onSubmit}
                    info={info}
                />
            ),
        },
        {
            title: '导入结果',
            key: 'import',
            icon: <Icon type="cloud-upload" />,
            content: (
                <div className="final-import-tips">
                    <p style={{ color: 'red' }}>{info.mCreateResult.msg}</p>
                    <Button
                        type="primary"
                        onClick={() => {
                            clearUploadData();
                            setStepCurrent(0);
                        }}
                    >
                        继续导入
                    </Button>
                </div>
            ),
        },
    ];
    return (
        <div className="dmi-page-content">
            {configFetchLoading ? (
                <BlockLoading2 />
            ) : (
                <div className="dmi-step-content">
                    <Steps current={stepCurrent}>
                        {_.map(stepRenderData, step => {
                            return (
                                <Step
                                    key={step.key}
                                    icon={step.icon}
                                    title={step.title}
                                />
                            );
                        })}
                    </Steps>
                </div>
            )}
            <div className="dmi-action-content">
                {stepRenderData[stepCurrent].content || null}
            </div>
        </div>
    );
};

export default observer(AchievementImport);
