import React, { useState, useEffect } from 'react';
import './index.less';
import { defaultAxios } from '@/utils';
import { message, Spin } from 'antd';
import { Venn, G2 } from '@ant-design/plots';
import _, { isEqual } from 'lodash';
import { TableLv2 } from '@/components';
import { trans2DotTwo } from '../../achievement-situation/index';
const { getActionClass, registerAction } = G2;
const ElementHighlightAction: any = getActionClass('venn-element-highlight');

/** 用一个变量来存储 label 的初始化 visible 状态 */
const VISIBLE_STATUS = 'visible-status';

//  toggle labels 的 visible 状态
function toggleLabels(view: any, stateName: any) {
    const activeElements = view.geometries[0].elements.filter((ele: any) =>
        ele.getStates().includes(stateName),
    );
    const activeDatas = activeElements.map((ele: any) => ele.getData());
    const labels = view.geometries[0].labelsContainer.getChildren();
    labels.forEach((label: any) => {
        label.set(VISIBLE_STATUS, label.get('visible'));
        if (
            !activeDatas.find((d: any) => isEqual(d, label.get('origin').data))
        ) {
            label.hide();
        } else {
            label.show();
        }
    });
}

// 重置 labels 的 visible 状态
function resetLabels(view: any) {
    const labels = view.geometries[0].labelsContainer.getChildren();
    labels.forEach((label: any) => {
        const visible =
            label.get(VISIBLE_STATUS) !== undefined
                ? label.get(VISIBLE_STATUS)
                : true;
        label.set('visible', visible);
        label.set(VISIBLE_STATUS, undefined);
    });
}

/**
 * @override 自定义韦恩图 · 图形元素高亮交互
 */
class VennElementHighlight extends ElementHighlightAction {
    /** 激活图形元素 */
    public highlight() {
        super.highlight();
        this.toggleLabels();
    }

    /** toggle 图形元素激活状态 */
    public toggle() {
        super.toggle();
        this.toggleLabels();
    }

    /** 重置 */
    public reset() {
        super.reset();
        this.resetLabels();
    }

    /**
     * toggle labels 的 visible 状态
     */
    public toggleLabels() {
        toggleLabels(this.context.view, this.stateName);
    }

    /**
     *  重置 labels 的 visible 状态
     */
    public resetLabels() {
        resetLabels(this.context.view);
    }
}

// 自定义注册韦恩图 · 图形元素高亮交互
registerAction('venn-element-highlight', VennElementHighlight as any);
interface IPerformanceProductSale {}
interface IPerformanceProductSaleData {
    product_code: string;
    product_type: string;
    customer_num: number;
    customer_num_rate: number;
}
const PerformanceProductSale: React.FC<IPerformanceProductSale> = props => {
    const keyToLabel: {
        [key: string]: string;
    } = {
        A: '企业号',
        B: '人才银行',
        C: '雇主',
        'A+B': '人+企',
        'A+C': '企+雇',
        'B+C': '人+雇',
        'A+B+C': '人+企+雇',
    };
    const [
        performanceProductSaleData,
        setPerformanceProductSaleData,
    ] = useState<IPerformanceProductSaleData[]>([]);
    const [
        performanceProductSaleTableData,
        setPerformanceProductSaleTableData,
    ] = useState<IPerformanceProductSaleData[]>([]);
    const [
        performanceProductSaleLoading,
        setPerformanceProductSaleLoading,
    ] = useState(false);
    const fetchPerformanceProductSaleData = async () => {
        const url = '/crm/data/query/list_business_cross_sale';
        setPerformanceProductSaleLoading(true);
        const [d, e] = await defaultAxios.put(url, {});
        setPerformanceProductSaleLoading(false);
        if (d === null || d.data === null) {
            message.error('请求产品交叉售卖数据失败～');
            return [null, e];
        }
        if (d.data) {
            setPerformanceProductSaleData(d.data.chart || []);
            setPerformanceProductSaleTableData(d.data.table || []);
        }
    };
    useEffect(() => {
        fetchPerformanceProductSaleData();
    }, []);
    return (
        <div className="performance-product-sale-content">
            <div className="p-p-sale-header">
                <span className="p-p-sale-title">近12个月产品交叉售卖情况</span>
            </div>
            <Spin spinning={performanceProductSaleLoading}>
                <div className="p-p-sale-body">
                    <div className="p-p-sale-charts-content">
                        <Venn
                            legend={false}
                            interactions={[{ type: 'venn-element-highlight' }]}
                            // state={{
                            //     active: {
                            //         style: {
                            //             fillOpacity: 1,
                            //             stroke: 'black',
                            //             lineWidth: 1,
                            //         },
                            //     }
                            // }}
                            tooltip={{
                                fields: ['label', 'size'],
                                formatter: datam => {
                                    return {
                                        name: datam.label,
                                        value: datam.size,
                                    };
                                },
                            }}
                            data={_.map(performanceProductSaleData, data => {
                                return {
                                    sets: data.product_code.split('+'),
                                    size: data.customer_num,
                                    label:
                                        keyToLabel?.[data.product_code] || '-',
                                };
                            })}
                            color={['#5470c6', '#ee6666', '#F5A623']}
                            blendMode={'screen'}
                            setsField="sets"
                            sizeField="size"
                            pointStyle={{
                                fillOpacity: 0.85,
                            }}
                            label={{
                                // offsetY: 7,
                                // labelLine: true,
                                style: {
                                    fontSize: 12,
                                    fill: '#222',
                                },
                                layout: {
                                    type: 'overlap',
                                },
                                type: 'outer',
                                formatter: datam => {
                                    return datam.label;
                                },
                            }}
                        />
                    </div>
                    <div className="p-p-sale-table-content">
                        <TableLv2
                            loading={performanceProductSaleLoading}
                            className={'p-p-sale-table'}
                            dataSource={performanceProductSaleTableData}
                            closeRowSelection={true}
                            pagination={false}
                            scroll={{ x: '100%', y: 400 }}
                            size={'middle'}
                            columns={[
                                {
                                    title: '合作产品',
                                    dataIndex: 'product_type',
                                },
                                {
                                    title: '客户数量',
                                    dataIndex: 'customer_num',
                                },
                                {
                                    title: '数量占比',
                                    dataIndex: 'customer_num_rate',
                                    render: v => {
                                        return `${trans2DotTwo(v)}%`;
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
            </Spin>
        </div>
    );
};
export default PerformanceProductSale;
