import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { ISSMBusinessTypeData, ISSMStageSetting } from '../store';
import _ from 'lodash';
import { Input, Tag, message, Spin, Checkbox } from 'antd';
import { toJS } from 'mobx';
import { AntButton } from '@/components';
import SSMTransferModal from '../ssmTransferModal';

import './index.scss';

interface ISSMActionBodyProps {
    selectedData: ISSMBusinessTypeData;
    reloadBusinessTypeData: () => void;
    fetchBusinessTypeLoading: boolean;
}
const SSMActionBody: React.FC<ISSMActionBodyProps> = props => {
    const {
        selectedData,
        reloadBusinessTypeData,
        fetchBusinessTypeLoading,
    } = props;
    const store = useContext(StoreContext);
    const [updateData, setUpdateData] = useState<ISSMStageSetting[]>([]);
    const [transferModalVis, setTransferModalVis] = useState(false);
    const [selectedRequired, setSelectedRequired] = useState<{
        requiredField: any[];
        requiredCondition: any[];
    }>({
        requiredField: [],
        requiredCondition: [],
    });
    const [modalMode, setModalMode] = useState<'condition' | 'field'>(
        'condition',
    );
    const SSMStore = store.getDefaultSSMStore();
    const {
        SSMStageRequirement,
        SSMGroupStageRequirement,
        updateSSMBizTypeLoading,
        updateBusiessTypeSetting,
        getSSMStageRequirementConfig,
        fetchConfigLoading,
    } = SSMStore;
    useEffect(() => {
        if (
            selectedData &&
            !_.isEmpty(selectedData) &&
            selectedData?.sysOpportunitySaleStageDtoList
        ) {
            getSSMStageRequirementConfig(selectedData.bizType as string);
            setUpdateData(
                _.cloneDeep(selectedData.sysOpportunitySaleStageDtoList),
            );
        }
    }, [selectedData]);
    const onUpdateActionHandle = async () => {
        const cloneUpdateData = _.cloneDeep(updateData);
        const transArrayToString = (strArray: string[]) => {
            if (strArray.length > 1) {
                return strArray.join(',');
            } else if (strArray.length === 0) {
                return '';
            } else {
                return strArray[0];
            }
        };
        const transUpdateData = _.map(cloneUpdateData, v => {
            return {
                ...v,
                requiredField: transArrayToString(v.requiredField as string[]),
                requiredCondition: transArrayToString(
                    v.requiredCondition as string[],
                ),
                notifyUserType: transArrayToString(
                    v.notifyUserType as string[],
                ),
            };
        });
        const [d, e] = await updateBusiessTypeSetting(transUpdateData);
        if (d && !d?.msg) {
            message.success('修改成功', 1);
            reloadBusinessTypeData();
        } else {
            message.error('修改失败', 1);
        }
    };

    const renderStage = (stageData: ISSMStageSetting) => {
        return (
            <div className="ssm-stage-items">
                <p className="ssm-stage-rank">{stageData.stageNum}</p>
                <div className="ssm-stage-info">
                    <div className="stage-winrate">
                        赢率：{stageData.winRate}%
                    </div>
                    <div className="stage-name">
                        <span>阶段名称：</span>
                        <Input
                            value={stageData.stageName}
                            onChange={e => {
                                stageData.stageName = e.target.value;
                                setUpdateData([...updateData]);
                            }}
                        />
                    </div>
                </div>
                <div className="ssm-stage-required">
                    <div className="ssm-stage-required-title">阶段要求：</div>
                    <div style={{ paddingLeft: '10px' }}>
                        <div
                            className="ssm-stage-required-title"
                            style={{ color: '#6e727a' }}
                        >
                            必填字段：
                        </div>
                        <div className="ssm-stage-required-tags">
                            {!stageData?.requiredField?.length && <div>无</div>}
                            {_.map(stageData?.requiredField || [], field => {
                                const fieldConfigArray =
                                    SSMGroupStageRequirement.requiredField[
                                        field
                                    ] || [];
                                return (
                                    <Tag
                                        style={{ marginTop: '2px' }}
                                        key={field}
                                        closable={true}
                                        onClose={() => {
                                            const fieldArray =
                                                stageData?.requiredField || [];
                                            _.remove(fieldArray, item => {
                                                return item === field;
                                            });
                                            setUpdateData([...updateData]);
                                        }}
                                    >
                                        {fieldConfigArray[0]?.fieldName ||
                                            field}
                                    </Tag>
                                );
                            })}
                        </div>
                    </div>
                    <div style={{ paddingLeft: '10px' }}>
                        <div
                            className="ssm-stage-required-title"
                            style={{ color: '#6e727a' }}
                        >
                            必需条件：
                        </div>
                        <div className="ssm-stage-required-tags">
                            {!stageData?.requiredCondition?.length && (
                                <div>无</div>
                            )}
                            {_.map(
                                stageData?.requiredCondition || [],
                                condition => {
                                    const conditionConfigArray =
                                        SSMGroupStageRequirement
                                            .requiredCondition[condition] || [];
                                    return (
                                        <Tag
                                            style={{ marginTop: '2px' }}
                                            key={condition}
                                            closable={true}
                                            onClose={() => {
                                                const conditionArray =
                                                    stageData?.requiredCondition ||
                                                    [];
                                                _.remove(
                                                    conditionArray,
                                                    item => {
                                                        return (
                                                            item === condition
                                                        );
                                                    },
                                                );
                                                setUpdateData([...updateData]);
                                            }}
                                        >
                                            {conditionConfigArray[0]
                                                ?.conditionName || condition}
                                        </Tag>
                                    );
                                },
                            )}
                        </div>
                    </div>
                    <div className="ssm-add-required">
                        <span
                            onClick={() => {
                                setModalMode('field');
                                setSelectedRequired({
                                    requiredCondition:
                                        stageData.requiredCondition,
                                    requiredField: stageData.requiredField,
                                });
                                setTransferModalVis(true);
                            }}
                        >
                            添加必填字段
                        </span>
                        <span
                            onClick={() => {
                                setModalMode('condition');
                                setSelectedRequired({
                                    requiredCondition:
                                        stageData.requiredCondition,
                                    requiredField: stageData.requiredField,
                                });
                                setTransferModalVis(true);
                            }}
                        >
                            添加必需条件
                        </span>
                    </div>
                </div>
                {!!SSMStageRequirement.notifyTypes.length && (
                    <div className="ssm-stage-required">
                        <div className="ssm-stage-required-title">
                            阶段提醒：
                        </div>
                        <div style={{ paddingLeft: '10px' }}>
                            <div
                                className="ssm-stage-required-title"
                                style={{ color: '#6e727a' }}
                            >
                                提醒对象：
                            </div>

                            <Checkbox.Group
                                options={SSMStageRequirement.notifyTypes.map(
                                    item => ({
                                        label: item.typeName,
                                        value: item.notifyUserType,
                                    }),
                                )}
                                defaultValue={stageData.notifyUserType}
                                onChange={values => {
                                    stageData.notifyUserType = values as string[];
                                    setUpdateData([...updateData]);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };
    return (
        <>
            <SSMTransferModal
                requiredConfig={SSMStageRequirement}
                requiredData={selectedRequired}
                setModalVis={setTransferModalVis}
                modalVis={transferModalVis}
                mode={modalMode}
                onOkClickHandle={data => {
                    const oRequiredData =
                        modalMode === 'condition'
                            ? selectedRequired.requiredCondition
                            : selectedRequired.requiredField;
                    // 双副作用修改
                    while (oRequiredData.length > 0) {
                        oRequiredData.pop();
                    }
                    for (const item of data) {
                        oRequiredData.push(item);
                    }
                    setUpdateData([...updateData]);
                }}
            />
            <Spin spinning={fetchConfigLoading || fetchBusinessTypeLoading}>
                <div className="bo-action-content">
                    <div className="bo-action-title">阶段及要求设置</div>
                    <div className="bo-stage-list">
                        {_.map(updateData, renderItem => {
                            return renderStage(renderItem);
                        })}
                    </div>
                    <AntButton
                        className={`bo-save-btn`}
                        size="default"
                        type="primary"
                        onClick={onUpdateActionHandle}
                        loading={updateSSMBizTypeLoading}
                    >
                        保存
                    </AntButton>
                </div>
            </Spin>
        </>
    );
};
export default observer(SSMActionBody);
