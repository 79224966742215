import React, { memo } from 'react';

export const EquityDetail: React.FC<{
    args: any[];
    item: any;
    onAction: (...args: any[]) => void;
}> = memo(({ args, item, onAction }) => {
    return (
        <a
            onClick={() => {
                onAction('see-equity-detail', item);
            }}
        >
            查看权益
        </a>
    );
});
