import React, { useState } from 'react';
import moment from 'moment';
import classname from 'classname';
import { Button } from 'antd';
import { CCStore } from '@/containers/hoc/call-center/store';
import { IconTelephone, IconArrow } from '../icon';
import './index.less';

interface CallBarProps {
    callStore?: CCStore;
    style?: React.CSSProperties;
    onCollapse?: (collapsed: boolean) => void;
}

const CallBar: React.FC<CallBarProps> = ({ callStore, style, onCollapse }) => {
    const [collapsed, setCollapsed] = useState(false);

    const handleCollapse = () => {
        const current = !collapsed;
        setCollapsed(current);
        onCollapse && onCollapse(current);
    };

    if (!callStore) return null;
    return (
        <div className="call-bar-container" style={style}>
            <div className="call-bar-wrap">
                <div className="call-bar-lable">
                    <div className="call-bar-lable-cn">
                        {callStore.supplierDesc.supplierCn}
                    </div>
                    <strong>{callStore.supplierDesc.no}</strong>
                </div>
                <div className="call-bar-divide-line" />
                <div className="call-bar-status">
                    <strong>
                        {callStore.statusText === '空闲中'
                            ? '已挂断'
                            : callStore.statusText}
                    </strong>
                </div>
            </div>
            <div className="call-bar-wrap">
                <div className="call-bar-timer">
                    通话时间：
                    {callStore.showCallingTime
                        ? moment(callStore.callingTime * 1000).format('mm:ss')
                        : '- -'}
                </div>
                <Button
                    shape="circle"
                    size="large"
                    type="danger"
                    className="call-bar-control-button"
                    loading={callStore.somethingIsLoading}
                    disabled={!callStore.isCalling()}
                    onClick={() => callStore.closeCall()}
                >
                    <IconTelephone
                        style={
                            callStore.somethingIsLoading
                                ? { display: 'none' }
                                : undefined
                        }
                    />
                </Button>
                <Button
                    shape="circle"
                    size="large"
                    onClick={handleCollapse}
                    className={classname('call-bar-control-button', {
                        rotate: !collapsed,
                    })}
                >
                    <IconArrow />
                </Button>
            </div>
        </div>
    );
};

export default CallBar;
