import React from 'react';
import _ from 'lodash';
import { Icon } from 'antd';
import { inspectRouter, getFlattenRouter, arrToJson } from '@/utils';
import { globalHistory, HistoryLocation } from '@reach/router';

export interface IRouteItem {
    key: string;
    cn: string;
    children?: IRouteItem[];
    icon?: string | React.ReactElement;
    schema: string;
    selectedRoute?: IRouteItem;
    visible?: boolean;
    defaultSelectedChildrenPath?: string;
    forceShow?: boolean; // for test
}

const routeLayout: IRouteItem[] = [
    {
        cn: '样例',
        schema: '/example',
        key: 'example',
        visible: false,
        defaultSelectedChildrenPath: '/example/mobx/counter',
        children: [
            {
                icon: <span className="iconfont left-menu-icon">&#xe604;</span>,
                cn: 'mobx',
                schema: '/mobx',
                key: 'mobx-counter',
                children: [
                    {
                        cn: 'counter',
                        schema: '/counter',
                        key: 'mobx-dummy',
                    },
                    {
                        cn: 'counter',
                        schema: '/counter-invisible',
                        key: 'mobx-dummy-invisible',
                        visible: false,
                    },
                ],
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe605;</span>,
                cn: '基础场景',
                schema: '/demo2',
                key: 'demo2-wrapper',
                children: [
                    {
                        cn: 'loading',
                        schema: '/loading',
                        key: 'demo2-loading',
                    },
                    {
                        cn: 'graphql',
                        schema: '/gql',
                        key: 'demo2-graphql',
                    },
                    {
                        cn: 'form',
                        schema: '/form',
                        key: 'demo2-form',
                    },
                    {
                        cn: 'form2',
                        schema: '/form2',
                        key: 'demo2-form2',
                    },
                    {
                        cn: 'sgrid',
                        schema: '/grid',
                        key: 'demo2-grid',
                    },
                    {
                        cn: 'crud',
                        schema: '/crud',
                        key: 'demo2-crud',
                    },
                    {
                        cn: 'standard-crud',
                        schema: '/standard-crud',
                        key: 'demo2-standard-crud',
                    },
                    {
                        cn: '时间轴',
                        schema: '/timeline',
                        key: 'demo2-timeline',
                    },
                    {
                        cn: 'searchable-tree',
                        schema: '/searchable-tree',
                        key: 'demo2-searchable-tree',
                    },
                    {
                        cn: '用户选择',
                        schema: '/user-selector',
                        key: 'demo2-userselector',
                    },
                    {
                        cn: '定制抽屉',
                        schema: '/crm-drawer',
                        key: 'demo2-crm-drawer',
                    },
                    {
                        cn: '定制列表选择',
                        schema: '/crm-tablecheckbox',
                        key: 'demo2-crm-tablecheckbox',
                    },
                    {
                        cn: '高级filter',
                        schema: '/crm-advanced-filter',
                        key: 'demo2-advanced-filter',
                    },
                    {
                        cn: '杂七杂八',
                        schema: '/crm-test',
                        key: 'demo2-test',
                    },
                    {
                        cn: 'crud模版',
                        schema: '/crud-template',
                        key: 'demo2-crud-template',
                    },
                    {
                        cn: '商机阶段进度条',
                        schema: '/opportunity-step',
                        key: 'demo2-opportunity-step',
                    },
                    {
                        cn: '打电话',
                        schema: '/call',
                        key: 'demo2-call',
                    },
                    {
                        cn: '可编辑列表',
                        schema: '/editalbe-diplay',
                        key: 'demo2-editalbe-diplay',
                    },
                    {
                        cn: '测试table性能',
                        schema: '/table-test',
                        key: 'demo2-table-test',
                    },
                ],
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe603;</span>,
                cn: 'antd',
                schema: '/antd',
                key: 'demo2-antd',
            },
        ],
    },
    {
        cn: '首页',
        schema: '/home',
        key: 'home',
        defaultSelectedChildrenPath: '/home',
    },
    {
        cn: '首页',
        schema: '/home-new',
        key: 'home-new',
        defaultSelectedChildrenPath: '/home-new',
        visible: false,
    },
    {
        cn: 'TL工作台',
        schema: '/tl-worktable',
        key: 'tl-worktable',
        defaultSelectedChildrenPath: '/tl-worktable',
        visible: false,
    },
    {
        cn: '配置中心',
        schema: '/setting',
        key: 'setting',
        defaultSelectedChildrenPath: '/setting/user-authority',
        visible: false,
        children: [
            {
                icon: <Icon type="security-scan" />,
                key: 'user-authority',
                cn: '用户与权限',
                schema: '/user-authority',
                children: [
                    {
                        key: 'organization-personnel-management',
                        cn: '组织人员管理',
                        schema: '/opm',
                    },
                    {
                        key: 'data-role-management',
                        cn: '数据角色管理',
                        schema: '/dpm',
                    },
                    {
                        key: 'function-personnel-management',
                        cn: '功能角色管理',
                        schema: '/fpm',
                    },
                ],
            },
            {
                icon: <Icon type="user" />,
                key: 'customer-hsp',
                cn: '客户公海池',
                schema: '/custmer-hsp',
                children: [
                    {
                        key: 'customer-grouping',
                        cn: '客户公海池分组',
                        schema: '/grouping',
                    },
                    {
                        key: 'customer-limit',
                        cn: '客户私池数量限制',
                        schema: '/limit',
                    },
                    // {
                    //     key: 'customer-reject-reason',
                    //     cn: '退回原因设置',
                    //     schema: '/reject-reason',
                    // },
                    {
                        key: 'customer-automatic-allocation',
                        cn: '公海池自动分配',
                        schema: '/automatic-allocation',
                    },
                    {
                        key: 'customer-receive',
                        cn: '优先领取',
                        schema: '/customer-receive',
                    },
                ],
            },
            {
                icon: <Icon type="border-outer" />,
                cn: '区域管理',
                schema: '/regional-management',
                key: 'regional-management',
                children: [
                    {
                        cn: '客户新建区域限制',
                        schema: '/regional-customer-limit',
                        key: 'regional-customer-limit',
                    },
                ],
            },
            // {
            //     icon: <Icon type="bulb" />,
            //     key: 'clue-hsp',
            //     cn: '线索公海池',
            //     schema: '/clue-hsp',
            //     children: [
            //         {
            //             cn: '线索公海池分组',
            //             key: 'clue-grouping',
            //             schema: '/grouping',
            //         },
            //         {
            //             cn: '线索私池数量限制',
            //             key: 'clue-limit',
            //             schema: '/limit',
            //         },
            //         {
            //             cn: '退回原因',
            //             key: 'clue-reject-reason',
            //             schema: '/reject-reason',
            //         },
            //     ],
            // },
            {
                icon: <Icon type="project" />,
                cn: '商机',
                schema: '/business-opportunities',
                key: 'business-opportunities',
                children: [
                    {
                        cn: '销售阶段管理',
                        key: 'sales-stage-management',
                        schema: '/sales-stage-management',
                    },
                ],
            },
            {
                icon: <Icon type="container" />,
                cn: '日志',
                schema: '/log',
                key: 'log',
                children: [
                    {
                        cn: '登录日志',
                        key: 'login-log',
                        schema: '/login-log',
                    },
                    {
                        cn: '用户操作日志',
                        key: 'user-log',
                        schema: '/user-log',
                    },
                ],
            },
            {
                icon: <Icon type="deployment-unit" />,
                cn: '流程管理',
                schema: '/workflow',
                key: 'workflow',
                children: [
                    {
                        cn: '活动配置',
                        key: 'workflow-activity',
                        schema: '/activity',
                    },
                ],
            },
            {
                icon: <Icon type="phone" />,
                cn: '坐席管理',
                schema: '/cc-admin',
                key: 'call-center-admin',
                children: [
                    {
                        cn: '坐席列表',
                        key: 'call-center-admin-list',
                        schema: '/list',
                    },
                ],
            },
        ],
    },
    {
        cn: '销售跟进',
        schema: '/buy',
        key: 'buy',
        defaultSelectedChildrenPath: '/buy/custom/all-custom',
        children: [
            {
                key: 'global-search',
                cn: '全局搜索',
                visible: false,
                schema: '/global-search',
            },
            {
                icon: (
                    <span className="iconfont1 left-menu-icon">&#xe619;</span>
                ),
                key: 'buy-custom',
                cn: '客户',
                schema: '/custom',
                children: [
                    {
                        key: 'buy-custom-all',
                        cn: '公海客户',
                        schema: '/all-custom',
                    },
                    {
                        key: 'buy-custom-mine',
                        cn: '我的客户',
                        schema: '/my-custom',
                    },
                    {
                        key: 'buy-custom-to-release',
                        cn: '待回收客户',
                        schema: '/to-release',
                    },
                    {
                        key: 'buy-custom-stars',
                        cn: '关注客户',
                        schema: '/stars',
                    },
                    {
                        key: 'buy-custom-outflow',
                        cn: '流出客户',
                        schema: '/outflow',
                    },
                ],
            },
            {
                icon: (
                    <span className="iconfont1 left-menu-icon">&#xe601;</span>
                ),
                key: 'job-management',
                cn: '任务管理',
                schema: '/job-management',
                children: [
                    {
                        key: 'job-management-cus-follow',
                        cn: '客户跟进任务',
                        schema: '/cus-follow',
                    },
                ],
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe605;</span>,
                key: 'buy-lead',
                cn: '线索',
                schema: '/lead',
                children: [
                    {
                        key: 'buy-lead-all',
                        cn: '全部线索',
                        schema: '/all-lead',
                    },
                    {
                        key: 'buy-lead-mine',
                        cn: '我的线索',
                        schema: '/my-lead',
                    },
                ],
            },
            {
                icon: (
                    <span className="iconfont1 left-menu-icon">&#xe616;</span>
                ),
                key: 'buy-opportunity',
                cn: '销售机会',
                schema: '/opportunity',
                children: [
                    {
                        key: 'buy-opportunity-all',
                        cn: '全部商机',
                        schema: '/all',
                    },
                    {
                        key: 'buy-opportunity-product',
                        cn: '商机明细',
                        schema: '/opt-product',
                    },
                ],
            },
            {
                icon: (
                    <span className="iconfont1 left-menu-icon">&#xe614;</span>
                ),
                key: 'buy-contact',
                cn: '联系人',
                schema: '/contact',
                children: [
                    {
                        key: 'buy-contact-all',
                        cn: '全部联系人',
                        schema: '/all',
                    },
                ],
            },
            {
                icon: (
                    <span className="iconfont1 left-menu-icon">&#xe613;</span>
                ),
                key: 'activation-record',
                cn: '活动记录',
                schema: '/activation-record',
                children: [
                    {
                        key: 'activation-record-all',
                        cn: '全部活动记录',
                        schema: '/all',
                    },
                ],
            },
            {
                icon: (
                    <span className="iconfont1 left-menu-icon">&#xe612;</span>
                ),
                key: 'buy-contract',
                cn: '合同管理',
                schema: '/contract',
                children: [
                    {
                        key: 'buy-contract-all',
                        cn: '合同',
                        schema: '/all',
                    },
                    {
                        key: 'buy-contract-invoice',
                        cn: '合同发票申请',
                        schema: '/invoice',
                    },
                    {
                        key: 'buy-contract-invoice-result',
                        cn: '合同开票汇总',
                        schema: '/invoice-result',
                    },
                    {
                        key: 'buy-contract-income',
                        cn: '回款',
                        schema: '/income',
                    },
                    {
                        key: 'buy-contract-product',
                        cn: '合同产品汇总',
                        schema: '/product',
                    },
                    {
                        key: 'buy-contract-product-execute',
                        cn: '开通申请汇总',
                        schema: '/product-execute',
                    },
                ],
            },
            {
                icon: (
                    <span className="iconfont1 left-menu-icon">&#xe615;</span>
                ),
                key: 'process-management',
                cn: '流程管理',
                schema: '/process-management',
                children: [
                    {
                        key: 'process-management-all',
                        cn: '全部流程',
                        schema: '/all',
                    },
                    {
                        key: 'process-management-category',
                        cn: '流程分类',
                        schema: '/category',
                    },
                    {
                        key: 'process-management-group',
                        cn: '流程分组',
                        schema: '/group',
                    },
                ],
            },
        ],
    },
    {
        cn: '运营管理',
        schema: '/om',
        key: 'om',
        defaultSelectedChildrenPath: '/om/product/all',
        children: [
            {
                icon: <span className="iconfont left-menu-icon">&#xe604;</span>,
                key: 'om-achievement',
                cn: '目标管理',
                schema: '/achievement',
                children: [
                    {
                        key: 'om-achievement-management',
                        cn: '业绩目标',
                        schema: '/management',
                    },
                ],
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe604;</span>,
                key: 'om-product',
                cn: '产品管理',
                schema: '/product',
                children: [
                    {
                        key: 'om-product-all',
                        cn: '所有产品',
                        schema: '/all',
                    },
                    {
                        key: 'om-product-equality',
                        cn: '产品权益',
                        schema: '/equality',
                    },
                    {
                        key: 'om-equality-talent-bank-account',
                        cn: '人才银行账号赠送审批',
                        schema: '/talent-bank-account',
                    },
                    {
                        key: 'om-equality-increase-price',
                        cn: '增购价格配置',
                        schema: '/increase-price',
                    },
                    {
                        key: 'om-equality-contract-sign',
                        cn: '合同加签审批配置',
                        schema: '/contract-sign',
                    },
                ],
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe604;</span>,
                key: 'om-contract',
                cn: '合同管理',
                schema: '/contract',
                children: [
                    {
                        key: 'om-contract-all',
                        cn: '合同',
                        schema: '/all',
                    },
                    {
                        key: 'om-contract-invoice',
                        cn: '合同发票申请',
                        schema: '/invoice',
                    },
                    {
                        key: 'om-contract-invoice-result',
                        cn: '合同开票汇总',
                        schema: '/invoice-result',
                    },
                    {
                        key: 'om-contract-income',
                        cn: '回款',
                        schema: '/income',
                    },
                    {
                        key: 'om-contract-product',
                        cn: '合同产品汇总',
                        schema: '/product',
                    },
                    {
                        key: 'om-contract-product-execute',
                        cn: '开通申请汇总',
                        schema: '/product-execute',
                    },
                ],
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe604;</span>,
                key: 'om-data',
                cn: '数据管理',
                schema: '/data',
                children: [
                    {
                        key: 'om-data-import',
                        cn: '数据导入',
                        schema: '/import',
                    },
                    // {
                    //     key: 'om-data-export',
                    //     cn: '数据导出',
                    //     schema: '/export',
                    // },
                    {
                        key: 'om-data-customer-distribution',
                        cn: '批量分配客户',
                        schema: '/distribution-customer',
                    },
                ],
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe604;</span>,
                key: 'om-member',
                cn: '会员管理',
                schema: '/member',
                children: [
                    {
                        key: 'om-member-list',
                        cn: '会员列表',
                        schema: '/list',
                    },
                    // {
                    //     key: 'om-member-data',
                    //     cn: '会员数据',
                    //     schema: '/data',
                    // },
                ],
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe604;</span>,
                key: 'om-tag',
                cn: '标签管理',
                schema: '/tag',
                children: [
                    {
                        key: 'om-tag-list',
                        cn: '标签配置',
                        schema: '/list',
                    },
                    {
                        key: 'om-sale-tag',
                        cn: '售卖标签',
                        schema: '/sale-tag',
                    },
                ],
            },
        ],
    },
    {
        key: 'analysis',
        cn: '数据中心',
        schema: '/analysis',
        defaultSelectedChildrenPath:
            '/analysis/composite/composite-business-live-new',
        children: [
            {
                cn: '仪表盘',
                schema: '/composite',
                icon: (
                    <span className="iconfont1 left-menu-icon">&#xe61b;</span>
                ),
                key: 'composite',
                children: [
                    {
                        key: 'composite-business',
                        cn: '商业大盘',
                        schema: '/composite-business',
                    },
                    {
                        key: 'composite-business-live',
                        cn: '商业大盘实况',
                        schema: '/composite-business-live',
                    },
                    {
                        key: 'composite-business-live-new',
                        cn: '商业大盘实况(新)',
                        schema: '/composite-business-live-new',
                    },
                    {
                        key: 'composite-business-income-board',
                        cn: '回款看板',
                        schema: '/composite-business-income-board',
                    },
                    {
                        key: 'composite-business-prediction-board',
                        cn: '预估看板',
                        schema: '/composite-business-prediction-board',
                    },
                    {
                        key: 'composite-business-opportunity-board',
                        cn: '商机看板',
                        schema: '/composite-business-opportunity-board',
                    },
                    {
                        key: 'composite-business-action-board',
                        cn: '行为看板',
                        schema: '/composite-business-action-board',
                    },
                    {
                        key: 'composite-business-wmx-board',
                        cn: '吴明轩看板',
                        schema: '/composite-business-wmx-board',
                    },
                ],
            },
            {
                icon: (
                    <span className="iconfont1 left-menu-icon">&#xe614;</span>
                ),
                key: 'analysis-sales',
                cn: '销售管理',
                schema: '/sales',
                children: [
                    {
                        key: 'analysis-sales-achievement-completion',
                        cn: '业绩完成情况',
                        schema: '/achievement-completion',
                    },
                    {
                        key: 'analysis-sales-opportunity-reserve',
                        cn: '商机储备情况',
                        schema: '/opportunity-reserve',
                    },
                    {
                        key: 'analysis-sales-lead-follow-up',
                        cn: '线索跟进任务',
                        schema: '/lead-follow-up',
                    },
                    {
                        key: 'analysis-sales-new-training',
                        cn: '新开培训任务',
                        schema: '/new-training',
                    },
                    {
                        key: 'analysis-sales-321-task',
                        cn: '续约321任务',
                        schema: '/321-task',
                    },
                ],
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe605;</span>,
                key: 'analysis-renew',
                cn: '续约管理',
                schema: '/renew',
                children: [
                    {
                        key: 'analysis-renew-cooperate-report',
                        cn: '合作客户数据盘点',
                        schema: '/cooperate-report',
                    },
                ],
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe605;</span>,
                key: 'analysis-lead',
                cn: '线索管理',
                schema: '/lead',
                children: [
                    {
                        key: 'analysis-lead-channel',
                        cn: '渠道汇总',
                        schema: '/channel',
                    },
                    {
                        key: 'analysis-lead-sales-detail',
                        cn: '销售明细',
                        schema: '/sales-detail',
                    },
                    {
                        key: 'analysis-lead-transfer-source',
                        cn: '线索转化（来源）',
                        schema: '/transfer-source',
                    },
                    {
                        key: 'analysis-lead-transfer-belongto',
                        cn: '线索转化（归属）',
                        schema: '/transfer-belongto',
                    },
                    {
                        key: 'analysis-new-opt-source',
                        cn: '新签商机（来源）',
                        schema: '/new-opt-source',
                    },
                    {
                        key: 'analysis-new-sign-source',
                        cn: '新签核心指标',
                        schema: '/new-sign-core',
                    },
                ],
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe605;</span>,
                key: 'analysis-new-sign',
                cn: '新签管理',
                schema: '/new',
                children: [
                    {
                        key: 'analysis-new-sign-core',
                        cn: '新签核心指标',
                        schema: '/core',
                    },
                    {
                        key: 'analysis-new-sign-lead-transfer-source',
                        cn: '线索转化（分渠道）',
                        schema: '/lead-transfer-source',
                    },
                    {
                        key: 'analysis-new-sign-lead-transfer-belongto',
                        cn: '线索转化（站内外）',
                        schema: '/lead-transfer-belongto',
                    },
                    {
                        key: 'analysis-new-sign-opt-source',
                        cn: '新签商机（分渠道）',
                        schema: '/opt-source',
                    },
                ],
            },
            {
                icon: (
                    <span className="iconfont1 left-menu-icon">&#xe612;</span>
                ),
                key: 'analysis-product',
                cn: '产品管理',
                schema: '/product',
                children: [
                    {
                        key: 'analysis-product-rights',
                        cn: '产品权益售卖明细',
                        schema: '/product-rights',
                    },
                ],
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe605;</span>,
                key: 'analysis-business-opportunity',
                cn: '商机分析',
                schema: '/business-opportunity',
            },
            {
                icon: <span className="iconfont left-menu-icon">&#xe605;</span>,
                key: 'analysis-follow-up',
                cn: '销售跟进分析',
                schema: '/follow-up',
            },
        ],
    },
];

if ('pro' !== process.env.REACT_APP_ENV) {
    inspectRouter(routeLayout);
}

const flattenRouterMap = arrToJson(
    getFlattenRouter(_.cloneDeep(routeLayout)),
    'fullSchema',
);
const historyHandlerForDocTitle = (location: HistoryLocation) => {
    const routeItem = flattenRouterMap[location.pathname];
    const cnTitle = routeItem?.cn;
    if (undefined !== cnTitle) {
        document.title = 'CRM - ' + cnTitle;
    } else {
        document.title = '脉脉 - CRM';
    }
};
globalHistory.listen(historyParam => {
    historyHandlerForDocTitle(historyParam.location);
});
historyHandlerForDocTitle(globalHistory.location);

export type IRouteLayout = typeof routeLayout;
export { routeLayout };
