import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    FastFilterStore,
    makeDefaultInitailingHook,
} from '@/stores/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import { mutate } from '@/api/graphql';
import _, { isTypedArray } from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    getLeads,
    TGetLeadsReturnType,
    xDelete,
    commonQuery,
    TGetCommonReturnType,
} from '@/api/rest';
import {
    defaultWritingDataParser,
    transformDataToTreeData,
    arrToJson,
} from '@/utils';
import EE from 'eventemitter3';
import $ from 'jquery';
import moment from 'moment';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';
import { fetchFeEquityDetailByBackEndFormat } from './utils';
import {
    makeDefaultInitailingHookB,
    createDefaultInstanceGetterA,
} from '@/stores/utilsA';

const transformBackEndValidateStateToValidateResult = (
    data: any[],
): ValidateResult<any> => {
    const ret: ValidateResult<any> = {};
    for (const d of data) {
        const { errorMsg, errorType, fieldName } = d;
        if (!errorMsg || !errorType || !fieldName) {
            continue;
        }
        ret[fieldName] = {
            msg: errorMsg,
            status: errorType === 'error' ? 'fail' : 'warning',
        };
    }
    return ret;
};

export class ProductListStore {
    // 数据
    public data: any[] = [];

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public get pageTitle() {
        if (this.listType === 'all') {
            return '全部产品';
        } else if (this.listType === 'mine') {
            return '我的产品';
        }
        return '未知listType';
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFastFilters = this.defaultFastFilters;
        const {
            selectedFastFilters,
            selectedLv4MenuFilters,
        } = defaultFastFilters;

        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );
        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        for (const filterSet of selectedFastFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        for (const filterSet of selectedLv4MenuFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        ret.filters?.push(...defaultFilters);
        if (
            !ret.filters?.find(item => {
                return item.fieldId === 'end_time';
            })
        ) {
            ret.filters?.push({
                fieldId: 'end_time',
                filterValue: moment()
                    .endOf('day')
                    .format('YYYY-MM-DD HH:mm:ss'),
                operateType: '>',
            });
        }
        return ret;
    }

    public get defaultRelatedContrait() {
        const { objectMeta: objFullMeta } = this.defaultMeta;
        const ret: any = {
            constraintObj: {
                objSaveFieldId: 'id',
                objShowFieldId: 'product_name',
                objFindFieldIdList: [
                    'id',
                    'product_name',
                    'product_describe',
                    'product_price',
                    'product_type',
                    'product_version2',
                    'product_deliver_method',
                    'product_subcategories',
                    'equity_detail',
                    'talent_lib_version',
                ],
                constraintObj: 'product',
            },
            fieldConstraintType: 'obj',
            constraintObjMeta: {
                objectCode: 'product',
                objectName: '产品表',
                objectComment: '产品表',
            },
            objShowField: {
                fieldName: 'product_name',
            },
            objSaveField: {
                fieldName: 'id',
            },
        };
        if (objFullMeta) {
            ret.objFullMeta = objFullMeta;
        }
        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }
    public setAction(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra.id) {
                this.fetchInstantlyMutatingDataById(extra.id);
            } else {
                message.error('修改必须传id');
            }
        }
        this.actionType = nextAction;
    }
    public async setActionAsync(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra) {
                if (extra.id) {
                    const ok = await this.fetchInstantlyMutatingDataById(
                        extra.id,
                    );
                    if (!ok) {
                        return false;
                    }
                } else {
                    message.error('修改必须传id');
                    return false;
                }
            }
        }
        this.actionType = nextAction;
        return true;
    }
    public resetAction() {
        this.netValidateResult = {};
        this.actionType = '';
    }
    public async dispatchAction() {
        if (this.actionType === 'create') {
            return this.create();
        } else if (this.actionType === 'update') {
            return this.update();
        }
    }

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;
    get mutatingDataParsed() {
        const ret = defaultWritingDataParser(this.mutatingData);
        // 将equity_detail变成后端需要的形式
        let realEquityDetail: any = null;
        const { equity_detail: equityDetail } = ret;
        console.log(equityDetail, 'equityDetailequityDetail');
        if (_.isArray(equityDetail) && equityDetail.length > 0) {
            const realEquityDetailArr = [];
            for (let equityDetailItem of equityDetail) {
                equityDetailItem = equityDetailItem || {};
                let { relatedObj } = equityDetailItem;
                relatedObj = relatedObj || {};

                const {
                    equity_key: equityKey,
                    value: equityNum,
                    convertibility_discount,
                } = relatedObj;

                if (_.isNil(equityKey) || _.isNil(equityNum)) {
                    continue;
                }
                realEquityDetailArr.push({
                    equityKey,
                    equityNum: Number(equityNum),
                    convertibilityDiscount: convertibility_discount,
                });
            }
            realEquityDetail = JSON.stringify(realEquityDetailArr);
        }
        ret.equity_detail = realEquityDetail;
        // TODO
        console.log('retretretret', ret);
        return ret;
    }

    public setMutatingData(next: any) {
        const { biz_type: next_biz_type } = next || {};
        const { biz_type: prev_biz_type } = this.mutatingData || {};
        if (+next_biz_type !== +prev_biz_type) {
            next.product_catalog = null;
        } else {
            next.product_catalog = Number(next.product_catalog);
        }

        this.mutatingData = next;
    }

    public setCurrentEquityDetailsFocusedData(next: any) {
        this.currentEquityDetailsFocusedData = next;
    }
    public currentEquityDetailsFocusedData: any = null;
    public currentEquityDetailsTableData: any[] = [];
    public currentEquityDetailsLoading: boolean = false;
    public currentEquityDetailsColunms: any[] = [
        {
            title: '权益',
            key: 'equity_name',
            dataIndex: 'equity_name',
        },
        {
            title: '标准权益值',
            key: 'value',
            dataIndex: 'value',
        },
        {
            title: '赠送兑换折扣',
            key: 'convertibility_discount',
            dataIndex: 'convertibility_discount',
        },
    ];
    public async fetchEquityDetailsData(str: string) {
        this.currentEquityDetailsLoading = true;
        const objectsData = await fetchFeEquityDetailByBackEndFormat(str);
        this.currentEquityDetailsLoading = false;
        this.currentEquityDetailsTableData = objectsData.map(item => {
            item = item || {};
            let { relatedObj } = item;
            relatedObj = relatedObj || {};
            const {
                id,
                equity_name,
                value,
                convertibility_discount,
            } = relatedObj;
            return {
                key: id,
                equity_name,
                value,
                convertibility_discount,
            };
        });
        // const sample = [
        //     {
        //         "originalValue": "t2",
        //         "relatedObj": {
        //             "relation_object_map": {},
        //             "equity_name": "test2",
        //             "equity_key": "t2",
        //             "id": 20,
        //             "value": "2"
        //         },
        //         "type": "obj"
        //     },
        //     {
        //         "originalValue": "xx",
        //         "relatedObj": {
        //             "relation_object_map": {},
        //             "equity_name": "xx",
        //             "equity_key": "xx",
        //             "id": 19,
        //             "value": "3"
        //         },
        //         "type": "obj"
        //     }
        // ]
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        const initialFormData = this.defaultMeta.defaultWidgetValues;
        this.mutatingData = _.cloneDeep(initialFormData);
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;

    get fieldAuthCode() {
        return '';
    }

    get dataAuthCode() {
        return '';
    }

    public async fetchInstantlyMutatingDataById(id: any) {
        const { fieldAuthCode, dataAuthCode } = this;

        this.startNewData();
        this.mutatingDataLoading = true;
        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: id,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            undefined,
            dataAuthCode,
        );

        if (d === null || e !== null) {
            return false;
        }
        const { datas } = d;
        if (datas && datas[0]) {
            this.mutatingData = datas[0];
            const { equity_detail } = this.mutatingData;
            if (_.isString(equity_detail)) {
                const feEquityDetail = await fetchFeEquityDetailByBackEndFormat(
                    equity_detail,
                );
                this.mutatingData.equity_detail = feEquityDetail;
            }
            this.mutatingDataLoading = false;

            return true;
        } else {
            this.mutatingDataLoading = false;

            message.error('没有该数据的权限');
            return false;
        }
    }
    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode, dataAuthCode } = this;

        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            this.defaultMeta.tableColsParamsFromUserSetting.map(
                item => item.key,
            ),
            dataAuthCode,
        );

        if (d === null || e !== null) {
            return [d, e];
        }
        const leadsData = d;
        const { amount, datas } = leadsData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    public get productCateLogFlattenTreeItems() {
        const { objectMeta } = this.defaultMeta;
        const productCateLogMeta = objectMeta.find(
            item => item.fieldName === 'product_catalog',
        );
        const items = productCateLogMeta?.constraint?.constraintItemList || [];
        if (!items) {
            return [];
        }
        const flattenItemsBiztypeMap: any = {};

        items.map((item: any) => {
            const bizId = item?.item?.bizId;
            if (!bizId) {
                return;
            }
            const ret: any = {
                key: item.constraintValue,
                title: item.constraintLabel,
            };
            const parentId = item?.item?.parentId;
            if (parentId) {
                ret.parentKey = parentId;
            }
            flattenItemsBiztypeMap[bizId] = flattenItemsBiztypeMap[bizId] || [];
            flattenItemsBiztypeMap[bizId].push(ret);
        });
        return flattenItemsBiztypeMap;
    }
    public get currentProductCateLogFlattenTreeItems() {
        const { mutatingData, productCateLogFlattenTreeItems } = this;
        const { biz_type } = mutatingData;
        if (!biz_type) {
            return [];
        }
        return _.cloneDeep(productCateLogFlattenTreeItems[biz_type] || []);
    }
    public get currentProductCateLogTreeData() {
        const { currentProductCateLogFlattenTreeItems } = this;
        return transformDataToTreeData(currentProductCateLogFlattenTreeItems);
    }

    public async create() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        delete data.id;
        delete data.key;
        const [ret, errMsg] = await mutate('product', 'insert', data);
        if (!ret || errMsg) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('新建成功');
        this.defaultEE.emit('create-success');
        return true;
    }
    public async update() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        delete data.key;
        const [ret, errMsg] = await mutate('product', 'update', data);
        if (!ret || errMsg) {
            return false;
        }
        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('修改成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    public async delete() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = [data.id];
        const [d, e] = await xDelete(tableId, operatingIdList);
        if (!e) {
            message.info('删除成功');
            return true;
        }
        return false;
    }

    public async mdelete() {
        const ids = _.map(this.defaultMSelect.mSeletedData, item => item.id);
        if (ids.length === 0) {
            return;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = ids;
        const [d, e] = await xDelete(tableId, operatingIdList);
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('删除成功');
            return true;
        } else {
            this.defaultMSelect.setMopResult('批量删除结果', d.data);
            return false;
        }
    }

    public dupDetailLead: any = null;
    public setDupDetailLead(product: any) {
        this.dupDetailLead = product;
    }
    public clearDupDetailLead() {
        this.dupDetailLead = null;
    }

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMeta = new ListMeta(
        '/bff/api/rest/meta/product/list',
        '/bff/api/rest/meta/product/list/filters/save',
        '/bff/api/rest/meta/product/list/table-cols/save',
    );
    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();
    public defaultPerm: Perm = new Perm('/bff/api/rest/perm?mod=om:product');
    public defaultMSelect: MSelectStore = new MSelectStore();
    public defaultFastFilters = new FastFilterStore();
    constructor() {
        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultMeta: false,
            defaultFilter: false,
            defaultEE: false,
            defaultPerm: false,
            defaultMSelect: false,
            defaultFastFilters: false,
        });
    }
}

export const getDefaultProductListStore = createDefaultInstanceGetterA(
    ProductListStore,
);

export const useAnyProductStore = makeDefaultInitailingHookB(
    ProductListStore,
    'product',
);
