import { autorun, reaction } from 'mobx';
import { globalHistory, HistoryListener } from '@reach/router';
import querystring from 'querystring';
import _, { reject } from 'lodash';
import { addQueryToHistory } from '@/utils';
import { Perm } from '../perm';
import { ListMeta } from './list-meta';
import { useState, useContext, useEffect } from 'react';
import { StoreContext } from '..';

export * from './pagination';
export * from './sorter';
export * from './filter';
export * from './mselect';
export * from './list-meta';
export * from './fast-filters';
export function createDefaultInstanceGetter<T>(
    Class: new (...args: any[]) => T,
): () => T {
    let instance: Nullable<T> = null;
    return function getDefaultInstance(): T {
        if (instance === null) {
            instance = new Class();
        }
        return instance;
    };
}

export interface IDefaultEntityStore {
    // 目前需要初始化的store可能含有一些需要异步加载的成员
    defaultPerm?: Perm; // 权限api请求
    defaultMeta?: ListMeta; // 元信息api请求
}
// 高阶hook
export const makeDefaultInitailingHook = <T extends IDefaultEntityStore>(
    Kls: new () => T, // 一个满足IDefaultEntityStore的class
    entityCode: string, // 实体代码
) => {
    const useAStore = (options: {
        mayBeInitedStore?: T;
        opUserSuffix?: string;
        autoLoad?: boolean;
    }): [boolean, T, () => void] => {
        let {
            mayBeInitedStore,
            opUserSuffix = 'default',
            autoLoad = true,
        } = options;
        const store = useContext(StoreContext);
        const userStore = store.getAuthStore();

        const [newStore] = useState(() => new Kls());
        if (mayBeInitedStore === undefined) {
            mayBeInitedStore = newStore;
        }

        const [loadTrigger, setLoadTrigger] = useState(0);
        const manuallyLoad = () => {
            setLoadTrigger(loadTrigger + 1);
        };
        const [prepared, setPrepared] = useState(false);
        const { defaultMeta, defaultPerm } = mayBeInitedStore;
        const genPromises = () =>
            [
                defaultMeta
                    ? new Promise((resolve, reject) => {
                          if (defaultMeta.inited || defaultMeta.loading) {
                              return resolve();
                          }
                          defaultMeta
                              .fetch()
                              .then(resolve)
                              .catch(reject);
                      })
                    : undefined,
                defaultPerm
                    ? new Promise((resolve, reject) => {
                          if (defaultPerm.inited || defaultPerm.loading) {
                              return resolve();
                          }
                          defaultPerm
                              .fetch()
                              .then(resolve)
                              .catch(reject);
                      })
                    : undefined,
            ].filter(Boolean) as any;

        useEffect(() => {
            if (autoLoad === false && loadTrigger === 0) {
                return;
            }
            if (userStore.userInfo && defaultMeta) {
                defaultMeta.setTableId(entityCode);
                if (!defaultMeta.opUsername) {
                    defaultMeta.setOpUsername(
                        userStore.userInfo.userId + '-' + opUserSuffix,
                    );
                }
            }
            Promise.all(genPromises())
                .then(() => {
                    setPrepared(true);
                })
                .catch(e => {
                    setPrepared(false);
                }); // 初始化元信息
        }, [loadTrigger]);

        return [prepared, mayBeInitedStore, manuallyLoad];
    };
    return useAStore;
};

export interface IStoreHistoyBidirectBindOption {
    withInit?: boolean;
}

export function storeHistoyBidirectBind(
    store: {
        caredHistorySearchParams: string[];
        // 虽然从ts的角度来说它就是个普通，不过实际上它必须是个observable
        queryInputFromStore: querystring.ParsedUrlQueryInput;
        mutateStoreFromSearchChange: (
            parsedSearchObject: querystring.ParsedUrlQueryInput,
        ) => void;
    },
    inputOptions?: IStoreHistoyBidirectBindOption,
) {
    let lockHistoryListening = false;
    let lockStoreListening = false;

    const options: IStoreHistoyBidirectBindOption = inputOptions || {};
    const { withInit = true } = options;
    const { mutateStoreFromSearchChange } = store;

    const selectCaredKeys = (
        object: querystring.ParsedUrlQueryInput,
        caredHistorySearchParams: string[],
    ) => {
        return _.pick(object, caredHistorySearchParams);
    };

    const isChanged = (
        nextSearchObject: querystring.ParsedUrlQueryInput,
        currentSearchObject: querystring.ParsedUrlQueryInput,
        caredHistorySearchParams: string[],
    ) => {
        const caredNextSearchObject = selectCaredKeys(
            nextSearchObject,
            caredHistorySearchParams,
        );
        const caredCurrentSearchObject = selectCaredKeys(
            currentSearchObject,
            caredHistorySearchParams,
        );
        if (
            querystring.stringify(caredNextSearchObject) ===
            querystring.stringify(caredCurrentSearchObject)
        ) {
            return false;
        }
        return true;
    };

    const disposeAutoRun = reaction(
        () => {
            const {
                caredHistorySearchParams,
                queryInputFromStore: nextSearchObject,
            } = store;
            return {
                caredHistorySearchParams,
                queryInputFromStore: nextSearchObject,
            };
        },
        mutatedStore => {
            const {
                caredHistorySearchParams,
                queryInputFromStore: nextSearchObject,
            } = mutatedStore;

            try {
                if (lockStoreListening) {
                    return;
                }
                lockHistoryListening = true;

                const currentSearch = globalHistory.location.search.substring(
                    1,
                );
                const currentSearchObject = querystring.parse(currentSearch);
                if (
                    !isChanged(
                        nextSearchObject,
                        currentSearchObject,
                        caredHistorySearchParams,
                    )
                ) {
                    return;
                }

                addQueryToHistory(
                    // all query
                    selectCaredKeys(nextSearchObject, caredHistorySearchParams),
                    // unused query to remove
                    _.difference(
                        caredHistorySearchParams,
                        _.keys(nextSearchObject),
                    ),
                );
            } catch (e) {
                console.error(e);
            } finally {
                lockHistoryListening = false;
            }
        },
    );

    const innerHandler: HistoryListener = e => {
        try {
            if (lockHistoryListening) {
                return;
            }
            lockStoreListening = true;

            if (e.action !== 'POP') {
                return;
            }
            const {
                caredHistorySearchParams,
                queryInputFromStore: currentSearchObject,
            } = store;
            const nextLocation = e.location;
            const nextSearch = nextLocation.search.substring(1);
            const nextSearchObject = querystring.parse(nextSearch);

            if (
                !isChanged(
                    nextSearchObject,
                    currentSearchObject,
                    caredHistorySearchParams,
                )
            ) {
                return;
            }

            mutateStoreFromSearchChange(
                selectCaredKeys(nextSearchObject, caredHistorySearchParams),
            );
        } catch (e) {
            console.error(e);
        } finally {
            lockStoreListening = false;
        }
    };
    const unsubHistory = globalHistory.listen(innerHandler);
    if (withInit) {
        innerHandler({
            action: 'POP',
            location: globalHistory.location,
        });
    }

    return () => {
        disposeAutoRun();
        unsubHistory();
    };
}
