import React, { useState, useEffect, useContext, CSSProperties } from 'react';
import { StoreContext } from '@/stores';
import { homeRangeOptions, homeTargetLevelOptions } from '../../store';
import _ from 'lodash';
import './index.less';
import {
    trans2W,
    trans2DotTwo,
} from '@/pages/analysis/dashboard/composite-live/component/achievement-situation';
import { Icon, Spin, Select, Statistic } from 'antd';
import PerformanceClockItem from '../../../../analysis/dashboard/composite-live/component/achievement-situation/component/clock-item/index';
import BigNumberCard from '@/pages/analysis/dashboard/composite-live/component/big-number-card';
import { observer } from 'mobx-react-lite';

const HomeAchievementSituation: React.FC = () => {
    const store = useContext(StoreContext);
    const homeNewStore = store.getDefaultHomeNewStore();
    const {
        setHomeNewFilterData,
        homeNewFilterData,
        homeNewData,
        getHomeNewData,
        setHomeNewSelectedData,
        homeNewSelectedData,
        homeNewDataLoading,
        homeNewDataRefreshTime,
    } = homeNewStore;
    useEffect(() => {
        getHomeNewData();
    }, [homeNewFilterData, homeNewSelectedData]);
    const rangeSelectedOptionItem = _.find(homeRangeOptions, opt => {
        return opt.value == homeNewFilterData.range;
    });
    const levelSelectedOptionItem = _.find(homeTargetLevelOptions, opt => {
        return opt.value == homeNewFilterData.targetLevel;
    });
    const renderTipsText = () => {
        if (!homeNewData?.speed_gap) {
            return null;
        }
        let status = 'error';
        let text = `落后${trans2DotTwo(homeNewData?.speed_gap)}%`;
        // if (homeNewData.speed_gap > 0 && homeNewData.speed_gap < 10) {
        //     status = 'warning';
        //     text = `落后${trans2DotTwo(homeNewData?.speed_gap)}%`;
        // }
        if (homeNewData.speed_gap < 0) {
            status = 'success';
            text = `领先${trans2DotTwo(-homeNewData?.speed_gap)}%`;
        }
        return <span className={`home-a-s-tips-text ${status}`}>{text}</span>;
    };
    const statisticValueMap: {
        title: string;
        key: string;
        preText?: string;
        prodText?: string;
        formatter: any;
    }[] = [
        {
            title: `目标（${levelSelectedOptionItem?.label || ''}）`,
            key: 'income_target',
            formatter: (v: any) => `${trans2W(v)}`,
            prodText: 'W',
        },
        {
            title: `GAP（${levelSelectedOptionItem?.label || ''}）`,
            key: 'income_gap',
            formatter: (v: any) => `${trans2W(v)}`,
            prodText: 'W',
        },
        {
            title: '目标完成率',
            key: 'speed_income',
            formatter: (v: any) => `${trans2DotTwo(v)}`,
            prodText: '%',
        },
        {
            title: '时间进度',
            key: 'speed_day',
            formatter: (v: any) => `${trans2DotTwo(v)}`,
            prodText: '%',
        },
        {
            title: `${rangeSelectedOptionItem?.label}剩余工作日`,
            key: 'day_remain',
            formatter: (v: any) => v,
        },
    ];
    const statisticValueStyle: CSSProperties = {
        fontSize: '16px',
        // color: '#0052ff',
        fontWeight: 500,
    };
    return (
        <div className="new-home-achievement-situation-content achievement-situation-content">
            <Spin spinning={homeNewDataLoading}>
                <div className="a-s-body">
                    <div className="a-s-f-header">
                        <div className="a-s-f-left">
                            <span className="a-s-f-title">
                                业绩目标达成情况
                            </span>
                            <span className="a-s-f-time">
                                （更新时间：
                                {homeNewDataRefreshTime
                                    ? homeNewDataRefreshTime.format(
                                          'YYYY-MM-DD HH:mm:ss',
                                      )
                                    : '- -'}
                                ）
                            </span>
                        </div>
                        <div className="a-s-f-filter">
                            {homeNewSelectedData.selectedLevel === 0 && (
                                <div className="a-s-f-item">
                                    <label htmlFor="">目标级别</label>
                                    <Select
                                        value={homeNewFilterData.targetLevel}
                                        size="small"
                                        onChange={(value: any) => {
                                            setHomeNewFilterData({
                                                ...homeNewFilterData,
                                                targetLevel: value,
                                            });
                                        }}
                                        style={{
                                            minWidth: '70px',
                                        }}
                                    >
                                        {_.map(
                                            homeTargetLevelOptions,
                                            option => {
                                                return (
                                                    <Select.Option
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </Select.Option>
                                                );
                                            },
                                        )}
                                    </Select>
                                </div>
                            )}
                            <div className="a-s-f-item">
                                <label htmlFor="">时间</label>
                                <Select
                                    size="small"
                                    value={homeNewFilterData.range}
                                    onChange={(value: any) => {
                                        setHomeNewFilterData({
                                            ...homeNewFilterData,
                                            range: value,
                                        });
                                    }}
                                    style={{
                                        minWidth: '80px',
                                    }}
                                >
                                    {_.map(homeRangeOptions, option => {
                                        return (
                                            <Select.Option value={option.value}>
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="a-s-data">
                        <BigNumberCard
                            title={`${rangeSelectedOptionItem?.label}回款`}
                            number={homeNewData?.income_money || 0}
                            showToday={true}
                            isHomeNew={true}
                            todayEl={
                                <span className="a-s-data-income-today">
                                    今日回款：
                                    {!_.isNil(homeNewData?.income_today) ? (
                                        <span className="a-s-data-income-number">
                                            {trans2W(homeNewData?.income_today)}
                                            w
                                        </span>
                                    ) : (
                                        '- -'
                                    )}
                                </span>
                            }
                        />
                        <div className="a-s-static-content">
                            {_.map(statisticValueMap, item => {
                                return (
                                    <div className="a-s-static-item">
                                        {homeNewData?.[item.key] ? (
                                            <div className="a-s-statics-data">
                                                <Statistic
                                                    style={statisticValueStyle}
                                                    groupSeparator={','}
                                                    prefix={item.preText || ''}
                                                    value={
                                                        homeNewData?.[item.key]
                                                            ? item.formatter(
                                                                  homeNewData?.[
                                                                      item.key
                                                                  ],
                                                              )
                                                            : '- -'
                                                    }
                                                    suffix={item.prodText || ''}
                                                />
                                            </div>
                                        ) : (
                                            <Statistic
                                                style={statisticValueStyle}
                                                groupSeparator={','}
                                                value={'- -'}
                                            />
                                        )}
                                        <p className="a-s-static-title">
                                            {item.title}
                                            {item.key === 'speed_income'
                                                ? renderTipsText()
                                                : null}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default observer(HomeAchievementSituation);
