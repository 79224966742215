import React, { ReactNode } from 'react';
import { Col, Row } from 'antd';
import _ from 'lodash';
import { ColProps } from 'antd/lib/col';
import { RowProps } from 'antd/lib/row';

type TSGridCol = number | [number, number] | ColProps;
type TSGridRol =
    | TSGridCol[]
    | (RowProps & {
          cols: TSGridCol[];
      });

export type TSGridLayoutProp = TSGridRol[];

export const SGridDefaultGutters: { [key: string]: [number, number] } = {
    compact: [8, 8],
    normal: [16, 16],
    big: [24, 24],
};

export type TGutterType = 'compact' | 'normal' | 'big';
export type TRowGultter = TGutterType | [number, number];
export interface ISGridLayout {
    layout: TSGridLayoutProp;
    rowGutter?: TRowGultter;
    rowRender?: (eles: React.ReactNode[]) => React.ReactNode;
}

export const SGrid: React.FC<ISGridLayout> = ({
    layout,
    rowGutter = 'normal',
    children: cld,
    rowRender,
}) => {
    const children = _.isArray(cld) ? cld : [cld];
    let i = 0;
    const getAChild = () => {
        return children[i++];
    };
    const rows = layout.map(r => {
        let currentRowProps: RowProps = {};
        let cols: TSGridCol[] = [];
        if (!_.isArray(r)) {
            currentRowProps = r;
            cols = r.cols;
        } else {
            cols = r;
        }
        const gutter =
            currentRowProps.gutter ||
            (_.isString(rowGutter)
                ? SGridDefaultGutters[rowGutter]
                : rowGutter);
        currentRowProps.gutter = gutter;
        let allNull = true;
        const ret = (
            <Row {...currentRowProps}>
                {cols.map((c, i) => {
                    const child = getAChild();
                    if (!child) {
                        return null;
                        // throw new Error(
                        //     'getAChild return undefined, child不够用了',
                        // );
                    }
                    allNull = false;
                    let currentColProps: ColProps = {};
                    if (_.isNumber(c)) {
                        currentColProps.span = c;
                    } else if (_.isArray(c)) {
                        currentColProps.span = c[0];
                        currentColProps.offset = c[1];
                    } else {
                        currentColProps = c;
                    }
                    return (
                        <Col {...currentColProps} key={i}>
                            {child}
                        </Col>
                    );
                })}
            </Row>
        );
        // 如果这一行没有东西，就不展示这一行
        if (allNull) {
            return null;
        }
        return ret;
    });
    if (rowRender) {
        return <>{rowRender(rows)}</>;
    }
    return <>{rows}</>;
};
