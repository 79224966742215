import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { uuid } from '@/utils';
import { Spin } from 'antd';
import './index.less';
import _ from 'lodash';

export interface IChartsComponentsProps {
    containerClassName?: string;
    chartsOptions: echarts.EChartsOption;
    loading: boolean;
}
// const domId = `charts-${uuid()}`;
// let chartElement: echarts.ECharts | null = null;
const ChartsComponents: React.FC<IChartsComponentsProps> = props => {
    const { containerClassName = '', chartsOptions, loading } = props;
    const [chartElement, setChartElement] = useState<echarts.ECharts | null>(
        null,
    );
    const boxRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef(null);

    const [domId] = useState<string>(`charts-${uuid()}`);
    const [boxId] = useState<string>(`box-${uuid()}`);
    const resizeFunction = _.debounce(
        () => {
            const boxDom = document.getElementById(boxId);
            const chartDom = document.getElementById(domId);
            if (chartDom) {
                chartDom.style.width = `${boxDom?.clientWidth}px` || '0px';
                chartDom.style.height = `${boxDom?.clientHeight}px` || '0px';
                // chartDom.setAttribute("style", `width: ${boxDom?.clientWidth}; height: ${boxDom?.clientHeight}`)
            }
            if (chartElement) {
                chartElement.resize();
            }
        },
        300,
        {
            trailing: true,
        },
    );
    useEffect(() => {
        return () => {
            window.removeEventListener('resize', resizeFunction);
        };
        // console.dir(boxRef.current?.clientWidth;
        // console.log(boxRef.current?.offsetHeight);
        // console.(document.getElementById(boxId)?.offsetWidth);
    }, []);

    useLayoutEffect(() => {
        // console.dir(boxRef.current?.clientWidth);
        // console.log(boxRef.current?.offsetHeight);
        const chartDom = document.getElementById(domId);
        // console.log('dom', chartDom);
        if (chartDom) {
            setChartElement(echarts.init(chartDom));
        }
    }, []);
    useEffect(() => {
        // console.dir(document.getElementById(boxId)?.clientWidth);
        const boxDom = document.getElementById(boxId);
        const chartDom = document.getElementById(domId);
        if (chartDom) {
            chartDom.style.width = `${boxDom?.clientWidth}px` || '0px';
            chartDom.style.height = `${boxDom?.clientHeight}px` || '0px';
            // chartDom.setAttribute("style", `width: ${boxDom?.clientWidth}; height: ${boxDom?.clientHeight}`)
        }
        // console.log('testst', chartsOptions, chartElement);
        if (chartElement && chartsOptions) {
            chartElement.setOption(chartsOptions);
            chartElement.resize();
        }
    }, [chartsOptions]);
    useEffect(() => {
        if (chartElement) {
            window.addEventListener('resize', resizeFunction);
        }
    }, [chartElement]);
    return (
        <Spin spinning={loading}>
            <div id={boxId} className="charts-item-box">
                <div
                    ref={containerRef}
                    className={`charts-item-container ${containerClassName}`}
                    id={domId}
                ></div>
            </div>
        </Spin>
    );
};

export default ChartsComponents;
