import React from 'react';
import _ from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import TableCard, { TableCardProps } from '../../components/table-card';

function ActionOwnerTable<T>({
    dataSource,
    loading,
    dimension,
    ...rest
}: TableCardProps<T> & { dimension: string }) {
    const Columns: ColumnProps<T>[] = [
        {
            title: '指标项',
            dataIndex: 'name',
            width: 130,
            // ellipsis: true,
            fixed: 'left',
        },
        {
            title: '本日销售行为',
            children: [
                {
                    title: '覆盖客户数',
                    dataIndex: 'today_customer_uv',
                },
                {
                    title: '覆盖客户次',
                    dataIndex: 'today_customer_pv',
                },
                {
                    title: '有联系人个数',
                    dataIndex: 'today_contacts_count',
                },
                {
                    title: '微信沟通个数',
                    dataIndex: 'today_weixin',
                },
                {
                    title: '拜访个数',
                    dataIndex: 'today_baifang',
                },
                {
                    title: '视频演示个数',
                    dataIndex: 'today_yuancheng',
                },
                {
                    title: '电话个数',
                    dataIndex: 'today_dianhua',
                },
                {
                    title: '有效电话个数',
                    dataIndex: 'today_vaild_dianhua',
                },
            ],
        },
        {
            title: '本周销售行为',
            children: [
                {
                    title: '覆盖客户数',
                    dataIndex: 'week_customer_uv',
                },
                {
                    title: '覆盖客户次',
                    dataIndex: 'week_customer_pv',
                },
                {
                    title: '有联系人个数',
                    dataIndex: 'week_contacts_count',
                },
                {
                    title: '微信沟通个数',
                    dataIndex: 'week_weixin',
                },
                {
                    title: '拜访个数',
                    dataIndex: 'week_baifang',
                },
                {
                    title: '视频演示个数',
                    dataIndex: 'week_yuancheng',
                },
                {
                    title: '电话个数',
                    dataIndex: 'week_dianhua',
                },
                {
                    title: '有效电话个数',
                    dataIndex: 'week_vaild_dianhua',
                },
            ],
        },
        {
            title: '本月销售行为',
            children: [
                {
                    title: '覆盖客户数',
                    dataIndex: 'month_customer_uv',
                },
                {
                    title: '覆盖客户次',
                    dataIndex: 'month_customer_pv',
                },
                {
                    title: '有联系人个数',
                    dataIndex: 'month_contacts_count',
                },
                {
                    title: '微信沟通个数',
                    dataIndex: 'month_weixin',
                },
                {
                    title: '拜访个数',
                    dataIndex: 'month_baifang',
                },
                {
                    title: '视频演示个数',
                    dataIndex: 'month_yuancheng',
                },
                {
                    title: '电话个数',
                    dataIndex: 'month_dianhua',
                },
                {
                    title: '有效电话个数',
                    dataIndex: 'month_vaild_dianhua',
                },
            ],
        },
        {
            title: '本双月销售行为',
            children: [
                {
                    title: '覆盖客户数',
                    dataIndex: 'session_customer_uv',
                },
                {
                    title: '覆盖客户次',
                    dataIndex: 'session_customer_pv',
                },
                {
                    title: '有联系人个数',
                    dataIndex: 'session_contacts_count',
                },
                {
                    title: '微信沟通个数',
                    dataIndex: 'session_weixin',
                },
                {
                    title: '拜访个数',
                    dataIndex: 'session_baifang',
                },
                {
                    title: '视频演示个数',
                    dataIndex: 'session_yuancheng',
                },
                {
                    title: '电话个数',
                    dataIndex: 'session_dianhua',
                },
                {
                    title: '有效电话个数',
                    dataIndex: 'session_vaild_dianhua',
                },
            ],
        },
    ];
    return (
        <TableCard
            loading={loading}
            columns={Columns}
            dataSource={dataSource}
            className="board-solar-table"
            style={{ boxShadow: 'none', padding: 0 }}
            rowKey="name"
            scroll={{ x: 3200, y: 355 }}
            bodyStyle={{ maxHeight: 355 }}
            {...rest}
        />
    );
}

export default ActionOwnerTable;
