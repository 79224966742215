import { defaultAxios } from '@/utils';
import { message } from 'antd';

// 获取客户信息
export const getCusInfo: (
    customerId: number,
) => Promise<any> = async customerId => {
    const [d, e] = await defaultAxios.get(
        '/crm/activationRecord/cusInfo',
        { customerId },
        { timeout: 100000 }, //可能要删
    );
    if (d === null || d.data === null || e) {
        if (e.message.includes('timeout')) {
            message.error('请求超时，请刷新页面');
        }
        return null;
    }
    return d.data;
};

// 获取客户信息
export const getCusContactPerson: (
    customerId: number,
) => Promise<any> = async customerId => {
    const [d, e] = await defaultAxios.get(
        '/bff/api/rest/customer/follow-person-list',
        { id: customerId, arType: 3 },
        { timeout: 100000 }, //可能要删
    );
    if (d === null || d.data === null || e) {
        if (e.message.includes('timeout')) {
            message.error('请求超时，请刷新页面');
        }
        return null;
    }
    return d.data;
};
