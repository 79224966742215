import React, { memo } from 'react';
import { DForm } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CCAdminListStore } from '../store';

const DemoForm: React.FC<{
    defaultStore: CCAdminListStore;
}> = ({ defaultStore }) => {
    const form = <DForm defaultStore={defaultStore} />;
    return form;
};

const FinalDemoForm = memo(observer(DemoForm));
export default FinalDemoForm;
