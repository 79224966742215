import React, { useMemo } from 'react';
import ChartsWrap from '../../../components/charts-wrap';
import * as echarts from 'echarts';

import './index.less';

type GaugeColors = 'blue' | 'cyan' | 'orange';

interface ChartsGaugeProps {
    value: number;
    label?: string;
    suffix?: string;
    max?: number;
    loading?: boolean;
    color?: GaugeColors;
}

const GaugeColorsMap = {
    blue: ['#5E92FF', '#0004FF'],
    cyan: ['#85E0FF', '#2C8BFF'],
    orange: ['#FFDA08', '#FFA055'],
};

const ChartsGauge: React.FC<ChartsGaugeProps> = props => {
    const {
        loading = false,
        value,
        label,
        suffix = '',
        max = 100,
        color = 'blue',
    } = props;

    const option: echarts.EChartsOption = useMemo(
        () => ({
            series: [
                {
                    type: 'gauge',
                    startAngle: 230,
                    endAngle: -50,
                    min: 0,
                    max,
                    center: ['50%', '50%'],
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0,
                            1,
                            1,
                            0,
                            [
                                {
                                    offset: 0,
                                    color: GaugeColorsMap[color][0], // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: GaugeColorsMap[color][1], // 100% 处的颜色
                                },
                            ],
                            false,
                        ),
                        shadowColor: 'rgba(24, 41, 255, 0.2)',
                        shadowBlur: 10,
                        shadowOffsetX: 2,
                        shadowOffsetY: 2,
                    },
                    progress: {
                        show: true,
                        roundCap: true,
                        width: 10,
                    },
                    pointer: {
                        show: false,
                    },
                    axisLine: {
                        roundCap: true,
                        lineStyle: {
                            width: 10,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    title: {
                        offsetCenter: [0, '30%'],
                        fontSize: 12,
                        color: '#6E727A',
                    },
                    detail: {
                        valueAnimation: true,
                        width: '60%',
                        lineHeight: 40,
                        offsetCenter: [0, '-15%'],
                        fontSize: 18,
                        fontWeight: 'bolder',
                        formatter: `{value}${suffix}`,
                        color: '#000',
                    },
                    data: [
                        {
                            value: value,
                            name: label,
                        },
                    ],
                },
            ],
        }),
        [value, label],
    );
    return (
        <ChartsWrap
            chartsClassName="board-card-charts-content"
            loading={loading}
            chartsOptions={option}
        />
    );
};

export default ChartsGauge;
