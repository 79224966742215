import React, { useState, useEffect, useCallback } from 'react';
import {
    applyCustomConfToCellsSelector,
    ICellMultiple,
    DTable,
    isMultipleCell,
    makeObjColClickable,
    ITableLv2Props,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CustomerListStore } from '../store';
import { runInAction } from 'mobx';
import CustomerDrawer from '@/components/common/customer-drawer';
import { getQueryAsObject, tryRefresh, useQueryAsObject } from '@/utils';
import { useCustomerDrawerSnippets } from '@/components/common/customer-drawer/hooks';
import { CtxProvider } from '@/utils/context';
import { StarCus, StarCusUnStarOnly } from './star';
import { defaultAxios } from '../../../../../utils/net';

const FormDemoTable: React.FC<{
    customerList: CustomerListStore;
    loading: boolean;
    type: string;
    reload?: any;
}> = ({ customerList, loading, type, reload }) => {
    const widthInfoKey = 'custom-list-' + type;
    const eventCode = type === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    const { defaultMSelect } = customerList;
    const { mSeletedData, setMSelectedData } = defaultMSelect;

    // const [start] = useState({v: Date.now()})
    // useEffect(() => {
    //     console.log('mount time: ', Date.now() - start.v)
    //     start.v = Date.now()
    // }, [customerList.data]);
    const [customerDrawerVis, setCustomerDrawerVis] = useState(false);
    const [cid, setCid] = useState<any>(undefined);

    useQueryAsObject(queryObj => {
        if (queryObj.id) {
            setCid(queryObj.id);
            setCustomerDrawerVis(true);
        }
        // 暂时统一处理，后续可能分开处理
        // if (queryObj.target === 'notice') {
        // }
    });

    const [___, cusDrawerSnippet] = useCustomerDrawerSnippets({
        drawerVis: customerDrawerVis,
        setDrawerVis: setCustomerDrawerVis,
        cid,
        customerList,
        type,
        autoLoad: false,
    });

    const onColumnsUpdated = useCallback(() => reload && reload(), [reload]);
    const [dtableOptions] = useState({
        useOriginalPermKey: true,
    });
    const cellsSelectorTransformer = useCallback(
        (originalbaiscCellsSelector: any) => {
            const baiscCellsSelector = [...originalbaiscCellsSelector];
            applyCustomConfToCellsSelector(
                baiscCellsSelector,
                'customer_name',
                () => {
                    const nextCellSelector: ICellMultiple<any> = {
                        colKey: 'customer_name',
                        cells: [],
                    };

                    if (nextCellSelector.cells.length === 0) {
                        nextCellSelector.cells.push({
                            cell: 'ActionParamCustomer',
                            inputs: [
                                'customer_name',
                                {
                                    paramName: 'customer_name',
                                },
                            ],
                        });
                    }
                    return nextCellSelector;
                },
            );

            makeObjColClickable(baiscCellsSelector, 'parent_customer_id');
            makeObjColClickable(baiscCellsSelector, 'customer_id');

            applyCustomConfToCellsSelector(
                baiscCellsSelector,
                'compete_web_url',
                cellSelector => {
                    if (isMultipleCell(cellSelector)) {
                        return cellSelector;
                    }
                    cellSelector.cell = 'MLinks';
                    return cellSelector;
                },
            );

            if (type !== 'stars') {
                applyCustomConfToCellsSelector(
                    baiscCellsSelector,
                    'recent_activity_record_time',
                    cellSelector => {
                        if (isMultipleCell(cellSelector)) {
                            return cellSelector;
                        }
                        cellSelector.cell = 'LastestActivityRecord';
                        return cellSelector;
                    },
                );
            }

            applyCustomConfToCellsSelector(
                baiscCellsSelector,
                'recycle_type',
                cellSelector => {
                    if (isMultipleCell(cellSelector)) {
                        return cellSelector;
                    }
                    cellSelector.cell = 'RecycleType';
                    return cellSelector;
                },
            );

            return baiscCellsSelector;
        },
        [],
    );

    const propsTransformer = useCallback(
        (props: ITableLv2Props<any, any>) => {
            props.loading = loading;
            props.needMoreNum = 3;
            props.actions =
                type === 'all'
                    ? [
                          {
                              actionKey: 'dispatch',
                              actionCn: '分配',
                          },
                          {
                              actionKey: 'fetch',
                              actionCn: '领取',
                          },
                          {
                              actionKey: 'star',
                              actionCn: '关注',
                              render(data: any) {
                                  const cus = data;
                                  if (!cus) {
                                      return null;
                                  }
                                  return (
                                      <StarCus
                                          cus={cus}
                                          customerList={customerList}
                                          defaultStared={cus?.focus || false}
                                      />
                                  );
                              },
                          },
                      ]
                    : [
                          {
                              actionKey: 'update',
                              actionCn: '修改',
                          },
                          {
                              actionKey: 'delete',
                              actionCn: '删除',
                          },
                          {
                              actionKey: 'star',
                              actionCn: '关注',
                              render(data: any) {
                                  const cus = data;
                                  if (!cus) {
                                      return null;
                                  }
                                  return (
                                      <StarCus
                                          cus={cus}
                                          customerList={customerList}
                                          defaultStared={cus?.focus || false}
                                      />
                                  );
                              },
                          },
                      ];
            props.onAction = (key, data) => {
                if (key === 'update') {
                    runInAction(() => {
                        customerList.setAction('update', {
                            id: data.id,
                        });
                        // 如果是更新操作，mutatingData必须是实时值
                        // customerList.setMutatingData(data);
                    });
                } else if (key === 'delete') {
                    runInAction(() => {
                        customerList.setAction('delete');
                        customerList.setMutatingData(data);
                    });
                } else if (key === 'dispatch') {
                    runInAction(() => {
                        customerList.setAction('m-dispatch');
                        customerList.setMutatingData(data);
                    });
                } else if (key === 'fetch') {
                    runInAction(() => {
                        customerList.setAction('m-fetch');
                        customerList.setMutatingData(data);
                    });
                } else if (_.startsWith(key, 'reveal-obj/')) {
                    if (key.indexOf('/parent_customer_id') !== -1) {
                        setCid(data.parent_customer_id?.originalValue);
                        setCustomerDrawerVis(true);
                    } else if (key.indexOf('/customer_id') !== -1) {
                        setCid(data.customer_id?.originalValue);
                        setCustomerDrawerVis(true);
                    }
                } else if (_.startsWith(key, 'reveal-param/')) {
                    // 可点击参数
                    if (key.indexOf('customer_name') !== -1) {
                        setCid(data.id);
                        setCustomerDrawerVis(true);
                        if (type !== 'all' && data.score_remind) {
                            runInAction(async () => {
                                const [d, e] = await defaultAxios.post(
                                    '/crm/customer/updateScoreRemain',
                                    {
                                        id: data.id,
                                    },
                                );
                                if (!d || !d.data || e) {
                                    return;
                                }
                                tryRefresh(['customer']);
                            });
                        }
                    }
                }
            };
            props.closeRowSelection = false;
            props.selectedItems = mSeletedData;
            props.onSelectedDataChange = setMSelectedData;
            props.widthInfoKey = widthInfoKey;

            if (type === 'to-release') {
                props.closeRowSelection = true;
                props.actions = [];
            } else if (type === 'stars') {
                props.closeRowSelection = true;
                props.actions = [
                    {
                        actionKey: 'star',
                        actionCn: '取消关注',
                        render(data: any) {
                            const cus = data;
                            if (!cus) {
                                return null;
                            }
                            return (
                                <StarCusUnStarOnly
                                    cus={cus}
                                    customerList={customerList}
                                    onOk={() => tryRefresh(['customer'])}
                                />
                            );
                        },
                        isVisible: () => true,
                        force: true,
                    },
                ];
            }

            if (type === 'outflow') {
                props.closeRowSelection = true;
                props.actions = [];
            }

            return props;
        },
        [type, loading, widthInfoKey, mSeletedData, setMSelectedData],
    );

    const dataTable = (
        <>
            {cusDrawerSnippet}
            <DTable
                onColumnsUpdated={onColumnsUpdated}
                defaultStore={customerList}
                sceneCode={'crm_customer'}
                eventCode={eventCode}
                options={dtableOptions}
                cellsSelectorTransformer={cellsSelectorTransformer}
                propsTransformer={propsTransformer}
                filterWidgetsGenerator={defaultStore => {
                    const { defaultMeta } = defaultStore;

                    const widgets = defaultMeta.defaultAdvancedFilterWidgetsAll;
                    for (const widget of widgets) {
                        if (widget.key === 'lose_operate_type') {
                            const whiteList = new Set(['1', '4']);
                            widget.filterEnumOptions =
                                widget.filterEnumOptions || {};
                            if (widget.filterEnumOptions.options) {
                                widget.filterEnumOptions.options = widget.filterEnumOptions.options.filter(
                                    item => {
                                        if (_.isString(item)) {
                                            return true;
                                        }
                                        if (_.isString(item.value)) {
                                            return whiteList.has(item.value);
                                        }
                                        return true;
                                    },
                                );
                            }
                        }
                    }
                    return widgets;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalFormTable = observer(FormDemoTable);
export default FinalFormTable;
