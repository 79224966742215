import React, { useEffect, useContext, useState } from 'react';
import {
    DForm,
    IProcessCtx,
    IDFormProps,
    applyCustomConfToWidget,
    AntTable,
    applyConfToFormItem,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CtxContext } from '@/utils/context';
import { StoreContext } from '@/stores';

import { IProcessComProps, IProcessComs } from './def';
import { widgets } from '@/pages/demo2/crud/filter-options';
import { getValidatorByParamMeta } from '@/stores/utils';
import {
    arrToJson,
    defaultAxios,
    isNetSuccess,
    makeRangeValidator,
    decodePhone as phoneDecode,
} from '@/utils';
import { commonQuery } from '@/api/rest';
import { seeContractProcess } from '@/pages/om/contract/list/store';
import { message, Icon, Alert } from 'antd';

const openAdminSuffixes = ['', '2', '3', '4', '5'];

const DemoForm: React.FC<IProcessComProps> = ({
    defaultStore,
    processCtx,
    tableCode,
    mode,
}) => {
    const ctx = useContext(CtxContext);
    const { position, positionDetail } = ctx;
    const isCreate = mode === 'create';
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const sysId = authStore?.userInfo?.sysUser?.id;

    const { defaultMeta } = defaultStore;
    const { mutatingData } = defaultStore;
    const productVersion = mutatingData?.product_version;

    // 算开通基础信息布局
    const [
        productOpenAdminBasicGroupLayout,
        setProductOpenAdminBasicGroupLayout,
    ] = useState<any[]>([]);
    const decodePhone = async (phoneNum: string | number) => {
        const url = '/crm/crypto/phone/decrypt';
        const [d, e] = await defaultAxios.post(url, {
            phone: phoneNum,
            scene: 'crm_activation',
        });
        const data = phoneDecode(d?.data);
        const error = e as Error | null;
        if (data) {
            return data;
        } else {
            return phoneNum;
        }
    };
    useEffect(() => {
        const activityFields =
            defaultMeta?.processAllInfo?.crmActivity?.activityFields;
        if (!defaultMeta?.objectMeta?.length || !_.isArray(activityFields)) {
            return;
        }
        const activityFieldsMap = arrToJson(activityFields, 'fieldCode');

        const layout: any[] = [];
        // magic...
        for (const metaItem of defaultMeta?.objectMeta || []) {
            if (_.endsWith(metaItem.groupKey, '_open')) {
                if (
                    !activityFieldsMap[metaItem.fieldName] ||
                    activityFieldsMap[metaItem.fieldName].isView !== 1
                ) {
                    continue;
                }
                if (metaItem.fieldName === 'product_open_administrator') {
                    layout.push(
                        [8, 8, 8],
                        [8, 8, 8],
                        [8, 8, 8],
                        [8, 8, 8],
                        [8, 8, 8],
                    );
                    break;
                }
                layout.push([24]);
            }
        }

        setProductOpenAdminBasicGroupLayout(layout);
    }, [tableCode, defaultMeta?.processAllInfo, defaultMeta?.objectMeta]);

    // 产品类型自动企业号
    useEffect(() => {
        if (!mutatingData) {
            return;
        }
        if (!!mutatingData?.product_type) {
            return;
        }
        mutatingData.product_type = 1;
    }, [mutatingData?.product_type]);

    const [companyRelated, setCompanyRelated] = useState<any>(null);
    useEffect(() => {
        const name = mutatingData?.customer_id?.relatedObj?.customer_name;
        if (!name) {
            return;
        }

        setCompanyRelated(null);
        // cid重复
        defaultAxios
            .get('/crm/workflow/contractproduct/companyrelated', {
                name,
            })
            .then(([d, e]) => {
                if (
                    isNetSuccess(d, e) &&
                    _.isArray(d?.data) &&
                    d?.data.length > 0
                ) {
                    setCompanyRelated(d?.data);
                } else {
                    setCompanyRelated(null);
                }
            });
    }, [tableCode, mutatingData?.customer_id]);

    if (!defaultMeta || !mutatingData) {
        return null;
    }
    const baiscWidgets = defaultMeta.defaultWidgetsAllWithoutGroupCheck;

    const collapseLayout = defaultMeta.defaultFormCollapseLayout;
    const shouldGroup = collapseLayout.length > 0;

    const layout = new Array(Math.ceil(baiscWidgets.length / 2)).fill([24]);
    if (shouldGroup) {
        for (const colla of collapseLayout) {
            colla.layout = layout;
        }
    }

    if (processCtx === null || !processCtx.currentUser) {
        return null;
    }

    const props: IDFormProps = {
        processCtx,
        defaultStore: defaultStore as any,
        widgetsGenerator: store => {
            return store.defaultMeta.defaultWidgetsAllWithoutGroupCheck;
        },
    };

    if (shouldGroup) {
        props.options = {
            grouped: true,
        };
    } else {
        props.propsTransformer = props => {
            props.layout = layout;
            return props;
        };
    }

    const { objectMetaMap } = defaultMeta;

    // http://crm-test.in.taou.com/crm/metadata/process_product_give_open_table
    const { apply_type } = mutatingData;
    // apply_type为赠送，则合同必填，后端应配置成非必填

    props.labelsTransformer = originalLabels => {
        const nextLabels = [...originalLabels];
        nextLabels.forEach(label => {
            if (apply_type === 1 && label.key === 'contract_id') {
                label.required = true;
            }

            // 邮箱统一布局
            // product_open_administrator_email
            if (
                _.startsWith(
                    label.key as string,
                    'product_open_administrator_email',
                )
            ) {
                label.labelWidth = 13;
            }

            // 电话统一布局
            // product_open_administrator_phone
            if (
                _.startsWith(
                    label.key as string,
                    'product_open_administrator_phone',
                )
            ) {
                label.labelWidth = 15;
                label.style = { transform: 'translateX(8px)' };
            }

            if ((label.key as string) === 'enterprise_slogan') {
                if (productVersion === 1) {
                    label.required = true;
                } else {
                    label.required = false;
                }
            }
        });
        return nextLabels;
    };

    props.validatorsTransformer = originalValidators => {
        const nextValidators = { ...originalValidators };
        const keys = _.keys(nextValidators);
        keys.forEach(k => {
            if (apply_type === 1 && k === 'contract_id') {
                const paramsMeta = _.cloneDeep(objectMetaMap[k]);
                paramsMeta.isRequired = true;
                nextValidators[k] = getValidatorByParamMeta(paramsMeta);
            }

            if (productVersion === 1 && k === 'enterprise_slogan') {
                const paramsMeta = _.cloneDeep(objectMetaMap[k]);
                paramsMeta.isRequired = true;
                nextValidators[k] = getValidatorByParamMeta(paramsMeta);
            }

            if (apply_type === 2 && k === 'operate_duration') {
                const paramsMeta = objectMetaMap[k];
                nextValidators[k] = makeRangeValidator(
                    'operate_duration',
                    paramsMeta.isRequired,
                    1,
                    3,
                );
            }
        });
        return nextValidators;
    };

    props.widgetsTransformer = widgets => {
        applyConfToFormItem(widgets, 'inputSecret', {
            sceneCode: 'crm_enterprise_give',
        });
        applyCustomConfToWidget(widgets, 'contract_id', prevWidget => {
            const widget = _.cloneDeep(prevWidget);

            const cusId = mutatingData?.customer_id?.originalValue;
            if (!cusId) {
                widget.status = {
                    ...widget.status,
                    disabled: true,
                };
            }
            if (widget.objectOptions) {
                widget.objectOptions.defaultFilters = cusId
                    ? [
                          {
                              fieldName: 'customer_id',
                              operateType: 'EQUAL',
                              fieldValue: cusId,
                          },
                      ]
                    : [];
                widget.objectOptions.reloadTrigger = cusId;
            }

            return widget;
        });

        applyCustomConfToWidget(baiscWidgets, 'contract_no', nextWidget => {
            nextWidget.nextlineWidget = () => {
                const contractId = mutatingData?.contract_id?.originalValue;
                if (!contractId) {
                    return null;
                }
                return (
                    <div style={{ paddingTop: 4 }}>
                        <a
                            onClick={() => {
                                if (contractId) {
                                    seeContractProcess(contractId);
                                } else {
                                    message.error('合同id不存在');
                                }
                            }}
                        >
                            查看合同
                            <Icon type="link" />
                        </a>
                    </div>
                );
            };
            return nextWidget;
        });

        applyCustomConfToWidget(baiscWidgets, 'customer_cid', nextWidget => {
            nextWidget.nextlineWidget = () => {
                return companyRelated ? (
                    <div style={{ paddingTop: 4 }}>
                        <Alert
                            type="warning"
                            icon={<Icon type="step-forward" />}
                            message={
                                <div>
                                    <p
                                        style={{
                                            color: 'red',
                                            paddingBottom: 5,
                                        }}
                                    >
                                        当前客户存在多个疑似关联公司，需要合并，请发布【CID合并申请】邮件至qyh@taou.com，提供相关合并企业号cid、合并保留cid信息，成功合并后再回CRM内提交企业号开通。
                                    </p>
                                    <AntTable
                                        dataSource={companyRelated}
                                        columns={[
                                            {
                                                title: '客户名称',
                                                key: 'company',
                                                dataIndex: 'company',
                                            },
                                            {
                                                title: 'CID',
                                                key: 'cid',
                                                dataIndex: 'cid',
                                            },
                                        ]}
                                        pagination={false}
                                    ></AntTable>
                                </div>
                            }
                        />
                    </div>
                ) : null;
            };
            return nextWidget;
        });

        for (const suffix of openAdminSuffixes) {
            applyCustomConfToWidget(
                widgets,
                'product_open_administrator' + suffix,
                prevwidget => {
                    const widget = _.cloneDeep(prevwidget);
                    const cusId = mutatingData?.customer_id?.originalValue;
                    if (!cusId) {
                        widget.status = {
                            ...widget.status,
                            disabled: true,
                        };
                    }

                    if (widget.objectOptions) {
                        widget.objectOptions.defaultFilters = cusId
                            ? [
                                  {
                                      fieldName: 'customer_id',
                                      operateType: 'EQUAL',
                                      fieldValue: cusId,
                                  },
                              ]
                            : [];
                        widget.objectOptions.reloadTrigger = cusId;
                    }
                    return widget;
                },
            );
        }

        return widgets;
    };

    const originalOnChange = props.onChange;
    props.onChange = (key, value, widgetKey) => {
        const tmpk = key as string;
        if (_.startsWith(tmpk, 'product_open_administrator')) {
            const suffix = tmpk.substring('product_open_administrator'.length);
            mutatingData['product_open_administrator_phone' + suffix] = null;
            mutatingData['product_open_administrator_email' + suffix] = null;
            commonQuery(
                'contacts',
                {
                    pageSize: 1,
                    pageNum: 1,
                },
                {
                    filters: [
                        {
                            fieldId: 'id',
                            filterValue: value?.originalValue,
                            operateType: '=',
                        },
                    ],
                },
                undefined,
                undefined,
                undefined,
                'SEARCH-ALL',
            ).then(async ([d, e]) => {
                if (_.isNil(d) || e !== null) {
                    return;
                }
                const { datas } = d;
                if (datas && datas[0]) {
                    const dt = datas[0];
                    const pData = await decodePhone(dt.contacts_phone);
                    mutatingData[
                        'product_open_administrator_phone' + suffix
                    ] = pData;
                    mutatingData['product_open_administrator_email' + suffix] =
                        dt.contacts_work_email;
                } else {
                }
            });
        }

        if (key === 'customer_id') {
            for (const suffix of openAdminSuffixes) {
                mutatingData['product_open_administrator' + suffix] = null;
                mutatingData[
                    'product_open_administrator_phone' + suffix
                ] = null;
                mutatingData[
                    'product_open_administrator_email' + suffix
                ] = null;
            }
            mutatingData.contract_id = null;
            // 回填cid
            if (value?.originalValue) {
                const clear = () => {
                    mutatingData.customer_cid = null;
                };
                clear();
                commonQuery(
                    'customer',
                    {
                        pageSize: 1,
                        pageNum: 1,
                    },
                    {
                        filters: [
                            {
                                fieldId: 'id',
                                filterValue: value?.originalValue,
                                operateType: '=',
                            },
                        ],
                    },
                    undefined,
                    undefined,
                    undefined,
                    'SEARCH-ALL',
                ).then(([d, e]) => {
                    if (_.isNil(d) || e !== null) {
                        return;
                    }
                    const { datas } = d;
                    if (datas && datas[0]) {
                        const dt = datas[0];
                        mutatingData.customer_cid = dt.customer_cid;
                    }
                });
            }
        }

        if (key === 'contract_id') {
            // 回填合同相关信息
            if (value?.originalValue) {
                const clear = () => {
                    mutatingData.contract_no = null;
                    mutatingData.contract_amout = null;
                    mutatingData.contract_amount = null;
                    mutatingData.contract_attachments = null;
                };
                clear();
                commonQuery(
                    'contract',
                    {
                        pageSize: 1,
                        pageNum: 1,
                    },
                    {
                        filters: [
                            {
                                fieldId: 'id',
                                filterValue: value?.originalValue,
                                operateType: '=',
                            },
                        ],
                    },
                    undefined,
                    undefined,
                    undefined,
                    'SEARCH-ALL',
                ).then(([d, e]) => {
                    if (_.isNil(d) || e !== null) {
                        return;
                    }
                    const { datas } = d;
                    if (datas && datas[0]) {
                        const dt = datas[0];
                        mutatingData.contract_no = dt.contract_code;
                        mutatingData.contract_amout = dt.contract_amount;
                        mutatingData.contract_amount = dt.contract_amount;
                        mutatingData.contract_attachments =
                            dt.contract_attachments;
                    }
                });
            }
        }

        if (originalOnChange) {
            return originalOnChange(key, value, widgetKey);
        }
    };

    props.propsTransformer = props => {
        if (props.collapseLayout) {
            for (const colla of props.collapseLayout) {
                if (_.endsWith(colla.groupKey, '_open')) {
                    colla.layout = productOpenAdminBasicGroupLayout;
                }
            }
        }
        props.labelWidth = 18;
        return props;
    };

    if (tableCode === null) {
        return null;
    }

    const form = <DForm {...props} />;
    return form;
};

export const ProcessProductGiveOpenTable = observer(DemoForm);
