import React, { useState } from 'react';
import { DTable, makeObjColClickable } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ActiveRecordStore } from '../store';
import { useCustomerDrawerSnippets } from '@/components/common/customer-drawer/hooks';
import {
    applyCustomConfToCellsSelector,
    ICell,
    ICellMultiple,
} from '@/components/common';
import { message } from 'antd';

const ARTable: React.FC<{
    defaultStore: ActiveRecordStore;
    loading: boolean;
    reload?: any;
}> = ({ defaultStore, loading, reload }) => {
    const [drawerVisCus, setDrawerVisCus] = useState(false);
    const [cidCus, setCidCus] = useState(undefined);

    const [___, cusDrawerSnippet] = useCustomerDrawerSnippets({
        drawerVis: drawerVisCus,
        setDrawerVis: setDrawerVisCus,
        cid: cidCus,
    });

    const dataTable = (
        <>
            {cusDrawerSnippet}
            <DTable
                onColumnsUpdated={() => reload && reload()}
                defaultStore={defaultStore}
                sceneCode={'crm_activation'}
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [];
                    props.onAction = (key, data) => {
                        if (_.startsWith(key, 'reveal-param/')) {
                            // 可点击参数
                            if (key.indexOf('customer_name') !== -1) {
                                const cid = data?.customer_id?.originalValue;
                                if (!cid) {
                                    return message.error('无cid');
                                }
                                setCidCus(cid);
                                setDrawerVisCus(true);
                            }
                        }
                    };
                    props.widthInfoKey = 'activation-record-list-default';
                    return props;
                }}
                cellsSelectorTransformer={baiscCellsSelector => {
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'customer_name',
                        () => {
                            const nextCellSelector: ICellMultiple<any> = {
                                colKey: 'customer_name',
                                cells: [],
                            };

                            if (nextCellSelector.cells.length === 0) {
                                nextCellSelector.cells.push({
                                    cell: 'ActionParam',
                                    inputs: [
                                        'customer_name',
                                        {
                                            paramName: 'customer_name',
                                        },
                                    ],
                                });
                            }
                            return nextCellSelector;
                        },
                    );
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'image',
                        () => {
                            const nextCellSelector: ICell<any> = {
                                cell: 'image',
                                colKey: 'image',
                                inputs: ['image'],
                            };
                            return nextCellSelector;
                        },
                    );

                    return baiscCellsSelector;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalARTable = observer(ARTable);
export default FinalARTable;
