import React, { useState, useEffect } from 'react';
import './index.less';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts/core';
import _ from 'lodash';
import CompositeLiveChartsComponents from '../../composite-live-charts';
import {
    IAchievementSituationFilterData,
    areaOptions,
    rangeOptions,
    trans2DotTwo,
} from '../../achievement-situation';
import { create, all } from 'mathjs';
import { Select } from 'antd';
import { trans2WDotTwo, trans2W } from '../../achievement-situation/index';
const IMath: any = create(all);
IMath.config({
    number: 'BigNumber',
    precision: 64,
});
const treeMapValue = [
    {
        name: 'IT/互联网',
        value: 38000000,
    },
    {
        name: '商务服务业',
        value: 18000000,
    },
    {
        name: '金融业',
        value: 12000000,
    },
    {
        name: '元宇宙',
        value: 9000000,
    },
    {
        name: '教育/科研',
        value: 7000000,
    },
    {
        name: '银行',
        value: 5000000,
    },
    {
        name: '半导体',
        value: 3000000,
    },
    {
        name: '服务业',
        value: 1500000,
    },
    {
        name: '其他',
        value: 2000000,
    },
];
const testOptions: EChartsOption = {
    grid: {
        left: '0%',
        right: '0%',
        width: '100%',
        height: '100%',
        containLabel: true,
    },
    legend: {
        show: false,
    },
    tooltip: {
        formatter: (info: any) => {
            const value = info.value;
            const amount = value[0];
            const tipsText = amount ? `${amount}万元` : '-';
            return [
                '<div class="tooltip-title">' +
                    echarts.format.encodeHTML(info.name) +
                    '</div>',
                tipsText,
            ].join('');
        },
    },
    series: [
        {
            type: 'treemap',
            nodeClick: 'link',
            color: ['#0052ff'],
            colorMappingBy: 'value',
            label: {
                show: true,
                position: 'inside',
                formatter: (info: any) => {
                    if (info.dataIndex < 2) {
                        return '{b}: {a}';
                    } else {
                        return '{b}';
                    }
                },
            },
            data: treeMapValue,
        },
    ],
};
interface IPerformanceCustomerIndustryProps {
    industryData?: {
        industry_customer_money: {
            [key: string]: number;
        };
        industry_customer_num: {
            [key: string]: number;
        };
        industry_customer_arpu: {
            [key: string]: number;
        };
    };
    asLoading: boolean;
    asFilterData: IAchievementSituationFilterData;
    setAsFilterData: (fData: IAchievementSituationFilterData) => void;
}
const PerformanceCustomerIndustry: React.FC<IPerformanceCustomerIndustryProps> = props => {
    const {
        industry_customer_arpu,
        industry_customer_num,
        industry_customer_money,
    } = props.industryData || {};
    const { asFilterData, setAsFilterData } = props;
    const finalSortMoneyList = _.sortBy(
        _.map(industry_customer_money, (item, idx) => {
            return {
                name: idx,
                value: trans2W(item),
            };
        }),
        'value',
    );
    const allMoneyCount = _.reduce(
        finalSortMoneyList,
        (total, item) => {
            return total + item.value;
        },
        0,
    );
    const finalSortARPUList = _.sortBy(
        _.map(industry_customer_arpu, (item, idx) => {
            return {
                name: idx,
                value: item,
            };
        }),
        'value',
    );
    const finalSortNumList = _.sortBy(
        _.map(industry_customer_num, (item, idx) => {
            return {
                name: idx,
                value: item,
            };
        }),
        'value',
    );
    const allNumCount = _.reduce(
        finalSortNumList,
        (total, item) => {
            return total + item.value;
        },
        0,
    );
    const moneyYList: string[] = _.map(finalSortMoneyList, item => {
        return item.name;
    });
    const numYList: string[] = _.map(finalSortNumList, item => {
        return item.name;
    });
    const arpuYList: string[] = _.map(finalSortARPUList, item => {
        return item.name;
    });

    const moneyXList: number[] = _.map(finalSortMoneyList, item => {
        return item.value;
    });
    const numXList: number[] = _.map(finalSortNumList, item => {
        return item.value;
    });
    const arpuXList: number[] = _.map(finalSortARPUList, item => {
        return item.value;
    });

    const moneyChartsOptions: EChartsOption = {
        grid: {
            left: '0%',
            right: '0%',
            width: '100%',
            height: '100%',
            containLabel: true,
        },
        legend: {
            show: false,
        },
        tooltip: {
            formatter: (info: any) => {
                const value = info.value;
                // const amount = value[0];
                const tipsText = value ? `${value}万元` : '-';
                const rateText = value
                    ? `${IMath.abs(
                          IMath.multiply(
                              IMath.bignumber(
                                  trans2DotTwo(value / allMoneyCount),
                              ),
                              100,
                          ),
                      )}%`
                    : '';
                return [
                    '<div class="tooltip-title">' +
                        echarts.format.encodeHTML(info.name) +
                        '</div>',
                    tipsText + '<br>',
                    rateText,
                ].join('');
            },
        },
        series: [
            {
                type: 'treemap',
                // silent: true,
                nodeClick: 'link',
                color: [
                    '#1F6EC0',
                    '#257CD7',
                    '#2588D7',
                    '#2992E7',
                    '#2E9DF6',
                    '#2EAFF6',
                    '#3BBAFF',
                    '#3BCFFF',
                    '#62D8FF',
                    '#62F2FF',
                    '#5FFFFF',
                ],
                colorMappingBy: 'index',
                breadcrumb: {
                    show: false,
                },
                roam: false,
                label: {
                    show: true,
                    position: 'inside',
                    formatter: (info: any) => {
                        return `${info.name}: ${info.value}`;
                        // if (info.dataIndex < 2) {
                        //     return `${info.name}: ${info.value}`;
                        // } else {
                        //     return `${info.value}`;
                        // }
                    },
                },
                data: [
                    {
                        name: '客户数量',
                        children: finalSortMoneyList,
                    },
                ],
            },
        ],
    };
    const numberCustomerOptions: EChartsOption = {
        grid: {
            left: '0%',
            right: '0%',
            width: '100%',
            height: '100%',
            containLabel: true,
        },
        legend: {
            show: false,
        },
        tooltip: {
            formatter: (info: any) => {
                const value = info.value;
                // const amount = value[0];
                const tipsText = value ? `${value}` : '-';
                const rateText = value
                    ? `${IMath.abs(
                          IMath.multiply(
                              IMath.bignumber(
                                  trans2DotTwo(value / allNumCount),
                              ),
                              100,
                          ),
                      )}%`
                    : '';
                return [
                    '<div class="tooltip-title">' +
                        echarts.format.encodeHTML(info.name) +
                        '</div>',
                    tipsText + '<br>',
                    rateText,
                ].join('');
            },
        },
        series: [
            {
                type: 'treemap',
                // silent: true,
                nodeClick: 'link',
                color: [
                    '#1F6EC0',
                    '#257CD7',
                    '#2588D7',
                    '#2992E7',
                    '#2E9DF6',
                    '#2EAFF6',
                    '#3BBAFF',
                    '#3BCFFF',
                    '#62D8FF',
                    '#62F2FF',
                    '#5FFFFF',
                ],
                colorMappingBy: 'index',
                breadcrumb: {
                    show: false,
                },
                roam: false,
                label: {
                    show: true,
                    position: 'inside',
                    formatter: (info: any) => {
                        return `${info.name}: ${info.value}`;
                        // if (info.dataIndex < 2) {
                        //     return `${info.name}: ${info.value}`;
                        // } else {
                        //     return `${info.value}`;
                        // }
                    },
                },
                data: [
                    {
                        name: '客户数量',
                        children: finalSortNumList,
                    },
                ],
            },
        ],
    };

    const arpuOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'line', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        legend: {
            show: false,
        },
        grid: {
            left: '5%',
            right: '5%',
            bottom: '5%',
            top: '5%',
            width: '90%',
            height: '90%',
            containLabel: true,
        },
        yAxis: {
            type: 'category',
            data: arpuYList,
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
        },
        series: [
            {
                name: '行业',
                type: 'bar',
                color: '#0052FF',
                // label: {
                //     show: true
                // },
                // emphasis: {
                //     focus: 'series'
                // },
                data: arpuXList.map(item => {
                    return Math.round(item / 100) / 100;
                }),
            },
        ],
    };
    return (
        <div className="c-i-composite-content">
            <div className="c-i-header">
                <span className="c-i-title">业绩成分-客户行业</span>
                <div className="a-s-f-filter">
                    <div className="a-s-f-item">
                        <label htmlFor="">区域</label>
                        <Select
                            size="small"
                            value={asFilterData.area}
                            onChange={(value: any) => {
                                setAsFilterData({
                                    ...asFilterData,
                                    area: value,
                                });
                            }}
                            style={{
                                minWidth: '80px',
                            }}
                        >
                            {_.map(areaOptions, option => {
                                return (
                                    <Select.Option value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div className="a-s-f-item">
                        <label htmlFor="">时间</label>
                        <Select
                            size="small"
                            value={asFilterData.range}
                            onChange={(value: any) => {
                                setAsFilterData({
                                    ...asFilterData,
                                    range: value,
                                });
                            }}
                            style={{
                                minWidth: '80px',
                            }}
                        >
                            {_.map(rangeOptions, option => {
                                return (
                                    <Select.Option value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
            </div>
            <div className="c-i-charts-content">
                <div className="c-i-charts-row">
                    <CompositeLiveChartsComponents
                        chartsOptions={moneyChartsOptions}
                        loading={props.asLoading}
                        extraRight={'单位：万元'}
                        title={'行业占比（按业绩）'}
                        chartsClassName="c-i-charts-content"
                    />
                    <CompositeLiveChartsComponents
                        chartsClassName="c-i-charts-content"
                        loading={props.asLoading}
                        chartsOptions={numberCustomerOptions}
                        title={'行业占比（按客户数）'}
                    />
                    <CompositeLiveChartsComponents
                        chartsClassName="c-i-charts-content"
                        loading={props.asLoading}
                        chartsOptions={arpuOptions}
                        extraRight={'单位：万元'}
                        title={'ARPU值排名Top10'}
                    />
                </div>
            </div>
        </div>
    );
};
export default PerformanceCustomerIndustry;
