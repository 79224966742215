import React, { useState, useEffect, useRef } from 'react';
import './index.less';
import EE from 'eventemitter3';
import ReactDOM from 'react-dom';
declare const window: Window & { audioEE: any };
window.audioEE = new EE();
const AudioCom: React.FC = () => {
    const [audioSrc, setAudioSrc] = useState<string>('');
    const audioRef = useRef<HTMLAudioElement | null>(null);
    useEffect(() => {
        window.audioEE.on(
            'audio_start',
            (src: string, callFileId: string | number) => {
                if (src) {
                    setAudioSrc(src);
                    window.audioEE.emit('audio_url_change', callFileId);
                    audioRef.current?.play();
                } else {
                    if (audioSrc && audioRef?.current?.paused) {
                        audioRef.current && audioRef.current.play();
                    }
                }
            },
        );
        window.audioEE.on('audio_end', () => {
            audioRef.current && audioRef.current.pause();
        });
    }, []);
    return ReactDOM.createPortal(
        <audio ref={audioRef} src={audioSrc} hidden></audio>,
        document.body,
    );
};
export default AudioCom;
