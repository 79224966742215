import { loadProgressBar } from 'axios-progress-bar';

/**
 * enable axios progress bar
 */
export const enableAjaxBar = (): void => {
    loadProgressBar();
};

/**
 * disable axios progress bar
 * reture a recover fn
 */
const autoAjaxBarBlocker = document.createElement('style') as HTMLStyleElement;
autoAjaxBarBlocker.innerHTML = '#nprogress { display: none }';
export const silentAjaxBar = (recoverDelay: number = 500): (() => void) => {
    document.body.append(autoAjaxBarBlocker);
    const recover = () => {
        setTimeout(() => autoAjaxBarBlocker.remove(), recoverDelay);
    };
    return recover;
};
