import React, { useState, useEffect } from 'react';
export interface IAutoImgPropsData {
    imgSrc: string;
    squareLength: number;
    imgContentClassName?: string;
    imgClassName?: string;
}

const AutoImg: React.FC<IAutoImgPropsData> = props => {
    const [sizeHeight, setSizeHeight] = useState(false);
    useEffect(() => {
        let imgWidth;
        let imgHeight;
        const imgItem = new Image();
        imgItem.src = props.imgSrc;
        imgItem.onload = () => {
            imgWidth = imgItem.width;
            imgHeight = imgItem.height;
            if (imgHeight < imgWidth) {
                setSizeHeight(true);
            } else {
                setSizeHeight(false);
            }
        };
    }, [props.imgSrc]);
    return (
        <div
            style={{
                width: props.squareLength,
                height: props.squareLength,
                backgroundColor: '#FFF',
                overflow: 'hidden',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className={props?.imgContentClassName || ''}
        >
            {props.imgSrc && (
                <img
                    src={`${props.imgSrc}`}
                    style={{
                        // backgroundImage: `url(${item.url})`,
                        width: sizeHeight ? 'auto' : props.squareLength,
                        height: sizeHeight ? props.squareLength : 'auto',
                        // height: '100px',
                        // borderRadius: '50%',
                        // backgroundPosition:
                        //     'center',
                        // backgroundSize: 'cover',
                    }}
                    className={props?.imgClassName || ''}
                />
            )}
        </div>
    );
};
export default AutoImg;
