import React from 'react';

interface DataCardProps {
    header: string;
    total: number;
    unit: string;
    oldCount: number;
    newCount: number;
}

const DataCard: React.FC<DataCardProps> = ({
    header,
    total,
    unit,
    oldCount,
    newCount,
}) => {
    return (
        <div className="card-container">
            <div className="card-header">{header}</div>
            <div className="card-body">
                <span className="card-body-data">{total} </span>
                <span className="card-body-unit">{unit}</span>
            </div>
            <div className="card-footer">
                <div className="card-footer-item">
                    新客：{`${newCount}${unit}`}
                </div>
                <div className="card-footer-item">
                    老客：{`${oldCount}${unit}`}
                </div>
            </div>
        </div>
    );
};

export default DataCard;
