import { makeAutoObservable } from 'mobx';
import { createDefaultInstanceGetter } from '@/stores/utils';
import { defaultAxios } from '@/utils';
// 菜单权限
export interface IMenuAuthData {
    value: boolean; // isChecked 被选中是true，没有则是false
    id: number | string;
    menuKey: string | number; // 菜单key值
    menuName: string; // 菜单名
    parentMenuId: number | string; // 上级菜单id，一级菜单的上级菜单是0
}
// 功能权限
export interface IFunctionAuthData {
    value: boolean; // isChecked 被选中是true，没有则是false
    id: number | string;
    functionKey: string | number; // 功能key值
    functionName: string; // 功能名
    parentFunctionId: number | string; // 上级功能id，一级功能的上级功能是0
}
// 模块内权限
export interface IFiledEntityAuthData {
    fieldName: string; // 字段名
    fieldId: number | string; // 字段id
    fieldKey: string; // 字段key
    editAuth: {
        authName: string; // 权限名 (如：私海编辑)
        authKey: string; // 权限key
        authValue: boolean; // 权限是否选中
    }[];
    viewAuth: {
        authName: string; // 权限名 (如：私海查看)
        authKey: string; // 权限key
        authValue: boolean; // 权限是否选中
    }[];
    createAuth: {
        authName: string; // 权限名 (如：查看)
        authKey: string; // 权限key
        authValue: boolean; // 权限是否选中
    }[];
}
// 字段权限
export interface IFieldAuthData {
    entityName: string; // 模块名
    entityId: number | string; // 模块id
    entityAuth: IFiledEntityAuthData[]; // 模块-字段权限
}
export interface IBizTypeAuthData {
    id: number | string;
    businessTypeName: string;
    selectable: boolean;
}
// 业务实体权限
export interface IEntityBizTypeAuthData {
    entityCode: string; // 实体code
    entityName: string; // 实体名字
    businessTypeItems: IBizTypeAuthData[]; // 业务类型-权限
}
// 功能角色权限
export interface IFRMAuthData {
    menuAuth: IMenuAuthData[]; // 菜单权限
    functionAuth: IFunctionAuthData[]; // 功能权限
    fieldAuth: IFieldAuthData[]; // 字段权限
    bizTypeAuth: IEntityBizTypeAuthData[]; // 业务实体权限
}
// 功能角色
export interface IFRMRoleData {
    id?: number | string; // id
    functionRoleName?: string; // 功能角色名称
    authority?: IFRMAuthData;
}
// 角色列表
export type TGetFRMRoleListReturnType = NetResponse<{
    data: IFRMRoleData[];
    msg: string;
    code: number;
}>;
// 权限数据
export type TGetFRMRoleAuthReturnType = NetResponse<{
    data: IFRMAuthData;
    msg: string;
    code: number;
}>;
// 实体-字段权限列表
export type TGetFRMEntityFieldAuthReturnType = NetResponse<{
    data: IFiledEntityAuthData[];
    msg: string;
    code: number;
}>;
export class FRMStore {
    constructor() {
        makeAutoObservable(this);
    }
    // 角色
    public FRMRoleListData: IFRMRoleData[] = [];
    // 角色-权限
    public FRMRoleAuthData: IFRMAuthData = {
        menuAuth: [],
        functionAuth: [],
        fieldAuth: [],
        bizTypeAuth: [],
    };
    // 角色-字段权限-实体-字段列表
    public FRMEntityFieldAuthData: IFiledEntityAuthData[] = [];
    public updateFRMRoleLoading: boolean = false;
    // 功能角色数据
    public fetchFRMRoleListData: () => Promise<
        TGetFRMRoleListReturnType
    > = async () => {
        const url = '/bff/api/rest/frm/r-list';
        const [d, e] = await defaultAxios.get(url);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data && Array.isArray(data)) {
            this.FRMRoleListData = data || [];
        }
        return [data, error];
    };
    // 角色对应的权限
    public fetchRoleAuth: (
        roleId: string | number,
    ) => Promise<TGetFRMRoleAuthReturnType> = async roleId => {
        if (!roleId) {
            return [null, null];
        }
        const url = '/bff/api/rest/frm/role-auth';
        const [d, e] = await defaultAxios.get(url, {
            roleId,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data && data?.menuAuth) {
            this.FRMRoleAuthData = data;
        }
        return [data, error];
    };
    // 实体对应的字段权限列表
    public fetchEntityFieldAuth: (
        entityId: string | number,
        roleId: string | number,
    ) => Promise<TGetFRMEntityFieldAuthReturnType> = async (
        entityId,
        roleId,
    ) => {
        if (!entityId) {
            return [null, null];
        }
        const url = '/bff/api/rest/frm/entity-field-auth';
        const [d, e] = await defaultAxios.get(url, {
            entityId,
            roleId,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data && Array.isArray(data)) {
            this.FRMEntityFieldAuthData = data;
        }
        return [data, error];
    };
    // 修改菜单权限
    public updateFRMRoleMenuAuthData = async (
        roleId: string | number,
        updateAuthData: (string | number)[],
        roleName: string,
    ) => {
        const url = '/bff/api/rest/frm/update-role-menu-auth';
        this.updateFRMRoleLoading = true;
        const [d, e] = await defaultAxios.post(url, {
            updateAuthData,
            roleId,
            roleName,
        });
        this.updateFRMRoleLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 修改功能权限
    public updateFRMRoleFunctionAuthData = async (
        roleId: string | number,
        updateAuthData: (string | number)[],
        roleName: string,
    ) => {
        const url = '/bff/api/rest/frm/update-function-auth';
        this.updateFRMRoleLoading = true;
        const [d, e] = await defaultAxios.post(url, {
            updateAuthData,
            roleId,
            roleName,
        });
        this.updateFRMRoleLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 修改实体-字段权限
    public updateFRMRoleFieldAuthData = async (
        roleId: string | number,
        entityId: string | number,
        updateAuthData: IFiledEntityAuthData[],
        roleName: string,
    ) => {
        const url = '/bff/api/rest/frm/update-entity-field-auth';
        this.updateFRMRoleLoading = true;
        const [d, e] = await defaultAxios.post(url, {
            updateAuthData,
            roleId,
            entityId,
            roleName,
        });
        this.updateFRMRoleLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    public updateFRMRoleBizTypeAuthData = async (
        roleId: string | number,
        updateAuthData: IEntityBizTypeAuthData[],
        roleName: string,
    ) => {
        const url = '/bff/api/rest/frm/update-biz-type-auth';
        this.updateFRMRoleLoading = true;
        const [d, e] = await defaultAxios.post(url, {
            updateAuthData,
            roleId,
            roleName,
        });
        this.updateFRMRoleLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 新增功能角色
    public createFRMRole = async (roleName: string) => {
        const url = '/bff/api/rest/frm/update-create-role';
        const [d, e] = await defaultAxios.post(url, {
            roleName,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 删除功能角色
    public deleteFRMRoleData = async (deleteRole: IFRMRoleData) => {
        const url = '/bff/api/rest/frm/delete-role';
        const [d, e] = await defaultAxios.post(url, {
            id: deleteRole.id,
        });
        this.updateFRMRoleLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
}
export const getDefaultFRMStore = createDefaultInstanceGetter(FRMStore);
