import React from 'react';
import { LayoutFilterTable } from '../filterTable';
import './index.scss';

export const LayoutStandardCrud: React.FC<{
    message: React.ReactChild | null;
    popups: React.ReactChild | null;
    title: React.ReactChild | null;
    globalOps: React.ReactChild | null;
    filters: React.ReactChild | null;
    multipleOps: React.ReactChild | null;
    dataTable: React.ReactChild | null;
    className?: string;
    operationClassName?: string;
    filtersDisplay?: React.ReactChild | null;
}> = props => {
    const {
        message,
        popups,
        title,
        globalOps,
        filters,
        multipleOps,
        dataTable,
        className,
        operationClassName,
        filtersDisplay = null,
    } = props;
    return (
        <div className={'crm-standard-crud-outer ' + className}>
            {popups}
            <div className="crm-standard-crud">
                <LayoutFilterTable>
                    <div className={'operation ' + operationClassName}>
                        {message && <div className="message">{message}</div>}
                        <div className="meta animated fadeIn">
                            <div className="title">{title}</div>
                            {filtersDisplay && (
                                <div className="filters-display">
                                    {filtersDisplay}
                                </div>
                            )}
                            <div className="op-group">{globalOps}</div>
                        </div>
                        <div className="filters animated fadeIn">
                            {filters}
                            {multipleOps}
                        </div>
                    </div>
                    <div className="data">{dataTable}</div>
                </LayoutFilterTable>
            </div>
        </div>
    );
};
