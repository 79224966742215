import React, { useState, useEffect, useContext } from 'react';
import {
    applyCustomConfToCellsSelector,
    isMultipleCell,
    DTable,
    makeObjColClickable,
} from '@/components';
import { Tooltip } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { LeadListStore } from '../store';
import { runInAction } from 'mobx';
import LeadDrawer from '@/components/common/lead-drawer';
import { StoreContext } from '@/stores';
import { useCustomerDrawerSnippets } from '@/components/common/customer-drawer/hooks';
import { tryRefresh, useQueryAsObject } from '@/utils';
import { LeadOptCreate } from '@/components/common/lead-drawer/lead-opt-create';
import { LeadContactsCreate } from '@/components/common/lead-drawer/lead-contacts-create';
import AddFollowLog from '@/components/common/add-follow-log-new';
import { CtxProvider } from '@/utils/context';
import { isInValidLead } from '../utils';

const FormDemoTable: React.FC<{
    leadList: LeadListStore;
    loading: boolean;
    type: string;
    widthInfoKey: string;
}> = ({ leadList, loading, type, widthInfoKey = 'lead-list' }) => {
    const eventCode = type === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    const store = useContext(StoreContext);
    const customerListStore = store.getDefaultCustomerListStore();

    const [leadDrawerVis, setLeadDrawerVis] = useState(false);
    const [detailId, setDetailId] = useState(undefined);

    const [drawerVisCus, setDrawerVisCus] = useState(false);
    const [cidCus, setCidCus] = useState(undefined);

    const [followDrawerVis, setFollowDrawerVis] = useState(false);
    const [followDetailId, setFollowDetailId] = useState(undefined);
    const [followCidCus, setFollowCidCus] = useState(undefined);

    useQueryAsObject(queryObj => {
        if (queryObj.id) {
            setDetailId(queryObj.id);
            setLeadDrawerVis(true);
        }
    });

    const [___, cusDrawerSnippet] = useCustomerDrawerSnippets({
        drawerVis: drawerVisCus,
        setDrawerVis: setDrawerVisCus,
        cid: cidCus,
        type,
    });

    const [leadOptCreateTrigger, setLeadOptCreateTrigger] = useState(0);
    const [leadContactsCreateTrigger, setLeadContactsCreateTrigger] = useState(
        0,
    );

    const dataTable = (
        <>
            {cusDrawerSnippet}
            <CtxProvider
                ctx={{ position: 'list', positionDetail: 'lead-table' }}
            >
                <LeadOptCreate
                    leadInfo={leadList.mutatingData}
                    trigger={leadOptCreateTrigger}
                />
                <LeadContactsCreate
                    leadInfo={leadList.mutatingData}
                    trigger={leadContactsCreateTrigger}
                />
            </CtxProvider>
            <AddFollowLog
                // key={[
                //     followCidCus,
                //     followDetailId,
                //     followKey
                // ].join('-')}
                entityType={'lead'}
                cid={followCidCus}
                id={followDetailId}
                drawerVis={followDrawerVis}
                setDrawerVis={setFollowDrawerVis}
            />
            <LeadDrawer
                leadListStore={leadList}
                drawerVis={leadDrawerVis}
                setDrawerVis={setLeadDrawerVis}
                detailId={detailId}
                destroyOnClose={true}
                onEdit={data => {
                    runInAction(() => {
                        leadList.setAction('update', { id: data.id });
                    });
                }}
                onCreat={() => {
                    runInAction(() => {
                        leadList.setAction('create');
                        leadList.startNewData();
                    });
                }}
            />
            <DTable
                onColumnsUpdated={() => tryRefresh(['lead'])}
                sceneCode={'crm_lead'}
                eventCode={eventCode}
                defaultStore={leadList}
                cellsSelectorTransformer={baiscCellsSelector => {
                    const nextbaiscCellsSelector = [...baiscCellsSelector];
                    applyCustomConfToCellsSelector(
                        nextbaiscCellsSelector,
                        'name',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'ActionParam';
                            nextCellSelector.inputs.push({
                                paramName: 'name',
                            });
                            return nextCellSelector;
                        },
                    );

                    applyCustomConfToCellsSelector(
                        nextbaiscCellsSelector,
                        'duplicate_flag',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'DuplicateFlag';
                            return nextCellSelector;
                        },
                    );

                    makeObjColClickable(nextbaiscCellsSelector, 'customer_id');

                    return nextbaiscCellsSelector;
                }}
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [];
                    if (type === 'mine') {
                        props.actions.push(
                            {
                                actionKey: 'transfer-contacts',
                                actionCn: '转联系人',
                                render(data, original) {
                                    const invalid = isInValidLead(
                                        data?.follow_status,
                                    );

                                    const transformedContact = !!data
                                        ?.contact_id?.originalValue;

                                    if (invalid) {
                                        return (
                                            <Tooltip title="无效线索">
                                                <span style={{ color: 'gray' }}>
                                                    转联系人
                                                </span>
                                            </Tooltip>
                                        );
                                    }

                                    const transformedOpt = !!data
                                        ?.opportunity_id?.originalValue;

                                    if (transformedOpt) {
                                        return (
                                            <Tooltip title="已转商机">
                                                <span style={{ color: 'gray' }}>
                                                    转联系人
                                                </span>
                                            </Tooltip>
                                        );
                                    }

                                    if (transformedContact) {
                                        return (
                                            <Tooltip title="已转联系人">
                                                <span style={{ color: 'gray' }}>
                                                    转联系人
                                                </span>
                                            </Tooltip>
                                        );
                                    }
                                    return original;
                                },
                            },
                            {
                                actionKey: 'transfer-opportunity',
                                actionCn: '转商机',
                                render(data, original) {
                                    const invalid = isInValidLead(
                                        data?.follow_status,
                                    );

                                    const transformedOpt = !!data
                                        ?.opportunity_id?.originalValue;

                                    if (invalid) {
                                        return (
                                            <Tooltip title="无效线索">
                                                <span style={{ color: 'gray' }}>
                                                    转商机
                                                </span>
                                            </Tooltip>
                                        );
                                    }

                                    if (transformedOpt) {
                                        return (
                                            <Tooltip title="已转商机">
                                                <span style={{ color: 'gray' }}>
                                                    转商机
                                                </span>
                                            </Tooltip>
                                        );
                                    }
                                    return original;
                                },
                            },
                            {
                                actionKey: 'follow',
                                actionCn: '跟进',
                            },
                        );
                    }
                    props.actions.push(
                        {
                            actionKey: 'transform',
                            actionCn: '转移',
                        },
                        {
                            actionKey: 'update',
                            actionCn: '修改',
                        },
                    );

                    props.needMoreNum = 4;
                    props.onAction = (key, data) => {
                        if (key === 'update') {
                            runInAction(() => {
                                leadList.setActionAsync('update', {
                                    id: data.id,
                                });
                            });
                        } else if (key === 'transform') {
                            runInAction(() => {
                                leadList.setAction('transform');
                                leadList.setMutatingData(data);
                            });
                        } else if (key === 'follow') {
                            setFollowDetailId(data.id);
                            setFollowCidCus(data.customer_id?.originalValue);
                            setFollowDrawerVis(true);

                            const followConfig: any = {
                                id: data.id,
                                cid: data.customer_id?.originalValue,
                                entityType: 'lead',
                            };
                            customerListStore.defaultEE.emit(
                                'save-action-log-show',
                                followConfig,
                            );
                        } else if (_.startsWith(key, 'reveal-obj/')) {
                            // 关联对象都可以点击
                            if (key.indexOf('/customer') !== -1) {
                                setDrawerVisCus(true);
                                setCidCus(data.customer_id?.originalValue);
                            }
                        } else if (_.startsWith(key, 'reveal-param/')) {
                            // 可点击参数
                            console.log('reveal-param/', key, data);
                            if (key.indexOf('/name') !== -1) {
                                setDetailId(data.id);
                                setLeadDrawerVis(true);
                            }
                        } else if (key === 'transfer-contacts') {
                            setLeadContactsCreateTrigger(
                                leadContactsCreateTrigger + 1,
                            );
                            leadList.fetchInstantlyMutatingDataById(data.id);
                        } else if (key === 'transfer-opportunity') {
                            setLeadOptCreateTrigger(leadOptCreateTrigger + 1);
                            leadList.fetchInstantlyMutatingDataById(data.id);
                        } else if (key === 'see-dupli-detail') {
                            leadList.setAction('see-dupli-detail');
                            leadList.setDupDetailLead(data);
                        }
                    };
                    props.closeRowSelection = true;
                    props.widthInfoKey = widthInfoKey;
                    return props;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalFormTable = observer(FormDemoTable);
export default FinalFormTable;
