import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import {
    getAutoOnChangeHandlerOriginal,
    IWidgetItem,
    embedDataToWidgets,
    IFormLV2Props,
    FormLV2,
} from '@/components';
import { IDataSelectFormData } from '../../store';
import _ from 'lodash';
import { message, Button, Icon, Spin } from 'antd';
import XLSX from 'xlsx';
import './index.less';
import { toJS } from 'mobx';
import { defaultAxios, arrToJson } from '@/utils';
import moment from 'moment';
import { ParamsEditorModal } from '@/components/common/params-editor';
export interface IDataChooseCompProps {
    nextStep: () => void;
}
export interface IObjMetaData {
    constraint: {
        constraintItemList: any[];
        objFullMeta: any[];
        objSaveField: {
            fieldName: string;
        };
        fieldConstraintType: 'item_only' | 'item' | 'obj'; // item_only单选 item多选 obj关联对象
    };
    fieldShowType: 'input' | 'number' | 'date' | 'datetime';
    fieldComment: string; // 字段名称
    fieldName: string;
    groupKey: string;
    groupName: string;
    groupId: number;
    newFieldMetaItem: {
        id: number;
        fieldBind: string;
        fieldCode: string;
        fieldComment: string; // 字段名称
        fieldName: string;
        isEdit: 1 | 0;
        isRequired: 1 | 0;
        fieldType: string; // 字段类型
        options: {
            label: string; // 文本
            value: any; // 值
            selected: boolean; // 是否选中（修改时初始值）
            id: number;
            bizId: number;
            parentId: number;
        }[];
        relationObject: {
            filterName: any;
            findFields: string[]; // （筛选展示）/请求的字段列表
            objectCode: string; // 实体code
            showField: string; // 列表展示的字段
        };
    };
}
const DataChooseComp: React.FC<IDataChooseCompProps> = props => {
    const { nextStep } = props;
    const store = useContext(StoreContext);
    const dmiStore = store.getDefaultDataManageImportStore();
    const {
        dataSelectFormData,
        setDataSelectFormData,
        dataManageImportConfig,
        configFetchLoading,
        sheetReadLoading,
        readSheet,
        fetchDataChooseMetaData,
        canEditMetaData,
        requiredMetaData,
        fetchMetaDataLoading,
        selectedExampleMetaKey,
        setSelectedExampleMetaKey,
    } = dmiStore;
    // 获取默认的数据回调
    // const onDataChange = getAutoOnChangeHandlerOriginal(
    //     dataSelectFormData as IDataSelectFormData,
    //     setDataSelectFormData,
    // );

    // const [requiredMetaData, setRequiredMetaData] = useState<IObjMetaData[]>([]);
    // const [canEditMetaData, setCanEditMetaData] = useState<IObjMetaData[]>([]);
    // const [fetchMetaDataLoading, setFetchMetaDataLoading] = useState(false);
    const [importKeyChooseModalVis, setImportKeyChooseModalVis] = useState(
        false,
    );
    // const [anyFiledSelectClick, setAnyFiledSelectClick] = useState<boolean>(false);
    // const fetchDataChooseMetaData = async () => {
    //     setFetchMetaDataLoading(true);
    //     const url = '/bff/api/rest/om-import/meta-data';
    //     const [d, e] = await defaultAxios.get(url, {
    //         entityCode: dataSelectFormData.entityType
    //     });
    //     setFetchMetaDataLoading(false);
    //     if (d === null || d.data === null) {
    //         message.error('拉取元数据失败，请稍后重试');
    //         return [null, e];
    //     };
    //     if (d.data.canEditFieldMeta) {
    //         setCanEditMetaData(d.data.canEditFieldMeta);
    //     }
    //     if (d.data.requiredFieldMeta) {
    //         setRequiredMetaData(d.data.requiredFieldMeta);
    //     }
    // };
    useEffect(() => {
        if (dataSelectFormData.entityType) {
            fetchDataChooseMetaData();
        }
    }, [dataSelectFormData.entityType]);
    const onDataChange = (
        key: keyof IDataSelectFormData,
        value: any,
        widget: any,
    ) => {
        getAutoOnChangeHandlerOriginal(
            dataSelectFormData,
            setDataSelectFormData,
        )(key, value, widget);
        // }
    };
    const clearFile = () => {
        setDataSelectFormData({
            ...dataSelectFormData,
            file: undefined,
        });
    };
    const groupCanEditMetaData = arrToJson(canEditMetaData, 'fieldName');
    const makeExampleExcel = (keyArray: string[]) => {
        const aoaExample = [
            _.map(keyArray, key => {
                const existingMeta = groupCanEditMetaData[key];
                if (existingMeta) {
                    return existingMeta.fieldComment;
                } else {
                    return undefined;
                }
            }).filter(Boolean),
        ];
        const sheet = XLSX.utils.aoa_to_sheet(aoaExample);
        openDownloadDialog(
            sheet2blob(sheet),
            `${dataSelectFormData.entityType}_模版_${moment().format(
                'DD_HH_ss',
            )}.csv`,
        );
    };
    const sheet2blob = (sheet: any, sheetName?: any) => {
        sheetName = sheetName || 'sheet1';
        const workbook: {
            SheetNames: any;
            Sheets: any;
        } = {
            SheetNames: [sheetName],
            Sheets: {},
        };
        workbook.Sheets[sheetName] = sheet; // 生成excel的配置项
        const wopts: {
            bookType: any;
            bookSST: boolean;
            type: any;
        } = {
            bookType: 'csv', // 要生成的文件类型
            bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
            type: 'binary',
        };
        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([s2ab(wbout)], {
            type: 'application/octet-stream',
        }); // 字符串转ArrayBuffer
        function s2ab(s: any) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i != s.length; ++i) {
                // tslint:disable-next-line:no-bitwise
                view[i] = s.charCodeAt(i) & 0xff;
            }
            return buf;
        }
        return blob;
    };
    function openDownloadDialog(url: any, saveName: any) {
        if (typeof url == 'object' && url instanceof Blob) {
            url = URL.createObjectURL(url); // 创建blob地址
        }
        const aLink = document.createElement('a');
        aLink.href = url;
        aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
        let event;
        if (window.MouseEvent) {
            event = new MouseEvent('click');
        } else {
            event = document.createEvent('MouseEvents');
            event.initMouseEvent(
                'click',
                true,
                false,
                window,
                0,
                0,
                0,
                0,
                0,
                false,
                false,
                false,
                false,
                0,
                null,
            );
        }
        aLink.dispatchEvent(event);
    }
    const widgets: Array<IWidgetItem<IDataSelectFormData>> = [
        {
            key: 'entityType',
            widgetKey: 'select',
            selectOptions: {
                optionFilterProp: 'label',
                style: { width: '220px' },
                options: _.map(
                    dataManageImportConfig.entityType || [],
                    config => {
                        return {
                            name: config.label,
                            value: config.key,
                        };
                    },
                ),
            },
            status: {
                loading: configFetchLoading,
            },
        },
        {
            key: 'importType',
            widgetKey: 'select',
            selectOptions: {
                optionFilterProp: 'label',
                style: { width: '220px' },
                options: _.map(
                    dataManageImportConfig.importType || [],
                    config => {
                        return {
                            name: config.label,
                            value: config.key,
                        };
                    },
                ),
            },
            status: {
                loading: configFetchLoading,
            },
        },
        // {
        //     key: 'encodingType',
        //     widgetKey: 'select',
        //     selectOptions: {
        //         optionFilterProp: 'label',
        //         style: { width: '220px' },
        //         options: _.map(dataManageImportConfig.encodingType || [], (config) => {
        //             return {
        //                 name: config.label,
        //                 value: config.key
        //             }
        //         })
        //     },
        //     status: {
        //         loading: configFetchLoading
        //     }
        // },
        {
            key: 'file',
            widgetKey: 'inputUpload',
            widgetClassName: 'dmi-data-choose-upload-content',
            afterWidget: (
                <>
                    <span className="dmi-data-choose-upload-btn">选择文件</span>
                    {dataSelectFormData.file &&
                    dataSelectFormData.file.length ? (
                        <div className="dmi-data-choose-upload-text">
                            <span>{dataSelectFormData.file?.[0].name}</span>
                            <Icon onClick={clearFile} type="close" />
                        </div>
                    ) : null}
                </>
            ),
            inputUploadOptions: {
                className: 'dmi-data-choose-upload',
                accept: '.xlsx, .xls, .csv',
            },
        },
    ];
    embedDataToWidgets<IDataSelectFormData>(widgets, dataSelectFormData);
    const dataChooseFormProps: IFormLV2Props<IDataSelectFormData> = {
        data: dataSelectFormData,
        formOptions: {
            layout: 'vertical',
        },
        widgets,
        onChange: onDataChange,
        classNameLv2: 'lv2-datachoose-form',
        actionWidgets: [
            {
                key: 'download',
                option: {
                    btnText: '下载模版',
                    type: 'primary',
                    disabled: !dataSelectFormData.entityType,
                    loading: fetchMetaDataLoading,
                    clickActionType: 'download',
                    className: 'dmi-download-btn',
                },
            },
            {
                key: 'submit',
                option: {
                    btnText: '下一步',
                    type: 'primary',
                    loading: sheetReadLoading,
                },
            },
        ],
        onInteract: (type, data, value) => {},
        onAction: async (actionType, data, isPass, validateResult) => {
            if (actionType === 'download') {
                // makeExampleExcel(selectedExampleMetaKey);
                setImportKeyChooseModalVis(true);
            }
            if (actionType === 'submit') {
                // if(!anyFiledSelectClick) {
                //     setAnyFiledSelectClick(true);
                // };
                if (isPass) {
                    readSheet(nextStep);
                    console.log(data);
                } else {
                    message.error('数据不正确，请检查后进行下一步操作');
                }
            }
        },
        formItemOptions: [
            {
                key: 'entityType',
                label: '对象类型',
                required: true,
                className: 'dmi-form-entitytype',
            },
            {
                key: 'importType',
                label: '导入类型',
                required: true,
                className: 'dmi-form-importtype',
            },
            // {
            //     key: 'encodingType',
            //     label: '文件编码',
            //     required: true,
            //     className: 'dmi-form-encodingtype'
            // },
            {
                key: 'file',
                label: '上传文件',
                required: true,
                className: 'dmi-form-file',
            },
        ],
        validateResult: {},
        validateMode: 'instantly',
        validators: {
            entityType: data => {
                if (!data.entityType || _.isNil(data.entityType)) {
                    return {
                        status: 'fail',
                        msg: '请选择对象类型',
                    };
                }
                return {
                    status: 'success',
                    msg: '',
                };
            },
            importType: data => {
                if (!data.importType || _.isNil(data.importType)) {
                    return {
                        status: 'fail',
                        msg: '请选择导入类型',
                    };
                }
                return {
                    status: 'success',
                    msg: '',
                };
            },
            // encodingType: (data) => {
            //     if (!data.encodingType || _.isNil(data.encodingType)) {
            //         return {
            //             status: 'fail',
            //             msg: '请选择文件编码'
            //         }
            //     }
            //     return {
            //         status: 'success',
            //         msg: ''
            //     }
            // },
            file: data => {
                if (!data.file || !data.file.length) {
                    return {
                        status: 'fail',
                        msg: '请选择文件',
                    };
                }
                if (data.file[0].size > 3 * 1024 * 1024) {
                    return {
                        status: 'fail',
                        msg: '上传的文件不能超过3M大小',
                    };
                }
                return {
                    status: 'success',
                    msg: '',
                };
            },
        },
    };
    return (
        <>
            <ParamsEditorModal
                visible={importKeyChooseModalVis}
                title={'自定义导入字段列表'}
                okText={'生成模版'}
                okButtonProps={{}}
                defaultSelectedKeys={_.map(requiredMetaData, metaData => {
                    return metaData.fieldName;
                })}
                afterClose={() => setImportKeyChooseModalVis(false)}
                onCancel={() => setImportKeyChooseModalVis(false)}
                params={_.map(canEditMetaData, metaData => {
                    return {
                        title: metaData.fieldComment,
                        key: metaData.fieldName,
                        readonly: !!metaData.newFieldMetaItem.isRequired,
                        groupKey: metaData.groupKey,
                        groupTitle: metaData.groupName,
                    };
                })}
                onSelectedKeysConfirmed={nextKey => {
                    console.log(nextKey);
                    setSelectedExampleMetaKey(nextKey);
                    setImportKeyChooseModalVis(false);
                    makeExampleExcel(nextKey);
                }}
            />
            <FormLV2<IDataSelectFormData> {...dataChooseFormProps}>
                <>
                    {/* <div className={`data-choose-key-select 
                            ${ dataSelectFormData.entityType ? '' : ' disabled'}
                            `}>
                        <span className="key-select-label">导入字段</span>
                        <Spin
                            spinning={fetchMetaDataLoading}
                        >
                            <span
                                className={`key-select-btn`}
                                onClick={() => {
                                    if (!dataSelectFormData.entityType || fetchMetaDataLoading) {
                                        return;
                                    }
                                    setImportKeyChooseModalVis(true);
                                }}
                            >选择字段</span>
                        </Spin>
                        {/* {anyFiledSelectClick ? <p className="error-text">请选择字段</p> : null} */}
                    {/* </div> */}
                    <div className="data-choose-tips">
                        <p>
                            导入只支持编码为GB
                            18030与UTF-8的文件，无特殊需求时，请勿修改文件模版编码
                        </p>
                        <p>单次上传的文件行数不能超过3000条,大小不能超过3M</p>
                    </div>
                </>
            </FormLV2>
        </>
    );
};

export default observer(DataChooseComp);
