import React, { useState, useEffect } from 'react';
import { Radio, Divider } from 'antd';
// import {} from './dtitle2-grouped';

// SUBMIT("待提交")、APPROVE("审批中")、FINISH("已完成")、TODO("待审批")、DONE("已完成")、SUBMIT_ALL("我提交的全部")、TODO_ALL("待我审批的全部")

// key: 'my-applicant-todo',
// label: '待提交',

// key: 'my-applicant-ing',
// label: '审批中',

// key: 'my-applicant-done',
// label: '已完成',

// key: 'my-deal-todo',
// label: '待审批',

// key: 'my-deal-done',
// label: '已完成',

// key: 'my-task-all',
// label: '全部流程',

// 原来用fastFilter那一套去硬封装，太别扭了，这里就写死就完事了，基本不会动态化
export const ProcessTypeSelector: React.FC<{
    onChange: (filter: any) => void;
    selected: any;
    processStatistics: {
        [key: string]: any;
    };
    resetTrigger?: any;
}> = ({ onChange, selected, processStatistics, resetTrigger }) => {
    const [topType, setTopType] = useState('a');
    useEffect(() => {
        if (topType === 'a') {
            // 我提交的
            onChange('SUBMIT');
        } else if (topType === 'b') {
            // 待我审批的
            onChange('TODO');
        }
    }, [resetTrigger, topType]);
    return (
        <div
            className="process-type-selector"
            style={{
                display: 'flex',
                alignItems: 'stretch',
            }}
        >
            <div style={{ marginLeft: 18, marginRight: 8 }}>
                <Radio.Group
                    onChange={e => {
                        const v = e.target.value;
                        setTopType(v);
                    }}
                    value={topType}
                >
                    <Radio.Button value="a">我提交的</Radio.Button>
                    <Radio.Button value="b">待我审批的</Radio.Button>
                </Radio.Group>
            </div>
            <Divider type="vertical" style={{ height: 'initial' }} />
            <div style={{ marginLeft: 8 }} className="process-subtype-selector">
                {topType === 'a' && (
                    <Radio.Group
                        onChange={e => {
                            const v = e.target.value;
                            onChange(v);
                        }}
                        value={selected}
                    >
                        <Radio value="SUBMIT">
                            待提交
                            {processStatistics['SUBMIT']
                                ? `（${processStatistics['SUBMIT']}）`
                                : ``}
                        </Radio>
                        <Radio value="APPROVE">
                            审批中
                            {processStatistics['APPROVE']
                                ? `（${processStatistics['APPROVE']}）`
                                : ``}
                        </Radio>
                        <Radio value="FINISH">已完成</Radio>
                        <Radio value="SUBMIT_ALL">全部</Radio>
                    </Radio.Group>
                )}

                {topType === 'b' && (
                    <Radio.Group
                        onChange={e => {
                            const v = e.target.value;
                            onChange(v);
                        }}
                        value={selected}
                    >
                        <Radio value="TODO">
                            待审批
                            {processStatistics['TODO']
                                ? `（${processStatistics['TODO']}）`
                                : ``}
                        </Radio>
                        <Radio value="DONE">已审批</Radio>
                        <Radio value="TODO_ALL">全部</Radio>
                    </Radio.Group>
                )}
            </div>
        </div>
    );
};
