import React from 'react';
import { defaultAxios } from '../net';
import { useState, useEffect } from 'react';
import { message, Modal } from 'antd';
import _ from 'lodash';
import { IMOpResult, MOpResultDisplay } from '@/components';

export interface ICheckBody {
    tableCode: string;
    body: any;
}

export const makePrecheckRequest = (checkBody: ICheckBody) => {
    const { tableCode, body } = checkBody;
    return defaultAxios.put('/crm/common/' + tableCode + '/check', body);
};

export const useAuthPrecheck = (options: {
    checkBody?: ICheckBody;
    parentProps: { onClick?: React.MouseEventHandler<HTMLElement> };
}): [React.MouseEventHandler<HTMLElement>, React.ReactElement, boolean] => {
    const { checkBody, parentProps } = options;

    const { onClick } = parentProps;

    const [loading, setLoading] = useState(false);
    const [mOpResult, setMOpResult] = useState<IMOpResult | null>(null);

    if (!checkBody) {
        return [e => void 0, <></>, false];
    }

    let nextOnClick: React.MouseEventHandler<HTMLElement> = e => void 0;
    if (onClick !== undefined) {
        nextOnClick = async ev => {
            setLoading(true);
            const [d, e] = await makePrecheckRequest(checkBody);
            setLoading(false);
            if (!_.isNil(e) || _.isNil(d)) {
                return;
            }
            let { warningNum, errorNum } = d.data || {};
            warningNum = warningNum || 0;
            errorNum = errorNum || 0;
            if (warningNum === 0 && errorNum === 0) {
                onClick(ev);
            } else {
                setMOpResult(d?.data || null);
            }
        };
    }
    const snippet = (
        <>
            {mOpResult ? (
                <Modal
                    visible={!!mOpResult}
                    title={'无操作权限'}
                    onCancel={() => {
                        setMOpResult(null);
                    }}
                    onOk={async () => {
                        setMOpResult(null);
                    }}
                >
                    <MOpResultDisplay result={mOpResult} />
                </Modal>
            ) : null}
        </>
    );
    return [nextOnClick, snippet, loading];
};
