import React from 'react';
import { Icon, Button } from 'antd';
import './index.scss';
import { AutoScrollable } from '../filters-display/auto-scrollable';
import { AutoEllipsis } from '../auto-ellipsis';

export const FastFilter: React.FC<{
    list: Array<{ label: string; fastFilterKey: string; value: any }>;
    onListItemClick: (value: any) => void;
    activeKey: string;
}> = ({ list, onListItemClick, activeKey }) => {
    if (list.length === 0) {
        return null;
    }

    return (
        <div className="crm-fast-filters-2">
            <div className="fast-filters">
                <AutoScrollable>
                    <div className="filters-wrapper">
                        {list?.map((item, index) => {
                            const { label, fastFilterKey } = item;
                            const isActive = activeKey === fastFilterKey;
                            const nextItem = list[index + 1];
                            const isLast = !nextItem;
                            const isNextActive =
                                nextItem &&
                                nextItem.fastFilterKey === activeKey;
                            const showAppendLine =
                                !isNextActive && !isLast && !isActive;
                            return (
                                <div
                                    className={
                                        'fast-filter-item ' +
                                        (isActive ? 'active ' : ' ') +
                                        (showAppendLine ? 'append-line ' : ' ')
                                    }
                                    onClick={() => {
                                        if (!isActive) {
                                            onListItemClick &&
                                                onListItemClick(item);
                                        } else {
                                            onListItemClick &&
                                                onListItemClick(null);
                                        }
                                    }}
                                >
                                    <span
                                        style={{
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            width: '100%',
                                            display: 'inline-block',
                                            lineHeight: '1',
                                        }}
                                    >
                                        {label}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </AutoScrollable>
            </div>
        </div>
    );
};
