import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    embedDataToWidgets,
    getAutoOnChangeHandler,
    FormStandardCrudFilter,
    LayoutFilterTable,
    TableLv1,
} from '@/components';
import { Alert } from 'antd';
import { OptionsList } from '@/components/common/form/widgets';
import _ from 'lodash';
import {
    IFormCrudDemoFilterData,
    widgets,
    formItemOptions,
} from './filter-options';
import { defaultAuthDeco } from '@/containers/hoc';
import { StoreContext } from '@/stores';
import { columnsConfig, cellsSelector } from './table-options';
import { useNet } from '@/utils';
import { NexusGenFieldTypes } from '@/definations/graphql/auto-gen';
import { observer } from 'mobx-react-lite';
import './index.scss';
import { storeHistoyBidirectBind } from '@/stores/utils';
import querystring from 'querystring';

const FormDemo: React.FC<RouteComponentProps> = ({ children }) => {
    const store = useContext(StoreContext);
    const [demoCurd] = useState(() => new store.CrudDemo());
    const {
        data,
        filterData,
        setFilterData,
        confirmedfilterData,
        defaultPagination,
    } = demoCurd;
    const {
        setPaginationByUI,
        paginationForUI,
        paginationForFetch,
        amount,
    } = defaultPagination;

    const demoCurdFetch = useCallback(() => demoCurd.fetch(), []);
    const [error, loading, reload] = useNet(demoCurdFetch);

    // 翻页
    useEffect(() => {
        reload();
    }, [paginationForFetch, confirmedfilterData]);

    useEffect(() => {
        return storeHistoyBidirectBind(demoCurd);
    }, []);

    // 获取默认的数据回调
    const onDataChange = getAutoOnChangeHandler(filterData, setFilterData);
    // 结合插件配置和真实数据
    const finalWidgets = widgets();
    embedDataToWidgets<IFormCrudDemoFilterData>(finalWidgets, filterData);

    return (
        <div className="page-demo-crud">
            <LayoutFilterTable>
                <FormStandardCrudFilter<IFormCrudDemoFilterData>
                    data={filterData}
                    widgets={finalWidgets}
                    onChange={onDataChange}
                    formItemOptions={formItemOptions}
                    layout="inline"
                    onInteract={(key, type, value) => {
                        console.log('key, type, value: ', key, type, value);
                    }}
                    onAction={actionType => {
                        demoCurd.confirmFilterData();
                    }}
                ></FormStandardCrudFilter>
                <TableLv1<NexusGenFieldTypes['Author']>
                    loading={loading}
                    dataSource={data}
                    columns={columnsConfig}
                    cellsSelector={cellsSelector}
                    onChange={setPaginationByUI}
                    pagination={{
                        ...paginationForUI,
                        total: amount,
                    }}
                />
            </LayoutFilterTable>
        </div>
    );
};

const FinalFormDemo = defaultAuthDeco(observer(FormDemo));
export default FinalFormDemo;
