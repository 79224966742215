import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import './index.scss';
import {
    ICrmTimelineItemGroupProps,
    CrmTimelineItemGroup,
} from './timeline-items';
import { getQueryAsObject } from '@/utils';

export interface ICrmTimelineProps {
    groups: ICrmTimelineItemGroupProps[];
    showCount?: number; // 默认展示多少条
}

export const CrmTimeline: React.FC<ICrmTimelineProps & { isNew?: boolean }> = ({
    groups,
    showCount,
    isNew,
}) => {
    const preLimit = (showGroups: ICrmTimelineItemGroupProps[]) => {
        const finalShowGroups: ICrmTimelineItemGroupProps[] = [];
        let showTempCount = showCount;
        _.forEach(showGroups, group => {
            if (!showTempCount) {
                return;
            }
            if (group.timelineItems.length <= showTempCount) {
                finalShowGroups.push(group);
                showTempCount = showTempCount - group.timelineItems.length;
            } else {
                if (!!showTempCount) {
                    const tempGroup = _.cloneDeep(group);
                    tempGroup.timelineItems = _.take(
                        group.timelineItems,
                        showTempCount,
                    );
                    finalShowGroups.push(tempGroup);
                    showTempCount = 0;
                }
            }
        });
        return finalShowGroups;
    };
    const defaultShowAll = (
        showGroups: ICrmTimelineItemGroupProps[],
        recordId: number,
    ) => {
        const groups = preLimit(showGroups);
        return !groups.some(group =>
            group.timelineItems.some(timelineItem => {
                return timelineItem.id === recordId;
            }),
        );
    };
    let showGroups = [...groups];
    const [showAll, setShowAll] = useState(false);
    useEffect(() => {
        const { recordId } = getQueryAsObject();
        if (!recordId) {
            return;
        }
        setShowAll(defaultShowAll(showGroups, Number(recordId)));
    }, [showGroups]);

    if (showCount && !showAll) {
        // 修改为展示真正的条数而非天数（2021.4.6）
        const finalShowGroups: any[] = [];
        let showTempCount = showCount;
        _.forEach(showGroups, group => {
            if (!showTempCount) {
                return;
            }
            if (group.timelineItems.length <= showTempCount) {
                finalShowGroups.push(group);
                showTempCount = showTempCount - group.timelineItems.length;
            } else {
                if (!!showTempCount) {
                    const tempGroup = _.cloneDeep(group);
                    tempGroup.timelineItems = _.take(
                        group.timelineItems,
                        showTempCount,
                    );
                    finalShowGroups.push(tempGroup);
                    showTempCount = 0;
                }
            }
        });
        showGroups = finalShowGroups;
    }
    let allLength = 0;
    _.forEach(groups, group => {
        allLength = allLength + group.timelineItems.length;
    });
    return (
        <div className="crm-timeline">
            {_.map(showGroups, (timelineGroup, key) => {
                return (
                    <CrmTimelineItemGroup
                        isNew={isNew}
                        {...timelineGroup}
                        key={key}
                    />
                );
            })}
            {showCount && showCount < allLength && (
                <div className="show-more">
                    {showAll ? (
                        <p
                            onClick={() => {
                                setShowAll(false);
                            }}
                        >
                            收起记录
                        </p>
                    ) : (
                        <p
                            onClick={() => {
                                setShowAll(true);
                            }}
                        >
                            查看更多
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};
