import * as React from 'react';
import { useState } from 'react';
import _ from 'lodash';
import './index.scss';

interface IDrawerCardItem {
    title: string | React.ReactElement | (() => React.ReactElement);
    content: string | React.ReactElement | (() => React.ReactElement);
    titleClassName?: string;
    contentClassName?: string;
    onClick?: (cardInfo: { title: any; content: any }) => void;
}
export interface IDrawerCardListProps {
    dataSource: IDrawerCardItem[];
}
const DrawerCardList: React.FC<IDrawerCardListProps> = ({ dataSource }) => {
    const renderCardTitle = (item: IDrawerCardItem) => {
        const { title, titleClassName } = item;
        if (typeof title === 'string') {
            return <p className={titleClassName}>{title}</p>;
        } else if (typeof title === 'function') {
            return title();
        } else {
            return title;
        }
    };
    const renderCardBody = (item: IDrawerCardItem) => {
        const { content, contentClassName } = item;
        if (typeof content === 'string') {
            return <p className={contentClassName}>{content}</p>;
        } else if (typeof content === 'function') {
            return content();
        } else {
            return content;
        }
    };
    return (
        <div className="drawer-card-list-content">
            {_.map(dataSource, (item, idx) => {
                return (
                    <div key={idx} className="drawer-card-list-item">
                        <div className="card-title-content">
                            {renderCardTitle(item)}
                        </div>
                        <div className="card-item-body">
                            {renderCardBody(item)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
export default DrawerCardList;
