// AGREE
// ADD_APPROVE

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Input, message, Form } from 'antd';
import { rejects, btnMap } from './conf';
import './action.scss';
import _ from 'lodash';
import { ProcessCatStore } from '../../store';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const AgreeAction: React.FC<{
    currentAction: string;
    data: any;
    onOk: (extra?: any) => void;
    onCancel: () => void;
    defaultStore: ProcessCatStore | null;
    loading: boolean;
}> = observer(
    ({ currentAction, data, onOk, onCancel, defaultStore, loading }) => {
        if (!defaultStore?.defaultMeta) {
            return null;
        }
        const title = btnMap[currentAction] || '';
        const comment = data?.comment || '';
        const processBtns = defaultStore?.defaultMeta?.processBtns || [];
        const showAddApprove = processBtns.indexOf('ADD_APPROVE') > -1;
        const visible =
            currentAction === 'AGREE' && !showAddApprove && data !== null;

        return (
            <Modal
                title={title}
                onOk={() => {
                    onOk();
                }}
                confirmLoading={loading}
                onCancel={onCancel}
                visible={visible}
                zIndex={10001}
            >
                <p>请填写审批意见</p>

                <div className="process-action">
                    <div className="form-wrapper">
                        <Form className="form" layout={'horizontal'}>
                            <Form.Item
                                {...formItemLayout}
                                label={<span className="label">审批意见</span>}
                            >
                                <Input.TextArea
                                    value={comment}
                                    onChange={e => {
                                        const text = e?.target?.value;
                                        if (!_.isUndefined(text)) {
                                            data.comment = text;
                                        }
                                    }}
                                    placeholder={'审批意见'}
                                ></Input.TextArea>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        );
    },
);
