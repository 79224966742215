import React, { useEffect, memo, useState, useRef } from 'react';
import {
    embedDataToWidgets,
    getAutoOnChangeHandler,
    EditableDisplay,
    AntButton,
    loadingWrapper,
    AntCollapse,
    OptStep,
    defaultLabelsGenerator,
    BlockLoading2,
    DForm,
} from '@/components';
import _ from 'lodash';

import { observer } from 'mobx-react-lite';
import { OptListStore } from '../store';
import {
    message,
    Popover,
    Input,
    Modal,
    Form,
    Spin,
    Select,
    Radio,
    Alert,
} from 'antd';
import {
    defaultCellsSelectorGenerator,
    applyConfToFormItem,
} from '@/components';
import { makeAutoObservable, runInAction } from 'mobx';
import './parse-trans.scss';
import {
    defaultAxios,
    arrToJsonPrimary,
    tryRefresh,
    executeAllValidatorFor,
    useRefresh,
} from '@/utils';
import { getOpts, commonQuery } from '@/api/rest';
import {
    IWidgetItem,
    IItemOptionItem,
} from '@/components/common/form/advanced-filters';
import { getValidatorByParamMeta } from '@/stores/utils';
import { WidgetSelect } from '@/components/common/form/widgets';
import { embedProductParamToOpt } from '../utils';
import { useRequest } from 'ahooks';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

// 40商机退回20或输单时，新增退回原因
const transReasonOptions = [
    {
        value: 0,
        label: '客户无兴趣',
    },
    {
        value: 1,
        label: '未明确决策人',
    },
    {
        value: 2,
        label: '未发出报价',
    },
    {
        value: 3,
        label: '其他',
    },
];

export const formWidgetsParseTrans = (
    defaultStore: OptListStore,
): Array<IWidgetItem<any>> => {
    const { defaultMeta } = defaultStore;
    const wdigetsRaw = defaultMeta.defaultWidgetsAllWithoutGroupCheck;
    return wdigetsRaw;
};

export const formformItemOptionsParseTrans = (
    defaultStore: OptListStore,
): Array<IItemOptionItem<any>> => {
    const { defaultMeta } = defaultStore;
    const wdigetsRawLabels = defaultMeta.defaultWidgetLabels;
    return wdigetsRawLabels;
};

export interface IStageItem {
    bizType: number;
    id: number;
    requiredCondition: string[];
    requiredField: string[];
    stageName: string;
    stageNum: string;
    winRate: number;
}

interface ReasonType {
    choice: boolean;
    name: string;
    value: number;
}

const MutateRequiredParams: React.FC<{
    psStore: ParseTransStore;
    defaultStore: OptListStore;
    badParams: string[];
    visible: boolean;
    reset?: () => void;
    onOk?: () => void;
}> = observer(({ psStore, defaultStore, badParams, visible, reset, onOk }) => {
    const mp = arrToJsonPrimary(badParams);
    const [innerStore] = useState(() => {
        const store = new OptListStore();
        store.setDefaultMeta(defaultStore.defaultMeta);
        return store;
    });

    const { mutatingData } = innerStore;
    // 代理
    useEffect(() => {
        innerStore.setMutatingData(
            badParams.reduce((ret, k) => {
                ret[k] = psStore.editingData[k];
                return ret;
            }, {} as any),
        );
    }, [badParams]);
    if (!innerStore.defaultMeta.inited || badParams.length === 0) {
        return null;
    }

    return (
        <Modal
            visible={visible}
            title={'请完善当前阶段所需信息'}
            onCancel={() => {
                reset && reset();
            }}
            confirmLoading={psStore.confirmLoading}
            onOk={async () => {
                const result = await executeAllValidatorFor(
                    innerStore.mutatingData,
                    defaultStore.defaultMeta.defaultValidators,
                    badParams,
                );
                if (!result.isPass) {
                    innerStore.setNetValidateResult(result.result);
                    return;
                }
                const prevEditingData = psStore.editingData;
                psStore.setEditingData({
                    ...prevEditingData,
                    ...innerStore.mutatingData,
                });
                const isSuccess = await psStore.confirmEditingData();
                if (isSuccess) {
                    return onOk && onOk();
                }
                psStore.setEditingData(prevEditingData);
            }}
        >
            <DForm
                defaultStore={innerStore}
                widgetsTransformer={baiscWidgets => {
                    applyConfToFormItem(baiscWidgets, 'inputSecret', {
                        sceneCode: 'crm_opportunity',
                    });
                    return baiscWidgets;
                }}
                widgetsGenerator={store => {
                    const widgets = store.defaultMeta.defaultWidgetsAllWithoutGroupCheck.filter(
                        item => mp[item.key as string],
                    );
                    return widgets;
                }}
                options={{
                    grouped: false,
                }}
            />
        </Modal>
    );
});

// 一个测试store，到时候直接给商机这里用
export class ParseTransStore {
    public confirmedData: any = {};
    public editingData: any = {};

    public inited = false;
    public setInited(next: boolean) {
        this.inited = false;
    }

    public stageListLoading = false;
    public stageList: IStageItem[] = [];
    public originalStageList: IStageItem[] = [];
    get stageTexts() {
        if (this.isShudan && this.stageBeforeShudan) {
            const ret: Array<{ text: number; title: string }> = [];
            for (const item of this.stageList) {
                ret.push({
                    // text: item.stageName + item.winRate + '%',
                    text: item.winRate,
                    title: item.stageName,
                });
                if (item.id === this.stageBeforeShudan) {
                    break;
                }
            }
            ret.push({
                text: 0,
                title: '输单',
            });
            return ret;
        }
        return this.stageList.map(item => {
            return {
                // text: item.stageName + item.winRate + '%',
                text: item.winRate,
                title: item.stageName,
            };
        });
    }

    get currentStageIndex() {
        const index = this.stageList
            .map(item => item.id)
            .indexOf(this.confirmedData?.opportunity_sale_stage?.originalValue);
        if (index === -1) {
            // TODO
            // 如果找不到，其实就是输单了
            // 这时候其实需要从另一个字段拿它的active数据
            return -1;
        }
        // 现在就是当前完成了哪一步，加载完就停留在哪一步
        return index;
    }

    get isDone() {
        const index = this.stageList
            .map(item => item.id)
            .indexOf(this.confirmedData?.opportunity_sale_stage?.originalValue);
        // 到最后异步，或者超了就是完了
        return index >= this.stageList.length - 1;
    }

    get currentStage() {
        return this.stageList[this.currentStageIndex];
    }

    public refreshLoading = false;
    public async refresh(fieldAuthCode?: string, dataAuthCode?: string) {
        const id = this.confirmedData?.id;
        this.refreshLoading = true;
        const [d, e] = await commonQuery(
            'opt',
            {
                pageNum: 1,
                pageSize: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        operateType: '=',
                        filterValue: id,
                    },
                ],
            },
            {},
            fieldAuthCode,
            undefined,
            dataAuthCode,
        );
        if (e || d === null) {
            return false;
        }
        const optsData = d;
        const { datas: opts } = optsData;
        if (!opts || opts.length === 0) {
            return message.error('商机未找到');
        }

        await embedProductParamToOpt(opts[0]);
        this.refreshLoading = false;

        runInAction(() => {
            this.confirmedData = opts[0];
            this.editingData = opts[0];
        });
    }

    public shudanStage: IStageItem | null = null;
    get isShudan() {
        return (
            this.shudanStage !== null &&
            this.shudanStage.id ===
                this.confirmedData?.opportunity_sale_stage?.originalValue
        );
    }
    get stageBeforeShudan() {
        return this.confirmedData?.lost_stale_stage_id?.originalValue;
    }
    public async fetchStageList() {
        const bizType = this.confirmedData?.biz_type;
        if (_.isUndefined(bizType)) {
            return;
        }
        this.stageListLoading = true;
        const [d, e] = await defaultAxios.get(
            '/crm/opportunity/findSysOpportunitySaleStageList',
            {
                bizType,
            },
        );
        if (e) {
            return;
        }
        if (d && _.isArray(d.data)) {
            runInAction(() => {
                this.stageListLoading = false;
                this.shudanStage = d.data[0];
                this.originalStageList = d.data;
                this.stageList = d.data.slice(1);
            });
        }
    }

    public stageStatus: any = {};
    public stageStatusLoading = false;
    public async fetchStageStatus(stageItem: IStageItem) {
        if (!this.confirmedData || !stageItem || this.isDone) {
            return;
        }
        // Long opportunityId, Long saleStageId,Integer bizType
        this.stageStatus = {};
        this.stageStatusLoading = true;
        const [d, e] = await defaultAxios.get(
            '/crm/opportunity/checkSaleStageChange',
            {
                opportunityId: this.confirmedData?.id,
                saleStageId: stageItem.id,
                bizType: stageItem.bizType,
            },
            {
                silent: true,
            },
        );
        this.stageStatusLoading = false;
        if (!e && d) {
            this.stageStatus = d.data || {};
        }
    }

    public isGoodStageStatus(statusItem: any) {
        const { conditionList, conditionMsg, fieldList, fieldMsg } = statusItem;
        if (
            conditionList === null &&
            conditionMsg === null &&
            fieldList === null &&
            fieldMsg === null
        ) {
            return true;
        }
        return false;
    }

    public shudanLoading = false;
    public async postShudan(inputShudanReason: string, shudanType: string[]) {
        const shudanReason = _.trim(inputShudanReason);

        const shudanStage = this.shudanStage;
        if (!this.confirmedData || !shudanStage) {
            return false;
        }
        this.editingData.lose_order_reason_type = shudanType?.join(',');
        this.editingData.lose_order_remark = shudanReason;
        this.shudanLoading = true;
        const isSuccess = await this.optStore.update2(this.editingData);
        if (isSuccess) {
            this.syncEditingData();
        } else {
            message.error('输单失败，输单信息保存失败');
            this.shudanLoading = false;
            return false;
        }

        const [d, e] = await defaultAxios.get(
            '/crm/opportunity/updateOpportunitySaleStage',
            {
                opportunityId: this.confirmedData?.id,
                saleStageId: shudanStage.id,
                bizType: shudanStage.bizType,
            },
        );
        this.shudanLoading = false;

        if (e || !d) {
            message.error('输单失败，商机阶段转移失败');
            tryRefresh(['opt']);
            return false;
        }

        if (!d.data || _.isEmpty(d.data) || this.isGoodStageStatus(d.data)) {
            message.success('输单成功');
            tryRefresh(['opt']);

            // 通知活动记录商机赢率更新
            window?.opener?.postMessage(
                {
                    type: 'opt-stage-update',
                    payload: {
                        opportunityId: this.confirmedData?.id,
                        saleStageId: shudanStage.id,
                        winRate: shudanStage.winRate,
                    },
                },
                window.location.origin,
            );
            return true;
        }
        message.error('输单失败，商机阶段转移失败');
        tryRefresh(['opt']);
        return false;
    }

    public async postStage(stageItem: IStageItem, reason?: string) {
        if (!this.confirmedData || !stageItem) {
            return;
        }
        const [d, e] = await defaultAxios.get(
            '/crm/opportunity/updateOpportunitySaleStage',
            {
                opportunityId: this.confirmedData?.id,
                saleStageId: stageItem.id,
                bizType: stageItem.bizType,
                reason,
            },
        );

        if (e || !d) {
            return false;
        }

        if (!d.data || _.isEmpty(d.data) || this.isGoodStageStatus(d.data)) {
            message.success('商机阶段转移成功');

            // 通知活动记录商机赢率更新
            window?.opener?.postMessage(
                {
                    type: 'opt-stage-update',
                    payload: {
                        opportunityId: this.confirmedData?.id,
                        saleStageId: stageItem.id,
                        winRate: stageItem.winRate,
                    },
                },
                window.location.origin,
            );

            return true;
        }
        message.error('商机阶段转移失败');
        return false;
    }

    public async updateStage40(state: number, reason?: string) {
        if (!this.confirmedData) {
            return;
        }
        const [d, e] = await defaultAxios.post(
            '/crm/opportunity/updateOpportunityStage40State',
            {
                id: this.confirmedData?.id,
                stage40State: state,
                stage40RefuseReason: reason,
            },
        );

        if (e || !d) {
            return false;
        }

        return true;
    }

    public optStore = new OptListStore();
    public setOptStore(nextStore: OptListStore) {
        this.optStore = nextStore;
    }

    public setEditingData(nextData: any) {
        this.editingData = nextData;
    }
    public syncEditingData() {
        this.confirmedData = _.cloneDeep(this.editingData);
    }

    public confirmLoading = false;

    public async confirmEditingData() {
        this.confirmLoading = true;
        const isSuccess = await this.optStore.update2(this.editingData);
        this.confirmLoading = false;
        if (isSuccess) {
            tryRefresh(['opt']);
            this.confirmedData = _.cloneDeep(this.editingData);
        }
        return isSuccess;
    }
    public cancelEditingData() {
        this.editingData = _.cloneDeep(this.confirmedData);
    }

    public editingMap: { [key: string]: boolean } = {};
    get isMultipleEditing() {
        const keys = _.keys(this.editingData);
        const editingKeys = _.filter(keys, key => {
            return !!this.editingMap[key];
        });
        return editingKeys.length >= 2;
    }
    public editAll() {
        const nextEditingMap: { [key: string]: boolean } = {};
        const keys = _.keys(this.confirmedData);
        for (const key of keys) {
            nextEditingMap[key] = true;
        }
        this.editingMap = nextEditingMap;
    }
    public cancelEditingAll() {
        this.editingMap = {};
    }
    public editSomeKey(keys: string[]) {
        const nextEditingMap: { [key: string]: boolean } = _.cloneDeep(
            this.editingMap,
        );
        for (const key of keys) {
            nextEditingMap[key] = true;
        }
        this.editingMap = nextEditingMap;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const genloadingBlock = () => {
    return loadingWrapper(true)(
        <div>
            <div className="opt-parses">
                <div className="step">
                    <div className="title">
                        <span>
                            <span>销售阶段/赢率</span>
                            <span className="desc">
                                除输单外，只能按顺序点击销售阶段变化，不可以操作非相邻的阶段
                            </span>
                        </span>
                    </div>
                    <div className="content">
                        <div style={{ height: 150 }} />
                    </div>
                </div>
            </div>
        </div>,
    );
};

const optParseDefaultActiveKey = ['required-params'];
const ParseTrans: React.FC<{
    defaultStore: OptListStore;
    prepared: boolean;
    drawerVis?: boolean;
}> = ({ defaultStore, prepared, drawerVis = true }) => {
    const { newDataHash, defaultMeta, defaultPerm } = defaultStore;
    const [parseTransStore] = useState(() => new ParseTransStore());

    const {
        editingData,
        editingMap,
        isMultipleEditing,
        stageList,
        stageTexts,
        currentStageIndex,
        confirmedData,
        isDone,
        refreshLoading,
    } = parseTransStore;

    // 获取默认的数据回调
    const onDataChange = getAutoOnChangeHandler(editingData, (data: any) =>
        parseTransStore.setEditingData(data),
    );

    const [badParams, setbadParams] = useState<string[]>([]);
    const [visible, setvisible] = useState(false);
    const [submitStep, setsubmitStep] = useState<number | null>(null);

    useEffect(() => {
        if (defaultStore.mutatingData === null) {
            return;
        }
        runInAction(() => {
            parseTransStore.setOptStore(defaultStore);
            parseTransStore.setEditingData(
                _.cloneDeep(defaultStore.mutatingData),
            );
            parseTransStore.syncEditingData();
            parseTransStore.setInited(true);
            parseTransStore.fetchStageList();
        });
    }, [defaultStore.mutatingData]);

    const [shudanVisible, setShudanVisible] = useState(false);
    const [shudanReason, setShudanRason] = useState('');
    const [shudanType, setShudanType] = useState([]);

    // 回退原因
    const [transReasonCode, setTransReasonCode] = useState<number>();
    const [transReasonText, setTransReasonText] = useState('');
    const [transReasonVisible, setTransReasonVisible] = useState(false);

    // 40商机确认
    const [oppr40Visible, setOppr40Visible] = useState(false);
    const [oppr40Status, setOppr40Status] = useState(1);
    const [oppr40Reason, setOppr40Reason] = useState('');

    // 返回预算说明和是否与对应客户有拜访/视频类型的活动记录
    const { data: stage40Info = {} } = useRequest(
        async () => {
            const [d, e] = await defaultAxios.get(
                '/crm/opportunity/findOpBudgetInfoAndHaveVisited',
                {
                    opId: parseTransStore.confirmedData?.id,
                },
            );
            return d?.data || {};
        },
        {
            ready: !!parseTransStore.confirmedData?.id && drawerVis,
        },
    );

    const basicformformItemOptions = defaultLabelsGenerator(defaultStore);

    // display
    const baiscCellsSelector = defaultCellsSelectorGenerator(defaultStore);

    // 自定义列表
    const { inited: metaInited } = defaultMeta;

    // steps test
    const [active, setActive] = useState(-1);
    const [done, setDone] = useState(true);
    useEffect(() => {
        if (
            currentStageIndex === -1 ||
            currentStageIndex === stageList.length - 1
        ) {
            setDone(true);
            return;
        }
        setDone(false);
        setActive(currentStageIndex);
    }, [currentStageIndex, stageList]);

    const [updateTrigger, setUpdateTigger] = useState(0);
    useEffect(() => {
        if (active === -1 || !stageList[active + 1]) {
            return;
        }
        parseTransStore.fetchStageStatus(stageList[active + 1]);
    }, [active, updateTrigger, defaultStore.dataVer]);

    useEffect(() => {
        const handler = (newData: any) => {
            parseTransStore.fetchStageStatus(stageList[active + 1]);
            if (newData) {
                parseTransStore.setEditingData(newData);
                parseTransStore.syncEditingData();
            }
        };
        defaultStore.defaultEE.on('update-success', handler);
        return () => {
            defaultStore.defaultEE.removeListener('update-success', handler);
        };
    }, [defaultStore, parseTransStore]);

    useRefresh(
        ['parse-trans-refresh'],
        () => {
            parseTransStore.fetchStageStatus(stageList[active + 1]);
        },
        [active],
    );

    const [ssloading, setSSLoading] = useState(false);
    const [showShudan, setShowShudan] = useState(false);

    const [permLess60, setPermLess60] = useState(false);
    const [permMore60, setPermMore60] = useState(false);
    useEffect(() => {
        if (!prepared) {
            return;
        }
        setTimeout(() => {
            const less60Perm = defaultPerm.getPermByTypeAndAction(
                defaultStore.listType,
            )('change-stage-less60').visible;

            const more60Perm = defaultPerm.getPermByTypeAndAction(
                defaultStore.listType,
            )('change-stage-more60').visible;

            setPermLess60(less60Perm);
            setPermMore60(more60Perm);

            if (parseTransStore.isShudan || parseTransStore.isDone) {
                setShowShudan(false);
            } else if (less60Perm) {
                setShowShudan(true);
            }
        }, 100);
    }, [
        parseTransStore.isShudan,
        parseTransStore.isDone,
        parseTransStore.confirmedData,
        prepared,
    ]);

    // 结合插件配置和真实数据
    // 只展示当前阶段的数据
    const currentActiveStage = stageList[active + 1];
    if (!currentActiveStage) {
        return genloadingBlock();
    }
    const fieldWhiteList = currentActiveStage.requiredField;
    const fieldWhiteListMap = arrToJsonPrimary(fieldWhiteList);
    const baiscWidgets = formWidgetsParseTrans(defaultStore).filter(
        item => fieldWhiteListMap[item.key as string],
    );
    embedDataToWidgets<any>(baiscWidgets, editingData);

    const submit = async (submitStep: number, reason?: string) => {
        // 提交这一步，到下一步
        setSSLoading(true);
        const isSuccess = await parseTransStore.postStage(
            stageList[submitStep],
            reason,
        );
        if (isSuccess) {
            const { fieldAuthCode, dataAuthCode } = defaultStore;
            tryRefresh(['opt']);
            await parseTransStore.refresh(fieldAuthCode, dataAuthCode);
        }
        setSSLoading(false);
    };

    if (
        !metaInited ||
        stageList.length === 0 ||
        parseTransStore.editingData === null ||
        !prepared
    ) {
        return genloadingBlock();
    }
    const dataId = parseTransStore.confirmedData?.id;

    const layout = new Array(Math.ceil(baiscWidgets.length / 2)).fill([12, 12]);

    let {
        conditionList,
        conditionMsg,
        fieldList,
        fieldMsg,
    } = parseTransStore.stageStatus;
    conditionList = conditionList || [];
    conditionMsg = conditionMsg || '';
    fieldList = conditionList || [];
    fieldMsg = fieldMsg || '';

    const condiDisplay = !conditionList.length ? null : (
        <>
            <div className="header" style={{ marginTop: 14 }}>
                <span className="title">必需条件</span>
                <span className="error">{conditionMsg}</span>
            </div>
            <div className="condition-display">
                {conditionList.map((str: string) => {
                    return <div className="condition-item">{str}</div>;
                })}
            </div>
        </>
    );

    const form = (
        <div className="form-inner">
            {/* <div>
                {isMultipleEditing ? (
                    <AntButton
                        onClick={() => {
                            runInAction(() => {
                                parseTransStore.confirmEditingData();
                                parseTransStore.cancelEditingAll();
                            });
                        }}
                        type="primary"
                    >
                        确定
                    </AntButton>
                ) : (
                    <AntButton
                        onClick={() => {
                            parseTransStore.editAll();
                        }}
                        type="primary"
                    >
                        编辑所有
                    </AntButton>
                )}
                <AntButton
                    onClick={() => {
                        runInAction(() => {
                            parseTransStore.cancelEditingAll();
                            parseTransStore.cancelEditingData();
                        });
                    }}
                >
                    取消
                </AntButton>
            </div> */}
            {!fieldWhiteList.length ? null : (
                <div className="header">
                    <span className="title">必填字段</span>
                    <span className="error">{fieldMsg}</span>
                </div>
            )}
            <EditableDisplay<any>
                key={'parse-trans-' + dataId}
                data={editingData}
                widgets={baiscWidgets}
                onChange={(key, value, k) => {
                    onDataChange(key, value, k);
                }}
                formItemOptions={basicformformItemOptions}
                layout={layout}
                rowGutter={[0, 0]}
                confirmLoading={parseTransStore.confirmLoading}
                onInteract={async (key, type, value) => {
                    // TODO, 在这里面改变editingMap
                    if (
                        type === 'start-editing-display-param' &&
                        _.isString(key)
                    ) {
                        runInAction(() => {
                            parseTransStore.cancelEditingAll();
                            parseTransStore.cancelEditingData();
                            parseTransStore.editSomeKey([key]);
                        });
                    } else if (type === 'confirm-editing-display-param') {
                        const isSuccess = await parseTransStore.confirmEditingData();
                        if (isSuccess) {
                            parseTransStore.cancelEditingAll();
                            setUpdateTigger(updateTrigger + 1);
                        }
                    } else if (type === 'cancel-editing-display-param') {
                        message.info('变更取消');
                        runInAction(() => {
                            parseTransStore.cancelEditingData();
                            parseTransStore.cancelEditingAll();
                        });
                    }
                }}
                labelWidth={15}
                validateResult={{}}
                cellsSelector={baiscCellsSelector}
                editingMap={editingMap}
                isMultipleEditing={isMultipleEditing}
            ></EditableDisplay>
            {condiDisplay}
        </div>
    );

    return (
        <div
            style={drawerVis ? {} : { visibility: 'hidden' }}
            key={'parse-trans-' + dataId}
        >
            <MutateRequiredParams
                visible={visible}
                badParams={badParams}
                defaultStore={defaultStore}
                psStore={parseTransStore}
                reset={() => {
                    setbadParams([]);
                    setvisible(false);
                    setsubmitStep(null);
                }}
                onOk={() => {
                    if (null !== submitStep) {
                        setbadParams([]);
                        setvisible(false);
                        setsubmitStep(null);
                        submit(submitStep);
                    }
                }}
            />
            <div className="opt-parses">
                <div className="step">
                    <div className="title">
                        <span>
                            <span>销售阶段/赢率</span>
                            <span className="desc">
                                除输单外，只能按顺序点击销售阶段变化，不可以操作非相邻的阶段
                            </span>
                        </span>
                    </div>
                    <div className="content">
                        {refreshLoading ? (
                            <Spin className="loading-icon" />
                        ) : null}
                        <div
                            className="step-container"
                            style={
                                refreshLoading
                                    ? {
                                          opacity: 0.5,
                                          pointerEvents: 'none',
                                      }
                                    : {}
                            }
                        >
                            {/* stageBeforeShudan */}

                            <OptStep
                                key={'parse-trans-' + dataId}
                                items={stageTexts}
                                current={currentStageIndex}
                                active={active}
                                isShudan={parseTransStore.isShudan}
                                onActiveChange={nextActive => {
                                    setActive(nextActive);
                                }}
                                onNextClick={async (submitStep, status) => {
                                    const current = currentStageIndex;
                                    const nextIndex = submitStep;

                                    // 都没有
                                    if (!permLess60 && !permMore60) {
                                        return message.error(
                                            '您没有此项操作权限',
                                        );
                                    }

                                    if (!permMore60) {
                                        // 有permLess60，没有permMore60
                                        if (nextIndex > 2) {
                                            return message.error(
                                                '您没有此项操作权限',
                                            );
                                        }

                                        if (nextIndex <= 2 && current > 2) {
                                            return message.error(
                                                '您没有此项操作权限',
                                            );
                                        }
                                    }

                                    if (!permLess60) {
                                        // 有permMore60，没有permLess60
                                        if (nextIndex < 2) {
                                            return message.error(
                                                '您没有此项操作权限',
                                            );
                                        }
                                    }

                                    if (status === 'before') {
                                        // 40 商机退回 20
                                        if (nextIndex === 0 && current === 1) {
                                            setTransReasonVisible(true);
                                            return;
                                        }
                                        // 回退
                                        Modal.confirm({
                                            title:
                                                '请再次确认是否转移至当前阶段',
                                            okText: '确认',
                                            cancelText: '取消',
                                            onOk() {
                                                submit(submitStep);
                                            },
                                        });

                                        return;
                                    }
                                    if (status === 'done') {
                                        // 回退
                                        Modal.confirm({
                                            title:
                                                '请再次确认是否转移至当前阶段',
                                            okText: '确认',
                                            cancelText: '取消',
                                            onOk() {
                                                submit(submitStep);
                                            },
                                        });

                                        return;
                                    }
                                    if (status === 'todo') {
                                        if (condiDisplay) {
                                            Modal.confirm({
                                                title:
                                                    '转移失败，尚未达到当前条件',
                                                okText: '确认',
                                                cancelText: '取消',
                                                content: condiDisplay,
                                            });
                                            return;
                                        }
                                        if (fieldWhiteList.length) {
                                            const validators = makeValidatorForParseTrans(
                                                defaultStore,
                                                fieldWhiteList,
                                            );
                                            const result = await executeAllValidatorFor(
                                                confirmedData,
                                                validators,
                                                fieldWhiteList,
                                            );
                                            console.log(
                                                'basraewfafa',
                                                validators,
                                                result,
                                                fieldWhiteList,
                                            );
                                            if (!result.isPass) {
                                                const nextBads = _.keys(
                                                    result.result,
                                                ).filter(
                                                    k =>
                                                        result?.result[k]
                                                            ?.status !==
                                                        'success',
                                                );
                                                setbadParams(nextBads);
                                                setvisible(true);
                                                setsubmitStep(submitStep);
                                                return;
                                            }
                                        }
                                        Modal.confirm({
                                            title:
                                                '请再次确认是否转移至当前阶段',
                                            okText: '确认',
                                            cancelText: '取消',
                                            onOk() {
                                                submit(submitStep);
                                            },
                                        });
                                    }
                                }}
                                submitStepLoading={ssloading}
                            />
                            <Modal
                                visible={transReasonVisible}
                                title="请再次确认是否转移至当前阶段"
                                onCancel={() => {
                                    setTransReasonVisible(false);
                                    setTransReasonCode(-1);
                                    setTransReasonText('');
                                }}
                                onOk={() => {
                                    const reason =
                                        transReasonCode === 3
                                            ? transReasonText
                                            : transReasonOptions.find(
                                                  v =>
                                                      v.value ===
                                                      transReasonCode,
                                              )?.label;
                                    console.log('submitStepreason', reason);
                                    if (!reason) {
                                        message.error('请填写退回原因');
                                        return Promise.reject();
                                    } else {
                                        setTransReasonVisible(false);
                                        submit(0, reason);
                                    }
                                }}
                            >
                                <Form className="form">
                                    <Form.Item
                                        {...{
                                            labelCol: {
                                                span: 6,
                                            },
                                            wrapperCol: {
                                                span: 14,
                                            },
                                        }}
                                        required
                                        label="退回原因"
                                    >
                                        <Select
                                            placeholder="请选择原因"
                                            onChange={value => {
                                                console.log('value', value);
                                                setTransReasonCode(
                                                    value as number,
                                                );
                                            }}
                                        >
                                            {transReasonOptions.map(v => (
                                                <Select.Option value={v.value}>
                                                    {v.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    {transReasonCode === 3 && (
                                        <Form.Item
                                            {...{
                                                labelCol: {
                                                    span: 6,
                                                },
                                                wrapperCol: {
                                                    span: 14,
                                                },
                                            }}
                                            required
                                            label="其他原因"
                                        >
                                            <Input.TextArea
                                                placeholder="请输入"
                                                onChange={e => {
                                                    setTransReasonText(
                                                        e.target.value,
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                    )}
                                </Form>
                            </Modal>
                        </div>
                    </div>
                    <div className="shudan-btn">
                        <Modal
                            visible={shudanVisible}
                            title={'输单'}
                            onCancel={() => {
                                setShudanVisible(false);
                                setShudanType([]);
                                setShudanRason('');
                            }}
                            confirmLoading={parseTransStore.shudanLoading}
                            okButtonProps={{ type: 'danger' }}
                            onOk={async () => {
                                if (shudanType?.length === 0) {
                                    return message.error('输单原因必填');
                                }
                                if (!shudanReason) {
                                    return message.error('输单描述必填');
                                }
                                const isSuccess = await parseTransStore.postShudan(
                                    shudanReason,
                                    shudanType,
                                );
                                if (!isSuccess) {
                                    return;
                                }
                                tryRefresh(['opt']);
                                setActive(-1);
                                setDone(true);
                                await parseTransStore.refresh();
                                setShudanVisible(false);
                            }}
                        >
                            <Form className="form" layout={'horizontal'}>
                                <Form.Item
                                    {...formItemLayout}
                                    required
                                    label={
                                        <span className="label">输单原因</span>
                                    }
                                >
                                    <Select
                                        placeholder="输单原因"
                                        onChange={(value: []) => {
                                            setShudanType(value);
                                        }}
                                        mode={'multiple'}
                                    >
                                        {defaultStore.metaloseOrderReasonTypes
                                            .filter((v: ReasonType) => v.choice)
                                            .map((v: ReasonType) => (
                                                <Select.Option value={v.value}>
                                                    {v.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    {...formItemLayout}
                                    required
                                    label={
                                        <span className="label">输单描述</span>
                                    }
                                >
                                    <Input.TextArea
                                        value={shudanReason}
                                        placeholder={'输单描述'}
                                        onChange={e => {
                                            setShudanRason(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                        </Modal>

                        <Modal
                            visible={oppr40Visible}
                            title={'40商机确认'}
                            onCancel={() => {
                                setOppr40Visible(false);
                                setOppr40Status(1);
                                setOppr40Reason('');
                            }}
                            confirmLoading={parseTransStore.shudanLoading}
                            okButtonProps={{ type: 'danger' }}
                            onOk={async () => {
                                if (oppr40Status === 2 && !oppr40Reason) {
                                    return message.error('请填写拒绝原因');
                                }
                                const isSuccess = await parseTransStore.updateStage40(
                                    oppr40Status,
                                    oppr40Reason,
                                );

                                if (!isSuccess) {
                                    return;
                                }

                                message.success('修改40商机状态成功');
                                // 通知活动记录商机赢率更新
                                window?.opener?.postMessage(
                                    {
                                        type: 'opt-stage-update',
                                        payload: {
                                            opportunityId:
                                                parseTransStore.confirmedData
                                                    ?.id,
                                            saleStageId: oppr40Status,
                                            winRate: 40,
                                        },
                                    },
                                    window.location.origin,
                                );

                                tryRefresh(['opt']);
                                tryRefresh(['contract', 'parse-trans-refresh']);
                                await parseTransStore.refresh();
                                setOppr40Visible(false);
                                setOppr40Status(1);
                                setOppr40Reason('');
                            }}
                        >
                            <Form className="form" layout={'horizontal'}>
                                {/* <Alert
                                    message={
                                       
                                    }
                                    type="warning"
                                /> */}
                                <div
                                    style={{
                                        marginBottom: '10px',
                                        padding: '15px 0',
                                        backgroundColor: '#f4f5fa',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <>
                                        <Form.Item
                                            {...formItemLayout}
                                            label={
                                                <span className="label">
                                                    预算说明
                                                </span>
                                            }
                                        >
                                            <p>
                                                {stage40Info.budgetInfo || '-'}
                                            </p>
                                        </Form.Item>
                                        <Form.Item
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                            label={
                                                <span className="label">
                                                    拜访或视频演示
                                                </span>
                                            }
                                        >
                                            <Radio.Group
                                                disabled
                                                value={stage40Info.haveVisited}
                                            >
                                                <Radio value={1}>有</Radio>
                                                <Radio value={0}>没有</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </>
                                </div>

                                <Form.Item
                                    {...formItemLayout}
                                    required
                                    label={
                                        <span className="label">更新状态</span>
                                    }
                                >
                                    <Radio.Group
                                        onChange={({ target }) => {
                                            setOppr40Status(target.value);
                                        }}
                                        value={oppr40Status}
                                    >
                                        <Radio value={1}>确认</Radio>
                                        <Radio value={2}>拒绝</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {oppr40Status === 2 && (
                                    <Form.Item
                                        {...formItemLayout}
                                        required
                                        label={
                                            <span className="label">
                                                拒绝原因
                                            </span>
                                        }
                                    >
                                        <Input.TextArea
                                            value={oppr40Reason}
                                            placeholder="请输入拒绝原因"
                                            onChange={e => {
                                                setOppr40Reason(e.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                )}
                            </Form>
                        </Modal>

                        {parseTransStore.confirmedData.biz_type === 1 &&
                            parseTransStore.confirmedData.win_rate === 40 &&
                            !parseTransStore.confirmedData.stage40_state &&
                            defaultPerm.getPermByTypeAndAction(
                                defaultStore.listType,
                            )('change-stage40-state').visible && (
                                <AntButton
                                    style={{ marginRight: '10px' }}
                                    onClick={() => {
                                        setOppr40Visible(true);
                                    }}
                                >
                                    40商机确认
                                </AntButton>
                            )}

                        {!(
                            parseTransStore.isShudan ||
                            parseTransStore.isDone ||
                            !showShudan
                        ) &&
                            showShudan && (
                                <AntButton
                                    type="danger"
                                    disabled={
                                        parseTransStore.isShudan ||
                                        parseTransStore.isDone ||
                                        !showShudan
                                    }
                                    onClick={() => {
                                        setShudanVisible(true);
                                    }}
                                >
                                    {parseTransStore.isShudan
                                        ? '已输单'
                                        : '输单'}
                                </AntButton>
                            )}
                    </div>
                </div>
                {done ? null : (
                    <div className="form">
                        <AntCollapse
                            collapseConfig={[
                                {
                                    key: 'required-params',
                                    header: <span>推进阶段必须内容</span>,
                                    content: loadingWrapper(
                                        parseTransStore.stageStatusLoading,
                                    )(form),
                                },
                            ]}
                            defaultActiveKey={optParseDefaultActiveKey}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const makeValidatorForParseTrans = (
    defaultStore: OptListStore,
    fields: string[],
) => {
    const ret: { [key in keyof any]?: Validator<any> } = {};
    const mp = arrToJsonPrimary(fields);
    const mts = defaultStore.defaultMeta.objectMeta.filter(
        item => mp[item.fieldName],
    );

    console.log('basraewfafa', '2222', fields, mp, mts);

    for (const fieldItem of mts) {
        const { fieldName } = fieldItem;
        const curValidator = getValidatorByParamMeta(fieldItem, true);
        if (curValidator) {
            ret[fieldName] = curValidator;
        }
    }
    return ret;
};

const FinalParseTrans = memo(observer(ParseTrans));
export default FinalParseTrans;
