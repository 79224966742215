import React, { useMemo } from 'react';
import _ from 'lodash';
import ChartsWrap from '../../components/charts-wrap';
import * as echarts from 'echarts';

type RegionChartsProps = {
    chartsClassName?: string;
    loading?: boolean;
    title: string;
    xAxis: string[];
    dataSource: DataSource[];
};

type DataSource = {
    label: string;
    data: number[];
};

const RegionCharts: React.FC<RegionChartsProps> = ({
    chartsClassName = '',
    loading = false,
    title,
    xAxis = [],
    dataSource = [],
}) => {
    const chartsOptions: echarts.EChartsOption = useMemo(() => {
        const start = Math.floor(((xAxis.length - 6) * 100) / xAxis.length);
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999',
                    },
                },
            },
            legend: {
                bottom: '10%',
                itemWidth: 10,
                itemHeight: 10,
                // itemGap: 20,
            },
            grid: {
                left: '0%',
                right: '0%',
                bottom: '20%',
                width: '100%',
                height: '70%',
                containLabel: true,
            },
            xAxis: [
                {
                    type: 'category',
                    data: xAxis,
                    axisPointer: {
                        type: 'shadow',
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    // name: '业绩目标',
                    splitLine: {
                        lineStyle: {
                            type: 'dashed',
                        },
                    },
                    // min: 0,
                    // max: 250,
                    // interval: 50,
                    // alignTicks: true,
                    axisLabel: {
                        formatter: '{value} 万',
                    },
                },
                {
                    type: 'value',
                    // name: '完成率',
                    // min: 0,
                    // max: 100,
                    // interval: 10,
                    // alignTicks: true,
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                        // formatter: '{value}  %',
                    },
                },
            ],
            series: [
                {
                    name: dataSource[0]?.label,
                    type: 'bar',
                    color: new echarts.graphic.LinearGradient(
                        0,
                        1,
                        0,
                        0,
                        [
                            {
                                offset: 0,
                                color: '#83ABFF', // 0% 处的颜色
                            },
                            {
                                offset: 1,
                                color: '#002BFF', // 100% 处的颜色
                            },
                        ],
                        false,
                    ),
                    itemStyle: {
                        borderRadius: [3, 3, 0, 0],
                    },
                    tooltip: {
                        valueFormatter: (value: any) => {
                            return `${value}万 `;
                        },
                    },
                    label: {
                        show: true,
                        position: 'top',
                    },
                    data: dataSource[0]?.data,
                },
                {
                    name: dataSource[1]?.label,
                    type: 'bar',
                    color: new echarts.graphic.LinearGradient(
                        0,
                        1,
                        0,
                        0,
                        [
                            {
                                offset: 0,
                                color: '#FFEA73', // 0% 处的颜色
                            },
                            {
                                offset: 1,
                                color: '#FF8C00', // 100% 处的颜色
                            },
                        ],
                        false,
                    ),
                    itemStyle: {
                        borderRadius: [3, 3, 0, 0],
                    },
                    tooltip: {
                        valueFormatter: (value: any) => {
                            return `${value}万 `;
                        },
                    },
                    label: {
                        show: true,
                        position: 'top',
                    },
                    data: dataSource[1]?.data,
                },
                {
                    name: dataSource[2]?.label,
                    type: 'line',
                    yAxisIndex: 1,
                    tooltip: {
                        valueFormatter: (value: any) => {
                            return value + ' %';
                        },
                    },
                    color: '#20C7FF',
                    data: dataSource[2]?.data,
                },
                // {
                //     name: dataSource[3]?.label,
                //     type: 'line',
                //     tooltip: {
                //         valueFormatter: (value: any) => {
                //             return `${value}万 `;
                //         },
                //     },
                //     color: '#FF6161',
                //     data: dataSource[3]?.data,
                // },
                // {
                //     name: dataSource[4]?.label,
                //     type: 'line',
                //     tooltip: {
                //         valueFormatter: (value: any) => {
                //             return `${value}万 `;
                //         },
                //     },
                //     color: '#FFDA08',
                //     data: dataSource[4]?.data,
                // },
            ],
            dataZoom: {
                type: 'slider',
                show: true,
                bottom: '4%',
                height: 10,
                start: start > 0 ? start : 0,
                end: 100,
            },
        };
    }, [xAxis, dataSource]);
    return (
        <ChartsWrap
            chartsClassName={chartsClassName}
            loading={loading}
            title={title}
            chartsOptions={chartsOptions}
        />
    );
};

export default RegionCharts;
