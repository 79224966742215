import * as React from 'react';
import { useState, useEffect } from 'react';
import { ICrmTimelineProps, CrmTimeline } from '../../timeline';
import _ from 'lodash';
import ItemUser, { IItemsUserProps } from '../../items/user/index';
import './index.scss';
import { ICustomerActionLogDailyData } from '../../customer-drawer/customer-action-log';
import { ICrmTimelineItemProps } from '../../timeline/timeline-items';
import moment from 'moment';
import { Icon, message, Dropdown, Menu } from 'antd';
import { commonDelete } from '@/api/rest';
import { defaultAxios } from '@/utils';
import { AutoLink } from '../../auto-link';
import { AntButton } from '@/components/antd';
import RecordBtn from './record-btn/index';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

export interface ICustomerDrawerTimeLineItems
    extends ICustomerActionLogDailyData { }
export interface ICustomerDrawerTimelineItemGroupProps {
    day: string;
    timelineItems: ICustomerDrawerTimeLineItems[];
}
export interface ICustomerDrawerTimeLineProps {
    groups: ICustomerDrawerTimelineItemGroupProps[];
    showCount?: number;
    actionLogConfig: {
        arType: any[];
        followType: any[];
    };
    reloadFunc?: () => void;
}
// 生成客户抽屉的time-line
const DrawerTimeLine: React.FC<ICustomerDrawerTimeLineProps> = props => {
    const { showCount, actionLogConfig, reloadFunc } = props;
    const [actionLogAuth, setActionLogAuth] = useState<{
        delete: boolean;
    }>({
        delete: false,
    });
    useEffect(() => {
        fetchActionLogAuth();
    }, []);
    const getRecordFileUrl = async (callFileId: string | number) => {
        const url = '/crm/callRecord/fileLink';
        const [d, e] = await defaultAxios.get(url, {
            callFileId,
        });
        if (d === null || d.data === null) {
            message.error('获取录音失败');
            return false;
        }
        const error = e as Error | null;
        return d.data;
    };
    const fetchActionLogAuth = async () => {
        const url = '/bff/api/rest/perm?mod=sales-follow-up:activity';
        const [d, e] = await defaultAxios.get(url);
        if (d && d.data && d.data?.length) {
            const finalAuth: any = {};
            _.forEach(d.data, item => {
                if (item.key.indexOf('delete') > -1) {
                    if (!item.visible) {
                        finalAuth.delete = false;
                    } else {
                        finalAuth.delete = true;
                    }
                    return;
                }
            });
            setActionLogAuth(finalAuth);
        }
    };
    // 删除活动记录
    const deleteActionLogNet = async (id: string | number) => {
        const [d, e] = await commonDelete('cu_activation_record', [
            id as number,
        ]);
        if (!e) {
            reloadFunc && reloadFunc();
            message.info('删除成功');
            return true;
        }
    };
    // timeline-items生成器
    const makeTimeLineItems: () => ICrmTimelineProps = () => {
        const { groups } = props;
        const ATimelineConfig = _.clone(groups);
        const renderBody = (timelineItems: ICustomerDrawerTimeLineItems) => {
            return (
                <div className="drawer-time-line-body">
                    {/* {timelineItems.current_name && (
                        <div className="liaison">
                            姓名：<em>{timelineItems.current_name}</em>
                        </div>
                    )}
                    {timelineItems.follow_type && (
                        <div className="follow-way">
                            跟进方式：
                            <em>
                                {
                                    _.find(actionLogConfig.followType, item => {
                                        return (
                                            item.value ==
                                            timelineItems.follow_type
                                        );
                                    })?.label
                                }
                            </em>
                        </div>
                    )} */}
                    {timelineItems.contacts_name && (
                        <div className="follow-detail">
                            联系人：<em>{timelineItems.contacts_name}</em>
                        </div>
                    )}
                    {timelineItems.note && (
                        <div className="follow-detail">
                            活动记录内容：
                            <em>
                                <AutoLink text={timelineItems.note} />
                            </em>
                        </div>
                    )}
                    {timelineItems.customer_is_new_record !== null && (
                        <div className="follow-detail">
                            新老客户(活动记录时)：
                            <em>
                                {timelineItems.customer_is_new_record
                                    ? '新客户'
                                    : '老客户'}
                            </em>
                        </div>
                    )}
                    {timelineItems.next_date && (
                        <div className="next-plan">
                            下次跟进时间：
                            <em>
                                {moment(timelineItems.next_date).format(
                                    'YYYY-MM-DD HH:mm:ss',
                                )}
                            </em>
                        </div>
                    )}
                    {timelineItems.next_note && (
                        <div className="follow-detail">
                            下次跟进内容：<em>{timelineItems.next_note}</em>
                        </div>
                    )}
                    {timelineItems.schedule_date && (
                        <div className="next-plan">
                            备忘时间：
                            <em>
                                {moment(timelineItems.schedule_date).format(
                                    'YYYY-MM-DD HH:mm:ss',
                                )}
                            </em>
                        </div>
                    )}
                    {timelineItems.schedule_note && (
                        <div className="follow-detail">
                            备忘内容：<em>{timelineItems.schedule_note}</em>
                        </div>
                    )}
                    {timelineItems.call_file_id && (
                        <RecordBtn call_file_id={timelineItems.call_file_id} />
                    )}
                    <div className="follow-update-time">
                        更新时间：
                        <em>
                            {timelineItems.update_time
                                ? moment(timelineItems.update_time).format(
                                    'YYYY-MM-DD HH:mm:ss',
                                )
                                : '无'}
                        </em>
                    </div>
                    {timelineItems.image && (
                        <div className="photo-provider">
                            <PhotoProvider>
                                {timelineItems.image.split(',').map(item => {
                                    return (
                                        <PhotoView src={item}>
                                            <img
                                                src={item}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                }}
                                            />
                                        </PhotoView>
                                    );
                                })}
                            </PhotoProvider>
                        </div>
                    )}
                </div>
            );
        };
        ATimelineConfig.forEach((dayItems, idx) => {
            dayItems.timelineItems = dayItems.timelineItems.map(
                (timelineItems, idx) => {
                    const ATimelineItems: ICrmTimelineItemProps = {
                        ...timelineItems,
                        content: (
                            <div className="drawer-time-line-content">
                                <div className="drawer-time-line-header">
                                    <ItemUser
                                        avatarConfig={{
                                            src: '',
                                        }}
                                        name={timelineItems.create_user_name}
                                    />
                                    <span className="describe">
                                        发布了
                                        <span
                                            style={{
                                                margin: '0 2px',
                                                color: '#15161f',
                                            }}
                                        >
                                            {_.find(
                                                actionLogConfig.followType,
                                                item => {
                                                    return (
                                                        item.value ==
                                                        timelineItems.follow_type
                                                    );
                                                },
                                            )?.label || '- -'}
                                        </span>
                                        活动记录
                                    </span>
                                    {actionLogAuth.delete ? (
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Menu.Item>
                                                        <div
                                                            onClick={() => {
                                                                deleteActionLogNet(
                                                                    timelineItems.id as string,
                                                                );
                                                            }}
                                                        >
                                                            删除
                                                        </div>
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                            placement={'bottomRight'}
                                        >
                                            <span className="delete">
                                                <Icon type="down" />
                                            </span>
                                        </Dropdown>
                                    ) : null}
                                </div>
                                {renderBody(timelineItems)}
                            </div>
                        ),
                        time: moment(timelineItems.create_time).format('HH:mm'),
                        background: timelineItems?.background || '',
                        icon: <Icon type={timelineItems.icon || ''} /> || '',
                    };
                    return ATimelineItems as ICustomerDrawerTimeLineItems;
                },
            );
        });
        return { groups: ATimelineConfig } as ICrmTimelineProps;
    };

    return <CrmTimeline showCount={showCount} {...makeTimeLineItems()} />;
};

export default DrawerTimeLine;
