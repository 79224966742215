import React, { useState } from 'react';
import {
    DTable,
    isMultipleCell,
    ICell,
    makeObjColClickable,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ContractInvoiceApplyListStore } from '../store';
import { runInAction } from 'mobx';
import { applyCustomConfToCellsSelector } from '@/components/common/form/advanced-filters';
import { message } from 'antd';
import { useContractDrawerSnippets } from '@/pages/om/contract/list/drawer/hooks';

const DemoTable: React.FC<{
    defaultStore: ContractInvoiceApplyListStore;
    loading: boolean;
    reload?: any;
}> = ({ defaultStore, loading, reload }) => {
    const [contractDrawerLid, setContractDrawerCid] = useState(undefined);
    const [contractDrawerVis, setContractDrawerVis] = useState(false);
    const [____, cusContractSnippet] = useContractDrawerSnippets({
        drawerVis: contractDrawerVis,
        setDrawerVis: setContractDrawerVis,
        itemId: contractDrawerLid,
    });

    const dataTable = (
        <>
            {cusContractSnippet}
            <DTable
                sceneCode={'crm_contract'}
                onColumnsUpdated={() => reload && reload()}
                defaultStore={defaultStore}
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [];

                    props.onAction = (key, data) => {
                        if (key === 'see-equity-detail') {
                            // 其他动作
                            runInAction(() => {
                                defaultStore.setAction('see-equity-detail');
                                defaultStore.setMutatingData(data);
                            });
                        }
                        if (_.startsWith(key, 'reveal-obj/')) {
                            // 关联对象都可以点击
                            if (key.indexOf('/contract_id') !== -1) {
                                setContractDrawerCid(
                                    data.contract_id?.originalValue,
                                );
                                setContractDrawerVis(true);
                            }
                        }
                    };

                    props.closeRowSelection = true;
                    props.widthInfoKey = 'contract-invoice-apply-list-default';
                    return props;
                }}
                cellsSelectorTransformer={baiscCellsSelector => {
                    makeObjColClickable(baiscCellsSelector, 'contract_id');

                    return baiscCellsSelector;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
