import React, { useState, useEffect, memo } from 'react';

import { JobBaseStore } from '../../../../utils';
import { Modal, Form, Alert, message } from 'antd';
import _ from 'lodash';
import { WidgetSelect } from '@/components/common/form/widgets';
import TextArea from 'antd/lib/input/TextArea';
import './cancel.scss';
import { runInAction } from 'mobx';
import { loadingWrapper, MOpNameDisplayContainer } from '@/components';
import { observer } from 'mobx-react-lite';
import { tryRefresh } from '@/utils';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
export const ActionCancel: React.FC<{
    defaultStore: JobBaseStore;
}> = observer(({ defaultStore }) => {
    const { mutatingData, mutatingDataLoading } = defaultStore;
    const [remark, setRemark] = useState('');

    const resetForm = () => {
        setRemark('');
    };

    const visible = defaultStore.actionType === 'cancel';
    return (
        <Modal
            visible={visible}
            title={'取消任务'}
            onCancel={() => {
                defaultStore.resetAction();
            }}
            onOk={async () => {
                const ok = await defaultStore.cancel(remark);
                if (ok) {
                    defaultStore.resetAction();
                    tryRefresh(['task_activation']);
                }
            }}
        >
            {loadingWrapper(mutatingDataLoading)(
                <>
                    <p>是否确认取消该任务？</p>
                    <div className="crm-cus-trans-job-cancel">
                        <div className="form-wrapper">
                            <Form className="form" layout={'horizontal'}>
                                {/* <Form.Item
                                {...reasonHelps}
                                {...formItemLayout}
                                required
                                label={
                                    <span className="label">退回原因</span>
                                }
                            >
                                <WidgetSelect
                                    value={reason}
                                    options={{
                                        placeholder: '请选择退回原因',
                                        options: reasonOptions.map(
                                            (option: any) => ({
                                                name:
                                                    option.constraintLabel,
                                                value:
                                                    option.constraintValue,
                                                disabled:
                                                    option.constraintValue ===
                                                    4,
                                            }),
                                        ),
                                    }}
                                    onChange={value => {
                                        setReason(value);
                                        setRequiredDetected(false);
                                    }}
                                />
                            </Form.Item> */}
                                <Form.Item
                                    {...formItemLayout}
                                    label={
                                        <span className="label">取消原因</span>
                                    }
                                >
                                    <TextArea
                                        placeholder="取消原因"
                                        value={remark}
                                        onChange={e => {
                                            const value = e?.target?.value;
                                            if (!_.isNil(value)) {
                                                setRemark(value);
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </>,
            )}
        </Modal>
    );
});
