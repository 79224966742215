import { IMOpResult } from '@/components';
import { runInAction, makeAutoObservable } from 'mobx';

export class MSelectStore {
    // 多选
    public mSeletedData: Array<any> = [];
    get isMSelectionMode() {
        return this.mSeletedData.length > 0;
    }
    public setMSelectedData = (nextData: Array<any>) => {
        this.mSeletedData = nextData;
    };
    public removeAllSelectedData = () => {
        this.mSeletedData = [];
    };
    // 批量操作
    public mopResultVisiable = false;
    public mopResultTitle = '';
    public mopResult: null | IMOpResult = null;
    public setMopResult(title: string, result: IMOpResult) {
        this.mopResultTitle = title;
        this.mopResult = result;
        this.mopResultVisiable = true;
    }
    public resetMopResult() {
        this.mopResultVisiable = false;
        setTimeout(() => {
            runInAction(() => {
                this.mopResultTitle = '';
                this.mopResult = null;
            });
        }, 300);
    }

    constructor() {
        makeAutoObservable(this);
    }
}
