import React from 'react';
import { getCommonStatus, ICommonProps } from './common';
import { InputValueType } from './input';
import _ from 'lodash';
import { Button, Icon } from 'antd';
import Search, { SearchProps } from 'antd/lib/input/Search';

export type SearchInputValue = InputValueType;

export interface ISearchInputOptions extends SearchProps {}

export interface ISearchInputProps<T = any> extends ICommonProps<T> {
    value: SearchInputValue;
    options: ISearchInputOptions;
    onChange: (value: SearchInputValue) => void;
}

export const WidgetSearchInput: React.FC<ISearchInputProps> = ({
    value,
    options,
    onChange,
    status,
    onInteract = key => void 0,
    data,
    statusExtraData,
}) => {
    const { loading, disabled } = getCommonStatus(
        status,
        data,
        statusExtraData,
    );
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }
    if (!_.isNil(loading)) {
        options.loading = loading;
    }

    return (
        <Search
            value={value}
            onSearch={() => onInteract('search')}
            onBlur={() => {
                onInteract('blur');
                onInteract('validate-instantly');
            }}
            onFocus={() => {
                onInteract('focus');
                onInteract('remove-validate-status');
            }}
            onChange={e => {
                if (e && e.target) {
                    onChange(e.target.value);
                }
            }}
            {...options}
        />
    );
};
