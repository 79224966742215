import { makeAutoObservable } from 'mobx';
import { createDefaultInstanceGetter } from '../utils/index';
import axios from 'axios';
import { message } from 'antd';
import { navigate } from '@reach/router';
import Config from '@/configs/default';
import { goToLogin } from '../../utils/helpers';
import _ from 'lodash';

export type ICustomerSettingInfoType = {
    name?: string;
    avatar?: string;
    union_id?: string;
    id?: string;
    phone?: string | number;
    [key: string]: any;
}[];
export interface IDepartSettingInfoType {
    pId?: string | number; // pid 对应 parent_depart_id
    value?: string | number; // value 对应 id
    title?: string | number; // title 对应 depart_name
    isLeaf?: boolean;
    id?: string | number; // id
    depart_code?: string | number; // 部门编码
    depart_name?: string | number; // 部门名称
    parent_depart_id?: string | number; // 上级部门
    depart_type?: number; // 部门类型
    special_type?: number; // 部门分类
    area_admin?: number; // 部门负责人
    [key: string]: any;
}

export interface IAreaInfoType {
    dictCity: ICityInfoType[];
    dictDistrict: IDistrictInfoType[];
    dictState: IStateInfoType[];
    stateRelation: IStateRelationType[];
}
export interface IDistrictInfoType {
    city_id: number;
    district_name: string;
    id: number;
}
export interface ICityInfoType {
    city_name: string;
    id: number;
    state_id: number;
}
export interface IStateInfoType {
    state_name: string;
    id: number;
}
export interface IStateRelationType {
    id: string | number;
    name: string;
    citys: {
        id: string | number;
        name: string;
        state_id: string | number;
        districts: {
            id: string | number;
            name: string;
            city_id: string | number;
        }[];
    }[];
}
export class SettingStore {
    public isCustomerInfoLoading: boolean = true; // 正在init可选择的用户数据
    public customerInfo: ICustomerSettingInfoType = []; // 可选择的用户
    public isDepartInfoLoading: boolean = true; // 正在init可选择的部门数据
    public departInfo: IDepartSettingInfoType[] = [];
    public isAreaInfoLoading: boolean = true; // 正在init省市区信息
    public areaInfo: IAreaInfoType = {
        dictCity: [],
        dictDistrict: [],
        dictState: [],
        stateRelation: [],
    }; // 省市区信息
    constructor() {
        makeAutoObservable(this);
    }
    public getAreaInfo = async () => {
        this.isAreaInfoLoading = true;
        axios({
            url: '/bff/api/rest/init-data/all-area-info',
            method: 'get',
            withCredentials: true,
        })
            .then(res => {
                this.isAreaInfoLoading = false;
                if (res) {
                    this.areaInfo = res?.data?.data || {
                        dictCity: [],
                        dictDistrict: [],
                        dictState: [],
                        stateRelation: [],
                    };
                } else {
                }
            })
            .catch(error => {
                // node反错
                this.isAreaInfoLoading = false;
                // if (error.response && error.response.status) {
                // if (
                //     error.response.status === 401 ||
                //     error.response.status === 403
                // ) {
                //     if (autoJump) {
                //         goToLogin();
                //     }
                // }
                // } else {
                message.error('请求失败，请检查网络连接后重试');
                // }
            });
    };
    public getDepartInfo = async (autoJump?: boolean) => {
        this.isDepartInfoLoading = true;
        axios({
            url: '/bff/api/rest/init-data/all-depart',
            method: 'get',
            withCredentials: true,
        })
            .then(res => {
                this.isDepartInfoLoading = false;
                if (res) {
                    this.departInfo = res?.data?.data || [];
                } else {
                }
            })
            .catch(error => {
                // node反错
                this.isDepartInfoLoading = false;
                if (error.response && error.response.status) {
                    if (
                        error.response.status === 401 ||
                        error.response.status === 403
                    ) {
                        if (autoJump) {
                            goToLogin();
                        }
                    }
                } else {
                    message.error('请求失败，请检查网络连接后重试');
                }
            });
    };
    public getCustomerInfo = async (autoJump: boolean = true) => {
        this.isCustomerInfoLoading = true;
        axios({
            url: '/bff/api/rest/init-data/sys-custom-list',
            method: 'get',
            withCredentials: true,
        })
            .then(res => {
                this.isCustomerInfoLoading = false;
                if (res) {
                    this.customerInfo = res?.data?.data || [];
                } else {
                }
            })
            .catch(error => {
                // node反错
                this.isCustomerInfoLoading = false;
                if (error.response && error.response.status) {
                    if (
                        error.response.status === 401 ||
                        error.response.status === 403
                    ) {
                        if (autoJump) {
                            goToLogin();
                        }
                    }
                } else {
                    message.error('请求失败，请检查网络连接后重试');
                }
            });
    };
}

export const getSettingDefaultStore = createDefaultInstanceGetter(SettingStore);
