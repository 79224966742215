import React, { useMemo, useState, useEffect } from 'react';

import { ContractListStore } from '../store';
import { Modal, Descriptions, Select, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { arrToJson, defaultAxios, tryRefresh } from '@/utils';
import _ from 'lodash';
import { useRequest } from 'ahooks';

const { Option } = Select;

// 合同类型标记
export const fetchContractTabType: (
    id?: number,
    type?: number,
) => Promise<boolean> = async (id, type) => {
    if (!id || !type) {
        return false;
    }
    const [d, e] = await defaultAxios.post('/crm/contract/tab/type', {
        id,
        contract_tab_type: type,
    });
    if (d === null || d.code !== '0') {
        return false;
    }
    return true;
};

export const ActionMark: React.FC<{
    defaultStore: ContractListStore;
}> = observer(({ defaultStore }) => {
    const [selectedId, setSelectedId] = useState<number | undefined>();

    const { mutatingData, defaultMeta } = defaultStore;
    const objectMetaMap = arrToJson(defaultMeta.objectMeta, 'fieldName');

    const visible = defaultStore.actionType === 'contract_tab_type';

    const options = useMemo(() => {
        return (
            objectMetaMap['contract_tab_type']?.newFieldMetaItem?.options || []
        );
    }, [objectMetaMap]);

    const { loading, runAsync: submit } = useRequest(
        () => {
            return fetchContractTabType(mutatingData.id, selectedId);
        },
        { manual: true },
    );

    // 回填
    useEffect(() => {
        if (mutatingData?.contract_tab_type && visible) {
            setSelectedId(mutatingData?.contract_tab_type);
        }
    }, [visible]);

    const handleChange = (e: any) => {
        setSelectedId(e);
    };

    if (mutatingData === null) {
        return null;
    }
    return (
        <>
            <Modal
                visible={visible}
                title={'合同标记'}
                onCancel={() => {
                    defaultStore.resetAction();
                    setSelectedId(undefined);
                }}
                style={{ width: 400 }}
                onOk={async () => {
                    if (!selectedId) {
                        message.warn('请选择合同类型标记');
                        return;
                    }
                    submit().then(success => {
                        if (success) {
                            message.success('合同标记成功');

                            defaultStore.resetAction();
                            tryRefresh(['contract']);
                        }
                    });
                }}
                confirmLoading={loading}
            >
                <Descriptions>
                    <Descriptions.Item label="合同名称" span={3}>
                        {mutatingData.contract_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="合同编号" span={3}>
                        {mutatingData.contract_code}
                    </Descriptions.Item>
                    <Descriptions.Item label="客户" span={3}>
                        {mutatingData.customer_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="标记为" span={3}>
                        <Select
                            placeholder="合同标记类型"
                            style={{ width: 220 }}
                            onChange={handleChange}
                            value={selectedId}
                        >
                            {options.map((item: any) => (
                                <Option key={item.value} value={item.value}>
                                    {item.label}
                                </Option>
                            ))}
                        </Select>
                    </Descriptions.Item>
                </Descriptions>
            </Modal>
        </>
    );
});
