import { makeAutoObservable } from 'mobx';
import { createDefaultInstanceGetter } from '@/stores/utils';
import { defaultAxios } from '@/utils';
export interface IMemberConfigDataType {
    talent_lib_version: {
        name: string;
        id: number | string;
    }[];
}
export class MemberStore {
    public configData: IMemberConfigDataType = {
        talent_lib_version: [],
    };
    public memberConfigLoading = false;
    public fetchConfigData = async () => {
        this.memberConfigLoading = true;
        const url = '/bff/api/rest/member-list/config';
        const [d, e] = await defaultAxios.get(url);
        this.memberConfigLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        if (data) {
            this.configData = data;
        }
        const error = e as Error | null;
        return [data, error];
    };
    constructor() {
        makeAutoObservable(this);
    }
}

export const getDefaultMemberStore = createDefaultInstanceGetter(MemberStore);
