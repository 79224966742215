import React, { useState, useContext, useEffect } from 'react';
import './index.scss';
import { FormLV2, IItemOptionItem, embedDataToWidgets } from '@/components';
import { IOPMTableData } from '../store';
import moment from 'moment';
import _ from 'lodash';
import { IActionWidgetItem } from '../../../../components/common/form/level2';
import {
    IWidgetItem,
    getAutoOnChangeHandlerOriginal,
} from '../../../../components/common/form/level1';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { message } from 'antd';
import { transformAllArrayToString } from '../../../../utils/helpers';
import { OPMEE } from '@/utils';

const regEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
const regPhone = /^[1][0-9]{10}$/;

const salesLevelMap = [
    {
        value: 'S1',
        key: 'S1',
    },
    {
        value: 'S2',
        key: 'S2',
    },
    {
        value: 'S3',
        key: 'S3',
    },
    {
        value: 'S4',
        key: 'S4',
    },
    {
        value: 'S5',
        key: 'S5',
    },
    {
        value: 'S6',
        key: 'S6',
    },
    {
        value: 'K1',
        key: 'K1',
    },
    {
        value: 'K2',
        key: 'K2',
    },
    {
        value: 'K3',
        key: 'K3',
    },
    {
        value: 'K4',
        key: 'K4',
    },
    {
        value: 'K5',
        key: 'K5',
    },
    {
        value: 'K6',
        key: 'K6',
    },
    {
        value: 'K1-1',
        key: 'K1-1',
    },
    {
        value: 'K1-2',
        key: 'K1-2',
    },
    {
        value: 'K1-3',
        key: 'K1-3',
    },
    {
        value: 'K1-4',
        key: 'K1-4',
    },
    {
        value: 'K2-1',
        key: 'K2-1',
    },
    {
        value: 'K2-2',
        key: 'K2-2',
    },
    {
        value: 'K2-3',
        key: 'K2-3',
    },
    {
        value: 'K2-4',
        key: 'K2-4',
    },
    {
        value: 'K3-1',
        key: 'K3-1',
    },
    {
        value: 'K3-2',
        key: 'K3-2',
    },
    {
        value: 'K3-3',
        key: 'K3-3',
    },
    {
        value: 'K3-4',
        key: 'K3-4',
    },
    {
        value: 'K4',
        key: 'K4',
    },
];
interface IOPMDrawerProps {
    selectedData?: any;
    drawerVis: boolean;
    hideDrawer: () => void;
}
const OPMDrawer: React.FC<IOPMDrawerProps> = props => {
    const { selectedData = {}, drawerVis, hideDrawer } = props;
    const isAdd = !selectedData.id;
    const [drawerData, setDrawerData] = useState<IOPMTableData>({
        name: '',
        personalEmail: '',
        phone: '',
        status: 1,
        dimDepart: '',
        dimDepartName: '',
        parent_depart: '',
        rankId: '',
        dataRoleList: [],
        funRoleList: [],
        userLevel: '',
        quitAt: '',
        joinAt: '',
        appraiseAt: '',
        positiveAt: '',
        avatar: '',
    });
    useEffect(() => {
        // 如果这里不拦截，会直接死循环
        if (selectedData.dimDepart) {
            const cloneSelectedData = _.cloneDeep(selectedData);
            const tempSelectedData: any = {
                ...cloneSelectedData,
                dataRoleList: !cloneSelectedData.dataRoleList
                    ? []
                    : typeof cloneSelectedData.dataRoleList === 'string'
                    ? cloneSelectedData.dataRoleList.split(',')
                    : cloneSelectedData.dataRoleList,
                funRoleList: !cloneSelectedData.funRoleList
                    ? []
                    : typeof cloneSelectedData.funRoleList === 'string'
                    ? cloneSelectedData.funRoleList.split(',')
                    : cloneSelectedData.funRoleList,
            };
            setDrawerData(tempSelectedData as IOPMTableData);
        }
    }, [selectedData]);
    // 获取默认的数据回调
    const onDataChange = (
        key: keyof IOPMTableData,
        value: any,
        widget: any,
    ) => {
        console.log(key, value, widget);
        console.log(drawerData);
        // if (key === ) {
        // } else {
        getAutoOnChangeHandlerOriginal(drawerData, setDrawerData)(
            key,
            value,
            widget,
        );
        // }
    };
    // const onDataChange = () => {
    //     getAutoOnChangeHandler(drawerData, setDrawerData)
    // };
    const clearDrawerData = () => {
        setDrawerData({
            name: '',
            personalEmail: '',
            phone: '',
            status: 1,
            dimDepart: '',
            dimDepartName: '',
            parent_depart: '',
            rankId: '',
            dataRoleList: [],
            funRoleList: [],
            userLevel: '',
            positiveAt: '',
            quitAt: '',
            joinAt: '',
            appraiseAt: '',
            avatar: '',
        });
    };
    const store = useContext(StoreContext);
    const settingStore = store.getSettingDefaultStore();
    const OPMStore = store.getDefaultOPMStore();
    const FRMStore = store.getDefaultFRMStore();
    const DRMStore = store.getDefaultDRMStore();
    const { updateOrAddPerson, defaultOPMEE } = OPMStore;
    const { FRMRoleListData } = FRMStore;
    const { DRMRoleListData } = DRMStore;
    const { isCustomerInfoLoading, customerInfo, departInfo } = settingStore;
    const widgets: Array<IWidgetItem<IOPMTableData>> = [
        {
            key: 'personalEmail',
            widgetKey: 'input',
            inputOptions: {
                maxLength: 30,
                width: '100%',
                placeholder: '请输入公司邮箱，限制30个字',
            },
        },
        {
            key: 'name',
            widgetKey: 'input',
            inputOptions: {
                maxLength: 20,
                placeholder: '请输入员工姓名，限制20个字',
            },
        },
        {
            key: 'phone',
            widgetKey: 'input',
            inputOptions: {
                placeholder: '请输入员工手机号码',
            },
        },
        {
            key: 'dimDepart',
            widgetKey: 'teamSelector',
            teamSelectorOptions: {
                multiple: false,
                treeCheckable: false,
                treeData: departInfo,
                showCheckedStrategy: 'SHOW_PARENT',
                // treeCheckStrictly: true,
                onChange: v => {
                    console.log(v);
                },
            },
        },
        {
            key: 'managerId',
            widgetKey: 'userSelector',
            userSelectorOptions: {
                userDocs: customerInfo as any,
                mode: 'default',
            },
            status: {
                loading: isCustomerInfoLoading,
            },
        },
        {
            key: 'userLevel',
            widgetKey: 'select',
            selectOptions: {
                options: _.map(salesLevelMap, d => {
                    return {
                        name: d.key,
                        value: d.value,
                    };
                }),
            },
        },
        {
            key: 'rankId',
            widgetKey: 'radio',
            radioOptions: {
                className: 'opm-radio-group',
                options: [
                    {
                        label: '其他',
                        value: '0',
                    },
                    {
                        label: 'KA销售',
                        value: '1',
                    },
                    {
                        label: 'DKA销售',
                        value: '7',
                    },
                    {
                        label: 'SKA销售',
                        value: '8',
                    },
                    {
                        label: '小组长',
                        value: '9',
                    },
                    {
                        label: '总部运营',
                        value: '10',
                    },
                    {
                        label: '区域运营BP',
                        value: '11',
                    },
                    {
                        label: '清洗客服',
                        value: '12',
                    },
                    {
                        label: '精英销售',
                        value: '2',
                    },
                    {
                        label: '小组TL',
                        value: '3',
                    },
                    {
                        label: '城市负责人',
                        value: '4',
                    },
                    {
                        label: '区域负责人',
                        value: '5',
                    },
                    {
                        label: '商业化负责人',
                        value: '6',
                    },
                    // {
                    //     label: '区域负责人',
                    //     value: 5,
                    // },
                    // {
                    //     label: '商业化负责人',
                    //     value: 6,
                    // },
                ],
            },
        },
        {
            key: 'status',
            widgetKey: 'radio',
            radioOptions: {
                className: 'opm-radio-group',
                options: [
                    {
                        label: '在职',
                        value: 1,
                    },
                    {
                        label: '禁用',
                        value: 3,
                    },
                    {
                        label: '离职',
                        value: 2,
                        disabled: true,
                    },
                ],
            },
        },
        {
            key: 'avatar',
            widgetKey: 'upload',
            uploadOptions: {
                single: true,
                otherUse: true,
                lint:
                    '请上传5M以内2寸白底免冠证件照,支持文件格式为jpg,png,jpeg',
                fileExtWhitelist: ['jpg', 'png', 'jpeg'],
                limit: 5 * 1024 * 1024,
                overlimitLint: '照片大小不能超过5M',
            },
        },
        {
            key: 'dataRoleList',
            widgetKey: 'select',
            selectOptions: {
                mode: 'multiple',
                optionFilterProp: 'label',
                options: _.map(DRMRoleListData, d => {
                    return {
                        name: d?.dataRoleName || '',
                        value: d?.id || '',
                    };
                }),
            },
        },
        {
            key: 'funRoleList',
            widgetKey: 'select',
            selectOptions: {
                mode: 'multiple',
                optionFilterProp: 'label',
                options: _.map(FRMRoleListData, d => {
                    return {
                        name: d?.functionRoleName || '',
                        value: d?.id || '',
                    };
                }),
            },
        },
        {
            key: 'positiveAt',
            widgetKey: 'datetime',
            datetimeOptions: {
                placeholder: '请选择转正时间',
            },
        },
        {
            key: 'quitAt',
            widgetKey: 'datetime',
            datetimeOptions: {
                placeholder: '请选择离职时间',
            },
        },
        {
            key: 'appraiseAt',
            widgetKey: 'datetime',
            datetimeOptions: {
                placeholder: '请选择考核时间',
            },
        },
        {
            key: 'joinAt',
            widgetKey: 'datetime',
            datetimeOptions: {
                placeholder: '请选择入职时间',
            },
        },
    ];
    // 灌数据
    embedDataToWidgets<IOPMTableData>(widgets, drawerData);
    const actionWidgets: Array<IActionWidgetItem<IOPMTableData, {}>> = [
        {
            key: 'submit',
            option: {
                btnText: '提交',
                type: 'primary',
            },
        },
        {
            key: 'cancel',
            option: {
                btnText: '取消',
                type: 'default',
                clickActionType: 'cancel',
            },
        },
    ];
    const formItemOptions: Array<IItemOptionItem<IOPMTableData>> = [
        {
            key: 'personalEmail',
            label: '公司邮箱',
            required: true,
        },
        {
            key: 'name',
            label: '姓名',
            required: true,
        },
        {
            key: 'phone',
            label: '手机号码',
            required: true,
        },
        {
            key: 'dimDepart',
            label: '所属部门',
            required: true,
        },
        {
            key: 'managerId',
            label: '直属上级',
            required: true,
        },
        {
            key: 'rankId',
            label: '职级',
            required: true,
        },
        {
            key: 'userLevel',
            label: '销售级别',
        },
        {
            key: 'status',
            label: '状态',
            required: true,
        },
        {
            key: 'avatar',
            label: '头像',
        },
        {
            key: 'dataRoleList',
            label: '数据角色',
            // required: true,
        },
        {
            key: 'funRoleList',
            label: '功能角色',
            // required: true,
        },
        {
            key: 'positiveAt',
            label: '转正时间',
        },
        {
            key: 'quitAt',
            label: '离职时间',
        },
        {
            key: 'appraiseAt',
            label: '考核时间',
        },
        {
            key: 'joinAt',
            label: '入职时间',
        },
    ];

    const validators: {
        [key in keyof IOPMTableData]?: Validator<IOPMTableData>;
    } = {
        personalEmail: datam => {
            if (!_.trim(datam.personalEmail)) {
                return {
                    status: 'fail',
                    msg: '邮箱不能为空',
                };
            } else {
                if (!regEmail.test(datam.personalEmail)) {
                    // 验证邮箱
                    return {
                        status: 'fail',
                        msg: '邮箱格式不正确',
                    };
                }
                return {
                    status: 'success',
                    msg: '',
                };
            }
        },
        name: datam => {
            if (!_.trim(datam.name as string)) {
                return {
                    status: 'fail',
                    msg: '姓名不能为空',
                };
            } else {
                return {
                    status: 'success',
                    msg: '',
                };
            }
        },
        phone: datam => {
            const phone = datam.phone;
            if (!_.trim(phone as string)) {
                return {
                    status: 'fail',
                    msg: '手机号码不能为空',
                };
            } else {
                if (!regPhone.test(phone)) {
                    return {
                        status: 'fail',
                        msg: '手机号码格式不正确',
                    };
                }
                return {
                    status: 'success',
                    msg: '',
                };
            }
        },
        managerId: datam => {
            if (!_.trim(datam.managerId as string)) {
                return {
                    status: 'fail',
                    msg: '直属上级不能为空',
                };
            } else {
                if (!isAdd) {
                    if (datam.managerId == selectedData.id) {
                        return {
                            status: 'fail',
                            msg: '直属上级不能为被修改人员自身',
                        };
                    }
                }
                return {
                    status: 'success',
                    msg: '',
                };
            }
        },
        rankId: datam => {
            if (!datam.rankId) {
                return {
                    status: 'fail',
                    msg: '职级不能为空',
                };
            } else {
                return {
                    status: 'success',
                    msg: '',
                };
            }
        },
        status: datam => {
            if (!datam.status) {
                return {
                    status: 'fail',
                    msg: '状态不能为空',
                };
            } else {
                return {
                    status: 'success',
                    msg: '',
                };
            }
        },
        // dataRoleList: datam => {
        //     if (datam.dataRoleList.length === 0) {
        //         return {
        //             status: 'fail',
        //             msg: '数据角色不能为空',
        //         };
        //     } else {
        //         return {
        //             status: 'success',
        //             msg: '',
        //         };
        //     }
        // },
        // funRoleList: datam => {
        //     if (datam.funRoleList.length === 0) {
        //         return {
        //             status: 'fail',
        //             msg: '功能角色不能为空',
        //         };
        //     } else {
        //         return {
        //             status: 'success',
        //             msg: '',
        //         };
        //     }
        // },
    };
    const onOPMDrawerActionDispatch = async (
        actionKey: string,
        data: IOPMTableData,
        isPass: boolean,
    ) => {
        switch (actionKey) {
            case 'submit':
                if (isPass) {
                    // let avatarUrl;
                    // if (data.avatar) {
                    //     avatarUrl = JSON.parse(data.avatar)?.[0]?.url;
                    // }
                    const payloadData: any = {
                        id: data.id || '',
                        name: data.name,
                        dimDepart: data.dimDepart,
                        personalEmail: data.personalEmail,
                        phone: data.phone,
                        rankId: data.rankId,
                        managerId: data.managerId,
                        status: data.status,
                        dataRoleList: data.dataRoleList,
                        funRoleList: data.funRoleList,
                        userLevel: data.userLevel,
                        positiveAt: data.positiveAt,
                        quitAt: data.quitAt,
                        avatar: data.avatar,
                        joinAt: data.joinAt,
                        appraiseAt: data.appraiseAt,
                    };
                    const [d, e] = await updateOrAddPerson({ ...payloadData });
                    if (d && !d?.msg) {
                        message.success(`${isAdd ? '新增' : '修改'}成功`, 1);
                        OPMEE.emit('update-suc');
                        // defaultOPMEE.emit('update_suc');
                        hideDrawer();
                        clearDrawerData();
                    } else {
                        message.error(`${isAdd ? '新增' : '修改'}失败`, 1);
                    }
                } else {
                    message.error('表单填写不正确，请检查后提交');
                }
                break;
            case 'cancel':
                hideDrawer();
                clearDrawerData();
                break;
            default:
                break;
        }
    };
    return (
        <FormLV2<IOPMTableData>
            data={drawerData}
            onChange={onDataChange}
            widgets={widgets}
            actionWidgets={actionWidgets}
            validators={validators}
            validateMode="instantly"
            container={'drawer'}
            onAction={onOPMDrawerActionDispatch}
            formItemOptions={formItemOptions}
            containerDrawerProps={{
                visible: drawerVis,
                title: `${isAdd ? '添加' : '修改'}人员`,
                onClose: () => {
                    hideDrawer();
                    clearDrawerData();
                },
                destroyOnClose: true,
                width: '35%',
                className: 'opm-drawer-content',
            }}
        />
    );
};

export default observer(OPMDrawer);
