import React, { useState } from 'react';
import { IWidgetItem, IActionWidgetItem, IItemOptionItem } from '@/components';
import _ from 'lodash';
import { toJS } from 'mobx';

export interface IMemberSettingData {
    description: string | any;
    dataDefaultAdmin: (string | any)[] | string | any;
    isHighValue: string | any;
    dataAdmin: (string | any)[];
    teamUsers: (string | any)[];
    teamDeparts: (string | any)[];
    autoDistributUsers: (string | any)[];
}

export interface IMemberExtraData {
    memberOptionsDataLoading: boolean;
}

export const widgetsMemberSetting: (
    customInfo: any,
    departInfo: any,
    data: any,
    isAdd: boolean,
) => Array<IWidgetItem<IMemberSettingData, IMemberExtraData>> = (
    customInfo,
    departInfo,
    data,
    isAdd,
) => {
    const selectedDataAdmin = data?.dataAdmin || []; // 已选择的分组管理员
    // 找到源数据
    const selectedDataAdminItems = _.map(selectedDataAdmin, item => {
        return _.find(customInfo, custom => {
            // tslint:disable-next-line:triple-equals
            return item == custom.id;
        });
    });
    const widgets: Array<IWidgetItem<IMemberSettingData, IMemberExtraData>> = [
        {
            key: 'description',
            widgetKey: 'input',
            formItemClassName: 'member-items',
            inputOptions: {
                style: {
                    width: '300px',
                },
            },
            status: {
                disabled: !isAdd,
            },
        },
        {
            key: 'dataAdmin',
            widgetKey: 'userSelector',
            formItemClassName: 'member-items',
            status: {
                loading: (data, extra) => {
                    return extra.memberOptionsDataLoading;
                },
            },
            userSelectorOptions: {
                userDocs: customInfo,
                mode: 'multiple',
                style: {
                    width: '300px',
                },
            },
        },
        {
            key: 'dataDefaultAdmin',
            widgetKey: 'userSelector',
            formItemClassName: 'member-items',
            status: {
                loading: (data, extra) => {
                    return extra.memberOptionsDataLoading;
                },
            },
            userSelectorOptions: {
                userDocs: selectedDataAdminItems,
                mode: 'default',
                style: {
                    width: '300px',
                },
            },
        },
        {
            key: 'teamUsers',
            widgetKey: 'userSelector',
            formItemClassName: 'member-items',
            status: {
                loading: (data, extra) => {
                    return extra.memberOptionsDataLoading;
                },
            },
            userSelectorOptions: {
                userDocs: customInfo,
                mode: 'multiple',
                placeholder: '请选择用户',
                style: {
                    width: '270px',
                },
            },
            wrapperKey: 'member-team',
        },
        {
            key: 'autoDistributUsers',
            widgetKey: 'userSelector',
            formItemClassName: 'member-items',
            status: {
                loading: (data, extra) => {
                    return extra.memberOptionsDataLoading;
                },
            },
            userSelectorOptions: {
                userDocs: customInfo,
                mode: 'multiple',
                placeholder: '请选择用户',
                style: {
                    width: '270px',
                },
            },
            wrapperKey: 'member-team',
        },
        {
            key: 'teamDeparts',
            widgetKey: 'teamSelector',
            formItemClassName: 'member-items',
            status: {
                loading: (data, extra) => {
                    return extra.memberOptionsDataLoading;
                },
            },
            teamSelectorOptions: {
                treeData: departInfo,
                showCheckedStrategy: 'SHOW_ALL',
                // treeCheckStrictly: true,
                labelInValue: true,
                onChange: v => {
                    console.log(v);
                },
                style: {
                    width: '270px',
                },
            },
            wrapperKey: 'member-team',
        },
        {
            key: 'isHighValue',
            widgetKey: 'select',
            formItemClassName: 'member-items',
            status: {
                loading: (data, extra) => {
                    return extra.memberOptionsDataLoading;
                },
            },
            selectOptions: {
                mode: 'default',
                options: [
                    {
                        name: '是',
                        value: 6,
                    },
                    {
                        name: '否',
                        value: 7,
                    },
                ],
                style: {
                    width: '300px',
                },
            },
        },
        // {
        //     key: 'dataRoleList',
        //     widgetKey: 'select',
        //     selectOptions: {
        //         mode: 'multiple',
        //         optionFilterProp: 'label',
        //         options: _.map(DRMRoleListData, d => {
        //             return {
        //                 name: d?.dataRoleName || '',
        //                 value: d?.id || '',
        //             };
        //         }),
        //     },
        // },
    ];
    return widgets;
};

export const makeMemberActionWidgets = (
    loadingStatus: boolean,
    isAdd?: boolean,
) => {
    const memberActionWidgets: Array<IActionWidgetItem<
        IMemberSettingData,
        IMemberExtraData
    >> = [
        {
            key: 'submit',
            option: {
                btnText: isAdd ? '下一步' : '提交',
                type: 'primary',
                disabled: loadingStatus,
            },
        },
        {
            key: 'cancel',
            option: {
                btnText: '取消',
                type: 'default',
                clickActionType: 'cancel',
            },
        },
    ];
    return memberActionWidgets;
};
export const memberActionWidgets: Array<IActionWidgetItem<
    IMemberSettingData,
    IMemberExtraData
>> = [
    {
        key: 'submit',
        option: {
            btnText: '提交',
            type: 'primary',
        },
    },
    {
        key: 'cancel',
        option: {
            btnText: '取消',
            type: 'default',
            clickActionType: 'cancel',
        },
    },
];

export const memberFormItemOptions: Array<IItemOptionItem<
    IMemberSettingData
>> = [
    {
        key: 'description',
        label: '分组名称',
        labelCol: { span: 5, offset: 15 },
    },
    {
        key: 'dataAdmin',
        label: '分组管理员',
        labelCol: { span: 5, offset: 15 },
    },
    {
        key: 'dataDefaultAdmin',
        label: '数据管理员',
        labelCol: { span: 5, offset: 15 },
    },
    {
        key: 'isHighValue',
        label: '是否高价值',
        labelCol: { span: 5, offset: 15 },
    },
    {
        key: 'teamUsers',
        label: '分组成员',
        labelCol: { span: 5, offset: 15 },
    },
    {
        key: 'autoDistributUsers',
        label: '自动分配成员',
        labelCol: { span: 5, offset: 15 },
    },
    {
        key: 'teamDeparts',
        label: '分组部门',
        labelCol: { span: 5, offset: 15 },
    },
];

export const memberValidators: {
    [key in keyof IMemberSettingData]?: Validator<IMemberSettingData>;
} = {
    description: datam => {
        const data = datam.description;
        if (!data) {
            return {
                status: 'fail',
                msg: '请输入分组名',
            };
        }
        return {
            status: 'success',
            msg: '',
        };
    },
    dataAdmin: datam => {
        const data = datam.dataAdmin;
        if (!data || !data.length) {
            return {
                status: 'fail',
                msg: '请选择分组管理员',
            };
        }
        return {
            status: 'success',
            msg: '',
        };
    },
    dataDefaultAdmin: datam => {
        const data = datam.dataDefaultAdmin;
        if (!data || !data.length) {
            return {
                status: 'fail',
                msg: '请选择数据管理员',
            };
        }
        return {
            status: 'success',
            msg: '',
        };
    },
    isHighValue: datam => {
        const data = datam.isHighValue;
        if (!data) {
            return {
                status: 'fail',
                msg: '请选择是否高价值',
            };
        }
        return {
            status: 'success',
            msg: '',
        };
    },
    teamDeparts: datam => {
        const departsData = datam.teamDeparts;
        const memberData = datam.teamUsers;
        if (departsData?.length || memberData?.length) {
            return {
                status: 'success',
                msg: '',
            };
        } else {
            return {
                status: 'fail',
                msg: '分组成员/部门至少其一不为空',
            };
        }
    },
};
