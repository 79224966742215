import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import './index.less';
export interface IBigNumberCardProps {
    number: number | string;
    title: string;
    showToday?: boolean;
    todayEl?: any;
    isHomeNew?: boolean;
}
const number2Label: {
    [key: string]: any;
} = {
    4: '千',
    5: '万',
    6: '十万',
    7: '百万',
    8: '千万',
    9: '亿',
    10: '十亿',
};
const BigNumberCard: React.FC<IBigNumberCardProps> = props => {
    const numberArray = Array.from(props.number.toString());
    const numberLength = numberArray.length;
    if (numberLength < 9) {
        // 不满亿 则前插到亿
        let gapLength = 9 - numberLength;
        while (gapLength--) {
            numberArray.unshift('0');
        }
    }
    numberArray.reverse();
    const finalArray: {
        value: string;
        label?: string;
    }[] = [];
    _.forEach(numberArray, (item, idx) => {
        finalArray.push({
            value: item,
            label: number2Label?.[idx + 1] || '',
        });
        if ((idx + 1) % 3 === 0) {
            if (idx === 8 && numberLength < 9) {
                return;
            }
            // 每三位插入一位逗号
            finalArray.push({
                value: 'comma',
                label: '',
            });
        }
    });
    return (
        <div className={`big-number-container ${props.isHomeNew ? 'h-n' : ''}`}>
            <img
                width={props.isHomeNew ? '800px' : '898px'}
                height={props.isHomeNew ? '228px' : '257px'}
                src={`${process.env.PUBLIC_URL}/imgs/composite/data-pan.png`}
                alt=""
                className="big-number-container-img"
            />
            <span
                className={`big-number-title ${props.isHomeNew ? 'h-n' : ''}`}
            >
                {props.title}
            </span>
            {props.showToday && (
                <span
                    className={`big-number-today-income ${
                        props.isHomeNew ? 'h-n' : ''
                    }`}
                >
                    {props.todayEl}
                </span>
            )}
            <div
                className={`number-line-content ${
                    props.isHomeNew ? 'h-n' : ''
                }`}
            >
                {_.map(finalArray, item => {
                    return item.value === 'comma' ? (
                        <div className="comma-item">
                            <img
                                height="23px"
                                width="11px"
                                src={`${process.env.PUBLIC_URL}/imgs/composite/${item.value}.png`}
                                alt=""
                            />
                        </div>
                    ) : (
                        <div className="number-item">
                            {item.label ? (
                                <span className="number-label">
                                    {item.label}
                                </span>
                            ) : (
                                <span className="number-empty"></span>
                            )}
                            <img
                                height="88px"
                                width="56px"
                                src={`${process.env.PUBLIC_URL}/imgs/composite/${item.value}.png`}
                                alt=""
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BigNumberCard;
