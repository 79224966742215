import React, { useState, useContext, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { useNet } from '@/utils';
import {
    INormalSettingData,
    ISpecialUserData,
    ICHSPSLTableData,
} from './store';
import { LayoutStandardCrud } from '../../../components/layouts/standard-crud/index';
import { AntButton, TableLv2, AntTabs } from '@/components';
import moment from 'moment';
import { message, Tabs } from 'antd';
import NormalLimitSettingModal from './normal-limit-setting-modal/index';
import SpecialLimitSettingModal from './special-limit-setting-modal/index';
import './index.scss';

export interface ICHSPLProps {}
const CHSPSL: React.FC<ICHSPLProps> = () => {
    const store = useContext(StoreContext);
    const { getDefaultCHSPSLStore } = store;
    const CHSPSLStore = getDefaultCHSPSLStore();
    const [normalSettingData, setNormalSettingData] = useState<
        INormalSettingData
    >();
    const [specialSettingData, setSpecialSettingData] = useState<
        ISpecialUserData | undefined
    >({
        userId: [],
    });
    const [salesSettingMode, setSalesSettingMode] = useState('1'); // 1: 普通销售 2: 特殊销售;

    const [normalModalVis, setNormalModalVis] = useState(false);
    const [specialModalVis, setSpecialModalVis] = useState(false);

    const {
        specialLimitTableData,
        getCHSPLimitData,
        deleteSpecialSales,
        normalLimitTableData,
    } = CHSPSLStore;
    const dataFetch = useCallback(() => getCHSPLimitData(), []);
    const [error, loading, reload] = useNet(dataFetch);

    const onActionDispatch = async (
        actionKey: string,
        data: ISpecialUserData,
    ) => {
        switch (actionKey) {
            case 'edit':
                setSpecialSettingData(data);
                setSpecialModalVis(true);
                break;
            case 'delete':
                const [d, e] = await deleteSpecialSales(data.id as string);
                if (d) {
                    reload();
                    message.success('删除成功', 1);
                } else {
                    message.error('删除失败', 1);
                }
                break;
            default:
                break;
        }
    };
    // const haveDeal = !!normalSettingData?.includeDeal;
    // const haveSelf = !!normalSettingData?.includeSelfBuild;
    // const haveNotSelfAndDeal = !haveSelf && !haveDeal;
    // const haveSelfAndDeal = haveSelf && haveDeal;
    // const renderText = () => {
    //     if (loading) {
    //         return null;
    //     }
    //     if (haveSelfAndDeal) {
    //         return '(包含自建和已签约客户)：';
    //     }
    //     if (haveNotSelfAndDeal) {
    //         return (
    //             <>
    //                 (
    //                 <em style={{ fontStyle: 'normal', fontWeight: 500 }}>不</em>
    //                 包含自建的客户,
    //                 <em style={{ fontStyle: 'normal', fontWeight: 500 }}>不</em>
    //                 包含已签约客户)：
    //             </>
    //         );
    //     }
    //     if (haveSelf) {
    //         return '(包含自建客户,不包含已签约用户)：';
    //     }
    //     if (haveDeal) {
    //         return '(不包含自建客户,包含已签约客户)：';
    //     }
    // };
    const globalOptions = (
        <>
            <AntButton
                onClick={() => setSpecialModalVis(true)}
                type="primary"
                disabled={loading}
            >
                新建特殊销售
            </AntButton>
        </>
    );
    const titleSpecial = (
        <span>
            特殊销售最大私池客户数&nbsp;
            {/* {metaLoading ? <Spin /> : null} */}
        </span>
    );
    const titleNormal = (
        <span>
            普通销售最大私池客户数&nbsp;
            {/* {metaLoading ? <Spin /> : null} */}
        </span>
    );
    // type?: number | string;
    // maxNum?: number | string; // 最大库容量
    // isIncludeCreate?: number | string; // 包括自建
    // isIncludeSign?: number | string; // 包括签约
    // userLevel?: string; // 销售级别
    const renderNormalSalesSetting = () => {
        return (
            <div className="chspl-content">
                <TableLv2<INormalSettingData>
                    loading={loading}
                    dataSource={normalLimitTableData}
                    columns={[
                        {
                            key: 'userLevel',
                            title: '销售级别',
                            dataIndex: 'userLevel',
                            width: 150,
                        },
                        {
                            key: 'isIncludeCreate',
                            title: '包含自建客户',
                            dataIndex: 'isIncludeCreate',
                            width: 150,
                            render: v => {
                                return v === 1 ? '是' : '否';
                            },
                        },
                        {
                            key: 'isIncludeSign',
                            title: '包含已签约客户',
                            dataIndex: 'isIncludeSign',
                            width: 150,
                            render: v => {
                                return v === 1 ? '是' : '否';
                            },
                        },
                        {
                            key: 'maxNum',
                            title: '最大库容量',
                            dataIndex: 'maxNum',
                            width: 150,
                        },
                    ]}
                    pagination={false}
                    closeRowSelection={true}
                    onAction={(actionKey, record) => {
                        if (actionKey === 'edit') {
                            setNormalSettingData(record);
                            setNormalModalVis(true);
                        }
                    }}
                    actionsWidth={'150px'}
                    isFixedActions={false}
                    actions={[
                        {
                            actionKey: 'edit',
                            actionCn: '修改',
                        },
                    ]}
                />
            </div>
        );
    };
    return (
        <div className="chspl-content">
            <AntTabs
                defaultActiveKey="1"
                tabConfig={[
                    {
                        key: '1',
                        tab: '普通销售库容',
                        content: (
                            <div className="chspl-normal">
                                {/* {titleNormal}
                                {renderNormalSalesSetting()} */}
                                <LayoutStandardCrud
                                    message={null}
                                    popups={null}
                                    title={titleNormal}
                                    multipleOps={null}
                                    globalOps={null}
                                    filters={null}
                                    dataTable={renderNormalSalesSetting()}
                                />
                            </div>
                            // <p className="chspl-normal">
                            //     <span className="normal-title">
                            //         普通销售最大私池客户数{renderText()}
                            //         <span>{normalSettingData?.generalMaxLimit}</span>
                            //     </span>
                            //     <AntButton
                            //         type="primary"
                            //         onClick={() => setNormalModalVis(true)}
                            //         disabled={loading}
                            //     >
                            //         修改
                            //     </AntButton>
                            // </p>
                        ),
                    },
                    {
                        key: '2',
                        tab: '特殊销售库容',
                        content: (
                            <div className="chspl-special">
                                {/* {titleSpecial}
                                {globalOptions}
                                <TableLv2<ISpecialUserData>
                                    loading={loading}
                                    dataSource={specialLimitTableData}
                                    columns={[
                                        {
                                            key: 'name',
                                            title: '姓名',
                                            dataIndex: 'userName',
                                            width: 150,
                                        },
                                        {
                                            key: 'maxNum',
                                            title: '最大客户数',
                                            dataIndex: 'maxNum',
                                            width: 150,
                                        },
                                        {
                                            key: 'isIncludeCreate',
                                            title: '包含自建客户',
                                            dataIndex: 'isIncludeCreate',
                                            width: 150,
                                            render: v => {
                                                return v === 1 ? '是' : '否';
                                            },
                                        },
                                        {
                                            key: 'isIncludeSign',
                                            title: '包含已签约客户',
                                            dataIndex: 'isIncludeSign',
                                            width: 150,
                                            render: v => {
                                                return v === 1 ? '是' : '否';
                                            },
                                        },
                                        {
                                            key: 'updateTime',
                                            title: '最后修改时间',
                                            dataIndex: 'updateTime',
                                            width: 150,
                                            render: v => {
                                                return moment(v).format(
                                                    'YYYY-MM-DD HH:mm',
                                                );
                                            },
                                        },
                                    ]}
                                    pagination={false}
                                    closeRowSelection={true}
                                    onAction={onActionDispatch}
                                    actionsWidth={'150px'}
                                    isFixedActions={false}
                                    actions={[
                                        {
                                            actionKey: 'edit',
                                            actionCn: '修改',
                                        },
                                        {
                                            actionKey: 'delete',
                                            actionCn: '删除',
                                            popconfirm: true,
                                            popcomfirmConfig: {
                                                title:
                                                    '是否要删除该特殊销售？',
                                            },
                                        },
                                    ]}
                                /> */}
                                <LayoutStandardCrud
                                    message={null}
                                    popups={null}
                                    title={titleSpecial}
                                    multipleOps={null}
                                    globalOps={globalOptions}
                                    filters={null}
                                    dataTable={
                                        <TableLv2<ISpecialUserData>
                                            loading={loading}
                                            dataSource={specialLimitTableData}
                                            columns={[
                                                {
                                                    key: 'name',
                                                    title: '姓名',
                                                    dataIndex: 'userName',
                                                    width: 150,
                                                },
                                                {
                                                    key: 'maxNum',
                                                    title: '最大客户数',
                                                    dataIndex: 'maxNum',
                                                    width: 150,
                                                },
                                                {
                                                    key: 'isIncludeCreate',
                                                    title: '包含自建客户',
                                                    dataIndex:
                                                        'isIncludeCreate',
                                                    width: 150,
                                                    render: v => {
                                                        return v === 1
                                                            ? '是'
                                                            : '否';
                                                    },
                                                },
                                                {
                                                    key: 'isIncludeSign',
                                                    title: '包含已签约客户',
                                                    dataIndex: 'isIncludeSign',
                                                    width: 150,
                                                    render: v => {
                                                        return v === 1
                                                            ? '是'
                                                            : '否';
                                                    },
                                                },
                                                {
                                                    key: 'updateTime',
                                                    title: '最后修改时间',
                                                    dataIndex: 'updateTime',
                                                    width: 150,
                                                    render: v => {
                                                        return moment(v).format(
                                                            'YYYY-MM-DD HH:mm',
                                                        );
                                                    },
                                                },
                                            ]}
                                            pagination={false}
                                            closeRowSelection={true}
                                            onAction={onActionDispatch}
                                            actionsWidth={'150px'}
                                            isFixedActions={false}
                                            actions={[
                                                {
                                                    actionKey: 'edit',
                                                    actionCn: '修改',
                                                },
                                                {
                                                    actionKey: 'delete',
                                                    actionCn: '删除',
                                                    popconfirm: true,
                                                    popcomfirmConfig: {
                                                        title:
                                                            '是否要删除该特殊销售？',
                                                    },
                                                },
                                            ]}
                                        />
                                    }
                                />
                            </div>
                        ),
                    },
                ]}
            ></AntTabs>
            <NormalLimitSettingModal
                modalDefaultData={
                    { ...normalSettingData } as INormalSettingData
                }
                setModalDefaultData={setNormalSettingData}
                setModalVis={setNormalModalVis}
                modalVis={normalModalVis}
                reloadTable={reload}
            />
            <SpecialLimitSettingModal
                modalVis={specialModalVis}
                setModalVis={setSpecialModalVis}
                modalDefaultData={specialSettingData as ISpecialUserData}
                setModalDefaultData={setSpecialSettingData}
                normalSettingData={normalSettingData as INormalSettingData}
                reloadTable={reload}
            />
        </div>
    );
};

export default observer(CHSPSL);
