import React, { useEffect } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Input, Form, Modal } from 'antd';
import { SaleTagInfo } from '../api';
import _ from 'lodash';

export interface ISaleDrawerProps extends FormComponentProps {
    visible: boolean;
    initialValues?: SaleTagInfo;
    onSubmit: (val: any, type: string) => void;
    onClose: () => void;
    loading?: boolean;
}

const SaleTagDrawerSub: React.FC<ISaleDrawerProps> = ({
    visible,
    initialValues,
    onSubmit = () => {},
    onClose = () => {},
    loading = false,
    form,
}) => {
    const {
        getFieldDecorator,
        setFieldsValue,
        validateFields,
        resetFields,
    } = form;

    useEffect(() => {
        _.isEmpty(initialValues)
            ? resetFields()
            : setFieldsValue(initialValues!);
    }, [initialValues]);

    useEffect(() => {
        if (!visible) {
            resetFields();
        }
    }, [visible]);

    const handleSubmit = () => {
        validateFields((errs, values) => {
            if (errs) {
                return;
            }
            const type = initialValues?.value ? 'update' : 'create';
            let extendValues 
            if (type === 'update') {
                extendValues = {
                    choice: initialValues?.choice || true,
                    value: initialValues?.value,
                    selected: initialValues?.selected || false,
                    label: values.label,
                }
            } else {
                extendValues = {
                    choice: true,
                    selected: false,
                    label: values.label,
                }
            } 
            onSubmit(extendValues, type);
        });
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <Modal
                title={initialValues?.value ? '修改标签' : '新建标签'}
                visible={visible}
                onOk={handleSubmit}
                onCancel={handleClose}
                bodyStyle={{
                    height: 100,
                }}
            >
                <div
                    className="standard-drawer-form-wrapper"
                    style={{ marginTop: '5px' }}
                >
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                        <Form.Item label="标签名称">
                            {getFieldDecorator('label', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入标签名称',
                                    },
                                ],
                            })(
                                <Input
                                    placeholder="请输入标签名称"
                                />,
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default Form.create<ISaleDrawerProps>()(SaleTagDrawerSub);
