import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import moment, { Moment } from 'moment';
import { DatePicker, Input, Popover, Icon, Button, message } from 'antd';
import './index.less';
import { useNet } from '@/utils';
import { LayoutStandardCrud, TableLv2 } from '@/components';
import { IProductRightsAnaData } from './store';
import _ from 'lodash';

const RangePicker = DatePicker.RangePicker;

const AnaProductRights: React.FC = props => {
    const store = useContext(StoreContext);
    const anaStore = store.getDefaultAnalysisStore();
    const productRightsStore = store.getDefaultProductRightsStore();
    const {
        getProductRightsData,
        exportProductRightsData,
        productRightsAnaData,
    } = productRightsStore;
    const [error, loading, reload] = useNet(() =>
        getProductRightsData(anaSearchValue),
    );
    const afterDay = moment().subtract(1, 'month');
    const [anaSearchValue, setAnaSearchValue] = useState<{
        beginDate?: Moment | string;
        endDate?: Moment | string;
        firstBeginDate?: Moment | string;
        firstEndDate?: Moment | string;
    }>({
        beginDate: afterDay.startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        endDate: afterDay.endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        firstBeginDate: undefined,
        firstEndDate: undefined,
    });
    // 商机预计回款时间变更
    const onActionSearchFirstTimeChange = (
        dates: any,
        dateStrings: [string, string],
    ) => {
        const limitStart = moment(dateStrings[1])
            .subtract('3', 'months')
            .startOf('days');
        const startMoment = moment(dateStrings[0]).startOf('days');
        if (limitStart.isAfter(startMoment)) {
            message.warning('请选择小于或等于三个月的时间区间～');
            return;
        }
        setAnaSearchValue({
            firstBeginDate: dateStrings[0],
            firstEndDate: dateStrings[1],
        });
    };
    // 时间选择变更
    const onActionSearchTimeChange = (
        dates: any,
        dateStrings: [string, string],
    ) => {
        const limitStart = moment(dateStrings[1])
            .subtract('3', 'months')
            .startOf('days');
        const startMoment = moment(dateStrings[0]).startOf('days');
        if (limitStart.isAfter(startMoment)) {
            message.warning('请选择小于或等于三个月的时间区间～');
            return;
        }
        setAnaSearchValue({
            beginDate: dateStrings[0],
            endDate: dateStrings[1],
        });
        // reload();
    };
    const renderLoginPageFilter = () => {
        const preTimeRange: any = [
            anaSearchValue.firstBeginDate
                ? moment(anaSearchValue.firstBeginDate)
                : undefined,
            anaSearchValue.firstEndDate
                ? moment(anaSearchValue.firstEndDate)
                : undefined,
        ];
        const createTimeRange: any = [
            anaSearchValue.beginDate
                ? moment(anaSearchValue.beginDate)
                : undefined,
            anaSearchValue.endDate ? moment(anaSearchValue.endDate) : undefined,
        ];
        return (
            <div className="ana-filter-content">
                <div className="ana-filter-item">
                    <label htmlFor="time">合同创建时间：</label>
                    <RangePicker
                        name="time"
                        disabled={loading}
                        value={createTimeRange}
                        showTime={true}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={onActionSearchTimeChange}
                        style={{ width: '400px' }}
                        allowClear={false}
                        onOk={reload}
                    />
                </div>
                <div className="ana-filter-item">
                    <label htmlFor="startTime">商机预计回款时间：</label>
                    <RangePicker
                        name="startTime"
                        disabled={loading}
                        value={preTimeRange}
                        showTime={true}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={onActionSearchFirstTimeChange}
                        style={{ width: '400px' }}
                        allowClear={true}
                        onOk={reload}
                    />
                </div>
                <Button
                    type="primary"
                    onClick={() => {
                        // defaultChannelGatherAnaPagination.pagenum = 1;s
                        reload();
                    }}
                >
                    查询
                </Button>
                {anaStore.exportVis && (
                    <Button
                        type="primary"
                        onClick={() => {
                            exportProductRightsData(anaSearchValue);
                            // defaultLoginLogPagination.pagenum = 1;
                            // reload();
                        }}
                    >
                        导出
                    </Button>
                )}
            </div>
        );
    };
    // customerName?: string; // 客户名称
    // customerProvince?: string; // 省份
    // customerCity?: string; // 城市
    // contractCode: string; // 合同编号
    // contractOwnerName?: string; // 合同所有人
    // contractOwnerDepartName?: string; // 合同所有人所属部门
    // contractCreateTime?: string; // 合同创建时间
    // opportunityName?: string; // 关联商机名称
    // opportunityCreateTime?: number; // 商机创建时间
    // winRate?: number; // 关联商机赢率
    // productCatalog?: string; // 产品类型
    // productVersion?: string; // 产品版本
    // productName?: string; // 产品名称
    // cost?: number; // 刊例价
    // totalCost?: number; // 实际销售价
    // saleType?: string; // 购买类型
    // equityName?: string; // 权益名称
    // standardEquityValue?: number; // 标准权益值
    // changeEquityValue?: number; // 变更后权益值
    const renderRowByMerge = (
        text: string,
        row: IProductRightsAnaData,
        index: number,
        key: string,
    ) => {
        return text;
        // if(_.isNil(text)) {
        //     return text;
        // }
        // let indexRowSpan = 0;
        // if (
        //     index === 0 ||
        //     text != productRightsAnaData?.[index - 1]?.[key] ||
        //     row.customerName != productRightsAnaData?.[index - 1].customerName
        // ) {
        //     let tempI = index + 1;
        //     let count = 1;
        //     while (
        //         text === productRightsAnaData?.[tempI]?.[key] &&
        //         row.customerName === productRightsAnaData?.[tempI].customerName
        //     ) {
        //         count = count + 1;
        //         tempI = tempI + 1;
        //     }
        //     indexRowSpan = count;
        // }
        // return {
        //     children: <span>{text}</span>,
        //     props: {
        //         colSpan:
        //             index !== 0 &&
        //             text === productRightsAnaData?.[index - 1]?.[key] &&
        //             row.customerName ===
        //                 productRightsAnaData?.[index - 1].customerName
        //                 ? 0
        //                 : 1,
        //         rowSpan: indexRowSpan,
        //     },
        // };
    };
    return (
        <div className="analysis-content">
            <LayoutStandardCrud
                title={'产品权益-售卖明细表'}
                message={null}
                popups={null}
                multipleOps={null}
                globalOps={null}
                filters={renderLoginPageFilter()}
                dataTable={
                    <TableLv2<IProductRightsAnaData>
                        loading={loading}
                        dataSource={productRightsAnaData}
                        closeRowSelection={true}
                        pagination={false}
                        // pagination={{
                        //     ...defaultChannelGatherAnaPagination.paginationForUI,
                        //     total: defaultChannelGatherAnaPagination.amount,
                        // }}
                        // onPaginationChange={
                        //     defaultChannelGatherAnaPagination.setPaginationByUI
                        // }
                        scroll={{ x: '100%' }}
                        columns={[
                            {
                                title: '客户名称',
                                key: 'customerName',
                                dataIndex: 'customerName',
                                width: '160px',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'customerName',
                                    );
                                },
                            },
                            {
                                title: '客户所属省份',
                                key: 'customerProvince',
                                width: '120px',
                                dataIndex: 'customerProvince',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'customerProvince',
                                    );
                                },
                            },
                            {
                                title: '客户所属城市',
                                key: 'customerCity',
                                dataIndex: 'customerCity',
                                width: '120px',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'customerCity',
                                    );
                                },
                            },
                            {
                                title: '合同编号',
                                key: 'contractCode',
                                dataIndex: 'contractCode',
                                width: '160px',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'contractCode',
                                    );
                                },
                            },
                            {
                                title: '合同所有人',
                                key: 'contractOwnerName',
                                dataIndex: 'contractOwnerName',
                                width: '120px',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'contractOwnerName',
                                    );
                                },
                            },
                            {
                                title: '合同所有人所属部门',
                                key: 'contractOwnerDepartName',
                                width: '160px',
                                dataIndex: 'contractOwnerDepartName',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'contractOwnerDepartName',
                                    );
                                },
                            },
                            {
                                title: '合同创建时间',
                                key: 'contractCreateTime',
                                dataIndex: 'contractCreateTime',
                                width: '160px',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text
                                            ? moment(text).format(
                                                  'YYYY-MM-DD HH:mm:ss',
                                              )
                                            : text,
                                        row,
                                        index,
                                        'contractCreateTime',
                                    );
                                },
                            },
                            {
                                title: '商机预计回款时间',
                                key: 'predictionPayBackTime',
                                dataIndex: 'predictionPayBackTime',
                                width: '160px',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text
                                            ? moment(text).format(
                                                  'YYYY-MM-DD HH:mm:ss',
                                              )
                                            : text,
                                        row,
                                        index,
                                        'predictionPayBackTime',
                                    );
                                },
                            },
                            {
                                title: '关联商机名称',
                                key: 'opportunityName',
                                dataIndex: 'opportunityName',
                                width: '180px',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'opportunityName',
                                    );
                                },
                            },
                            {
                                title: '商机创建时间',
                                key: 'opportunityCreateTime',
                                width: '160px',
                                dataIndex: 'opportunityCreateTime',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text
                                            ? moment(text).format(
                                                  'YYYY-MM-DD HH:mm:ss',
                                              )
                                            : text,
                                        row,
                                        index,
                                        'opportunityCreateTime',
                                    );
                                },
                            },
                            {
                                title: '关联商机赢率',
                                key: 'winRate',
                                width: '120px',
                                dataIndex: 'winRate',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'winRate',
                                    );
                                },
                            },
                            {
                                title: '产品类型',
                                key: 'productCatalog',
                                width: '120px',
                                dataIndex: 'productCatalog',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'productCatalog',
                                    );
                                },
                            },
                            {
                                title: '产品版本',
                                key: 'productVersion',
                                dataIndex: 'productVersion',
                                width: '120px',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'productVersion',
                                    );
                                },
                            },
                            {
                                title: '产品名称',
                                key: 'productName',
                                width: '180px',
                                dataIndex: 'productName',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'productName',
                                    );
                                },
                            },
                            {
                                title: '刊例价',
                                key: 'cost',
                                width: '120px',
                                dataIndex: 'cost',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'cost',
                                    );
                                },
                            },
                            {
                                title: '实际销售价',
                                key: 'totalCost',
                                width: '120px',
                                dataIndex: 'totalCost',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'totalCost',
                                    );
                                },
                            },
                            {
                                title: '购买类型',
                                key: 'purchaseType',
                                width: '120px',
                                dataIndex: 'purchaseType',
                                render: (
                                    text: string,
                                    row: IProductRightsAnaData,
                                    index: number,
                                ) => {
                                    return renderRowByMerge(
                                        text,
                                        row,
                                        index,
                                        'purchaseType',
                                    );
                                },
                            },
                            {
                                title: '权益名称',
                                key: 'equityName',
                                width: '120px',
                                dataIndex: 'equityName',
                            },
                            {
                                title: '标准权益值',
                                key: 'standardEquityValue',
                                width: '120px',
                                dataIndex: 'standardEquityValue',
                            },
                            {
                                title: '兑换后权益值',
                                key: 'changeEquityValue',
                                width: '120px',
                                dataIndex: 'changeEquityValue',
                            },
                        ]}
                    />
                }
            />
        </div>
    );
};
export default observer(AnaProductRights);
