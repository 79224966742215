import _ from 'lodash';
import { fetchFeEquityDetailByBackEndFormat } from '@/pages/om/product/list/utils';
import { commonQuery } from '@/api/rest';
import { getProductsTotalPriceByData } from '@/components/common/form/widgets/opt/select-opt-product';

const stringifyParsedEquityDetail = (parsedEquityDetail: any[]) => {
    const ret: any[] = [];
    if (!_.isArray(parsedEquityDetail)) {
        return ret;
    }

    for (const datum of parsedEquityDetail) {
        if (!datum) {
            continue;
        }
        const relatedObj = datum.relatedObj;
        if (!relatedObj) {
            continue;
        }
        const cur: any = {};
        const {
            equity_key,
            equity_name,
            id,
            value,
            original_value,
            // equity_gift_num,
        } = relatedObj;

        cur.id = id;
        cur.equityName = equity_name;
        cur.equityKey = equity_key;
        cur.equityNum = value;
        cur.equityOriginalNum = original_value;
        // cur.equityGiftNum = equity_gift_num;
        ret.push(cur);
    }

    return JSON.stringify(ret);
};

export const parseFeProductToBackEndContractProduct = async (
    feProduct: any,
    parentData: any,
) => {
    const data = feProduct;
    const ret: any[] = [];
    if (!_.isArray(data)) {
        return ret;
    }
    const asyncs: any[] = [];
    for (const datum of data) {
        if (!datum) {
            continue;
        }
        const relatedObj = datum.relatedObj;
        if (!relatedObj) {
            continue;
        }
        const cur: any = {};
        const {
            _product_id,

            id,
            product_name,
            product_price,

            discount,
            sale_num,
            sale_price,
            total_price,

            // TODOO 20210707 新字段
            product_type,
            product_version2,
            product_deliver_method,
            talent_lib_version,
            buy_type,
            sale_tag,
            open_type,

            equity_detail,
            equity_detail_parsed,
            product_describe,
            service_month,
        } = relatedObj;

        // 继承上级
        cur.owner_id = parentData.owner_id || null;
        cur.biz_type = parentData.biz_type || null;
        // 其实商机产品和合同产品也就这里不一样
        cur.contract_id = parentData.id || null;

        // product原本信息
        cur.product_id = id;
        cur.product_name = product_name;
        cur.product_price = product_price;

        // product额外信息
        cur.discount = discount;
        cur.sale_num = sale_num;
        cur.sale_price = sale_price;
        cur.total_price = total_price;

        cur.product_type = product_type;
        cur.product_version = product_version2;
        cur.deliver_method = product_deliver_method;
        cur.talent_lib_version = talent_lib_version;
        cur.buy_type = buy_type;
        cur.sale_tag = sale_tag;
        cur.open_type = open_type;
        cur.product_describe = product_describe;
        cur.service_month = service_month;

        // 如果存在，则是修改
        if (_product_id) {
            cur.id = _product_id;
        }

        // 拼接equity_detail
        if (
            _.isArray(equity_detail_parsed) &&
            equity_detail_parsed.length > 0
        ) {
            cur.equity_detail = stringifyParsedEquityDetail(
                equity_detail_parsed,
            );
        } else if (_.isString(equity_detail) && equity_detail.length > 0) {
            asyncs.push(
                new Promise(async resolve => {
                    const equities = await fetchFeEquityDetailByBackEndFormat(
                        equity_detail,
                    );
                    cur.equity_detail = stringifyParsedEquityDetail(equities);
                    resolve();
                }),
            );
        }

        ret.push(cur);
    }
    await Promise.all(asyncs);
    return ret;
};

export const embedProductParamToContract = async (contract: any) => {
    if (!contract) {
        return false;
    }
    const { id } = contract;

    if (!id) {
        return false;
    }

    const product = [];
    const [d, e] = await commonQuery(
        'contract_product',
        {
            pageSize: 100,
            pageNum: 1,
        },
        {
            filters: [
                {
                    fieldId: 'contract_id',
                    operateType: '=',
                    filterValue: id,
                },
            ],
        },
    );
    if (!d || e) {
        return false;
    }
    const optProducts = d.datas;

    for (const optProduct of optProducts) {
        if (!optProduct) {
            continue;
        }
        const { product_id, id } = optProduct;
        if (!product_id || !id) {
            continue;
        }
        const cur: any = {};
        cur.originalValue = product_id.originalValue;
        cur.type = 'obj';
        cur.relatedObj = {} as any;

        cur.relatedObj.product_price = optProduct.product_price;
        cur.relatedObj.id = product_id.originalValue;
        cur.relatedObj.product_name = optProduct.product_name;
        cur.relatedObj.sale_price = optProduct.sale_price;
        cur.relatedObj.sale_num = optProduct.sale_num;
        cur.relatedObj.discount = optProduct.discount + '';
        cur.relatedObj.total_price = optProduct.total_price;

        // 新增字段
        cur.relatedObj.product_type = optProduct.product_type;
        cur.relatedObj.product_version2 = optProduct.product_version;
        cur.relatedObj.product_deliver_method = optProduct.deliver_method;
        cur.relatedObj.talent_lib_version = optProduct.talent_lib_version;
        cur.relatedObj.buy_type = optProduct.buy_type;
        cur.relatedObj.sale_tag = optProduct.sale_tag;
        cur.relatedObj.open_type = optProduct.open_type;
        cur.relatedObj.product_describe = optProduct.product_describe;
        cur.relatedObj.service_month = optProduct.service_month;

        // 先缓存一个id，修改时用
        // 如果是clone模式，不带id
        cur.relatedObj._product_id = id;

        const equityDetailParsed = [];
        const { equity_detail } = optProduct;
        let equityDetailList = [];
        try {
            if (equity_detail !== null) {
                equityDetailList = JSON.parse(equity_detail);
            }
        } catch (e) {}

        for (const equityDetail of equityDetailList) {
            // TODOO @lichen
            const {
                id,
                equityName,
                equityKey,
                equityNum,
                equityOriginalNum,
                // equityGiftNum,
            } = equityDetail;

            const curEquity: any = {};
            if (!id || !equityKey) {
                continue;
            }
            curEquity.originalValue = equityKey;
            cur.type = 'obj';
            curEquity.relatedObj = {
                equity_name: equityName,
                equity_key: equityKey,
                id,
                value: equityNum,
                original_value: equityOriginalNum,
                // equity_gift_num: equityGiftNum,
            };
            equityDetailParsed.push(curEquity);
        }
        cur.relatedObj.equity_detail_parsed = equityDetailParsed;
        product.push(cur);
    }
    contract.product = product;
    // contract.contract_amount = getProductsTotalPriceByData(product);

    return true;
};
