import React, { useEffect, memo, useState, useCallback } from 'react';

import { ContactListStore } from '../store';
import { Modal, Alert, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { tryRefresh, useNet } from '@/utils';
import { commonQuery } from '@/api/rest';

export const ActionDelete: React.FC<{
    defaultStore: ContactListStore;
}> = observer(({ defaultStore }) => {
    const { mutatingData } = defaultStore;
    const visible = defaultStore.actionType === 'delete';
    const [loading2, setLoading] = useState<boolean>(false);

    const [carCount, setCarCount] = useState(0);

    const loadCount = useCallback(async () => {
        if (!visible || !mutatingData || !mutatingData.id) {
            return [null, null];
        }
        setCarCount(0);
        const [d, e] = await commonQuery(
            'cu_activation_record',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'contacts_id',
                        filterValue: mutatingData.id,
                        operateType: '=',
                    },
                ],
            },
            undefined,
            undefined,
            undefined,
            undefined,
        );

        if (d === null || e !== null) {
            return [null, new Error('活动记录数量加载错误')];
        }
        setCarCount(d.amount);
        return [d, e];
    }, [visible, mutatingData]) as () => Promise<[any | null, Error | null]>;

    const [error, loading, reload] = useNet(loadCount, {
        autoLoad: false,
        defaultLoading: true,
    });

    useEffect(() => {
        reload();
    }, [visible, mutatingData]);

    if (mutatingData === null) {
        return null;
    }
    return (
        <Modal
            visible={visible}
            title={'删除联系人'}
            onCancel={() => {
                defaultStore.resetAction();
            }}
            confirmLoading={loading || loading2}
            onOk={async () => {
                setLoading(true);
                const ok = await defaultStore.delete();
                setLoading(false);

                if (ok) {
                    defaultStore.resetAction();
                    tryRefresh(['contacts']);
                }
            }}
        >
            <p>
                是否删除联系人【{mutatingData.contacts_name}】？
                {loading ? (
                    <Spin />
                ) : (
                    <span>
                        该联系人目前有<b>{carCount}</b>条活动记录
                    </span>
                )}
            </p>
            <Alert type="error" message="删除成功之后，该操作无法恢复。" />
        </Modal>
    );
});
