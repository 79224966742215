import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AntButton, LayoutStandardCrud } from '@/components';
import { Menu, Icon, message, Tooltip, Spin, Alert } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { columnsConfig } from './components/table-options';
import { useNet } from '@/utils';
import { observer } from 'mobx-react-lite';
import {
    storeHistoyBidirectBind,
    useListMetaWithReactSnippet,
} from '@/stores/utils';
import classname from 'classname';
import { AntDrawer } from '@/components/antd/drawer';
import FormDemoFilter from './components/filter';
import FormDemoTable from './components/table';

const menu = (
    <Menu
        onClick={e => {
            message.info('Click on menu item.');
            console.log('click', e);
        }}
    >
        <Menu.Item key="1">
            <Icon type="user" />
            1st menu item
        </Menu.Item>
        <Menu.Item key="2">
            <Icon type="user" />
            2nd menu item
        </Menu.Item>
        <Menu.Item key="3">
            <Icon type="user" />
            3rd item
        </Menu.Item>
    </Menu>
);

const FormDemoStandard: React.FC<RouteComponentProps> = ({ children }) => {
    const store = useContext(StoreContext);
    const [demoCurd] = useState(() => new store.CrudDemo2());
    const {
        confirmedfilterData,
        defaultPagination,
        defaultSorter,
        mSeletedData,
        setMSelectedData,
        isMSelectionMode,
        defaultMeta,
    } = demoCurd;
    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const demoCurdFetch = useCallback(() => demoCurd.fetch(), []);
    const [error, loading, reload] = useNet(demoCurdFetch);

    // 翻页
    useEffect(() => {
        reload();
    }, [paginationForFetch, sortersForFetch, confirmedfilterData]);

    useEffect(() => {
        defaultMeta.fetch(); // 初始化元信息
        // store history 双向绑定
        const disposeDibind = storeHistoyBidirectBind(demoCurd);
        return () => {
            disposeDibind();
        };
    }, []);

    const [drawerVisible, setDrawerVisible] = useState(false);

    const popups = (
        <>
            <AntDrawer
                title={'新建客户'}
                placement={'right'}
                visible={drawerVisible}
                onClose={() => setDrawerVisible(false)}
            >
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </AntDrawer>
        </>
    );
    const title = <span>客户列表&nbsp;</span>;
    const globalOps = (
        <>
            <AntButton size="large">下载</AntButton>
            <AntButton
                onClick={() => setDrawerVisible(true)}
                type="primary"
                size="large"
            >
                新建客户
            </AntButton>
        </>
    );
    const filters = null;
    const multipleOps = (
        <div
            className={classname({
                'mselected-ops': true,
                'multiple-mode': isMSelectionMode,
                'normal-mode': !isMSelectionMode,
            })}
        >
            <Tooltip title="取消多选">
                <span
                    onClick={() => {
                        setMSelectedData([]);
                    }}
                    style={{
                        padding: 5,
                        cursor: 'pointer',
                    }}
                >
                    <Icon type="close" />
                </span>
            </Tooltip>
            <div>
                <span>已选{mSeletedData.length}条：</span>
            </div>
            <AntButton size="large">变更归属人</AntButton>
            <AntButton size="large">变更分组</AntButton>
        </div>
    );

    const dataTable = <FormDemoTable demoCurd={demoCurd} loading={loading} />;

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters,
        multipleOps,
        dataTable,
        message: (
            <Alert
                closable
                message="通知：正新鸡排新品上市，限时八折~"
                type="warning"
            />
        ),
    };

    return <LayoutStandardCrud {...layoutComs} />;
};

const FinalFormDemoStandard = observer(FormDemoStandard);
export default FinalFormDemoStandard;
