import React, { createContext } from 'react';

export interface ICtxContextProps {
    position?: 'drawer' | 'list' | 'form';
    positionDetail?: string;
    data?: any;
}

export const CtxContext = createContext<ICtxContextProps>({});

export const CtxProvider: React.FC<{ ctx: ICtxContextProps }> = ({
    ctx,
    children,
}) => {
    return <CtxContext.Provider value={ctx}>{children}</CtxContext.Provider>;
};
