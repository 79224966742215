// AGREE
// ADD_APPROVE

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Input, message, Form, Divider, Alert } from 'antd';
import { rejects, btnMap } from '../conf';
import '../action.scss';
import _ from 'lodash';
import { ProcessCatStore } from '../../../store';
import {
    WidgetCommonObject,
    WidgetRadio,
} from '@/components/common/form/widgets';
import { PreviewProductOpen } from './preview-product-open';
import moment from 'moment';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const AgreeAddApproveAction: React.FC<{
    currentAction: string;
    data: any;
    onOk: (extra?: any) => void;
    onCancel: () => void;
    defaultStore: ProcessCatStore | null;
    loading: boolean;
}> = observer(
    ({ currentAction, data, onOk, onCancel, defaultStore, loading }) => {
        const [disabled, setD] = useState(true);
        const [step, setStep] = useState(0);
        const [d, setData] = useState<any>({});
        const [pass, setPass] = useState(true);
        const [useApprove, setUseApprove] = useState('0');
        const inputHelps: any = !pass
            ? {
                  validateStatus: 'error',
                  help: '加签人必填',
              }
            : {};

        let processAllInfo = null;
        if (defaultStore) {
            processAllInfo = defaultStore?.defaultMeta?.processAllInfo;
        }
        const openProcessId = processAllInfo?.businessInfo?.id;

        const title = btnMap[currentAction] || '';
        const comment = data?.comment || '';
        const newTaskUser = data?.newTaskUser || null;
        const processBtns = defaultStore?.defaultMeta?.processBtns || [];
        const showAddApprove = processBtns.indexOf('ADD_APPROVE') > -1;
        const visible =
            currentAction === 'AGREE' && !!showAddApprove && data !== null;

        useEffect(() => {
            if (!visible) {
                return;
            }
            setStep(2);
        }, [visible]);

        // 需要一个人员类型的字段元信息
        let ownerCol = null;
        if (defaultStore?.defaultMeta) {
            ownerCol = _.find(
                defaultStore?.defaultMeta.objectMeta,
                item => item.fieldName === 'create_user',
            );
        }

        if (!ownerCol) {
            return null;
        }
        const constraint = ownerCol.constraint;
        if (!constraint || !defaultStore?.defaultMeta) {
            return null;
        }

        const mutatingData = defaultStore?.mutatingData;
        const contractName =
            mutatingData?.contract_id?.relatedObj?.contract_name;
        const productName = d?.product_name;
        let tt =
            contractName && productName
                ? `执行【${contractName}】合同中的【${productName}】产品`
                : '执行产品';

        const isRenGong = mutatingData?.product_open_method === 2;
        if (isRenGong) tt = '加签';

        return (
            <Modal
                title={tt}
                onOk={() => {
                    if (step === 1) {
                        return setStep(2);
                    }
                    if (useApprove === '1' && newTaskUser === null) {
                        setPass(false);
                        return message.error('加签人必填');
                    }
                    onOk({ useApprove: useApprove === '1' });
                }}
                okButtonProps={
                    {
                        // disabled: step === 1 ? false : disabled,
                    }
                }
                okText={step === 1 ? '下一步' : '确定'}
                confirmLoading={loading}
                onCancel={() => {
                    setD(true);
                    setStep(0);
                    setPass(true);
                    setUseApprove('0');
                    onCancel();
                }}
                visible={visible}
                zIndex={10001}
            >
                {step === 2 ? (
                    <div className="process-action">
                        {!isRenGong && (
                            <>
                                <h3>开通预览</h3>
                                <PreviewProductOpen
                                    key={openProcessId + ':' + visible}
                                    onGotData={setData}
                                    visible={visible}
                                    openProcessId={openProcessId}
                                    mutatingData={mutatingData}
                                    onOk={() => {
                                        setD(false);
                                    }}
                                />
                            </>
                        )}
                        <p style={{ marginTop: 12 }}>审批意见</p>
                        <div className="form-wrapper">
                            <Form className="form" layout={'horizontal'}>
                                <Form.Item
                                    {...formItemLayout}
                                    label={
                                        <span className="label">审批意见</span>
                                    }
                                >
                                    <Input.TextArea
                                        required={true}
                                        // disabled={disabled}
                                        value={comment}
                                        onChange={e => {
                                            const text = e?.target?.value;
                                            if (!_.isUndefined(text)) {
                                                data.comment = text;
                                            }
                                        }}
                                        placeholder={'审批意见'}
                                    ></Input.TextArea>
                                </Form.Item>

                                <Form.Item
                                    {...formItemLayout}
                                    label={<span className="label">加签</span>}
                                >
                                    <WidgetRadio
                                        value={useApprove}
                                        options={{
                                            // disabled,
                                            options: [
                                                {
                                                    value: '1',
                                                    label: '是',
                                                },
                                                {
                                                    value: '0',
                                                    label: '否',
                                                },
                                            ],
                                        }}
                                        onChange={v => {
                                            setUseApprove(v);
                                        }}
                                    />
                                </Form.Item>

                                {useApprove === '1' ? (
                                    <Form.Item
                                        {...formItemLayout}
                                        {...inputHelps}
                                        required
                                        label={
                                            <span className="label">
                                                加签人
                                            </span>
                                        }
                                    >
                                        <WidgetCommonObject
                                            k={'create_user'}
                                            value={newTaskUser}
                                            onChange={val => {
                                                setPass(true);
                                                data.newTaskUser = val;
                                            }}
                                            options={{
                                                // disabled,
                                                placeholder: '请选择加签人',
                                                constraint,
                                                listMeta:
                                                    defaultStore.defaultMeta,
                                            }}
                                        />
                                    </Form.Item>
                                ) : null}
                            </Form>
                        </div>
                    </div>
                ) : null}
                {step === 1 ? (
                    <div style={{ marginTop: 12 }}>
                        <Alert
                            type="warning"
                            message="销售申请开通日期大于当前日期，如果是新签业务，点击开通将按当前日期立即开通；如果是续约业务，点击将按续约开始日期开通"
                        />
                    </div>
                ) : null}
            </Modal>
        );
    },
);
