import React, { memo } from 'react';
import './action-param-customer.scss';
import { AutoEllipsis } from '../../auto-ellipsis';
import { allCells } from './index';
import { Badge, Tag } from 'antd';
import { BusinessRegistrationDisplay, CusTag } from './business-registration';
import moment from 'moment';
import { getCustomerTags } from '@/pages/sales-follow-up/custom/list/utils';

export const ActionParamContacts: React.FC<{
    args: any[];
    item: any;
    onAction: (...args: any[]) => void;
}> = memo(({ args, item, onAction }) => {
    const value = args[0];
    const options = args[1] || {};
    const paramName = options.paramName || 'unknow';
    const actionName = 'reveal-param/' + paramName;

    const originData = item;
    const isKp = originData.is_kp === 1;
    if (value) {
        let prepend: any = [];
        let count = 0;
        if (isKp) {
            count++;
            prepend.push(
                <CusTag
                    style={{ marginRight: -4 }}
                    color="#FFA408"
                    text="KP"
                />,
            );
        }

        const colCount = Math.ceil(count / 2);
        const w = (colCount ? 2 : 0) + 38 * colCount;
        return (
            <a
                style={{ position: 'relative' }}
                onClick={() => {
                    onAction(actionName, item);
                }}
            >
                <AutoEllipsis
                    style={{ paddingLeft: w }}
                    text={
                        <>
                            {count !== 0 && (
                                <span
                                    style={count === 1 ? { top: -2 } : {}}
                                    className="action-param-customer-minitags"
                                >
                                    {prepend}
                                </span>
                            )}
                            {value}
                        </>
                    }
                />
            </a>
        );
    }
    return null;
});
