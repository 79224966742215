import React from 'react';
import { IActionWidgetItem, IItemOptionItem, IWidgetItem } from '@/components';
import { OptionsList } from '@/components/common/form/widgets';
import { Tooltip, Icon } from 'antd';
import { alwaysSuccess } from '@/utils';
import _ from 'lodash';

export interface IForm2DemoData {
    name: string;
    gender: string[];
    accountStatus: boolean;
}

export const validators: {
    [key in keyof IForm2DemoData]?: Validator<IForm2DemoData>;
} = {
    name: (datum): ValidateStatus => {
        const name = datum.name;
        if (_.trim(name) === '') {
            return {
                status: 'fail',
                msg: '姓名不能为空',
            };
        }
        const isValid = name !== 'trump';
        return {
            status: isValid ? 'success' : 'fail',
            msg: isValid ? '' : '姓名不能为trump',
        };
    },
    gender: datum => {
        const gender = datum.gender;
        const name = datum.name;
        if (gender.length === 0) {
            return {
                status: 'fail',
                msg: '性别不能为空',
            };
        }
        if (name === 'shaomingquan' && gender.toString() !== '1') {
            return {
                status: 'fail',
                msg: 'shaomingquan应为男性',
            };
        }
        return {
            status: 'success',
            msg: '',
        };
    },
    accountStatus: datum => {
        const name = datum.name;
        const accountStatus = datum.accountStatus;
        if (name === '狗子' && accountStatus === false) {
            return {
                status: 'fail',
                msg: '不可关闭狗子账号',
            };
        }
        return {
            status: 'success',
            msg: '',
        };
    },
};

export interface IForm2DemoExtraData {
    genderLoading: boolean;
}

export const widgets = ({
    genderData,
}: {
    genderData: OptionsList<string>;
}): Array<IWidgetItem<IForm2DemoData, IForm2DemoExtraData>> => {
    return [
        {
            key: 'name',
            widgetKey: 'input',
            inputOptions: {
                placeholder: '并输入姓名',
            },
            beforeLabel: (
                <Tooltip title="名字">
                    <Icon type="question-circle" />
                </Tooltip>
            ),
        },
        {
            key: 'gender',
            widgetKey: 'select',
            selectOptions: {
                mode: 'multiple',
                placeholder: '请输入性别',
                options: genderData,
            },
            status: {
                loading: (data, extra) => {
                    return !!extra.genderLoading;
                },
            },
        },
        {
            key: 'accountStatus',
            widgetKey: 'switch',
            switchOptions: {
                checkedChildren: '开启',
                unCheckedChildren: '关闭',
            },
        },
    ];
};

export const actionWidgets: Array<IActionWidgetItem<
    IForm2DemoData,
    IForm2DemoExtraData
>> = [
    {
        key: 'submit',
        option: {
            btnText: '提交',
            type: 'primary',
        },
    },
    {
        key: 'cancel',
        option: {
            btnText: '取消',
            clickActionType: 'cancel',
            type: 'default',
        },
    },
];

export const formItemOptions: Array<IItemOptionItem<IForm2DemoData>> = [
    {
        key: 'name',
        label: '姓名',
        required: true,
    },
    {
        key: 'gender',
        label: '性别',
        required: true,
    },
    {
        key: 'accountStatus',
        label: '账户状态',
    },
];
