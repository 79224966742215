import { useEffect, useState } from 'react';
import _ from 'lodash';
import EE from 'eventemitter3';

export const refreshEE = new EE();

export const tryRefresh = (keysToRefresh: string[]) => {
    refreshEE.emit('refresh', keysToRefresh);
};

export const useRefresh = (
    refreshKeys: string[],
    reloadCb: () => void,
    deps?: any[],
) => {
    useEffect(() => {
        const handler = (keysToRefresh?: string[]) => {
            // 当前写入需要刷新哪些key
            if (!_.isArray(keysToRefresh)) {
                return;
            }
            // 当前reload回调回被哪些key影响
            if (!_.isArray(refreshKeys)) {
                return;
            }
            // 有交集，说明被影响了
            const shouldRefresh =
                _.intersection(keysToRefresh, refreshKeys).length > 0;
            if (shouldRefresh) {
                reloadCb();
            }
        };
        refreshEE.on('refresh', handler);
        return () => {
            refreshEE.removeListener('refresh', handler);
        };
    }, [reloadCb, refreshKeys, [...(deps || [])]]);
};

export const useNet = <T = any>(
    req: () => Promise<[T | null, Error | null]>,
    options?: {
        defaultLoading?: boolean;
        autoLoad?: boolean;
        depWithReq?: boolean;
        refreshKeys?: string[];
    },
): [Error | null, boolean, () => void, T | null] => {
    const defaultLoading = _.isNil(options)
        ? true
        : _.isNil(options.defaultLoading)
        ? true
        : options.defaultLoading;

    const autoLoad = _.isNil(options)
        ? true
        : _.isNil(options.autoLoad)
        ? true
        : options.autoLoad;

    const depWithReq = _.isNil(options)
        ? false
        : _.isNil(options.depWithReq)
        ? false
        : options.depWithReq;

    const refreshKeys = _.isNil(options)
        ? []
        : _.isNil(options.refreshKeys)
        ? []
        : options.refreshKeys;

    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState<boolean>(defaultLoading);
    const [inited, setInited] = useState<boolean>(false);
    const [reloadId, setReloadId] = useState(0);
    const reload = () => {
        setReloadId(reloadId + 1);
    };
    useEffect(() => {
        if (reloadId === 0 && autoLoad === false) {
            return;
        }
        setLoading(true);
        req().then(([d, e]) => {
            if (null === d && null === e) {
                return;
            }
            setData(d);
            setError(e);
            setLoading(false);
            setInited(true);
        });
    }, [depWithReq ? req : null, reloadId, autoLoad]);

    useRefresh(refreshKeys, reload);

    return [error, loading, reload, data];
};
