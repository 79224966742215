import React, { useState, useCallback, useEffect, ReactNode } from 'react';
import { InputNumber, Input, Alert } from 'antd';
import { getCommonStatus, ICommonProps } from '../common';
import _ from 'lodash';
import './enum.scss';
import Checkbox, {
    CheckboxGroupProps,
    CheckboxProps,
    CheckboxChangeEvent,
} from 'antd/lib/checkbox';
import CheckboxGroup, { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { isObservableValue } from 'mobx/dist/internal';

export type FilterEnumValueType = CheckboxValueType[];
export interface IFilterEnumOptions extends CheckboxGroupProps {
    itemProps?: CheckboxProps;
}

export interface IWidgetFilterEnumProps<T = any> extends ICommonProps<T> {
    value: FilterEnumValueType;
    options: IFilterEnumOptions;
    onChange: (value: FilterEnumValueType) => void;
}

export const WidgetFilterEnum: React.FC<IWidgetFilterEnumProps> = ({
    value,
    options,
    onChange,
    onInteract = key => void 0,
    status,
    data,
    statusExtraData,
}) => {
    const [search, setSearch] = useState<undefined | string>(undefined);
    const onSearchChange = useCallback((e: any) => {
        setSearch(e?.target?.value);
    }, []);

    const { disabled } = getCommonStatus(status, data, statusExtraData);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }

    const { options: optionList = [] } = options;

    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const onCheckedAllChange = useCallback(
        (e: CheckboxChangeEvent) => {
            const nextCheckedAll = e.target.checked;
            setIndeterminate(false);
            if (nextCheckedAll) {
                setCheckAll(true);
                onChange(
                    optionList.map(item => {
                        if (_.isString(item)) {
                            return item;
                        }
                        return item.value;
                    }),
                );
            } else {
                setCheckAll(false);
                onChange([]);
            }
        },
        [optionList],
    );

    useEffect(() => {
        if (value.length === 0) {
            setCheckAll(false);
            setIndeterminate(false);
        }
    }, [value]);

    const onCheckboxGroupChange = useCallback(
        (checkedList: CheckboxValueType[]) => {
            const nextIndeterminate =
                !!checkedList.length && checkedList.length < optionList.length;
            const nextCheckAll = checkedList.length === optionList.length;
            setIndeterminate(nextIndeterminate);
            setCheckAll(nextCheckAll);
            onChange(checkedList);
        },
        [optionList],
    );

    const finalSearch = _.trim(search);
    const optionsListToDisplay = !finalSearch
        ? optionList
        : optionList.filter(item => {
              if (_.isString(item)) {
                  return item.indexOf(finalSearch) > -1;
              }
              return ('' + item.label).indexOf(finalSearch) > -1;
          });

    return (
        <div className="crm-widgets-enum-input">
            <div className="search">
                <Input
                    value={search}
                    onChange={onSearchChange}
                    placeholder="字段搜索"
                />
            </div>
            <div className="select-all">
                <Checkbox
                    indeterminate={indeterminate}
                    checked={checkAll}
                    onChange={onCheckedAllChange}
                >
                    全选
                    <b>
                        （已选{value?.length}/{optionList?.length}）
                    </b>
                </Checkbox>
            </div>
            <div className="options">
                <CheckboxGroup onChange={onCheckboxGroupChange} value={value}>
                    {!!optionsListToDisplay.length ? (
                        optionsListToDisplay.map(option => {
                            let name: ReactNode = '';
                            let value: CheckboxValueType;
                            if (_.isString(option)) {
                                name = option;
                                value = option;
                            } else {
                                name = option.label;
                                value = option.value;
                            }
                            return (
                                <div key={'' + value} className="checkbox-item">
                                    <Checkbox value={value + ''}>
                                        {name}
                                    </Checkbox>
                                </div>
                            );
                        })
                    ) : !!finalSearch ? (
                        <Alert
                            type="warning"
                            message={`“${finalSearch}”没有选项`}
                        />
                    ) : (
                        <Alert type="warning" message={'选项列表为空'} />
                    )}
                </CheckboxGroup>
            </div>
        </div>
    );
};
