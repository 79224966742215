import React, { useState, useEffect } from 'react';
import './index.scss';
import { observer } from 'mobx-react-lite';
import { TableLv2 } from '@/components';
import moment from 'moment';
import { defaultAxios } from '@/utils';

export interface ICustomerMaiTrendFinanceData {
    financeTime: string;
    financeRound: string;
    financeMoney: string;
    financeInvestor: string;
    customerValuation: string;
    orangeId: string | number;
}

export interface ICustomerMaiTrendFinanceTableProps {
    cName?: number | string;
}

const CustomerMaiTrendFinanceTable: React.FC<ICustomerMaiTrendFinanceTableProps> = props => {
    const [maiTrendFinanceData, setMaiTrendFinanceData] = useState<
        ICustomerMaiTrendFinanceData[]
    >([]);
    const [maiTrendFinanceLoading, setMaiTrendFinanceLoading] = useState(false);
    useEffect(() => {
        fetchCustomerMaiTrendFinanceData();
    }, []);
    const fetchCustomerMaiTrendFinanceData = async () => {
        if (props.cName) {
            setMaiTrendFinanceLoading(true);
            const url = '/bff/api/rest/customer/finance-info';
            const [d, e] = await defaultAxios.get(url, {
                customerName: props.cName,
            });
            setMaiTrendFinanceLoading(false);
            if (d === null || d.data === null) {
                return [null, e];
            }
            const data = d.data;
            const error = e as Error | null;
            if (d && d.data) {
                setMaiTrendFinanceData(d.data);
            }
            return [data, error];
        }
    };
    const jumpToDetail = (id: string) => {
        window.open(`https://www.itjuzi.com/company/${id}`, '_blank');
    };
    // useEffect(() => {
    //     fetchCustomerMaiTrendFinanceData();
    // }, []);
    return (
        <TableLv2<ICustomerMaiTrendFinanceData>
            dataSource={maiTrendFinanceData}
            loading={maiTrendFinanceLoading}
            columns={[
                {
                    key: 'financeTime',
                    title: '时间',
                    dataIndex: 'financeTime',
                    width: 100,
                    render: v => {
                        return v ? moment(v).format('YYYY-MM-DD') : '';
                    },
                },
                {
                    key: 'financeRound',
                    title: '轮次',
                    dataIndex: 'financeRound',
                    width: 90,
                },
                {
                    key: 'financeMoney',
                    title: '金额',
                    dataIndex: 'financeMoney',
                    width: 120,
                },
                {
                    key: 'financeInvestor',
                    title: '投资方',
                    dataIndex: 'financeInvestor',
                    width: 120,
                },
                // {
                //     key: 'customerValuation',
                //     title: '最新估值（估算）',
                //     dataIndex: 'customerValuation',
                //     width: 90,
                // },
                {
                    key: 'detail',
                    title: '详情',
                    render: (item: ICustomerMaiTrendFinanceData) => {
                        return item.orangeId ? (
                            <span
                                onClick={() =>
                                    jumpToDetail(item.orangeId as string)
                                }
                                style={{ color: '#0052ff', cursor: 'pointer' }}
                            >
                                查看详情
                            </span>
                        ) : (
                            '- -'
                        );
                    },
                },
            ]}
            pagination={false}
            closeRowSelection={true}
        />
    );
    return null;
};

export default observer(CustomerMaiTrendFinanceTable);
