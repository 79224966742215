import React, { memo } from 'react';
import { DForm } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { EquityListStore } from '../store';

const DemoForm: React.FC<{
    defaultStore: EquityListStore;
}> = ({ defaultStore }) => {
    const { actionType } = defaultStore;

    let eventCode = '';
    if (actionType === 'create') {
        eventCode = 'CREATE-EDIT';
    } else if (actionType === 'update') {
        eventCode = 'PUBLIC-EDIT';
    }

    if (eventCode === '') {
        return null;
    }

    const form = <DForm eventCode={eventCode} defaultStore={defaultStore} />;
    return form;
};

const FinalDemoForm = memo(observer(DemoForm));
export default FinalDemoForm;
