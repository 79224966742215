import React, { useState, useEffect, useContext, useMemo } from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { LeadListStore } from '@/pages/sales-follow-up/lead/list/store';
import { runInAction, toJS } from 'mobx';
import CustomerDrawer from '@/components/common/customer-drawer';
import { StoreContext } from '@/stores';
import { defaultWritingDataParser, tryRefresh, arrToJson } from '@/utils';
import { message, Menu, Button, Tooltip, Dropdown, Icon, Spin } from 'antd';
import { IAddFollowLogPropsFromEventOrProps } from '@/components/common/add-follow-log-new';
import { LeadOptCreate } from '@/components/common/lead-drawer/lead-opt-create';
import { LeadContactsCreate } from '@/components/common/lead-drawer/lead-contacts-create';
import { defaultStore } from '@/containers/hoc/call-center';
import {
    isInValidLead,
    getFollowStatusesByOriginalFollowStatus,
} from '@/pages/sales-follow-up/lead/list/utils';
import { AntDropdownButton, AntSpin } from '@/components/antd';
import { CC } from '@/components/common/table/cells/cc';
import moment from 'moment';
import { useRequest } from 'ahooks';

// import './hack.scss'

const FastLeadStatusSelect: React.FC<{
    defaultStore: LeadListStore;
    id: number;
}> = observer(({ defaultStore, id }) => {
    const { defaultMeta } = defaultStore;
    const { inited } = defaultMeta;

    const { data: mutatingData, loading: initLoading, refresh } = useRequest(
        () => defaultStore.fetchMutatingDataById(id),
        {
            ready: !!id,
        },
    );

    const [loading, setLoading] = useState(false);
    if (!inited) {
        return null;
    }
    const followStatusMetaItem = defaultMeta.objectMeta.find(
        item => item.fieldName === 'follow_status',
    );
    const options = followStatusMetaItem?.constraint?.constraintItemList;
    if (!followStatusMetaItem || !_.isArray(options)) {
        return null;
    }

    if (initLoading || !mutatingData) {
        return null;
    }

    const optItem = options.find(
        item => item.constraintValue === mutatingData.follow_status,
    );

    const originalFollowStatus = mutatingData._original_follow_status;
    const selectableFollowStatuses = getFollowStatusesByOriginalFollowStatus(
        originalFollowStatus,
    );
    const disabled = _.isEmpty(Array.from(selectableFollowStatuses));

    const loadingWrapper: (
        loading: boolean,
    ) => (ele: React.ReactElement) => React.ReactElement = loading => {
        if (!loading) {
            return ele => ele;
        }
        return ele => (
            <span>
                <Spin>{ele}</Spin>
            </span>
        );
    };

    return loadingWrapper(loading || initLoading)(
        <AntDropdownButton
            hasRight={false}
            type="primary"
            overlay={
                <Menu>
                    {options
                        .filter(option =>
                            selectableFollowStatuses.has(
                                Number(option.constraintValue),
                            ),
                        )
                        .map(item => {
                            return (
                                <Menu.Item
                                    onClick={async () => {
                                        const prevStatus =
                                            mutatingData.follow_status;
                                        mutatingData.follow_status =
                                            item.constraintValue;
                                        setLoading(true);
                                        const isSuccess = await defaultStore.update(
                                            defaultWritingDataParser(
                                                mutatingData,
                                            ),
                                        );
                                        setLoading(false);
                                        if (!isSuccess) {
                                            mutatingData.follow_status = prevStatus;
                                        } else {
                                            tryRefresh(['lead']);
                                            refresh();
                                        }
                                    }}
                                >
                                    {item.constraintLabel}
                                </Menu.Item>
                            );
                        })}
                </Menu>
            }
            buttonConfig={{
                children: optItem ? optItem.constraintLabel : '暂无跟进状态',
                size: 'small',
                type: disabled ? 'default' : 'primary',
                style: disabled
                    ? {
                        pointerEvents: 'none',
                        cursor: 'not-allowed',
                        opacity: '0.6',
                    }
                    : {},
                icon: 'down',
                // disabled, // 不好使
            }}
        />,
    );
});

const FormDemoCard: React.FC<{
    leadList: LeadListStore;
    isStandard: boolean;
    reload?: any;
}> = ({ leadList, isStandard, reload }) => {
    const store = useContext(StoreContext);
    const customerListStore = store.getDefaultCustomerListStore();

    const [leadOptCreateTrigger, setLeadOptCreateTrigger] = useState(0);
    const [leadContactsCreateTrigger, setLeadContactsCreateTrigger] = useState(
        0,
    );

    const leadEnums2Label = (field: string, value: number) => {
        const enums =
            leadList.defaultMeta.objectMetaMap[field]?.newFieldMetaItem
                ?.options || [];

        return enums.find((v: any) => v.value === value)?.label || '--';
    };

    console.log(
        'leadList11',
        toJS(leadList),
        leadList.defaultMeta.objectMetaMap,
    );

    const transferContactsTips = (data: any) => {
        const invalid = isInValidLead(data?.follow_status);

        const transformedContact = !!data?.contact_id?.originalValue;

        const transformedOpt = !!data?.opportunity_id?.originalValue;

        if (invalid) {
            return '无效线索';
        }

        if (transformedOpt) {
            return '已转商机';
        }

        if (transformedContact) {
            return '已转联系人';
        }
        return null;
    };

    const transferOpportunityTips = (data: any) => {
        const invalid = isInValidLead(data?.follow_status);

        const transformedOpt = !!data?.opportunity_id?.originalValue;

        if (invalid) {
            return '无效线索';
        }

        if (transformedOpt) {
            return '已转商机';
        }

        return null;
    };

    const dataCard = (
        <>
            <LeadOptCreate
                leadInfo={leadList.mutatingData}
                trigger={leadOptCreateTrigger}
            />
            <LeadContactsCreate
                leadInfo={leadList.mutatingData}
                trigger={leadContactsCreateTrigger}
            />

            {leadList?.data?.map(item => (
                <div className="kp-card">
                    <div className="kp-card-body">
                        <div className="kp-card-body-head">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {item.parse_uid && (
                                    <img
                                        className="kp-card-body-head-logo"
                                        src="https://i9.taou.com/maimai/p/32288/5432_6_72LktAkzGDTI9Xha"
                                    />
                                )}

                                <a
                                    className="kp-card-body-head-name"
                                    href={`/buy/lead/my-lead?id=${item.id}&target=customer-drawer`}
                                    target="_blank"
                                >
                                    {item.name}
                                </a>
                                {!!item.post && <span>（{item.post}）</span>}
                            </div>
                            <div>
                                <FastLeadStatusSelect
                                    defaultStore={leadList}
                                    id={item.id}
                                />
                            </div>
                        </div>
                        <div className="kp-card-body-center">
                            <div className="kp-card-body-center-link">
                                <span>联系方式：</span>
                                {item.phone ? (
                                    <CC
                                        args={[item.phone]}
                                        config={{
                                            customerId:
                                                item.customer_id?.originalValue,
                                            customerName: item.company_name,
                                            leadId: item.id,
                                            userName: item.name,
                                            userPosition: item.post,
                                        }}
                                        isHidden={true}
                                        sceneCode="crm_lead"
                                        outerHiddenNumber={
                                            item.phone?.slice(0, 3) + '********'
                                        }
                                    />
                                ) : (
                                    '-'
                                )}
                            </div>
                            <div>备注：{item.remark || '--'}</div>
                        </div>
                        <div className="kp-card-body-tips">
                            <div>
                                线索跟进状态：
                                {leadEnums2Label(
                                    'lead_follow_status',
                                    item.lead_follow_status,
                                )}
                            </div>
                            <div className="kp-card-body-tips-time">
                                {moment(item.create_time).format(
                                    'YYYY-MM-DD HH:mm:ss',
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="kp-card-footer">
                        <div className="kp-card-footer-tips">
                            <div className="kp-card-footer-tips-source">
                                线索来源：
                                {item.lead_source_url ? (
                                    <a
                                        href={item.lead_source_url}
                                        target="_blank"
                                    >
                                        {leadEnums2Label(
                                            'lead_source',
                                            item.lead_source,
                                        ) +
                                            `（${leadEnums2Label(
                                                'lead_sub_source',
                                                item.lead_sub_source,
                                            )}）`}
                                    </a>
                                ) : (
                                    leadEnums2Label(
                                        'lead_source',
                                        item.lead_source,
                                    ) +
                                    `（${leadEnums2Label(
                                        'lead_sub_source',
                                        item.lead_sub_source,
                                    )}）`
                                )}
                            </div>
                            <div className="kp-card-footer-tips-source">
                                联系人来源：
                                {leadEnums2Label(
                                    'lead_kp_source',
                                    item.lead_kp_source,
                                )}
                            </div>
                        </div>
                        <div className="kp-card-ops">
                            <Button
                                type="link"
                                size="small"
                                onClick={() => {
                                    const cid =
                                        item?.customer_id?.originalValue;
                                    if (!cid) {
                                        return message.error('无cid');
                                    }
                                    const followConfig: IAddFollowLogPropsFromEventOrProps = {
                                        id: item.id,
                                        cid,
                                        entityType: 'lead',
                                    };
                                    customerListStore.defaultEE.emit(
                                        'save-action-log-show',
                                        followConfig,
                                    );
                                }}
                            >
                                跟进
                            </Button>
                            <Tooltip title={transferContactsTips(item)}>
                                <Button
                                    disabled={!!transferContactsTips(item)}
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                        setLeadContactsCreateTrigger(
                                            leadContactsCreateTrigger + 1,
                                        );
                                        leadList.fetchInstantlyMutatingDataById(
                                            item.id,
                                        );
                                    }}
                                >
                                    转联系人
                                </Button>
                            </Tooltip>

                            <Tooltip title={transferOpportunityTips(item)}>
                                <Button
                                    disabled={!!transferOpportunityTips(item)}
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                        setLeadOptCreateTrigger(
                                            leadOptCreateTrigger + 1,
                                        );
                                        leadList.fetchInstantlyMutatingDataById(
                                            item.id,
                                        );
                                    }}
                                >
                                    转商机
                                </Button>
                            </Tooltip>
                            <Dropdown
                                overlay={
                                    <Menu
                                        style={{
                                            width: '80px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Menu.Item
                                            key="1"
                                            onClick={() => {
                                                runInAction(() => {
                                                    leadList.setAction(
                                                        'transform',
                                                    );
                                                    leadList.setMutatingData(
                                                        item,
                                                    );
                                                });
                                            }}
                                        >
                                            转移
                                        </Menu.Item>
                                        <Menu.Item
                                            key="2"
                                            onClick={() => {
                                                runInAction(() => {
                                                    leadList.setActionAsync(
                                                        'update',
                                                        {
                                                            id: item.id,
                                                        },
                                                    );
                                                });
                                            }}
                                        >
                                            修改
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <div style={{ padding: '0 7px' }}>
                                    <Icon type="ellipsis" />
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );

    return dataCard;
};

const FinalFormTable = observer(FormDemoCard);
export default FinalFormTable;
