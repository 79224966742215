import React, { useState, useEffect, useRef } from 'react';
// import lottie from 'lottie-web';
import './index.less';

export interface ICrmLoadingProps {}
// const CrmLoading: React.FC<ICrmLoadingProps> = props => {
//     const ref = useRef<any | null>(null);
//     useEffect(() => {
//         const element = ref.current;
//         if (element) {
//             lottie.loadAnimation({
//                 container: element, // the dom element that will contain the animation
//                 renderer: 'svg',
//                 loop: true,
//                 autoplay: true,
//                 path: `${process.env.PUBLIC_URL}/quanjuloading2.json`, // the path to the animation json
//             });
//         }
//     }, []);
//     return <div ref={ref} className="crm-loading-content"></div>;
// };

// export default CrmLoading;

export const CrmLoading2: React.FC<ICrmLoadingProps> = props => {
    return (
        <div
            style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100px 100px',
                backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/blank-loading.gif')`,
            }}
            className="crm-loading-content"
        ></div>
    );
};

export const CrmLoading3: React.FC<ICrmLoadingProps> = props => {
    return (
        <div
            style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100px 100px',
                backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/blank-loading2.gif')`,
            }}
            className="crm-loading-content"
        ></div>
    );
};
