import React, { useState, useEffect, useContext } from 'react';
import './index.scss';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import {
    Input,
    DatePicker,
    Button,
    message,
    Modal,
    InputNumber,
    Radio,
    Popover,
    Icon,
    Select,
} from 'antd';
import moment from 'moment';
import { LayoutStandardCrud } from '../../../../components/layouts/standard-crud/index';
import { TableLv2, AntTabs } from '@/components';
import { IMemberListData, IMemberChildData } from './store';
import _ from 'lodash';
import ExpandedMemberRow from './ component/ExpandedMemberRow';
import { defaultAxios } from '@/utils';
import MemberListStagingPage from '../member-list-staging/index';
import MemberTuoKeTong from '../member-tuoketong/index';

const RangePicker = DatePicker.RangePicker;

export const MEMBER_IDENTITY_MAP: {
    [key: string]: any;
} = {
    1: 'v2',
    2: '老版迁移',
    3: '新版试用',
    6: '人才银行v3.0',
    7: '工作台',
    8: 'v4.1',
    10: '老版付费',
};
export const detailFieldsMap = {
    ask_talent: [
        {
            label: '使用量',
            field: 'ask_talent_send',
        },
        {
            label: '余量',
            field: 'ask_talent_left',
        },
        {
            label: '通过率',
            field: 'ask_talent_rate',
            suffix: '%',
        },
    ],
    resume: [
        {
            label: '简历总数',
            field: 'resume_total',
        },
        {
            label: '感兴趣比例',
            field: 'resume_processing_rate',
            suffix: '%',
        },
    ],
    smartInvite: [
        {
            label: '总数',
            field: 'batch_invite_balance_total',
        },
        {
            label: '剩余',
            field: 'batch_invite_balance_left',
        },
    ],
    addFr: [
        {
            label: '已兑换',
            field: 'addfr_exchange',
        },
        {
            label: '剩余',
            field: 'addfr_left',
        },
        {
            label: '已使用',
            field: 'addfr_used',
        },
    ],
    uh: [
        {
            label: '已兑换',
            field: 'uh_exchange',
        },
        {
            label: '剩余',
            field: 'uh_left',
        },
        {
            label: '已使用',
            field: 'uh_used',
        },
    ],
    exposure: [
        {
            label: '已兑换',
            field: 'exposure_exchange',
        },
        {
            label: '剩余',
            field: 'exposure_left',
        },
        {
            label: '已使用',
            field: 'exposure_used',
        },
    ],
    invite: [
        {
            label: '发送量',
            field: 'oppo_invite_send',
        },
    ],
    oppoRecruiterSend: [
        {
            label: '发送量',
            field: 'oppo_recruiter_send',
        },
    ],
    oppoJobSeekerReceive: [
        {
            label: '发送量',
            field: 'oppo_job_seeker_receive',
        },
    ],
    talentSeatNum: [
        {
            label: '总数',
            field: 'total_recruit_lic',
        },
        {
            label: '剩余待分配',
            field: 'remain_recruit_lic',
        },
    ],
    departSeatNum: [
        {
            label: '总数',
            field: 'total_dep_lic',
        },
        {
            label: '剩余待分配',
            field: 'remain_dep_lic',
        },
    ],
    childAdminNum: [
        {
            label: '总数',
            field: 'total_sub_lic',
        },
        {
            label: '剩余待分配',
            field: 'remain_sub_lic',
        },
    ],
    // directOppo: [
    //     {
    //         label: '总量',
    //         field: 'direct_oppo_monthly'
    //     }, {
    //         label: '剩余',
    //         field: 'direct_oppo_left'
    //     }, {
    //         label: '发出数',
    //         field: ''
    //     }
    // ]
};
export interface IMemberListSearchData {
    mobile?: number | string; // 管理员手机号
    cid?: number | string; // cid
    start?: number | string; // 有效期开始时间
    end?: number | string; // 有效期结束时间
}
const MemberListPage: React.FC<RouteComponentProps> = () => {
    const store = useContext(StoreContext);
    const [searchData, setSearchData] = useState<IMemberListSearchData>({
        mobile: '',
        cid: '',
        start: '',
        end: '',
    });
    const [selectedRow, setSelectedRow] = useState<IMemberListData>();
    const [addEmptyAccountNumber, setAddEmptyAccountNumber] = useState<
        number
    >();
    const [addEmptyAccountModalVis, setAddEmptyAccountModalVis] = useState(
        false,
    );
    const [delayAccountModalVis, setDelayAccountModalVis] = useState(false);
    const [modifyEqualityModalVis, setModifyEqualityModalVis] = useState(false);
    const [modifyEqualityValue, setModifyEqualityValue] = useState<{
        talentLibVersion: string | number;
        attentionCompanyNum: string | number;
        mmCoinBalance: string | number;
        batchInviteBalanceLeft: string | number;
        coIdentity: string | number;
        reachLimit: string | number;
        directOppo: string | number;
        directOppoMonthBalance: string | number;
        addFrMonthBalance: string | number;
        aiTicketNum: string | number;
        aiAccountNum: string | number;
        reportProNum: string | number;
        reportNum: string | number;
        goldAccountNum: string | number;
        goldAccountMonthlyBalance: string | number;
    }>({
        talentLibVersion: 2,
        attentionCompanyNum: 0,
        mmCoinBalance: 0, // 设置点数（人脉币）
        batchInviteBalanceLeft: 0, // 设置智能邀约（原批量邀约）次数
        coIdentity: 1, // 系统版本
        reachLimit: 0, // 剩余电话
        directOppo: 0, // 剩余沟通
        directOppoMonthBalance: 0, // 立即沟通月额度
        addFrMonthBalance: 0, // 加好友月额度
        aiTicketNum: 0,
        aiAccountNum: 0,
        reportProNum: 0,
        reportNum: 0,
        goldAccountNum: 0,
        goldAccountMonthlyBalance: 0,
    });
    const [delayModalValue, setDelayModalValue] = useState<{
        dueType?: string | number;
        dueReason?: string;
        dueDate?: any;
    }>({
        dueType: '1',
        dueReason: '',
    });
    const [equityCount, setEquityCount] = useState<number>();
    const [
        changeMobileEqualityModalVis,
        setChangeMobileEqualityModalVis,
    ] = useState(false);
    const [
        distributeEqualityModalVis,
        setDistributeEqualityModalVis,
    ] = useState(false);
    const [phoneCallNum, setPhoneCallNum] = useState<number>();
    const [addFrNum, setAddFrNum] = useState<number>();
    const [distributeReason, setDistributeReason] = useState('');
    const [selectedChild, setSelectedChild] = useState<IMemberChildData[]>([]);
    const [selectedChildCid, setSelectedChildCid] = useState<any>();

    const [updateCidModalVis, setUpdateCidModalVis] = useState(false);
    const [updateCidNum, setUpdateCidNum] = useState<number>();
    const [authMap, setAuthMap] = useState<{
        openTrial: boolean;
        closeTrial: boolean;
        createBlank: boolean;
        delay: boolean;
        modifyEquality: boolean;
        modifyMobileEquality: boolean;
        clearChildAuth: boolean;
        deleteChild: boolean;
        viewStaging: boolean;
        viewList: boolean;
        updateCid: boolean;
        recycleChild: boolean;
        distributeEquality: boolean;
    }>({
        openTrial: false,
        closeTrial: false,
        createBlank: false,
        delay: false,
        modifyMobileEquality: false,
        modifyEquality: false,
        clearChildAuth: false,
        deleteChild: false,
        viewStaging: false,
        viewList: false,
        updateCid: false,
        recycleChild: false,
        distributeEquality: false,
    });
    const [shouldRefreshChildMark, setShouldRefreshChildMark] = useState<any>(
        undefined,
    );
    const memberListStore = store.getDefaultMemberListStore();
    const memberStore = store.getDefaultMemberStore();
    const { configData } = memberStore;
    const {
        memberListLoading,
        memberListData,
        getMemberListData,
        defaultMemberListPagination,
        createEmptyAccount,
        closeTrail,
        dueDataSubmit,
        modifyEquality,
        modifyCid,
        changeMobileEquality,
        distributeChildEquality,
        exportMemberListData,
        clearStore,
    } = memberListStore;
    useEffect(() => {
        defaultMemberListPagination.pagesize = 20;
        if (searchData.cid || searchData.mobile) {
            getMemberListData(searchData);
        }
    }, [defaultMemberListPagination.pagenum]);
    const searchHandle = async () => {
        if (searchData.mobile || searchData.cid) {
            defaultMemberListPagination.pagenum = 1;
            getMemberListData(searchData);
        } else {
            message.warning('请输入cid或者手机号');
        }
    };
    useEffect(() => {
        fetchMemberAuth();
        return () => {
            clearStore();
        };
    }, []);
    // 获取客户的权限
    const fetchMemberAuth = async () => {
        const url = '/bff/api/rest/perm?mod=om:member-list';
        const stagingUrl = '/bff/api/rest/perm?mod=om:member-staging:view';
        const [sData, sError] = await defaultAxios.get(stagingUrl);
        console.log(sData);
        const [d, e] = await defaultAxios.get(url);
        if (d && d.data && d.data?.length) {
            setAuthMap({
                openTrial:
                    _.find(d.data, item => {
                        return item.key === 'om:member-list:open-try';
                    })?.visible || false,
                closeTrial:
                    _.find(d.data, item => {
                        return item.key === 'om:member-list:close-try';
                    })?.visible || false,
                createBlank:
                    _.find(d.data, item => {
                        return item.key === 'om:member-list:create-blank';
                    })?.visible || false,
                delay:
                    _.find(d.data, item => {
                        return item.key === 'om:member-list:delay';
                    })?.visible || false,
                modifyMobileEquality:
                    _.find(d.data, item => {
                        return (
                            item.key === 'om:member-list:modify-phone-rights'
                        );
                    })?.visible || false,
                modifyEquality:
                    _.find(d.data, item => {
                        return item.key === 'om:member-list:modify-rights';
                    })?.visible || false,
                clearChildAuth:
                    _.find(d.data, item => {
                        return item.key === 'om:member-list:clear-child-rights';
                    })?.visible || false,
                deleteChild:
                    _.find(d.data, item => {
                        return item.key === 'om:member-list:delete-child';
                    })?.visible || false,
                viewStaging:
                    _.find(sData.data, item => {
                        return item.key === 'om:member-staging:view';
                    })?.visible || false,
                viewList:
                    _.find(d.data, item => {
                        return item.key === 'om:member-list:view';
                    })?.visible || false,
                updateCid:
                    _.find(d.data, item => {
                        return item.key === 'om:member-list:update-cid';
                    })?.visible || false,
                recycleChild:
                    _.find(d.data, item => {
                        return item.key === 'om:member-list:recycle-child';
                    })?.visible || false,
                distributeEquality:
                    _.find(d.data, item => {
                        return (
                            item.key === 'om:member-list:distribute-equality'
                        );
                    })?.visible || false,
            });
        }
    };
    const renderCreateEmptyModal = () => {
        return (
            <Modal
                visible={addEmptyAccountModalVis}
                title={`为「${selectedRow?.co_short_name}」新增空账号`}
                onOk={async () => {
                    if (!addEmptyAccountNumber || addEmptyAccountNumber <= 0) {
                        message.warning('请输入正确的开通个数');
                        return;
                    }
                    if (selectedRow) {
                        const res = await createEmptyAccount(
                            selectedRow,
                            addEmptyAccountNumber,
                        );
                        if (res === true) {
                            message.success(
                                `已为「${selectedRow.co_short_name}」添加了「${addEmptyAccountNumber}」个空账号`,
                            );
                            setAddEmptyAccountNumber(undefined);
                            setShouldRefreshChildMark(selectedRow.id);
                            setTimeout(() => {
                                setShouldRefreshChildMark(undefined);
                            }, 0);
                            setAddEmptyAccountModalVis(false);
                        } else {
                            message.error(
                                typeof res === 'string' ? res : '添加失败',
                            );
                        }
                    }
                }}
                onCancel={() => {
                    setAddEmptyAccountNumber(undefined);
                    setAddEmptyAccountModalVis(false);
                }}
            >
                <label htmlFor="">
                    账号个数<span className="member-required-mark">*</span>
                </label>
                <InputNumber
                    onChange={value => {
                        setAddEmptyAccountNumber(value);
                    }}
                    value={addEmptyAccountNumber}
                    min={0}
                    max={10000}
                    type="number"
                />
            </Modal>
        );
    };
    const renderDelayModal = () => {
        return (
            <Modal
                title={`为「${selectedRow?.co_short_name}」延期`}
                visible={delayAccountModalVis}
                onOk={async () => {
                    if (!delayModalValue.dueDate) {
                        message.warning('延期时间不能为空');
                        return;
                    }
                    if (!delayModalValue.dueReason) {
                        message.warning('延期原因不能为空');
                        return;
                    }
                    if (selectedRow) {
                        const res = await dueDataSubmit(selectedRow, {
                            ...delayModalValue,
                        });
                        if (res === true) {
                            message.success(
                                `已为「${selectedRow.co_short_name}」延期`,
                            );
                            setDelayModalValue({
                                dueReason: '',
                                dueType: '1',
                                dueDate: undefined,
                            });
                            setDelayAccountModalVis(false);
                            getMemberListData(searchData);
                        } else {
                            message.error(
                                typeof res === 'string' ? res : '延期失败',
                            );
                        }
                    }
                }}
                onCancel={() => {
                    setDelayModalValue({
                        dueReason: '',
                        dueType: '1',
                        dueDate: undefined,
                    });
                    setDelayAccountModalVis(false);
                }}
            >
                <div className="member-delay-modal-content">
                    <div className="member-delay-flex">
                        <label style={{ marginRight: '12px' }} htmlFor="">
                            延期类型
                        </label>
                        <Radio.Group
                            onChange={e => {
                                setDelayModalValue({
                                    ...delayModalValue,
                                    dueType: e.target.value,
                                });
                            }}
                            defaultValue="1"
                            buttonStyle="solid"
                        >
                            <Radio.Button value="1">赠送</Radio.Button>
                            <Radio.Button value="2">其他</Radio.Button>
                        </Radio.Group>
                        <Popover
                            content={
                                <div>
                                    <p>赠送：正常原因延期</p>
                                    <p>
                                        其它：非正常延期情况，如配合销售签单等操作
                                    </p>
                                </div>
                            }
                        >
                            <Icon
                                type="question-circle"
                                style={{ marginLeft: '5px' }}
                            />
                        </Popover>
                    </div>
                    <div className="member-delay-flex">
                        <label htmlFor="">
                            延期日期
                            <span className="member-required-mark">*</span>
                        </label>
                        <DatePicker
                            style={{ marginBottom: '10px' }}
                            onChange={date => {
                                setDelayModalValue({
                                    ...delayModalValue,
                                    dueDate: date,
                                });
                            }}
                            value={delayModalValue.dueDate}
                        />
                    </div>
                    <div className="member-delay-flex">
                        <label
                            htmlFor=""
                            style={{ marginBottom: '5px', display: 'block' }}
                        >
                            延期原因
                            <span className="member-required-mark">*</span>
                        </label>
                        <Input.TextArea
                            onChange={e => {
                                setDelayModalValue({
                                    ...delayModalValue,
                                    dueReason: e.target.value,
                                });
                            }}
                            value={delayModalValue.dueReason}
                        />
                    </div>
                </div>
            </Modal>
        );
    };
    const renderModifyEquality = () => {
        return (
            <Modal
                title={`修改「${selectedRow?.co_short_name}」权益`}
                visible={modifyEqualityModalVis}
                onCancel={() => {
                    setModifyEqualityValue({
                        talentLibVersion: 2,
                        attentionCompanyNum: 0,
                        mmCoinBalance: 0, // 设置点数（人脉币）
                        batchInviteBalanceLeft: 0, // 设置智能邀约（原批量邀约）次数
                        coIdentity: 1, // 系统版本
                        reachLimit: 0, // 剩余电话
                        directOppo: 0, // 剩余沟通
                        directOppoMonthBalance: 0, // 立即沟通月额度
                        addFrMonthBalance: 0, // 加好友月额度
                        aiTicketNum: 0,
                        aiAccountNum: 0,
                        reportProNum: 0,
                        reportNum: 0,
                        goldAccountNum: 0,
                        goldAccountMonthlyBalance: 0,
                    });
                    setModifyEqualityModalVis(false);
                }}
                onOk={async () => {
                    if (selectedRow) {
                        console.log(
                            'selectedRow',
                            selectedRow,
                            modifyEqualityValue,
                        );
                        const res = await modifyEquality(selectedRow, {
                            ...modifyEqualityValue,
                        });
                        if (res === true) {
                            message.success(
                                `已为「${selectedRow.co_short_name}」修改权益`,
                            );
                            setModifyEqualityValue({
                                talentLibVersion: 2,
                                attentionCompanyNum: 0,
                                mmCoinBalance: 0, // 设置点数（人脉币）
                                batchInviteBalanceLeft: 0, // 设置智能邀约（原批量邀约）次数
                                coIdentity: 1, // 系统版本
                                reachLimit: 0, // 剩余电话
                                directOppo: 0, // 剩余沟通
                                directOppoMonthBalance: 0, // 立即沟通月额度
                                addFrMonthBalance: 0, // 加好友月额度
                                aiTicketNum: 0,
                                aiAccountNum: 0,
                                reportProNum: 0,
                                reportNum: 0,
                                goldAccountNum: 0,
                                goldAccountMonthlyBalance: 0,
                            });
                            setModifyEqualityModalVis(false);
                            getMemberListData(searchData);
                        } else {
                            message.error(
                                typeof res === 'string' ? res : '修改权益失败',
                            );
                        }
                    }
                }}
            >
                <div className="member-modify-equality-content">
                    <div className="member-modify-flex">
                        <label htmlFor="">系统版本：</label>
                        <Select
                            // defaultValue={String(selectedRow?.co_identity)}
                            style={{ width: 120 }}
                            onChange={(value: any) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    coIdentity: value,
                                });
                            }}
                            value={
                                modifyEqualityValue.coIdentity
                                    ? String(modifyEqualityValue.coIdentity)
                                    : undefined
                            }
                        >
                            {_.map(MEMBER_IDENTITY_MAP, (item, idx) => {
                                return (
                                    <Select.Option key={idx} value={idx}>
                                        {item}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">人才库版本：</label>
                        <Select
                            // defaultValue={selectedRow?.talent_lib_version}
                            style={{ width: 160 }}
                            value={modifyEqualityValue.talentLibVersion}
                            onChange={(value: any) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    talentLibVersion: value,
                                });
                            }}
                        >
                            {configData.talent_lib_version &&
                                configData.talent_lib_version.map(item => (
                                    <Select.Option
                                        key={item.name}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">关注公司数量：</label>
                        <InputNumber
                            onChange={value => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    attentionCompanyNum: value as any,
                                });
                            }}
                            value={
                                modifyEqualityValue.attentionCompanyNum as any
                            }
                            defaultValue={
                                selectedRow?.attention_company_nbr as number
                            }
                            type="number"
                            // max={1000}
                            min={0}
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">点数：</label>
                        <InputNumber
                            onChange={value => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    mmCoinBalance: value as any,
                                });
                            }}
                            value={modifyEqualityValue.mmCoinBalance as any}
                            defaultValue={
                                selectedRow?.mm_coin_balance_left_not_assign as number
                            }
                            type="number"
                            // max={1000}
                            min={0}
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">智能邀约（管理员）：</label>
                        <InputNumber
                            onChange={value => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    batchInviteBalanceLeft: value as any,
                                });
                            }}
                            value={
                                modifyEqualityValue.batchInviteBalanceLeft as any
                            }
                            defaultValue={
                                selectedRow?.batch_invite_balance_left as number
                            }
                            type="number"
                            // max={1000}
                            min={0}
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">虚拟电话（管理员）：</label>
                        <InputNumber
                            onChange={value => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    reachLimit: value as any,
                                });
                            }}
                            value={modifyEqualityValue.reachLimit as any}
                            defaultValue={
                                selectedRow?.reach_nbr_new_left as number
                            }
                            type="number"
                            // max={1000}
                            min={0}
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">立即沟通（管理员）：</label>
                        <InputNumber
                            onChange={value => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    directOppo: value as any,
                                });
                            }}
                            value={modifyEqualityValue.directOppo as any}
                            defaultValue={
                                selectedRow?.direct_oppo_left as number
                            }
                            type="number"
                            // max={1000}
                            min={0}
                        />
                    </div>
                    {selectedRow?.co_identity == 1 ||
                    selectedRow?.co_identity == 2 ||
                    selectedRow?.co_identity == 3 ? null : (
                        <>
                            <div className="member-modify-flex">
                                <label htmlFor="">立即沟通（月配额）：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            directOppoMonthBalance: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.directOppoMonthBalance as any
                                    }
                                    defaultValue={
                                        selectedRow?.direct_oppo_monthly as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">加好友（月配额）：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            addFrMonthBalance: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.addFrMonthBalance as any
                                    }
                                    defaultValue={
                                        selectedRow?.add_fr_monthly as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">AI点数：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            aiTicketNum: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.aiTicketNum as any
                                    }
                                    defaultValue={
                                        selectedRow?.ai_ticket_num as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">AI账号数：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            aiAccountNum: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.aiAccountNum as any
                                    }
                                    defaultValue={
                                        selectedRow?.ai_account_num as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">数据报告专业版：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            reportProNum: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.reportProNum as any
                                    }
                                    defaultValue={
                                        selectedRow?.report_pro_num as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">数据报告高级版：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            reportNum: value as any,
                                        });
                                    }}
                                    value={modifyEqualityValue.reportNum as any}
                                    defaultValue={
                                        selectedRow?.report_num as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">金牌账号：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            goldAccountNum: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.goldAccountNum as any
                                    }
                                    defaultValue={
                                        selectedRow?.gold_account_num as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">推广点数：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            goldAccountMonthlyBalance: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.goldAccountMonthlyBalance as any
                                    }
                                    defaultValue={
                                        selectedRow?.gold_account_monthly_balance as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        );
    };
    const renderUpdateChildMobileEqualityModal = (
        selectedChild: IMemberChildData[],
    ) => {
        // if(selectedChild.length === 1) {
        //     setEquityCount(selectedChild[0].report.reach_nbr)
        // }
        return (
            <Modal
                visible={changeMobileEqualityModalVis}
                title={`修改电话权益`}
                onOk={async () => {
                    if (!equityCount || equityCount <= 0) {
                        message.warning('请输入正确的电话权益上限');
                        return;
                    }
                    const res = await changeMobileEquality(
                        selectedChild,
                        3,
                        equityCount,
                    );
                    if (res === true) {
                        setChangeMobileEqualityModalVis(false);
                        setEquityCount(undefined);
                        setShouldRefreshChildMark(selectedChildCid);
                        setTimeout(() => {
                            setShouldRefreshChildMark(undefined);
                        }, 0);
                        getMemberListData(searchData);
                        message.success(`已修改电话权益`);
                    } else {
                        message.error(res.msg || '修改电话权益失败');
                    }
                }}
                onCancel={() => {
                    setChangeMobileEqualityModalVis(false);
                    setEquityCount(undefined);
                }}
            >
                <label htmlFor="">
                    每日虚拟电话权益上限
                    <span className="member-required-mark">*</span>
                </label>
                <InputNumber
                    onChange={value => {
                        setEquityCount(value);
                    }}
                    value={equityCount}
                    min={0}
                    max={10000}
                    type="number"
                />
            </Modal>
        );
    };
    const renderDistributeChildEqualityModal = (
        selectedChild: IMemberChildData[],
    ) => {
        // if(selectedChild.length === 1) {
        //     setEquityCount(selectedChild[0].report.reach_nbr)
        // }
        return (
            <Modal
                visible={distributeEqualityModalVis}
                title={`分配权益`}
                onOk={async () => {
                    if (
                        phoneCallNum ||
                        phoneCallNum === 0 ||
                        addFrNum ||
                        addFrNum === 0
                    ) {
                        if (phoneCallNum && phoneCallNum <= 0) {
                            message.warning('请正确填写电话沟通券');
                            return;
                        }
                        if (addFrNum && addFrNum <= 0) {
                            message.warning('请正确填写不过期好友券');
                            return;
                        }
                    } else {
                        message.warning('请输入电话沟通券或不过期好友券');
                        return;
                    }
                    const res = await distributeChildEquality(selectedChild, {
                        addFrNum,
                        phoneCallNum,
                        reason: distributeReason,
                    });
                    if (res === true) {
                        setDistributeEqualityModalVis(false);
                        setAddFrNum(undefined);
                        setPhoneCallNum(undefined);
                        setDistributeReason('');
                        setShouldRefreshChildMark(selectedChildCid);
                        setTimeout(() => {
                            setShouldRefreshChildMark(undefined);
                        }, 0);
                        getMemberListData(searchData);
                        message.success(`已分配子账号权益`);
                    } else {
                        message.error(res.msg || '分配子账号权益失败');
                    }
                }}
                onCancel={() => {
                    setDistributeEqualityModalVis(false);
                    setAddFrNum(undefined);
                    setPhoneCallNum(undefined);
                    setDistributeReason('');
                }}
            >
                <div className="member-modify-equality-content">
                    <div className="member-modify-flex">
                        <label htmlFor="">
                            电话沟通券
                            {/* <span className="member-required-mark">*</span> */}
                        </label>
                        <InputNumber
                            style={{
                                width: '200px',
                            }}
                            onChange={value => {
                                setPhoneCallNum(value);
                                setAddFrNum(undefined);
                                // setEquityCount(value);
                            }}
                            placeholder={'请输入额度'}
                            value={phoneCallNum}
                            maxLength={2}
                            min={0}
                            max={99}
                            type="number"
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">
                            不过期加好友券
                            {/* <span className="member-required-mark">*</span> */}
                        </label>
                        <InputNumber
                            width="200px"
                            onChange={value => {
                                setAddFrNum(value);
                                setPhoneCallNum(undefined);
                                // setEquityCount(value);
                            }}
                            style={{
                                width: '200px',
                            }}
                            placeholder={'请输入额度'}
                            value={addFrNum}
                            maxLength={5}
                            min={0}
                            max={99999}
                            type="number"
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">
                            原因
                            {/* <span className="member-required-mark">*</span> */}
                        </label>
                        <Input
                            onChange={e => {
                                setDistributeReason(e.target.value || '');
                                // setEquityCount(value);
                            }}
                            style={{
                                width: '300px',
                                display: 'inline-block',
                            }}
                            placeholder={'请输入分配原因'}
                            value={distributeReason}
                            maxLength={20}
                        />
                    </div>
                </div>
            </Modal>
        );
    };
    const renderUpdateCidModal = () => {
        // if(selectedChild.length === 1) {
        //     setEquityCount(selectedChild[0].report.reach_nbr)
        // }
        return (
            <Modal
                visible={updateCidModalVis}
                title={`更新cid`}
                onOk={async () => {
                    if (!updateCidNum || updateCidNum <= 0) {
                        message.warning('请输入正确的cid');
                        return;
                    }
                    if (String(updateCidNum).length > 20) {
                        message.warning('cid不能大于20个字符');
                        return;
                    }
                    if (selectedRow) {
                        const res = await modifyCid(selectedRow, updateCidNum);
                        if (res === true) {
                            message.success(
                                `已为「${selectedRow.co_short_name}」修改cid`,
                            );
                            const searchDataTemp = {
                                ...searchData,
                            };
                            if (searchData.cid) {
                                searchDataTemp.cid = updateCidNum;
                                setSearchData(searchDataTemp);
                            }
                            setUpdateCidNum(undefined);
                            setUpdateCidModalVis(false);
                            getMemberListData(searchDataTemp);
                        } else {
                            message.error(
                                typeof res === 'string' ? res : '修改cid失败',
                            );
                        }
                    }
                }}
                onCancel={() => {
                    setUpdateCidModalVis(false);
                    setUpdateCidNum(undefined);
                }}
            >
                <label htmlFor="">
                    cid
                    <span className="member-required-mark">*</span>
                </label>
                <InputNumber
                    onChange={value => {
                        setUpdateCidNum(value);
                    }}
                    value={updateCidNum}
                    min={0}
                    maxLength={20}
                    style={{
                        width: '200px',
                    }}
                    type="number"
                />
            </Modal>
        );
    };
    const renderMemberListPageFilter = () => {
        const rangeTimeValue: any = [
            searchData.start ? moment(searchData.start) : undefined,
            searchData.end ? moment(searchData.end) : undefined,
        ];
        return (
            <div className="member-filter-content">
                <div className="member-filter-row">
                    <div className="member-filter-item">
                        <label htmlFor="cid">cid：</label>
                        <Input
                            name="cid"
                            onChange={e => {
                                setSearchData({
                                    ...searchData,
                                    mobile: '',
                                    cid: e.target.value,
                                });
                            }}
                            value={searchData.cid}
                            onPressEnter={searchHandle}
                        />
                    </div>
                    <div className="member-filter-item">
                        <label htmlFor="mobile">管理员或子账号手机：</label>
                        <Input
                            name="mobile"
                            onChange={e => {
                                setSearchData({
                                    ...searchData,
                                    cid: '',
                                    mobile: e.target.value,
                                });
                            }}
                            value={searchData.mobile}
                            onPressEnter={searchHandle}
                        />
                    </div>
                    <div className="member-filter-item">
                        <label htmlFor="time">有效期范围：</label>
                        <RangePicker
                            name="time"
                            showTime={true}
                            format="YYYY-MM-DD"
                            value={rangeTimeValue}
                            onChange={values => {
                                setSearchData({
                                    ...searchData,
                                    start: values[0]
                                        ? moment(values[0])
                                              .startOf('day')
                                              .valueOf()
                                        : '',
                                    end: values[1]
                                        ? moment(values[1])
                                              .endOf('day')
                                              .valueOf()
                                        : '',
                                });
                            }}
                            // onOk={}
                            style={{ width: '300px' }}
                            allowClear={true}
                        />
                    </div>
                </div>
                <div className="member-button-row">
                    <Button
                        type="primary"
                        loading={memberListLoading}
                        onClick={() => {
                            searchHandle();
                        }}
                    >
                        查询
                    </Button>
                    <Button
                        type="primary"
                        disabled={memberListLoading}
                        onClick={() => {
                            if (searchData.mobile || searchData.cid) {
                                exportMemberListData(searchData);
                            } else {
                                message.warning('请输入cid或者手机号');
                            }
                        }}
                    >
                        导出
                    </Button>
                </div>
            </div>
        );
    };
    const changeChildMobileEqualityModalVis = (
        bool: boolean,
        selectedItem: IMemberChildData[],
        cid: string | number,
    ) => {
        setChangeMobileEqualityModalVis(bool);
        setSelectedChild(selectedItem);
        setSelectedChildCid(cid);
        if (selectedItem.length === 1) {
            setEquityCount(selectedItem[0].report.reach_nbr);
        }
    };
    const changeDistributeEqualityModalVis = (
        bool: boolean,
        selectedItem: IMemberChildData[],
        cid: string | number,
    ) => {
        setDistributeEqualityModalVis(bool);
        setSelectedChild(selectedItem);
        setSelectedChildCid(cid);
        // if (selectedItem.length === 1) {
        //     // setEquityCount(selectedItem[0].report.reach_nbr);
        // }
    };
    const renderMemberListTable = () => {
        // co_full_name: string | number; // 企业名称
        // bd_name: string | number; // 销售
        // co_identity: string | number; // 账号类型
        // adm_name: string | number; // 管理员
        // mbr_end_time: string | number; // 有效期至
        // license_nbr: string | number; // license额度
        // mm_coin_balance_total: string | number; // 总点数
        // mm_coin_balance_left: string | number; // 剩余点数
        // batch_invite_balance_total: string | number; // 智能邀约-总数
        // batch_invite_balance_left: string | number; // 智能邀约-剩余
        // addfr_exchange: string | number; // 加好友-已兑换
        // addfr_left: string | number; // 加好友-剩余
        // addfr_used: string | number; // 加好友-已使用
        // uh_exchange: string | number; // 极速联系-已兑换
        // uh_left: string | number; // 极速联系-剩余
        // uh_used: string | number; // 极速联系-已使用
        // exposure_exchange: string | number; // 极速曝光-已兑换
        // exposure_left: string | number; // 极速曝光-剩余
        // exposure_used: string | number; // 极速曝光-已使用
        // talent_lib_version: string | number; // 人才库版本
        // reach_nbr: string | number; // 每日虚拟电话权益上限
        // attention_company_nbr: string | number; // 关注公司数量
        // direct_oppo_monthly: string | number; // 立即沟通（月配额）
        // add_fr_monthly: string | number; // 加好友（月配额）
        const renderItemDetail = (
            fields: {
                field: string;
                label: string;
                suffix?: string;
                // tslint:disable-next-line:align
            }[],
            values: any,
        ) => {
            return (
                <div className="flex-column">
                    {fields.map(item => (
                        <span style={{ marginBottom: '6px' }} key={item.field}>
                            <span
                                style={{ width: '60px' }}
                                className="color-dilution no-break display-inline-block"
                            >
                                {item.label}:
                            </span>
                            <span className="no-break">
                                {`${values[item.field] || '- -'}${item.suffix ||
                                    ''}`}
                            </span>
                        </span>
                    ))}
                </div>
            );
        };
        return (
            <TableLv2<IMemberListData>
                loading={memberListLoading}
                dataSource={memberListData}
                closeRowSelection={true}
                pagination={{
                    ...defaultMemberListPagination.paginationForUI,
                    total: defaultMemberListPagination.amount,
                    showSizeChanger: false,
                }}
                onPaginationChange={
                    defaultMemberListPagination.setPaginationByUI
                }
                expandedRowRender={record => {
                    return memberListLoading ? null : (
                        <ExpandedMemberRow
                            cid={record.id}
                            changeChildMobileEqualityModalVis={
                                changeChildMobileEqualityModalVis
                            }
                            distributeChildEqualityModalVis={
                                changeDistributeEqualityModalVis
                            }
                            identityType={record.co_identity}
                            shouldRefreshCid={shouldRefreshChildMark}
                            authMap={authMap}
                            adminPhone={record.adm_mobile}
                        />
                    );
                }}
                scroll={{ x: '2200' }}
                actions={[
                    // {
                    //     actionKey: 'member-data',
                    //     actionCn: '导出报表',
                    // },
                    {
                        actionKey: 'close-trail',
                        actionCn: '关闭试用',
                        popconfirm: true,
                        popcomfirmConfig: {
                            title: `确定要关闭该公司的试用吗？`,
                        },
                        status: {
                            hidden: !authMap.closeTrial,
                        },
                    },
                    {
                        actionKey: 'create-empty',
                        actionCn: '新增空账号',
                        status: {
                            hidden: !authMap.createBlank,
                        },
                    },
                    {
                        actionKey: 'delay',
                        actionCn: '延期',
                        status: {
                            hidden: !authMap.delay,
                        },
                    },
                    {
                        actionKey: 'update-equality',
                        actionCn: '修改权益',
                        status: {
                            hidden: !authMap.modifyEquality,
                        },
                    },
                    {
                        actionKey: 'update-cid',
                        actionCn: '更新cid',
                        status: {
                            hidden: !authMap.updateCid,
                        },
                    },
                ]}
                onAction={async (actionKey: string, record) => {
                    setSelectedRow(record);
                    if (actionKey === 'close-trail') {
                        // 关闭试用
                        const res = await closeTrail(record.id);
                        if (res === true) {
                            message.success(
                                `已经关闭${record.co_short_name}的试用账号`,
                            );
                        } else {
                            message.error(res || '关闭试用失败');
                        }
                    }
                    if (actionKey === 'create-empty') {
                        setAddEmptyAccountModalVis(true);
                    }
                    if (actionKey === 'delay') {
                        setDelayAccountModalVis(true);
                    }
                    // 修改权益
                    if (actionKey === 'update-equality') {
                        setModifyEqualityValue({
                            talentLibVersion: record.talent_lib_version,
                            attentionCompanyNum: record.attention_company_nbr,
                            mmCoinBalance:
                                record.mm_coin_balance_left_not_assign,
                            batchInviteBalanceLeft:
                                record.batch_invite_balance_left,
                            coIdentity: record.co_identity,
                            reachLimit: record.reach_nbr_new_left,
                            directOppo: record.direct_oppo_left,
                            directOppoMonthBalance: record.direct_oppo_monthly,
                            addFrMonthBalance: record.add_fr_monthly,
                            aiTicketNum: record.ai_ticket_num,
                            aiAccountNum: record.ai_account_num,
                            reportProNum: record.report_pro_num,
                            reportNum: record.report_num,
                            goldAccountNum: record.gold_account_num,
                            goldAccountMonthlyBalance:
                                record.gold_account_monthly_balance,
                        });
                        setModifyEqualityModalVis(true);
                    }
                    if (actionKey === 'update-cid') {
                        setUpdateCidNum(record.cid);
                        setUpdateCidModalVis(true);
                    }
                }}
                actionsWidth={360}
                isFixedActions={false}
                columns={[
                    {
                        title: '企业名称',
                        key: 'co_full_name',
                        dataIndex: 'co_full_name',
                        width: '160px',
                    },
                    {
                        title: '销售',
                        key: 'bd_name',
                        width: '80px',
                        dataIndex: 'bd_name',
                    },
                    {
                        title: '账号类型',
                        dataIndex: 'co_identity',
                        key: 'co_identity',
                        width: '80px',
                        render: (
                            text: string,
                            row: IMemberListData,
                            index: number,
                        ) => {
                            return MEMBER_IDENTITY_MAP?.[text]
                                ? MEMBER_IDENTITY_MAP?.[text]
                                : null;
                        },
                    },
                    {
                        title: '管理员',
                        key: 'adm_name',
                        dataIndex: 'adm_name',
                        width: '120px',
                        render: (
                            text: string,
                            row: IMemberListData,
                            index: number,
                        ) => {
                            return (
                                <span className="flex-column">
                                    <span className="np-break">{text}</span>
                                    <span className="np-break">
                                        {row.adm_mobile}
                                    </span>
                                </span>
                            );
                        },
                    },
                    {
                        title: '有效期至',
                        key: 'mbr_end_time',
                        dataIndex: 'mbr_end_time',
                        width: '120px',
                        render: v => {
                            return v ? moment(v).format('YYYY-MM-DD') : null;
                        },
                    },
                    {
                        title: 'license额度',
                        dataIndex: 'license_nbr',
                        key: 'license_nbr',
                        width: '100px',
                        render: (v, item) => {
                            return v;
                        },
                    },
                    {
                        title: '总点数',
                        key: 'mm_coin_balance_total',
                        dataIndex: 'mm_coin_balance_total',
                        width: '80px',
                    },
                    {
                        title: '剩余点数',
                        dataIndex: 'mm_coin_balance_left',
                        key: 'mm_coin_balance_left',
                        width: '80px',
                    },
                    {
                        title: '智能邀约',
                        dataIndex: 'batch_invite_balance_total',
                        width: '120px',
                        render: (v, item) => {
                            return renderItemDetail(
                                detailFieldsMap.smartInvite,
                                item,
                            );
                        },
                    },
                    {
                        title: '加好友',
                        dataIndex: 'addfr_exchange',
                        width: '120px',
                        render: (v, item) => {
                            return renderItemDetail(
                                detailFieldsMap.addFr,
                                item,
                            );
                        },
                    },
                    {
                        title: '极速联系',
                        dataIndex: 'uh_exchange',
                        width: '120px',
                        render: (v, item) => {
                            return renderItemDetail(detailFieldsMap.uh, item);
                        },
                    },
                    {
                        title: '极速曝光',
                        dataIndex: 'exposure',
                        width: '120px',
                        render: (v, item) => {
                            return renderItemDetail(
                                detailFieldsMap.exposure,
                                item,
                            );
                        },
                    },
                    {
                        title: '人才库版本',
                        dataIndex: 'talent_lib_version',
                        width: 100,
                        render: d => {
                            const { talent_lib_version } = configData;
                            if (
                                talent_lib_version &&
                                talent_lib_version.length
                            ) {
                                return (
                                    _.find(talent_lib_version, v => {
                                        return v.id === d;
                                    })?.name || ''
                                );
                            }
                        },
                    },
                    {
                        title: '每日虚拟电话权益上限',
                        dataIndex: 'reach_nbr',
                        key: 'reach_nbr',
                        width: 150,
                    },
                    {
                        title: '关注公司数量',
                        dataIndex: 'attention_company_nbr',
                        key: 'attention_company_nbr',
                        width: 100,
                    },
                    {
                        title: '立即沟通(月配额)',
                        dataIndex: 'direct_oppo_monthly',
                        key: 'direct_oppo_monthly',
                        width: 150,
                    },
                    {
                        title: '加好友(月配额)',
                        dataIndex: 'add_fr_monthly',
                        key: 'add_fr_monthly',
                        width: 150,
                    },
                    {
                        title: 'cid',
                        dataIndex: 'cid',
                        key: 'cid',
                        width: 120,
                    },
                ]}
            />
        );
    };
    const tabConfigWithAuth = () => {
        const listConf = {
            key: '1',
            tab: '会员列表',
            content: (
                <div className="member-content om-member-list">
                    <LayoutStandardCrud
                        title={'会员列表'}
                        message={null}
                        popups={null}
                        multipleOps={null}
                        globalOps={null}
                        filters={renderMemberListPageFilter()}
                        dataTable={renderMemberListTable()}
                    />
                </div>
            ),
        };
        const stagingConf = {
            key: '2',
            tab: '会员列表（工作台）',
            content: <MemberListStagingPage />,
        };
        const tuoketongConf = {
            key: '3',
            tab: '拓客通',
            content: <MemberTuoKeTong />,
        };
        const finalConf = [];
        if (authMap.viewList) {
            finalConf.push(listConf);
        }
        if (authMap.viewStaging) {
            finalConf.push(stagingConf);
        }
        finalConf.push(tuoketongConf);
        return finalConf;
    };
    return (
        <>
            {renderCreateEmptyModal()}
            {renderDelayModal()}
            {renderModifyEquality()}
            {renderUpdateCidModal()}
            {renderDistributeChildEqualityModal(selectedChild)}
            {renderUpdateChildMobileEqualityModal(selectedChild)}
            <div className="om-member-list-content">
                {authMap.viewList || authMap.viewStaging ? (
                    <AntTabs
                        defaultActiveKey={
                            authMap.viewList
                                ? '1'
                                : authMap.viewStaging
                                ? '2'
                                : undefined
                        }
                        tabConfig={tabConfigWithAuth()}
                    ></AntTabs>
                ) : null}
            </div>
        </>
    );
};

export default observer(MemberListPage);
