import React, { useState, useEffect, useContext } from 'react';
import {
    DTable,
    applyCustomConfToCellsSelector,
    isMultipleCell,
    makeObjColClickable,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { OptListStore, useAnyOptStore } from '../store';
import { runInAction } from 'mobx';
import DefaultDrawer from '../drawer';
import { StoreContext } from '@/stores';
import ParseTrans from './parse-trans';
import { useCustomerDrawerSnippets } from '@/components/common/customer-drawer/hooks';
import { tryRefresh, useQueryAsObject } from '@/utils';
import { useLeadDrawerSnippets } from '@/components/common/lead-drawer/hooks';
import { OptContractCreate } from '../drawer/opt-contract-create';
import { message } from 'antd';
import { useOptDrawerSnippets } from '../drawer/hooks';
import { useContractDrawerSnippets } from '@/pages/om/contract/list/drawer/hooks';
import { OptExtra } from './extra';

export const newContractBtnRender: (
    data: any,
    original: React.ReactElement | null,
) => React.ReactElement | null = (data: any, original) => {
    const wr = data?.opportunity_sale_stage?.relatedObj?.win_rate;
    if (wr < 40) {
        return <span style={{ color: 'gray' }}>新建合同</span>;
    }
    if (!!data.contract_id?.originalValue) {
        return <span style={{ color: 'gray' }}>新建合同</span>;
    }
    return original;
};

const FormDemoTable: React.FC<{
    defaultStore: OptListStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const contractCreateVisible = authStore.userInfo.contractCreateVisible;
    const isOm = window.location.pathname.indexOf('/om') > -1;
    const ccV = isOm ? contractCreateVisible?.om : contractCreateVisible?.buy;

    const { defaultMSelect } = defaultStore;
    const [drawerVis, setDrawerVis] = useState(false);
    const [detailId, setDetailId] = useState(undefined);

    const [______, drawerSnippet] = useOptDrawerSnippets({
        drawerVis,
        setDrawerVis,
        itemId: detailId,
    });

    const [contractDrawerLid, setContractDrawerCid] = useState(undefined);
    const [contractDrawerVis, setContractDrawerVis] = useState(false);
    const [_________, cusContractSnippet] = useContractDrawerSnippets({
        drawerVis: contractDrawerVis,
        setDrawerVis: setContractDrawerVis,
        itemId: contractDrawerLid,
    });

    const [
        parseTransOptStoreReady,
        parseTransOptStore,
        prepareParseTransOptStore,
    ] = useAnyOptStore({
        autoLoad: false,
    });
    useEffect(() => {
        if (!drawerVis) {
            parseTransOptStore.setMutatingData(null);
            setDetailId(undefined);
            return;
        }
        prepareParseTransOptStore();
    }, [drawerVis]);

    const [customerDrawerCid, setCustomerDrawerCid] = useState(undefined);
    const [customerDrawerVis, setCustomerDrawerVis] = useState(false);
    const [___, cusDrawerSnippet] = useCustomerDrawerSnippets({
        drawerVis: customerDrawerVis,
        setDrawerVis: setCustomerDrawerVis,
        cid: customerDrawerCid,
    });

    const [leadDrawerLid, setLeadDrawerLid] = useState(undefined);
    const [leadDrawerVis, setLeadDrawerVis] = useState(false);
    const [____, cusLeadSnippet] = useLeadDrawerSnippets({
        drawerVis: leadDrawerVis,
        setDrawerVis: setLeadDrawerVis,
        itemId: leadDrawerLid,
    });

    useQueryAsObject(queryObj => {
        if (queryObj.id) {
            setDetailId(queryObj.id);
            setDrawerVis(true);

            // 阶段转移需要
            parseTransOptStore.fetchInstantlyMutatingDataById(queryObj.id);
        }
    });

    const [optContractCreateTrigger, setOptContractCreateTrigger] = useState(0);

    const dataTable = (
        <>
            {drawerSnippet}
            {cusDrawerSnippet}
            {cusLeadSnippet}
            {cusContractSnippet}
            <OptContractCreate
                trigger={optContractCreateTrigger}
                optInfo={defaultStore.mutatingData}
                outerCtxInfo={{
                    cid: defaultStore.mutatingData?.customer_id?.originalValue,
                }}
            />
            <DTable
                defaultStore={defaultStore}
                sceneCode={'crm_opt'}
                onColumnsUpdated={() => tryRefresh(['opt'])}
                cellsSelectorTransformer={originalbaiscCellsSelector => {
                    const baiscCellsSelector = [...originalbaiscCellsSelector];
                    // 自定义cell
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'opportunity_name',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'ActionParam';
                            nextCellSelector.inputs.push({
                                paramName: 'opportunity_name',
                            });
                            return nextCellSelector;
                        },
                    );

                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'customer_recent_activity_record_time',
                        cellSelector => {
                            if (isMultipleCell(cellSelector)) {
                                return cellSelector;
                            }
                            cellSelector.cell = 'LastestActivityRecord';
                            return cellSelector;
                        },
                    );

                    makeObjColClickable(baiscCellsSelector, 'customer_id');

                    makeObjColClickable(baiscCellsSelector, 'lead_id');

                    makeObjColClickable(baiscCellsSelector, 'contract_id');

                    return baiscCellsSelector;
                }}
                propsTransformer={props => {
                    // 自定义table props
                    props.loading = loading;
                    props.actions = [];
                    props.actionsWidth = 172;
                    props.actions.push({
                        actionKey: 'update',
                        actionCn: '修改',
                    });
                    if (ccV) {
                        props.actions.push({
                            actionKey: 'new-contract',
                            actionCn: '新建合同',
                            render: newContractBtnRender,
                            force: true,
                        });
                    }
                    props.actions.push({
                        actionKey: 'delete',
                        actionCn: '删除',
                    });

                    props.onAction = (key, data) => {
                        if (key === 'new-contract') {
                            // runInAction(() => {
                            //     defaultStore.setActionAsync('update', {
                            //         id: data.id,
                            //     });
                            // });
                            runInAction(() => {
                                setOptContractCreateTrigger(
                                    optContractCreateTrigger + 1,
                                );
                                // defaultStore.fetchInstantlyMutatingDataById(data.id)
                                defaultStore.setMutatingData({
                                    id: data.id,
                                    customer_id: data.customer_id,
                                });
                            });
                        } else if (key === 'update') {
                            defaultStore.setAction('update', data);
                        } else if (key === 'delete') {
                            runInAction(() => {
                                defaultStore.setAction('delete');
                                defaultStore.setMutatingData(data);
                            });
                        } else if (key === 'transform') {
                            // 其他动作
                        } else if (_.startsWith(key, 'reveal-obj/')) {
                            // 关联对象都可以点击
                            if (key.indexOf('/customer_id') !== -1) {
                                setCustomerDrawerCid(
                                    data.customer_id?.originalValue,
                                );
                                setCustomerDrawerVis(true);
                            }

                            if (key.indexOf('/lead_id') !== -1) {
                                setLeadDrawerLid(data.lead_id?.originalValue);
                                setLeadDrawerVis(true);
                            }

                            if (key.indexOf('/contract_id') !== -1) {
                                setContractDrawerCid(
                                    data.contract_id?.originalValue,
                                );
                                setContractDrawerVis(true);
                            }
                        } else if (_.startsWith(key, 'reveal-param/')) {
                            // 可点击参数，召唤drawer
                            if (key.indexOf('/opportunity_name') !== -1) {
                                setDetailId(data.id);
                                setDrawerVis(true);

                                // 阶段转移需要
                                parseTransOptStore.fetchInstantlyMutatingDataById(
                                    data.id,
                                );
                            }
                        }
                    };
                    props.extra = <OptExtra defaultStore={defaultStore} />;
                    props.closeRowSelection = false;
                    props.selectedItems = defaultMSelect.mSeletedData;
                    props.onSelectedDataChange =
                        defaultMSelect.setMSelectedData;
                    props.widthInfoKey = 'opt-list-default';
                    return props;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalFormTable = observer(FormDemoTable);
export default FinalFormTable;
