import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { AntButton, BlockLoading2, DForm } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { AntDrawer } from '@/components/antd/drawer';
import { defaultAuthDeco } from '@/containers/hoc';
import { ConfRoleDiscountListStore } from '../store';
import { tryRefresh } from '@/utils';

const DemoForm: React.FC<{
    defaultStore: ConfRoleDiscountListStore;
}> = ({ defaultStore }) => {
    const { actionType } = defaultStore;

    let eventCode = '';
    if (actionType === 'create') {
        eventCode = 'CREATE-EDIT';
    } else if (actionType === 'update') {
        eventCode = 'PUBLIC-EDIT';
    }

    if (eventCode === '') {
        return null;
    }

    const form = <DForm eventCode={eventCode} defaultStore={defaultStore} />;
    return form;
};

const FormDrawer: React.FC<RouteComponentProps & {
    defaultStore: ConfRoleDiscountListStore;
    preRequiredReady: boolean;
}> = ({ defaultStore, preRequiredReady }) => {
    const { actionType, bigFormVisible, mutatingDataLoading } = defaultStore;

    if (!preRequiredReady) {
        return <BlockLoading2 />;
    }

    const popups = (
        <>
            <AntDrawer
                title={actionType === 'create' ? '新建配置' : '修改配置'}
                placement={'right'}
                visible={bigFormVisible}
                onClose={() => {
                    defaultStore.resetAction();
                }}
                bodyStyle={{
                    width: 850,
                    overflow: 'scroll',
                }}
                width={850 + 'px'}
            >
                <div className="standard-drawer-form-wrapper">
                    {mutatingDataLoading ? (
                        <BlockLoading2 />
                    ) : (
                        <DemoForm defaultStore={defaultStore} />
                    )}
                    <div className="fixed-form-btns">
                        <AntButton
                            size="large"
                            type="primary"
                            onClick={async () => {
                                const isSuccess = await defaultStore.dispatchAction();
                                if (isSuccess) {
                                    tryRefresh(['contract_sign_approval_conf']);
                                }
                            }}
                        >
                            {actionType === 'create' ? '新建' : '修改'}
                        </AntButton>
                        <AntButton
                            size="large"
                            onClick={() => defaultStore.resetAction()}
                        >
                            取消
                        </AntButton>
                    </div>
                </div>
            </AntDrawer>
        </>
    );

    return popups;
};

const FinalFormDrawer = defaultAuthDeco(observer(FormDrawer));
export default FinalFormDrawer;
