import React, {
    CSSProperties,
    PropsWithChildren,
    Children,
    ReactNode,
    useState,
    useEffect,
} from 'react';
import {
    Widgets,
    WidgetsKeys,
    IWidgetsOptions,
    IWidgetsValueTypes,
    ICommonStatus,
    getCommonStatus,
    InnerInteractCallback,
    ICommonProps,
} from '@/components/common/form/widgets';
import _ from 'lodash';
import { Form, Icon, Tooltip } from 'antd';
import { FormItemProps, FormProps } from 'antd/lib/form';
import { arrToJson, isZeroValue2, isRelatedObjValue } from '@/utils';
import classname from 'classname';
import './index.scss';
import { TCell } from '../table';
import { allCells } from '../table/cells';
import { AntButton } from '@/components/antd';

export interface IWidgetEditableHocProps<T = any> {
    widgetKey: WidgetsKeys;
    record: T;
    formWidgetProps: any;
    cellItem: any;
    isEditing: boolean;
    editable?: boolean;
    onAction?: (actionKey: string, datum: T) => void;
    isMultipleEditing?: boolean;
    emptyText?: string;
    iconVisibleWhenHover?: boolean;
}

/**
 * 其实就是将cells 体系和formWidgets 体系结合起来了
 */

export const WidgetEditable: React.FC<IWidgetEditableHocProps> = ({
    widgetKey,
    record,
    formWidgetProps,
    cellItem,
    isEditing,
    editable,
    onAction,
    isMultipleEditing,
    emptyText,
    iconVisibleWhenHover,
}) => {
    const WCom = Widgets[widgetKey];
    const { onInteract = () => void 0, k } = formWidgetProps;
    isMultipleEditing = _.isUndefined(isMultipleEditing)
        ? false
        : isMultipleEditing;
    editable = _.isUndefined(editable) ? true : editable;
    emptyText = emptyText || '未填写';

    const { cell, inputs } = cellItem;
    const CellCom = (allCells as any)[cell] as any;
    if (!CellCom || !WCom) {
        return (
            <div className="component-widgets-editalbe">
                元信息有误 CellCom: {!!CellCom}, WCom: {!!WCom}
            </div>
        );
    }

    // TODO， multi cells
    const args = [
        ...inputs.map((input: any) => {
            if (_.isString(input)) {
                const v = (record as any)[input];
                if (v !== undefined) {
                    return v;
                }
                return input;
            }
            return input;
        }),
    ];

    if (k === 'contract_amount') {
        // console.log(
        //     'vaseraefargawrafsrvas',
        //     widgetKey,
        //     record,
        //     formWidgetProps,
        //     cellItem,
        //     isEditing,
        //     editable,
        //     onAction,
        //     isMultipleEditing,
        //     emptyText,
        // )
    }

    return (
        <div className="mixed-widget">
            {!isEditing && (
                <div
                    className="display"
                    // onClick={() => {
                    //     onInteract('start-editing-display-param');
                    // }}
                >
                    {/* 如果是zeroValue，则提示请输入 */}
                    {isZeroValue2(formWidgetProps.value) ? (
                        <span className="empty">{emptyText}</span>
                    ) : (
                        <CellCom
                            args={args}
                            item={record}
                            onAction={onAction || (() => void 0)}
                        />
                    )}
                    {editable && (
                        <div
                            className={
                                !!iconVisibleWhenHover
                                    ? 'visible-when-hover'
                                    : ''
                            }
                            style={{
                                backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/mis/edit.png')`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '100%',
                                height: 12,
                                width: 12,
                                marginLeft: 4,
                            }}
                        />
                    )}
                </div>
            )}
            {isEditing && (
                <div className="eidting">
                    <WCom {...formWidgetProps} />
                    {/* 只有单个的时候才有 */}
                    {!isMultipleEditing && (
                        <>
                            &nbsp;
                            <AntButton
                                onClick={() => {
                                    onInteract('confirm-editing-display-param');
                                }}
                                size="small"
                                type="link"
                                icon="check"
                                style={{ color: '#3ed711' }}
                            ></AntButton>
                            <AntButton
                                onClick={() => {
                                    onInteract('cancel-editing-display-param');
                                }}
                                size="small"
                                type="link"
                                icon="close"
                                style={{ color: '#ff001f' }}
                            ></AntButton>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
