import React, { useState, useEffect, CSSProperties } from 'react';
import { Icon, Statistic, Spin, Select } from 'antd';
import moment from 'moment';
import './index.less';
import { AntButton } from '@/components';
import _ from 'lodash';
import {
    IAchievementSituationFilterData,
    trans2W,
    trans2DotTwo,
} from '../achievement-situation';
import {
    targetLevelOptions,
    areaOptions,
    rangeOptions,
} from '../achievement-situation/index';
export interface IOptAnalysisCompositeData {
    opportunity_amount?: number; // 商机金额合计
    opportunity_amount20?: number; // 20商机金额
    opportunity_amount40?: number; // 40商机金额
    opportunity_amount60?: number; // 60商机金额
    opportunity_amount80?: number; // 80商机金额
    opportunity_amount6080?: number; // 6080商机金额
    opportunity_gap?: number; // 商机GAP
    opportunity_amount_rate?: number; // 商机/GAP
    income_gap?: number; // 业绩目标gap
    industry_customer_money: {
        [key: string]: number;
    };
    industry_customer_num: {
        [key: string]: number;
    };
    industry_customer_arpu: {
        [key: string]: number;
    };
    [key: string]: any;
}
const optAnalysisValueMap: {
    title: string;
    key: string;
    formatter: any;
    preText?: string;
    prodText?: string;
}[] = [
    {
        title: '60+80+90商机金额',
        key: 'opportunity_amount6080',
        formatter: (v: any) => `${trans2W(v)}`,
        prodText: 'W',
    },
    {
        title: '商机金额合计',
        key: 'opportunity_amount',
        formatter: (v: any) => `${trans2W(v)}`,
        prodText: 'W',
    },
    {
        title: '20商机金额',
        key: 'opportunity_amount20',
        formatter: (v: any) => `${trans2W(v)}`,
        prodText: 'W',
    },
    {
        title: '40商机金额',
        key: 'opportunity_amount40',
        formatter: (v: any) => `${trans2W(v)}`,
        prodText: 'W',
    },
    {
        title: '60商机金额',
        key: 'opportunity_amount60',
        formatter: (v: any) => `${trans2W(v)}`,
        prodText: 'W',
    },
    {
        title: '80商机金额',
        key: 'opportunity_amount80',
        formatter: (v: any) => `${trans2W(v)}`,
        prodText: 'W',
    },
    {
        title: '90商机金额',
        key: 'opportunity_amount90',
        formatter: (v: any) => `${trans2W(v)}`,
        prodText: 'W',
    },
];
const CompositeOptAnalysis: React.FC<{
    compositeOptAnalysisData?: IOptAnalysisCompositeData;
    dataLoading: boolean;
    asFilterData: IAchievementSituationFilterData;
    setAsFilterData: (fData: IAchievementSituationFilterData) => void;
}> = props => {
    const { compositeOptAnalysisData, asFilterData, setAsFilterData } = props;
    const statisticValueStyle: CSSProperties = {
        fontSize: '16px',
        // color: '#0052ff',
        fontWeight: 500,
    };
    const statisticCanClickValueStyle: CSSProperties = {
        fontSize: '16px',
        color: '#0052ff',
        fontWeight: 500,
        cursor: 'pointer',
    };
    const renderTipsText = () => {
        if (!compositeOptAnalysisData?.opportunity_gap) {
            return <span className="c-o-a-tips-all">- -</span>;
        }
        const status =
            compositeOptAnalysisData.opportunity_gap > 0 ? 'error' : 'success';
        const text =
            compositeOptAnalysisData.opportunity_gap > 0 ? '缺口' : '超出';

        const incomeGapW = Math.round(
            (compositeOptAnalysisData?.income_gap || 0) / 10000,
        );
        const income6080W = Math.round(
            (compositeOptAnalysisData.opportunity_amount6080 || 0) / 10000,
        );
        let optGapW = Math.round(
            (compositeOptAnalysisData?.opportunity_gap || 0) / 10000,
        );
        if (optGapW < 0) {
            optGapW = -optGapW;
        }
        return (
            <span className="c-o-a-tips-all">
                解读（供参考）：业绩目标GAP为
                <Statistic
                    value={incomeGapW}
                    style={{
                        fontSize: '12px',
                        color: '#6E727A',
                        display: 'inline',
                    }}
                ></Statistic>
                万，目前60+80+90储备金额为
                <Statistic
                    value={income6080W}
                    style={{
                        fontSize: '12px',
                        color: '#6E727A',
                        display: 'inline',
                    }}
                ></Statistic>
                万，
                <span className={`c-o-a-tips-text ${status}`}>
                    {text}
                    <Statistic
                        value={optGapW}
                        style={{
                            fontSize: '12px',
                            display: 'inline',
                        }}
                    ></Statistic>
                    万
                </span>
                ，608090储备金额/目标GAP为
                <span className={`c-o-a-tips-text ${status}`}>
                    {trans2DotTwo(
                        compositeOptAnalysisData.opportunity_amount_rate as number,
                    )}
                    %
                </span>
            </span>
        );
    };
    return (
        <Spin spinning={props.dataLoading}>
            <div className="composite-opt-analysis-content">
                <div className="c-o-a-header">
                    <span className="c-o-a-title">业绩储备-商机分析</span>
                    {/* <AntButton>生成报告</AntButton> */}
                    <div className="a-s-f-filter">
                        {/* <div className="a-s-f-item">
                            <label htmlFor="">目标级别</label>
                            <Select
                                value={asFilterData.targetLevel}
                                onChange={(value: any) => {
                                    setAsFilterData({
                                        ...asFilterData,
                                        targetLevel: value,
                                    });
                                }}
                                style={{
                                    minWidth: '120px',
                                }}
                            >
                                {_.map(targetLevelOptions, option => {
                                    return (
                                        <Select.Option value={option.value}>
                                            {option.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div> */}
                        <div className="a-s-f-item">
                            <label htmlFor="">区域</label>
                            <Select
                                value={asFilterData.area}
                                size="small"
                                onChange={(value: any) => {
                                    setAsFilterData({
                                        ...asFilterData,
                                        area: value,
                                    });
                                }}
                                style={{
                                    minWidth: '80px',
                                }}
                            >
                                {_.map(areaOptions, option => {
                                    return (
                                        <Select.Option value={option.value}>
                                            {option.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="a-s-f-item">
                            <label htmlFor="">时间</label>
                            <Select
                                size="small"
                                value={asFilterData.range}
                                onChange={(value: any) => {
                                    setAsFilterData({
                                        ...asFilterData,
                                        range: value,
                                    });
                                }}
                                style={{
                                    minWidth: '80px',
                                }}
                            >
                                {_.map(rangeOptions, option => {
                                    return (
                                        <Select.Option value={option.value}>
                                            {option.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="c-o-a-body">
                    <div className="c-o-a-static-content">
                        {_.map(optAnalysisValueMap, item => {
                            return (
                                <div className="c-o-a-static-item">
                                    {compositeOptAnalysisData?.[item.key] ? (
                                        <div className="c-o-a-statics-data">
                                            <Statistic
                                                style={statisticValueStyle}
                                                groupSeparator={','}
                                                suffix={item.prodText || ''}
                                                value={
                                                    compositeOptAnalysisData?.[
                                                        item.key
                                                    ]
                                                        ? item.formatter(
                                                              compositeOptAnalysisData?.[
                                                                  item.key
                                                              ],
                                                          )
                                                        : '- -'
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <Statistic
                                            style={statisticValueStyle}
                                            groupSeparator={','}
                                            value={'- -'}
                                        />
                                    )}
                                    <p className="c-o-a-static-title">
                                        {item.title}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    <div className="c-o-a-alert-content">
                        <Icon type="info-circle" style={{ color: '#0052FF' }} />
                        {renderTipsText()}
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default CompositeOptAnalysis;
