import React, {
    useState,
    useEffect,
    ReactElement,
    useContext,
    useCallback,
} from 'react';
import './index.scss';
import {
    IFRMRoleData,
    IMenuAuthData,
    IFunctionAuthData,
    IFieldAuthData,
    IFiledEntityAuthData,
    IFRMAuthData,
    IEntityBizTypeAuthData,
    IBizTypeAuthData,
} from '../store';
import { observer } from 'mobx-react-lite';
import {
    Input,
    Radio,
    Tree,
    Select,
    message,
    Spin,
    Modal,
    Checkbox,
} from 'antd';
import { transformArrayToTree } from '../../../../utils/helpers';
import _ from 'lodash';
import { AntButton } from '@/components';
import { toJS } from 'mobx';
import FRMFieldTable from '../frm-field-table/index';
import { StoreContext } from '@/stores';
import { useNet } from '@/utils';

// 去除drawer状态，修改ue
interface IFRMActionBodyProps {
    selectedData: IFRMRoleData;
    saveBtnClassName?: string;
    isDrawer?: boolean;
    drawerVis?: boolean;
    setDrawerVis?: (bool: boolean) => void;
    isUpdateAction?: boolean;
    reloadRoleList: () => void;
}
const FRMActionBody: React.FC<IFRMActionBodyProps> = props => {
    const {
        selectedData,
        isDrawer,
        saveBtnClassName,
        isUpdateAction,
        drawerVis,
        reloadRoleList,
    } = props;
    const store = useContext(StoreContext);
    const frmStore = store.getDefaultFRMStore();
    const {
        updateFRMRoleLoading,
        fetchEntityFieldAuth,
        fetchRoleAuth,
        FRMRoleAuthData,
        FRMEntityFieldAuthData,
        updateFRMRoleFunctionAuthData,
        updateFRMRoleMenuAuthData,
        updateFRMRoleFieldAuthData,
        updateFRMRoleBizTypeAuthData,
    } = frmStore;
    const roleAuthFetch = useCallback(() => {
        return fetchRoleAuth(selectedData?.id || '');
    }, [selectedData]);
    const [error, roleAuthLoading, roleAuthReload] = useNet(roleAuthFetch);
    const [updateData, setUpdateData] = useState<IFRMRoleData>({});
    const [functionWay, setFunctionWay] = useState<
        'menuAuth' | 'functionAuth' | 'fieldAuth' | 'bizTypeAuth'
    >('menuAuth');
    const [menuAuthSelectedKeys, setMenuAuthSelectedKeys] = useState<
        | string[]
        | {
              checked: string[];
              halfChecked: string[];
          }
    >([]);
    const [functionAuthSelectedKeys, setFunctionAuthSelectedKeys] = useState<
        | string[]
        | {
              checked: string[];
              halfChecked: string[];
          }
    >([]);
    const [fieldAuthCheckBoxValue, setFieldAuthCheckBoxValue] = useState<
        IFiledEntityAuthData[]
    >([]);
    const [bizTypeAuthUpdateValue, setBizTypeAuthUpdateValue] = useState<
        IEntityBizTypeAuthData[]
    >([]);
    const [selectedFiledEntity, setSelectedFiledEntity] = useState<string>();

    const [menuAuthExpandedKeys, setMenuAuthExpandedKeys] = useState<string[]>(
        [],
    );
    const [functionAuthExpandedKeys, setFunctionAuthExpandedKeys] = useState<
        string[]
    >([]);

    const [isAnyChange, setIsAnyChange] = useState(false); // 是否在提交之前触发过onchange
    const entityFieldFetch = useCallback(() => {
        return fetchEntityFieldAuth(
            selectedFiledEntity as string,
            selectedData.id as string,
        );
    }, [selectedFiledEntity, selectedData]);
    const [entityFieldError, entityFieldLoading, entityFieldReload] = useNet(
        entityFieldFetch,
    );
    const [validateUpdateData, setValidateUpdateData] = useState<{
        [key: string]: {
            status: 'error' | 'success';
            msg: string;
        };
    }>({
        functionRoleName: {
            status: 'success',
            msg: '',
        },
    });
    // 获取一组key的父key(有leaf)
    const getAllParentKeys = (
        keys: string[],
        originData: any[],
        mode: 'menuAuth' | 'functionAuth',
    ) => {
        const pKeysMap = _.map(keys, key => {
            return getAllParentKey(key, originData, mode);
        });
        console.log(_.uniq(_.flatten(pKeysMap)));
        return _.uniq(_.flatten(pKeysMap));
    };
    // (无leaf)
    const getAllParentKeysNoLeaf = (
        keys: string[],
        originData: any[],
        mode: 'menuAuth' | 'functionAuth',
    ) => {
        const pKeysMap = _.map(keys, key => {
            return getAllParentKeyNoLeaf(key, originData, mode);
        });
        console.log(_.uniq(_.flatten(pKeysMap)));
        return _.uniq(_.flatten(pKeysMap));
    };
    const checkLeafNode = (
        id: number | string,
        originData: any[],
        mode: 'menuAuth' | 'functionAuth',
    ) => {
        const pIdIndex =
            mode === 'menuAuth' ? 'parentMenuId' : 'parentFunctionId';
        let isLeaf = true;
        for (const item of originData) {
            if (item[pIdIndex] == id) {
                isLeaf = false;
                break;
            }
        }
        return isLeaf;
    };
    const getAllParentKeyNoLeaf = (
        key: string,
        originData: any[],
        mode: 'menuAuth' | 'functionAuth',
    ) => {
        const groupKeyMap = _.groupBy(originData, 'id');
        const keyIndex = mode === 'menuAuth' ? 'menuKey' : 'functionKey';
        const pIdIndex =
            mode === 'menuAuth' ? 'parentMenuId' : 'parentFunctionId';
        const keyNode = _.find(originData, node => {
            return node.id == key;
        });
        // 顶层
        // 超级坑，antd的树上的key，只能是string，不是string的就会被强转string，所以如果expandKey是number[]，则失效
        if (!(keyNode && keyNode[pIdIndex])) {
            return [String(keyNode.id)];
        } else {
            const allParentKey: any[] = [];
            const loopKey: any = (node: any) => {
                if (checkLeafNode(node.id, originData, mode)) {
                    if (node[pIdIndex]) {
                        return loopKey(groupKeyMap[node[pIdIndex]][0]);
                    } else {
                        return;
                    }
                } else {
                    if (!node[pIdIndex]) {
                        allParentKey.push(String(node.id));
                        return;
                    } else {
                        allParentKey.push(String(node.id));
                        return loopKey(groupKeyMap[node[pIdIndex]][0]);
                    }
                }
            };
            loopKey(keyNode);
            return allParentKey;
        }
    };
    // 获取一个key的所有父key (菜单)
    const getAllParentKey = (
        key: string,
        originData: any[],
        mode: 'menuAuth' | 'functionAuth',
    ) => {
        const groupKeyMap = _.groupBy(originData, 'id');
        const keyIndex = mode === 'menuAuth' ? 'menuKey' : 'functionKey';
        const pIdIndex =
            mode === 'menuAuth' ? 'parentMenuId' : 'parentFunctionId';
        const keyNode = _.find(originData, node => {
            return node.id == key;
        });
        // 顶层
        // 超级坑，antd的树上的key，只能是string，不是string的就会被强转string，所以如果expandKey是number[]，则失效
        if (!(keyNode && keyNode[pIdIndex])) {
            return [String(keyNode.id)];
        } else {
            const allParentKey: any[] = [];
            const loopKey: any = (node: any) => {
                // 这个逻辑可以加，优化expandKey的加载速度，但是需要分离一个包含叶子节点的寻找函数，给更新使用
                // if (checkLeafNode(node.id, originData, mode)) {
                //     if (node[pIdIndex]) {
                //         return loopKey(groupKeyMap[node[pIdIndex]][0]);
                //     } else {
                //         return;
                //     }
                // } else {
                if (!node[pIdIndex]) {
                    allParentKey.push(String(node.id));
                    return;
                } else {
                    allParentKey.push(String(node.id));
                    return loopKey(groupKeyMap[node[pIdIndex]][0]);
                }
                // }
            };
            loopKey(keyNode);
            return allParentKey;
        }
    };
    // 生成上传的菜单权限
    const generateFinalMenuAuth = () => {
        // const menuAuthObj: any = updateData?.authority || {};
        // const menuAuth: IMenuAuthData[] = menuAuthObj?.menuAuth || [];
        // const reduceMap = menuAuth.reduce((res: any, v) => {
        //     res[v.menuKey as string] = v;
        //     return res;
        // }, {});
        const selectedMenuKey = Array.isArray(menuAuthSelectedKeys)
            ? menuAuthSelectedKeys
            : menuAuthSelectedKeys.checked;
        // const halfCheckedMenuKey = Array.isArray(menuAuthSelectedKeys)
        //     ? []
        //     : menuAuthSelectedKeys.halfChecked;
        // _.forEach(selectedMenuKey, key => {
        //     reduceMap[key].value = true;
        // });
        return _.uniq(
            _.map(
                getAllParentKeys(
                    selectedMenuKey,
                    FRMRoleAuthData.menuAuth,
                    'menuAuth',
                ),
                key => Number(key),
            ),
        );
    };
    // 生成上传的功能权限
    const generateFinalFunctionAuth = () => {
        // const functionAuthObj: any = updateData?.authority || {};
        // const functionAuth: IFunctionAuthData[] =
        //     functionAuthObj?.functionAuth || [];
        // const reduceMap = functionAuth.reduce((res: any, v) => {
        //     res[v.functionKey as string] = v;
        //     return res;
        // }, {});
        const selectedFunctionKey = Array.isArray(functionAuthSelectedKeys)
            ? functionAuthSelectedKeys
            : functionAuthSelectedKeys.checked;

        const halfSelectedFunctionKey = Array.isArray(functionAuthSelectedKeys)
            ? []
            : functionAuthSelectedKeys.halfChecked;
        // _.forEach(selectedFunctionKey, key => {
        //     reduceMap[key].value = true;
        // });
        return _.uniq(
            _.map(
                getAllParentKeys(
                    selectedFunctionKey,
                    FRMRoleAuthData.functionAuth,
                    'functionAuth',
                ),
                key => Number(key),
            ),
        );
    };
    // 上传动作
    const onUpdateActionHandle = async () => {
        if (!updateData.functionRoleName) {
            message.warning('输入不正确，请检查后再提交', 1);
            setValidateUpdateData({
                ...validateUpdateData,
                functionRoleName: {
                    status: 'error',
                    msg: '功能角色名称不能为空',
                },
            });
            return;
        }
        // const updateAuthData: IFRMAuthData = {
        //     menuAuth: generateFinalMenuAuth(),
        //     functionAuth: generateFinalFunctionAuth(),
        //     fieldAuth: fieldAuthCheckBoxValue,
        // };
        // const finalUpdateData: IFRMRoleData = {
        //     id: selectedData.id,
        //     functionRoleName: updateData.functionRoleName,
        //     authority: updateAuthData,
        // };
        let data: any = false;
        let error: any = false;
        if (functionWay === 'menuAuth') {
            // 菜单权限
            [data, error] = await updateFRMRoleMenuAuthData(
                selectedData.id as string,
                generateFinalMenuAuth(),
                updateData.functionRoleName,
            );
        } else if (functionWay === 'functionAuth') {
            [data, error] = await updateFRMRoleFunctionAuthData(
                selectedData.id as string,
                generateFinalFunctionAuth(),
                updateData.functionRoleName,
            );
        } else if (functionWay === 'fieldAuth') {
            if (!selectedFiledEntity) {
                message.error('请选择业务类型后再提交更改~', 1);
                return;
            }
            [data, error] = await updateFRMRoleFieldAuthData(
                selectedData.id as string,
                selectedFiledEntity as string,
                fieldAuthCheckBoxValue,
                updateData.functionRoleName,
            );
        } else {
            [data, error] = await updateFRMRoleBizTypeAuthData(
                selectedData.id as string,
                bizTypeAuthUpdateValue,
                updateData.functionRoleName,
            );
        }
        let howShowAuthRes = true;
        let howShowRoleRes = true;
        if (data.authRes && !data.authRes?.msg) {
            howShowAuthRes = true;
        } else {
            howShowAuthRes = false;
        }
        if (data.roleRes && !data.roleRes?.msg) {
            if (functionWay === 'fieldAuth') {
                entityFieldReload();
            } else {
                reloadRoleList();
            }
            selectedData.functionRoleName = updateData.functionRoleName;
            howShowRoleRes = true;
        } else {
            howShowRoleRes = false;
        }
        if (howShowRoleRes && howShowAuthRes) {
            message.success(`成员与${functionWay}修改成功～`, 1);
            setIsAnyChange(false);
        } else {
            message.error(
                `成员修改${
                    howShowRoleRes ? '成功' : data.roleRes?.msg || '失败'
                }  
            ${functionWay}权限修改${
                    howShowAuthRes ? '成功' : data.authRes?.msg || '失败'
                }`,
                2,
            );
        }
    };
    const initData = () => {
        const tempData = _.cloneDeep(selectedData);
        const tempAuthData = _.cloneDeep(FRMRoleAuthData);
        setUpdateData({
            ...tempData,
            authority: tempAuthData,
        });
        // ---- 处理树初始值 ----
        // 初始值为true的选项（但不一定被选中，也许是半选状态）
        const selectedMenuAuthKeys = _.map(
            _.filter(tempAuthData?.menuAuth, item => {
                return item.value;
            }),
            item => {
                return String(item.id) as string;
            },
        );
        const selectedFunctionAuthKeys = _.map(
            _.filter(tempAuthData?.functionAuth, item => {
                return item.value;
            }),
            item => {
                return String(item.id) as string;
            },
        );
        // 初始值为false的选项（一定没被选中）
        const NotSelectedFunctionAuthKeys = _.map(
            _.filter(tempAuthData?.functionAuth, item => {
                return !item.value;
            }),
            item => {
                return String(item.id) as string;
            },
        );
        const NotSelectedMenuAuthKeys = _.map(
            _.filter(tempAuthData?.menuAuth, item => {
                return !item.value;
            }),
            item => {
                return String(item.id) as string;
            },
        );
        // 寻找半选择（半选择本质上未被选中）
        let pList: any[] = [];
        const loopParent: any = (
            node: any,
            pIdIndex: string,
            originData: any,
        ) => {
            const keyNode = _.find(originData, (data: any) => {
                return data.id == node;
            });
            console.log(keyNode, node);
            if (!keyNode) {
                return;
            }
            const pId = keyNode[pIdIndex];
            if (pId) {
                pList.push(String(pId));
                return loopParent(pId, pIdIndex, originData);
            } else {
                return;
            }
        };
        pList = [];
        // _.map(NotSelectedFunctionAuthKeys, (keys) => {
        //     loopParent(keys, 'parentFunctionId');
        // });
        _.map(NotSelectedMenuAuthKeys, keys => {
            loopParent(keys, 'parentMenuId', tempAuthData?.menuAuth);
        });
        // 最终被选中的菜单权限
        const finalSelectedMenuAuth = _.difference(selectedMenuAuthKeys, pList);
        pList = [];
        _.map(NotSelectedFunctionAuthKeys, keys => {
            loopParent(keys, 'parentFunctionId', tempAuthData?.functionAuth);
        });
        // 最终被选中的功能权限
        const finalSelectedFunctionAuth = _.difference(
            selectedFunctionAuthKeys,
            pList,
        );
        // ---- 初始值设置完毕 ----
        const fieldAuth = tempAuthData?.fieldAuth || [];
        // 字段设置默认选中第一个
        // setFieldAuthCheckBoxValue(fieldAuth || []);
        if (fieldAuth[0]?.entityId) {
            setSelectedFiledEntity(fieldAuth[0].entityId as string);
        }
        setMenuAuthSelectedKeys(finalSelectedMenuAuth);
        setMenuAuthExpandedKeys(
            getAllParentKeys(
                selectedMenuAuthKeys,
                tempAuthData?.menuAuth || [],
                'menuAuth',
            ),
        );

        setFunctionAuthSelectedKeys(finalSelectedFunctionAuth);
        setFunctionAuthExpandedKeys(
            getAllParentKeysNoLeaf(
                selectedFunctionAuthKeys,
                tempAuthData?.functionAuth || [],
                'functionAuth',
            ),
        );

        setBizTypeAuthUpdateValue(tempAuthData?.bizTypeAuth || []);
    };
    useEffect(() => {
        if (selectedData) {
            roleAuthReload();
            entityFieldReload();
            if (isDrawer) {
                // _.once(() => {
                //     initData();
                //     setValidateUpdateData({
                //         functionRoleName: {
                //             status: 'success',
                //             msg: ''
                //         }
                //     });
                // });
            } else {
                // initData();
                setValidateUpdateData({
                    functionRoleName: {
                        status: 'success',
                        msg: '',
                    },
                });
            }
        }
    }, [selectedData]);
    useEffect(() => {
        setFieldAuthCheckBoxValue(_.cloneDeep(FRMEntityFieldAuthData));
    }, [FRMEntityFieldAuthData]);
    useEffect(() => {
        if (selectedFiledEntity) {
            // 选中实体变更
            entityFieldReload();
            // fetchEntityFieldAuth(selectedFiledEntity);
        }
    }, [selectedFiledEntity]);
    useEffect(() => {
        if (FRMRoleAuthData) {
            initData();
        }
    }, [FRMRoleAuthData]);
    useEffect(() => {
        if (functionWay === 'fieldAuth') {
            entityFieldReload();
        }
        roleAuthReload();
    }, [functionWay]);
    useEffect(() => {
        if (isUpdateAction && isDrawer) {
            onUpdateActionHandle();
        }
    }, [isUpdateAction]);
    useEffect(() => {
        if (drawerVis) {
            // 打开时清空
            // 清空数据
            initData();
            setValidateUpdateData({
                functionRoleName: {
                    status: 'success',
                    msg: '',
                },
            });
        }
    }, [drawerVis]);

    const onUpdateDataChange = (
        updateKey: keyof typeof updateData,
        val: any,
    ) => {
        const tempUpdateData: IFRMRoleData & {
            [key: string]: any;
        } = {
            ...updateData,
        };
        tempUpdateData[updateKey] = val;
        setUpdateData(tempUpdateData);
    };
    // 全选
    const checkAllAuthKey = (
        authWay: 'editAuth' | 'createAuth' | 'viewAuth',
    ) => {
        setIsAnyChange(true);
        const tempCheckboxValue = [...fieldAuthCheckBoxValue];
        _.forEach(tempCheckboxValue, item => {
            const wayAuthItem = item[authWay];
            _.forEach(wayAuthItem, authItem => {
                authItem.authValue = true;
            });
        });
        if (authWay === 'editAuth') {
            // 全选编辑也会把查看全选
            _.forEach(tempCheckboxValue, item => {
                const wayAuthItem = item.viewAuth;
                _.forEach(wayAuthItem, authItem => {
                    authItem.authValue = true;
                });
            });
        }
        setFieldAuthCheckBoxValue(tempCheckboxValue);
    };
    // 修改字段权限的数据源
    const changeFieldCheckboxValue = (
        val: boolean,
        fieldKey: string,
        authWay: 'editAuth' | 'createAuth' | 'viewAuth',
        authKey: string,
    ) => {
        setIsAnyChange(true);
        console.log(val, fieldKey, authWay, authKey);
        // 找到需要修改的值
        const tempCheckboxValue = [...fieldAuthCheckBoxValue];
        // // 实体
        // const existingTempEntityValue = _.find(tempCheckboxValue, v => {
        //     return v.entityId === selectedFiledEntity;
        // });
        // 实体-字段
        const existingTempEntityFieldValue: any =
            _.find(tempCheckboxValue || [], v => {
                return v.fieldKey === fieldKey;
            }) || {};
        // 实体-字段-方法
        const exstingTempEntityFieldWayValue =
            existingTempEntityFieldValue[authWay] || [];
        // 实体-字段-方法-权限
        const exstingTempEntityFieldWayAuthValue =
            _.find(exstingTempEntityFieldWayValue, v => {
                return v.authKey === authKey;
            }) || {};
        if (authWay === 'createAuth') {
            // 新建方法下
            // 能编辑必然能查看
            // 不能查看必然不能编辑
            if (exstingTempEntityFieldWayValue.length === 2) {
                // 编辑
                if (
                    exstingTempEntityFieldWayAuthValue.authKey ===
                        'CREATE-EDIT' &&
                    exstingTempEntityFieldWayAuthValue.authValue === false &&
                    val === true
                ) {
                    const viewItem =
                        _.find(exstingTempEntityFieldWayValue, v => {
                            return v.authKey === 'CREATE-VIEW';
                        }) || {};
                    viewItem.authValue = val;
                    exstingTempEntityFieldWayAuthValue.authValue = val;
                } else if (
                    exstingTempEntityFieldWayAuthValue.authKey ===
                        'CREATE-VIEW' &&
                    exstingTempEntityFieldWayAuthValue.authValue === true &&
                    val === false
                ) {
                    const editItem =
                        _.find(exstingTempEntityFieldWayValue, v => {
                            return v.authKey === 'CREATE-EDIT';
                        }) || {};
                    editItem.authValue = val;
                    exstingTempEntityFieldWayAuthValue.authValue = val;
                } else {
                    exstingTempEntityFieldWayAuthValue.authValue = val;
                }
            } else {
                exstingTempEntityFieldWayAuthValue.authValue = val;
            }
        } else {
            // 非新建（查看、编辑）
            if (exstingTempEntityFieldWayValue.length === 2) {
                const changeAuthKey =
                    exstingTempEntityFieldWayAuthValue.authKey;
                const isPublic = changeAuthKey.indexOf('PUBLIC') != -1; // 是否公海
                const isEdit = changeAuthKey.indexOf('EDIT') != -1; // 是否编辑
                if (isPublic) {
                    // 公海
                    // 公海编辑 从 false到true
                    if (
                        isEdit &&
                        exstingTempEntityFieldWayAuthValue.authValue ===
                            false &&
                        val === true
                    ) {
                        // 找到公海查看
                        const viweAuthArray =
                            existingTempEntityFieldValue?.viewAuth || [];
                        const publicViewAuth = _.find(viweAuthArray, v => {
                            return (
                                v.authKey && v.authKey.indexOf('PUBLIC') != -1
                            );
                        });
                        publicViewAuth.authValue = val;
                        exstingTempEntityFieldWayAuthValue.authValue = val;
                    } else if (
                        !isEdit &&
                        exstingTempEntityFieldWayAuthValue.authValue === true &&
                        val === false
                    ) {
                        // 公海查看从true到false
                        // 找到公海编辑
                        const editAuthArray =
                            existingTempEntityFieldValue?.editAuth || [];
                        const publicEditAuth = _.find(editAuthArray, v => {
                            return (
                                v.authKey && v.authKey.indexOf('PUBLIC') != -1
                            );
                        });
                        publicEditAuth.authValue = val;
                        exstingTempEntityFieldWayAuthValue.authValue = val;
                    } else {
                        exstingTempEntityFieldWayAuthValue.authValue = val;
                    }
                } else {
                    // 私海
                    // 私海编辑从true到false
                    if (
                        isEdit &&
                        exstingTempEntityFieldWayAuthValue.authValue ===
                            false &&
                        val === true
                    ) {
                        // 找到私海查看
                        const viweAuthArray =
                            existingTempEntityFieldValue?.viewAuth || [];
                        const privateViewAuth = _.find(viweAuthArray, v => {
                            return (
                                v.authKey && v.authKey.indexOf('PRIVATE') != -1
                            );
                        });
                        privateViewAuth.authValue = val;
                        exstingTempEntityFieldWayAuthValue.authValue = val;
                    } else if (
                        !isEdit &&
                        exstingTempEntityFieldWayAuthValue.authValue === true &&
                        val === false
                    ) {
                        // 私海查看从true到false
                        // 找到私海编辑
                        const editAuthArray =
                            existingTempEntityFieldValue?.editAuth || [];
                        const privateEditAuth = _.find(editAuthArray, v => {
                            return (
                                v.authKey && v.authKey.indexOf('PRIVATE') != -1
                            );
                        });
                        privateEditAuth.authValue = val;
                        exstingTempEntityFieldWayAuthValue.authValue = val;
                    } else {
                        exstingTempEntityFieldWayAuthValue.authValue = val;
                    }
                }
            } else {
                exstingTempEntityFieldWayAuthValue.authValue = val;
            }
        }
        setFieldAuthCheckBoxValue(tempCheckboxValue);
    };

    const renderTree: () => any = () => {
        if (functionWay === 'fieldAuth' || functionWay === 'bizTypeAuth') {
            return null;
        }
        const originDataObj: any = updateData?.authority || {};
        const originData = originDataObj[functionWay];
        let parentId = '';
        let titleId = '';
        let keyId = '';
        switch (functionWay) {
            case 'functionAuth':
                parentId = 'parentFunctionId';
                titleId = 'functionName';
                keyId = 'functionKey';
                break;
            case 'menuAuth':
                parentId = 'parentMenuId';
                titleId = 'menuName';
                keyId = 'menuKey';
                break;
            default:
                break;
        }
        const treeRenderData = transformArrayToTree(originData, parentId, 'id');
        const loopRender: (data: any[]) => any = data => {
            return _.map(data, (node: any) => {
                if (node.children) {
                    return (
                        <Tree.TreeNode title={node[titleId]} key={node.id}>
                            {loopRender(node.children)}
                        </Tree.TreeNode>
                    );
                } else {
                    return (
                        <Tree.TreeNode title={node[titleId]} key={node.id} />
                    );
                }
            });
        };
        return (
            <Tree
                style={{ paddingBottom: '30px' }}
                checkable
                expandedKeys={
                    functionWay === 'functionAuth'
                        ? functionAuthExpandedKeys
                        : menuAuthExpandedKeys
                }
                onExpand={expandedKeys => {
                    functionWay === 'functionAuth'
                        ? setFunctionAuthExpandedKeys(expandedKeys)
                        : setMenuAuthExpandedKeys(expandedKeys);
                }}
                selectable={false}
                // defaultExpandAll={true}
                // defaultExpandParent={true}
                // autoExpandParent={true}
                checkedKeys={
                    functionWay === 'functionAuth'
                        ? functionAuthSelectedKeys
                        : menuAuthSelectedKeys
                }
                onCheck={checkedKeys => {
                    console.log(checkedKeys);
                    setIsAnyChange(true);
                    functionWay === 'functionAuth'
                        ? setFunctionAuthSelectedKeys(checkedKeys)
                        : setMenuAuthSelectedKeys(checkedKeys);
                }}
            >
                {loopRender(treeRenderData)}
            </Tree>
        );
    };
    const renderCheckBoxTable = () => {
        if (functionWay !== 'fieldAuth') {
            return null;
        }
        // // 选中的field-entity
        // const selectedFieldEntityAuth: any =
        //     _.find(fieldAuthCheckBoxValue, v => {
        //         return v.entityId === selectedFiledEntity;
        //     }) || {};
        return (
            <div className="frm-checkbox-table">
                <div className="frm-field-entity">
                    <label>业务模块</label>
                    <Select
                        style={{ width: '250px' }}
                        value={selectedFiledEntity}
                        onSelect={(val: any) => {
                            // 拦截变更
                            if (isAnyChange) {
                                Modal.confirm({
                                    content:
                                        '切换当前业务模块会导致变更丢失，请确认是否切换',
                                    title: '警告',
                                    onOk: () => {
                                        setSelectedFiledEntity(val);
                                        setIsAnyChange(false);
                                    },
                                });
                            } else {
                                setSelectedFiledEntity(val);
                            }
                        }}
                    >
                        {_.map(FRMRoleAuthData.fieldAuth, entity => {
                            return (
                                <Select.Option value={entity.entityId}>
                                    {entity.entityName}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                <Spin spinning={entityFieldLoading}>
                    <div className="frm-table-body">
                        <FRMFieldTable
                            fieldEntityData={fieldAuthCheckBoxValue || []}
                            onFieldChange={changeFieldCheckboxValue}
                            onAuthWayAllCheck={checkAllAuthKey}
                        />
                    </div>
                </Spin>
            </div>
        );
    };
    const renderBizTypeAuthChoose = () => {
        if (functionWay !== 'bizTypeAuth') {
            return null;
        }
        const renderBizAuthChooseItem = (item: IEntityBizTypeAuthData) => {
            let isAllSelected = true;
            let isAllNotSelected = true;
            _.forEach(item.businessTypeItems, item => {
                if (item.selectable) {
                    isAllNotSelected = false;
                } else {
                    isAllSelected = false;
                }
            });
            return (
                <div className="biz-type-auth-item">
                    <div>
                        <p className="biz-type-title">
                            {item.entityName}
                            {item.businessTypeItems.length != 0 && (
                                <Checkbox
                                    className="frm-all-selected-checkbox"
                                    indeterminate={
                                        !isAllSelected && !isAllNotSelected
                                    }
                                    checked={isAllSelected}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            _.forEach(
                                                item.businessTypeItems,
                                                item => {
                                                    item.selectable = true;
                                                },
                                            );
                                        } else {
                                            _.forEach(
                                                item.businessTypeItems,
                                                item => {
                                                    item.selectable = false;
                                                },
                                            );
                                        }
                                        setBizTypeAuthUpdateValue([
                                            ...bizTypeAuthUpdateValue,
                                        ]);
                                    }}
                                >
                                    全选
                                </Checkbox>
                            )}
                        </p>
                    </div>
                    <div className="biz-type-auth-body">
                        {_.map(item.businessTypeItems, authItem => {
                            return (
                                <Checkbox
                                    checked={authItem.selectable}
                                    key={authItem.id}
                                    onChange={e => {
                                        const checked = e.target.checked;
                                        authItem.selectable = checked;
                                        setBizTypeAuthUpdateValue([
                                            ...bizTypeAuthUpdateValue,
                                        ]);
                                    }}
                                >
                                    {authItem.businessTypeName}
                                </Checkbox>
                            );
                        })}
                    </div>
                </div>
            );
        };
        return (
            <div className="frm-biz-type-auth-content">
                {_.map(bizTypeAuthUpdateValue, bizTypeItem => {
                    return renderBizAuthChooseItem(bizTypeItem);
                })}
            </div>
        );
    };
    return (
        <>
            <Spin spinning={roleAuthLoading}>
                <div className="frm-action-body">
                    <div className="pa-table-item">
                        <label htmlFor="name" className="pa-table-item-label">
                            角色名称<span className="required-mark">*</span>
                        </label>
                        <Input
                            className={
                                validateUpdateData.functionRoleName.status ===
                                'error'
                                    ? 'has-error'
                                    : ''
                            }
                            placeholder="请输入角色名称"
                            style={{ width: '300px' }}
                            id="name"
                            onChange={e => {
                                onUpdateDataChange(
                                    'functionRoleName',
                                    e.target.value,
                                );
                            }}
                            onFocus={() => {
                                setValidateUpdateData({
                                    ...validateUpdateData,
                                    functionRoleName: {
                                        status: 'success',
                                        msg: '',
                                    },
                                });
                            }}
                            value={updateData.functionRoleName}
                        />
                    </div>
                    {validateUpdateData.functionRoleName.status === 'error' && (
                        <p className="pa-error-msg">
                            {validateUpdateData.functionRoleName.msg}
                        </p>
                    )}
                    <div
                        className="pa-table-item"
                        style={{
                            flex: 1,
                            alignItems: 'unset',
                            flexDirection: 'column',
                        }}
                    >
                        <div className="pa-table-item-label">
                            数据权限<span className="required-mark">*</span>
                            <span className="pa-table-label-description">
                                请设置该职能具体功能权限，包含菜单、功能、字段、业务类型四个方面
                            </span>
                        </div>
                        <Radio.Group
                            value={functionWay}
                            onChange={e => {
                                // 弹窗警告
                                if (isAnyChange) {
                                    Modal.confirm({
                                        content:
                                            '离开此标签后，当前权限修改的内容将会丢失，请确认是否离开？',
                                        title: '警告',
                                        onOk: () => {
                                            setIsAnyChange(false);
                                            setFunctionWay(e.target.value);
                                        },
                                    });
                                } else {
                                    setFunctionWay(e.target.value);
                                }
                            }}
                        >
                            <Radio.Button value="menuAuth">
                                菜单权限
                            </Radio.Button>
                            <Radio.Button value="functionAuth">
                                功能权限
                            </Radio.Button>
                            <Radio.Button value="fieldAuth">
                                字段权限
                            </Radio.Button>
                            <Radio.Button value="bizTypeAuth">
                                业务类型
                            </Radio.Button>
                        </Radio.Group>
                        {renderTree()}
                        {renderCheckBoxTable()}
                        {renderBizTypeAuthChoose()}
                    </div>
                </div>
            </Spin>
            {!isDrawer && (
                <AntButton
                    className={`frm-save-btn ${saveBtnClassName || ''}`}
                    size="default"
                    type="primary"
                    onClick={onUpdateActionHandle}
                    loading={updateFRMRoleLoading}
                    disabled={roleAuthLoading || entityFieldLoading}
                >
                    保存
                </AntButton>
            )}
        </>
    );
};
export default observer(FRMActionBody);
