import React, { useEffect } from 'react';
import _ from 'lodash';
import { runInAction } from 'mobx';
import LeadDrawer from './index';
import LeadFormDrawer from '@/pages/sales-follow-up/lead/list/components/form-drawer';
import {
    useAnyLeadStore,
    LeadListStore,
} from '@/pages/sales-follow-up/lead/list/store';

export const useLeadDrawerSnippets = (options: {
    drawerVis: boolean;
    setDrawerVis: any;
    itemId: any;
    type?: string;
    leadList?: LeadListStore;
}) => {
    const { drawerVis, setDrawerVis, itemId, type = 'mine' } = options;
    const { leadList: inputLeadList } = options;

    const [prepared, leadList] = useAnyLeadStore({
        mayBeInitedStore: inputLeadList,
    });

    useEffect(() => {
        leadList.setListType(type);
    }, [type]);

    if (!prepared) {
        return [leadList, null];
    }
    return [
        leadList,
        <>
            <LeadFormDrawer
                leadList={leadList}
                preRequiredReady={prepared}
                // 如果是从这里进来的话，不知道如何选择type
                // 如何区分是公海还是私海lead？
                type={type}
            />
            <LeadDrawer
                leadListStore={leadList}
                drawerVis={drawerVis}
                setDrawerVis={setDrawerVis}
                detailId={itemId}
                destroyOnClose={true}
                onEdit={data => {
                    runInAction(() => {
                        leadList &&
                            leadList.setAction('update', { id: data.id });
                    });
                }}
            />
        </>,
    ];
};
