import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    FastFilterStore,
} from '@/stores/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import { mutate } from '@/api/graphql';
import _ from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    getLeads,
    TGetLeadsReturnType,
    xDelete,
    commonQuery,
    commonQueryProcess,
    commonQueryCount,
    TGetCommonReturnType,
    getCommonQueryBody,
} from '@/api/rest';
import { defaultWritingDataParser, defaultAxios, isNetSuccess } from '@/utils';
import EE from 'eventemitter3';
import $ from 'jquery';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';

const transformBackEndValidateStateToValidateResult = (
    data: any[],
): ValidateResult<any> => {
    const ret: ValidateResult<any> = {};
    for (const d of data) {
        const { errorMsg, errorType, fieldName } = d;
        if (!errorMsg || !errorType || !fieldName) {
            continue;
        }
        ret[fieldName] = {
            msg: errorMsg,
            status: errorType === 'error' ? 'fail' : 'warning',
        };
    }
    return ret;
};

export class ContractIncomeListStore {
    // 数据
    public data: any[] = [];

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public get pageTitle() {
        if (this.listType === 'all') {
            return '全部回款记录';
        } else if (this.listType === 'mine') {
            return '我的回款记录';
        }
        return '未知listType';
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFastFilters = this.defaultFastFilters;
        const {
            selectedFastFilters,
            selectedLv4MenuFilters,
        } = defaultFastFilters;

        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );
        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        for (const filterSet of selectedFastFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        for (const filterSet of selectedLv4MenuFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        ret.filters?.push(...defaultFilters);
        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }
    public setAction(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra.id) {
                this.fetchInstantlyMutatingDataById(extra.id);
            } else {
                message.error('修改必须传id');
            }
        }
        this.actionType = nextAction;
    }
    public async setActionAsync(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra) {
                if (extra.id) {
                    const ok = await this.fetchInstantlyMutatingDataById(
                        extra.id,
                    );
                    if (!ok) {
                        return false;
                    }
                } else {
                    message.error('修改必须传id');
                    return false;
                }
            }
        }
        this.actionType = nextAction;
        return true;
    }
    public resetAction() {
        this.netValidateResult = {};
        this.actionType = '';
    }
    public async dispatchAction() {
        if (this.actionType === 'create') {
            return this.create();
        } else if (this.actionType === 'update') {
            return this.update();
        }
    }

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;
    get mutatingDataParsed() {
        return defaultWritingDataParser(this.mutatingData);
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        const initialFormData = this.defaultMeta.defaultWidgetValues;
        this.mutatingData = _.cloneDeep(initialFormData);
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;

    get fieldAuthCode() {
        return this.listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    }
    get dataAuthCode() {
        return this.listType === 'all' ? 'SEARCH-PUBLIC' : 'SEARCH-PRIVATE';
    }
    public async fetchInstantlyMutatingDataById(id: any) {
        const { fieldAuthCode, dataAuthCode } = this;

        this.startNewData();
        this.mutatingDataLoading = true;
        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: id,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            undefined,
            dataAuthCode,
        );
        this.mutatingDataLoading = false;

        if (d === null || e !== null) {
            return false;
        }
        const { datas } = d;
        if (datas && datas[0]) {
            this.mutatingData = datas[0];
            return true;
        } else {
            message.error('没有该数据的权限');
            return false;
        }
    }
    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode, dataAuthCode } = this;

        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            this.defaultMeta.tableColsParamsFromUserSetting.map(
                item => item.key,
            ),
            dataAuthCode,
        );

        if (_.isNil(d) || !_.isNil(e)) {
            return [d, e];
        }
        const leadsData = d;
        const { amount, datas } = leadsData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    public async create() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        delete data.id;
        delete data.key;
        const [ret, err] = await mutate('sale_income', 'insert', data);
        if (!ret) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('新建成功');
        this.defaultEE.emit('create-success');
        return true;
    }
    public async update() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        delete data.key;
        const [ret, errMsg] = await mutate('sale_income', 'update', data);
        if (!ret || errMsg) {
            return false;
        }
        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('修改成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    public async delete() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = [data.id];
        const [d, e] = await xDelete(tableId, operatingIdList);
        if (!e) {
            message.info('删除成功');
            return true;
        }
        return false;
    }

    public async mdelete() {
        const ids = _.map(this.defaultMSelect.mSeletedData, item => item.id);
        if (ids.length === 0) {
            return false;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = ids;
        const [d, e] = await xDelete(tableId, operatingIdList);

        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('删除成功');
            return true;
        } else {
            this.defaultMSelect.setMopResult('批量删除结果', d.data);
            return false;
        }
    }

    get downloadUrl() {
        const { tableId: objectName } = this.defaultMeta;
        const { amount } = this.defaultPagination;
        if (!amount) {
            return '';
        }
        const pageSize = Math.max(Math.min(Math.ceil(amount / 10), 300), 100);
        const fetchBody = getCommonQueryBody(
            { ...this.defaultPagination.paginationArgs, pageSize },
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            undefined,
            this.defaultMeta.tableColsParamsFromUserSetting.map(
                item => item.key,
            ),
            undefined,
            false,
        );
        fetchBody.objectName = objectName;
        return `${
            window.location.origin
        }/bff/api/rest/export?q=${encodeURIComponent(
            JSON.stringify(fetchBody),
        )}`;
    }

    public extraStatisticResult: any = null;
    public setExtraStatisticResuilt(next: any) {
        this.extraStatisticResult = next;
    }
    public async getExtraStatistic() {
        const { fieldAuthCode, dataAuthCode } = this;

        const [d, e] = await commonQueryCount(
            this.defaultMeta.tableId,
            {
                pageSize: 1,
                pageNum: 1,
            },
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            ['income_money'],
            dataAuthCode,
        );

        if (!isNetSuccess(d, e) || _.isNil(d)) {
            return false;
        }
        this.extraStatisticResult = d;

        return true;
    }

    public async polulateByDept(deptObj: any) {
        const deptId = deptObj?.originalValue;

        const [d2, e2] = await commonQueryProcess(
            'sys_organization',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: deptId,
                        operateType: '=',
                    },
                ],
            },
            undefined,
            'PUBLIC-VIEW',
            undefined,
            'SEARCH-PUBLIC',
        );

        if (!d2) {
            return false;
        }
        const { datas: datas2 } = d2;
        if (!(datas2 && datas2[0])) {
            return false;
        }
        const dept = datas2[0];
        if (!this.mutatingData) {
            return false;
        }
        const parentDeptName = dept?.parent_depart_id?.relatedObj?.depart_name;
        if (!parentDeptName) {
            return false;
        }
        this.mutatingData.achievement_city = parentDeptName;
        return true;
    }

    public async populateByIncomeOwnerId(ownerObj: any) {
        const ownerId = ownerObj?.originalValue;
        const [d, e] = await commonQueryProcess(
            'sys_user',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: ownerId,
                        operateType: '=',
                    },
                ],
            },
            undefined,
            'PUBLIC-VIEW',
            undefined,
            'SEARCH-PUBLIC',
        );
        if (!d) {
            return false;
        }
        const { datas } = d;
        if (!(datas && datas[0])) {
            return false;
        }
        const owner = datas[0];
        if (!this.mutatingData) {
            return false;
        }

        if (!owner?.dim_depart?.relatedObj) {
            return false;
        }
        this.mutatingData.achievement_dept = owner?.dim_depart;
        const deptObj = owner?.dim_depart;
        return this.polulateByDept(deptObj);
    }

    public tableCode = 'sale_income';
    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMeta = new ListMeta(
        '/bff/api/rest/meta/process/list?target=any&tableCode=' +
            this.tableCode,
        '/bff/api/rest/meta/process/list/filters/save?tableCode=' +
            this.tableCode,
        '/bff/api/rest/meta/process/list/table-cols/save?tableCode=' +
            this.tableCode,
    );
    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();
    public defaultPerm: Perm = new Perm(
        '/bff/api/rest/perm?mod=om:sale_income',
    );
    public defaultMSelect: MSelectStore = new MSelectStore();
    public defaultFastFilters = new FastFilterStore();
    constructor() {
        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultMeta: false,
            defaultFilter: false,
            defaultEE: false,
            defaultPerm: false,
            defaultMSelect: false,
            defaultFastFilters: false,
        });
    }
}

export const getDefaultContractIncomeListStore = createDefaultInstanceGetter(
    ContractIncomeListStore,
);
