import React, { useState, ReactNodeArray } from 'react';
import _ from 'lodash';

import UserSelector, { IUserSelectorProps } from '../../user-selector/index';
import { ICommonProps, getCommonStatus } from './common';
import { loadingWrapper } from '@/components';
export type IWidgetUserSelectorValue = any[];
export interface IWidgetUserSelectorProps<T = any> extends ICommonProps<T> {
    options: IUserSelectorProps;
    value: any[] | any;
    onChange: (value: any[] | any) => void;
}

export const WidgetUserSelector: React.FC<IWidgetUserSelectorProps> = ({
    value,
    options,
    onChange,
    status,
    onInteract = key => void 0,
    data,
    statusExtraData,
}) => {
    const { loading, disabled } = getCommonStatus(
        status,
        data,
        statusExtraData,
    );
    const wrapper = loadingWrapper(loading || false);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }
    return wrapper(
        <UserSelector
            style={{ width: '100%', minWidth: 150 }}
            {...options}
            value={value}
            defaultValue={value}
            onFocus={() => {
                onInteract('focus');
                onInteract('remove-validate-status');
            }}
            onChange={(v: any) => {
                onInteract('validate-instantly', v);
                onChange(v);
            }}
            onBlur={() => {
                onInteract('blur');
                onInteract('validate-instantly');
            }}
        />,
    );
};
