import React, { useEffect } from 'react';
import ContactsForm from '@/pages/sales-follow-up/contact/list/components/form-drawer';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Modal } from 'antd';
import { useAnyContactsStore } from '@/pages/sales-follow-up/contact/list/store';
import _ from 'lodash';
import { tryRefresh } from '@/utils';
import { genLeadIdParamByContractItemAndParamMeta } from '@/pages/sales-follow-up/lead/list/store';

export const LeadContactsCreate: React.FC<{
    trigger: number;
    leadInfo: any;
}> = observer(({ trigger, leadInfo }) => {
    leadInfo = leadInfo || {};
    const customerId = leadInfo.customer_id;
    const leadId = leadInfo.id;
    const [prepared, defaultStore, prepareStore] = useAnyContactsStore({
        opUserSuffix: 'customer-drawer',
        autoLoad: false,
    });
    const { actionType } = defaultStore;

    useEffect(() => {
        if (trigger > 0) {
            prepareStore();
            runInAction(() => {
                defaultStore.setAction('create');
            });
        }
    }, [trigger]);

    useEffect(() => {
        if (!prepared || actionType !== 'create') {
            return;
        }
        defaultStore.startNewData();
        if (!customerId || !leadId) {
            return;
        }
        const { mutatingData } = defaultStore;
        const nextMutatingData = _.cloneDeep(mutatingData);
        nextMutatingData.customer_id = customerId;
        nextMutatingData.lead_id = genLeadIdParamByContractItemAndParamMeta(
            leadInfo,
        );

        nextMutatingData.contacts_name = leadInfo.name;
        nextMutatingData.contacts_duty = leadInfo.post;
        nextMutatingData.contacts_phone = leadInfo.phone;
        nextMutatingData.contacts_telephone = leadInfo.landline_phone;
        nextMutatingData.contacts_wechat = leadInfo.wechat_id;
        nextMutatingData.contacts_work_email = leadInfo.email;
        nextMutatingData.contacts_source = 3;
        defaultStore.setMutatingData(nextMutatingData);
    }, [prepared, actionType, leadInfo]);

    if (trigger === 0) {
        return null;
    }

    const preRequiredReady = prepared;
    return (
        <ContactsForm
            mutateSuccess={() => {
                tryRefresh(['lead']);
            }}
            defaultStore={defaultStore}
            preRequiredReady={preRequiredReady}
        />
    );
});
