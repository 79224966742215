import React, { useState, useEffect, useContext, useCallback } from 'react';
import './index.scss';
import { observer } from 'mobx-react-lite';
import { Spin, Icon, Modal, Input, Radio, Select, message } from 'antd';
import SettingMenu from '../../../components/common/setting-menu/index';
import _ from 'lodash';
import { IDRMAuthData, IDRMRoleData } from './store';
import { StoreContext } from '@/stores';
import { useNet } from '../../../utils/hooks/axios';
import { toJS, set } from 'mobx';
import { TableLv2, AntButton } from '@/components';
import DRMActionBody from './drm-action-body';
import { AntDrawer } from '@/components/antd/drawer';
import DrawTitle from '../../../components/common/drawer/drawer-title/index';

const simpleAuthArray = [
    {
        label: '本人',
        value: '1',
    },
    {
        label: '本人及下属',
        value: '2',
    },
    // {
    //     label: '本部门',
    //     value: '3',
    // },
    // {
    //     label: '本部门及下级部门',
    //     value: '4',
    // },
    {
        label: '全部',
        value: '5',
    },
];
interface IRenderDRMAuthEle {
    authName: string; // 权限名 (查询、编辑、删除、转移)
    authValue?: string | number; // 权限状态 (1 本人 2 本人及下属 3 本部门 4 本部门及下级部门 5 全部)
    canEdit?: boolean; // 是否是需要编辑的权限 (true: 可以编辑 false: 不可以)
}
// 从源数据转换的权限数据，用于渲染
interface IRenderDRMAuthData {
    moduleName: string; // 模块名称
    moduleId: string; // 模块id
    searchEle?: IRenderDRMAuthEle; // 空则渲染空 --渲染横线 数据渲染select
    editEle?: IRenderDRMAuthEle; // 编辑
    deleteEle?: IRenderDRMAuthEle; // 删除
    transformEle?: IRenderDRMAuthEle; // 转移
}
interface IUpdateDRMData {
    dataRoleName?: string; // 数据角色名称
    simpleAuth?: string; // 简易模式下四个选择
    authority: IRenderDRMAuthData[];
}
const DataRoleManagement: React.FC<{}> = props => {
    const store = useContext(StoreContext);
    const DRMStore = store.getDefaultDRMStore();
    const {
        fetchDRMRoleList,
        DRMRoleListData,
        isUpdateLoading,
        deleteDRMRole,
        createDRMRole,
        fetchConfig,
    } = DRMStore;
    const [selectedData, setSelectedData] = useState<IDRMRoleData>({});

    const [modalVis, setModalVis] = useState(false); // 新建角色的弹窗vis
    const [createNameInputValue, setCreateNameInputValue] = useState<string>();
    const [validateCreateRole, setValidateCreateRole] = useState<{
        [key: string]: {
            status: 'error' | 'success';
            msg: string;
        };
    }>({
        dataRoleName: {
            status: 'success',
            msg: '',
        },
    });

    const [isSaveClick, setIsSaveClick] = useState(false); // 纯粹的副作用控制内部上传动作
    const drmRoleDataFetch = useCallback(fetchDRMRoleList, []);
    const [error, roleLoading, roleReload] = useNet(drmRoleDataFetch);
    useEffect(() => {
        fetchConfig();
    }, []);
    const onMenuActionDispatch = (actionKey: string, data: any) => {
        switch (actionKey) {
            case 'edit':
                break;
            case 'delete':
                Modal.confirm({
                    content: (
                        <span>
                            该操作将删除
                            {
                                <span style={{ fontWeight: 500 }}>
                                    「{data?.dataRoleName}」
                                </span>
                            }
                            ，删除后该角色数据将不能再使用，请确认
                        </span>
                    ),
                    onOk: async () => {
                        const [d, e] = await deleteDRMRole(data);
                        if (d && !d.msg) {
                            if (data.id === selectedData.id) {
                                // 删除选中的则清空右侧
                                setSelectedData({});
                            }
                            message.success('删除成功');
                            roleReload();
                        } else {
                            message.error(d?.msg || '删除失败');
                        }
                    },
                });
                break;
            default:
                break;
        }
    };
    return (
        <div className="pa-content">
            {
                <Modal
                    className="drm-modal-content"
                    title="新建数据角色"
                    onCancel={() => {
                        setModalVis(false);
                    }}
                    onOk={async () => {
                        if (!createNameInputValue) {
                            setValidateCreateRole({
                                ...setValidateCreateRole,
                                dataRoleName: {
                                    status: 'error',
                                    msg: '请填写角色姓名',
                                },
                            });
                            return;
                        }
                        const [d, e] = await createDRMRole(
                            createNameInputValue,
                        );
                        if (d) {
                            message.success('新增数据角色成功');
                            roleReload();
                            setCreateNameInputValue(undefined);
                            setModalVis(false);
                        } else {
                            message.error('新增数据角色失败');
                        }
                    }}
                    visible={modalVis}
                >
                    <div className="drm-action-body">
                        <div className="pa-table-item">
                            <label
                                htmlFor="name"
                                className="pa-table-item-label"
                            >
                                角色名称<span className="required-mark">*</span>
                            </label>
                            <Input
                                className={
                                    validateCreateRole.dataRoleName.status ===
                                    'error'
                                        ? 'has-error'
                                        : ''
                                }
                                placeholder="请输入角色名称"
                                style={{ width: '300px' }}
                                id="name"
                                onChange={e => {
                                    setCreateNameInputValue(e.target.value);
                                }}
                                onFocus={() => {
                                    setValidateCreateRole({
                                        ...setValidateCreateRole,
                                        dataRoleName: {
                                            status: 'success',
                                            msg: '',
                                        },
                                    });
                                }}
                                value={createNameInputValue}
                            />
                        </div>
                    </div>
                </Modal>
            }
            <div className="pa-title">
                数据角色管理
                <span className="pa-title-description">
                    用来控制用户查看数据的范围，设置完成后请在「组织架构」中为人员分配角色
                </span>
                <div className="pa-title-action-bar">
                    <AntButton type="primary" onClick={() => setModalVis(true)}>
                        新建数据角色
                    </AntButton>
                </div>
            </div>
            <div className="pa-body">
                <Spin spinning={roleLoading}>
                    <div className="pa-menu-content">
                        <SettingMenu
                            menuItemsData={DRMRoleListData}
                            uniqueKeyIndex={'id'}
                            titleIndex={'dataRoleName'}
                            actionOptions={[
                                {
                                    actionCn: <Icon type="delete" />,
                                    actionKey: 'delete',
                                },
                            ]}
                            onAction={onMenuActionDispatch}
                            onSelect={(selectedKey: string, data: any) => {
                                setSelectedData(data);
                            }}
                        />
                    </div>
                </Spin>
                <div className="pa-table-content drm-table-content">
                    {!_.isEmpty(selectedData) && (
                        <>
                            <DRMActionBody
                                selectedData={selectedData}
                                saveBtnClassName={'update-pa-btn'}
                                roleReload={roleReload}
                            />
                            <div className="save-bar"></div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default observer(DataRoleManagement);
