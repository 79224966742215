export const listMeta = {
    objectMeta: [
        {
            id: 4,
            objId: 1,
            fieldName: 'owner_id',
            fieldComment: '客户所有人',
            fieldBind: 'owner_id',
            fieldType: 'bigint',
            fieldLength: 20,
            fieldDecimalLength: null,
            isEdit: 1,
            isRequired: 1,
            isDefaultValue: 1,
            defaultValue: '',
            fieldStatus: 1,
            constraint: {
                fieldConstraintType: 'obj',
                constraintItemList: null,
                constraintObj: {
                    constraintObj: 5,
                    objShowFieldId: 81,
                    objFindFieldIdList: [78, 79, 81],
                    objSaveFieldId: 78,
                },
                constraintObjMeta: {
                    id: 5,
                    objName: 'sys_user',
                    objComment: '用户表',
                },
                objFullMeta: [
                    {
                        id: 78,
                        objId: 5,
                        fieldName: 'id',
                        fieldComment: 'Key',
                        fieldBind: 'id',
                        fieldType: 'bigint',
                        fieldLength: 11,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'number',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 79,
                        objId: 5,
                        fieldName: 'phone',
                        fieldComment: '手机号码',
                        fieldBind: 'phone',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 80,
                        objId: 5,
                        fieldName: 'entity_type',
                        fieldComment: '用户业务类型',
                        fieldBind: 'entity_type',
                        fieldType: 'tinyint',
                        fieldLength: 1,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'switch',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 81,
                        objId: 5,
                        fieldName: 'name',
                        fieldComment: '姓名',
                        fieldBind: 'name',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 82,
                        objId: 5,
                        fieldName: 'dim_depart',
                        fieldComment: '部门',
                        fieldBind: 'dim_depart',
                        fieldType: 'bigint',
                        fieldLength: 20,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'number',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 83,
                        objId: 5,
                        fieldName: 'employee_code',
                        fieldComment: '员工编号',
                        fieldBind: 'employee_code',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 84,
                        objId: 5,
                        fieldName: 'union_id',
                        fieldComment: '唯一标识',
                        fieldBind: 'union_id',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 85,
                        objId: 5,
                        fieldName: 'unique_key',
                        fieldComment: '唯一Key',
                        fieldBind: 'unique_key',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 86,
                        objId: 5,
                        fieldName: 'gender',
                        fieldComment: '性别',
                        fieldBind: 'gender',
                        fieldType: 'tinyint',
                        fieldLength: 1,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'switch',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 87,
                        objId: 5,
                        fieldName: 'enterprise_wechat_account',
                        fieldComment: '企业微信账号',
                        fieldBind: 'enterprise_wechat_account',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 88,
                        objId: 5,
                        fieldName: 'join_at',
                        fieldComment: '入职日期',
                        fieldBind: 'join_at',
                        fieldType: 'datetime',
                        fieldLength: 0,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'date',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 89,
                        objId: 5,
                        fieldName: 'birthday',
                        fieldComment: '出生日期',
                        fieldBind: 'birthday',
                        fieldType: 'datetime',
                        fieldLength: 0,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'date',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 90,
                        objId: 5,
                        fieldName: 'position_name',
                        fieldComment: '职位',
                        fieldBind: 'position_name',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 91,
                        objId: 5,
                        fieldName: 'rank_id',
                        fieldComment: '职级',
                        fieldBind: 'rank_id',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 92,
                        objId: 5,
                        fieldName: 'manager_id',
                        fieldComment: '直属上级',
                        fieldBind: 'manager_id',
                        fieldType: 'bigint',
                        fieldLength: 20,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'number',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 93,
                        objId: 5,
                        fieldName: 'related_area',
                        fieldComment: '相关区域',
                        fieldBind: 'related_area',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 94,
                        objId: 5,
                        fieldName: 'related_business',
                        fieldComment: '相关业务',
                        fieldBind: 'related_business',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 95,
                        objId: 5,
                        fieldName: 'related_industry',
                        fieldComment: '相关行业',
                        fieldBind: 'related_industry',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 96,
                        objId: 5,
                        fieldName: 'nick_name',
                        fieldComment: '昵称',
                        fieldBind: 'nick_name',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 97,
                        objId: 5,
                        fieldName: 'status',
                        fieldComment: '状态',
                        fieldBind: 'status',
                        fieldType: 'tinyint',
                        fieldLength: 1,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'switch',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 98,
                        objId: 5,
                        fieldName: 'lastest_login_at',
                        fieldComment: '最近一次登录时间',
                        fieldBind: 'lastest_login_at',
                        fieldType: 'datetime',
                        fieldLength: 0,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'date',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 99,
                        objId: 5,
                        fieldName: 'self_intro',
                        fieldComment: '自我介绍',
                        fieldBind: 'self_intro',
                        fieldType: 'varchar',
                        fieldLength: 255,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 100,
                        objId: 5,
                        fieldName: 'telephone',
                        fieldComment: '办公电话',
                        fieldBind: 'telephone',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 101,
                        objId: 5,
                        fieldName: 'hometown',
                        fieldComment: '家乡',
                        fieldBind: 'hometown',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 102,
                        objId: 5,
                        fieldName: 'im',
                        fieldComment: 'QQ/MSN',
                        fieldBind: 'im',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 103,
                        objId: 5,
                        fieldName: 'weibo',
                        fieldComment: '微博',
                        fieldBind: 'weibo',
                        fieldType: 'varchar',
                        fieldLength: 64,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 104,
                        objId: 5,
                        fieldName: 'hobby',
                        fieldComment: '兴趣爱好',
                        fieldBind: 'hobby',
                        fieldType: 'varchar',
                        fieldLength: 255,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'input',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 105,
                        objId: 5,
                        fieldName: 'create_time',
                        fieldComment: '创建时间',
                        fieldBind: 'create_time',
                        fieldType: 'timestamp',
                        fieldLength: 0,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'date',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 106,
                        objId: 5,
                        fieldName: 'create_user',
                        fieldComment: '创建人',
                        fieldBind: 'create_user',
                        fieldType: 'bigint',
                        fieldLength: 11,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'number',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 107,
                        objId: 5,
                        fieldName: 'update_time',
                        fieldComment: '修改时间',
                        fieldBind: 'update_time',
                        fieldType: 'timestamp',
                        fieldLength: 0,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'date',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 108,
                        objId: 5,
                        fieldName: 'update_user',
                        fieldComment: '修改人',
                        fieldBind: 'update_user',
                        fieldType: 'bigint',
                        fieldLength: 11,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'number',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                    {
                        id: 109,
                        objId: 5,
                        fieldName: 'is_del',
                        fieldComment: '是否删除，1正常0删除',
                        fieldBind: 'is_del',
                        fieldType: 'tinyint',
                        fieldLength: 1,
                        fieldDecimalLength: null,
                        isEdit: 1,
                        isRequired: 1,
                        isDefaultValue: 1,
                        defaultValue: '',
                        fieldStatus: 1,
                        constraint: null,
                        groupId: 0,
                        groupKey: null,
                        groupName: null,
                        fieldOrder: 0,
                        fieldShowType: 'switch',
                        isServerCheck: 0,
                        regularId: -1,
                        regularExp: null,
                        paramSuggestion: null,
                    },
                ],
                objShowField: {
                    id: 81,
                    objId: 5,
                    fieldName: 'name',
                    fieldComment: '姓名',
                    fieldBind: 'name',
                    fieldType: 'varchar',
                    fieldLength: 64,
                    fieldDecimalLength: null,
                    isEdit: 1,
                    isRequired: 1,
                    isDefaultValue: 1,
                    defaultValue: '',
                    fieldStatus: 1,
                    constraint: null,
                    groupId: 0,
                    groupKey: null,
                    groupName: null,
                    fieldOrder: 0,
                    fieldShowType: 'input',
                    isServerCheck: 0,
                    regularId: -1,
                    regularExp: null,
                    paramSuggestion: null,
                },
                objSaveField: {
                    id: 78,
                    objId: 5,
                    fieldName: 'id',
                    fieldComment: 'Key',
                    fieldBind: 'id',
                    fieldType: 'bigint',
                    fieldLength: 11,
                    fieldDecimalLength: null,
                    isEdit: 1,
                    isRequired: 1,
                    isDefaultValue: 1,
                    defaultValue: '',
                    fieldStatus: 1,
                    constraint: null,
                    groupId: 0,
                    groupKey: null,
                    groupName: null,
                    fieldOrder: 0,
                    fieldShowType: 'number',
                    isServerCheck: 0,
                    regularId: -1,
                    regularExp: null,
                    paramSuggestion: null,
                },
            },
            groupId: 1,
            groupKey: 'base',
            groupName: '基本信息',
            fieldOrder: 10,
            fieldShowType: 'select',
            isServerCheck: 0,
            regularId: -1,
            regularExp: null,
            paramSuggestion: null,
        },
    ],
};
