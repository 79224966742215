import React from 'react';
import { Button } from 'antd';
import { AntButton } from '@/components';
import _ from 'lodash';
import { ButtonType, ButtonProps } from 'antd/lib/button';

// | 按钮         | 文本 | 参数   | 说明                         |
// | ------------ | ---- | ------ | ---------------------------- |
// | SUBMIT       | 提交 |        | 申请人提交                   |
// | AGREE        | 通过 |        | 审批人同意                   |
// | REJECT_BACK  | 拒绝 |        | 审批人拒绝-退回上一步        |
// | REJECT_BEGIN | 拒绝 |        | 审批人拒绝-退回开始          |
// | REJECT_APPLY | 拒绝 |        | 审批人拒绝-退回申请          |
// | RESET        | 撤回 |        | 申请人撤回                   |
// | ADD_APPROVE  | 加签 | 加签人 | 审批人添加新的审批人（多人） |
// | TRANSFER     | 转办 | 转办人 | 审批人转移任务给其他人       |

const btnMap: { [str: string]: string } = {
    SUBMIT: '提交',
    AGREE: '通过',
    REJECT_BACK: '拒绝(退回上一步)',
    REJECT_BEGIN: '拒绝(退回开始)',
    REJECT_APPLY: '拒绝(退回申请)',
    RESET: '撤回',
    ADD_APPROVE: '加签',
    TRANSFER: '转办',
    DELETE: '删除',
};

export type TBtnsCusRenders = {
    [key: string]: ButtonProps;
};

export const ProcessButtons: React.FC<{
    buttons: string[];
    customRenders?: TBtnsCusRenders;
    onButtonClick: (type: string) => void;
}> = ({ buttons, onButtonClick, customRenders = {} }) => {
    return (
        <>
            {buttons
                .map(btnStr => {
                    if (btnStr === 'ADD_APPROVE') {
                        return null;
                    }
                    const cn = btnMap[btnStr];
                    let type: ButtonType = 'primary';
                    if (_.startsWith(btnStr, 'REJECT') || btnStr === 'DELETE') {
                        type = 'danger';
                    }
                    if (cn) {
                        if (customRenders[btnStr]) {
                            return (
                                <>
                                    <AntButton
                                        size="large"
                                        type={type}
                                        onClick={() => {
                                            onButtonClick(btnStr);
                                        }}
                                        {...customRenders[btnStr]}
                                    ></AntButton>
                                    &nbsp;
                                </>
                            );
                        }
                        return (
                            <>
                                <AntButton
                                    size="large"
                                    type={type}
                                    onClick={() => {
                                        onButtonClick(btnStr);
                                    }}
                                >
                                    {cn}
                                </AntButton>
                                &nbsp;
                            </>
                        );
                    }
                })
                .filter(Boolean)}
        </>
    );
};
