import React, { useEffect, memo } from 'react';

import { ProductListStore } from '../store';
import { Modal, Alert } from 'antd';

import { runInAction } from 'mobx';
import _ from 'lodash';
import { MOpNameDisplayContainer } from '@/components';
import { observer } from 'mobx-react-lite';
import { tryRefresh } from '@/utils';

export const ActionMDelete: React.FC<{
    defaultStore: ProductListStore;
}> = observer(({ defaultStore }) => {
    const { defaultMSelect } = defaultStore;
    const { mSeletedData } = defaultMSelect;
    if (mSeletedData === null || mSeletedData.length === 0) {
        return null;
    }
    const visible = defaultStore.actionType === 'm-delete';
    return (
        <Modal
            visible={visible}
            title={'批量删除产品'}
            onCancel={() => {
                defaultStore.resetAction();
            }}
            onOk={async () => {
                const ok = await defaultStore.mdelete();
                if (ok) {
                    runInAction(() => {
                        defaultStore.resetAction();
                        defaultMSelect.removeAllSelectedData();
                        tryRefresh(['contacts']);
                    });
                }
            }}
        >
            <h4>
                是否将选择的{mSeletedData.length}个产品删除？
                <MOpNameDisplayContainer
                    defaultStore={defaultStore}
                    nameParam="product_name"
                />
            </h4>
            <Alert type="error" message="删除成功之后，该操作无法恢复。" />
        </Modal>
    );
});
