import React, { useState, useEffect, useContext } from 'react';
import './index.less';
import { defaultAxios } from '@/utils';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
const AnalysisPage: React.FC = props => {
    const store = useContext(StoreContext);
    const anaStore = store.getDefaultAnalysisStore();
    useEffect(() => {
        anaStore.fetchAnaAuth();
    }, []);
    return <div className="analysis-content">{props.children}</div>;
};

export default observer(AnalysisPage);
