import React, { useRef, useCallback, useState, CSSProperties } from 'react';
import './index.scss';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

const widthHelper = document.createElement('span');
widthHelper.style.visibility = 'hidden';
document.body.appendChild(widthHelper);
const getTextWidth = (text: string) => {
    widthHelper.innerText = text;
    return widthHelper.offsetWidth;
};

export const AutoEllipsis: React.FC<{
    text: React.ReactNode;
    style?: CSSProperties;
    className?: string;
    placement?: TooltipPlacement;
}> = ({ text, style, className = '', placement = 'right' }) => {
    // const [node, setNode] = useState<HTMLSpanElement | null>(null);
    // const nodeRef = useCallback(node => {
    //     if (node !== null) {
    //         setNode(node);
    //     }
    // }, []);

    if (!text) {
        return null;
    }
    // let showTooltip = false;
    // if (node !== null && typeof text === 'string') {
    //     const width = node.offsetWidth;
    //     const textWidth = getTextWidth(text);
    //     showTooltip = textWidth > width;
    // }

    // let wrapper = (ele: any) => ele;
    // if (showTooltip) {
    //     wrapper = (ele: any) => (
    //         <Tooltip placement={placement} title={text}>
    //             {ele}
    //         </Tooltip>
    //     );
    // }

    // return wrapper(
    return (
        <span
            // ref={nodeRef}
            style={style}
            className={'crm-auto-ellipsis ' + className}
        >
            {text}
        </span>
    );
    // );
};
