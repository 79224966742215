import React, { useState } from 'react';

import { ContactListStore } from '../store';
import { Modal, Form } from 'antd';
import _ from 'lodash';
import {
    WidgetCommonObject,
    WidgetSelect,
} from '@/components/common/form/widgets';
import './transform.scss';
import { runInAction } from 'mobx';
import TextArea from 'antd/lib/input/TextArea';
import { observer } from 'mobx-react-lite';
import { MOpNameDisplayContainer } from '@/components';
import { tryRefresh } from '@/utils';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const ActionTransform: React.FC<{
    defaultStore: ContactListStore;
}> = observer(({ defaultStore }) => {
    const { defaultMSelect } = defaultStore;
    const { mSeletedData } = defaultMSelect;
    const [customer, setCustomer] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const [requiredDetected, setRequiredDetected] = useState(false);
    const inputHelps: any = requiredDetected
        ? {
              validateStatus: 'error',
              help: '新关联客户必填',
          }
        : {};

    const [reason, setReason] = useState(-1);
    const [remark, setRemark] = useState('');
    const resetForm = () => {
        setCustomer(null);
        setReason(-1);
        setRemark('');
    };
    const [requiredReasonDetected, setRequiredReasonDetected] = useState(false);
    const reasonHelps: any = requiredReasonDetected
        ? {
              validateStatus: 'error',
              help: '转移原因必填',
          }
        : {};

    if (mSeletedData.length === 0) {
        return null;
    }

    const bizType = mSeletedData[0]?.biz_type;
    const visible = defaultStore.actionType === 'transform';

    const { defaultMeta: listMeta } = defaultStore;
    const pageSize = 100;

    const ownerCol = _.find(
        listMeta.objectMeta,
        item => item.fieldName === 'customer_id',
    );
    if (!ownerCol) {
        return <span>customer_id, meta info not found</span>;
    }
    const constraint = ownerCol.constraint;

    if (!constraint) {
        return <span>customer_id, constraint info not found</span>;
    }

    const reasonOptions = [
        {
            constraintLabel: '联系人关联错误',
            constraintValue: 1,
        },
        {
            constraintLabel: '客户改名',
            constraintValue: 2,
        },
        {
            constraintLabel: '人员变动',
            constraintValue: 3,
        },
        {
            constraintLabel: '其他',
            constraintValue: 4,
        },
    ];

    return (
        <Modal
            visible={visible}
            title={'联系人转移'}
            onCancel={() => {
                defaultStore.resetAction();
                resetForm();
            }}
            confirmLoading={loading}
            onOk={async () => {
                let hasInvalid = false;
                if (customer === null) {
                    hasInvalid = true;
                    setRequiredDetected(true);
                }
                // if (reason === -1) {
                //     hasInvalid = true;
                //     setRequiredReasonDetected(true);
                // }
                if (hasInvalid) {
                    return;
                }
                setLoading(true);
                const ok = await defaultStore.transform(customer);
                setLoading(false);
                if (ok) {
                    resetForm();
                    runInAction(() => {
                        defaultStore.resetAction();
                        defaultMSelect.removeAllSelectedData();
                        tryRefresh(['contacts']);
                    });
                }
            }}
        >
            <p>
                转移联系人<b>{mSeletedData.length}个</b>：
                <MOpNameDisplayContainer
                    defaultStore={defaultStore}
                    nameParam="contacts_name"
                />
            </p>
            <div className="customer-m-transform">
                <div className="form-wrapper">
                    <Form className="form" layout={'horizontal'}>
                        <Form.Item
                            {...inputHelps}
                            {...formItemLayout}
                            required
                            label={<span className="label">新关联客户</span>}
                        >
                            <WidgetCommonObject
                                k={'customer_id'}
                                value={customer}
                                onChange={val => {
                                    setCustomer(val);
                                    setRequiredDetected(false);
                                }}
                                options={{
                                    placeholder: '请选择新关联客户',
                                    constraint,
                                    listMeta,
                                    pageSize,
                                    fieldAuthCode: 'PUBLIC-VIEW',
                                    dataAuthCode: 'SEARCH-TRANSFER',
                                    entityCode: 'contacts',
                                    defaultFilters: [
                                        {
                                            fieldName: 'biz_type',
                                            operateType: 'EQUAL',
                                            fieldValue: bizType,
                                        },
                                    ],
                                    reloadTrigger: 'bizType_' + bizType,
                                    listClearTrigger: 'bizType_' + bizType,
                                }}
                            />
                        </Form.Item>
                        {/* <Form.Item
                            {...reasonHelps}
                            {...formItemLayout}
                            required
                            label={<span className="label">转移原因</span>}
                        >
                            <WidgetSelect
                                value={reason}
                                options={{
                                    placeholder: '请选择转移原因',
                                    options: reasonOptions.map(
                                        (option: any) => ({
                                            name: option.constraintLabel,
                                            value: option.constraintValue,
                                        }),
                                    ),
                                }}
                                onChange={value => {
                                    setReason(value);
                                    setRequiredReasonDetected(false);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={<span className="label">备注</span>}
                        >
                            <TextArea
                                placeholder="备注信息（选填）"
                                value={remark}
                                onChange={e => {
                                    const value = e?.target?.value;
                                    if (!_.isNil(value)) {
                                        setRemark(value);
                                    }
                                }}
                            />
                        </Form.Item> */}
                    </Form>
                </div>
            </div>
        </Modal>
    );
});
