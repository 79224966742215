import React, { useState, useContext, useEffect } from 'react';

import { LeadListStore } from '../store';
import { Modal, message, Button } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import './transform.scss';
import { BlockLoading2, AntTable } from '@/components';
import { defaultAxios } from '@/utils';
import { ContactListStore } from '@/pages/sales-follow-up/contact/list/store';

const leadListColumns = [
    {
        title: '客户所有人',
        dataIndex: 'customer_owner_name',
        key: 'customer_owner_name',
    },
    {
        title: '客户名称',
        dataIndex: 'customer_name',
        key: 'customer_name',
    },
    {
        title: '线索姓名',
        dataIndex: 'lead_name',
        key: 'lead_name',
    },
    {
        title: '手机',
        dataIndex: 'lead_phone',
        key: 'lead_phone',
    },
];

const contactsListColumns = [
    {
        title: '客户所有人',
        dataIndex: 'customer_owner_name',
        key: 'customer_owner_name',
    },
    {
        title: '客户名称',
        dataIndex: 'customer_name',
        key: 'customer_name',
    },
    {
        title: '联系人姓名',
        dataIndex: 'contacts_name',
        key: 'contacts_name',
    },
    {
        title: '手机',
        dataIndex: 'contacts_phone',
        key: 'contacts_phone',
    },
    {
        title: '合作状态',
        dataIndex: 'cooperation_status',
        key: 'cooperation_status',
    },
    {
        title: '最后回款时间',
        dataIndex: 'income_time',
        key: 'income_time',
    },
];

export const ActionDedupDetail: React.FC<{
    leadList?: LeadListStore;
    contactList?: ContactListStore;
    caredLead?: any;
    caredContacts?: any;
}> = ({ leadList, contactList, caredLead, caredContacts }) => {
    let isLead = false;
    let isContacts = false;
    if (!!caredLead) {
        isLead = true;
    }
    if (!!caredContacts) {
        isContacts = true;
    }

    const [data, setData] = useState<any>({});
    const [dataLoading, setDataLoading] = useState(true);

    let visible = false;
    if (isLead) {
        visible = !!(leadList && leadList.actionType === 'see-dupli-detail');
    }
    if (isContacts) {
        visible = !!(
            contactList && contactList.actionType === 'see-dupli-detail'
        );
    }
    const loading = dataLoading;

    useEffect(() => {
        if (caredLead === null) {
            return;
        }
        (async () => {
            if (!visible) {
                return false;
            }
            setDataLoading(true);

            // 兼容线索和联系人
            let api = '';
            let query = {};
            if (isLead) {
                api = '/crm/lead/findLeadDuplicateFlagInfo';
                query = { leadId: caredLead.id };
            }
            if (isContacts) {
                api = '/crm/contacts/findContactsDuplicateFlagInfo';
                query = { contactsId: caredContacts.id };
            }

            const [d, e] = await defaultAxios.get(api, query);
            setDataLoading(false);
            if (e) {
                return;
            }
            if (d) {
                d.data.contacts_info_list = d.data.contacts_info_list.map(
                    (item: any) => {
                        item.income_time = item.income_time
                            ? moment(item.income_time).format(
                                  'YYYY-MM-DD HH:mm:ss',
                              )
                            : '';
                        item.cooperation_status = (() => {
                            switch (item.cooperation_status) {
                                case 1:
                                    return '未合作';
                                case 2:
                                    return '合作中';
                                case 3:
                                    return '濒危客户';
                                case 4:
                                    return '到期未续约';
                                case 5:
                                    return '合作客户相关客户';
                                default:
                                    return '';
                            }
                        })();
                        return item;
                    },
                );
                setData(d.data);
            }
        })();
    }, [caredLead, caredContacts, visible]);

    if ((caredLead && caredContacts) || (!caredLead && !caredContacts)) {
        return null;
    }

    const dataContactsInfoList = data.contacts_info_list || [];

    const dataLeadList = data.lead_list || [];

    return (
        <Modal
            title={'疑似重复'}
            visible={visible}
            onCancel={() => {
                if (isLead) {
                    leadList?.resetAction();
                    leadList?.clearDupDetailLead();
                } else {
                    contactList?.resetAction();
                    contactList?.clearDupDetailContacts();
                }
            }}
            width={850}
            footer={[
                <Button
                    onClick={async () => {
                        if (isLead) {
                            leadList?.resetAction();
                            leadList?.clearDupDetailLead();
                        } else {
                            contactList?.resetAction();
                            contactList?.clearDupDetailContacts();
                        }
                    }}
                    type="primary"
                >
                    关闭
                </Button>,
            ]}
        >
            <div>
                {isLead && (
                    <p>
                        姓名为“<b>{caredLead.name}</b>
                        ”的线索，可能与以下数据重复【查重字段：手机号码】
                    </p>
                )}
                {isContacts && (
                    <p>
                        姓名为“<b>{caredContacts.contacts_name}</b>
                        ”的联系人，可能与以下数据重复【查重字段：手机号码】
                    </p>
                )}
                {loading && <BlockLoading2 />}
                {!loading && (
                    <div>
                        {dataLeadList.length > 0 && (
                            <>
                                <h3 style={{ marginTop: 10 }}>
                                    线索（{dataLeadList.length}）
                                </h3>
                                <AntTable
                                    columns={leadListColumns}
                                    dataSource={dataLeadList}
                                />
                            </>
                        )}
                        {dataContactsInfoList.length > 0 && (
                            <>
                                <h3 style={{ marginTop: 10 }}>
                                    联系人（{dataContactsInfoList.length}）
                                </h3>
                                <AntTable
                                    columns={contactsListColumns}
                                    dataSource={dataContactsInfoList}
                                />
                            </>
                        )}
                    </div>
                )}
            </div>
        </Modal>
    );
};
