import React, { useState, useEffect, useContext } from 'react';
import './index.less';
import { observer } from 'mobx-react-lite';
import AchievementSituation from './component/achievement-situation';
import PerformanceAnalysisCompositeContent from './component/performance-analysis';
import { voyagerTrack } from '@shared/voyager';
import { StoreContext } from '@/stores';

const CompositeLivePage: React.FC = props => {
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    useEffect(() => {
        voyagerTrack('crm_composite_live_view', {
            crm_uid: authStore?.userInfo?.userId,
        });
    }, []);
    return (
        <div className="composite-live-content">
            <AchievementSituation />
        </div>
    );
};

export default observer(CompositeLivePage);
