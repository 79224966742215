import React, { useState, useContext, useCallback, useEffect } from 'react';
import './index.scss';
import '../log.scss';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { LayoutStandardCrud, AntButton, TableLv2 } from '@/components';
import { DatePicker, Input } from 'antd';
import moment, { Moment } from 'moment';
import { ILoginLogData } from './store';
import _ from 'lodash';
import { useNet } from '@/utils';
import UserSelector from '../../../../components/common/user-selector/index';
const RangePicker = DatePicker.RangePicker;
const LoginLogPage: React.FC = props => {
    const store = useContext(StoreContext);
    const loginLogStore = store.getDefaultLoginLogStore();
    const settingStore = store.getSettingDefaultStore();
    const {
        getLoginLogData,
        loginLogData,
        defaultLoginLogPagination,
    } = loginLogStore;

    const { customerInfo, isCustomerInfoLoading } = settingStore;
    const [logSearchValue, setLogSearchValue] = useState<{
        userId?: string | number;
        beginDate: Moment | string;
        endDate: Moment | string;
    }>({
        userId: undefined,
        beginDate: moment()
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment()
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
    });
    const [error, loading, reload] = useNet(() =>
        getLoginLogData(logSearchValue),
    );
    // 时间选择变更
    const onActionSearchTimeChange = (
        dates: any,
        dateStrings: [string, string],
    ) => {
        setLogSearchValue({
            ...logSearchValue,
            beginDate: dateStrings[0],
            endDate: dateStrings[1],
        });
    };
    useEffect(() => {
        reload();
    }, [defaultLoginLogPagination.pagenum]);
    const renderLoginPageFilter = () => {
        return (
            <div className="log-filter-content">
                <div className="log-filter-item">
                    <label htmlFor="time">时间：</label>
                    <RangePicker
                        name="time"
                        value={[
                            moment(logSearchValue.beginDate),
                            moment(logSearchValue.endDate),
                        ]}
                        showTime={true}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={onActionSearchTimeChange}
                        style={{ width: '400px' }}
                        allowClear={false}
                    />
                </div>
                <div className="log-filter-item">
                    <label htmlFor="user">用户：</label>
                    <UserSelector
                        style={{ width: '300px' }}
                        name="user"
                        value={logSearchValue.userId}
                        mode="default"
                        placeholder="请选择用户"
                        onChange={e => {
                            setLogSearchValue({
                                ...logSearchValue,
                                userId: e as string,
                            });
                        }}
                        loading={isCustomerInfoLoading}
                        userDocs={customerInfo as any}
                    />
                    {/* <Input
                        width="300px"c
                        name="user"
                        value={logSearchValue.user}
                        placeholder="请输入选择用户名称"
                        onChange={e => {
                            setLogSearchValue({
                                ...logSearchValue,
                                user: e.target.value,
                            });
                        }}
                    /> */}
                </div>
                <AntButton
                    type="primary"
                    onClick={() => {
                        defaultLoginLogPagination.pagenum = 1;
                        reload();
                    }}
                >
                    查询
                </AntButton>
            </div>
        );
    };
    return (
        <LayoutStandardCrud
            title={'用户登录日志'}
            message={null}
            popups={null}
            multipleOps={null}
            globalOps={null}
            filters={renderLoginPageFilter()}
            dataTable={
                <TableLv2<ILoginLogData>
                    loading={loading}
                    dataSource={loginLogData}
                    closeRowSelection={true}
                    pagination={{
                        ...defaultLoginLogPagination.paginationForUI,
                        total: defaultLoginLogPagination.amount,
                    }}
                    onPaginationChange={
                        defaultLoginLogPagination.setPaginationByUI
                    }
                    columns={[
                        {
                            title: '用户',
                            key: 'userName',
                            dataIndex: 'userName',
                            width: '90px',
                        },
                        {
                            title: '登录时间',
                            key: 'loginTime',
                            width: '150px',
                            dataIndex: 'loginTime',
                            render: v => {
                                if (!v) {
                                    return null;
                                }
                                return moment(v).format('YYYY-MM-DD HH:mm:ss');
                            },
                        },
                        {
                            title: '登录方式',
                            key: 'loginWay',
                            dataIndex: 'loginWay',
                            width: '120px',
                        },
                        {
                            title: 'IP',
                            key: 'ip',
                            dataIndex: 'ip',
                            width: '120px',
                        },
                        {
                            title: '登录地点',
                            key: 'loginPlace',
                            dataIndex: 'loginPlace',
                            width: '150px',
                        },
                        {
                            title: '设备类型',
                            key: 'deviceType',
                            dataIndex: 'deviceType',
                            width: '120px',
                        },
                        {
                            title: '终端内核',
                            key: 'kernel',
                            dataIndex: 'kernel',
                            width: '150px',
                        },
                        {
                            title: '平台',
                            key: 'platform',
                            dataIndex: 'platform',
                            width: '120px',
                        },
                    ]}
                />
            }
        />
    );
};
export default observer(LoginLogPage);
