import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
} from '@/components';
import { message } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet } from '@/utils';
import { observer } from 'mobx-react-lite';
import DemoTable from './components/table-for-home-page';
import { defaultAuthDeco } from '@/containers/hoc';
import './index.scss';
import { homePageMetaGetter } from './utils';

const ActiveRecordListHome: React.FC<{
    type: string;
}> = ({ type }) => {
    const store = useContext(StoreContext);
    const [defaultStore] = useState(() => new store.ActiveRecordStore());
    const userStore = store.getAuthStore();

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        pageTitle,
        defaultPerm,
        defaultFilter,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const listFetch = useCallback(() => defaultStore.fetch(), []);
    const [error, loading, reload] = useNet(listFetch, {
        autoLoad: false,
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        confirmedfilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            defaultStore.setDefaultFilters([
                {
                    fieldId: 'next_note',
                    filterValue: null,
                    operateType: 'not_null',
                    parser: 'string',
                },
                {
                    fieldId: 'create_user',
                    filterValue: userStore.userInfo.sysUser.id,
                    operateType: '=',
                    parser: 'string',
                },
            ]);
            defaultMeta.setTableId('cu_activation_record');
            defaultMeta.setMetaSource(homePageMetaGetter);
            defaultMeta.setOpUsername(userStore.userInfo.userId + '-' + type);
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {});
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useEffect(() => {
        defaultStore.setListType(type);
        // create_user
    }, [type]);

    if (!preRequiredReady) {
        return <BlockLoading2 />;
    }

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = <></>;

    const title = <span>{pageTitle}&nbsp;</span>;
    const globalOps = <></>;
    const multipleOps = <></>;

    const dataTable = (
        <DemoTable
            defaultStore={defaultStore}
            loading={loading}
            reload={reload}
        />
    );

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters: null,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay,
    };

    return (
        <LayoutStandardCrud
            className="page-activation-record-list page-activation-record-list-home"
            {...layoutComs}
        />
    );
};

export const FinalActiveRecordListHome = observer(ActiveRecordListHome);
