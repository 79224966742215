import _ from 'lodash';
import { genDaysAgoFilterFactory } from './helpers';
import { AuthStore } from '@/stores/auth';
import moment from 'moment';

export const optDFConfs: { [entityCode: string]: any } = {
    opportunity: {
        lv4MenuFilters: {
            all: [
                {
                    key: 'opportunity-df-all',
                    label: '全部商机',
                    value: [],
                },
                async (options: { authStore: AuthStore }) => {
                    const { authStore } = options;
                    const ownerId = authStore?.userInfo?.sysUser?.id;
                    return {
                        key: 'opportunity-df-all-mine',
                        label: '我负责的商机',
                        value: [
                            ownerId
                                ? {
                                      condition: {
                                          fieldId: 'owner_id',
                                          filterValue: ownerId,
                                          operateType: '=',
                                      },
                                  }
                                : null,
                        ].filter(Boolean),
                    };
                },
            ],
            mine: [],
        },
        fastFilters: {
            all: [
                // {
                //     label: '线索转化商机',
                //     fastFilterKey: 'lead-transform',
                //     value: [
                //         {
                //             condition: {
                //                 fieldId: 'opportunity_source',
                //                 filterValue: 2,
                //                 operateType: '=',
                //             },
                //         },
                //     ],
                // },
                // async () => {
                //     return {
                //         label: '7天未跟进商机',
                //         fastFilterKey: '7_recent_activity_record_time',
                //         value: [
                //             {
                //                 condition: {
                //                     fieldId: 'recent_activity_record_time',
                //                     filterValue: moment()
                //                         .subtract(7, 'days')
                //                         .startOf('day')
                //                         .format('YYYY-MM-DD HH:mm:ss'),
                //                     operateType: '<=',
                //                 },
                //             },
                //         ],
                //     };
                // },
                // {
                //     label: '60%阶段7天无动态',
                //     fastFilterKey: 'xx',
                //     value: [
                //         {
                //             condition: {
                //                 fieldId: 'update_time',
                //                 filterValue: moment()
                //                     .subtract(7, 'days')
                //                     .startOf('day')
                //                     .format('YYYY-MM-DD HH:mm:ss'),
                //                 operateType: '>=',
                //             },
                //         },
                //         {
                //             condition: {
                //                 fieldId: 'recent_activity_record_time',
                //                 filterValue: moment()
                //                     .subtract(7, 'days')
                //                     .startOf('day')
                //                     .format('YYYY-MM-DD HH:mm:ss'),
                //                 operateType: '<=',
                //             },
                //         },
                //         {
                //             condition: {
                //                 fieldId: 'opportunity_sale_stage',
                //                 filterValue: [5, 11],
                //                 operateType: 'in',
                //             },
                //         },
                //     ],
                // },
                {
                    label: '7天未跟进',
                    fastFilterKey: 'opt_7_recent_activity_record_time',
                    value: [
                        {
                            condition: {
                                code: 'orA',
                                fieldId: 'customer_recent_activity_record_time',
                                filterValue: moment()
                                    .subtract(7, 'days')
                                    .startOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        {
                            condition: {
                                code: 'orB',
                                fieldId: 'customer_recent_activity_record_time',
                                filterValue: '',
                                operateType: 'null',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'win_rate',
                                filterValue: 20,
                                operateType: '>=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'win_rate',
                                filterValue: 80,
                                operateType: '<=',
                            },
                        },
                    ],
                },
                {
                    label: '超30天未更新赢率',
                    fastFilterKey: 'yy',
                    value: [
                        {
                            condition: {
                                fieldId: 'stage_update_time',
                                filterValue: moment()
                                    .subtract(30, 'days')
                                    .startOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'win_rate',
                                filterValue: 20,
                                operateType: '>=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'win_rate',
                                filterValue: 80,
                                operateType: '<=',
                            },
                        },
                    ],
                },
            ],
            mine: [],
        },
    },
};
