import React, { useEffect, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Alert, Button, Spin, Icon } from 'antd';
import { LazyDisplay } from '@/components/common/lazy-display';
import { observer } from 'mobx-react-lite';
import {
    DEFAULT_REQUIREG_LOGIN,
    DEFAULT_REQUIREG_USERDATA,
    DEFAULT_AUTOLOGIN,
} from '@/configs/auth';

import { StoreContext } from '@/stores';
import { P403 } from '@/pages/status';

export interface IAuthHOCOptions {
    requiredLogin?: boolean;
    requiredUserdata?: boolean;
    autoLogin?: boolean;
    authKey?: string;
}

// TODO: 默认值放到常量里面
// TODO: 可以有两种模式，静默登录，主动触发登录

const AuthWrapperC: React.FC<IAuthHOCOptions> = ({
    requiredLogin = true,
    requiredUserdata = true,
    autoLogin = true,
    children,
}) => {
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();

    const { isLoginLoading, isAuthLoading, isLogin } = authStore;
    /**
     * TODO:需要完善鉴权，鉴权与获取用户信息串行，在userStore里进行串行的权限验证
     */
    useEffect(() => {
        if (requiredLogin) {
            // 如果需求登录
            if (!authStore.isLogin) {
                authStore.getUserInfo(autoLogin);
            }
        }
    }, []);
    // 替换loading页面与403页面
    const antIcon = <Icon type="loading" spin />;
    return (
        <>
            {requiredLogin ? (
                isLoginLoading ? (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Spin
                            size="large"
                            indicator={antIcon}
                            style={{ margin: '0 auto' }}
                        />
                    </div>
                ) : isLogin ? (
                    children
                ) : (
                    <P403 />
                )
            ) : (
                children
            )}
        </>
    );
};

export const AuthWrapper = observer(AuthWrapperC);

export const authDeco: (
    options?: IAuthHOCOptions,
) => <T>(Com: React.FC<T>) => React.FC<T> /** 类型结束 */ = (
    options: IAuthHOCOptions = {},
) => Com => props => {
    const requiredUserdata =
        options.requiredUserdata || DEFAULT_REQUIREG_USERDATA;

    // 如果需要用户信息，一定也需要登录
    const requiredLogin = requiredUserdata
        ? true
        : options.requiredLogin || DEFAULT_REQUIREG_LOGIN;
    const autoLogin = options.autoLogin || DEFAULT_AUTOLOGIN;

    if (requiredLogin || requiredUserdata) {
        return (
            <AuthWrapper
                {...{
                    requiredUserdata,
                    requiredLogin,
                    autoLogin,
                }}
            >
                <Com {...props} />
            </AuthWrapper>
        );
    }

    return <Com {...props} />;
};

export const defaultAuthDeco = authDeco({
    requiredLogin: true,
    requiredUserdata: true,
    autoLogin: true,
});
