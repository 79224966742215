import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Result } from 'antd';

const P500: React.FC<RouteComponentProps> = ({ location }) => {
    return (
        <Result
            status="500"
            title="500"
            subTitle="服务器飞到火星去啦，请稍等片刻或联系管理员～"
        />
    );
};

export { P500 };
