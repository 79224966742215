import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import './index.scss';
import { Select } from 'antd';
import { defaultAxios, isNetSuccess, delay } from '@/utils';
import _ from 'lodash';
import { loadingWrapper } from '@/components';
import { WaSettingBody } from './wa-setting-body';

const WorkflowActivity: React.FC<RouteComponentProps> = () => {
    const [wfList, setWfList] = useState<any[] | null>(null);
    const [wfLoading, setWfLoading] = useState(false);
    const [wfErrored, setWfErrored] = useState(false);
    const [wfSelected, setWfSelected] = useState<string | null>(null);
    useEffect(() => {
        (async () => {
            setWfLoading(true);

            const [d, e] = await defaultAxios.get(
                '/crm/workflow/processdef/list',
            );
            // await delay(1000);
            // const d: any = {
            //     code: '0',
            //     msg: '成功',
            //     data: [
            //         {
            //             id:
            //                 'Process_d3ZN2HV5k:1:30a98cea-8adf-11eb-8638-7e252d9689ba',
            //             key: 'myAccount',
            //             name: '报销流程',
            //             description: '报销流程',
            //         },
            //         {
            //             id:
            //                 'Process_d3ZN2HV5k:1:30a98cea-8adf-11eb-8638-7e252d9689ba-xxxxxxxxxxx',
            //             key: 'myAccount2',
            //             name: '报销流程2',
            //             description: '报销流程2',
            //         },
            //     ],
            // };
            // const e = null;

            if (d === null && e === null) {
                return;
            }
            setWfLoading(false);
            if (!isNetSuccess(d, e) || !_.isArray(d?.data)) {
                setWfErrored(true);
                return;
            }
            setWfErrored(false);
            setWfList(d.data);
        })();
    }, []);

    const [activityList, setActivityList] = useState<any[] | null>(null);
    const [activityLoading, setActivityLoading] = useState(false);
    const [activityErrored, setActivityErrored] = useState(false);
    const [activitySelected, setActivitySelected] = useState<string | null>(
        null,
    );
    useEffect(() => {
        setActivityList(null);
        setActivitySelected(null);

        if (!wfSelected) {
            return;
        }
        // 加载流程的活动列表
        (async () => {
            setActivityLoading(true);

            const [d, e] = await defaultAxios.get(
                '/crm/workflow/processdef/activities?processDefinitionId=' +
                    wfSelected,
            );
            // await delay(1000);
            // const d: any = {
            //     code: '0',
            //     msg: '成功',
            //     data: [
            //         {
            //             processDefinitionId:
            //                 'myholiday:1:ce6785e5-863d-11eb-82b2-7e252d9689ba-xxxxxxx',
            //             activityId: 'UserTask_0eyg4la2',
            //             activityName: '测试审批2',
            //             activityType: 'userTask2',
            //         },
            //         {
            //             processDefinitionId:
            //                 'myholiday:1:ce6785e5-863d-11eb-82b2-7e252d9689ba',
            //             activityId: 'UserTask_0eyg4la',
            //             activityName: '测试审批',
            //             activityType: 'userTask',
            //         },
            //     ],
            // };
            // const e = null;

            setActivityLoading(false);
            if (d === null && e === null) {
                return;
            }
            if (!isNetSuccess(d, e) || !_.isArray(d?.data)) {
                setActivityErrored(true);
                return;
            }
            setActivityErrored(false);
            setActivityList(d.data);
        })();
    }, [wfSelected]);

    return (
        <div className="pa-content workflow-activity">
            <div className="pa-title">
                活动环节配置
                <span className="pa-title-description">
                    用来控制用户审批中的数据操作权限和按钮权限
                </span>
            </div>
            <div className="pa-body">
                <div className="pa-menu-content">
                    <div className="wa-menu-title">流程列表</div>
                    {loadingWrapper(wfLoading)(
                        <div className="wa-menu">
                            <div className="wa-menu-list">
                                {wfList?.map((item: any) => {
                                    const {
                                        id,
                                        key,
                                        name,
                                        // description,
                                    } = item;
                                    if (!id || !key || !name) {
                                        return null;
                                    }

                                    const clickHandler = () => {
                                        setWfSelected(id);
                                        setActivitySelected(null);
                                    };
                                    const isActive = wfSelected === id;
                                    return (
                                        <div
                                            onClick={clickHandler}
                                            className={
                                                'wa-menu-list-item ' +
                                                (isActive ? 'active' : '')
                                            }
                                        >
                                            {name}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>,
                    )}
                </div>
                <div className="pa-table-content">
                    <div className="wa-content-title">
                        <span>当前活动</span>&nbsp;&nbsp;
                        {loadingWrapper(activityLoading)(
                            <Select
                                onChange={nextActivityId => {
                                    setActivitySelected(
                                        nextActivityId as string,
                                    );
                                }}
                                loading={activityLoading}
                                style={{ width: 200 }}
                                placeholder={'请选择活动'}
                            >
                                {activityList?.map(item => {
                                    const {
                                        processDefinitionId,
                                        activityId,
                                        activityName,
                                        activityType,
                                    } = item;
                                    if (
                                        !processDefinitionId ||
                                        !activityId ||
                                        !activityName
                                    ) {
                                        return;
                                    }
                                    return (
                                        <Select.Option value={activityId}>
                                            {activityName}
                                        </Select.Option>
                                    );
                                })}
                            </Select>,
                        )}
                    </div>
                    <div className="wa-content-content">
                        <WaSettingBody
                            processDefinitionId={wfSelected}
                            activityId={activitySelected}
                            key={wfSelected + ':' + activitySelected}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkflowActivity;
