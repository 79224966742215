import React, { useState, useEffect } from 'react';
import { SorterStore } from '@/stores/utils';
import { AutoEllipsis } from '../auto-ellipsis';
import {
    Icon,
    Dropdown,
    Menu,
    Button,
    Modal,
    Input,
    message,
    Tooltip,
} from 'antd';
import './index.scss';
import { arrToJson } from '@/utils';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import _ from 'lodash';
import moment from 'moment';
import { AutoScrollable } from './auto-scrollable';
import { resizeLv1Table } from '../table';
import { defaultAxios } from '@/utils';

const extractTitleDesc = (
    objectMeta: any[],
    filterData: { [key: string]: any },
    sorterData: SorterStore<any>,
): Array<{
    fieldId: string;
    title: string;
    desc: React.ReactElement;
}> => {
    const ret: Array<{
        fieldId: string;
        title: string;
        desc: React.ReactElement;
    }> = [];
    const objectMetaMap = arrToJson(objectMeta, 'fieldName');
    const sorter = sorterData.sorter;
    if (sorter !== null) {
        const { columnKey, order } = sorter;
        const keyCn = objectMetaMap[columnKey]?.fieldComment;
        const orderCn = order === 'descend' ? '降序' : '升序';
        if (keyCn && orderCn) {
            ret.push({
                fieldId: '____sorter',
                title: '排序',
                desc: (
                    <AutoEllipsis
                        placement="bottom"
                        style={{ lineHeight: 'unset' }}
                        text={orderCn + '-' + keyCn}
                    />
                ),
            });
        }
    }

    const condiList = serializeFilterData3(filterData, { objectMetaMap });

    if (!condiList.filters) {
        return ret;
    }
    const filtersMap = arrToJson(condiList.filters, 'fieldId');
    const groupedFilters = _.groupBy(condiList.filters, 'fieldId');
    const keys = _.keys(groupedFilters).sort((a, b) => {
        const aTs = filtersMap[a]?.extra?.ts || 0;
        const bTs = filtersMap[b]?.extra?.ts || 0;
        return -aTs + bTs;
    });

    // 排序，用一个时间戳来排序
    for (const fieldId of keys) {
        const filters = groupedFilters[fieldId];
        const metaItem = objectMetaMap[fieldId];
        if (!metaItem) {
            continue;
        }
        const title = metaItem.fieldComment;
        if (!title) {
            continue;
        }

        const descTexts: string[] = [];

        for (const filter of filters) {
            const { operateType, filterValue, widgetKey, extra = {} } = filter;
            if (filterValue !== null) {
                if (widgetKey === 'input') {
                    descTexts.push(filterValue);
                } else if (widgetKey === 'filterEnum') {
                    const enumMap = arrToJson(
                        metaItem?.constraint?.constraintItemList || [],
                        'constraintValue',
                    );
                    descTexts.push(
                        filterValue
                            .split(',')
                            .map(
                                (constraintValue: any) =>
                                    enumMap[constraintValue]?.constraintLabel,
                            )
                            .filter(Boolean),
                    );
                } else if (
                    widgetKey === 'filterNumberRange' ||
                    widgetKey === 'filterDateRange'
                ) {
                    const isNumber = widgetKey === 'filterNumberRange';
                    const rangeString = isNumber
                        ? filterValue
                        : moment(filterValue).format('YYYY-MM-DD HH:mm:ss');
                    const prevDateString = descTexts[0];
                    if (prevDateString) {
                        const coms = prevDateString.split('~');
                        if (operateType === '>=') {
                            descTexts[0] = `${rangeString}~${coms[1]}`;
                        } else if (operateType === '<=') {
                            descTexts[0] = `${coms[0]}~${rangeString}`;
                        }
                    } else {
                        if (operateType === '>=') {
                            descTexts.push(
                                `${rangeString}~${isNumber ? '+∞' : '更晚'}`,
                            );
                        } else if (operateType === '<=') {
                            descTexts.push(
                                `${isNumber ? '-∞' : '更早'}~${rangeString}`,
                            );
                        }
                    }
                } else if (widgetKey === 'object') {
                    if (_.isEmpty(extra)) {
                        continue;
                    }
                    const objectsMap = arrToJson(
                        extra?.value || [],
                        'originalValue',
                    );
                    const objShowField = metaItem?.constraint?.objShowField;
                    if (!objShowField) {
                        continue;
                    }
                    descTexts.push(
                        filterValue
                            .split(',')
                            .map((originalValue: any) => {
                                const obj =
                                    objectsMap[originalValue]?.relatedObj;
                                return obj ? obj[objShowField.fieldName] : null;
                            })
                            .filter(Boolean),
                    );
                } else if (widgetKey === 'sysUserSelector') {
                    if (_.isEmpty(extra) || !extra?.value) {
                        continue;
                    }
                    const showStr = _.map(extra?.value, val => {
                        return val.title;
                    }).join(',');
                    descTexts.push(showStr);
                }
            } else {
                const { isEmpty, isNotEmpty } = extra;
                if (isNotEmpty) {
                    descTexts.push('<非空>');
                } else {
                    descTexts.push('<空>');
                }
            }
        }

        if (descTexts.length) {
            const desc = (
                <AutoEllipsis
                    placement="bottom"
                    style={{ lineHeight: 'unset' }}
                    text={descTexts.join(', ')}
                />
            );
            ret.push({
                fieldId,
                title,
                desc,
            });
        }
    }

    return ret;
};
export interface FastFilterType {
    fastFilterKey: string;
    label: string;
    value: [
        {
            condition: {
                fieldId: string;
                filterValue: string;
                operateType: string;
            };
        },
    ];
}

export const FiltersDisplay: React.FC<{
    objectMeta: any[];
    filterData: { [key: string]: any };
    sorterData: SorterStore<any>;
    selectedFastFilters?: any[];
    className?: string;
    onClose: (key: string) => void;
    onConfirm?: (list: any, fastFilters: [FastFilterType]) => void;
    ref?: any;
    defaultFilter?: any;
    isShowCollection?: any;
}> = ({
    objectMeta,
    filterData,
    sorterData,
    className,
    selectedFastFilters,
    onClose = () => void 0,
    onConfirm = () => void 0,
    defaultFilter,
    isShowCollection,
}) => {
    const [prevFilterData, setPrevFilterData] = useState(filterData);
    const [list, setList] = useState<any[]>([]);
    const [conditionalList, setConditionalList] = useState<any[]>([]);

    const [currentConditional, setCurrentConditional] = useState<any>({});
    const [nowFastFilters, setNowFastFilters] = useState<any[]>([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [conditionalName, setConditionalName] = useState('');

    useEffect(() => {
        if (prevFilterData === filterData) {
            return;
        }
        setPrevFilterData(filterData);
    }, [filterData]);

    useEffect(() => {
        const nextlist = extractTitleDesc(objectMeta, filterData, sorterData);
        setList(nextlist);
        resizeLv1Table();
    }, [filterData, sorterData.sorter]);

    useEffect(() => {
        setCurrentConditional({});
    }, [filterData]);

    useEffect(() => {
        getConditional();
    }, []);

    useEffect(() => {
        const isEqual = _.isEqual(selectedFastFilters, nowFastFilters);
        if (!isEqual) {
            setCurrentConditional({});
        }
    }, [selectedFastFilters]);

    const pathname = window.location.pathname.split('/');
    const page = pathname[pathname.length - 1];

    const getConditional = async () => {
        const url = '/bff/api/rest/customer/search-conditional';
        const [d, e] = await defaultAxios.get(url, {
            page,
        });
        const { data = [] } = d;
        setConditionalList(data);
        return data;
    };

    const handleCollect = async () => {
        if (conditionalName === '') {
            message.error('请输入方案名称');
            return;
        }
        const objectMetaMap = arrToJson(objectMeta, 'fieldName');
        const condiList = serializeFilterData3(filterData, { objectMetaMap });
        const { filters = [] } = condiList;

        const filterMap: {
            [fieldId: string]: any;
        } = {};

        // 获取过滤条件
        filters?.forEach((item: { fieldId: string }) => {
            const fieldId = item.fieldId;
            const filterItem = filterData[fieldId];
            filterMap[fieldId] = filterItem;
        });
        const fastFilters = selectedFastFilters || [];
        const conditional = JSON.stringify({ filterMap, fastFilters });
        const url = '/bff/api/rest/customer/add-conditional';
        await defaultAxios.post(url, {
            page,
            conditionalName,
            conditional,
        });

        const contionalList = await getConditional();
        const [contionalItem] = contionalList;
        setIsModalOpen(false);
        setConditionalName('');
        setCurrentConditional({
            children: contionalItem.conditionalName,
            eventKey: contionalItem.id,
        });
        message.success('添加成功');
    };

    const handleCancelCollction = async (id?: number) => {
        const url3 = `/bff/api/rest/customer/delete-conditional`;
        await defaultAxios.post(url3, {
            id: id || currentConditional.eventKey,
        });
        setCurrentConditional({});
        getConditional();
        message.success('取消收藏成功');
    };

    const timeFilterList = [
        'create_time',
        'claim_time',
        'expire_time',
        'next_follow_time',
        'high_potential_create_time',
        'liepin_update_import_time',
        'update_time',
        'recycle_alert_date',
        'visit_latest_time',
        'back_high_sea_time',
        'lock_expire_time',
        'recent_activity_record_time',
        'recent_lead_time',
        'talent_bank_nearest_expire_time',
        'talent_bank_latest_expire_time',
        'enterprise_expire_time',
    ];

    const handleSelectCollection = ({ item }: any) => {
        const { props = {} } = item;
        const { eventKey = '' } = props;
        const conditionalItem = _.find(conditionalList, {
            id: Number(eventKey),
        });
        defaultFilter.resetFilterData();
        const conditional = JSON.parse(conditionalItem.conditional);
        const { filterMap, fastFilters } = conditional;
        const cloneFilterData = _.clone(defaultFilter.filterData);

        for (let key in filterMap) {
            const filterItem = filterMap[key];
            // 时间范围需要动态处理
            if (timeFilterList.includes(key)) {
                const [start, end] = filterItem.value;
                const diff = moment(end).diff(moment(start), 'days');
                const beforeTime = moment()
                    .startOf('day')
                    .subtract(diff, 'days')
                    .valueOf();
                const nowTime = moment()
                    .endOf('day')
                    .valueOf();
                filterItem.value = [beforeTime, nowTime];
            }

            cloneFilterData[key] = {
                ...cloneFilterData[key],
                ...filterItem,
            };
        }
        const nextlist = extractTitleDesc(
            objectMeta,
            cloneFilterData,
            sorterData,
        );

        setList(nextlist);
        onConfirm(filterMap, fastFilters);
        setNowFastFilters(fastFilters);
        setCurrentConditional(props);
    };

    const isMaxConditionalCount = conditionalList.length >= 5;
    const isCollected = currentConditional?.children;

    const Collection = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Dropdown
                    overlay={
                        <Menu>
                            {conditionalList.map(item => (
                                <Menu.Item
                                    onClick={item => {
                                        handleSelectCollection(item);
                                    }}
                                    key={item.id}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div>{item.conditionalName}</div>
                                        <Icon
                                            type="close-circle"
                                            style={{
                                                color: 'red',
                                                marginLeft: '5px',
                                            }}
                                            onClick={e => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleCancelCollction(item.id);
                                            }}
                                        />
                                    </div>
                                </Menu.Item>
                            ))}
                        </Menu>
                    }
                >
                    <Button>
                        <div>
                            {currentConditional.children || (
                                <div>
                                    <Tooltip
                                        placement="top"
                                        title={
                                            '方案上限为5组，每组最多收藏10项条件'
                                        }
                                    >
                                        <Icon
                                            type="exclamation-circle"
                                            style={{ color: '#4482f7' }}
                                        />
                                    </Tooltip>
                                    <span style={{ marginLeft: '5px' }}>
                                        {`我的方案 (${conditionalList.length}组)`}
                                    </span>
                                </div>
                            )}
                        </div>
                    </Button>
                </Dropdown>
                <div style={{ marginRight: '10px', marginLeft: '20px' }}>
                    {isCollected ? (
                        <Tooltip placement="top" title={'取消收藏'}>
                            <Icon
                                type="star"
                                theme="filled"
                                style={{
                                    color: '#f1d247',
                                    marginRight: '20px',
                                }}
                                onClick={() => {
                                    handleCancelCollction();
                                }}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip placement="top" title={'收藏'}>
                            {isMaxConditionalCount ? (
                                <Tooltip placement="top" title={'方案已满'}>
                                    <Icon type="star" />
                                </Tooltip>
                            ) : (
                                <Tooltip placement="top" title={'收藏'}>
                                    <Icon
                                        type="star"
                                        style={{ color: '#f1d247' }}
                                        onClick={() => {
                                            setIsModalOpen(true);
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    };

    return (
        <AutoScrollable className={className}>
            {isShowCollection && <Collection />}
            {list.map(item => {
                return (
                    <div
                        onClick={() => {
                            onClose(item.fieldId);
                        }}
                        className="item-selected-filter"
                    >
                        <span className="title">{item.title}：</span>
                        <span className="desc">{item.desc}</span>
                        <span className="close">
                            <Icon type="close" />
                        </span>
                    </div>
                );
            })}
            <Modal
                title="已收藏当前方案"
                visible={isModalOpen}
                onOk={() => {
                    handleCollect();
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                    setConditionalName('');
                }}
            >
                <Input
                    placeholder="请输入方案名称"
                    value={conditionalName}
                    onChange={e => {
                        setConditionalName(e.target.value);
                    }}
                    maxLength={10}
                />
            </Modal>
        </AutoScrollable>
    );
};
