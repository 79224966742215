import { createDefaultInstanceGetterA } from '@/stores/utilsA';
import { makeAutoObservable } from 'mobx';
import { Moment } from 'moment';
import { defaultAxios } from '@/utils';
import _ from 'lodash';
import moment from 'moment';

export interface IHomeNewFilterData {
    targetLevel: number;
    range: number;
}
export const homeTargetLevelOptions = [
    {
        label: 'E',
        value: 3,
    },
    {
        label: 'M+',
        value: 2,
    },
    {
        label: 'M',
        value: 1,
    },
];

export const homeRangeOptions = [
    {
        label: '本年',
        value: 1,
    },
    {
        label: '本季',
        value: 2,
    },
    {
        label: '本月',
        value: 3,
    },
    {
        label: '本双月',
        value: 4,
    },
];

export interface IOrgTreeDto {
    dimPart: number; // 部门id
    name: string; // 部门名
    canView: boolean; // 是否可以展示(选择)
    selected: boolean; // 默认选择
    orgType: number;
    childOrgList: IOrgTreeDto[] | null;
    userInfoList:
        | {
              userId: number;
              userName: string;
              canView: boolean;
              selected: boolean;
          }[]
        | null;
}
export interface IHomePreData {
    indexType?: 1 | 2; // 1旧版 2新版
    indexViewOrgTreeDto?: IOrgTreeDto;
}
export interface IHomeOptAnalysisCompositeData {
    opportunity_amount?: number; // 商机20~80金额合计
    opportunity_amount20?: number; // 20商机金额
    opportunity_amount20_rate?: number; // 20商机占比
    opportunity_amount40?: number; // 40商机金额
    opportunity_amount40_rate?: number; // 40商机占比
    opportunity_amount60?: number; // 60商机金额
    opportunity_amount60_rate?: number; // 60商机占比
    opportunity_amount80?: number; // 80商机金额
    opportunity_amount80_rate?: number; // 80商机占比
    opportunity_amount_60_80_90?: number; // 608090商机金额
    opportunity_amount90_rate?: number; // 90商机占比

    opportunity_40_week_increase?: number; // 本周40商机新增
    opportunity_20_week_increase?: number; // 本周20商机新增
    income_gap?: number; // 目标gap
    opportunity_gap?: number; // 商机GAP（缺口）
    opportunity_amount_rate?: number; // 商机6080/GAP
    opportunity_gap_rate?: number; // 商机缺口占比
    userInfoList?: {
        userId: number;
        userName: string;
    }[];
    [key: string]: any;
}
export interface IHomeAchievementSituationData {
    update_time?: string | number;
    income_money?: number; // 回款
    income_target?: number; // 目标
    income_gap?: number; // 差距
    day_pass?: number; // 过去时间
    day_total?: number; // 总共时间
    day_remain?: number; // 剩余时间
    speed_day?: number; // 时间进度
    speed_gap?: number; // 进度gap(对比)
    speed_income?: number; // 目标完成率
    completion_rate?: number; // 目标完成率
    income_today?: number; // 今日回款
    opportunity_amount?: number; // 商机金额合计
    opportunity_amount20?: number; // 20商机金额
    opportunity_amount40?: number; // 40商机金额
    opportunity_amount60?: number; // 60商机金额
    opportunity_amount80?: number; // 80商机金额
    opportunity_amount6080?: number; // 6080商机金额
    opportunity_gap?: number; // 商机GAP
    opportunity_amount_rate?: number; // 商机/GAP
    [key: string]: any;
}

export interface IHomeSalesAreaData {
    areaRankList?: {
        achievementFinishRate: number;
        orgId: number;
        incomeMoney: number;
        orgName: string;
        incomeTarget: number;
    }[];
    cityRankList?: {
        achievementFinishRate: number;
        orgId: number;
        incomeMoney: number;
        orgName: string;
        incomeTarget: number;
    }[];
}

export interface IHomePerformanceOptData {
    // 销售版
    toDayVisit?: number;
    toDayCall?: number;
    toDayCallValid?: number;
    toDayCallTotalTime?: number;
    toDayCustomerClaim?: number;
    toDayCustomerCreate?: number;
    toDayOpportunityCreate?: number;

    // 城市/全国版
    toWeekNewSignTaskOnTimeFinishRate?: number;
    toWeekNewSignTaskOnTimeFinishRateCompared?: number; // 新签

    toMonthNewSignTaskOnTimeFinishRate?: number;
    toMonthNewSignTaskOnTimeFinishRateCompared?: number;

    toMonthNewTrainTaskOnTimeFinishRateCompared?: number; // 新开
    toMonthNewTrainTaskOnTimeFinishRate?: number;

    toWeekNewTrainTaskOnTimeFinishRate?: number;
    toWeekNewTrainTaskOnTimeFinishRateCompared?: number;

    toMonthRenew321TaskOnTimeFinishRate?: number; // 续约
    toMonthRenew321TaskOnTimeFinishRateCompared?: number;

    toWeekRenew321TaskOnTimeFinishRate?: number;
    toWeekRenew321TaskOnTimeFinishRateCompared?: number;

    cooperateCustomerMonthOverrideRate?: number; // 321
    cooperateCustomerMonthOverrideRateCompared?: number;
    userInfoList?: {
        userId: number;
        userName: string;
    }[];
    [key: string]: any;
}

export interface IHomePerformanceSalesRankData {
    cityRankUserList?: {
        groupName: string;
        userName: string;
        userId: string | number;
        achievementFinishRate: number;
        avatar: string;
    }[];
    countryRankUserList?: {
        groupName: string;
        userName: string;
        userId: string | number;
        achievementFinishRate: number;
        avatar: string;
    }[];
}

export interface IHomePerformanceCityRankData {
    achievementFinishRate: number;
    orgId: number;
    incomeMoney: number;
    orgName: string;
    incomeTarget: number;
}
export interface IHomePerformanceGroupRankData {
    achievementFinishRate: number;
    orgId: number;
    incomeMoney: number;
    orgName: string;
    incomeTarget: number;
}
export interface IHomeProcessCountInfo {
    all?: number;
    finished?: number;
    unFinished?: number;
    publishFinished?: number;
    publishWait?: number;
    publishUnfinished?: number;
    publishReject?: number;
}
export interface IHomeCustomerCountInfo {
    todayWaitRecycle?: number;
    day7WaitRecycle?: number;
    privateHighSeaHighPotential?: number;
    publicHighSeaHighPotential?: number;
    cooperateCustomer?: number;
}
export interface IHomeTaskCountInfo {
    todayExpire?: number; // 今日到期
    toWeekExpire?: number; // 本周到期
    running?: number; // 进行中
    overdue?: number; // 已逾期
}
export interface IHomeFrontSalesErrorInfo {
    more7dayNotFollowCustomer?: number;
    more15dayNotFollowCustomer?: number;
    more30dayNotFollowCustomer?: number;
    notFollowLead?: number;
    more30dayNotFollowOpportunity?: number;
    opportunityTobeConfirmed?: number;
}
export interface IHomeBehindSalesErrorInfo {
    activeHalfRecruitmentCount?: number;
    openNotPayBack?: number;
    payBackNotOpen?: number;
    openInvoice?: number;
    more30dayNotFollowCustomer?: number;
    less30talentBankExpireCustomer?: number;
    less30enterpriseBankExpireCustomer?: number;
    less90talentBankExpireCustomer?: number;
    less90enterpriseBankExpireCustomer?: number;
    publishedPosition?: number;
}
export interface IHomeRightInfo {
    taskJumpInfo?: IHomeTaskCountInfo;
    customerJumpInfo?: IHomeCustomerCountInfo;
    beforeSaleAbnormalJumpInfo?: IHomeFrontSalesErrorInfo;
    postSaleAbnormalJumpInfo?: IHomeBehindSalesErrorInfo;
    userInfoList?: {
        userId: number;
        userName: string;
    }[];
}
export const entityCodeToUrl: any = {
    customer: '/buy/custom/all-custom',
    private_customer: '/buy/custom/my-custom',
    to_release_customer: '/buy/custom/to-release',
    contacts: '/buy/contact/all',
    lead: '/buy/lead/all-lead',
    leadmine: '/buy/lead/my-lead',
    opportunity: '/buy/opportunity/all',
    contract: '/buy/contract/all',
    process: '/buy/process-management/all',
    job: '/buy/job-management/cus-follow',
    activation_record: '/buy/activation-record/all',
};
export class HomeNewStore {
    public homePerData: IHomePreData = {};
    public setHomePreData = (d: IHomePreData) => {
        this.homePerData = d;
    };

    public showHomeNewPage: boolean = false;
    public showHomeAuthLoading: boolean = true;
    public homeNewSelectedData: {
        selectedLevel: number; // 0是国家，3是区域，2是城市，1是小组，4是个人
        selectedId?: string | number;
        selectedText?: string;
        selectedRankName?: string; // 只有是销售的时候存在是什么职级
    } = {
        selectedLevel: 0,
    };
    public get homePreDataCanViewMap() {
        const finalMap: any = {
            0: [], // 国
            1: [], // 小组
            2: [], // 城市
            3: [], // 区域
            4: [], // 个人
        };
        const diffTree = (oData: IOrgTreeDto[]) => {
            _.forEach(oData, item => {
                if (item.canView) {
                    finalMap[item.orgType].push(item);
                }
                if (item.childOrgList && item.childOrgList.length) {
                    diffTree(item.childOrgList);
                }
                if (item.userInfoList && item.userInfoList.length) {
                    _.forEach(item.userInfoList, item => {
                        if (item.canView) {
                            finalMap[4].push(item);
                        }
                    });
                }
            });
        };
        if (this.homePerData.indexViewOrgTreeDto?.canView) {
            finalMap[0].push(this.homePerData.indexViewOrgTreeDto);
        }
        if (this.homePerData.indexViewOrgTreeDto?.childOrgList) {
            diffTree(this.homePerData.indexViewOrgTreeDto?.childOrgList);
        }
        return {
            ...finalMap,
        };
    }
    public setHomeNewSelectedData = (selectedData: {
        selectedLevel: number; // 0是国家，1是小组，2是城市，3是地域，4是个人
        selectedId?: string | number;
        selectedText?: string;
        selectedRankName?: string;
    }) => {
        this.homeNewSelectedData = selectedData;
    };

    public getHomeNewAuth = async () => {
        this.showHomeAuthLoading = true;
        const url = '/crm/index/preData';
        const [d, e] = await defaultAxios.get(url);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            this.homePerData = data || [];
            this.showHomeNewPage = data.indexType === 2;
            // setNormalSettingData({
            //     type: data?.type || 1,
            //     generalMaxLimit: data?.generalMaxLimit || '',
            //     includeSelfBuild: data?.includeSelfBuild || '',
            //     includeDeal: data?.includeDeal || '',
            // });
        }
        this.showHomeAuthLoading = false;
        return [data, error];
    };
    public homeNewFilterData: IHomeNewFilterData = {
        targetLevel: 2,
        range: 4,
    };
    public setHomeNewFilterData = (fData: IHomeNewFilterData) => {
        this.homeNewFilterData = fData;
    };
    public homeNewDataLoading: boolean = false;
    public homeNewData: IHomeAchievementSituationData = {};

    public homeNewDataRefreshTime: Moment | null = null;
    public setHomeNewData = (d: IHomeAchievementSituationData) => {
        this.homeNewData = d;
    };
    public getHomeNewData = async () => {
        this.homeNewDataLoading = true;
        const url = '/crm/index/incomeTarget';
        const [d, e] = await defaultAxios.post(url, {
            targetLevel: this.homeNewFilterData.targetLevel,
            timeType: this.homeNewFilterData.range,
            userId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? this.homeNewSelectedData.selectedId
                    : '',
            orgId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? ''
                    : this.homeNewSelectedData.selectedId,
            orgType:
                this.homeNewSelectedData.selectedLevel === 4
                    ? ''
                    : this.homeNewSelectedData.selectedLevel,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            this.homeNewData = data || [];
            this.homeNewDataRefreshTime = moment();
        }
        this.homeNewDataLoading = false;
        return [data, error];
    };

    public homeNewAnaOptData: IHomeOptAnalysisCompositeData = {};
    public setHomeNewAnaOptData = (d: IHomeOptAnalysisCompositeData) => {
        this.homeNewAnaOptData = d;
    };
    public homeNewAnaOptDataLoading: boolean = false;
    public getHomeNewAnaOptData = async () => {
        this.homeNewAnaOptDataLoading = true;
        const url = '/crm/index/opportunityAmountInfo';
        const [d, e] = await defaultAxios.post(url, {
            timeType: this.homeNewFilterData.range,
            userId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? this.homeNewSelectedData.selectedId
                    : '',
            orgId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? ''
                    : this.homeNewSelectedData.selectedId,
            orgType:
                this.homeNewSelectedData.selectedLevel === 4
                    ? ''
                    : this.homeNewSelectedData.selectedLevel,
            targetLevel: this.homeNewFilterData.targetLevel,
        });
        this.homeNewAnaOptDataLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            this.homeNewAnaOptData = data || [];
        }
        return [data, error];
    };

    public homeNewSaleAreaData: IHomeSalesAreaData = {};
    public setHomeNewSaleAreaData = (d: IHomeSalesAreaData) => {
        this.homeNewSaleAreaData = d;
    };
    public homeNewSaleLoading: boolean = false;
    public getHomeSalesAreaData = async () => {
        this.homeNewSaleLoading = true;
        const url = '/crm/index/achievementRank';
        const [d, e] = await defaultAxios.post(url, {
            timeType: this.homeNewFilterData.range,
            userId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? this.homeNewSelectedData.selectedId
                    : '',
            orgId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? ''
                    : this.homeNewSelectedData.selectedId,
        });
        this.homeNewSaleLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            this.homeNewSaleAreaData = data || [];
        }
        return [data, error];
    };

    public homeNewPerformanceOptData: IHomePerformanceOptData = {};
    public setHomeNewPerformanceOpt = (d: IHomePerformanceOptData) => {
        this.homeNewPerformanceOptData = d;
    };
    public homeNewPerformanceOptLoading: boolean = false;
    public getHomeNewPerformanceOptData = async () => {
        this.homeNewPerformanceOptLoading = true;
        const url = '/crm/index/currentTaskExecuteInfo';
        const [d, e] = await defaultAxios.post(url, {
            timeType: this.homeNewFilterData.range,
            userId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? this.homeNewSelectedData.selectedId
                    : '',
            orgId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? ''
                    : this.homeNewSelectedData.selectedId,
            orgType:
                this.homeNewSelectedData.selectedLevel === 4
                    ? ''
                    : this.homeNewSelectedData.selectedLevel,
            targetLevel: this.homeNewFilterData.targetLevel,
        });
        this.homeNewPerformanceOptLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            this.homeNewPerformanceOptData = data || [];
        }
        return [data, error];
    };

    public homeNewSalesRankData: IHomePerformanceSalesRankData = {};
    public setHomeNewSalesRankData = (d: IHomePerformanceSalesRankData) => {
        this.homeNewSalesRankData = d;
    };
    public homeNewSalesRankLoading: boolean = false;

    public getHomeNewSalesRankData = async () => {
        this.homeNewSalesRankLoading = true;
        const url = '/crm/index/achievementRank';
        const [d, e] = await defaultAxios.post(url, {
            timeType: this.homeNewFilterData.range,
            userId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? this.homeNewSelectedData.selectedId
                    : '',
            orgId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? ''
                    : this.homeNewSelectedData.selectedId,
        });
        this.homeNewSalesRankLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            this.homeNewSalesRankData = data || [];
        }
        return [data, error];
    };

    public homeNewCityRankData: IHomePerformanceCityRankData[] = [];
    public setHomeNewCityRankData = (d: IHomePerformanceCityRankData[]) => {
        this.homeNewCityRankData = d;
    };
    public homeNewCityRankLoading: boolean = false;
    public getHomeNewCityRankData = async () => {
        this.homeNewCityRankLoading = true;
        const url = '/crm/index/achievementRank';
        const [d, e] = await defaultAxios.post(url, {
            timeType: this.homeNewFilterData.range,
            userId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? this.homeNewSelectedData.selectedId
                    : '',
            orgId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? ''
                    : this.homeNewSelectedData.selectedId,
        });
        this.homeNewCityRankLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            this.homeNewCityRankData = data.cityRankList || [];
        }
        return [data, error];
    };

    public homeNewGroupRankData: IHomePerformanceGroupRankData[] = [];
    public setHomeNewGroupRankData = (d: IHomePerformanceGroupRankData[]) => {
        this.homeNewGroupRankData = d;
    };
    public homeNewGroupRankLoading: boolean = false;
    public getHomeNewGroupRankData = async () => {
        this.homeNewGroupRankLoading = true;
        const url = '/crm/index/achievementRank';
        const [d, e] = await defaultAxios.post(url, {
            timeType: this.homeNewFilterData.range,
            userId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? this.homeNewSelectedData.selectedId
                    : '',
            orgId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? ''
                    : this.homeNewSelectedData.selectedId,
        });
        this.homeNewGroupRankLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            this.homeNewGroupRankData = data.groupRankList || [];
        }
        return [data, error];
    };

    public homeNewProcessInfoData: IHomeProcessCountInfo = {};
    public setHomeNewProcessInfoData = (d: IHomeProcessCountInfo) => {
        this.homeNewProcessInfoData = d;
    };
    public homeNewProcessInfoLoading: boolean = false;
    public fetchHomePageProcessInfo = async (userId: number | string) => {
        this.homeNewProcessInfoLoading = true;
        const url = '/bff/api/rest/home/home-process-data';
        const [d, e] = await defaultAxios.get(url, {
            currentUser: userId,
        });
        this.homeNewProcessInfoLoading = false;
        if (e || d === null || !d.data) {
            return false;
        } else {
            this.homeNewProcessInfoData = d.data;
        }
    };

    public homeNewTaskInfoData: IHomeTaskCountInfo = {};
    public setHomeNewTaskInfoData = (d: IHomeTaskCountInfo) => {
        this.homeNewTaskInfoData = d;
    };
    public homeNewTaskInfoLoading: boolean = false;
    public fetchHomePageJobInfo = async () => {
        this.homeNewTaskInfoLoading = true;
        const url = '/bff/api/rest/job/home-job-statistic';
        const [d, e] = await defaultAxios.get(url);
        this.homeNewTaskInfoLoading = false;
        if (e || d === null || !d.data) {
            return false;
        } else {
            this.homeNewTaskInfoData = d.data;
        }
    };

    public homeNewCustomerInfoData: IHomeCustomerCountInfo = {};
    public setHomeNewCustomerInfoData = (d: IHomeCustomerCountInfo) => {
        this.homeNewCustomerInfoData = d;
    };
    public homeNewCustomerInfoLoading: boolean = false;
    public fetchHomePageCustomerInfo = async () => {
        this.homeNewCustomerInfoLoading = true;
        const url = '/bff/api/rest/home/home-new-customer-info';
        const [d, e] = await defaultAxios.get(url);
        this.homeNewCustomerInfoLoading = false;
        if (e || d === null || !d.data) {
            return false;
        }
        this.homeNewCustomerInfoData = d.data;
    };

    public homeNewRightInfoData: IHomeRightInfo = {};
    public setHomeNewRightInfoData = (d: IHomeRightInfo) => {
        this.homeNewRightInfoData = d;
    };
    public homeNewRightInfoLoading: boolean = false;
    public fetchHomePageRightInfo = async () => {
        this.homeNewRightInfoLoading = true;
        const url = '/crm/index/fastJumpInfo';
        const [d, e] = await defaultAxios.post(url, {
            userId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? this.homeNewSelectedData.selectedId
                    : '',
            orgId:
                this.homeNewSelectedData.selectedLevel === 4
                    ? ''
                    : this.homeNewSelectedData.selectedId,
            orgType:
                this.homeNewSelectedData.selectedLevel === 4
                    ? ''
                    : this.homeNewSelectedData.selectedLevel,
        });
        this.homeNewRightInfoLoading = false;
        if (e || d === null || !d.data) {
            return false;
        }
        this.homeNewRightInfoData = d.data;
    };

    public goToPage = (
        key:
            | 'customer'
            | 'contacts'
            | 'lead'
            | 'leadmine'
            | 'opportunity'
            | 'contract'
            | 'process',
    ) => {
        window.open(entityCodeToUrl[key], '_blank');
    };
    constructor() {
        makeAutoObservable(this);
    }
}
export const getDefaultHomeNewStore = createDefaultInstanceGetterA(
    HomeNewStore,
);
