import { makeAutoObservable, runInAction } from 'mobx';
import {
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    makeDefaultInitailingHook,
    FastFilterStore,
} from '@/stores/utils';
import {
    NexusGenFieldTypes,
    NexusGenInputs,
} from '@/definations/graphql/auto-gen';
import {
    TFetchCustomersReturnType,
    fetchCustomers,
    mutate,
} from '@/api/graphql';
import _ from 'lodash';
import {
    serializeFilterData2,
    serializeFilterData3,
} from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    getCustomers,
    TGetCustomersReturnType,
    xDelete,
    commonQuery,
    commonStarQuery,
    commonQueryOutflow,
    TGetCommonReturnType,
    getCommonQueryBody,
} from '@/api/rest';
import {
    defaultWritingDataParser,
    isAllValidateResultSuccess,
    defaultAxios,
    isZeroValue,
    delay,
    isNetSuccess,
    arrToJson,
} from '@/utils';
import EE from 'eventemitter3';
import { IMOpResult } from '@/components';
import $ from 'jquery';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';
import moment from 'moment';
import { makeDefaultInitailingHookB } from '@/stores/utilsA';
import { createDefaultInstanceGetterA } from '../../../../stores/utilsA';

function mergeCustomersToMopResult(customers: any[], mopResult: IMOpResult) {
    const cusMap = arrToJson(customers, 'id');
    const items = [
        ...(mopResult?.errorList || []),
        ...(mopResult?.successList || []),
        ...(mopResult?.warningList || []),
    ];
    items.forEach(item => {
        if (!item.description && item.id && cusMap[item.id]?.customer_name) {
            item.description = cusMap[item.id]?.customer_name;
        }
    });
    return mopResult;
}

type TAutoFilling = {
    [key: string]: {
        editable: boolean;
        value: any;
    };
};

const transformBackEndValidateStateToValidateResult = (
    data: any[],
): ValidateResult<any> => {
    const ret: ValidateResult<any> = {};
    for (const d of data) {
        const { errorMsg, errorType, fieldName } = d;
        if (!errorMsg || !errorType || !fieldName) {
            continue;
        }
        ret[fieldName] = {
            msg: errorMsg,
            status: errorType === 'error' ? 'fail' : 'warning',
        };
    }
    return ret;
};

export class CustomerListStore {
    // 数据
    public data: any[] = [];

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public get pageTitle() {
        return '客户列表';
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFastFilters = this.defaultFastFilters;
        const {
            selectedFastFilters,
            selectedLv4MenuFilters,
        } = defaultFastFilters;

        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );
        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        for (const filterSet of selectedFastFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        for (const filterSet of selectedLv4MenuFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }
        ret.filters?.push(...defaultFilters);

        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public updateMode: string = '';
    public setUpdateMode(nextMode: string) {
        this.updateMode = nextMode;
    }

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }

    public async setAction(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra) {
                if (extra.id) {
                    await this.fetchInstantlyMutatingDataById(extra.id);
                } else {
                    message.error('修改必须传id');
                }
            }
        }

        if (
            !!['m-transform-sea', 'm-transform', 'm-delete'].includes(
                nextAction,
            )
        ) {
            const { mSeletedData } = this.defaultMSelect;
            const isSingleBizType =
                _.uniqBy(mSeletedData, cus => cus.biz_type).length === 1;
            if (!isSingleBizType) {
                // 如果不是同一个bizType
                this.actionType = 'invalid-biztype';
                return;
            }
        }

        this.actionType = nextAction;
    }
    public async setActionAsync(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra) {
                if (extra.id) {
                    const ok = await this.fetchInstantlyMutatingDataById(
                        extra.id,
                    );
                    if (!ok) {
                        return false;
                    }
                } else {
                    message.error('修改必须传id');
                    return false;
                }
            }
        }
        this.actionType = nextAction;
        return true;
    }
    public resetAction() {
        this.netValidateResult = {};
        this.actionType = '';
        this.updateMode = '';
        this.autoFillingByCName = null;
        this.autoFillingLoading = false;
        this.suggestionCustomer = null;
    }
    public async dispatchAction() {
        if (this.actionType === 'create') {
            return this.create();
        } else if (this.actionType === 'update') {
            return this.update();
        }
    }

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;
    get mutatingDataParsed() {
        return defaultWritingDataParser(this.mutatingData);
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public mutateDataByKey(k: string, v: any) {
        const nextMutatingData = { ...this.mutatingData };
        nextMutatingData[k] = v;
        this.mutatingData = nextMutatingData;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        let initialFormData = this.defaultMeta.defaultWidgetValues;

        // // test
        // initialFormData = _.cloneDeep(initialFormData)
        // initialFormData.parent_customer_id = [ initialFormData.parent_customer_id ].filter((item) =>  Boolean(item) && Boolean(item.originalValue))

        this.mutatingData = _.cloneDeep(initialFormData);
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public autoFillingByCName: TAutoFilling | null = null;
    public autoFillingLoading: boolean = false;
    public suggestionCustomer: any = null;
    public async clearAutoFillParamsByCustomerName() {
        const data = this.mutatingDataParsed;
        if (this.autoFillingByCName === null) {
            return;
        }
        const keys = _.keys(this.autoFillingByCName);
        const nextData = this.defaultMeta.defaultResetData(data, keys);
        this.mutatingData = nextData;
    }
    public async clearParamValidateState(key: string) {
        if (!this.netValidateResult[key]) {
            return;
        }
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }
    public paramsToClearValidateState: string[] = [];
    public async clearParamsValidateState(keys: string[]) {
        const nextNetValidateResult = { ...this.netValidateResult };
        for (const key of keys) {
            delete nextNetValidateResult[key];
        }
        this.paramsToClearValidateState = keys;
        this.netValidateResult = nextNetValidateResult;
    }
    public async autoFillOtherParamsByCustomerName(
        customerName: string,
        bizType: any,
    ) {
        if (isZeroValue(customerName) || !bizType || this.autoFillingLoading) {
            return;
        }
        this.autoFillingLoading = true;
        const [d, e] = await defaultAxios.get(
            '/bff/api/rest/customer/checkCustomerName',
            {
                customerName,
                bizType,
            },
        );
        this.autoFillingLoading = false;
        if (e) {
            return;
        }

        const ret = d?.data;

        const {
            isEdit: isAllEditable,
            customer: mutatingDataSuggestion,
            qichachaCustomer,
            errorMsg,
        } = ret;
        const nextAutoFillingByCName: TAutoFilling = {};

        if (_.isString(errorMsg) && errorMsg !== '') {
            message.error(errorMsg);
            runInAction(() => {
                nextAutoFillingByCName.customer_name = {
                    editable: true,
                    value: this.mutatingData?.customer_name,
                };
                this.autoFillingByCName = nextAutoFillingByCName;
                this.suggestionCustomer = null;
            });
            return;
        }

        const keys = _.keys(mutatingDataSuggestion);
        for (const key of keys) {
            nextAutoFillingByCName[key] = {
                editable: isAllEditable,
                value: (mutatingDataSuggestion as any)[key],
            };
        }
        if (!nextAutoFillingByCName.customer_name) {
            nextAutoFillingByCName.customer_name = {
                editable: true,
                value: customerName,
            };
        }

        const nextMutatingData = _.assign(
            _.cloneDeep(this.mutatingData),
            mutatingDataSuggestion,
        );

        runInAction(() => {
            // 如果当前存在推荐，则清空之前的推荐
            if (this.autoFillingByCName) {
                this.clearAutoFillParamsByCustomerName();
            }
            this.autoFillingByCName = nextAutoFillingByCName;
            // TODO, 这样不太行
            this.clearParamsValidateState(_.keys(this.autoFillingByCName));
            this.mutatingData = nextMutatingData;
            // 推荐客户
            this.suggestionCustomer = qichachaCustomer;
        });
    }

    public useSuggestionCustomer() {
        if (this.suggestionCustomer && this.suggestionCustomer.customer_name) {
            const customerName = this.suggestionCustomer.customer_name;
            const bizType = this?.mutatingData?.biz_type;
            this.autoFillOtherParamsByCustomerName(customerName, bizType);
        }
    }

    public async refreshBusinessRegistration() {
        const mSeletedData = this.mutatingData
            ? [this.mutatingData]
            : this.defaultMSelect.mSeletedData;
        const [d, e] = await defaultAxios.get(
            '/crm/customer/flushQichachaInfo',
            {
                customerId: mSeletedData
                    .map(item => item.id)
                    .filter(Boolean)
                    .join(','),
            },
        );
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        message.destroy();
        if (d.data) {
            message.success('刷新成功');
            return true;
        } else {
            message.error('刷新失败');
            return false;
        }
    }

    public async refreshBusinessRegistrationQichacha() {
        const mSeletedData = this.mutatingData
            ? [this.mutatingData]
            : this.defaultMSelect.mSeletedData;
        const [d, e] = await defaultAxios.get(
            '/crm/customer/flushQichachaInfoNotPN',
            {
                customerId: mSeletedData
                    .map(item => item.id)
                    .filter(Boolean)
                    .join(','),
            },
        );
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        message.destroy();
        if (d.data) {
            message.success('刷新成功');
            return true;
        } else {
            message.error('刷新失败');
            return false;
        }
    }

    public async refreshCooStatus() {
        const mSeletedData = this.mutatingData
            ? [this.mutatingData]
            : this.defaultMSelect.mSeletedData;

        if (mSeletedData.length === 0) {
            message.error('请选择客户');
            return false;
        }
        const [d, e] = await defaultAxios.put(
            '/crm/system/highsea/recycle/customer/' + mSeletedData[0].id,
        );
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        message.destroy();
        if (d.data) {
            message.success('刷新成功');
            return true;
        } else {
            message.error('刷新失败');
            return false;
        }
    }

    public async create() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        delete data.id;
        delete data.key;

        const [ret, errMsg] = await mutate('customer', 'insert', data);
        if (!ret || errMsg) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        // 自拓来源 特殊处理
        const innerDataFinal =
            data['sub_source'] === null
                ? [
                      ...innerData,
                      {
                          fieldName: 'sub_source',
                          errorType: 'Error',
                          errorMsg: '参数必填',
                      },
                  ]
                : innerData;

        if (_.isArray(innerDataFinal) && innerDataFinal.length > 0) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerDataFinal),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('新建成功');
        this.defaultEE.emit('create-success');
        return true;
    }
    public async saveDDetail(d: any) {
        const data = _.cloneDeep(defaultWritingDataParser(d));
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        delete data.key;
        const [ret, errMsg] = await mutate('customer', 'update', data);
        if (!ret || errMsg) {
            return false;
        }
        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            message.error('存在校验不通过的字段');
            return false;
        }
        this.defaultEE.emit('update-success');
        return true;
    }
    public async update() {
        const { updateMode } = this;
        const transStand = 'trans-standard' === updateMode;

        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        delete data.key;
        const [ret, errMsg] = await mutate('customer', 'update', data);
        if (!ret || errMsg) {
            return false;
        }
        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            return false;
        }
        if (+code !== 0) {
            transStand && message.error('转移标准客户失败，请联系管理员。');
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        !transStand && message.info('修改成功');
        transStand &&
            message.info('成功转移非标客户至【' + data.customer_name + '】');
        this.defaultEE.emit('update-success');
        return true;
    }

    public async delete() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = [data.id];
        const [d, e] = await xDelete(tableId, operatingIdList);
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        if (!e) {
            message.info('删除成功');
            return true;
        }
        return false;
    }
    public async mdelete() {
        const ids = _.map(this.defaultMSelect.mSeletedData, item => item.id);
        if (ids.length === 0) {
            return;
        }
        const tableId = this.defaultMeta.tableId;
        const operatingIdList = ids;
        const [d, e] = await xDelete(tableId, operatingIdList);
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('删除成功');
        } else {
            this.defaultMSelect.setMopResult('批量删除结果', d.data);
            if (errorNum === 0) {
                return true;
            }
        }

        return true;
    }
    public async mDispatch(user: any) {
        if (_.isNil(user)) {
            message.error('目标用户未选择，转移失败');
            return false;
        }
        const mSeletedData = this.mutatingData
            ? [this.mutatingData]
            : this.defaultMSelect.mSeletedData;

        const [d, e] = await defaultAxios.post('/crm/customer/assign', {
            customerIds: mSeletedData.map(item => item.id).filter(Boolean),
            userId: user.originalValue,
        });
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('分配成功');
            return true;
        } else {
            this.defaultMSelect.setMopResult('批量分配结果', d.data);
            if (errorNum === 0) {
                return true;
            }
            return false;
        }
    }

    public async mDrop(reason: number, remark: string) {
        const mSeletedData = this.mutatingData
            ? [this.mutatingData]
            : this.defaultMSelect.mSeletedData;
        const [d, e] = await defaultAxios.post('/crm/customer/backHighSea', {
            customerIdList: mSeletedData.map(item => item.id).filter(Boolean),
            backHighSeaReason: {
                reasonType: reason,
                reasonRemark: remark,
            },
        });
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('退回成功');
        } else {
            this.defaultMSelect.setMopResult('批量退回结果', d.data);
            if (errorNum === 0) {
                return true;
            }
            return false;
        }

        return true;
    }

    public async delay(
        delayDays: number,
        remark: string,
        applicant: string,
        processDefinitionId: string,
    ) {
        const customer_id = this.mutatingData.id;
        const delay_days = delayDays;
        const delay_reason = remark;

        const [d, e] = await defaultAxios.put('/crm/workflow/instance/start', {
            applicant,
            businessInfo: {
                applicant_dept_name: null,
                applicant_id: null,
                applicant_time: null,
                customer_id,
                delay_days,
                delay_reason,
            },
            processDefinitionId,
        });

        if (isNetSuccess(d, e)) {
            return true;
        }

        return false;
    }

    public async mFetch(validCodeBody?: any) {
        const mSeletedData = this.mutatingData
            ? [this.mutatingData]
            : this.defaultMSelect.mSeletedData;

        const [d, e] = await defaultAxios.post(
            '/bff/api/rest/customer/get',
            {
                customerIds: mSeletedData.map(item => item.id).filter(Boolean),
            },
            {
                headers: validCodeBody
                    ? (() => {
                          validCodeBody = validCodeBody || {};
                          const { code, token } = validCodeBody;
                          if (code && token) {
                              return { mmvc: token + '@' + code };
                          }
                          return {};
                      })()
                    : {},
            },
        );
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('领取成功');
        } else {
            const mopResult: IMOpResult = d.data;
            // 领取时，后端在某些case下不方便返回description，这里用前端数据把这个字段补齐
            this.defaultMSelect.setMopResult(
                '领取结果',
                mergeCustomersToMopResult(mSeletedData, mopResult),
            );
            if (errorNum === 0) {
                return true;
            }
        }

        return true;
    }

    public async mTransform(user: any, addTeam: boolean) {
        if (_.isNil(user)) {
            message.error('目标用户未选择，转移失败');
            return false;
        }
        const mSeletedData = this.defaultMSelect.mSeletedData;

        const [d, e] = await defaultAxios.post(
            '/crm/customer/transferCustomer',
            {
                customerIds: mSeletedData.map(item => item.id).filter(Boolean),
                userId: user.originalValue,
                addTeam,
            },
        );
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum, errorList = [] } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('转移成功');
            return true;
        } else {
            this.defaultMSelect.setMopResult('批量转移结果', d.data);
            if (errorNum === 0) {
                return true;
            }
            return false;
        }
    }

    public async mTransformSea(highSea: any) {
        if (_.isNil(highSea)) {
            message.error('目标公海池未选择，转池失败');
            return false;
        }
        const mSeletedData = this.defaultMSelect.mSeletedData;

        const [d, e] = await defaultAxios.post(
            '/crm/customer/transferHighSea',
            {
                customerIdList: mSeletedData
                    .map(item => item.id)
                    .filter(Boolean),
                targetHighSeaId: highSea.originalValue,
            },
        );
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('转池成功');
            return true;
        } else {
            this.defaultMSelect.setMopResult('批量转池结果', d.data);
            if (errorNum === 0) {
                return true;
            }
            return false;
        }
    }

    public async star(cus: any, nextState: boolean) {
        if (!cus?.id) {
            message.error('无客户id');
            return false;
        }
        const [d, e] = await defaultAxios.get('/crm/focus/customer', {
            customerId: cus?.id,
            focusStatus: nextState ? 1 : 0,
        });
        if (isNetSuccess(d, e)) {
            return true;
        }
        return false;
    }

    get fieldAuthCode() {
        if (this.listType === 'to-release') {
            return 'PRIVATE-VIEW';
        }
        return this.listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    }

    get dataAuthCode() {
        if (this.listType === 'to-release') {
            return 'SEARCH-PRIVATE';
        }
        return this.listType === 'all' ? 'SEARCH-PUBLIC' : 'SEARCH-PRIVATE';
    }

    public async fetchInstantlyMutatingDataById(id: any) {
        const { fieldAuthCode, dataAuthCode } = this;
        this.startNewData();

        this.mutatingDataLoading = true;
        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: id,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            undefined,
            dataAuthCode,
        );
        this.mutatingDataLoading = false;

        if (d === null || e !== null) {
            return false;
        }
        const { datas } = d;
        if (datas && datas[0]) {
            this.mutatingData = datas[0];
            return true;
        } else {
            message.error('没有该数据的权限');
            return false;
        }
    }

    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode, dataAuthCode } = this;
        let kase = '';
        const activityds = this.defaultFastFilters.selectedFastFilters.find(
            item =>
                _.endsWith(item.fastFilterKey, '_recent_activity_record_time'),
        );
        if (!!activityds) {
            kase = 'activityds';
        }
        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            [
                'business_registration',
                'customer_cid',
                'lock_expire_time',
                'relate_type',
                'cross_region_tag',
                'new_economy_company',
                'high_potential',
                'gradation',
                'owner_id',
                'score_remind',
                'score_remind_detail',
                'customer_status',
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
            ],
            dataAuthCode,
            undefined,
            kase ? '/bff/api/rest/customer/list?kase=' + kase : undefined,
        );

        if (d === null || e !== null) {
            return [d, e];
        }
        const customersData = d;
        const { amount, datas } = customersData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    public async fetchStars(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode, dataAuthCode } = this;
        let kase = '';
        const activityd7 = this.defaultFastFilters.selectedFastFilters.find(
            item => item.fastFilterKey === '7_recent_activity_record_time',
        );
        if (!!activityd7) {
            kase = '7_recent_activity_record_time';
        }
        const [d, e] = await commonStarQuery(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            [
                'business_registration',
                'customer_cid',
                'lock_expire_time',
                'relate_type',
                'cross_region_tag',
                'new_economy_company',
                'high_potential',
                'gradation',
                'owner_id',
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
            ],
            dataAuthCode,
            undefined,
            kase ? '/bff/api/rest/customer/list?kase=' + kase : undefined,
        );

        if (d === null || e !== null) {
            return [d, e];
        }
        const customersData = d;
        const { amount, datas } = customersData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    public async fetchOutflow(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode, dataAuthCode } = this;
        let kase = '';
        const activityd7 = this.defaultFastFilters.selectedFastFilters.find(
            item => item.fastFilterKey === '7_recent_activity_record_time',
        );
        if (!!activityd7) {
            kase = '7_recent_activity_record_time';
        }
        const [d, e] = await commonQueryOutflow(
            'customer',
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            [
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
            ],
            dataAuthCode,
            undefined,
            kase ? '/bff/api/rest/customer/list?kase=' + kase : undefined,
        );

        if (d === null || e !== null) {
            return [d, e];
        }
        const customersData = d;
        const { amount, datas } = customersData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    get downloadUrl() {
        const { fieldAuthCode, dataAuthCode } = this;
        const { tableId: objectName } = this.defaultMeta;
        const { amount } = this.defaultPagination;
        if (!amount) {
            return '';
        }
        const pageSize = Math.max(Math.min(Math.ceil(amount / 10), 300), 100);
        const fetchBody = getCommonQueryBody(
            { ...this.defaultPagination.paginationArgs, pageSize },
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            [
                'id',
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
            ],
            dataAuthCode,
            false,
        );
        // 如果是cu_role_user则是流出记录
        fetchBody.objectName = objectName;
        return (
            `${
                window.location.origin
            }/bff/api/rest/export?q=${encodeURIComponent(
                JSON.stringify(fetchBody),
            )}&hash=` + Math.random()
        );
    }

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMeta = new ListMeta(
        '/bff/api/rest/meta/customer/list',
        '/bff/api/rest/meta/customer/list/filters/save',
        '/bff/api/rest/meta/customer/list/table-cols/save',
    );
    public setDefaultMeta(nextDefaultMeta: ListMeta) {
        this.defaultMeta = nextDefaultMeta;
        this.defaultFilter = new FilterStore(
            this.defaultSorter,
            nextDefaultMeta,
        );
    }

    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();
    public defaultPerm: Perm = new Perm(
        '/bff/api/rest/perm?mod=sales-follow-up:customer',
    );
    public defaultFastFilters = new FastFilterStore();
    public defaultMSelect: MSelectStore = new MSelectStore();
    constructor(listType?: string) {
        if (_.isString(listType) && listType === 'all') {
            this.defaultMeta = new ListMeta(
                '/bff/api/rest/meta/customer-all/list',
                '/bff/api/rest/meta/customer-all/list/filters/save',
                '/bff/api/rest/meta/customer-all/list/table-cols/save',
            );
            this.setDefaultMeta(this.defaultMeta);
        }

        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultMeta: false,
            defaultEE: false,
            defaultPerm: false,
            defaultMSelect: false,
            defaultFastFilters: false,
        });
    }
}

export const getDefaultCustomerListStore = createDefaultInstanceGetterA(
    CustomerListStore,
);

// precache
// (new CustomerListStore()).defaultMeta.fetch()

export const useAnyCustomerStore = makeDefaultInitailingHookB(
    CustomerListStore,
    'customer',
);

export const genCustomerIdParamByCustomerItemAndParamMeta = (
    customer: any,
    fieldMeta?: any,
) => {
    // HACK: 先写死
    const tempCustomer = _.cloneDeep(customer) || {};
    if (!tempCustomer.id || !tempCustomer.customer_name) {
        return null;
    }
    return {
        type: 'obj',
        showFiledName: 'customer_name',
        originalValue: tempCustomer.id,
        relatedObj: {
            relation_object_map: {},
            id: tempCustomer.id,
            customer_name: tempCustomer.customer_name,
            cooperation_status: tempCustomer.cooperation_status,
        },
        showRelatedObj: {
            relation_object_map: {},
            id: tempCustomer.id,
            customer_name: tempCustomer.customer_name,
        },
    };
};
