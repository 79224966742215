import React from 'react';
import Switch, { SwitchProps } from 'antd/lib/switch';
import { getCommonStatus, ICommonProps } from './common';
import _ from 'lodash';

export type SwitchValueType = boolean;
export interface ISwitchOptions extends SwitchProps {}

export interface IWidgetSwitchProps<T = any> extends ICommonProps<T> {
    value: SwitchValueType;
    options: ISwitchOptions;
    onChange: (value: SwitchValueType) => void;
}

export const WidgetSwitch: React.FC<IWidgetSwitchProps> = ({
    value,
    options,
    onChange,
    status,
    onInteract = key => void 0,
    data,
    statusExtraData,
}) => {
    const { disabled } = getCommonStatus(status, data, statusExtraData);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }
    return (
        <Switch
            checked={value}
            onChange={checked => {
                onChange(checked);
                onInteract('validate-instantly', checked);
            }}
            {...options}
        ></Switch>
    );
};
