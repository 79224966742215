import React from 'react';

import { OptProductListStore } from '../store';
import { Modal, Button } from 'antd';

import _ from 'lodash';
import { AntTable } from '@/components';
import { observer } from 'mobx-react-lite';

export const EquityDetail: React.FC<{
    defaultStore: OptProductListStore;
}> = observer(({ defaultStore }) => {
    const { mutatingData } = defaultStore;
    const visible = defaultStore.actionType === 'see-equity-detail';
    if (!mutatingData) {
        return null;
    }

    const { equity_detail: equityDetailStr } = mutatingData;
    let dataSource = [];
    try {
        dataSource = JSON.parse(equityDetailStr);
    } catch (e) {}

    return (
        <Modal
            visible={visible}
            title={'权益详情'}
            cancelText={null}
            onCancel={() => {
                defaultStore.resetAction();
            }}
            footer={[
                <Button
                    onClick={async () => {
                        defaultStore.resetAction();
                    }}
                    type="primary"
                >
                    关闭
                </Button>,
            ]}
            zIndex={10001}
        >
            <AntTable
                columns={[
                    {
                        title: '权益',
                        key: 'equityName',
                        dataIndex: 'equityName',
                    },
                    {
                        title: '标准权益值',
                        key: 'equityOriginalNum',
                        dataIndex: 'equityOriginalNum',
                    },
                    {
                        title: '兑换后权益值',
                        key: 'equityNum',
                        dataIndex: 'equityNum',
                    },
                    // {
                    //     title: '赠送值',
                    //     key: 'equityGiftNum',
                    //     dataIndex: 'equityGiftNum',
                    // },
                ]}
                dataSource={dataSource}
                pagination={false}
            />
        </Modal>
    );
});
