import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { CrmTimeline, CrmUserTrend } from '@/components';
import { Icon, Alert } from 'antd';

const TimelineDemo: React.FC<RouteComponentProps> = props => {
    return (
        <div>
            <div
                style={{
                    width: 500,
                    backgroundColor: 'white',
                    padding: 30,
                }}
            >
                <CrmTimeline
                    groups={[
                        {
                            day: '今天',
                            timelineItems: [
                                {
                                    icon: <Icon type="phone" />,
                                    background: '#F5A87A',
                                    content: (
                                        <div style={{ padding: 15 }}>
                                            <Alert message="鸡排打折" />
                                        </div>
                                    ),
                                    time: '22:00',
                                },
                                {
                                    icon: <Icon type="wechat" />,
                                    background: '#75D468',
                                    content: (
                                        <div style={{ padding: 15 }}>
                                            <Alert message="鸡排打折" />
                                        </div>
                                    ),
                                    time: '18:30',
                                },
                            ],
                        },
                        {
                            day: '昨天',
                            timelineItems: [
                                {
                                    icon: <Icon type="phone" />,
                                    background: '#F5A87A',
                                    content: (
                                        <div style={{ padding: 15 }}>
                                            <Alert message="鸡排打折" />
                                        </div>
                                    ),
                                    time: '12:00',
                                },
                                {
                                    icon: <Icon type="wechat" />,
                                    background: '#75D468',
                                    content: (
                                        <div style={{ padding: 15 }}>
                                            <Alert message="鸡排打折" />
                                        </div>
                                    ),
                                    time: '10:30',
                                },
                                {
                                    icon: <Icon type="phone" />,
                                    background: '#F5A87A',
                                    content: (
                                        <div style={{ padding: 15 }}>
                                            <Alert message="鸡排打折" />
                                        </div>
                                    ),
                                    time: '05:00',
                                },
                            ],
                        },
                    ]}
                />
            </div>
            <div
                style={{
                    width: 500,
                    backgroundColor: 'white',
                    padding: 40,
                }}
            >
                <div
                    style={{
                        width: 310,
                        backgroundColor: 'white',
                        padding: 14,
                        boxShadow: '0px 2px 20px 0px rgba(21,22,31,0.05)',
                        borderRadius: 8,
                    }}
                >
                    <div
                        style={{
                            fontSize: '16px',
                            color: '#15161F',
                            lineHeight: '16px',
                            fontWeight: 500,
                            marginBottom: 24,
                        }}
                    >
                        客户动态
                    </div>
                    <CrmUserTrend
                        showCount={4}
                        groups={[
                            {
                                day: '今天',
                                userTrendItems: [
                                    {
                                        content: '鸡排打折',
                                    },
                                    {
                                        content: '鸡排打折',
                                    },
                                ],
                            },
                            {
                                day: '昨天',
                                userTrendItems: [
                                    {
                                        content: '鸡排打折',
                                    },
                                    {
                                        content: '鸡排打折',
                                    },
                                    {
                                        content: '鸡排打折',
                                    },
                                    {
                                        content: '鸡排打折',
                                    },
                                ],
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default TimelineDemo;
