import React, { useState, useMemo, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { Button } from 'antd';
import _ from 'lodash';
import { loadingWrapperOver } from '@/components';
import BoardCard from '../components/board-card';
import Selector from '../components/selector';
import ActionOwnerTable from './components/action-owner-table';
import BoardSwitch from '../components/board-switch';
import {
    queryActionSolarizationOwner,
    queryActionSolarizationCustomer,
    exportActionSolarizationOwner,
    exportActionSolarizationCustomer,
} from './api';
import { useAuthView } from '../hooks';

import './index.less';
import { getDefaultOrganize } from '../composite-opportunity';

const CompositeActionPage: React.FC = () => {
    const [orgId, setOrgId] = useState(63);

    const { data, loading, userRegionsLv2, userRegionsLv3 } = useAuthView(
        orgId,
    );
    console.log('authViewData', data, userRegionsLv2, userRegionsLv3);
    const isSaleAndGroup = data?.viewOrgType === '3';
    const { defaultOrganize, defaultOrganizeOption } = getDefaultOrganize(
        isSaleAndGroup,
    );
    // 销售维度组织筛选
    const [ownerOrganize, setOwnerOrganize] = useState(defaultOrganize);
    // 客户维度组织筛选
    const [customerOrganize, setCustomerOrganize] = useState(0);
    // console.log('organize', organize, data?.viewOrgType);
    useEffect(() => {
        let defaultOrganize: number = 0;
        switch (data?.viewOrgType) {
            case '6':
                defaultOrganize = 1;
                break;
            case '5':
                defaultOrganize = 1;
                break;
            case '4':
                defaultOrganize = 2;
                break;
            case '3':
                defaultOrganize = 3;
                break;
            default:
                defaultOrganize = 3;
                break;
        }
        setOwnerOrganize(defaultOrganize);
    }, [data]);

    // 销售数据通晒
    const { data: ownerData, loading: ownerLoading } = useRequest(
        () => queryActionSolarizationOwner(orgId),
        {
            refreshDeps: [orgId],
        },
    );

    // 客户数据通晒
    const { data: customerData, loading: customerLoading } = useRequest(
        () => queryActionSolarizationCustomer(orgId),
        { refreshDeps: [orgId] },
    );

    // 销售数据通晒导出
    const {
        run: handleExportActionSolarizationOwner,
        loading: exportActLoadingOwner,
    } = useRequest(() => exportActionSolarizationOwner(orgId), {
        manual: true,
    });

    // 客户数据通晒导出
    const {
        run: handleExportActionSolarizationCustomer,
        loading: exportActLoadingCustomer,
    } = useRequest(() => exportActionSolarizationCustomer(orgId), {
        manual: true,
    });

    // 销售数据通晒数据源
    const ownerDataSource = useMemo(() => {
        const dataSource = _.cloneDeep(ownerData);

        return dataSource?.map((info: any) => {
            info.children = info.group_list;
            info.name = info.city_name;
            info.children?.map((group: any) => {
                group.name = group.group_name;
                group.children = group.sale_list;
                group.children?.map((sale: any) => {
                    sale.city_name = info.city_name;
                    sale.group_name = group.group_name;
                    sale.name = sale.sale_name;
                });
            });
            return info;
        });
    }, [ownerData]);
    console.log('ownerDataSource', ownerDataSource);

    // 客户数据通晒数据源
    const customerDataSource = useMemo(() => {
        const dataSource = _.cloneDeep(customerData);

        return dataSource?.map((info: any) => {
            info.name = info.region;
            info.children = info.city_list;
            info?.children?.map((city: any) => {
                city.region = info.region;
                city.children = city.group_list;
                city.name = city.city_name;
                city.children?.map((group: any) => {
                    group.region = info.region;
                    group.name = group.group_name;
                });
            });
            return info;
        });
    }, [customerData]);
    console.log('customerDataSource', customerDataSource);

    const expandedRowKeysOwner = useMemo(() => {
        if (ownerOrganize === 1) {
            return [];
        }
        if (ownerOrganize === 2) {
            return ownerData?.reduce((prev: any, cur: any) => {
                prev.push(cur.city_name);
                return prev;
            }, []);
        }
        if (ownerOrganize === 3) {
            return ownerData?.reduce((prev: any, cur: any) => {
                prev.push(cur?.city_name);
                cur?.group_list?.map((group: any) => {
                    prev.push(group?.group_name);
                });
                return prev;
            }, []);
        }
        return [];
    }, [ownerData, ownerOrganize]);

    const expandedRowKeysCustomer = useMemo(() => {
        if (customerOrganize === 1) {
            return customerData?.map((info: any) => info.region);
        }

        return [];
    }, [customerData, customerOrganize]);

    return loadingWrapperOver(loading)(
        <div>
            <BoardSwitch onChange={setOrgId} />
            <BoardCard
                title="销售行为数据通晒"
                filters={
                    <>
                        <Selector
                            label="组织筛选"
                            options={defaultOrganizeOption}
                            defaultValue={0}
                            value={ownerOrganize}
                            onChange={selected => {
                                if (typeof selected === 'number') {
                                    setOwnerOrganize(selected);
                                }
                            }}
                        />
                    </>
                }
                extra={
                    <Button
                        className="board-button"
                        loading={exportActLoadingOwner}
                        onClick={handleExportActionSolarizationOwner}
                    >
                        导出
                    </Button>
                }
            >
                <ActionOwnerTable
                    dimension={'owner'}
                    dataSource={ownerDataSource}
                    loading={ownerLoading}
                    expandedRowKeys={expandedRowKeysOwner}
                    expandIcon={() => <span />}
                />
            </BoardCard>

            {!isSaleAndGroup && (
                <BoardCard
                    title="开城：销售行为数据通晒"
                    subtitle={
                        <span>
                            说明：开城视角当前是以客户所在城市维度来看销售行为数据的，例如客户在武汉，那么客户属地为武汉的客户所产生的销售行为，会被记录在通晒内，数据可能存在偏差
                        </span>
                    }
                    filters={
                        <>
                            <Selector
                                label="组织筛选"
                                options={[
                                    { title: '展开到省', value: 0 },
                                    { title: '展开到城市', value: 1 },
                                ]}
                                defaultValue={0}
                                value={customerOrganize}
                                onChange={selected => {
                                    if (typeof selected === 'number') {
                                        setCustomerOrganize(selected);
                                    }
                                }}
                            />
                        </>
                    }
                    extra={
                        <Button
                            className="board-button"
                            loading={exportActLoadingCustomer}
                            onClick={handleExportActionSolarizationCustomer}
                        >
                            导出
                        </Button>
                    }
                >
                    <ActionOwnerTable
                        dimension={'customer'}
                        dataSource={customerDataSource}
                        loading={customerLoading}
                        expandedRowKeys={expandedRowKeysCustomer}
                        expandIcon={() => <span />}
                    />
                </BoardCard>
            )}
            {/* <BoardCard title="其他数据维度通晒">
                <div className="opportunity-charts-row pre">
                    <CompositeLiveChartsComponents
                        chartsClassName="opportunity-charts-content"
                        containerClassName="opportunity-charts-pre"
                        loading={false}
                        chartsOptions={{}}
                        showPre={true}
                        title={''}
                    />
                </div>
            </BoardCard> */}
        </div>,
    );
};

export default CompositeActionPage;
