import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';

import './index.less';

export interface TableTitleProps {
    title: string;
    tips?: string | ReactNode;
}

const TableTitle: React.FC<TableTitleProps> = props => {
    const { title, tips } = props;
    return (
        <Tooltip title={tips}>
            <span className="board-table-title-tips">{title}</span>
        </Tooltip>
    );
};

export default TableTitle;
