import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { LayoutStandardCrud, AntButton, TableLv2 } from '@/components';
import { DatePicker, Input, Button } from 'antd';
import './index.less';
import moment, { Moment } from 'moment';
import { StoreContext } from '@/stores';
import { useNet } from '@/utils';
import { ISalesDetailAnaData } from './store';
import { create, all } from 'mathjs';
import UserSelector from '../../../../components/common/user-selector/index';
const IMath: any = create(all);
IMath.config({
    number: 'BigNumber',
    precision: 64,
});
const RangePicker = DatePicker.RangePicker;
const SalesDetailAna: React.FC<RouteComponentProps> = props => {
    const {} = props;
    const store = useContext(StoreContext);
    const salesDetailStore = store.getDefaultSalesDetailStore();
    const anaStore = store.getDefaultAnalysisStore();
    const {
        getSalesDetailData,
        exportSalesDetailData,
        salesDetailAnaData,
        salesListLoading,
        salesListData,
        getSalesList,
    } = salesDetailStore;
    const [anaSearchValue, setAnaSearchValue] = useState<{
        beginDate: Moment | string;
        endDate: Moment | string;
        userId: string[];
    }>({
        beginDate: moment()
            .startOf('month')
            .format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment()
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
        userId: [],
    });
    const [error, loading, reload] = useNet(() =>
        getSalesDetailData(anaSearchValue),
    );

    // 时间选择变更
    const onActionSearchTimeChange = (
        dates: any,
        dateStrings: [string, string],
    ) => {
        setAnaSearchValue({
            ...anaSearchValue,
            beginDate: dateStrings[0],
            endDate: dateStrings[1],
        });
        // reload();
    };
    useEffect(() => {
        getSalesList();
    }, []);
    // useEffect(() => {
    //     reload();
    // }, [defaultSalesDetailAnaPagination.pagenum]);
    const renderAnaPageFilter = () => {
        return (
            <div className="ana-filter-content">
                <div className="ana-filter-row">
                    <div className="ana-filter-item">
                        <label htmlFor="user">线索所有人：</label>
                        <UserSelector
                            style={{ width: '360px' }}
                            name="user"
                            value={anaSearchValue.userId}
                            mode="multiple"
                            placeholder="请选择用户"
                            onChange={e => {
                                setAnaSearchValue({
                                    ...anaSearchValue,
                                    userId: e as any,
                                });
                            }}
                            loading={salesListLoading}
                            userDocs={salesListData as any}
                        />
                    </div>
                    <div className="ana-filter-item">
                        <label htmlFor="time">线索创建时间：</label>
                        <RangePicker
                            name="time"
                            value={[
                                moment(anaSearchValue.beginDate),
                                moment(anaSearchValue.endDate),
                            ]}
                            showTime={true}
                            format="YYYY-MM-DD HH:mm:ss"
                            onChange={onActionSearchTimeChange}
                            onOk={reload}
                            style={{ width: '400px' }}
                            allowClear={false}
                        />
                    </div>
                </div>
                <div className="ana-button-row">
                    <Button
                        type="primary"
                        onClick={() => {
                            // defaultSalesDetailAnaPagination.pagenum = 1;
                            reload();
                        }}
                    >
                        查询
                    </Button>
                    {anaStore.exportVis && (
                        <Button
                            type="primary"
                            disabled={loading}
                            onClick={() => {
                                exportSalesDetailData(anaSearchValue);
                            }}
                        >
                            导出
                        </Button>
                    )}
                </div>
            </div>
        );
    };
    // leadOwner?: string; // 线索所有人
    // immediateSuperior?: string; // 直属上级
    // country?: string; // 城市
    // area?: string; // 区域
    // leadChannel?: string; // 线索渠道
    // leadCount?: number; // 线索数量
    // followedLeadCount?: number; // 跟进线索数量
    // followedLeadRate?: number; // 线索跟进率
    // transformOptCount?: number; // 转商机数
    // winCount?: number; // 赢单量
    // transformOptMoneyCount?: number; // 赢单金额
    return (
        <div className="analysis-content sales-detail">
            <LayoutStandardCrud
                title={'线索转化-销售明细表'}
                message={null}
                popups={null}
                multipleOps={null}
                globalOps={null}
                filters={renderAnaPageFilter()}
                dataTable={
                    <TableLv2<ISalesDetailAnaData>
                        loading={loading}
                        dataSource={salesDetailAnaData}
                        closeRowSelection={true}
                        pagination={false}
                        // pagination={{
                        //     ...defaultSalesDetailAnaPagination.paginationForUI,
                        //     total: defaultSalesDetailAnaPagination.amount,
                        // }}
                        // onPaginationChange={
                        //     defaultSalesDetailAnaPagination.setPaginationByUI
                        // }
                        scroll={{ x: '1400' }}
                        columns={[
                            {
                                title: '线索所有人',
                                key: 'leadOwner',
                                dataIndex: 'leadOwner',
                                width: '100px',
                                render: (
                                    text: string,
                                    row: ISalesDetailAnaData,
                                    index: number,
                                ) => {
                                    let indexRowSpan = 0;
                                    if (
                                        index === 0 ||
                                        text !=
                                            salesDetailAnaData?.[index - 1]
                                                ?.leadOwner
                                    ) {
                                        let tempI = index + 1;
                                        let count = 1;
                                        while (
                                            text ===
                                            salesDetailAnaData?.[tempI]
                                                ?.leadOwner
                                        ) {
                                            count = count + 1;
                                            tempI = tempI + 1;
                                        }
                                        indexRowSpan = count;
                                    }
                                    return {
                                        children: <span>{text}</span>,
                                        props: {
                                            colSpan:
                                                index !== 0 &&
                                                text ===
                                                    salesDetailAnaData?.[
                                                        index - 1
                                                    ]?.leadOwner
                                                    ? 0
                                                    : 1,
                                            rowSpan: indexRowSpan,
                                        },
                                    };
                                },
                            },
                            {
                                title: '直属上级',
                                key: 'immediateSuperior',
                                width: '100px',
                                dataIndex: 'immediateSuperior',
                                render: (
                                    text: string,
                                    row: ISalesDetailAnaData,
                                    index: number,
                                ) => {
                                    let indexRowSpan = 0;
                                    if (
                                        index === 0 ||
                                        text !=
                                            salesDetailAnaData?.[index - 1]
                                                ?.immediateSuperior ||
                                        row.leadOwner !=
                                            salesDetailAnaData?.[index - 1]
                                                ?.leadOwner
                                    ) {
                                        let tempI = index + 1;
                                        let count = 1;
                                        while (
                                            text ===
                                                salesDetailAnaData?.[tempI]
                                                    ?.immediateSuperior &&
                                            row.leadOwner ===
                                                salesDetailAnaData?.[tempI]
                                                    ?.leadOwner
                                        ) {
                                            count = count + 1;
                                            tempI = tempI + 1;
                                        }
                                        indexRowSpan = count;
                                    }
                                    return {
                                        children: <span>{text}</span>,
                                        props: {
                                            colSpan:
                                                index !== 0 &&
                                                text ===
                                                    salesDetailAnaData?.[
                                                        index - 1
                                                    ]?.immediateSuperior &&
                                                row.leadOwner ===
                                                    salesDetailAnaData?.[
                                                        index - 1
                                                    ]?.leadOwner
                                                    ? 0
                                                    : 1,
                                            rowSpan: indexRowSpan,
                                        },
                                    };
                                },
                            },
                            {
                                title: '城市',
                                key: 'city',
                                dataIndex: 'city',
                                width: '100px',
                                render: (
                                    text: string,
                                    row: ISalesDetailAnaData,
                                    index: number,
                                ) => {
                                    let indexRowSpan = 0;
                                    if (
                                        index === 0 ||
                                        text !=
                                            salesDetailAnaData?.[index - 1]
                                                ?.city ||
                                        row.leadOwner !=
                                            salesDetailAnaData?.[index - 1]
                                                ?.leadOwner
                                    ) {
                                        let tempI = index + 1;
                                        let count = 1;
                                        if (
                                            row.leadOwner !=
                                            salesDetailAnaData?.[tempI]
                                                ?.leadOwner
                                        ) {
                                            indexRowSpan = 1;
                                        }
                                        while (
                                            text ===
                                                salesDetailAnaData?.[tempI]
                                                    ?.city &&
                                            row.leadOwner ===
                                                salesDetailAnaData?.[tempI]
                                                    ?.leadOwner
                                        ) {
                                            count = count + 1;
                                            tempI = tempI + 1;
                                        }
                                        indexRowSpan = count;
                                    }
                                    return {
                                        children: <span>{text}</span>,
                                        props: {
                                            colSpan:
                                                index !== 0 &&
                                                text ===
                                                    salesDetailAnaData?.[
                                                        index - 1
                                                    ]?.city &&
                                                row.leadOwner ===
                                                    salesDetailAnaData?.[
                                                        index - 1
                                                    ]?.leadOwner
                                                    ? 0
                                                    : 1,
                                            rowSpan: indexRowSpan,
                                        },
                                    };
                                },
                            },
                            {
                                title: '区域',
                                key: 'area',
                                dataIndex: 'area',
                                width: '100px',
                                render: (
                                    text: string,
                                    row: ISalesDetailAnaData,
                                    index: number,
                                ) => {
                                    let indexRowSpan = 0;
                                    if (
                                        index === 0 ||
                                        text !=
                                            salesDetailAnaData?.[index - 1]
                                                ?.area ||
                                        row.leadOwner !=
                                            salesDetailAnaData?.[index - 1]
                                                ?.leadOwner
                                    ) {
                                        let tempI = index + 1;
                                        let count = 1;
                                        if (
                                            row.leadOwner !=
                                            salesDetailAnaData?.[tempI]
                                                ?.leadOwner
                                        ) {
                                            indexRowSpan = 1;
                                        }
                                        while (
                                            text ===
                                                salesDetailAnaData?.[tempI]
                                                    ?.area &&
                                            row.leadOwner ===
                                                salesDetailAnaData?.[tempI]
                                                    ?.leadOwner
                                        ) {
                                            count = count + 1;
                                            tempI = tempI + 1;
                                        }
                                        indexRowSpan = count;
                                    }
                                    return {
                                        children: <span>{text}</span>,
                                        props: {
                                            colSpan:
                                                index !== 0 &&
                                                text ===
                                                    salesDetailAnaData?.[
                                                        index - 1
                                                    ]?.area &&
                                                row.leadOwner ===
                                                    salesDetailAnaData?.[
                                                        index - 1
                                                    ]?.leadOwner
                                                    ? 0
                                                    : 1,
                                            rowSpan: indexRowSpan,
                                        },
                                    };
                                },
                            },
                            {
                                title: '线索渠道',
                                key: 'leadChannel',
                                dataIndex: 'leadChannel',
                                width: '160px',
                            },
                            {
                                title: '线索数量',
                                key: 'leadCount',
                                dataIndex: 'leadCount',
                                width: '120px',
                            },
                            {
                                title: '跟进数量',
                                key: 'followedLeadCount',
                                dataIndex: 'followedLeadCount',
                                width: '120px',
                            },
                            {
                                title: '线索跟进率',
                                key: 'followedLeadRate',
                                width: '120px',
                                render: item => {
                                    return `${
                                        item.leadCount
                                            ? (
                                                  Number(
                                                      (
                                                          item.followedLeadCount /
                                                          item.leadCount
                                                      ).toFixed(4),
                                                  ) * 100
                                              ).toFixed(2)
                                            : 0
                                    }%`;
                                },
                            },
                            {
                                title: '转商机数',
                                key: 'transformOptCount',
                                width: '120px',
                                dataIndex: 'transformOptCount',
                            },
                            {
                                title: '赢单量',
                                key: 'winCount',
                                dataIndex: 'winCount',
                                width: '120px',
                            },
                            {
                                title: '赢单金额',
                                key: 'transformOptMoneyCount',
                                dataIndex: 'transformOptMoneyCount',
                                width: '160px',
                            },
                        ]}
                    />
                }
            />
        </div>
    );
};
export default observer(SalesDetailAna);
