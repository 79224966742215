import { defaultAxios } from '@/utils';

export const getCustomerReceive: () => Promise<any> = async () => {
    const [d, e] = await defaultAxios.get('/crm/system/receive');
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
};

export const addCustomerReceive: (params: any) => Promise<any> = params => {
    return defaultAxios.post('/crm/system/receive', params);
};

export const updataCustomerReceive: (params: any) => Promise<any> = params => {
    return defaultAxios.put('/crm/system/receive', params);
};

export const deleteCustomerReceive: (id: number) => Promise<any> = id => {
    return defaultAxios.delete(`/crm/system/receive?id=${id}`);
};

export const getCanAddCustomer = async () => {
    const [d, e] = await defaultAxios.get(`/bff/api/rest/opm/list?status=1`);
    if (d && d.data) {
        return d.data;
    }
    return null;
};
