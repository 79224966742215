import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import { Button, Empty, message } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { loadingWrapperOver } from '@/components';
import BoardCard from '../components/board-card';
import TableCard from '../components/table-card';
import Selector from '../components/selector';
import AnnualTable from './components/annual-table';
import SolarTable from './components/solarization-table';
import RegionCharts from './components/region-charts';
import SelectDatePicker from '../components/date-picker';
import CompareCharts from './components/compare-charts';
import BoardSwitch from '../components/board-switch';

import {
    queryAnnualSummary,
    queryIncomeSolarization,
    queryIncomeCompare,
    exportAnnualSummary,
    exportIncomeSolarization,
    exportIncomeCompare,
} from './api';
import { useAuthView } from '../hooks';

import './index.less';
import { getDefaultOrganize } from '../composite-opportunity';

const RegionSelectOptions = [
    {
        title: '全国',
        value: '全国',
    },
    // {
    //     title: '华北区',
    //     value: '华北',
    // },
    // {
    //     title: '华东区',
    //     value: '华东',
    // },
    // {
    //     title: '华南区',
    //     value: '华南',
    // },
    {
        title: '北京',
        value: '北京',
    },
    {
        title: '上海',
        value: '上海',
    },
    {
        title: '杭州',
        value: '杭州',
    },
    {
        title: '广州',
        value: '广州',
    },
    {
        title: '深圳',
        value: '深圳',
    },
    {
        title: '成都',
        value: '成都',
    },
];

const CompositeIncomePage: React.FC<{}> = () => {
    const [orgId, setOrgId] = useState(63);

    const { authView, authViewWithComponent, loading, data } = useAuthView(
        orgId,
    );
    const isSaleAndGroup = data?.viewOrgType === '3';
    const { defaultOrganize, defaultOrganizeOption } = getDefaultOrganize(
        isSaleAndGroup,
    );

    // 组织筛选
    const [organize, setOrganize] = useState(defaultOrganize);
    useEffect(() => {
        let defaultOrganize: number = 0;
        switch (data?.viewOrgType) {
            case '6':
                defaultOrganize = 1;
                break;
            case '5':
                defaultOrganize = 1;
                break;
            case '4':
                defaultOrganize = 2;
                break;
            case '3':
                defaultOrganize = 3;
                break;
            default:
                defaultOrganize = 3;
                break;
        }
        setOrganize(defaultOrganize);
    }, [data]);
    // 对比类型 同比1  环比2
    const [compareType, setCompareType] = useState(1);
    // 时间维度 按天1 按月2
    const [timeDim, setTimeDim] = useState(1);
    // 区域名
    const [region, setRegion] = useState('全国');
    // 当前开始结束时间
    const [currentTimeBegin, setCurrentTimeBegin] = useState('');
    const [currentTimeEnd, setCurrentTimeEnd] = useState('');
    // 对比开始结束时间
    const [compareTimeBegin, setCompareTimeBegin] = useState('');
    const [compareTimeEnd, setCompareTimeEnd] = useState('');

    // 保留 可能有用
    // const { data: annualData, loading: annualLoading } = useRequest(
    //     queryAnnualSummary,
    // );

    const { data: solarData, loading: solarLoading } = useRequest(
        () => queryIncomeSolarization(orgId),
        { refreshDeps: [orgId] },
    );

    const {
        data: compareData,
        run: compareGenerate,
        loading: compareLoading,
        mutate: compareMutate,
    } = useRequest(
        () =>
            queryIncomeCompare({
                orgId,
                compareType,
                region,
                timeDim,
                currentTimeBegin,
                currentTimeEnd,
                compareTimeBegin,
                compareTimeEnd,
            }),
        { manual: true },
    );

    // 重置时间组件
    const resetDataPicker = () => {
        setCurrentTimeBegin('');
        setCurrentTimeEnd('');
        setCompareTimeBegin('');
        setCompareTimeEnd('');
    };

    // 重置对比
    const handleCompareReset = () => {
        setCompareType(1);
        setTimeDim(1);
        setRegion('全国');
        resetDataPicker();
        compareMutate();
    };

    // 切换时间维度时触发
    useEffect(() => {
        resetDataPicker();
    }, [timeDim]);

    const regionChartsXAxis = useCallback(
        region => {
            return (
                solarData?.month_income?.[region]?.map(
                    (item: any) => item.y + '年' + item.m + '月',
                ) || []
            );
        },
        [solarData],
    );

    // 区域图表数据源
    const regionChartsDataSource = useCallback(
        region => {
            return (
                [
                    {
                        label: '业绩目标',
                        data: solarData?.month_income?.[
                            region
                        ]?.map((item: any) => Math.round(item.target)),
                    },
                    {
                        label: '实际业绩',
                        data: solarData?.month_income?.[
                            region
                        ]?.map((item: any) => Math.round(item.income)),
                    },
                    {
                        label: '完成率',
                        data: solarData?.month_income?.[
                            region
                        ]?.map((item: any) => Math.round(item.completion_rate)),
                    },
                ] || []
            );
        },
        [solarData],
    );

    // 回款数据通晒数据源
    const solarDataSource = useMemo(() => {
        const dataSource = _.cloneDeep(solarData);
        return dataSource?.income_info?.map((info: any) => {
            info.children = info.group_list;
            info.name = info.city_name;
            info.children?.map((group: any) => {
                group.name = group.group_name;
                group.children = group.sale_list;
                group.children?.map((sale: any) => {
                    sale.region = info.region;
                    sale.city_name = info.city_name;
                    sale.group_name = group.group_name;
                    sale.name = sale.sale_name;
                });
            });
            return info;
        });
    }, [solarData]);

    const expandedRowKeys = useMemo(() => {
        if (organize === 1) {
            return [];
        }
        if (organize === 2) {
            return solarData?.income_info?.reduce((prev: any, cur: any) => {
                prev.push(cur.city_name);
                return prev;
            }, []);
        }
        if (organize === 3) {
            return solarData?.income_info?.reduce((prev: any, cur: any) => {
                prev.push(cur?.city_name);
                cur?.group_list?.map((group: any) => {
                    prev.push(group?.group_name);
                });
                return prev;
            }, []);
        }
        return [];
    }, [solarData, organize]);

    // 对比图表X轴
    const compareChartsXAxis = useMemo(() => {
        const currentDate =
            compareData?.current_income?.map((item: any) => {
                return item.income_date;
            }) || [];
        return currentDate;
    }, [compareData]);

    // 对比表头
    const compareColumns = useMemo(() => {
        return [
            {
                title: '指标项',
                dataIndex: 'name',
            },
            {
                title: compareType === 1 ? '同比' : '环比',
                dataIndex: compareType === 1 ? 'yoy' : 'link_relative_ratio',
                render: (t: string) => {
                    const text = Math.round(Number(t));
                    if (_.isNaN(text)) return '-';
                    return text > 0 ? (
                        <span style={{ color: '#ff001f' }}>{`+${text}%`}</span>
                    ) : (
                        <span style={{ color: '#09b300' }}>{`${text}%`}</span>
                    );
                },
            },
            ...compareChartsXAxis?.map((date: string) => ({
                title: date,
                dataIndex: date,
                render: (t: string) => {
                    const text = Math.round(Number(t));
                    return !_.isNaN(text) ? text : '-';
                },
            })),
        ];
    }, [compareChartsXAxis]);

    const compareDataSource = useMemo(() => {
        const baseData: any[] = [
            {
                name: '当前时间业绩',
                yoy: compareData?.yoy,
                link_relative_ratio: compareData?.link_relative_ratio,
            },
            { name: '对比时间业绩', yoy: '-', link_relative_ratio: '-' },
        ];
        baseData.map((item, idx) => {
            compareChartsXAxis.map((x: string, i: number) => {
                item[x] =
                    idx === 0
                        ? compareData?.current_income[i]?.sum_income_money
                        : compareData?.compare_income[i]?.sum_income_money;
            });
        });
        return baseData;
    }, [compareChartsXAxis, compareData]);

    const {
        run: handleExportAnnualSummary,
        loading: exportAnnualSummaryLoading,
    } = useRequest(exportAnnualSummary, { manual: true });

    const {
        run: handleExportIncomeSolarization,
        loading: exportIncomeSolarizationLoading,
    } = useRequest(() => exportIncomeSolarization(orgId), { manual: true });

    const {
        run: handleExportIncomeCompare,
        loading: exportIncomeCompareLoading,
    } = useRequest(
        () => {
            if (!currentTimeBegin || !currentTimeEnd) {
                message.error('请选择当前时间');
                return Promise.reject();
            }
            if (!compareTimeBegin || !compareTimeEnd) {
                message.error('请选择对比时间');
                return Promise.reject();
            }
            return exportIncomeCompare({
                orgId,
                compareType,
                region,
                timeDim,
                currentTimeBegin,
                currentTimeEnd,
                compareTimeBegin,
                compareTimeEnd,
            });
        },
        { manual: true },
    );

    // 业绩对比 选择当前时间后 默认给一个对比时间
    useEffect(() => {
        if (currentTimeBegin) {
            const dateMoment = moment(currentTimeBegin);
            const format = dateMoment.creationData().format as string;
            setCompareTimeBegin(
                dateMoment
                    .subtract(1, compareType === 1 ? 'year' : 'quarter')
                    .format(format),
            );
        }
        if (currentTimeEnd) {
            const dateMoment = moment(currentTimeEnd);
            const format = dateMoment.creationData().format as string;
            setCompareTimeEnd(
                dateMoment
                    .subtract(1, compareType === 1 ? 'year' : 'quarter')
                    .format(format),
            );
        }
    }, [currentTimeBegin, currentTimeEnd]);

    const refreshTime = useMemo(() => {
        return moment().format('YYYY-MM-DD');
    }, []);

    return loadingWrapperOver(loading)(
        <div>
            <BoardSwitch onChange={setOrgId} />
            {/* 保留 可能有用 */}
            {/* {!isSaleAndGroup &&
                authViewWithComponent('华北,华东,华南')(
                    <BoardCard
                        title="各年度汇总"
                        extra={
                            <Button
                                className="board-button"
                                loading={exportAnnualSummaryLoading}
                                onClick={handleExportAnnualSummary}
                            >
                                导出
                            </Button>
                        }
                    >
                        <AnnualTable
                            dataSource={annualData}
                            loading={annualLoading}
                        />
                    </BoardCard>,
                )} */}

            <BoardCard
                title="回款数据通晒"
                subtitle={
                    <span>
                        统计口径：{refreshTime}{' '}
                        实时数据，单位为万元。点击字段可显示该字段的含义与计算方式说明。
                        <a
                            href="https://maimai.feishu.cn/docx/DFWzd3csqoW8YLx11zQcaAb2nob"
                            target="_blank"
                        >
                            详细取数逻辑参见文档
                        </a>
                    </span>
                }
                filters={
                    <>
                        <Selector
                            label="组织筛选"
                            options={defaultOrganizeOption}
                            value={organize}
                            onChange={selected => {
                                if (typeof selected === 'number') {
                                    setOrganize(selected);
                                }
                            }}
                        />
                    </>
                }
                extra={
                    <Button
                        className="board-button"
                        loading={exportIncomeSolarizationLoading}
                        onClick={handleExportIncomeSolarization}
                    >
                        导出
                    </Button>
                }
            >
                <SolarTable
                    dataSource={solarDataSource}
                    loading={solarLoading}
                    expandedRowKeys={expandedRowKeys}
                    expandIcon={() => <span />}
                />
                {!isSaleAndGroup && (
                    <>
                        {authViewWithComponent('全国')(
                            <div className="income-card-charts-wrap">
                                <RegionCharts
                                    chartsClassName="income-card-charts-content right"
                                    loading={solarLoading}
                                    title="全国：按月度业绩完成情况"
                                    xAxis={regionChartsXAxis('全国')}
                                    dataSource={regionChartsDataSource('全国')}
                                />
                            </div>,
                        )}

                        <div className="income-card-charts-wrap">
                            {authViewWithComponent('北京')(
                                <RegionCharts
                                    chartsClassName="income-card-charts-content"
                                    loading={solarLoading}
                                    title="北京"
                                    xAxis={regionChartsXAxis('北京')}
                                    dataSource={regionChartsDataSource('北京')}
                                />,
                            )}
                            {authViewWithComponent('上海')(
                                <RegionCharts
                                    chartsClassName="income-card-charts-content"
                                    loading={solarLoading}
                                    title="上海"
                                    xAxis={regionChartsXAxis('上海')}
                                    dataSource={regionChartsDataSource('上海')}
                                />,
                            )}
                        </div>
                        <div className="income-card-charts-wrap">
                            {authViewWithComponent('杭州')(
                                <RegionCharts
                                    chartsClassName="income-card-charts-content"
                                    loading={solarLoading}
                                    title="杭州"
                                    xAxis={regionChartsXAxis('杭州')}
                                    dataSource={regionChartsDataSource('杭州')}
                                />,
                            )}
                            {authViewWithComponent('广州')(
                                <RegionCharts
                                    chartsClassName="income-card-charts-content"
                                    loading={solarLoading}
                                    title="广州"
                                    xAxis={regionChartsXAxis('广州')}
                                    dataSource={regionChartsDataSource('广州')}
                                />,
                            )}
                        </div>
                        <div className="income-card-charts-wrap">
                            {authViewWithComponent('深圳')(
                                <RegionCharts
                                    chartsClassName="income-card-charts-content"
                                    loading={solarLoading}
                                    title="深圳"
                                    xAxis={regionChartsXAxis('深圳')}
                                    dataSource={regionChartsDataSource('深圳')}
                                />,
                            )}
                            {authViewWithComponent('成都')(
                                orgId === 64 && (
                                    <RegionCharts
                                        chartsClassName="income-card-charts-content"
                                        loading={solarLoading}
                                        title="成都"
                                        xAxis={regionChartsXAxis('成都')}
                                        dataSource={regionChartsDataSource(
                                            '成都',
                                        )}
                                    />
                                ),
                            )}
                        </div>
                    </>
                )}
            </BoardCard>
            {!isSaleAndGroup && (
                <BoardCard
                    title="业绩对比"
                    filters={
                        <div className="board-compare-filters">
                            <div>
                                <div className="compare-filters-selector-wrap">
                                    <Selector
                                        label="选择对比类型"
                                        value={compareType}
                                        options={[
                                            { title: '同比', value: 1 },
                                            { title: '环比', value: 2 },
                                        ]}
                                        onChange={selected => {
                                            if (typeof selected === 'number') {
                                                setCompareType(selected);
                                            }
                                        }}
                                    />
                                    <Selector
                                        label="选择时间维度"
                                        value={timeDim}
                                        options={[
                                            { title: '按天', value: 1 },
                                            { title: '按月', value: 2 },
                                        ]}
                                        onChange={selected => {
                                            if (typeof selected === 'number') {
                                                setTimeDim(selected);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="compare-filters-date">
                                    <div>请选择当前时间：</div>
                                    <SelectDatePicker
                                        disabledDate={current => {
                                            if (!current) return false;

                                            if (!currentTimeEnd) {
                                                return (
                                                    current >
                                                    moment().endOf('day')
                                                );
                                            }

                                            const startTime =
                                                timeDim === 1
                                                    ? moment(
                                                          currentTimeEnd,
                                                      ).subtract(30, 'days')
                                                    : moment(
                                                          currentTimeEnd,
                                                      ).subtract(12, 'month');
                                            return (
                                                current >
                                                    moment(currentTimeEnd) ||
                                                current < startTime
                                            );
                                        }}
                                        value={
                                            currentTimeBegin
                                                ? moment(currentTimeBegin)
                                                : null
                                        }
                                        mode={timeDim === 1 ? 'date' : 'month'}
                                        placeholder="开始时间"
                                        onChange={(dates, dateString) => {
                                            setCurrentTimeBegin(dateString);
                                        }}
                                    />

                                    <div className="compare-filters-date-link">
                                        至
                                    </div>
                                    <SelectDatePicker
                                        disabledDate={current => {
                                            if (!current) return false;

                                            const today = moment().endOf('day');

                                            if (!currentTimeBegin) {
                                                return current > today;
                                            }

                                            const startTime = moment(
                                                currentTimeBegin,
                                            );

                                            const endTime =
                                                timeDim === 1
                                                    ? moment(
                                                          currentTimeBegin,
                                                      ).add(30, 'days')
                                                    : moment(
                                                          currentTimeBegin,
                                                      ).add(12, 'month');

                                            return (
                                                current >
                                                    (today < endTime
                                                        ? today
                                                        : endTime) ||
                                                current < startTime
                                            );
                                        }}
                                        value={
                                            currentTimeEnd
                                                ? moment(currentTimeEnd)
                                                : null
                                        }
                                        mode={timeDim === 1 ? 'date' : 'month'}
                                        placeholder="结束时间"
                                        onChange={(dates, dateString) => {
                                            setCurrentTimeEnd(dateString);
                                        }}
                                    />
                                </div>
                                <div className="compare-filters-date">
                                    <div>请选择对比时间：</div>
                                    <SelectDatePicker
                                        disabledDate={current => {
                                            if (!current) return false;

                                            if (!compareTimeEnd) {
                                                return (
                                                    current >
                                                    moment().endOf('day')
                                                );
                                            }

                                            const startTime =
                                                timeDim === 1
                                                    ? moment(
                                                          compareTimeEnd,
                                                      ).subtract(30, 'days')
                                                    : moment(
                                                          compareTimeEnd,
                                                      ).subtract(12, 'month');
                                            return (
                                                current >
                                                    moment(compareTimeEnd) ||
                                                current < startTime
                                            );
                                        }}
                                        value={
                                            compareTimeBegin
                                                ? moment(compareTimeBegin)
                                                : null
                                        }
                                        mode={timeDim === 1 ? 'date' : 'month'}
                                        placeholder="开始时间"
                                        onChange={(dates, dateString) => {
                                            setCompareTimeBegin(dateString);
                                        }}
                                    />
                                    <div className="compare-filters-date-link">
                                        至
                                    </div>
                                    <SelectDatePicker
                                        disabledDate={current => {
                                            if (!current) return false;

                                            const today = moment().endOf('day');

                                            if (!compareTimeBegin) {
                                                return current > today;
                                            }

                                            const startTime = moment(
                                                compareTimeBegin,
                                            );

                                            const endTime =
                                                timeDim === 1
                                                    ? moment(
                                                          compareTimeBegin,
                                                      ).add(30, 'days')
                                                    : moment(
                                                          compareTimeBegin,
                                                      ).add(12, 'month');

                                            return (
                                                current >
                                                    (today < endTime
                                                        ? today
                                                        : endTime) ||
                                                current < startTime
                                            );
                                        }}
                                        value={
                                            compareTimeEnd
                                                ? moment(compareTimeEnd)
                                                : null
                                        }
                                        mode={timeDim === 1 ? 'date' : 'month'}
                                        placeholder="结束时间"
                                        onChange={(dates, dateString) => {
                                            setCompareTimeEnd(dateString);
                                        }}
                                    />
                                </div>
                                <Selector
                                    label="区域"
                                    options={RegionSelectOptions.filter(
                                        option => authView(option.value),
                                    )}
                                    value={region}
                                    onChange={selected => {
                                        if (typeof selected === 'string') {
                                            setRegion(selected);
                                        }
                                    }}
                                />
                            </div>
                            <div>
                                <Button
                                    className="compare-filters-button-generate"
                                    onClick={() => {
                                        if (
                                            !currentTimeBegin ||
                                            !currentTimeEnd
                                        ) {
                                            message.error('请选择当前时间');
                                            return;
                                        }
                                        if (
                                            !compareTimeBegin ||
                                            !compareTimeEnd
                                        ) {
                                            message.error('请选择对比时间');
                                            return;
                                        }
                                        compareGenerate();
                                    }}
                                    loading={compareLoading}
                                >
                                    开始生成图表
                                </Button>
                                <div className="compare-filters-button-wrap">
                                    <Button
                                        className="board-button"
                                        onClick={handleCompareReset}
                                    >
                                        重置
                                    </Button>
                                    <Button
                                        className="board-button"
                                        loading={exportIncomeCompareLoading}
                                        onClick={handleExportIncomeCompare}
                                    >
                                        导出
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                >
                    {compareData ? (
                        <>
                            <div className="income-card-charts-wrap">
                                <CompareCharts
                                    chartsClassName="income-card-charts-content"
                                    loading={compareLoading}
                                    title="对比趋势图"
                                    xAxis={compareChartsXAxis}
                                    dataSource={[
                                        {
                                            label: '当前时间业绩',
                                            data: compareData?.current_income?.map(
                                                (item: any) => {
                                                    return Math.round(
                                                        item.sum_income_money,
                                                    );
                                                },
                                            ),
                                        },
                                        {
                                            label: '对比时间业绩',
                                            data: compareData?.compare_income?.map(
                                                (item: any) => {
                                                    return Math.round(
                                                        item.sum_income_money,
                                                    );
                                                },
                                            ),
                                        },
                                        {
                                            label: '当前时间趋势线',
                                            data: compareData?.current_income?.map(
                                                (item: any) => {
                                                    return Math.round(
                                                        item.sum_income_money,
                                                    );
                                                },
                                            ),
                                        },
                                        {
                                            label: '对比时间趋势线',
                                            data: compareData?.compare_income?.map(
                                                (item: any) => {
                                                    return Math.round(
                                                        item.sum_income_money,
                                                    );
                                                },
                                            ),
                                        },
                                    ]}
                                />
                            </div>

                            <TableCard
                                loading={compareLoading}
                                columns={compareColumns}
                                dataSource={compareDataSource}
                                style={{
                                    boxShadow: 'none',
                                    padding: 0,
                                    marginTop: 20,
                                }}
                                scroll={{ x: 'max-content' }}
                            />
                        </>
                    ) : (
                        <Empty
                            style={{ margin: '50px 0' }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={'暂无内容，请生成图表后查看'}
                        />
                    )}
                </BoardCard>
            )}
        </div>,
    );
};

export default CompositeIncomePage;
