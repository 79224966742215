import { makeAutoObservable } from 'mobx';
import { createDefaultInstanceGetter } from '../utils';

export class Counter {
    public times = 0;

    constructor() {
        makeAutoObservable(this);
    }

    public increaseTime() {
        this.times += 1;
    }

    public decreaseTime() {
        this.times -= 1;
    }
}

export const getDefaultCounter = createDefaultInstanceGetter(Counter);
