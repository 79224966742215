import React, { useState } from 'react';

import { CustomerListStore } from '../store';
import { Modal, Form } from 'antd';
import './m-transform-sea.scss';
import _ from 'lodash';
import { WidgetCommonObject } from '@/components/common/form/widgets';
import { runInAction } from 'mobx';
import { MOpNameDisplayContainer } from '@/components';
import { observer } from 'mobx-react-lite';
import { tryRefresh } from '@/utils';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const ActionMTransoformSea: React.FC<{
    customerList: CustomerListStore;
}> = observer(({ customerList }) => {
    const { defaultMSelect } = customerList;
    const { mSeletedData } = defaultMSelect;

    const [highSea, setHighSea] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [requiredDetected, setRequiredDetected] = useState(false);
    const inputHelps: any = requiredDetected
        ? {
              validateStatus: 'error',
              help: '新公海池必填',
          }
        : {};

    if (mSeletedData === null || mSeletedData.length === 0) {
        return null;
    }
    const visible = customerList.actionType === 'm-transform-sea';

    const { defaultMeta: listMeta } = customerList;
    const pageSize = 100;

    // 把high_sea_id的constraint抽出来
    const highSeaCol = _.find(
        listMeta.objectMeta,
        item => item.fieldName === 'high_sea_id',
    );
    if (!highSeaCol) {
        return <span>high_sea_id, meta info not found</span>;
    }
    const constraint = highSeaCol.constraint;
    if (!constraint) {
        return <span>high_sea_id, constraint info not found</span>;
    }
    return (
        <Modal
            visible={visible}
            title={'转公海池'}
            onCancel={() => {
                customerList.resetAction();
                setHighSea(null);
            }}
            onOk={async () => {
                if (highSea === null) {
                    return setRequiredDetected(true);
                }
                setLoading(true);
                const ok = await customerList.mTransformSea(highSea);
                setLoading(false);
                if (ok) {
                    setHighSea(null);
                    runInAction(() => {
                        customerList.resetAction();
                        defaultMSelect.removeAllSelectedData();
                        tryRefresh(['customer']);
                    });
                }
            }}
            confirmLoading={loading}
        >
            <h4>
                转移选择的{mSeletedData.length}个客户到新公海池。
                <MOpNameDisplayContainer
                    defaultStore={customerList}
                    nameParam="customer_name"
                />
            </h4>
            <div className="customer-m-transfer-sea">
                <div className="form-wrapper">
                    <Form className="form" layout={'horizontal'}>
                        <Form.Item
                            {...inputHelps}
                            {...formItemLayout}
                            required
                            label={<span className="label">新公海池</span>}
                        >
                            <WidgetCommonObject
                                k={'high_sea_id'}
                                value={highSea}
                                onChange={val => {
                                    setHighSea(val);
                                    setRequiredDetected(false);
                                }}
                                options={{
                                    placeholder: '请选择新公海池',
                                    constraint,
                                    listMeta,
                                    pageSize,
                                    dataSource: {
                                        api:
                                            '/crm/customer/transferCustomerHighSeaList',
                                        appendQuery: {
                                            bizType: mSeletedData[0].biz_type,
                                        },
                                    },
                                }}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    );
});
