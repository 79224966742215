import {
    TPaginationStoreInstance,
    PaginationStore,
} from '../../../../stores/utils/index';
import moment from 'moment';
import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import { defaultAxios } from '@/utils';
import { Modal } from 'antd';
import React from 'react';
import { createDefaultInstanceGetterA } from '@/stores/utilsA';
export interface IChannelGatherAnaData {
    leadChannel?: string; // 线索渠道
    originLeadCount?: number; // 原始线索数量
    noCooperationCount?: number; // 未合作客户
    cooperatedCount?: number; // 合作过客户
    followedLeadCount?: number; // 已跟进线索量
    leadFollowUpRate?: number; // 线索跟进率
    effectiveLeadCount?: number; // 有效线索量
    effectiveLeadRate?: number; // 有效线索占比
    transformOptLeadCount?: number; // 转商机线索量
    transformOptLeadRate?: number; // 转商机的线索占比
    winLeadCount?: number; // 赢单线索量
    winLeadRate?: number; // 线索成交占比
}
export type TChannelGatherAnaReturnType = NetResponse<{
    data: IChannelGatherAnaData[];
    msg: string;
    code: number;
}>;
export class ChannelGatherStore {
    constructor() {
        makeAutoObservable(this);
    }
    public channelGatherAnaData: IChannelGatherAnaData[] = [];
    // public defaultChannelGatherAnaPagination: TPaginationStoreInstance = new PaginationStore();
    public getChannelGatherData: (
        searchData: any,
    ) => Promise<TChannelGatherAnaReturnType> = async searchData => {
        const finalSearchData = {
            ...searchData,
            start: moment(searchData.beginDate).valueOf(),
            end: moment(searchData.endDate).valueOf(),
            // ...this.defaultChannelGatherAnaPagination.paginationArgs,
        };
        const url = '/bff/api/rest/ana-lead/channel-gather/fetch';
        const [d, e] = await defaultAxios.get(url, finalSearchData);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data)) {
            this.channelGatherAnaData = data;
            // this.defaultChannelGatherAnaPagination.amount = 1;
        }
        // if (data && Array.isArray(data?.data)) {
        //     this.channelGatherAnaData = data?.data;
        //     this.defaultChannelGatherAnaPagination.amount = data?.totalSize || 0;
        // }
        const error = e as Error | null;
        return [data, error];
    };
    public exportChannelGatherData = async (searchData: any) => {
        if (this.channelGatherAnaData.length > 50000) {
            Modal.error({
                title: '导出提示',
                content: (
                    <p>
                        最多支持导出50000条（当前条件共
                        {this.channelGatherAnaData.length}
                        条，请更换筛选条件再导出
                    </p>
                ),
            });
            return;
        }
        const url = `${
            window.location.origin
        }/bff/api/rest/ana-lead/channel-gather/export?start=${moment(
            searchData.beginDate,
        ).valueOf()}&end=${moment(searchData.endDate).valueOf()}`;
        window.open(url, '_blank');
        return;
    };
}

export const getDefaultChannelGatherStore = createDefaultInstanceGetterA(
    ChannelGatherStore,
);
