import Input from 'antd/lib/input';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import React, { useEffect } from 'react';
import { getCommonStatus, ICommonProps } from './common';
import _ from 'lodash';
import { loadingWrapper } from '@/components/common/block-loading';

const InputTextArea = Input.TextArea;

export type InputTextAreaValueType = string;
export interface IInputTextAreaOptions extends TextAreaProps {}

export interface IWidgetInputTextAreaProps<T = any> extends ICommonProps<T> {
    value: InputTextAreaValueType;
    options: IInputTextAreaOptions;
    onChange: (val: InputTextAreaValueType) => void;
}

export const WidgetInputTextArea: React.FC<IWidgetInputTextAreaProps> = ({
    value,
    options,
    onChange,
    status,
    onInteract = key => void 0,
    data,
    statusExtraData,
}) => {
    const { loading, disabled } = getCommonStatus(
        status,
        data,
        statusExtraData,
    );
    const wrapper = loadingWrapper(loading || false);

    if (disabled) {
        options.disabled = true;
    } else {
        options.disabled = false;
    }

    return wrapper(
        <InputTextArea
            {...options}
            value={value}
            onFocus={e => {
                onInteract('focus');
                onInteract('remove-validate-status');
            }}
            onBlur={e => {
                onInteract('blur');
                onInteract('validate-instantly');
            }}
            onChange={e => {
                if (e.target && !_.isNil(e.target.value)) {
                    onChange(e.target.value);
                }
            }}
        />,
    );
};
