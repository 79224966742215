import { RefObject } from 'react';
import { useMemoizedFn, useIsomorphicLayoutEffect } from 'ahooks';

export default function useResizeEffect<T extends HTMLElement>(
    effect: (target: T) => void,
    targetRef: RefObject<T>,
) {
    const fn = useMemoizedFn(effect);
    useIsomorphicLayoutEffect(() => {
        const target = targetRef.current;
        if (!target) return;
        if (window.ResizeObserver) {
            const observer = new ResizeObserver(() => {
                fn(target);
            });
            observer.observe(target);
            return () => {
                observer.disconnect();
            };
        }
        fn(target);
    }, [fn, targetRef]);
}

export { useResizeEffect };
