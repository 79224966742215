import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'antd';
import { TreeSelectProps } from 'antd/lib/tree-select';
import { TreeNodeValue } from 'antd/lib/tree-select/interface';

export interface ITeamSelectorProps<T extends TreeNodeValue>
    extends TreeSelectProps<T> {}
const TeamSelector: <T extends TreeNodeValue>(
    props: ITeamSelectorProps<T>,
) => React.ReactElement<ITeamSelectorProps<T>> = props => {
    const treeRef = React.createRef<any>();
    const finalProps: typeof props = {
        allowClear: true,
        treeNodeFilterProp: 'title',
        showSearch: true,
        searchPlaceholder: '请选择部门',
        treeDataSimpleMode: true,
        dropdownStyle: { maxHeight: 400, overflow: 'auto' },
        treeCheckable: true,
        multiple: true,
        ...props,
    };
    // useEffect(() => {
    //     const dom = document.getElementsByClassName('maimai-crm-tree-selector')[0].getElementsByTagName('input')[0];
    //     dom.onfocus = (e: any) => {
    //         if (finalProps.onFocus) {
    //             finalProps.onFocus(e);
    //             console.log('focus');
    //         }
    //     }
    //     dom.onblur = (e: any) => {
    //         if (finalProps.onBlur) {
    //             finalProps.onBlur(e)
    //             console.log('blur');
    //         }
    //     }
    //     return () => {
    //         dom.onblur = null;
    //         dom.onfocus = null;
    //     };
    // }, []);
    const onChangeHandle = (value: any, label: any, extra: any) => {
        if (props.onChange) {
            //  自动focus
            props.onChange(value, label, extra);
            treeRef.current.focus();
        }
    };
    return (
        <TreeSelect
            className="maimai-crm-tree-selector"
            ref={treeRef}
            {...finalProps}
            onChange={onChangeHandle}
        />
    );
};

export default TeamSelector;
