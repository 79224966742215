import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    AntButton,
    loadingWrapperOver,
    loadingWrapper,
} from '@/components';
import { Icon, Tooltip, message, Select } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, useRefresh } from '@/utils';
import { observer } from 'mobx-react-lite';
import DemoTable from './components/table';
import { runInAction } from 'mobx';
import {
    defaultAuthDeco,
    dFilterHOC,
    useDefaultDFilterSnippets,
} from '@/containers/hoc';
import './index.scss';
import { MOpResultDisplayContainer } from '@/components';
import FormDrawer from './components/form-drawer';
import classname from 'classname';
import { useFastFilterByKey } from '@/stores/utils';
import { ActionExport } from '@/pages/sales-follow-up/activation-record/list/components/export';

const DemoList: React.FC<RouteComponentProps & {
    type: string;
    dfilters?: any;
}> = ({ type = 'all', dfilters }) => {
    const defaultTable = dfilters?.fastFilters?.all?.[0]?.table;
    const defaultTargetType = dfilters?.fastFilters?.all?.[0]?.target_type;
    const store = useContext(StoreContext);
    const [table, setTable] = useState(defaultTable);

    const [targetType, setTargetType] = useState(defaultTargetType);
    const [defaultStore] = useState(
        () => new store.AchievementManaStore(defaultTable),
    );
    console.log('table38', defaultStore);

    const { targetYear } = defaultStore;
    useEffect(() => {
        defaultStore.setDefaultMeta(table, targetType);
    }, [table, targetType, targetYear]);

    const userStore = store.getAuthStore();

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        defaultPerm,
        defaultFilter,
        defaultMSelect,
        defaultFastFilters,
        condListbyFilterData,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    useRefresh(['achievement'], () => {
        defaultStore.fetch();
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    const [metaLoading, setMetaLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady || metaLoading) {
            return;
        }
        setLoading(true);
        defaultStore.fetch().then(() => setLoading(false));
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
        metaLoading,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            if (defaultMeta.tableId || !table) {
                // 如果已经有tableId了，说明已经inited了，不要重复执行
                // 或者没有table，则可能执行无效
                return;
            }
            // target_year
            // 默认filter
            defaultMeta.setTableId(table);
            defaultStore.setData([]);
            defaultStore.setDefaultFilters([
                {
                    fieldId: 'target_type',
                    filterValue: defaultStore.targetType,
                    operateType: '=',
                    parser: 'string',
                },
                {
                    fieldId: 'target_year',
                    filterValue: defaultStore.targetYear,
                    operateType: '=',
                    parser: 'string',
                },
            ]);
            defaultMeta.setOpUsername(
                userStore.userInfo.userId + '-achievement-' + table,
            );
            setMetaLoading(true);
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setMetaLoading(false);
                    setPreRequiredReady(true);
                })
                .catch(() => {
                    setMetaLoading(false);
                });
        }
    }, [userStore.userInfo, defaultMeta, defaultPerm]);

    useEffect(() => {
        defaultFastFilters.setFilters(dfilters);
    }, [dfilters]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta, defaultFilter, defaultMeta.inited]);

    useFastFilterByKey(
        defaultStore.defaultFastFilters,
        'quanguo',
        useCallback(() => true, []),
    );

    useEffect(() => {
        runInAction(() => {
            defaultFastFilters.setType(type);
            defaultStore.setListType(type);
        });
    }, [type]);

    const { title, fastFilters } = useDefaultDFilterSnippets(defaultStore, {
        onFFSelect: next => {
            if (next?.table && next?.target_type) {
                setTable(next?.table);
                setTargetType(next?.target_type);
            }
        },
    });

    const [isBeforeFirstLoaded, setIsBeforeFirstLoaded] = useState(true);
    const [initedData] = useState(defaultStore.data);
    useEffect(() => {
        if (defaultStore.data !== initedData) {
            setIsBeforeFirstLoaded(false);
        }
    }, [defaultStore.data]);

    const finalLoading = isBeforeFirstLoaded || !preRequiredReady;

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = (
        <>
            <FormDrawer
                key={'form-' + table + '-' + targetType + '-' + targetYear}
                defaultStore={defaultStore}
                preRequiredReady={preRequiredReady}
            />
            <ActionExport defaultStore={defaultStore} />
        </>
    );

    const globalOps = (
        <>
            {defaultPerm.getPermByTypeAndAction(type)('import').visible && (
                <AntButton
                    onClick={() => {
                        runInAction(() => {
                            defaultStore.setAction('create');
                            defaultStore.startNewData();
                        });
                    }}
                    type="primary"
                    size="large"
                >
                    Excel导入
                </AntButton>
            )}
            {defaultPerm.getPermByTypeAndAction(type)('export').visible && (
                <AntButton
                    onClick={() => {
                        defaultStore.setAction('export');
                    }}
                    size="large"
                >
                    导出
                </AntButton>
            )}
        </>
    );
    const multipleOps = (
        <div
            className={classname({
                'mselected-ops': true,
                'multiple-mode': defaultMSelect.isMSelectionMode,
                'normal-mode': !defaultMSelect.isMSelectionMode,
            })}
        >
            <Tooltip title="取消多选">
                <span
                    onClick={() => {
                        defaultMSelect.setMSelectedData([]);
                    }}
                    style={{
                        padding: 5,
                        cursor: 'pointer',
                    }}
                >
                    <Icon type="close" />
                </span>
            </Tooltip>
            <div>
                <span>已选{defaultMSelect.mSeletedData.length}条：</span>
            </div>
            {defaultPerm.getPermByTypeAndAction(type)('delete').visible && (
                <AntButton
                    onClick={() => {
                        defaultStore.setAction('m-delete');
                    }}
                    size="large"
                    checkBody={{
                        tableCode: 'customer',
                        body: {
                            dataAuthCode: 'DEL',
                            ids: defaultMSelect.mSeletedData
                                .map(item => item.id)
                                .filter(Boolean),
                        },
                    }}
                >
                    删除
                </AntButton>
            )}
        </div>
    );

    const dataTable = (
        <DemoTable
            key={'table-' + table + '-' + targetType + '-' + targetYear}
            defaultStore={defaultStore}
            loading={metaLoading || loading}
        />
    );

    const layoutComs = {
        popups,
        title: (
            <span>
                年度&nbsp;&nbsp;&nbsp;
                <Select<string>
                    style={{ minWidth: 80 }}
                    value={defaultStore.targetYear}
                    onChange={v => {
                        defaultStore.setTargetYear(v);
                    }}
                >
                    <Select.Option value={'2027'}>2027</Select.Option>
                    <Select.Option value={'2026'}>2026</Select.Option>
                    <Select.Option value={'2025'}>2025</Select.Option>
                    <Select.Option value={'2024'}>2024</Select.Option>
                    <Select.Option value={'2023'}>2023</Select.Option>
                    <Select.Option value={'2022'}>2022</Select.Option>
                    <Select.Option value={'2021'}>2021</Select.Option>
                    <Select.Option value={'2020'}>2020</Select.Option>
                    <Select.Option value={'2019'}>2019</Select.Option>
                </Select>
            </span>
        ),
        globalOps,
        filters: filtersDisplay,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay: fastFilters,
    };

    return loadingWrapperOver(finalLoading)(
        <LayoutStandardCrud className="page-customer-list" {...layoutComs} />,
    );
};

const FinalDemoList = dFilterHOC(observer(DemoList), 'achievement_mana');
export default FinalDemoList;
