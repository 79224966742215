import React, { useState, useEffect } from 'react';
import { Icon, Popover, Spin, message } from 'antd';
import './index.scss';
import { tryNewCall } from '@/containers/hoc/call-center';
import TelSvg from '@/inline-resource/tel.svg';
import { defaultAxios, decodePhone as phoneDecode } from '@/utils';
import Ccpopover from '../cc-popover';

// export const CCBtn: React.FC<{ phoneNum: string }> = ({ phoneNum }) => {
//     return (
//         <span className="cc-btn">
//             {phoneNum}
//             &nbsp;
//             <Icon
//                 type="eye"
//                 style={{
//                     fontSize: '12px',
//                     color: '#0052ff',
//                     marginRight: '2px',
//                 }}
//             />
//             <img
//                 onClick={() => tryNewCall(phoneNum)}
//                 style={{ height: 11, width: 11 }}
//                 src={TelSvg}
//             />
//         </span>
//     );
// };

export const CCBtn: React.FC<{
    phoneNum: string;
    callConfig?: {
        customerName?: string;
        customerId?: string | number;
        showFollowLog?: boolean;
        contactsId?: string | number;
        leadId?: string | number;
        detail?: any;
    };
    isHidden?: boolean;
    sceneCode: string;
    outerHiddenNumber?: string;
}> = ({
    phoneNum,
    callConfig = {},
    isHidden = true,
    sceneCode,
    outerHiddenNumber,
}) => {
    const [hiddenPhone, setHiddenPhone] = useState(false);
    const [phoneNumberInner, setPhoneNumberInner] = useState('');
    const [decodeLoading, setDecodeLoading] = useState(false);
    const [popoverVisable, setPopoverVisable] = useState(false);

    const frontEncode = (phoneNumber: string | number) => {
        let phoneStr = '';
        if (typeof phoneNumber === 'number') {
            phoneStr = String(phoneNumber);
        } else {
            phoneStr = phoneNumber;
        }
        return outerHiddenNumber
            ? outerHiddenNumber
            : `${phoneStr.slice(0, 3)}${new Array(8).fill('*').join('')}`;
    };
    const decodePhone = async (isCall?: boolean) => {
        if (!(isHidden && hiddenPhone)) {
            return phoneNumberInner;
        }
        const url = '/crm/crypto/phone/decrypt';
        setDecodeLoading(true);
        const [d, e] = await defaultAxios.post(url, {
            phone: phoneNum,
            scene: sceneCode
                ? isCall
                    ? `${sceneCode}_call`
                    : `${sceneCode}_view`
                : undefined,
        });
        setDecodeLoading(false);
        const data = phoneDecode(d?.data);
        const error = e as Error | null;
        if (data) {
            if (!isCall) {
                setPhoneNumberInner(data);
                setHiddenPhone(false);
            }
            return data;
        } else {
            message.error('线路问题请联系管理员');
            return false;
        }
    };
    useEffect(() => {
        setHiddenPhone(isHidden);
        if (isHidden) {
            setPhoneNumberInner(frontEncode(phoneNum));
        } else {
            setPhoneNumberInner(phoneNum);
        }
    }, []);
    useEffect(() => {
        setHiddenPhone(isHidden);
        if (isHidden) {
            setPhoneNumberInner(frontEncode(phoneNum));
        } else {
            setPhoneNumberInner(phoneNum);
        }
    }, [phoneNum]);

    useEffect(() => {
        setHiddenPhone(isHidden);
    }, [isHidden]);

    const onVisibleChange = (visable: any) => {
        setPopoverVisable(visable);
    };

    return phoneNum ? (
        <Spin
            spinning={decodeLoading}
            indicator={<Icon type="loading" style={{ fontSize: 14 }} spin />}
        >
            <span
                className="cc-btn"
                onClick={() => {
                    if (isHidden) {
                        return;
                    }
                    tryNewCall(phoneNum, true, {
                        ...callConfig,
                        sceneCode,
                    });
                }}
            >
                <span style={{ minWidth: '87px' }}>{phoneNumberInner}</span>
                &nbsp;
                {isHidden && hiddenPhone ? (
                    <Icon
                        onClick={() => {
                            decodePhone(false);
                        }}
                        type="eye"
                        style={{
                            fontSize: '12px',
                            color: '#0052ff',
                            marginRight: '2px',
                        }}
                    />
                ) : null}
                <Popover
                    getPopupContainer={() => {
                        return document.body as HTMLElement;
                    }}
                    onVisibleChange={onVisibleChange}
                    placement="right"
                    content={
                        <Ccpopover
                            phoneNum={popoverVisable ? phoneNum : undefined}
                            whetherShow={false}
                        />
                    }
                >
                    <img
                        onClick={async () => {
                            if (hiddenPhone) {
                                const res = await decodePhone(true);
                                if (res) {
                                    tryNewCall(res, true, {
                                        ...callConfig,
                                        sceneCode,
                                    });
                                }
                            } else {
                                tryNewCall(phoneNumberInner, true, {
                                    ...callConfig,
                                    sceneCode,
                                });
                            }
                        }}
                        style={{ height: 11, width: 11 }}
                        src={TelSvg}
                    />
                </Popover>
            </span>
        </Spin>
    ) : null;
};
