import React, { useEffect, memo, useState } from 'react';

import { ContractProductListStore } from '../store';
import { Modal, Form, Alert, Input, message } from 'antd';
import _ from 'lodash';
import { WidgetSelect, WidgetRadio } from '@/components/common/form/widgets';
import TextArea from 'antd/lib/input/TextArea';
import './exe.scss';
import { runInAction } from 'mobx';
import { MOpNameDisplayContainer } from '@/components';
import { observer } from 'mobx-react-lite';
import { tryRefresh } from '@/utils';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

// executeType	integer
// 非必须
// 执行类型，1:开通。2:续费。3:升级
// admMobile	string
// 非必须
// 管理员手机号
// toEmail	string
// 非必须
// 收件邮箱
// serviceMonth	integer
// 非必须
// 服务周期（月）
// remark	string
// 非必须
// 备注

export const ActionExe: React.FC<{
    defaultStore: ContractProductListStore;
}> = observer(({ defaultStore }) => {
    const [loading, setLoading] = useState(false);
    const [executeType, setExecuteType] = useState('');
    const [remark, setRemark] = useState('');
    const [admMobile, setAdmMobile] = useState('');
    const [toEmail, setToEmail] = useState('');
    const [serviceMonth, setServiceMonth] = useState('');
    const resetForm = () => {
        setExecuteType('');
        setRemark('');
        setAdmMobile('');
        setToEmail('');
        setServiceMonth('');
    };

    const [requiredCheckDisplay, setRequiredCheckDisplay] = useState<{
        [key: string]: any;
    }>({});
    const getNextRequiredCheckDisplay = (keys: string[]) => {
        const next: { [key: string]: any } = { ...requiredCheckDisplay };
        for (const key of keys) {
            if (key === 'executeType') {
                if (_.trim(executeType) === '') {
                    next.executeType = {
                        validateStatus: 'error',
                        help: '执行类型必填',
                    };
                } else {
                    delete next.executeType;
                }
            } else if (key === 'admMobile') {
                if (_.trim(admMobile) === '') {
                    next.admMobile = {
                        validateStatus: 'error',
                        help: '管理员手机号必填',
                    };
                } else {
                    delete next.admMobile;
                }
            } else if (key === 'toEmail') {
                if (_.trim(toEmail) === '') {
                    next.toEmail = {
                        validateStatus: 'error',
                        help: '收件邮箱必填',
                    };
                } else {
                    delete next.toEmail;
                }
            } else if (key === 'serviceMonth') {
                const isEmpty = _.trim(serviceMonth) === '';
                const isNumStr =
                    '' + Number(serviceMonth) === _.trim(serviceMonth);
                if (isEmpty) {
                    next.serviceMonth = {
                        validateStatus: 'error',
                        help: '服务周期必填',
                    };
                } else if (!isNumStr) {
                    next.serviceMonth = {
                        validateStatus: 'error',
                        help: '服务周期应为数字',
                    };
                } else {
                    delete next.serviceMonth;
                }
            }
        }
        return next;
    };
    const removeRequiredCheckDisplay = (key: string) => {
        const next: { [key: string]: any } = { ...requiredCheckDisplay };
        delete next[key];
        setRequiredCheckDisplay(next);
    };
    // executeType
    // remark
    // admMobile
    // toEmail
    // serviceMonth

    const { mutatingData } = defaultStore;
    if (mutatingData === null) {
        return null;
    }

    let errorEle = null;

    const visible = defaultStore.actionType === 'execute';

    const executeTypeOptions = [
        {
            constraintLabel: '开通',
            constraintValue: '1',
        },
        {
            constraintLabel: '续费',
            constraintValue: '2',
        },
        {
            constraintLabel: '升级',
            constraintValue: '3',
        },
    ];

    return (
        <Modal
            visible={visible}
            title={'开通产品'}
            onCancel={() => {
                resetForm();
                defaultStore.resetAction();
            }}
            onOk={async () => {
                const next = getNextRequiredCheckDisplay([
                    'executeType',
                    'remark',
                    'admMobile',
                    'toEmail',
                    'serviceMonth',
                ]);
                setRequiredCheckDisplay(next);
                if (!_.isEmpty(next)) {
                    return message.error('存在未校验的字段');
                }

                setLoading(true);
                const ok = await defaultStore.execute({
                    executeType,
                    remark,
                    admMobile,
                    toEmail,
                    serviceMonth,
                });
                setLoading(false);
                if (ok) {
                    resetForm();
                    runInAction(() => {
                        defaultStore.resetAction();
                        defaultStore.setMutatingData(null);
                        tryRefresh(['contract_product']);
                    });
                }
            }}
        >
            {errorEle ? (
                errorEle
            ) : (
                <>
                    <div
                        className={
                            'crm-customer-drop ' + (loading ? 'loading' : '')
                        }
                    >
                        <h4>
                            客户：
                            {mutatingData?.customer_name || '--'}
                        </h4>
                        <h4>
                            产品：
                            {mutatingData?.product_id?.relatedObj
                                ?.product_name || '--'}
                        </h4>
                        <div className="form-wrapper">
                            <Form className="form" layout={'horizontal'}>
                                <Form.Item
                                    {...formItemLayout}
                                    {...(requiredCheckDisplay.executeType ||
                                        {})}
                                    required
                                    label={
                                        <span className="label">执行类型</span>
                                    }
                                >
                                    <WidgetRadio
                                        value={executeType + ''}
                                        options={{
                                            options: executeTypeOptions.map(
                                                (option: any) => ({
                                                    label:
                                                        option.constraintLabel,
                                                    value:
                                                        option.constraintValue,
                                                }),
                                            ),
                                        }}
                                        onChange={value => {
                                            setExecuteType(value);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...formItemLayout}
                                    {...(requiredCheckDisplay.admMobile || {})}
                                    required
                                    label={
                                        <span className="label">
                                            管理员手机号
                                        </span>
                                    }
                                >
                                    <Input
                                        placeholder="管理员手机号"
                                        value={admMobile}
                                        onChange={e => {
                                            const value = e?.target?.value;
                                            if (!_.isNil(value)) {
                                                setAdmMobile(value);
                                            }
                                        }}
                                        onFocus={() => {
                                            removeRequiredCheckDisplay(
                                                'admMobile',
                                            );
                                        }}
                                        onBlur={() => {
                                            setRequiredCheckDisplay(
                                                getNextRequiredCheckDisplay([
                                                    'admMobile',
                                                ]),
                                            );
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...formItemLayout}
                                    {...(requiredCheckDisplay.toEmail || {})}
                                    required
                                    label={
                                        <span className="label">收件邮箱</span>
                                    }
                                >
                                    <Input
                                        placeholder="收件邮箱"
                                        value={toEmail}
                                        onChange={e => {
                                            const value = e?.target?.value;
                                            if (!_.isNil(value)) {
                                                setToEmail(value);
                                            }
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...formItemLayout}
                                    {...(requiredCheckDisplay.serviceMonth ||
                                        {})}
                                    required
                                    label={
                                        <span className="label">
                                            服务周期（月）
                                        </span>
                                    }
                                >
                                    {/* serviceMonth
                                    setServiceMonth */}
                                    <Input
                                        placeholder="服务周期（月）"
                                        value={serviceMonth}
                                        onChange={e => {
                                            const value = e?.target?.value;
                                            if (!_.isNil(value)) {
                                                setServiceMonth(value);
                                            }
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...formItemLayout}
                                    label={<span className="label">备注</span>}
                                >
                                    <TextArea
                                        placeholder="备注信息（选填）"
                                        value={remark}
                                        onChange={e => {
                                            const value = e?.target?.value;
                                            if (!_.isNil(value)) {
                                                setRemark(value);
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
});
