import { makeAutoObservable, flow } from 'mobx';
import { createDefaultInstanceGetter } from '../utils';

export class CompatibilitySniffer {
    public isCompatible = false;
    public sniffing = true;

    constructor() {
        makeAutoObservable(this);
    }

    public check() {
        this.sniffing = true;
        const hasNativeProxyConstructor = !!window.Proxy;
        this.sniffing = false;
        this.isCompatible = hasNativeProxyConstructor;
    }
}

export const getDefaultCompatibilitySniffer = createDefaultInstanceGetter(
    CompatibilitySniffer,
);
