import React, {
    useEffect,
    memo,
    useState,
    useContext,
    useCallback,
} from 'react';

import { CustomerListStore } from '../store';
import { Modal, Form, Alert, message, Empty } from 'antd';
import _, { values } from 'lodash';
import {
    WidgetSelect,
    WidgetInput,
    WidgetInputSearchable,
} from '@/components/common/form/widgets';
import TextArea from 'antd/lib/input/TextArea';
import './m-drop.scss';
import { runInAction } from 'mobx';
import {
    MOpNameDisplayContainer,
    BlockLoading2,
    loadingWrapper,
} from '@/components';
import { observer } from 'mobx-react-lite';
import { tryRefresh, defaultAxios, delay, isNetSuccess } from '@/utils';
import { StoreContext } from '@/stores';
import { WidgetSearchInput } from '@/components/common/form/widgets/search-input';

const me = {
    type: 'obj',
    showFiledName: 'name',
    originalValue: 4,
    relatedObj: {
        birthday: 1608200200000,
        last_login_at: 1608119015000,
        rank_id: '3',
        gender: 1,
        related_area: '',
        employee_code: '',
        enterprise_wechat_account: '',
        join_at: 1608119015000,
        update_time: 1617974302000,
        update_user: 3,
        weibo: '',
        manager_id: 10002,
        is_del: 0,
        union_id: '608257532254748672',
        id: 4,
        hometown: '',
        create_time: 1608119015000,
        im: '',
        unique_key: '',
        position_name: '',
        telephone: '',
        related_industry: '',
        dim_depart: 6,
        entity_type: 1,
        phone: '18801110222',
        self_intro: '',
        nick_name: '',
        name: '邵明全',
        create_user: -1,
        related_business: '',
        hobby: '',
        status: 1,
    },
    showRelatedObj: {
        birthday: 1608200200000,
        last_login_at: 1608119015000,
        rank_id: '3',
        gender: 1,
        related_area: '',
        employee_code: '',
        enterprise_wechat_account: '',
        join_at: 1608119015000,
        update_time: 1617974302000,
        update_user: 3,
        weibo: '',
        manager_id: 10002,
        is_del: 0,
        union_id: '608257532254748672',
        id: 4,
        hometown: '',
        create_time: 1608119015000,
        im: '',
        unique_key: '',
        position_name: '',
        telephone: '',
        related_industry: '',
        dim_depart: 6,
        entity_type: 1,
        phone: '18801110222',
        self_intro: '',
        nick_name: '',
        name: '邵明全',
        create_user: -1,
        related_business: '',
        hobby: '',
        status: 1,
    },
};

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const checkCusName = _.debounce(
    (
        cusName: string,
        curId: string,
        startLoading: () => void,
        endLoading: () => void,
        counter: { count: number },
        cb: (ret: any) => void,
    ) => {
        (async () => {
            startLoading();
            counter.count++;
            const count = counter.count;
            const [d, e] = await defaultAxios.get(
                '/crm/customer/findCustomerMergeCheckInfo',
                {
                    customerName: cusName,
                    notStandardCustomerId: curId,
                },
            );
            if (counter.count > count) {
                return;
            }
            endLoading();
            if (!isNetSuccess(d, e)) {
                return;
            }
            const data = d?.data;
            if (!data) {
                return;
            }

            const { type, customerInfo, isCrossRegion } = data;
            const isPravite = customerInfo?.customerStatus === 1;
            const highSea =
                customerInfo?.extendParam?.relationObjectMap?.high_sea_id;

            if (type === 1) {
                // 已存在的标准客户
                const ownerId =
                    customerInfo?.extendParam?.relationObjectMap?.owner_id;
                cb({
                    isStandard: true,
                    hasCustomer: true,
                    isCrossRegion,
                    isPravite,
                    ownerId,
                    highSea,
                    customerInfo,
                });
            }

            if (type === 2) {
                // 不存在的标准客户
                cb({
                    isStandard: true,
                    hasCustomer: false,
                    isCrossRegion,
                    isPravite,
                    ownerId: null,
                    highSea,
                });
            }

            if (type === 3) {
                // 已存在的标准客户
                const ownerId =
                    customerInfo?.extendParam?.relationObjectMap?.owner_id;
                cb({
                    isStandard: false,
                    hasCustomer: true,
                    isCrossRegion,
                    isPravite,
                    ownerId,
                    highSea,
                    customerInfo,
                });
            }

            if (type === 4) {
                // 不存在的标准客户
                cb({
                    isStandard: false,
                    hasCustomer: false,
                    isCrossRegion,
                    isPravite,
                    ownerId: null,
                    highSea,
                    customerInfo,
                });
            }

            // 存在当前客户，客户所有人是我
            // cb({
            //     isStandard: true,
            //     hasCustomer: true,
            //     ownerId: me,
            // })
        })();
    },
    500,
);

export const ActionTransStandard: React.FC<{
    customerList: CustomerListStore;
    topCustomerList: CustomerListStore;
    setDrawerVis: (vis: boolean) => void;
}> = observer(({ customerList, topCustomerList, setDrawerVis }) => {
    const [loading, setLoading] = useState(false);
    const [checkLoading, setCheckLoading] = useState(false);
    const [checkStatus, setCheckStatus] = useState<any>(null);
    const [newCusName, setNewCusName] = useState({ v: '' });
    const [counter, setCounter] = useState({ count: 0 });
    const resetForm = () => {
        setNewCusName({ v: '' });
        setRequiredDetected(false);
        setCheckLoading(false);
        setCheckStatus(null);
    };

    const [requiredDetected, setRequiredDetected] = useState(false);
    const remarkHelps: any = requiredDetected
        ? {
              validateStatus: 'error',
              help: '标准客户名称',
          }
        : {};

    const { mutatingData } = customerList;
    const curId = mutatingData?.id;
    const visible = customerList.actionType === 'trans-standard';

    useEffect(() => {
        resetForm();
    }, [visible]);

    useEffect(() => {
        if (_.trim(newCusName.v).length <= 4 || !curId) {
            setCheckStatus(null);
            return;
        }
        const startLoading = () => setCheckLoading(true);
        const endLoading = () => setCheckLoading(false);
        checkCusName.cancel();
        checkCusName(
            newCusName.v,
            curId,
            startLoading,
            endLoading,
            counter,
            ret => {
                setCheckStatus(ret);
            },
        );
    }, [newCusName.v]);

    if (mutatingData === null || !curId) {
        return null;
    }

    let disabled = true;
    let alertEle = (
        <Alert
            type={'error'}
            message="查不到标准客户的工商注册信息，请确认名称是否正确。"
        />
    );
    if (checkStatus !== null && !!_.trim(newCusName.v)) {
        const {
            isStandard,
            hasCustomer,
            ownerId,
            isCrossRegion,
            highSea,
            isPravite,
        } = checkStatus;

        if (isStandard === true) {
            if (hasCustomer) {
                if (isPravite) {
                    // 211206 https://maimai.feishu.cn/wiki/wikcnpWrdd4ckSYXpRjmXRR1YBc
                    const ownerName = ownerId?.name;
                    disabled = false;
                    alertEle = (
                        <Alert
                            type={'error'}
                            message={
                                <span>
                                    标准客户已存在，且在
                                    <b>【{ownerName}】</b>
                                    的私海。转标准客户成功后，非标客户信息将全部合并到标准客户。
                                </span>
                            }
                        />
                    );
                } else {
                    // 211206 https://maimai.feishu.cn/wiki/wikcnpWrdd4ckSYXpRjmXRR1YBc
                    if (isCrossRegion) {
                        const highSeaDesc = highSea?.description;
                        alertEle = (
                            <Alert
                                type={'error'}
                                message={
                                    <span>
                                        标准客户目前归属在
                                        <b>【{highSeaDesc}】</b>
                                        ，您没有权限，请联系区域运营BP处理。
                                    </span>
                                }
                            />
                        );
                    } else {
                        const ownerName = ownerId?.name;
                        if (!!ownerName) {
                            disabled = false;
                            alertEle = (
                                <Alert
                                    type={'error'}
                                    message={
                                        <span>
                                            标准客户已存在，客户所有人为
                                            <b>【{ownerName}】</b>
                                            。转标准客户成功后，非标客户信息将全部合并到标准客户。
                                        </span>
                                    }
                                />
                            );
                        } else {
                            alertEle = (
                                <Alert
                                    type={'error'}
                                    message={
                                        <span>
                                            标准客户已存在，客户所有人为
                                            <b>【{ownerName}】</b>
                                            。转标准客户成功后，非标客户信息将全部合并到标准客户。
                                        </span>
                                    }
                                />
                            );
                        }
                    }
                }
            } else {
                if (isCrossRegion) {
                    // 211206 https://maimai.feishu.cn/wiki/wikcnpWrdd4ckSYXpRjmXRR1YBc
                    const highSeaDesc = highSea?.description;
                    alertEle = (
                        <Alert
                            type={'error'}
                            message={
                                <span>
                                    标准客户目前归属在
                                    <b>【{highSeaDesc}】</b>
                                    ，您没有权限，请联系区域运营BP处理。
                                </span>
                            }
                        />
                    );
                } else {
                    disabled = false;
                    alertEle = (
                        <Alert
                            message={
                                <span>
                                    标准客户系统不存在。转标准客户成功后，当前非标客户将升级为标准客户并保留所有非标客户的原有信息。
                                </span>
                            }
                        />
                    );
                }
            }
        }
    }

    return (
        <Modal
            visible={visible}
            title={'转标准客户'}
            onCancel={() => {
                resetForm();
                customerList.resetAction();
            }}
            confirmLoading={loading}
            okButtonProps={{
                disabled,
            }}
            okText={'转标准'}
            onOk={async () => {
                const { isStandard, hasCustomer, ownerId } = checkStatus;

                if (!isStandard) {
                    return;
                }

                // 如果标准客户未存在，则转到标准客户的编辑页面，对编辑页面做一个定制
                const gotoEdit = !hasCustomer;
                if (gotoEdit) {
                    setLoading(true);
                    topCustomerList.setUpdateMode('trans-standard');
                    await topCustomerList.setActionAsync('update', {
                        id: mutatingData.id,
                    });
                    setLoading(false);
                    customerList.resetAction();
                    resetForm();
                    await topCustomerList.autoFillOtherParamsByCustomerName(
                        newCusName.v,
                        topCustomerList.mutatingData?.biz_type,
                    );
                    return;
                }

                if (_.trim(newCusName.v) === '') {
                    setRequiredDetected(true);
                    return;
                }

                const { customerInfo } = checkStatus || {};
                if (!customerInfo || !customerInfo?.id) {
                    message.error('缺少标准客户id');
                    return;
                }

                setLoading(true);
                const [d, e] = await defaultAxios.get(
                    '/crm/customer/mergeNotStandardCustomer',
                    {
                        standardCustomer: customerInfo?.id,
                        notStandardCustomer: curId,
                    },
                );
                let ok = false;
                if (isNetSuccess(d, e)) {
                    ok = true;
                }
                // 如果选择的标准客户已存在，走一个后端的接口
                // 且改接口会删除当前非标准客户，所以需要关闭抽屉
                setLoading(false);
                if (ok) {
                    message.success(
                        `成功转移非标客户至【${customerInfo?.customerName}】`,
                    );
                    setDrawerVis(false);
                    runInAction(() => {
                        customerList.resetAction();
                        resetForm();
                        tryRefresh(['customer']);
                    });
                } else {
                    message.error(`转移标准客户失败，请联系管理员。`);
                }
            }}
        >
            <div className={'crm-customer-drop ' + (loading ? 'loading' : '')}>
                <h4>
                    当前非标客户名称<b>【{mutatingData.customer_name}】</b>
                </h4>
                <div className="form-wrapper">
                    <Form className="form" layout={'horizontal'}>
                        <Form.Item
                            {...formItemLayout}
                            {...remarkHelps}
                            required={true}
                            label={<span className="label">标准客户名称</span>}
                        >
                            <WidgetInputSearchable
                                options={{
                                    api: `/crm/customer/searchCompanyName?biz_type=${mutatingData.biz_type}&standard=true`,
                                    searchQueryKey: 'companyName',
                                    hack: true,
                                    placeholder: '标准客户名称',
                                    emptyChild: (
                                        <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description={
                                                <span
                                                    style={{
                                                        padding: '0 10px',
                                                    }}
                                                >
                                                    查不到标准客户的工商注册信息，请确认名称是否正确
                                                </span>
                                            }
                                        />
                                    ),
                                }}
                                onChange={value => {
                                    if (!_.isNil(value)) {
                                        const nextNewCusName = { v: value };
                                        setNewCusName(nextNewCusName);
                                    }
                                }}
                                value={newCusName.v}
                                onInteract={(type, value) => {
                                    if (type === 'focus') {
                                        setRequiredDetected(false);
                                    }
                                    if (type === 'blur2') {
                                        if (_.trim(newCusName.v) === '') {
                                            setRequiredDetected(true);
                                        }
                                    }
                                }}
                            />
                        </Form.Item>
                        <div>{loadingWrapper(checkLoading)(alertEle)}</div>
                    </Form>
                </div>
            </div>
        </Modal>
    );
});
