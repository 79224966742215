import React, { useEffect, useState } from 'react';
import { CustomerListStore } from '@/pages/sales-follow-up/custom/list/store';
import { AntTable, AntCollapse } from '@/components/antd';
import { defaultAxios, isNetSuccess } from '@/utils';
import { MultiDragPlugin } from 'sortablejs/plugins';

const makeParentRequestBody = (cid: number) => ({
    pagination: {
        pageSize: 1000,
        pageNum: 1,
    },
    filters: {
        filters: [
            {
                fieldId: 'parent_customer_id',
                operateType: 'in',
                filterValue: cid,
                parser: 'string',
                widgetKey: 'object',
            },
        ],
    },
    selectiveFields: ['id', 'biz_type', 'customer_name'],
    dataAuthCode: 'SEARCH-ALL',
});

const columns = [
    {
        title: '关联类型',
        dataIndex: 'related_type', // 1 主客户  2 子客户
        render(tp: any) {
            if (tp === 1) {
                return '主客户';
            }
            return '子客户';
        },
    },
    {
        title: '客户名称',
        dataIndex: 'subcustomer_id',
        render(text: any, datum: any) {
            // customer_id
            // subcustomer_id
            const { click } = datum;
            if (click === false) {
                return datum.customer_name;
            }
            return (
                <a
                    target="_blank"
                    href={'/buy/custom/my-custom?id=' + datum.id}
                >
                    {datum.customer_name}
                </a>
            );
        },
    },
];

export const CusSubCus: React.FC<{
    defaultStore: CustomerListStore;
}> = ({ defaultStore }) => {
    const { mutatingData } = defaultStore;
    const [data, setData] = useState<any[] | null>(null);

    useEffect(() => {
        if (!mutatingData) {
            return;
        }
        const { id: cid } = mutatingData;
        const { parent_customer_id } = mutatingData;
        if (parent_customer_id?.relatedObj) {
            const { customer_name } = parent_customer_id?.relatedObj || {};
            if (!customer_name) {
                return;
            }

            setData([
                {
                    id: parent_customer_id?.originalValue,
                    customer_name,
                    related_type: 1,
                    click: true,
                },
                { ...mutatingData, related_type: 2, click: false },
            ]);
            return;
        }
        if (!cid) {
            return;
        }
        defaultAxios
            .post('/bff/api/rest/customer/list', makeParentRequestBody(cid))
            .then(([d, e]) => {
                if (isNetSuccess(d, e)) {
                    const ds = d?.data?.datas || [];
                    // 加入类型
                    if (ds.length === 0) {
                        setData([]);
                        //
                    } else {
                        setData([
                            { ...mutatingData, related_type: 1, click: false },
                            ...ds.map((d: any) => ({
                                ...d,
                                related_type: 2,
                                click: true,
                            })),
                        ]);
                    }
                }
            });
    }, [mutatingData]);

    if (mutatingData === null) {
        return null;
    }

    return (
        <div style={{ marginTop: 6 }}>
            <AntCollapse
                collapseConfig={[
                    {
                        key: 'cus-sub-cus',
                        header: <span>主子客户关联记录</span>,
                        content: !!data ? (
                            <AntTable columns={columns} dataSource={data} />
                        ) : null,
                    },
                ]}
                defaultActiveKey={['cus-sub-cus']}
            />
        </div>
    );
};
