import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Result } from 'antd';

const P403: React.FC<RouteComponentProps> = ({ location }) => {
    return (
        <Result
            status="403"
            title="403"
            subTitle="您暂无访问CRM系统的权限，请联系销售运营团队开通～"
        />
    );
};

export { P403 };
