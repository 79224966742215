import React, { memo } from 'react';
import { AutoEllipsis } from '../../auto-ellipsis';
import _ from 'lodash';
import querystring from 'querystring';

export const Link: React.FC<{
    args: any[];
    item: any;
    onAction: (...args: any[]) => void;
}> = memo(({ args, item, onAction }) => {
    const value = args[0];
    const options = args[1] || {};
    const queryFetcher = options.queryFetcher || (() => {});
    const query = options.query || queryFetcher(item);
    const url = options.url || value;
    const target = options.target || '_blank';

    if (value) {
        const href =
            url + (_.isEmpty(query) ? '' : '?' + querystring.stringify(query));
        return (
            <a target={target} href={href}>
                <AutoEllipsis text={value} />
            </a>
        );
    }
    return null;
});

console.log('LinkLinkLinkLink', Link);
