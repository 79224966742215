import React, { useEffect, useState, memo, useContext } from 'react';
import {
    TableLv2,
    applyParamListToColumns,
    ITableLv2Props,
    TCellsSelector,
    ICommonTableColumnProps,
    getAutoOnChangeHandler,
    embedDataToWidgets,
    CommonFormOnInteract,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import {
    ListMeta,
    PaginationStore,
    SorterStore,
    useListMetaWithReactSnippet,
} from '@/stores/utils';
import { FilterStore } from '@/stores/utils/filter';
import { Perm, getParamPermApi } from '@/stores/perm';
import { Icon } from 'antd';
import {
    IWidgetItem,
    getAdvancedFilterWidgets,
} from '@/components/common/form/advanced-filters';
import { arrToJson, delay } from '@/utils';
import { loadingWrapper, BlockLoading2 } from '../block-loading';
import { TableLv1 } from './level1';
import { CtxContext } from '@/utils/context';
import { AntTableCtx } from '@/components/antd';

export interface IDTableStore<T = any> {
    data: T[];
    listType?: string;
    defaultMeta: ListMeta;
    defaultPerm: Perm;
    defaultFilter: FilterStore;
    defaultPagination: PaginationStore;
    defaultSorter: SorterStore;
}

export interface IDTableConfig {
    autoInit?: boolean;
    withActionPermCheck?: boolean;
    useOriginalPermKey?: boolean;
}

export interface IDTableProps<T = any, E = any> {
    defaultStore: IDTableStore;
    options?: IDTableConfig;
    eventCode?: string;
    withEventCode?: boolean;
    onColumnsUpdated?: () => void;
    propsTransformer?: (props: ITableLv2Props<T, E>) => ITableLv2Props<T, E>;
    cellsSelectorGenerator?: (store: IDTableStore) => TCellsSelector<T>;
    cellsSelectorTransformer?: (
        cellsSelector: TCellsSelector<T>,
    ) => TCellsSelector<T>;
    tableColumnGenerator?: (
        defaultStore: IDTableStore,
    ) => Array<ICommonTableColumnProps<any>>;
    tableColumnTransformer?: (
        tableColum: Array<ICommonTableColumnProps<any>>,
    ) => Array<ICommonTableColumnProps<any>>;
    filtersElememtsGenerator?: (
        defaultStore: IDTableStore,
        filterWidgetsGenerator?: (
            defaultStore: IDTableStore,
        ) => Array<IWidgetItem<any>>,
    ) => undefined | { [key: string]: React.ReactElement | null };
    filterWidgetsGenerator?: (
        defaultStore: IDTableStore,
    ) => Array<IWidgetItem<any>>;
    sceneCode?: string;
}

export const defaultPropsTransformer: <T = any, E = any>(
    props: ITableLv2Props<T, E>,
) => ITableLv2Props<T, E> = props => {
    return props;
};
export const defaultCellsSelectorGenerator: <T = any>(
    store: IDTableStore,
) => TCellsSelector<T> = store => {
    const { defaultMeta } = store;
    return defaultMeta.defaultColumnsCellsSelector;
};
export const defaultCellsSelectorTransformer: <T = any>(
    cellsSelector: TCellsSelector<T>,
) => TCellsSelector<T> = cellsSelector => {
    return cellsSelector;
};
export const defaultTableColumnGenerator: (
    defaultStore: IDTableStore,
) => Array<ICommonTableColumnProps<any>> = store => {
    const { defaultMeta } = store;
    return defaultMeta.defaultColumnsAll;
};
export const defaultTableColumnTransformer: (
    tableColum: Array<ICommonTableColumnProps<any>>,
) => Array<ICommonTableColumnProps<any>> = tableColum => {
    return tableColum;
};
export const defaultFilterWidgetsGenerator = (
    defaultStore: IDTableStore,
): Array<IWidgetItem<any>> => {
    const { defaultMeta } = defaultStore;
    return defaultMeta.defaultAdvancedFilterWidgetsAll;
};

const defaultFiltersElememtsGenerator = (
    defaultStore: IDTableStore,
    filterWidgetsGenerator?: (
        defaultStore: IDTableStore,
    ) => Array<IWidgetItem<any>>,
) => {
    const { defaultMeta, defaultFilter } = defaultStore;
    filterWidgetsGenerator =
        filterWidgetsGenerator || defaultFilterWidgetsGenerator;
    const {
        filterData,
        setFilterData,
        onFilterCancel,
        onFilterConfirmed,
    } = defaultFilter;

    // 获取默认的数据回调
    const onDataChange = getAutoOnChangeHandler(
        filterData,
        setFilterData.bind(defaultFilter),
    );
    // 结合插件配置和真实数据

    const baiscWidgets = filterWidgetsGenerator(defaultStore);
    // embedDataToWidgets<any>(baiscWidgets, filterData);

    // 自定义列表
    const { inited: metaInited } = defaultMeta;

    if (!metaInited || !filterData || _.isEmpty(filterData)) {
        return undefined;
    }

    const onInteract: CommonFormOnInteract<any> = (key, type, value) => { };
    const props = {
        data: filterData,
        widgets: baiscWidgets,
        onChange: onDataChange.bind(defaultFilter),
        onCancel: onFilterCancel.bind(defaultFilter),
        onConfirm: onFilterConfirmed.bind(defaultFilter),
        onInteract,
    };

    const adfilterWidgets = getAdvancedFilterWidgets(props);
    return adfilterWidgets;
};

export const DTable: React.FC<IDTableProps> = memo(
    observer(
        ({
            defaultStore,
            options,
            propsTransformer,
            cellsSelectorGenerator,
            cellsSelectorTransformer,
            tableColumnGenerator,
            tableColumnTransformer,
            filtersElememtsGenerator,
            filterWidgetsGenerator,
            onColumnsUpdated = () => void 0,
            eventCode,
            withEventCode,
            sceneCode,
        }) => {
            propsTransformer = propsTransformer || defaultPropsTransformer;
            cellsSelectorGenerator =
                cellsSelectorGenerator || defaultCellsSelectorGenerator;
            cellsSelectorTransformer =
                cellsSelectorTransformer || defaultCellsSelectorTransformer;
            tableColumnGenerator =
                tableColumnGenerator || defaultTableColumnGenerator;
            tableColumnTransformer =
                tableColumnTransformer || defaultTableColumnTransformer;
            filtersElememtsGenerator =
                filtersElememtsGenerator || defaultFiltersElememtsGenerator;
            filterWidgetsGenerator =
                filterWidgetsGenerator || defaultFilterWidgetsGenerator;
            options = options || {};
            const autoInit = _.isUndefined(options.autoInit)
                ? false
                : options.autoInit;
            const withActionPermCheck = _.isUndefined(
                options.withActionPermCheck,
            )
                ? true
                : options.withActionPermCheck;
            const useOriginalPermKey = options.useOriginalPermKey;

            const ctx = useContext(CtxContext);
            const innerDrawer = ctx.position === 'drawer';

            // 默认全用PUBLIC-VIEW来控制
            eventCode = _.isUndefined(eventCode) ? 'PUBLIC-VIEW' : eventCode;
            withEventCode = _.isUndefined(withEventCode) ? true : withEventCode;

            const {
                data,
                defaultMeta,
                defaultPagination,
                defaultSorter,
                defaultPerm,
                defaultFilter,
                listType = 'default',
            } = defaultStore;

            // const [start] = useState({v: Date.now()})
            // useEffect(() => {
            //     console.log('mount time 2: ', Date.now() - start.v)
            //     start.v = Date.now()
            // }, [defaultStore.data]);

            const { inited } = defaultMeta;
            useEffect(() => {
                if (!inited && autoInit) {
                    defaultMeta.fetch();
                }
            }, [inited]);

            // const [props, setProps] = useState<ITableLv2Props<any, any> | null>(
            //     null,
            // );

            const [tableColsParamsAll, setTableColsParamsAll] = useState<
                any[] | null
            >(null);
            useEffect(() => {
                if (inited) {
                    if (eventCode && withEventCode) {
                        (async () => {
                            const list = await defaultMeta.asyncTableColsParamsAll(
                                getParamPermApi(defaultMeta.tableId, eventCode),
                            );
                            setTableColsParamsAll(list);
                        })();
                    } else {
                        setTableColsParamsAll(defaultMeta.tableColsParamsAll);
                    }
                }
            }, [inited]);

            // 更改筛选条件，页码置为1
            useEffect(() => {
                defaultStore.defaultPagination.pagenum = 1;
            }, [defaultStore.defaultFilter.confirmedfilterData]);

            const {
                setPaginationByUI,
                paginationForUI,
                amount,
            } = defaultPagination;

            const { setSorter } = defaultSorter;

            // 自定义列表
            const {
                snippet: customParamsModalSnippets,
                setTableParamsEditorVisible,
            } = useListMetaWithReactSnippet(
                defaultMeta,
                tableColsParamsAll || [],
                onColumnsUpdated,
            );
            // const customParamsModalSnippets = null;
            // const setTableParamsEditorVisible = (next: boolean) => {};

            const tableColsParamsToDisplay = ListMeta.composeParamsFromUserAndAll(
                defaultMeta.tableColsParamsFromUserSetting,
                tableColsParamsAll || [],
                defaultMeta.noUserSetting,
            );

            const finalColunmsConfig = applyParamListToColumns(
                tableColumnTransformer(tableColumnGenerator(defaultStore)),
                tableColsParamsToDisplay,
            );

            const baiscCellsSelector = cellsSelectorTransformer(
                cellsSelectorGenerator(defaultStore),
            );

            console.log('ddfasrawer', baiscCellsSelector);

            const advancedFilterMap = filtersElememtsGenerator(
                defaultStore,
                filterWidgetsGenerator,
            );
            if (advancedFilterMap) {
                delete advancedFilterMap.equity_detail;
            }

            const props = propsTransformer({
                className: 'table-v-stretch',
                dataSource: data,
                columns: finalColunmsConfig,
                cellsSelector: baiscCellsSelector,
                onPaginationChange: setPaginationByUI,
                onSorterChange: setSorter,
                advancedFilterMap,
                closeRowSelection: true,
                fixedTd: true,
                useThFilter: true,
                pagination: {
                    ...paginationForUI,
                    total: amount,
                },
                paramWhiteList: defaultMeta.defaultAllParams,
                afterOpSlot: defaultMeta.noUserSetting ? <></> : (
                    <span
                        onClick={() => {
                            setTableParamsEditorVisible(true);
                        }}
                    >
                        <Icon type="setting" />
                    </span>
                ),
                tableCode: defaultMeta.tableId,
                fadeIn: true,
            });
            if (_.isArray(props.actions) && withActionPermCheck) {
                props.actions = defaultPerm.applyToTableActionsByTypeAndAction(
                    listType,
                    useOriginalPermKey,
                )(props.actions);
            }

            // 这样写还是不行
            // useEffect(() => {
            //     if (!propsTransformer) {
            //         return;
            //     }
            //     const pr = propsTransformer({
            //         className: 'table-v-stretch',
            //         dataSource: data,
            //         columns: finalColunmsConfig,
            //         cellsSelector: baiscCellsSelector,
            //         onPaginationChange: setPaginationByUI,
            //         onSorterChange: setSorter,
            //         advancedFilterMap,
            //         closeRowSelection: true,
            //         fixedTd: true,
            //         useThFilter: true,
            //         pagination: {
            //             ...paginationForUI,
            //             total: amount,
            //         },
            //         paramWhiteList: defaultMeta.defaultAllParams,
            //         afterOpSlot: (
            //             <span
            //                 onClick={() => {
            //                     setTableParamsEditorVisible(true);
            //                 }}
            //             >
            //                 <Icon type="setting" />
            //             </span>
            //         ),
            //         tableCode: defaultMeta.tableId,
            //         fadeIn: true,
            //     });
            //     if (_.isArray(pr.actions) && withActionPermCheck) {
            //         pr.actions = defaultPerm.applyToTableActionsByTypeAndAction(
            //             listType,
            //             useOriginalPermKey,
            //         )(pr.actions);
            //     }

            //     setProps(pr);
            // }, [
            //     data,
            //     defaultMeta,
            //     defaultPerm,
            //     defaultMeta.defaultAllParams,
            //     defaultMeta.tableId,
            //     tableColsParamsAll,
            //     propsTransformer,
            //     withActionPermCheck,
            //     listType,
            //     useOriginalPermKey,
            // ]);

            if (!tableColsParamsAll || !props) {
                return (
                    <div
                        style={{
                            height: '100%',
                            paddingBottom: 42,
                            flexGrow: 1,
                        }}
                    >
                        <div
                            style={{ height: '100%', backgroundColor: 'white' }}
                        >
                            {/* <BlockLoading2 /> */}
                        </div>
                    </div>
                );
            }

            if (!innerDrawer) {
                const dataTable = (
                    <AntTableCtx.Provider value={{ useVTable: true }}>
                        {customParamsModalSnippets}
                        <TableLv2<any> {...props} sceneCode={sceneCode} />
                    </AntTableCtx.Provider>
                );
                return dataTable;
            } else {
                const dataTable = (
                    <>
                        {customParamsModalSnippets}
                        <TableLv2<any> {...props} sceneCode={sceneCode} />
                    </>
                );

                return dataTable;
            }
        },
    ),
);

// function testFrame() {
//     let start =
//     requestAnimationFrame(() => {

//     })
// }
