import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from '@/stores';
import { observer } from 'mobx-react-lite';
import { Spin, Select } from 'antd';
import _ from 'lodash';
import { homeRangeOptions } from '../../store';
import './index.less';
import AutoImg from '@/components/common/auto-img';
import { TableLv2 } from '../../../../../components/common/table/level2';
import { trans2DotTwo } from '@/pages/analysis/dashboard/composite-live/component/achievement-situation';
import { goToOtherPage } from '../home-ana-opt/index';

const HomePerformanceSalesRank: React.FC = () => {
    const store = useContext(StoreContext);
    const homeNewStore = store.getDefaultHomeNewStore();
    // const authStore = store.getAuthStore();
    // const { userInfo } = authStore;
    // const { sysUser } = userInfo;
    // const userId = sysUser?.id;
    const {
        homeNewSalesRankData,
        homeNewSalesRankLoading,
        setHomeNewSalesRankData,
        setHomeNewFilterData,
        homeNewFilterData,
        getHomeNewSalesRankData,
        homeNewSelectedData,
    } = homeNewStore;
    const userId = homeNewSelectedData.selectedId;
    useEffect(() => {
        getHomeNewSalesRankData();
    }, [homeNewFilterData.range, homeNewSelectedData]);
    const cityTop10Data = _.slice(homeNewSalesRankData.cityRankUserList, 0, 9);
    const countryTop10Data = _.slice(
        homeNewSalesRankData.countryRankUserList,
        0,
        9,
    );
    const cityTop3Data = _.take(homeNewSalesRankData.cityRankUserList, 3);
    const countryTop3Data = _.take(homeNewSalesRankData.countryRankUserList, 3);
    const cityOtherData = _.slice(homeNewSalesRankData.cityRankUserList, 3, 10);
    const countryOtherData = _.slice(
        homeNewSalesRankData.countryRankUserList,
        3,
        10,
    );
    const inCityNumber = _.findIndex(
        homeNewSalesRankData.cityRankUserList,
        item => {
            return item.userId === userId;
        },
    );
    const inCountryNumber = _.findIndex(
        homeNewSalesRankData.countryRankUserList,
        item => {
            return item.userId === userId;
        },
    );
    const inCityTop10 = inCityNumber != -1 && inCityNumber < 9;
    const inCountryTop10 = inCountryNumber != -1 && inCountryNumber < 9;
    // groupName: string;
    // userName: string;
    // userId: string | number;
    // achievementFinishRate: number;
    // avatar: string;
    const rankTableColumns: any = [
        {
            title: '排名',
            width: '60px',
            align: 'center',
            render: (r: any, t: any, index: number) => {
                return index + 4;
            },
        },
        {
            title: '小组',
            width: '80px',
            dataIndex: 'groupName',
            align: 'center',
        },
        {
            title: '销售',
            width: '60px',
            dataIndex: 'userName',
            align: 'center',
            render: (t: string, r: any) => {
                const showBlue = r.userId === userId;
                return showBlue ? (
                    <span className="home-new-tips-blue">{t}</span>
                ) : (
                    t
                );
            },
        },
        {
            title: '业绩完成率',
            width: '80px',
            dataIndex: 'achievementFinishRate',
            align: 'center',
            render: (v: any) => {
                return v ? `${trans2DotTwo(v)}%` : '- -';
            },
        },
    ];
    const trans2AvatarUrl = (avatarString: string) => {
        if (avatarString) {
            try {
                const avatarArray = JSON.parse(avatarString);
                if (avatarArray && avatarArray?.[0]) {
                    return avatarArray[0].url;
                } else {
                    return undefined;
                }
            } catch (error) {
                return undefined;
            }
        }
    };
    const goToHighQualityCustomerPage = () => {
        goToOtherPage('customer', '3days-gaoqian');
    };
    return (
        <Spin spinning={homeNewSalesRankLoading}>
            <div className="home-new-item-content h-p-s-r-content">
                <div className="home-new-item-header">
                    <span className="h-n-i-title">
                        业绩完成率排名
                        <span className="h-n-i-tips">
                            （注意：此处数据非实时数据，是截止昨日24时的数据）
                        </span>
                    </span>
                    {/* <AntButton>生成报告</AntButton> */}
                    <div className="h-n-i-filter">
                        <div className="h-n-i-item">
                            <label htmlFor="">时间</label>
                            <Select
                                value={homeNewFilterData.range}
                                onChange={(value: any) => {
                                    setHomeNewFilterData({
                                        ...homeNewFilterData,
                                        range: value,
                                    });
                                }}
                                style={{
                                    minWidth: '80px',
                                }}
                                size={'small'}
                            >
                                {_.map(homeRangeOptions, option => {
                                    return (
                                        <Select.Option value={option.value}>
                                            {option.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="home-p-s-r-body">
                    <div className="h-p-s-r-rank-content-city">
                        <img
                            style={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                zIndex: 1,
                                top: '-30px',
                            }}
                            width="350"
                            height="182"
                            src={`${process.env.PUBLIC_URL}/imgs/home/rankShadow.png`}
                        />
                        <p className="h-p-s-r-title">
                            {/* {homeNewSelectedData.selectedRankName || 'KA'}
                            销售业绩排行榜（城市榜）
                             */}
                            <img
                                width="271"
                                height="79"
                                style={{
                                    position: 'relative',
                                    zIndex: 2,
                                }}
                                src={`${process.env.PUBLIC_URL}/imgs/home/cityRankTitle.png`}
                            />
                        </p>
                        <div className="h-p-s-r-top-content-city">
                            <div className="h-p-s-r-avatar-content-city">
                                <img
                                    style={{
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        zIndex: 1,
                                    }}
                                    width="168"
                                    height="167"
                                    src={`${process.env.PUBLIC_URL}/imgs/home/top1Back.png`}
                                />
                                <div className="avatar-item top2-avatar-item">
                                    <div className="h-p-s-r-a-body">
                                        <div
                                            className="h-p-s-r-avatar"
                                            // style={{
                                            //     backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/home/cityTop2.png')`,
                                            // }}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/imgs/home/salesTop2.png`}
                                                width="60"
                                                height="71"
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    right: '3px',
                                                    top: '-8px',
                                                }}
                                            />
                                            <div
                                                className="h-p-s-r-sales-img"
                                                // style={{
                                                //     backgroundImage: `url('${cityTop3Data?.[1]?.avatar}')`,
                                                // }}
                                            >
                                                <AutoImg
                                                    imgSrc={trans2AvatarUrl(
                                                        cityTop3Data?.[1]
                                                            ?.avatar,
                                                    )}
                                                    squareLength={56}
                                                />
                                            </div>
                                        </div>
                                        <div className="h-p-s-r-info">
                                            <p
                                                className={`h-p-r-s-name ${
                                                    cityTop3Data?.[1]
                                                        ?.userId === userId
                                                        ? 'home-new-tips-blue'
                                                        : ''
                                                }`}
                                            >
                                                {cityTop3Data?.[1]?.userName ||
                                                    '- -'}
                                            </p>
                                            <p className="h-p-r-s-group">
                                                {cityTop3Data?.[1]?.groupName ||
                                                    '- -'}
                                            </p>
                                            <p className="h-p-r-s-rate">
                                                完成率：
                                                {cityTop3Data?.[1]
                                                    ?.achievementFinishRate
                                                    ? `${trans2DotTwo(
                                                          cityTop3Data?.[1]
                                                              ?.achievementFinishRate,
                                                      )}%`
                                                    : '- -'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="avatar-item top1-avatar-item">
                                    <div className="h-p-s-r-a-body">
                                        <div
                                            className="h-p-s-r-avatar top1"
                                            // style={{
                                            //     backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/home/cityTop1.png`,
                                            // }}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/imgs/home/salesTop1.png`}
                                                width="68"
                                                height="83"
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    top: '-14px',
                                                    right: '1px',
                                                }}
                                            />
                                            <div
                                                className="h-p-s-r-sales-img"
                                                // style={{
                                                //     backgroundImage: `url('${cityTop3Data?.[0]?.avatar}')`,
                                                // }}
                                            >
                                                <AutoImg
                                                    imgSrc={trans2AvatarUrl(
                                                        cityTop3Data?.[0]
                                                            ?.avatar,
                                                    )}
                                                    squareLength={64}
                                                />
                                            </div>
                                        </div>
                                        <div className="h-p-s-r-info">
                                            <p
                                                className={`h-p-r-s-name ${
                                                    cityTop3Data?.[0]
                                                        ?.userId === userId
                                                        ? 'home-new-tips-blue'
                                                        : ''
                                                }`}
                                            >
                                                {cityTop3Data?.[0]?.userName ||
                                                    '- -'}
                                            </p>
                                            <p className="h-p-r-s-group">
                                                {cityTop3Data?.[0]?.groupName ||
                                                    '- -'}
                                            </p>
                                            <p className="h-p-r-s-rate">
                                                完成率：
                                                {cityTop3Data?.[0]
                                                    ?.achievementFinishRate
                                                    ? `${trans2DotTwo(
                                                          cityTop3Data?.[0]
                                                              ?.achievementFinishRate,
                                                      )}%`
                                                    : '- -'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="avatar-item top3-avatar-item">
                                    <div className="h-p-s-r-a-body">
                                        <div
                                            className="h-p-s-r-avatar"
                                            // style={{
                                            //     backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/home/cityTop3.png`,
                                            // }}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/imgs/home/salesTop3.png`}
                                                width="60"
                                                height="71"
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    right: '3px',
                                                    top: '-8px',
                                                }}
                                            />
                                            <div
                                                className="h-p-s-r-sales-img"
                                                // style={{
                                                //     backgroundImage: `url('${cityTop3Data?.[2]?.avatar}')`,
                                                // }}
                                            >
                                                <AutoImg
                                                    imgSrc={trans2AvatarUrl(
                                                        cityTop3Data?.[2]
                                                            ?.avatar,
                                                    )}
                                                    squareLength={56}
                                                />
                                            </div>
                                        </div>
                                        <div className="h-p-s-r-info">
                                            <p
                                                className={`h-p-r-s-name ${
                                                    cityTop3Data?.[2]
                                                        ?.userId === userId
                                                        ? 'home-new-tips-blue'
                                                        : ''
                                                }`}
                                            >
                                                {cityTop3Data?.[2]?.userName ||
                                                    '- -'}
                                            </p>
                                            <p className="h-p-r-s-group">
                                                {cityTop3Data?.[2]?.groupName ||
                                                    '- -'}
                                            </p>
                                            <p className="h-p-r-s-rate">
                                                完成率：
                                                {cityTop3Data?.[2]
                                                    ?.achievementFinishRate
                                                    ? `${trans2DotTwo(
                                                          cityTop3Data?.[2]
                                                              ?.achievementFinishRate,
                                                      )}%`
                                                    : '- -'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="h-p-s-r-table-content-city">
                                <TableLv2
                                    closeRowSelection={true}
                                    pagination={false}
                                    className={'h-p-s-r-table'}
                                    // scroll={{
                                    //     x: '100%',
                                    //     y: '200px',
                                    // }}
                                    dataSource={cityOtherData}
                                    columns={rankTableColumns}
                                />
                            </div>
                        </div>
                        <p className="h-p-s-r-tips-city">
                            {inCityTop10 ? (
                                '恭喜您成功上榜，继续加油！'
                            ) : (
                                <>
                                    <span>{`您当前的排名是第${
                                        inCityNumber === -1
                                            ? '- -'
                                            : inCityNumber + 1
                                    }名，距离榜首还差${
                                        inCityNumber === -1
                                            ? '- -'
                                            : inCityNumber
                                    }名。`}</span>
                                </>
                            )}
                        </p>
                    </div>
                    <div className="h-p-s-r-rank-content-country">
                        <img
                            style={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                zIndex: 1,
                                top: '-40px',
                            }}
                            width="350"
                            height="182"
                            src={`${process.env.PUBLIC_URL}/imgs/home/rankShadow.png`}
                        />
                        <p className="h-p-s-r-title">
                            <img
                                width="264"
                                height="68"
                                style={{
                                    position: 'relative',
                                    zIndex: 2,
                                }}
                                src={`${process.env.PUBLIC_URL}/imgs/home/countryRankTitle.png`}
                            />
                        </p>
                        <div className="h-p-s-r-top-content-country">
                            <div className="h-p-s-r-avatar-content-country">
                                <img
                                    style={{
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        zIndex: 1,
                                    }}
                                    width="168"
                                    height="167"
                                    src={`${process.env.PUBLIC_URL}/imgs/home/top1Back.png`}
                                />
                                <div className="avatar-item top2-avatar-item">
                                    <div className="h-p-s-r-a-body">
                                        <div
                                            className="h-p-s-r-avatar country-top2"
                                            // style={{
                                            //     backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/home/countryTop2.png`,
                                            // }}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/imgs/home/salesTop2.png`}
                                                width="60"
                                                height="71"
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    right: '15px',
                                                    top: '-3px',
                                                }}
                                            />
                                            <div
                                                className="h-p-s-r-sales-img"
                                                // style={{
                                                //     backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/menus/logo.png`,
                                                // }}
                                            >
                                                <AutoImg
                                                    imgSrc={trans2AvatarUrl(
                                                        countryTop3Data?.[1]
                                                            ?.avatar,
                                                    )}
                                                    squareLength={56}
                                                />
                                            </div>
                                        </div>
                                        <div className="h-p-s-r-info">
                                            <p
                                                className={`h-p-r-s-name ${
                                                    countryTop3Data?.[1]
                                                        ?.userId === userId
                                                        ? 'home-new-tips-blue'
                                                        : ''
                                                }`}
                                            >
                                                {countryTop3Data?.[1]
                                                    ?.userName || '- -'}
                                            </p>
                                            <p className="h-p-r-s-group">
                                                {countryTop3Data?.[1]
                                                    ?.groupName || '- -'}
                                            </p>
                                            <p className="h-p-r-s-rate">
                                                完成率：
                                                {countryTop3Data?.[1]
                                                    ?.achievementFinishRate
                                                    ? `${trans2DotTwo(
                                                          countryTop3Data?.[1]
                                                              ?.achievementFinishRate,
                                                      )}%`
                                                    : '- -'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="avatar-item top1-avatar-item">
                                    <div className="h-p-s-r-a-body">
                                        <div
                                            className="h-p-s-r-avatar country-top1"
                                            // style={{
                                            //     backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/home/countryTop1.png`,
                                            // }}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/imgs/home/salesTop1.png`}
                                                width="68"
                                                height="83"
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    top: '-6px',
                                                    right: '18px',
                                                }}
                                            />
                                            <div
                                                className="h-p-s-r-sales-img"
                                                // style={{
                                                //     backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/menus/logo.png`,
                                                // }}
                                            >
                                                <AutoImg
                                                    imgSrc={trans2AvatarUrl(
                                                        countryTop3Data?.[0]
                                                            ?.avatar,
                                                    )}
                                                    squareLength={64}
                                                />
                                            </div>
                                        </div>
                                        <div className="h-p-s-r-info">
                                            <p
                                                className={`h-p-r-s-name ${
                                                    countryTop3Data?.[0]
                                                        ?.userId === userId
                                                        ? 'home-new-tips-blue'
                                                        : ''
                                                }`}
                                            >
                                                {countryTop3Data?.[0]
                                                    ?.userName || '- -'}
                                            </p>
                                            <p className="h-p-r-s-group">
                                                {countryTop3Data?.[0]
                                                    ?.groupName || '- -'}
                                            </p>
                                            <p className="h-p-r-s-rate">
                                                完成率：
                                                {countryTop3Data?.[0]
                                                    ?.achievementFinishRate
                                                    ? `${trans2DotTwo(
                                                          countryTop3Data?.[0]
                                                              ?.achievementFinishRate,
                                                      )}%`
                                                    : '- -'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="avatar-item top3-avatar-item">
                                    <div className="h-p-s-r-a-body">
                                        <div
                                            className="h-p-s-r-avatar country-top3"
                                            // style={{
                                            //     backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/home/countryTop3.png`,
                                            // }}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/imgs/home/salesTop3.png`}
                                                width="60"
                                                height="71"
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    right: '15px',
                                                    top: '-3px',
                                                }}
                                            />
                                            <div className="h-p-s-r-sales-img">
                                                <AutoImg
                                                    imgSrc={trans2AvatarUrl(
                                                        countryTop3Data?.[2]
                                                            ?.avatar,
                                                    )}
                                                    squareLength={56}
                                                />
                                            </div>
                                        </div>
                                        <div className="h-p-s-r-info">
                                            <p
                                                className={`h-p-r-s-name ${
                                                    countryTop3Data?.[2]
                                                        ?.userId === userId
                                                        ? 'home-new-tips-blue'
                                                        : ''
                                                }`}
                                            >
                                                {countryTop3Data?.[2]
                                                    ?.userName || '- -'}
                                            </p>
                                            <p className="h-p-r-s-group">
                                                {countryTop3Data?.[2]
                                                    ?.groupName || '- -'}
                                            </p>
                                            <p className="h-p-r-s-rate">
                                                完成率：
                                                {countryTop3Data?.[2]
                                                    ?.achievementFinishRate
                                                    ? `${trans2DotTwo(
                                                          countryTop3Data?.[2]
                                                              ?.achievementFinishRate,
                                                      )}%`
                                                    : '- -'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="h-p-s-r-table-content-country">
                                <TableLv2
                                    closeRowSelection={true}
                                    pagination={false}
                                    className={'h-p-s-r-table'}
                                    dataSource={countryOtherData}
                                    columns={rankTableColumns}
                                />
                            </div>
                        </div>
                        <p className="h-p-s-r-tips-country">
                            {inCountryTop10 ? (
                                '恭喜您成功上榜，继续加油！'
                            ) : (
                                <>
                                    <span>{`您当前的排名是第${
                                        inCountryNumber === -1
                                            ? '- -'
                                            : inCountryNumber + 1
                                    }名，距离榜首还差${
                                        inCountryNumber === -1
                                            ? '- -'
                                            : inCountryNumber
                                    }名。`}</span>
                                </>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </Spin>
    );
};
export default observer(HomePerformanceSalesRank);
