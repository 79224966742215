import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    FastFilterStore,
    makeDefaultInitailingHook,
} from '@/stores/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import { mutate } from '@/api/graphql';
import _ from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    getLeads,
    TGetLeadsReturnType,
    xDelete,
    commonQuery,
    TGetCommonReturnType,
} from '@/api/rest';
import { defaultWritingDataParser, defaultAxios, isNetSuccess } from '@/utils';
import EE from 'eventemitter3';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';
import { genContractIdParamByContractItemAndParamMeta } from '../list/store';

const transformBackEndValidateStateToValidateResult = (
    data: any[],
): ValidateResult<any> => {
    const ret: ValidateResult<any> = {};
    for (const d of data) {
        const { errorMsg, errorType, fieldName } = d;
        if (!errorMsg || !errorType || !fieldName) {
            continue;
        }
        ret[fieldName] = {
            msg: errorMsg,
            status: errorType === 'error' ? 'fail' : 'warning',
        };
    }
    return ret;
};

export class ContractInvoiceApplyListStore {
    // 数据
    public data: any[] = [];

    public sysUserId = '';
    public setSysUserId(sysUserId: string) {
        this.sysUserId = sysUserId;
    }

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public get pageTitle() {
        if (this.listType === 'all') {
            return '全部合同开发票申请';
        } else if (this.listType === 'mine') {
            return '我的合同开发票申请';
        }
        return '未知listType';
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFastFilters = this.defaultFastFilters;
        const {
            selectedFastFilters,
            selectedLv4MenuFilters,
        } = defaultFastFilters;

        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );
        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        for (const filterSet of selectedFastFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        for (const filterSet of selectedLv4MenuFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        ret.filters?.push(...defaultFilters);
        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }
    public setAction(nextAction: string) {
        this.actionType = nextAction;
    }
    public resetAction() {
        this.netValidateResult = {};
        this.actionType = '';
    }
    public async dispatchAction() {
        if (this.actionType === 'create') {
            return this.create();
        }
    }

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;

    get mutatingDataParsed() {
        return defaultWritingDataParser(this.mutatingData);
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        const initialFormData = this.defaultMeta.defaultWidgetValues;
        this.mutatingData = _.cloneDeep(initialFormData);
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;

    get fieldAuthCode() {
        return this.listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    }

    get dataAuthCode() {
        return this.listType === 'all' ? 'SEARCH-PUBLIC' : 'SEARCH-PRIVATE';
    }

    public async fetchInstantlyMutatingDataById(id: any) {
        const { fieldAuthCode, dataAuthCode } = this;

        this.mutatingDataLoading = true;
        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: id,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            undefined,
            dataAuthCode,
        );
        this.mutatingDataLoading = false;

        if (d === null || e !== null) {
            return false;
        }
        const { datas } = d;
        if (datas && datas[0]) {
            this.mutatingData = datas[0];
            return true;
        } else {
            message.error('没有该数据的权限');
            return false;
        }
    }

    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode } = this;

        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
        );

        if (_.isNil(d) || !_.isNil(e)) {
            return [d, e];
        }
        const leadsData = d;
        const { amount, datas } = leadsData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    public async create() {
        const data = _.cloneDeep(this.mutatingDataParsed);

        delete data.id;
        delete data.key;
        data.owner_id = this.sysUserId;

        const [ret, errMsg] = await defaultAxios.post(
            '/crm/contract/applyContractInvoice',
            data,
        );
        if (!ret || errMsg) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('新建成功');
        this.defaultEE.emit('create-success');
        return true;
    }

    public async askInvoiceQualifyByContactId(contractId: any) {
        this.setInvoiceQualificationLoading(true);

        const [d, e] = await commonQuery(
            'contract',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: contractId.originalValue,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            this.fieldAuthCode,
            undefined,
            this.dataAuthCode,
        );
        if (null === d) {
            this.invoiceQuilificationBad();
            message.error('请求合同开票资质出错');
            return false;
        }

        const { datas } = d;
        if (!datas || !datas[0]) {
            this.invoiceQuilificationBad();
            message.error('请求合同开票资质出错');
            return false;
        }

        const contractData = datas[0];
        const customerId = contractData?.customer_id?.originalValue;
        if (!customerId) {
            this.invoiceQuilificationBad();
            message.error('请求合同开票资质出错');
            return false;
        }

        const [d1, e1] = await commonQuery(
            'invoice_qualification',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'customer_id',
                        filterValue: customerId,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            this.fieldAuthCode,
            undefined,
            this.dataAuthCode,
        );
        if (null === d1) {
            this.invoiceQuilificationBad();
            message.error('请求合同开票资质出错');
            return false;
        }

        this.setContractCustomer(contractData?.customer_id);
        this.setInvoiceQualificationInited(true);
        this.setInvoiceQualificationLoading(false);

        const { datas: datas1 } = d1;
        if (!datas1 || !datas1[0]) {
            this.setHasInvoiceQualification(false);
            this.setInvoiceQualificationData(null);
            return false;
        }
        this.setHasInvoiceQualification(true);
        this.setInvoiceQualificationData(datas1[0]);
        return true;
    }

    public contractCustomer: any = null;
    public setContractCustomer(next: any) {
        this.contractCustomer = next;
    }

    public invoiceQualificationLoading = false;
    public setInvoiceQualificationLoading(next: boolean) {
        this.invoiceQualificationLoading = next;
    }
    public invoiceQualificationInited = false;
    public setInvoiceQualificationInited(next: boolean) {
        this.invoiceQualificationInited = next;
    }
    public hasInvoiceQualification = false;
    public setHasInvoiceQualification(next: boolean) {
        this.hasInvoiceQualification = next;
    }
    public invoiceQualificationData: any = null;
    public setInvoiceQualificationData(next: any) {
        this.invoiceQualificationData = next;
    }

    public invoiceQuilificationBad() {
        this.setInvoiceQualificationLoading(false);
        this.setInvoiceQualificationInited(false);
        this.setHasInvoiceQualification(false);
        this.setInvoiceQualificationData(null);
    }

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMeta = new ListMeta(
        '/bff/api/rest/meta/contract_invoice_apply/list',
        '/bff/api/rest/meta/contract_invoice_apply/list/filters/save',
        '/bff/api/rest/meta/contract_invoice_apply/list/table-cols/save',
    );
    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();
    public defaultPerm: Perm = new Perm('/bff/api/rest/perm?mod=om:contract');
    public defaultMSelect: MSelectStore = new MSelectStore();
    public defaultFastFilters = new FastFilterStore();
    constructor() {
        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultMeta: false,
            defaultFilter: false,
            defaultEE: false,
            defaultPerm: false,
            defaultMSelect: false,
            defaultFastFilters: false,
        });
    }
}

export const getDefaultContractInvoiceApplyListStore = createDefaultInstanceGetter(
    ContractInvoiceApplyListStore,
);

export const useAnyContractInvoiceApplyListStore = makeDefaultInitailingHook(
    ContractInvoiceApplyListStore,
    'contract_invoice_apply',
);
