import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { LayoutStandardCrud, AntButton, TableLv2 } from '@/components';
import { DatePicker, Input, Popover, Icon, Button } from 'antd';
import './index.less';
import moment, { Moment } from 'moment';
import { StoreContext } from '@/stores';
import { arrToJson, useNet } from '@/utils';
import UserSelector from '../../../../components/common/user-selector/index';

const commonCols: any[] = [
    {
        title: '线索时间',
        key: 'lead_time',
        dataIndex: 'lead_time',
    },
    {
        title: '线索量（导入CRM）',
        key: 'count',
        dataIndex: 'count',
    },
    {
        title: '已跟进线索量',
        key: 'follow_count',
        dataIndex: 'follow_count',
    },
    {
        title: '线索7日跟进率',
        key: 'follow_7_rate',
        dataIndex: 'follow_7_rate',
    },
    {
        title: '线索-商机转化率',
        key: 'lead_convert_oppo_rate',
        dataIndex: 'lead_convert_oppo_rate',
    },
    {
        title: '线索-赢单转化率',
        key: 'win_oppo_convert_lead_rate',
        dataIndex: 'win_oppo_convert_lead_rate',
    },
    {
        title: '新签商机量',
        key: 'op_new_count',
        dataIndex: 'op_new_count',
    },
    {
        title: '100赢单商机量',
        key: 'win_oppo_sum',
        dataIndex: 'win_oppo_sum',
    },
    {
        title: '商机赢单率',
        key: 'lead_to_new_op_rate',
        dataIndex: 'lead_to_new_op_rate',
    },
    {
        title: '商机赢单金额',
        key: 'win_opportunity_sale_amount',
        dataIndex: 'win_opportunity_sale_amount',
    },
];

const RangePicker = DatePicker.RangePicker;
const NewSignAna: React.FC<RouteComponentProps & {
    // type: string;
}> = props => {
    // const { type } = props;
    const [columns] = useState(() => {
        return [...commonCols];
    });
    const store = useContext(StoreContext);
    const newSignStore = store.getDefaultNewSignStore();
    const anaStore = store.getDefaultAnalysisStore();
    const { getNewSignData, exportNewSignData, newSignAnaData } = newSignStore;
    const afterDay = moment();
    const [anaSearchValue, setAnaSearchValue] = useState<{
        feCols: any[];
        fields: string[];
        begin_time: Moment | string;
        end_time: Moment | string;
    }>({
        feCols: columns.map(col => ({ key: col.key, header: col.title })),
        fields: columns.map(col => col.key),
        begin_time: afterDay
            .clone()
            .subtract(12, 'months')
            .startOf('month')
            .format('YYYY-MM-DD'),
        end_time: afterDay.endOf('month').format('YYYY-MM-DD'),
    });
    const [error, loading, reload] = useNet(() =>
        getNewSignData(anaSearchValue),
    );

    const onActionSearchTimeChange = (dates: any) => {
        setAnaSearchValue({
            ...anaSearchValue,
            begin_time: dates?.[0].startOf('month').format('YYYY-MM-DD'),
            end_time: dates?.[1].endOf('month').format('YYYY-MM-DD'),
        });
        // reload();
    };
    console.log(anaSearchValue, 'anaSearchValue');
    const title = '新签核心指标';
    const renderLoginPageFilter = () => {
        return (
            <div className="ana-filter-content">
                <div className="ana-filter-item">
                    <label htmlFor="time">月份范围：</label>
                    <RangePicker
                        value={[
                            moment(anaSearchValue.begin_time),
                            moment(anaSearchValue.end_time),
                        ]}
                        name="time"
                        showTime={true}
                        placeholder={['开始月份', '结束月份']}
                        format="YYYY-MM"
                        style={{ width: '400px' }}
                        allowClear={false}
                        onOk={reload}
                        onPanelChange={values =>
                            onActionSearchTimeChange(values)
                        }
                        mode={['month', 'month']}
                    />
                </div>
                <Button
                    type="primary"
                    onClick={() => {
                        reload();
                    }}
                >
                    查询
                </Button>
                {anaStore.exportVis && (
                    <Button
                        type="primary"
                        onClick={() => {
                            exportNewSignData(anaSearchValue, title);
                        }}
                    >
                        导出
                    </Button>
                )}
            </div>
        );
    };
    return (
        <div className="analysis-content">
            <LayoutStandardCrud
                title={title}
                message={null}
                popups={null}
                multipleOps={null}
                globalOps={null}
                filters={renderLoginPageFilter()}
                dataTable={
                    <TableLv2<any>
                        loading={loading}
                        dataSource={newSignAnaData}
                        closeRowSelection={true}
                        pagination={false}
                        scroll={{ x: '1660' }}
                        columns={columns}
                    />
                }
            />
        </div>
    );
};

export default observer(NewSignAna);
