import React, { memo, useState, useEffect } from 'react';
import { AutoEllipsis } from '../../auto-ellipsis';
import _ from 'lodash';
import { Modal } from 'antd';

export const Files: React.FC<{ args: any[] }> = memo(({ args }) => {
    const value = args[0];
    const [fileList, setFileList] = useState<any[]>([]);
    useEffect(() => {
        try {
            const parsedfileList = JSON.parse(value || '');
            if (_.isArray(parsedfileList)) {
                setFileList(parsedfileList.filter(f => f.status === 'done'));
            }
        } catch (e) {}
    }, [value]);
    if (!_.isArray(fileList)) {
        return null;
    }
    if (fileList.length === 0) {
        return null;
    }
    if (fileList.length === 1) {
        const file = fileList[0];
        return (
            <AutoEllipsis
                text={
                    <a target="__blank" href={file.url}>
                        {file.name}
                    </a>
                }
            />
        );
    }
    return (
        <a
            onClick={() => {
                Modal.confirm({
                    icon: null,
                    title: '所有附件',
                    content: (
                        <>
                            {fileList.map(file => {
                                return (
                                    <AutoEllipsis
                                        text={
                                            <a target="__blank" href={file.url}>
                                                {file.name}
                                            </a>
                                        }
                                    />
                                );
                            })}
                        </>
                    ),
                });
            }}
        >
            所有附件
        </a>
    );
});
