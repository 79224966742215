import {
    action,
    computed,
    makeAutoObservable,
    makeObservable,
    observable,
    runInAction,
} from 'mobx';
import {
    PaginationStore,
    SorterStore,
    ListMeta,
    MSelectStore,
    FastFilterStore,
    makeDefaultInitailingHook,
} from '@/stores/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import _ from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    commonQueryJob,
    getCommonJobQueryBody,
    TGetCommonReturnType,
} from '@/api/rest';
import { arrToJson, defaultAxios, defaultWritingDataParser } from '@/utils';
import EE from 'eventemitter3';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';
import $ from 'jquery';
import {
    getDefaultCondListByFilterData,
    makeJobDepedStores,
    transformBackEndValidateStateToValidateResult,
} from '../../utils';
import moment from 'moment';
import { makeDefaultInitailingHookB } from '@/stores/utilsA';

export class LeadJobStore {
    public getNewItemText() {
        return '新建任务';
    }

    public get pageTitle() {
        return '全部任务';
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        return getDefaultCondListByFilterData(this);
    }

    public async create() {
        const followCustomer = this.mutatingData?.follow_customer;
        if (_.isArray(followCustomer)) {
            // 展开数据
            const outsideCustomersMap = arrToJson(
                this.mutatingData?._outsideCustomers || [],
                'id',
            );

            const data = _.cloneDeep(this.mutatingDataParsed);
            data.task_type = 1;
            delete data.id;
            delete data.key;
            delete data._outsideCustomers;

            // v1
            // const body = followCustomer.map(curFollowCus => {
            //     return {
            //         ...data,
            //         follow_customer: curFollowCus.originalValue,
            //         executor:
            //             outsideCustomersMap[curFollowCus.originalValue]
            //                 ?.owner_id?.originalValue || null,
            //     };
            // });

            // v2
            const base_data = _.cloneDeep(data);
            delete base_data.follow_customer;
            delete base_data.executor;

            const individuation_data = followCustomer.map(curFollowCus => {
                return {
                    follow_customer: curFollowCus.originalValue,
                    executor:
                        outsideCustomersMap[curFollowCus.originalValue]
                            ?.owner_id?.originalValue || null,
                };
            });

            const [ret, err] = await defaultAxios.post(
                '/crm/task/create/batch/v2',
                {
                    base_data,
                    individuation_data,
                },
            );
            if (!ret || err) {
                return false;
            }

            const { msg, code, data: innerData } = ret;

            if (
                _.isArray(innerData) &&
                innerData.length > 0 &&
                typeof innerData[0] === 'object'
            ) {
                // 如果是数组就是一堆字段报错
                const formTopEle = $('.component-form-lv2')[0];
                if (formTopEle) {
                    (formTopEle as any).___withAutoScroll = true;
                }
                this.setNetValidateResult(
                    transformBackEndValidateStateToValidateResult(innerData),
                );
                message.error('存在校验不通过的字段');
                return false;
            }
            if (+code !== 0) {
                return false;
            }
            this.setNetValidateResult({});
            this.resetAction();
            message.info('批量新建成功');
            this.defaultEE.emit('create-success');
            return true;
        } else {
            const data = _.cloneDeep(this.mutatingDataParsed);
            data.task_type = 1;
            delete data.id;
            delete data.key;

            const [ret, err] = await defaultAxios.post(
                '/crm/task/create',
                data,
            );
            if (!ret || err) {
                return false;
            }

            const { msg, code, data: innerData } = ret;

            if (
                _.isArray(innerData) &&
                innerData.length > 0 &&
                typeof innerData[0] === 'object'
            ) {
                // 如果是数组就是一堆字段报错
                const formTopEle = $('.component-form-lv2')[0];
                if (formTopEle) {
                    (formTopEle as any).___withAutoScroll = true;
                }
                this.setNetValidateResult(
                    transformBackEndValidateStateToValidateResult(innerData),
                );
                message.error('存在校验不通过的字段');
                return false;
            }
            if (+code !== 0) {
                return false;
            }
            this.setNetValidateResult({});
            this.resetAction();
            message.info('新建成功');
            this.defaultEE.emit('create-success');
            return true;
        }
    }

    public data: any[] = [];

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }
    public setAction(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra.id) {
                this.fetchInstantlyMutatingDataById(extra.id);
            } else {
                message.error('修改必须传id');
            }
        }

        if (nextAction === 'cancel') {
            if (extra.id) {
                this.fetchInstantlyMutatingDataById(extra.id);
            } else {
                message.error('取消必须传id');
            }
        }

        this.actionType = nextAction;
    }
    public resetAction() {
        this.netValidateResult = {};
        this.actionType = '';
        this.subActionType = '';
    }
    public subActionType: string = '';
    public setSubActionType(nextAction: string) {
        this.subActionType = nextAction;
    }
    public async dispatchAction() {
        if (this.actionType === 'create') {
            return this.create();
        } else if (this.actionType === 'update') {
            if (this.subActionType === 'complete') {
                return this.complete();
            }
            return this.update();
        }
    }

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;
    get mutatingDataParsed() {
        return defaultWritingDataParser(this.mutatingData);
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        const initialFormData = this.defaultMeta.defaultWidgetValues;
        this.mutatingData = _.cloneDeep(initialFormData);

        this.mutatingData.follow_mode = 10;
        this.mutatingData.follow_target = 1;

        this.mutatingData.priority = 1;
        this.mutatingData.start_time = moment()
            .startOf('day')
            .valueOf();
        this.mutatingData.end_time = moment()
            .endOf('day')
            .valueOf();
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;

    get fieldAuthCode() {
        return this.listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    }

    get dataAuthCode() {
        return this.listType === 'all' ? 'SEARCH-PUBLIC' : 'SEARCH-PRIVATE';
    }

    public async fetchInstantlyMutatingDataById(id: any) {
        const { fieldAuthCode, dataAuthCode } = this;

        this.mutatingDataLoading = true;
        const [d, e] = await commonQueryJob(
            this.defaultMeta.tableId,
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: id,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            undefined,
            dataAuthCode,
        );
        this.mutatingDataLoading = false;

        if (d === null || e !== null) {
            return false;
        }
        const { datas } = d;
        if (datas && datas[0]) {
            this.mutatingData = datas[0];
            return true;
        } else {
            message.error('没有该数据的权限');
            return false;
        }
    }
    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode, dataAuthCode } = this;

        const [d, e] = await commonQueryJob(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            this.defaultMeta.tableColsParamsFromUserSetting.map(
                item => item.key,
            ),
            dataAuthCode,
        );

        if (_.isNil(d) || !_.isNil(e)) {
            return [d, e];
        }
        const leadsData = d;
        const { amount, datas } = leadsData;

        runInAction(() => {
            this.data = datas;
            if (this.defaultPagination) {
                this.defaultPagination.amount = amount;
            }
        });
        return [d, e];
    }

    public async update() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        delete data.key;

        const [ret, err] = await defaultAxios.put('/crm/task/update', data);
        if (!ret || err) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (
            _.isArray(innerData) &&
            innerData.length > 0 &&
            typeof innerData[0] === 'object'
        ) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('修改成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    public async saveDDetail(d: any) {
        const data = _.cloneDeep(defaultWritingDataParser(d));
        delete data.key;

        const [ret, err] = await defaultAxios.put('/crm/task/update', data);
        if (!ret || err) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (
            _.isArray(innerData) &&
            innerData.length > 0 &&
            typeof innerData[0] === 'object'
        ) {
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.defaultEE.emit('update-success');
        return true;
    }

    public async complete() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        delete data.key;

        const [ret, err] = await defaultAxios.put('/crm/task/update', data);
        if (!ret || err) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (
            _.isArray(innerData) &&
            innerData.length > 0 &&
            typeof innerData[0] === 'object'
        ) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }

        const [ret2, err2] = await defaultAxios.put('/crm/task/finish', {
            id: data.id,
        });
        if (!ret2 || err2) {
            return false;
        }

        this.setNetValidateResult({});
        this.resetAction();
        message.info('完成成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    public async delete() {
        this.resetAction();
        return true;
    }

    public async cancel(remark: string) {
        const data = _.cloneDeep(this.mutatingDataParsed);
        // data.canceler_reason = remark
        // delete data.key;

        // const [ret, err] = await defaultAxios.put('/crm/task/update', data);
        // if (!ret || err) {
        //     return false;
        // }

        // const { msg, code, data: innerData } = ret;

        // if (
        //     _.isArray(innerData) &&
        //     innerData.length > 0 &&
        //     typeof innerData[0] === 'object'
        // ) {
        //     // 如果是数组就是一堆字段报错
        //     const formTopEle = $('.component-form-lv2')[0];
        //     if (formTopEle) {
        //         (formTopEle as any).___withAutoScroll = true;
        //     }
        //     this.setNetValidateResult(
        //         transformBackEndValidateStateToValidateResult(innerData),
        //     );
        //     message.error('存在校验不通过的字段');
        //     return false;
        // }
        // if (+code !== 0) {
        //     return false;
        // }

        const [ret2, err2] = await defaultAxios.put('/crm/task/cancel', {
            id: data.id,
            cancelReason: remark,
        });
        if (!ret2 || err2) {
            return false;
        }

        this.setNetValidateResult({});
        this.resetAction();
        message.info('取消成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    get downloadUrl() {
        const { tableId: objectName } = this.defaultMeta;
        const { amount } = this.defaultPagination;
        if (!amount) {
            return '';
        }
        const pageSize = Math.max(Math.min(Math.ceil(amount / 10), 300), 100);
        const fetchBody = getCommonJobQueryBody(
            { ...this.defaultPagination.paginationArgs, pageSize },
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            undefined,
            [
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
            ],
            undefined,
            false,
        );
        fetchBody.objectName = objectName;
        return `${
            window.location.origin
        }/bff/api/rest/export?q=${encodeURIComponent(
            JSON.stringify(fetchBody),
        )}`;
    }

    public defaultPagination: PaginationStore = new PaginationStore();
    public defaultSorter: SorterStore = new SorterStore();
    public defaultMeta: ListMeta = new ListMeta();
    public defaultFilter: FilterStore = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE: EE = new EE();
    public defaultPerm: Perm = new Perm('');
    public defaultMSelect: MSelectStore = new MSelectStore();
    public defaultFastFilters: FastFilterStore = new FastFilterStore();
    public tableCode: string = '';

    initByTableCode(tableCode: string) {
        this.tableCode = tableCode;
        const {
            defaultPagination,
            defaultSorter,
            defaultMeta,
            defaultFilter,
            defaultEE,
            defaultPerm,
            defaultMSelect,
            defaultFastFilters,
        } = makeJobDepedStores(tableCode);
        this.defaultPagination = defaultPagination;
        this.defaultSorter = defaultSorter;
        this.defaultMeta = defaultMeta;
        this.defaultFilter = defaultFilter;
        this.defaultEE = defaultEE;
        this.defaultPerm = defaultPerm;
        this.defaultMSelect = defaultMSelect;
        this.defaultFastFilters = defaultFastFilters;
    }

    constructor() {
        this.initByTableCode('task_activation');
        makeObservable(this, {
            data: observable,
            listType: observable,
            getNewItemText: observable,
            defaultFilters: observable,
            netValidateResult: observable,
            actionType: observable,
            newDataHash: observable,
            mutatingData: observable,
            mutatingDataLoading: observable,
            isFromExsitCustomerTab: observable,

            setListType: action,
            setDefaultFilters: action,
            setNetValidateResult: action,
            setAction: action,
            resetAction: action,
            dispatchAction: action,
            setMutatingData: action,
            startNewData: action,
            startEditing: action,
            clearParamValidateState: action,
            fetchInstantlyMutatingDataById: action,
            fetch: action,
            create: action,
            update: action,
            delete: action,

            pageTitle: computed,
            condListbyFilterData: computed,
            bigFormVisible: computed,
            mutatingDataParsed: computed,
            fieldAuthCode: computed,
            dataAuthCode: computed,
        });
    }
}

export const useAnyLeadJobStore = makeDefaultInitailingHookB(
    LeadJobStore,
    'task_activation',
);
