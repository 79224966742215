import React, { useEffect } from 'react';
import { useAnyLeadStore } from '@/pages/sales-follow-up/lead/list/store';
import OptForm from '@/pages/sales-follow-up/opt/list/components/form-drawer';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Modal } from 'antd';
import { useAnyOptStore } from '@/pages/sales-follow-up/opt/list/store';
import { genCustomerIdParamByCustomerItemAndParamMeta } from '@/pages/sales-follow-up/custom/list/store';
import _ from 'lodash';
import { tryRefresh } from '@/utils';

export const CustomerOptCreate: React.FC<{
    trigger: number;
    customerInfo: any;
    onPrecheckLoadingChange: (nextLoading: boolean) => void;
    onSuccess?: () => void;
}> = observer(
    ({ trigger, customerInfo, onPrecheckLoadingChange, onSuccess }) => {
        customerInfo = customerInfo || {};
        const { id: customer_id } = customerInfo;
        const [prepared, defaultStore, prepareStore] = useAnyOptStore({
            opUserSuffix: 'customer-drawer',
            autoLoad: false,
        });
        const { actionType } = defaultStore;

        useEffect(() => {
            if (trigger > 0) {
                onPrecheckLoadingChange(true);
                prepareStore();
                defaultStore.setAction('pre-create');
            }
        }, [trigger]);

        useEffect(() => {
            if (!prepared || actionType !== 'pre-create') {
                return;
            }
            defaultStore.startNewData();
            const customerId = genCustomerIdParamByCustomerItemAndParamMeta(
                customerInfo,
            );
            if (customerId) {
                const nextMutatingData = _.cloneDeep(defaultStore.mutatingData);
                nextMutatingData.customer_id = customerId;
                defaultStore.setMutatingData(nextMutatingData);
            }
            if (!customer_id) {
                onPrecheckLoadingChange(false);
                return;
            }
            defaultStore
                .askAutoFillingDataByCustomer({
                    originalValue: customer_id,
                })
                .then(ok => {
                    onPrecheckLoadingChange(false);
                    if (!ok) {
                        defaultStore.resetAction();
                        defaultStore.setMutatingData(null);
                    } else {
                        runInAction(() => {
                            defaultStore.setAction('create');
                        });
                    }
                });
        }, [prepared, customer_id, actionType, trigger]);

        if (trigger === 0) {
            return null;
        }

        // 根据外部抽屉数据的状态和内部leadstore准备状态
        const preRequiredReady = customer_id && prepared;
        // return null
        return (
            <OptForm
                defaultStore={defaultStore}
                mutateSuccess={() => {
                    tryRefresh(['customer']);
                    onSuccess && onSuccess();
                }}
                preRequiredReady={preRequiredReady}
                ignoreCtx={true}
            />
        );
    },
);
