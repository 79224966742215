import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
} from '@/components';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet } from '@/utils';
import { observer } from 'mobx-react-lite';
import FormDemoTable from './components/table-for-home-page';
import { runInAction } from 'mobx';
import './index.scss';
import { homePageMetaGetter } from './utils';

const OptList: React.FC<{
    type: string;
}> = ({ type }) => {
    const store = useContext(StoreContext);
    const [defaultStore] = useState(() => new store.OptListStore());
    const userStore = store.getAuthStore();

    const ack = dtext('crm')('opt-list-message-' + type, '');

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        pageTitle,
        defaultPerm,
        defaultFilter,

        actionType,
        defaultMSelect,
        condListbyFilterData,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const LeadListFetch = useCallback(() => defaultStore.fetch(), []);
    const [error, loading, reload] = useNet(LeadListFetch, {
        autoLoad: false,
        refreshKeys: ['opt'],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            defaultMeta.setTableId('opportunity');
            defaultStore.setDefaultFilters([
                {
                    fieldId: 'win_rate',
                    operateType: '>=',
                    filterValue: 1,
                    parser: 'string',
                    widgetKey: 'filterNumberRange',
                },
                {
                    fieldId: 'win_rate',
                    operateType: '<=',
                    filterValue: 99,
                    parser: 'string',
                    widgetKey: 'filterNumberRange',
                },
            ]);
            defaultMeta.setMetaSource(homePageMetaGetter);
            defaultMeta.setOpUsername(userStore.userInfo.userId + '-' + type);
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {}); // 初始化元信息
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useEffect(() => {
        runInAction(() => {
            defaultStore.setListType(type);
            defaultStore.setDefaultFields(['opportunity_sale_stage']);
        });
    }, [type]);

    if (!preRequiredReady) {
        return <BlockLoading2 />;
    }

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const dataTable = (
        <FormDemoTable defaultStore={defaultStore} loading={loading} />
    );

    const layoutComs = {
        popups: null,
        title: pageTitle,
        globalOps: null,
        filters: null,
        multipleOps: null,
        dataTable,
        message: null,
        filtersDisplay,
    };

    return (
        <LayoutStandardCrud className="page-opportunity-list" {...layoutComs} />
    );
};

export const FinalOptListForHomePage = observer(OptList);
