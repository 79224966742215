import { makeAutoObservable } from 'mobx';
import { createDefaultInstanceGetter } from '@/stores/utils';
import { defaultAxios, isUTF8, fixdata, netLog } from '@/utils';
import XLSX from 'xlsx';
import { message } from 'antd';
import jschardet from 'jschardet';
import { IObjMetaData } from './component/data-choose';
export interface IDataManageImportConfigData {
    entityType: {
        label: string;
        key: string;
    }[];
    importType: {
        label: string;
        key: string;
    }[];
    encodingType: {
        label: string;
        key: string;
    }[];
}
export interface IDataSelectFormData {
    entityType?: string;
    importType?: string;
    // encodingType?: string,
    file?: FileList;
}
export class DataManageImportStore {
    public dataManageImportConfig: IDataManageImportConfigData = {
        entityType: [],
        importType: [],
        encodingType: [],
    };
    public configFetchLoading: boolean = false;
    public sheetReadLoading: boolean = false;
    public uploadLoading: boolean = false;
    public dataSelectFormData: IDataSelectFormData = {
        entityType: 'lead',
        importType: '2',
        // encodingType: '',
        file: undefined,
    };
    public sheetJSON: any = {};
    public sheetHeader: string[] = [];
    public finalUploadJSON: any = [];
    public setFinalUploadJSON = (json: any) => {
        this.finalUploadJSON = json;
    };
    public setDataSelectFormData = (formData: IDataSelectFormData) => {
        this.dataSelectFormData = formData;
    };

    public selectedExampleMetaKey: string[] = [];
    public setSelectedExampleMetaKey = (keys: string[]) => {
        this.selectedExampleMetaKey = keys;
    };
    public canEditMetaData: IObjMetaData[] = [];
    public requiredMetaData: IObjMetaData[] = [];
    public allMetaData: IObjMetaData[] = [];
    public fetchMetaDataLoading: boolean = false;
    public clearUploadData = () => {
        this.dataSelectFormData.file = undefined;
        this.sheetHeader = [];
        this.sheetJSON = [];
        this.finalUploadJSON = [];
    };
    public uploadJSONData = async () => {
        const url = '/crm/import/add';
        this.uploadLoading = true;
        const [d, e] = await defaultAxios.post(url, {
            entityCode: this.dataSelectFormData.entityType,
            sourceMap: this.finalUploadJSON,
        });
        this.uploadLoading = false;
        if (d === null || d.data === null) {
            netLog({
                linkUrl: url,
                method: 'post',
                payload: {
                    entityCode: this.dataSelectFormData.entityType,
                },
                isError: true,
                errorMsg: e?.msg || '',
            });
            message.error('上传文件失败，请稍后重试');
            return false;
        }
        if (d.data) {
            message.success('上传文件成功');
            return true;
        } else {
            netLog({
                linkUrl: url,
                method: 'post',
                payload: {
                    entityCode: this.dataSelectFormData.entityType,
                },
                isError: true,
                errorMsg: d.msg || '',
            });
            message.error(d.msg || '上传文件失败，请稍后重试');
            return false;
        }
    };
    public fetchDataChooseMetaData = async () => {
        this.fetchMetaDataLoading = true;
        const url = '/bff/api/rest/om-import/meta-data';
        const [d, e] = await defaultAxios.get(url, {
            entityCode: this.dataSelectFormData.entityType,
        });
        this.fetchMetaDataLoading = false;
        if (d === null || d.data === null) {
            message.error('拉取元数据失败，请稍后重试');
            return [null, e];
        }
        if (d.data.canEditFieldMeta) {
            this.canEditMetaData = d.data.canEditFieldMeta;
        }
        if (d.data.requiredFieldMeta) {
            this.requiredMetaData = d.data.requiredFieldMeta;
        }
        if (d.data.allFieldMeta) {
            this.allMetaData = d.data.allFieldMeta;
        }
    };
    public readSheet = async (cb?: any) => {
        if (this.dataSelectFormData.file?.length) {
            this.sheetReadLoading = true;
            const fileReader = new FileReader();
            const isCSV =
                this.dataSelectFormData.file[0].name.split('.').reverse()[0] ==
                'csv';
            fileReader.readAsBinaryString(this.dataSelectFormData.file[0]);
            // fileReader.readAsText(this.dataSelectFormData.file[0]);
            fileReader.onerror = () => {
                message.error('解析表格失败，请联系技术同学～');
                this.sheetReadLoading = false;
            };

            fileReader.onload = event => {
                try {
                    const result = event.target?.result;
                    let workbook: any;
                    const checkUTF8 = jschardet.detect(result as string);
                    if (checkUTF8.encoding === 'UTF-8') {
                        workbook = XLSX.read(result, { type: 'binary' });
                    } else {
                        workbook = XLSX.read(result, {
                            type: 'binary',
                            codepage: 936,
                        });
                    }
                    // 以二进制流方式读取得到整份excel表格对象
                    // 存储获取到的数据
                    let data: any[] = [];
                    let header: any[] = [];
                    // 遍历每张工作表进行读取（这里默认只读取第一张表）
                    for (const sheet in workbook.Sheets) {
                        // esline-disable-next-line
                        if (workbook.Sheets.hasOwnProperty(sheet)) {
                            header = header.concat(
                                XLSX.utils
                                    .sheet_to_csv(workbook.Sheets[sheet])
                                    .split(/[\s\n]/)[0]
                                    .split(','),
                            );
                            // 利用 sheet_to_json 方法将 excel 转成 json 数据
                            data = data.concat(
                                XLSX.utils.sheet_to_json(
                                    workbook.Sheets[sheet],
                                ),
                            );
                            // break; // 如果只取第一张表，就取消注释这行
                        }
                    }
                    this.sheetReadLoading = false;
                    if (data.length > 3000) {
                        message.error('上传表格的行数不能超过3000');
                        return;
                    }
                    // 最终获取到并且格式化后的 json 数据
                    this.sheetJSON = data;
                    console.log('cb', data);

                    this.sheetHeader = header;
                    if (cb && typeof cb === 'function') {
                        cb();
                    }
                } catch (e) {
                    console.log(e);
                    this.sheetReadLoading = false;
                    // 这里可以抛出文件类型错误不正确的相关提示
                    message.error('解析文件流失败，请联系技术同学～');
                }
            };
        }
    };
    constructor() {
        makeAutoObservable(this);
    }
    public fetchDataImportConfig = async () => {
        this.configFetchLoading = true;
        const url = '/bff/api/rest/om-import/config';
        const [d, e] = await defaultAxios.get(url);
        this.configFetchLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d.data) {
            this.dataManageImportConfig = d.data;
        }
        return [data, error];
    };
}
export const getDefaultDataManageImportStore = createDefaultInstanceGetter(
    DataManageImportStore,
);
