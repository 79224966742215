import { SelectProps, SelectValue } from 'antd/lib/select';
import { Icon, Select } from 'antd';
import React from 'react';
import { getCommonStatus, ICommonProps } from './common';
import { loadingWrapper } from '@/components';
import _ from 'lodash';
const Option = Select.Option;

export type SelectValueType<T = SelectValue> = T;
export type OptionsList<U> = Array<{ name: React.ReactNode; value: U }>;

export interface ISelectOptions<T = SelectValue, U = string | number>
    extends SelectProps {
    options: OptionsList<U>;
    originalOptions?: OptionsList<U>;
    clearable?: boolean;
    hijackOnChange?: (v: SelectValueType, opt: ISelectOptions) => any;
    hijackValue?: (v: any, opt: ISelectOptions) => any;
}

export interface IWidgetSelectProps<T = SelectValue, U = string | number>
    extends ICommonProps<T> {
    value: SelectValueType<T>;
    options: ISelectOptions<T, U>;
    onChange: (val: SelectValueType<T>) => void;
}

export const WidgetSelect: <T extends SelectValue>(
    props: IWidgetSelectProps<T>,
) => React.ReactElement<SelectProps<T>> = ({
    value,
    options,
    onChange,
    status,
    data,
    statusExtraData,
    onInteract = key => void 0,
}) => {
    const { loading, disabled } = getCommonStatus(
        status,
        data,
        statusExtraData,
    );
    const wrapper = loadingWrapper(loading || false);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }

    const {
        options: optionList,
        clearable = true,
        hijackOnChange,
        hijackValue,
    } = options;

    value = hijackValue ? hijackValue(value, options) : value;
    let isValueValid = true;
    if (value && Array.isArray(value)) {
    } else {
        if (!_.find(optionList, item => item.value === value)) {
            isValueValid = false;
        }
    }

    return wrapper(
        <div style={{ width: '100%', minWidth: 150, position: 'relative' }}>
            <Select
                {...options}
                value={isValueValid ? value : undefined}
                onChange={(v: any) => {
                    if (hijackOnChange) {
                        onChange(hijackOnChange(v, options));
                    } else {
                        onChange(v);
                    }
                    onInteract('remove-validate-status');
                }}
                onFocus={() => {
                    onInteract('focus');
                    onInteract('remove-validate-status');
                }}
                onBlur={() => {
                    onInteract('blur');
                    onInteract('validate-instantly');
                }}
            >
                {optionList.map((item: any) => {
                    const { name, value: v, disabled = false } = item;
                    return (
                        <Option
                            key={'op-' + v}
                            value={v}
                            label={name}
                            disabled={disabled}
                        >
                            {name}
                        </Option>
                    );
                })}
            </Select>
            {clearable && (
                <Icon
                    onClick={() => onChange(null as any)}
                    style={{
                        opacity: '0.3',
                        position: 'absolute',
                        right: '36px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        height: '14px',
                        cursor: 'pointer',
                        pointerEvents: disabled ? 'none' : 'unset',
                    }}
                    type="close-circle"
                />
            )}
        </div>,
    );
};
