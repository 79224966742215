import React from 'react';
import {
    DTable,
    applyCustomConfToWidget,
    applyCustomConfToDefaultCellSelector,
    isMultipleCell,
    makeObjColClickable,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ActiveRecordStore } from '../store';
import querystring from 'querystring';

const ARTable: React.FC<{
    defaultStore: ActiveRecordStore;
    loading: boolean;
    reload?: any;
}> = ({ defaultStore, loading, reload }) => {
    const dataTable = (
        <>
            <DTable
                onColumnsUpdated={() => reload && reload()}
                defaultStore={defaultStore}
                sceneCode={'crm_activation'}
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [];
                    props.onAction = (key, data) => {
                        if (_.startsWith(key, 'reveal-obj/')) {
                            if (key.indexOf('/customer_id') !== -1) {
                                window.open(
                                    '/buy/custom/my-custom?' +
                                        querystring.stringify({
                                            id: data.customer_id?.originalValue,
                                            target: 'home_page',
                                        }),
                                    '__blank',
                                );
                            }
                        }
                    };
                    props.widthInfoKey = 'activation-record-list-home';
                    props.afterOpSlot = undefined;
                    props.colDefaultWidth = 180;
                    return props;
                }}
                cellsSelectorTransformer={originalbaiscCellsSelector => {
                    const baiscCellsSelector = [...originalbaiscCellsSelector];
                    makeObjColClickable(baiscCellsSelector, 'customer_id');
                    return baiscCellsSelector;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalARTable = observer(ARTable);
export default FinalARTable;
