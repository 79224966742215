import React, { useEffect, useMemo, useState, useRef } from 'react';

import { Modal, Form, Button, Row, Col, message, Empty } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import _ from 'lodash';
import { useRequest } from 'ahooks';
import {
    fetchBindTagList,
    bindCustomerForPage,
    TagItemInfo,
} from '@/pages/om/tag/list/api';
import SelectGroup from './select-group';

export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    customerIds: (number | string | undefined)[];
    initialValues?: TagItemInfo[];
    onSubmit: () => void;
    onClose: () => void;
}

const FormModalLabel: React.FC<IFormModalProps> = ({
    visible,
    customerIds,
    initialValues,
    form,
    onSubmit,
    onClose,
}) => {
    const selector = useRef<any>({});

    const [submitLoading, setSubmitLoading] = useState(false);

    const { data: tagListData = [], loading } = useRequest(() =>
        fetchBindTagList(),
    );

    useEffect(() => {
        if (!initialValues) return;
        selector.current = initialValues?.reduce((prev, current) => {
            prev[current.group_id!] = prev[current.group_id!]
                ? [...prev[current.group_id!], current.id]
                : [current.id];
            return prev;
        }, {} as any);
    }, [initialValues]);

    const multiple = useMemo(() => customerIds.length > 1, [customerIds]);

    const handleChangeTag = (value: any, id: string) => {
        selector.current = { ...selector.current, [id]: value };
    };

    const handleSubmit = async () => {
        let labelList: (number | string)[] = [];
        if (_.isEmpty(selector.current)) {
            onClose();
            return;
        }
        Object.keys(selector.current).map((item: any) => {
            if (_.isEmpty(selector.current[item])) return;
            labelList.push(...selector.current[item]);
        });
        const params = {
            entity_id_list: customerIds,
            entity_code: 'customer',
            label_list: labelList,
        };
        setSubmitLoading(true);
        await bindCustomerForPage(params);
        setSubmitLoading(false);
        message.success(multiple ? '批量绑定标签成功' : '绑定标签成功');
        onSubmit();
    };

    return (
        <Modal
            visible={visible}
            title={multiple ? '批量绑定标签' : '绑定标签'}
            width={'600px'}
            bodyStyle={{ maxHeight: '350px', overflowY: 'auto' }}
            destroyOnClose
            onCancel={onClose}
            onOk={handleSubmit}
            confirmLoading={submitLoading}
        >
            {multiple && (
                <p>
                    绑定标签：共选择了
                    <span style={{ color: 'red', fontWeight: 'bold' }}>
                        {customerIds.length}
                    </span>
                    个客户，将为这些客户批量绑定标签
                </p>
            )}

            <Form layout="horizontal">
                {tagListData?.length > 0 ? (
                    tagListData.map((group, index) => {
                        return group.items?.length ? (
                            <Form.Item
                                key={index}
                                label={`${group.name}${
                                    group.option === 2 ? '（多选）' : ''
                                }`}
                                required={group.can_empty === 0}
                            >
                                <SelectGroup
                                    defaultValue={initialValues
                                        ?.filter(
                                            item => item.group_id === group.id,
                                        )
                                        .map(item => Number(item.id))}
                                    multiple={group.option === 2}
                                    required={group.can_empty === 0}
                                    option={group.items?.map(tag => ({
                                        value: tag.id,
                                        label: tag.name,
                                        comment: tag.comment,
                                    }))}
                                    onChange={v => {
                                        handleChangeTag(v, group.id);
                                    }}
                                />
                            </Form.Item>
                        ) : null;
                    })
                ) : (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span>暂无标签数据</span>}
                    />
                )}
            </Form>
        </Modal>
    );
};

export default Form.create<IFormModalProps>()(FormModalLabel);
