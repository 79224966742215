import * as React from 'react';
import { useState, CSSProperties } from 'react';
import './index.scss';
import { message } from 'antd';

export interface IItemsPhoneProps {
    num: number | string; // 真实电话
    showText?: string; // 展示的文本
    onClick?: (num: number | string) => void;
    mergeClick?: boolean;
    style?: CSSProperties;
    className?: string;
}
/**
 *
 * @param onClick function 默认内部封装了click回调,传入的onclick会根据mergeClick的不同而以不同的方式调用
 * @param mergeClick boolean true为跟内部回调一起调用，false为覆盖
 */
const ItemPhone: React.FC<IItemsPhoneProps> = ({
    mergeClick = true,
    num,
    style = {},
    onClick,
    className = '',
    showText = '',
}) => {
    console.log(num);
    const defaultPhoneClickHandle = () => {
        message.success('click');
    };
    const phoneClickHandle = () => {
        if (onClick && typeof onClick === 'function') {
            if (mergeClick) {
                defaultPhoneClickHandle();
                onClick(num);
            } else {
                onClick(num);
            }
        } else {
            defaultPhoneClickHandle();
        }
    };
    return (
        <span
            className={`crm-phone-items ${className}`}
            style={style}
            onClick={phoneClickHandle}
        >
            {showText ? showText : num}
        </span>
    );
};

export default ItemPhone;
