import { ColumnProps } from 'antd/lib/table';
import { arrToJson, arrToSortHelper, sortArrBySortHelper } from '@/utils';
import _ from 'lodash';
import { getDefaultParamPerm } from '@/utils/param';
import { param } from 'jquery';

export const applyParamListToColumns = <T>(
    columns: Array<ColumnProps<T>>,
    params: IParamItem[],
): Array<ColumnProps<T>> => {
    const nextColumns: Array<ColumnProps<T>> = [];
    const paramsMap = arrToJson(params, 'key');
    for (const col of columns) {
        if (_.isNil(col.key)) {
            continue;
        }
        const currentParam = paramsMap[col.key];
        if (_.isNil(currentParam)) {
            continue;
        }
        const paramPerm = getDefaultParamPerm(currentParam.perm);
        if (!paramPerm.visible) {
            continue;
        }
        const nextColItem = _.assign({}, col);
        nextColumns.push(nextColItem);
    }

    const sortHelper = arrToSortHelper(params, 'key');
    console.log('vaefrabaerta', 2, params, sortHelper);
    // sortHelper.id = -1;
    return _.filter(
        sortArrBySortHelper(nextColumns, sortHelper, 'key'),
        _.isObject,
    );
};
