import React, {
    useEffect,
    useState,
    useContext,
    useCallback,
    useLayoutEffect,
} from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    AntButton,
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
} from '@/components';
import { Menu, Icon, message, Tooltip, Spin, Alert } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, tryRefresh } from '@/utils';
import { observer } from 'mobx-react-lite';
import { AntDrawer } from '@/components/antd/drawer';
import CustomerForm from '@/pages/sales-follow-up/custom/list/components/form';
import { defaultAuthDeco } from '@/containers/hoc';
import { type } from 'jquery';
import { OptListStore } from '../store';
import FormDemoForm from './form';
import { CtxContext } from '@/utils/context';

const FormDrawer: React.FC<RouteComponentProps & {
    mutateSuccess?: any;
    keepData?: boolean;
    defaultStore: OptListStore;
    preRequiredReady: boolean;
    ignoreCtx?: boolean;
}> = ({
    defaultStore,
    keepData = false,
    preRequiredReady,
    mutateSuccess,
    ignoreCtx = false,
}) => {
    const ctx = useContext(CtxContext);
    const { position, positionDetail } = ctx;
    const leadTrans = ignoreCtx
        ? false
        : positionDetail === 'lead-drawer' ||
          positionDetail === 'lead-table' ||
          positionDetail === 'customer-drawer';

    const { actionType, bigFormVisible, mutatingDataLoading } = defaultStore;
    const [loading, setLoading] = useState(false);
    if (!preRequiredReady) {
        return null;
    }

    const popups = (
        <>
            <AntDrawer
                title={
                    leadTrans
                        ? '线索转商机'
                        : actionType === 'create'
                        ? '新建商机'
                        : '修改商机'
                }
                placement={'right'}
                visible={bigFormVisible}
                onClose={() => {
                    defaultStore.resetAction();
                    // if (!keepData) {
                    //     defaultStore.setMutatingData(null);
                    // }
                    defaultStore.setMutatingData(null);
                }}
                bodyStyle={{
                    width: 1050,
                    overflow: 'scroll',
                }}
                width={1050 + 'px'}
            >
                <div className="standard-drawer-form-wrapper">
                    {mutatingDataLoading ? (
                        <BlockLoading2 />
                    ) : (
                        <FormDemoForm
                            ignoreCtx={ignoreCtx}
                            defaultStore={defaultStore}
                        />
                    )}
                    <div className="fixed-form-btns">
                        <AntButton
                            size="large"
                            type="primary"
                            loading={loading}
                            onClick={async () => {
                                setLoading(true);
                                const isSuccess = await defaultStore.dispatchAction();
                                setLoading(false);
                                if (isSuccess) {
                                    tryRefresh(['opt']);
                                    mutateSuccess && mutateSuccess();
                                    defaultStore.setMutatingData(null);
                                }
                            }}
                        >
                            {actionType === 'create' ? '新建' : '修改'}
                        </AntButton>
                        <AntButton
                            size="large"
                            onClick={() => defaultStore.resetAction()}
                        >
                            取消
                        </AntButton>
                    </div>
                </div>
            </AntDrawer>
        </>
    );

    return popups;
};

const FinalFormDrawer = defaultAuthDeco(observer(FormDrawer));
export default FinalFormDrawer;
