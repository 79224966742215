import React, { useState } from 'react';
import { Input, Form, message, Button, Radio } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { mutate } from '@/api/graphql';
import _ from 'lodash';

import './index.less';

interface AddContactProps extends FormComponentProps {
    customerId?: string | number;
    onSuccess?: () => void;
    onClose?: () => void;
}

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
};

const AddContact: React.FC<AddContactProps> = ({
    form,
    customerId,
    onSuccess,
    onClose,
}) => {
    const { getFieldDecorator, validateFields, resetFields } = form;
    const [isAllowed, setIsAllowed] = useState<number>(1);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!customerId) return;
        validateFields(async (errs, values) => {
            if (errs) return;

            const payload = {
                customer_id: customerId,
                contacts_source: 1, // 手工新建
                ...values,
            };

            const [ret, errMsg] = await mutate('contacts', 'insert', payload);
            if (!ret || errMsg) {
                return false;
            }
            const { msg, code, data: innerData } = ret;

            if (_.isArray(innerData) && innerData.length > 0) {
                // 如果是数组就是一堆字段报错
                message.error('存在校验不通过的字段');
                return;
            }
            if (+code !== 0) {
                return message.error(msg);
            }
            resetFields();
            onSuccess && onSuccess();
            message.info('新建成功');
        });
    };

    return (
        <Form
            className="add-contact-form"
            hideRequiredMark
            {...formItemLayout}
            onSubmit={handleSubmit}
        >
            <Form.Item label="姓名">
                {getFieldDecorator('contacts_name', {
                    rules: [{ required: true, message: '请输入姓名' }],
                })(<Input placeholder="请输入姓名" />)}
            </Form.Item>
            <Form.Item label="职位">
                {getFieldDecorator('contacts_duty', {
                    rules: [{ required: true, message: '请输入职务' }],
                })(<Input placeholder="请输入职务" />)}
            </Form.Item>
            <Form.Item label="手机">
                {getFieldDecorator('contacts_phone')(
                    <Input placeholder="请输入号码" />,
                )}
            </Form.Item>
            <Form.Item label="座机">
                {getFieldDecorator('contacts_telephone')(
                    <Input placeholder="请输入号码" />,
                )}
            </Form.Item>
            <Form.Item label="是否允许打电话">
                <Radio.Group
                    onChange={e => setIsAllowed(e.target.value)}
                    value={isAllowed}
                >
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>
            </Form.Item>
            <div className="add-contact-form-control">
                <Button type="link" onClick={() => onClose && onClose()}>
                    取消
                </Button>
                <Button type="link" htmlType="submit">
                    提交
                </Button>
            </div>
        </Form>
    );
};

export default Form.create<AddContactProps>()(AddContact);
