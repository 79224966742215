import * as React from 'react';
import { useState, useEffect, useMemo, useContext } from 'react';
import { ICrmTimelineProps, CrmTimeline } from '../../timeline';
import _ from 'lodash';
import ItemUser, { IItemsUserProps } from '../../items/user/index';
import './index-new.scss';
import { ICustomerActionLogDailyData } from '../../customer-drawer/customer-action-log-new';
import { ICrmTimelineItemProps } from '../../timeline/timeline-items';
import moment from 'moment';
import {
    Icon,
    message,
    Dropdown,
    Menu,
    Input,
    Button,
    Spin,
    Collapse,
} from 'antd';
import { commonDelete } from '@/api/rest';
import { defaultAxios, useNet } from '@/utils';
import { AutoLink } from '../../auto-link';
import { AntButton } from '@/components/antd';
import { Modal } from 'antd';
import RecordBtn from './record-btn/index';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import {
    Key2LabelOfNew,
    Key2LabelOfOld,
} from '../../customer-drawer/customer-info-card';
import { StoreContext } from '@/stores';
import CollapsePanel, {
    CollapsePanelProps,
} from 'antd/lib/collapse/CollapsePanel';

export interface ICustomerDrawerTimeLineItems
    extends ICustomerActionLogDailyData { }
export interface ICustomerDrawerTimelineItemGroupProps {
    day: string;
    timelineItems: ICustomerDrawerTimeLineItems[];
}
export interface ICustomerDrawerTimeLineProps {
    groups: ICustomerDrawerTimelineItemGroupProps[];
    showCount?: number;
    actionLogConfig: {
        arType: any[];
        followType: any[];
    };
    reloadFunc?: () => void;
    isNewCustomer?: number;
}
export type TQueryHistoryCommentReturnType = NetResponse<{
    data: any[];
    msg: string;
    code: number;
}>;

const toLowerLine = (str: string) => {
    let temp = str.replace(/[A-Z]/g, function (match) {
        return '_' + match.toLowerCase();
    });
    if (temp.slice(0, 1) === '_') {
        //如果首字母是大写，执行replace时会多一个_，这里需要去掉
        temp = temp.slice(1);
    }
    return temp;
};

const MyCollapsePanel: React.FC<CollapsePanelProps & {
    isActive?: boolean;
    permFollow?: boolean;
    setFollowLogModalVis?: Function;
}> = props => {
    const renderHeader = () => {
        const { header, isActive } = props;
        return (
            <div className="title">
                <div
                    className="title-content"
                // onClick={() => SetIsActive(!isActive)}
                >
                    {header}
                    <Icon
                        style={{
                            color: '#D1D3DE',
                            marginLeft: '5px',
                        }}
                        type="caret-right"
                        rotate={isActive ? 90 : 0}
                    />
                </div>
            </div>
        );
    };
    return (
        <CollapsePanel showArrow={false} {...props} header={renderHeader()} />
    );
};

const TimelineCard: React.FC<ICustomerDrawerTimeLineItems & {
    isNewCustomer?: number;
    actionLogConfig?: any;
    perm?: Record<string, boolean>;
}> = props => {
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const sysUser = authStore.userInfo?.sysUser || {};

    // let timelineItems = _.cloneDeep(props);
    let timelineItems = props;
    const { isNewCustomer, actionLogConfig } = timelineItems;
    const Key2Label = isNewCustomer === 1 ? Key2LabelOfNew : Key2LabelOfOld;
    const customerInfoShow = useMemo(() => {
        return Object.keys(Key2Label).some((key: string) => {
            const lineKey = toLowerLine(key);
            if (!(timelineItems as any)[lineKey]) {
                return false;
            }
            if (lineKey === 'contacts_id') {
                return false;
            }
            if (lineKey === 'relate_results') {
                return false;
            }
            return true;
        });
    }, [timelineItems]);

    const [visibleModal, setVisibleModal] = useState(false);
    const [showComment, setShowComment] = useState(false);

    const [content, setContent] = useState('');
    // const [loading, setLoading] = useState<boolean>(true);
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [history, setHistory] = useState<any[]>([]);
    const [isWellReceived, setIsWellReceived] = useState<string | number>(
        timelineItems?.is_well_received || 0,
    );
    const [thumbsDownNum, setThumbsDownNum] = useState<number>(
        timelineItems?.thumbs_up_num || 0,
    );
    const [thumbsUpNum, setThumbsUpNum] = useState<number>(
        timelineItems?.thumbs_down_num || 0,
    );
    const [activationEvaluate, setActivationEvaluate] = useState<string>(
        timelineItems?.activation_evaluate || '',
    );
    const [evaluateUser, setEvaluateUser] = useState<string>(
        timelineItems?.evaluate_user || '',
    );
    const [evaluateTime, setEvaluateTime] = useState<string | number>(
        timelineItems?.evaluate_time || 0,
    );
    let isMounted = true;
    useEffect(() => {
        setIsWellReceived(timelineItems?.is_well_received || 0);
        setThumbsUpNum(timelineItems?.thumbs_up_num || 0);
        setThumbsDownNum(timelineItems?.thumbs_down_num || 0);
        setActivationEvaluate(timelineItems?.activation_evaluate || '');
        setEvaluateUser(timelineItems?.evaluate_user || '');
        setEvaluateTime(timelineItems?.evaluate_time || 0);
        // setActivationEvaluate({
        //     activation_evaluate: timelineItems?.activation_evaluate || '',
        //     evaluate_user: timelineItems?.evaluate_user || '',
        //     evaluate_time: timelineItems?.evaluate_time || 0,
        // });
    }, [timelineItems]);

    // useEffect(() => {
    //     queryHistoryComment();
    // }, []);

    // const [activationEvaluate, setActivationEvaluate] = useState<any[]>(
    //     timelineItems?.activation_evaluate || [],
    // );

    const thumbsUp = (thumbsUpType: number) => {
        //
        return async () => {
            const [d, e] = await defaultAxios.post(
                '/crm/thumbsUp',
                {
                    activationId: timelineItems.id,
                    thumbsUpType,
                },
                { timeout: 100000 }, //可能要删
            );
            if (d === null || d.data === null || e) {
                if (e.message.includes('timeout')) {
                    message.error('请求超时，请刷新页面');
                }
                message.error('评价失败');
                return;
            }
            if (d.data === 0) {
                message.warn('您已对该活动记录进行过评价，不可再次评价');
                return;
            }
            message.success('评价成功');
            setIsWellReceived(thumbsUpType);
            // timelineItems = {
            //     ...timelineItems,
            //     is_well_received: thumbsUpType,
            // };
            if (thumbsUpType === 1) {
                setThumbsUpNum(thumbsUpNum + 1);
                // timelineItems = {
                //     ...timelineItems,
                //     thumbs_up_num: thumbsUpNum + 1,
                // };
            } else {
                setThumbsDownNum(thumbsDownNum + 1);
                // timelineItems = {
                //     ...timelineItems,
                //     thumbs_down_num: thumbsDownNum + 1,
                // };
            }
        };
    };
    const queryHistoryComment: () => Promise<
        TQueryHistoryCommentReturnType
    > = async () => {
        const [d, e] = await defaultAxios.post('/crm/evaluate/query', [
            timelineItems.id,
        ]);
        if (d === null || d.data === null || e) {
            return [null, e];
        }
        if (d.data && Array.isArray(d.data)) {
            setHistory(d.data.slice(1));
        }
        return [d.data, e];
    };
    const [error, loading, reload] = useNet(queryHistoryComment, {
        defaultLoading: false,
    });
    const evaluateSend = async () => {
        const [d, e] = await defaultAxios.post(
            '/crm/evaluate',
            {
                evaluate: content,
                activationId: timelineItems.id,
                sort: 0,
                parentId: 0,
            },
            { timeout: 100000 }, //可能要删
        );
        if (d === null || d.data === null || e) {
            if (e.message.includes('timeout')) {
                message.error('请求超时，请刷新页面');
            }
            message.error('评论失败');
            return null;
        }
        message.success('评论成功');
        reload();
        setActivationEvaluate(content);
        setEvaluateTime(moment().valueOf());
        setEvaluateUser(sysUser.name);
        setContent('');
    };

    const handleCloseModal = () => {
        setVisibleModal(false);
    };

    const contactsDuty =
        timelineItems?.relation_object_map?.contacts_id?.contacts_duty;

    return (
        <div className="drawer-time-line-body">
            <Modal
                title={moment(timelineItems.create_time).format(
                    'YYYY-MM-DD HH:mm:ss',
                )}
                visible={visibleModal}
                width={650}
                onOk={handleCloseModal}
                onCancel={handleCloseModal}
            >
                <div
                    style={{
                        whiteSpace: 'pre-line',
                        lineHeight: '24px',
                        maxHeight: '400px',
                        overflowY: 'auto',
                    }}
                    dangerouslySetInnerHTML={{
                        __html: timelineItems.call_content || '',
                    }}
                />
            </Modal>

            {timelineItems.opportunity_name && (
                <div className="activity-record-item">
                    <div className="item-header">商机</div>
                    <div className="item-content">
                        {timelineItems.opportunity_name}{' '}
                        {timelineItems.after_win_rate
                            ? `${timelineItems.after_win_rate}赢率`
                            : ''}
                    </div>
                </div>
            )}
            {timelineItems.contacts_name && (
                <div className="activity-record-item">
                    <div className="item-header">联系人</div>
                    <div className="item-content">
                        {timelineItems.contacts_name}
                        {contactsDuty ? `（${contactsDuty}）` : ''}{' '}
                        {actionLogConfig?.['isDecision']?.[
                            (timelineItems as any)['is_decision'] - 1
                        ]?.label || ''}
                    </div>
                </div>
            )}
            {timelineItems.follow_type === 10 &&
                (timelineItems.call_time_length as number) > 45 && (
                    <div className="activity-record-item">
                        <div className="item-header">通话时间</div>
                        <div className="item-content">
                            {`${timelineItems.call_time_length}秒`}
                        </div>
                    </div>
                )}
            {timelineItems.note && (
                <div className="activity-record-item">
                    <div className="item-header">活动记录内容</div>
                    <div className="item-content">
                        <AutoLink text={timelineItems.note} />
                    </div>
                </div>
            )}
            {timelineItems.next_date && (
                <div className="next-plan">
                    <div className="activity-record-item">
                        <div className="item-header">下次跟进时间</div>
                        <div className="item-content">
                            {moment(timelineItems.next_date).format(
                                'YYYY-MM-DD HH:mm:ss',
                            )}
                        </div>
                    </div>
                </div>
            )}
            {timelineItems.next_note && (
                <div className="activity-record-item">
                    <div className="item-header">下次跟进内容</div>
                    <div className="item-content">
                        {timelineItems.next_note}
                    </div>
                </div>
            )}
            {timelineItems.schedule_date && (
                <div className="activity-record-item">
                    <div className="item-header">备忘时间</div>
                    <div className="item-content">
                        {moment(timelineItems.schedule_date).format(
                            'YYYY-MM-DD HH:mm:ss',
                        )}
                    </div>
                </div>
            )}
            {timelineItems.schedule_note && (
                <div className="activity-record-item">
                    <div className="item-header">备忘内容</div>
                    <div className="item-content">
                        {timelineItems.schedule_note}
                    </div>
                </div>
            )}

            {timelineItems.image && (
                <div className="activity-record-item">
                    <div className="item-header">图片记录</div>
                    <div className="item-content">
                        <div className="new-photo-provider">
                            <PhotoProvider>
                                {timelineItems.image.split(',').map(item => {
                                    return (
                                        <PhotoView src={item} key={item}>
                                            <div
                                                style={{
                                                    paddingBottom: '28%',
                                                    height: '0',
                                                    width: '28%',
                                                    marginRight: '5%',
                                                    position: 'relative',
                                                }}
                                            >
                                                <img
                                                    src={item}
                                                    style={{
                                                        position: 'absolute',
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                            </div>
                                        </PhotoView>
                                    );
                                })}
                            </PhotoProvider>
                        </div>
                    </div>
                </div>
            )}
            {/* {timelineItems.follow_target && (
                <div className="activity-record-item">
                    <div className="item-header">跟进目的</div>
                    <div className="item-content">
                        {
                            actionLogConfig?.['followTarget']?.[
                                timelineItems.follow_target - 1
                            ]?.label
                        }
                    </div>
                </div>
            )} */}
            {timelineItems.relate_results && (
                <div className="activity-record-item">
                    <div className="item-header">联系结果</div>
                    <div className="item-content">
                        {
                            actionLogConfig?.['relateResults']?.[
                                timelineItems.relate_results - 1
                            ]?.label
                        }
                    </div>
                </div>
            )}
            {!_.isNil(isNewCustomer) && customerInfoShow && (
                <div>
                    <Collapse
                        className="customer-info-collapse"
                        bordered={false}
                        // defaultActiveKey={['customer_info']}
                        expandIcon={({ isActive }) => (
                            <Icon
                                type="caret-right"
                                rotate={isActive ? 90 : 0}
                            />
                        )}
                    >
                        <MyCollapsePanel
                            header="客户信息采集"
                            key="customer_info"
                        >
                            {Object.keys(Key2Label).map((key: string) => {
                                const lineKey = toLowerLine(key);
                                if (!(timelineItems as any)[lineKey]) {
                                    return;
                                }
                                if (lineKey === 'contacts_id') {
                                    return;
                                }
                                if (lineKey === 'relate_results') {
                                    return;
                                }
                                const res =
                                    actionLogConfig?.[key]?.[
                                        (timelineItems as any)[lineKey] - 1
                                    ]?.label || '';
                                return (
                                    <div
                                        className="activity-record-item"
                                        key={key}
                                    >
                                        <div className="item-header">
                                            {Key2Label[key]}
                                        </div>
                                        <div className="item-content">
                                            {res}
                                        </div>
                                    </div>
                                );
                            })}
                        </MyCollapsePanel>
                    </Collapse>
                </div>
            )}
            {/* <div className="follow-update-time">
                创建时间：
                <em>
                    {timelineItems.create_time
                        ? moment(timelineItems.create_time).format(
                              'YYYY-MM-DD HH:mm:ss',
                          )
                        : '无'}
                </em>
            </div> */}
            <div className="icon-box">
                {timelineItems.call_file_id && (
                    <>
                        <RecordBtn call_file_id={timelineItems.call_file_id} />
                        {props.perm?.[
                            'sales-follow-up:activity:call_content'
                        ] && (
                                <Icon
                                    type="profile"
                                    style={{
                                        float: 'left',
                                        color: timelineItems.call_content
                                            ? '#0052ff'
                                            : '',
                                        lineHeight: '21px',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                    }}
                                    onClick={() => {
                                        if (!timelineItems.call_content) return;
                                        setVisibleModal(true);
                                    }}
                                />
                            )}
                    </>
                )}
                <Icon
                    type="like"
                    theme="filled"
                    className={`icon-box-item iconfont${isWellReceived === 1 ? ' active' : ''
                        }`}
                    onClick={thumbsUp(1)}
                ></Icon>
                <span className="icon-box-item number">
                    {isWellReceived === 1 && !thumbsUpNum
                        ? 1
                        : thumbsUpNum || 0}
                </span>
                <Icon
                    type="dislike"
                    theme="filled"
                    className={`icon-box-item iconfont${isWellReceived === 2 ? ' active' : ''
                        }`}
                    onClick={thumbsUp(2)}
                ></Icon>
                <span className="icon-box-item number">
                    {isWellReceived === 2 && !thumbsDownNum
                        ? 1
                        : thumbsDownNum || 0}
                </span>
                <Icon
                    type="message"
                    className="icon-box-item iconfont"
                    onClick={() => {
                        setShowComment(true);
                    }}
                />
            </div>
            {activationEvaluate && (
                // <>
                //     <div className="follow-comment">
                //         {evaluateUser}：<em>{activationEvaluate}</em>
                //     </div>
                //     <div className="follow-update-time">
                //         评语时间：
                //         <em>
                //             {moment(evaluateTime).format('YYYY-MM-DD HH:mm:ss')}
                //         </em>
                //     </div>
                // </>
                <div className="follow-comment">
                    <div className="follow-comment-header">
                        {evaluateUser}
                        <span className="follow-comment-header-time">
                            {moment(evaluateTime).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                    </div>
                    <div className="follow-comment-content">
                        {activationEvaluate}
                    </div>
                </div>
            )}
            <Spin spinning={loading}>
                {loading && <div className="follow-placeholder"></div>}
                {showHistory &&
                    history.map(item => {
                        return (
                            <div className="follow-comment">
                                <div className="follow-comment-header">
                                    {item?.evaluateUserName}
                                    <span className="follow-comment-header-time">
                                        {moment(item?.createTime).format(
                                            'YYYY-MM-DD HH:mm:ss',
                                        )}
                                    </span>
                                </div>
                                <div className="follow-comment-content">
                                    {item?.evaluate}
                                </div>
                            </div>
                        );
                    })}
                {history.length > 0 && (
                    <div
                        className="follow-show-history"
                        onClick={() => {
                            // 一开始和发表评论时都获取了最新的评论记录
                            // if (!showHistory) {
                            //     queryShowHistory();
                            // }
                            setShowHistory(!showHistory);
                        }}
                    >
                        {showHistory
                            ? `收起评论`
                            : `查看剩余${history.length}条评论`}
                    </div>
                )}
            </Spin>
            {showComment && (
                <Input
                    placeholder="输入评语（最多255字）"
                    value={content}
                    onChange={node => {
                        setContent(node.target.value);
                    }}
                    suffix={
                        <AntButton type="primary" onClick={evaluateSend}>
                            评论
                        </AntButton>
                    }
                    style={{ marginTop: '10px' }}
                />
            )}
        </div>
    );
};

const DrawerTimeLine: React.FC<ICustomerDrawerTimeLineProps> = props => {
    const { showCount, actionLogConfig, reloadFunc, groups } = props;
    console.log('groups', groups);

    const [orignalPerm, setOrignalPerm] = useState<Record<string, boolean>>();

    useEffect(() => {
        fetchActionLogAuth();
    }, []);

    const fetchActionLogAuth = async () => {
        const url = '/bff/api/rest/perm?mod=sales-follow-up:activity';
        const [d, e] = await defaultAxios.get(url);
        if (d && d.data && d.data?.length) {
            const perm = d.data.reduce(
                (result: Record<string, boolean>, item: any) => {
                    if (item.visible) {
                        result[item.key] = true;
                    }
                    return result;
                },
                {},
            );
            setOrignalPerm(perm);
        }
    };
    // 删除活动记录
    const deleteActionLogNet = async (id: string | number) => {
        const [d, e] = await commonDelete('cu_activation_record', [
            id as number,
        ]);
        if (!e) {
            reloadFunc && reloadFunc();
            message.info('删除成功');
            return true;
        }
    };
    // timeline-items生成器
    const makeTimeLineItems: () => ICrmTimelineProps = () => {
        const { groups, isNewCustomer } = props;
        const ATimelineConfig = _.clone(groups);
        console.log('ATimelineConfig', ATimelineConfig);

        const renderBody = (
            timelineItems: ICustomerDrawerTimeLineItems,
            isNewCustomer?: number,
        ) => {
            return (
                <TimelineCard
                    {...timelineItems}
                    isNewCustomer={isNewCustomer}
                    actionLogConfig={actionLogConfig}
                />
            );
        };
        ATimelineConfig.forEach((dayItems, idx) => {
            dayItems.timelineItems = dayItems.timelineItems.map(
                (timelineItem, idx) => {
                    const ATimelineItems: ICrmTimelineItemProps = {
                        ...timelineItem,
                        orignalPerm,
                        reloadFunc,
                        content: (
                            <div className="drawer-time-line-content">
                                <div className="drawer-time-line-header">
                                    <ItemUser
                                        avatarConfig={{
                                            src: '',
                                        }}
                                        name={timelineItem.create_user_name}
                                    />
                                    <span className="describe">
                                        发布了
                                        <span
                                            style={{
                                                margin: '0 2px',
                                                color: '#15161f',
                                            }}
                                        >
                                            {_.find(
                                                actionLogConfig.followType,
                                                item => {
                                                    return (
                                                        item.value ==
                                                        timelineItem.follow_type
                                                    );
                                                },
                                            )?.label || '- -'}
                                        </span>
                                        活动记录
                                    </span>
                                    <span className="delete">
                                        {timelineItem &&
                                            timelineItem.is_valid !== null && (
                                                <img
                                                    className="delete-icon"
                                                    src={
                                                        timelineItem.is_valid ===
                                                            1
                                                            ? `${process.env.PUBLIC_URL}/imgs/youxiao.png`
                                                            : `${process.env.PUBLIC_URL}/imgs/wuxiao.png`
                                                    }
                                                    alt="是否有效标记"
                                                />
                                            )}
                                    </span>
                                </div>
                                {/* {renderBody(timelineItem, isNewCustomer)} */}
                                <TimelineCard
                                    {...timelineItem}
                                    perm={orignalPerm}
                                    isNewCustomer={isNewCustomer}
                                    actionLogConfig={actionLogConfig}
                                />
                            </div>
                        ),
                        time: moment(timelineItem.create_time).format(
                            'HH:mm:ss',
                        ),
                        background: timelineItem?.background || '',
                        // icon: <Icon type={timelineItem.icon || ''} /> || '',
                        icon: timelineItem.icon || '',
                        id: timelineItem.id || 0,
                    };
                    return ATimelineItems as ICustomerDrawerTimeLineItems;
                },
            );
        });
        return { groups: ATimelineConfig } as ICrmTimelineProps;
    };

    return (
        <CrmTimeline
            isNew={true}
            showCount={showCount}
            {...makeTimeLineItems()}
        />
    );
};

export default React.memo(DrawerTimeLine);
