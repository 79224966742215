import React, { memo } from 'react';
import moment from 'moment';
import { AutoEllipsis } from '../../auto-ellipsis';

export const DefaultMoment: React.FC<{ args: [number] }> = memo(
    ({ args: [ts] }) => {
        if (!ts) {
            return null;
        }
        const text = moment(ts).format('YYYY-MM-DD');
        return <AutoEllipsis text={text} />;
    },
);
