import React from 'react';
import { RouteComponentProps } from '@reach/router';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import './index.scss';
import ProcessCatListAny from '@/pages/sales-follow-up/process/list/category-any.tsx';

const DemoList: React.FC<RouteComponentProps & {
    type: string;
}> = () => {
    return <ProcessCatListAny processKey="contractinvoice" title="发票" />;
};

const FinalDemoList = observer(DemoList);
export default FinalDemoList;
