import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    AntButton,
} from '@/components';
import { message } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet } from '@/utils';
import { observer } from 'mobx-react-lite';
import DemoTable from './components/table';
import { EquityDetail } from '@/pages/sales-follow-up/opt/opt-product/components/equity-detail';
import { runInAction } from 'mobx';
import { dFilterHOC, useDefaultDFilterSnippets } from '@/containers/hoc';
import './index.scss';
import { useAnyProductStore } from '@/pages/om/product/list/store';
import {
    useAnyContractListStore,
    genContractIdParamByContractItemAndParamMeta,
} from '@/pages/om/contract/list/store';
import FormDrawer from '@/pages/om/contract/list/components/form-drawer';
import { ActionDelete } from '@/pages/om/contract/list/components/delete';
import { ActionPreOpen } from '@/pages/om/contract/list/components/pre-openproduct';
import { CtxProvider } from '@/utils/context';
import { FinalProcessFormProxy } from '@/pages/sales-follow-up/process/list/form-proxy';

const DemoList: React.FC<{
    onCreate?: any;
    type: string;
    drawerData: any;
    drawEntity?: string;
}> = ({ onCreate, type = 'all', drawerData, drawEntity = 'opt' }) => {
    const currentDatum = (drawerData.originData || [])[0] || {};
    const wr = currentDatum?.opportunity_sale_stage?.relatedObj?.win_rate;
    const contractId = currentDatum?.contract_id?.originalValue;

    const [prepared, defaultStore] = useAnyContractListStore({
        opUserSuffix: type,
        autoLoad: true,
    });

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        defaultFilter,
        condListbyFilterData,
        defaultPerm,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const listFetch = useCallback(() => defaultStore.fetch(), []);
    const [error, loading, reload] = useNet(listFetch, {
        autoLoad: false,
        refreshKeys: ['opt', 'contract'], // 修改商机，商机明细刷新
    });

    useEffect(() => {
        if (!currentDatum.id) {
            return;
        }
        if (drawEntity === 'opt') {
            defaultStore.setDefaultFilters([
                {
                    fieldId: 'opportunity_id',
                    filterValue: currentDatum.id,
                    operateType: '=',
                    parser: 'string',
                },
            ]);
        }

        if (drawEntity === 'customer') {
            defaultStore.setDefaultFilters([
                {
                    fieldId: 'customer_id',
                    filterValue: currentDatum.id,
                    operateType: '=',
                    parser: 'string',
                },
            ]);
        }
    }, [drawerData]);

    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!prepared || !currentDatum.id) {
            return;
        }
        reload();
    }, [paginationForFetch, sortersForFetch, condListbyFilterData, prepared]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useEffect(() => {
        runInAction(() => {
            defaultStore.setListType(type);
        });
    }, [type]);

    if (!prepared) {
        return <BlockLoading2 />;
    }

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = (
        <>
            <ActionPreOpen defaultStore={defaultStore} />
            <CtxProvider
                ctx={{ position: 'list', positionDetail: 'contract-product' }}
            >
                <FinalProcessFormProxy
                    visible={
                        defaultStore.actionType === 'contract_invoice_apply'
                    }
                    onClose={() => {
                        defaultStore.setMutatingData(null);
                        defaultStore.resetAction();
                    }}
                    key={
                        defaultStore.actionType === 'contract_invoice_apply'
                            ? 'opened'
                            : 'nooppened'
                    }
                    processKey={'contractinvoice'}
                    lifeCycle={{
                        onClose: store => {
                            store.setMutatingData(null);
                            defaultStore.setMutatingData(null);
                            defaultStore.resetAction();
                        },
                        onOpen: store => {
                            if (!defaultStore.mutatingData) {
                                return;
                            }

                            store.startNewData();
                            const contractId = genContractIdParamByContractItemAndParamMeta(
                                defaultStore.mutatingData,
                            );

                            // 自动触发回填，发票资质
                            _.assign(store.mutatingData, {
                                contract_id: contractId,
                                customer_id:
                                    defaultStore.mutatingData?.customer_id,
                            });
                        },
                    }}
                    preRequestReady={!!defaultStore.mutatingData}
                />
            </CtxProvider>
        </>
    );

    let globalOps = null;
    if (defaultPerm.getPermByTypeAndAction('')('create').visible) {
        globalOps =
            drawEntity === 'opt' ? (
                <>
                    {/* 阶段大于等于40（确认合同），且无合同则可以新建 */}
                    <div style={{ height: 49 }}>
                        <AntButton
                            disabled={!!contractId || wr < 40}
                            loading={loading || wr === undefined}
                            onClick={() => {
                                onCreate && onCreate();
                            }}
                        >
                            新建合同
                        </AntButton>
                    </div>
                </>
            ) : (
                <>
                    {/* 阶段大于等于60（确认合同），且无合同则可以新建 */}
                    <div style={{ height: 49 }}>
                        <AntButton
                            loading={loading}
                            onClick={() => {
                                onCreate && onCreate();
                            }}
                        >
                            新建合同
                        </AntButton>
                    </div>
                </>
            );
    }

    const multipleOps = null;

    const dataTable = (
        <DemoTable defaultStore={defaultStore} loading={loading} />
    );

    const layoutComs = {
        popups,
        title: null,
        globalOps,
        filters: null,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay,
    };

    return <LayoutStandardCrud className="opt-tab" {...layoutComs} />;
};

const FinalDemoList = observer(DemoList);
export default FinalDemoList;
