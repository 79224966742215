import React, { useMemo } from 'react';
import _ from 'lodash';
import ChartsWrap, { HeaderColors } from '../../components/charts-wrap';
import * as echarts from 'echarts';

type ChartsProps = {
    chartsClassName?: string;
    loading?: boolean;
    color?: HeaderColors;
    showDays?: number;
    title: string;
    xAxis: string[];
    dataSource: DataSource[];
};

type DataSource = {
    label: string;
    data: number[];
};

const OpportunityTrendCharts: React.FC<ChartsProps> = ({
    chartsClassName = '',
    loading = false,
    color,
    showDays = 5,
    title,
    xAxis = [],
    dataSource = [],
}) => {
    const chartsOptions: echarts.EChartsOption = useMemo(() => {
        const start = Math.round(
            ((xAxis.length - showDays) * 100) / xAxis.length,
        );
        const legendData = dataSource?.map((item: any) => item.label);
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999',
                    },
                },
            },
            legend: {
                // type: 'scroll',
                // pageIconSize: 10,
                bottom: '10%',
                itemWidth: 10,
                itemHeight: 10,
                // align: 'center',
                // itemGap: 20,
                data: legendData,
                selected: {
                    '20商机个数': false,
                    // '20商机金额': false,
                    '40商机个数': false,
                    '40商机金额': false,
                    '60商机个数': true,
                    '60商机待回款金额': true,
                    '80商机个数': false,
                    '80商机待回款金额': false,
                },
            },
            grid: {
                left: '0%',
                right: '0%',
                bottom: '35%',
                width: '100%',
                height: '60%',
                containLabel: true,
            },
            xAxis: [
                {
                    type: 'category',
                    data: xAxis,
                    axisPointer: {
                        type: 'shadow',
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine: {
                        lineStyle: {
                            type: 'dashed',
                        },
                    },
                    // min: 0,
                    // max: 250,
                    // interval: 50,
                    // alignTicks: true,
                    axisLabel: {
                        formatter: '{value}',
                    },
                },
                {
                    type: 'value',
                    show: false,
                    splitLine: {
                        lineStyle: {
                            type: 'dashed',
                        },
                    },
                    // min: 0,
                    // max: 250,
                    // interval: 50,
                    // alignTicks: true,
                    axisLabel: {
                        formatter: '{value}',
                    },
                },
            ],
            series: [
                {
                    name: dataSource[0]?.label,

                    type: 'line',
                    color: '#FFE862',
                    yAxisIndex: 0,
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text} 个`;
                        },
                    },
                    symbol: 'none',
                    data: dataSource[0]?.data,
                },
                // {
                //     name: dataSource[1]?.label,
                //     type: 'bar',
                //     yAxisIndex: 1,
                //     // stack: 'total',
                //     barMaxWidth: 50,
                //     color: new echarts.graphic.LinearGradient(
                //         0,
                //         1,
                //         0,
                //         0,
                //         [
                //             {
                //                 offset: 0,
                //                 color: '#83ABFF',
                //             },
                //             {
                //                 offset: 1,
                //                 color: '#002BFF',
                //             },
                //         ],
                //         false,
                //     ),
                //     tooltip: {
                //         valueFormatter: (value: any) => {
                //             const text = Number(value);
                //             if (_.isNaN(text)) return '-';
                //             return `${text.toFixed(2)} 万`;
                //         },
                //     },
                //     data: dataSource[1]?.data,
                // },
                {
                    name: dataSource[2]?.label,
                    type: 'line',
                    color: '#FF9A1E',
                    yAxisIndex: 0,
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text} 个`;
                        },
                    },
                    symbol: 'none',
                    data: dataSource[2]?.data,
                },
                {
                    name: dataSource[3]?.label,
                    type: 'bar',
                    yAxisIndex: 1,
                    // stack: 'total',
                    barMaxWidth: 50,
                    color: new echarts.graphic.LinearGradient(
                        0,
                        1,
                        0,
                        0,
                        [
                            {
                                offset: 0,
                                color: '#D0A1FF',
                            },
                            {
                                offset: 1,
                                color: '#A74FFF',
                            },
                        ],
                        false,
                    ),
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text.toFixed(2)} 万`;
                        },
                    },
                    data: dataSource[3]?.data,
                },
                {
                    name: dataSource[4]?.label,
                    type: 'line',
                    color: '#FF2C1D',
                    yAxisIndex: 0,
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text} 个`;
                        },
                    },
                    symbol: 'none',
                    data: dataSource[4]?.data,
                },
                {
                    name: dataSource[5]?.label,
                    type: 'bar',
                    yAxisIndex: 1,
                    // stack: 'total',
                    barMaxWidth: 50,
                    color: new echarts.graphic.LinearGradient(
                        0,
                        1,
                        0,
                        0,
                        [
                            {
                                offset: 0,
                                color: '#7AD5FF',
                            },
                            {
                                offset: 1,
                                color: '#2AABE7',
                            },
                        ],
                        false,
                    ),
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text.toFixed(2)} 万`;
                        },
                    },
                    data: dataSource[5]?.data,
                },
                {
                    name: dataSource[6]?.label,
                    type: 'line',
                    color: '#A50000',
                    yAxisIndex: 0,
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text} 个`;
                        },
                    },
                    symbol: 'none',
                    data: dataSource[6]?.data,
                },
                {
                    name: dataSource[7]?.label,
                    type: 'bar',
                    // stack: 'total',
                    yAxisIndex: 1,
                    barMaxWidth: 50,
                    color: new echarts.graphic.LinearGradient(
                        0,
                        1,
                        0,
                        0,
                        [
                            {
                                offset: 0,
                                color: '#85E7E4',
                            },
                            {
                                offset: 1,
                                color: '#3DDFDA',
                            },
                        ],
                        false,
                    ),
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text.toFixed(2)} 万`;
                        },
                    },
                    data: dataSource[7]?.data,
                },
            ],
            // 需要完善
            dataZoom: {
                type: 'slider',
                show: true,
                bottom: '4%',
                height: 10,
                start: start > 0 ? start : 0,
                end: 100,
            },
        };
    }, [xAxis, dataSource]);
    return (
        <ChartsWrap
            chartsClassName={chartsClassName}
            loading={loading}
            color={color}
            title={title}
            chartsOptions={chartsOptions}
        />
    );
};

export default OpportunityTrendCharts;
