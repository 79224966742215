import { makeAutoObservable } from 'mobx';
import { createDefaultInstanceGetter } from '@/stores/utils';
import { defaultAxios } from '@/utils';
import _ from 'lodash';

// 必填字段/必需条件配置表
export interface ISSMStageRequirementConfig {
    requiredField: {
        fieldName: string;
        id: string | number;
    }[];
    requiredCondition: {
        conditionName: string;
        id: string | number;
    }[];
    notifyTypes: {
        typeName: string;
        notifyUserType: string;
    }[];
}
// 阶段及要求设置
export interface ISSMStageSetting {
    id: string;
    bizType: string;
    stageName: string; // 阶段名称
    winRate: string | number; // 赢率
    stageNum: string; // 阶段数 (如 阶段一)
    requiredField: (string | number)[]; // 必填字段
    requiredCondition: (string | number)[]; // 必需条件
    notifyUserType: (string | number)[]; // 提醒对象
}
// 业务类型
export interface ISSMBusinessTypeData {
    bizType?: number | string; // id
    bizTypeName?: string; // 业务类型名称
    sysOpportunitySaleStageDtoList?: ISSMStageSetting[];
}
export type TGetSSMBusinessTypeListReturnType = NetResponse<{
    data: ISSMBusinessTypeData[];
    msg: string;
    code: number;
}>;
export class SSMStore {
    constructor() {
        makeAutoObservable(this);
    }
    public SSMBusinessTypeList: ISSMBusinessTypeData[] = [];
    public SSMStageRequirement: ISSMStageRequirementConfig = {
        requiredCondition: [],
        requiredField: [],
        notifyTypes: [],
    };
    public updateSSMBizTypeLoading = false;
    public fetchConfigLoading = false;

    get SSMGroupStageRequirement() {
        return {
            requiredCondition: _.groupBy(
                this.SSMStageRequirement.requiredCondition,
                'id',
            ),
            requiredField: _.groupBy(
                this.SSMStageRequirement.requiredField,
                'id',
            ),
            notifyTypes: _.groupBy(this.SSMStageRequirement.notifyTypes, 'id'),
        };
    }
    // 配置表获取
    public getSSMStageRequirementConfig = async (bizType: string | number) => {
        const url = '/bff/api/rest/ssm/config';
        this.fetchConfigLoading = true;
        const [d, e] = await defaultAxios.get(url, {
            bizType,
        });
        this.fetchConfigLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data && data.requiredCondition) {
            this.SSMStageRequirement = data || {
                requiredCondition: [],
                requiredField: [],
                notifyTypes: [],
            };
        }
        return [data, error];
    };
    // 获取业务对象-阶段配置
    public getSSMBusinessTypeList: () => Promise<
        TGetSSMBusinessTypeListReturnType
    > = async () => {
        const url = '/bff/api/rest/ssm/business-type-list';
        const [d, e] = await defaultAxios.get(url);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data && Array.isArray(data)) {
            this.SSMBusinessTypeList = data || [];
        }
        return [data, error];
    };
    // 修改配置表
    public updateBusiessTypeSetting = async (updateData: any) => {
        this.updateSSMBizTypeLoading = true;
        const url = '/bff/api/rest/ssm/update-business-type';
        const [d, e] = await defaultAxios.post(url, updateData);
        this.updateSSMBizTypeLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
}

export const getDefaultSSMStore = createDefaultInstanceGetter(SSMStore);
