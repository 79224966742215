import { defaultAxios } from '@/utils';
import { exportFile } from '../api';

// 预估数据通晒
export const queryPreSolarization: (
    orgId: string | number,
) => Promise<any> = async orgId => {
    const [d, e] = await defaultAxios.put(
        '/crm/data/query/predict_solarization',
        {
            orgId,
            version: 2,
        },
    );
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
};

// 预估数据通晒导出
export const exportPreSolarization = (orgId: string | number) => {
    return exportFile('/crm/data/query/predict_solarization/export', { orgId });
};

// 趋势分析
export const queryTrendAnalysis: (
    timeDim: number,
    orgId: string | number,
) => Promise<any> = async (timeDim, orgId) => {
    const [d, e] = await defaultAxios.put('/crm/data/query/trend_analysis', {
        timeDim,
        orgId,
        version: 2,
    });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
};

// 趋势分析导出
export const exportTrendAnalysis = (
    timeDim: number,
    orgId: string | number,
) => {
    return exportFile('/crm/data/query/trend_analysis/import', {
        timeDim,
        orgId,
        version: 2,
    });
};
