import React, { useState, useEffect, useContext } from 'react';
import './index.scss';
import { IHSPGDataTypes } from '../index';
import { StoreContext } from '@/stores';
import { observer } from 'mobx-react-lite';
import { Modal, DatePicker } from 'antd';
import { useNet } from '@/utils';
import { TableLv2 } from '@/components';
import { ICHSPGActionLogData } from '../store';
import moment from 'moment';

interface ILogCHSPGModalProps {
    visible: boolean;
    setVis: (bool: boolean) => void;
    selectedItems?: IHSPGDataTypes;
}
const LogCHSPGModal: React.FC<ILogCHSPGModalProps> = ({
    visible = false,
    setVis,
    selectedItems,
}) => {
    const store = useContext(StoreContext);
    const SettingCHSPGStore = store.getDefaultSettingCHSPGStore();
    const {
        getCHSPGActionLog,
        // defaultActionLogPagination,
        actionLogDateFilter,
        changeCHSPGDate,
        LogData,
    } = SettingCHSPGStore;
    const hideModal = () => {
        setVis(false);
    };
    const [error, loading, reload] = useNet(() =>
        getCHSPGActionLog(selectedItems?.id),
    );
    useEffect(() => {
        reload();
    }, [selectedItems]);
    return (
        <Modal
            visible={visible}
            onCancel={hideModal}
            onOk={hideModal}
            title={'操作日志'}
            centered
            width="900px"
            wrapClassName="chspg-action-log-modal-content"
        >
            <div className="chspg-action-log-modal-body">
                <div className="chspg-action-log-title">
                    {selectedItems?.description}
                </div>
                <div className="chspg-action-log-filter">
                    <span>时间筛选：</span>
                    <DatePicker.RangePicker
                        value={[
                            actionLogDateFilter.startTime,
                            actionLogDateFilter.endTimme,
                        ]}
                        onChange={changeCHSPGDate}
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder={['Start Time', 'End Time']}
                        onOk={reload}
                    />
                </div>
                <div className="chspg-action-log-table">
                    <TableLv2<ICHSPGActionLogData>
                        loading={loading}
                        // pagination={{
                        //     ...defaultActionLogPagination.paginationForUI,
                        //     total: defaultActionLogPagination.amount,
                        // }}
                        pagination={false}
                        scroll={{
                            y: 350,
                        }}
                        dataSource={LogData}
                        // onPaginationChange={
                        //     defaultActionLogPagination.setPaginationByUI
                        // }
                        closeRowSelection={true}
                        columns={[
                            {
                                key: 'useName',
                                dataIndex: 'userName',
                                title: '最后修改人',
                                width: 70,
                            },
                            {
                                key: 'changeDate',
                                dataIndex: 'changeDate',
                                title: '变更时间',
                                render: v => {
                                    return moment(Number(v)).format(
                                        'YYYY-MM-DD HH:mm:ss',
                                    );
                                },
                                width: 150,
                            },
                            {
                                key: 'changeContent',
                                dataIndex: 'changeContent',
                                title: '回收规则变更日志',
                                width: 230,
                                render: v => {
                                    return (
                                        <div
                                            style={{
                                                whiteSpace: 'pre-line',
                                            }}
                                        >
                                            {v}
                                        </div>
                                    );
                                },
                            },
                        ]}
                    ></TableLv2>
                </div>
            </div>
        </Modal>
    );
};

export default observer(LogCHSPGModal);
