import React, { useMemo } from 'react';
import _ from 'lodash';
import ChartsWrap, { HeaderColors } from '../../components/charts-wrap';
import * as echarts from 'echarts';

type ChartsProps = {
    chartsClassName?: string;
    loading?: boolean;
    color?: HeaderColors;
    title: string;
    xAxis: string[];
    dataSource: DataSource[] | PieDataSource[];
};

type DataSource = {
    label: string;
    data: number[];
};

type PieDataSource = {
    name: string;
    value: number;
};

const keyToColorMap = new Map([
    ['20赢率', '#83ABFF'],
    ['40赢率', '#D0A1FF'],
    ['60赢率', '#7AD5FF'],
    ['80赢率', '#85E7E4'],
]);

const OpportunityPie: React.FC<ChartsProps> = ({
    chartsClassName = '',
    loading = false,
    color,
    title,
    xAxis = [],
    dataSource = [],
}) => {
    const colorArr = [
        ['#83ABFF', '#002BFF'],
        ['#D0A1FF', '#A74FFF'],
        ['#7AD5FF', '#2AABE7'],
        ['#85E7E4', '#3DDFDA'],
    ];

    const colorList = useMemo(() => {
        return colorArr.reduce((pre: any, cur: string[]) => {
            pre.push(
                new echarts.graphic.LinearGradient(
                    0,
                    1,
                    0,
                    0,
                    [
                        {
                            offset: 0,
                            color: cur[0],
                        },
                        {
                            offset: 0.8,
                            color: cur[1],
                        },
                        {
                            offset: 1,
                            color: cur[1],
                        },
                    ],
                    false,
                ),
            );
            return pre;
        }, []);
    }, [colorArr]);

    const ChartOptions: echarts.EChartsOption = useMemo(() => {
        const data: any[] = _.cloneDeep(dataSource);
        data.forEach((obj: any) => {
            // itemStyle: {
            //     //shadowBlur: 15,
            //     shadowColor: '#D0A1FF',
            //     // shadowOffsetY: 10,
            // },
            // label: {
            //     show: true,
            //     position: 'inner',
            //     formatter: `{c}`,
            //     color: '#fff',
            // },
            obj.itemStyle = {
                shadowBlur: 15,
                shadowColor: keyToColorMap.get(obj.name),
                // shadowOffsetY: 10,
                opacity: 1,
            };
            obj.label = {
                show: true,
                // 占比小于 5% 位置改为外置
                position: obj.ratio > 5 ? 'inner' : 'outside',
                formatter: `{c}`,
                fontWeight: 'bold',
                color: obj.ratio > 5 ? '#fff' : '#000',
            };
            obj.labelLine = {
                show: obj.ratio > 5 ? false : true,
            };
        });

        return {
            tooltip: {
                trigger: 'item',
                // position: 'bottom',
                position: function(point, params, dom, rect, size) {
                    // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
                    // 提示框位置
                    let x = 0; // x坐标位置
                    let y = 0; // y坐标位置

                    // 当前鼠标位置
                    const pointX = point[0];
                    const pointY = point[1];

                    // 外层div大小
                    const viewWidth = size.viewSize[0];
                    const viewHeight = size.viewSize[1];

                    // 提示框大小
                    const boxWidth = size.contentSize[0];
                    const boxHeight = size.contentSize[1];

                    // boxWidth > pointX 说明鼠标左边放不下提示框
                    if (pointX <= viewWidth / 2) {
                        x = pointX + 10;
                    } else {
                        // 左边放的下
                        x = pointX - boxWidth - 10;
                    }

                    // boxHeight > pointY 说明鼠标上边放不下提示框
                    if (pointY <= viewHeight / 2) {
                        y = pointY + 10;
                    } else {
                        // 上边放得下
                        y = pointY - boxHeight - 10;
                    }

                    return [x, y];
                },
                formatter: (item: any) => {
                    // console.log('123wumingxuan', item);
                    //     float: left;
                    // style="display: block;"
                    let html = `<div style="width:180px;">
                            <div style="width:100%;overflow:hidden;">
                                <div style="float:left;">${item.marker}${
                        item.name
                    }个数</div>
                                <div style="float:right;">${item.value}个</div>
                            </div>
                            <div style="width:100%;overflow:hidden;">
                                <div style="float:left;">${item.marker}${
                        item.name
                    }金额</div>
                                <div style="float:right;">${item.data.amount.toFixed(
                                    2,
                                )}万</div>
                            </div>
                            <div style="width:100%;overflow:hidden;">
                                <div style="float:left;">${item.marker}${
                        item.name
                    }百分比</div>
                                <div style="float:right;">${item.percent}%</div>
                            </div>
                        </div>`;
                    // item.marker +
                    // item.name +
                    // item.value +
                    // '<br>' +
                    // item.marker +
                    // '商机金额' +
                    // item.data.a +
                    // '<br>' +
                    // item.marker +
                    // '百分比' +
                    // item.percent +
                    // '<br>';
                    return html;
                },
            },
            color: colorList,
            legend: {
                // type: 'scroll',
                pageIconSize: 10,
                bottom: '0%',
                itemWidth: 8,
                itemHeight: 8,
                textStyle: {
                    fontSize: 11,
                },
            },
            grid: {
                left: '0%',
                right: '0%',
                bottom: '15%',
                width: '100%',
                height: '70%',
                containLabel: true,
            },
            series: [
                {
                    // name: 'Access From',
                    type: 'pie',
                    radius: ['30%', '70%'],
                    center: ['50%', '50%'],
                    avoidLabelOverlap: false,
                    // label: {
                    //     show: false,
                    //     position: 'center',
                    // },
                    // emphasis: {
                    //     label: {
                    //         show: true,
                    //         fontSize: 18,
                    //         // fontWeight: 'bold',
                    //     },
                    // },
                    // labelLine: {
                    //     show: false,
                    // },
                    itemStyle: {
                        // //shadowBlur: 15,
                        // shadowColor: '#3DDFDA',
                        // shadowColor: [
                        //      '#002BFF',
                        //      '#A74FFF',
                        //      '#2AABE7',
                        //      '#3DDFDA',
                        // ],
                    },

                    data: data,
                    // data: [
                    //     {
                    //         value:20,
                    //         name:"20",
                    //         itemStyle:{

                    //             opacity:1
                    //         },
                    //         label:{
                    //             show:true,
                    //         },
                    //         labelLine:{
                    //             show:true,
                    //         }
                    //     }
                    // ],
                },
            ],
        };
    }, [xAxis, dataSource]);

    return (
        <ChartsWrap
            chartsClassName={chartsClassName}
            loading={loading}
            color={color}
            title={title}
            chartsOptions={ChartOptions}
        />
    );
};

export default OpportunityPie;
