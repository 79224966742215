import React from 'react';
import { observer } from 'mobx-react-lite';
import { AntTable, AntCollapse } from '@/components';
import { ProcessCatStore } from '../../store';
import { defaultTo } from 'lodash';
import moment from 'moment';
import { Divider } from 'antd';
import _ from 'lodash';
import { HighLightText } from '@/components/common/highlight-text';
import { unescape } from '@/utils/html-escaper';

// action: "提交"
// fullMessage: ""
// message: ""
// processInstanceId: "d1912406-9b6e-11eb-ba2d-525400df0a63"
// taskId: "d191722e-9b6e-11eb-ba2d-525400df0a63"
// taskName: "账户申请"
// time: 1618218583032
// type: "button"
// userId: "shaomingquan"
// username: "邵明全"

const highlightConf = {
    转办: {
        color: 'blue',
    },
    完成: {
        color: 'blue',
    },
    驳回: {
        color: 'red',
    },
    退回: {
        color: 'red',
    },
};

const dummyEle = document.createElement('div');

const columns = [
    { title: '序号', key: 'index', dataIndex: 'index' },
    { title: '节点名称', key: 'taskName', dataIndex: 'taskName' },
    { title: '审批人', key: 'username', dataIndex: 'username' },
    {
        title: '审批时间',
        key: 'time',
        dataIndex: 'time',
        render(v: any) {
            if (!v) {
                return null;
            }
            return moment(v).format('YYYY-MM-DD HH:mm:ss');
        },
    },
    {
        title: '操作',
        key: 'action',
        dataIndex: 'action',
        render(v: any) {
            if (!_.isString(v)) {
                return null;
            }
            return <HighLightText text={v} highlightConf={highlightConf} />;
        },
    },
    {
        title: '留言',
        key: 'message',
        dataIndex: 'message',
        render(v: any) {
            if (!_.isString(v)) {
                return null;
            }
            const html = unescape(v);
            console.log(html, 'htmlhtml');
            dummyEle.innerHTML = html;
            const text = dummyEle.innerText;
            console.log(text, 'htmlhtml', 3);
            return <HighLightText text={text} highlightConf={highlightConf} />;
        },
    },
];

export const CommentDisplay: React.FC<{
    defaultStore: ProcessCatStore | null;
}> = observer(({ defaultStore }) => {
    const processAllInfo = defaultStore?.defaultMeta?.processAllInfo;
    if (!processAllInfo) {
        return null;
    }
    const crmComments = processAllInfo.crmComments || [];
    if (crmComments.length === 0) {
        return null;
    }

    return (
        <>
            <AntCollapse
                defaultActiveKey={['comments']}
                collapseConfig={[
                    {
                        key: 'comments',
                        style: {
                            marginTop: -45,
                        },
                        header: <span>审批记录</span>,
                        content: (
                            <AntTable
                                dataSource={crmComments.map(
                                    (item: any, index: any) => ({
                                        index: index + 1,
                                        ...item,
                                    }),
                                )}
                                columns={columns}
                                pagination={false}
                            />
                        ),
                    },
                ]}
            />
        </>
    );
});
