import _ from 'lodash';
import { isZeroValue } from './helpers';
import { commonValidate } from '@/api/rest';

export const alwaysSuccess = (input: any): ValidateStatus => {
    return {
        status: 'success',
        msg: '',
    };
};

const isValidateRegExpWithMsg = (
    value: any,
): value is ValidateRegExpWithMsg => {
    if (value.successMsg) {
        return true;
    }
    return false;
};

export const isAllValidatorPass = (validateResult: ValidateResult<any>) => {
    const keys = _.keys(validateResult);
    return _.every(keys, key => {
        const currentStatus = validateResult[key];
        if (currentStatus) {
            return currentStatus.status === 'success';
        }
        return true;
    });
};

export const makeRangeValidator = <T = any>(
    key: string,
    required: boolean,
    min: number = 0,
    max: number = 100,
) => {
    const validator = async (input: T): Promise<ValidateStatus> => {
        const ret: ValidateStatus = {
            status: 'success',
            msg: '',
        };
        if (_.isUndefined((input as any)[key])) {
            return ret;
        }
        const value = _.isUndefined((input as any)[key])
            ? ''
            : (input as any)[key];
        if (required && (value === null || isZeroValue(value))) {
            ret.status = 'fail';
            ret.msg = '该字段为必填项';
            return ret;
        }

        // 如果是非必填，且没有值，就成功
        if (!required && (value === null || isZeroValue(value))) {
            return ret;
        }

        if (value > max) {
            ret.status = 'fail';
            ret.msg = `字段值不能大于${max}`;
        }

        if (value < min) {
            ret.status = 'fail';
            ret.msg = `字段值不能小于${min}`;
        }
        return ret;
    };
    return validator;
};

export const makeStringValidator = <T = any>(
    key: string,
    required: boolean,
    fieldLength: number = 100,
    regularExp: string = '',
    paramSuggestion: string = '',
) => {
    const validator = async (input: T): Promise<ValidateStatus> => {
        const ret: ValidateStatus = {
            status: 'success',
            msg: '',
        };
        if (_.isUndefined((input as any)[key])) {
            return ret;
        }
        const value = _.isUndefined((input as any)[key])
            ? ''
            : (input as any)[key];
        if (required && (value === null || isZeroValue(value))) {
            ret.status = 'fail';
            ret.msg = '该字段为必填项';
            return ret;
        }

        // 如果是非必填，且没有值，就成功
        if (!required && (value === null || isZeroValue(value))) {
            return ret;
        }

        if (value.length > fieldLength) {
            ret.status = 'fail';
            ret.msg =
                ret.msg + (ret.msg ? '，' : '') + `长度不能大于${fieldLength}`;
        }

        const regExp = new RegExp(regularExp);
        if (!regExp.test(value)) {
            ret.status = 'fail';
            ret.msg =
                ret.msg +
                (ret.msg ? '，' : '') +
                (paramSuggestion || '格式校验不通过');
        }
        return ret;
    };
    return validator;
};

export const makeNumberValidator = <T = any>(
    key: string,
    required: boolean,
    fieldLength: number = 100,
    fieldDecimalLength: number = 2,
    paramSuggestion: string = '',
) => {
    const validator = async (input: T): Promise<ValidateStatus> => {
        const ret: ValidateStatus = {
            status: 'success',
            msg: '',
        };
        const vSource = ('' + (input as any)[key]) as string;
        const isCurrentValueZero = isZeroValue(vSource);
        if (required && isCurrentValueZero) {
            ret.status = 'fail';
            ret.msg = '该字段为必填项';
            return ret;
        }

        // 如果是非必填，且没有值，就成功
        if (!required && isCurrentValueZero) {
            return ret;
        }

        const vNumber = parseFloat(vSource);
        if (Number.isNaN(vNumber)) {
            ret.status = 'fail';
            ret.msg = '类型必须是数字';
            return ret;
        }
        const v = '' + vNumber;
        let com0 = '';
        let com1 = '';
        const coms = v.split('.');
        com0 = coms[0] || '';
        com1 = coms[1] || '';

        if (com0.length > fieldLength) {
            ret.status = 'fail';
            ret.msg =
                ret.msg +
                (ret.msg ? '，' : '') +
                `整数位数不能大于${fieldLength}`;
        }

        if (com1.length > fieldDecimalLength) {
            ret.status = 'fail';
            ret.msg =
                ret.msg +
                (ret.msg ? '，' : '') +
                `小数位数不能大于${fieldDecimalLength}`;
        }

        if (paramSuggestion) {
            ret.msg = paramSuggestion;
        }

        return ret;
    };
    return validator;
};

export const makeArrayValidator = <T = any>(key: string, required: boolean) => {
    const validator = async (input: T): Promise<ValidateStatus> => {
        const ret: ValidateStatus = {
            status: 'success',
            msg: '',
        };
        const v = (input as any)[key];
        const exsit = _.isArray(v) && v.length > 0;

        if (required && !exsit) {
            ret.status = 'fail';
            ret.msg = '该字段为必填项';
            return ret;
        }
        return ret;
    };
    return validator;
};

export const makeObjValidator = <T = any>(key: string, required: boolean) => {
    const validator = async (input: T): Promise<ValidateStatus> => {
        const ret: ValidateStatus = {
            status: 'success',
            msg: '',
        };
        const vSource = (input as any)[key] as any;

        if (
            required &&
            (!vSource ||
                vSource === -1 ||
                vSource === '' ||
                (vSource && vSource.originalValue === null))
        ) {
            ret.status = 'fail';
            ret.msg = '该字段为必填项';
            return ret;
        }

        return ret;
    };
    return validator;
};

export const makeNetworkValidator = <T = any>(tableId: number, key: string) => {
    const validator = async (input: T): Promise<ValidateStatus> => {
        const ret: ValidateStatus = {
            status: 'success',
            msg: '',
        };
        let [d, e] = await commonValidate(tableId, input, [key]);
        // test
        // d = [
        //     {
        //         errorMsg: '后端报错',
        //     },
        // ];
        if (!d) {
            return ret;
        }
        if (d.length >= 1 && _.isObject(d[0])) {
            const { errorMsg = '' } = d[0] as any;
            if (errorMsg.length > 0) {
                ret.msg = errorMsg;
                ret.status = 'fail';
            }
        }

        return ret;
    };
    return validator;
};

export const composeFeValidatorAndNetValidator = <T = any>(
    fe: (input: T) => Promise<ValidateStatus>,
    net: (input: T) => Promise<ValidateStatus>,
) => {
    const validator = async (input: T): Promise<ValidateStatus> => {
        const feRet = await fe(input);
        if (feRet.status !== 'success') {
            return feRet;
        }
        const netRet = await net(input);
        return netRet;
    };
    return validator;
};

export const isAllValidateResultSuccess = <T = any>(
    results: ValidateResult<any>,
) => {
    const keys = _.keys(results);
    let pass = true;
    for (const key of keys) {
        if (results[key]?.status !== 'success') {
            pass = false;
            break;
        }
    }
    return pass;
};

export const executeAllValidatorFor = async <T>(
    datum: T,
    validators: { [key in keyof T]?: Validator<T> },
    keys: Array<keyof T>,
): Promise<{ result: ValidateResult<T>; isPass: boolean }> => {
    const ret: ValidateResult<T> = {};
    for (const key of keys) {
        const validator = validators[key];
        if (!validator) {
            continue;
        }
        const value = datum[key];
        if (_.isRegExp(validator) || isValidateRegExpWithMsg(validator)) {
            const validateRegExp = isValidateRegExpWithMsg(validator)
                ? validator.regExp
                : validator;
            const isSuccess = validateRegExp.test(value + '');
            if (isSuccess) {
                ret[key] = {
                    status: 'success',
                    msg: isValidateRegExpWithMsg(validator)
                        ? validator.successMsg || '校验成功'
                        : '校验成功',
                };
            } else {
                ret[key] = {
                    status: 'fail',
                    msg: isValidateRegExpWithMsg(validator)
                        ? validator.failMsg || '校验失败'
                        : '校验失败',
                };
            }
        } else if (_.isFunction(validator)) {
            const status = await validator(datum);
            ret[key] = status;
        }
    }
    if (isAllValidatorPass(ret)) {
        return {
            result: ret,
            isPass: true,
        };
    }
    return {
        result: ret,
        isPass: false,
    };
};
