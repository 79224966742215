import _ from 'lodash';
import { genDaysAgoFilterFactory } from './helpers';
import moment from 'moment';
import { AuthStore } from '@/stores/auth';

export const contactsDFConfs: { [entityCode: string]: any } = {
    contacts: {
        lv4MenuFilters: {
            all: [
                {
                    key: 'contacts-df-all',
                    label: '全部联系人',
                    value: [],
                },
                async (options: { authStore: AuthStore }) => {
                    const { authStore } = options;
                    const ownerId = authStore?.userInfo?.sysUser?.id;
                    return {
                        key: 'contacts-df-mine',
                        label: '我的联系人',
                        value: [
                            ownerId
                                ? {
                                      condition: {
                                          fieldId: 'customer_id.owner_id',
                                          filterValue: ownerId,
                                          operateType: '=',
                                      },
                                  }
                                : null,
                        ].filter(Boolean),
                    };
                },
            ],
            mine: [],
        },
        fastFilters: {
            all: [
                genDaysAgoFilterFactory({
                    label: '今日新增联系人',
                    fieldId: 'create_time',
                    fastFilterKey: 'today-create',
                    days: 0,
                }),
                async () => {
                    return {
                        label: '7天内未跟进联系人',
                        fastFilterKey: '7_recent_activity_record_time',
                        value: [
                            {
                                condition: {
                                    fieldId: 'recent_activity_record_time',
                                    filterValue: moment()
                                        .subtract(7, 'days')
                                        .startOf('day')
                                        .format('YYYY-MM-DD HH:mm:ss'),
                                    operateType: '<=',
                                },
                            },
                        ],
                    };
                },
            ],
            mine: [],
        },
    },
};
