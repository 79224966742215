import React, { useContext, useState, useEffect, useMemo } from 'react';
import { StoreContext } from '@/stores';
import { Spin, message, Icon, Popover, Button, Input } from 'antd';
import { CCStore } from './store';
import EE from 'eventemitter3';
import './index.scss';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { isLibOk, init } from './api';
import AddFollowLog from '@/components/common/add-follow-log-new';
import { delay } from '@/utils';
import { CustomerActivityFollowJobCreate } from '@/pages/sales-follow-up/job/jobs/lead/components/created-by-cus-activity';

type EntityType = 'customer' | 'lead' | 'contacts';
type EntityTypeId = 'customerId' | 'leadId' | 'contactsId';

export const defaultStore = new CCStore();
// 就是个单例，所以也用一个单例ee来获取到下游的数据
let comRendered = false;
export const CCEE = new EE();
export const tryNewCall = (
    phoneNum: string,
    isHidden?: boolean,
    config?: {
        customerName?: string;
        customerId?: string | number;
        contactsId?: string | number;
        leadId?: string | number;
        showFollowLog?: boolean;
        sceneCode?: string;
        detail?: any;
        [key: string]: any;
    },
) => {
    if (!comRendered) {
        return message.error('尚未配置坐席');
    }
    CCEE.emit('new-call', phoneNum, isHidden, config);
};

const extractSupplierInfo = (callCenterConfig: any) => {
    if (!callCenterConfig) {
        message.error('供应商配置不存在');
        return null;
    }
    const {
        account,
        available,
        bind_tel,
        create_time,
        create_user,
        id,
        online_status,
        password,
        relation_object_map,
        type,
        update_time,
        user_id,
        token,
    } = callCenterConfig;

    if (type === 1) {
        if (!account || !password || !bind_tel) {
            message.error('天润融通，配置信息不全');
            return null;
        }
        // 天润
        const supplierStr = 'clink';
        const context = {
            identifier: 'bjtytx',
            cno: account,
            password,
            bindTel: bind_tel,
            bindType: 1,
            loginStatus: 1,
        };

        return {
            supplierStr,
            context,
        };
    } else if (type === 2) {
        // 7moor
        if (!account || !password) {
            message.error('7moor，配置信息不全');
            return null;
        }
        const supplierStr = '7moor';
        const context = {
            loginName: account,
            password,
            loginType: 'gateway',
            // loginType: 'sip', // 7moor的webrtc，也就是sip
            agentStatus: '1',
        };
        return {
            supplierStr,
            context,
        };
    } else if (type === 3) {
        // baichuan
        if (!account || !token) {
            message.error('百川智通，配置信息不全');
            return null;
        }
        const supplierStr = 'baichuan';
        const context = {
            agent: account,
            token: token,
        };
        return {
            supplierStr,
            context,
        };
    }

    message.error('未知供应商类型');
    return null;
};

export const CCToolBar: React.FC = observer(() => {
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();

    const userInfo = authStore.userInfo || {};
    const { callCenterConfig, sysUser } = userInfo;
    // 大概会在 authStore.userInfo 夹带一些信息
    // 当前用户对应的运营商坐席的账号密码
    // 当前坐席的运营商
    const [inited, setInited] = useState(false);
    const [actionLogVis, setActionLogVis] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [
        customerActivityFollowJobCreateTrigger,
        setCustomerActivityFollowJobCreateTrigger,
    ] = useState(0);
    const [createdFollowLog, setCreatedFollowLog] = useState<any>(null);
    const [followActionInitMark, setFollowActionInitMark] = useState(false);

    const openActionLogModal = () => {
        setActionLogVis(true);
        setFollowActionInitMark(true);
        setTimeout(() => {
            setFollowActionInitMark(false);
        }, 0);
    };

    useEffect(() => {
        if (!callCenterConfig) {
            return;
        }

        defaultStore.setUsername(sysUser?.userId || '');

        comRendered = true;
        if (isLibOk(defaultStore)) {
            // ClinkAgent.setup
            // 初始化，加载运营商对应的sdk
            const parsedSupplierInfo = extractSupplierInfo(callCenterConfig);

            if (!parsedSupplierInfo) {
                return;
            }

            // const sevenMoorSupplierStr = '7moor';
            // const sevenMoorContext = {
            //     loginName: '8017@taoyou',
            //     password: '9_OuZNlYHTq',
            //     loginType: 'gateway',
            //     agentStatus: '1',
            // };

            // const clinkSupplierStr = 'clink';
            // const clinkContext = {
            //     identifier: 'bjtytx',
            //     cno: 2000,
            //     password: 123456,
            //     bindTel: 18610963803,
            //     bindType: 1,
            //     loginStatus: 1,
            // };

            defaultStore.setContext(
                parsedSupplierInfo.supplierStr,
                parsedSupplierInfo.context,
            );
            // defaultStore.setContext(sevenMoorSupplierStr, sevenMoorContext);
            // defaultStore.setContext(clinkSupplierStr, clinkContext);

            init(defaultStore, isSuccess => {
                if (isSuccess) {
                    setInited(true);
                } else {
                    message.error('初始化失败，请刷新重试');
                    setError('初始化失败');
                }
            });
        } else {
            message.error('依赖加载失败，请刷新重试');
            setError('依赖加载失败');
        }

        let timer: NodeJS.Timeout;
        const tryLogout = async () => {
            if (
                defaultStore.isLogin &&
                // !m7IsCalling && // 这个状态不稳定，不要等了，销售等不及
                !defaultStore.isCalling() &&
                !defaultStore.locked
            ) {
                await defaultStore.logout();
            }
            timer = setTimeout(() => {
                clearTimeout(timer);
                tryLogout();
            }, 100);
        };
        tryLogout();
        return () => clearTimeout(timer);
    }, [callCenterConfig]);

    useEffect(() => {
        // test
        // defaultStore.ticker2.start()
        if (!callCenterConfig) {
            return;
        }

        const handler = async (
            phoneNum: string,
            isHiddenCallPhoneNum?: boolean,
            config?: any,
        ) => {
            if (!phoneNum) {
                return false;
            }
            // 处理电话的格式
            phoneNum = _.trim(phoneNum.replace(/-/g, ''));

            if (defaultStore.isCalling()) {
                message.error('系统忙，请稍后重试，联系销售运营-胥言处理');
                return false;
            }
            if (defaultStore.locked) {
                return false;
            }
            defaultStore.lock();
            if (!defaultStore.isLogin) {
                const isSuccess = await defaultStore.login();
                if (!isSuccess) {
                    defaultStore.unlock();
                    return false;
                }
            }

            // 不信任任何sdk的状态同步！！！！！！！！！！
            // 业务store不建议尝试同步任何sdk提供的状态！！！！！！！！！！！！！！！！！！

            // 如果当前忙则不处理
            // if (defaultStore.isCalling()) {
            //     return message.info('请结束当前通话后再尝试');
            // }
            // if (defaultStore.somethingIsLoading) {
            //     return message.info('当前有正在处理的操作，请稍后再试');
            // }

            // 处理新的电话请求
            await defaultStore.handleNewCall(
                phoneNum,
                isHiddenCallPhoneNum,
                config,
            );

            await delay(1000);
            defaultStore.unlock();
        };
        CCEE.on('new-call', handler);
        return () => {
            CCEE.removeListener('new-call', handler);
        };
    }, [callCenterConfig]);

    useEffect(() => {
        if (!inited) {
            return;
        }

        // 自动登录，需要预先获取账号密码
        // no auto
        // defaultStore.login();
    }, [inited]);

    useEffect(() => {
        if (defaultStore.isCalling()) {
            openActionLogModal();
        }
    }, [defaultStore.isCalling()]);

    const { somethingIsLoading, isLogined } = defaultStore;

    if (!callCenterConfig) {
        return null;
    }

    if (null !== error) {
        return (
            <span className="cc-toolbar">
                <Icon className="icon" type="customer-service" />
                <span className="status">{error}</span>
            </span>
        );
    }

    if (!inited) {
        return (
            <span className="cc-toolbar">
                <Icon className="icon" type="customer-service" />
                <span className="title">呼叫中心：</span>
                <span className="status">初始化中</span>
            </span>
        );
    }
    // const renderActivityFollowJob = () => {
    //     return (
    //         <CustomerActivityFollowJobCreate
    //             customers={[
    //                 createdFollowLog.customerObj
    //             ]}
    //             followLog={createdFollowLog}
    //             trigger={customerActivityFollowJobCreateTrigger}
    //             onSuccess={() => { }}
    //         />
    //     )
    // }
    const {
        supplierDesc,
        showCallingTime,
        callingTime,
        noLogout,
        isInCall,
    } = defaultStore;
    const { supplierCn, no } = supplierDesc;
    // const entityType = defaultStore.callActionConfig.sceneCode?.indexOf('lead') > -1 ? 'lead'
    const sceneCode = defaultStore.callActionConfig.sceneCode;
    let entityType: EntityType = 'customer';
    if (sceneCode) {
        if (sceneCode.indexOf('lead') > -1) {
            entityType = 'lead';
        } else if (sceneCode.indexOf('contacts') > -1) {
            entityType = 'contacts';
        }
    }

    const entityTypeIdMap = {
        customer: 'customerId',
        lead: 'leadId',
        contacts: 'contactsId',
    };

    return (
        <>
            <CustomerActivityFollowJobCreate
                customers={[createdFollowLog?.customerObj]}
                followLog={createdFollowLog}
                trigger={customerActivityFollowJobCreateTrigger}
                onSuccess={() => {}}
            />

            <AddFollowLog
                entityType={entityType}
                cid={
                    defaultStore.callActionConfig.customerId as
                        | number
                        | undefined
                }
                id={
                    defaultStore.callActionConfig[
                        entityTypeIdMap[entityType] as EntityTypeId
                    ]
                }
                addFollowData={{
                    contactsId: defaultStore.callActionConfig.contactsId,
                    leadId: defaultStore.callActionConfig.leadId,
                }}
                callStore={defaultStore}
                drawerVis={actionLogVis}
                setDrawerVis={setActionLogVis}
                initMark={followActionInitMark}
                onCreated={(data: any) => {
                    if (data.isCreateJob === 1) {
                        setCreatedFollowLog(data);
                        setCustomerActivityFollowJobCreateTrigger(
                            1 + customerActivityFollowJobCreateTrigger,
                        );
                    }
                }}
            />

            <span className="cc-toolbar">
                <Icon
                    className="icon"
                    style={isLogined ? { color: '#0052ff' } : {}}
                    type="customer-service"
                />
                <span className="title">呼叫中心：</span>
                <span
                    className="status animated fadeIn"
                    key={'status-' + defaultStore.statusText}
                >
                    {defaultStore.statusText}
                </span>
            </span>
            <Popover
                trigger="click"
                placement="bottomRight"
                content={
                    <div className="cc-toolbar-call" style={{ padding: 8 }}>
                        <Input.Search
                            width={250}
                            loading={somethingIsLoading}
                            enterButton={<Icon type="phone" />}
                            placeholder="输入手机号或座机号"
                            onSearch={value => {
                                tryNewCall(value, false, {
                                    showFollowLog: true,
                                });
                            }}
                        ></Input.Search>
                    </div>
                }
            >
                <Button icon="phone" shape="circle" size="small" />
            </Popover>
        </>
    );
});
