import React, { useState, useContext, useEffect } from 'react';
import './index.scss';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { LayoutStandardCrud } from '../../../components/layouts/standard-crud/index';
import { AntButton } from '../../../components/antd/index';
import { TableLv2 } from '@/components';
import { defaultAxios } from '@/utils';
import _ from 'lodash';
import { ICityInfoType, IStateInfoType } from '../../../stores/setting/index';
import { Modal, Select, message } from 'antd';
const customerLevelMap = [
    {
        value: '6',
        key: '高价值客户',
    },
    {
        value: '7',
        key: '普通客户',
    },
];
// const customerLevelMap = [
//     {
//         value: '1',
//         key: 'S-战略客户',
//     },
//     {
//         value: '2',
//         key: 'A-品牌客户',
//     },
//     {
//         value: '3',
//         key: 'B-重点客户',
//     },
//     {
//         value: '4',
//         key: 'C-潜力用户',
//     },
//     {
//         value: '5',
//         key: 'D-普通用户',
//     },
// ];
export interface ICHSPAADataType {
    id?: string | number;
    stateId: string | number;
    cityId: string | number;
    highSeaId: string | number;
    customerLevel?: string | number;
    remark: string;
}
const CustomerHSPAutoAllocation: React.FC = props => {
    const store = useContext(StoreContext);
    const settingStore = store.getSettingDefaultStore();
    const settingCHSPGStore = store.getDefaultSettingCHSPGStore();
    const { tableData: highSeasData, fetchCHSPGTableData } = settingCHSPGStore;
    const { isAreaInfoLoading, areaInfo, departInfo } = settingStore;

    const [CHSPAADataSource, setCHSPAADataSource] = useState<ICHSPAADataType[]>(
        [],
    );
    const [CHSPAALoading, setCHSPAALoading] = useState(false);

    const [addCHSPAAModalVis, setAddCHSPAAModalVis] = useState(false);
    const [CHSPAAModalMode, setCHSPAAModalMode] = useState<'update' | 'create'>(
        'create',
    );
    const [addCHSPAAData, setAddCHSPAAData] = useState<ICHSPAADataType>({
        stateId: '',
        cityId: '',
        highSeaId: '',
        remark: '',
    });
    const [addCHSPAAValidateData, setAddCHSPAAValidateData] = useState<{
        [key: string]: {
            status: 'error' | 'success';
            msg: string;
        };
    }>({
        stateId: {
            status: 'success',
            msg: '',
        },
        highSeaId: {
            status: 'success',
            msg: '',
        },
    });
    const fetchCHSPAAList = async () => {
        setCHSPAALoading(true);
        const url = '/bff/api/rest/chspaa/list';
        const [d, e] = await defaultAxios.get(url);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (Array.isArray(d.data)) {
            setCHSPAADataSource(d.data);
            setCHSPAALoading(false);
        }
        return [data, error];
    };
    const deleteCHSPGAA = async (id: string | number) => {
        setCHSPAALoading(true);
        const url = '/bff/api/rest/chspaa/delete';
        const [d, e] = await defaultAxios.post(url, {
            id,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d.data) {
            fetchCHSPAAList();
        }
        return [data, error];
    };
    const addCHSPGAA = async () => {
        let isError = false;
        let tempAddCHSPAAValidateData = {
            ...addCHSPAAValidateData,
        };
        if (!addCHSPAAData.stateId) {
            // message.warning('输入不正确，请检查后再提交', 1);
            tempAddCHSPAAValidateData = {
                ...tempAddCHSPAAValidateData,
                stateId: {
                    status: 'error',
                    msg: '省份不能为空',
                },
            };
            isError = true;
        }
        if (!addCHSPAAData.highSeaId) {
            tempAddCHSPAAValidateData = {
                ...tempAddCHSPAAValidateData,
                highSeaId: {
                    status: 'error',
                    msg: '公海池不能为空',
                },
            };
            isError = true;
        }
        if (isError) {
            setAddCHSPAAValidateData(tempAddCHSPAAValidateData);
            message.warning('输入不正确，请检查后再提交', 1);
            return;
        }
        const url =
            CHSPAAModalMode === 'create'
                ? '/bff/api/rest/chspaa/add'
                : '/bff/api/rest/chspaa/update';
        const [d, e] = await defaultAxios.post(url, {
            ...addCHSPAAData,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d.data) {
            setAddCHSPAAModalVis(false);
            setAddCHSPAAData({
                stateId: '',
                cityId: '',
                highSeaId: '',
                remark: '',
                id: undefined,
                customerLevel: undefined,
            });
            fetchCHSPAAList();
        }
        return [data, error];
    };
    useEffect(() => {
        fetchCHSPGTableData();
        fetchCHSPAAList();
    }, []);

    const renderCHSPAAAddModal = () => {
        const selectedState = _.find(areaInfo.stateRelation, state => {
            return state.id === addCHSPAAData.stateId;
        });
        return (
            <Modal
                title={`${
                    CHSPAAModalMode === 'create' ? '新建' : '修改'
                }公海池自动分配规则`}
                okText="提交"
                visible={addCHSPAAModalVis}
                onCancel={() => {
                    setAddCHSPAAModalVis(false);
                    setAddCHSPAAData({
                        id: undefined,
                        stateId: '',
                        cityId: '',
                        highSeaId: '',
                        remark: '',
                        customerLevel: undefined,
                    });
                    setAddCHSPAAValidateData({
                        stateId: {
                            status: 'success',
                            msg: '',
                        },
                        highSeaId: {
                            status: 'success',
                            msg: '',
                        },
                    });
                }}
                onOk={addCHSPGAA}
            >
                <div className="chsp-aa-add-modal-content">
                    <div>
                        <div className="chsp-aa-add-modal-items">
                            <div className="chsp-aa-add-select">
                                <label>
                                    省<span className="required-mark">*</span>
                                </label>
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    placeholder="选择省份"
                                    value={
                                        addCHSPAAData.stateId === -1
                                            ? undefined
                                            : addCHSPAAData.stateId
                                    }
                                    filterOption={(searchValue, option) => {
                                        const children =
                                            (option.props.children as string) ||
                                            '';
                                        return (
                                            children.indexOf(searchValue) != -1
                                        );
                                    }}
                                    onSelect={(value: any) => {
                                        setAddCHSPAAData({
                                            ...addCHSPAAData,
                                            stateId: value,
                                            cityId: '',
                                        });
                                    }}
                                    onFocus={() => {
                                        setAddCHSPAAValidateData({
                                            ...addCHSPAAValidateData,
                                            stateId: {
                                                status: 'success',
                                                msg: '',
                                            },
                                        });
                                    }}
                                >
                                    {_.map(areaInfo.stateRelation, state => {
                                        return (
                                            <Select.Option
                                                value={state.id}
                                                key={state.id}
                                            >
                                                {state.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>
                            {addCHSPAAValidateData.stateId.status ===
                                'error' && (
                                <p className="aa-error-msg">
                                    {addCHSPAAValidateData.stateId.msg}
                                </p>
                            )}
                        </div>
                        <div className="chsp-aa-add-modal-items">
                            <div className="chsp-aa-add-select">
                                <label>市</label>
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    placeholder="选择城市"
                                    value={
                                        addCHSPAAData.cityId === -1
                                            ? undefined
                                            : addCHSPAAData.cityId
                                    }
                                    filterOption={(searchValue, option) => {
                                        const children =
                                            (option.props.children as string) ||
                                            '';
                                        return (
                                            children.indexOf(searchValue) != -1
                                        );
                                    }}
                                    onSelect={(value: any) => {
                                        setAddCHSPAAData({
                                            ...addCHSPAAData,
                                            cityId: value,
                                        });
                                    }}
                                    disabled={!addCHSPAAData.stateId}
                                >
                                    <Select.Option
                                        value={undefined}
                                        key={'undefiled'}
                                    >
                                        无
                                    </Select.Option>
                                    {_.map(selectedState?.citys || [], city => {
                                        return (
                                            <Select.Option
                                                value={city.id}
                                                key={city.id}
                                            >
                                                {city.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="chsp-aa-add-modal-items">
                            <div className="chsp-aa-add-select">
                                <label>客户分层</label>
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    placeholder="选择客户分层"
                                    value={
                                        !addCHSPAAData.customerLevel ||
                                        addCHSPAAData.customerLevel == -1
                                            ? undefined
                                            : String(
                                                  addCHSPAAData.customerLevel,
                                              )
                                    }
                                    // filterOption={(searchValue, option) => {
                                    //     const children =
                                    //         (option.props.children as string) ||
                                    //         '';
                                    //     return (
                                    //         children.indexOf(searchValue) != -1
                                    //     );
                                    // }}
                                    onSelect={(value: any) => {
                                        setAddCHSPAAData({
                                            ...addCHSPAAData,
                                            customerLevel: value,
                                        });
                                    }}
                                    // disabled={!addCHSPAAData.stateId}
                                >
                                    {_.map(
                                        customerLevelMap || [],
                                        customerLevel => {
                                            return (
                                                <Select.Option
                                                    value={customerLevel.value}
                                                    key={customerLevel.value}
                                                >
                                                    {customerLevel.key}
                                                </Select.Option>
                                            );
                                        },
                                    )}
                                </Select>
                            </div>
                        </div>
                        <div className="chsp-aa-add-modal-items">
                            <div className="chsp-aa-add-select">
                                <label>
                                    分配公海池
                                    <span className="required-mark">*</span>
                                </label>
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    placeholder="选择公海池"
                                    value={addCHSPAAData.highSeaId}
                                    filterOption={(searchValue, option) => {
                                        const children =
                                            (option.props.children as string) ||
                                            '';
                                        return (
                                            children.indexOf(searchValue) != -1
                                        );
                                    }}
                                    onSelect={(value: any) => {
                                        setAddCHSPAAData({
                                            ...addCHSPAAData,
                                            highSeaId: value,
                                        });
                                    }}
                                    onFocus={() => {
                                        setAddCHSPAAValidateData({
                                            ...addCHSPAAValidateData,
                                            highSeaId: {
                                                status: 'success',
                                                msg: '',
                                            },
                                        });
                                    }}
                                >
                                    {_.map(highSeasData, v => {
                                        return (
                                            <Select.Option
                                                value={v.id}
                                                key={v.id}
                                            >
                                                {v.description}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>
                            {addCHSPAAValidateData.highSeaId.status ===
                                'error' && (
                                <p className="aa-error-msg">
                                    {addCHSPAAValidateData.highSeaId.msg}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };
    const globalOptions = (
        <>
            <AntButton
                onClick={() => {
                    setCHSPAAModalMode('create');
                    setAddCHSPAAModalVis(true);
                }}
                type="primary"
            >
                新建
            </AntButton>
        </>
    );
    const title = (
        <span>
            公海池自动分配&nbsp;
            {/* {metaLoading ? <Spin /> : null} */}
        </span>
    );
    return (
        <>
            {renderCHSPAAAddModal()}
            <LayoutStandardCrud
                message={null}
                popups={null}
                title={title}
                multipleOps={null}
                globalOps={globalOptions}
                filters={null}
                className="customer-hsp-aa-content"
                dataTable={
                    <TableLv2<ICHSPAADataType>
                        loading={CHSPAALoading}
                        dataSource={CHSPAADataSource}
                        pagination={false}
                        closeRowSelection={true}
                        onAction={(actionKey, actionItem) => {
                            if (actionKey === 'delete') {
                                // deleteCHSPGAA(actionItem.id);
                                const existingCity = _.find(
                                    areaInfo.dictCity,
                                    oCity => {
                                        return oCity.id === actionItem.cityId;
                                    },
                                );
                                const existingState = _.find(
                                    areaInfo.dictState,
                                    oState => {
                                        return oState.id === actionItem.stateId;
                                    },
                                );
                                const existingHighSea = _.find(
                                    highSeasData,
                                    oHighSeas => {
                                        return (
                                            oHighSeas.id ===
                                            actionItem.highSeaId
                                        );
                                    },
                                );
                                Modal.confirm({
                                    title: '删除自动分配公海池',
                                    content: (
                                        <div>
                                            该操作将删除「
                                            {existingState?.state_name || ''}
                                            {existingCity?.city_name || ''}」 「
                                            {existingHighSea?.description}
                                            」的客户新建自动分配公海池，确认是否删除？
                                        </div>
                                    ),
                                    onOk: () =>
                                        deleteCHSPGAA(actionItem.id as number),
                                });
                            }

                            if (actionKey === 'update') {
                                setCHSPAAModalMode('update');
                                setAddCHSPAAData({
                                    ...actionItem,
                                    stateId: '',
                                    cityId: '',
                                });
                                setAddCHSPAAModalVis(true);
                            }
                        }}
                        actions={[
                            {
                                actionKey: 'delete',
                                actionCn: '删除',
                            },
                            {
                                actionKey: 'update',
                                actionCn: '修改',
                            },
                        ]}
                        columns={[
                            {
                                title: '客户省市',
                                key: 'customer_state_city',
                                width: '150px',
                                render: (item: ICHSPAADataType) => {
                                    const existingCity = _.find(
                                        areaInfo.dictCity,
                                        oCity => {
                                            return oCity.id === item.cityId;
                                        },
                                    ) as ICityInfoType;
                                    const existingState = _.find(
                                        areaInfo.dictState,
                                        oState => {
                                            return oState.id === item.stateId;
                                        },
                                    ) as IStateInfoType;
                                    return `${existingState?.state_name ||
                                        ''}${`${
                                        existingCity && existingState ? '-' : ''
                                    }${existingCity?.city_name || ''}`}`;
                                },
                            },
                            {
                                title: '自动分配公海池',
                                width: '200px',
                                key: 'highSeaId',
                                render: (item: ICHSPAADataType) => {
                                    const existingHighSea = _.find(
                                        highSeasData,
                                        oHighSeas => {
                                            return (
                                                oHighSeas.id === item.highSeaId
                                            );
                                        },
                                    );
                                    return existingHighSea?.description;
                                },
                            },
                            {
                                title: '客户分层',
                                width: '200px',
                                key: 'customerLevel',
                                render: (item: ICHSPAADataType) => {
                                    if (item.customerLevel) {
                                        const existingLevel = _.find(
                                            customerLevelMap,
                                            customerLevel => {
                                                return (
                                                    customerLevel.value ==
                                                    item.customerLevel
                                                );
                                            },
                                        );
                                        return existingLevel?.key || '';
                                    } else {
                                        return null;
                                    }
                                },
                            },
                        ]}
                    />
                }
            />
        </>
    );
};

export default observer(CustomerHSPAutoAllocation);
