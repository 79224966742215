import React, { useEffect } from 'react';
import { useAnyLeadStore } from '@/pages/sales-follow-up/lead/list/store';
import LeadForm from '@/pages/sales-follow-up/lead/list/components/form-drawer';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Modal } from 'antd';
import { tryRefresh } from '@/utils';

export const CustomerLeadsCreate: React.FC<{
    trigger: number;
    customerInfo: any;
}> = observer(({ trigger, customerInfo }) => {
    customerInfo = customerInfo || {};
    const { customer_name, biz_type } = customerInfo;
    const [prepared, defaultStore, prepareStore] = useAnyLeadStore({
        opUserSuffix: 'customer-drawer',
        autoLoad: false,
    });
    const { actionType } = defaultStore;

    useEffect(() => {
        if (trigger > 0) {
            prepareStore();
            runInAction(() => {
                defaultStore.setAction('create');
            });
        }
    }, [trigger]);

    useEffect(() => {
        if (!prepared || actionType !== 'create') {
            return;
        }
        defaultStore.startNewData();
        if (!customer_name) {
            return;
        }
        defaultStore.mutatingData.company_name = customer_name;
        defaultStore.mutatingData.biz_type = biz_type;
        defaultStore.autoFillOtherParamsByCustomerName(customer_name);
    }, [prepared, customer_name, actionType]);

    if (trigger === 0) {
        return null;
    }

    // 根据外部抽屉数据的状态和内部leadstore准备状态
    const preRequiredReady = customer_name && prepared;
    // return null
    return (
        <LeadForm
            leadList={defaultStore}
            preRequiredReady={preRequiredReady}
            mutateSuccess={() => {
                tryRefresh(['customer']);
            }}
            type={'all'}
            fromCustomerTab={true}
        />
    );
});
