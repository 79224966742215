import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    FastFilterStore,
} from '@/stores/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import { mutate } from '@/api/graphql';
import _ from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    getLeads,
    TGetLeadsReturnType,
    xDelete,
    commonQuery,
    TGetCommonReturnType,
} from '@/api/rest';
import { defaultWritingDataParser, defaultAxios } from '@/utils';
import EE from 'eventemitter3';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';

export class ContractProductExecuteListStore {
    // 数据
    public data: any[] = [];

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public get pageTitle() {
        if (this.listType === 'all') {
            return '全部开通申请';
        } else if (this.listType === 'mine') {
            return '我的开通申请';
        }
        return '未知listType';
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFastFilters = this.defaultFastFilters;
        const {
            selectedFastFilters,
            selectedLv4MenuFilters,
        } = defaultFastFilters;

        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );
        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        for (const filterSet of selectedFastFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        for (const filterSet of selectedLv4MenuFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        ret.filters?.push(...defaultFilters);
        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }
    public setAction(nextAction: string) {
        this.actionType = nextAction;
    }
    public resetAction() {
        this.netValidateResult = {};
        this.actionType = '';
    }
    public async dispatchAction() {}

    public newDataHash = '';
    public mutatingData: any = null;
    get mutatingDataParsed() {
        return defaultWritingDataParser(this.mutatingData);
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        const initialFormData = this.defaultMeta.defaultWidgetValues;
        this.mutatingData = _.cloneDeep(initialFormData);
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;

    get fieldAuthCode() {
        return this.listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    }
    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode } = this;

        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
        );

        if (_.isNil(d) || !_.isNil(e)) {
            return [d, e];
        }
        const leadsData = d;
        const { amount, datas } = leadsData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMeta = new ListMeta(
        '/bff/api/rest/meta/contract_product_execute/list',
        '/bff/api/rest/meta/contract_product_execute/list/filters/save',
        '/bff/api/rest/meta/contract_product_execute/list/table-cols/save',
    );
    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();
    public defaultPerm: Perm = new Perm('/bff/api/rest/perm?mod=om:contract');
    public defaultMSelect: MSelectStore = new MSelectStore();
    public defaultFastFilters = new FastFilterStore();
    constructor() {
        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultMeta: false,
            defaultFilter: false,
            defaultEE: false,
            defaultPerm: false,
            defaultMSelect: false,
            defaultFastFilters: false,
        });
    }
}

export const getDefaultContractProductExecuteListStore = createDefaultInstanceGetter(
    ContractProductExecuteListStore,
);
