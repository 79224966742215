import { makeAutoObservable, observable } from 'mobx';
import { createDefaultInstanceGetter } from '../../../stores/utils/index';
import { defaultAxios } from '@/utils';
import EE from 'eventemitter3';
import { message } from 'antd';

// 数据角色权限
export interface IDRMAuthData {
    entityName: string; // 模块名称
    entityCode: string; // 模块id
    auth: {
        authKey: string; // 权限key
        authName: string; // 权限名 (查询、编辑、删除、转移)
        authValue?: string | number; // 权限状态 (1 本人 2 本人及下属 3 本部门 4 本部门及下级部门 5 全部)
        editable?: boolean; // 是否是需要编辑的权限 (true: 可以编辑 false: 不可以)
    }[];
}
export interface IDRMRoleAuthData {
    isSimple?: boolean; // 是否是简易模式 true是简易 false不是
    simpleAuth?: string | number; // (1 本人 2 本人及下属 3 本部门 4 本部门及下级部门 5 全部),
    authority?: IDRMAuthData[];
}
// 数据角色
export interface IDRMRoleData {
    id?: number | string; // id
    dataRoleName?: string; // 数据角色名称
}
export type TGetDRMRoleAuthReturnType = NetResponse<{
    data: IDRMAuthData[];
    msg: string;
    code: number;
}>;
export type TGetDRMRoleListReturnType = NetResponse<{
    data: IDRMRoleData[];
    msg: string;
    code: number;
}>;
export class DRMStore {
    constructor() {
        makeAutoObservable(this);
    }
    public DRMRoleListData: IDRMRoleData[] = [];
    public DRMRoleAuthData: IDRMRoleAuthData = {};
    public DRMRoleAuthConfig: IDRMAuthData[] = [];
    public defaultDRMEE = new EE();
    public isUpdateLoading = false; // 提交数据状态
    // 配置
    public fetchConfig = async () => {
        const url = '/bff/api/rest/drm/config';
        const [d, e] = await defaultAxios.get(url);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data && Array.isArray(data)) {
            this.DRMRoleAuthConfig = data || [];
        }
        return [data, error];
    };
    // 新增数据角色
    public createDRMRole = async (roleName: string) => {
        const url = '/bff/api/rest/drm/update-create-role';
        const [d, e] = await defaultAxios.post(url, {
            roleName,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 获取角色列表
    public fetchDRMRoleList: () => Promise<
        TGetDRMRoleListReturnType
    > = async () => {
        const url = '/bff/api/rest/drm/d-list';
        const [d, e] = await defaultAxios.get(url);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data && Array.isArray(data)) {
            this.DRMRoleListData = data || [];
        }
        return [data, error];
    };
    // 获取数据角色权限
    public fetchDataRoleAuth: (
        roleId: string | number,
    ) => Promise<TGetDRMRoleAuthReturnType> = async roleId => {
        if (!roleId) {
            return [null, null];
        }
        const url = '/bff/api/rest/drm/data-role-auth';
        const [d, e] = await defaultAxios.get(url, {
            roleId,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data && Array.isArray(data.authority)) {
            this.DRMRoleAuthData = data || {};
        }
        return [data, error];
    };
    // 修改角色权限
    public updateDRMRole = async (updateData: any) => {
        if (updateData.isSimple && !updateData.simpleAuth) {
            message.warning('请选择数据权限后保存～');
            return [null, null];
        }
        this.isUpdateLoading = true;
        const url = '/bff/api/rest/drm/update-data-role-auth';
        const [d, e] = await defaultAxios.post(url, updateData);
        this.isUpdateLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 删除角色
    public deleteDRMRole = async (deleteRole: IDRMRoleData) => {
        const url = '/bff/api/rest/drm/delete-role';
        const [d, e] = await defaultAxios.post(url, {
            id: deleteRole.id,
        });
        this.isUpdateLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
}
export const getDefaultDRMStore = createDefaultInstanceGetter(DRMStore);
