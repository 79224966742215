import { makeAutoObservable } from 'mobx';
import { createDefaultInstanceGetter } from '../utils/index';
import axios from 'axios';
import { message } from 'antd';
import Config from '@/configs/default';
import { goToLogin, goToStatus } from '../../utils/helpers';
import { MenuPermStore } from '../perm';
import { navigate } from '@reach/router';
import * as Sentry from '@sentry/react';
import * as Cookie from 'tiny-cookie';

export interface IUserInfoType {
    userName?: string;
    userAvatar?: string;
    sysUser?: any;
    subUsers?: any;
    highseaList?: any;
    highseaCustomerVisible?: boolean;
    mineCustomerVisible?: boolean;
    contractCreateVisible?: {
        buy: boolean;
        om: boolean;
    };
    supportedBizTypes?: {
        [entityCode: string]: {
            businessTypeIds?: number[];
        };
    };
    callCenterConfig?: any;
    mdUserId?: number;
    userId?: string;
    [key: string]: any;
}
export interface IAuthInfoType {
    permission?: {
        [key: string]: any;
    };
}

export class AuthStore {
    public isLoginLoading = true; // 是否正在请求登录(用户信息)接口，默认true
    public isLogin = false; // 默认没有login
    public isAuthLoading = false; // 是否正在请求鉴权接口
    public userInfo: IUserInfoType = {}; // 用户信息
    public authInfo: IAuthInfoType = {}; // 权限信息
    public defaultMenuPermStore = MenuPermStore;
    constructor() {
        makeAutoObservable(this);
    }
    public loginOut = () => {
        window.location.href = Config.login_out_url;
    };
    public login = goToLogin;
    public getAuthInfo = async () => {
        this.isAuthLoading = true;
        try {
            const res = await axios({
                url: '/bff/api/rest/get-auth-info',
                method: 'post',
                withCredentials: true,
            });
            if (res) {
                this.authInfo = res.data || {};
                this.isAuthLoading = false;
            } else {
                this.isAuthLoading = false;
                // TODO:错误处理
            }
        } catch (error) {
            // 请求失败
            this.isAuthLoading = false;
            message.error(error || '请求失败，请检查网络连接后重试');
        }
    };

    public getUserInfo = async (autoJump: boolean = true) => {
        this.isLoginLoading = true;
        axios({
            url: '/bff/api/rest/get-user-info',
            method: 'post',
            withCredentials: true,
        })
            .then(res => {
                if (res) {
                    this.userInfo = res?.data?.data || {};
                    if (
                        !this.userInfo?.menuPerm ||
                        this.userInfo.menuPerm.length === 0
                    ) {
                        navigate('/status/403');
                    }
                    // document.cookie=`crmuid=${this.userInfo.sysUser.id}`
                    if (this.userInfo.sysUser.id) {
                        Cookie.set('crmuid', this.userInfo.sysUser.id);
                    }
                    if (this.userInfo.sysUser.name) {
                        Cookie.set('crmuname', this.userInfo.sysUser.name);
                    }
                    Sentry.configureScope(scope => {
                        scope.setUser({
                            id: this.userInfo?.userId,
                            username: this.userInfo?.userName,
                        });
                    });
                    this.defaultMenuPermStore.setOrignalPerm(
                        this.userInfo?.menuPerm || [],
                    );
                    this.isLoginLoading = false;
                    this.isLogin = true;
                } else {
                    this.isLoginLoading = false;
                }
            })
            .catch(error => {
                // node反错
                this.isLoginLoading = false;
                this.defaultMenuPermStore.setOrignalPerm([]);
                if (error.response && error.response.status) {
                    if (
                        error.response.status === 403 ||
                        error.response.status === 500
                    ) {
                        if (window.location.href.indexOf('status') === -1) {
                            goToStatus(error.response.status);
                        }
                    }
                    if (error.response.status === 401) {
                        if (autoJump) {
                            goToLogin();
                        }
                    }
                } else {
                    message.error('请求失败，请检查网络连接后重试');
                }
            });
    };

    public genSysUserIdItemAndParamMeta = () => {
        const sysUser = this.userInfo?.sysUser || {};
        if (!sysUser.id || !sysUser.name || !sysUser.phone) {
            return null;
        }
        return {
            type: 'obj',
            showFiledName: 'name',
            originalValue: sysUser.id,
            relatedObj: {
                relation_object_map: {},
                id: sysUser.id,
                name: sysUser.name,
                phone: sysUser.phone,
            },
            showRelatedObj: {
                relation_object_map: {},
                id: sysUser.id,
                name: sysUser.name,
                phone: sysUser.phone,
            },
        };
    };
}

export const getAuthStore = createDefaultInstanceGetter(AuthStore);
