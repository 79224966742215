import _ from 'lodash';
import { genDaysAgoFilterFactory } from './helpers';
import { AuthStore } from '@/stores/auth';
import moment from 'moment';

export const leadDFConfs: { [entityCode: string]: any } = {
    lead: {
        lv4MenuFilters: {
            all: [
                {
                    key: 'buy-lead-all-public',
                    label: '全部公海线索',
                    value: [],
                },
                // TODO, 动态公海池列表筛选
                async (options: { authStore: AuthStore }) => {
                    const { authStore } = options;
                    const highseaList = authStore?.userInfo?.highseaList || [];

                    return highseaList
                        .map((highsea: any) => {
                            const { description, id } = highsea || {};
                            if (!description || !id) {
                                return;
                            }
                            return {
                                label: description + '线索',
                                value: [
                                    {
                                        condition: {
                                            fieldId: 'customer_id.high_sea_id',
                                            filterValue: id,
                                            operateType: '=',
                                        },
                                    },
                                ],
                            };
                        })
                        .filter(Boolean);
                },
            ],
            mine: [
                {
                    key: 'buy-lead-mine-private',
                    label: '全部私池客户的线索',
                    value: [],
                },
                async (options: { authStore: AuthStore }) => {
                    const { authStore } = options;
                    const ownerId = authStore?.userInfo?.sysUser?.id;
                    return {
                        key: 'buy-lead-mine-my',
                        label: '我负责客户的线索',
                        value: [
                            ownerId
                                ? {
                                      condition: {
                                          fieldId: 'customer_id.owner_id',
                                          filterValue: ownerId,
                                          operateType: '=',
                                      },
                                  }
                                : null,
                        ].filter(Boolean),
                    };
                },
                async (options: { authStore: AuthStore }) => {
                    const { authStore } = options;
                    const subIds = (authStore?.userInfo?.subUsers || []).map(
                        (item: any) => item.id,
                    );

                    return {
                        key: 'buy-lead-mine-subordinate',
                        label: '我下属客户的线索',
                        value: [
                            subIds.length > 0
                                ? {
                                      condition: {
                                          fieldId: 'owner_id',
                                          filterValue: subIds.join(','),
                                          operateType: 'in',
                                      },
                                  }
                                : null,
                        ].filter(Boolean),
                    };
                },
            ],
        },
        fastFilters: {
            all: [
                genDaysAgoFilterFactory({
                    label: '今日新增线索',
                    fastFilterKey: 'today-new',
                    fieldId: 'create_time',
                    days: 0,
                }),
            ],
            mine: [
                genDaysAgoFilterFactory({
                    label: '今日新增线索',
                    fastFilterKey: 'today-new',
                    fieldId: 'create_time',
                    days: 0,
                }),
                {
                    label: 'callin线索',
                    fastFilterKey: 'callin-lead',
                    value: [
                        {
                            condition: {
                                fieldId: 'lead_source',
                                filterValue: 15,
                                operateType: '=',
                            },
                        },
                    ],
                },
                // {
                //     label: '市场线索',
                //     fastFilterKey: 'market-lead',
                //     value: [
                //         {
                //             condition: {
                //                 fieldId: 'lead_source',
                //                 filterValue: 2,
                //                 operateType: '=',
                //             },
                //         },
                //     ],
                // },
                // async () => {
                //     return {
                //         label: '7天内未跟进线索',
                //         fastFilterKey: '7_recent_activity_record_time',
                //         value: [
                //             {
                //                 condition: {
                //                     fieldId: 'recent_activity_record_time',
                //                     filterValue: moment()
                //                         .subtract(7, 'days')
                //                         .startOf('day')
                //                         .format('YYYY-MM-DD HH:mm:ss'),
                //                     operateType: '<=',
                //                 },
                //             },
                //         ],
                //     };
                // },
                {
                    label: '留资线索',
                    fastFilterKey: 'liuzi-lead',
                    value: [
                        {
                            condition: {
                                fieldId: 'lead_source',
                                filterValue: 7,
                                operateType: '=',
                            },
                        },
                    ],
                },
                {
                    label: '未跟进线索',
                    fastFilterKey: 'undeal-lead',
                    value: [
                        {
                            condition: {
                                fieldId: 'lead_follow_status',
                                filterValue: 2,
                                operateType: '=',
                            },
                        },
                    ],
                },
            ],
        },
    },
};
