import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Input, message, Form } from 'antd';
import { rejects, btnMap } from './conf';
import './action.scss';
import _ from 'lodash';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const RejectAction: React.FC<{
    currentAction: string;
    data: any;
    onOk: (extra?: any) => void;
    onCancel: () => void;
    loading: boolean;
}> = observer(({ currentAction, data, onOk, onCancel, loading }) => {
    const title = btnMap[currentAction] || '';
    const comment = data?.comment || '';
    const visible =
        rejects[currentAction] && currentAction !== '' && data !== null;

    const [pass, setPass] = useState(true);
    const inputHelps: any = !pass
        ? {
              validateStatus: 'error',
              help: '审批意见必填',
          }
        : {};

    return (
        <Modal
            title={title}
            onOk={() => {
                if (!_.trim(comment)) {
                    setPass(false);
                    return message.error('审批建议必填');
                }
                onOk();
            }}
            confirmLoading={loading}
            onCancel={onCancel}
            visible={visible}
            zIndex={10001}
        >
            <p>请填写审批意见</p>

            <div className="process-action">
                <div className="form-wrapper">
                    <Form className="form" layout={'horizontal'}>
                        <Form.Item
                            {...formItemLayout}
                            {...inputHelps}
                            required
                            label={<span className="label">审批意见</span>}
                        >
                            <Input.TextArea
                                required={true}
                                value={comment}
                                onChange={e => {
                                    const text = e?.target?.value;
                                    if (!_.isUndefined(text)) {
                                        data.comment = text;
                                    }
                                }}
                                placeholder={'审批意见'}
                            ></Input.TextArea>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    );
});
