import React, { useEffect, memo, useState } from 'react';

import { CustomerListStore } from '../store';
import { Modal, Alert, Button, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { defaultAxios, isNetSuccess } from '@/utils';
import { BlockLoading2 } from '@/components';

export const Kurong: React.FC<{
    customerList: CustomerListStore;
}> = observer(({ customerList }) => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const resetState = () => {
        setData(null);
        setLoading(false);
    };
    const visible = customerList.actionType === 'kurongyue';
    useEffect(() => {
        if (!visible) {
            return;
        }
        setLoading(true);
        defaultAxios
            .get('/crm/customer/searchUserHighSeaLimit')
            .then(([d, e]) => {
                setLoading(false);
                if (isNetSuccess(d, e)) {
                    setData(d?.data || null);
                } else {
                    message.error('库容数据查询出错');
                }
            });
    }, [visible]);

    return (
        <Modal
            visible={visible}
            title={'我的库容余额'}
            onCancel={() => {
                customerList.resetAction();
                resetState();
            }}
            footer={[
                <Button
                    onClick={async () => {
                        customerList.resetAction();
                        resetState();
                    }}
                    type="primary"
                >
                    关闭
                </Button>,
            ]}
        >
            {/* {loading && <BlockLoading2 />} */}
            {/* surplusNum: 96
totalNum: 98
usingNum: 2 */}
            {!loading && data && (
                <Alert
                    type="info"
                    message={
                        <span>
                            私海库容<b>{data?.totalNum}</b>个，当前可用余额
                            <b style={{ color: 'red' }}>{data?.surplusNum}</b>
                            个（子客户不占库容）
                        </span>
                    }
                />
            )}
        </Modal>
    );
});
