import React, { useState, useEffect, useContext, useCallback } from 'react';
import { DTable, isMultipleCell } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { DemoListStore } from '../store';
import { runInAction } from 'mobx';
import DefaultDrawer from '../drawer';
import { StoreContext } from '@/stores';
import { applyCustomConfToCellsSelector } from '@/components/common/form/advanced-filters';
import { tryRefresh } from '@/utils';

const DemoTable: React.FC<{
    defaultStore: DemoListStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const store = useContext(StoreContext);
    const customerListStore = store.getDefaultCustomerListStore();
    const { defaultMSelect } = defaultStore;

    const { mSeletedData, setMSelectedData } = defaultMSelect;

    const [drawerVis, setDrawerVis] = useState(false);
    const [detailId, setDetailId] = useState(undefined);

    const dataTable = (
        <>
            <DefaultDrawer
                drawerVis={drawerVis}
                setDrawerVis={setDrawerVis}
                detailId={detailId}
                destroyOnClose={true}
                onEdit={data => {
                    runInAction(() => {
                        defaultStore.setAction('update', {
                            id: data.id,
                        });
                    });
                }}
                onCreat={() => {
                    runInAction(() => {
                        defaultStore.setAction('create');
                        defaultStore.startNewData();
                    });
                }}
            />
            <DTable
                onColumnsUpdated={() => tryRefresh(['lead'])}
                defaultStore={defaultStore}
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [
                        {
                            actionKey: 'update',
                            actionCn: '修改',
                        },
                        {
                            actionKey: 'delete',
                            actionCn: '删除',
                        },
                    ];
                    props.onAction = (key, data) => {
                        if (key === 'update') {
                            runInAction(() => {
                                defaultStore.setAction('update', {
                                    id: data.id,
                                });
                            });
                        } else if (key === 'transform') {
                            // 其他动作
                        } else if (_.startsWith(key, 'reveal-obj/')) {
                            // 关联对象都可以点击
                        } else if (_.startsWith(key, 'reveal-param/')) {
                            // 可点击参数，召唤drawer
                            if (key.indexOf('/name') !== -1) {
                                setDetailId(data.id);
                                setDrawerVis(true);
                            }
                        }
                    };
                    props.closeRowSelection = false;
                    props.selectedItems = mSeletedData;
                    props.onSelectedDataChange = setMSelectedData;
                    return props;
                }}
                cellsSelectorTransformer={baiscCellsSelector => {
                    // 详情可点击
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'name',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'ActionParam';
                            nextCellSelector.inputs.push({
                                paramName: 'name',
                            });
                            return nextCellSelector;
                        },
                    );
                    return baiscCellsSelector;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
