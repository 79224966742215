import _ from 'lodash';

export const AchieveManaDFConfs: { [entityCode: string]: any } = {
    achievement_mana: {
        lv4MenuFilters: {
            all: [],
        },
        fastFilters: {
            all: [
                {
                    label: '全国',
                    fastFilterKey: 'quanguo',
                    value: [],
                    table: 'sale_target',
                    target_type: 1,
                },
                {
                    label: '区域',
                    fastFilterKey: 'quyu',
                    value: [],
                    table: 'sale_target',
                    target_type: 2,
                },
                {
                    label: '城市',
                    fastFilterKey: 'chengshi',
                    value: [],
                    table: 'sale_target',
                    target_type: 3,
                },
                {
                    label: '小组',
                    fastFilterKey: 'xiaozu',
                    value: [],
                    table: 'sale_target',
                    target_type: 4,
                },
                {
                    label: '销售',
                    fastFilterKey: 'xiaoshou',
                    value: [],
                    table: 'sale_target',
                    target_type: 5,
                },
            ],
        },
    },
};
