export const rejects: { [action: string]: boolean } = {
    REJECT_BACK: true,
    REJECT_BEGIN: true,
    REJECT_APPLY: true,
};

export const dontUseDefaultAction: { [action: string]: boolean } = {
    ...rejects,
    TRANSFER: true,
    AGREE: true,
    SUBMIT: true,
    DELETE: true,
};

export const btnMap: { [str: string]: string } = {
    SUBMIT: '提交',
    AGREE: '通过',
    REJECT_BACK: '拒绝(退回上一步)',
    REJECT_BEGIN: '拒绝(退回开始)',
    REJECT_APPLY: '拒绝(退回申请)',
    RESET: '撤回',
    ADD_APPROVE: '加签',
    TRANSFER: '转办',
    DELETE: '删除',
};
