import { arrToJson } from '@/utils';
import React, { memo } from 'react';
import { AutoEllipsis } from '../../../auto-ellipsis';

export const JobPriority: React.FC<{ args: any[] }> = memo(({ args }) => {
    const value = args[0];
    if (!value) {
        return null;
    }
    const text = jobPriorityMap[value]?.label || '';
    return (
        <AutoEllipsis
            style={{ color: colorMap[text] || 'inherit' }}
            text={text}
        />
    );
});

export const colorMap: { [label: string]: string } = {
    高: 'rgb(216, 57, 49)',
    中: 'rgb(222, 120, 2)',
    低: 'black',
};
const priorityEnums = [
    {
        label: '高',
        value: 1,
    },
    {
        label: '中',
        value: 2,
    },
    {
        label: '低',
        value: 3,
    },
];

export const jobPriorityMap = arrToJson(priorityEnums, 'value');

export const getJobPriorityColorByValue = (value: number): string => {
    return colorMap[jobPriorityMap[value + '']?.label || ''];
};
