import React, { useEffect, useCallback } from 'react';
import { useState, useContext } from 'react';
import './index.scss';
import CommonDrawer from '@/components/common/drawer/index';
import { IDrawerTitleProps } from '@/components/common/drawer/drawer-title';
import { Icon, message, Menu, Select, DatePicker, Modal } from 'antd';
import { IDrawerCardListProps } from '@/components/common/drawer/drawer-card-list/index';
import ItemUser, {
    IItemsUserProps,
} from '@/components/common/items/user/index';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import ItemPhone from '@/components/common/items/phone';
import { IDrawerTabsProps } from '@/components/common/drawer/drawer-tabs/index';
import DrawCollapse from '@/components/common/drawer/drawer-collapse';
import { IDrawerCollapseProps } from '@/components/common/drawer/drawer-collapse/index';
import DataReview from '@/components/common/items/data-review/index';
import { defaultAxios, delay, isNetSuccess, useNet, useRefresh } from '@/utils';
import FinalContractProductList from './components/contract-product-list';
import FinalContractIncomeList from './components/contract-income-list';
import _ from 'lodash';
import moment from 'moment';
import { ContractInvoiceApplyCreate } from './components/contract-invoice-apply-create';
import { useAnyContractListStore, ContractListStore } from '../store';
import { DDetail } from '@/components/common/editable-display/ddetail';
import {
    makeObjColClickable,
    isMultipleCell,
    applyCustomConfToCellsSelector,
} from '@/components';
import { CtxProvider } from '@/utils/context';
import ContractInvoiceTable from '@/pages/om/contract/contract-invoice/for-contract-tab';
import { CategoryProcessForTabContext } from '@/pages/sales-follow-up/process/list/category-any-for-tab';

// 抽屉针对客户业务的二次封装，高度业务定制
interface ICrmContractDrawer {
    drawerVis: boolean;
    setDrawerVis: (bool: boolean) => void;
    detailId?: string | number;
    destroyOnClose?: boolean;
    onEdit?: (data: any) => void;
    onCreat?: (data: any) => void;
    forceReload?: boolean; // 如果要在外部强制刷新用户详情，则将该值变化为true
    contractListStore: ContractListStore;
}
interface ICrmDetailData {
    detailInfo: IDrawerCollapseProps;
    originData: any;
    // 此处可维护静态字段
    staticData: {
        name: string; // 客户名称
        id: string | number; // 客户id
        owner_id: any; // 关联字段 - 客户所有人
    };
    userField: any;
    actionLogConfig: {
        arType: any[];
        followType: any[];
    }; // 活动记录基础配置
}
export type TGetCrmDetailReturnType = NetResponse<{
    data: ICrmDetailData;
    msg: string;
    code: number;
}>;
const ContractDrawer: React.FC<ICrmContractDrawer> = props => {
    const [
        contractInvoiceCreateTrigger,
        setContractInvoiceCreateTrigger,
    ] = useState(0);

    const [editLoading, setEditLoading] = useState(false);

    const {
        drawerVis,
        setDrawerVis,
        detailId,
        destroyOnClose = false,
        onCreat,
        onEdit,
        forceReload = false,
        contractListStore,
    } = props;
    const [drawerData, setDrawerData] = useState<any>({});

    const { listType } = contractListStore;
    const eventCode = listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';

    const [prepared, defaultContractListStore] = useAnyContractListStore({});

    const { defaultPerm } = defaultContractListStore;

    const loadDetail = () => {
        if (prepared && drawerVis) {
            if (detailId) {
                defaultContractListStore.fetchInstantlyMutatingDataById(
                    detailId,
                );
            } else {
                defaultContractListStore.setMutatingData(null);
            }
        } else {
            defaultContractListStore.setMutatingData(null);
        }
    };
    useEffect(loadDetail, [drawerVis, detailId, prepared]);
    useRefresh(
        ['contract'],
        () => {
            loadDetail();
        },
        [drawerVis, detailId, prepared],
    );

    const [dataFail, setDataFail] = useState(false);

    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const fetchCustomerDetail: () => Promise<
        TGetCrmDetailReturnType
    > = async () => {
        const thisCid = detailId;
        if (_.isNil(detailId)) {
            return [null, null];
        }
        const [d, e] = await defaultAxios.get(
            `/bff/api/rest/contract/detail?id=${detailId}`,
        );
        if (d === null || d.data === null) {
            setDataFail(true);
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d && d.data) {
            if (
                !d.data.originData ||
                _.isEmpty(d.data.originData) ||
                !d.data.originData?.length
            ) {
                setDataFail(true);
                return [null, new Error('no auth')];
            }
            if (thisCid === detailId) {
                setDataFail(false);
                setDrawerData(d.data);
            }
        }
        return [data, error];
    };
    const [error, loading, reload] = useNet(fetchCustomerDetail, {
        refreshKeys: ['contract'],
    });
    useEffect(() => {
        if (drawerVis) {
            if (detailId) {
                reload();
            }
        } else {
            // 当客户抽屉关闭，将会清空里面的所有数据
            setDrawerData({});
        }
    }, [detailId, drawerVis]);

    const originData = drawerData?.originData
        ? drawerData?.originData[0] || {}
        : {};

    const staticData = drawerData?.staticData || {};
    const titleExampleConfig: IDrawerTitleProps = {
        title: (
            <>
                <span
                    className="iconfont1"
                    style={{
                        color: '#0052ff',
                        fontSize: '18px',
                        marginRight: '8px',
                    }}
                >
                    &#xe612;
                </span>
                {staticData?.contract_name || '- -'}
            </>
        ),
        icons: [],
        tags: [],
        buttons: [],
    };

    if (
        prepared &&
        titleExampleConfig.buttons &&
        defaultPerm.getPermByTypeAndAction('')('contract_invoice_apply').visible
    ) {
        titleExampleConfig.buttons.push({
            type: 'button',
            buttonConfig: {
                type: 'primary',
                children: '开发票',
                onClick: () => {
                    contractListStore.setAction('contract_invoice_apply');
                    contractListStore.fetchInstantlyMutatingDataById(detailId);
                },
            },
        });
    }

    const goEdit = () => {
        const { process_instance_id } = originData;
        if (!process_instance_id) {
            return message.error('process_instance_id不存在，流程打开失败');
        }
        window.open(
            `/buy/process-management/group?id=${process_instance_id}&target=contract_detail`,
        );
    };

    if (
        originData &&
        prepared &&
        titleExampleConfig.buttons &&
        defaultPerm.getPermByTypeAndAction('')('update').visible
    ) {
        titleExampleConfig.buttons.push({
            type: 'button',
            buttonConfig: {
                type: 'default',
                children: '编辑',
                loading: editLoading,
                onClick: async () => {
                    const {
                        process_applicant_id,
                        process_instance_id,
                        process_approval_status,
                        process_approval_userid,
                    } = originData;

                    if (authStore.userInfo?.userId !== process_applicant_id) {
                        return message.error(
                            '您不是合同审批流程提交人，无权修改',
                        );
                    }

                    if (process_approval_status === 'COMPLETED') {
                        return message.error(
                            '此合同已审批归档，不支持修改。如有特殊需求，请联系CRM产品',
                        );
                    }

                    if (process_applicant_id === process_approval_userid) {
                        return goEdit();
                    }

                    const detailUrl = `/crm/workflow/instance/detail?instanceId=${process_instance_id}&currentUser=${process_applicant_id}`;
                    setEditLoading(true);
                    const [d, e] = await defaultAxios.get(detailUrl);
                    const btns = d?.data?.buttons;
                    if (!isNetSuccess(d, e) || !_.isArray(btns)) {
                        setEditLoading(false);
                        return message.error('流程详情请求出错');
                    }

                    const resetable = btns.indexOf('RESET') > -1;
                    if (!resetable) {
                        setEditLoading(false);
                        return message.error(
                            '当前流程节点不支持撤回再修改，如有特殊需求，请联系CRM产品',
                        );
                    }

                    Modal.confirm({
                        title: '此合同还在审批中，请确认是否撤回再修改？',
                        content: (
                            <div>
                                <div>具体修改方法：</div>
                                <div>1. 先撤回原合同审批流程</div>
                                <div>2. 确认【当前审批人】是自己</div>
                                <div>3. 修改合同信息后</div>
                                <div>4. 再次提交审批</div>
                            </div>
                        ),
                        okText: '确认撤回',
                        async onOk() {
                            // 执行撤回操作
                            const taskId = d?.data?.currentTask?.id;
                            const operate = 'RESET';
                            const currentUser = authStore.userInfo?.userId;
                            const [d2, e2] = await defaultAxios.put(
                                '/crm/workflow/instance/operate',
                                {
                                    taskId,
                                    operate,
                                    currentUser,
                                },
                            );
                            if (!isNetSuccess(d2, e2)) {
                                setEditLoading(false);
                                return message.error('撤回失败');
                            }

                            setEditLoading(false);
                            const close = message.loading('正在打开修改页面');
                            await delay(300);
                            close();
                            goEdit();
                        },
                    });
                },
            },
        });
    }

    if (dataFail) {
        titleExampleConfig.buttons = [];
    }

    const cardExampleConfig: IDrawerCardListProps = {
        dataSource: [
            {
                title: '客户名称',
                content: originData?.customer_name ? (
                    <a
                        target="_blank"
                        href={
                            '/buy/custom/my-custom?target=contract-drawer-header&id=' +
                            originData?.customer_id?.originalValue
                        }
                    >
                        {originData?.customer_name || '- -'}
                    </a>
                ) : (
                    '- -'
                ),
            },
            {
                title: '客户所有人',
                content: originData?.customer_owner || '- -',
            },
            {
                title: '合同金额',
                content: originData?.contract_amount || '- -',
            },
            {
                title: '合同所有人',
                content: originData?.opportunity_owner || '- -',
            },
            {
                title: '合同状态',
                content: staticData?.contract_status
                    ? (() => {
                          const metas =
                              contractListStore.defaultMeta?.objectMeta || [];
                          const metaItem = metas.find(
                              item => item.fieldName === 'contract_status',
                          );
                          if (!metaItem) {
                              return '- -';
                          }
                          const constraint = metaItem.constraint;
                          if (
                              constraint &&
                              !constraint.constraintObj &&
                              Array.isArray(constraint.constraintItemList)
                          ) {
                              const existingValue = _.find(
                                  constraint.constraintItemList,
                                  constraintItem => {
                                      return (
                                          constraintItem?.constraintValue ===
                                          staticData?.contract_status
                                      );
                                  },
                              );
                              if (!existingValue) {
                                  return '- -';
                              }
                              return existingValue.constraintLabel;
                          }
                          return '- -';
                      })()
                    : '- -',
            },
        ],
    };
    const customExampleCollapseConfig: IDrawerCollapseProps =
        drawerData.detailInfo;
    const tabsExampleConfig: IDrawerTabsProps = {
        tabsContentConfig: {
            defaultActiveKey: '1',
            tabBarGutter: 24,
            tabConfig: [
                {
                    key: '1',
                    tab: '合同信息',
                    content: (
                        <DDetail
                            eventCode={eventCode}
                            defaultStore={defaultContractListStore}
                            cellsSelectorTransformer={originalbaiscCellsSelector => {
                                const baiscCellsSelector = [
                                    ...originalbaiscCellsSelector,
                                ];
                                _.forEach(baiscCellsSelector, cItem => {
                                    if (isMultipleCell(cItem)) {
                                        return cItem;
                                    }
                                    if (cItem.cell === 'CC') {
                                        if (cItem.inputs.length < 3) {
                                            cItem.inputs = [
                                                ...cItem.inputs,
                                                'crm_contract',
                                            ];
                                        } else {
                                            cItem.inputs[2] = 'crm_contract';
                                        }
                                        // cItem.inputs.push('crm_lead');
                                    }
                                    // if (cItem.cell === 'CC') {
                                    //     cItem.inputs.push('crm_contract');
                                    // }
                                });

                                applyCustomConfToCellsSelector(
                                    baiscCellsSelector,
                                    'contract_attachments',
                                    cellSelector => {
                                        const nextCellSelector = _.cloneDeep(
                                            cellSelector,
                                        );
                                        if (isMultipleCell(nextCellSelector)) {
                                            return nextCellSelector;
                                        }
                                        nextCellSelector.cell = 'Files';

                                        return nextCellSelector;
                                    },
                                );

                                makeObjColClickable(
                                    baiscCellsSelector,
                                    'customer_id',
                                );
                                makeObjColClickable(
                                    baiscCellsSelector,
                                    'opportunity_id',
                                );
                                return baiscCellsSelector;
                            }}
                        />
                    ),
                },
                {
                    key: '2',
                    tab: '合同产品',
                    content: (
                        <FinalContractProductList
                            drawerData={drawerData}
                            type="inner-contract-drawer"
                        />
                    ),
                },
                {
                    key: '2.5',
                    tab: '发票',
                    content: (
                        <CategoryProcessForTabContext.Provider
                            value={{ parentData: originData }}
                        >
                            <ContractInvoiceTable type="all" />
                        </CategoryProcessForTabContext.Provider>
                    ),
                },
                {
                    key: '3',
                    tab: '回款记录',
                    content: (
                        <FinalContractIncomeList
                            drawerData={drawerData}
                            type="inner-contract-drawer"
                        />
                    ),
                },
            ],
        },
    };

    return (
        <>
            <CtxProvider
                ctx={{ position: 'drawer', positionDetail: 'contract-drawer' }}
            >
                {/* <ContractInvoiceApplyCreate
                    trigger={contractInvoiceCreateTrigger}
                    contractInfo={originData}
                /> */}
                <CommonDrawer
                    isFail={dataFail}
                    destroy={destroyOnClose ? !drawerVis : false}
                    loading={loading}
                    titleConfig={titleExampleConfig}
                    DrawerContentConfig={{
                        zIndex: 999,
                        placement: 'right',
                        onClose: () => {
                            setDrawerVis(false);
                        },
                        visible: drawerVis,
                    }}
                    extraRight={<></>}
                    hideRightContent={true}
                    DrawerCardListConfig={cardExampleConfig}
                    DrawerTabsConfig={tabsExampleConfig}
                />
            </CtxProvider>
        </>
    );
};

export default observer(ContractDrawer);
