/*
 * @Author: your name
 * @Date: 2020-12-04 17:19:23
 * @LastEditTime: 2020-12-08 14:47:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /maimai_crm/src/components/common/draw/drawer-data-review/index.js
 */

import React, { useState, ReactElement } from 'react';
import './index.scss';
import _ from 'lodash';
import { SGrid, ISGridLayout } from '@/components';
import { TSGridLayoutProp } from '@/components/layouts';
import ItemPhone from '../phone';
import ItemUser from '../user';
import moment from 'moment';

export interface IDataReviewItemsProps {
    key?: string;
    label: string | React.ReactElement | (() => ReactElement);
    labelExtra?: React.ReactElement | (() => ReactElement);
    labelExtraPosition?: 'left' | 'right';
    labelClassName?: string;
    detail?:
        | string
        | (() => ReactElement)
        | number
        | {
              constraintLabel?: string | number; // 无关联转换实际展示
              constraintValue?: any; // 无关联转换元数据
              type?: string; // 关联转换type
              originalValue?: any; // 关联转换元数据
              showFiledName?: string; // 展示的字段名
              showRelatedObj?: {
                  [key: string]: any;
              };
              [key: string]: any;
          };
    detailClassName?: string;
    inline?: boolean; // false的时候元素会单独成行
    viewMode?: 'inline' | 'row'; // label和detail的展示方案是左右(inline)还是上下(row)
    detailType?: 'phone' | 'input' | 'clickInput' | 'user';
    typeConfig?: any;
    visible?: boolean;
}
export interface IDataReviewProps {
    dataReviewConfig: IDataReviewItemsProps[];
    contentClassName?: string;
    itemClassName?: string;
    gridLayout?: TSGridLayoutProp;
    rowNum?: number;
}

const DataReview: React.FC<IDataReviewProps> = props => {
    const {
        dataReviewConfig = [],
        contentClassName = '',
        itemClassName = '',
        gridLayout = [],
        rowNum = 2,
    } = props;

    // 自动生成layout配置
    const makeGridLayoutConfig = () => {
        const allConfigArray: number[][] = []; // 保存多行信息
        let lineConfigArray: number[] = []; // 保存单行信息
        let lineConfigCount = 0;

        for (const config of dataReviewConfig) {
            const { visible = true } = config;
            if (!visible) {
                continue;
            }
            if (lineConfigCount === rowNum) {
                allConfigArray.push(lineConfigArray);
                lineConfigArray = [];
                lineConfigCount = 0;
            }
            if (config.inline === undefined || config.inline) {
                lineConfigCount++;
                lineConfigArray.push(Math.round(24 / rowNum));
            } else {
                // 单行触发为保证顺序，将直接push多行元素，所以应避免将单行元素与多行元素间隔使用
                if (lineConfigArray.length) {
                    allConfigArray.push(lineConfigArray);
                    lineConfigArray = [];
                    lineConfigCount = 0;
                }
                allConfigArray.push([24]);
            }
        }
        // 可能有最后一行
        if (lineConfigArray.length) {
            allConfigArray.push(lineConfigArray);
        }
        console.log(allConfigArray);
        return allConfigArray;
    };

    const gridLayoutConfig = gridLayout.length
        ? gridLayout
        : makeGridLayoutConfig();

    const renderDetail = (detail: IDataReviewItemsProps) => {
        const generateDetail = () => {
            if (detail.detailType) {
                switch (detail.detailType) {
                    case 'input':
                        // TODO:
                        break;
                    case 'phone':
                        return <ItemPhone {...detail.typeConfig} />;
                    case 'user':
                        return <ItemUser {...detail.typeConfig} />;
                    case 'clickInput':
                        // TODO:
                        break;
                    default:
                        break;
                }
            } else {
                const finalDetail = detail.detail;
                if (typeof finalDetail === 'function') {
                    return finalDetail();
                } else if (typeof finalDetail === 'object') {
                    if (finalDetail.constraintLabel) {
                        // 非关联
                        return finalDetail.constraintLabel;
                    }
                    if (
                        finalDetail.showRelatedObj &&
                        finalDetail.showFiledName
                    ) {
                        // 关联
                        return finalDetail.showRelatedObj[
                            finalDetail.showFiledName
                        ];
                    }
                    return '';
                } else {
                    if (detail.key?.indexOf('time') != -1) {
                        return moment(finalDetail).format(
                            'YYYY-MM-DD HH:mm:ss',
                        );
                    }
                    return finalDetail;
                }
            }
        };
        const finalDetail = generateDetail();
        if (_.isNil(finalDetail) || finalDetail === -1 || finalDetail === '') {
            // 空处理
            return '- -';
        } else {
            return finalDetail;
        }
    };
    const dataReviewDataSource = _.filter(
        dataReviewConfig,
        (config: IDataReviewItemsProps) => {
            const { visible = true } = config;
            return visible;
        },
    );
    return (
        <div className={`crm-data-review-content ${contentClassName}`}>
            <SGrid layout={gridLayoutConfig}>
                {_.map(
                    dataReviewDataSource,
                    (item: IDataReviewItemsProps, idx) => {
                        const {
                            detailClassName = '',
                            labelClassName = '',
                            viewMode = 'inline',
                            labelExtraPosition = 'right',
                            inline = true,
                            visible = true,
                        } = item;
                        if (!visible) {
                            return null;
                        }
                        return (
                            <div
                                className={`crm-data-review-item ${itemClassName} ${
                                    inline ? '' : `item-${rowNum}-single`
                                } ${
                                    viewMode === 'inline' ? 'inline' : 'block'
                                }`}
                            >
                                {item.label && (
                                    <div
                                        className={`${
                                            viewMode === 'inline'
                                                ? 'label-left'
                                                : 'label-top'
                                        } ${labelClassName}`}
                                    >
                                        {item.labelExtraPosition === 'left' && (
                                            <span className="label-extra left-extra">
                                                {typeof item.labelExtra ===
                                                'function'
                                                    ? item.labelExtra()
                                                    : item.labelExtra}
                                            </span>
                                        )}
                                        {typeof item.label === 'function'
                                            ? item.label()
                                            : item.label}
                                        {item.labelExtraPosition ===
                                            'right' && (
                                            <span className="label-extra right-extra">
                                                {typeof item.labelExtra ===
                                                'function'
                                                    ? item.labelExtra()
                                                    : item.labelExtra}
                                            </span>
                                        )}
                                    </div>
                                )}
                                <div
                                    className={`${
                                        viewMode === 'inline'
                                            ? 'detail-right'
                                            : 'detail-bottom'
                                    } ${detailClassName}`}
                                >
                                    {item.detail || item.detailType
                                        ? renderDetail(item)
                                        : '- -'}
                                </div>
                            </div>
                        );
                    },
                )}
            </SGrid>
        </div>
    );
};
export default DataReview;
