import React, { useState, useEffect } from 'react';
import _ from 'lodash';

export const HighLightText: React.FC<{
    text: string;
    highlightConf: {
        [text: string]: {
            color: string;
        };
    };
}> = ({ text, highlightConf }) => {
    const keys = _.keys(highlightConf);

    const [textEles, setTextEles] = useState<React.ReactChild[] | null>(null);

    useEffect(() => {
        const textSnippets = keys.reduce<string[]>(
            (ret, cur) => {
                const next: string[] = [];
                for (const item of ret) {
                    const _coms = item.split(cur);
                    const coms = [];
                    for (const c of _coms) {
                        coms.push(c);
                        coms.push(cur);
                    }
                    coms.pop();
                    next.push(...coms.filter(Boolean));
                }
                return next;
            },
            [text],
        );

        const textEles = textSnippets.map(t => {
            if (highlightConf[t]) {
                return (
                    <span style={{ color: highlightConf[t].color }}>{t}</span>
                );
            }
            return t;
        });

        setTextEles(textEles);
    }, [text, highlightConf]);

    return <>{textEles}</>;
};
