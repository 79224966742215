import * as React from 'react';
import _ from 'lodash';
import { IActionWidgetItem, IItemOptionItem, IWidgetItem } from '@/components';

export interface IRuleSettingData {
    assignType?: number | string;
    recycleType?: number | string;
    returnCantClaim?: number | string;
    recycleGetNotFollow?: number | string;
    recycleGetNotFollowTo?: number | string;
    inCooperationFollowup?: number | string;
    noCooperation20Opportunity?: number | string;
    noCooperation40Opportunity?: number | string;
    noFollowAfterFirstFollow?: number | string;
    recycleNotDealNew?: number | string;
    // recycleGetNoNewOpportunity?: number | string;
    recycleNotDeal?: number | string;
    // recycleGetDefinedDeal?: any[];
    recycleBreak?: number | string; // 断约客户
    recycleDeferred?: number | string; // 延期天数
    recycleAlertOne?: number | string; // 提醒时间1
    recycleAlertTwo?: number | string; // 提醒时间2
    recycleAlertThree?: number | string; // 提醒时间3
    dealText?: string; // 已成交客户（文案）
    // recycleDealNotFollow?: number | string;
    // recycleDealNotFollowTo?: number | string;
}
export interface IRuleExtraData {
    ruleDefaultDataLoading: boolean; // 加载初始的rule
}

export const widgetsRuleSetting: () => Array<
    IWidgetItem<IRuleSettingData, IRuleExtraData>
> = () => {
    const widgets: Array<IWidgetItem<IRuleSettingData, IRuleExtraData>> = [
        {
            key: 'assignType',
            widgetKey: 'radio',
            radioOptions: {
                className: 'chspg-radio-group',
                options: [
                    {
                        label: '分组管理员手动分配给销售员',
                        value: 1,
                    },
                    {
                        label: '销售员自行领取',
                        value: 2,
                    },
                ],
            },
        },
        {
            key: 'recycleType',
            widgetKey: 'radio',
            radioOptions: {
                className: 'chspg-radio-group',
                options: [
                    {
                        label: '分组管理员手动回收',
                        value: 1,
                    },
                    {
                        label: '系统自动回收',
                        value: 2,
                    },
                ],
            },
        },
        {
            key: 'recycleGetNotFollow',
            afterWidget: <span>天无首次跟进（新增活动记录）</span>,
            beforeWidget: <span>自领取入库后</span>,
            widgetKey: 'input',
            widgetClassName: 'gc-rule-input',
            wrapperKey: 'gc-rule',
            inputOptions: {
                type: 'number',
                min: 0,
            },
        },
        {
            key: 'noFollowAfterFirstFollow',
            beforeWidget: <span>自首次跟进后</span>,
            afterWidget: <span>天无二次跟进（新增活动记录）</span>,
            widgetKey: 'input',
            wrapperKey: 'gc-rule',
            inputOptions: {
                type: 'number',
                min: 0,
            },

        },
        {
            key: 'recycleGetNotFollowTo',
            beforeWidget: <span>自上次跟进后</span>,
            afterWidget: <span>天无再次跟进（新增活动记录）</span>,
            widgetKey: 'input',
            wrapperKey: 'gc-rule',
            inputOptions: {
                type: 'number',
                min: 0,
            },
        },

        {
            key: 'noCooperation20Opportunity',
            beforeWidget: <span >自领取入库后</span>,
            afterWidget: <span>天内未创建20商机</span>,
            widgetKey: 'input',
            wrapperKey: 'gc-rule',
            inputOptions: {
                type: 'number',
                min: 0,
            },
        },
        {
            key: 'noCooperation40Opportunity',
            beforeWidget: <span >自领取入库后</span>,
            afterWidget: <span>天未推进到40商机</span>,
            widgetKey: 'input',
            wrapperKey: 'gc-rule',
            inputOptions: {
                type: 'number',
                min: 0,
            },
        },
        {
            key: 'recycleNotDeal',
            afterWidget: <span>天没有成交（必须大于跟进和再次跟进天数）</span>,
            widgetKey: 'input',
            wrapperKey: 'gc-rule',
            inputOptions: {
                type: 'number',
                min: 0,
            },
        },
        {
            key: 'dealText',
            afterWidget: (
                <span className="deal-text">最后一个付费产品到期未续约</span>

            ),
            widgetKey: 'input',
            wrapperKey: 'gc-rule',
            inputOptions: {
                type: 'hidden',
                disabled: true,
            },
        },
        {
            key: 'inCooperationFollowup',
            afterWidget: <span>天没有再次跟进（新增活动记录）</span>,
            widgetKey: 'input',
            wrapperKey: 'gc-rule',
            inputOptions: {
                type: 'number',
                min: 0,
            },
        },
        {
            key: 'recycleBreak',
            afterWidget: <span>天没有成交</span>,
            widgetKey: 'input',
            wrapperKey: 'gc-rule',
            inputOptions: {
                type: 'number',
                min: 0,
            },
        },
        // {
        //     key: 'recycleGetDefinedDeal',
        //     widgetKey: 'checkbox',
        //     formItemClassName: 'chspg-rule-recycleGetDefinedDeal',
        //     checkboxOptions: {
        //         className: 'chspg-rule-checkbox',
        //         options: [
        //             {
        //                 label: '机会赢单',
        //                 value: '1',
        //             },
        //             {
        //                 label: '创建合同',
        //                 value: '2',
        //             },
        //             {
        //                 label: '创建订单',
        //                 value: '3',
        //             },
        //         ],
        //     },
        //     afterWidget: (
        //         <span
        //             style={{
        //                 display: 'block',
        //                 fontSize: '12px',
        //                 position: 'relative',
        //                 left: '5px',
        //                 color: 'rgb(155,155,155)',
        //             }}
        //         >
        //             至少选择一项
        //         </span>
        //     ),
        //     wrapperKey: 'gc-rule',
        // },
        // {
        //     key: 'recycleDealNotFollow',
        //     afterWidget: <span>天没有持续跟进</span>,
        //     widgetKey: 'input',
        //     wrapperKey: 'gc-rule',
        //     inputOptions: {
        //         type: 'number',
        //         min: 0,
        //     },
        // },
        // {
        //     key: 'recycleDealNotFollowTo',
        //     afterWidget: <span>天没有再次成交（必须大于持续跟进天数）</span>,
        //     widgetKey: 'input',
        //     wrapperKey: 'gc-rule',
        //     inputOptions: {
        //         type: 'number',
        //         min: 0,
        //     },
        // },
        {
            key: 'returnCantClaim',
            widgetKey: 'input',
            afterWidget: <span>天内，同一销售不能重复认领同一个客户</span>,
            // beforeWidget: <span>退回之后</span>,
            widgetClassName: 'cshpg-rule-returnCantClaim',
            inputOptions: {
                type: 'number',
                min: 0,
            },
        },
        {
            key: 'recycleDeferred',
            widgetKey: 'input',
            afterWidget: (
                <span>天（延期审核通过，在正常到期时间基础上增加天数）</span>
            ),
            widgetClassName: 'cshpg-rule-recycleDeferred',
            inputOptions: {
                type: 'number',
                min: 0,
            },
        },
        {
            key: 'recycleAlertOne',
            widgetKey: 'input',
            beforeWidget: <span>客户回收截止日前：</span>,
            afterWidget: <span>天，</span>,
            wrapperKey: 'recycle-alert',
            widgetClassName: 'cshpg-rule-recycleAlert-body',
            formItemClassName: 'cshpg-rule-recycleAlert-item',
            inputOptions: {
                type: 'number',
                min: 0,
            },
        },
        {
            key: 'recycleAlertTwo',
            widgetKey: 'input',
            wrapperKey: 'recycle-alert',
            afterWidget: <span>天，</span>,
            widgetClassName: 'cshpg-rule-recycleAlert-body',
            formItemClassName: 'cshpg-rule-recycleAlert-item',
            inputOptions: {
                type: 'number',
                min: 0,
            },
        },
        {
            key: 'recycleAlertThree',
            widgetKey: 'input',
            wrapperKey: 'recycle-alert',
            afterWidget: <span>天，分别提醒销售</span>,
            widgetClassName: 'cshpg-rule-recycleAlert-body',
            formItemClassName: 'cshpg-rule-recycleAlert-item',
            inputOptions: {
                type: 'number',
                min: 0,
            },
        },
    ];
    const lastWidgets = [...widgets];
    // if (recycleType !== 2) {
    //     // 2是系统自动回收
    //     // 动态变化表单
    //     lastWidgets.splice(2, 1);
    //     console.log(widgets);
    // };
    return lastWidgets;
};
export const makeRuleActionWidgets = (
    loadingStatus: boolean,
    isAdd?: boolean,
) => {
    const ruleActionWidgets: Array<IActionWidgetItem<
        IRuleSettingData,
        IRuleExtraData
    >> = [
            {
                key: 'submit',
                option: {
                    btnText: '提交',
                    type: 'primary',
                    disabled: loadingStatus,
                },
            },
            {
                key: 'back-step',
                option: {
                    btnText: '上一步',
                    type: 'default',
                    clickActionType: 'other',
                    hidden: !isAdd,
                },
            },
            {
                key: 'cancel',
                option: {
                    btnText: '取消',
                    type: 'default',
                    clickActionType: 'cancel',
                },
            },
        ];
    return ruleActionWidgets;
};
export const ruleActionWidgets: Array<IActionWidgetItem<
    IRuleSettingData,
    IRuleExtraData
>> = [
        {
            key: 'submit',
            option: {
                btnText: '提交',
                type: 'primary',
            },
        },
        {
            key: 'cancel',
            option: {
                btnText: '取消',
                type: 'default',
                clickActionType: 'cancel',
            },
        },
    ];

export const ruleFormItemOptions: Array<IItemOptionItem<IRuleSettingData>> = [
    {
        key: 'assignType',
        label: '客户领取/分配机制',
        required: true,
    },
    {
        key: 'recycleType',
        label: '客户回收机制',
        required: true,
    },
    {
        key: 'returnCantClaim',
        label: '反作弊规则',
        required: true,
    },
    {
        key: 'recycleDeferred',
        label: '延期天数',
    },
    {
        key: 'recycleAlertOne',
        label: '回收提醒',
    },
    {
        key: 'recycleGetNotFollow',
        label: '未成交客户',
        labelCol: { span: 3, offset: 12 },
    },
    {
        key: 'recycleNotDeal',
        label: '',
    },
    {
        key: 'dealText',
        label: '已成交客户',
        labelCol: { span: 3, offset: 12 },
    },
    {
        key: 'recycleBreak',
        label: '断约客户',
        labelCol: { span: 3, offset: 12 },
    }, {
        key: 'recycleNotDealNew',
        label: '',
    },

    // {
    //     key: 'recycleGetDefinedDeal',
    //     label: '成交定义：',
    // },
];

export const ruleValidators: {
    [key in keyof IRuleSettingData]?: Validator<IRuleSettingData>;
} = {
    assignType: datam => {
        console.log(datam);
        const data = datam.assignType;
        if (!data) {
            return {
                status: 'fail',
                msg: '请选择领取/分配机制',
            };
        }
        return {
            status: 'success',
            msg: '',
        };
    },
    recycleType: datam => {
        const data = datam.recycleType;
        if (!data) {
            return {
                status: 'fail',
                msg: '请选择客户回收机制',
            };
        }
        return {
            status: 'success',
            msg: '',
        };
    },
    recycleGetNotFollowTo: datam => {
        const dataGC = datam.recycleType;
        const dataNotFollowTo = datam.recycleGetNotFollowTo || '';
        if (dataGC !== 2) {
            return {
                status: 'success',
                msg: '',
            };
        }
        if (!dataNotFollowTo || _.trim(dataNotFollowTo as string) === '') {
            if (dataNotFollowTo == 0) {
                return {
                    status: 'fail',
                    msg: '不能等于0',
                };
            }
            return {
                status: 'success',
                msg: '',
            };
        }
        if (dataNotFollowTo < 0) {
            return {
                status: 'fail',
                msg: '不能小于0',
            };
        } else {
            return {
                status: 'success',
                msg: '',
            };
        }
    },
    //     const dataGC = datam.recycleType;
    //     const dataNotFollowTo = datam.recycleGetNotFollowTo || '';
    //     if (dataGC !== 2) {
    //         return {
    //             status: 'success',
    //             msg: '',
    //         };
    //     }
    //     if (
    //         !datam.recycleGetNotFollow ||
    //         _.trim(datam.recycleGetNotFollow as string) === ''
    //     ) {
    //         return {
    //             status: 'fail',
    //             msg: '不能为空或0',
    //         };
    //     } else {
    //         if (datam.recycleGetNotFollow < 0) {
    //             return {
    //                 status: 'fail',
    //                 msg: '不能小于0',
    //             };
    //         }
    //         if (Number(dataNotFollowTo) <= Number(datam.recycleGetNotFollow)) {
    //             console.log(dataNotFollowTo, datam.recycleGetNotFollow);
    //             return {
    //                 status: 'fail',
    //                 msg: '再次跟进天数必须大于跟进天数',
    //             };
    //         }
    //         return {
    //             status: 'success',
    //             msg: '',
    //         };
    //     }
    // },
    // noCooperation20Opportunity: datam => {
    //     const dataGC = datam.recycleType;
    //     if (dataGC !== 2) {
    //         return {
    //             status: 'success',
    //             msg: '',
    //         };
    //     }
    //     if (!datam.noCooperation20Opportunity) {
    //         return {
    //             status: 'fail',
    //             msg: '不能为空或0',
    //         };
    //     } else {
    //         if (datam.noCooperation20Opportunity < 0) {
    //             return {
    //                 status: 'fail',
    //                 msg: '不能小于0',
    //             };
    //         }
    //         return {
    //             status: 'success',
    //             msg: '',
    //         };
    //     }
    // },
    // noCooperation40Opportunity: datam => {
    //     const dataGC = datam.recycleType;
    //     const dataNotFollowTo = datam.recycleGetNotFollowTo || '';
    //     if (dataGC !== 2) {
    //         return {
    //             status: 'success',
    //             msg: '',
    //         };
    //     }
    //     if (
    //         !datam.recycleGetNotFollow ||
    //         _.trim(datam.recycleGetNotFollow as string) === ''
    //     ) {
    //         return {
    //             status: 'fail',
    //             msg: '不能为空或0',
    //         };
    //     } else {
    //         if (datam.recycleGetNotFollow < 0) {
    //             return {
    //                 status: 'fail',
    //                 msg: '不能小于0',
    //             };
    //         }
    //         if (Number(dataNotFollowTo) <= Number(datam.recycleGetNotFollow)) {
    //             console.log(dataNotFollowTo, datam.recycleGetNotFollow);
    //             return {
    //                 status: 'fail',
    //                 msg: '再次跟进天数必须大于跟进天数',
    //             };
    //         }
    //         return {
    //             status: 'success',
    //             msg: '',
    //         };
    //     }
    // },
    recycleGetNotFollow: datam => {
        const dataGC = datam.recycleType;
        const dataNotFollowTo = datam.recycleGetNotFollowTo || '';
        if (dataGC !== 2) {
            return {
                status: 'success',
                msg: '',
            };
        }
        if (
            !datam.recycleGetNotFollow ||
            _.trim(datam.recycleGetNotFollow as string) === ''
        ) {
            return {
                status: 'fail',
                msg: '不能为空或0',
            };
        } else {
            if (datam.recycleGetNotFollow < 0) {
                return {
                    status: 'fail',
                    msg: '不能小于0',
                };
            }
            if (Number(dataNotFollowTo) <= Number(datam.recycleGetNotFollow)) {
                console.log(dataNotFollowTo, datam.recycleGetNotFollow);
                return {
                    status: 'fail',
                    msg: '再次跟进天数必须大于跟进天数',
                };
            }
            return {
                status: 'success',
                msg: '',
            };
        }
    },
    returnCantClaim: datam => {
        const data = datam.returnCantClaim;
        if (!data) {
            return {
                status: 'fail',
                msg: '请填写反作弊规则',
            };
        }
        if (data <= 0) {
            return {
                status: 'fail',
                msg: '反作弊规则天数必须大于0',
            };
        }
        return {
            status: 'success',
            msg: '',
        };
    },
    recycleNotDeal: datam => {
        const dataGC = datam.recycleType;
        if (dataGC !== 2) {
            return {
                status: 'success',
                msg: '',
            };
        }
        if (
            !datam.recycleNotDeal ||
            _.trim(datam.recycleNotDeal as string) === ''
        ) {
            return {
                status: 'fail',
                msg: '不能为空或0',
            };
        } else {
            if (datam.recycleNotDeal < 0) {
                return {
                    status: 'fail',
                    msg: '不能小于0',
                };
            }
            const bnf = Number(datam.recycleGetNotFollow);
            const bnd = Number(datam.recycleNotDeal);
            const bnt = Number(datam.recycleGetNotFollowTo);
            if (bnf && bnf >= bnd) {
                return {
                    status: 'fail',
                    msg: '必须大于跟进天数',
                };
            }

            if (bnt && bnt >= bnd) {
                return {
                    status: 'fail',
                    msg: '必须大于再次跟进天数',
                };
            }
            return {
                status: 'success',
                msg: '',
            };
        }
    },
};
