import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useToggle, useRequest } from 'ahooks';
import { Button } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { loadingWrapperOver } from '@/components';
import BoardCard from '../components/board-card';
import PredictTable from './components/predict-table';
import Selector from '../components/selector';
import TrendCharts from './components/trend-charts';
import SwitchButton from '@/components/common/switch-button';
import BoardSwitch from '../components/board-switch';
import {
    queryPreSolarization,
    queryTrendAnalysis,
    exportPreSolarization,
    exportTrendAnalysis,
} from './api';
import { useAuthView } from '../hooks';

import './index.less';
import { getDefaultOrganize } from '../composite-opportunity';

enum TimeDimMap {
    'date',
    'month',
    'quarter',
}

const CompositePredictPage: React.FC = () => {
    const [orgId, setOrgId] = useState(63);

    const { authViewWithComponent, loading, data } = useAuthView(orgId);
    const isSaleAndGroup = data?.viewOrgType === '3';
    const { defaultOrganize, defaultOrganizeOption } = getDefaultOrganize(
        isSaleAndGroup,
    );

    // 组织筛选
    const [organize, setOrganize] = useState(defaultOrganize);

    useEffect(() => {
        let defaultOrganize: number = 0;
        switch (data?.viewOrgType) {
            case '6':
                defaultOrganize = 1;
                break;
            case '5':
                defaultOrganize = 1;
                break;
            case '4':
                defaultOrganize = 2;
                break;
            case '3':
                defaultOrganize = 3;
                break;
            default:
                defaultOrganize = 3;
                break;
        }
        setOrganize(defaultOrganize);
    }, [data]);

    const [range, { set }] = useToggle<'month', 'quarter'>('month', 'quarter');

    // 预估数据通晒
    const { data: predictData, loading: predictLoading } = useRequest(
        () => queryPreSolarization(orgId),
        { refreshDeps: [orgId] },
    );

    // 预估数据通晒导出
    const {
        run: handleExportPreSolarization,
        loading: exportPreLoading,
    } = useRequest(() => exportPreSolarization(orgId), { manual: true });

    // 趋势分析
    const { data: trendData, loading: trendLoading } = useRequest(
        () => queryTrendAnalysis(TimeDimMap[range], orgId),
        {
            refreshDeps: [range, orgId],
        },
    );

    // 趋势分析导出
    const {
        run: handleExportTrendLoading,
        loading: exportTrendLoading,
    } = useRequest(() => exportTrendAnalysis(TimeDimMap[range], orgId), {
        manual: true,
    });

    // 预估数据通晒数据源
    const predictDataSource = useMemo(() => {
        const dataSource = _.cloneDeep(predictData);
        return dataSource?.map((info: any) => {
            info.children = info.group_list;
            info.name = info.city_name;
            info.children?.map((group: any) => {
                group.name = group.group_name;
                group.children = group.sale_list;
                group.children?.map((sale: any) => {
                    sale.region = info.region;
                    sale.city_name = info.city_name;
                    sale.group_name = group.group_name;
                    sale.name = sale.sale_name;
                });
            });
            return info;
        });
    }, [predictData]);

    const expandedRowKeys = useMemo(() => {
        if (organize === 1) {
            return [];
        }
        if (organize === 2) {
            return predictData?.reduce((prev: any, cur: any) => {
                prev.push(cur.city_name);
                return prev;
            }, []);
        }
        if (organize === 3) {
            return predictData?.reduce((prev: any, cur: any) => {
                prev.push(cur?.city_name);
                cur?.group_list?.map((group: any) => {
                    prev.push(group?.group_name);
                });
                return prev;
            }, []);
        }
        return [];
    }, [predictData, organize]);

    const chartsXAxis = useCallback(
        region => {
            return (
                trendData?.[region]?.map((item: any) =>
                    moment(item.date).format('M.D'),
                ) || []
            );
        },
        [trendData],
    );

    // 区域图表数据源
    const chartsDataSource = useCallback(
        region => {
            return (
                [
                    {
                        label: '目标',
                        data: trendData?.[region]?.map((item: any) =>
                            Math.round(item.target),
                        ),
                    },
                    {
                        label: '稳回',
                        data: trendData?.[region]?.map((item: any) =>
                            Math.round(item.steady),
                        ),
                    },
                    {
                        label: '大概率回',
                        data: trendData?.[region]?.map((item: any) =>
                            Math.round(item.large_probability),
                        ),
                    },
                    {
                        label: '保守预估',
                        data: trendData?.[region]?.map((item: any) =>
                            Math.round(item.conservative_predict),
                        ),
                    },
                    {
                        label: '冲刺预估',
                        data: trendData?.[region]?.map((item: any) =>
                            Math.round(item.spurt_predict),
                        ),
                    },
                    {
                        label: range === 'month' ? '月度回款' : '季度回款',
                        data: trendData?.[region]?.map((item: any) =>
                            Math.round(item.total_income),
                        ),
                    },
                ] || []
            );
        },
        [trendData, range],
    );

    const refreshTime = useMemo(() => {
        return moment().format('YYYY-MM-DD');
    }, []);

    return loadingWrapperOver(loading)(
        <div>
            <BoardSwitch onChange={setOrgId} />
            <BoardCard
                title="预估数据通晒"
                subtitle={
                    <span>
                        统计口径：{refreshTime}{' '}
                        实时数据，单位为万元。其中今日预估为非实时数据，是截止到昨日24时的数据；且日维度预估统计60及以上商机金额。
                        点击字段可显示该字段的含义与计算方式说明。
                        <a
                            href="https://maimai.feishu.cn/docx/DFWzd3csqoW8YLx11zQcaAb2nob"
                            target="_blank"
                        >
                            详细取数逻辑参见文档
                        </a>
                    </span>
                }
                filters={
                    <>
                        <Selector
                            label="组织筛选"
                            options={defaultOrganizeOption}
                            defaultValue={0}
                            value={organize}
                            onChange={selected => {
                                if (typeof selected === 'number') {
                                    setOrganize(selected);
                                }
                            }}
                        />
                    </>
                }
                extra={
                    <Button
                        className="board-button"
                        loading={exportPreLoading}
                        onClick={handleExportPreSolarization}
                    >
                        导出
                    </Button>
                }
            >
                <PredictTable
                    dataSource={predictDataSource}
                    loading={predictLoading}
                    expandedRowKeys={expandedRowKeys}
                    expandIcon={() => <span />}
                />
            </BoardCard>
            {!isSaleAndGroup && (
                <BoardCard
                    title="预估趋势图"
                    subtitle={
                        <span>
                            此处数据为非实时数据，是截止到昨日24时的数据，
                            <a
                                href="https://maimai.feishu.cn/docx/DFWzd3csqoW8YLx11zQcaAb2nob"
                                target="_blank"
                            >
                                详细取数逻辑参见文档
                            </a>
                        </span>
                    }
                    extra={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                className="board-button"
                                loading={exportTrendLoading}
                                onClick={handleExportTrendLoading}
                            >
                                导出
                            </Button>
                            <SwitchButton
                                style={{ marginLeft: '16px' }}
                                loading={trendLoading}
                                buttonOptions={[
                                    { value: 'month', name: '月度' },
                                    { value: 'quarter', name: '双月' },
                                ]}
                                value={range}
                                onChange={set}
                            />
                        </div>
                    }
                >
                    {authViewWithComponent('全国')(
                        <div className="predict-card-charts-wrap">
                            <TrendCharts
                                chartsClassName="predict-card-charts-content"
                                loading={trendLoading}
                                title="全国商机趋势图"
                                xAxis={chartsXAxis('全国')}
                                dataSource={chartsDataSource('全国')}
                            />
                        </div>,
                    )}
                    {/* <div className="predict-card-charts-wrap">
                        {authViewWithComponent('华北')(
                            <TrendCharts
                                chartsClassName="predict-card-charts-content"
                                loading={trendLoading}
                                color="cyan"
                                title="华北"
                                xAxis={chartsXAxis('华北')}
                                dataSource={chartsDataSource('华北')}
                            />,
                        )}
                        {authViewWithComponent('华东')(
                            <TrendCharts
                                chartsClassName="predict-card-charts-content"
                                loading={trendLoading}
                                color="blue"
                                title="华东"
                                xAxis={chartsXAxis('华东')}
                                dataSource={chartsDataSource('华东')}
                            />,
                        )}
                        {authViewWithComponent('华南')(
                            <TrendCharts
                                chartsClassName="predict-card-charts-content"
                                loading={trendLoading}
                                color="orange"
                                title="华南"
                                xAxis={chartsXAxis('华南')}
                                dataSource={chartsDataSource('华南')}
                            />,
                        )}
                    </div> */}
                    <div className="predict-card-charts-wrap">
                        {authViewWithComponent('北京')(
                            <TrendCharts
                                chartsClassName="predict-card-charts-content"
                                loading={trendLoading}
                                title="北京"
                                xAxis={chartsXAxis('北京')}
                                dataSource={chartsDataSource('北京')}
                            />,
                        )}
                        {authViewWithComponent('上海')(
                            <TrendCharts
                                chartsClassName="predict-card-charts-content"
                                loading={trendLoading}
                                title="上海"
                                xAxis={chartsXAxis('上海')}
                                dataSource={chartsDataSource('上海')}
                            />,
                        )}
                        {authViewWithComponent('杭州')(
                            <TrendCharts
                                chartsClassName="predict-card-charts-content"
                                loading={trendLoading}
                                title="杭州"
                                xAxis={chartsXAxis('杭州')}
                                dataSource={chartsDataSource('杭州')}
                            />,
                        )}
                    </div>
                    <div className="predict-card-charts-wrap">
                        {authViewWithComponent('深圳')(
                            <TrendCharts
                                chartsClassName="predict-card-charts-content"
                                loading={trendLoading}
                                title="深圳"
                                xAxis={chartsXAxis('深圳')}
                                dataSource={chartsDataSource('深圳')}
                            />,
                        )}
                        {authViewWithComponent('广州')(
                            <TrendCharts
                                chartsClassName="predict-card-charts-content"
                                loading={trendLoading}
                                title="广州"
                                xAxis={chartsXAxis('广州')}
                                dataSource={chartsDataSource('广州')}
                            />,
                        )}
                        {authViewWithComponent('成都')(
                            chartsXAxis('成都').length > 0 && (
                                <TrendCharts
                                    chartsClassName="predict-card-charts-content"
                                    loading={trendLoading}
                                    title="成都"
                                    xAxis={chartsXAxis('成都')}
                                    dataSource={chartsDataSource('成都')}
                                />
                            ),
                        )}
                    </div>
                </BoardCard>
            )}
        </div>,
    );
};

export default CompositePredictPage;
