import { RouteComponentProps } from '@reach/router';
import React, { useState, useEffect } from 'react';
import { SGrid } from '@/components/layouts';
// import {
//     getAutoOnChangeHandler
// } from '@/components';
import { Alert, Input, message } from 'antd';
import { observer } from '@/containers/hoc';
import {
    CCBtn,
    defaultLabelsGenerator,
    defaultCellsSelectorGenerator,
} from '@/components';
import { useAnyCustomerStore } from '@/pages/sales-follow-up/custom/list/store';
import {
    EditableDisplay,
    getAutoOnChangeHandler,
} from '@/components/common/editable-display';
import { makeAutoObservable } from 'mobx';

import _ from 'lodash';
import { embedDataToWidgets } from '@/components/common/form/advanced-filters';

class EditableDisplayStore {
    public editingMap: { [key: string]: boolean } = {};
    public editSomeKey(keys: string[]) {
        const nextEditingMap: { [key: string]: boolean } = _.cloneDeep(
            this.editingMap,
        );
        for (const key of keys) {
            nextEditingMap[key] = true;
        }
        this.editingMap = nextEditingMap;
    }
    public cancelEditingAll() {
        this.editingMap = {};
    }

    public confirmedData: any = {};
    public editingData: any = {};

    public setEditingData(nextData: any) {
        this.editingData = nextData;
    }
    public confirmEditingData() {
        this.confirmedData = _.cloneDeep(this.editingData);
    }
    public cancelEditingData() {
        this.editingData = _.cloneDeep(this.confirmedData);
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const EditableDisplayDemo: React.FC<RouteComponentProps> = ({}) => {
    const [prepared, defaultStore] = useAnyCustomerStore({
        autoLoad: true,
    });
    const [editableDisplayStore] = useState(() => new EditableDisplayStore());

    useEffect(() => {
        if (prepared) {
            defaultStore.startNewData();
        }
    }, [prepared]);

    const { mutatingData, defaultMeta } = defaultStore;
    useEffect(() => {
        if (mutatingData === null) {
            return;
        }
        editableDisplayStore.setEditingData(mutatingData);
        editableDisplayStore.confirmEditingData();
    }, [mutatingData]);

    const { editingMap, editingData, confirmedData } = editableDisplayStore;
    useEffect(() => {
        // callback with confirmedData!
    }, [confirmedData]);

    const onDataChange = getAutoOnChangeHandler(editingData, (data: any) =>
        editableDisplayStore.setEditingData(data),
    );

    // 是否可编辑
    const editableMap: { [key: string]: boolean } = {
        customer_name: true,
        short_name: true,
    };

    const baiscWidgets = defaultMeta.defaultWidgetsAll;
    const basicformformItemOptions = defaultLabelsGenerator(defaultStore);
    const baiscCellsSelector = defaultCellsSelectorGenerator(defaultStore);
    const layout = new Array(Math.ceil(baiscWidgets.length / 2)).fill([12, 12]);
    embedDataToWidgets<any>(baiscWidgets, editingData);

    const collapseLayout = _.cloneDeep(defaultMeta.defaultFormCollapseLayout);
    for (const colla of collapseLayout) {
        colla.layout = layout;
    }
    const collapseProps = {
        defaultActiveKey: collapseLayout.map(item => item.groupKey),
    };
    // 当confirm的时候，传一个loading状态
    const confirmLoading = false;
    // 是可以支持多编辑的
    const isMultipleEditing = false;

    if (!prepared || !mutatingData) {
        return null;
    }

    return (
        <div>
            <EditableDisplay<any>
                data={editingData}
                widgets={baiscWidgets}
                onChange={(key, value, k) => {
                    onDataChange(key, value, k);
                }}
                collapseLayout={collapseLayout}
                collapseProps={collapseProps}
                formItemOptions={basicformformItemOptions}
                layout={layout}
                rowGutter={[0, 0]}
                confirmLoading={confirmLoading}
                onInteract={async (key, type, value) => {
                    if (
                        type === 'start-editing-display-param' &&
                        _.isString(key)
                    ) {
                        editableDisplayStore.cancelEditingAll();
                        editableDisplayStore.cancelEditingData();
                        editableDisplayStore.editSomeKey([key]);
                    } else if (type === 'confirm-editing-display-param') {
                        message.info('变更确认');
                        editableDisplayStore.confirmEditingData();
                        editableDisplayStore.cancelEditingAll();
                    } else if (type === 'cancel-editing-display-param') {
                        message.info('变更取消');
                        editableDisplayStore.cancelEditingAll();
                        editableDisplayStore.cancelEditingData();
                    }
                }}
                labelWidth={15}
                validateResult={{}}
                cellsSelector={baiscCellsSelector}
                editingMap={editingMap}
                editableMap={editableMap}
                isMultipleEditing={isMultipleEditing}
            ></EditableDisplay>
        </div>
    );
};

export default observer(EditableDisplayDemo);
