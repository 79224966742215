import {
    TPaginationStoreInstance,
    PaginationStore,
} from '../../../../stores/utils/index';
import moment from 'moment';
import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import { defaultAxios } from '@/utils';
import { Modal, message } from 'antd';
import React from 'react';
import { createDefaultInstanceGetterA } from '@/stores/utilsA';
export interface IProductRightsAnaData {
    customerName?: string; // 客户名称
    customerProvince?: string; // 省份
    customerCity?: string; // 城市
    contractCode: string; // 合同编号
    contractOwnerName?: string; // 合同所有人
    contractOwnerDepartName?: string; // 合同所有人所属部门
    contractCreateTime?: string; // 合同创建时间
    opportunityName?: string; // 关联商机名称
    opportunityCreateTime?: number; // 商机创建时间
    winRate?: number; // 关联商机赢率
    productCatalog?: string; // 产品类型
    productVersion?: string; // 产品版本
    productName?: string; // 产品名称
    cost?: number; // 刊例价
    totalCost?: number; // 实际销售价
    purchaseType?: string; // 购买类型
    equityName?: string; // 权益名称
    standardEquityValue?: number; // 标准权益值
    changeEquityValue?: number; // 变更后权益值
    [key: string]: any;
}
export type TProductRightsAnaReturnType = NetResponse<{
    data: IProductRightsAnaData[];
    msg: string;
    code: number;
}>;
export class ProductRightsStore {
    constructor() {
        makeAutoObservable(this);
    }
    public productRightsAnaData: IProductRightsAnaData[] = [];
    // public defaultChannelGatherAnaPagination: TPaginationStoreInstance = new PaginationStore();
    public getProductRightsData: (
        searchData: any,
    ) => Promise<TProductRightsAnaReturnType> = async searchData => {
        const finalSearchData = {
            // ...searchData,
            contractStartTime: searchData.beginDate
                ? moment(searchData.beginDate).valueOf()
                : undefined,
            contractEndTime: searchData.endDate
                ? moment(searchData.endDate).valueOf()
                : undefined,
            predictionPayStartTime: searchData.firstBeginDate
                ? moment(searchData.firstBeginDate).valueOf()
                : undefined,
            predictionPayEndTime: searchData.firstEndDate
                ? moment(searchData.firstEndDate).valueOf()
                : undefined,
            // ...this.defaultChannelGatherAnaPagination.paginationArgs,
        };
        const url = '/bff/api/rest/ana-product/product-rights/fetch';
        const [d, e] = await defaultAxios.get(url, finalSearchData);
        if (d === null || d.data === null) {
            message.error('查询失败～');
            this.productRightsAnaData = [];
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data)) {
            this.productRightsAnaData = data;
            // this.defaultChannelGatherAnaPagination.amount = 1;
        } else {
            this.productRightsAnaData = [];
            message.error(data.msg || '查询失败～');
        }
        // if (data && Array.isArray(data?.data)) {
        //     this.channelGatherAnaData = data?.data;
        //     this.defaultChannelGatherAnaPagination.amount = data?.totalSize || 0;
        // }

        const error = e as Error | null;
        return [data, error];
    };
    public exportProductRightsData = async (searchData: any) => {
        if (this.productRightsAnaData.length > 50000) {
            Modal.error({
                title: '导出提示',
                content: (
                    <p>
                        最多支持导出50000条（当前条件共
                        {this.productRightsAnaData.length}
                        条，请更换筛选条件再导出
                    </p>
                ),
            });
            return;
        }
        const url = `${
            window.location.origin
        }/bff/api/rest/ana-product/product-rights/export?contractStartTime=${
            searchData.beginDate ? moment(searchData.beginDate).valueOf() : ''
        }&contractEndTime=${
            searchData.endDate ? moment(searchData.endDate).valueOf() : ''
        }&predictionPayStartTime=${
            searchData.firstBeginDate
                ? moment(searchData.firstBeginDate).valueOf()
                : ''
        }&predictionPayEndTime=${
            searchData.firstEndDate
                ? moment(searchData.firstEndDate).valueOf()
                : ''
        }`;
        window.open(url, '_blank');
        return;
    };
}

export const getDefaultProductRightsStore = createDefaultInstanceGetterA(
    ProductRightsStore,
);
