import { defaultAxios } from '@/utils';
import { message } from 'antd';

// 今日团队数据
export const getTodayTeamDate: (
    orgId: number | string,
) => Promise<any> = async orgId => {
    if (orgId === '' || orgId === -1) {
        return null;
    }

    const [d, e] = await defaultAxios.get('/crm/workbench/todayTeamDate', {
        orgId,
    });
    if (d === null || d.data === null || e) {
        if (e.message.includes('timeout')) {
            message.error('请求超时，请刷新页面');
        }
        return null;
    }
    return d.data;
};
