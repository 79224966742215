import { defaultAxios } from '@/utils';
import { message } from 'antd';
import { exportFile } from '../api';

// 销售维度数据通晒
export const queryActionSolarizationOwner: (
    orgId: string | number,
) => Promise<any> = async orgId => {
    const [d, e] = await defaultAxios.put(
        '/crm/data/query/action_solarization_by_owner',
        { orgId, version: 2 },
        { timeout: 100000 }, //可能要删
    );
    if (d === null || d.data === null || e) {
        if (e.message.includes('timeout')) {
            message.error('请求超时，请刷新页面');
        }
        return null;
    }
    return d.data;
};

// 销售维度数据通晒导出
export const exportActionSolarizationOwner = (orgId: string | number) => {
    return exportFile('/crm/data/query/action_solarization_by_owner/export', {
        orgId,
        version: 2
    });
};

// 客户维度数据通晒
export const queryActionSolarizationCustomer: (
    orgId: string | number,
) => Promise<any> = async orgId => {
    const [d, e] = await defaultAxios.put(
        '/crm/data/query/action_solarization_by_customer',
        { orgId, version: 2 },
        { timeout: 100000 }, //可能要删
    );
    if (d === null || d.data === null || e) {
        if (e.message.includes('timeout')) {
            message.error('请求超时，请刷新页面');
        }
        return null;
    }
    return d.data;
};

// 客户维度数据通晒导出
export const exportActionSolarizationCustomer = (orgId: string | number) => {
    return exportFile(
        '/crm/data/query/action_solarization_by_customer/import',
        { orgId, version: 2 },
    );
};
