import React from 'react';
import { ICheckBody, useAuthPrecheck } from '@/utils/hooks/auth-precheck';

export const PreCheckA: React.FC<React.AnchorHTMLAttributes<
    HTMLAnchorElement
> & {
    checkBody?: ICheckBody;
}> = props => {
    const { checkBody } = props;
    const [nextOnClick, snippet, preCheckLoading] = useAuthPrecheck({
        checkBody,
        parentProps: props,
    });

    if (checkBody !== undefined) {
        return (
            <>
                {snippet}
                <a {...props} onClick={nextOnClick}>
                    {props.children}
                </a>
            </>
        );
    } else {
        return <a {...props} />;
    }
};
