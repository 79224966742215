import React, { memo, useContext, useState } from 'react';
import { AntButton, BlockLoading2 } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CustomerListStore } from '../store';
import FormDemoForm from './form';
import { AntDrawer } from '@/components/antd/drawer';
import { getQueryAsObject, tryRefresh } from '@/utils';
import { runInAction } from 'mobx';
import { voyagerTrack } from '@shared/voyager';
import { StoreContext } from '@/stores';

const FormDrawer: React.FC<{
    customerList: CustomerListStore;
    type: string;
}> = observer(({ customerList, type }) => {
    const {
        actionType,
        updateMode,
        bigFormVisible,
        mutatingDataLoading,
        autoFillingLoading,
        mutatingData,
    } = customerList;
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();

    const [loading, setLoading] = useState(false);

    const transStand = 'trans-standard' === updateMode;
    return (
        <AntDrawer
            title={
                transStand
                    ? '转标准客户'
                    : actionType === 'create'
                    ? '新建客户'
                    : '修改客户'
            }
            placement={'right'}
            visible={bigFormVisible}
            onClose={() => {
                runInAction(() => {
                    customerList.resetAction();
                    customerList.setMutatingData(null);
                });
            }}
            bodyStyle={{
                width: '850px',
                overflow: 'scroll',
            }}
            width="850px"
        >
            <div className="standard-drawer-form-wrapper">
                {mutatingDataLoading ? (
                    <BlockLoading2 />
                ) : (
                    <FormDemoForm customerList={customerList} type={type} />
                )}
                <div className="fixed-form-btns">
                    <AntButton
                        size="large"
                        type="primary"
                        loading={
                            mutatingDataLoading || autoFillingLoading || loading
                        }
                        onClick={async () => {
                            setLoading(true);
                            const isSuccess = await customerList.dispatchAction();
                            setLoading(false);
                            if (isSuccess) {
                                //打点
                                const searchObj = getQueryAsObject();
                                if (searchObj.ref === 'plugin') {
                                    // 从插件点击新建客户进入我的客户，新建客户
                                    voyagerTrack('crm_sale_source_high_sea', {
                                        filter: { plugin: '1' },
                                        userId: authStore?.userInfo?.userId,
                                        appEnv: process.env.REACT_APP_ENV,
                                        customerId: mutatingData?.id,
                                    });
                                }
                                customerList.setMutatingData(null);
                                tryRefresh([
                                    'customer',
                                    'lead',
                                    'opt',
                                    'contacts',
                                ]);
                            }
                        }}
                    >
                        {transStand
                            ? '转标准'
                            : actionType === 'create'
                            ? '新建'
                            : '修改'}
                    </AntButton>
                    <AntButton
                        size="large"
                        onClick={() => {
                            runInAction(() => {
                                customerList.resetAction();
                                customerList.setMutatingData(null);
                            });
                        }}
                    >
                        取消
                    </AntButton>
                </div>
            </div>
        </AntDrawer>
    );
});

const FinalFormDrawer = memo(FormDrawer);
export default FinalFormDrawer;
