import React, { memo } from 'react';
import {
    DForm,
    applyCustomConfToCellsSelector,
    applyCustomConfToWidget,
    loadingWrapper,
    AntButton,
    applyConfToFormItem,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ContractInvoiceApplyListStore } from '../store';
import { Alert } from 'antd';
import { InvoiceQualificationListStore } from '../../contract-invoice-qualification/store';
import { runInAction } from 'mobx';
import { arrToJson } from '@/utils';

const customerInvoiceTypes = [
    { constraintLabel: '一般纳税人', constraintValue: 1 },
    { constraintLabel: '小规模纳税人', constraintValue: 2 },
    { constraintLabel: '个人', constraintValue: 3 },
];
const customerInvoiceTypesMap = arrToJson(
    customerInvoiceTypes,
    'constraintValue',
);

const IQDisplay: React.FC<{ invoiceQualificationData: any }> = ({
    invoiceQualificationData,
}) => {
    if (null === invoiceQualificationData) {
        return null;
    }
    const params = [
        {
            key: 'customer_invoice_type',
            name: '客户类型',
        },
        {
            key: 'invoice_company',
            name: '开票公司名',
        },
        {
            key: 'invoice_id_num',
            name: '纳税人识别号',
        },
        {
            key: 'company_address',
            name: '公司地址',
        },
        {
            key: 'company_phone',
            name: '公司电话',
        },
        {
            key: 'open_account_bank',
            name: '开户银行',
        },
        {
            key: 'bank_account',
            name: '银行账号',
        },
    ];
    return (
        <div>
            {params.map(({ key, name }) => {
                let value = invoiceQualificationData[key] || '--';
                if (key === 'customer_invoice_type') {
                    value =
                        (customerInvoiceTypesMap[invoiceQualificationData[key]]
                            ? customerInvoiceTypesMap[
                                  invoiceQualificationData[key]
                              ]?.constraintLabel
                            : null) || '--';
                }
                return (
                    <div style={{ display: 'flex', margin: '5px 0' }} key={key}>
                        <div
                            style={{
                                fontWeight: 'bolder',
                                flexBasis: 110,
                                textAlign: 'right',
                            }}
                        >
                            {name}：
                        </div>
                        <div>{value}</div>
                    </div>
                );
            })}
        </div>
    );
};

const DemoForm: React.FC<{
    defaultStore: ContractInvoiceApplyListStore;
    iqStoreInfo: {
        iqStorePrepared: boolean;
        iqStore: InvoiceQualificationListStore;
        prepareIqStore: () => void;
    };
}> = ({ defaultStore, iqStoreInfo }) => {
    const {
        invoiceQualificationLoading,
        invoiceQualificationInited,
        hasInvoiceQualification,
        invoiceQualificationData,
        contractCustomer,
    } = defaultStore;

    const { actionType } = defaultStore;

    let eventCode = '';
    if (actionType === 'create') {
        eventCode = 'CREATE-EDIT';
    } else if (actionType === 'update') {
        eventCode = 'PUBLIC-EDIT';
    }

    if (eventCode === '') {
        return null;
    }

    const { iqStorePrepared, iqStore, prepareIqStore } = iqStoreInfo;

    const form = (
        <DForm
            eventCode={eventCode}
            defaultStore={defaultStore}
            widgetsTransformer={baiscWidgets => {
                applyConfToFormItem(baiscWidgets, 'inputSecret', {
                    sceneCode: 'crm_contract',
                });
                applyCustomConfToWidget(
                    baiscWidgets,
                    'contract_id',
                    nextWidget => {
                        nextWidget.status = {
                            loading: () => {
                                return invoiceQualificationLoading;
                            },
                        };

                        if (
                            !invoiceQualificationInited ||
                            !contractCustomer ||
                            !contractCustomer.relatedObj
                        ) {
                            delete nextWidget.nextlineWidget;
                        } else {
                            nextWidget.nextlineWidget = () => (
                                <div style={{ padding: 5 }}>
                                    {loadingWrapper(
                                        invoiceQualificationLoading,
                                    )(
                                        <Alert
                                            key={
                                                'hash-' +
                                                defaultStore.newDataHash
                                            }
                                            type="info"
                                            message={
                                                <div>
                                                    {hasInvoiceQualification ? (
                                                        <p>
                                                            当前客户【
                                                            {
                                                                contractCustomer
                                                                    .relatedObj
                                                                    .customer_name
                                                            }
                                                            】开票资质：
                                                            <IQDisplay
                                                                invoiceQualificationData={
                                                                    invoiceQualificationData
                                                                }
                                                            />
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            当前客户【
                                                            {
                                                                contractCustomer
                                                                    .relatedObj
                                                                    .customer_name
                                                            }
                                                            】暂无开票资质
                                                        </p>
                                                    )}
                                                    <div>
                                                        {hasInvoiceQualification ? (
                                                            <AntButton
                                                                onClick={() => {
                                                                    prepareIqStore();
                                                                    (async () => {
                                                                        await iqStore.setAction(
                                                                            'update',
                                                                            {
                                                                                id:
                                                                                    invoiceQualificationData.id,
                                                                            },
                                                                        );
                                                                        iqStore.setCustomerId(
                                                                            contractCustomer,
                                                                        );
                                                                    })();
                                                                }}
                                                                type="primary"
                                                            >
                                                                修改用户开票资质
                                                            </AntButton>
                                                        ) : (
                                                            <AntButton
                                                                onClick={() => {
                                                                    prepareIqStore();
                                                                    runInAction(
                                                                        () => {
                                                                            iqStore.startNewData();
                                                                            iqStore.setCustomerId(
                                                                                contractCustomer,
                                                                            );
                                                                            iqStore.setAction(
                                                                                'create',
                                                                            );
                                                                        },
                                                                    );
                                                                }}
                                                                type="primary"
                                                            >
                                                                新建用户开票资质
                                                            </AntButton>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                        />,
                                    )}
                                </div>
                            );
                        }
                        return nextWidget;
                    },
                );
                return baiscWidgets;
            }}
        />
    );
    return form;
};

const FinalDemoForm = memo(observer(DemoForm));
export default FinalDemoForm;
