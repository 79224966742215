import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import { ListMeta } from '@/stores/utils';
import _ from 'lodash';
import { isZeroValue } from './helpers';
import moment from 'moment';

const primaryTyoeSerializer = (input: any): string => {
    if (input.toString) {
        return input.toString() as string;
    }
    return input + '';
};

export const serializers: { [key: string]: (input: any) => string } = {
    string: primaryTyoeSerializer,
    number: primaryTyoeSerializer,
};

export type TOpAndSerializers = {
    [key: string]: { op: string; serializer: string; parser?: string };
};

export const serializeFilterData = <T extends { [key: string]: any }>(
    object: T,
    defaultMeta: ListMeta,
    opAndSerializers: TOpAndSerializers,
): NexusGenInputs['FiltersArgs'] => {
    const filtersArgs: NexusGenInputs['FiltersArgs'] = {
        filters: [],
    };
    if (!object) {
        return filtersArgs;
    }
    const { filtersParamsFromUserSettingMap } = defaultMeta;
    const filterKeys = _.filter(
        _.keys(object),
        key => !!filtersParamsFromUserSettingMap[key],
    );
    const arr = filtersArgs.filters;
    if (!arr) {
        return filtersArgs;
    }

    for (const key of filterKeys) {
        const originValue = object[key];
        if (isZeroValue(originValue)) {
            continue;
        }
        const opAndSerializer = opAndSerializers[key];
        const { op, serializer, parser } = opAndSerializer;
        if (!serializers[serializer]) {
            continue;
        }
        const v = serializers[serializer](object[key]);

        const currentFilterItem: NexusGenInputs['FilterItem'] = {
            fieldId: key,
            operateType: op,
            filterValue: v,
            parser: parser || serializer,
        };
        arr.push(currentFilterItem);
    }

    return filtersArgs;
};

export const serializeFilterData2 = <T extends { [key: string]: any }>(
    object: T,
    defaultMeta: ListMeta,
): NexusGenInputs['FiltersArgs'] => {
    const filtersArgs: NexusGenInputs['FiltersArgs'] = {
        filters: [],
    };
    if (!object) {
        return filtersArgs;
    }
    const { filtersParamsFromUserSettingMap, objectMetaMap } = defaultMeta;
    const filterKeys = _.filter(
        _.keys(object),
        key => !!filtersParamsFromUserSettingMap[key],
    );
    const arr = filtersArgs.filters;
    if (!arr) {
        return filtersArgs;
    }

    for (const key of filterKeys) {
        const originValue = object[key];
        const keyMeta = objectMetaMap[key];
        if (_.isNil(keyMeta)) {
            continue;
        }
        if (_.isArray(originValue)) {
            const [from, to] = originValue;
            if (!isZeroValue(from)) {
                const currentFilterItem: NexusGenInputs['FilterItem'] = {
                    fieldId: key,
                    operateType: '>=',
                    filterValue: from,
                    parser: 'string',
                };
                arr.push(currentFilterItem);
            }
            if (!isZeroValue(to)) {
                const currentFilterItem: NexusGenInputs['FilterItem'] = {
                    fieldId: key,
                    operateType: '<=',
                    filterValue: to,
                    parser: 'string',
                };
                arr.push(currentFilterItem);
            }
        } else {
            if (isZeroValue(originValue)) {
                continue;
            }

            const v = object[key];

            const currentFilterItem: NexusGenInputs['FilterItem'] = {
                fieldId: key,
                operateType: 'like',
                filterValue: v,
                parser: 'string',
            };
            arr.push(currentFilterItem);
        }
    }

    return filtersArgs;
};

export const serializeFilterData3 = <T extends { [key: string]: any }>(
    object: T,
    defaultMeta: { objectMetaMap: any },
): NexusGenInputs['FiltersArgs'] => {
    const filtersArgs: NexusGenInputs['FiltersArgs'] = {
        filters: [],
    };
    if (!object) {
        return filtersArgs;
    }
    const { objectMetaMap } = defaultMeta;
    const filterKeys = _.keys(object);
    const arr = filtersArgs.filters;
    if (!arr) {
        return filtersArgs;
    }

    for (const key of filterKeys) {
        const filterObject = object[key];
        console.log('filterObject',filterObject)
        console.log('filterKeys',filterKeys)
        const { value, isEmpty, isNotEmpty, widgetKey, ts } = filterObject;
        const keyMeta = objectMetaMap[key];
        const extra: any = { ts, isEmpty, isNotEmpty };
        if (!isEmpty && !isNotEmpty) {
            if (widgetKey === 'input') {
                if (_.isString(value) && !!_.trim(value)) {
                    if (keyMeta.fieldShowType === 'phone') {
                        const currentFilterItem: NexusGenInputs['FilterItem'] = {
                            fieldId: key,
                            operateType: '=',
                            filterValue: _.trim(value),
                            parser: 'string',
                            widgetKey,
                            extra,
                        };
                        arr.push(currentFilterItem);
                    } else {
                        const currentFilterItem: NexusGenInputs['FilterItem'] = {
                            fieldId: key,
                            operateType: 'like',
                            filterValue: _.trim(value),
                            parser: 'string',
                            widgetKey,
                            extra,
                        };
                        arr.push(currentFilterItem);
                    }
                }
            } else if (
                widgetKey === 'filterNumberRange' ||
                widgetKey === 'filterDateRange'
            ) {
                const [from, to] = value;
                if (_.isNumber(from)) {
                    const currentFilterItem: NexusGenInputs['FilterItem'] = {
                        fieldId: key,
                        operateType: '>=',
                        filterValue:
                            widgetKey === 'filterNumberRange'
                                ? from
                                : moment(from).format('YYYY-MM-DD HH:mm:ss'),
                        parser: 'string',
                        widgetKey,
                        extra,
                    };
                    arr.push(currentFilterItem);
                }
                if (_.isNumber(to)) {
                    const currentFilterItem: NexusGenInputs['FilterItem'] = {
                        fieldId: key,
                        operateType: '<=',
                        filterValue:
                            widgetKey === 'filterNumberRange'
                                ? to
                                : moment(to).format('YYYY-MM-DD HH:mm:ss'),
                        parser: 'string',
                        widgetKey,
                        extra,
                    };
                    arr.push(currentFilterItem);
                }
            } else if (widgetKey === 'filterEnum') {
                if (_.isArray(value) && !!value.length) {
                    const currentFilterItem: NexusGenInputs['FilterItem'] = {
                        fieldId: key,
                        operateType: 'in',
                        filterValue: value.join(','),
                        parser: 'string',
                        widgetKey,
                        extra,
                    };
                    arr.push(currentFilterItem);
                }
            } else if (widgetKey === 'object') {
                if (_.isArray(value) && !!value.length) {
                    const values = value
                        .map(v => v.originalValue)
                        .filter(v => v !== undefined);
                    extra.value = value;
                    const currentFilterItem: NexusGenInputs['FilterItem'] = {
                        fieldId: key,
                        operateType: 'in',
                        filterValue: values.join(','),
                        parser: 'string',
                        widgetKey,
                        extra,
                    };
                    arr.push(currentFilterItem);
                }
            } else if (widgetKey === 'sysUserSelector') {
                if (_.isArray(value) && !!value.length) {
                    const values = value.map(v => v.key.replace('user-', ''));
                    extra.value = value;
                    const currentFilterItem: NexusGenInputs['FilterItem'] = {
                        fieldId: key,
                        operateType: 'in',
                        filterValue: values.join(','),
                        parser: 'string',
                        widgetKey,
                        extra,
                    };
                    arr.push(currentFilterItem);
                }
            }
        } else if (isEmpty) {
            const currentFilterItem: NexusGenInputs['FilterItem'] = {
                fieldId: key,
                operateType: 'null',
                filterValue: null,
                parser: 'string',
                widgetKey,
                extra,
            };
            arr.push(currentFilterItem);
        } else if (isNotEmpty) {
            const currentFilterItem: NexusGenInputs['FilterItem'] = {
                fieldId: key,
                operateType: 'not_null',
                filterValue: null,
                parser: 'string',
                widgetKey,
                extra,
            };
            arr.push(currentFilterItem);
        }
    }

    return filtersArgs;
};
