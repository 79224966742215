import {
    NexusGenFieldTypes,
    NexusGenInputs,
} from '@/definations/graphql/auto-gen';
import { defaultAxios, gql } from '@/utils';
import { AuthorBasic } from './fragments';

export const testQuery = gql`
    query FindAuthor($authorId: ID!) {
        author(id: $authorId) {
            ...AuthorBasic
            books(limit: 1) {
                id
                title
            }
        }
    }
    ${AuthorBasic}
`;

export const fetchTestQuery = async (variables: {
    authorId: string;
}): Promise<NetResponse<{ author: NexusGenFieldTypes['Author'] }>> => {
    const [d, e] = await defaultAxios.gql({
        query: testQuery,
        variables,
    });
    if (d === null) {
        return [d, e];
    }
    const data = d.data as { author: NexusGenFieldTypes['Author'] };
    const error = e as Error | null;
    return [data, error];
};

export const testMutation = gql`
    mutation UpdateAuthor($author: AuthorUpdateInput!) {
        updateAuthor(author: $author) {
            id
            name
        }
    }
`;

export const fetchTestMutation = async (variables: {
    author: NexusGenInputs['AuthorUpdateInput'];
}): Promise<[
    { updateAuthor: NexusGenFieldTypes['Author'] } | null,
    Error | null,
]> => {
    const [d, e] = await defaultAxios.gql({
        query: testMutation,
        variables,
    });
    if (d === null) {
        return [d, e];
    }
    const data = d as { updateAuthor: NexusGenFieldTypes['Author'] };
    const error = e as Error | null;
    return [data, error];
};

export const testFetchAuthors = gql`
    query fetchAuthors(
        $pagination: PaginationInput!
        $filters: AuthorFiltersInput
        $sorter: AuthorSorterInput
    ) {
        authors(pagination: $pagination, filters: $filters, sorter: $sorter) {
            amount
            authors {
                id
                name
                gender
                books {
                    id
                    title
                }
            }
        }
    }
`;

export type TGetTestFetchAuthorsReturnType = NetResponse<{
    amount: number;
    authors: Array<NexusGenFieldTypes['Author']>;
}>;

export const getTestFetchAuthors = async (
    pagination: NexusGenInputs['PaginationInput'],
    filters?: NexusGenInputs['AuthorFiltersInput'],
    sorter?: NexusGenInputs['AuthorSorterInput'],
): Promise<TGetTestFetchAuthorsReturnType> => {
    const variables = {
        pagination,
        filters,
        sorter,
    };
    const [d, e] = await defaultAxios.gql({
        query: testFetchAuthors,
        variables,
    });
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = d.data.authors as {
        amount: number;
        authors: Array<NexusGenFieldTypes['Author']>;
    };
    const error = e as Error | null;
    return [data, error];
};
