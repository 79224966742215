import { useCallback, useMemo } from 'react';
import { useRequest } from 'ahooks';
import { getBoardAuth } from './api';

// 看板权限
export function useAuthView(orgId: string | number) {
    const { data, loading } = useRequest(() => getBoardAuth(orgId), {
        refreshDeps: [orgId],
    });

    const userRegionsLv2 = useMemo(
        () =>
            data?.dataBoardOrgList
                ?.filter((item: any) => {
                    return item.orgType === '5' && item.managerId !== null;
                })
                .map((item: any) => {
                    return item.orgName;
                }),
        [data],
    );

    const userRegionsLv3 = useMemo(
        () =>
            data?.dataBoardOrgList
                ?.filter((item: any) => {
                    return item.orgType === '4' && item.managerId !== null;
                })
                .map((item: any) => {
                    return item.orgName;
                }),
        [data],
    );

    const authView = useCallback(
        (name: string) => {
            const isShow =
                name === '全国'
                    ? data?.viewOrgType === '6'
                    : !!data?.dataBoardOrgList?.find(org => {
                          return name.split(',').includes(org.orgName || '');
                      });

            return isShow;
        },
        [data],
    );

    const authViewWithComponent = (name: string) => {
        const isShow = authView(name);
        return (component: React.ReactNode) => {
            return isShow ? component : null;
        };
    };

    return {
        data,
        loading,
        userRegionsLv2,
        userRegionsLv3,
        authView,
        authViewWithComponent,
    };
}
