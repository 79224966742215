import React, { useState, useEffect, useContext } from 'react';
import './index.scss';
import { RouteComponentProps } from '@reach/router';
import { defaultAxios } from '@/utils';
import { IMemberChildData } from '../../store';
import { observer } from 'mobx-react-lite';
import { TableLv2 } from '../../../../../../components/common/table/level2';
import { Button, Table, Popconfirm, message, Modal, Icon } from 'antd';
import { TableLv1 } from '@/components';
import _ from 'lodash';
import { StoreContext } from '@/stores';

export interface IExpandedMemberRowProps {
    cid: string | number;
    shouldRefreshCid?: string | number;
    adminPhone: string | number;
    identityType: string | number;
    authMap: {
        openTrial: boolean;
        closeTrial: boolean;
        createBlank: boolean;
        delay: boolean;
        modifyEquality: boolean;
        modifyMobileEquality: boolean;
        clearChildAuth: boolean;
        deleteChild: boolean;
        recycleChild: boolean;
        distributeEquality: boolean;
    };
    changeChildMobileEqualityModalVis: (
        bool: boolean,
        selectedItem: IMemberChildData[],
        cid: any,
    ) => void;
    distributeChildEqualityModalVis: (
        bool: boolean,
        selectedItem: IMemberChildData[],
        cid: any,
    ) => void;
}
const ExpandedMemberRow: React.FC<IExpandedMemberRowProps &
    RouteComponentProps> = props => {
    const store = useContext(StoreContext);
    const memberListStore = store.getDefaultMemberListStore();
    const {
        deleteChildEquality,
        clearChildEquality,
        recycleChild,
        batchRecycleChild,
        changeMobileEquality,
    } = memberListStore;
    const {
        adminPhone,
        authMap,
        changeChildMobileEqualityModalVis,
        cid,
        shouldRefreshCid,
        distributeChildEqualityModalVis,
        identityType,
    } = props;
    const [fetchChildListLoading, setFetchChildListLoading] = useState(false);
    const [childListData, setChildListData] = useState<IMemberChildData[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<
        string[] | number[]
    >();
    const [selectedRows, setSelectedRows] = useState<IMemberChildData[]>();
    useEffect(() => {
        if (props.shouldRefreshCid && props.cid === props.shouldRefreshCid) {
            fetchChildList();
        }
    }, [props.shouldRefreshCid]);
    const fetchChildList = async () => {
        setFetchChildListLoading(true);
        const url = '/bff/api/rest/member-list/child-list';
        const [d, e] = await defaultAxios.get(url, {
            adm_mobile: adminPhone,
        });
        setFetchChildListLoading(false);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data.data;
        if (data && Array.isArray(data)) {
            setChildListData(data);
        } else {
            message.error(d.data.msg || '获取子列表失败');
        }
    };
    const decodePhone = async (uid: string | number) => {};
    useEffect(() => {
        if (adminPhone) {
            fetchChildList();
        }
    }, []);
    const onSelectChange = (
        selectedRowKeys: string[] | number[],
        selectedRows: IMemberChildData[],
    ) => {
        setSelectedRows(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
    };
    const renderMemberChildTable = () => {
        return (
            <Table<IMemberChildData>
                loading={fetchChildListLoading}
                dataSource={childListData}
                pagination={false}
                // onSelectedDataChange={onSelectChange}
                rowSelection={{
                    selectedRowKeys,
                    onChange: onSelectChange,
                }}
                size={'middle'}
                columns={[
                    {
                        title: '序号',
                        key: 'index',
                        width: '50px',
                        render: (d, item, idx) => {
                            return <span>{idx + 1}</span>;
                        },
                    },
                    {
                        title: '员工姓名',
                        dataIndex: 'ucard.name',
                        width: '100px',
                        render: d => {
                            if (d) {
                                return d;
                            }
                            return '[未分配]';
                        },
                    },
                    {
                        title: '手机号',
                        // dataIndex: 'ucard.mobile',
                        width: '75px',
                        render: item => {
                            return item.ucard.mobile ? (
                                <span>
                                    {item.ucard.mobile}
                                    {/* <Icon
                                        onClick={() => {
                                            if (item.ucard.id) {
                                                decodePhone(item.ucard.id);
                                            }
                                        }}
                                        type="eye"
                                        style={{
                                            fontSize: '12px',
                                            color: '#0052ff',
                                            marginLeft: '2px',
                                            cursor: 'pointer',
                                        }}
                                    /> */}
                                </span>
                            ) : (
                                ''
                            );
                        },
                    },
                    {
                        title: '邮箱',
                        dataIndex: 'ucard.email',
                        width: '150px',
                    },
                    {
                        title: '有效期',
                        dataIndex: 'join_time',
                        width: '130px',
                        render: (d, v) => {
                            return (
                                v.ucard.join_time &&
                                `${v.ucard.join_time} ~ ${v.report.expire_time}`
                            );
                        },
                    },
                    {
                        title: 'uid',
                        dataIndex: 'ucard.id',
                        width: '50px',
                    },
                    {
                        title: 'V2虚拟电话每日上限',
                        dataIndex: 'report.reach_nbr',
                        width: '150px',
                    },
                    {
                        title: 'V3电话沟通券剩余',
                        dataIndex: 'report.reach_nbr',
                        width: '150px',
                    },
                    {
                        title: '加好友券剩余',
                        dataIndex: 'report.add_fr_v2tov3',
                        width: '150px',
                    },
                    {
                        title: '操作',
                        width: '260px',
                        key: 'op',
                        render: item => {
                            const canShowDistribute = identityType === 6;
                            const canShowRecycleChild =
                                identityType === 1 ||
                                identityType === 2 ||
                                identityType === 3 ||
                                identityType === 6;
                            const canShowModifyMobileEquality =
                                identityType === 1 ||
                                identityType === 2 ||
                                identityType === 3;
                            return (
                                <p className="member-child-op">
                                    {authMap.modifyMobileEquality &&
                                    canShowModifyMobileEquality ? (
                                        <span
                                            onClick={() => {
                                                changeChildMobileEqualityModalVis(
                                                    true,
                                                    [item],
                                                    cid,
                                                );
                                            }}
                                        >
                                            修改电话权益
                                        </span>
                                    ) : null}
                                    {authMap.clearChildAuth ? (
                                        <Popconfirm
                                            title={'确定要清除子账号权益吗'}
                                            onConfirm={async () => {
                                                const res = await clearChildEquality(
                                                    [item],
                                                );
                                                if (res === true) {
                                                    message.success(
                                                        '清除子账号权益成功',
                                                    );
                                                    fetchChildList();
                                                } else {
                                                    message.error(
                                                        typeof res === 'string'
                                                            ? res
                                                            : '清除子账号权益失败',
                                                    );
                                                }
                                            }}
                                            okText="确认"
                                            cancelText="取消"
                                        >
                                            <span>清除子账号权益</span>
                                        </Popconfirm>
                                    ) : null}
                                    {authMap.deleteChild ? (
                                        <Popconfirm
                                            title={`确定要删除子账号吗`}
                                            onConfirm={async () => {
                                                const res = await deleteChildEquality(
                                                    [item],
                                                );
                                                if (res === true) {
                                                    message.success(
                                                        '删除子账号成功',
                                                    );
                                                    fetchChildList();
                                                } else {
                                                    message.error(
                                                        typeof res === 'string'
                                                            ? res
                                                            : '删除子账号失败',
                                                    );
                                                }
                                            }}
                                            okText="确认"
                                            cancelText="取消"
                                        >
                                            <span>删除子账号</span>
                                        </Popconfirm>
                                    ) : null}
                                    {authMap.recycleChild &&
                                    canShowRecycleChild ? (
                                        <Popconfirm
                                            title={`确认回收子账号？`}
                                            onConfirm={async () => {
                                                const res = await recycleChild([
                                                    item,
                                                ]);
                                                if (res === true) {
                                                    message.success(
                                                        '回收子账号成功',
                                                    );
                                                    fetchChildList();
                                                } else {
                                                    message.error(
                                                        typeof res === 'string'
                                                            ? res
                                                            : '回收子账号失败',
                                                    );
                                                }
                                            }}
                                            okText="确认"
                                            cancelText="取消"
                                        >
                                            <span>回收子账号</span>
                                        </Popconfirm>
                                    ) : null}
                                    {authMap.distributeEquality &&
                                    canShowDistribute ? (
                                        <span
                                            onClick={() => {
                                                distributeChildEqualityModalVis(
                                                    true,
                                                    [item],
                                                    cid,
                                                );
                                            }}
                                        >
                                            分配权益
                                        </span>
                                    ) : null}
                                </p>
                            );
                        },
                    },
                ]}
            />
        );
    };
    return (
        <>
            <div className="child-batch-content">
                {authMap.modifyMobileEquality ? (
                    <Button
                        size={'small'}
                        style={{ marginBottom: '15px' }}
                        onClick={() => {
                            if (selectedRows && selectedRows.length > 0) {
                                changeChildMobileEqualityModalVis(
                                    true,
                                    selectedRows,
                                    cid,
                                );
                            } else {
                                message.warning('无选择的子账号');
                            }
                        }}
                    >
                        批量修改电话权益
                    </Button>
                ) : null}
                {authMap.clearChildAuth ? (
                    <Button
                        size={'small'}
                        style={{ marginBottom: '15px', marginLeft: '5px' }}
                        onClick={() => {
                            if (selectedRows && selectedRows.length > 0) {
                                Modal.confirm({
                                    title: '批量清除子账号权益',
                                    okText: '确认',
                                    cancelText: '取消',
                                    content: (
                                        <span>
                                            确认要批量清除选中的子账号权益吗？
                                        </span>
                                    ),
                                    onOk: async () => {
                                        const res = await clearChildEquality(
                                            selectedRows,
                                        );
                                        if (res === true) {
                                            message.success(
                                                '批量清除子账号权益成功',
                                            );
                                            fetchChildList();
                                        } else {
                                            message.error(
                                                typeof res === 'string'
                                                    ? res
                                                    : '批量清除子账号权益失败',
                                            );
                                        }
                                    },
                                });
                            } else {
                                message.warning('无选择的子账号');
                            }
                        }}
                    >
                        批量清除子账号权益
                    </Button>
                ) : null}
                {authMap.deleteChild ? (
                    <Button
                        size={'small'}
                        style={{ marginBottom: '15px', marginLeft: '5px' }}
                        onClick={() => {
                            if (selectedRows && selectedRows.length > 0) {
                                Modal.confirm({
                                    title: '批量删除子账号',
                                    okText: '确认',
                                    cancelText: '取消',
                                    content: (
                                        <span>
                                            确认要批量删除选中的子账号吗？
                                        </span>
                                    ),
                                    onOk: async () => {
                                        const res = await deleteChildEquality(
                                            selectedRows,
                                        );
                                        if (res === true) {
                                            message.success(
                                                '批量删除子账号成功',
                                            );
                                            setSelectedRows(undefined);
                                            setSelectedRowKeys(undefined);
                                            fetchChildList();
                                        } else {
                                            message.error(
                                                typeof res === 'string'
                                                    ? res
                                                    : '批量删除子账号失败',
                                            );
                                        }
                                    },
                                });
                            } else {
                                message.warning('无选择的子账号');
                            }
                        }}
                    >
                        批量删除子账号
                    </Button>
                ) : null}
                {authMap.recycleChild ? (
                    <Button
                        size={'small'}
                        style={{ marginBottom: '15px', marginLeft: '5px' }}
                        onClick={() => {
                            if (selectedRows && selectedRows.length > 0) {
                                Modal.confirm({
                                    title: '批量回收子账号',
                                    okText: '确认',
                                    cancelText: '取消',
                                    content: (
                                        <span>
                                            确认要批量回收选中的子账号吗？
                                        </span>
                                    ),
                                    onOk: async () => {
                                        const res = await batchRecycleChild(
                                            selectedRows,
                                        );
                                        if (res === true) {
                                            message.success(
                                                '批量回收子账号成功',
                                            );
                                            setSelectedRows(undefined);
                                            setSelectedRowKeys(undefined);
                                            fetchChildList();
                                        } else {
                                            message.error(
                                                typeof res === 'string'
                                                    ? res
                                                    : '批量回收子账号失败',
                                            );
                                        }
                                    },
                                });
                            } else {
                                message.warning('无选择的子账号');
                            }
                        }}
                    >
                        批量回收子账号
                    </Button>
                ) : null}
            </div>
            {renderMemberChildTable()}
        </>
    );
};

export default observer(ExpandedMemberRow);
