import React, { useState, useContext, useEffect, useCallback } from 'react';
import './index.scss';
import { observer } from 'mobx-react-lite';
import { IDRMAuthData, IDRMRoleData, IDRMRoleAuthData } from '../store';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { Input, Radio, Select, message, Spin } from 'antd';
import { TableLv2, AntButton } from '@/components';
import { useNet } from '../../../../utils/hooks/axios';
// 简单权限的配置
const simpleAuthArray = [
    {
        label: '本人',
        value: 1,
    },
    {
        label: '本人及下属',
        value: 2,
    },
    // {
    //     label: '本部门',
    //     value: 3,
    // },
    // {
    //     label: '本部门及下级部门',
    //     value: 4,
    // },
    {
        label: '全部',
        value: 5,
    },
];
interface IRenderDRMAuthEle {
    authKey: string; // 权限key
    authName: string; // 权限名 (查询、编辑、删除、转移)
    authValue?: string | number; // 权限状态 (1 本人 2 本人及下属 3 本部门 4 本部门及下级部门 5 全部)
    editable?: boolean; // 是否是需要编辑的权限 (true: 可以编辑 false: 不可以)
}
// 从源数据转换的权限数据，用于渲染
interface IRenderDRMAuthData {
    entityName: string; // 模块名称
    entityCode: string; // 模块id
    searchEle?: IRenderDRMAuthEle; // 空则渲染空 --渲染横线 数据渲染select
    editEle?: IRenderDRMAuthEle; // 编辑
    deleteEle?: IRenderDRMAuthEle; // 删除
    transformEle?: IRenderDRMAuthEle; // 转移
}
interface IUpdateDRMData {
    dataRoleName?: string; // 数据角色名称
    simpleAuth?: string | number; // 简易模式下四个选择
    authority: IRenderDRMAuthData[];
}
export interface IDRMActionBodyProps {
    selectedData: IDRMRoleData;
    saveBtnClassName?: string;
    roleReload: () => void;
    drawerVis?: boolean;
    isDrawer?: boolean; // 是否是抽屉
    isUpdateAction?: boolean; // 副作用控制上传
    setDrawerVis?: (bool: boolean) => void;
}
const DRMActionBody: React.FC<IDRMActionBodyProps> = props => {
    const {
        selectedData,
        saveBtnClassName,
        drawerVis,
        isDrawer,
        isUpdateAction,
        setDrawerVis,
        roleReload,
    } = props;
    const store = useContext(StoreContext);
    const DRMStore = store.getDefaultDRMStore();
    const {
        DRMRoleAuthConfig,
        updateDRMRole,
        isUpdateLoading,
        fetchDataRoleAuth,
        DRMRoleAuthData,
    } = DRMStore;
    const roleAuthFetch = useCallback(() => {
        return fetchDataRoleAuth(selectedData.id as string);
    }, [selectedData]);
    const [drmRoleAuthError, drmRoleAuthLoading, drmRoleAuthReload] = useNet(
        roleAuthFetch,
    );
    const [mode, setMode] = useState<'complex' | 'simple'>('simple'); // simple是简单模式
    const [updateData, setUpdateData] = useState<IUpdateDRMData>({
        simpleAuth: 5, // 1 本人 2 本人及下属 3 本部门 4 本部门及下级部门 5 全部
        authority: [],
    });
    const [validateUpdateData, setValidateUpdateData] = useState<{
        [key: string]: {
            status: 'error' | 'suc';
            msg: string;
        };
    }>({
        dataRoleName: {
            status: 'suc',
            msg: '',
        },
    });
    const transformAuthItemData2Render = (
        authority?: IDRMAuthData[],
        allValue?: string | number,
    ) => {
        if (authority && Array.isArray(authority)) {
            return _.map(authority, authItem => {
                const groupAuth = _.groupBy(authItem?.auth || [], 'authName');
                const searchEleArray = groupAuth?.查询 || [];
                const editEleArray = groupAuth?.编辑 || [];
                const deleteEleArray = groupAuth?.删除 || [];
                const transformEleArray = groupAuth?.转移 || [];
                if (allValue) {
                    searchEleArray[0] &&
                        (searchEleArray[0].authValue = allValue);
                    editEleArray[0] && (editEleArray[0].authValue = allValue);
                    deleteEleArray[0] &&
                        (deleteEleArray[0].authValue = allValue);
                    transformEleArray[0] &&
                        (transformEleArray[0].authValue = allValue);
                }
                const renderAuth: IRenderDRMAuthData = {
                    entityCode: authItem.entityCode,
                    entityName: authItem.entityName,
                    searchEle: searchEleArray[0],
                    editEle: editEleArray[0],
                    deleteEle: deleteEleArray[0],
                    transformEle: transformEleArray[0],
                };
                return renderAuth;
            }) as IRenderDRMAuthData[];
        } else {
            // 如果authority没有又不是简易模式，那应该是数据脏了
            return [];
        }
    };
    const transformRender2AuthItemData: (
        renderAuthData: IRenderDRMAuthData[],
    ) => IDRMAuthData[] = renderAuthData => {
        if (renderAuthData && Array.isArray(renderAuthData)) {
            return _.map(renderAuthData, renderAuth => {
                const {
                    searchEle,
                    transformEle,
                    deleteEle,
                    editEle,
                } = renderAuth;
                const authList = [
                    searchEle,
                    editEle,
                    deleteEle,
                    transformEle,
                ].filter(Boolean);
                return {
                    entityCode: renderAuth.entityCode,
                    entityName: renderAuth.entityName,
                    auth: authList,
                } as IDRMAuthData;
            });
        }
        return [];
    };
    const transformOriginData2RenderData: (
        originData: IDRMRoleAuthData,
    ) => IRenderDRMAuthData[] = oData => {
        if (oData.isSimple) {
            // 简易模式下也要生成备用的选择表
            return transformAuthItemData2Render(_.cloneDeep(DRMRoleAuthConfig));
        }
        const authority = _.cloneDeep(oData)?.authority;
        return transformAuthItemData2Render(authority);
    };
    useEffect(() => {
        if (!_.isEmpty(DRMRoleAuthData)) {
            setMode(DRMRoleAuthData.isSimple ? 'simple' : 'complex');
            if (isDrawer) {
                _.once(() => {
                    setUpdateData({
                        dataRoleName: selectedData.dataRoleName,
                        simpleAuth: DRMRoleAuthData.isSimple
                            ? DRMRoleAuthData.simpleAuth
                            : '',
                        authority: transformOriginData2RenderData(
                            DRMRoleAuthData,
                        ), // 这里是需要转化的
                    });
                    setValidateUpdateData({
                        ...validateUpdateData,
                        dataRoleName: {
                            status: 'suc',
                            msg: '',
                        },
                    });
                });
            } else {
                setValidateUpdateData({
                    ...validateUpdateData,
                    dataRoleName: {
                        status: 'suc',
                        msg: '',
                    },
                });
                setUpdateData({
                    dataRoleName: selectedData.dataRoleName,
                    simpleAuth: DRMRoleAuthData.isSimple
                        ? DRMRoleAuthData.simpleAuth
                        : '',
                    authority: transformOriginData2RenderData(DRMRoleAuthData), // 这里是需要转化的
                });
            }
        }
    }, [DRMRoleAuthData]);
    useEffect(() => {
        drmRoleAuthReload();
    }, [selectedData]);
    useEffect(() => {
        console.log(drawerVis);
        if (drawerVis !== undefined && !drawerVis) {
            // 清空数据
            setMode('simple');
            setUpdateData({
                dataRoleName: undefined,
                simpleAuth: 5,
                authority: transformOriginData2RenderData(DRMRoleAuthData),
            });
            setValidateUpdateData({
                dataRoleName: {
                    status: 'suc',
                    msg: '',
                },
            });
        }
    }, [drawerVis]);
    useEffect(() => {
        console.log(isUpdateAction);
        if (isUpdateAction && isDrawer) {
            onUpdateActionHandle();
        }
    }, [isUpdateAction]);
    const onUpdateDataChange = (
        updateKey: keyof typeof updateData,
        val: any,
    ) => {
        const tempUpdateData: IUpdateDRMData & {
            [key: string]: any;
        } = {
            ...updateData,
        };
        tempUpdateData[updateKey] = val;
        setUpdateData(tempUpdateData);
    };
    const onUpdateActionHandle = async () => {
        if (!updateData.dataRoleName) {
            message.warning('填写不正确，请检查后提交', 1);
            setValidateUpdateData({
                dataRoleName: {
                    status: 'error',
                    msg: '数据角色名称不能为空',
                },
            });
            return;
        }
        const finalUpdateAuthData = transformRender2AuthItemData(
            updateData.authority,
        );
        const finalUpdateData: any = {
            ...updateData,
            id: selectedData.id,
            isSimple: mode === 'simple',
            authority: finalUpdateAuthData,
        };
        let howShowAuthRes = false;
        let howShowRoleRes = false;
        const [data, e] = await updateDRMRole(finalUpdateData);
        if (data.authRes && !data.authRes?.msg) {
            howShowAuthRes = true;
            drmRoleAuthReload();
        } else {
            howShowAuthRes = false;
        }
        if (data.roleRes && !data.roleRes?.msg) {
            selectedData.dataRoleName = updateData.dataRoleName;
            roleReload();
            howShowRoleRes = true;
        } else {
            howShowRoleRes = false;
        }
        if (howShowRoleRes && howShowAuthRes) {
            message.success(`成员与数据权限修改成功～`, 1);
        } else {
            message.error(
                `成员修改${
                    howShowRoleRes ? '成功' : data.roleRes?.msg || '失败'
                }  
            数据权限修改${
                howShowAuthRes ? '成功' : data.authRes?.msg || '失败'
            }`,
                2,
            );
        }
    };
    return (
        <Spin spinning={drmRoleAuthLoading}>
            <div className="drm-action-body">
                <div className="pa-table-item">
                    <label htmlFor="name" className="pa-table-item-label">
                        角色名称<span className="required-mark">*</span>
                    </label>
                    <Input
                        className={
                            validateUpdateData.dataRoleName.status === 'error'
                                ? 'has-error'
                                : ''
                        }
                        placeholder="请输入角色名称"
                        style={{ width: '300px' }}
                        id="name"
                        onChange={e => {
                            onUpdateDataChange('dataRoleName', e.target.value);
                        }}
                        onFocus={() => {
                            setValidateUpdateData({
                                ...validateUpdateData,
                                dataRoleName: {
                                    status: 'suc',
                                    msg: '',
                                },
                            });
                        }}
                        value={updateData.dataRoleName}
                    />
                    <span
                        className="switch-drm-mode"
                        onClick={() => {
                            // 从简易模式切换至高级模式时构造高级模式
                            setMode(mode === 'complex' ? 'simple' : 'complex');
                            if (mode === 'simple' && updateData.simpleAuth) {
                                setUpdateData({
                                    ...updateData,
                                    authority: transformAuthItemData2Render(
                                        DRMRoleAuthConfig,
                                        updateData.simpleAuth,
                                    ),
                                });
                            }
                        }}
                    >
                        切换至{mode === 'complex' ? '简易' : '高级'}设置
                    </span>
                </div>
                {validateUpdateData.dataRoleName.status === 'error' && (
                    <p className="pa-error-msg">
                        {validateUpdateData.dataRoleName.msg}
                    </p>
                )}
                <div
                    className="pa-table-item"
                    style={{
                        flex: 1,
                        alignItems: 'unset',
                        flexDirection: 'column',
                    }}
                >
                    <>
                        <div className="pa-table-item-label">
                            数据权限<span className="required-mark">*</span>
                            <span className="pa-table-label-description">
                                请设置该角色，在部门管理维度下的数据权限（包括：查询、修改、删除、转移）
                            </span>
                        </div>
                        {mode === 'simple' ? (
                            <Radio.Group
                                onChange={e => {
                                    onUpdateDataChange(
                                        'simpleAuth',
                                        e.target.value,
                                    );
                                }}
                                value={updateData.simpleAuth}
                            >
                                {_.map(simpleAuthArray, item => {
                                    return (
                                        <Radio
                                            style={{
                                                display: 'block',
                                                marginBottom: '26px',
                                            }}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Radio>
                                    );
                                })}
                            </Radio.Group>
                        ) : (
                            <TableLv2<IRenderDRMAuthData>
                                className={'table-v-stretch'}
                                pagination={false}
                                closeRowSelection={true}
                                dataSource={updateData.authority}
                                columns={[
                                    {
                                        key: 'entityName',
                                        dataIndex: 'entityName',
                                        width: '100px',
                                        title: '模块',
                                    },
                                    {
                                        key: 'searchEle',
                                        title: '查询',
                                        width: '160px',
                                        render: (
                                            record: IRenderDRMAuthData,
                                            v,
                                            idx,
                                        ) => {
                                            const searchEle = record?.searchEle;
                                            if (searchEle) {
                                                if (searchEle.editable) {
                                                    return (
                                                        <Select
                                                            value={
                                                                searchEle.authValue
                                                            }
                                                            onSelect={(
                                                                val: any,
                                                            ) => {
                                                                const tempUpdateData = {
                                                                    ...updateData,
                                                                };
                                                                const tempEle: any =
                                                                    tempUpdateData
                                                                        .authority[
                                                                        idx
                                                                    ]
                                                                        .searchEle ||
                                                                    {};
                                                                tempEle.authValue = val;
                                                                setUpdateData(
                                                                    tempUpdateData,
                                                                );
                                                            }}
                                                            style={{
                                                                width: '150px',
                                                            }}
                                                        >
                                                            {_.map(
                                                                simpleAuthArray,
                                                                auth => {
                                                                    return (
                                                                        <Select.Option
                                                                            value={
                                                                                auth.value
                                                                            }
                                                                        >
                                                                            {
                                                                                auth.label
                                                                            }
                                                                        </Select.Option>
                                                                    );
                                                                },
                                                            )}
                                                        </Select>
                                                    );
                                                } else {
                                                    return '- -';
                                                }
                                            } else {
                                                return null;
                                            }
                                        },
                                    },
                                    {
                                        key: 'editEle',
                                        title: '编辑',
                                        width: '160px',
                                        render: (
                                            record: IRenderDRMAuthData,
                                            v,
                                            idx,
                                        ) => {
                                            const editEle = record?.editEle;
                                            if (editEle) {
                                                if (editEle.editable) {
                                                    return (
                                                        <Select
                                                            onSelect={(
                                                                val: any,
                                                            ) => {
                                                                const tempUpdateData = {
                                                                    ...updateData,
                                                                };
                                                                const tempEle: any =
                                                                    tempUpdateData
                                                                        .authority[
                                                                        idx
                                                                    ].editEle ||
                                                                    {};
                                                                tempEle.authValue = val;
                                                                setUpdateData(
                                                                    tempUpdateData,
                                                                );
                                                            }}
                                                            value={
                                                                editEle.authValue
                                                            }
                                                            style={{
                                                                width: '150px',
                                                            }}
                                                        >
                                                            {_.map(
                                                                simpleAuthArray,
                                                                auth => {
                                                                    return (
                                                                        <Select.Option
                                                                            value={
                                                                                auth.value
                                                                            }
                                                                        >
                                                                            {
                                                                                auth.label
                                                                            }
                                                                        </Select.Option>
                                                                    );
                                                                },
                                                            )}
                                                        </Select>
                                                    );
                                                } else {
                                                    return '- -';
                                                }
                                            } else {
                                                return null;
                                            }
                                        },
                                    },
                                    {
                                        key: 'deleteEle',
                                        title: '删除',
                                        width: '160px',
                                        render: (
                                            record: IRenderDRMAuthData,
                                            v,
                                            idx,
                                        ) => {
                                            const deleteEle = record?.deleteEle;
                                            if (deleteEle) {
                                                if (deleteEle.editable) {
                                                    return (
                                                        <Select
                                                            onSelect={(
                                                                val: any,
                                                            ) => {
                                                                const tempUpdateData = {
                                                                    ...updateData,
                                                                };
                                                                const tempEle: any =
                                                                    tempUpdateData
                                                                        .authority[
                                                                        idx
                                                                    ]
                                                                        .deleteEle ||
                                                                    {};
                                                                tempEle.authValue = val;
                                                                setUpdateData(
                                                                    tempUpdateData,
                                                                );
                                                            }}
                                                            value={
                                                                deleteEle.authValue
                                                            }
                                                            style={{
                                                                width: '150px',
                                                            }}
                                                        >
                                                            {_.map(
                                                                simpleAuthArray,
                                                                auth => {
                                                                    return (
                                                                        <Select.Option
                                                                            value={
                                                                                auth.value
                                                                            }
                                                                        >
                                                                            {
                                                                                auth.label
                                                                            }
                                                                        </Select.Option>
                                                                    );
                                                                },
                                                            )}
                                                        </Select>
                                                    );
                                                } else {
                                                    return '- -';
                                                }
                                            } else {
                                                return null;
                                            }
                                        },
                                    },
                                    {
                                        key: 'transformEle',
                                        title: '转移',
                                        width: '160px',
                                        render: (
                                            record: IRenderDRMAuthData,
                                            v,
                                            idx,
                                        ) => {
                                            const transformEle =
                                                record?.transformEle;
                                            if (transformEle) {
                                                if (transformEle.editable) {
                                                    return (
                                                        <Select
                                                            onSelect={(
                                                                val: any,
                                                            ) => {
                                                                const tempUpdateData = {
                                                                    ...updateData,
                                                                };
                                                                const tempEle: any =
                                                                    tempUpdateData
                                                                        .authority[
                                                                        idx
                                                                    ]
                                                                        .transformEle ||
                                                                    {};
                                                                tempEle.authValue = val;
                                                                setUpdateData(
                                                                    tempUpdateData,
                                                                );
                                                            }}
                                                            value={
                                                                transformEle.authValue
                                                            }
                                                            style={{
                                                                width: '150px',
                                                            }}
                                                        >
                                                            {_.map(
                                                                simpleAuthArray,
                                                                auth => {
                                                                    return (
                                                                        <Select.Option
                                                                            value={
                                                                                auth.value
                                                                            }
                                                                        >
                                                                            {
                                                                                auth.label
                                                                            }
                                                                        </Select.Option>
                                                                    );
                                                                },
                                                            )}
                                                        </Select>
                                                    );
                                                } else {
                                                    return '- -';
                                                }
                                            } else {
                                                return null;
                                            }
                                        },
                                    },
                                ]}
                            />
                        )}
                    </>
                </div>
                {!isDrawer && (
                    <AntButton
                        className={`drm-save-btn ${saveBtnClassName || ''}`}
                        size="default"
                        type="primary"
                        onClick={onUpdateActionHandle}
                        loading={isUpdateLoading}
                    >
                        保存
                    </AntButton>
                )}
            </div>
        </Spin>
    );
};

export default observer(DRMActionBody);
