import React, { useEffect } from 'react';
import { ICommonProps } from '../common';
import _ from 'lodash';
import './date-range.scss';

import { Calendar, Select, Row, Icon, TimePicker, Tag } from 'antd';
import { HeaderRender } from 'antd/lib/calendar/Header';
import moment, { Moment } from 'moment';
import { CalendarProps } from 'antd/lib/calendar';
import zhCN from 'antd/es/locale/zh_CN';

function onPanelChange(value: any, mode: any) {
    console.log(value, mode);
}

const oneDay = 86400000;
const endOfDay = oneDay - 1000;
const startOfDay = 0;

moment().endOf('day');

export type FilterDateRangeValueType = [number | undefined, number | undefined];
export interface IFilterDateRangeOptions extends CalendarProps {
    fromInputProps?: CalendarProps;
    fromInputText?: string;
    toInputProps?: CalendarProps;
    toInputText?: string;
}

export interface IWidgetFilterDateRangeProps<T = any> extends ICommonProps<T> {
    value: FilterDateRangeValueType;
    options: IFilterDateRangeOptions;
    onChange: (value: FilterDateRangeValueType) => void;
}

const startYear = 2010;
const endYear = 2030;

const makeHeaderRender: HeaderRender = ({
    value,
    type,
    onChange,
    onTypeChange,
}) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];

    value = _.isNil(value) ? moment() : value;

    const current = value.clone();
    const localeData = value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
        current.month(i);
        months.push(localeData.monthsShort(current));
    }

    for (let index = start; index < end; index++) {
        monthOptions.push(
            <Select.Option
                value={index}
                className="month-item"
                key={`${index}`}
            >
                {index + 1}月
            </Select.Option>,
        );
    }
    const month = _.isNil(value) ? undefined : value.month();

    const year = _.isNil(value) ? undefined : value.year();
    const options = [];
    for (let i = startYear; i <= endYear; i += 1) {
        options.push(
            <Select.Option key={i} value={i} className="year-item">
                {i}年
            </Select.Option>,
        );
    }

    const goPrevMonth = () => {
        const prevMonth = value.clone().subtract(1, 'month');
        if (!_.isNil(onChange)) {
            onChange(prevMonth);
        }
    };

    const goNextMonth = () => {
        const nextMonth = value.clone().add(1, 'month');
        if (!_.isNil(onChange)) {
            onChange(nextMonth);
        }
    };

    return (
        <div className="header">
            <div onClick={goPrevMonth} className="left-icon arrow">
                <Icon type="left" />
            </div>
            <div className="selector year">
                <Select<number>
                    size="small"
                    dropdownMatchSelectWidth={false}
                    className="my-year-select"
                    onChange={newYear => {
                        const now = value.clone().year(newYear);
                        if (!_.isNil(onChange)) {
                            onChange(now);
                        }
                    }}
                    value={year}
                >
                    {options}
                </Select>
            </div>
            <div className="selector month">
                <Select<number>
                    size="small"
                    dropdownMatchSelectWidth={false}
                    value={month}
                    onChange={selectedMonth => {
                        const newValue = value.clone();
                        newValue.month(selectedMonth);
                        if (!_.isNil(onChange)) {
                            onChange(newValue);
                        }
                    }}
                >
                    {monthOptions}
                </Select>
            </div>
            <div onClick={goNextMonth} className="right-icon arrow">
                <Icon type="right" />
            </div>
        </div>
    );
};

export const WidgetFilterDateRange: React.FC<IWidgetFilterDateRangeProps> = ({
    value,
    options,
    onChange,
    onInteract = key => void 0,
    status,
    data,
    statusExtraData,
}) => {
    const [fromValue, toValue] = value;
    const {
        fromInputProps,
        toInputProps,
        fromInputText = '开始日期',
        toInputText = '结束日期',
    } = options;

    const invalid = (toValue || Infinity) < (fromValue || -Infinity);
    useEffect(() => {
        if (invalid) {
            onInteract('filter-become-invalid');
        } else {
            onInteract('filter-become-valid');
        }
    }, [invalid]);

    const handleStartChange: (date?: Moment | undefined) => void = date => {
        if (fromValue === undefined) {
            date = date?.clone().startOf('day');
        }
        onChange([date?.valueOf(), toValue]);
    };
    const handleEndChange: (date?: Moment | undefined) => void = date => {
        if (toValue === undefined) {
            date = date?.clone().endOf('day');
        }
        onChange([fromValue, date?.valueOf()]);
    };
    const handleBoth: (
        startDate?: Moment | undefined,
        endData?: Moment | undefined,
    ) => void = (startDate, endData) => {
        onChange([startDate?.valueOf(), endData?.valueOf()]);
    };

    const handleStartTimeChange: (date?: Moment | undefined) => void = date => {
        onChange([date?.valueOf(), toValue]);
    };
    const handleEndTimeChange: (date?: Moment | undefined) => void = date => {
        onChange([fromValue, date?.valueOf()]);
    };

    const startV = _.isNil(fromValue) ? undefined : moment(fromValue);
    const endV = _.isNil(toValue) ? undefined : moment(toValue);

    const startChalenProps: CalendarProps = {
        locale: zhCN,
        fullscreen: false,
        headerRender: makeHeaderRender,
        onPanelChange,
        onChange: handleStartChange,
        ...options,
        ...toInputProps,
    };
    if (startV) {
        startChalenProps.value = startV;
    }

    const endChalenProps: CalendarProps = {
        locale: zhCN,
        fullscreen: false,
        headerRender: makeHeaderRender,
        onPanelChange,
        onChange: handleEndChange,
        ...options,
        ...toInputProps,
    };
    if (endV) {
        endChalenProps.value = endV;
    }

    return (
        <div>
            <div className="crm-widgets-date-range-input">
                <div className="calendar left">
                    <div className="title">{fromInputText}</div>
                    <div className="calendar-itself">
                        <Calendar
                            key={'fkantd-' + startV}
                            className={startV === undefined ? 'noselected' : ''}
                            {...startChalenProps}
                        />
                        <div className="time-selector">
                            <TimePicker
                                value={startV}
                                onChange={handleStartTimeChange}
                                allowClear={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="calendar right">
                    <div className="title">
                        {toInputText}
                        {invalid ? (
                            <span style={{ color: '#ff001f' }}>
                                （应大于起始值）
                            </span>
                        ) : null}
                    </div>
                    <div className="calendar-itself">
                        <Calendar
                            key={'fkantd-' + endV}
                            className={endV === undefined ? 'noselected' : ''}
                            {...endChalenProps}
                        />
                        <div className="time-selector">
                            <TimePicker
                                value={endV}
                                onChange={handleEndTimeChange}
                                allowClear={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: 8 }} className="">
                快捷选项
            </div>
            <div className="crm-widgets-date-range-input-fast-selectors">
                <div>
                    {fastSelectors.map(({ label, start, end }) => {
                        return (
                            <Tag
                                style={{
                                    marginRight: '8px',
                                    cursor: 'pointer',
                                }}
                                color="blue"
                                onClick={() => handleBoth(start(), end())}
                            >
                                {label}
                            </Tag>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const fastSelectors = [
    {
        label: '今天',
        start: () => moment().startOf('day'),
        end: () => moment().endOf('day'),
    },
    {
        label: '三天内',
        start: () =>
            moment()
                .subtract(3, 'days')
                .startOf('day'),
        end: () => moment().endOf('day'),
    },
    {
        label: '七天内',
        start: () =>
            moment()
                .subtract(7, 'days')
                .startOf('day'),
        end: () => moment().endOf('day'),
    },
    {
        label: '一个月内',
        start: () =>
            moment()
                .subtract(1, 'month')
                .startOf('day'),
        end: () => moment().endOf('day'),
    },
];
