import React from 'react';
import { DatePicker } from 'antd';
import { getCommonStatus, ICommonProps } from './common';
import { RangePickerProps } from 'antd/lib/date-picker/interface';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from './date';
import _ from 'lodash';

const RangePicker = DatePicker.RangePicker;

export type DateRangeValueType = [number, number];
export interface IDateRangeOptions extends RangePickerProps {}

export interface IWidgetDateRangeProps<T = any> extends ICommonProps<T> {
    value: DateRangeValueType;
    options: IDateRangeOptions;
    onChange: (value: DateRangeValueType) => void;
}

export const WidgetDateRange: React.FC<IWidgetDateRangeProps> = ({
    value,
    options,
    onChange,
    onInteract = key => void 0,
    status,
    data,
    statusExtraData,
}) => {
    const { disabled } = getCommonStatus(status, data, statusExtraData);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }
    const v = {
        value: _.map(value, (rangeItem, b, c) => {
            if (!rangeItem || rangeItem === -1) {
                return undefined;
            }
            return moment(rangeItem);
        }),
    } as Pick<RangePickerProps, 'value'>;
    return (
        <RangePicker
            onBlur={() => {
                onInteract('blur');
                onInteract('validate-instantly');
            }}
            onFocus={() => {
                onInteract('focus');
                onInteract('remove-validate-status');
            }}
            onChange={v => {
                const [from, to] = v;
                const nextValue: [number, number] = [-1, -1];
                if (!_.isNil(from)) {
                    nextValue[0] = from.valueOf();
                }
                if (!_.isNil(to)) {
                    nextValue[1] = to.valueOf();
                }
                onChange(nextValue);
            }}
            {...v}
            {...options}
        />
    );
};
