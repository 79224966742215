import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { observer } from '@/containers/hoc';
import { CrmLoading2 } from '@/components/common/crm-loading';

const LoadingDemo: React.FC<RouteComponentProps> = ({}) => {
    return <CrmLoading2 />;
};

export default observer(LoadingDemo);
