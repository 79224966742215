import React from 'react';
import Button, { ButtonProps } from 'antd/lib/button';
import { getCommonStatus, ICommonProps } from './common';
import _ from 'lodash';

export type ButtonValueType = undefined;
export interface IButtonOptions extends ButtonProps {
    btnText: string;
    clickActionType?: string;
    hidden?: boolean;
}

export interface IWidgetButtonProps<T = any> extends ICommonProps<T> {
    value: ButtonValueType;
    options: IButtonOptions;
    onChange: (value: ButtonValueType) => void;
}

export const WidgetButton: React.FC<IWidgetButtonProps> = ({
    options,
    status,
    onInteract = key => void 0,
    data,
    statusExtraData,
}) => {
    const { disabled, loading } = getCommonStatus(
        status,
        data,
        statusExtraData,
    );
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }
    if (!_.isNil(loading)) {
        options.loading = loading;
    }
    return options.hidden ? null : (
        <Button
            onClick={e => {
                onInteract(options.clickActionType || 'validate-all|submit');
            }}
            {...options}
        >
            {options.btnText}
        </Button>
    );
};
