import React, { useEffect, memo } from 'react';

import { CCAdminListStore } from '../store';
import { Modal, Alert } from 'antd';
import { observer } from 'mobx-react-lite';
import { tryRefresh } from '@/utils';

export const ActionDelete: React.FC<{
    defaultStore: CCAdminListStore;
}> = observer(({ defaultStore }) => {
    const { mutatingData } = defaultStore;
    if (mutatingData === null) {
        return null;
    }
    const visible = defaultStore.actionType === 'delete';
    return (
        <Modal
            visible={visible}
            title={'删除坐席'}
            onCancel={() => {
                defaultStore.resetAction();
            }}
            onOk={async () => {
                const ok = await defaultStore.delete();
                if (ok) {
                    defaultStore.resetAction();
                    tryRefresh(['sys_call_center_user']);
                }
            }}
        >
            <p>
                是否删除坐席【{mutatingData.account}@
                {mutatingData.type === 1 ? '天润融通' : '容联七陌'}】？
            </p>
            <Alert type="error" message="删除成功之后，该坐席将不能外呼" />
        </Modal>
    );
});
