import './inits/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import AppRouter, { ErrorBoundaryComponent } from './AppRouter';
import * as serviceWorker from './serviceWorker';

import { comResouceMap } from '@/configs/resource-map';
import { routeLayout } from '@/configs/route';

import { StoreContext, createStore } from '@/stores';
import { AntdProvider } from '@/inits/antd';
import * as Cookie from 'tiny-cookie';
import { vuuid } from './utils/v-uuid';
import '@/stores/utils';
// initSentry
// initApp

// 设置样式
import '@/inits/style';

// 网络
import '@/inits/net';

// 时间
import '@/inits/moment';

// 授权
import '@/inits/auth';

// if (process.env.NODE_ENV === 'production') {
// const methods: string[] = ['log', 'warn', 'info', 'error']
// for (const method of methods) {
//     (window as any)['oldConsole' + method] = (window.console as any)[method]
//     (window.console as any)[method] = () => {}
// }
// }

/**
 * App lifecycle
 * 0) initApp
 * 1) beforeAppMount
 * 2) AppMount
 */
Sentry.init({
    dsn:
        'https://514b07f881134aaa8bd1ae7b25e01377@crm.taoum.cn/UQZeXarfJquEF1SL/15',
    environment:
        process.env.REACT_APP_ENV === 'pro' ? 'production' : 'development',
    integrations: [
        new TracingIntegrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(
                window.history,
            ),
        }),
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
});
Sentry.configureScope(scope => {
    scope.setTag('CRM-PC', 'GLOBAL');
    if (process.env.NODE_ENV !== 'production') {
        scope.setLevel(Sentry.Severity.Warning);
    }
});
if (!Cookie.get('_buuid')) {
    Cookie.set('_buuid', vuuid(), { path: '/', expires: '10Y' });
}
async function beforeAppMount() {
    // recoverRedirect();
}

async function bootstrap() {
    try {
        await beforeAppMount();
        // renderApp
        ReactDOM.render(
            <ErrorBoundaryComponent isAll={true}>
                <AntdProvider>
                    <StoreContext.Provider value={createStore({})}>
                        <AppRouter
                            comResouceMap={comResouceMap}
                            routeLayout={routeLayout}
                        />
                    </StoreContext.Provider>
                </AntdProvider>
            </ErrorBoundaryComponent>,
            document.getElementById('root'),
        );
    } catch (e) {
        console.warn('initAppFail: ', e);
    }
}

bootstrap();

function beforeAppUnmount(e: BeforeUnloadEvent) {
    // // Cancel the event as stated by the standard.
    // e.preventDefault();
    // // Chrome requires returnValue to be set.
    // e.returnValue = '';
}

window.addEventListener('beforeunload', beforeAppUnmount);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const testLag = () => {
    const start = Date.now();
    window.requestAnimationFrame(() => {
        const diff = Date.now() - start;
        if (diff > 500) {
            console.log('lag: ', diff);
        }
        testLag();
    });
};
testLag();
