import React, { useEffect, memo } from 'react';

import { CustomerListStore } from '../store';
import { Modal, Alert } from 'antd';
import { observer } from 'mobx-react-lite';
import { tryRefresh } from '@/utils';

export const ActionDelete: React.FC<{
    customerList: CustomerListStore;
}> = observer(({ customerList }) => {
    const { mutatingData } = customerList;
    if (mutatingData === null) {
        return null;
    }
    const visible = customerList.actionType === 'delete';
    return (
        <Modal
            visible={visible}
            title={'删除客户'}
            onCancel={() => {
                customerList.resetAction();
            }}
            onOk={async () => {
                const ok = await customerList.delete();
                if (ok) {
                    customerList.resetAction();
                    tryRefresh(['customer']);
                }
            }}
        >
            <p>是否删除客户【{mutatingData.customer_name}】？</p>
            <Alert type="error" message="删除成功之后，该操作无法恢复。" />
        </Modal>
    );
});
