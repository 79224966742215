import { IItemOptionItem, IWidgetItem } from '@/components';
export interface IFormCrudDemoFilterData {
    name: string;
    gender: string;
}

export const widgets = (): Array<IWidgetItem<IFormCrudDemoFilterData>> => {
    return [
        {
            key: 'name',
            widgetKey: 'input',
            inputOptions: {
                placeholder: '按姓名筛选',
            },
        },
        {
            key: 'gender',
            widgetKey: 'input',
            inputOptions: {
                placeholder: '按性别筛选',
            },
        },
    ];
};

export const formItemOptions: Array<IItemOptionItem<
    IFormCrudDemoFilterData
>> = [
    {
        key: 'name',
        label: '姓名',
    },
    {
        key: 'gender',
        label: '性别',
    },
];
