import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';

const MemberPage: React.FC<RouteComponentProps> = props => {
    const store = useContext(StoreContext);
    const memberStore = store.getDefaultMemberStore();
    const { fetchConfigData } = memberStore;
    useEffect(() => {
        fetchConfigData();
    }, []);
    return <>{props.children}</>;
};

export default observer(MemberPage);
