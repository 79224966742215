import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
} from '@/stores/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import _ from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import {
    commonQuery,
    TGetCommonReturnType,
    getCommonQueryBody,
} from '@/api/rest';
import EE from 'eventemitter3';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';

export class ActiveRecordStore {
    // 数据
    public data: any[] = [];

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public get pageTitle() {
        if (this.listType === 'home') {
            return '我的拜访计划';
        }
        if (this.listType === 'all') {
            return '全部活动记录';
        }
        return '未知listType';
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );

        ret.filters?.push(...defaultFilters);
        return ret;
    }

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }
    public setAction(nextAction: string) {
        this.actionType = nextAction;
    }
    public resetAction() {
        this.actionType = '';
    }
    public async dispatchAction() {
        // NOOP
    }

    public async fetch(): Promise<TGetCommonReturnType> {
        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            'PRIVATE-VIEW',
            this.defaultMeta.tableColsParamsFromUserSetting.map(
                item => item.key,
            ),
            'SEARCH-PRIVATE',
        );

        if (d === null || e !== null) {
            return [d, e];
        }
        const leadsData = d;
        const { amount, datas } = leadsData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    get downloadUrl() {
        const { tableId: objectName } = this.defaultMeta;
        const { amount } = this.defaultPagination;
        if (!amount) {
            return '';
        }
        const pageSize = Math.max(Math.min(Math.ceil(amount / 10), 300), 100);
        const fetchBody = getCommonQueryBody(
            { ...this.defaultPagination.paginationArgs, pageSize },
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            'PRIVATE-VIEW',
            [
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
                'customer_id',
            ],
            'SEARCH-PRIVATE',
            false,
        );
        fetchBody.objectName = objectName;
        return `${
            window.location.origin
        }/bff/api/rest/export?q=${encodeURIComponent(
            JSON.stringify(fetchBody),
        )}`;
    }

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMeta = new ListMeta(
        '/bff/api/rest/meta/cu_activation_record/list',
        '/bff/api/rest/meta/cu_activation_record/list/filters/save',
        '/bff/api/rest/meta/cu_activation_record/list/table-cols/save',
    );
    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();
    public defaultPerm: Perm = new Perm(
        '/bff/api/rest/perm?mod=sales-follow-up:activity',
    );
    public defaultMSelect: MSelectStore = new MSelectStore();
    constructor() {
        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultMeta: false,
            defaultFilter: false,
            defaultEE: false,
            defaultPerm: false,
            defaultMSelect: false,
        });
    }
}

export const getDefaultActiveRecordStore = createDefaultInstanceGetter(
    ActiveRecordStore,
);
