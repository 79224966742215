import React, { useState, useEffect } from 'react';
import { Icon, Input } from 'antd';
import { getCommonStatus, ICommonProps } from '../common';
import _ from 'lodash';
import { SearchAbleTreeLeafOnlyModal } from '../../../searchable-tree/leaf-only';
import { ISearchAbleTreeProps } from '../../../searchable-tree';
import { InputProps } from 'antd/lib/input';

export type TreeSingleLeafOnlyValueType = any;
export interface ITreeSingleLeafOnlyOptions {
    treeProps: ISearchAbleTreeProps;
    flattenData: any[];
    inputProps: InputProps;
}

export interface IWidgetTreeSingleLeafOnlyProps<T = any>
    extends ICommonProps<T> {
    value: TreeSingleLeafOnlyValueType;
    options: ITreeSingleLeafOnlyOptions;
    onChange: (value: TreeSingleLeafOnlyValueType) => void;
}

export const WidgetTreeSingleLeafOnly: React.FC<IWidgetTreeSingleLeafOnlyProps> = ({
    value,
    options,
    onChange,
    onInteract = key => void 0,
    status,
    data,
    statusExtraData,
}) => {
    const { treeData, inputProps = {} } = options.treeProps;
    const flattenData = options.flattenData;
    const inputPropsOuter = options.inputProps;
    const { disabled } = getCommonStatus(status, data, statusExtraData);
    const [visible, setVisible] = useState(false);

    const [selectedKeys, setSelectedKeys] = useState(value ? [value] : []);
    const onSelectedChange = (keys: any[]) => {
        setVisible(false);
        setSelectedKeys(keys);
    };

    useEffect(() => {
        const [key] = selectedKeys;
        if (!key) {
            return;
        }
        onChange(key);
    }, [selectedKeys]);

    // value is key
    const selectedDatum = flattenData.find((item: any) => item.key === value);
    const text = selectedDatum ? selectedDatum.title || '' : '';
    return (
        <div
            style={{
                width: '100%',
                minWidth: 150,
                display: 'inline-block',
                position: 'relative',
            }}
            className="crm-widgets-object"
        >
            <Input
                style={{ cursor: 'pointer' }}
                {...inputPropsOuter}
                value={text}
                onClick={e => {
                    setVisible(true);
                    e.nativeEvent.preventDefault();
                    return false;
                }}
            />
            <Icon
                onClick={disabled ? () => {} : () => setVisible(true)}
                style={{
                    opacity: '0.3',
                    position: 'absolute',
                    right: '12px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    height: '14px',
                    cursor: 'pointer',
                    pointerEvents: disabled ? 'none' : 'unset',
                }}
                type="plus"
            />
            <div style={{ width: 300 }}>
                <SearchAbleTreeLeafOnlyModal
                    visible={visible}
                    onVisibleChange={setVisible}
                    treeData={treeData}
                    inputProps={inputProps}
                    selectedKeys={selectedKeys}
                    onSelectedChange={onSelectedChange}
                />
            </div>
        </div>
    );
};
