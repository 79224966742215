import React from 'react';
import _ from 'lodash';
import './index.scss';
import { Badge } from 'antd';
import { AutoEllipsis } from '@/components';

export const DTitle2: React.FC<{
    title: string;
    list?: Array<{ label: string; value: any; key?: string; selected?: boolean; choice?: boolean }>;
    selected?: Array<{ label: string; value: any }>;
    onListItemClick?: (value: any) => void;
    extra?: any;
}> = ({ title, list, selected, onListItemClick, extra }) => {
    const hasList = !_.isNil(list) && _.isArray(list) && list.length > 0;
    selected = selected || [];

    const statistic = extra?.statistic || {};
    if (!hasList) {
        return null;
    }

    return (
        <div className="process-cat-dtitle2">
            <div className="process-cat-dtitle2-list-outer">
                <div className="process-cat-dtitle2-list">
                    {list?.map(item => {
                        const { label, value, key } = item;
                        let isActive = false;
                        if (_.isArray(selected)) {
                            isActive = !!selected.find(
                                item => item.value === value,
                            );
                        }
                        const sta = statistic[key || ''] || 0;
                        return (
                            <div
                                className={
                                    'process-cat-dtitle2-item ' +
                                    (isActive ? 'active' : '')
                                }
                                onClick={() => {
                                    onListItemClick && onListItemClick(item);
                                }}
                            >
                                <span style={{ flexGrow: 1, paddingRight: 4 }}>
                                    <AutoEllipsis text={label} />
                                </span>

                                {!!+sta ? <Badge count={+sta} /> : null}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
