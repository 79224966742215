import React, { useMemo, useState } from 'react';
import { Statistic, Spin } from 'antd';
import BigNumberCard from './components/big-number-card';
import TopBoard from './components/top-board';
import ChartsGauge from './components/charts-gauge';
import AchievCard, { AchievColors } from './components/achiev-card';
import TableCard from '../components/table-card';
import BoardCard from '../components/board-card';
import BoardSwitch from '../components/board-switch';
import moment from 'moment';
import { useRequest } from 'ahooks';
import { queryIncomeBoard, queryWarSituation } from './api';
import _ from 'lodash';
import TableTitle from '../components/table-title';
import { Key2Tips } from '../constants';

import './index.less';

const TadayColumns = [
    {
        title: '区域',
        dataIndex: 'region',
    },
    {
        title: <TableTitle title="今日预估" tips={Key2Tips['今日预估']} />,
        dataIndex: 'today_predict',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    {
        title: <TableTitle title="今日已回" tips={Key2Tips['今日已回']} />,
        dataIndex: 'today_income',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    {
        title: <TableTitle title="明日预估" tips={Key2Tips['明日预估']} />,
        dataIndex: 'tomorrow_predict',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
];

const WeekColumns = [
    {
        title: '区域',
        dataIndex: 'region',
    },
    {
        title: <TableTitle title="已回" tips={Key2Tips['周回款']} />,
        dataIndex: 'week_income',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    {
        title: <TableTitle title="保守预估" tips={Key2Tips['周保守预估']} />,
        dataIndex: 'week_conservative_predict',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    // {
    //     title: (
    //         <TableTitle title="保守预估Gap" tips={Key2Tips['周保守预估Gap']} />
    //     ),
    //     dataIndex: 'week_conservative_predict_gap',
    //     render: (t: string) => {
    //         const text = Math.round(Number(t));
    //         return !_.isNaN(text) ? text : '-';
    //     },
    // },
    {
        title: (
            <TableTitle
                title="已回/保守预估"
                tips={Key2Tips['周已回/保守预估']}
            />
        ),
        dataIndex: 'week_conservative_predict_completion_rate',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? `${text}%` : '-';
        },
    },
    {
        title: <TableTitle title="冲刺预估" tips={Key2Tips['周冲刺预估']} />,
        dataIndex: 'week_spurt_predict',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    // {
    //     title: (
    //         <TableTitle title="冲刺预估Gap" tips={Key2Tips['周冲刺预估Gap']} />
    //     ),
    //     dataIndex: 'week_spurt_predict_gap',
    //     render: (t: string) => {
    //         const text = Math.round(Number(t));
    //         return !_.isNaN(text) ? text : '-';
    //     },
    // },
    {
        title: (
            <TableTitle
                title="已回/冲刺预估"
                tips={Key2Tips['周已回/冲刺预估']}
            />
        ),
        dataIndex: 'week_spurt_predict_completion_rate',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? `${text}%` : '-';
        },
    },
];

const MonthColumns = [
    {
        title: '区域',
        dataIndex: 'region',
    },
    {
        title: <TableTitle title="已回" tips={Key2Tips['月回款']} />,
        dataIndex: 'month_income',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    {
        title: <TableTitle title="目标" tips={Key2Tips['月目标']} />,
        dataIndex: 'month_target',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    {
        title: <TableTitle title="达成率" tips={Key2Tips['月达成率']} />,
        dataIndex: 'month_completion_rate',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? `${text}%` : '-';
        },
    },
    {
        title: <TableTitle title="保守预估" tips={Key2Tips['月保守预估']} />,
        dataIndex: 'month_conservative_predict',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    // {
    //     title: (
    //         <TableTitle title="保守预估Gap" tips={Key2Tips['月保守预估Gap']} />
    //     ),
    //     dataIndex: 'month_conservative_predict_gap',
    //     render: (t: string) => {
    //         const text = Math.round(Number(t));
    //         return !_.isNaN(text) ? text : '-';
    //     },
    // },
    {
        title: (
            <TableTitle
                title="保守预估达成率"
                tips={Key2Tips['月保守预估达成率']}
            />
        ),
        dataIndex: 'month_conservative_predict_completion_rate',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? `${text}%` : '-';
        },
    },
    {
        title: <TableTitle title="冲刺预估" tips={Key2Tips['月冲刺预估']} />,
        dataIndex: 'month_spurt_predict',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    // {
    //     title: (
    //         <TableTitle title="冲刺预估Gap" tips={Key2Tips['月冲刺预估Gap']} />
    //     ),
    //     dataIndex: 'month_spurt_predict_gap',
    //     render: (t: string) => {
    //         const text = Math.round(Number(t));
    //         return !_.isNaN(text) ? text : '-';
    //     },
    // },
    {
        title: (
            <TableTitle
                title="冲刺预估达成率"
                tips={Key2Tips['月冲刺预估达成率']}
            />
        ),
        dataIndex: 'month_spurt_predict_completion_rate',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? `${text}%` : '-';
        },
    },
];

const quarterColumns = [
    {
        title: '区域',
        dataIndex: 'region',
    },
    {
        title: <TableTitle title="已回" tips={Key2Tips['双月回款']} />,
        dataIndex: 'session_income',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    {
        title: <TableTitle title="目标" tips={Key2Tips['双月目标']} />,
        dataIndex: 'session_target',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    {
        title: <TableTitle title="达成率" tips={Key2Tips['双月达成率']} />,
        dataIndex: 'session_completion_rate',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? `${text}%` : '-';
        },
    },
    {
        title: <TableTitle title="保守预估" tips={Key2Tips['双月保守预估']} />,
        dataIndex: 'session_conservative_predict',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    // {
    //     title: (
    //         <TableTitle
    //             title="保守预估Gap"
    //             tips={Key2Tips['双月度保守预估Gap']}
    //         />
    //     ),
    //     dataIndex: 'session_conservative_predict_gap',
    //     render: (t: string) => {
    //         const text = Math.round(Number(t));
    //         return !_.isNaN(text) ? text : '-';
    //     },
    // },
    {
        title: (
            <TableTitle
                title="保守预估达成率"
                tips={Key2Tips['双月保守预估达成率']}
            />
        ),
        dataIndex: 'session_conservative_predict_completion_rate',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? `${text}%` : '-';
        },
    },
    {
        title: <TableTitle title="冲刺预估" tips={Key2Tips['双月冲刺预估']} />,
        dataIndex: 'session_spurt_predict',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? text : '-';
        },
    },
    // {
    //     title: (
    //         <TableTitle title="冲刺预估Gap" tips={Key2Tips['双月冲刺预估Gap']} />
    //     ),
    //     dataIndex: 'session_spurt_predict_gap',
    //     render: (t: string) => {
    //         const text = Math.round(Number(t));
    //         return !_.isNaN(text) ? text : '-';
    //     },
    // },
    {
        title: (
            <TableTitle
                title="冲刺预估达成率"
                tips={Key2Tips['双月冲刺预估达成率']}
            />
        ),
        dataIndex: 'session_spurt_predict_completion_rate',
        render: (t: string) => {
            const text = Math.round(Number(t));
            return !_.isNaN(text) ? `${text}%` : '-';
        },
    },
];

const AchievCardColor: AchievColors[] = [
    'cyan',
    'blue',
    'orange',
    'blue',
    'cyan',
    'orange',
];

const CompositeLivePage: React.FC = () => {
    const [orgId, setOrgId] = useState(63);

    const { data: incomeData, loading } = useRequest(
        () => queryIncomeBoard(orgId),
        { refreshDeps: [orgId] },
    );

    const { data: warData, loading: warLoading } = useRequest(
        () => queryWarSituation(orgId),
        { refreshDeps: [orgId] },
    );

    const refreshTime = useMemo(() => {
        return moment().format('YYYY-MM-DD');
    }, []);

    return (
        <Spin spinning={loading}>
            <div className="composite-live-new-content">
                <BoardSwitch onChange={setOrgId} />
                {/* 业绩目标达成情况 */}
                <BoardCard
                    title="业绩目标达成情况"
                    subtitle={`时间口径：${refreshTime} 实时数据`}
                >
                    <div className="board-card-content">
                        <div
                        // style={{ marginRight: '20px' }}
                        >
                            <BigNumberCard
                                number={Math.round(
                                    (incomeData?.year_income || 0) * 10000,
                                )}
                            />
                            <div
                                className="board-card-statistics"
                                style={{ marginTop: '36px' }}
                            >
                                <div className="board-card-statistic-item">
                                    <Statistic
                                        groupSeparator={','}
                                        suffix="w"
                                        value={Math.round(
                                            incomeData?.today_income || 0,
                                        )}
                                    />
                                    <div className="board-card-statistic-label">
                                        今日回款
                                    </div>
                                </div>
                                <div className="board-card-statistic-item">
                                    <Statistic
                                        groupSeparator={','}
                                        suffix="w"
                                        value={Math.round(
                                            incomeData?.month_income || 0,
                                        )}
                                    />
                                    <div className="board-card-statistic-label">
                                        本月回款
                                    </div>
                                </div>
                                <div className="board-card-statistic-item">
                                    <Statistic
                                        groupSeparator={','}
                                        suffix="w"
                                        value={Math.round(
                                            incomeData?.session_income || 0,
                                        )}
                                    />
                                    <div className="board-card-statistic-label">
                                        本双月回款
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TopBoard top3={incomeData?.top3} />
                    </div>
                </BoardCard>

                <BoardCard title="本双月-业绩">
                    <div className="board-card-container">
                        <div className="board-card-statistics">
                            <div className="board-card-statistic-item blue">
                                <Statistic
                                    suffix="w"
                                    value={
                                        Math.round(
                                            incomeData?.session_item.income,
                                        ) || 0
                                    }
                                />
                                <div className="board-card-statistic-label">
                                    回款情况
                                </div>
                            </div>
                            <div className="board-card-statistic-item">
                                <Statistic
                                    suffix="w"
                                    value={
                                        Math.round(
                                            incomeData?.session_item.target,
                                        ) || 0
                                    }
                                />
                                <div className="board-card-statistic-label">
                                    目标
                                </div>
                            </div>
                            <div className="board-card-statistic-item">
                                <Statistic
                                    suffix="w"
                                    value={
                                        Math.round(
                                            incomeData?.session_item.gap,
                                        ) || 0
                                    }
                                />
                                <div className="board-card-statistic-label">
                                    Gap
                                </div>
                            </div>
                            <div className="board-card-statistic-item">
                                <ChartsGauge
                                    value={
                                        Number(
                                            Number(
                                                incomeData?.session_item
                                                    .completion_rate,
                                            ).toFixed(1),
                                        ) || 0
                                    }
                                    suffix={'%'}
                                    label="完成率"
                                />
                            </div>
                            <div className="board-card-statistic-item">
                                <ChartsGauge
                                    value={
                                        Number(
                                            Number(
                                                incomeData?.session_item
                                                    .time_rate,
                                            ).toFixed(1),
                                        ) || 0
                                    }
                                    suffix={'%'}
                                    color="cyan"
                                    label="时间进度"
                                />
                            </div>
                            <div className="board-card-statistic-item">
                                <ChartsGauge
                                    value={
                                        incomeData?.session_item.work_day || 0
                                    }
                                    suffix={'天'}
                                    color="orange"
                                    max={90}
                                    label="剩余工作日"
                                />
                            </div>
                        </div>
                        <div className="achiev-card-container">
                            <div className="achiev-card-wrap">
                                {incomeData?.session_item.city_infos?.map(
                                    (item: any, index: number) => (
                                        <AchievCard
                                            title={item.region}
                                            color={AchievCardColor[index]}
                                            info={item}
                                            key={item.region}
                                        />
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </BoardCard>

                {/* 本月度-业绩 */}
                <BoardCard title="本月度-业绩">
                    <div className="board-card-container">
                        <div className="board-card-statistics">
                            <div className="board-card-statistic-item blue">
                                <Statistic
                                    suffix="w"
                                    value={
                                        Math.round(
                                            incomeData?.month_item.income,
                                        ) || 0
                                    }
                                />
                                <div className="board-card-statistic-label">
                                    回款情况
                                </div>
                            </div>
                            <div className="board-card-statistic-item">
                                <Statistic
                                    suffix="w"
                                    value={
                                        Math.round(
                                            incomeData?.month_item.target,
                                        ) || 0
                                    }
                                />
                                <div className="board-card-statistic-label">
                                    目标
                                </div>
                            </div>
                            <div className="board-card-statistic-item">
                                <Statistic
                                    suffix="w"
                                    value={
                                        Math.round(
                                            incomeData?.month_item.gap,
                                        ) || 0
                                    }
                                />
                                <div className="board-card-statistic-label">
                                    Gap
                                </div>
                            </div>
                            <div className="board-card-statistic-item">
                                <ChartsGauge
                                    value={
                                        Number(
                                            Number(
                                                incomeData?.month_item
                                                    .completion_rate,
                                            ).toFixed(1),
                                        ) || 0
                                    }
                                    suffix={'%'}
                                    label="完成率"
                                />
                            </div>
                            <div className="board-card-statistic-item">
                                <ChartsGauge
                                    value={
                                        Number(
                                            Number(
                                                incomeData?.month_item
                                                    .time_rate,
                                            ).toFixed(1),
                                        ) || 0
                                    }
                                    suffix={'%'}
                                    color="cyan"
                                    label="时间进度"
                                />
                            </div>
                            <div className="board-card-statistic-item">
                                <ChartsGauge
                                    value={incomeData?.month_item.work_day || 0}
                                    suffix={'天'}
                                    color="orange"
                                    max={30}
                                    label="剩余工作日"
                                />
                            </div>
                        </div>
                        <div className="achiev-card-container">
                            <div className="achiev-card-wrap">
                                {incomeData?.month_item.city_infos?.map(
                                    (item: any, index: number) => (
                                        <AchievCard
                                            title={item.region}
                                            color={AchievCardColor[index]}
                                            info={item}
                                            key={item.region}
                                        />
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </BoardCard>

                <BoardCard
                    title={
                        <img
                            className="board-card-header-title_img"
                            src="https://i9.taou.com/maimai/p/32898/3638_6_419kwalCZCsAeVLK"
                        />
                    }
                    subtitle={
                        <span>
                            统计口径：{refreshTime}{' '}
                            实时数据，单位为万元。其中今日预估为非实时数据，是截止到昨日24时的数据；且日维度预估统计60及以上商机金额。
                            <a
                                href="https://maimai.feishu.cn/docx/DFWzd3csqoW8YLx11zQcaAb2nob"
                                target="_blank"
                            >
                                详细取数逻辑参见文档
                            </a>
                        </span>
                    }
                >
                    <div className="board-card-table-container">
                        <TableCard
                            title="今日战况 / Today"
                            columns={TadayColumns}
                            dataSource={warData}
                            loading={warLoading}
                            rowKey="region"
                            style={{ flex: 1, marginRight: '20px' }}
                        />
                        <TableCard
                            title="本周战况 / Week"
                            columns={WeekColumns}
                            dataSource={warData}
                            loading={warLoading}
                            rowKey="region"
                            style={{ flex: 2 }}
                        />
                    </div>
                    <TableCard
                        title="本月战况 / Month"
                        columns={MonthColumns}
                        dataSource={warData}
                        loading={warLoading}
                        style={{ marginBottom: 20 }}
                        rowKey="region"
                    />
                    <TableCard
                        title="双月战况 / Bimonthly"
                        columns={quarterColumns}
                        dataSource={warData}
                        loading={warLoading}
                        rowKey="region"
                    />
                </BoardCard>
            </div>
        </Spin>
    );
};

export default CompositeLivePage;
