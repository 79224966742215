import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    MOpResultDisplayContainer,
    AntButton,
    loadingWrapperOver,
} from '@/components';
import { Menu, Icon, message } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, useBeforeFirstDataLoaded, useQueryAsObject } from '@/utils';
import { observer } from 'mobx-react-lite';
import FormDemoTable from './components/table';
import { ActionTransoform } from './components/transform';
import { ActionDedupDetail } from './components/dedup-detail';
import { ActionInvalidBizType } from './components/invalid-biztype';
import { runInAction } from 'mobx';
import { dFilterHOC, useDefaultDFilterSnippets } from '@/containers/hoc';
import './index.scss';
import FormDrawer from './components/form-drawer';
import {
    useFirstLv4Menu2,
    useLv4MenuByKey,
    useFastFilterByKey,
    useFastFilterByUrl,
} from '@/stores/utils';
import { ActionExport } from '../../activation-record/list/components/export';
import { setFilterByUrlKey } from '../../../../stores/utils/filter';

const menu = (
    <Menu
        onClick={e => {
            message.info('Click on menu item.');
            console.log('click', e);
        }}
    >
        <Menu.Item key="1">
            <Icon type="user" />
            1st menu item
        </Menu.Item>
        <Menu.Item key="2">
            <Icon type="user" />
            2nd menu item
        </Menu.Item>
        <Menu.Item key="3">
            <Icon type="user" />
            3rd item
        </Menu.Item>
    </Menu>
);

const LeadList: React.FC<RouteComponentProps & {
    type: string; // 公海池，我的线索
    dfilters?: any;
}> = ({ type, dfilters }) => {
    const store = useContext(StoreContext);
    const [leadList] = useState(() => new store.LeadListStore());
    const userStore = store.getAuthStore();

    const ack = dtext('crm')('lead-list-message-' + type, '');

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        defaultPerm,
        defaultFilter,
        defaultFastFilters,
        condListbyFilterData,
    } = leadList;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const LeadListFetch = useCallback(() => leadList.fetch(), []);
    const [error, loading, reload] = useNet(LeadListFetch, {
        autoLoad: false,
        refreshKeys: ['lead'],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            defaultMeta.setTableId('lead');
            defaultMeta.setOpUsername(userStore.userInfo.userId + '-' + type);
            defaultFastFilters.setFilters(dfilters);
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {}); // 初始化元信息
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            setFilterByUrlKey(defaultFilter);
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    const condiAll = useCallback(() => type === 'all', [type]);
    useFirstLv4Menu2(defaultFastFilters, condiAll);

    const condiMine = useCallback(() => type === 'mine', [type]);
    useLv4MenuByKey(defaultFastFilters, 'buy-lead-mine-my', condiMine);
    useFastFilterByKey(defaultFastFilters, 'undeal-lead', condiMine);

    useEffect(() => {
        runInAction(() => {
            leadList.setListType(type);
            defaultFastFilters.setType(type);
        });
    }, [type]);
    useQueryAsObject(
        (queryObj: any) => {
            if (
                queryObj.fastfilter &&
                defaultFastFilters.fastFilters.length &&
                defaultFastFilters.lv4MenuFilters.length
            ) {
                // 选择快速筛选菜单，并且根据快速筛选菜单，选择对应的四级菜单
                const targetFilter = defaultFastFilters.fastFilters.find(
                    (item: any) => item.fastFilterKey === queryObj.fastfilter,
                );
                if (targetFilter) {
                    defaultFastFilters.setSelectedFastFilters([targetFilter]);
                    if (queryObj.filterKey) {
                        const lv4Menu = defaultFastFilters.lv4MenuFilters.find(
                            (item: any) => item.key === 'buy-lead-mine-private',
                        );
                        if (lv4Menu) {
                            defaultFastFilters.setSelectedLv4MenuFilters([
                                lv4Menu,
                            ]);
                        }
                    }
                } else {
                    defaultFastFilters.setSelectedFastFilters([]);
                    if (queryObj.lv4MenuFilter) {
                        const lv4Menu = defaultFastFilters.lv4MenuFilters.find(
                            (item: any) => item.key === queryObj.lv4MenuFilter,
                        );
                        if (lv4Menu) {
                            defaultFastFilters.setSelectedLv4MenuFilters([
                                lv4Menu,
                            ]);
                        }
                    }
                }
            }
        },
        [defaultFastFilters.fastFilters, defaultFastFilters.lv4MenuFilters],
    );

    const [isBeforeFirstLoaded] = useBeforeFirstDataLoaded(leadList);

    const { title, fastFilters } = useDefaultDFilterSnippets(leadList);

    // if (!preRequiredReady) {
    //     return <BlockLoading2 />;
    // }
    const finalLoading = isBeforeFirstLoaded || !preRequiredReady;

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={leadList.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = (
        <>
            <ActionExport defaultStore={leadList} />
            <MOpResultDisplayContainer defaultStore={leadList} />
            <ActionTransoform leadList={leadList} />
            <ActionDedupDetail
                leadList={leadList}
                caredLead={leadList.dupDetailLead}
            />
            <FormDrawer
                leadList={leadList}
                preRequiredReady={preRequiredReady}
                type={type}
            />
            <ActionInvalidBizType defaultStore={leadList} />
        </>
    );

    const globalOps = (
        <>
            {defaultPerm.getPermByTypeAndAction(leadList.listType)('export')
                .visible && (
                <AntButton
                    onClick={() => {
                        leadList.setAction('export');
                    }}
                    size="large"
                >
                    导出
                </AntButton>
            )}
            {defaultPerm.getPermByTypeAndAction(leadList.listType)('create')
                .visible && (
                <AntButton
                    onClick={() => {
                        runInAction(() => {
                            leadList.setAction('create');
                            leadList.startNewData();
                        });
                    }}
                    type="primary"
                    size="large"
                >
                    新建线索
                </AntButton>
            )}
        </>
    );
    const multipleOps = null;

    const dataTable = (
        <FormDemoTable
            leadList={leadList}
            loading={loading}
            type={type}
            widthInfoKey={'lead-list-' + type}
        />
    );

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters: filtersDisplay,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay: fastFilters,
    };

    return loadingWrapperOver(finalLoading)(
        <LayoutStandardCrud className="page-customer-list" {...layoutComs} />,
    );
};

const FinalLeadList = dFilterHOC(observer(LeadList), 'lead');
export default FinalLeadList;
