import React from 'react';
import Radio, { RadioProps, RadioGroupProps } from 'antd/lib/radio';
import { getCommonStatus, ICommonProps } from './common';
import _ from 'lodash';

const RadioGroup = Radio.Group;

export type RadioValueType = string;
export interface IRadioOptions extends RadioGroupProps {}

export interface IWidgetRadioProps<T = any> extends ICommonProps<T> {
    value: RadioValueType;
    options: IRadioOptions;
    onChange: (value: RadioValueType) => void;
}

export const WidgetRadio: React.FC<IWidgetRadioProps> = ({
    value,
    options,
    onChange,
    status,
    onInteract = key => void 0,
    data,
    statusExtraData,
}) => {
    const { disabled } = getCommonStatus(status, data, statusExtraData);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }
    return (
        <div
            onClick={() => {
                console.log('remove-validate-status');
                onInteract('remove-validate-status');
            }}
        >
            <RadioGroup
                value={value}
                onChange={e => {
                    if (e && e.target) {
                        onChange(e.target.value);
                        onInteract('validate-instantly', e.target.value);
                    }
                }}
                {...options}
            ></RadioGroup>
        </div>
    );
};
