import React, { useState, useEffect } from 'react';
import { ContractIncomeListStore } from '../store';
import { Icon, Spin, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { isNumeric, toNum } from '@/utils';
import _ from 'lodash';

export const IncomeExtra: React.FC<{
    defaultStore: ContractIncomeListStore;
}> = observer(({ defaultStore }) => {
    const { extraStatisticResult } = defaultStore;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        defaultStore.setExtraStatisticResuilt(null);
    }, [defaultStore.condListbyFilterData]);

    return (
        <Tooltip
            title={
                extraStatisticResult ? '刷新回款金额合计' : '加载回款金额合计'
            }
        >
            <span
                style={{
                    cursor: 'pointer',
                    fontSize: 14,
                    color: '#0052ff',
                    fontWeight: 'bolder',
                }}
                onClick={async () => {
                    setLoading(true);
                    await defaultStore.getExtraStatistic();
                    setLoading(false);
                }}
            >
                <div
                    style={{
                        backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/mis/money.png')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100%',
                        height: 14,
                        width: 14,
                        marginRight: 4,
                        display: 'inline-block',
                        position: 'relative',
                        top: '2px',
                    }}
                />
                回款金额合计
                {!_.isNil(extraStatisticResult) ? (
                    <>
                        （元）：
                        {isNumeric(extraStatisticResult)
                            ? toNum(extraStatisticResult).toLocaleString()
                            : extraStatisticResult}
                    </>
                ) : null}
                &nbsp;{' '}
                {loading ? (
                    <Icon type="loading" style={{ color: 'blue' }} />
                ) : null}
            </span>
        </Tooltip>
    );
});
