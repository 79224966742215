import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AntButton, BlockLoading2, loadingWrapper } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { AntDrawer } from '@/components/antd/drawer';
import { defaultAuthDeco } from '@/containers/hoc';
import { ProcessCatStore } from '../store';
import DemoForm, { transferSingleParamList } from './form';
import { ProcessComHub } from './processes/registry';
import { tryRefresh, delay, defaultAxios, isNetSuccess } from '@/utils';
import { Form, Radio, message, Modal } from 'antd';
import RadioGroup from 'antd/lib/radio/group';
import { runInAction } from 'mobx';
import { ProcessButtons, TBtnsCusRenders } from './buttons';
import { ContractFormProxy } from './contract-proxy';
import { ContractIQForm } from './contract-iq-form';
import { ContractInvoiceForm } from './contract-invoice-form';
import {
    openProductApplyChangeToMutatingData,
    changeAdminGetRequiredAndHiddenParams,
    changeAdminRequiredResult,
} from './utils';
import { InvoiceQualificationListStore } from '@/pages/om/contract/contract-invoice-qualification/store';
import moment from 'moment';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const FormDrawer: React.FC<RouteComponentProps & {
    defaultStore: ProcessCatStore;
    preRequiredReady: boolean;
    autoNext?: boolean;
    keepState?: boolean;
    lifeCycle?: {
        onOpen: (store: ProcessCatStore) => void;
        onClose: (store: ProcessCatStore) => void;
    };
}> = ({ defaultStore, preRequiredReady, autoNext, keepState, lifeCycle }) => {
    const {
        actionType,
        bigFormVisible,
        mutatingDataLoading,
        defaultFastFilters,
        defaultMeta,
    } = defaultStore;

    autoNext = _.isUndefined(autoNext) ? false : autoNext;
    keepState = _.isUndefined(keepState) ? false : keepState;

    // 根据processBtns渲染对应的按钮

    const { innerlv4MenuFilters, selectedLv4MenuFilters } = defaultFastFilters;
    const lv4MenuFilters = innerlv4MenuFilters.category;

    const [step, setStep] = useState(0);
    const [selectedProcesstype, setSelectedProcesstype] = useState(
        defaultStore.tableCode || null,
    );
    const [createLoading, setCreateLoading] = useState(false);
    const selectCateItem: any = _.isArray(lv4MenuFilters)
        ? lv4MenuFilters.find((item: any) => item.key === selectedProcesstype)
        : undefined;

    const [
        createProcessStore,
        setCreateProcessStore,
    ] = useState<ProcessCatStore | null>(null);
    const [ready, setReady] = useState(false);

    let processCtx = null;
    let processAllInfo = null;
    // let processBtns: any[] = [];
    let processActivityName = '';
    const processBtns = useMemo(() => {
        const processBtns = createProcessStore?.defaultMeta?.processBtns || [];
        return processBtns;
    }, [
        createProcessStore,
        createProcessStore?.defaultMeta,
        createProcessStore?.defaultMeta?.processBtns,
    ]);
    if (createProcessStore) {
        // 这个ctx在dform内部指导流程配置对表单的影响
        const selectedFilter = lv4MenuFilters.find(
            (item: any) => item?.originalItem?.formKey === selectedProcesstype,
        );
        processCtx = defaultStore.getProcessCreateCtx(
            defaultStore.sysUser,
            selectedFilter ? [selectedFilter] : [],
        );

        processAllInfo = createProcessStore.defaultMeta?.processAllInfo;
        // processBtns = createProcessStore.defaultMeta?.processBtns || [];

        processActivityName =
            null === processAllInfo
                ? ''
                : processAllInfo?.crmActivity?.activityName;
    }

    // onOpen
    useEffect(() => {
        if (defaultStore.actionType !== 'create') {
            return;
        }
        if (createProcessStore === null || !ready || !preRequiredReady) {
            return;
        }
        const cb = lifeCycle?.onOpen;
        if (cb) {
            cb(createProcessStore);
        }
    }, [ready, defaultStore.actionType, createProcessStore, lifeCycle?.onOpen]);

    // onClose
    const onClose = () => {
        if (defaultStore.actionType !== '') {
            return;
        }
        if (createProcessStore === null || !ready || !preRequiredReady) {
            return;
        }
        const cb = lifeCycle?.onClose;
        if (cb) {
            cb(createProcessStore);
        }
    };

    // 不太好
    useEffect(() => {
        setSelectedProcesstype(defaultStore.tableCode);
    }, [defaultStore.tableCode]);

    const resetStates = () => {
        setStep(0);
        setSelectedProcesstype(defaultStore.tableCode);
        setCreateProcessStore(null);
        setReady(false);
    };

    useEffect(() => {
        if (_.isNil(createProcessStore)) {
            return;
        }
        const {
            tableCode,
            defaultMeta,
            defaultPerm,
            defaultFilter,
        } = createProcessStore;
        if (
            _.isNil(tableCode) ||
            _.isNil(defaultMeta) ||
            _.isNil(defaultPerm)
        ) {
            return;
        }
        defaultMeta.setTableId(tableCode);
        createProcessStore.setSysUser(defaultStore.sysUser);

        Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
            .then(async () => {
                setReady(true);
                defaultFilter.resetFilterData();
                defaultFilter.confirmFilterData();
                createProcessStore.startNewData();
            })
            .catch(() => {});
    }, [createProcessStore, defaultStore.sysUser]);

    const goNext = async () => {
        if (!selectedProcesstype) {
            return;
        }
        setStep(1);
        const nextStore = new ProcessCatStore();
        runInAction(() => {
            nextStore.setFastFilterStore(defaultStore.defaultFastFilters);
            nextStore.setAction('create');
            nextStore.setTableCode(selectedProcesstype);
        });
        setCreateProcessStore(nextStore);
    };

    useEffect(() => {
        if (!autoNext) {
            return;
        }
        goNext();
    }, [selectedProcesstype, autoNext]);

    // DIRTY: 开发票申请
    // const [
    //     iqStore,
    //     setIqStore,
    // ] = useState<InvoiceQualificationListStore | null>(null);

    if (createProcessStore) {
        const { mutatingData: createMutatingData } = createProcessStore;
        console.log('createMutatingData', createMutatingData);
    }

    let text = '';
    if (step === 0) {
        text = '选择流程类型';
    } else if (step === 1) {
        if (ready) {
        }
        text = processActivityName ? processActivityName : ``;
    }
    const [customRender, setCustomRender] = useState<TBtnsCusRenders>({});
    useEffect(() => {
        let customRender: TBtnsCusRenders = {};

        const { mutatingData } = createProcessStore || {};
        if (selectedProcesstype === 'process_customer_delay_table') {
            const defaultCustomRender: TBtnsCusRenders = {};
            processBtns.forEach((btn: string) => {
                if (btn === 'SUBMIT') {
                    defaultCustomRender[btn] = {
                        disabled: true,
                        children: '提交',
                    };
                }
            });
            setCustomRender(defaultCustomRender);
            if (
                mutatingData?.customer_id?.relateObj?.cooperation_status === 2
            ) {
                return;
            }
            if (mutatingData?.customer_id?.originalValue) {
                defaultAxios
                    .put(
                        '/crm/system/highsea/recycle/customer/' +
                            mutatingData?.customer_id?.originalValue,
                    )
                    .then(([d, e]) => {
                        if (!_.isNil(e) || _.isNil(d)) {
                            message.error('请求超时，请稍后重试');
                            return;
                        }
                        message.destroy();
                        let expireTime: number;
                        if (d.data) {
                            if (d.data.expireTime) {
                                expireTime = moment(
                                    d.data.expireTime,
                                ).valueOf();
                            } else {
                                expireTime = -1;
                            }
                        } else {
                            expireTime = 0;
                        }
                        const preTime = moment(expireTime)
                            .add(mutatingData?.delay_days || 30, 'day')
                            .valueOf();
                        processBtns.forEach((btn: string) => {
                            if (btn === 'SUBMIT') {
                                const disabled =
                                    expireTime === 0 || expireTime === -1
                                        ? true
                                        : preTime <= moment().valueOf()
                                        ? true
                                        : false;
                                customRender[btn] = {
                                    disabled,
                                    children: '提交',
                                };
                            }
                        });
                        setCustomRender(customRender);
                    });
            }
        }
    }, [
        createProcessStore?.mutatingData,
        createProcessStore?.mutatingData?.customer_id,
        selectedProcesstype,
        processBtns,
    ]);

    const popups = (
        <>
            {/* process_contract_invoice_table 合同开发票申请对这个代码结构破坏很大 */}
            {/* {selectedProcesstype === 'process_contract_invoice_table' && (
                <ContractIQForm
                    preRequiredReady={preRequiredReady}
                    onIQStoreChange={setIqStore}
                    defaultStore={createProcessStore}
                />
            )} */}
            <AntDrawer
                title={text}
                placement={'right'}
                visible={bigFormVisible}
                onClose={() => {
                    defaultStore.resetAction();
                    onClose();
                    if (keepState) {
                        return;
                    }
                    resetStates();
                }}
                bodyStyle={{
                    width: 950,
                    overflow: 'scroll',
                }}
                width={950 + 'px'}
            >
                <div className="standard-drawer-form-wrapper">
                    {!preRequiredReady && <BlockLoading2 />}
                    {preRequiredReady && step === 0 ? (
                        <div>
                            {/* <Form>
                                <Form.Item
                                    {...formItemLayout}
                                    label="选择流程类型"
                                > */}
                            <div style={{ paddingTop: 8, paddingLeft: 16 }}>
                                <RadioGroup
                                    value={selectedProcesstype}
                                    onChange={e => {
                                        setSelectedProcesstype(e.target.value);
                                    }}
                                >
                                    {lv4MenuFilters.map(item => {
                                        item = item || {};
                                        const { key, label } = item;
                                        const active =
                                            key === selectedProcesstype;

                                        // 在流程侧封闭入口
                                        if (
                                            key ===
                                            'process_contract_product_open_table'
                                        ) {
                                            return null;
                                        }
                                        return (
                                            <div
                                                style={{
                                                    padding: '6px 16px',
                                                    backgroundColor: active
                                                        ? '#0052ff'
                                                        : 'rgb(244, 245, 250)',
                                                    borderRadius: 6,
                                                    marginRight: 8,
                                                    marginBottom: 8,
                                                    display: 'inline-block',
                                                }}
                                            >
                                                <Radio
                                                    key={
                                                        active
                                                            ? 'active'
                                                            : 'noneactive'
                                                    }
                                                    value={key}
                                                >
                                                    <span
                                                        style={{
                                                            color: active
                                                                ? 'white'
                                                                : 'black',
                                                        }}
                                                    >
                                                        {label}
                                                    </span>
                                                </Radio>
                                            </div>
                                        );
                                    })}
                                </RadioGroup>
                            </div>
                            {/* </Form.Item>
                            </Form> */}
                        </div>
                    ) : null}
                    {preRequiredReady && step === 1 ? (
                        !ready || createProcessStore === null ? (
                            <BlockLoading2 />
                        ) : (
                            <>
                                {selectedProcesstype === 'contract' ? (
                                    <ContractFormProxy
                                        processCtx={processCtx}
                                        defaultStore={createProcessStore}
                                    />
                                ) : null}
                                {/* 进这了 */}
                                {['contract'].indexOf(
                                    selectedProcesstype || '',
                                ) === -1 ? (
                                    <ProcessComHub
                                        processCtx={processCtx}
                                        defaultStore={createProcessStore}
                                        tableCode={selectedProcesstype}
                                        mode={'create'}
                                    />
                                ) : null}
                            </>
                        )
                    ) : null}
                    {preRequiredReady && (
                        <div className="fixed-form-btns">
                            {step === 0 ? (
                                <AntButton
                                    size="large"
                                    type="primary"
                                    onClick={() => {
                                        if (!selectedProcesstype) {
                                            return message.error(
                                                '请选择流程类型',
                                            );
                                        }
                                        goNext();
                                    }}
                                >
                                    下一步
                                </AntButton>
                            ) : null}
                            {step === 1
                                ? loadingWrapper(createLoading)(
                                      <>
                                          <ProcessButtons
                                              buttons={processBtns}
                                              // 控制
                                              customRenders={customRender}
                                              onButtonClick={async type => {
                                                  let isSuccess = false;
                                                  if (type === 'SUBMIT') {
                                                      if (!createProcessStore) {
                                                          return;
                                                      }
                                                      const applicant =
                                                          defaultStore.sysUser
                                                              ?.userId;
                                                      const businessInfo = _.cloneDeep(
                                                          createProcessStore.mutatingData,
                                                      );
                                                      const processDefinitionId =
                                                          selectCateItem
                                                              ?.originalItem
                                                              ?.id;

                                                      if (
                                                          !applicant ||
                                                          !businessInfo ||
                                                          !processDefinitionId
                                                      ) {
                                                          return;
                                                      }

                                                      if (
                                                          selectedProcesstype ===
                                                              'process_customer_transfer_table' &&
                                                          +businessInfo?.transfer_type !==
                                                              1
                                                      ) {
                                                          for (const key of transferSingleParamList) {
                                                              businessInfo[
                                                                  key
                                                              ] = null;
                                                          }
                                                      }

                                                      if (
                                                          selectedProcesstype ===
                                                          'process_customer_delay_table'
                                                      ) {
                                                          const customerId =
                                                              businessInfo
                                                                  ?.customer_id
                                                                  ?.originalValue;
                                                          const [
                                                              d,
                                                              e,
                                                          ] = await defaultAxios.get(
                                                              '/crm/customer/delay/remains',
                                                              { customerId },
                                                              { silent: true },
                                                          );
                                                          if (e) {
                                                              const confirmed = await new Promise(
                                                                  (
                                                                      resolve,
                                                                      reject,
                                                                  ) => {
                                                                      Modal.confirm(
                                                                          {
                                                                              title:
                                                                                  e.message,
                                                                              onOk: () =>
                                                                                  resolve(
                                                                                      true,
                                                                                  ),
                                                                              onCancel: () =>
                                                                                  resolve(
                                                                                      false,
                                                                                  ),
                                                                          },
                                                                      );
                                                                  },
                                                              );
                                                              if (!confirmed) {
                                                                  return false;
                                                              }
                                                          } else if (
                                                              _.isNumber(
                                                                  d?.data,
                                                              )
                                                          ) {
                                                              businessInfo.remaining_times =
                                                                  d?.data;
                                                          }
                                                      }

                                                      if (
                                                          selectedProcesstype ===
                                                          'process_contract_product_open_table'
                                                      ) {
                                                          if (
                                                              _.isString(
                                                                  businessInfo?.cidDiffLint,
                                                              )
                                                          ) {
                                                              const confirmed = await new Promise(
                                                                  (
                                                                      resolve,
                                                                      reject,
                                                                  ) => {
                                                                      Modal.confirm(
                                                                          {
                                                                              title:
                                                                                  businessInfo?.cidDiffLint,
                                                                              onOk: () =>
                                                                                  resolve(
                                                                                      true,
                                                                                  ),
                                                                              onCancel: () =>
                                                                                  resolve(
                                                                                      false,
                                                                                  ),
                                                                          },
                                                                      );
                                                                  },
                                                              );
                                                              if (!confirmed) {
                                                                  return;
                                                              }
                                                          }

                                                          const openCustomerId =
                                                              createProcessStore
                                                                  .mutatingData
                                                                  ?.open_customer_id
                                                                  ?.originalValue;
                                                          const customerId =
                                                              createProcessStore
                                                                  .mutatingData
                                                                  ?.customer_id
                                                                  ?.originalValue;
                                                          const [
                                                              d,
                                                              e,
                                                          ] = await defaultAxios.get(
                                                              '/crm/customer/relation/check',
                                                              {
                                                                  customerId,
                                                                  openCustomerId,
                                                              },
                                                          );

                                                          if (
                                                              !isNetSuccess(
                                                                  d,
                                                                  e,
                                                              )
                                                          ) {
                                                              message.error(
                                                                  '预校验失败',
                                                              );
                                                              return;
                                                          }

                                                          if (
                                                              d?.data === false
                                                          ) {
                                                              const confirmed = await new Promise(
                                                                  (
                                                                      resolve,
                                                                      reject,
                                                                  ) => {
                                                                      Modal.confirm(
                                                                          {
                                                                              title:
                                                                                  '签约公司和开通公司不同且不是关联客户，如需开通请确认已上传相关证明文件。',
                                                                              onOk: () =>
                                                                                  resolve(
                                                                                      true,
                                                                                  ),
                                                                              onCancel: () =>
                                                                                  resolve(
                                                                                      false,
                                                                                  ),
                                                                              okText:
                                                                                  '继续开通',
                                                                          },
                                                                      );
                                                                  },
                                                              );
                                                              if (!confirmed) {
                                                                  return;
                                                              }
                                                          }

                                                          openProductApplyChangeToMutatingData(
                                                              businessInfo,
                                                              createProcessStore.defaultMeta,
                                                          );
                                                      }

                                                      if (
                                                          selectedProcesstype ===
                                                          'process_customer_admin_change_table'
                                                      ) {
                                                          if (
                                                              businessInfo.admin_register ===
                                                              0
                                                          ) {
                                                              message.error(
                                                                  '管理员必须是脉脉注册用户',
                                                              );
                                                              return;
                                                          }

                                                          // require信息验证
                                                          const {
                                                              requiredParams,
                                                          } = changeAdminGetRequiredAndHiddenParams(
                                                              businessInfo.product_type,
                                                              businessInfo.change_type,
                                                          );
                                                          const {
                                                              result,
                                                          } = changeAdminRequiredResult(
                                                              requiredParams,
                                                              businessInfo,
                                                          );
                                                          if (!result) {
                                                              return;
                                                          }
                                                      }

                                                      //   if (
                                                      //       selectedProcesstype ===
                                                      //       'process_replace_payment_table'
                                                      //   ) {
                                                      //       if (
                                                      //           [3, 4].indexOf(
                                                      //               businessInfo.invoice_type,
                                                      //           ) > -1
                                                      //       ) {
                                                      //           message.error(
                                                      //               '不支持所选票款类型',
                                                      //           );
                                                      //           return;
                                                      //       }
                                                      //   }

                                                      if (
                                                          selectedProcesstype ===
                                                          'process_product_give_open_table'
                                                      ) {
                                                          // product_version
                                                          // enterprise_slogan
                                                          if (
                                                              businessInfo?.product_version ===
                                                                  1 &&
                                                              _.trim(
                                                                  businessInfo?.enterprise_slogan,
                                                              ) === ''
                                                          ) {
                                                              message.error(
                                                                  '产品版本为付费版时，企业号-slogan必填',
                                                              );
                                                              return;
                                                          }

                                                          if (
                                                              businessInfo?.apply_type ===
                                                                  2 &&
                                                              businessInfo?.operate_duration &&
                                                              !(
                                                                  businessInfo?.operate_duration >=
                                                                      1 &&
                                                                  businessInfo?.operate_duration <=
                                                                      3
                                                              )
                                                          ) {
                                                              message.error(
                                                                  '申请类型为试用时，服务周期的值要大于0小于等于3',
                                                              );
                                                              return;
                                                          }
                                                      }

                                                      //   if (
                                                      //       selectedProcesstype ===
                                                      //       'process_contract_invoice_table'
                                                      //   ) {
                                                      //       if (
                                                      //           !createProcessStore.hasInvoiceQualification
                                                      //       ) {
                                                      //           message.error(
                                                      //               '请创建客户开票资质',
                                                      //           );
                                                      //           return;
                                                      //       }
                                                      //   }

                                                      setCreateLoading(true);
                                                      isSuccess = await defaultStore.startProcess(
                                                          {
                                                              applicant,
                                                              businessInfo,
                                                              processDefinitionId,
                                                          },
                                                          selectedProcesstype,
                                                          createProcessStore,
                                                      );
                                                      setCreateLoading(false);
                                                  }

                                                  if (isSuccess) {
                                                      tryRefresh([
                                                          defaultStore.tableCode ||
                                                              '',
                                                          'raw-process',
                                                      ]);

                                                      defaultStore.resetAction();
                                                      if (keepState) {
                                                          return;
                                                      }
                                                      resetStates();
                                                  }
                                              }}
                                          />
                                          <AntButton
                                              size="large"
                                              onClick={() => {
                                                  defaultStore.resetAction();
                                                  onClose();
                                                  if (keepState) {
                                                      return;
                                                  }
                                                  resetStates();
                                              }}
                                          >
                                              取消
                                          </AntButton>
                                      </>,
                                  )
                                : null}
                        </div>
                    )}
                </div>
            </AntDrawer>
        </>
    );

    return popups;
};

const FinalFormDrawer = observer(FormDrawer);
export default FinalFormDrawer;
