import React, { memo, useContext, useEffect, useState } from 'react';
import {
    AntButton,
    applyCustomConfToWidget,
    loadingWrapper,
    DForm,
    isMultipleCell,
    applyConfToFormItem,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CustomerListStore } from '../store';
import {
    IInputSearchableOptions,
    ISelectProductRightOptions,
} from '@/components/common/form/widgets';
import { Alert } from 'antd';
import { action } from 'mobx';
import { CtxProvider } from '@/utils/context';
import { StoreContext } from '@/stores';
import { getValidatorByParamMeta } from '@/stores/utils';

const FormDemoFilter: React.FC<{
    customerList: CustomerListStore;
    type: string;
}> = ({ customerList, type }) => {
    const store = useContext(StoreContext);
    const userStore = store.getAuthStore();
    const [customerNameBlur, setCustomerNameBlur] = useState(false);

    const supportedTypes =
        userStore.userInfo.supportedBizTypes?.['customer']?.businessTypeIds ||
        [];
    const supportedTypesSet = new Set(supportedTypes);
    useEffect(() => {
        if (supportedTypes.length === 1 && customerList.mutatingData) {
            customerList.mutatingData.biz_type = supportedTypes[0];
        }
    }, [supportedTypes, customerList.mutatingData]);

    const {
        autoFillingLoading,
        suggestionCustomer,
        actionType,
        updateMode,
        defaultMeta,
    } = customerList;

    useEffect(() => {
        if (!actionType) {
            setCustomerNameBlur(false);
        }
    }, [actionType]);

    const transStand = 'trans-standard' === updateMode;

    let eventCode = '';
    if (actionType === 'create') {
        eventCode = 'CREATE-EDIT';
    } else if (actionType === 'update') {
        if (type === 'all') {
            eventCode = 'PUBLIC-EDIT';
        } else if (type === 'mine') {
            eventCode = 'PRIVATE-EDIT';
        }
    }

    if (eventCode === '') {
        return null;
    }

    const form = (
        <CtxProvider
            ctx={{ position: 'form', positionDetail: 'customer-form' }}
        >
            <DForm
                eventCode={eventCode}
                defaultStore={customerList}
                options={{
                    grouped: true,
                }}
                onInteract={(key, type, value) => {
                    if (
                        key === 'customer_name' &&
                        type === 'input-outside-clicked'
                    ) {
                        const customerName =
                            customerList.mutatingData?.customer_name;
                        if (
                            customerName &&
                            customerList.autoFillingByCName?.customer_name
                                ?.value !== customerName &&
                            customerList.actionType === 'create'
                        ) {
                            customerList.autoFillOtherParamsByCustomerName(
                                customerName,
                                customerList.mutatingData?.biz_type,
                            );
                            setCustomerNameBlur(true);
                        }
                    }
                }}
                onChange={(key, value, k) => {
                    if (key === 'state') {
                        const mutatingData = customerList.mutatingData;
                        if (value) {
                            if (
                                !mutatingData.state ||
                                mutatingData.state.originalValue !==
                                    value.originalValue
                            ) {
                                mutatingData.city = null;
                                mutatingData.district = null;
                            }
                        }
                    }
                    if (key === 'city') {
                        const mutatingData = customerList.mutatingData;
                        if (value) {
                            if (
                                !mutatingData.city ||
                                mutatingData.city.originalValue !==
                                    value.originalValue
                            ) {
                                mutatingData.district = null;
                            }
                        }
                    }
                    if (key === 'biz_type') {
                        const customerName =
                            customerList.mutatingData?.customer_name;
                        const bizType = value;
                        customerList.autoFillOtherParamsByCustomerName(
                            customerName,
                            bizType,
                        );
                    }
                }}
                widgetsTransformer={baiscWidgets => {
                    applyConfToFormItem(baiscWidgets, 'inputSecret', {
                        sceneCode: 'crm_customer',
                    });
                    applyCustomConfToWidget(
                        baiscWidgets,
                        'parent_customer_id',
                        prevWidget => {
                            if (prevWidget.objectOptions) {
                                prevWidget.objectOptions.fieldAuthCode =
                                    'PRIVATE-VIEW';
                                prevWidget.objectOptions.dataAuthCode =
                                    'SEARCH-PRIVATE';
                            }
                            return prevWidget;
                        },
                    );

                    // applyCustomConfToWidget(
                    //     baiscWidgets,
                    //     'parent_customer_id',
                    //     prevWidget => {
                    //         const nextWidget = _.cloneDeep(prevWidget);
                    //         nextWidget.status = {
                    //             ...nextWidget.status,
                    //             disabled: true,
                    //         };
                    //         return nextWidget;
                    //     },
                    // );

                    // 自定义组件
                    applyCustomConfToWidget(
                        baiscWidgets,
                        'customer_name',
                        prevWidget => {
                            const nextWidget = _.cloneDeep(prevWidget);
                            nextWidget.widgetKey = 'inputSearchable';

                            const isCreate =
                                customerList.actionType === 'create';
                            nextWidget.status = {
                                loading: () => {
                                    return autoFillingLoading;
                                },
                                disabled: !isCreate || customerNameBlur,
                            };

                            const nextOptions: IInputSearchableOptions = _.assign(
                                {},
                                nextWidget.inputOptions,
                                {
                                    api: '/crm/customer/searchCompanyName',
                                    searchQueryKey: 'companyName',
                                },
                            );

                            // TODO: 如果存在一个回填，不等于当前填写公司名
                            // 则给一个suggestion，让用户使用回填公司名，然后再触发一次回填
                            if (
                                _.isNil(suggestionCustomer) ||
                                _.isNil(customerList?.mutatingData?.biz_type)
                            ) {
                                delete nextWidget.nextlineWidget;
                            } else {
                                nextWidget.nextlineWidget = () => (
                                    <div style={{ padding: 5 }}>
                                        {loadingWrapper(autoFillingLoading)(
                                            <Alert
                                                type="info"
                                                message={
                                                    <div>
                                                        <p>
                                                            推荐公司名称：【
                                                            {
                                                                suggestionCustomer.customer_name
                                                            }
                                                            】
                                                        </p>
                                                        <div>
                                                            <AntButton
                                                                onClick={() =>
                                                                    customerList.useSuggestionCustomer()
                                                                }
                                                                type="primary"
                                                            >
                                                                使用推荐公司名称
                                                            </AntButton>
                                                        </div>
                                                    </div>
                                                }
                                            />,
                                        )}
                                    </div>
                                );
                            }
                            nextWidget.inputSearchableOptions = nextOptions;
                            return nextWidget;
                        },
                    );

                    applyCustomConfToWidget(
                        baiscWidgets,
                        'customer_business_card',
                        prevWidget => {
                            const nextWidget = _.cloneDeep(prevWidget);
                            nextWidget.widgetKey = 'upload';
                            nextWidget.uploadOptions = {
                                multiple: true,
                                fileExtWhitelist: ['jpg', 'png', 'jpeg'],
                                lint:
                                    '附件要求:2M以内,支持文件格式为jpg,png,jpeg',
                                limit: 2 * 1024 * 1024,
                                overlimitLint: '名片大小不能超过2M',
                            };
                            return nextWidget;
                        },
                    );

                    applyCustomConfToWidget(
                        baiscWidgets,
                        'source',
                        nextWidget => {
                            nextWidget.status = {
                                ...nextWidget.status,
                                disabled: true,
                            };
                            return nextWidget;
                        },
                    );

                    applyCustomConfToWidget(
                        baiscWidgets,
                        'biz_type',
                        nextWidget => {
                            if (nextWidget.selectOptions) {
                                nextWidget.selectOptions.options = nextWidget.selectOptions.options.filter(
                                    item => {
                                        return supportedTypesSet.has(
                                            +item.value,
                                        );
                                    },
                                );
                            }
                            return nextWidget;
                        },
                    );

                    return baiscWidgets;
                }}
                validatorsTransformer={originalValidators => {
                    const nextValidators = { ...originalValidators };
                    const key = 'sub_source';
                    const paramsMeta = defaultMeta.objectMetaMap[key];
                    if (paramsMeta) {
                        paramsMeta.isRequired = 1;
                        nextValidators[key] = getValidatorByParamMeta(
                            paramsMeta,
                        );
                    }

                    return nextValidators;
                }}
            />
        </CtxProvider>
    );
    return form;
};

const FinalFormDemoFilter = memo(observer(FormDemoFilter));
export default FinalFormDemoFilter;
