import React from 'react';
import { PaginationStore } from '@/stores/utils';
import { Button, Modal, Alert, message } from 'antd';
import { AntButton } from '@/components';
import { observer } from 'mobx-react-lite';
import { defaultAxios, isNetSuccess } from '@/utils';
import _ from 'lodash';

export const ActionExport: React.FC<{
    defaultStore: {
        downloadUrl: string;
        defaultPagination: PaginationStore;
        actionType: string;
        resetAction: () => void;
    };
    threshold?: number;
}> = observer(({ defaultStore, threshold = 50000 }) => {
    const visible = defaultStore.actionType === 'export';
    const amount = defaultStore.defaultPagination.amount;
    const ok = amount <= threshold;
    return (
        <Modal
            visible={visible}
            title={'导出'}
            cancelText={null}
            onCancel={() => {
                defaultStore.resetAction();
            }}
            footer={[
                <Button
                    style={{ marginRight: 8 }}
                    onClick={async () => {
                        defaultStore.resetAction();
                    }}
                >
                    关闭
                </Button>,
                ok ? (
                    // <a href={defaultStore.downloadUrl} target="__blank">
                    //     <Button
                    //         onClick={async () => {
                    //             defaultStore.resetAction();
                    //         }}
                    //         type="primary"
                    //     >
                    //         导出
                    //     </Button>
                    // </a>
                    <Button
                        onClick={async () => {
                            try {
                                const tmp = defaultStore.downloadUrl.split(
                                    '?q=',
                                )[1];
                                const body = JSON.parse(
                                    decodeURIComponent(tmp.split('&hash')[0]),
                                );
                                defaultAxios
                                    .post('/bff/api/rest/export/v2/pre', body)
                                    .then(([d, e]) => {
                                        if (
                                            isNetSuccess(d, e) &&
                                            _.isString(d?.data)
                                        ) {
                                            window.open(
                                                window.location.origin +
                                                    '/bff/api/rest/export/v2?exportKey=' +
                                                    d?.data,
                                            );
                                            defaultStore.resetAction();
                                        } else {
                                            message.error(
                                                '导出请求出错，请联系管理员',
                                            );
                                        }
                                    });
                            } catch (e) {
                                message.error('导出请求出错，请联系管理员');
                                return false;
                            }
                            defaultStore.resetAction();
                        }}
                        type="primary"
                    >
                        导出
                    </Button>
                ) : (
                    <Button type="primary" disabled={true}>
                        导出
                    </Button>
                ),
            ]}
            zIndex={10001}
        >
            {ok ? (
                <Alert
                    message={`确认导出？（当前条件共${amount}条）`}
                    type="info"
                ></Alert>
            ) : (
                <Alert
                    message={`最多支持导出${threshold}条（当前条件共${amount}条），请更换筛选条件再导出`}
                    type="error"
                ></Alert>
            )}
        </Modal>
    );
});
