import { SelectProps, SelectValue } from 'antd/lib/select';
import { Select, Icon, Modal, Input, Checkbox } from 'antd';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { getCommonStatus, ICommonProps } from './common';
import { loadingWrapper } from '@/components';
import _ from 'lodash';
import { transparentQuery } from '@/api/rest';
import {
    arrToJson,
    arrToJsonPrimary,
    defaultAxios,
    isNetSuccess,
} from '@/utils';
import { ListMeta } from '@/stores/utils';
import { TableLv2, applyParamListToColumns } from '../../table';
import { toJS, autorun } from 'mobx';
import './object.scss';
import { CtxContext } from '@/utils/context';
import { REDIRECT_INFO_KEY } from '@/configs/auth';
const Option = Select.Option;

/**
 * TODO: 多选
 */

export type CommonObjectValueType = any;

export interface ICommonObjectOptions extends SelectProps {
    pageSize?: number;
    dataAuthCode?: string;
    fieldAuthCode?: string;
    entityCode?: string;
    // 后端给的约束配置
    constraint: any; // magic...
    // {
    //     "fieldConstraintType": "obj",
    //     "constraintItemList": null,
    //     "constraintObj": {
    //         "constraintObj": 1,
    //         "objShowFieldId": 2,
    //         "objFindFieldIdList": [
    //             3,
    //             2,
    //             1
    //         ],
    //         "objSaveFieldId": 3
    //     }
    // 从bff返回给前端，前端爱怎么折腾都可以
    // objFullMeta
    // objSaveField
    // objShowField
    // }
    //
    listMeta: any;
    dataSource?: { api?: string };
    multiple?: boolean;
    modalVisibleTrigger?: number;
    paramsBlackList?: { [key: string]: boolean };
    defaultFilters?: any[];
    reloadTrigger?: any;
    clearable?: boolean;
    listClearTrigger?: any;

    onGotMeta?: (meta: any[]) => void;

    innerFilterChanger?: React.FC<any>;
}

export interface IWidgetCommonObjectProps extends ICommonProps {
    value: CommonObjectValueType;
    // 初始值，可以是空
    // {
    //     "type": "obj",
    //     "originalValue": 12,
    //     "relatedObj": {
    //         "id": 12,
    //         "name": "北京淘友天下科技发展有限公司test1113",
    //         "short_name": "100020"
    //     }
    // }
    options: ICommonObjectOptions;
    onChange: (val: CommonObjectValueType) => void;
    onBlur?: () => void;
    onFocus?: () => void;
}

const cache = new WeakMap();

export const WidgetCommonObject: (
    props: IWidgetCommonObjectProps,
) => React.ReactElement<CommonObjectValueType> = ({
    k: key,
    value,
    options,
    onChange,
    onBlur = () => {},
    onFocus = () => {},
    status,
    data,
    statusExtraData,
    onInteract = key => void 0,
}) => {
    const { loading, disabled } = getCommonStatus(
        status,
        data,
        statusExtraData,
    );
    const [requestCount] = useState({ v: 0 });
    const ctx = useContext(CtxContext);
    const underCustomerForm = ctx.positionDetail === 'customer-form';

    const wrapper = loadingWrapper(loading || false);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }

    let {
        constraint,
        pageSize,
        listMeta,
        dataSource,
        multiple,
        dataAuthCode,
        fieldAuthCode,
        entityCode,
        paramsBlackList,
        defaultFilters,
        reloadTrigger,
        listClearTrigger,
        clearable = true,
        innerFilterChanger,
    } = options;
    dataSource = dataSource || {};
    listMeta = listMeta || {};
    constraint = constraint || {};
    dataAuthCode = dataAuthCode || 'SEARCH-ALL'; // 'SEARCH-PRIVATE';
    fieldAuthCode = fieldAuthCode || ''; // 'PRIVATE-VIEW';
    entityCode = entityCode || ''; // 'PRIVATE-VIEW';
    multiple = multiple === undefined ? false : multiple;
    paramsBlackList = { ...(paramsBlackList || {}), id: true };

    const composedValue = value || null;

    const getFinalValue = useCallback(() => {
        let finalValue: CommonObjectValueType;
        if (multiple === false) {
            finalValue =
                composedValue?.originalValue === -1
                    ? undefined
                    : composedValue?.originalValue;
        } else if (_.isArray(composedValue)) {
            finalValue = composedValue.map(item => item.originalValue);
        }
        return finalValue;
    }, [composedValue]);

    const getFinalList = useCallback(() => {
        let finalList: CommonObjectValueType;
        if (multiple === false) {
            finalList = [
                composedValue?.relatedObj === -1
                    ? undefined
                    : composedValue?.relatedObj,
            ].filter(Boolean);
        } else if (_.isArray(composedValue)) {
            finalList = composedValue
                .map(item => item.relatedObj)
                .filter(Boolean);
        }
        return finalList;
    }, [composedValue]);

    pageSize = pageSize || 50;
    const dataSourceApi = dataSource.api || '';

    let {
        objSaveField,
        objShowField,
        constraintObj,
        objFullMeta,
        constraintObjMeta,
    } = constraint;
    objSaveField = objSaveField || {};
    objShowField = objShowField || {};
    constraintObj = constraintObj || {};
    constraintObjMeta = constraintObjMeta || {};
    const [netObjFullMeta, setNetObjFullMeta] = useState<any[] | null>(null);
    const [netObjFullMetaLoading, setNetObjFullMetaLoading] = useState(false);
    const [netObjFullMetaError, setNetObjFullMetaError] = useState<
        string | null
    >(null);
    objFullMeta = netObjFullMeta || objFullMeta || [];
    useEffect(() => {
        if (!options.onGotMeta) {
            return;
        }
        options.onGotMeta(objFullMeta);
    }, [options.onGotMeta, objFullMeta]);

    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        if (!options.onGotMeta) {
            return;
        }

        if (objFullMeta.length > 0) {
            return;
        }

        const constraintObjId = constraintObj.constraintObj;
        if (!constraintObjId) {
            setNetObjFullMetaError('元信息获取错误');
        }
        setNetObjFullMetaLoading(true);
        defaultAxios
            .get('/bff/api/rest/anymeta', {
                constraintObj: constraintObj.constraintObj,
            })
            .then(([d, e]) => {
                setNetObjFullMetaLoading(false);
                if (isNetSuccess(d, e)) {
                    const data = d?.data || [];
                    setNetObjFullMeta(data);
                }
            });
    }, [options.onGotMeta, objFullMeta]);

    useEffect(() => {
        if (!!options.onGotMeta) {
            return;
        }

        if (
            objFullMeta.length > 0 ||
            netObjFullMeta !== null ||
            modalVisible === false
        ) {
            return;
        }
        const constraintObjId = constraintObj.constraintObj;
        if (!constraintObjId) {
            setNetObjFullMetaError('元信息获取错误');
        }
        setNetObjFullMetaLoading(true);
        defaultAxios
            .get('/bff/api/rest/anymeta', {
                constraintObj: constraintObj.constraintObj,
            })
            .then(([d, e]) => {
                setNetObjFullMetaLoading(false);
                if (isNetSuccess(d, e)) {
                    const data = d?.data || [];
                    setNetObjFullMeta(data);
                }
            });
    }, [options.onGotMeta, objFullMeta, netObjFullMeta, modalVisible]);

    // 内部 filter 外部篡改机制
    const [innerFilter, setInnerDefaultFilter] = useState<any>(null);
    useEffect(() => {
        requestListBySearch(true);
    }, [innerFilter]);
    const FilterChangerCom: React.FC<any> = innerFilterChanger
        ? innerFilterChanger
        : () => null;
    const filterChangeSnippet = (
        <>
            <FilterChangerCom
                objectMeta={objFullMeta}
                filterValue={innerFilter}
                onFilterValueChange={setInnerDefaultFilter}
                data={data}
            />
        </>
    );

    // 目标对象的一个集合
    const [innerTableListMeta, setInnerTableListMeta] = useState(() => {
        const tableListMeta = new ListMeta();
        tableListMeta.setObjectMeta(objFullMeta);
        return tableListMeta;
    });
    
    useEffect(() => {
        const tableListMeta = new ListMeta();
        tableListMeta.setObjectMeta(objFullMeta);
        setInnerTableListMeta(tableListMeta);
    }, [objFullMeta]);

    // let innerTableListMeta: ListMeta = cache.get(objFullMeta);
    // if (!innerTableListMeta) {
    //     innerTableListMeta = new ListMeta();
    //     innerTableListMeta.setObjectMeta(objFullMeta);
    // }

    let currentFieldMeta = _.find(
        listMeta.objectMeta,
        metaItem => metaItem.fieldName === key,
    );

    currentFieldMeta = currentFieldMeta || {};

    const mode = multiple ? 'multiple' : 'single';

    let {
        constraintObj: constraintObjId,
        objShowFieldId,
        objFindFieldIdList,
    } = constraintObj;

    objFindFieldIdList = objFindFieldIdList || [];

    const objFindFieldIdListMap = arrToJsonPrimary(objFindFieldIdList);
    let objFullMetaToShow: IParamItem[] = [];
    for (const item of objFullMeta) {
        if (
            objFindFieldIdListMap[item.fieldName] &&
            paramsBlackList &&
            !paramsBlackList[item.fieldName]
        ) {
            objFullMetaToShow.push({
                key: item.fieldName,
                title: item.fieldComment,
            });
        }
    }
    console.log('objFullMetaToShow', objFullMetaToShow);

    const [list, setList] = useState<any[]>([]);
    const listMap = arrToJson(list, 'id');
    const [innerloading, setInnerloading] = useState(false);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    useEffect(() => {
        const finalList = getFinalList();
        if (finalList === undefined) {
            return;
        }
        const appendList = finalList;

        const nextList = _.uniqBy(
            [...list, ...appendList],
            objSaveField.fieldName,
        );
        setList(nextList);
        setSelectedItems([...appendList]);
    }, [composedValue, modalVisible]);

    useEffect(() => {
        if (modalVisible === true) {
            requestListBySearch(true);
        }
    }, [modalVisible, entityCode]);

    useEffect(() => {
        if (Number(options.modalVisibleTrigger) > 0) {
            setModalVisible(true);
        }
    }, [options.modalVisibleTrigger]);

    useEffect(() => {
        if (Number(options.modalVisibleTrigger) > 0) {
            setSelectedItems([]);
        }
    }, [options.modalVisibleTrigger]);

    // 城市自动请求
    useEffect(() => {
        if (key !== 'city' || !underCustomerForm) {
            return;
        }
        const dispose = autorun(() => {
            const { state } = data;
            requestListBySearch(true);
        });

        return () => {
            dispose();
        };
    }, [key]);

    // 区自动请求
    useEffect(() => {
        if (key !== 'district' || !underCustomerForm) {
            return;
        }
        const dispose = autorun(() => {
            const { city } = data;
            requestListBySearch(true);
        });

        return () => {
            dispose();
        };
    }, [key]);

    useEffect(() => {
        if (!reloadTrigger) {
            return;
        }
        requestListBySearch(true);
    }, [reloadTrigger]);

    useEffect(() => {
        if (!listClearTrigger) {
            return;
        }
        setList([]);
    }, [listClearTrigger]);

    // 小加号弹窗
    // 这里要过滤一下，留下该有的columns

    const columns = _.cloneDeep(innerTableListMeta.defaultColumnsAll);
    const finalColumns = applyParamListToColumns(columns, objFullMetaToShow);
    for (const col of finalColumns) {
        delete col.fixed;
    }

    const [isEqualQuery, setIsEqualQuery] = useState(false);
    useEffect(() => {
        requestListBySearch(true);
    }, [isEqualQuery]);
    const modalElement = (
        <Modal
            title={
                <span>
                    选择{currentFieldMeta.fieldComment}（
                    {mode === 'single' ? '单选' : '多选'}）&nbsp;
                    <span>
                        <Checkbox
                            onChange={e => {
                                if (_.isBoolean(e.target.checked)) {
                                    setIsEqualQuery(e.target.checked);
                                }
                            }}
                        >
                            精确匹配
                        </Checkbox>
                    </span>
                </span>
            }
            visible={modalVisible}
            onCancel={() => {
                setModalVisible(false);
            }}
            onOk={() => {
                // 将选择的传给onChange
                if (mode === 'single' && selectedItems.length > 0) {
                    handleChange(selectedItems[0][objSaveField.fieldName]);
                } else {
                    handleChange(
                        selectedItems
                            .map(item => {
                                return item[objSaveField.fieldName];
                            })
                            .filter(Boolean),
                    );
                }
                setModalVisible(false);
            }}
            width={910}
            className="crm-object-selector-modal"
            zIndex={10009}
        >
            <div
                style={{
                    padding: '1px 26px 16px 20px',
                    marginLeft: '-12px',
                }}
            >
                <Input
                    placeholder={`搜索${currentFieldMeta.fieldComment}`}
                    prefix={<Icon style={{ color: '#A2A6B6' }} type="search" />}
                    onChange={e => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ marginTop: 8 }}>{filterChangeSnippet}</div>
            </div>
            <div style={{ height: 400 }}>
                <TableLv2<any>
                    className={mode === 'single' ? 'single-select' : ''}
                    loading={innerloading || netObjFullMetaLoading}
                    cellsSelector={
                        innerTableListMeta.defaultColumnsCellsSelector
                    }
                    columns={finalColumns}
                    dataSource={_.uniqBy(
                        [...list, ...selectedItems],
                        objSaveField.fieldName,
                    )}
                    selectedItems={selectedItems}
                    rowClassName={record => {
                        // return (record[objShowField.fieldName] + '').indexOf(
                        //     _.trim(search),
                        // ) > -1
                        //     ? 'table-show'
                        //     : 'table-hidden';
                        return 'table-show';
                    }}
                    colDefaultWidth={220}
                    onSelectedDataChange={nextSelectedItems => {
                        if (mode === 'single') {
                            setSelectedItems(
                                _.differenceBy(
                                    nextSelectedItems,
                                    selectedItems,
                                    objSaveField.fieldName,
                                ),
                            );
                        } else {
                            setSelectedItems([...nextSelectedItems]);
                        }
                    }}
                />
            </div>
        </Modal>
    );

    const optionsElements = list.map(d => (
        <Option
            key={d[objSaveField.fieldName]}
            value={d[objSaveField.fieldName]}
        >
            {d[objShowField.fieldName]}
        </Option>
    ));

    const [search, setSearch] = useState('');
    const requestListBySearch = async (force?: boolean) => {
        if (search === '' && !force) {
            return;
        }
        const body: any = {
            pageNum: 1,
            pageSize,
            selectiveFields: _.uniq(['id', ...objFindFieldIdList]),
            fieldQueries: _.trim(search)
                ? [
                      {
                          fieldName: objShowField.fieldName,
                          operateType: isEqualQuery ? 'EQUAL' : 'SEARCH',
                          fieldValue: _.trim(search),
                      },
                  ]
                : [],
        };
        if (_.isString(dataAuthCode) && dataAuthCode !== '') {
            body.dataAuthCode = dataAuthCode;
        }
        if (_.isString(fieldAuthCode) && fieldAuthCode !== '') {
            body.fieldAuthCode = fieldAuthCode;
        }
        if (_.isString(entityCode) && entityCode !== '') {
            body.entityCode = entityCode;
        }

        if (defaultFilters) {
            body.fieldQueries.push(...defaultFilters);
        }

        if (innerFilter) {
            body.fieldQueries.push(...innerFilter);
        }

        if (underCustomerForm) {
            if (key === 'city') {
                if (data.state && data.state.originalValue) {
                    const state = data.state.originalValue;
                    body.fieldQueries.push({
                        fieldName: 'state_id',
                        operateType: 'EQUAL',
                        fieldValue: state,
                    });
                } else {
                    body.fieldQueries.push({
                        fieldName: 'state_id',
                        operateType: 'EQUAL',
                        fieldValue: 99999,
                    });
                }
            }

            if (key === 'district') {
                if (data.city && data.city.originalValue) {
                    const city = data.city.originalValue;
                    body.fieldQueries.push({
                        fieldName: 'city_id',
                        operateType: 'EQUAL',
                        fieldValue: city,
                    });
                } else {
                    body.fieldQueries.push({
                        fieldName: 'city_id',
                        operateType: 'EQUAL',
                        fieldValue: 99999,
                    });
                }
            }
        }

        setInnerloading(true);
        requestCount.v++;
        const count = requestCount.v;
        const [list, err] = await transparentQuery(
            body,
            constraintObjMeta.objectCode,
            dataSourceApi,
            _.trim(search),
        );
        setInnerloading(false);

        // shit 简单做
        if (requestCount.v !== count) {
            return;
        }
        if (err || !_.isArray(list)) {
            return;
        }

        const nextList = list;
        if (entityCode === 'contacts') {
            // debugger;
        }

        const finalList = getFinalList();
        if (finalValue !== undefined) {
            nextList.push(...finalList);
        }
        setList(_.uniqBy(nextList, objSaveField.fieldName));
    };

    useEffect(() => {
        requestListBySearch(_.trim(search) === '');
    }, [search]);

    // 1，拿到新的搜索返回结果
    // 2，在旧的搜索结果中筛选出当前选择的对象，与新返回的搜索结果合并
    // 3，生成新的options对象

    const handleSearch = useCallback((value: string) => {
        setSearch(value);
    }, []);

    const handleChange = useCallback(
        (v: any) => {
            // 把值往回怼
            if (_.isArray(v)) {
                // 多选
                const originalValues = v;
                const listMap = arrToJson(
                    [...list, ...selectedItems],
                    objSaveField.fieldName,
                );
                const relatedObjsMap: any = {};
                const relatedObjs = originalValues
                    .map(v => {
                        const item = listMap[v];
                        if (!!item) {
                            relatedObjsMap[v] = item;
                        }
                        return item;
                    })
                    .filter(Boolean);
                const type = 'obj';
                if (originalValues.length === relatedObjs.length) {
                    onChange(
                        originalValues.map(originalValue => {
                            const relatedObj = relatedObjsMap[originalValue];
                            return {
                                originalValue,
                                relatedObj,
                                type,
                            };
                        }),
                    );
                    onInteract('remove-validate-status');
                }
            } else {
                // 单选
                const originalValue = v;
                const relatedObj = list.find(
                    item => item[objSaveField.fieldName] === v,
                );

                const type = 'obj';
                if (originalValue && relatedObj && type) {
                    onChange({
                        originalValue,
                        relatedObj,
                        type,
                    });
                    onInteract('remove-validate-status');
                }
            }
        },
        [list, onChange],
    );

    if (!currentFieldMeta) {
        return <span>缺少：currentFieldMeta</span>;
    }

    // 获取value，数组或者非数组
    const finalValue: CommonObjectValueType = getFinalValue();

    return wrapper(
        <div
            style={{
                width: '100%',
                minWidth: 150,
                display: 'inline-block',
                position: 'relative',
            }}
            className="crm-widgets-object"
        >
            <Select
                style={{ width: '100%' }}
                {...options}
                showSearch
                value={finalValue}
                onChange={(v: any) => {
                    // onChange(v);
                    handleChange(v);
                }}
                onFocus={() => {
                    if (list.length === 0) {
                        requestListBySearch(true);
                    }
                    onFocus();
                    onInteract('focus');
                    onInteract('remove-validate-status');
                }}
                onBlur={() => {
                    onBlur();
                    onInteract('blur');
                    onInteract('validate-instantly');
                }}
                onSearch={handleSearch}
                showArrow={false}
                filterOption={false}
                defaultActiveFirstOption={false}
                notFoundContent={null}
                loading={innerloading}
                mode={multiple ? 'multiple' : 'default'}
                dropdownStyle={{ zIndex: 11111 }}
            >
                {optionsElements}
            </Select>
            {multiple
                ? null
                : clearable && (
                      <Icon
                          onClick={() => onChange(null)}
                          style={{
                              opacity: '0.3',
                              position: 'absolute',
                              right: '36px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              height: '14px',
                              cursor: 'pointer',
                              pointerEvents: disabled ? 'none' : 'unset',
                          }}
                          type="close-circle"
                      />
                  )}
            <Icon
                onClick={() => setModalVisible(true)}
                style={{
                    opacity: '0.3',
                    position: 'absolute',
                    right: '12px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    height: '14px',
                    cursor: 'pointer',
                    pointerEvents: disabled ? 'none' : 'unset',
                }}
                type="plus"
            />
            {modalElement}
        </div>,
    );
};
