import React, { memo, useContext } from 'react';
import { DForm, loadingWrapper, applyConfToFormItem } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ContactListStore } from '../store';
import { applyCustomConfToWidget } from '@/components/common/form/advanced-filters';
import { Alert } from 'antd';
import { CtxContext } from '@/utils/context';

const DemoForm: React.FC<{
    defaultStore: ContactListStore;
    ignoreCtx?: boolean;
}> = ({ defaultStore, ignoreCtx = false }) => {
    const ctx = useContext(CtxContext);
    const { position, positionDetail } = ctx;
    const leadTrans = ignoreCtx
        ? false
        : positionDetail === 'lead-drawer' ||
          positionDetail === 'customer-drawer';

    const cuserTrans = ignoreCtx
        ? false
        : positionDetail === 'customer-drawer-maitrend-cuser';

    const {
        actionType,
        checkPhoneLoading,
        checkPhoneDedupResult,
        checkPhoneInited,
    } = defaultStore;

    let eventCode = '';
    if (actionType === 'create') {
        eventCode = 'CREATE-EDIT';
    } else if (actionType === 'update') {
        eventCode = 'PUBLIC-EDIT';
    }

    if (eventCode === '') {
        return null;
    }

    const form = (
        <DForm
            options={{
                grouped: true,
            }}
            widgetsTransformer={baiscWidgets => {
                applyConfToFormItem(baiscWidgets, 'inputSecret', {
                    sceneCode: 'crm_contacts',
                });
                applyCustomConfToWidget(
                    baiscWidgets,
                    'customer_id',
                    prevWidget => {
                        if (prevWidget.objectOptions) {
                            prevWidget.objectOptions.fieldAuthCode =
                                'PRIVATE-VIEW';
                            prevWidget.objectOptions.dataAuthCode =
                                'SEARCH-PRIVATE';
                        }
                        return prevWidget;
                    },
                );

                applyCustomConfToWidget(baiscWidgets, 'lead_id', prevWidget => {
                    const nextWidget = _.cloneDeep(prevWidget);
                    if (leadTrans) {
                        nextWidget.status = {
                            ...nextWidget.status,
                            disabled: true,
                        };
                    } else {
                        nextWidget.status = {
                            ...nextWidget.status,
                            hidden: true,
                        };
                    }
                    return nextWidget;
                });

                applyCustomConfToWidget(
                    baiscWidgets,
                    'contacts_source',
                    prevWidgets => {
                        prevWidgets.status = {
                            disabled: true,
                        };
                        return prevWidgets;
                    },
                );

                if (leadTrans) {
                    for (const key of ['customer_id', 'lead_id']) {
                        applyCustomConfToWidget(
                            baiscWidgets,
                            key,
                            prevWidgets => {
                                prevWidgets.status = {
                                    disabled: true,
                                };
                                return prevWidgets;
                            },
                        );
                    }
                }

                if (actionType === 'create') {
                    applyCustomConfToWidget(
                        baiscWidgets,
                        'contacts_phone',
                        prevWidget => {
                            const nextWidget = _.cloneDeep(prevWidget);

                            nextWidget.status = {
                                loading: () => {
                                    return checkPhoneLoading;
                                },
                            };

                            if (cuserTrans) {
                                nextWidget.status = {
                                    ...nextWidget.status,
                                    loading: () => {
                                        return checkPhoneLoading;
                                    },
                                    disabled: true,
                                };
                                if (nextWidget.inputOptions) {
                                    nextWidget.inputOptions.type = 'password';
                                }
                            } else {
                                nextWidget.status = {
                                    loading: () => {
                                        return checkPhoneLoading;
                                    },
                                };
                            }

                            if (cuserTrans) {
                                if (!checkPhoneInited) {
                                    nextWidget.nextlineWidget = () => {
                                        return (
                                            <div style={{ padding: 5 }}>
                                                <Alert
                                                    type="warning"
                                                    message={
                                                        '成功转为联系人后，手机号将会正常展示。转为联系人前，请务必完成与用户的手机号授权确认'
                                                    }
                                                />
                                            </div>
                                        );
                                    };
                                } else {
                                    nextWidget.nextlineWidget = () => {
                                        const cuserAlert = (
                                            <Alert
                                                type="warning"
                                                message={
                                                    '成功转为联系人后，手机号将会正常展示。转为联系人前，请务必完成与用户的手机号授权确认'
                                                }
                                            />
                                        );
                                        if (!checkPhoneDedupResult) {
                                            return (
                                                <div style={{ padding: 5 }}>
                                                    {cuserAlert}
                                                </div>
                                            );
                                        }
                                        return (
                                            <div style={{ padding: 5 }}>
                                                {cuserAlert}
                                                <br />
                                                {loadingWrapper(
                                                    checkPhoneLoading,
                                                )(
                                                    <Alert
                                                        type="warning"
                                                        message={
                                                            <div>
                                                                <p>
                                                                    联系人手机号疑似重复
                                                                </p>
                                                            </div>
                                                        }
                                                    />,
                                                )}
                                            </div>
                                        );
                                    };
                                }
                            } else {
                                if (!checkPhoneInited) {
                                    delete nextWidget.nextlineWidget;
                                } else {
                                    nextWidget.nextlineWidget = () => {
                                        if (!checkPhoneDedupResult) {
                                            return null;
                                        }
                                        return (
                                            <div style={{ padding: 5 }}>
                                                {loadingWrapper(
                                                    checkPhoneLoading,
                                                )(
                                                    <Alert
                                                        type="warning"
                                                        message={
                                                            <div>
                                                                <p>
                                                                    联系人手机号疑似重复
                                                                </p>
                                                            </div>
                                                        }
                                                    />,
                                                )}
                                            </div>
                                        );
                                    };
                                }
                            }

                            return nextWidget;
                        },
                    );
                }

                return baiscWidgets;
            }}
            onInteract={(key, type, value) => {
                if (key === 'contacts_phone' && type === 'blur') {
                    defaultStore.checkPhone();
                }
            }}
            defaultStore={defaultStore}
            eventCode={eventCode}
        />
    );
    return form;
};

const FinalDemoForm = memo(observer(DemoForm));
export default FinalDemoForm;
