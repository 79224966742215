import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    AntButton,
    loadingWrapperOver,
} from '@/components';
import { Icon, Tooltip, message, Alert } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, useBeforeFirstDataLoaded } from '@/utils';
import { observer } from 'mobx-react-lite';
import DemoTable from './components/table';
import { ActionDelete } from './components/delete';
import { ActionTransform } from './components/transform';
import { ActionMerge } from './components/merge';
import { ActionMDelete } from './components/m-delete';
import { ActionInvalidBizType } from './components/invalid-biztype';
import { ActionDedupDetail } from '@/pages/sales-follow-up/lead/list/components/dedup-detail';
import { runInAction } from 'mobx';
import {
    defaultAuthDeco,
    dFilterHOC,
    useDefaultDFilterSnippets,
} from '@/containers/hoc';
import './index.scss';
import { MOpResultDisplayContainer } from '@/components';
import FormDrawer from './components/form-drawer';
import classname from 'classname';
import { useFirstLv4Menu } from '@/stores/utils';

const DemoList: React.FC<RouteComponentProps & {
    type: string;
    dfilters?: any;
}> = ({ type = 'all', dfilters }) => {
    const store = useContext(StoreContext);
    const [defaultStore] = useState(() => new store.ContactListStore());
    const userStore = store.getAuthStore();
    const ack = dtext('crm')('contact-list-message-' + '');

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        pageTitle,
        defaultPerm,
        defaultFilter,
        defaultMSelect,
        defaultFastFilters,
        condListbyFilterData,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const ListFetch = useCallback(() => defaultStore.fetch(), []);
    const [error, loading, reload] = useNet(ListFetch, {
        autoLoad: false,
        refreshKeys: ['contacts'],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            defaultMeta.setTableId('contacts');
            defaultMeta.setOpUsername(userStore.userInfo.userId + '-' + type);
            defaultFastFilters.setFilters(dfilters);
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {});
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            defaultFilter.defaultSorter.setSorter({
                columnKey: 'is_kp',
                order: 'descend',
            });
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useFirstLv4Menu(defaultFastFilters);

    useEffect(() => {
        runInAction(() => {
            defaultStore.setListType(type);
            defaultFastFilters.setType(type);
        });
    }, [type]);

    const [isBeforeFirstLoaded] = useBeforeFirstDataLoaded(defaultStore);

    const { title, fastFilters } = useDefaultDFilterSnippets(defaultStore);

    // if (!preRequiredReady) {
    //     return <BlockLoading2 />;
    // }
    const finalLoading = isBeforeFirstLoaded || !preRequiredReady;

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = (
        <>
            <MOpResultDisplayContainer defaultStore={defaultStore} />
            <ActionDelete defaultStore={defaultStore} />
            <ActionTransform defaultStore={defaultStore} />
            <ActionMerge defaultStore={defaultStore} />
            <ActionMDelete defaultStore={defaultStore} />
            <ActionDedupDetail
                contactList={defaultStore}
                caredContacts={defaultStore.dupDetailContacts}
            />
            <FormDrawer
                defaultStore={defaultStore}
                preRequiredReady={preRequiredReady}
            />
            <ActionInvalidBizType defaultStore={defaultStore} />
        </>
    );

    const globalOps = (
        <>
            {defaultPerm.getPermByTypeAndAction(type)('create').visible && (
                <AntButton
                    onClick={() => {
                        runInAction(() => {
                            defaultStore.setAction('create');
                            defaultStore.startNewData();
                        });
                    }}
                    type="primary"
                    size="large"
                >
                    新建联系人
                </AntButton>
            )}
        </>
    );
    const multipleOps = (
        <div
            className={classname({
                'mselected-ops': true,
                'multiple-mode': defaultMSelect.isMSelectionMode,
                'normal-mode': !defaultMSelect.isMSelectionMode,
            })}
        >
            <Tooltip title="取消多选">
                <span
                    onClick={() => {
                        defaultMSelect.setMSelectedData([]);
                    }}
                    style={{
                        padding: 5,
                        cursor: 'pointer',
                    }}
                >
                    <Icon type="close" />
                </span>
            </Tooltip>
            <div>
                <span>已选{defaultMSelect.mSeletedData.length}条：</span>
            </div>
            {defaultPerm.getPermByTypeAndAction(type)('transform').visible && (
                <AntButton
                    onClick={() => {
                        defaultStore.setAction('transform');
                    }}
                    size="large"
                    checkBody={{
                        tableCode: 'contacts',
                        body: {
                            dataAuthCode: 'TRANSFER',
                            ids: defaultMSelect.mSeletedData
                                .map(item => item.id)
                                .filter(Boolean),
                        },
                    }}
                >
                    转移
                </AntButton>
            )}
            {defaultPerm.getPermByTypeAndAction(type)('merge').visible &&
                (defaultMSelect.mSeletedData.length < 2 ? (
                    <Tooltip title={'合并至少选择两个联系人'}>
                        <span>
                            <AntButton
                                onClick={() => {
                                    defaultStore.setAction('merge');
                                }}
                                size="large"
                            >
                                合并
                            </AntButton>
                        </span>
                    </Tooltip>
                ) : (
                    <AntButton
                        onClick={() => {
                            defaultStore.setAction('merge');
                        }}
                        size="large"
                    >
                        合并
                    </AntButton>
                ))}
        </div>
    );

    const dataTable = (
        <DemoTable defaultStore={defaultStore} loading={loading} type={type} />
    );

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters: filtersDisplay,
        multipleOps,
        dataTable,
        message: null,
        // message: <Alert type={'warning'} style={{
        //     position: 'absolute'
        // }} showIcon message={ack} />,
        filtersDisplay: fastFilters,
    };

    return loadingWrapperOver(finalLoading)(
        <LayoutStandardCrud className="page-customer-list" {...layoutComs} />,
    );
};

const FinalDemoList = dFilterHOC(observer(DemoList), 'contacts');
export default FinalDemoList;
