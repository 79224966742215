import React, { useContext, useEffect, useState } from 'react';

import { CustomerListStore } from '../store';
import { Icon, Input, message, Modal, Spin } from 'antd';
import { runInAction } from 'mobx';
import _ from 'lodash';
import {
    MOpResultDisplayContainer,
    MOpNameDisplayContainer,
    loadingWrapper,
} from '@/components';
import { observer } from 'mobx-react-lite';
import {
    defaultAxios,
    getQueryAsObject,
    isNetSuccess,
    tryRefresh,
} from '@/utils';
import './m-fetch.scss';
import { voyagerTrack } from '@shared/voyager';
import { StoreContext } from '@/stores';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';

const formatFilters = (filter: NexusGenInputs['FiltersArgs']) => {
    // res={
    //     "recycle_type":1 or not null
    // }
    let res: any = {};
    filter?.filters?.forEach((filter: { [x: string]: any }) => {
        res[filter['fieldId']] = _.isNil(filter['filterValue'])
            ? filter['operateType']
            : filter['filterValue'];
    });
    console.log('wmxformat', res);

    return res;
};

export const ActionMFetch: React.FC<{
    customerList: CustomerListStore;
    isDetail?: boolean;
    refreshFunc?: () => void;
}> = observer(({ customerList, isDetail, refreshFunc }) => {
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const { defaultMSelect, mutatingData } = customerList;
    const { mSeletedData: dataFromMSelecte } = defaultMSelect;
    const mSeletedData = mutatingData ? [mutatingData] : dataFromMSelecte;
    const customerName = mutatingData?.customer_name;
    const [loading, setLoading] = useState(false);

    const isbadData = mSeletedData === null || mSeletedData.length === 0;
    const visible = customerList.actionType === 'm-fetch';
    // 基础数据
    const [validCodeData, setValidCodeData] = useState('');
    const [validCodeDataToken, setValidCodeDataToken] = useState('');
    const [validCodeValue, setValidCodeValue] = useState('');
    const [validCodeDataLoading, setValidCodeDataLoading] = useState(false);
    // 刷新用
    const [
        validCodeDataRefreshTrigger,
        setValidCodeDataRefreshTrigger,
    ] = useState(0);

    // 过期，报错，过期timer
    const [validCodeOutdate, setValidCodeOutdate] = useState(false);
    const [validCodeError, setValidCodeError] = useState(false);
    const [validCodeOutdateTimer] = useState({
        instance: setTimeout(() => {}, 0),
    });

    const refreshValidCodeData = () => {
        setValidCodeDataLoading(true);

        setValidCodeOutdate(false);
        setValidCodeError(false);
        setValidCodeValue('');
        setValidCodeDataToken('');
        clearTimeout(validCodeOutdateTimer.instance);

        // 强制刷新1.5s，防止瞎jb点
        setTimeout(() => {
            const realVisible = customerList.actionType === 'm-fetch';
            if (!realVisible) {
                return;
            }

            setValidCodeDataRefreshTrigger(1 + validCodeDataRefreshTrigger);
        }, 1000);
    };

    // 如果能获取到这个data，说明需要验证码
    const useValidCode = !!validCodeData;
    const invalidText = (() => {
        if (!useValidCode) {
            // 不用验证码
            return '';
        }
        // 过期
        if (validCodeOutdate) {
            return '验证码过期，请刷新验证码后重试';
        }
        // 出错
        if (validCodeError) {
            return '验证码请求出错，请刷新验证码后重试';
        }
        // 用验证码但没有验证码
        if (!_.trim(validCodeValue)) {
            return '请填写验证码';
        }
        // 正常
        return '';
    })();

    useEffect(() => {
        if (!visible || isbadData) {
            setValidCodeOutdate(false);
            setValidCodeError(false);
            setValidCodeValue('');
            setValidCodeDataToken('');
            clearTimeout(validCodeOutdateTimer.instance);

            // 清除data
            setValidCodeData('');
            return;
        }
        setValidCodeDataLoading(true);
        // defaultAxios
        //     .get('/crm/system/captcha?eventKey=CUSTOMER_CLAIM')
        Promise.resolve([{ data: { image: '', token: '' } }, null]).then(
            ([d, e]) => {
                setValidCodeDataLoading(false);

                const realVisible = customerList.actionType === 'm-fetch';
                if (!realVisible) {
                    return;
                }

                const setError = () => {
                    setValidCodeError(true);
                    setValidCodeData('ERROR');
                };

                if (isNetSuccess(d, e)) {
                    const useCodeData = !!d?.data?.image;
                    if (useCodeData) {
                        const token = d?.data?.token || '';
                        if (token === '') {
                            return setError();
                        }
                        setValidCodeData(d?.data?.image || '');
                        setValidCodeDataToken(token);

                        // console.log('diff', d?.data?.expiredTime - Date.now())

                        const timeout = 60000;
                        clearTimeout(validCodeOutdateTimer.instance);
                        validCodeOutdateTimer.instance = setTimeout(() => {
                            const realVisible =
                                customerList.actionType === 'm-fetch';
                            if (!realVisible) {
                                return;
                            }

                            setValidCodeOutdate(true);
                            clearTimeout(validCodeOutdateTimer.instance);
                        }, timeout);
                    } else {
                        if (!!validCodeData) {
                            message.info('本次领取无须填写验证码');
                        }
                        setValidCodeData('');
                    }
                } else {
                    setError();
                }
            },
        );
    }, [visible, validCodeDataRefreshTrigger]);

    if (isbadData) {
        return null;
    }

    let refreshWrapper = (ele: any) => ele;
    if (validCodeOutdate) {
        refreshWrapper = ele => (
            <Spin
                className="outdatespin"
                indicator={
                    <span onClick={() => refreshValidCodeData()}>
                        验证码已过期，点击刷新&nbsp;
                        <Icon type="sync" />
                    </span>
                }
            >
                {ele}
            </Spin>
        );
    }
    if (validCodeError) {
        refreshWrapper = ele => (
            <Spin
                className="outdatespin"
                indicator={
                    <span onClick={() => refreshValidCodeData()}>
                        验证码请求出错，点击刷新&nbsp;
                        <Icon type="sync" />
                    </span>
                }
            >
                {ele}
            </Spin>
        );
    }
    return (
        <Modal
            visible={visible}
            title={'领取客户'}
            onCancel={() => {
                customerList.resetAction();
                if (customerList.mutatingData && !isDetail) {
                    customerList.setMutatingData(null);
                }
            }}
            confirmLoading={loading || validCodeDataLoading}
            // okButtonProps={{ disabled: okBtnDisabled }}
            onOk={async () => {
                if (invalidText) {
                    return message.error(invalidText);
                }
                setLoading(true);
                const ok = await customerList.mFetch({
                    code: _.trim(validCodeValue),
                    token: validCodeDataToken,
                });
                setLoading(false);
                if (ok) {
                    // 打点
                    if (
                        window.location.pathname.includes('/buy/global-search')
                    ) {
                        const searchObj = getQueryAsObject();
                        if (searchObj.ref === 'plugin') {
                            // 从插件点击领取客户进入大搜，领取客户
                            voyagerTrack('crm_sale_source_high_sea', {
                                filter: { plugin: '1' },
                                userId: authStore?.userInfo?.userId,
                                appEnv: process.env.REACT_APP_ENV,
                                customerId: mutatingData?.id,
                            });
                        } else {
                            // 大搜领取客户
                            voyagerTrack('crm_sale_source_high_sea', {
                                filter: { global_search: '1' },
                                time: Date.now(),
                                userId: authStore?.userInfo?.userId,
                                appEnv: process.env.REACT_APP_ENV,
                                customerId: mutatingData?.id,
                            });
                        }
                    } else if (
                        window.location.pathname.includes(
                            '/buy/custom/all-custom',
                        )
                    ) {
                        // 在公海领取客户
                        voyagerTrack('crm_sale_source_high_sea', {
                            filter: formatFilters(
                                customerList.condListbyFilterData,
                            ),
                            userId: authStore?.userInfo?.userId,
                            appEnv: process.env.REACT_APP_ENV,
                            customerId: mutatingData?.id,
                        });
                    }
                    runInAction(() => {
                        customerList.resetAction();
                        defaultMSelect.removeAllSelectedData();
                        // tryRefresh(['customer']);
                        refreshFunc && refreshFunc();
                    });
                }
            }}
        >
            <div className="crm-customer-m-fetch">
                <h4>
                    {customerName ? (
                        <span>是否领取【{customerName}】？</span>
                    ) : (
                        <span>是否领取选择的{mSeletedData.length}个客户？</span>
                    )}
                    <MOpNameDisplayContainer
                        defaultStore={customerList}
                        nameParam="customer_name"
                    />
                    <MOpResultDisplayContainer defaultStore={customerList} />
                </h4>
                {useValidCode &&
                    refreshWrapper(
                        loadingWrapper(validCodeDataLoading)(
                            <Input
                                placeholder={'请输入验证码'}
                                onChange={e => {
                                    setValidCodeValue(e.target.value);
                                }}
                                size="large"
                                style={{ margin: '8px 0' }}
                                addonAfter={
                                    validCodeData === 'ERROR' ? null : (
                                        <img
                                            style={{
                                                cursor: 'pointer',
                                                height: 38,
                                            }}
                                            src={validCodeData}
                                            onClick={() =>
                                                refreshValidCodeData()
                                            }
                                        />
                                    )
                                }
                            />,
                        ),
                    )}
            </div>
        </Modal>
    );
});
