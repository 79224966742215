import React, { useState, useEffect, useContext, useCallback } from 'react';
import { DTable, isMultipleCell, makeObjColClickable } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ContractListStore } from '../store';
import { runInAction } from 'mobx';
import DefaultDrawer from '../drawer';
import { StoreContext } from '@/stores';
import { applyCustomConfToCellsSelector } from '@/components/common/form/advanced-filters';
import { tryRefresh, useQueryAsObject } from '@/utils';
import { useCustomerDrawerSnippets } from '@/components/common/customer-drawer/hooks';
import { useOptDrawerSnippets } from '@/pages/sales-follow-up/opt/list/drawer/hooks';

export const contractCellsSelectorTransformer = (
    originalbaiscCellsSelector: any,
) => {
    const baiscCellsSelector = [...originalbaiscCellsSelector];
    // 详情可点击
    applyCustomConfToCellsSelector(
        baiscCellsSelector,
        'contract_name',
        cellSelector => {
            const nextCellSelector = _.cloneDeep(cellSelector);
            if (isMultipleCell(nextCellSelector)) {
                return nextCellSelector;
            }
            nextCellSelector.cell = 'ActionParam';
            nextCellSelector.inputs.push({
                paramName: 'contract_name',
            });
            return nextCellSelector;
        },
    );

    applyCustomConfToCellsSelector(
        baiscCellsSelector,
        'contract_attachments',
        cellSelector => {
            const nextCellSelector = _.cloneDeep(cellSelector);
            if (isMultipleCell(nextCellSelector)) {
                return nextCellSelector;
            }
            nextCellSelector.cell = 'Files';

            return nextCellSelector;
        },
    );

    applyCustomConfToCellsSelector(
        baiscCellsSelector,
        'process_approval_status',
        cellSelector => {
            if (isMultipleCell(cellSelector)) {
                return cellSelector;
            }
            cellSelector.cell = 'ProcessStatus';
            return cellSelector;
        },
    );

    makeObjColClickable(baiscCellsSelector, 'customer_id');
    makeObjColClickable(baiscCellsSelector, 'opportunity_id');

    return baiscCellsSelector;
};

const DemoTable: React.FC<{
    defaultStore: ContractListStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const { defaultMSelect } = defaultStore;

    const { mSeletedData, setMSelectedData } = defaultMSelect;

    const [drawerVis, setDrawerVis] = useState(false);
    const [detailId, setDetailId] = useState(undefined);

    const eventCode = 'PUBLIC-VIEW';

    useQueryAsObject(queryObj => {
        if (queryObj.id) {
            setDetailId(queryObj.id);
            setDrawerVis(true);
        }
    });

    const [customerDrawerCid, setCustomerDrawerCid] = useState(undefined);
    const [customerDrawerVis, setCustomerDrawerVis] = useState(false);
    const [___, cusDrawerSnippet] = useCustomerDrawerSnippets({
        drawerVis: customerDrawerVis,
        setDrawerVis: setCustomerDrawerVis,
        cid: customerDrawerCid,
    });

    const [optDrawerOptid, setOptDrawerOptid] = useState(undefined);
    const [optDrawerVis, setOptDrawerVis] = useState(false);
    const [____, optDrawerSnippet] = useOptDrawerSnippets({
        drawerVis: optDrawerVis,
        setDrawerVis: setOptDrawerVis,
        itemId: optDrawerOptid,
    });

    const dataTable = (
        <>
            {cusDrawerSnippet}
            {optDrawerSnippet}
            <DefaultDrawer
                drawerVis={drawerVis}
                setDrawerVis={setDrawerVis}
                detailId={detailId}
                destroyOnClose={true}
                onEdit={data => {
                    runInAction(() => {
                        defaultStore.setAction('update', {
                            id: data.id,
                        });
                    });
                }}
                onCreat={() => {
                    runInAction(() => {
                        defaultStore.setAction('create');
                        defaultStore.startNewData();
                    });
                }}
                contractListStore={defaultStore}
            />
            <DTable
                eventCode={eventCode}
                sceneCode={'crm_contract'}
                onColumnsUpdated={() => tryRefresh(['contract'])}
                defaultStore={defaultStore}
                options={
                    {
                        // withActionPermCheck: false,
                    }
                }
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [
                        {
                            actionKey: 'contract_tab_type',
                            actionCn: '标记',
                        },
                        {
                            actionKey: 'contract_invoice_apply',
                            actionCn: '开发票',
                        },
                        {
                            actionKey: 'open_apply',
                            actionCn: '开通',
                        },
                    ];
                    props.onAction = (key, data) => {
                        if (key === 'update') {
                            runInAction(() => {
                                defaultStore.setAction('update', {
                                    id: data.id,
                                });
                            });
                        } else if (key === 'delete') {
                            defaultStore.setAction('delete');
                            // 其他动作
                        } else if (key === 'transform') {
                            // 其他动作
                        } else if (key === 'open_apply') {
                            // 开通预览
                            defaultStore.setAction('open_apply');
                            defaultStore.setMutatingData(data);
                        } else if (key === 'contract_tab_type') {
                            // 开通预览
                            defaultStore.setAction('contract_tab_type');
                            defaultStore.setMutatingData(data);
                        } else if (key === 'contract_invoice_apply') {
                            defaultStore.setAction('contract_invoice_apply');
                            defaultStore.fetchInstantlyMutatingDataById(
                                data.id,
                            );
                            // 其他动作
                        } else if (_.startsWith(key, 'reveal-obj/')) {
                            // 关联对象都可以点击
                            if (key.indexOf('/customer_id') !== -1) {
                                setCustomerDrawerCid(
                                    data.customer_id?.originalValue,
                                );
                                setCustomerDrawerVis(true);
                            }

                            if (key.indexOf('/opportunity_id') !== -1) {
                                setOptDrawerOptid(
                                    data.opportunity_id?.originalValue,
                                );
                                setOptDrawerVis(true);
                            }
                        } else if (_.startsWith(key, 'reveal-param/')) {
                            // 可点击参数，召唤drawer
                            if (key.indexOf('/contract_name') !== -1) {
                                setDetailId(data.id);
                                setDrawerVis(true);
                            }
                        }
                    };
                    props.closeRowSelection = true;
                    props.selectedItems = mSeletedData;
                    props.onSelectedDataChange = setMSelectedData;
                    props.widthInfoKey = 'contract-list-default';
                    return props;
                }}
                cellsSelectorTransformer={contractCellsSelectorTransformer}
            />
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
