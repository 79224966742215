import * as React from 'react';
import { useState, CSSProperties } from 'react';
import './index.scss';
import { message, Avatar, Modal } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';

export interface IItemsUserProps {
    avatar?: React.ReactElement | (() => React.ReactElement);
    name?: string | number;
    avatarConfig?: AvatarProps;
    onClick?: (user: any) => void;
    mergeClick?: boolean;
    style?: CSSProperties;
    className?: string;
}
/**
 *
 * @param onClick function 默认内部封装了click回调,传入的onclick会根据mergeClick的不同而以不同的方式调用
 * @param mergeClick boolean true为跟内部回调一起调用，false为覆盖
 */
const ItemUser: React.FC<IItemsUserProps> = ({
    mergeClick = true,
    avatar,
    avatarConfig,
    name,
    style = {},
    onClick,
    className = '',
}) => {
    const defaultUserClickHandle = () => {
        message.success('click');
    };
    const userClickHandle = () => {
        if (onClick && typeof onClick === 'function') {
            if (mergeClick) {
                // defaultUserClickHandle();
                onClick({
                    avatar,
                    name,
                });
            } else {
                onClick({
                    avatar,
                    name,
                });
            }
        } else {
            // defaultUserClickHandle();
        }
    };
    const renderOtherAvatar = () => {
        if (avatar) {
            return typeof avatar === 'function' ? avatar() : avatar;
        }
    };
    return (
        <div
            className={`maimai-user-content ${className}`}
            style={style}
            onClick={userClickHandle}
        >
            {!avatar ? (
                <Avatar size={20} icon="user" {...avatarConfig} />
            ) : (
                renderOtherAvatar()
            )}
            <span className="maimai-user-name">{name}</span>
        </div>
    );
};

export default ItemUser;
