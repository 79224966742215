import React from 'react';
import _ from 'lodash';
import './index.scss';

export const LayoutFilterTable: React.FC = ({ children }) => {
    if (_.isArray(children)) {
        const filter = children[0];
        const table = children[1];
        return (
            <div className="maimai-crm-layout-ft">
                <div className="filter">{filter}</div>
                <div className="table">{table}</div>
            </div>
        );
    }

    return null;
};
