import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    makeDefaultInitailingHook,
    FastFilterStore,
} from '@/stores/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import { mutate } from '@/api/graphql';
import _ from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import { message } from 'antd';
import { xDelete, commonQuery, TGetCommonReturnType } from '@/api/rest';
import { defaultWritingDataParser, defaultAxios, isNetSuccess } from '@/utils';
import EE from 'eventemitter3';
import $ from 'jquery';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';
import { isNetworkError } from 'axios-retry';
import {
    makeDefaultInitailingHookB,
    createDefaultInstanceGetterA,
} from '@/stores/utilsA';

const transformBackEndValidateStateToValidateResult = (
    data: any[],
): ValidateResult<any> => {
    const ret: ValidateResult<any> = {};
    for (const d of data) {
        const { errorMsg, errorType, fieldName } = d;
        if (!errorMsg || !errorType || !fieldName) {
            continue;
        }
        ret[fieldName] = {
            msg: errorMsg,
            status: errorType === 'error' ? 'fail' : 'warning',
        };
    }
    return ret;
};

export class ContactListStore {
    // 数据
    public data: any[] = [];

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public position: string = '';
    public setPosition(position: string) {
        this.position = position;
    }

    public get pageTitle() {
        return '联系人列表';
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    public defaultFields: string[] = [];
    public setDefaultFields(fields: string[]) {
        this.defaultFields = fields;
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFastFilters = this.defaultFastFilters;
        const {
            selectedFastFilters,
            selectedLv4MenuFilters,
        } = defaultFastFilters;

        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );
        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        for (const filterSet of selectedFastFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        for (const filterSet of selectedLv4MenuFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        ret.filters?.push(...defaultFilters);
        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }
    public setAction(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra.id) {
                this.fetchInstantlyMutatingDataById(extra.id);
            } else {
                message.error('修改必须传id');
            }
        }
        if (!!['transform', 'merge'].includes(nextAction)) {
            const { mSeletedData } = this.defaultMSelect;
            const isSingleBizType =
                _.uniqBy(mSeletedData, cus => cus.biz_type).length === 1;
            if (!isSingleBizType) {
                // 如果不是同一个bizType
                this.actionType = 'invalid-biztype';
                return;
            }
        }
        this.actionType = nextAction;
    }
    public async setActionAsync(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra) {
                if (extra.id) {
                    const ok = await this.fetchInstantlyMutatingDataById(
                        extra.id,
                    );
                    if (!ok) {
                        return false;
                    }
                } else {
                    message.error('修改必须传id');
                    return false;
                }
            }
        }
        this.actionType = nextAction;
        return true;
    }
    public resetAction() {
        this.netValidateResult = {};
        this.resetCheckPhoneStatus();
        this.actionType = '';
    }

    public async dispatchAction() {
        if (this.actionType === 'create') {
            return this.create();
        } else if (this.actionType === 'update') {
            return this.update();
        }
    }

    public dupDetailContacts: any = null;
    public setDupDetailContacts(contacts: any) {
        this.dupDetailContacts = contacts;
    }

    public clearDupDetailContacts() {
        this.dupDetailContacts = null;
    }

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;
    get mutatingDataParsed() {
        return defaultWritingDataParser(this.mutatingData);
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        const initialFormData = this.defaultMeta.defaultWidgetValues;
        this.mutatingData = _.cloneDeep(initialFormData);
        this.mutatingData.contacts_source = 1;
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;
    get fieldAuthCode() {
        if (this.position === 'customer-drawer') {
            // return 'PRIVATE-VIEW' // ??
            return ''; // 先不给
        }
        const { defaultFastFilters } = this;
        const selectedMine = !!defaultFastFilters.selectedLv4MenuFilters.find(
            item => {
                return item.key === 'contacts-df-all-mine';
            },
        );
        return !selectedMine ? '' : '';
        // return !selectedMine ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    }
    get dataAuthCode() {
        if (this.position === 'customer-drawer') {
            // return 'PRIVATE-VIEW' // ??
            return 'SEARCH-ALL';
            // return 'SEARCH-PUBLIC'; // 先不给
        }
        const { defaultFastFilters } = this;
        const selectedMine = !!defaultFastFilters.selectedLv4MenuFilters.find(
            item => {
                return item.key === 'contacts-df-all-mine';
            },
        );
        return !selectedMine ? 'SEARCH-PUBLIC' : 'SEARCH-PRIVATE';
    }

    public async fetchInstantlyMutatingDataById(id: any) {
        const { fieldAuthCode, dataAuthCode } = this;

        this.startNewData();
        this.mutatingDataLoading = true;
        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: id,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            undefined,
            dataAuthCode,
        );
        this.mutatingDataLoading = false;

        if (d === null || e !== null) {
            return false;
        }
        const { datas } = d;
        if (datas && datas[0]) {
            this.mutatingData = datas[0];
            this.checkPhone();
            return true;
        } else {
            message.error('没有该数据的权限');
            return false;
        }
    }

    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode, dataAuthCode } = this;

        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            [
                'is_kp',
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
                ...this.defaultFields,
            ],
            dataAuthCode,
        );

        if (d === null || e !== null) {
            return [d, e];
        }
        const { amount, datas } = d;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    public async transform(customer: any) {
        if (_.isNil(customer)) {
            message.error('目标用户未选择，转移失败');
            return false;
        }
        const { defaultMSelect } = this;

        const [d, e] = await defaultAxios.put(
            '/crm/contacts/transfer2Customer',
            {
                ids: defaultMSelect.mSeletedData.map(item => item.id),
                customerId: customer.originalValue,
            },
        );
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('转移成功');
            return true;
        } else {
            this.defaultMSelect.setMopResult('转移结果', d.data);
            return false;
        }
    }

    public async merge(masterId: any) {
        if (_.isNil(masterId)) {
            message.error('主联系人必选');
            return false;
        }
        const { defaultMSelect } = this;

        const [d, e] = await defaultAxios.put('/crm/contacts/merge', {
            ids: defaultMSelect.mSeletedData.map(item => item.id),
            masterId,
        });
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('合并成功');
            return true;
        } else {
            this.defaultMSelect.setMopResult('合并结果', d.data);
            return false;
        }
    }

    public hasPhone(data: any) {
        if (data === null) {
            return false;
        }

        const { contacts_phone, contacts_telephone } = data;

        const contractsPhone = _.trim(contacts_phone);
        const contractsTelephone = _.trim(contacts_telephone);

        if (!contractsPhone && !contractsTelephone) {
            return false;
        }

        return true;
    }
    public async checkKp(data: any) {
        const url = '/crm/contacts/checkMaxKpCountByCustomerId';
        if (data === null) {
            return false;
        }
        const { customer_id, is_kp } = data;
        if (is_kp !== 1) {
            return true;
        }
        if (customer_id) {
            const [d, e] = await defaultAxios.get(url, {
                customerId: customer_id,
            });
            if (d === null || d.data === null) {
                return true;
            }
            if (d.data) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    public async create() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        delete data.id;
        delete data.key;

        const hasPhone = this.hasPhone(data);
        if (!hasPhone) {
            message.error('请填写【联系人手机】或【联系人座机】');
            return false;
        }

        const overKpLimit = await this.checkKp(data);
        if (!overKpLimit) {
            message.error('客户KP联系人已达上限，无法添加新的KP');
            return false;
        }

        const [ret, errMsg] = await mutate('contacts', 'insert', data);
        if (!ret || errMsg) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('新建成功');
        this.defaultEE.emit('create-success');
        return true;
    }
    public async update() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        delete data.key;

        const hasPhone = this.hasPhone(data);
        if (!hasPhone) {
            message.error('请填写【联系人手机】或【联系人座机】');
            return false;
        }
        const overKpLimit = await this.checkKp(data);
        if (!overKpLimit) {
            message.error('客户KP联系人已达上限，无法添加新的KP');
            return false;
        }

        const [ret, errMsg] = await mutate('contacts', 'update', data);
        if (!ret || errMsg) {
            return false;
        }
        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('修改成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    public async delete() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = [data.id];
        const [d, e] = await xDelete(tableId, operatingIdList);
        if (!e) {
            message.info('删除成功');
            return true;
        }
        return false;
    }

    public async mdelete() {
        const ids = _.map(this.defaultMSelect.mSeletedData, item => item.id);
        if (ids.length === 0) {
            return false;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = ids;
        const [d, e] = await xDelete(tableId, operatingIdList);
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('删除成功');
            return true;
        } else {
            this.defaultMSelect.setMopResult('批量删除结果', d.data);
            return false;
        }
    }

    public resetCheckPhoneStatus() {
        this.checkPhoneLoading = false;
        this.checkPhoneDedupResult = false;
        this.checkPhoneInited = false;
    }

    public checkPhoneLoading: boolean = false;
    public checkPhoneDedupResult: boolean = false;
    public checkPhoneInited: boolean = false;
    public async checkPhone() {
        const { mutatingData } = this;
        if (!mutatingData || !mutatingData.contacts_phone) {
            return false;
        }
        this.checkPhoneLoading = true;
        const [d, e] = await defaultAxios.get(
            '/crm/contacts/checkPhone?phone=' + mutatingData.contacts_phone,
        );
        if (!isNetSuccess(d, e)) {
            return false;
        }
        this.checkPhoneLoading = false;

        const isDedup = d?.data;
        if (_.isUndefined(isDedup)) {
            return false;
        }

        this.checkPhoneDedupResult = isDedup;
        this.checkPhoneInited = true;
        return true;
    }

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMeta = new ListMeta(
        '/bff/api/rest/meta/contacts/list',
        '/bff/api/rest/meta/contacts/list/filters/save',
        '/bff/api/rest/meta/contacts/list/table-cols/save',
    );
    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();
    public defaultPerm: Perm = new Perm(
        '/bff/api/rest/perm?mod=sales-follow-up:contacts',
    );
    public defaultMSelect: MSelectStore = new MSelectStore();
    public defaultFastFilters = new FastFilterStore();
    constructor() {
        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultMeta: false,
            defaultFilter: false,
            defaultEE: false,
            defaultPerm: false,
            defaultMSelect: false,
        });
    }
}

export const getDefaultContactListStore = createDefaultInstanceGetterA(
    ContactListStore,
);
export const useAnyContactsStore = makeDefaultInitailingHookB(
    ContactListStore,
    'contacts',
);
