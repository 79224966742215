import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AntButton, BlockLoading2 } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { AntDrawer } from '@/components/antd/drawer';
import { defaultAuthDeco } from '@/containers/hoc';
import { ContractListStore } from '../store';
import { ContractFormProcess } from './form-process';
import { tryRefresh } from '@/utils';

const FormDrawer: React.FC<RouteComponentProps & {
    mutateSuccess?: any;
    outerVisible?: boolean;
    outerCancel?: any;
    outerCtxInfo?: any;
    defaultStore: ContractListStore;
    preRequiredReady: boolean;
}> = ({
    mutateSuccess,
    outerVisible,
    outerCancel,
    outerCtxInfo,
    defaultStore,
    preRequiredReady,
}) => {
    const {
        actionType,
        actionTs,
        bigFormVisible,
        mutatingDataLoading,
    } = defaultStore;

    const [loading, setLoading] = useState(false);

    if (!preRequiredReady) {
        return null;
    }

    const popups = (
        <>
            <AntDrawer
                title={actionType === 'create' ? '新建合同' : '修改合同'}
                placement={'right'}
                visible={
                    !_.isNil(outerVisible)
                        ? outerVisible && bigFormVisible
                        : bigFormVisible
                }
                onClose={() => {
                    defaultStore.resetAction();
                    outerCancel && outerCancel();
                }}
                bodyStyle={{
                    width: '1050px',
                    overflow: 'scroll',
                }}
                width="1050px"
            >
                <div className="standard-drawer-form-wrapper">
                    {mutatingDataLoading ? (
                        <BlockLoading2 />
                    ) : (
                        <ContractFormProcess
                            key={actionType + actionTs}
                            defaultStore={defaultStore}
                            outerCtxInfo={outerCtxInfo}
                            processCtx={defaultStore.contractProcessContext}
                        />
                    )}
                    <div className="fixed-form-btns">
                        <AntButton
                            size="large"
                            type="primary"
                            loading={loading}
                            onClick={async () => {
                                setLoading(true);
                                const isSuccess = await defaultStore.dispatchAction();
                                setLoading(false);
                                if (isSuccess) {
                                    mutateSuccess && mutateSuccess();
                                    tryRefresh([
                                        'contract',
                                        'parse-trans-refresh',
                                    ]);
                                }
                            }}
                        >
                            {actionType === 'create' ? '新建' : '修改'}
                        </AntButton>
                        <AntButton
                            size="large"
                            onClick={() => {
                                defaultStore.resetAction();
                                outerCancel && outerCancel();
                            }}
                        >
                            取消
                        </AntButton>
                    </div>
                </div>
            </AntDrawer>
        </>
    );

    return popups;
};

const FinalFormDrawer = defaultAuthDeco(observer(FormDrawer));
export default FinalFormDrawer;
