import React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Input, Form } from 'antd';
import { dontUseDefaultAction, btnMap } from './conf';
import _ from 'lodash';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const DefaultAction: React.FC<{
    currentAction: string;
    data: any;
    onOk: (extra?: any) => void;
    onCancel: () => void;
    loading: boolean;
}> = observer(({ currentAction, data, onOk, onCancel, loading }) => {
    const title = btnMap[currentAction] || '';
    const comment = data?.comment || '';
    const visible =
        !dontUseDefaultAction[currentAction] &&
        currentAction !== '' &&
        data !== null;

    return (
        <Modal
            title={title}
            onOk={onOk}
            confirmLoading={loading}
            onCancel={onCancel}
            visible={visible}
            zIndex={10001}
        >
            <div className="customer-m-transform">
                <div className="form-wrapper">
                    <Form className="form" layout={'horizontal'}>
                        <Form.Item
                            {...formItemLayout}
                            label={<span className="label">备注</span>}
                        >
                            <Input.TextArea
                                required={true}
                                value={comment}
                                onChange={e => {
                                    const text = e?.target?.value;
                                    if (!_.isUndefined(text)) {
                                        data.comment = text;
                                    }
                                }}
                                placeholder={'备注（非必填）'}
                            ></Input.TextArea>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    );
});
