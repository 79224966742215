import React, { useState } from 'react';
import { Table, Checkbox } from 'antd';
import { IFieldAuthData, IFiledEntityAuthData } from '../store';
import _ from 'lodash';

interface IFRMFieldTableProps {
    onFieldChange: (
        val: boolean,
        fieldKey: string,
        authWay: 'editAuth' | 'createAuth' | 'viewAuth',
        authKey: string,
    ) => void;
    fieldEntityData: IFiledEntityAuthData[];
    onAuthWayAllCheck: (
        authWay: 'editAuth' | 'createAuth' | 'viewAuth',
    ) => void;
}
const FRMFieldTable: React.FC<IFRMFieldTableProps> = props => {
    const { onFieldChange, fieldEntityData, onAuthWayAllCheck } = props;
    return (
        <Table<IFiledEntityAuthData>
            dataSource={fieldEntityData}
            pagination={false}
            columns={[
                {
                    title: '字段',
                    dataIndex: 'fieldName',
                    width: '150px',
                },
                {
                    title: (
                        <span>
                            查看{' '}
                            <span
                                onClick={() => {
                                    onAuthWayAllCheck('viewAuth');
                                }}
                                className="all-checked-btn"
                            >
                                全选
                            </span>
                        </span>
                    ),
                    render: (item: IFiledEntityAuthData) => {
                        return (
                            <div className="frm-table-item-checkbox-content">
                                {_.map(item?.viewAuth || [], auth => {
                                    return (
                                        <Checkbox
                                            onChange={e => {
                                                onFieldChange(
                                                    e.target.checked,
                                                    item.fieldKey,
                                                    'viewAuth',
                                                    auth.authKey,
                                                );
                                            }}
                                            checked={auth.authValue}
                                        >
                                            {auth.authName}
                                        </Checkbox>
                                    );
                                })}
                            </div>
                        );
                    },
                },
                {
                    title: (
                        <span>
                            编辑{' '}
                            <span
                                onClick={() => {
                                    onAuthWayAllCheck('editAuth');
                                }}
                                className="all-checked-btn"
                            >
                                全选
                            </span>
                        </span>
                    ),
                    render: (item: IFiledEntityAuthData) => {
                        return (
                            <div className="frm-table-item-checkbox-content">
                                {_.map(item?.editAuth || [], auth => {
                                    return (
                                        <Checkbox
                                            onChange={e => {
                                                onFieldChange(
                                                    e.target.checked,
                                                    item.fieldKey,
                                                    'editAuth',
                                                    auth.authKey,
                                                );
                                            }}
                                            checked={auth.authValue}
                                        >
                                            {auth.authName}
                                        </Checkbox>
                                    );
                                })}
                            </div>
                        );
                    },
                },
                {
                    title: (
                        <span>
                            新建{' '}
                            <span
                                onClick={() => {
                                    onAuthWayAllCheck('createAuth');
                                }}
                                className="all-checked-btn"
                            >
                                全选
                            </span>
                        </span>
                    ),
                    render: (item: IFiledEntityAuthData) => {
                        return (
                            <div className="frm-table-item-checkbox-content">
                                {_.map(item?.createAuth || [], auth => {
                                    return (
                                        <Checkbox
                                            onChange={e => {
                                                onFieldChange(
                                                    e.target.checked,
                                                    item.fieldKey,
                                                    'createAuth',
                                                    auth.authKey,
                                                );
                                            }}
                                            checked={auth.authValue}
                                        >
                                            {auth.authName}
                                        </Checkbox>
                                    );
                                })}
                            </div>
                        );
                    },
                },
            ]}
        />
    );
};

export default FRMFieldTable;

export const f = [
    {
        key: 'sales-follow-up',
        children: [
            {
                key: 'sales-follow-up:contacts',
                children: [
                    {
                        key: 'sales-follow-up:contacts:all',
                        children: [
                            {
                                key: 'sales-follow-up:contacts:all:create',
                            },
                            {
                                key: 'sales-follow-up:contacts:all:update',
                            },
                            {
                                key: 'sales-follow-up:contacts:all:delete',
                            },
                            {
                                key: 'sales-follow-up:contacts:all:transform',
                            },
                            {
                                key: 'sales-follow-up:contacts:all:merge',
                            },
                        ],
                    },
                    {
                        key: 'sales-follow-up:contacts:mine',
                        children: [
                            {
                                key: 'sales-follow-up:contacts:mine:create',
                            },
                            {
                                key: 'sales-follow-up:contacts:mine:update',
                            },
                            {
                                key: 'sales-follow-up:contacts:mine:delete',
                            },
                            {
                                key: 'sales-follow-up:contacts:mine:transform',
                            },
                            {
                                key: 'sales-follow-up:contacts:mine:merge',
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
