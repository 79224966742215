import React from 'react';
import { DTable, isMultipleCell, ICellMultiple, ICell } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ProductListStore } from '../store';
import { runInAction } from 'mobx';
import { applyCustomConfToCellsSelector } from '@/components/common/form/advanced-filters';
import { tryRefresh } from '@/utils';

const DemoTable: React.FC<{
    defaultStore: ProductListStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const { defaultMSelect } = defaultStore;
    const { mSeletedData, setMSelectedData } = defaultMSelect;

    const dataTable = (
        <>
            <DTable
                onColumnsUpdated={() => tryRefresh(['product'])}
                defaultStore={defaultStore}
                sceneCode={'crm_om_product'}
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [
                        {
                            actionKey: 'update',
                            actionCn: '修改',
                        },
                        {
                            actionKey: 'delete',
                            actionCn: '删除',
                        },
                    ];
                    props.onAction = (key, data) => {
                        if (key === 'update') {
                            runInAction(() => {
                                defaultStore.setActionAsync('update', {
                                    id: data.id,
                                });
                            });
                        } else if (key === 'delete') {
                            runInAction(() => {
                                defaultStore.setAction('delete');
                                defaultStore.setMutatingData(data);
                            });
                        } else if (key === 'see-equity-detail') {
                            runInAction(() => {
                                defaultStore.setAction('see-equity-detail');
                                defaultStore.setCurrentEquityDetailsFocusedData(
                                    data,
                                );
                                defaultStore.fetchEquityDetailsData(
                                    data.equity_detail,
                                );
                            });
                        }
                    };
                    props.closeRowSelection = false;
                    props.selectedItems = mSeletedData;
                    props.onSelectedDataChange = setMSelectedData;
                    props.widthInfoKey = 'product-list-default';
                    return props;
                }}
                cellsSelectorTransformer={baiscCellsSelector => {
                    // EquityDetail
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'equity_detail',
                        () => {
                            const nextCellSelector: ICell<any> = {
                                colKey: 'equity_detail',
                                inputs: ['equity_detail'],
                                cell: 'EquityDetail',
                            };
                            return nextCellSelector;
                        },
                    );
                    return baiscCellsSelector;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
