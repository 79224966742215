import React, { memo } from 'react';
import { AutoEllipsis } from '../../auto-ellipsis';
import { allCells } from './index';
import { Badge, Tag, Tooltip } from 'antd';
import { BusinessRegistrationDisplay, CusTag } from './business-registration';
import moment from 'moment';
import './action-param-customer.scss';
import { getCustomerTags } from '@/pages/sales-follow-up/custom/list/utils';

export const ActionParamCustomer: React.FC<{
    args: any[];
    item: any;
    onAction: (...args: any[]) => void;
}> = memo(({ args, item, onAction }) => {
    const value = args[0];
    const options = args[1] || {};
    const paramName = options.paramName || 'unknow';
    const actionName = 'reveal-param/' + paramName;

    const originData = item;
    const { customer_status, score_remind, score_remind_detail } = item;
    const {
        isNotStanderCustomer,
        haveCID,
        isLock,
        isChildrenCustomer,
        isNewEconomyCompany_Toubu,
        isNewEconomyCompany_Yaobu,
        isKP,
        isGaoqian,
    } = getCustomerTags(originData);

    if (value) {
        let prepend: any = [];
        let count = 0;
        if (isNotStanderCustomer) {
            count++;
            prepend.push(
                <CusTag
                    style={{ marginRight: -4 }}
                    color="#ff001f"
                    text="非标"
                />,
            );
        }
        if (isLock) {
            count++;
            prepend.push(
                <CusTag
                    style={{ marginRight: -4 }}
                    color="#ff001f"
                    text="锁定"
                />,
            );
        }
        if (isChildrenCustomer) {
            count++;
            prepend.push(
                <CusTag
                    style={{ marginRight: -4 }}
                    color="#FFA408"
                    text="子客户"
                />,
            );
        }
        // if (!haveCID) {
        //     prepend.push(<CusTag style={{marginRight: -4}} color="#FFA408" text="无CID" />);
        // }
        if (!!originData?.cross_region_tag) {
            count++;
            prepend.push(
                <CusTag
                    style={{ marginRight: -4 }}
                    color="#FFA408"
                    text="跨区"
                />,
            );
        }
        // if (isNewEconomyCompany_Toubu) {
        //     count++;
        //     prepend.push(
        //         <CusTag
        //             style={{ marginRight: -4 }}
        //             color="rgb(44, 163, 31)"
        //             text="新经济"
        //         />,
        //     );
        // }
        // if (isNewEconomyCompany_Yaobu) {
        //     count++;
        //     prepend.push(
        //         <CusTag
        //             style={{ marginRight: -4 }}
        //             color="rgb(44, 163, 31)"
        //             text="新经济"
        //         />,
        //     );
        // }
        if (isKP) {
            count++;
            prepend.push(
                <CusTag
                    style={{ marginRight: -4 }}
                    color="rgb(44, 163, 31)"
                    text="高价值"
                />,
            );
        }
        if (isGaoqian) {
            count++;
            prepend.push(
                <CusTag
                    style={{ marginRight: -4 }}
                    color="rgb(44, 163, 31)"
                    text="高潜"
                />,
            );
        }
        // 4 / 156
        // 3 / 118
        // 2 / 80
        // 1 / 42

        const colCount = Math.ceil(count / 2);
        const w = (colCount ? 2 : 0) + 38 * colCount;
        return (
            <a
                style={{ position: 'relative' }}
                onClick={() => {
                    onAction(actionName, item);
                }}
            >
                <AutoEllipsis
                    style={{ paddingLeft: w }}
                    text={
                        <>
                            {customer_status !== 2 && score_remind === 1 && (
                                <Tooltip title={score_remind_detail}>
                                    <Badge color="red" />
                                </Tooltip>
                            )}
                            {count !== 0 && (
                                <span
                                    style={count === 1 ? { top: -2 } : {}}
                                    className="action-param-customer-minitags"
                                >
                                    {prepend}
                                </span>
                            )}

                            {value}
                        </>
                    }
                />
            </a>
        );
    }
    return null;
});
