import React from 'react';
import { TableLv2, applyParamListToColumns } from '@/components';
import _ from 'lodash';
import { columnsConfig, cellsSelector } from './table-options';
import { NexusGenFieldTypes } from '@/definations/graphql/auto-gen';
import { observer } from 'mobx-react-lite';
import { ListMeta } from '@/stores/utils';
import { CrudDemo } from '../store';

const FormDemoTable: React.FC<{
    demoCurd: CrudDemo;
    loading: boolean;
}> = ({ demoCurd, loading }) => {
    const {
        data,
        defaultPagination,
        defaultSorter,
        mSeletedData,
        setMSelectedData,
        defaultMeta,
    } = demoCurd;
    const { setPaginationByUI, paginationForUI, amount } = defaultPagination;

    const { setSorter } = defaultSorter;

    const tableColsParamsToDisplay = ListMeta.composeParamsFromUserAndAll(
        defaultMeta.tableColsParamsFromUserSetting,
        defaultMeta.tableColsParamsAll,
    );
    const finalColunmsConfig = applyParamListToColumns(
        columnsConfig,
        tableColsParamsToDisplay,
    );

    const dataTable = (
        <TableLv2<NexusGenFieldTypes['Author']>
            loading={loading}
            dataSource={data}
            columns={finalColunmsConfig}
            cellsSelector={cellsSelector}
            onPaginationChange={setPaginationByUI}
            onSorterChange={setSorter}
            pagination={{
                ...paginationForUI,
                total: amount,
            }}
            extra={undefined}
            selectedItems={mSeletedData}
            onSelectedDataChange={setMSelectedData}
            // tslint:disable-next-line:object-literal-key-quotes
            disabledSelectionItems={{ '1': true }}
            actions={[
                {
                    actionKey: 'modify',
                    actionCn: '修改',
                },
                {
                    actionKey: 'modify',
                    actionCn: '修改',
                },
                {
                    actionKey: 'delete',
                    actionCn: '删除',
                },
                {
                    actionKey: 'delete',
                    actionCn: '删除',
                },
            ]}
            onAction={(key, data) => {
                console.log('action!', key, data);
            }}
        />
    );

    return dataTable;
};

const FinalFormTable = observer(FormDemoTable);
export default FinalFormTable;
