import React, { useEffect, useContext, useState } from 'react';
import {
    DForm,
    IProcessCtx,
    IDFormProps,
    applyCustomConfToWidget,
    applyConfToFormItem,
    AntTable,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CtxContext } from '@/utils/context';
import { StoreContext } from '@/stores';

import { IProcessComProps, IProcessComs } from './def';
import { widgets } from '@/pages/demo2/crud/filter-options';
import { getValidatorByParamMeta } from '@/stores/utils';
import { arrToJson, defaultAxios, isNetSuccess } from '@/utils';
import { commonQuery } from '@/api/rest';
import { seeContractProcess } from '@/pages/om/contract/list/store';
import { message, Icon, Alert } from 'antd';

const openAdminSuffixes = ['', '2', '3', '4', '5'];

const disabledParams = new Set([
    'customer_id',
    'opportunity_id',
    'contract_product_id',
    'product_purchase_amount_ratio',
]);

const DemoForm: React.FC<IProcessComProps> = ({
    defaultStore,
    processCtx,
    tableCode,
    mode,
}) => {
    const ctx = useContext(CtxContext);
    const { position, positionDetail } = ctx;
    const isCreate = mode === 'create';
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const sysId = authStore?.userInfo?.sysUser?.id;

    const { defaultMeta } = defaultStore;
    const { mutatingData } = defaultStore;

    const [
        fillContractProductOpenLoading,
        setFillContractProductOpenLoading,
    ] = useState(false);

    if (!defaultMeta || !mutatingData) {
        return null;
    }
    const { objectMetaMap } = defaultMeta;

    const baiscWidgets = defaultMeta.defaultWidgetsAllWithoutGroupCheck;

    const collapseLayout = defaultMeta.defaultFormCollapseLayout;
    const shouldGroup = collapseLayout.length > 0;

    const layout = new Array(Math.ceil(baiscWidgets.length / 2)).fill([24]);
    if (shouldGroup) {
        for (const colla of collapseLayout) {
            colla.layout = layout;
        }
    }

    if (processCtx === null || !processCtx.currentUser) {
        return null;
    }

    const props: IDFormProps = {
        processCtx,
        defaultStore: defaultStore as any,
        widgetsGenerator: store => {
            return store.defaultMeta.defaultWidgetsAllWithoutGroupCheck;
        },
    };

    if (shouldGroup) {
        props.options = {
            grouped: true,
        };
    } else {
        props.propsTransformer = props => {
            props.layout = layout;
            return props;
        };
    }

    if (tableCode === null) {
        return null;
    }

    let { opportunity_win_rate } = mutatingData;
    opportunity_win_rate = _.isNil(opportunity_win_rate)
        ? 99
        : opportunity_win_rate;
    props.labelsTransformer = originalLabels => {
        const nextLabels = [...originalLabels];
        nextLabels.forEach(label => {
            if (label.key === 'product_operate_file') {
                if (opportunity_win_rate < 60) {
                    label.required = true;
                } else {
                    label.required = false;
                }
            }
            if (label.key === 'product_purchase_amount_ratio') {
                label.style = { width: 270 };
            }
        });
        return nextLabels;
    };

    props.validatorsTransformer = originalValidators => {
        const nextValidators = { ...originalValidators };
        const keys = _.keys(nextValidators);
        keys.forEach(k => {
            if (k === 'product_operate_file') {
                const paramsMeta = _.cloneDeep(objectMetaMap[k]);
                if (opportunity_win_rate < 60) {
                    paramsMeta.required = true;
                } else {
                    paramsMeta.required = false;
                }
                nextValidators[k] = getValidatorByParamMeta(paramsMeta);
            }
        });
        return nextValidators;
    };

    props.widgetsTransformer = widgets => {
        applyCustomConfToWidget(baiscWidgets, 'contract_code', nextWidget => {
            nextWidget.nextlineWidget = () => {
                const contractId = mutatingData?.contract_id?.originalValue;
                if (!contractId) {
                    return null;
                }
                return (
                    <div style={{ paddingTop: 4 }}>
                        <a
                            onClick={() => {
                                if (contractId) {
                                    seeContractProcess(contractId);
                                } else {
                                    message.error('合同id不存在');
                                }
                            }}
                        >
                            查看合同
                            <Icon type="link" />
                        </a>
                    </div>
                );
            };
            return nextWidget;
        });

        for (const widget of widgets) {
            if (disabledParams.has(widget.key as string)) {
                widget.status = {
                    ...widget.status,
                    disabled: true,
                };
            }
            if ((widget.key as string) === 'product_purchase_amount_ratio') {
                if (widget.numberOptions) {
                    widget.numberOptions.addonAfter = <span>%</span>;
                }
            }
        }

        applyCustomConfToWidget(widgets, 'contract_product_id', widget => {
            widget.status = {
                ...widget.status,
                loading: fillContractProductOpenLoading,
            };
            if (widget.objectOptions) {
                widget.objectOptions.defaultFilters = [
                    {
                        fieldName: 'contract_id',
                        operateType: 'EQUAL',
                        fieldValue: mutatingData.contract_id?.originalValue,
                    },
                ];
            }
            return widget;
        });

        // product_purchase_storage_data
        applyCustomConfToWidget(
            widgets,
            'product_purchase_storage_data',
            widget => {
                widget.nextlineWidget = () => {
                    return (
                        <div style={{ paddingTop: 4 }}>
                            <Alert message="如未提供，请先提供入库资料" />
                        </div>
                    );
                };
                return widget;
            },
        );

        // product_operate_file

        return widgets;
    };
    props.onChange = (key, value, widgetKey) => {
        if (key === 'contract_product_id') {
            const contractProductId = value?.originalValue;
            if (contractProductId) {
                setFillContractProductOpenLoading(true);

                // TODOO
                defaultAxios
                    .put(
                        '/bff/api/rest/populate?tableCode=process_contract_employer_open_table',
                        {
                            contract_product_id: contractProductId,
                        },
                    )
                    .then(([d, e]) => {
                        setFillContractProductOpenLoading(false);
                        if (isNetSuccess(d, e)) {
                            const datum = d?.data;
                            delete datum.contract_product_id;
                            if (datum) {
                                _.assign(mutatingData, datum);
                            }
                        }
                    });
            }
        }

        if (key === 'product_purchase_amount' || key === 'product_amount') {
            // product_purchase_amount_ratio
            let ppa: any;
            let pa: any;
            if (key === 'product_purchase_amount') {
                ppa = value;
                pa = mutatingData.product_amount;
            }
            if (key === 'product_amount') {
                ppa = mutatingData.product_purchase_amount;
                pa = value;
            }

            const r = (100 * ppa) / pa;
            if (!Number.isNaN(r)) {
                mutatingData.product_purchase_amount_ratio = r.toFixed(2);
            } else {
                mutatingData.product_purchase_amount_ratio = null;
            }
        }
    };

    const form = <DForm {...props} />;
    return form;
};

export const ProcessContractEmployerOpenTable = observer(DemoForm);
