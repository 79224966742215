import { RouteComponentProps } from '@reach/router';
import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { observer } from 'mobx-react-lite';
import {
    defaultLabelsGenerator,
    defaultCellsSelectorGenerator,
} from '@/components';
import { useAnyCustomerStore } from '@/pages/sales-follow-up/custom/list/store';
import {
    EditableDisplay,
    getAutoOnChangeHandler,
} from '@/components/common/editable-display';
import { makeAutoObservable } from 'mobx';

import _ from 'lodash';
import { embedDataToWidgets } from '@/components/common/form/advanced-filters';
import { ListMeta } from '@/stores/utils';
import { IDFormStore, IWidgetItem } from '../form';
import {
    IDTableStore,
    TCellsSelector,
    defaultCellsSelectorTransformer,
} from '../table';
import { defaultWidgetsTransformer } from '../form/dform';
import { getParamPermApi } from '@/stores/perm';
import { arrToJson } from '@/utils';
import { loadingWrapper } from '../block-loading';

class DDetailStore {
    public editingMap: { [key: string]: boolean } = {};
    public editSomeKey(keys: string[]) {
        const nextEditingMap: { [key: string]: boolean } = _.cloneDeep(
            this.editingMap,
        );
        for (const key of keys) {
            nextEditingMap[key] = true;
        }
        this.editingMap = nextEditingMap;
    }
    public cancelEditingAll() {
        this.editingMap = {};
    }

    public confirmedData: any = {};
    public editingData: any = {};

    public setEditingData(nextData: any) {
        this.editingData = nextData;
    }
    public confirmEditingData() {
        this.confirmedData = _.cloneDeep(this.editingData);
    }
    public cancelEditingData() {
        this.editingData = _.cloneDeep(this.confirmedData);
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export interface IDDetail<T = any> {
    defaultStore: IDFormStore &
        IDTableStore & {
            saveDDetail?: (data: any) => Promise<boolean>;
        };
    eventCode?: string;
    cellsSelectorTransformer?: (
        cellsSelector: TCellsSelector<T>,
    ) => TCellsSelector<T>;
    widgetsTransformer?: (
        widgets: Array<IWidgetItem<T>>,
    ) => Array<IWidgetItem<T>>;
    outerLoading?: boolean;
    formEventCode?: string;
    editableBlackList?: Set<string>;
    editableWhiteList?: Set<string>;
    invisibleMap?: { [key: string]: boolean };
    hiddenWhenNoBoys?: boolean;
    cellNextLineItems?: { [key: string]: any };
}

export const DDetail: React.FC<IDDetail> = observer(
    ({
        defaultStore,
        eventCode,
        cellsSelectorTransformer,
        widgetsTransformer,
        outerLoading,
        formEventCode,
        editableBlackList,
        editableWhiteList,
        invisibleMap,
        hiddenWhenNoBoys,
        cellNextLineItems,
    }) => {
        cellsSelectorTransformer =
            cellsSelectorTransformer || defaultCellsSelectorTransformer;

        widgetsTransformer = widgetsTransformer || defaultWidgetsTransformer;

        const [editableDisplayStore] = useState(() => new DDetailStore());
        const { mutatingData, defaultMeta } = defaultStore;

        const { inited } = defaultMeta;

        // 可编辑字段的白名单
        // 都不可编辑，如果以后需要，再搞
        // 如果提供了formEventCode，就可以编辑
        const [editableMap, setEditableMap] = useState<{
            [key: string]: boolean;
        }>({});
        const [loading, setLoading] = useState(false);
        const [confirmLoading, setConfirmLoading] = useState(false);

        const finalLoading = outerLoading || loading;

        useEffect(() => {
            if (inited && formEventCode) {
                (async () => {
                    setLoading(true);
                    const list = await defaultMeta.asyncFormParamsAll(
                        getParamPermApi(defaultMeta.tableId, formEventCode),
                    );
                    setLoading(false);
                    let ks = _.keys(
                        arrToJson(
                            list.filter(item => true === item.editable),
                            'key',
                        ),
                    );
                    if (editableWhiteList) {
                        ks = ks.filter(k => editableWhiteList.has(k));
                    } else if (editableBlackList) {
                        ks = ks.filter(k => !editableBlackList.has(k));
                    }
                    setEditableMap(
                        _.reduce(
                            ks,
                            (ret, cur) => {
                                ret[cur] = true;
                                return ret;
                            },
                            {} as any,
                        ),
                    );
                })();
            }
        }, [inited, formEventCode]);

        useEffect(() => {
            if (mutatingData === null) {
                return;
            }
            editableDisplayStore.setEditingData(_.cloneDeep(mutatingData));
            editableDisplayStore.confirmEditingData();
        }, [mutatingData]);

        const { editingMap, editingData, confirmedData } = editableDisplayStore;
        useEffect(() => {
            // callback with confirmedData!
        }, [confirmedData]);

        const [tableColsParamsAll, setTableColsParamsAll] = useState<
            any[] | null
        >(null);

        useEffect(() => {
            if (inited) {
                if (eventCode) {
                    (async () => {
                        const list = await defaultMeta.asyncTableColsParamsAll(
                            getParamPermApi(defaultMeta.tableId, eventCode),
                        );
                        setTableColsParamsAll(list);
                    })();
                } else {
                    setTableColsParamsAll(defaultMeta.tableColsParamsAll);
                }
            }
        }, [inited, eventCode]);

        if (!tableColsParamsAll) {
            return null;
        }
        const formParamsAllMap = arrToJson(tableColsParamsAll || [], 'key');

        const onDataChange = getAutoOnChangeHandler(editingData, (data: any) =>
            editableDisplayStore.setEditingData(data),
        );

        const baiscWidgets = _.cloneDeep(
            defaultMeta.defaultWidgetsAllWithoutGroupCheck,
        );
        baiscWidgets.forEach(widget => {
            const key = widget.key as string;
            widget.status = widget.status || {};
            if (!!formParamsAllMap[key]) {
                widget.status.hidden = false;
            } else {
                widget.status.hidden = true;
            }
        });

        const basicformformItemOptions = defaultLabelsGenerator(defaultStore);
        const baiscCellsSelector = cellsSelectorTransformer(
            defaultCellsSelectorGenerator(defaultStore),
        );
        const layout = new Array(Math.ceil(baiscWidgets.length / 2)).fill([
            12,
            12,
        ]);
        embedDataToWidgets<any>(widgetsTransformer(baiscWidgets), editingData);

        const collapseLayout = _.cloneDeep(
            defaultMeta.defaultFormCollapseLayout,
        );
        for (const colla of collapseLayout) {
            colla.layout = layout;
        }
        const collapseProps = {
            defaultActiveKey: collapseLayout.map(item => item.groupKey),
        };

        // 是可以支持多编辑的，这里不打开
        const isMultipleEditing = false;

        if (!inited || !mutatingData) {
            return null;
        }

        console.log('editableMap', editableMap);

        return loadingWrapper(finalLoading)(
            <div>
                <EditableDisplay<any>
                    className="ddetail"
                    data={editingData}
                    widgets={baiscWidgets}
                    onChange={(key, value, k) => {
                        onDataChange(key, value, k);
                    }}
                    collapseLayout={collapseLayout}
                    collapseProps={collapseProps}
                    formItemOptions={basicformformItemOptions}
                    cellNextLineItems={cellNextLineItems}
                    layout={layout}
                    rowGutter={[0, 0]}
                    confirmLoading={confirmLoading}
                    iconVisibleWhenHover={true}
                    onInteract={async (key, type, value) => {
                        if (
                            type === 'start-editing-display-param' &&
                            _.isString(key)
                        ) {
                            editableDisplayStore.cancelEditingAll();
                            editableDisplayStore.cancelEditingData();
                            editableDisplayStore.editSomeKey([key]);
                        } else if (type === 'confirm-editing-display-param') {
                            if (defaultStore.saveDDetail) {
                                setConfirmLoading(true);
                                const isSucc = await defaultStore.saveDDetail(
                                    editableDisplayStore.editingData,
                                );
                                setConfirmLoading(false);
                                if (isSucc) {
                                    editableDisplayStore.confirmEditingData();
                                    editableDisplayStore.cancelEditingAll();
                                    message.success('保存成功');
                                } else {
                                    message.error('保存失败');
                                }
                            } else {
                                editableDisplayStore.confirmEditingData();
                                editableDisplayStore.cancelEditingAll();
                            }
                        } else if (type === 'cancel-editing-display-param') {
                            editableDisplayStore.cancelEditingAll();
                            editableDisplayStore.cancelEditingData();
                            message.info('变更已取消');
                        }
                    }}
                    labelWidth={15}
                    validateResult={{}}
                    cellsSelector={baiscCellsSelector}
                    editingMap={editingMap}
                    editableMap={editableMap}
                    invisibleMap={invisibleMap}
                    isMultipleEditing={isMultipleEditing}
                    hiddenWhenNoBoys={hiddenWhenNoBoys}
                    emptyText={'- -'}
                ></EditableDisplay>
            </div>,
        );
    },
);
