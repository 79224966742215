import React, { useState, useEffect, useContext } from 'react';
import './index.less';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import {
    IHomePreData,
    IOrgTreeDto,
    homeRangeOptions,
    homeTargetLevelOptions,
} from '../../store';
import { Select, Popover, Progress, Spin } from 'antd';
import _ from 'lodash';
import { toJS } from 'mobx';
import { defaultAxios } from '@/utils';
import { trans2DotTwo } from '@/pages/analysis/dashboard/composite-live/component/achievement-situation';

const keyToTreeType: {
    [key: string]: any;
} = {
    0: 'country',
    1: 'group',
    2: 'city',
    3: 'area',
    4: 'sale',
};
const treeTypeToKey: {
    [key: string]: any;
} = {
    country: 0,
    group: 1,
    city: 2,
    area: 3,
    sale: 4,
};
interface IPopOverStepItemProps {
    itemKey: number;
    renderListMap: any;
    onPopItemClick: (itemKey: number, selectedItem: any) => void;
}
const PopOverStepItem: React.FC<IPopOverStepItemProps> = observer(props => {
    const { itemKey, renderListMap, onPopItemClick } = props;
    const [renderChooseListData, setRenderChooseListData] = useState<any>([]);
    const [finalRenderListData, setFinalRenderListData] = useState<
        {
            name: string;
            id: string | number;
            rate: number;
            rankName: string;
        }[]
    >([]);
    const store = useContext(StoreContext);
    const newHomeStore = store.getDefaultHomeNewStore();
    const { homeNewSelectedData, homeNewFilterData } = newHomeStore;
    const [headerStepLoading, setHeaderStepLoading] = useState(false);
    const [popOverStepVis, setPopOverStepVis] = useState(false);

    const fetchHeaderStepRate = async () => {
        setHeaderStepLoading(true);
        const url = '/crm/index/incomeSpeed';
        const idList = _.map(renderChooseListData, (item: any) => {
            return itemKey === 4
                ? item.userId
                : {
                      orgId: item.dimPart,
                      orgType: item.orgType,
                  };
        });
        const params = {
            timeType: homeNewFilterData.range,
            targetLevel: homeNewFilterData.targetLevel,
            orgList: itemKey === 4 ? undefined : idList,
            userIdList: itemKey === 4 ? idList : undefined,
        };
        const [d, e] = await defaultAxios.post(url, params);
        setHeaderStepLoading(false);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            const finalData = _.map(data, (item: any) => {
                const exItem = _.find(renderChooseListData, cItem => {
                    if (itemKey === 4) {
                        return cItem.userId == item.userId;
                    } else {
                        return cItem.dimPart == item.orgId;
                    }
                });
                return {
                    name: itemKey === 4 ? exItem.userName : exItem.name,
                    id: itemKey === 4 ? item.userId : item.orgId,
                    rate: item.speed,
                    rankName: itemKey === 4 ? exItem.rankName : '',
                };
            });
            setFinalRenderListData(finalData);
        }
        return [data, error];
    };
    useEffect(() => {
        const renderList = renderListMap[itemKey];
        if (renderList && renderList?.length > 0) {
            setRenderChooseListData(renderList);
        }
    }, [renderListMap]);
    useEffect(() => {
        if (popOverStepVis) {
            fetchHeaderStepRate();
        }
    }, [popOverStepVis]);
    const rangeSelectedOptionItem = _.find(homeRangeOptions, opt => {
        return opt.value == homeNewFilterData.range;
    });
    const levelSelectedOptionItem = _.find(homeTargetLevelOptions, opt => {
        return opt.value == homeNewFilterData.targetLevel;
    });
    return renderChooseListData && renderChooseListData.length > 0 ? (
        <Popover
            className={'popover-step-item'}
            visible={popOverStepVis}
            onVisibleChange={vis => {
                setPopOverStepVis(vis);
            }}
            content={
                <Spin spinning={headerStepLoading}>
                    <div className="new-home-header-popover-content">
                        {finalRenderListData.map(item => {
                            const id = item.id;
                            // itemKey === 4 ? item.userId : item.dimPart;
                            const name = item.name;
                            // itemKey === 4 ? item.userName : item.name;
                            const rate = item.rate;
                            const isSelected =
                                homeNewSelectedData.selectedId === id &&
                                homeNewSelectedData.selectedLevel === itemKey;
                            return (
                                <div
                                    className="new-home-header-popover-content-item"
                                    onClick={() => {
                                        newHomeStore.setHomeNewSelectedData({
                                            selectedLevel: itemKey,
                                            selectedId: id,
                                            selectedText: name,
                                            selectedRankName: item.rankName,
                                        });
                                        onPopItemClick(itemKey, {
                                            text: name,
                                            value: id,
                                        });
                                        setPopOverStepVis(false);
                                    }}
                                >
                                    <div className="home-header-popover-item-info">
                                        {isSelected ? (
                                            <img
                                                src={`${process.env.PUBLIC_URL}/imgs/home/headerItemSelected.png`}
                                                alt=""
                                            />
                                        ) : (
                                            <img
                                                src={`${process.env.PUBLIC_URL}/imgs/home/headerItemSelect.png`}
                                                alt=""
                                            />
                                        )}
                                        <span className="home-header-popover-item-name">
                                            {name}
                                        </span>
                                        <span className="home-header-popover-item-rate">{`${
                                            rangeSelectedOptionItem?.label
                                        }目标完成率：${trans2DotTwo(
                                            rate,
                                        )}%`}</span>
                                    </div>
                                    <Progress
                                        percent={rate}
                                        showInfo={false}
                                        strokeColor={'#0052FF'}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Spin>
            }
            trigger="click"
            placement="bottomLeft"
        >
            {props.children}
        </Popover>
    ) : (
        (props.children as any)
    );
});
const NewHomeHeader: React.FC = () => {
    const store = useContext(StoreContext);
    const newHomeStore = store.getDefaultHomeNewStore();
    const {
        homeNewSelectedData,
        setHomeNewSelectedData,
        homePerData,
        homePreDataCanViewMap,
    } = newHomeStore;
    const [
        selectedHomeHeaderTreeData,
        setSelectedHomeHeaderTreeData,
    ] = useState<{
        country?: {
            text: string;
            value: string | number;
        };
        area?: {
            text: string;
            value: string | number;
        };
        city?: {
            text: string;
            value: string | number;
        };
        group?: {
            text: string;
            value: string | number;
        };
        sale?: {
            text: string;
            value: string | number;
        };
        [key: string]:
            | {
                  text: string;
                  value: string | number;
              }
            | undefined;
    }>({});
    const [
        canHeaderTreeViewRenderList,
        setCanHeaderTreeViewRenderList,
    ] = useState<{
        0?: IOrgTreeDto[];
        1?: IOrgTreeDto[];
        2?: IOrgTreeDto[];
        3?: IOrgTreeDto[];
        4?: IOrgTreeDto[];
    }>({});
    // const findDeepView = () => {
    //     let deepViewLevel = 1;
    //     const findFunction = (cData: IOrgTreeDto[]) => {
    //         if (cData?.[0].canView) {
    //             deepViewLevel = deepViewLevel + 1;
    //             if (cData?.[0].childOrgList && cData?.[0].childOrgList.length) {
    //                 findFunction(cData?.[0].childOrgList);
    //             } else if (
    //                 cData?.[0].userInfoList &&
    //                 cData?.[0].userInfoList.length &&
    //                 cData?.[0].userInfoList[0].canView
    //             ) {
    //                 deepViewLevel = deepViewLevel + 1;
    //             } else {
    //                 return;
    //             }
    //         } else {
    //             return;
    //         }
    //     };
    //     if (
    //         homePerData.indexViewOrgTreeDto &&
    //         homePerData.indexViewOrgTreeDto.childOrgList
    //     ) {
    //         findFunction(homePerData.indexViewOrgTreeDto.childOrgList);
    //     }
    //     return deepViewLevel;
    // };
    const onHeaderPopOverClick = (itemKey: number, selectedItem: any) => {
        if (selectedItem && !_.isNil(itemKey)) {
            if (itemKey === 4) {
                setSelectedHomeHeaderTreeData({
                    ...selectedHomeHeaderTreeData,
                    [keyToTreeType[itemKey]]: selectedItem,
                });
            } else if (itemKey === 3) {
                setSelectedHomeHeaderTreeData({
                    [keyToTreeType[itemKey]]: selectedItem,
                    country: selectedHomeHeaderTreeData.country,
                });
            } else if (itemKey === 2) {
                setSelectedHomeHeaderTreeData({
                    [keyToTreeType[itemKey]]: selectedItem,
                    country: selectedHomeHeaderTreeData.country,
                    area: selectedHomeHeaderTreeData.area,
                });
            } else if (itemKey === 1) {
                setSelectedHomeHeaderTreeData({
                    [keyToTreeType[itemKey]]: selectedItem,
                    country: selectedHomeHeaderTreeData.country,
                    area: selectedHomeHeaderTreeData.area,
                    city: selectedHomeHeaderTreeData.city,
                });
            } else if (itemKey === 0) {
                setSelectedHomeHeaderTreeData({
                    [keyToTreeType[itemKey]]: selectedItem,
                });
            }
        }
    };
    const renderSelectTree = () => {
        const { selectedLevel } = homeNewSelectedData;
        if (homePerData.indexViewOrgTreeDto) {
            return (
                <div className="new-home-header-select-tree-content">
                    {/* {homePreDataCanViewMap[0] &&
                    homePreDataCanViewMap[0].length ? ( */}
                    <>
                        <PopOverStepItem
                            itemKey={0}
                            renderListMap={canHeaderTreeViewRenderList}
                            onPopItemClick={onHeaderPopOverClick}
                        >
                            <div
                                className={`n-h-h-tree-item item-country ${
                                    selectedLevel === 0 ? 'selected' : ''
                                } `}
                                onClick={() => {}}
                            >
                                {selectedHomeHeaderTreeData.country?.text ||
                                    '国家'}
                            </div>
                        </PopOverStepItem>
                        <img
                            className="n-h-h-tree-arrow"
                            src={`${process.env.PUBLIC_URL}/imgs/home/headerArrow.png`}
                        />
                    </>
                    {/* ) : null} */}
                    {/* {homePreDataCanViewMap[3] &&
                    homePreDataCanViewMap[3].length ? ( */}
                    <>
                        <PopOverStepItem
                            itemKey={3}
                            renderListMap={canHeaderTreeViewRenderList}
                            onPopItemClick={onHeaderPopOverClick}
                        >
                            <div
                                className={`n-h-h-tree-item item-area ${
                                    selectedLevel === 3 ? 'selected' : ''
                                } `}
                                onClick={() => {}}
                            >
                                {selectedHomeHeaderTreeData.area?.text ||
                                    '区域'}
                                <div className="n-h-h-tree-triangle"></div>
                            </div>
                        </PopOverStepItem>
                        <img
                            className="n-h-h-tree-arrow"
                            src={`${process.env.PUBLIC_URL}/imgs/home/headerArrow.png`}
                        />
                    </>
                    {/* ) : null} */}
                    {/* {homePreDataCanViewMap[2] &&
                    homePreDataCanViewMap[2].length ? ( */}
                    <>
                        <PopOverStepItem
                            itemKey={2}
                            renderListMap={canHeaderTreeViewRenderList}
                            onPopItemClick={onHeaderPopOverClick}
                        >
                            <div
                                className={`n-h-h-tree-item item-city ${
                                    selectedLevel === 2 ? 'selected' : ''
                                } `}
                                onClick={() => {}}
                            >
                                {selectedHomeHeaderTreeData.city?.text ||
                                    '城市'}
                                <div className="n-h-h-tree-triangle"></div>
                            </div>
                        </PopOverStepItem>
                        <img
                            className="n-h-h-tree-arrow"
                            src={`${process.env.PUBLIC_URL}/imgs/home/headerArrow.png`}
                        />
                    </>
                    {/* ) : null} */}
                    {/* {homePreDataCanViewMap[1] &&
                    homePreDataCanViewMap[1].length ? ( */}
                    <>
                        <PopOverStepItem
                            itemKey={1}
                            renderListMap={canHeaderTreeViewRenderList}
                            onPopItemClick={onHeaderPopOverClick}
                        >
                            <div
                                className={`n-h-h-tree-item item-area ${
                                    selectedLevel === 1 ? 'selected' : ''
                                } `}
                                onClick={() => {}}
                            >
                                {selectedHomeHeaderTreeData.group?.text ||
                                    '小组'}
                                <div className="n-h-h-tree-triangle"></div>
                            </div>
                        </PopOverStepItem>
                        <img
                            className="n-h-h-tree-arrow"
                            src={`${process.env.PUBLIC_URL}/imgs/home/headerArrow.png`}
                        />
                    </>
                    {/* ) : null} */}

                    {/* {homePreDataCanViewMap[4] &&
                    homePreDataCanViewMap[4].length ? ( */}
                    <>
                        <PopOverStepItem
                            itemKey={4}
                            renderListMap={canHeaderTreeViewRenderList}
                            onPopItemClick={onHeaderPopOverClick}
                        >
                            <div
                                className={`n-h-h-tree-item item-sale ${
                                    selectedLevel === 4 ? 'selected' : ''
                                } `}
                                onClick={() => {}}
                            >
                                {selectedHomeHeaderTreeData.sale?.text ||
                                    '销售'}
                                <div className="n-h-h-tree-triangle"></div>
                            </div>
                        </PopOverStepItem>
                    </>
                    {/* ) : null} */}
                </div>
            );
        }
    };
    const findDeepSelected = () => {
        let deepLevel = 0;
        const selectedLevelItem: any = {
            country: {
                value: homePerData?.indexViewOrgTreeDto?.dimPart,
                text: homePerData?.indexViewOrgTreeDto?.name,
            },
        };
        let selectedItem: any = {};
        const findFunction = (cData: IOrgTreeDto[]) => {
            const childFirstData = _.find(cData, c => {
                return c.selected;
            });
            if (childFirstData && childFirstData.selected) {
                deepLevel = childFirstData.orgType;
                selectedItem = childFirstData;
                selectedLevelItem[keyToTreeType[childFirstData.orgType]] = {
                    value: childFirstData.dimPart,
                    text: childFirstData.name,
                };
                if (
                    childFirstData.childOrgList &&
                    childFirstData.childOrgList.length
                ) {
                    findFunction(childFirstData.childOrgList);
                } else if (
                    childFirstData.userInfoList &&
                    childFirstData.userInfoList.length &&
                    childFirstData.userInfoList[0].selected
                ) {
                    deepLevel = 4;
                    selectedItem = childFirstData.userInfoList[0];
                    selectedLevelItem.sale = {
                        value: childFirstData.userInfoList[0].userId,
                        text: childFirstData.userInfoList[0].userName,
                    };
                } else {
                    return;
                }
            } else {
                return;
            }
        };
        if (
            homePerData.indexViewOrgTreeDto &&
            homePerData.indexViewOrgTreeDto.childOrgList
        ) {
            findFunction(homePerData.indexViewOrgTreeDto.childOrgList);
        }
        setSelectedHomeHeaderTreeData(selectedLevelItem);
        return {
            selectedLevel: deepLevel,
            selectedId: selectedItem.dimPart || selectedItem.userId,
            selectedText: selectedItem.name || selectedItem.userName,
        };
    };
    const calRenderHomeHeaderSelect = () => {
        const { homePreDataCanViewMap } = newHomeStore;
        const finalSelectMap: any = {
            0: [], // 国
            1: [], // 小组
            2: [], // 城市
            3: [], // 区域
            4: [], // 个人
        };
        // tslint:disable-next-line:forin
        for (const key in selectedHomeHeaderTreeData) {
            const item = selectedHomeHeaderTreeData[key];
            const selectedOrgItem = _.find(
                homePreDataCanViewMap[treeTypeToKey[key]],
                viewItem => {
                    if (viewItem.userId) {
                        return viewItem.userId == item?.value;
                    } else {
                        return viewItem.dimPart == item?.value;
                    }
                },
            );
            if (selectedOrgItem) {
                finalSelectMap[treeTypeToKey[key]].push(selectedOrgItem);
            }
            if (
                selectedOrgItem?.childOrgList &&
                selectedOrgItem?.childOrgList?.length
            ) {
                // 有子节点
                _.forEach(selectedOrgItem?.childOrgList, cItem => {
                    if (cItem.canView) {
                        finalSelectMap[cItem.orgType].push(cItem);
                    }
                });
            }
            if (
                selectedOrgItem?.userInfoList &&
                selectedOrgItem?.userInfoList?.length
            ) {
                _.forEach(selectedOrgItem.userInfoList, uItem => {
                    if (uItem.canView) {
                        finalSelectMap[4].push(uItem);
                    }
                });
            }
        }
        for (const key in finalSelectMap) {
            if (finalSelectMap[key].length) {
                if (key == '4') {
                    finalSelectMap[key] = _.uniqBy(
                        finalSelectMap[key],
                        'userId',
                    );
                } else {
                    finalSelectMap[key] = _.uniqBy(
                        finalSelectMap[key],
                        'dimPart',
                    );
                }
            }
        }
        return finalSelectMap;
    };
    // init
    useEffect(() => {
        if (homePerData && homePerData.indexViewOrgTreeDto) {
            const selectedInfo = findDeepSelected();
            // const viewLevel = findDeepView();
            if (selectedInfo.selectedLevel === 0) {
                // 全国
                selectedInfo.selectedId =
                    homePerData.indexViewOrgTreeDto.dimPart;
                selectedInfo.selectedText =
                    homePerData.indexViewOrgTreeDto.name;
            }
            setHomeNewSelectedData({
                ...selectedInfo,
            });
        }
    }, [homePerData]);
    useEffect(() => {
        setCanHeaderTreeViewRenderList(calRenderHomeHeaderSelect());
    }, [selectedHomeHeaderTreeData]);
    return <div className="new-home-header">{renderSelectTree()}</div>;
};
export default observer(NewHomeHeader);
