import React, { useState, useEffect, useContext } from 'react';
import {
    Modal,
    Alert,
    Select,
    Input,
    Button,
    Tag,
    Icon,
    message,
    DatePicker,
    Radio,
    Popover,
} from 'antd';
import './index.less';
import { CUserStore, ICUserData, IPersonBuyData } from '../store';
import _ from 'lodash';
import { TableLv2 } from '../../table/level2';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { tryNewCall } from '@/containers/hoc/call-center';
import { CUser2Contacts } from './cuser2contacts';
import { CtxProvider } from '@/utils/context';

import { sendPCDot } from '@/utils';
import { StoreContext } from '@/stores';
import { voyagerTrack } from '@shared/voyager';
import { getValueOrDefault } from '@/utils/helpers';
import Ccpopover from '@/components/common/cc-popover';

export interface ICUserModalProps {
    modalVis: boolean;
    setModalVis: (bool: boolean) => void;
    defaultCustomerName: string;
    customerOriginData: any;
    userName: string;
    userId: string;
    showMode?: boolean; // true是展示模式（不支持搜索
    cid?: string | number;
    crm_cid?: string | number;
    isPrivateCus: boolean;
    queryScene?:
        | 'REGISTER_HR_3D'
        | 'REGISTER_HR_7D'
        | 'REGISTER_HR_30D'
        | 'ACTIVE_HR_3D'
        | 'ACTIVE_HR_7D'
        | 'ACTIVE_HR_30D'
        | 'HR_COUNT'
        | 'REGISTRATION_3D'
        | 'REGISTRATION_7D'
        | 'REGISTRATION_30D'
        | 'ALL_MEMBERS'
        | '';
}
const CUserModal: React.FC<ICUserModalProps> = props => {
    const {
        modalVis,
        setModalVis,
        defaultCustomerName,
        userName,
        customerOriginData,
        userId,
        showMode,
        cid,
        queryScene,
        crm_cid,
        isPrivateCus,
    } = props;
    const store = useContext(StoreContext);
    const cUserStore = store.getDefaultCUserStore();
    const {
        cUserPositionList,
        remainCount,
        searchCUserDataList,
        getRemainDecodeNumber,
        searchCUserLoading,
        cUserDataList,
        decodeCUser,
        checkCUserDecoded,
        cUserBuyDataLoading,
        cUserBuyList,
        getPersonBuyList,
        getCUserPositionList,
    } = cUserStore;
    const [searchMode, setSearchMode] = useState('1'); // 默认按公司（客户名称）搜索
    const [searchCompanyInputText, setSearchCompanyInputText] = useState<any>(); // 公司名
    const [searchNameInputText, setSearchNameInputText] = useState<any>(); // 联系人姓名
    const [searchUsedTime, setSearchUsedTime] = useState<any>('');
    const [cqueryScene, setCqueryScene] = useState<string>('');
    const [searchPosition, setSearchPosition] = useState<any>([]); // 职位
    const [preInit, setPreInit] = useState(false);
    const [popoverVisable, setPopoverVisable] = useState(false);
    const [cUserBuyModalVis, setCUserBuyModalVis] = useState(false);
    useEffect(() => {
        if (modalVis) {
            initData();
        } else {
            setPreInit(false);
        }
    }, [modalVis]);
    const searchPositionHandle = _.debounce(getCUserPositionList, 500);
    const searchCUserList = async () => {
        await searchCUserDataList({
            position: searchPosition,
            company: searchCompanyInputText,
            name: searchNameInputText,
            queryScene: queryScene || cqueryScene,
            // companyId: customerOriginData?.id,
            companyId:
                (queryScene || cqueryScene) && showMode
                    ? customerOriginData?.customer_cid
                    : '',
            lastLogin: searchUsedTime ? searchUsedTime.valueOf() : '',
        });
    };
    const initData = () => {
        // const defaultSelectPosition = ['HR', '猎头', '招聘', '人事', '人力'];
        setSearchMode('1');
        setSearchCompanyInputText(defaultCustomerName);
        // setSearchPosition(defaultSelectPosition || []);
        setSearchPosition([]);
        getRemainDecodeNumber();
        if (!preInit) {
            setPreInit(true);
        }
    };
    const decodePhoneHandle = async (
        id: string | number,
        name: string,
        position: string,
    ) => {
        const [d, e] = await checkCUserDecoded(id);
        if (d) {
            // 已解密
            const [data, error] = await decodeCUser(id);
            if (data) {
                // 拉起外呼
                tryNewCall(data, true, {
                    showFollowLog: true,
                    customerName: defaultCustomerName,
                    customerId: crm_cid,
                    userName: name,
                    userPosition: position,
                });
            }
        } else {
            if (remainCount <= 0) {
                message.warning(
                    '为了防止C端用户信息泄漏，您每天只有200次外呼手机号的机会，目前机会已用完！',
                );
                return;
            }
            // 未解密
            Modal.confirm({
                content: (
                    <div>
                        <p>本次外呼，将消耗1次机会</p>
                        <p>
                            您今天剩余可用机会为
                            <span style={{ color: 'red' }}>{remainCount}</span>
                            次，请确认是否外呼？
                        </p>
                    </div>
                ),
                onOk: async () => {
                    const [data, error] = await decodeCUser(id);
                    if (data) {
                        // 拉起外呼
                        tryNewCall(data, true, {
                            customerName: defaultCustomerName,
                            showFollowLog: true,
                            customerId: crm_cid,
                            userName: name,
                            userPosition: position,
                        });
                    }
                },
            });
        }
    };
    useEffect(() => {
        if (preInit) {
            searchCUserList();
        }
    }, [preInit, searchUsedTime, cqueryScene]);
    const closeHandle = () => {
        setCqueryScene('');
        setModalVis(false);
    };

    // 转联系人相关
    const [cuser2contactsTrigger, setCuser2contactsTrigger] = useState(0);
    const [
        cuser2contactsCurrentCuser,
        setCuser2contactsCurrentCuser,
    ] = useState<any>(null);
    // const rangeTimeValue: any = [
    //     searchUsedTime.start ? moment(searchUsedTime.start) : undefined,
    //     searchUsedTime.end ? moment(searchUsedTime.end) : undefined,
    // ];
    const onVisibleChange = (visable: any) => {
        setPopoverVisable(visable);
    };
    return (
        <>
            <CtxProvider
                ctx={{
                    position: 'drawer',
                    positionDetail: 'customer-drawer-maitrend-cuser',
                    data: { cid: customerOriginData?.id },
                }}
            >
                <CUser2Contacts
                    trigger={cuser2contactsTrigger}
                    customerInfo={customerOriginData}
                    cuser={cuser2contactsCurrentCuser}
                />
            </CtxProvider>
            <Modal
                width={'800px'}
                visible={cUserBuyModalVis}
                onCancel={() => {
                    setCUserBuyModalVis(false);
                }}
                onOk={() => {
                    setCUserBuyModalVis(false);
                }}
                title="会员购买记录"
            >
                <TableLv2<IPersonBuyData>
                    loading={cUserBuyDataLoading}
                    closeRowSelection={true}
                    pagination={false}
                    dataSource={cUserBuyList}
                    scroll={{
                        y: 350,
                        x: 1000,
                    }}
                    columns={[
                        {
                            title: 'UID',
                            key: 'uid',
                            width: 120,
                            dataIndex: 'uid',
                        },
                        {
                            title: '订单ID',
                            key: 'orderId',
                            width: 120,
                            dataIndex: 'orderId',
                        },
                        {
                            title: '购买时间',
                            key: 'buyTime',
                            dataIndex: 'buyTime',
                            width: 120,
                            render: item => {
                                return moment(Number(item)).format(
                                    'YYYY-MM-DD HH:mm',
                                );
                            },
                        },
                        {
                            title: '订单金额',
                            key: 'money',
                            dataIndex: 'money',
                            width: 100,
                        },
                        {
                            title: '会员类型',
                            key: 'memType',
                            dataIndex: 'memType',
                            width: 100,
                        },
                        {
                            title: '会员月数',
                            key: 'month',
                            dataIndex: 'month',
                            width: 100,
                        },
                        {
                            title: '购买渠道',
                            key: 'channel',
                            dataIndex: 'channel',
                            width: 100,
                        },
                        {
                            title: '购买方式',
                            key: 'buyType',
                            dataIndex: 'buyType',
                            width: 100,
                        },
                        {
                            title: '是否勾选自动续费',
                            key: 'autoRenew',
                            dataIndex: 'autoRenew',
                            width: 160,
                            render: v => {
                                return v ? '是' : '否';
                            },
                        },
                    ]}
                />
            </Modal>
            <Modal
                width={'1200px'}
                visible={modalVis}
                onCancel={closeHandle}
                title="脉脉C端用户信息"
                maskClosable={false}
                cancelButtonProps={{
                    hidden: true,
                }}
                okButtonProps={{
                    hidden: true,
                }}
                wrapClassName="cuser-modal-content"
                destroyOnClose
            >
                <Alert
                    showIcon
                    style={{
                        fontSize: '12px',
                    }}
                    type="warning"
                    message={
                        <div>
                            <p style={{ marginBottom: '0px' }}>
                                手机号需加密，无法明文展示。但是您每天有200次外呼机会，目前您的剩余可用机会为{' '}
                                <span style={{ color: 'red' }}>
                                    {remainCount}
                                </span>{' '}
                                次。（注意：同一用户一天之内外呼多次，只消耗1次机会）
                            </p>
                        </div>
                    }
                />
                {showMode ? null : (
                    <div className="cuser-search-content">
                        {/* <p className="cuser-search-row">
                            </p> */}
                        <p className="cuser-search-row">
                            <label htmlFor="position">联系人：</label>
                            <Input.Search
                                style={{ width: '180px', marginRight: '8px' }}
                                value={searchNameInputText}
                                onPressEnter={searchCUserList}
                                onChange={e => {
                                    setSearchNameInputText(e.target.value);
                                }}
                            />
                            <label htmlFor="position">职位：</label>
                            <Select
                                mode="multiple"
                                style={{ width: '200px', marginRight: '8px' }}
                                onChange={(val: any) => {
                                    setSearchPosition(val);
                                }}
                                value={searchPosition}
                                defaultActiveFirstOption={false}
                                onSearch={val => {
                                    searchPositionHandle(val);
                                }}
                                showArrow={false}
                                showSearch={true}
                            >
                                {_.map(cUserPositionList, position => {
                                    return (
                                        <Select.Option value={position}>
                                            {position}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                            {/* <label htmlFor="time">最近活跃时间：</label>
                            <DatePicker
                                name="time"
                                // showTime={true}
                                format="YYYY-MM-DD"
                                // ranges={{
                                //     今天: [
                                //         moment().startOf('day'),
                                //         moment().endOf('day'),
                                //     ],
                                //     三天内: [
                                //         moment()
                                //             .subtract('3', 'days')
                                //             .startOf('day'),
                                //         moment().endOf('day'),
                                //     ],
                                //     七天内: [
                                //         moment()
                                //             .subtract('7', 'days')
                                //             .startOf('day'),
                                //         moment().endOf('day'),
                                //     ],
                                //     一个月内: [
                                //         moment()
                                //             .startOf('month')
                                //             .startOf('day'),
                                //         moment()
                                //             .endOf('month')
                                //             .endOf('day'),
                                //     ],
                                // }}
                                onChange={values => {
                                    setSearchUsedTime(values);
                                }}
                                value={searchUsedTime}
                                // onOk={}
                                style={{ width: '160px' }}
                                allowClear={true}
                            /> */}
                            <Button
                                style={{ marginLeft: '12px' }}
                                type="primary"
                                onClick={() => searchCUserList()}
                                loading={searchCUserLoading}
                            >
                                搜索
                            </Button>
                        </p>
                        <p className="cuser-search-row">
                            <label htmlFor="time">最近活跃时间：</label>
                            <Radio.Group
                                defaultValue="1"
                                buttonStyle="solid"
                                onChange={e => {
                                    if (e.target.value == 1) {
                                        setSearchUsedTime(undefined);
                                        setCqueryScene('');
                                    } else if (e.target.value == 2) {
                                        setSearchUsedTime(
                                            moment().subtract('3', 'days'),
                                        );
                                        setCqueryScene('ACTIVE_HR_3D');
                                    } else if (e.target.value == 3) {
                                        setSearchUsedTime(
                                            moment().subtract('7', 'days'),
                                        );
                                        setCqueryScene('ACTIVE_HR_7D');
                                    } else if (e.target.value == 4) {
                                        setSearchUsedTime(
                                            moment().subtract('30', 'days'),
                                        );
                                        setCqueryScene('ACTIVE_HR_30D');
                                    }
                                    // searchCUserList();
                                }}
                            >
                                <Radio.Button value="1">全部</Radio.Button>
                                <Radio.Button value="2">近3天</Radio.Button>
                                <Radio.Button value="3">近7天</Radio.Button>
                                <Radio.Button value="4">近30天</Radio.Button>
                            </Radio.Group>
                            {/* 
                            <Button
                                style={{ marginRight: '12px' }}
                                onClick={() => {
                                    setSearchUsedTime(undefined);
                                    searchCUserList();
                                }}
                                loading={searchCUserLoading}
                            >
                                全部
                            </Button>
                            <Button
                                style={{ marginRight: '12px' }}
                                onClick={() => {
                                    setSearchUsedTime(moment().subtract('3', 'days'));
                                    searchCUserList();
                                }}
                                loading={searchCUserLoading}
                            >
                                近3天
                            </Button>
                            <Button
                                style={{ marginRight: '12px' }}
                                onClick={() => {
                                    setSearchUsedTime(moment().subtract('7', 'days'));
                                    searchCUserList();
                                }}
                                loading={searchCUserLoading}
                            >
                                近7天
                            </Button>
                            <Button
                                style={{ marginRight: '12px' }}
                                onClick={() => {
                                    setSearchUsedTime(moment().subtract('30', 'days'));
                                    searchCUserList();
                                }}
                                loading={searchCUserLoading}
                            >
                                近30天
                            </Button> 
                            */}
                        </p>
                    </div>
                )}
                <div className="cuser-table-content">
                    <TableLv2<ICUserData>
                        loading={searchCUserLoading}
                        closeRowSelection={true}
                        pagination={false}
                        dataSource={cUserDataList}
                        scroll={{
                            y: showMode ? 500 : 350,
                            x: 1000,
                        }}
                        onAction={(actionKey, data) => {
                            if (actionKey === 'transferContact') {
                                sendPCDot(
                                    '点击【转联系人】按钮',
                                    `userId=${userId},userName=${userName},customerName=${defaultCustomerName}`,
                                    false,
                                    'click',
                                    window.location.pathname,
                                    moment().format('YYYY-MM-DD HH:mm:ss'),
                                );
                                // 转联系人
                                // 拿手机号
                                decodeCUser(data.uid).then(([d, e]) => {
                                    const phone = d;
                                    if (!phone) {
                                        return message.error(
                                            '获取用户手机失败',
                                        );
                                    }
                                    setCuser2contactsTrigger(
                                        cuser2contactsTrigger + 1,
                                    );
                                    setCuser2contactsCurrentCuser({
                                        ...data,
                                        decodedPhone: phone,
                                    });
                                });
                            } else if (actionKey === 'viewBuyRecord') {
                                // 查看购买记录
                                sendPCDot(
                                    '点击【查看购买记录】按钮',
                                    `userId=${userId},userName=${userName},customerName=${defaultCustomerName}`,
                                    false,
                                    'click',
                                    window.location.pathname,
                                    moment().format('YYYY-MM-DD HH:mm:ss'),
                                );
                                getPersonBuyList(data.uid);
                                setCUserBuyModalVis(true);
                            }
                        }}
                        actions={[
                            {
                                actionKey: 'transferContact',
                                actionCn: '转联系人',
                                status: {
                                    disabled: item => {
                                        return !item.call;
                                    },
                                },
                            },
                            {
                                actionKey: 'viewBuyRecord',
                                actionCn: '购买记录',
                            },
                        ]}
                        actionsWidth={140}
                        isFixedActions={false}
                        columns={[
                            {
                                title: '姓名',
                                key: 'realname',
                                dataIndex: 'realname',
                                fixed: true,
                                render: (text, record) => {
                                    return (
                                        <span>
                                            <a
                                                href={record.profileUrl}
                                                target="_blank"
                                                onClick={() => {
                                                    voyagerTrack(
                                                        'crm_view_contacts_profile',
                                                        {
                                                            sense: 3,
                                                        },
                                                    );
                                                }}
                                            >
                                                {text}
                                            </a>{' '}
                                            {isPrivateCus ? (
                                                <Popover
                                                    style={{ zIndex: 666666 }}
                                                    getPopupContainer={() => {
                                                        return document.body as HTMLElement;
                                                    }}
                                                    onVisibleChange={
                                                        onVisibleChange
                                                    }
                                                    placement="right"
                                                    content={
                                                        <Ccpopover
                                                            uid={
                                                                popoverVisable
                                                                    ? record.uid
                                                                    : undefined
                                                            }
                                                            whetherShow={false}
                                                        />
                                                    }
                                                >
                                                    <Icon
                                                        onClick={() => {
                                                            decodePhoneHandle(
                                                                record.uid,
                                                                record.realname,
                                                                record.position,
                                                            );
                                                        }}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        type="phone"
                                                        theme="twoTone"
                                                        twoToneColor="#0052ff"
                                                    />
                                                </Popover>
                                            ) : null}
                                        </span>
                                    );
                                },
                            },
                            {
                                title: '会员类型',
                                key: 'memType',
                                dataIndex: 'memType',
                                width: 100,
                                render: text => getValueOrDefault(text),
                            },
                            {
                                title: '职位',
                                key: 'position',
                                dataIndex: 'position',
                                width: 120,
                            },
                            {
                                title: '最近活跃时间',
                                key: 'lastActiveDate',
                                dataIndex: 'lastActiveDate',
                                width: 120,
                                render: v => {
                                    if (moment('2010-01-01').valueOf() > v) {
                                        return '-';
                                    } else {
                                        return v
                                            ? moment(Number(v)).format(
                                                  'YYYY-MM-DD',
                                              )
                                            : '-';
                                    }
                                },
                                sorter: (a, b) =>
                                    a.lastActiveDate - b.lastActiveDate,
                            },
                            {
                                title: '近30日活跃次数',
                                key: 'oneMonthActiveCnt',
                                dataIndex: 'oneMonthActiveCnt',
                                width: 120,
                                render: text => getValueOrDefault(text),
                                sorter: (a, b) =>
                                    a.oneMonthActiveCnt - b.oneMonthActiveCnt,
                            },
                            {
                                title: '有效职位数',
                                key: 'validPositionCnt',
                                dataIndex: 'validPositionCnt',
                                width: 100,
                                render: text => getValueOrDefault(text),
                                sorter: (a, b) =>
                                    a.validPositionCnt - b.validPositionCnt,
                            },
                            {
                                title: '搜索人才数',
                                key: 'searchTalentCnt',
                                dataIndex: 'searchTalentCnt',
                                width: 80,
                                render: text => getValueOrDefault(text),
                            },
                            {
                                title: '主动加好友数',
                                key: 'initiateFriendingCnt',
                                dataIndex: 'initiateFriendingCnt',
                                width: 90,
                                render: text => getValueOrDefault(text),
                            },
                            {
                                title: '主动开聊数',
                                key: 'initiateCharCnt',
                                dataIndex: 'initiateCharCnt',
                                width: 80,
                                render: text => getValueOrDefault(text),
                            },
                            {
                                title: '极速联系数',
                                key: 'fastContactCnt',
                                dataIndex: 'fastContactCnt',
                                width: 80,
                                render: text => getValueOrDefault(text),
                            },
                        ]}
                    />
                </div>
            </Modal>
        </>
    );
};
export default observer(CUserModal);
