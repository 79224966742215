import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { SearchAbleTree, SGrid, SortableList } from '@/components';
import _ from 'lodash';
import './index.scss';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { IMyTreeNodeNormal } from '../searchable-tree';
import {
    arrToSortHelper,
    arrToSortHelperPrimary,
    sortArrBySortHelper,
} from '@/utils';

const transformParmasToTreeData: (
    params: IParamItem[],
) => IMyTreeNodeNormal[] = params => {
    const ret: IMyTreeNodeNormal[] = [];
    const groups = _.unionBy(
        _.filter(
            _.map(params, param => {
                const { groupKey, groupTitle, readonly = false } = param;
                if (groupKey && groupTitle) {
                    return {
                        groupKey,
                        groupTitle,
                        readonly,
                    };
                }
            }),
            Boolean,
        ),
        'groupKey',
    );
    const groupsMap: { [key: string]: IMyTreeNodeNormal } = {};
    for (const group of groups) {
        if (group === undefined) {
            continue;
        }
        const { groupKey, groupTitle, readonly = false } = group;
        const groupItem: IMyTreeNodeNormal = {
            key: 'group-' + groupKey,
            title: groupTitle,
            children: [],
            readonly,
        };
        ret.push(groupItem);
        groupsMap[groupKey] = groupItem;
    }

    for (const param of params) {
        const { groupKey } = param;
        const paramItem = {
            key: 'param-' + param.key,
            title: param.title,
            readonly: param.readonly,
        };
        if (_.isNil(groupKey) || _.isNil(groupsMap[groupKey])) {
            // 野生
            ret.push(paramItem);
        }
        const group = groupsMap[groupKey || ''];
        if (!_.isNil(group) && !_.isNil(group.children)) {
            group.children.push(paramItem);
        }
    }
    return ret;
};

const flattenAllAndMappify = (data: IMyTreeNodeNormal[] | undefined) => {
    const map: { [key: string]: IMyTreeNodeNormal } = {};
    if (data === undefined) {
        return map;
    }
    for (const item of data) {
        map[item.key] = item;
        const innerMap = flattenAllAndMappify(item.children);
        _.assign(map, innerMap);
    }
    return map;
};

export interface IParamsEditorProps {
    params: IParamItem[];
    selectedKeys?: string[];
    setSelectedKeys?: (nextKeys: string[]) => void;
}

export const ParamsEditor: React.FC<IParamsEditorProps> = props => {
    const { params: inputParams } = props;
    const params = [...inputParams];
    const {
        selectedKeys: inputSelectedKeys,
        setSelectedKeys: inputSetSelectedKeys,
    } = props;

    const sortHelper = arrToSortHelperPrimary(inputSelectedKeys || []);
    sortArrBySortHelper(params, sortHelper, 'key');

    const readonlyParams = params.filter(item => item.readonly);
    const allParamsKeys = params.map(({ key }) => 'param-' + key);
    const allReadOnlyKeys = readonlyParams.map(({ key, title }) => {
        return {
            key: 'param-' + key,
            title,
        };
    });

    const treeData = transformParmasToTreeData(inputParams);
    const itemMap = flattenAllAndMappify(treeData);

    const selectedKeys = _.map(inputSelectedKeys, key => 'param-' + key);
    const setSelectedKeys = (keys: string[]) => {
        if (!_.isNil(inputSetSelectedKeys)) {
            inputSetSelectedKeys(
                _.map(keys, key => {
                    const [com0, com1] = _.split(key, '-');
                    return com1 || com0;
                }),
            );
        }
    };

    const selectAll = () => {
        setSelectedKeys(
            _.uniq([
                ...allReadOnlyKeys.map(item => item.key),
                ...allParamsKeys,
            ]),
        );
    };
    const clearAll = () => {
        setSelectedKeys(allReadOnlyKeys.map(item => item.key));
    };
    const removeIndex = (index: number) => {
        const nextSelectedkeys = [...selectedKeys];
        nextSelectedkeys.splice(index, 1);
        setSelectedKeys(nextSelectedkeys);
    };
    const switchIndex = (fromIndex: number, toIndex: number) => {
        const nextSelectedkeys = [...selectedKeys];
        const tmp = nextSelectedkeys.splice(fromIndex, 1)[0];
        nextSelectedkeys.splice(toIndex, 0, tmp);
        setSelectedKeys(nextSelectedkeys);
    };
    const selectedItems = _.filter(
        _.map(selectedKeys, key => itemMap[key]),
        item => {
            if (!item || !item.key) {
                return false;
            }
            return _.startsWith(item.key, 'param-');
        },
    );

    const allParamsCount = params.length;
    const selectedParamsCount = selectedItems.length;

    return (
        <div className="params-editor">
            <SGrid rowGutter={'big'} layout={[[12, 12]]}>
                <div className="cell">
                    <div className="header">
                        <div className="title">
                            所有字段（{allParamsCount}）
                        </div>
                        <div className="op">
                            <a onClick={selectAll} className="op">
                                全选
                            </a>
                        </div>
                    </div>
                    <div className="body">
                        <SearchAbleTree
                            inputProps={{
                                placeholder: '查找筛选项',
                            }}
                            treeData={treeData}
                            selectedKeys={selectedKeys}
                            onSelectedChange={keys =>
                                setSelectedKeys([...keys])
                            }
                        />
                    </div>
                </div>
                <div className="cell">
                    <div className="header">
                        <div className="title">
                            显示字段（{selectedParamsCount}/{allParamsCount}）
                        </div>
                        <div className="op">
                            <a onClick={clearAll} className="op">
                                清空
                            </a>
                        </div>
                    </div>
                    <div style={{ padding: 0 }} className="body">
                        <SortableList
                            data={selectedItems}
                            readOnlyData={allReadOnlyKeys}
                            onRemove={removeIndex}
                            onSwitch={switchIndex}
                        />
                    </div>
                    <div className="lint">拖拽字段名可调换顺序</div>
                </div>
            </SGrid>
        </div>
    );
};

export interface IParamsEditorModalProps
    extends ModalProps,
        IParamsEditorProps {
    defaultSelectedKeys: string[];
    onSelectedKeysConfirmed: (nextKeys: string[]) => void;
}

// 非受控模式
export const ParamsEditorModal: React.FC<IParamsEditorModalProps> = props => {
    const { defaultSelectedKeys, visible } = props;
    const [selectedKeys, setSelectedKeys] = useState(defaultSelectedKeys);
    // 半受控，恶心又熟悉
    useEffect(() => {
        setSelectedKeys(defaultSelectedKeys);
    }, [defaultSelectedKeys.toString(), visible]);
    return (
        <Modal
            {...props}
            onOk={() => {
                props.onSelectedKeysConfirmed(selectedKeys);
            }}
            width={600}
        >
            <ParamsEditor
                params={props.params}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
            />
        </Modal>
    );
};
