import React, { useEffect, useCallback } from 'react';
import { useState, useContext } from 'react';
import './index.scss';
import CommonDrawer from '../drawer/index';
import { IDrawerTitleProps } from '@/components/common/drawer/drawer-title';
import { Icon, message, Menu, Select, DatePicker } from 'antd';
import { IDrawerCardListProps } from '../drawer/drawer-card-list/index';
import ItemUser, {
    IItemsUserProps,
} from '../../../components/common/items/user/index';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import ItemPhone from '@/components/common/items/phone';
import { IDrawerTabsProps } from '../drawer/drawer-tabs/index';
import DrawCollapse from '@/components/common/drawer/drawer-collapse';
import { IDrawerCollapseProps } from '../drawer/drawer-collapse/index';
import { LeadContactsCreate } from './lead-contacts-create';
import DataReview from '../../../components/common/items/data-review/index';
import { defaultAxios, useNet, useRefresh, tryRefresh } from '@/utils';
import _ from 'lodash';
import CustomerActionLog from '../customer-drawer/customer-action-log';
import moment from 'moment';
import { LeadOptCreate } from './lead-opt-create';
import {
    LeadListStore,
    useAnyLeadStore,
} from '@/pages/sales-follow-up/lead/list/store';
import AddFollowLog from '../add-follow-log-new';
import LogModal from '../log-modal/index';
import { CtxProvider } from '@/utils/context';
import { DDetail } from '../editable-display/ddetail';
import { makeObjColClickable } from '../form';
import { AntDropdownButton, AntSpin } from '@/components/antd';
import { loadingWrapper } from '../block-loading';
import {
    getFollowStatusesByOriginalFollowStatus,
    isInValidLead,
} from '@/pages/sales-follow-up/lead/list/utils';
import { CustomerActivityFollowJobCreate } from '@/pages/sales-follow-up/job/jobs/lead/components/created-by-cus-activity';
import { isMultipleCell } from '@/components/common/table';

// 抽屉针对客户业务的二次封装，高度业务定制
interface ICrmLeadDrawer {
    leadListStore: LeadListStore;
    drawerVis: boolean;
    setDrawerVis: (bool: boolean) => void;
    detailId?: string | number;
    destroyOnClose?: boolean;
    onEdit?: (data: any) => void;
    onCreat?: (data: any) => void;
}
interface ICrmDetailData {
    detailInfo: IDrawerCollapseProps;
    originData: any;
    // 此处可维护静态字段
    staticData: {
        name: string; // 客户名称
        id: string | number; // 客户id
        owner_id: any; // 关联字段 - 客户所有人
    };
    userField: any;
    actionLogConfig: {
        arType: any[];
        followType: any[];
    }; // 活动记录基础配置
}
export type TGetCrmDetailReturnType = NetResponse<{
    data: ICrmDetailData;
    msg: string;
    code: number;
}>;
const LeadDrawer: React.FC<ICrmLeadDrawer> = props => {
    const {
        drawerVis,
        setDrawerVis,
        detailId,
        destroyOnClose = false,
        onCreat,
        onEdit,
        leadListStore,
    } = props;
    const [followActionModalVis, setFollowActionModalVis] = useState(false);
    const [followActionInitMark, setFollowActionInitMark] = useState(false);
    const [logModalVis, setLogModalVis] = useState(false);
    const [drawerData, setDrawerData] = useState<any>({});
    const [leadContactsCreateTrigger, setLeadContactsCreateTrigger] = useState(
        0,
    );
    const [leadOptCreateTrigger, setLeadOptCreateTrigger] = useState(0);
    const [prepared, defaultLeadListStore] = useAnyLeadStore({});

    const [
        customerActivityFollowJobCreateTrigger,
        setCustomerActivityFollowJobCreateTrigger,
    ] = useState(0);
    const [createdFollowLog, setCreatedFollowLog] = useState<any>(null);

    const { listType, defaultPerm } = leadListStore;
    const eventCode = listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    const type = eventCode === 'PUBLIC-VIEW' ? 'all' : 'mine';

    const loadDetail = () => {
        if (drawerVis && prepared) {
            if (detailId) {
                defaultLeadListStore.fetchInstantlyMutatingDataById(detailId);
            } else {
                defaultLeadListStore.setMutatingData(null);
            }
        } else {
            defaultLeadListStore.setMutatingData(null);
        }
    };
    useEffect(loadDetail, [drawerVis, detailId, prepared]);

    const { mutatingData, defaultMeta } = defaultLeadListStore;

    let isStandCus = false;
    const br = mutatingData?.customer_id?.relatedObj?.business_registration;
    if (br === 1 || br === 3) {
        isStandCus = true;
    }

    useRefresh(
        ['lead'],
        () => {
            loadDetail();
        },
        [drawerVis, detailId, prepared],
    );

    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const sysUser = authStore.userInfo?.sysUser || {};

    const [dataFail, setDataFail] = useState(false);

    const fetchCustomerDetail: () => Promise<
        TGetCrmDetailReturnType
    > = async () => {
        const thisId = detailId;
        if (_.isNil(detailId)) {
            return [null, null];
        }
        const { fieldAuthCode, dataAuthCode } = leadListStore;

        const [d, e] = await defaultAxios.get(
            `/bff/api/rest/lead/detail?id=${detailId}&fieldAuthCode=${fieldAuthCode}&dataAuthCode=${dataAuthCode}`,
        );
        if (d === null || d.data === null) {
            setDataFail(true);
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d && d.data) {
            if (
                !d.data.originData ||
                _.isEmpty(d.data.originData) ||
                !d.data.originData?.length
            ) {
                setDataFail(true);
                return [null, new Error('no auth')];
            }
            if (thisId === detailId) {
                setDataFail(false);
                setDrawerData(d.data);
            }
        }
        return [data, error];
    };
    const [error, loading, reload] = useNet(fetchCustomerDetail, {
        refreshKeys: ['lead'],
    });
    useEffect(() => {
        if (drawerVis) {
            if (detailId) {
                reload();
            }
        } else {
            // 当客户抽屉关闭，将会清空里面的所有数据
            setDrawerData({});
        }
    }, [detailId, drawerVis]);

    const staticData = drawerData?.staticData || {};
    const originData = drawerData?.originData
        ? drawerData?.originData[0] || {}
        : {};
    const transformed =
        !!originData?.contact_id?.originalValue ||
        !!originData?.lead_id?.originalValue;
    const transformedContact = !!originData?.contact_id?.originalValue;
    const transformedOpt = !!originData?.opportunity_id?.originalValue;
    const invalid = isInValidLead(originData?.follow_status);
    const dropMenu = (
        <Menu
            onClick={e => {
                if (e.key === '1') {
                    setLogModalVis(true);
                }
            }}
        >
            <Menu.Item key="1">查看操作记录</Menu.Item>
        </Menu>
    );

    const permFollow = !!defaultPerm.getPermByTypeAndAction(type)('follow')
        .visible;

    const permViewOpRecord = !!defaultPerm.getPermByTypeAndAction(type)(
        'view-operate-record',
    ).visible;

    const permEdit = defaultPerm.getPermByTypeAndAction(type)('update').visible;
    const titleExampleConfig: IDrawerTitleProps = {
        title: (
            <span
                style={{
                    display: 'flex',
                    lineHeight: '32px',
                    alignItems: 'center',
                }}
            >
                <span
                    className="iconfont1"
                    style={{
                        color: '#0052ff',
                        fontSize: '18px',
                        marginRight: '8px',
                    }}
                >
                    &#xe617;
                </span>
                <span style={{ lineHeight: '32px' }}>
                    {(staticData?.name || '') +
                        (transformed ? '（已转化）' : '')}
                </span>
                &nbsp; &nbsp;
                {permEdit && !dataFail && (
                    <FastLeadStatusSelect defaultStore={defaultLeadListStore} />
                )}
            </span>
        ),
        icons: [],
        tags: [],
        buttons: [],
    };

    if (_.isArray(titleExampleConfig.buttons)) {
        const permEdit = defaultPerm.getPermByTypeAndAction(type)('update')
            .visible;
        if (permEdit) {
            titleExampleConfig.buttons.push({
                type: 'button',
                buttonConfig: {
                    children: '编辑',
                    onClick: () => {
                        // tslint:disable-next-line:no-unused-expression
                        typeof onEdit === 'function' &&
                            onEdit(
                                drawerData?.originData
                                    ? drawerData?.originData[0]
                                    : {},
                            );
                    },
                },
            });
        }
    }

    // 标准客户
    if (isStandCus && _.isArray(titleExampleConfig.buttons)) {
        const perfTransContacts = defaultPerm.getPermByTypeAndAction(type)(
            'transfer-contacts',
        ).visible;
        const perfTransOpt = defaultPerm.getPermByTypeAndAction(type)(
            'transfer-opportunity',
        ).visible;
        if (perfTransOpt) {
            titleExampleConfig.buttons.unshift({
                type: 'button',
                buttonConfig: {
                    type: 'primary',
                    disabled: loading || transformedOpt || invalid,
                    loading,
                    children: '转商机',
                    onClick: () => {
                        setLeadOptCreateTrigger(1 + leadOptCreateTrigger);
                    },
                },
            });
        }

        if (perfTransContacts) {
            titleExampleConfig.buttons.unshift({
                type: 'button',
                buttonConfig: {
                    type: 'primary',
                    disabled:
                        loading ||
                        transformedOpt ||
                        transformedContact ||
                        invalid,
                    loading,
                    children: '转联系人',
                    onClick: () => {
                        setLeadContactsCreateTrigger(
                            1 + leadContactsCreateTrigger,
                        );
                    },
                },
            });
        }
    }

    if (_.isArray(titleExampleConfig.buttons) && permViewOpRecord) {
        titleExampleConfig.buttons.push({
            type: 'drop',
            dropButtonConfig: {
                overlay: dropMenu,
                buttonConfig: {
                    type: 'default',
                    style: {
                        padding: 0,
                        backgroundColor: 'rgb(244, 245, 250)',
                    },
                    children: (
                        <span
                            className="iconfont1"
                            style={{
                                fontSize: '12px',
                                transform: 'scale(0.35)',
                            }}
                        >
                            &#xe618;
                        </span>
                    ),
                },
            },
        });
    }

    if (dataFail) {
        titleExampleConfig.buttons = [];
    }

    const cardExampleConfig: IDrawerCardListProps = {
        dataSource: [
            {
                title: '公司名称',
                content: staticData?.company_name ? (
                    <a
                        target="_blank"
                        href={
                            '/buy/custom/my-custom?target=lead-drawer-header&id=' +
                            originData?.customer_id?.originalValue
                        }
                    >
                        {staticData?.company_name || '- -'}
                    </a>
                ) : (
                    '- -'
                ),
            },
            {
                title: '线索一级来源',
                content:
                    (() => {
                        const ls = originData?.lead_source;
                        if (!ls) {
                            return '';
                        }
                        const options =
                            defaultMeta.objectMetaMap?.['lead_source']
                                ?.constraint?.constraintItemList || [];
                        const item = options.find(
                            (item: any) => item?.constraintValue === ls,
                        );
                        return item?.constraintLabel;
                    })() || '- -',
            },
            {
                title: '跟进结果',
                content: staticData?.follow_status?.constraintLabel || '- -',
            },
            {
                title: '创建时间',
                content: staticData?.create_time
                    ? moment(staticData?.create_time).format(
                        'YYYY-MM-DD HH:mm:ss',
                    )
                    : '- -',
            },
            {
                title: '最新活动记录时间',
                content: originData?.recent_activity_record_time
                    ? moment(originData?.recent_activity_record_time).format(
                        'YYYY-MM-DD HH:mm:ss',
                    )
                    : '- -',
            },
        ],
    };
    const customExampleCollapseConfig: IDrawerCollapseProps =
        drawerData.detailInfo;
    const tabsExampleConfig: IDrawerTabsProps = {
        tabsContentConfig: {
            defaultActiveKey: '1',
            tabBarGutter: 24,
            tabConfig: [
                {
                    key: '1',
                    tab: '线索信息',
                    content: (
                        <>
                            <DDetail
                                eventCode={eventCode}
                                defaultStore={defaultLeadListStore}
                                cellsSelectorTransformer={originalbaiscCellsSelector => {
                                    const baiscCellsSelector = [
                                        ...originalbaiscCellsSelector,
                                    ];
                                    _.forEach(baiscCellsSelector, cItem => {
                                        if (isMultipleCell(cItem)) {
                                            return cItem;
                                        }
                                        if (cItem.cell === 'CC') {
                                            if (cItem.inputs.length < 3) {
                                                cItem.inputs = [
                                                    ...cItem.inputs,
                                                    'crm_lead',
                                                ];
                                            } else {
                                                cItem.inputs[2] = 'crm_lead';
                                            }
                                            // cItem.inputs.push('crm_lead');
                                        }
                                    });
                                    // 详情可点击
                                    makeObjColClickable(
                                        baiscCellsSelector,
                                        'customer_id',
                                    );
                                    return baiscCellsSelector;
                                }}
                            />
                            {mutatingData?.opportunity_id?.originalValue ? (
                                <div
                                    style={{
                                        marginTop: 12,
                                        paddingLeft: 15,
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    商机：
                                    <a
                                        target="_blank"
                                        href={
                                            '/buy/opportunity/all?target=cusdetail&id=' +
                                            mutatingData?.opportunity_id
                                                ?.originalValue
                                        }
                                    >
                                        {
                                            mutatingData?.opportunity_id
                                                ?.relatedObj?.opportunity_name
                                        }
                                    </a>
                                </div>
                            ) : null}
                            {mutatingData?.contact_id?.originalValue ? (
                                <div
                                    style={{
                                        marginTop: 12,
                                        paddingLeft: 15,
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    联系人：
                                    <a
                                        target="_blank"
                                        href={
                                            '/buy/contact/all?target=cusdetail&id=' +
                                            mutatingData?.contact_id
                                                ?.originalValue
                                        }
                                    >
                                        {
                                            mutatingData?.contact_id?.relatedObj
                                                ?.contacts_name
                                        }
                                    </a>
                                </div>
                            ) : null}
                        </>
                    ),
                },
            ],
        },
    };
    const customerObj = staticData?.customer_id;
    const cid = customerObj?.originalValue;
    return (
        <CtxProvider
            ctx={{ position: 'drawer', positionDetail: 'lead-drawer' }}
        >
            <LogModal
                modalVis={logModalVis}
                setModalVis={setLogModalVis}
                entityCode="lead"
                entityValueId={detailId}
            />
            <LeadContactsCreate
                leadInfo={originData}
                trigger={leadContactsCreateTrigger}
            />
            <LeadOptCreate
                leadInfo={originData}
                trigger={leadOptCreateTrigger}
            />
            <CommonDrawer
                isFail={dataFail}
                destroy={destroyOnClose ? !drawerVis : false}
                loading={loading}
                titleConfig={titleExampleConfig}
                DrawerContentConfig={{
                    zIndex: 999,
                    placement: 'right',
                    onClose: () => {
                        setDrawerVis(false);
                    },
                    visible: drawerVis,
                }}
                extraRight={
                    <>
                        {originData?.customer_id?.originalValue && (
                            <CustomerActivityFollowJobCreate
                                customers={[
                                    originData?.customer_id?.relatedObj,
                                ]}
                                followLog={createdFollowLog}
                                trigger={customerActivityFollowJobCreateTrigger}
                                onSuccess={() => { }}
                            />
                        )}
                        <AddFollowLog
                            isOpenPopover={false}
                            entityType={'lead'}
                            cid={cid}
                            id={detailId}
                            key={cid + '-' + detailId}
                            drawerVis={followActionModalVis}
                            setDrawerVis={setFollowActionModalVis}
                            initMark={followActionInitMark}
                            onCreated={data => {
                                // 这里检查这条活动数据，满足一定条件就弹任务窗口
                                if (data.isCreateJob === 1) {
                                    setCreatedFollowLog(data);
                                    setCustomerActivityFollowJobCreateTrigger(
                                        1 +
                                        customerActivityFollowJobCreateTrigger,
                                    );
                                }
                            }}
                        />
                        <CustomerActionLog
                            entityType={'lead'}
                            id={detailId}
                            actionLogConfig={drawerData?.actionLogConfig}
                            drawerVis={drawerVis}
                            targetFixed={true}
                            setFollowLogModalVis={bool => {
                                setFollowActionModalVis(bool);
                                setFollowActionInitMark(true);
                                setTimeout(() => {
                                    setFollowActionInitMark(false);
                                }, 0);
                            }}
                            defaultArType={'2'}
                            ArTypeSelectVisible={false}
                            permFollow={permFollow}
                        />
                    </>
                }
                DrawerCardListConfig={cardExampleConfig}
                DrawerTabsConfig={tabsExampleConfig}
            />
        </CtxProvider>
    );
};

const FastLeadStatusSelect: React.FC<{
    defaultStore: LeadListStore;
}> = observer(({ defaultStore }) => {
    const { defaultMeta, mutatingData, mutatingDataLoading } = defaultStore;
    const { inited } = defaultMeta;

    const [loading, setLoading] = useState(false);
    if (!inited) {
        return null;
    }
    const followStatusMetaItem = defaultMeta.objectMeta.find(
        item => item.fieldName === 'follow_status',
    );
    const options = followStatusMetaItem?.constraint?.constraintItemList;
    if (!followStatusMetaItem || !_.isArray(options)) {
        return null;
    }

    if (mutatingDataLoading || mutatingData === null) {
        return null;
    }

    const optItem = options.find(
        item => item.constraintValue === mutatingData.follow_status,
    );

    const originalFollowStatus = mutatingData._original_follow_status;
    const selectableFollowStatuses = getFollowStatusesByOriginalFollowStatus(
        originalFollowStatus,
    );
    const disabled = _.isEmpty(Array.from(selectableFollowStatuses));

    return loadingWrapper(loading)(
        <AntDropdownButton
            hasRight={false}
            type="primary"
            overlay={
                <Menu>
                    {options
                        .filter(option =>
                            selectableFollowStatuses.has(
                                Number(option.constraintValue),
                            ),
                        )
                        .map(item => {
                            return (
                                <Menu.Item
                                    onClick={async () => {
                                        const prevStatus =
                                            mutatingData.follow_status;
                                        mutatingData.follow_status =
                                            item.constraintValue;
                                        setLoading(true);
                                        const isSuccess = await defaultStore.update();
                                        setLoading(false);
                                        if (!isSuccess) {
                                            mutatingData.follow_status = prevStatus;
                                        } else {
                                            tryRefresh(['lead']);
                                        }
                                    }}
                                >
                                    {item.constraintLabel}
                                </Menu.Item>
                            );
                        })}
                </Menu>
            }
            buttonConfig={{
                children: optItem ? optItem.constraintLabel : '暂无跟进状态',
                size: 'small',
                type: disabled ? 'default' : 'primary',
                style: disabled
                    ? {
                        pointerEvents: 'none',
                        cursor: 'not-allowed',
                        opacity: '0.6',
                    }
                    : {},
                icon: 'down',
                // disabled, // 不好使
            }}
        />,
    );
});

export default observer(LeadDrawer);
