import $ from 'jquery';
import _ from 'lodash';

export const markInvalidBySelector = (selector: string) => {
    $(selector).addClass('maimai-invilid');
};

export const removeInvalidStatus = (selector: string) => {
    $(selector).removeClass('maimai-invilid');
};

export const removeAllInvalidUnder = (dom: HTMLDivElement | null) => {
    if (dom) {
        $(dom)
            .find('.maimai-invilid')
            .removeClass('maimai-invilid');

        $(dom)
            .find('.maimai-performance-rank-selector-invalid')
            .removeClass('maimai-performance-rank-selector-invalid');
    }
};

const dummyDiv = document.createElement('div');
export const isRichTextEmpty = (text: string) => {
    dummyDiv.innerHTML = text;
    return _.trim(dummyDiv.innerText) === '';
};

export const getDashboardMain = () => {
    return $('.dashboard-right .main')[0];
};

export const shakeDashboardMainScroll = () => {
    setTimeout(() => {
        const main = getDashboardMain();
        main.scrollTop = main.scrollTop + 1;
        main.scrollTop = main.scrollTop - 1;
    }, 0);
};

const dummySpan = document.createElement('span');
dummySpan.setAttribute(
    'style',
    'font-size: 12px; padding-right: 5px; display: inline-block; visibility: hidden; height: 0;',
);
document.body.append(dummySpan);

export const getWidthOfValueTitle = (text: string) => {
    dummySpan.innerText = text;
    return dummySpan.offsetWidth;
};
