import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { AdvancedFilter } from '@/components';
import { Input } from 'antd';
import {
    WidgetFilterNumberRange,
    FilterNumberRangeValueType,
    FilterEnumValueType,
    WidgetFilterEnum,
    WidgetCommonObject,
    FilterDateRangeValueType,
    WidgetFilterDateRange,
} from '@/components/common/form/widgets';
import { listMeta } from './mis';

const AdvancedFilterDemo4: React.FC<{}> = () => {
    const [isEmpty, setIsEmpty] = useState(false);
    const [sorttype, setSorttype] = useState('');
    const [value, setValue] = useState<FilterDateRangeValueType>([
        undefined,
        undefined,
    ]);
    return (
        <div
            style={{
                width: 600,
                padding: '5px 15px',
                backgroundColor: 'white',
                marginBottom: 20,
            }}
        >
            {/* <AdvancedFilter
                k={'any'}
                widget={
                    <div>
                        <WidgetFilterDateRange
                            value={value}
                            onChange={setValue}
                            options={{}}
                        />
                    </div>
                }
                isEmpty={isEmpty}
                onIsEmptyValueChange={nextIsEmpty => {
                    setIsEmpty(nextIsEmpty);
                }}
                onSorterChange={nextSorttype => {
                    setSorttype(nextSorttype);
                }}
            /> */}

            <br />
            <div>isEmpty: {'' + isEmpty}</div>
            <div>value: {'' + value}</div>
            <div>sorttype: {sorttype}</div>
        </div>
    );
};

const AdvancedFilterDemo3: React.FC<{}> = () => {
    const [isEmpty, setIsEmpty] = useState(false);
    const [sorttype, setSorttype] = useState('');
    const [value, setValue] = useState(undefined);

    const k = 'owner_id';
    const itemMeta = listMeta.objectMeta.find(meta => meta.fieldName === k);
    if (!itemMeta) {
        return <span>itemMeta notFound</span>;
    }
    const constraint = itemMeta.constraint;
    return (
        <div
            style={{
                width: 450,
                padding: '5px 15px',
                backgroundColor: 'white',
                marginBottom: 20,
            }}
        >
            {/* <AdvancedFilter
                k={'any'}
                widget={
                    <div>
                        <WidgetCommonObject
                            k={k}
                            value={value}
                            onChange={setValue}
                            options={{
                                placeholder: `搜索${itemMeta.fieldComment}`,
                                pageSize: 20,
                                constraint,
                                listMeta,
                                multiple: true,
                            }}
                        />
                    </div>
                }
                isEmpty={isEmpty}
                onIsEmptyValueChange={nextIsEmpty => {
                    setIsEmpty(nextIsEmpty);
                }}
                onSorterChange={nextSorttype => {
                    setSorttype(nextSorttype);
                }}
            /> */}

            <br />
            <div>isEmpty: {'' + isEmpty}</div>
            <div>value: {'' + JSON.stringify(value)}</div>
            <div>sorttype: {sorttype}</div>
        </div>
    );
};

const AdvancedFilterDemo2: React.FC<{}> = () => {
    const [isEmpty, setIsEmpty] = useState(false);
    const [sorttype, setSorttype] = useState('');
    const [value, setValue] = useState<FilterEnumValueType>([]);
    return (
        <div
            style={{
                width: 300,
                padding: '5px 15px',
                backgroundColor: 'white',
                marginBottom: 20,
            }}
        >
            {/* <AdvancedFilter
                k={'any'}
                widget={
                    <div>
                        <WidgetFilterEnum
                            value={value}
                            onChange={setValue}
                            options={{
                                options: [
                                    {
                                        label: 'A',
                                        value: 1,
                                    },
                                    {
                                        label: 'B',
                                        value: 2,
                                    },
                                    {
                                        label: 'C',
                                        value: 3,
                                    },
                                    {
                                        label: 'D',
                                        value: 4,
                                    },
                                    {
                                        label: 'E',
                                        value: 5,
                                    },
                                    {
                                        label: 'F',
                                        value: 6,
                                    },
                                    {
                                        label: 'G',
                                        value: 7,
                                    },
                                    {
                                        label: 'H',
                                        value: 8,
                                    },
                                    {
                                        label: 'I',
                                        value: 9,
                                    },
                                    {
                                        label: 'AA',
                                        value: 11,
                                    },
                                    {
                                        label: 'BB',
                                        value: 22,
                                    },
                                    {
                                        label: 'CC',
                                        value: 33,
                                    },
                                    {
                                        label: 'DD',
                                        value: 44,
                                    },
                                    {
                                        label: 'EE',
                                        value: 55,
                                    },
                                    {
                                        label: 'FF',
                                        value: 66,
                                    },
                                    {
                                        label: 'GG',
                                        value: 77,
                                    },
                                    {
                                        label: 'HH',
                                        value: 88,
                                    },
                                    {
                                        label: 'II',
                                        value: 99,
                                    },
                                ],
                            }}
                        />
                    </div>
                }
                isEmpty={isEmpty}
                onIsEmptyValueChange={nextIsEmpty => {
                    setIsEmpty(nextIsEmpty);
                }}
                onSorterChange={nextSorttype => {
                    setSorttype(nextSorttype);
                }}
            /> */}

            <br />
            <div>isEmpty: {'' + isEmpty}</div>
            <div>value: {'' + value}</div>
            <div>sorttype: {sorttype}</div>
        </div>
    );
};

const AdvancedFilterDemo1: React.FC<{}> = () => {
    const [isEmpty, setIsEmpty] = useState(false);
    const [sorttype, setSorttype] = useState('');
    const [value, setValue] = useState<FilterNumberRangeValueType>([
        undefined,
        undefined,
    ]);
    return (
        <div
            style={{
                width: 300,
                padding: '5px 15px',
                backgroundColor: 'white',
                marginBottom: 20,
            }}
        >
            {/* <AdvancedFilter
                k={'any'}
                widget={
                    <div>
                        <WidgetFilterNumberRange
                            value={value}
                            onChange={setValue}
                            options={{}}
                        />
                    </div>
                }
                isEmpty={isEmpty}
                onIsEmptyValueChange={nextIsEmpty => {
                    setIsEmpty(nextIsEmpty);
                }}
                onSorterChange={nextSorttype => {
                    setSorttype(nextSorttype);
                }}
            /> */}

            <br />
            <div>isEmpty: {'' + isEmpty}</div>
            <div>value: {'' + value}</div>
            <div>sorttype: {sorttype}</div>
        </div>
    );
};

const AdvancedFilterDemo: React.FC<RouteComponentProps> = () => {
    const [isEmpty, setIsEmpty] = useState(false);
    const [sorttype, setSorttype] = useState('');

    return (
        <>
            <AdvancedFilterDemo4 />
            <AdvancedFilterDemo3 />
            <AdvancedFilterDemo2 />
            <AdvancedFilterDemo1 />
            <div
                style={{
                    width: 300,
                    padding: '5px 15px',
                    backgroundColor: 'white',
                }}
            >
                {/* <AdvancedFilter
                    k={'any'}
                    widget={
                        <div>
                            <div style={{ marginBottom: 6 }}>内容搜索</div>
                            <Input placeholder="输入关键字" />
                        </div>
                    }
                    isEmpty={isEmpty}
                    onIsEmptyValueChange={nextIsEmpty => {
                        setIsEmpty(nextIsEmpty);
                    }}
                    onSorterChange={nextSorttype => {
                        setSorttype(nextSorttype);
                    }}
                /> */}

                <br />
                <div>isEmpty: {'' + isEmpty}</div>
                <div>sorttype: {sorttype}</div>
            </div>
        </>
    );
};

const ExportedAdvancedFilterDemo = observer(AdvancedFilterDemo);

export default ExportedAdvancedFilterDemo;
