import React, { useState, useEffect } from 'react';
import { Modal, Transfer } from 'antd';
import { TransferItem } from 'antd/lib/transfer';
import _ from 'lodash';
import { ISSMStageRequirementConfig } from '../store';
import { toJS } from 'mobx';
import './index.scss';

export interface ISSMTransferModalProps {
    requiredConfig: ISSMStageRequirementConfig;
    mode: 'condition' | 'field';
    requiredData: {
        requiredField: any[];
        requiredCondition: any[];
    };
    modalVis: boolean;
    setModalVis: (bool: boolean) => void;
    onOkClickHandle: (data: any[]) => void;
}
// 选择条件的穿梭框
const SSMTransferModal: React.FC<ISSMTransferModalProps> = props => {
    const {
        requiredConfig,
        requiredData,
        modalVis,
        setModalVis,
        mode,
        onOkClickHandle,
    } = props;
    const originRequiredData =
        mode === 'condition'
            ? requiredData.requiredCondition
            : requiredData.requiredField;
    const originRequiredConfig =
        mode === 'condition'
            ? requiredConfig.requiredCondition
            : requiredConfig.requiredField;
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    useEffect(() => {
        setTargetKeys(originRequiredData);
    }, [requiredData]);
    return (
        <Modal
            visible={modalVis}
            onCancel={() => {
                setTargetKeys([]);
                setModalVis(false);
            }}
            onOk={() => {
                if (onOkClickHandle && typeof onOkClickHandle === 'function') {
                    onOkClickHandle(targetKeys);
                    setTargetKeys([]);
                    setModalVis(false);
                }
            }}
            width="800px"
            className="ssm-transfer-modal"
        >
            <Transfer
                showSearch
                listStyle={{
                    width: '300px',
                    height: '400px',
                }}
                titles={[
                    `未选择${mode === 'condition' ? '必需条件' : '必填字段'}`,
                    `已选择${mode === 'condition' ? '必需条件' : '必填字段'}`,
                ]}
                dataSource={_.map(originRequiredConfig, (config: any) => {
                    console.log(toJS(config));
                    return {
                        ...config,
                        key: config.id as string,
                        title:
                            mode === 'condition'
                                ? config.conditionName
                                : config.fieldName,
                    };
                })}
                render={item => {
                    return item.title as string;
                }}
                targetKeys={targetKeys}
                onChange={targetKeys => {
                    setTargetKeys(targetKeys);
                }}
            />
        </Modal>
    );
};

export default SSMTransferModal;
