import React, { useEffect, useState, useContext, useCallback } from 'react';
import { message, Alert } from 'antd';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
} from '@/components';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet } from '@/utils';
import { observer } from 'mobx-react-lite';
import FormDemoTable from './components/table';
import { ActionDelete } from '@/pages/sales-follow-up/contact/list/components/delete';
import { defaultAuthDeco } from '@/containers/hoc';
import './index.scss';
import FormDrawer from '@/pages/sales-follow-up/contact/list/components/form-drawer';
import { AntButton } from '@/components/antd';
import { CtxProvider } from '@/utils/context';
import { ActionDedupDetail } from '@/pages/sales-follow-up/lead/list/components/dedup-detail';

const ContactList: React.FC<RouteComponentProps & {
    onCreate?: any;
    drawerData: any;
    type: string;
}> = ({ onCreate, type = 'all', drawerData = {} }) => {
    const currentCustomer = (drawerData.originData || [])[0] || {};

    const store = useContext(StoreContext);
    const [defaultStore] = useState(() => new store.ContactListStore());
    defaultStore.setPosition('customer-drawer');
    const userStore = store.getAuthStore();

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        defaultPerm,
        defaultFilter,
    } = defaultStore;
    const isOverFour = defaultPagination.amount >= 5;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const LeadListFetch = useCallback(() => defaultStore.fetch(), []);
    const [error, loading, reload] = useNet(LeadListFetch, {
        autoLoad: false,
        refreshKeys: ['contacts'],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);

    useEffect(() => {
        if (!currentCustomer.id) {
            return;
        }
        defaultStore.setDefaultFilters([
            {
                fieldId: 'customer_id',
                filterValue: currentCustomer.id,
                operateType: '=',
                parser: 'string',
            },
        ]);
        defaultStore.setDefaultFields(['customer_id']);
    }, [drawerData]);

    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady || !currentCustomer.id) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        confirmedfilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            defaultMeta.setTableId('contacts'); // TOFIX TODO: 动态设置
            defaultMeta.setOpUsername(userStore.userInfo.userId + '-' + type);
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {}); // 初始化元信息
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            defaultFilter.defaultSorter.setSorter({
                columnKey: 'is_kp',
                order: 'descend',
            });
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useEffect(() => {
        defaultStore.setListType(type);
    }, [type]);

    if (!preRequiredReady) {
        return <BlockLoading2 />;
    }

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = (
        <>
            <ActionDelete defaultStore={defaultStore} />
            <ActionDedupDetail
                contactList={defaultStore}
                caredContacts={defaultStore.dupDetailContacts}
            />
            <FormDrawer
                defaultStore={defaultStore}
                preRequiredReady={preRequiredReady}
                ignoreCtx={true}
            />
        </>
    );

    const title = null;
    const globalOps = (
        <>
            {defaultPerm.getPermByTypeAndAction(type)('create').visible && (
                <div style={{ height: 49 }}>
                    <AntButton
                        onClick={() => {
                            onCreate && onCreate();
                        }}
                    >
                        新建联系人
                    </AntButton>
                </div>
            )}
        </>
    );
    const multipleOps = null;

    const dataTable = (
        <FormDemoTable defaultStore={defaultStore} loading={loading} />
    );

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters: null,
        multipleOps,
        dataTable,
        message: isOverFour ? (
            <Alert
                type="warning"
                showIcon
                message={'当前用户联系数量较多，请及时维护KP信息'}
            />
        ) : null,
        filtersDisplay,
    };

    return (
        <LayoutStandardCrud className="opt-tab contacts-tab" {...layoutComs} />
    );
};

const FinalContactList = defaultAuthDeco(observer(ContactList));
export default FinalContactList;
