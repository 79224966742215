import React, { useState, useContext, useCallback, useEffect } from 'react';
import './index.scss';
import { observer } from 'mobx-react-lite';
import { AntButton } from '@/components';
import { Spin } from 'antd';
import _ from 'lodash';
import SettingMenu from '../../../../components/common/setting-menu/index';
import { StoreContext } from '@/stores';
import { ISSMBusinessTypeData } from './store';
import { useNet } from '@/utils';
import SSMActionBody from './ssmActionBody';

const SalesStageManagement: React.FC<{}> = () => {
    const [selectedData, setSelectedData] = useState<ISSMBusinessTypeData>({});
    const store = useContext(StoreContext);
    const SSMStore = store.getDefaultSSMStore();
    const {
        getSSMBusinessTypeList,
        getSSMStageRequirementConfig,
        SSMStageRequirement,
        SSMBusinessTypeList,
    } = SSMStore;
    const ssmBusinessTypeDataFetch = useCallback(getSSMBusinessTypeList, []);
    const [error, businessTypeLoading, businessTypeReload] = useNet(
        ssmBusinessTypeDataFetch,
    );
    return (
        <div className="pa-content bo-content">
            <div className="pa-title">
                销售阶段管理
                <span className="pa-title-description">
                    针对销售不同阶段的必填字段的自定义配置
                </span>
            </div>
            <div className="pa-body">
                <Spin spinning={businessTypeLoading}>
                    <div className="bo-menu-content">
                        <p className="bo-menu-title">业务类型</p>
                        <SettingMenu
                            menuItemsData={SSMBusinessTypeList}
                            uniqueKeyIndex={'bizType'}
                            titleIndex={'bizTypeName'}
                            onSelect={(selectedKey: string, data: any) => {
                                setSelectedData(data);
                            }}
                        />
                    </div>
                </Spin>
                <div className="pa-table-content">
                    {!_.isEmpty(selectedData) && (
                        <>
                            <SSMActionBody
                                reloadBusinessTypeData={businessTypeReload}
                                selectedData={selectedData}
                                fetchBusinessTypeLoading={businessTypeLoading}
                            />
                            <div className="save-bar"></div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default observer(SalesStageManagement);
