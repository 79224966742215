import * as Input from './input';
import * as InputSearchable from './input-searchable';
import * as InputSecret from './input-secret';
import * as NumberInput from './number';
import * as Select from './select';
import * as Switch from './switch';
import * as Upload from './upload';
import * as Dt from './date';
import * as DateTime from './datetime';
import * as Radio from './radio';
import * as Checkbox from './checkbox';
import * as SearchInput from './search-input';
import * as DateRange from './date-range';
import * as UserSelector from './userSelector';
import * as ValueRange from './value-range';
import * as Obj from './object';
import * as InputTextArea from './input-textarea';
import * as TreeSingleLeafonly from './product/tree-single-leafonly';
import * as SelectProductRights from './product/select-product-rights';
import * as SelectOptProducts from './opt/select-opt-product';
import * as TeamSelector from './teamSelector';
import * as FilterNumberRange from './filters/number-range';
import * as FilterEnum from './filters/enum';
import * as FilterDateRange from './filters/date-range';
import * as FilterSysUserSelector from './filters/sysUser-selector';
import * as InputUpload from './input-upload';
import { IUserSelectorProps } from '../../user-selector';
import { ITeamSelectorProps } from '../../team-selector/index';

import {
    WidgetMFollowCusGroupValueType,
    IWidgetMFollowCusOptions,
    WidgetMFollowCus,
} from './jobs/multiple-follow-cus';

export const Widgets = {
    input: Input.WidgetInput,
    inputUpload: InputUpload.WidgetInputUpload,
    inputSearchable: InputSearchable.WidgetInputSearchable,
    inputTextArea: InputTextArea.WidgetInputTextArea,
    inputSecret: InputSecret.WidgetInputSecret,
    number: NumberInput.WidgetNumber,
    select: Select.WidgetSelect,
    switch: Switch.WidgetSwitch,
    upload: Upload.WidgetUpload,
    date: Dt.WidgetDate,
    datetime: DateTime.WidgetDateTime,
    radio: Radio.WidgetRadio,
    checkbox: Checkbox.WidgetCheckbox,
    searchInput: SearchInput.WidgetSearchInput,
    dateRange: DateRange.WidgetDateRange,
    userSelector: UserSelector.WidgetUserSelector,
    valueRange: ValueRange.WidgetValueRange,
    object: Obj.WidgetCommonObject,
    teamSelector: TeamSelector.WidgetTeamSelector,
    filterNumberRange: FilterNumberRange.WidgetFilterNumberRange,
    filterEnum: FilterEnum.WidgetFilterEnum,
    filterDateRange: FilterDateRange.WidgetFilterDateRange,
    treeSingleLeafonly: TreeSingleLeafonly.WidgetTreeSingleLeafOnly,
    selectProductRights: SelectProductRights.WidgetSelectProductRight,
    selectOptProducts: SelectOptProducts.WidgetSelectOptProduct,
    sysUserSelector: FilterSysUserSelector.WidgetFilterSysUserSelector,
    mFollowCus: WidgetMFollowCus,
};

export type WidgetsKeys = keyof typeof Widgets;

export interface IWidgetsOptions {
    inputUploadOptions?: InputUpload.IInputUploadOptions;
    inputOptions?: Input.IInputOptions;
    inputSearchableOptions?: InputSearchable.IInputSearchableOptions;
    inputTextAreaOptions?: InputTextArea.IInputTextAreaOptions;
    inputSecretOptions?: InputSecret.IInputSecretOptions;
    numberOptions?: NumberInput.INumberOptions;
    selectOptions?: Select.ISelectOptions;
    switchOptions?: Switch.ISwitchOptions;
    uploadOptions?: Upload.IUploadOptions;
    dateOptions?: Dt.IDateOptions;
    datetimeOptions?: DateTime.IDateTimeOptions;
    radioOptions?: Radio.IRadioOptions;
    checkboxOptions?: Checkbox.ICheckboxOptions;
    searchInputOptions?: SearchInput.ISearchInputOptions;
    dateRangeOptions?: DateRange.IDateRangeOptions;
    userSelectorOptions?: IUserSelectorProps;
    valueRangeOptions?: ValueRange.IValueRangeOptions;
    objectOptions?: Obj.ICommonObjectOptions;
    teamSelectorOptions?: ITeamSelectorProps<any[] | any>;
    filterNumberRangeOptions?: FilterNumberRange.IFilterNumberRangeOptions;
    filterEnumOptions?: FilterEnum.IFilterEnumOptions;
    filterDateRangeOptions?: FilterDateRange.IFilterDateRangeOptions;
    treeSingleLeafonlyOptions?: TreeSingleLeafonly.ITreeSingleLeafOnlyOptions;
    selectProductRightsOptions?: SelectProductRights.ISelectProductRightOptions;
    selectOptProductsOptions?: SelectOptProducts.ISelectOptProductOptions;
    sysUserSelectorOptions?: FilterSysUserSelector.IFilterSysUserSelectorOptions;
    mFollowCusOptions?: IWidgetMFollowCusOptions;
}

export interface IWidgetsValueTypes {
    inputUploadValue?: InputUpload.InputUploadValueType;
    inputValue?: Input.InputValueType;
    inputSearchableValue?: InputSearchable.InputSearchableValueType;
    inputTextAreaValue?: InputTextArea.InputTextAreaValueType;
    inputSecretValue?: InputSecret.InputSecretValueType;
    numberValue?: NumberInput.NumberValueType;
    selectValue?: Select.SelectValueType;
    switchValue?: Switch.SwitchValueType;
    uploadValue?: Upload.UploadValueType;
    dateValue?: Dt.DateValueType;
    datetimeValue?: DateTime.DateTimeValueType;
    radioValue?: Radio.RadioValueType;
    checkboxValue?: Checkbox.CheckboxGroupValueType;
    searchValue?: SearchInput.SearchInputValue;
    dateRangeValue?: DateRange.DateRangeValueType;
    userSelectorValue?: UserSelector.IWidgetUserSelectorValue;
    valueRangeValue?: ValueRange.ValueRangeValueType;
    objectValue?: Obj.CommonObjectValueType;
    teamSlector?: TeamSelector.IWidgetTeamSelectorValue;
    filterNumberRangeValue?: FilterNumberRange.FilterNumberRangeValueType;
    filterEnumValue?: FilterEnum.FilterEnumValueType;
    filterDateRangeValue?: FilterDateRange.FilterDateRangeValueType;
    treeSingleLeafonlyValue?: TreeSingleLeafonly.ITreeSingleLeafOnlyOptions;
    selectProductRightsValue?: SelectProductRights.SelectProductRightValueType;
    selectOptProductsValue?: SelectOptProducts.SelectOptProductValueType;
    sysUserSelectorValue?: FilterSysUserSelector.FilterSysUserSelectorValueType;
    mFollowCusValue?: WidgetMFollowCusGroupValueType;
}

export * from './input-upload';
export * from './input';
export * from './input-searchable';
export * from './input-textarea';
export * from './object';
export * from './select';
export * from './switch';
export * from './upload';
export * from './date';
export * from './radio';
export * from './checkbox';
export * from './date-range';
export * from './button';
export * from './product/tree-single-leafonly';
export * from './product/select-product-rights';
export * from './common';
export * from './teamSelector';
export * from './userSelector';
export * from './filters/number-range';
export * from './filters/enum';
export * from './filters/date-range';
export * from './input-secret';
