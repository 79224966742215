import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    FastFilterStore,
    makeDefaultInitailingHook,
} from '@/stores/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import { mutate } from '@/api/graphql';
import _, { isTypedArray } from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    getLeads,
    TGetLeadsReturnType,
    xDelete,
    commonQuery,
    TGetCommonReturnType,
} from '@/api/rest';
import {
    defaultWritingDataParser,
    transformDataToTreeData,
    arrToJson,
} from '@/utils';
import EE from 'eventemitter3';
import $ from 'jquery';
import moment from 'moment';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';

export const fetchFeEquityDetailByBackEndFormat = async (str: string) => {
    // const sample = [
    //     {
    //         "originalValue": "t2",
    //         "relatedObj": {
    //             "relation_object_map": {},
    //             "equity_name": "test2",
    //             "equity_key": "t2",
    //             "id": 20,
    //             "value": "2"
    //         },
    //         "type": "obj"
    //     },
    //     {
    //         "originalValue": "xx",
    //         "relatedObj": {
    //             "relation_object_map": {},
    //             "equity_name": "xx",
    //             "equity_key": "xx",
    //             "id": 19,
    //             "value": "3"
    //         },
    //         "type": "obj"
    //     }
    // ]
    // "[{\"equityKey\":\"xx\",\"equityNum\":8}]"

    let list = [];
    try {
        const parsed = JSON.parse(str);
        if (_.isArray(parsed)) {
            list = parsed;
        }
    } catch (e) {}
    const ret: any[] = [];
    if (list.length !== 0) {
        const [d, e] = await commonQuery(
            'equity',
            {
                pageSize: list.length,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'equity_key',
                        operateType: 'in',
                        filterValue: list.map(item => item.equityKey).join(','),
                    },
                    {
                        fieldId: 'end_time',
                        filterValue: moment()
                            .endOf('day')
                            .format('YYYY-MM-DD HH:mm:ss'),
                        operateType: '>',
                    },
                ],
            },
        );

        console.log(d, e, 'xxvvbbnn');
        if (d === null || e !== null) {
            return ret;
        }
        const { datas } = d;
        if (!_.isArray(datas)) {
            return ret;
        }
        const datasMap = arrToJson(datas, 'equity_key');
        for (let item of list) {
            item = item || {};
            const { equityKey, equityNum, convertibilityDiscount } = item;
            if (!equityKey) {
                continue;
            }
            const datum = datasMap[equityKey];
            if (!datum) {
                continue;
            }

            const curRetItem = {
                originalValue: equityKey,
                relatedObj: {
                    relation_object_map: {},
                    equity_name: datum.equity_name,
                    equity_key: equityKey,
                    id: datum.id,
                    value: equityNum,
                    original_value: equityNum,
                    convertibility_discount: convertibilityDiscount,
                },
                type: 'obj',
            };
            ret.push(curRetItem);
        }
    }
    return ret;
};
