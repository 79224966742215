import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import _ from 'lodash';
import { toJS } from 'mobx';
import { arrToJson, defaultAxios } from '@/utils';
import { IObjMetaData } from '../data-choose';
import { TableLv2 } from '../../../../../../components/common/table/level2';
import { Popover, Button } from 'antd';
import './index.less';
export interface IDataAnalyseProps {
    nextStep: () => void;
    prevStep: () => void;
}
interface IRenderDataItem {
    importHeader: string;
    metaHeader: string;
    metaHeaderCn?: string;
    isRequired: boolean;
    exampleOne: string;
    exampleTwo: string;
    isError: boolean;
    errorText?: string;
    exampleOneError?: string;
    exampleTwoError?: string;
    metaData?: IObjMetaData | null;
}
const DataAnalyse: React.FC<IDataAnalyseProps> = props => {
    const { nextStep, prevStep } = props;
    const store = useContext(StoreContext);
    const dmiStore = store.getDefaultDataManageImportStore();
    const {
        canEditMetaData,
        requiredMetaData,
        sheetJSON,
        selectedExampleMetaKey,
        sheetHeader,
        setFinalUploadJSON,
        uploadJSONData,
        uploadLoading,
        clearUploadData,
    } = dmiStore;
    const [renderArrayData, setRenderArrayData] = useState<IRenderDataItem[]>(
        [],
    );

    console.log(renderArrayData, 'renderArrayData');
    const [renderLoading, setRenderLoading] = useState(true);
    const [checkError, setCheckError] = useState(true);
    const sheetOne = sheetJSON?.[0];
    const sheetTwo = sheetJSON?.[1];
    const groupRequiredMateDataByComment = arrToJson(
        requiredMetaData,
        'fieldComment',
    );
    const groupCanEditMetaData = arrToJson(canEditMetaData, 'fieldName');
    const groupCanEditMetaDataByComment = arrToJson(
        canEditMetaData,
        'fieldComment',
    );
    // const isUseMetaKey = selectedExampleMetaKey.length > sheetHeader.length;
    useEffect(() => {
        console.log(toJS(sheetJSON));
        checkDataCorrect();
    }, [sheetJSON]);
    useEffect(() => {
        if (renderArrayData.length) {
            let isError = false;
            for (const item of renderArrayData) {
                if (item.isError) {
                    isError = true;
                    break;
                }
            }
            setCheckError(isError);
            setRenderLoading(false);
        }
    }, [renderArrayData]);
    const makeImportJson = async () => {
        // 将渲染的数据和原数据反译为后端需要的json
        const sheetHeaderMapMetaHeader: any = {};
        _.forEach(renderArrayData, item => {
            sheetHeaderMapMetaHeader[item.importHeader] = item.metaHeader;
        });
        const finalJson = _.map(sheetJSON, json => {
            console.log(toJS(json));
            const row: any = {};
            _.forEach(json, (rowItem, rowKey) => {
                if (rowKey === '__rowNum__') {
                    return;
                }
                if (sheetHeaderMapMetaHeader?.[rowKey]) {
                    row[sheetHeaderMapMetaHeader?.[rowKey]] = rowItem;
                } else {
                    throw new Error('反译导入数据失败');
                }
            });
            return row;
        });
        setFinalUploadJSON(finalJson);
        const res = await uploadJSONData();
        if (res) {
            nextStep();
            clearUploadData();
        }
    };
    const makeRenderDataArray = () => {
        const finalArray: IRenderDataItem[] = [];
        const requiredMetaFieldComment = Object.keys(
            groupRequiredMateDataByComment,
        );
        for (const comment of requiredMetaFieldComment) {
            if (!_.includes(sheetHeader, comment)) {
                const finalArrayItem: IRenderDataItem = {
                    importHeader: '',
                    metaHeader:
                        groupRequiredMateDataByComment?.[comment]?.fieldName ||
                        '',
                    metaHeaderCn: comment,
                    isRequired: true,
                    exampleOne: '',
                    exampleTwo: '',
                    isError: true,
                    errorText: '表格缺乏该必填字段',
                    metaData: groupRequiredMateDataByComment?.[comment],
                };
                finalArray.push(finalArrayItem);
            }
        }
        _.forEach(sheetHeader, (key, index) => {
            // 一旦header跟选择的数量不匹配则认为有错
            // 并且不能交换header的顺序，所以直接使用index进行索引
            // ---- 上述情况不满足需求，需求要求直接用导入的表头进行映射检索，所以选择与导入的字段没有直接关系
            // 首先还需要检查必填字段是否在表头中全部存在
            if (key) {
                // 下标对应元数据
                const existingMetaData = groupCanEditMetaDataByComment?.[key];
                if (existingMetaData) {
                    // 构造展示值
                    const finalArrayItem: IRenderDataItem = {
                        importHeader: key || '',
                        metaHeader: existingMetaData.fieldName || '',
                        metaHeaderCn: existingMetaData?.fieldComment,
                        isRequired:
                            existingMetaData.newFieldMetaItem.isRequired === 1,
                        exampleOne: sheetOne?.[key] || '',
                        exampleTwo: sheetTwo?.[key] || '',
                        isError: false,
                        metaData: existingMetaData,
                    };
                    finalArray.push(finalArrayItem);
                } else {
                    // 下标对应的元数据找不到了(用户修改表头)
                    finalArray.push({
                        importHeader: key || '',
                        metaHeader: '',
                        isRequired: false,
                        exampleOne: sheetOne?.[key] || '',
                        exampleTwo: sheetTwo?.[key] || '',
                        isError: true,
                        errorText: '该表头无对应字段，请检查表头',
                    });
                }
            } else {
                // 表头空了？
                // const existingMetaData = groupCanEditMetaData?.[selectedExampleMetaKey?.[index]] || undefined;
                // 数量不匹配则指示错误
                finalArray.push({
                    importHeader: '',
                    metaHeader: '',
                    metaHeaderCn: '',
                    isRequired: false,
                    exampleOne: '',
                    exampleTwo: '',
                    isError: true,
                    errorText: '有空表头存在，请检查数据',
                });
            }
        });
        return finalArray;
    };
    const checkDataCorrect = () => {
        const lv1RenderArray = makeRenderDataArray();
        const lv2RenderArray: IRenderDataItem[] = _.map(
            lv1RenderArray,
            lv1RenderItem => {
                if (lv1RenderItem.isRequired) {
                    // let isError = false;
                    if (!lv1RenderItem.exampleOne) {
                        // isError = true;
                        lv1RenderItem.isError = true;
                        lv1RenderItem.exampleOneError = '该字段不能为空';
                    }
                    if (!lv1RenderItem.exampleTwo && sheetTwo) {
                        // isError = true;
                        lv1RenderItem.isError = true;
                        lv1RenderItem.exampleTwoError = '该字段不能为空';
                    }
                    // if (isError) {
                    //     return finalRenderItem;
                    // }
                }
                if (!lv1RenderItem.exampleTwo && !lv1RenderItem.exampleOne) {
                    return lv1RenderItem;
                }
                if (lv1RenderItem.metaData) {
                    const {
                        fieldName,
                        fieldComment,
                        constraint,
                        fieldShowType,
                        newFieldMetaItem,
                    } = lv1RenderItem.metaData;
                    if (fieldName === 'id') {
                        return lv1RenderItem;
                    }
                    if (!fieldComment || !fieldName || !fieldShowType) {
                        return {
                            ...lv1RenderItem,
                            errorText: lv1RenderItem.errorText + '\n元数据失效',
                            isError: true,
                        };
                    }
                    // 只校验枚举值
                    if (_.isObject(constraint)) {
                        const {
                            constraintItemList,
                            fieldConstraintType,
                        } = constraint;
                        if (fieldConstraintType === 'obj') {
                            // 校验不了关联字段，因为不能确定长度
                            // // 关联字段
                            // const fetchObjectUrl = '/bff/api/rest/transparent/list';
                            // const fetchObjCode = newFieldMetaItem.relationObject.objectCode;
                            // const fetchObjField = newFieldMetaItem.relationObject.findFields;
                            // const showKey = newFieldMetaItem.relationObject.showField;
                            // const fetchObjectData = async () => {
                            //     if (!fetchObjCode) { return []; }
                            //     const finalUrl = `${fetchObjectUrl}?tableCode=${fetchObjCode}`;
                            //     const finalFetchData: any = {
                            //         fieldQueries: [],
                            //         pageNum: 1,
                            //         pageSize: 200,
                            //         selectiveFields: fetchObjField
                            //     };
                            //     const [d, e] = await defaultAxios.post(finalUrl, finalFetchData);
                            //     if(d && d.data) {
                            //         return d.data;
                            //     }
                            // };
                            // const objData = await fetchObjectData();
                            // const showKeyArray = _.map(objData, (data) => {
                            //     return data[showKey];
                            // });
                            // const finalRenderItem = {
                            //     ...lv1RenderItem
                            // }
                            // if(lv1RenderItem.exampleOne && !_.includes(showKeyArray, lv1RenderItem.exampleOne)) {
                            //     finalRenderItem.isError = true;
                            //     finalRenderItem.exampleOneError = '导入值在该字段下不存在';
                            // }
                            // if(lv1RenderItem.exampleTwo && !_.includes(showKeyArray, lv1RenderItem.exampleTwo)) {
                            //     finalRenderItem.isError = true;
                            //     finalRenderItem.exampleTwoError = '导入值在该字段下不存在';
                            // }
                            return lv1RenderItem;
                        } else if (constraintItemList) {
                            // 枚举值
                            const finalRenderItem = {
                                ...lv1RenderItem,
                            };
                            if (lv1RenderItem.exampleOne) {
                                const checkOneExisting = _.find(
                                    constraintItemList,
                                    item => {
                                        return (
                                            item.constraintLabel ===
                                            lv1RenderItem.exampleOne
                                        );
                                    },
                                );
                                if (!checkOneExisting) {
                                    finalRenderItem.isError = true;
                                    finalRenderItem.exampleOneError = `${
                                        finalRenderItem.exampleOneError
                                            ? `${finalRenderItem.exampleOneError}\n`
                                            : ''
                                    }导入值在该字段下不存在`;
                                }
                            }
                            if (lv1RenderItem.exampleTwo) {
                                const checkTwoExisting = _.find(
                                    constraintItemList,
                                    item => {
                                        return (
                                            item.constraintLabel ===
                                            lv1RenderItem.exampleTwo
                                        );
                                    },
                                );
                                if (!checkTwoExisting) {
                                    finalRenderItem.isError = true;
                                    finalRenderItem.exampleTwoError = `${
                                        finalRenderItem.exampleTwoError
                                            ? `${finalRenderItem.exampleTwoError}\n`
                                            : ''
                                    }导入值在该字段下不存在`;
                                }
                            }

                            return finalRenderItem;
                        }
                    }
                    return lv1RenderItem;
                } else {
                    return lv1RenderItem;
                }
            },
        );
        setRenderArrayData(lv2RenderArray);
    };
    return (
        <div className="dmi-table-content">
            <TableLv2<IRenderDataItem>
                loading={renderLoading}
                className={'data-analyse-table'}
                dataSource={renderArrayData}
                columns={[
                    {
                        key: 'importHeader',
                        title: '导入表头',
                        width: 150,
                        render: item => {
                            const hasError = item.isError && item.errorText;
                            return hasError ? (
                                <Popover content={item.errorText}>
                                    <div
                                        className={`ana-item-value ${
                                            hasError ? 'has-error' : ''
                                        }`}
                                    >
                                        {item.importHeader}
                                    </div>
                                </Popover>
                            ) : (
                                <div
                                    className={`ana-item-value ${
                                        hasError ? 'has-error' : ''
                                    }`}
                                >
                                    {item.importHeader}
                                </div>
                            );
                        },
                    },
                    {
                        key: 'metaHeaderCn',
                        title: '映射表头',
                        width: 150,
                        render: item => {
                            const hasError = item.isError && item.errorText;
                            return hasError ? (
                                <Popover content={item.errorText}>
                                    <div
                                        className={`ana-item-value ${
                                            hasError ? 'has-error' : ''
                                        }`}
                                    >
                                        {item.metaHeaderCn}
                                    </div>
                                </Popover>
                            ) : (
                                <div
                                    className={`ana-item-value ${
                                        hasError ? 'has-error' : ''
                                    }`}
                                >
                                    {item.metaHeaderCn}
                                </div>
                            );
                        },
                    },
                    {
                        key: 'isRequired',
                        title: '是否必填',
                        dataIndex: 'isRequired',
                        width: 100,
                        render: v => {
                            return (
                                <div className={`ana-item-value`}>
                                    {v ? '是' : '否'}
                                </div>
                            );
                        },
                    },
                    {
                        key: 'exampleOne',
                        title: '样例1/第一行',
                        width: 200,
                        render: item => {
                            const hasError =
                                item.isError && item.exampleOneError;
                            return hasError ? (
                                <Popover content={item.exampleOneError}>
                                    <div
                                        className={`ana-item-value ${
                                            hasError ? 'has-error' : ''
                                        }`}
                                    >
                                        {item.exampleOne}
                                    </div>
                                </Popover>
                            ) : (
                                <div
                                    className={`ana-item-value ${
                                        hasError ? 'has-error' : ''
                                    }`}
                                >
                                    {item.exampleOne}
                                </div>
                            );
                        },
                    },
                    {
                        key: 'exampleTwo',
                        title: '样例2/第二行',
                        width: 200,
                        render: item => {
                            const hasError =
                                item.isError && item.exampleTwoError;
                            return hasError ? (
                                <Popover content={item.exampleTwoError}>
                                    <div
                                        className={`ana-item-value ${
                                            hasError ? 'has-error' : ''
                                        }`}
                                    >
                                        {item.exampleTwo}
                                    </div>
                                </Popover>
                            ) : (
                                <div
                                    className={`ana-item-value ${
                                        hasError ? 'has-error' : ''
                                    }`}
                                >
                                    {item.exampleTwo}
                                </div>
                            );
                        },
                    },
                ]}
                pagination={false}
                closeRowSelection={true}
            />
            <div className="data-analyse-action-bar">
                <Button type="primary" onClick={prevStep}>
                    上一步
                </Button>
                <Button
                    type="primary"
                    onClick={makeImportJson}
                    disabled={checkError}
                    loading={uploadLoading}
                >
                    确认提交
                </Button>
            </div>
        </div>
    );
};

export default observer(DataAnalyse);
