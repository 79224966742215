import * as React from 'react';
import { useState, useContext, useEffect, useCallback } from 'react';
import './index.scss';
import { FormLV2, IFormLV2Props } from '@/components';
import {
    IRuleSettingData,
    widgetsRuleSetting,
    ruleFormItemOptions,
    ruleValidators,
    IRuleExtraData,
    makeRuleActionWidgets,
} from './options';
import {
    getAutoOnChangeHandlerOriginal,
    embedDataToWidgets,
} from '../../../../components/common/form/level1';
import { message, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { IHSPGDataTypes } from '..';
import { useNet } from '@/utils';
import { transformAllArrayToString } from '../../../../utils/helpers';
import _ from 'lodash';

export interface IRuleSettingModalProps {
    visible: boolean;
    setVis: (bool: boolean) => void;
    selectedItems?: IHSPGDataTypes;
    reloadTable: () => void;
}
const RuleSettingModal: React.FC<IRuleSettingModalProps> = props => {
    const store = useContext(StoreContext);
    const SettingCHSPGStore = store.getDefaultSettingCHSPGStore();
    const {
        visible = false,
        setVis = () => {},
        selectedItems,
        reloadTable = () => {},
    } = props;
    const [modalData, setModalData] = useState<IRuleSettingData>({
        returnCantClaim: '',
        recycleType: '',
        assignType: '',
        recycleGetNotFollow: '',
        recycleGetNotFollowTo: '',
        inCooperationFollowup: '',
        noCooperation20Opportunity: '',
        noCooperation40Opportunity: '',
        // recycleGetNoNewOpportunity: '',
        recycleNotDeal: '',
        recycleBreak: '',
        recycleDeferred: '',
        recycleAlertOne: '',
        recycleAlertTwo: '',
        recycleAlertThree: '',
        // recycleGetDefinedDeal: [],
        // recycleDealNotFollow: '',
        // recycleDealNotFollowTo: '',
    });
    // 请求初始modal数据
    useEffect(() => {
        if (selectedItems?.id) {
            console.log(selectedItems.id);
            SettingCHSPGStore.fetchCHSPGPoolSetting(
                selectedItems?.id,
                'rule',
                setModalData,
            );
        }
    }, [selectedItems]);
    const clearModalData = () => {
        setModalData({
            returnCantClaim: '',
            recycleType: '',
            assignType: '',
            recycleGetNotFollow: '',
            recycleGetNotFollowTo: '',
            inCooperationFollowup: '',
            noCooperation20Opportunity: '',
            noCooperation40Opportunity: '',
            // recycleGetNoNewOpportunity: '',
            recycleNotDeal: '',
            recycleBreak: '',
            recycleDeferred: '',
            recycleAlertOne: '',
            recycleAlertTwo: '',
            recycleAlertThree: '',
            // recycleGetDefinedDeal: [],
            // recycleDealNotFollow: '',
            // recycleDealNotFollowTo: '',
        });
    };
    // fetchCustomerSetting();
    // const [error, modalLoading, reload] = useNet(fetchCustomerSetting);
    // 获取默认的数据回调
    const onDataChange = getAutoOnChangeHandlerOriginal(
        modalData as IRuleSettingData,
        setModalData,
    );
    // 动态配置生成
    const finalWidgets = widgetsRuleSetting();
    // 灌数据
    embedDataToWidgets<IRuleSettingData>(finalWidgets, modalData);
    const RuleSetModalProps: IFormLV2Props<IRuleSettingData, IRuleExtraData> = {
        styleLv2: {
            // width: 500,
        },
        classNameLv2: 'chspg-setting-modal',
        data: modalData,
        formOptions: {
            layout: 'vertical',
        },
        catchGroupProps: {
            'gc-rule': {
                afterNum: 2,
                groupClassName: 'chspg-setting-gc-rule',
                visible: modalData.recycleType === 2,
            },
            'recycle-alert': {
                groupClassName: 'chspg-setting-recycle-alert-content',
            },
        },
        widgets: finalWidgets,
        actionWidgets: makeRuleActionWidgets(
            SettingCHSPGStore.ruleSettingLoading,
        ),
        onChange: onDataChange,
        formItemOptions: ruleFormItemOptions,
        onInteract: (key, type, value) => {
            console.log('key, type, value: ', key, type, value);
        },
        onAction: async (actionType, data, isPass, validateResult) => {
            console.log(
                'actionType, data, isPass, validateResult: ',
                actionType,
                data,
                isPass,
                validateResult,
            );
            if (actionType === 'submit') {
                if (isPass) {
                    const finalData: any = {
                        ...modalData,
                        recycleAlert: _.filter(
                            [
                                modalData.recycleAlertOne,
                                modalData.recycleAlertTwo,
                                modalData.recycleAlertThree,
                            ],
                            data => {
                                return !!data;
                            },
                        ),
                    };

                    delete finalData.recycleAlertOne;
                    delete finalData.recycleAlertTwo;
                    delete finalData.recycleAlertThree;
                    delete finalData.createUser;
                    finalData.isRule = true;
                    // const newRuleData = transformAllArrayToString(modalData);
                    if (selectedItems) {
                        // 这里必然有值
                        const [
                            d,
                            e,
                        ] = await SettingCHSPGStore.setCHSPGPoolSetting(
                            selectedItems.id,
                            finalData,
                        );
                        if (!d.msg) {
                            message.success('修改成功', 1);
                            reloadTable();
                            setVis(false);
                        } else {
                            message.error(d.msg || '修改失败', 1);
                        }
                    }
                    // toggleModalVisible()
                    // toggleDrawerVisible()
                } else {
                    message.error('数据不正确，不可提交');
                }
            }

            if (actionType === 'cancel') {
                setVis(false);
            }
        },
        validators: ruleValidators,
        validateMode: 'instantly',
        validatorRelationship: {
            recycleGetNotFollow: [
                'recycleGetNotFollow',
                'recycleNotDeal',
                'recycleGetNotFollowTo',
            ],
            recycleNotDeal: ['recycleGetNotFollow', 'recycleNotDeal'],
            recycleGetNotFollowTo: [
                'recycleGetNotFollowTo',
                'recycleNotDeal',
                'recycleGetNotFollow',
            ],
        },
    };
    const propsWithModalContainer: IFormLV2Props<
        IRuleSettingData,
        IRuleExtraData
    > = {
        ...RuleSetModalProps,
        container: 'modal',
        containerModalProps: {
            visible,
            title: '分组规则设置',
            onCancel: () => {
                setVis(false);
                clearModalData();
            },
            width: 600,
            wrapClassName: 'chspg-modal-content',
            destroyOnClose: true,
            loading: SettingCHSPGStore.ruleSettingLoading,
        },
    };
    return <FormLV2<IRuleSettingData> {...propsWithModalContainer}></FormLV2>;
};
export default observer(RuleSettingModal);
