import React from 'react';
import { RouteComponentProps } from '@reach/router';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import './index.scss';
import ProcessCatListAny from '@/pages/sales-follow-up/process/list/category-any.tsx';
import { AntButton } from '@/components';

const DemoList: React.FC<RouteComponentProps & {
    type: string;
}> = () => {
    return <ProcessCatListAny processKey="productopen" title="开通申请汇总" />;
};

const FinalDemoList = observer(DemoList);
export default FinalDemoList;
