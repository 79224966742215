import React, { memo } from 'react';
import { AutoEllipsis } from '../../auto-ellipsis';

export const Echo: React.FC<{ args: any[] }> = memo(({ args }) => {
    const value = args[0];
    if (!value) {
        return null;
    }
    return <AutoEllipsis text={value} />;
});

export const Echo2 = (props: { text: string }) => {
    const value = props.text;
    if (!value) {
        return null;
    }
    return value;
};
