// 活动记录，重新请求的次数过多，所以单独请求，单独刷新
// 未接口聚合
import React, { useState, useContext, useEffect, useCallback } from 'react';
import './index.scss';
import { AntCollapsePanel, AntInputTextArea } from '@/components/antd';
import { CollapseProps, CollapsePanelProps } from 'antd/lib/collapse';
import { DatePicker, Icon, Select, message, Collapse } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { defaultAxios } from '@/utils';
import { useNet } from '@/utils';
import DrawerTimeLine, {
    ICustomerDrawerTimeLineItems,
} from '../../drawer/drawer-time-line';
import DrawerTimeLineNew from '../../drawer/drawer-time-line/index-new';
import { Spin } from 'antd';
import FlexibleContent from '../../flexible-content';
import { AntButton } from '../../../antd/index';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';

const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;

export interface ICustomerActionLogSaveData {
    arType?: string; // 记录类型
    bizId?: string; // 跟进线索/客户
    note?: string; // 记录详情
    followType?: string; // 跟进方式
    nextDate?: any; // 跟进计划
}
export interface ICustomerActionLogDailyData {
    id?: string | number; // id
    ar_type?: string | number; // 记录类型
    // arValue?: string; // 记录类型文本
    follow_type?: string | number; // 跟进方式
    // followValue?: string; // 跟进方式文本
    note?: string; // 记录详情
    bizId?: string; // 跟进线索/客户
    next_date?: string; // 下次跟进时间
    next_note?: string; // 下次跟进计划
    schedule_note?: string; // 备忘内容
    schedule_date?: string; // 备忘时间
    create_time?: string | number; // 新建时间
    create_user?: string | number; // 跟进(创建)人
    update_time?: string | number; // 更新时间
    current_name?: string | number; // 姓名
    callrecord_id?: string | number; // 通话id
    call_file_id?: string | number; // 录音id
    call_content?: string; // 录音文字
    create_user_name?: string | number; // 跟进(创建)人姓名
    icon?: string; // 图标
    background?: string; // 颜色
    contacts_id?: string | number; // 联系人id
    contacts_name?: string; // 联系人姓名
    contacts_phone?: number; // 联系人电话
    image?: string; // 图片
    is_well_received?: string | number; //是否好评
    evaluate_id?: string | number; //最新评论id
    activation_evaluate?: string; //最新评论内容
    evaluate_time?: string | number; //最新评论时间
    evaluate_user?: string; //最新评论人
    thumbs_id?: string | number; //最新点赞id
    thumbs_time?: string | number; //最新点赞时间
    thumbs_up_user?: string | number; //最新点赞人
    thumbs_up_num?: number; //点赞数
    thumbs_down_num?: number; //差评数
    follow_target?: number; //跟进目的
    call_time_length?: number; //通话时间
    opportunity_name?: string; //商机名称
    after_win_rate?: number; //推进后赢率
    relate_results?: number; // 联系结果
    relation_object_map?: any;
    is_valid?: number; // 是否有效
}
export interface ICustomerActionLogData {
    day: string;
    dailyData: ICustomerActionLogDailyData[];
}
export type TGetCustomerActionLogReturnType = NetResponse<{
    data: ICustomerActionLogData[];
    msg: string;
    code: number;
}>;
type TGetCustomerActionLogFollowPersonListReturnType = NetResponse<{
    data: {
        id: number;
        name: string;
        phone: string;
    }[];
    msg: string;
    code: number;
}>;
export interface ICustomerActionLogProps {
    id?: string | number; // id
    entityType?: string; // '实体type'
    actionLogConfig: {
        arType: any[];
        followType: any[];
    };
    drawerVis: boolean; // 抽屉展示
    setFollowLogModalVis: (bool: boolean) => void; // 单独抽出活动记录

    // @shaomingquan 线索抽屉用
    defaultArType?: string; // 默认的类型
    ArTypeSelectVisible?: boolean; // filter中类型的select是否展示
    targetFixed?: boolean; // 固定活动记录目标（外部指示，内部不可改动）
    permFollow?: boolean;
}

const CustomerActionLog: React.FC<ICustomerActionLogProps & {
    isNewCustomer?: number;
}> = props => {
    const {
        id,
        actionLogConfig = { arType: [], followType: [] },
        drawerVis,
        defaultArType,
        entityType,
        setFollowLogModalVis,
        permFollow = true,
        isNewCustomer = -1,
    } = props;
    const ArTypeSelectVisible = _.isNil(props.ArTypeSelectVisible)
        ? true
        : props.ArTypeSelectVisible;
    const store = useContext(StoreContext);
    const customerListStore = store.getDefaultCustomerListStore();
    const [actionLogData, setActionLogData] = useState<
        ICustomerActionLogData[]
    >([]); // 活动记录数据源

    const CollapsePanel2: React.FC<CollapsePanelProps & {
        isActive?: boolean;
        permFollow?: boolean;
        setFollowLogModalVis: Function;
    }> = props => {
        const renderHeader = () => {
            // console.log('actionprops', props);
            const {
                header,
                isActive,
                permFollow,
                setFollowLogModalVis,
            } = props;
            return (
                // <div className="crm-collapse-header">
                //     {props.header}
                //     <Icon
                //         style={{ color: '#D1D3DE', marginLeft: '5px' }}
                //         type="caret-right"
                //         rotate={isActive ? 90 : 0}
                //     />
                // </div>
                <p className="title">
                    <span
                        className="title-content"
                    // onClick={() => SetIsActive(!isActive)}
                    >
                        {header}
                        <Icon
                            style={{
                                color: '#D1D3DE',
                                marginLeft: '5px',
                            }}
                            type="caret-right"
                            rotate={isActive ? 90 : 0}
                        />
                    </span>
                    <Icon
                        style={
                            permFollow
                                ? {}
                                : {
                                    visibility: 'hidden',
                                    pointerEvents: 'none',
                                }
                        }
                        onClick={e => {
                            setFollowLogModalVis(true);
                            customerListStore.defaultEE.emit(
                                'refresh-action-log',
                            );
                            e.stopPropagation();
                            // setActionLogModalVis(true);
                            // props.onStartAddActionLog &&
                            //     props.onStartAddActionLog();
                        }}
                        className="edit"
                        type="plus"
                    />
                </p>
            );
        };
        return (
            <CollapsePanel
                showArrow={false}
                {...props}
                header={renderHeader()}
            />
        );
    };

    useEffect(() => {
        return () => {
            customerListStore.defaultEE.removeListener(
                'add-follow-log-success',
            );
            // 关闭抽屉时清空所有
            setActionLogData([]);
        };
    }, []);
    useEffect(() => {
        customerListStore.defaultEE.on('add-follow-log-success', reload);
        // console.log('emiton', customerListStore.defaultEE);
        // 需要在显示添加活动记录弹窗时重新请求 fetchCustomerActionLog 以保证最新评论显示正确
        customerListStore.defaultEE.on('save-action-log-show', reload);
        customerListStore.defaultEE.on('refresh-action-log', reload);
        return () => {
            customerListStore.defaultEE.off('add-follow-log-success', reload);
            customerListStore.defaultEE.off('save-action-log-show', reload);
            customerListStore.defaultEE.off('refresh-action-log', reload);
        };
    }, [actionLogData]);
    const [actionLogSearchValue, setActionLogSearchValue] = useState<{
        arType?: string | number;
    }>({
        arType: defaultArType || 0,
    });
    const [actionLogSearchTime, setActionLogSearchTime] = useState<
        string | number
    >(0);
    // 活动记录列表
    const fetchCustomerActionLog: () => Promise<
        TGetCustomerActionLogReturnType
    > = async () => {
        const thisId = id;
        if (_.isNil(id)) {
            return [null, null];
        }
        const beginDate = actionLogSearchTime
            ? moment()
                .subtract(actionLogSearchTime, 'day')
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss')
            : undefined;
        const finalSearchData: any = {
            ...actionLogSearchValue,
            arType: actionLogSearchValue.arType,
            id,
            entityType,
            beginDate,
            endDate: actionLogSearchTime
                ? moment()
                    .endOf('day')
                    .format('YYYY-MM-DD HH:mm:ss')
                : undefined,
        };
        const [d, e] = await defaultAxios.get(
            `/bff/api/rest/customer/actionLog`,
            finalSearchData,
        );
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d && d.data && Array.isArray(d?.data)) {
            if (thisId === id) {
                setActionLogData(d?.data || []);
            }
        } else {
            if (d.msg) {
                message.error(d.msg);
            }
            setActionLogData([]);
        }
        return [data, error];
    };
    const [error, loading, reload] = useNet(fetchCustomerActionLog, {
        defaultLoading: false,
    });

    // 类型选择变更
    const onActionSearchTypeChange = (value: string | any) => {
        if (actionLogSearchValue.arType === value) {
            return;
        }
        setActionLogSearchValue({
            ...actionLogSearchValue,
            arType: value,
        });
        reload();
    };
    return (
        <>
            <Spin spinning={loading}>
                <div className="customer-drawer-action-log">
                    <Collapse
                        key={'Collapse_action_log'}
                        className="action-log-collapse"
                        bordered={false}
                        defaultActiveKey={['action_log']}
                        expandIcon={({ isActive }) => (
                            <Icon
                                type="caret-right"
                                rotate={isActive ? 90 : 0}
                            />
                        )}
                    >
                        <CollapsePanel2
                            header="活动记录"
                            key="action_log"
                            permFollow={permFollow}
                            setFollowLogModalVis={setFollowLogModalVis}
                        >
                            <div className="filter">
                                <div className="filter-item">
                                    <label>发布时间：</label>
                                    {/* <RangePicker
                                format="YYYY-MM-DD"
                                value={[
                                    moment(actionLogSearchValue.beginDate),
                                    moment(actionLogSearchValue.endDate),
                                ]}
                                onChange={onActionSearchTimeChange}
                                style={{ width: '250px' }}
                                allowClear={false}
                            /> */}
                                    <Select
                                        placeholder="时间"
                                        value={actionLogSearchTime}
                                        onSelect={(val: any) => {
                                            setActionLogSearchTime(val);
                                            reload();
                                        }}
                                        style={{ width: '110px' }}
                                    >
                                        <Option value={0}>全部</Option>
                                        <Option value={60}>最近60天</Option>
                                        <Option value={30}>最近30天</Option>
                                        <Option value={7}>最近7天</Option>
                                    </Select>
                                </div>
                                {ArTypeSelectVisible && (
                                    <div className="filter-item">
                                        <label>记录类型：</label>

                                        <Select
                                            placeholder="记录类型"
                                            value={actionLogSearchValue.arType}
                                            onSelect={onActionSearchTypeChange}
                                            style={{ width: '150px' }}
                                        >
                                            {/* <Option value="0">全部</Option>
                                    <Option value="1">客户</Option>
                                    <Option value="2">线索</Option> */}
                                            {actionLogConfig.arType.map(
                                                item => {
                                                    return (
                                                        <Option
                                                            value={item.value}
                                                            key={item.value}
                                                        >
                                                            {`${item.label}活动记录`}
                                                        </Option>
                                                    );
                                                },
                                            )}
                                        </Select>
                                    </div>
                                )}
                            </div>
                            <div className="customer-drawer-time-line">
                                <DrawerTimeLineNew
                                    reloadFunc={reload}
                                    showCount={3}
                                    actionLogConfig={actionLogConfig}
                                    groups={actionLogData.map(data => {
                                        return {
                                            day: data.day,
                                            timelineItems: data.dailyData,
                                        };
                                    })}
                                    isNewCustomer={isNewCustomer}
                                />
                            </div>
                        </CollapsePanel2>
                    </Collapse>
                </div>
            </Spin>
        </>
    );
};

export default observer(CustomerActionLog);
