import React, { useState, useEffect } from 'react';
import PerformanceNewOldCustomer from './new-old-customer/index';
import { IOptAnalysisCompositeData } from '../opt-analysis/index';
import PerformanceCustomerIndustry from './customer-industry';
import PerformanceCustomerTrend from './customer-trend';
import PerformanceBusinessProduct from './business-product';
import PerformanceSalesPW from './sales-pw';
import { IAchievementSituationFilterData } from '../achievement-situation';
import PerformanceProductSale from './product-sale';
import PerformanceSalesArea from './sales-area';
export interface IPerformanceAnalysisProps {
    asData?: IOptAnalysisCompositeData;
    asLoading: boolean;
    asFilterData: IAchievementSituationFilterData;
    setAsFilterData: (fData: IAchievementSituationFilterData) => void;
}
const PerformanceAnalysisCompositeContent: React.FC<IPerformanceAnalysisProps> = props => {
    return (
        <>
            <div className="performance-analysis-sales-area">
                <PerformanceSalesArea
                    asLoading={props.asLoading}
                    salesAreaData={props.asData as any}
                    asFilterData={props.asFilterData}
                    setAsFilterData={props.setAsFilterData}
                />
            </div>
            <div className="performance-analysis-composite-content">
                <PerformanceNewOldCustomer
                    asData={props.asData}
                    asLoading={props.asLoading}
                    asFilterData={props.asFilterData}
                    setAsFilterData={props.setAsFilterData}
                />
            </div>
            <div className="performance-analysis-customer-industry">
                <PerformanceCustomerIndustry
                    asLoading={props.asLoading}
                    industryData={props.asData}
                    asFilterData={props.asFilterData}
                    setAsFilterData={props.setAsFilterData}
                />
            </div>
            <div className="performance-analysis-customer-trend">
                <PerformanceCustomerTrend />
            </div>
            <div className="performance-analysis-business-product">
                <PerformanceBusinessProduct
                    asLoading={props.asLoading}
                    asData={props.asData as any}
                    asFilterData={props.asFilterData}
                    setAsFilterData={props.setAsFilterData}
                />
            </div>
            <div className="performance-analysis-product-sale">
                <PerformanceProductSale />
            </div>
            <div className="performance-analysis-sales-pw">
                <PerformanceSalesPW />
            </div>
        </>
    );
};

export default PerformanceAnalysisCompositeContent;
