import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
    DForm,
    IProcessCtx,
    IDFormProps,
    loadingWrapper,
    AntButton,
    applyConfToFormItem,
} from '@/components';
import _ from 'lodash';
import { ProcessCatStore } from '../store';
import { applyCustomConfToWidget } from '@/components/common/form/advanced-filters';
import { CtxContext } from '@/utils/context';
import { Alert, Icon, message } from 'antd';
import { InvoiceQualificationListStore } from '@/pages/om/contract/contract-invoice-qualification/store';
import {
    arrToJson,
    defaultAxios,
    isNetSuccess,
    makeArrayValidator,
    useRefresh,
    decodePhone as phoneDecode,
} from '@/utils';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { seeContractProcess } from '@/pages/om/contract/list/store';
import { commonQuery } from '@/api/rest';
import { getValidatorByParamMeta } from '@/stores/utils';
import { CustomerContactsCreate } from '@/components/common/customer-drawer/customer-contacts-create';

const customerInvoiceTypes = [
    { constraintLabel: '一般纳税人', constraintValue: 1 },
    { constraintLabel: '小规模纳税人', constraintValue: 2 },
    { constraintLabel: '个人', constraintValue: 3 },
];
const customerInvoiceTypesMap = arrToJson(
    customerInvoiceTypes,
    'constraintValue',
);

const iqParamsMapping: { [key: string]: string } = {
    customer_invoice_type: 'invoice_company_type',
    invoice_company: 'invoice_company_name',
    invoice_id_num: 'invoice_company_num',
    company_address: 'invoice_company_address',
    company_phone: 'invoice_company_phone',
    open_account_bank: 'invoice_open_bank',
    bank_account: 'invocie_bank_account',
};
const iqParams = _.keys(iqParamsMapping);

const badInvoiceOnlyParams = new Set([
    'invoice_renew',
    'invoice_reverse_apply_id',
    'invoice_reverse_code',
    'invoice_receive_reverse',
    'invoice_reverse_attachments',
]);

const badInvoiceRequiredParams = new Set([
    'invoice_renew',
    'invoice_reverse_apply_id',
    'invoice_reverse_code',
]);

const invoiceRenewIsNot1OnlyParams = new Set([
    'customer_id',
    'applicant_type',
    'invoice_renew',
    'contract_id',
    'contract_code',
    'invoice_reverse_apply_id',
    'invoice_reverse_code',
    'invoice_able_money',
    'invoice_receive_reverse',
    'invoice_reverse_attachments',
]);

// 开票场景必填字段，开票&作废重开
const dontKnowTheName = new Set([
    'invoice_type',
    'invoice_medium',

    'invoice_customer_id',
    'invoice_company_type',
    'invoice_company_num',

    'invoice_money',
    'invoice_pre',
    'invoice_cost_type',

    'receiver_contacts_id',
    'receiver_phone',
    'receiver_address',

    'delivery_company',
    'delivery_time',
    'delivery_num',
]);

// 运营节点
// 不需要配置了，为什么？
// 因为前端这边面向ui，如果这个节点required的字段，在其他节点恰好都是disabled（互斥），这时候ui层面**不展示为required状态**
// 所以取各个节点required并集即可
// 后续有调整再说
// const dontKnowTheName2 = new Set(['invoice_customer_id']);

const IQDisplay: React.FC<{ invoiceQualificationData: any }> = ({
    invoiceQualificationData,
}) => {
    if (null === invoiceQualificationData) {
        return null;
    }
    const params = [
        {
            key: 'customer_invoice_type',
            name: '客户类型',
        },
        {
            key: 'invoice_company',
            name: '开票公司名',
        },
        {
            key: 'invoice_id_num',
            name: '纳税人识别号',
        },
        {
            key: 'company_address',
            name: '公司地址',
        },
        {
            key: 'company_phone',
            name: '公司电话',
        },
        {
            key: 'open_account_bank',
            name: '开户银行',
        },
        {
            key: 'bank_account',
            name: '银行账号',
        },
    ];
    return (
        <div>
            {params.map(({ key, name }) => {
                let value = invoiceQualificationData[key] || '--';
                if (key === 'customer_invoice_type') {
                    value =
                        (customerInvoiceTypesMap[invoiceQualificationData[key]]
                            ? customerInvoiceTypesMap[
                                  invoiceQualificationData[key]
                              ]?.constraintLabel
                            : null) || '--';
                }
                return (
                    <div style={{ display: 'flex', margin: '5px 0' }} key={key}>
                        <div
                            style={{
                                fontWeight: 'bolder',
                                flexBasis: 110,
                                textAlign: 'right',
                            }}
                        >
                            {name}：
                        </div>
                        <div>{value}</div>
                    </div>
                );
            })}
        </div>
    );
};

export const ContractInvoiceForm: React.FC<{
    defaultStore: ProcessCatStore;
    // iqStore: InvoiceQualificationListStore | null;
    processCtx: IProcessCtx | null;
    tableCode: string | null;
    mode: 'create' | 'update';
}> = observer(({ defaultStore, processCtx, tableCode, mode }) => {
    const ctx = useContext(CtxContext);
    const { position, positionDetail } = ctx;
    const isEdit = mode === 'update';

    const inContractProduct = positionDetail === 'contract-product';

    // 开发票申请专用
    // const {
    //     invoiceQualificationLoading,
    //     invoiceQualificationInited,
    //     hasInvoiceQualification,
    //     invoiceQualificationData,
    //     contractCustomer,
    // } = defaultStore;

    // let iqStoreMutatingDataLoading = false;
    // if (iqStore) {
    //     iqStoreMutatingDataLoading = iqStore.mutatingDataLoading;
    // }

    const {
        defaultMeta,
        mutatingData,
        isbadInvoiceNoList,
        badInvoiceListOptions,
    } = defaultStore;
    const processAllInfo = defaultMeta?.processAllInfo;
    const activityId = processAllInfo?.crmActivity?.activityId;
    const isDropDone = activityId === '' || activityId === '';

    const [createContactsTrigger, setCreateContactsTrigger] = useState(0);

    // 开始创建或者退回重提
    const isFirstNode = activityId === 'open_apply';

    useEffect(() => {
        if (_.isString(mutatingData?.invoice_reverse_code)) {
            // 我们把来自数据库的数据转成前端可用的格式
            try {
                mutatingData._invoice_reverse_code = JSON.parse(
                    mutatingData.invoice_reverse_code,
                );
            } catch (e) {
                mutatingData._invoice_reverse_code = [];
            }
            mutatingData.invoice_reverse_code = defaultStore.dbBadInvoice2Fe(
                mutatingData?.invoice_reverse_code,
            );
        } else if (null === mutatingData?.invoice_reverse_code) {
            mutatingData._invoice_reverse_code = [];
            mutatingData.invoice_reverse_code = [];
        }
    }, [mutatingData?.invoice_reverse_code]);

    useEffect(() => {
        if (
            !mutatingData?.contract_id ||
            !mutatingData?.invoice_reverse_apply_id ||
            !isFirstNode
        ) {
            return;
        }

        // mutatingData?.invoice_reverse_code,

        defaultStore.fetchBadInvoiceList(
            mutatingData?.contract_id,
            mutatingData?.invoice_reverse_apply_id,
        );
    }, [
        mutatingData?.contract_id,
        mutatingData?.invoice_reverse_apply_id,
        isFirstNode,
    ]);

    const [fillingDataLoading, setFillingDataLoading] = useState(false);
    useEffect(() => {
        const contractId = mutatingData ? mutatingData.contract_id : null;
        if (contractId) {
            setFillingDataLoading(true);
            defaultAxios
                .put('/crm/common/process_contract_invoice_table/populate', {
                    contract_id: contractId.originalValue,
                    id: mutatingData?.id,
                    invoice_able_money: mutatingData?.invoice_able_money,
                })
                .then(([d, e]) => {
                    setFillingDataLoading(false);
                    if (isNetSuccess(d, e)) {
                        const toMerge = d?.data || {};
                        delete toMerge.contract_id;
                        delete toMerge.customer_id;
                        _.assign(mutatingData, toMerge);
                    }
                });
        }
    }, [inContractProduct, mutatingData ? mutatingData.contract_id : null]);

    useEffect(() => {
        const invoiceTp = mutatingData ? mutatingData.invoice_type : null;
        // if (invoiceTp === 0) {
        //     // 专用
        //     mutatingData.invoice_medium = 2;
        // }
    }, [mutatingData ? mutatingData.invoice_type : null]);

    // 新建的时候走这个字段关联，首屏自动
    useEffect(() => {
        if (mode !== 'create' || mutatingData === null) {
            return;
        }
        mutatingData.invoice_customer_id = mutatingData.customer_id;
    }, [mode, mutatingData?.customer_id]);

    useEffect(() => {
        if (mode !== 'create' || mutatingData === null) {
            return;
        }
        requestInvocieQ(mutatingData?.invoice_customer_id);
    }, [mode, , mutatingData?.invoice_customer_id]);

    const [contactsRefreshTrigger, setContactsRefreshTrigger] = useState(0);
    const updateContactsRefreshTrigger = useCallback(() => {
        setContactsRefreshTrigger(contactsRefreshTrigger + 1);
    }, [contactsRefreshTrigger]);
    useRefresh(['process-contacts'], updateContactsRefreshTrigger);

    const [iqPopulateLoading, setIQPopulateLoading] = useState(false);
    const clear = useCallback(() => {
        if (!mutatingData) {
            return;
        }
        runInAction(() => {
            for (const param of iqParams) {
                const mutatingKey = iqParamsMapping[param];
                mutatingData[mutatingKey] = null;
            }
        });
    }, [mutatingData]);
    const requestInvocieQ = (invoiceCusId: any) => {
        if (!invoiceCusId) {
            clear();
            return;
        }
        setIQPopulateLoading(true);
        commonQuery(
            'invoice_qualification',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'customer_id',
                        filterValue: invoiceCusId.originalValue,
                        operateType: '=',
                    },
                ],
            },
            undefined,
            undefined,
            undefined,
            'SEARCH-PUBLIC',
        ).then(([d, e]) => {
            setIQPopulateLoading(false);

            if (_.isNil(d) || e !== null) {
                clear();
                return;
            }
            const { datas } = d;
            if (datas && datas[0]) {
                const dt = datas[0];
                runInAction(() => {
                    for (const param of iqParams) {
                        const mutatingKey = iqParamsMapping[param];
                        mutatingData[mutatingKey] = dt[param];
                    }
                });
            } else {
                clear();
            }
        });
    };

    const cid = mutatingData?.customer_id?.originalValue;
    const [
        invoiceCustomerIdListLoading,
        setInvoiceCustomerIdListLoading,
    ] = useState(false);
    const [invoiceCustomerIdList, setInvoiceCustomerIdList] = useState<any[]>(
        [],
    );
    useEffect(() => {
        if (!cid) {
            return;
        }
        setInvoiceCustomerIdListLoading(true);
        defaultAxios
            .get('/crm/contract/query/InvoiceTitle', { customerId: cid })
            .then(([d, e]) => {
                setInvoiceCustomerIdListLoading(false);
                if (isNetSuccess(d, e) && _.isArray(d?.data)) {
                    setInvoiceCustomerIdList(
                        d?.data.map((item: any) => {
                            if (item.id && item.name) {
                                return {
                                    name: item.name,
                                    value: item.id,
                                };
                            }
                        }),
                    );
                }
            });
    }, [cid]);

    useEffect(() => {
        if (!mutatingData) {
            return;
        }
        if (!_.isNumber(mutatingData?.invoice_pre)) {
            mutatingData.invoice_pre = 0; // 否
        }
    }, [mutatingData]);

    if (!defaultMeta) {
        return null;
    }
    const baiscWidgets = defaultMeta.defaultWidgetsAllWithoutGroupCheck;
    const objectMetaMap = defaultMeta.objectMetaMap;

    const collapseLayout = defaultMeta.defaultFormCollapseLayout;
    const shouldGroup = collapseLayout.length > 0;

    const layout = new Array(Math.ceil(baiscWidgets.length / 2)).fill([12, 12]);
    if (shouldGroup) {
        for (const colla of collapseLayout) {
            colla.layout = layout;
        }
    }

    if (processCtx === null || !processCtx.currentUser) {
        return null;
    }

    const props: IDFormProps = {
        processCtx,
        defaultStore: defaultStore as any,
        widgetsGenerator: store => {
            return store.defaultMeta.defaultWidgetsAllWithoutGroupCheck;
        },
        widgetsTransformer: baiscWidgets => {
            applyConfToFormItem(baiscWidgets, 'inputSecret', {
                sceneCode: 'crm_contract',
            });
            return baiscWidgets;
        },
    };

    const { invoice_type, applicant_type, invoice_renew } = mutatingData || {};

    if (shouldGroup) {
        props.options = {
            grouped: true,
        };

        props.propsTransformer = props => {
            if (props.collapseLayout) {
                if (applicant_type === 2 && invoice_renew === 0) {
                    for (const colla of props.collapseLayout) {
                        if (
                            new Set([
                                '34_qualification',
                                '34_receiver',
                                '34_delivery',
                            ]).has(colla.groupKey)
                        ) {
                            colla.hidden = true;
                        }
                    }
                } else {
                    for (const colla of props.collapseLayout) {
                        if (
                            new Set([
                                '34_qualification',
                                '34_receiver',
                                '34_delivery',
                            ]).has(colla.groupKey)
                        ) {
                            colla.hidden = false;
                        }
                    }
                }
            }

            return props;
        };
    } else {
        props.propsTransformer = props => {
            props.layout = layout;

            return props;
        };
    }

    if (tableCode === null) {
        return null;
    }
    const decodePhone = async (phoneNum: string) => {
        const url = '/crm/crypto/phone/decrypt';
        // setDecodeLoading(true);
        const [d, e] = await defaultAxios.post(url, {
            phone: phoneNum,
            scene: 'crm_contacts',
        });
        // setDecodeLoading(false);
        const data = phoneDecode(d?.data);
        const error = e as Error | null;
        if (data) {
            return data;
        } else {
            // message.error('解密手机号失败');
            return phoneNum;
        }
    };
    const originalOnChange = props.onChange;
    props.onChange = (key, value, widgetKey) => {
        if (key === 'customer_id') {
            mutatingData.contract_id = null;
            // 变更时给发票抬头公司赋值
            if (mode !== 'create') {
                // 更改时走这个回填，选择时触发，并且可能触发资质重新请求
                if (
                    mutatingData.invoice_customer_id?.originalValue !==
                    value?.originalValue
                ) {
                    mutatingData.invoice_customer_id = value;
                    requestInvocieQ(value || null);
                }
            }
            defaultStore.setContractCustomer(null);
            defaultStore.invoiceQuilificationBad();
        }

        if (key === 'contract_id' || key === 'invoice_reverse_apply_id') {
            // 列表变了，默认给清空
            mutatingData._invoice_reverse_code = [];
            mutatingData.invoice_reverse_code = [];
        }

        if (key === 'contract_id') {
            mutatingData.invoice_reverse_apply_id = null;
        }

        if (key === 'invoice_reverse_code') {
            if (_.isArray(value)) {
                mutatingData._invoice_reverse_code = defaultStore.feBadInvoice2db(
                    value,
                );
            }
        }
        if (key === 'invoice_customer_id') {
            const invoiceCusId = value || null;
            if (mode !== 'create') {
                requestInvocieQ(invoiceCusId);
            }
        }
        if (key === 'receiver_contacts_id') {
            const contactsId = value?.originalValue;
            if (contactsId) {
                mutatingData.receiver_phone = null;
                defaultAxios
                    .get(
                        '/bff/api/rest/contacts/fillContractContactInfo?id=' +
                            contactsId,
                    )
                    .then(async ([d, e]) => {
                        if (isNetSuccess(d, e)) {
                            const data = d?.data;
                            if (mutatingData) {
                                const pData = await decodePhone(
                                    data.contacts_phone,
                                );
                                mutatingData.receiver_phone = pData;
                            }
                        }
                    });
            }
        }

        if (originalOnChange) {
            return originalOnChange(key, value, widgetKey);
        }
    };

    // 某些字段只有在作废重开的时候才是必填
    props.labelsTransformer = originalLabels => {
        const nextLabels = _.cloneDeep(originalLabels);

        nextLabels.forEach(label => {
            const makeRequired = () => (label.required = true);

            if (applicant_type === 2 && invoice_renew === 1) {
                // 双场景
                if (
                    badInvoiceRequiredParams.has(label.key as string) ||
                    dontKnowTheName.has(label.key as string)
                ) {
                    makeRequired();
                }
            } else if (applicant_type === 2 && invoice_renew === 0) {
                // 仅作废场景
                if (badInvoiceRequiredParams.has(label.key as string)) {
                    makeRequired();
                }
            } else if (applicant_type === 1) {
                // 仅开票场景
                if (dontKnowTheName.has(label.key as string)) {
                    makeRequired();
                }
            } else {
                // 默认
                if (dontKnowTheName.has(label.key as string)) {
                    makeRequired();
                }
            }
        });

        // if (applicant_type === 2) {
        //     nextLabels.forEach(label => {
        //         if (badInvoiceRequiredParams.has(label.key as string)) {
        //             label.required = true;
        //         }
        //     });
        // } else {
        //     nextLabels.forEach(label => {
        //         if (badInvoiceRequiredParams.has(label.key as string)) {
        //             label.required = false;
        //         }
        //     });
        // }
        return nextLabels;
    };

    props.validatorsTransformer = originalValidators => {
        const nextValidators = _.cloneDeep(originalValidators);
        const keys = _.keys(nextValidators);

        keys.forEach(key => {
            const getValidator = (k: any) => {
                if (k === 'invoice_reverse_code') {
                    return makeArrayValidator(k, true);
                }
                const paramsMeta = _.cloneDeep(objectMetaMap[k]);
                paramsMeta.isRequired = true;
                return getValidatorByParamMeta(paramsMeta);
            };

            if (applicant_type === 2 && invoice_renew === 1) {
                // 双场景
                if (
                    badInvoiceRequiredParams.has(key) ||
                    dontKnowTheName.has(key)
                ) {
                    nextValidators[key] = getValidator(key);
                }
            } else if (applicant_type === 2 && invoice_renew === 0) {
                // 仅作废场景
                if (badInvoiceRequiredParams.has(key)) {
                    nextValidators[key] = getValidator(key);
                }
            } else if (applicant_type === 1) {
                // 仅开票场景
                if (dontKnowTheName.has(key)) {
                    nextValidators[key] = getValidator(key);
                }
            } else {
                // 默认
                if (dontKnowTheName.has(key)) {
                    nextValidators[key] = getValidator(key);
                }
            }
        });

        // if (applicant_type === 2) {
        //     keys.forEach(key => {
        //         if (badInvoiceRequiredParams.has(key)) {
        //             if (key === 'invoice_reverse_code') {
        //                 nextValidators[key] = makeArrayValidator(key, true);
        //                 return;
        //             }
        //             const paramsMeta = _.cloneDeep(objectMetaMap[key]);
        //             paramsMeta.isRequired = true;
        //             nextValidators[key] = getValidatorByParamMeta(paramsMeta);
        //         }
        //     });
        // } else {
        //     keys.forEach(key => {
        //         if (badInvoiceRequiredParams.has(key)) {
        //             if (key === 'invoice_reverse_code') {
        //                 nextValidators[key] = makeArrayValidator(key, false);
        //                 return;
        //             }
        //             const paramsMeta = _.cloneDeep(objectMetaMap[key]);
        //             paramsMeta.isRequired = false;
        //             nextValidators[key] = getValidatorByParamMeta(paramsMeta);
        //         }
        //     });
        // }
        return nextValidators;
    };

    // console.log(
    //     applicant_type,
    //     invoice_renew,
    //     typeof applicant_type,
    //     typeof invoice_renew,
    //     _.isNil(applicant_type),
    //     _.isNil(invoice_renew),
    //     'njiuhgbnjhgbnj',
    // );
    props.widgetsTransformer = baiscWidgets => {
        for (const widget of baiscWidgets) {
            // 先都展示，再考虑隐藏哪些。。
            widget.status = {
                ...widget.status,
                hidden: false,
            };
            if (applicant_type === 2 && invoice_renew === 0) {
                // 作废，不重开
                // 不在白名单中隐藏。。。
                if (!invoiceRenewIsNot1OnlyParams.has(widget.key as string)) {
                    widget.status = {
                        ...widget.status,
                        hidden: true,
                    };
                }
            } else if (applicant_type === 1) {
                // 开票类型
                // 在黑名单中则隐藏
                if (badInvoiceOnlyParams.has(widget.key as string)) {
                    widget.status = {
                        ...widget.status,
                        hidden: true,
                    };
                }
            } else if (applicant_type === '' && invoice_renew === '') {
                // 都没选，隐藏作废相关选项
                if (badInvoiceOnlyParams.has(widget.key as string)) {
                    widget.status = {
                        ...widget.status,
                        hidden: true,
                    };
                }
            }
        }

        // // 有些字段只有作废重开才展示
        // if (applicant_type === 2) {
        //     //
        //     for (const widget of baiscWidgets) {
        //         if (badInvoiceOnlyParams.has(widget.key as string)) {
        //             widget.status = {
        //                 ...widget.status,
        //                 hidden: false,
        //             };
        //         }

        //         if (invoice_renew === 0) {
        //             if (
        //                 !invoiceRenewIsNot1OnlyParams.has(widget.key as string)
        //             ) {
        //                 widget.status = {
        //                     ...widget.status,
        //                     hidden: true,
        //                 };
        //             }
        //         } else {
        //             if (
        //                 !invoiceRenewIsNot1OnlyParams.has(widget.key as string)
        //             ) {
        //                 widget.status = {
        //                     ...widget.status,
        //                     hidden: false,
        //                 };
        //             }
        //         }
        //     }
        // } else {
        //     for (const widget of baiscWidgets) {
        //         if (
        //             !invoiceRenewIsNot1OnlyParams.has(widget.key as string)
        //         ) {
        //             widget.status = {
        //                 ...widget.status,
        //                 hidden: false,
        //             };
        //         }

        //         if (badInvoiceOnlyParams.has(widget.key as string)) {
        //             widget.status = {
        //                 ...widget.status,
        //                 hidden: true,
        //             };
        //         }

        //     }
        // }
        // if (invoice_type === 0) {
        //     applyCustomConfToWidget(
        //         baiscWidgets,
        //         'invoice_medium',
        //         originalWidget => {
        //             const nextWidget = _.cloneDeep(originalWidget);
        //             nextWidget.status = {
        //                 ...nextWidget.status,
        //                 disabled: true,
        //             };
        //             return nextWidget;
        //         },
        //     );
        // }

        if (inContractProduct) {
            applyCustomConfToWidget(
                baiscWidgets,
                'customer_id',
                prevnextWidget => {
                    const nextWidget = _.cloneDeep(prevnextWidget);
                    nextWidget.status = nextWidget.status || {};
                    nextWidget.status.disabled = true;
                    return nextWidget;
                },
            );
        }

        applyCustomConfToWidget(
            baiscWidgets,
            'invoice_customer_id',
            prevWidget => {
                const nextWidget = _.cloneDeep(prevWidget);
                nextWidget.status = {
                    ...nextWidget.status,
                    loading: iqPopulateLoading || invoiceCustomerIdListLoading,
                };
                nextWidget.nextlineWidget = () => {
                    const cusName =
                        mutatingData?.customer_id?.relatedObj?.customer_name;
                    const warningLint = () => (
                        <Alert
                            type="warning"
                            style={{ padding: 8 }}
                            message={
                                '代付款时，必须先走代付款申请流程，审批通过后才能选到代付款公司作为发票抬头公司'
                            }
                        ></Alert>
                    );
                    if (!cusName) {
                        return warningLint();
                    }
                    return (
                        <>
                            <Alert
                                style={{ padding: 8, marginBottom: 8 }}
                                message={'签约公司名称：' + cusName}
                            ></Alert>
                            {warningLint()}
                        </>
                    );
                };
                nextWidget.widgetKey = 'select';
                nextWidget.selectOptions = {
                    options: invoiceCustomerIdList,
                    showSearch: true,
                    placeholder: '发票抬头公司',
                    hijackOnChange: (v: any, options) => {
                        const selectedOption = (options.options || []).find(
                            item => item.value === v,
                        );
                        if (!selectedOption) {
                            return null;
                        }
                        return {
                            originalValue: selectedOption?.value,
                            relatedObj: {
                                id: selectedOption?.value,
                                customer_name: selectedOption?.name,
                            },
                            type: 'obj',
                        };
                    },
                    hijackValue: (v: any) => {
                        return v?.originalValue || null;
                    },
                };

                return nextWidget;
            },
        );

        applyCustomConfToWidget(
            baiscWidgets,
            'invoice_reverse_code',
            prevWidget => {
                const nextWidget = _.cloneDeep(prevWidget);
                nextWidget.widgetKey = 'select';
                nextWidget.selectOptions = {
                    options: badInvoiceListOptions, //
                    mode: 'multiple',
                    placeholder: '请选择要作废发票右上角的发票号',
                };

                const invoiceReverseApplyId =
                    mutatingData?.invoice_reverse_apply_id?.originalValue;
                // invoice_reverse_apply_id
                nextWidget.status = {
                    ...nextWidget.status,
                    loading: defaultStore.badInvoiceListLoading,
                    disabled: isFirstNode
                        ? !invoiceReverseApplyId
                            ? true
                            : false
                        : true,
                };
                nextWidget.nextlineWidget = () => {
                    if (invoice_renew !== 1) {
                        return null;
                    }
                    return isbadInvoiceNoList ? (
                        <div style={{ padding: 8 }}>
                            <Alert
                                type="warning"
                                message="该字段为必填项，若选不到作废发票时，请联系赵一航处理"
                            />
                        </div>
                    ) : (
                        <div style={{ padding: 8 }}>
                            <Alert
                                type="warning"
                                message="作废重开时，需重新填写开票信息"
                            />
                        </div>
                    );
                };
                return nextWidget;
            },
        );

        applyCustomConfToWidget(baiscWidgets, 'contract_code', nextWidget => {
            nextWidget.nextlineWidget = () => {
                return (
                    <div style={{ paddingTop: 4 }}>
                        <a
                            onClick={() => {
                                const contractId =
                                    mutatingData?.contract_id?.originalValue;
                                if (contractId) {
                                    seeContractProcess(contractId);
                                } else {
                                    message.error('合同id不存在');
                                }
                            }}
                        >
                            查看合同
                            <Icon type="link" />
                        </a>
                    </div>
                );
            };

            return nextWidget;
        });

        applyCustomConfToWidget(
            baiscWidgets,
            'receiver_contacts_id',
            nextWidget => {
                const cusId = mutatingData?.customer_id?.originalValue;
                const cusName =
                    mutatingData?.customer_id?.relatedObj?.customer_name;
                if (nextWidget.objectOptions) {
                    nextWidget.objectOptions.defaultFilters = cusId
                        ? [
                              {
                                  fieldName: 'customer_id',
                                  operateType: 'EQUAL',
                                  fieldValue: cusId,
                              },
                          ]
                        : [];
                    nextWidget.objectOptions.listClearTrigger =
                        contactsRefreshTrigger + ':' + cusId;
                }

                nextWidget.nextlineWidget = () => {
                    if (cusId && cusName) {
                        return (
                            <div
                                onClick={() =>
                                    setCreateContactsTrigger(
                                        1 + createContactsTrigger,
                                    )
                                }
                            >
                                <a>
                                    新建联系人
                                    <Icon type="user" />
                                </a>
                            </div>
                        );
                    }
                    return null;
                };

                return nextWidget;
            },
        );

        // 发票申请ID，需要contract_id作为查询条件
        // 否则不可选
        applyCustomConfToWidget(
            baiscWidgets,
            'invoice_reverse_apply_id',
            nextWidget => {
                const contractId = mutatingData?.contract_id?.originalValue;
                if (nextWidget.objectOptions) {
                    nextWidget.objectOptions.defaultFilters = contractId
                        ? [
                              {
                                  fieldName: 'contract_id',
                                  operateType: 'EQUAL',
                                  fieldValue: contractId,
                              },
                          ]
                        : [];
                    nextWidget.objectOptions.listClearTrigger =
                        createContactsTrigger + ':' + contractId;

                    nextWidget.objectOptions.placeholder =
                        '请选择作废发票的原申请编号';
                }
                if (!contractId) {
                    nextWidget.status = {
                        ...nextWidget.status,
                        disabled: true,
                    };
                }
                return nextWidget;
            },
        );

        applyCustomConfToWidget(baiscWidgets, 'contract_id', nextWidget => {
            // nextWidget.status = {
            //     ...nextWidget.status,
            //     loading: () => {
            //         return invoiceQualificationLoading || fillingDataLoading;
            //     },
            // };

            if (!mutatingData?.customer_id?.originalValue) {
                nextWidget.status = {
                    ...nextWidget.status,
                    disabled: true,
                };
            } else {
                nextWidget.status = {
                    ...nextWidget.status,
                    disabled: false,
                };
            }

            if (nextWidget.objectOptions) {
                const cusId = mutatingData?.customer_id?.originalValue;
                nextWidget.objectOptions.defaultFilters = cusId
                    ? [
                          {
                              fieldName: 'customer_id',
                              operateType: 'EQUAL',
                              fieldValue: cusId,
                          },
                      ]
                    : [];
                nextWidget.objectOptions.reloadTrigger = cusId;
            }
            // 2020.06.23, 不再通过表单维护客户发票资质
            // if (
            //     !invoiceQualificationInited ||
            //     !contractCustomer ||
            //     !contractCustomer.relatedObj
            // ) {
            //     delete nextWidget.nextlineWidget;
            // } else {
            //     nextWidget.nextlineWidget = () => (
            //         <div style={{ padding: 5 }}>
            //             {loadingWrapper(invoiceQualificationLoading)(
            //                 <Alert
            //                     key={'hash-' + defaultStore.newDataHash}
            //                     type={
            //                         hasInvoiceQualification ? 'info' : 'warning'
            //                     }
            //                     message={
            //                         <div>
            //                             {hasInvoiceQualification ? (
            //                                 <p>
            //                                     当前客户【
            //                                     {
            //                                         contractCustomer.relatedObj
            //                                             .customer_name
            //                                     }
            //                                     】开票资质：
            //                                     <IQDisplay
            //                                         invoiceQualificationData={
            //                                             invoiceQualificationData
            //                                         }
            //                                     />
            //                                 </p>
            //                             ) : (
            //                                 <p>
            //                                     当前客户【
            //                                     {
            //                                         contractCustomer.relatedObj
            //                                             .customer_name
            //                                     }
            //                                     】暂无开票资质
            //                                 </p>
            //                             )}
            //                             <div>
            //                                 {hasInvoiceQualification ? (
            //                                     <AntButton
            //                                         onClick={() => {
            //                                             (async () => {
            //                                                 await iqStore.setAction(
            //                                                     'update',
            //                                                     {
            //                                                         id:
            //                                                             invoiceQualificationData.id,
            //                                                     },
            //                                                 );
            //                                                 iqStore.setCustomerId(
            //                                                     contractCustomer,
            //                                                 );
            //                                             })();
            //                                         }}
            //                                         loading={
            //                                             iqStoreMutatingDataLoading
            //                                         }
            //                                         type="primary"
            //                                     >
            //                                         修改用户开票资质
            //                                     </AntButton>
            //                                 ) : (
            //                                     <AntButton
            //                                         onClick={() => {
            //                                             runInAction(() => {
            //                                                 iqStore.startNewData();
            //                                                 iqStore.setCustomerId(
            //                                                     contractCustomer,
            //                                                 );
            //                                                 iqStore.setAction(
            //                                                     'create',
            //                                                 );
            //                                             });
            //                                         }}
            //                                         type="primary"
            //                                     >
            //                                         新建用户开票资质
            //                                     </AntButton>
            //                                 )}
            //                             </div>
            //                         </div>
            //                     }
            //                 />,
            //             )}
            //         </div>
            //     );
            // }

            return nextWidget;
        });

        // 可开票金额不能修改 invoice_able_money
        applyCustomConfToWidget(baiscWidgets, 'invoice_able_money', widget => {
            const nextWidget = _.cloneDeep(widget);
            nextWidget.status = {
                ...nextWidget.status,
                disabled: true,
            };
            return nextWidget;
        });

        applyCustomConfToWidget(baiscWidgets, 'invoice_remark', widget => {
            widget.nextlineWidget = () => {
                return (
                    <div style={{ padding: 8 }}>
                        <Alert
                            type="error"
                            message={
                                <span>
                                    请填写1. 作废发票的原因；2.
                                    需要打印发票上的PO单号;3. 电子发票的邮箱。
                                    <span style={{ color: 'red' }}>
                                        除此三项以外其他信息写在快递备注
                                    </span>
                                </span>
                            }
                        />
                    </div>
                );
            };
            return widget;
        });

        return baiscWidgets;
    };

    const cusId = mutatingData?.customer_id?.originalValue;
    const cusName = mutatingData?.customer_id?.relatedObj?.customer_name;

    const form = (
        <>
            <CustomerContactsCreate
                customerInfo={{
                    id: cusId,
                    customer_name: cusName,
                }}
                trigger={createContactsTrigger}
            />
            <DForm {...props} />
        </>
    );
    return form;
});
