import React from 'react';

import './index.less';

interface BoardCardProps {
    title: string | React.ReactNode;
    filters?: React.ReactNode;
    subtitle?: string | React.ReactNode;
    style?: React.CSSProperties;
    extra?: React.ReactNode;
}

const BoardCard: React.FC<BoardCardProps> = props => {
    const { title, subtitle, extra, style, filters, children } = props;
    return (
        <div className="board-card" style={style}>
            <div className="board-card-header">
                <div>
                    {typeof title === 'string' ? (
                        <span className="board-card-header-title">{title}</span>
                    ) : (
                        title
                    )}
                    {subtitle && (
                        <span className="board-card-header-subtitle">
                            {subtitle}
                        </span>
                    )}
                </div>

                {extra && (
                    <div className="board-card-header-extra">{extra}</div>
                )}
            </div>
            {filters}
            {children}
        </div>
    );
};

export default BoardCard;
