import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    FastFilterStore,
    makeDefaultInitailingHook,
} from '@/stores/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import { mutate } from '@/api/graphql';
import _ from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    getLeads,
    TGetLeadsReturnType,
    xDelete,
    commonQuery,
    TGetCommonReturnType,
    getCommonQueryBody,
} from '@/api/rest';
import { defaultWritingDataParser, defaultAxios, isNetSuccess } from '@/utils';
import EE from 'eventemitter3';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';
import {
    makeDefaultInitailingHookB,
    createDefaultInstanceGetterA,
} from '@/stores/utilsA';

export class ContractProductListStore {
    // 数据
    public data: any[] = [];

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public get pageTitle() {
        if (this.listType === 'all') {
            return '全部合同产品';
        } else if (this.listType === 'mine') {
            return '我的合同产品';
        }
        return '未知listType';
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFastFilters = this.defaultFastFilters;
        const {
            selectedFastFilters,
            selectedLv4MenuFilters,
        } = defaultFastFilters;

        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );
        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        for (const filterSet of selectedFastFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        for (const filterSet of selectedLv4MenuFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        ret.filters?.push(...defaultFilters);
        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }
    public async setAction(nextAction: string, extra?: any) {
        if ('start-process' === nextAction && !!extra?.id) {
            this.fillInContractProductProcessObj(extra.id);
        }
        if ('start-process-guzhu' === nextAction && !!extra?.id) {
            this.fillInContractProductGuzhuProcessObj(extra.id);
        }
        this.actionType = nextAction;
    }
    public resetAction() {
        this.netValidateResult = {};
        this.actionType = '';
    }
    public async dispatchAction() {}

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;
    get mutatingDataParsed() {
        return defaultWritingDataParser(this.mutatingData);
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        const initialFormData = this.defaultMeta.defaultWidgetValues;
        this.mutatingData = _.cloneDeep(initialFormData);
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;

    get fieldAuthCode() {
        return this.listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    }

    public async fetchInstantlyMutatingDataById(id: any) {
        const { fieldAuthCode } = this;

        this.mutatingDataLoading = true;
        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: id,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            undefined,
            undefined,
        );
        if (d === null || e !== null) {
            this.mutatingDataLoading = false;
            return false;
        }
        const { datas } = d;
        if (datas && datas[0]) {
            runInAction(() => {
                this.mutatingDataLoading = false;
                this.mutatingData = datas[0];
            });
            return true;
        } else {
            message.error('没有该数据的权限');
            this.mutatingDataLoading = false;
            return false;
        }
    }
    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode } = this;

        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
        );

        if (_.isNil(d) || !_.isNil(e)) {
            return [d, e];
        }
        const leadsData = d;
        const { amount, datas } = leadsData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    get downloadUrl() {
        const { fieldAuthCode } = this;
        const { tableId: objectName } = this.defaultMeta;
        const { amount } = this.defaultPagination;
        if (!amount) {
            return '';
        }
        const pageSize = Math.max(Math.min(Math.ceil(amount / 10), 300), 100);
        const fetchBody = getCommonQueryBody(
            { ...this.defaultPagination.paginationArgs, pageSize },
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            this.defaultMeta.tableColsParamsFromUserSetting.map(
                item => item.key,
            ),
            undefined,
            false,
        );
        fetchBody.objectName = objectName;
        return (
            `${
                window.location.origin
            }/bff/api/rest/export?q=${encodeURIComponent(
                JSON.stringify(fetchBody),
            )}&hash=` + Math.random()
        );
    }

    public async execute(body: any) {
        const { mutatingData } = this;
        if (!mutatingData || !mutatingData.id) {
            message.error('开通失败，无产品合同id');
            return false;
        }
        body.contractProductId = mutatingData.id;
        body.executeType = Number(body.executeType);
        body.serviceMonth = Number(body.serviceMonth);
        const [d, e] = await defaultAxios.post(
            '/crm/contract/applyExecuteProduct',
            body,
        );
        if (d && !e) {
            message.success('申请开通成功');
            return true;
        }
        message.error('申请开通失败');
        return false;
    }

    // /bff/api/rest/process/fillContractProductOpen?contractProductId=17
    public fillInContractProductProcessObjLoading = false;
    public fillInContractProductProcessObjData: any = null;
    public setFillInContractProductProcessObjData(next: any) {
        this.fillInContractProductProcessObjData = next;
    }
    public async fillInContractProductProcessObj(contractProductId: number) {
        this.fillInContractProductProcessObjLoading = true;
        const [
            d,
            e,
        ] = await defaultAxios.get(
            '/bff/api/rest/process/fillContractProductOpen',
            { contractProductId },
        );
        this.fillInContractProductProcessObjLoading = false;
        if (!isNetSuccess(d, e)) {
            this.fillInContractProductProcessObjData = {};
            return false;
        }
        const datum = d?.data?.datas?.[0];
        try {
            const equity_detail = JSON.parse(
                datum.contract_product_id.relatedObj.equity_detail,
            );
            for (let i = 0; i < equity_detail.length; i++) {
                datum[equity_detail[i].equityKey] = equity_detail[i].equityNum;
            }
        } catch (err) {
            message.error('数据序列化失败');
        }
        this.fillInContractProductProcessObjData = datum || {};
        return true;
    }

    // guzhu类型的回填
    public fillInContractProductGuzhuProcessObjLoading = false;
    public fillInContractProductGuzhuProcessObjData: any = null;
    public setFillInContractProductGuzhuProcessObjData(next: any) {
        this.fillInContractProductGuzhuProcessObjData = next;
    }
    public async fillInContractProductGuzhuProcessObj(
        contractProductId: number,
    ) {
        this.fillInContractProductGuzhuProcessObjLoading = true;
        const [
            d,
            e,
        ] = await defaultAxios.put(
            '/bff/api/rest/populate?tableCode=process_contract_employer_open_table',
            { contract_product_id: contractProductId },
        );
        this.fillInContractProductGuzhuProcessObjLoading = false;
        if (!isNetSuccess(d, e)) {
            this.fillInContractProductGuzhuProcessObjData = {};
            return false;
        }
        const datum = d?.data;
        this.fillInContractProductGuzhuProcessObjData = datum || {};
        return true;
    }

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMeta = new ListMeta(
        '/bff/api/rest/meta/contract_product/list',
        '/bff/api/rest/meta/contract_product/list/filters/save',
        '/bff/api/rest/meta/contract_product/list/table-cols/save',
    );
    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();
    public defaultPerm: Perm = new Perm('/bff/api/rest/perm?mod=om:contract');
    public defaultMSelect: MSelectStore = new MSelectStore();
    public defaultFastFilters = new FastFilterStore();
    constructor() {
        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultMeta: false,
            defaultFilter: false,
            defaultEE: false,
            defaultPerm: false,
            defaultMSelect: false,
            defaultFastFilters: false,
        });

        const isOm = window.location.pathname.indexOf('/om') > -1;
        this.defaultPerm.setFinalApi(
            isOm
                ? '/bff/api/rest/perm?mod=om:contract'
                : '/bff/api/rest/perm?mod=sales-follow-up:contract',
        );
    }
}

export const getDefaultContractProductListStore = createDefaultInstanceGetterA(
    ContractProductListStore,
);

export const useAnyContractProductListStore = makeDefaultInitailingHookB(
    ContractProductListStore,
    'contract_product',
);

export const genContractProductParamByMis = (cp: any) => {
    // HACK: 先写死
    cp = cp || {};
    if (!cp.id || !cp.product_name) {
        return null;
    }
    return {
        type: 'obj',
        showFiledName: 'product_name',
        originalValue: cp.id,
        relatedObj: {
            relation_object_map: {},
            id: cp.id,
            product_name: cp.product_name,
        },
        showRelatedObj: {
            relation_object_map: {},
            id: cp.id,
            product_name: cp.product_name,
        },
    };
};
