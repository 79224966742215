import { createContext } from 'react';
import { Counter, getDefaultCounter } from './counter';
import { getAuthStore, AuthStore } from './auth/index';
import {
    CompatibilitySniffer,
    getDefaultCompatibilitySniffer,
} from './compatibility-sniffer';

import { CrudDemo, getDefaultCrudDemo } from '@/pages/demo2/crud/store';
import {
    DemoListStore,
    getDefaultDemoListStore,
} from '@/pages/demo2/crud-template/store';
import {
    CustomerListStore,
    getDefaultCustomerListStore,
} from '@/pages/sales-follow-up/custom/list/store';
import {
    LeadListStore,
    getDefaultLeadListStore,
} from '@/pages/sales-follow-up/lead/list/store';
import {
    OptListStore,
    getDefaultOptListStore,
} from '@/pages/sales-follow-up/opt/list/store';
import {
    OptProductListStore,
    getDefaultOptProductListStore,
} from '@/pages/sales-follow-up/opt/opt-product/store';

import {
    ContractProductListStore,
    getDefaultContractProductListStore,
} from '@/pages/om/contract/contract-product/store';
import {
    getDefaultCCAdminListStore,
    CCAdminListStore,
} from '@/pages/sales-follow-up/call-center-admin/list/store';
import {
    ContractProductExecuteListStore,
    getDefaultContractProductExecuteListStore,
} from '@/pages/om/contract/contract-product-execute/store';
import {
    ContractListStore,
    getDefaultContractListStore,
} from '@/pages/om/contract/list/store';

import {
    ContractIncomeListStore,
    getDefaultContractIncomeListStore,
} from '@/pages/om/contract/contract-income/store';
import {
    ContractInvoiceListStore,
    getDefaultContractInvoiceListStore,
} from '@/pages/om/contract/contract-invoice/store';
import {
    ContractInvoiceApplyListStore,
    getDefaultContractInvoiceApplyListStore,
} from '@/pages/om/contract/contract-invoice-apply/store';
import {
    InvoiceQualificationListStore,
    getDefaultInvoiceQualificationListStore,
} from '@/pages/om/contract/contract-invoice-qualification/store';
import {
    ContactListStore,
    getDefaultContactListStore,
} from '@/pages/sales-follow-up/contact/list/store';
import {
    ActiveRecordStore,
    getDefaultActiveRecordStore,
} from '@/pages/sales-follow-up/activation-record/list/store';
import {
    ProcessCatStore,
    getDefaultProcessCatStore,
} from '@/pages/sales-follow-up/process/list/store';

import {
    AchievementManaStore,
    getDefaultAchievementManaStore,
} from '@/pages/om/achievement/mana/store';

import {
    EquityListStore,
    getDefaultEquityListStore,
} from '@/pages/om/equity/list/store';

import {
    ConfAdvertisingRateListStore,
    getDefaultConfAdvertisingRateListStore,
} from '@/pages/om/equity/talent-bank-account/store';
import {
    ConfReservePriceListStore,
    getDefaultConfReservePriceListStore,
} from '@/pages/om/equity/increase-price/store';
import {
    ConfRoleDiscountListStore,
    getDefaultConfRoleDiscountListStore,
} from '@/pages/om/equity/contract-sign/store';
import {
    ProductListStore,
    getDefaultProductListStore,
} from '@/pages/om/product/list/store';
import {
    CrudDemo as CrudDemo2,
    getDefaultCrudDemo as getDefaultCrudDemo2,
} from '@/pages/demo2/standard-crud/store';
import { getSettingDefaultStore, SettingStore } from './setting/index';
import {
    SettingCHSPGStore,
    getDefaultSettingCHSPGStore,
} from '@/pages/setting/customer-hsp-grouping/store';
import {
    CHSPSLStore,
    getDefaultCHSPSLStore,
} from '@/pages/setting/customer-hsp-limit/store';
import {
    OPMStore,
    getDefaultOPMStore,
} from '@/pages/setting/organization-personnel-management/store';
import {
    DRMStore,
    getDefaultDRMStore,
} from '../pages/setting/data-role-management/store';
import {
    FRMStore,
    getDefaultFRMStore,
} from '../pages/setting/function-role-management/store';
import {
    SSMStore,
    getDefaultSSMStore,
} from '../pages/setting/business-opportunities/sales-stage-management/store';
import {
    UserLogStore,
    getDefaultUserLogStore,
} from '../pages/setting/log-page/user-log-page/store';
import {
    LoginLogStore,
    getDefaultLoginLogStore,
} from '../pages/setting/log-page/login-log-page/store';
import { NoticeStore, getDefaultNoticeStore } from './notice';
import {
    GlobalSearchStore,
    getDefaultGSStore,
} from '../pages/global-search/store';
import {
    getDefaultDataManageImportStore,
    DataManageImportStore,
} from '@/pages/om/data-manage/import/store';
import {
    ChannelGatherStore,
    getDefaultChannelGatherStore,
} from '@/pages/analysis/ana-lead/channel-gather/store';
import {
    SalesDetailStore,
    getDefaultSalesDetailStore,
} from '@/pages/analysis/ana-lead/sales-detail/store';
import { getDefaultLeadTransStore } from '@/pages/analysis/ana-lead/lead-transfer/store';

import {
    NewOptStore,
    getDefaultNewOptStore,
} from '@/pages/analysis/ana-opt/new-opt/store';

import {
    NewSignStore,
    getDefaultNewSignStore,
} from '@/pages/analysis/ana-lead/new-sign/store';

import { AnalysisStore, getDefaultAnalysisStore } from '@/pages/analysis/store';
import {
    CUserStore,
    getDefaultCUserStore,
} from '@/components/common/mai-trend/store';
import {
    MemberDataStore,
    getDefaultMemberDataStore,
} from '@/pages/om/member/member-data/store';
import {
    MemberListStore,
    getDefaultMemberListStore,
} from '@/pages/om/member/member-list/store';
import {
    MemberListStagingStore,
    getDefaultMemberListStagingStore,
} from '@/pages/om/member/member-list-staging/store';
import {
    MemberTuoKeTongStore,
    getDefaultMemberTuoKeTongStore,
} from '@/pages/om/member/member-tuoketong/store';
import { MemberStore, getDefaultMemberStore } from '@/pages/om/member/store';
import {
    CompositeBusinessStore,
    getDefaultCompositeBusinessStore,
} from '@/pages/analysis/dashboard/composite-business/store';
import {
    ProductRightsStore,
    getDefaultProductRightsStore,
} from '@/pages/analysis/ana-product/product-rights/store';
import {
    CompositeLiveStore,
    getDefaultCompositeLiveStore,
} from '@/pages/analysis/dashboard/composite-live/store';
import {
    HomeNewStore,
    getDefaultHomeNewStore,
} from '../pages/home/home-new/store';
// may got some options
export const createStore = (options: any) => {
    return {
        // 权限相关store
        Counter,
        getDefaultCounter,
        CompatibilitySniffer,
        getDefaultCompatibilitySniffer,
        CrudDemo,
        getDefaultCrudDemo,
        CrudDemo2,
        getDefaultCrudDemo2,
        DemoListStore,
        getDefaultDemoListStore,
        AuthStore,
        getAuthStore,
        CustomerListStore,
        getDefaultCustomerListStore,
        LeadListStore,
        getDefaultLeadListStore,
        OptListStore,
        getDefaultOptListStore,
        OptProductListStore,
        getDefaultOptProductListStore,
        ContractListStore,
        getDefaultContractListStore,
        ContractIncomeListStore,
        getDefaultContractIncomeListStore,
        ContractInvoiceListStore,
        getDefaultContractInvoiceListStore,
        ContractInvoiceApplyListStore,
        getDefaultContractInvoiceApplyListStore,
        InvoiceQualificationListStore,
        getDefaultInvoiceQualificationListStore,
        ContractProductListStore,
        getDefaultContractProductListStore,
        getDefaultCCAdminListStore,
        CCAdminListStore,
        ContractProductExecuteListStore,
        getDefaultContractProductExecuteListStore,
        ContactListStore,
        getDefaultContactListStore,
        ActiveRecordStore,
        getDefaultActiveRecordStore,
        ProcessCatStore,
        getDefaultProcessCatStore,
        EquityListStore,
        getDefaultEquityListStore,
        ConfAdvertisingRateListStore,
        getDefaultConfAdvertisingRateListStore,
        ConfReservePriceListStore,
        getDefaultConfReservePriceListStore,
        ConfRoleDiscountListStore,
        getDefaultConfRoleDiscountListStore,
        ProductListStore,
        getDefaultProductListStore,
        SettingCHSPGStore,
        getDefaultSettingCHSPGStore,
        SettingStore,
        getSettingDefaultStore,
        CHSPSLStore,
        getDefaultCHSPSLStore,
        OPMStore,
        getDefaultOPMStore,
        DRMStore,
        getDefaultDRMStore,
        FRMStore,
        getDefaultFRMStore,
        SSMStore,
        getDefaultSSMStore,
        UserLogStore,
        getDefaultUserLogStore,
        LoginLogStore,
        getDefaultLoginLogStore,
        NoticeStore,
        getDefaultNoticeStore,
        GlobalSearchStore,
        getDefaultGSStore,
        getDefaultDataManageImportStore,
        DataManageImportStore,
        ChannelGatherStore,
        getDefaultChannelGatherStore,
        SalesDetailStore,
        getDefaultSalesDetailStore,
        getDefaultAnalysisStore,
        getDefaultLeadTransStore,
        NewOptStore,
        getDefaultNewOptStore,
        NewSignStore,
        getDefaultNewSignStore,
        AnalysisStore,
        CUserStore,
        getDefaultCUserStore,
        MemberDataStore,
        getDefaultMemberDataStore,
        MemberListStore,
        getDefaultMemberListStore,
        MemberStore,
        getDefaultMemberStore,
        MemberListStagingStore,
        getDefaultMemberListStagingStore,
        MemberTuoKeTongStore,
        getDefaultMemberTuoKeTongStore,
        CompositeBusinessStore,
        getDefaultCompositeBusinessStore,
        ProductRightsStore,
        getDefaultProductRightsStore,

        AchievementManaStore,
        getDefaultAchievementManaStore,
        CompositeLiveStore,
        getDefaultCompositeLiveStore,
        HomeNewStore,
        getDefaultHomeNewStore,
    };
};

export const StoreContext = createContext<ReturnType<typeof createStore>>(
    createStore({}),
);
