import { makeAutoObservable } from 'mobx';
import { createDefaultInstanceGetter } from '../utils';
import { defaultAxios, arrToJson } from '@/utils';
import React from 'react';
import _ from 'lodash';
import { Alert } from 'antd';
import { ITableActionItem, IWidgetItem } from '@/components';
import { navigate } from '@reach/router';

const defaultMod = 'menu';
const defaultApi = '/bff/api/rest/perm?mod=' + defaultMod;

export const getParamPermApi = (tableCode: string, eventCode: string) => {
    if (eventCode.startsWith('JOB-')) {
        const status = eventCode.split('-')[1];
        return `/bff/api/rest/perm-params/job?entityCode=${tableCode}&status=${status}`;
    }
    return `/bff/api/rest/perm-params?entityCode=${tableCode}&event=${eventCode}`;
};

export interface IPermMap<T = any> {
    [key: string]: T;
}

export class Perm<T = any> {
    public originalPerm: T[] = [];
    public setOrignalPerm(data: any) {
        if (_.isArray(data)) {
            this.originalPerm = data;
            this.inited = true;
        }
    }
    get permMap() {
        const { originalPerm } = this;
        const retPermMap = arrToJson(originalPerm, 'key');
        return retPermMap;
    }

    get PermMapLast2Com() {
        const { originalPerm } = this;
        const retPermMapTmp = arrToJson(originalPerm, 'key');
        const keys = _.keys(retPermMapTmp);
        const retPermMap: typeof retPermMapTmp = {};
        for (const key of keys) {
            const keyComs = _.split(key, ':');
            const actionName = keyComs.pop();
            const type = keyComs.pop();
            if (type && actionName) {
                // retPermMap[type + ':' + actionName] = retPermMapTmp[key];
                retPermMap[actionName] = retPermMapTmp[key];
            }
        }
        return retPermMap;
    }

    get PermMapLast2ComOriginal() {
        const { originalPerm } = this;
        const retPermMapTmp = arrToJson(originalPerm, 'key');
        const keys = _.keys(retPermMapTmp);
        const retPermMap: typeof retPermMapTmp = {};
        for (const key of keys) {
            const keyComs = _.split(key, ':');
            const actionName = keyComs.pop();
            const type = keyComs.pop();
            if (type && actionName) {
                retPermMap[type + ':' + actionName] = retPermMapTmp[key];
            }
        }
        return retPermMap;
    }

    public defaultBlackListPerm = { visible: false };
    // public defaultBlackListPerm = { visible: true };
    public getPermByKey(key: string) {
        const netPerm = this.permMap[key];
        return netPerm || this.defaultBlackListPerm;
    }

    public getPermByTypeAndAction(type: string) {
        type = '';
        return (action: string) => {
            // 他们说不分type了，那么只看最后一段的action
            // 后面感觉可能改回去，现在底层改方法
            // const finalKey = type + ':' + action;
            const finalKey = type + action;
            const netPerm = this.PermMapLast2Com[finalKey];
            return netPerm || this.defaultBlackListPerm;
        };
    }

    public getPermByTypeAndActionOriginal(type: string) {
        return (action: string) => {
            const finalKey = type + ':' + action;
            const netPerm = this.PermMapLast2ComOriginal[finalKey];
            return netPerm || this.defaultBlackListPerm;
        };
    }

    public applyToTableActions(
        actions: ITableActionItem[],
    ): ITableActionItem[] {
        const nextActions = [...actions];
        return nextActions.filter(action => {
            return (this.getPermByKey(action.actionKey) as any).visible;
        });
    }

    public applyToTableActionsByTypeAndAction(
        type: string,
        useOriginal?: boolean,
    ) {
        useOriginal = _.isUndefined(useOriginal) ? false : useOriginal;
        return (actions: ITableActionItem[]) => {
            const nextActions = [...actions];
            return nextActions.filter(action => {
                if (!!action.force) {
                    return true;
                }
                if (useOriginal) {
                    return (this.getPermByTypeAndActionOriginal(type)(
                        action.actionKey,
                    ) as any).visible;
                }
                return (this.getPermByTypeAndAction(type)(
                    action.actionKey,
                ) as any).visible;
            });
        };
    }

    public applyToFormWidgets(widgets: Array<IWidgetItem<any>>) {
        const next = [...widgets];
        return next.filter(widget => {
            return (this.getPermByKey(widget.key as any) as any).visible;
        });
    }

    public inited = false;
    public loading = false;

    public async fetch(refresh?: boolean) {
        if (!refresh && this.inited) {
            return;
        }
        this.loading = true;
        if (!this.finalApi) {
            return;
        }
        const [d, e] = await defaultAxios.get(
            this.finalApi + '&hash=' + Math.random(),
        );
        this.loading = false;
        if (e) {
            return;
        }
        if (d && d.data) {
            this.setOrignalPerm(d.data);
        }
    }

    public finalApi = '';
    public setFinalApi(next: string) {
        this.finalApi = next;
    }

    constructor(api: string) {
        if (api) {
            this.finalApi = api;
        }
        makeAutoObservable(this);
    }
}

export const PermHOC: <T>(
    Com: React.FC<T>,
    permMap: IPermMap,
    key: string,
) => React.FC<T> = (Com, permMap, key) => {
    return props => {
        const permItem = permMap[key] || { visible: false };
        const visible = permItem.visible || key.startsWith('demo2');
        if (!visible) {
            return (
                <div style={{ padding: 15 }}>
                    <Alert
                        type="warning"
                        message={<span>无权访问</span>}
                        description={<span>请联系管理员申请权限</span>}
                    />
                </div>
            );
        }
        return <Com {...props} />;
    };
};

export const MenuPermStore = new Perm(defaultApi);
