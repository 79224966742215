import { makeAutoObservable } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
} from '@/stores/utils';
import { NexusGenFieldTypes } from '@/definations/graphql/auto-gen';
import {
    getTestFetchAuthors,
    TGetTestFetchAuthorsReturnType,
} from '@/api/graphql';
import { IFormCrudDemoFilterData } from './filter-options';
import _ from 'lodash';
import querystring from 'querystring';

export class CrudDemo {
    // 数据
    public data: Array<NexusGenFieldTypes['Author']> = [];

    // 筛选
    public filterData: IFormCrudDemoFilterData = {
        name: '',
        gender: '',
    };
    public setFilterData = (nextFilterData: IFormCrudDemoFilterData) => {
        this.filterData = nextFilterData;
    };

    // 筛选
    public confirmedfilterData: IFormCrudDemoFilterData = {
        name: '',
        gender: '',
    };
    public confirmFilterData = () => {
        this.confirmedfilterData = this.filterData;
    };

    public async fetch(): Promise<TGetTestFetchAuthorsReturnType> {
        const [d, e] = await getTestFetchAuthors(
            this.defaultPagination.paginationForFetch,
            this.filterData,
        );
        if (d === null || e !== null) {
            return [d, e];
        }
        const authorsData = d;
        const { amount, authors } = authorsData;

        this.data = authors;
        this.defaultPagination.amount = amount;
        return [d, e];
    }

    // history bidirect bind
    public caredHistorySearchParams = ['pagesize', 'pagenum', 'name', 'gender'];
    get queryInputFromStore() {
        const { defaultPagination, confirmedfilterData } = this;
        const { pagesize, pagenum } = defaultPagination;
        const { gender, name } = confirmedfilterData;
        const searchObject: querystring.ParsedUrlQueryInput = {};
        // 没有就不管，默认是三十条，也不管
        if (!_.isNil(pagesize) && pagesize !== 30) {
            searchObject.pagesize = '' + pagesize;
        }
        // 如果是第一页，也不管
        if (!_.isNil(pagenum) && pagenum > 1) {
            searchObject.pagenum = '' + pagenum;
        }
        if (gender !== '') {
            searchObject.gender = gender;
        }
        if (name !== '') {
            searchObject.name = name;
        }
        return searchObject;
    }
    public mutateStoreFromSearchChange = (
        parsedSearchObject: querystring.ParsedUrlQueryInput,
    ) => {
        const { name, gender, pagenum, pagesize } = parsedSearchObject;
        const nextFilterData = _.cloneDeep(this.filterData);
        if (_.isString(name)) {
            nextFilterData.name = name;
        } else {
            nextFilterData.name = '';
        }
        if (_.isString(gender)) {
            nextFilterData.gender = gender;
        } else {
            nextFilterData.gender = '';
        }
        this.filterData = nextFilterData;
        this.confirmedfilterData = nextFilterData;
        if (_.isString(pagenum) && !_.isNaN(Number(pagenum))) {
            this.defaultPagination.pagenum = Number(pagenum);
        } else {
            this.defaultPagination.pagenum = 1;
        }
        if (_.isString(pagesize) && !_.isNaN(Number(pagesize))) {
            this.defaultPagination.pagesize = Number(pagesize);
        } else {
            this.defaultPagination.pagesize = 30;
        }
    };

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    constructor() {
        makeAutoObservable(this, {
            defaultPagination: false,
        });
    }
}

export const getDefaultCrudDemo = createDefaultInstanceGetter(CrudDemo);
