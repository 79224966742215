import React from 'react';

const Key2Meaning = {
    今日预估: '预计回款时间在今日的60及以上所有商机储备金额加和',
    今日已回: '实时统计数据，预计回款时间在今日、且已经入账的金额',
    明日预估:
        '预计回款时间在明日的60及以上所有商机储备金额加和（若为周五，则为预计回款时间在周一的数据）',
    // = 周已回
    周回款:
        '实时统计数据，预计回款时间在当周、商机阶段在40及以上、且已经入账的所有商机储备金额加和',
    周已回:
        '实时统计数据，预计回款时间在当周、商机阶段在40及以上、且已经入账的所有商机储备金额加和',
    周保守预估: '周已回+周稳回',
    周稳回:
        '预计回款时间在当周工作日、商机阶段在40及以上的所有商机储备金额加和',
    周大概率回:
        '预计回款时间在当周节假日、商机阶段在40及以上的所有商机储备金额加和',
    周保守预估Gap: '周保守预估-周已回',
    '已回/保守预估': '周已回/周保守预估',
    周冲刺预估: '周已回+周稳回+周大概率回*0.7',
    周冲刺预估Gap: '周冲刺预估-周已回',
    '周已回/冲刺预估': '周已回/周冲刺预估',
    月回款: '实时统计数据，预计回款时间在当月、且已经入账的金额',
    月已回: '实时统计数据，预计回款时间在当月、且已经入账的金额',
    月目标: '月度任务',
    月大概率回:
        '预计回款时间在当月节假日、商机阶段在40及以上的所有商机储备金额加和',
    月稳回:
        '预计回款时间在当月工作日、商机阶段在40及以上的所有商机储备金额加和',
    月达成率: '月已回/月目标',
    月保守预估: '月已回+月稳回*0.9+月大概率回*0.7',
    月保守预估Gap: '月保守预估-月已回',
    月保守预估达成率: '月保守预估/月目标',
    月Gap: '月目标-月回款',
    月冲刺预估: '月已回+月稳回+月大概率回',
    月冲刺预估Gap: '月冲刺预估-月已回',
    月冲刺预估达成率: '月冲刺预估/月目标',
    月度稳回: '实时统计数据，预计回款时间在当月工作日、且已经入账的金额',
    月度大概率回:
        '预计回款时间在当月节假日、商机阶段在40及以上的所有商机储备金额加和',
    月同比: '（月回款/去年同月份回款金额-1）*100%',
    月环比: '（月回款/上月的的回款-1）*100%',
    双月回款: '实时统计数据，预计回款时间在当双月、且已经入账的金额',
    双月已回: '实时统计数据，预计回款时间在当双月、且已经入账的金额',
    双月目标: '双月任务',
    双月达成率: '双月已回/双月目标',
    双月保守预估: '双月已回+双月稳回*0.9+双月大概率回*0.7',
    双月保守预估达成率: '双月保守预估/双月目标',
    双月冲刺预估: '双月已回+双月稳回+双月大概率回',
    双月冲刺预估达成率: '双月冲刺预估/双月目标',
    双月稳回: '实时统计数据，预计回款时间在当双月工作日、且已经入账的金额',
    双月大概率回:
        '预计回款时间在当双月节假日、商机阶段在40及以上的所有商机储备金额加和',
    季回款: '实时统计数据，预计回款时间在当季、且已经入账的金额',
    季已回: '实时统计数据，预计回款时间在当季、且已经入账的金额',
    季目标: '季度任务',
    季达成率: '季已回/季目标',
    季度保守预估: '季已回+季稳回*0.9+季大概率回*0.7',
    季度保守预估Gap: '季度保守预估-季已回',
    季度保守预估达成率: '季度保守预估/季目标',
    季度Gap: '季目标-季已回',
    季冲刺预估: '季已回+季稳回+季大概率回',
    季冲刺预估Gap: '季冲刺预估-季已回',
    季冲刺预估达成率: '季冲刺预估/季目标',
    季度稳回: '实时统计数据，预计回款时间在当季工作日、且已经入账的金额',
    季度大概率回:
        '预计回款时间在当季节假日、商机阶段在40及以上的所有商机储备金额加和',
    双月同比: '（双月回款/去年同双月回款金额-1）*100%',
    双月环比: '（双月回款/上双月的回款-1）*100%',
    季度M1环比: '（本季第一个月的回款/上季第一个月的回款-1）*100%',
    季度M2环比: '（本季第二个月的回款/上季第二个月的回款-1）*100%',
    季度M3环比: '（本季第三个月的回款/上季第三个月的回款-1）*100%',
    本季第一个月的回款: '预计回款时间在本季第一个月、且已经入账的金额',
    上季第一个月的回款: '预计回款时间在上季第一个月、且已经入账的金额',
    本季第二个月的回款: '预计回款时间在本季第二个月、且已经入账的金额',
    上季第二个月的回款: '预计回款时间在上季第二个月、且已经入账的金额',
    本季第三个月的回款: '预计回款时间在本季第三个月、且已经入账的金额',
    上季第三个月的回款: '预计回款时间在上季第三个月、且已经入账的金额',
    年度回款: '实时统计数据，预计回款时间在当年、且已经入账的金额',
};

export const Key2Tips = {
    今日预估: Key2Meaning['今日预估'],
    今日已回: Key2Meaning['今日已回'],
    明日预估: Key2Meaning['明日预估'],
    // = 周已回
    周回款: Key2Meaning['周回款'],

    周保守预估: (
        <div>
            {Key2Meaning['周保守预估']}
            <br />
            周已回：{Key2Meaning['周回款']}
            <br />
            周稳回：{Key2Meaning['周稳回']}
        </div>
    ),
    周保守预估Gap: (
        <div>
            {Key2Meaning['周保守预估Gap']}
            <br />
            周保守预估：{Key2Meaning['周保守预估']}
            <br />
            周已回：{Key2Meaning['周已回']}
            <br />
            周稳回：{Key2Meaning['周稳回']}
        </div>
    ),
    '周已回/保守预估': (
        <div>
            {Key2Meaning['已回/保守预估']}
            <br />
            周已回：{Key2Meaning['周已回']}
            <br />
            周保守预估：{Key2Meaning['周保守预估']}
            <br />
            周已回：{Key2Meaning['周已回']}
            <br />
            周稳回：{Key2Meaning['周稳回']}
        </div>
    ),
    周冲刺预估: (
        <div>
            {Key2Meaning['周冲刺预估']}
            <br />
            周已回：{Key2Meaning['周已回']}
            <br />
            周稳回：{Key2Meaning['周稳回']}
            <br />
            周大概率回：{Key2Meaning['周大概率回']}
        </div>
    ),
    周冲刺预估Gap: (
        <div>
            {Key2Meaning['周冲刺预估Gap']}
            <br />
            周冲刺预估：{Key2Meaning['周冲刺预估']}
            <br />
            周已回：{Key2Meaning['周已回']}
            <br />
            周稳回：{Key2Meaning['周稳回']}
            <br />
            周大概率回：{Key2Meaning['周大概率回']}
        </div>
    ),
    '周已回/冲刺预估': (
        <div>
            {Key2Meaning['周已回/冲刺预估']}
            <br />
            周冲刺预估：{Key2Meaning['周冲刺预估']}
            <br />
            周已回：{Key2Meaning['周已回']}
            <br />
            周稳回：{Key2Meaning['周稳回']}
            <br />
            周大概率回：{Key2Meaning['周大概率回']}
        </div>
    ),
    月回款: Key2Meaning['月回款'],
    月目标: Key2Meaning['月目标'],
    月达成率: (
        <div>
            {Key2Meaning['月达成率']}
            <br />
            月已回：{Key2Meaning['月已回']}
            <br />
            月目标：{Key2Meaning['月目标']}
        </div>
    ),
    月保守预估: (
        <div>
            {Key2Meaning['月保守预估']}
            <br />
            月已回：{Key2Meaning['月已回']}
            <br />
            月稳回：{Key2Meaning['月稳回']}
            <br />
            月大概率回：{Key2Meaning['月度大概率回']}
        </div>
    ),
    月保守预估Gap: (
        <div>
            {Key2Meaning['月保守预估Gap']}
            <br />
            月保守预估：{Key2Meaning['月保守预估']}
            <br />
            月已回：{Key2Meaning['月已回']}
            <br />
            月稳回：{Key2Meaning['月稳回']}
            <br />
            月大概率回：{Key2Meaning['月度大概率回']}
        </div>
    ),
    月保守预估达成率: (
        <div>
            {Key2Meaning['月保守预估达成率']}
            <br />
            月保守预估：{Key2Meaning['月保守预估']}
            <br />
            月已回：{Key2Meaning['月已回']}
            <br />
            月稳回：{Key2Meaning['月稳回']}
            <br />
            月大概率回：{Key2Meaning['月度大概率回']}
            <br />
            月目标：{Key2Meaning['月目标']}
        </div>
    ),
    月Gap: (
        <div>
            {Key2Meaning['月Gap']}
            <br />
            月目标：{Key2Meaning['月目标']}
            <br />
            月已回：{Key2Meaning['月已回']}
        </div>
    ),
    月冲刺预估: (
        <div>
            {Key2Meaning['月冲刺预估']}
            <br />
            月已回：{Key2Meaning['月已回']}
            <br />
            月稳回：{Key2Meaning['月稳回']}
            <br />
            月大概率回：{Key2Meaning['月度大概率回']}
        </div>
    ),
    月冲刺预估Gap: (
        <div>
            {Key2Meaning['月冲刺预估Gap']}
            <br />
            月冲刺预估：{Key2Meaning['月冲刺预估']}
            <br />
            月已回：{Key2Meaning['月已回']}
            <br />
            月稳回：{Key2Meaning['月稳回']}
            <br />
            月大概率回：{Key2Meaning['月度大概率回']}
        </div>
    ),
    月冲刺预估达成率: (
        <div>
            {Key2Meaning['月冲刺预估达成率']}
            <br />
            月冲刺预估：{Key2Meaning['月冲刺预估']}
            <br />
            月已回：{Key2Meaning['月已回']}
            <br />
            月稳回：{Key2Meaning['月稳回']}
            <br />
            月大概率回：{Key2Meaning['月度大概率回']}
            <br />
            月目标：{Key2Meaning['月目标']}
        </div>
    ),
    月度稳回: Key2Meaning['月度稳回'],
    月度大概率回: Key2Meaning['月度大概率回'],
    月同比: (
        <div>
            {Key2Meaning['月同比']}
            <br />
            月回款：{Key2Meaning['月回款']}
        </div>
    ),
    月环比: (
        <div>
            {Key2Meaning['月环比']}
            <br />
            月回款：{Key2Meaning['月回款']}
        </div>
    ),
    双月回款: Key2Meaning['双月回款'],
    双月目标: Key2Meaning['双月目标'],
    双月达成率: (
        <div>
            {Key2Meaning['双月达成率']}
            <br />
            双月已回：{Key2Meaning['双月已回']}
            <br />
            双月目标：{Key2Meaning['双月目标']}
        </div>
    ),
    双月保守预估: (
        <div>
            {Key2Meaning['双月保守预估']}
            <br />
            双月已回：{Key2Meaning['双月已回']}
            <br />
            双月稳回：{Key2Meaning['双月稳回']}
            <br />
            双月大概率回：{Key2Meaning['双月大概率回']}
        </div>
    ),
    双月保守预估达成率: (
        <div>
            {Key2Meaning['双月保守预估达成率']}
            <br />
            双月保守预估：{Key2Meaning['双月保守预估']}
            <br />
            双月已回：{Key2Meaning['双月已回']}
            <br />
            双月稳回：{Key2Meaning['双月稳回']}
            <br />
            双月大概率回：{Key2Meaning['双月大概率回']}
            <br />
            双月目标：{Key2Meaning['双月目标']}
        </div>
    ),
    双月冲刺预估: (
        <div>
            {Key2Meaning['双月冲刺预估']}
            <br />
            双月{Key2Meaning['双月已回']}
            <br />
            双月稳回：{Key2Meaning['双月稳回']}
            <br />
            双月大概率回：{Key2Meaning['双月大概率回']}
        </div>
    ),
    双月冲刺预估达成率: (
        <div>
            {Key2Meaning['双月冲刺预估达成率']}
            <br />
            {Key2Meaning['双月冲刺预估']}
            <br />
            双月已回：{Key2Meaning['双月已回']}
            <br />
            双月稳回：{Key2Meaning['双月稳回']}
            <br />
            双月大概率回：{Key2Meaning['双月大概率回']}
            <br />
            双月目标：{Key2Meaning['双月目标']}
        </div>
    ),
    季回款: Key2Meaning['季回款'],
    季目标: Key2Meaning['季目标'],
    季达成率: (
        <div>
            {Key2Meaning['季达成率']}
            <br />
            季已回：{Key2Meaning['季已回']}
            <br />
            季目标：{Key2Meaning['季目标']}
        </div>
    ),
    季度保守预估: (
        <div>
            {Key2Meaning['季度保守预估']}
            <br />
            季已回：{Key2Meaning['季已回']}
            <br />
            季稳回：{Key2Meaning['季度稳回']}
            <br />
            季大概率回：{Key2Meaning['季度大概率回']}
        </div>
    ),
    季度保守预估Gap: (
        <div>
            {Key2Meaning['季度保守预估Gap']}
            <br />
            季度保守预估：{Key2Meaning['季度保守预估']}
            <br />
            季已回：{Key2Meaning['季已回']}
            <br />
            季稳回：{Key2Meaning['季度稳回']}
            <br />
            季大概率回：{Key2Meaning['季度大概率回']}
        </div>
    ),
    季度保守预估达成率: (
        <div>
            {Key2Meaning['季度保守预估达成率']}
            <br />
            季度保守预估：{Key2Meaning['季度保守预估']}
            <br />
            季已回：{Key2Meaning['季已回']}
            <br />
            季稳回：{Key2Meaning['季度稳回']}
            <br />
            季大概率回：{Key2Meaning['季度大概率回']}
            <br />
            季目标：{Key2Meaning['季目标']}
        </div>
    ),
    季度Gap: (
        <div>
            {Key2Meaning['季度Gap']}
            <br />
            季目标：{Key2Meaning['季目标']}
            <br />
            季已回：{Key2Meaning['季已回']}
        </div>
    ),
    季度冲刺预估: (
        <div>
            {Key2Meaning['季冲刺预估']}
            <br />
            季已回：{Key2Meaning['季已回']}
            <br />
            季稳回：{Key2Meaning['季度稳回']}
            <br />
            季大概率回：{Key2Meaning['季度大概率回']}
        </div>
    ),
    季冲刺预估Gap: (
        <div>
            {Key2Meaning['季冲刺预估Gap']}
            <br />
            季冲刺预估：{Key2Meaning['季冲刺预估']}
            <br />
            季已回：{Key2Meaning['季已回']}
            <br />
            季稳回：{Key2Meaning['季度稳回']}
            <br />
            季大概率回：{Key2Meaning['季度大概率回']}
        </div>
    ),
    季冲刺预估达成率: (
        <div>
            {Key2Meaning['季冲刺预估达成率']}
            <br />
            {Key2Meaning['季冲刺预估']}
            <br />
            季已回：{Key2Meaning['季已回']}
            <br />
            季稳回：{Key2Meaning['季度稳回']}
            <br />
            季大概率回：{Key2Meaning['季度大概率回']}
            <br />
            季目标：{Key2Meaning['季目标']}
        </div>
    ),
    双月稳回: Key2Meaning['双月稳回'],
    双月大概率回: Key2Meaning['双月大概率回'],
    双月同比: (
        <div>
            {Key2Meaning['双月同比']}
            <br />
            双月回款：{Key2Meaning['双月回款']}
        </div>
    ),
    双月环比: (
        <div>
            {Key2Meaning['双月环比']}
            <br />
            双月回款：{Key2Meaning['双月回款']}
        </div>
    ),
    季度M1环比: (
        <div>
            {Key2Meaning['季度M1环比']}
            <br />
            本季第一个月的回款：{Key2Meaning['本季第一个月的回款']}
            <br />
            上季第一个月的回款：{Key2Meaning['上季第一个月的回款']}
        </div>
    ),
    季度M2环比: (
        <div>
            {Key2Meaning['季度M2环比']}
            <br />
            本季第二个月的回款：{Key2Meaning['本季第二个月的回款']}
            <br />
            上季第二个月的回款：{Key2Meaning['上季第二个月的回款']}
        </div>
    ),
    季度M3环比: (
        <div>
            {Key2Meaning['季度M3环比']}
            <br />
            本季第三个月的回款：{Key2Meaning['本季第三个月的回款']}
            <br />
            上季第三个月的回款：{Key2Meaning['上季第三个月的回款']}
        </div>
    ),
    年度回款: Key2Meaning['年度回款'],
};
