// AGREE
// ADD_APPROVE

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Input, message, Form, Divider, Alert } from 'antd';
import { rejects, btnMap } from '../conf';
import '../action.scss';
import _ from 'lodash';
import { ProcessCatStore } from '../../../store';
import { PreviewProductOpen } from './preview-product-open';
import moment from 'moment';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const AgreeAction: React.FC<{
    currentAction: string;
    data: any;
    onOk: (extra?: any) => void;
    onCancel: () => void;
    defaultStore: ProcessCatStore | null;
    loading: boolean;
}> = observer(
    ({ currentAction, data, onOk, onCancel, defaultStore, loading }) => {
        const [disabled, setD] = useState(true);
        const [step, setStep] = useState(0);
        const [d, setData] = useState<any>({});

        let processAllInfo = null;
        if (defaultStore) {
            processAllInfo = defaultStore?.defaultMeta?.processAllInfo;
        }
        const openProcessId = processAllInfo?.businessInfo?.id;

        const title = btnMap[currentAction] || '';
        const comment = data?.comment || '';
        const processBtns = defaultStore?.defaultMeta?.processBtns || [];
        const showAddApprove = processBtns.indexOf('ADD_APPROVE') > -1;
        const visible =
            currentAction === 'AGREE' && !showAddApprove && data !== null;

        useEffect(() => {
            if (!visible) {
                return;
            }
            setStep(2);
        }, [visible]);

        const mutatingData = defaultStore?.mutatingData;
        const contractName =
            mutatingData?.contract_id?.relatedObj?.contract_name;
        const productName = d?.product_name;
        let tt =
            contractName && productName
                ? `执行【${contractName}】合同中的【${productName}】产品`
                : '执行产品';

        const isRenGong = mutatingData?.product_open_method === 2;
        if (isRenGong) tt = '通过';

        return (
            <Modal
                title={tt}
                onOk={() => {
                    if (step === 1) {
                        return setStep(2);
                    }
                    onOk();
                }}
                confirmLoading={loading}
                okButtonProps={
                    {
                        // disabled: step === 1 ? false : disabled,
                    }
                }
                okText={step === 1 ? '下一步' : '确定'}
                onCancel={() => {
                    setD(true);
                    setStep(0);
                    onCancel();
                }}
                visible={visible}
                zIndex={10001}
            >
                {step === 2 ? (
                    <div className="process-action">
                        {!isRenGong && (
                            <>
                                <h3>开通预览</h3>
                                <PreviewProductOpen
                                    onGotData={setData}
                                    key={openProcessId + ':' + visible}
                                    visible={visible}
                                    openProcessId={openProcessId}
                                    mutatingData={mutatingData}
                                    onOk={() => {
                                        setD(false);
                                    }}
                                />
                                <Divider />
                            </>
                        )}
                        <p style={{ marginTop: 12 }}>请填写审批意见</p>
                        <div className="form-wrapper">
                            <Form className="form" layout={'horizontal'}>
                                <Form.Item
                                    {...formItemLayout}
                                    label={
                                        <span className="label">审批意见</span>
                                    }
                                >
                                    <Input.TextArea
                                        required={true}
                                        value={comment}
                                        onChange={e => {
                                            const text = e?.target?.value;
                                            if (!_.isUndefined(text)) {
                                                data.comment = text;
                                            }
                                        }}
                                        placeholder={'审批意见'}
                                    ></Input.TextArea>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                ) : null}
                {step === 1 ? (
                    <div className="process-action">
                        <Alert
                            type="warning"
                            message="销售申请开通日期大于当前日期，如果是新签业务，点击开通将按当前日期立即开通；如果是续约业务，点击将按续约开始日期开通"
                        />
                    </div>
                ) : null}
            </Modal>
        );
    },
);
