import React, { memo, useContext, useEffect, useState } from 'react';
import {
    applyCustomConfToWidget,
    BlockLoading2,
    DForm,
    applyConfToFormItem,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { IJobStore } from '../../../utils';
import { commonQuery } from '@/api/rest';
import { StoreContext } from '@/stores';
import { defaultAxios, decodePhone as phoneDecode } from '@/utils';
import AddFollowLog from '@/components/common/add-follow-log-new';
import { Icon } from 'antd';
import moment from 'moment';

const DemoForm: React.FC<{
    defaultStore: IJobStore;
    isMultipleFollowCus?: boolean;
    customers?: any[];
}> = ({ defaultStore, isMultipleFollowCus, customers }) => {
    const { mutatingData, subActionType, actionType } = defaultStore;
    const cusId = mutatingData?.follow_customer?.originalValue;
    const startTime = mutatingData?.start_time;
    const store = useContext(StoreContext);
    const userStore = store.getAuthStore();
    const userId = userStore.userInfo?.sysUser?.id;
    const subUsers = userStore.userInfo?.subUsers || [];
    const myUsers = [userId, ...subUsers.map((item: any) => item.id)].filter(
        Boolean,
    );
    const [role, setRole] = useState(1);
    const [roleInited, setRoleInited] = useState(false);

    const [followActionModalVis, setFollowActionModalVis] = useState(false);
    const [followActionInitMark, setFollowActionInitMark] = useState(false);

    useEffect(() => {
        if (mutatingData && customers) {
            mutatingData._outsideCustomers = customers;
        }
    }, [mutatingData, customers]);

    useEffect(() => {
        if (actionType !== 'create' || !cusId || isMultipleFollowCus) {
            return;
        }
        mutatingData.executor = customers?.[0]?.owner_id || null;
    }, [actionType, cusId, isMultipleFollowCus, customers]);

    // 这里请求一个接口，根据他的权限，下面executor选择的范围不同
    // useEffect(() => {
    //     if (
    //         mutatingData &&
    //         roleInited &&
    //         role !== 2 &&
    //         role !== 5 &&
    //         !mutatingData.executor
    //     ) {
    //         mutatingData.executor = {
    //             originalValue: userId,
    //             relatedObj: userStore.userInfo?.sysUser,
    //             type: 'obj',
    //         };
    //     }

    //     if (!roleInited) {
    //         // fetch!
    //         defaultAxios
    //             .get('/crm/authority/role/data/user', {
    //                 entityCode: 'task_activation',
    //                 eventCode: 'SEARCH',
    //             })
    //             .then(([d, e]) => {
    //                 if (isNetSuccess(d, e)) {
    //                     setRole(d?.data);
    //                     setRoleInited(true);
    //                 }
    //             });
    //     }
    // }, [mutatingData, role, roleInited]);
    const decodePhone = async (phoneNum: string | number) => {
        const url = '/crm/crypto/phone/decrypt';
        const [d, e] = await defaultAxios.post(url, {
            phone: phoneNum,
            scene: 'crm_activation',
        });
        const data = phoneDecode(d?.data);
        const error = e as Error | null;
        if (data) {
            return data;
        } else {
            return phoneNum;
        }
    };
    useEffect(() => {
        const customer_contact = mutatingData?.customer_contact;
        if (!customer_contact) {
            return;
        }
        const value = customer_contact;
        mutatingData.contact_phone = null;
        commonQuery(
            'contacts',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: value?.originalValue,
                        operateType: '=',
                    },
                ],
            },
            undefined,
            undefined,
            undefined,
            'SEARCH-ALL',
        ).then(async ([d, e]) => {
            if (_.isNil(d) || e !== null) {
                return;
            }
            const { datas } = d;
            if (datas && datas[0]) {
                const dt = datas[0];
                // const pData = await decodePhone(dt.contacts_phone);
                mutatingData.contact_phone = dt.contacts_phone;
            } else {
            }
        });
    }, [mutatingData?.customer_contact]);

    useEffect(() => {
        const follow_customer = mutatingData?.follow_customer;
        if (!follow_customer || _.isArray(follow_customer)) {
            return;
        }
        const value = follow_customer;
        mutatingData.executor = null;
        commonQuery(
            'customer',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: value?.originalValue,
                        operateType: '=',
                    },
                ],
            },
            undefined,
            undefined,
            undefined,
            'SEARCH-ALL',
        ).then(([d, e]) => {
            if (_.isNil(d) || e !== null) {
                return;
            }
            const { datas } = d;
            if (datas && datas[0]) {
                const dt = datas[0];
                mutatingData.executor = dt.owner_id;
            } else {
            }
        });
    }, [mutatingData?.follow_customer]);

    const shouldAddFollowLog = !!cusId && actionType === 'update';

    if (!mutatingData) {
        return <BlockLoading2 />;
    }

    const form = (
        <>
            {shouldAddFollowLog && (
                <AddFollowLog
                    entityType={'customer'}
                    cid={cusId}
                    id={cusId}
                    drawerVis={followActionModalVis}
                    setDrawerVis={setFollowActionModalVis}
                    hiddenTask={true}
                    initMark={followActionInitMark}
                    withNextJobRadio={false}
                    onCreated={data => {
                        setFollowActionInitMark(true);
                        setTimeout(() => {
                            setFollowActionInitMark(false);
                        }, 0);

                        // 直接给塞到表单
                        const createdId = data?.createdData?.id;
                        if (createdId) {
                            mutatingData.activation_id = {
                                type: 'obj',
                                originalValue: createdId,
                                relatedObj: {
                                    id: createdId,
                                },
                            };
                        }
                    }}
                />
            )}
            <DForm
                defaultStore={defaultStore}
                options={{
                    grouped: true,
                }}
                eventCode={
                    'JOB-' +
                    (subActionType === 'complete'
                        ? '5'
                        : mutatingData?.task_state || '1')
                }
                onChange={(key, value, widgetKey) => {
                    if (key === 'follow_customer') {
                        mutatingData.customer_contact = null;
                        mutatingData.contact_phone = null;
                    }

                    if (key === 'start_time') {
                        mutatingData.end_time = moment(value)
                            .endOf('day')
                            .valueOf();
                    }
                }}
                propsTransformer={props => {
                    if (props.collapseProps && subActionType === 'complete') {
                        const otherGroup = ((props.collapseProps
                            ?.defaultActiveKey ||
                            []) as any[]).find((item: string) =>
                                item.endsWith('_finish'),
                            );
                        if (otherGroup) {
                            props.collapseProps.defaultActiveKey = [otherGroup];
                        }
                    }
                    return props;
                }}
                widgetsTransformer={widgets => {
                    const nextWidgets = [...widgets];
                    applyConfToFormItem(nextWidgets, 'inputSecret', {
                        sceneCode: 'crm_activation',
                    });
                    // 如果是多选形式，选择新插件，联系人随便选择
                    if (isMultipleFollowCus) {
                        applyCustomConfToWidget(
                            nextWidgets,
                            'follow_customer',
                            widget => {
                                widget.widgetKey = 'mFollowCus';
                                return widget;
                            },
                        );
                    } else {
                        applyCustomConfToWidget(
                            nextWidgets,
                            'follow_customer',
                            widget => {
                                if (widget.objectOptions) {
                                    widget.objectOptions.dataAuthCode =
                                        'SEARCH-PRIVATE';
                                }
                                return widget;
                            },
                        );
                    }

                    applyCustomConfToWidget(
                        nextWidgets,
                        'task_type',
                        widget => {
                            widget.status = {
                                ...widget.status,
                                hidden: true,
                            };
                            return widget;
                        },
                    );

                    applyCustomConfToWidget(
                        nextWidgets,
                        'customer_contact',
                        widget => {
                            if (cusId && widget.objectOptions) {
                                widget.objectOptions.defaultFilters = [
                                    {
                                        fieldName: 'customer_id',
                                        operateType: 'EQUAL',
                                        fieldValue: cusId,
                                    },
                                ];
                                widget.objectOptions.reloadTrigger = cusId;
                            }

                            widget.status = {
                                ...widget.status,
                                hidden: isMultipleFollowCus ? true : false,
                            };
                            return widget;
                        },
                    );

                    applyCustomConfToWidget(
                        nextWidgets,
                        'activation_id',
                        widget => {
                            if (widget.objectOptions) {
                                widget.objectOptions.paramsBlackList = {
                                    id: false,
                                };
                                widget.objectOptions.noSkipParams = true;
                                const constraintObj =
                                    widget.objectOptions?.constraint
                                        ?.constraintObj || {};
                                constraintObj.objFindFieldIdList = [
                                    'id',
                                    'create_time',
                                    'follow_type',
                                    'note',
                                ];
                                widget.objectOptions.defaultFilters = [
                                    {
                                        fieldName: 'customer_id',
                                        operateType: 'EQUAL',
                                        fieldValue: cusId,
                                    },
                                    {
                                        fieldName: 'create_user',
                                        operateType: 'EQUAL',
                                        fieldValue: userId,
                                    },
                                    {
                                        fieldName: 'task_id',
                                        operateType: 'NULL',
                                    },
                                    {
                                        fieldName: 'create_time',
                                        operateType: 'GREATER_EQUAL',
                                        fieldValue: moment(
                                            startTime || Date.now(),
                                        ).format('YYYY-MM-DD HH:mm:ss'),
                                    },
                                ];
                            }

                            widget.nextlineWidget = () => {
                                return (
                                    <div style={{ paddingTop: 4 }}>
                                        <a
                                            onClick={() => {
                                                setFollowActionModalVis(true);
                                            }}
                                        >
                                            新建活动记录
                                            <Icon type="plus" />
                                        </a>
                                    </div>
                                );
                            };

                            return widget;
                        },
                    );

                    applyCustomConfToWidget(
                        nextWidgets,
                        'contact_phone',
                        widget => {
                            if (cusId && widget.objectOptions) {
                                widget.objectOptions.defaultFilters = [
                                    {
                                        fieldName: 'customer_id',
                                        operateType: 'EQUAL',
                                        fieldValue: cusId,
                                    },
                                ];
                                widget.objectOptions.reloadTrigger = cusId;
                            }

                            widget.status = {
                                ...widget.status,
                                hidden: isMultipleFollowCus ? true : false,
                            };
                            return widget;
                        },
                    );

                    applyCustomConfToWidget(nextWidgets, 'executor', widget => {
                        if (isMultipleFollowCus) {
                            widget.status = {
                                ...widget.status,
                                hidden: true,
                            };
                        } else {
                            widget.status = {
                                ...widget.status,
                                disabled: true,
                            };
                        }

                        if (widget.objectOptions) {
                            delete widget.objectOptions.defaultFilters;
                            if (role === 5) {
                                // noop 全部
                            } else if (role === 2) {
                                widget.objectOptions.defaultFilters = [
                                    {
                                        fieldName: 'id',
                                        operateType: 'IN',
                                        fieldValue: [
                                            userId,
                                            ...subUsers.map(
                                                (item: any) => item.id,
                                            ),
                                        ]
                                            .filter(Boolean)
                                            .join(','),
                                    },
                                ];
                            } else {
                                // 其他case 按只看自己处理
                                widget.objectOptions.defaultFilters = [
                                    {
                                        fieldName: 'id',
                                        operateType: 'IN',
                                        fieldValue: [
                                            userId,
                                            ...subUsers.map(
                                                (item: any) => item.id,
                                            ),
                                        ]
                                            .filter(Boolean)
                                            .join(','),
                                    },
                                ];
                            }
                        }
                        return widget;
                    });

                    return nextWidgets;
                }}
            />
        </>
    );
    return form;
};

const FinalDemoForm = memo(observer(DemoForm));
export default FinalDemoForm;
