import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { uuid } from '@/utils';
import { Spin, Icon } from 'antd';
import './index.less';
import _ from 'lodash';

export interface IChartsComponentsProps {
    containerClassName?: string;
    chartsOptions: echarts.EChartsOption;
    loading: boolean;
    chartsClassName: string;
    title: string;
    showTitle?: boolean;
    extraRight?: string;
    showPre?: boolean;
}
// const domId = `charts-${uuid()}`;
// let chartElement: echarts.ECharts | null = null;
const CompositeLiveChartsComponents: React.FC<IChartsComponentsProps> = props => {
    const {
        containerClassName = '',
        chartsOptions,
        loading,
        chartsClassName,
        showTitle = true,
        showPre = false,
    } = props;
    const [chartElement, setChartElement] = useState<echarts.ECharts | null>(
        null,
    );
    const boxRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef(null);

    const [domId] = useState<string>(`charts-${uuid()}`);
    const [boxId] = useState<string>(`box-${uuid()}`);
    const resizeFunction = _.debounce(
        () => {
            const boxDom = document.getElementById(boxId);
            const chartDom = document.getElementById(domId);
            if (chartDom) {
                chartDom.style.width = `${boxDom?.clientWidth}px` || '0px';
                chartDom.style.height = `${boxDom?.clientHeight}px` || '0px';
                // chartDom.setAttribute("style", `width: ${boxDom?.clientWidth}; height: ${boxDom?.clientHeight}`)
            }
            if (chartElement) {
                chartElement.resize();
            }
        },
        300,
        {
            trailing: true,
        },
    );
    useEffect(() => {
        return () => {
            window.removeEventListener('resize', resizeFunction);
        };
        // console.dir(boxRef.current?.clientWidth;
        // console.log(boxRef.current?.offsetHeight);
        // console.(document.getElementById(boxId)?.offsetWidth);
    }, []);

    useLayoutEffect(() => {
        // console.dir(boxRef.current?.clientWidth);
        // console.log(boxRef.current?.offsetHeight);
        const chartDom = document.getElementById(domId);
        // console.log('dom', chartDom);
        if (chartDom) {
            setChartElement(echarts.init(chartDom));
        }
    }, []);
    useEffect(() => {
        // console.dir(document.getElementById(boxId)?.clientWidth);
        const boxDom = document.getElementById(boxId);
        const chartDom = document.getElementById(domId);
        if (chartDom) {
            chartDom.style.width = `${boxDom?.clientWidth}px` || '0px';
            chartDom.style.height = `${boxDom?.clientHeight}px` || '0px';
            // chartDom.setAttribute("style", `width: ${boxDom?.clientWidth}; height: ${boxDom?.clientHeight}`)
        }
        // console.log('testst', chartsOptions, chartElement);
        if (chartElement && chartsOptions) {
            chartElement.setOption(chartsOptions);
            chartElement.resize();
        }
    }, [chartsOptions]);
    useEffect(() => {
        if (chartElement) {
            chartElement.setOption(chartsOptions);
            resizeFunction();
            chartElement.resize();
            window.addEventListener('resize', resizeFunction);
        }
    }, [chartElement]);
    return (
        <Spin spinning={loading}>
            <div className={chartsClassName || ''}>
                {showTitle && (
                    <div className="composite-live-charts-header">
                        <span className="composite-live-charts-title">
                            {props.title}
                        </span>
                        <span className="composite-live-charts-tips">
                            {props.extraRight}
                        </span>
                    </div>
                )}
                {showPre ? (
                    <div
                        className={`composite-live-charts-pre ${containerClassName}`}
                    >
                        <span>
                            <Icon type="experiment" />
                            规划中，敬请期待
                        </span>
                    </div>
                ) : (
                    <div id={boxId} className="composite-live-charts-box">
                        <div
                            ref={containerRef}
                            className={`composite-live-charts-item-container ${containerClassName}`}
                            id={domId}
                        ></div>
                    </div>
                )}
            </div>
        </Spin>
    );
};

export default CompositeLiveChartsComponents;
