import React, { useEffect, memo, useState, useContext } from 'react';

import { CustomerListStore } from '../store';
import { Modal, Form, Checkbox } from 'antd';
import _ from 'lodash';
import { WidgetCommonObject } from '@/components/common/form/widgets';
import './m-transform.scss';
import { runInAction } from 'mobx';
import {
    MOpResultDisplayContainer,
    MOpNameDisplayContainer,
} from '@/components';
import { observer } from 'mobx-react-lite';
import { tryRefresh } from '@/utils';
import { voyagerTrack } from '@shared/voyager';
import { StoreContext } from '@/stores';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const ActionMTransoform: React.FC<{
    customerList: CustomerListStore;
    refreshFunc?: () => void;
}> = observer(({ customerList, refreshFunc }) => {
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const { defaultMSelect, mutatingData } = customerList;
    const { mSeletedData } = defaultMSelect;

    const [user, setUser] = useState<any>(null);
    const [keepCurrentUserAsMember, setKeepCurrentUserAsMember] = useState(
        false,
    );
    const [loading, setLoading] = useState<boolean>(false);

    const [requiredDetected, setRequiredDetected] = useState(false);
    const inputHelps: any = requiredDetected
        ? {
              validateStatus: 'error',
              help: '新所有人必填',
          }
        : {};

    if (mSeletedData === null || mSeletedData.length === 0) {
        return null;
    }
    const visible = customerList.actionType === 'm-transform';

    const { defaultMeta: listMeta } = customerList;
    const pageSize = 100;

    // userInfo

    // 把owner_id的constraint抽出来
    const ownerCol = _.find(
        listMeta.objectMeta,
        item => item.fieldName === 'owner_id',
    );
    if (!ownerCol) {
        return <span>owner_id, meta info not found</span>;
    }
    const constraint = ownerCol.constraint;

    if (!constraint) {
        return <span>owner_id, constraint info not found</span>;
    }

    return (
        <Modal
            visible={visible}
            title={'转移'}
            onCancel={() => {
                customerList.resetAction();
                setUser(null);
            }}
            confirmLoading={loading}
            onOk={async () => {
                if (user === null) {
                    return setRequiredDetected(true);
                }
                setLoading(true);
                const ok = await customerList.mTransform(
                    user,
                    keepCurrentUserAsMember,
                );
                setLoading(false);
                if (ok) {
                    // 转移领取客户
                    voyagerTrack('crm_sale_source_transfer', {
                        transfer: true,
                        userId: authStore?.userInfo?.userId,
                        appEnv: process.env.REACT_APP_ENV,
                    });
                    setUser(null);
                    runInAction(() => {
                        customerList.resetAction();
                        defaultMSelect.removeAllSelectedData();
                        tryRefresh(['customer']);
                        refreshFunc && refreshFunc();
                    });
                }
            }}
        >
            <h4>
                转移选择的{mSeletedData.length}个客户到新所有人。
                <MOpNameDisplayContainer
                    defaultStore={customerList}
                    nameParam="customer_name"
                />
                <MOpResultDisplayContainer defaultStore={customerList} />
            </h4>
            <div className="customer-m-transform">
                <div className="form-wrapper">
                    <Form className="form" layout={'horizontal'}>
                        <Form.Item
                            {...inputHelps}
                            {...formItemLayout}
                            required
                            label={<span className="label">新所有人</span>}
                        >
                            <WidgetCommonObject
                                k={'owner_id'}
                                value={user}
                                onChange={val => {
                                    setUser(val);
                                    setRequiredDetected(false);
                                }}
                                options={{
                                    placeholder: '请选择新所有人',
                                    constraint,
                                    listMeta,
                                    pageSize,
                                    dataAuthCode: 'SEARCH-TRANSFER',
                                    entityCode: 'customer',
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={<span />}
                            colon={false}
                        >
                            <Checkbox
                                onChange={e => {
                                    const v = e?.target?.checked;
                                    if (_.isBoolean(v)) {
                                        setKeepCurrentUserAsMember(v);
                                    }
                                }}
                                checked={keepCurrentUserAsMember}
                            >
                                保留当前【负责人】为团队成员
                            </Checkbox>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    );
});
