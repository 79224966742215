/**
 * 设置中心-简单的单选列表
 */
import React, { useState, useEffect, ReactElement } from 'react';
import { IActionOption } from '../search-tree/index';
import _ from 'lodash';
import { ReactNode } from 'react';
import { Popconfirm } from 'antd';
import './index.scss';

export interface ISettingMenuItemsData {
    title?: ReactNode | string;
    uniqueKey?: string; // 唯一的key，并且对应的也是selectedKey
    [key: string]: any;
}
export interface ISettingMenuProps {
    menuItemsData: ISettingMenuItemsData[];
    actionOptions?: IActionOption[];
    onAction?: (actionKey: string, data: ISettingMenuItemsData) => void;
    onSelect?: (selectKey: string, data: ISettingMenuItemsData) => void;
    selectedKey?: string; // 该值设置后，被选中的key受控
    uniqueKeyIndex?: string; // 指定数据中哪个值做为唯一key
    titleIndex?: string; // 应当展示哪个值做为title，当设置了renderTitle的时候该值无效
    renderTitle?: (
        data: ISettingMenuItemsData,
        idx: number,
    ) => ReactNode | string; // 外部指定title渲染方式
    defaultSelectedKey?: string; // 该值在selectedKey设置后无效
}
const SettingMenu: React.FC<ISettingMenuProps> = props => {
    const {
        menuItemsData = [],
        actionOptions,
        onAction = (actionKey: string, data: any) => {},
        onSelect = () => {},
        selectedKey,
        defaultSelectedKey,
        titleIndex,
        renderTitle,
        uniqueKeyIndex,
    } = props;
    const [selectedKeyInner, setSelectedKeyInner] = useState<string>();
    const [hoverKey, setHoverKey] = useState<string>(''); // hover标记
    const isControl = !_.isNil(selectedKey); // 是否受控
    const keyOrigin = isControl ? selectedKey : selectedKeyInner; // 实际操作的key
    const setKeyOrigin = isControl ? () => {} : setSelectedKeyInner; // 实际修改key的方式
    useEffect(() => {
        if (!isControl) {
            setSelectedKeyInner(defaultSelectedKey);
        }
    }, []);
    const getUniqueKey = (item: ISettingMenuItemsData) => {
        if (item.uniqueKey) {
            return item.uniqueKey;
        } else {
            if (uniqueKeyIndex && !_.isNil(item[uniqueKeyIndex])) {
                return item[uniqueKeyIndex];
            } else {
                throw new Error(
                    '请指定每个menuitem的uniqueKey或者设置正确的uniqueKeyIndex',
                );
            }
        }
    };
    const getTitle: (item: ISettingMenuItemsData, idx: number) => any = (
        item,
        idx,
    ) => {
        if (item.title) {
            if (typeof item.title === 'string') {
                return (
                    <span className="setting-menu-title-text">
                        {item.title}
                    </span>
                );
            } else {
                return item.title;
            }
        } else {
            if (renderTitle && typeof renderTitle === 'function') {
                return renderTitle(item, idx);
            } else if (titleIndex && !_.isNil(item[titleIndex])) {
                return item[titleIndex];
            } else {
                throw new Error(
                    '请指定每个menuitem的title或者设置正确的titleIndex抑或设置renderTitle',
                );
            }
        }
    };
    // 标题渲染
    const renderTitleElement = (
        title: ReactNode,
        item: ISettingMenuItemsData,
        idx: number,
    ) => {
        const uniqueKey = getUniqueKey(item);
        const isHover = hoverKey === uniqueKey;
        const isSelect = keyOrigin === uniqueKey;
        return (
            <div
                className={`crm-setting-menu-node 
                    ${isHover ? 'hover' : ''}
                    ${isSelect ? 'selected' : ''}`}
                onMouseEnter={() => {
                    setHoverKey(uniqueKey as string);
                }}
                onMouseLeave={() => {
                    setHoverKey('');
                }}
                onClick={() => {
                    onSelect(uniqueKey, item);
                    setKeyOrigin(uniqueKey);
                }}
            >
                <div className={`crm-setting-menu-title`}>{title}</div>
                <div className="crm-setting-menu-handle">
                    {_.map(actionOptions, action => {
                        const isPopConfirm = !!action.popconfirm;
                        const {
                            popcomfirmConfig = { title: '' },
                            actionKey,
                            autoHidden = true,
                            status,
                        } = action;
                        const isOuterHidden = () => {
                            const hidden = status?.hidden;
                            if (typeof hidden === 'function') {
                                return hidden(item);
                            } else {
                                return !!hidden;
                            }
                        };
                        const isOuterDisabled = (() => {
                            const disabled = status?.disabled;
                            if (typeof disabled === 'function') {
                                return disabled(item);
                            } else {
                                return !!disabled;
                            }
                        })();

                        if (isOuterHidden()) {
                            return null;
                        }
                        const titleEle = isPopConfirm ? (
                            <Popconfirm
                                disabled={isOuterDisabled}
                                {...popcomfirmConfig}
                                onConfirm={e => {
                                    if (popcomfirmConfig.onConfirm) {
                                        popcomfirmConfig.onConfirm(e);
                                    }
                                    onAction(actionKey, item);
                                }}
                            >
                                <span
                                    className={`crm-setting-menu-action ${
                                        isOuterDisabled ? 'disabled' : ''
                                    }`}
                                >
                                    {action.actionCn}
                                </span>
                            </Popconfirm>
                        ) : (
                            <span
                                onClick={e => {
                                    e.stopPropagation();
                                    // e.nativeEvent.stopPropagation();
                                    if (!isOuterDisabled) {
                                        onAction(actionKey, item);
                                    }
                                }}
                                className={`crm-setting-menu-action ${
                                    isOuterDisabled ? 'disabled' : ''
                                }`}
                            >
                                {action.actionCn}
                            </span>
                        );
                        return autoHidden
                            ? isHover
                                ? titleEle
                                : null
                            : titleEle;
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className="crm-setting-menu-content">
            {_.map(menuItemsData, (item, idx) => {
                const title = getTitle(item, idx);
                return renderTitleElement(title, item, idx);
            })}
        </div>
    );
};

export default SettingMenu;
