import React from 'react';
import { DatePicker } from 'antd';
import { getCommonStatus, ICommonProps } from './common';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import moment from 'moment';
import _ from 'lodash';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export type DateValueType = number | null;
export interface IDateOptions extends DatePickerProps {}

export interface IWidgetDateProps<T = any> extends ICommonProps<T> {
    value: DateValueType;
    options: IDateOptions;
    onChange: (value: DateValueType) => void;
}

export const WidgetDate: React.FC<IWidgetDateProps> = ({
    value,
    options,
    onChange,
    onInteract = key => void 0,
    status,
    data,
    statusExtraData,
}) => {
    const { disabled } = getCommonStatus(status, data, statusExtraData);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }
    // -1 是一个陈旧的标准
    const v = value === -1 || !value ? undefined : moment(value);
    return (
        <DatePicker
            value={v}
            onBlur={() => {
                onInteract('blur');
                onInteract('validate-instantly');
            }}
            onFocus={() => {
                onInteract('focus');
                onInteract('remove-validate-status');
            }}
            onChange={date => {
                if (date === null) {
                    return onChange(null);
                }
                onChange(date.valueOf());
            }}
            {...options}
        />
    );
};
