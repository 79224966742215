import { makeAutoObservable, toJS } from 'mobx';
import _ from 'lodash';
import { SorterStore, emptySorterStore } from './sorter';
import { ListMeta } from './list-meta';
import moment from 'moment';
import { useQueryAsObject, getQueryAsObject } from '@/utils';
import * as Cookie from 'tiny-cookie';
const genMomentFilterKey = (
    genKey: string,
    momentKey: string,
    filterObj: any,
) => {
    const finalReturnObj: any = {};
    const momentObj: any = {
        year: {
            [momentKey]: {
                value: [
                    moment()
                        .startOf('year')
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .endOf('year')
                        .endOf('day')
                        .valueOf(),
                ],
                emptyValue: [undefined, undefined],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterDateRange',
                ts: 0,
            },
        },
        month: {
            [momentKey]: {
                value: [
                    moment()
                        .startOf('month')
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .endOf('month')
                        .endOf('day')
                        .valueOf(),
                ],
                emptyValue: [undefined, undefined],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterDateRange',
                ts: 0,
            },
        },
        session: {
            [momentKey]: {
                value: [
                    moment()
                    .startOf('month').subtract(1,'month')
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .endOf('month')
                        .endOf('day')
                        .valueOf(),
                ],
                emptyValue: [undefined, undefined],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterDateRange',
                ts: 0,
            },
        },
        quarter: {
            [momentKey]: {
                value: [
                    moment()
                        .startOf('quarter')
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .endOf('quarter')
                        .endOf('day')
                        .valueOf(),
                ],
                emptyValue: [undefined, undefined],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterDateRange',
                ts: 0,
            },
        },
        week: {
            [momentKey]: {
                value: [
                    moment()
                        .startOf('weeks')
                        .add(1, 'day')
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .endOf('weeks')
                        .add(1, 'day')
                        .endOf('day')
                        .valueOf(),
                ],
                emptyValue: [undefined, undefined],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterDateRange',
                ts: 0,
            },
        },
    };
    finalReturnObj[`${genKey}_1`] = {
        ...filterObj,
        ...momentObj.year,
    };
    finalReturnObj[`${genKey}_2`] = {
        ...filterObj,
        ...momentObj.quarter,
    };
    finalReturnObj[`${genKey}_3`] = {
        ...filterObj,
        ...momentObj.month,
    };
    finalReturnObj[`${genKey}_4`] = {
        ...filterObj,
        ...momentObj.week,
    };
    finalReturnObj[`${genKey}_5`] = {
        ...filterObj,
        ...momentObj.session,
    };
    return finalReturnObj;
};
const genOptFilterKey = (key: string, filterObj: any) => {
    const finalReturnObj: any = {};
    const optMomentObj = {
        year: {
            prediction_pay_back_time: {
                value: [
                    moment()
                        .startOf('year')
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .endOf('year')
                        .endOf('day')
                        .valueOf(),
                ],
                emptyValue: [undefined, undefined],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterDateRange',
                ts: 0,
            },
        },
        month: {
            prediction_pay_back_time: {
                value: [
                    moment()
                        .startOf('month')
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .endOf('month')
                        .endOf('day')
                        .valueOf(),
                ],
                emptyValue: [undefined, undefined],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterDateRange',
                ts: 0,
            },
        },
        session: {
            prediction_pay_back_time: {
                value: [
                    moment()
                        .startOf('month').subtract(1,'month')
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .endOf('month')
                        .endOf('day')
                        .valueOf(),
                ],
                emptyValue: [undefined, undefined],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterDateRange',
                ts: 0,
            },
        },
        quarter: {
            prediction_pay_back_time: {
                value: [
                    moment()
                        .startOf('quarter')
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .endOf('quarter')
                        .endOf('day')
                        .valueOf(),
                ],
                emptyValue: [undefined, undefined],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterDateRange',
                ts: 0,
            },
        },
    };
    finalReturnObj[`${key}_1`] = genFFKeyFKBy(`${key}_1`, 'customer_owner', {
        ...filterObj,
        ...optMomentObj.year,
    });
    finalReturnObj[`${key}_3`] = genFFKeyFKBy(`${key}_3`, 'customer_owner', {
        ...filterObj,
        ...optMomentObj.month,
    });
    finalReturnObj[`${key}_2`] = genFFKeyFKBy(`${key}_2`, 'customer_owner', {
        ...filterObj,
        ...optMomentObj.quarter,
    });
    finalReturnObj[`${key}_5`] = genFFKeyFKBy(`${key}_5`, 'customer_owner', {
        ...filterObj,
        ...optMomentObj.session,
    });
    return finalReturnObj;
};
const genFFKeyFKBy = (ffKey: string, userKey: string, extObj?: any) => {
    const userObj = {
        [userKey]: {
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: false,
            ts: 0,
            value: JSON.parse(localStorage.getItem(ffKey) || '[]') || [],
            widgetKey: 'sysUserSelector',
        },
    };
    if (!extObj) {
        return userObj;
    } else {
        return {
            ...extObj,
            ...userObj,
        };
    }
};
const receiveUrlKeyToFilter = (key: string) => {
    const [create_user, name, type] = key.split('-');
    // well 为好评 badly为差评
    const is_well_received = type === 'well' ? 1 : 2;
    return {
        create_user: {
            value: [
                {
                    id: create_user,
                    key: 'user-' + create_user,
                    name: name,
                    title: name,
                },
            ],
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: undefined,
            widgetKey: 'sysUserSelector',
        },
        is_well_received: {
            value: [is_well_received],
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
            widgetKey: 'filterEnum',
        },
        thumbs_time: {
            value: [
                moment()
                    .startOf('day')
                    .valueOf(),
                moment()
                    .endOf('day')
                    .valueOf(),
            ],
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [undefined, undefined],
            widgetKey: 'filterDateRange',
        },
    };
};

const noOpportunityWithin7dayKeyToFilter = (key: string) => {
    const [, id, name] = key.split('-');

    return {
        customer_owner: {
            value: [
                {
                    id,
                    key: 'user-' + id,
                    name: name,
                    title: name,
                },
            ],
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: undefined,
            widgetKey: 'sysUserSelector',
        },
    };
};

const urlKeyToFilter: {
    [key: string]: any;
} = {
    'lead-web-callin': {
        lead_source: {
            value: ['15', '7'],
            widgetKey: 'filterEnum',
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
        },
        follow_status: {
            value: ['1'],
            widgetKey: 'filterEnum',
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
        },
        ...genFFKeyFKBy('lead-web-callin', 'customer_owner'),
    },
    '1days-gaoqian': {
        high_potential_create_time: {
            value: [
                moment()
                    .subtract(1, 'days')
                    .startOf('day')
                    .valueOf(),
                undefined,
            ],
            widgetKey: 'filterDateRange',
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [undefined, undefined],
        },
    },
    mine_cooperation_customer: genFFKeyFKBy(
        'mine_cooperation_customer',
        'owner_id',
        {
            cooperation_status: {
                value: ['2'],
                widgetKey: 'filterEnum',
                ts: 0,
                isEmpty: false,
                isNotEmpty: false,
                emptyValue: [],
            },
        },
    ),
    'today-experted-customer-fk': genFFKeyFKBy(
        'today-experted-customer-fk',
        'owner_id',
    ),
    'toweek-experted-customer-fk': genFFKeyFKBy(
        'toweek-experted-customer-fk',
        'owner_id',
        {
            expire_time: {
                value: [
                    undefined,
                    moment()
                        .add('7', 'days')
                        .endOf('day')
                        .valueOf(),
                ],
                emptyValue: [undefined, undefined],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterDateRange',
                ts: 0,
            },
        },
    ),
    '3days-gaoqian-fk': genFFKeyFKBy('3days-gaoqian-fk', 'owner_id'),
    'job-ing-fk': genFFKeyFKBy('job-ing-fk', 'executor'),
    'job-expire-today-fk': genFFKeyFKBy('job-expire-today-fk', 'executor'),
    'job-expire-thisweek-fk': genFFKeyFKBy(
        'job-expire-thisweek-fk',
        'executor',
    ),
    'job-expired-fk': genFFKeyFKBy('job-expired-fk', 'executor'),
    '7_recent_activity_record_time_fk': genFFKeyFKBy(
        '7_recent_activity_record_time_fk',
        'owner_id',
    ),
    '15_recent_activity_record_time_fk': genFFKeyFKBy(
        '15_recent_activity_record_time_fk',
        'owner_id',
    ),
    '30_recent_activity_record_time_fk': genFFKeyFKBy(
        '30_recent_activity_record_time_fk',
        'owner_id',
        {
            cooperation_status: {
                value: ['2'],
                widgetKey: 'filterEnum',
                ts: 0,
                isEmpty: false,
                isNotEmpty: false,
                emptyValue: [],
            },
        },
    ),
    'undeal-lead-fk': genFFKeyFKBy('undeal-lead-fk', 'customer_owner'),
    'yy-fk': genFFKeyFKBy('yy-fk', 'customer_owner'),
    'stage40-pending': genFFKeyFKBy('stage40-pending', 'customer_owner', {
        stage40_state: {
            value: [0],
            widgetKey: 'filterEnum',
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
        },
        win_rate: {
            value: [40, 40],
            emptyValue: [null, null],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterNumberRange',
            ts: 0,
        },
    }),
    kaitongweihuikuan_fk: genFFKeyFKBy('kaitongweihuikuan_fk', 'owner_id'),
    huikuanweikaitong_fk: genFFKeyFKBy('huikuanweikaitong_fk', 'owner_id'),
    yikaipiaoweihuikuan_fk: genFFKeyFKBy('yikaipiaoweihuikuan_fk', 'owner_id'),
    expired_no_renew_customer: genFFKeyFKBy(
        'expired_no_renew_customer',
        'owner_id',
        {
            cooperation_status: {
                value: ['4'],
                widgetKey: 'filterEnum',
                ts: 0,
                isEmpty: false,
                isNotEmpty: false,
                emptyValue: [],
            },
        },
    ),
    published_position: genFFKeyFKBy('published_position', 'owner_id', {
        published_position: {
            value: ['0'],
            widgetKey: 'filterEnum',
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
        },
    }),
    active_half_recruitment_count: genFFKeyFKBy(
        'active_half_recruitment_count',
        'owner_id',
        {
            active_half_recruitment_count: {
                value: ['0'],
                widgetKey: 'filterEnum',
                ts: 0,
                isEmpty: false,
                isNotEmpty: false,
                emptyValue: [],
            },
        },
    ),
    less_30_talent_expired_customer: genFFKeyFKBy(
        'less_30_talent_expired_customer',
        'owner_id',
        {
            talent_bank_nearest_expire_time: {
                value: [
                    moment()
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .add(30, 'days')
                        .endOf('day')
                        .valueOf(),
                ],
                widgetKey: 'filterDateRange',
                ts: 0,
                isEmpty: false,
                isNotEmpty: false,
                emptyValue: [undefined, undefined],
            },
        },
    ),
    less_30_enterprise_expired_customer: genFFKeyFKBy(
        'less_30_enterprise_expired_customer',
        'owner_id',
        {
            enterprise_expire_time: {
                value: [
                    moment()
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .add(30, 'days')
                        .endOf('day')
                        .valueOf(),
                ],
                widgetKey: 'filterDateRange',
                ts: 0,
                isEmpty: false,
                isNotEmpty: false,
                emptyValue: [undefined, undefined],
            },
        },
    ),
    less_90_talent_expired_customer: genFFKeyFKBy(
        'less_90_talent_expired_customer',
        'owner_id',
        {
            talent_bank_nearest_expire_time: {
                value: [
                    moment()
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .add(90, 'days')
                        .endOf('day')
                        .valueOf(),
                ],
                widgetKey: 'filterDateRange',
                ts: 0,
                isEmpty: false,
                isNotEmpty: false,
                emptyValue: [undefined, undefined],
            },
        },
    ),
    less_90_enterprise_expired_customer: genFFKeyFKBy(
        'less_90_enterprise_expired_customer',
        'owner_id',
        {
            enterprise_expire_time: {
                value: [
                    moment()
                        .startOf('day')
                        .valueOf(),
                    moment()
                        .add(90, 'days')
                        .endOf('day')
                        .valueOf(),
                ],
                widgetKey: 'filterDateRange',
                ts: 0,
                isEmpty: false,
                isNotEmpty: false,
                emptyValue: [undefined, undefined],
            },
        },
    ),
    ...genOptFilterKey('mine_608090_opt', {
        win_rate: {
            value: [60, 90],
            emptyValue: [null, null],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterNumberRange',
            ts: 0,
        },
    }),
    ...genOptFilterKey('mine_amount_opt', {
        win_rate: {
            value: [20, 90],
            emptyValue: [null, null],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterNumberRange',
            ts: 0,
        },
    }),
    ...genOptFilterKey('mine_90_opt', {
        win_rate: {
            value: [90, 90],
            emptyValue: [null, null],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterNumberRange',
            ts: 0,
        },
    }),
    ...genOptFilterKey('mine_80_opt', {
        win_rate: {
            value: [80, 80],
            emptyValue: [null, null],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterNumberRange',
            ts: 0,
        },
    }),
    ...genOptFilterKey('mine_60_opt', {
        win_rate: {
            value: [60, 60],
            emptyValue: [null, null],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterNumberRange',
            ts: 0,
        },
    }),
    ...genOptFilterKey('mine_40_opt', {
        win_rate: {
            value: [40, 40],
            emptyValue: [null, null],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterNumberRange',
            ts: 0,
        },
    }),
    ...genOptFilterKey('mine_20_opt', {
        win_rate: {
            value: [20, 20],
            emptyValue: [null, null],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterNumberRange',
            ts: 0,
        },
    }),
    mine_week_increase_40_opt: genFFKeyFKBy(
        'mine_week_increase_40_opt',
        'owner_id',
        {
            create_time: {
                value: [
                    moment()
                        .startOf('week')
                        .startOf('day')
                        .valueOf(),
                    undefined,
                ],
                emptyValue: [undefined, undefined],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterDateRange',
                ts: 0,
            },
            win_rate: {
                value: [40, 40],
                emptyValue: [null, null],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterNumberRange',
                ts: 0,
            },
        },
    ),
    mine_week_increase_20_opt: genFFKeyFKBy(
        'mine_week_increase_20_opt',
        'owner_id',
        {
            create_time: {
                value: [
                    moment()
                        .startOf('week')
                        .startOf('day')
                        .valueOf(),
                    undefined,
                ],
                emptyValue: [undefined, undefined],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterDateRange',
                ts: 0,
            },
            win_rate: {
                value: [20, 20],
                emptyValue: [null, null],
                isEmpty: false,
                isNotEmpty: false,
                widgetKey: 'filterNumberRange',
                ts: 0,
            },
        },
    ),
    today_visited_customer_record: {
        create_time: {
            value: [
                moment()
                    .startOf('day')
                    .valueOf(),
                ,
                moment()
                    .endOf('day')
                    .valueOf(),
            ],
            emptyValue: [undefined, undefined],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterDateRange',
            ts: 0,
        },
        follow_type: {
            value: ['20'],
            widgetKey: 'filterEnum',
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
        },
        owner_id: {
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: false,
            ts: 0,
            value:
                JSON.parse(
                    localStorage.getItem('today_visited_customer_record') ||
                    '[]',
                ) || [],
            widgetKey: 'sysUserSelector',
        },
    },
    call_in_record: {
        // 外呼接通记录+外呼总时长
        create_time: {
            value: [
                moment()
                    .startOf('day')
                    .valueOf(),
                moment()
                    .endOf('day')
                    .valueOf(),
            ],
            emptyValue: [undefined, undefined],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterDateRange',
            ts: 0,
        },
        follow_type: {
            value: ['10'],
            widgetKey: 'filterEnum',
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
        },
        owner_id: {
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: false,
            ts: 0,
            value:
                JSON.parse(localStorage.getItem('call_in_record') || '[]') ||
                [],
            widgetKey: 'sysUserSelector',
        },
        call_is_connect: {
            value: ['1'],
            widgetKey: 'filterEnum',
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
        },
    },
    today_fetch_customer: {
        owner_id: {
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: false,
            ts: 0,
            value:
                JSON.parse(
                    localStorage.getItem('today_fetch_customer') || '[]',
                ) || [],
            widgetKey: 'sysUserSelector',
        },
        claim_time: {
            value: [
                moment()
                    .startOf('day')
                    .valueOf(),
                moment()
                    .endOf('day')
                    .valueOf(),
            ],
            emptyValue: [undefined, undefined],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterDateRange',
            ts: 0,
        },
    },
    today_create_customer: {
        create_user: {
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: false,
            ts: 0,
            value:
                JSON.parse(
                    localStorage.getItem('today_create_customer') || '[]',
                ) || [],
            widgetKey: 'sysUserSelector',
        },
        create_time: {
            value: [
                moment()
                    .startOf('day')
                    .valueOf(),
                moment()
                    .endOf('day')
                    .valueOf(),
            ],
            emptyValue: [undefined, undefined],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterDateRange',
            ts: 0,
        },
    },
    today_create_opt: {
        create_user: {
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: false,
            ts: 0,
            value:
                JSON.parse(localStorage.getItem('today_create_opt') || '[]') ||
                [],
            widgetKey: 'sysUserSelector',
        },
        create_time: {
            value: [
                moment()
                    .startOf('day')
                    .valueOf(),
                moment()
                    .endOf('day')
                    .valueOf(),
            ],
            emptyValue: [undefined, undefined],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterDateRange',
            ts: 0,
        },
    },
    ...genMomentFilterKey('new_sign_task_on_time', 'end_time', {
        follow_target: {
            widgetKey: 'filterEnum',
            value: ['10', '9'],
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
        },
        executor: {
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: false,
            ts: 0,
            value:
                JSON.parse(
                    localStorage.getItem('new_sign_task_on_time') || '[]',
                ) || [],
            widgetKey: 'sysUserSelector',
        },
        create_user: {
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: false,
            ts: 0,
            value: [
                {
                    id: 10000,
                    key: `user-10000`,
                    name: '作业管理员',
                    title: '作业管理员',
                },
            ],
            widgetKey: 'sysUserSelector',
        },
        task_state: {
            value: ['5', '6', '7', '3', '2', '1'],
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
            widgetKey: 'filterEnum',
        },
    }),
    ...genMomentFilterKey('new_open_training_task', 'end_time', {
        executor: {
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: false,
            ts: 0,
            value:
                JSON.parse(
                    localStorage.getItem('new_open_training_task') || '[]',
                ) || [],
            widgetKey: 'sysUserSelector',
        },
        create_user: {
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: false,
            ts: 0,
            value: [
                {
                    id: 10000,
                    key: `user-10000`,
                    name: '作业管理员',
                    title: '作业管理员',
                },
            ],
            widgetKey: 'sysUserSelector',
        },
        task_state: {
            value: ['5', '6', '7', '3', '2', '1'],
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
            widgetKey: 'filterEnum',
        },
        follow_target: {
            widgetKey: 'filterEnum',
            value: ['5'],
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
        },
    }),
    ...genMomentFilterKey('renew_321_task', 'end_time', {
        executor: {
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: false,
            ts: 0,
            value:
                JSON.parse(localStorage.getItem('renew_321_task') || '[]') ||
                [],
            widgetKey: 'sysUserSelector',
        },
        create_user: {
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: false,
            ts: 0,
            value: [
                {
                    id: 10000,
                    key: `user-10000`,
                    name: '作业管理员',
                    title: '作业管理员',
                },
            ],
            widgetKey: 'sysUserSelector',
        },
        task_state: {
            value: ['5', '6', '7', '3', '2', '1'],
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
            widgetKey: 'filterEnum',
        },
        follow_target: {
            widgetKey: 'filterEnum',
            value: ['8'],
            ts: 0,
            isEmpty: false,
            isNotEmpty: false,
            emptyValue: [],
        },
    }),
    // call_valid_record: {
    //     create_time: {
    //         value: [
    //             moment()
    //                 .startOf('day')
    //                 .valueOf(),
    //             undefined,
    //         ],
    //         emptyValue: [undefined, undefined],
    //         isEmpty: false,
    //         isNotEmpty: false,
    //         widgetKey: 'filterDateRange',
    //         ts: 0,
    //     },
    //     follow_type: {
    //         value: ['10'],
    //         widgetKey: 'filterEnum',
    //         ts: 0,
    //         isEmpty: false,
    //         isNotEmpty: false,
    //         emptyValue: [],
    //     },
    //     owner_id: {
    //         emptyValue: undefined,
    //         isEmpty: false,
    //         isNotEmpty: false,
    //         ts: 0,
    //         value: [
    //             {
    //                 id: Cookie.get('crmuid'),
    //                 key: `user-${Cookie.get('crmuid')}`,
    //                 name: Cookie.get('crmuname'),
    //                 title: Cookie.get('crmuname'),
    //             },
    //         ],
    //         widgetKey: 'sysUserSelector',
    //     },
    //     call_is_connect: {
    //         value: ['1'],
    //         widgetKey: 'filterEnum',
    //         ts: 0,
    //         isEmpty: false,
    //         isNotEmpty: false,
    //         emptyValue: [],
    //     },
    //     call_time_length: {

    //     }
    // }
};

const optUrlKeyToFilter = (key: string) => {
    //'opt-20-range(day week month quarter)-region(华北 or 上海)-group(上海KA1组 or all)-78(id)-wmx(名字)';
    // ?filterKey=opt-0(0 1 2)-40-today-华北-all-all
    const [
        ,
        type,
        rate,
        range,
        region,
        city,
        group,
        customer_owner,
        name,
    ] = key.split('-');

    const optMomentObj: any = {
        year: {
            value: [
                moment()
                    .startOf('year')
                    .startOf('day')
                    .valueOf(),
                moment()
                    .endOf('year')
                    .endOf('day')
                    .valueOf(),
            ],
            emptyValue: [undefined, undefined],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterDateRange',
            ts: 0,
        },
        month: {
            value: [
                moment()
                    .startOf('month')
                    .startOf('day')
                    .valueOf(),
                moment()
                    .endOf('month')
                    .endOf('day')
                    .valueOf(),
            ],
            emptyValue: [undefined, undefined],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterDateRange',
            ts: 0,
        },
        session: {
            value: [
                moment()
                .startOf('month').subtract(1,'month')
                    .startOf('day')
                    .valueOf(),
                moment()
                    .endOf('month')
                    .endOf('day')
                    .valueOf(),
            ],
            emptyValue: [undefined, undefined],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterDateRange',
            ts: 0,
        },
        quarter: {
            value: [
                moment()
                    .startOf('quarter')
                    .startOf('day')
                    .valueOf(),
                moment()
                    .endOf('quarter')
                    .endOf('day')
                    .valueOf(),
            ],
            emptyValue: [undefined, undefined],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterDateRange',
            ts: 0,
        },
        week: {
            value: [
                moment()
                    .startOf('week')
                    .startOf('day')
                    .valueOf(),
                moment()
                    .endOf('week')
                    .endOf('day')
                    .valueOf(),
            ],
            emptyValue: [undefined, undefined],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterDateRange',
            ts: 0,
        },
        today: {
            value: [
                moment()
                    // .startOf('week')
                    .startOf('day')
                    .valueOf(),
                moment()
                    // .endOf('week')
                    .endOf('day')
                    .valueOf(),
            ],
            emptyValue: [undefined, undefined],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterDateRange',
            ts: 0,
        },
    };
    const typeToValueMap = new Map([
        ['1', '1'],
        ['2', '0'],
    ]);
    const optObj: any = {
        // 赢率分布
        // win_rate: {
        //     value: [Number(rate), Number(rate)],
        //     emptyValue: [null, null],
        //     isEmpty: false,
        //     isNotEmpty: false,
        //     widgetKey: 'filterNumberRange',
        //     ts: 0,
        // },
        customer_owner: {
            value:
                customer_owner === 'all'
                    ? undefined
                    : [
                        {
                            id: customer_owner,
                            key: 'user-' + customer_owner,
                            name: name,
                            title: name,
                        },
                    ],
            ts: 0,
            isEmpty: false,
            isNotEmpty: customer_owner === 'all' ? true : false,
            emptyValue: undefined,
            widgetKey: 'sysUserSelector',
        },
        // 业务类型
        biz_type: {
            value: ['1'],
            emptyValue: [],
            isEmpty: false,
            isNotEmpty: false,
            widgetKey: 'filterEnum',
            ts: 0,
        },
        // 时间区间
        [rate === '40'
            ? `first${rate}_stage_sure_update_time`
            : `first${rate}_stage_update_time`]: optMomentObj[range],
        // 区域
        owner_area: {
            value: region === 'all' || region === '全国' ? undefined : region,
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: region === 'all' || region === '全国' ? true : false,
            widgetKey: 'input',
            ts: 0,
        },
        // 城市
        owner_city: {
            value: city === 'all' ? undefined : city,
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: city === 'all' ? true : false,
            widgetKey: 'input',
            ts: 0,
        },
        // 小组
        owner_group: {
            value: group === 'all' ? undefined : group,
            emptyValue: undefined,
            isEmpty: false,
            isNotEmpty: group === 'all' ? true : false,
            widgetKey: 'input',
            ts: 0,
        },
        // 新老客户
        customer_type: {
            // 测试：新为1，老为2，新老一起选为 ["1","2"],全部 空为 []
            // 生产：新为1，老为0，新老一起选为 ["1","0"],全部 空为 []
            value: type === '0' ? [] : [typeToValueMap.get(type)],
            emptyValue: [],
            isEmpty: false,
            isNotEmpty: type === '0' ? true : false,
            widgetKey: 'filterEnum',
            ts: 0,
        },
    };
    // console.log('group', customer_owner === 'all');
    return optObj;
};

export const setFilterByUrlKey = (fStore: FilterStore) => {
    const queryObject = getQueryAsObject();
    if (!_.isString(queryObject.filterKey)) {
        return null;
    }
    if (
        /^(opt)-(\d)-(\d+)-([a-z]+)-(.+)-(.+)-(.+)-(.+)-(.+)$/.test(
            queryObject.filterKey,
        )
    ) {
        // filterKey=opt-type-rate-range-region-city-group
        // console.log('opt enter');
        fStore.assignFilterData(optUrlKeyToFilter(queryObject.filterKey));
        fStore.confirmFilterData();
    } else if (
        /^(\d+)-(.+)-(well|badly)-(received)$/.test(queryObject.filterKey)
    ) {
        fStore.assignFilterData(receiveUrlKeyToFilter(queryObject.filterKey));
        fStore.confirmFilterData();
    } else if (/^(opt)-(.+)-(.+)$/.test(queryObject.filterKey)) {
        fStore.assignFilterData(
            noOpportunityWithin7dayKeyToFilter(queryObject.filterKey),
        );
        fStore.confirmFilterData();
    } else {
        if (!urlKeyToFilter?.[queryObject.filterKey]) {
            return null;
        }
        fStore.assignFilterData(urlKeyToFilter?.[queryObject.filterKey]);
        fStore.confirmFilterData();
    }
};
export class FilterStore {
    // 筛选
    public filterData: any = null;
    public setFilterData(nextFilterData: any) {
        this.filterData = nextFilterData;
    }

    public mergeFilterDataByKey(key: string, value: any) {
        this.filterData[key] = value;
    }

    public assignFilterData(addFilterData: any) {
        console.log('testtest', addFilterData);
        const nextFilterData = { ...this.filterData };
        this.filterData = _.assign(nextFilterData, addFilterData);
    }

    public onFilterCancel(key: string) {
        this.filterData = _.cloneDeep(this.confirmedfilterData);
    }

    public onFilterClear(key: string) {
        if (!this.defaultSorter || !this.defaultMeta) {
            return;
        }
        if (key === '____sorter') {
            this.defaultSorter.clearSorter();
            return;
        }
        const nextFilterData = _.cloneDeep(this.confirmedfilterData);
        // TODO:有可能有bug
        nextFilterData[
            key
        ] = this.defaultMeta.defaultAdvancedFilterWidgetValues[key];
        this.confirmedfilterData = _.cloneDeep(nextFilterData);
        this.filterData = _.cloneDeep(nextFilterData);
    }

    public onFilterConfirmed(key: string) {
        const nextConfirmedFilterData = _.cloneDeep(this.confirmedfilterData);
        nextConfirmedFilterData[key] = this.filterData[key];
        nextConfirmedFilterData[key].ts = Date.now();
        console.log(
            'testtest',
            toJS(nextConfirmedFilterData?.cooperation_status),
        );
        this.confirmedfilterData = nextConfirmedFilterData;
    }

    // 筛选
    public confirmedfilterData: any = null;

    // 重置筛选项
    public resetFilterData() {
        if (!this.defaultMeta) {
            return;
        }
        const initailFilters = this.defaultMeta
            .defaultAdvancedFilterWidgetValues;
        this.filterData = _.cloneDeep(initailFilters);
    }

    public confirmFilterData = () => {
        if (!this.filterData) {
            return;
        }
        console.log('1234', toJS(this.filterData));
        this.confirmedfilterData = _.cloneDeep(this.filterData);
    };

    public defaultSorter: SorterStore = emptySorterStore;
    public defaultMeta: ListMeta | null = new ListMeta('', '', '');

    constructor(defaultSorter: SorterStore, defaultMeta: ListMeta | null) {
        this.defaultSorter = defaultSorter;
        this.defaultMeta = defaultMeta;
        makeAutoObservable(this, {
            defaultSorter: false,
            defaultMeta: false,
        });
    }
}
