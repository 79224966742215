import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AntButton, BlockLoading2, loadingWrapper } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { AntDrawer } from '@/components/antd/drawer';
import { ProcessCatStore } from '../store';
import DemoForm, { transferSingleParamList } from './form';
import { defaultAxios, isNetSuccess, tryRefresh } from '@/utils';
import { runInAction } from 'mobx';
import { ProcessButtons, TBtnsCusRenders } from './buttons';
import { SubmitActions, ISubmitActionsProps } from './actions';
import { CommentDisplay } from './actions/comments';
import { ContractFormProxy } from './contract-proxy';
import { message, Modal } from 'antd';
import { SubmitActionsOpenProductLastStep } from './open-product-last-step';
import { InvoiceQualificationListStore } from '@/pages/om/contract/contract-invoice-qualification/store';
import { ContractIQForm } from './contract-iq-form';
import { ContractInvoiceForm } from './contract-invoice-form';
import { ProcessComHub } from './processes/registry';

const FormDrawer: React.FC<RouteComponentProps & {
    defaultStore: ProcessCatStore;
    preRequiredReady: boolean;
}> = ({ defaultStore, preRequiredReady }) => {
    const {
        actionType,
        detailTableCode: tableCode,
        seeDetailItem,
        listType,
        submitActionData,
        submitActionType,
    } = defaultStore;

    const visible = actionType === 'see-detail';

    const [
        detailProcessStore,
        setDetailProcessStore,
    ] = useState<ProcessCatStore | null>(null);
    const [ready, setReady] = useState(false);
    const [opLoading, setOpLoading] = useState(false);

    const detailTableId = detailProcessStore?.defaultMeta?.tableId;
    useEffect(() => {
        if (
            !visible ||
            !seeDetailItem ||
            !detailTableId ||
            !detailProcessStore ||
            !defaultStore.processDetailCtx
        ) {
            return;
        }

        // 拿实体信息，方式不同
        // if (listType === 'category') {
        //     detailProcessStore.fetchInstantlyMutatingDataById(seeDetailItem.id);
        // } else {
        // }
        detailProcessStore.fetchInstantlyMutatingDataById(
            seeDetailItem.businessKey,
            defaultStore.processDetailCtx,
        );
    }, [
        visible,
        seeDetailItem,
        detailProcessStore,
        detailTableId,
        defaultStore.processDetailCtx,
    ]);

    let processCtx = null;
    let processAllInfo = null;
    let processBtns: any[] = [];
    let processActivityName = '';
    let processInstanceName = '';
    if (detailProcessStore) {
        processCtx = defaultStore.processDetailCtx;
        processAllInfo = detailProcessStore.defaultMeta?.processAllInfo;
        processBtns = [...(detailProcessStore.defaultMeta?.processBtns || [])];
        processActivityName = '';
        // null === processAllInfo
        //     ? ''
        //     : processAllInfo?.crmActivity?.activityName ||
        //       '未知activityName';
        processInstanceName =
            null === processAllInfo
                ? ''
                : processAllInfo?.crmProcessInstance?.name ||
                  '未知instanceName';
    }

    const resetStates = () => {
        setTimeout(() => {
            defaultStore.setDetailTableCode(null);
            setDetailProcessStore(null);
            setReady(false);
        }, 300);
    };

    useEffect(() => {
        if (_.isNil(detailProcessStore)) {
            return;
        }
        const {
            tableCode,
            defaultMeta,
            defaultPerm,
            defaultFilter,
        } = detailProcessStore;
        if (
            _.isNil(tableCode) ||
            _.isNil(defaultMeta) ||
            _.isNil(defaultPerm)
        ) {
            return;
        }
        defaultMeta.setTableId(tableCode);
        detailProcessStore.setSysUser(defaultStore.sysUser);

        Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
            .then(async () => {
                setReady(true);
                defaultFilter.resetFilterData();
                defaultFilter.confirmFilterData();
            })
            .catch(() => {});
    }, [detailProcessStore, defaultStore.sysUser]);

    // 每次点详情，tableCode可能不同，然后换store
    useEffect(() => {
        if (!tableCode) {
            return;
        }
        setReady(false);
        const nextStore = new ProcessCatStore();
        runInAction(() => {
            if (!tableCode) {
                return;
            }
            nextStore.setFastFilterStore(defaultStore.defaultFastFilters);
            nextStore.setAction('create');
            nextStore.setTableCode(tableCode);
            // TODO 先这样mock detail数据
            nextStore.startNewData();
        });
        setDetailProcessStore(nextStore);
    }, [tableCode]);

    // DIRTY: 开发票申请
    // const [
    //     iqStore,
    //     setIqStore,
    // ] = useState<InvoiceQualificationListStore | null>(null);

    const [openProductSaveLoading, setOpenProductSaveLoading] = useState(false);

    let mutatingDataLoading = true;
    if (detailProcessStore) {
        const { mutatingData: detailMutatingData } = detailProcessStore;
        mutatingDataLoading = detailProcessStore.mutatingDataLoading;
    }

    if (!preRequiredReady) {
        return null;
    }

    // 开通申请的最后一步“商业运营”
    const isLastStepOfProductOpen =
        processAllInfo?.crmProcessInstance?.processDefinitionKey ===
            'productopen' &&
        _.startsWith(
            processAllInfo?.crmActivity?.activityId || '',
            'business_operate',
        );

    let customRenders;
    if (isLastStepOfProductOpen) {
        const cRender: TBtnsCusRenders = {
            AGREE: {
                children: '开通',
                loading: openProductSaveLoading,
            },
        };
        customRenders = cRender;
    }

    const submitActionsProps: ISubmitActionsProps = {
        defaultStore: detailProcessStore,
        currentAction: defaultStore.submitActionType,
        data: defaultStore.submitActionData,
        onCancel: () => {
            defaultStore.resetSubmitActionItems();
        },
        loading: opLoading,
        onOk: async extra => {
            const operate = submitActionType;
            const businessInfo = detailProcessStore?.mutatingData;
            const taskId =
                detailProcessStore?.defaultMeta?.processAllInfo?.currentTask
                    ?.id;
            const comment = defaultStore.submitActionData.comment;
            const newTaskUser = defaultStore.submitActionData.newTaskUser;
            const currentUser = defaultStore.sysUser?.userId;

            if (
                !operate ||
                !businessInfo ||
                !taskId ||
                !detailProcessStore ||
                !currentUser
            ) {
                return;
            }

            if (
                tableCode === 'process_customer_transfer_table' &&
                +businessInfo?.transfer_type !== 1
            ) {
                for (const key of transferSingleParamList) {
                    businessInfo[key] = null;
                }
            }

            const payload: any = {
                operate,
                businessInfo,
                taskId,
                currentUser,
            };

            if (comment) {
                payload.comment = comment;
            }

            if (newTaskUser) {
                payload.newTaskUser = newTaskUser.originalValue;
            }

            const useApprove = extra?.useApprove || false;
            if (useApprove) {
                payload.operate = 'ADD_APPROVE';
            }

            setOpLoading(true);
            const isSuccess = await detailProcessStore.opProcess(payload);
            setOpLoading(false);

            if (isSuccess) {
                runInAction(() => {
                    defaultStore.resetSubmitActionItems();
                    defaultStore.resetAction();
                    defaultStore.setSeeDetailItem(null);
                    detailProcessStore.setMutatingData(null);
                });
                tryRefresh([tableCode || '', 'raw-process']);
            } else {
                defaultStore.resetSubmitActionItems();
            }
        },
    };

    const popups = (
        <>
            {isLastStepOfProductOpen && (
                <SubmitActionsOpenProductLastStep {...submitActionsProps} />
            )}
            {!isLastStepOfProductOpen && (
                <SubmitActions {...submitActionsProps} />
            )}
            {/* 2020.06.23, 不再通过表单维护客户发票资质 */}
            {/* {tableCode === 'process_contract_invoice_table' && (
                <ContractIQForm
                    preRequiredReady={preRequiredReady}
                    onIQStoreChange={setIqStore}
                    defaultStore={detailProcessStore}
                />
            )} */}
            <AntDrawer
                title={[processInstanceName, processActivityName]
                    .filter(Boolean)
                    .join('-')}
                placement={'right'}
                visible={visible}
                onClose={() => {
                    defaultStore.resetAction();
                    resetStates();
                }}
                bodyStyle={{
                    width: 1050,
                    overflow: 'scroll',
                }}
                width={1050 + 'px'}
            >
                <div className="standard-drawer-form-wrapper">
                    {!ready ||
                    detailProcessStore === null ||
                    mutatingDataLoading ? (
                        <BlockLoading2 />
                    ) : (
                        <>
                            {tableCode === 'contract' ? (
                                <ContractFormProxy
                                    processCtx={processCtx}
                                    defaultStore={detailProcessStore}
                                />
                            ) : null}
                            {['contract'].indexOf(tableCode || '') === -1 ? (
                                <ProcessComHub
                                    processCtx={processCtx}
                                    defaultStore={detailProcessStore}
                                    tableCode={tableCode}
                                    mode={'update'}
                                />
                            ) : null}
                            <CommentDisplay defaultStore={detailProcessStore} />
                        </>
                    )}
                    <div
                        style={{ top: 8, height: 55, paddingRight: 55 }}
                        className="fixed-form-btns"
                    >
                        {detailProcessStore === null || mutatingDataLoading
                            ? null
                            : loadingWrapper(opLoading)(
                                  <>
                                      <ProcessButtons
                                          buttons={processBtns}
                                          customRenders={customRenders}
                                          onButtonClick={async operate => {
                                              const businessInfo =
                                                  detailProcessStore?.mutatingData;
                                              const taskId =
                                                  detailProcessStore
                                                      ?.defaultMeta
                                                      ?.processAllInfo
                                                      ?.currentTask?.id;
                                              const comment =
                                                  defaultStore.submitActionData
                                                      ?.comment || null;
                                              const newTaskUser =
                                                  defaultStore.submitActionData
                                                      ?.newTaskUser || null;
                                              const currentUser =
                                                  defaultStore.sysUser?.userId;

                                              if (
                                                  tableCode ===
                                                  'process_contract_product_open_table'
                                              ) {
                                                  if (
                                                      _.isString(
                                                          businessInfo?.cidDiffLint,
                                                      ) &&
                                                      operate !== 'DELETE'
                                                  ) {
                                                      const confirmed = await new Promise(
                                                          (resolve, reject) => {
                                                              Modal.confirm({
                                                                  title:
                                                                      businessInfo?.cidDiffLint,
                                                                  onOk: () =>
                                                                      resolve(
                                                                          true,
                                                                      ),
                                                                  onCancel: () =>
                                                                      resolve(
                                                                          false,
                                                                      ),
                                                              });
                                                          },
                                                      );
                                                      if (!confirmed) {
                                                          return;
                                                      }
                                                  }
                                                  const activityId =
                                                      detailProcessStore
                                                          ?.defaultMeta
                                                          ?.processAllInfo
                                                          ?.crmActivity
                                                          ?.activityId;

                                                  const whiteActivities = [
                                                      'open_apply',
                                                      'business_operate',
                                                      'business_operate_tb',
                                                      'business_operate_en',
                                                  ];
                                                  //  审批中，如果activityId是最后两个节点，需要调用校验，否则不需要校验
                                                  if (
                                                      whiteActivities.includes(
                                                          activityId,
                                                      )
                                                  ) {
                                                      const openCustomerId =
                                                          detailProcessStore
                                                              .mutatingData
                                                              ?.open_customer_id
                                                              ?.originalValue;
                                                      const customerId =
                                                          detailProcessStore
                                                              .mutatingData
                                                              ?.customer_id
                                                              ?.originalValue;
                                                      const [
                                                          d,
                                                          e,
                                                      ] = await defaultAxios.get(
                                                          '/crm/customer/relation/check',
                                                          {
                                                              customerId,
                                                              openCustomerId,
                                                          },
                                                      );

                                                      if (!isNetSuccess(d, e)) {
                                                          message.error(
                                                              '预校验失败',
                                                          );
                                                          return;
                                                      }

                                                      if (d?.data === false) {
                                                          const confirmed = await new Promise(
                                                              (
                                                                  resolve,
                                                                  reject,
                                                              ) => {
                                                                  Modal.confirm(
                                                                      {
                                                                          title:
                                                                              '签约公司和开通公司不同且不是关联客户，如需开通请确认已上传相关证明文件。',
                                                                          onOk: () =>
                                                                              resolve(
                                                                                  true,
                                                                              ),
                                                                          onCancel: () =>
                                                                              resolve(
                                                                                  false,
                                                                              ),
                                                                          okText:
                                                                              '继续开通',
                                                                      },
                                                                  );
                                                              },
                                                          );
                                                          if (!confirmed) {
                                                              return;
                                                          }
                                                      }
                                                  }
                                              }

                                              if (
                                                  tableCode ===
                                                  'process_customer_delay_table'
                                              ) {
                                                  const activityId =
                                                      detailProcessStore
                                                          ?.defaultMeta
                                                          ?.processAllInfo
                                                          ?.crmActivity
                                                          ?.activityId;
                                                  if (
                                                      activityId ===
                                                      'delay_apply'
                                                  ) {
                                                      const customerId =
                                                          businessInfo
                                                              ?.customer_id
                                                              ?.originalValue;
                                                      const [
                                                          d,
                                                          e,
                                                      ] = await defaultAxios.get(
                                                          '/crm/customer/delay/remains',
                                                          { customerId },
                                                          { silent: true },
                                                      );
                                                      if (e) {
                                                          const confirmed = await new Promise(
                                                              (
                                                                  resolve,
                                                                  reject,
                                                              ) => {
                                                                  Modal.confirm(
                                                                      {
                                                                          title:
                                                                              e.message,
                                                                          onOk: () =>
                                                                              resolve(
                                                                                  true,
                                                                              ),
                                                                          onCancel: () =>
                                                                              resolve(
                                                                                  false,
                                                                              ),
                                                                      },
                                                                  );
                                                              },
                                                          );
                                                          if (!confirmed) {
                                                              return false;
                                                          }
                                                      } else if (
                                                          _.isNumber(d?.data)
                                                      ) {
                                                          businessInfo.remaining_times =
                                                              d?.data;
                                                      }
                                                  }
                                              }

                                              if (
                                                  operate === 'AGREE' &&
                                                  tableCode ===
                                                      'process_contract_product_open_table'
                                              ) {
                                                  // 如果选择【人工交付】，点击开通后，弹窗提示：请确认人工已交付？
                                                  if (
                                                      businessInfo.product_open_method ===
                                                      2
                                                  ) {
                                                      const ok = await new Promise(
                                                          (resolve, reject) =>
                                                              Modal.confirm({
                                                                  title:
                                                                      '人工交付',
                                                                  content:
                                                                      '请确认人工已交付？',
                                                                  okText:
                                                                      '已交付',
                                                                  cancelText:
                                                                      '未交付',
                                                                  onOk() {
                                                                      resolve(
                                                                          true,
                                                                      );
                                                                  },
                                                                  onCancel() {
                                                                      resolve(
                                                                          false,
                                                                      );
                                                                  },
                                                              }),
                                                      );
                                                      if (!ok) {
                                                          return false;
                                                      }
                                                  }
                                              }

                                              if (
                                                  isLastStepOfProductOpen &&
                                                  operate === 'AGREE'
                                              ) {
                                                  if (
                                                      !operate ||
                                                      !businessInfo ||
                                                      !taskId ||
                                                      !detailProcessStore ||
                                                      !currentUser
                                                  ) {
                                                      return;
                                                  }

                                                  const payload: any = {
                                                      operate,
                                                      businessInfo,
                                                      taskId,
                                                      currentUser,
                                                  };

                                                  setOpenProductSaveLoading(
                                                      true,
                                                  );
                                                  const isSuccess = await detailProcessStore.updateProcess(
                                                      payload,
                                                  );
                                                  setOpenProductSaveLoading(
                                                      false,
                                                  );
                                                  if (!isSuccess) {
                                                      return;
                                                  }
                                              }
                                              runInAction(() => {
                                                  defaultStore.setSubmitActionData(
                                                      {
                                                          comment: '',
                                                          newTaskUser: null,
                                                      },
                                                  );
                                                  defaultStore.setSubmitActionType(
                                                      operate,
                                                  );
                                              });
                                          }}
                                      />
                                      <AntButton
                                          size="large"
                                          onClick={() => {
                                              defaultStore.resetAction();
                                              resetStates();
                                          }}
                                      >
                                          取消
                                      </AntButton>
                                  </>,
                              )}
                    </div>
                </div>
            </AntDrawer>
        </>
    );

    return popups;
};

const FinalFormDrawer = observer(FormDrawer);
export default FinalFormDrawer;
