import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Input, message, Form } from 'antd';
import { rejects, btnMap } from './conf';
import './action.scss';
import _ from 'lodash';
import { ProcessCatStore } from '../../store';
import { WidgetCommonObject } from '@/components/common/form/widgets';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const TransferAction: React.FC<{
    currentAction: string;
    data: any;
    onOk: (extra?: any) => void;
    onCancel: () => void;
    defaultStore: ProcessCatStore | null;
    loading: boolean;
}> = observer(
    ({ currentAction, data, onOk, onCancel, defaultStore, loading }) => {
        const title = btnMap[currentAction] || '';
        const comment = data?.comment || '';
        const newTaskUser = data?.newTaskUser || null;
        const visible = currentAction === 'TRANSFER' && data !== null;

        const [pass, setPass] = useState(true);
        const inputHelps: any = !pass
            ? {
                  validateStatus: 'error',
                  help: '转办人必填',
              }
            : {};

        if (!defaultStore) {
            return null;
        }

        // 需要一个人员类型的字段元信息
        let ownerCol = null;
        if (defaultStore.defaultMeta) {
            ownerCol = _.find(
                defaultStore.defaultMeta.objectMeta,
                item => item.fieldName === 'create_user',
            );
        }

        if (!ownerCol) {
            return null;
        }
        const constraint = ownerCol.constraint;
        if (!constraint) {
            return null;
        }

        return (
            <Modal
                title={title}
                onOk={() => {
                    if (null === newTaskUser) {
                        setPass(false);
                        return message.error('转办人必填');
                    }
                    onOk();
                }}
                confirmLoading={loading}
                onCancel={onCancel}
                visible={visible}
                zIndex={10001}
            >
                <p>请填写审批意见</p>

                <div className="process-action">
                    <div className="form-wrapper">
                        <Form className="form" layout={'horizontal'}>
                            <Form.Item
                                {...formItemLayout}
                                label={<span className="label">审批意见</span>}
                            >
                                <Input.TextArea
                                    required={true}
                                    value={comment}
                                    onChange={e => {
                                        const text = e?.target?.value;
                                        if (!_.isUndefined(text)) {
                                            data.comment = text;
                                        }
                                    }}
                                    placeholder={'审批意见'}
                                ></Input.TextArea>
                            </Form.Item>
                            <Form.Item
                                {...inputHelps}
                                {...formItemLayout}
                                required
                                label={<span className="label">转办人</span>}
                            >
                                <WidgetCommonObject
                                    k={'create_user'}
                                    value={newTaskUser}
                                    onChange={val => {
                                        data.newTaskUser = val;
                                        setPass(true);
                                    }}
                                    options={{
                                        placeholder: '请选择转办人',
                                        constraint,
                                        listMeta: defaultStore.defaultMeta,
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        );
    },
);
