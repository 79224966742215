import React from 'react';
import { TableProps } from 'antd/lib/table';
import { AntTable } from '@/components/antd';

import './index.less';

export interface TableCardProps<T> extends Omit<TableProps<T>, 'title'> {
    title?: string;
    style?: React.CSSProperties;
}

function TableCard<T>(props: TableCardProps<T>) {
    const { title, style, ...rest } = props;
    return (
        <div className="board-table-card" style={style}>
            {title && (
                <div className="board-table-card-title">
                    <pre>{title}</pre>
                </div>
            )}
            <AntTable pagination={false} {...rest} />
        </div>
    );
}

export default TableCard;
