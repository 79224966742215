import React, { Suspense } from 'react';

import RTKCounter, { Dummy } from '@/pages/home';
import LoadingDemo from '@/pages/demo2/loading';
import FormDemo from '@/pages/demo2/form';
import Form2Demo from '@/pages/demo2/form2';
import GridDemo from '@/pages/demo2/grid';
import OpportunityStepDemo from '@/pages/demo2/opportunity-step';
import CallDemo from '@/pages/demo2/call';
import EditableDisplayDemo from '@/pages/demo2/editable-display';
import TableTest from '@/pages/demo2/test';
import AntdDemo from '@/pages/demo2/antd-global-style-demo';
import SearchableTreeDemo from '@/pages/demo2/searchable-tree';
import CrudTemplateDemo from '@/pages/demo2/crud-template';
import CrudDemo from '@/pages/demo2/crud';
import StandardCrudDemo from '@/pages/demo2/standard-crud';
import AdvancedFilterDemo from '@/pages/demo2/advanced-filter';
import TimelineDemo from '@/pages/demo2/timeline';
import CustomList from '@/pages/sales-follow-up/custom/list';
import LeadList from '@/pages/sales-follow-up/lead/list';
import ContactList from '@/pages/sales-follow-up/contact/list';
import ActivationRecord from '@/pages/sales-follow-up/activation-record/list';
import ProcessManagementAll from '@/pages/sales-follow-up/process/list/all';
import ProcessManagementCategory from '@/pages/sales-follow-up/process/list/category';
import ProcessManagementCategory2 from '@/pages/sales-follow-up/process/list/category2';
import AchievementMana from '@/pages/om/achievement/mana';
import JobManaggementCate from '@/pages/sales-follow-up/job/index';
import Equity from '@/pages/om/equity/list';
import EquityTalentBankAccount from '@/pages/om/equity/talent-bank-account';
import EquityIncreasePrice from '@/pages/om/equity/increase-price';
import EquityContractSign from '@/pages/om/equity/contract-sign';
import Product from '@/pages/om/product/list';
import OptList from '@/pages/sales-follow-up/opt/list';
import OptProductList from '@/pages/sales-follow-up/opt/opt-product';
import ContractProductList from '@/pages/om/contract/contract-product';
import ContractProductExecuteList from '@/pages/om/contract/contract-product-execute';
import ContractIncomeList from '@/pages/om/contract/contract-income';
import ContractInvoiceListProcess from '@/pages/om/contract/contract-invoice';
import ContractInvoiceListResult from '@/pages/om/contract/contract-invoice-old';
import ContractInvoiceApplyList from '@/pages/om/contract/contract-invoice-apply';
import ContractList from '@/pages/om/contract/list';
import CrmDrawer from '../pages/demo2/crm-drawer/index';
import { RouteComponentProps } from '@reach/router';
import { BlockLoading } from '@/components/common';
import UserSelectorDemo from '../pages/demo2/user-selector-demo/index';
import CrmTableCheckbox from '../pages/demo2/table-checkbox/index';
// import SettingPage from '@/pages/setting';
import CustomerSettingHSPG from '@/pages/setting/customer-hsp-grouping/index';
import CustomerSettingHSPL from '@/pages/setting/customer-hsp-limit/index';
import OPM from '../pages/setting/organization-personnel-management/index';
import dataRoleManagement from '@/pages/setting/data-role-management';
import functionRoleManagement from '@/pages/setting/function-role-management';
import salesStageManagement from '@/pages/setting/business-opportunities/sales-stage-management';
import userLogPage from '@/pages/setting/log-page/user-log-page';
import WorkflowActivity from '@/pages/setting/workflow/workflow-activity';
import loginLogPage from '@/pages/setting/log-page/login-log-page';
import CustomerSettingHSPAA from '@/pages/setting/customer-hsp-automatic-allocation/index';
import CustomerPriorityReceive from '@/pages/setting/customer-priority-receive';
import RegionalCustomerLimit from '@/pages/setting/regional-customer-limit/index';
import FinalCustomerListToRelease from '@/pages/sales-follow-up/custom/list/to-release';
import FinalCustomerStarList from '@/pages/sales-follow-up/custom/list/star-list';
import FinalCustomerListOutflow from '@/pages/sales-follow-up/custom/list/outflow';
import CallCenterAdminList from '@/pages/sales-follow-up/call-center-admin/list';
import GlobalSearch from '@/pages/global-search';
import DataManageImportPage from '@/pages/om/data-manage/import';
import ChannelGatherAna from '@/pages/analysis/ana-lead/channel-gather';
import LeadTransfer from '@/pages/analysis/ana-lead/lead-transfer';
import NewOpt from '@/pages/analysis/ana-opt/new-opt';
import NewSign from '@/pages/analysis/ana-lead/new-sign';
import SalesDetailAna from '@/pages/analysis/ana-lead/sales-detail';
import AnalysisPage from '@/pages/analysis';
import HomePage from '@/pages/home/home';
import MemberListPage from '@/pages/om/member/member-list/index';
import MemberDataPage from '@/pages/om/member/member-data/index';
import MemberPage from '@/pages/om/member/index';
import DistributionCustomerPage from '@/pages/om/data-manage/distribution-customer/index';
import CompositeBusinessPage from '@/pages/analysis/dashboard/composite-business';
import AnaProductRightsPage from '@/pages/analysis/ana-product/product-rights/index';
import CompositeLivePage from '@/pages/analysis/dashboard/composite-live';
import CompositeLiveNewPage from '@/pages/analysis/dashboard/composite-live-new';
import CompositeIncomePage from '@/pages/analysis/dashboard/composite-income';
import CompositePredictPage from '@/pages/analysis/dashboard/composite-predict';
import CompositeOpportunityPage from '@/pages/analysis/dashboard/composite-opportunity';
import CompositeActionPage from '@/pages/analysis/dashboard/composite-action-borad';
import HomeNewPage from '@/pages/home/home-new';
import HomeTlWorkTable from '@/pages/home/home-tl-worktable';
import AchievementCompletion from '@/pages/analysis/ana-sales/achievement-completion';
import OpportunityReserve from '@/pages/analysis/ana-sales/opportunity-reserve';
import LeadFollowUp from '@/pages/analysis/ana-sales/lead-follow-up';
import NewTraining from '@/pages/analysis/ana-sales/new-training';
import Task321 from '@/pages/analysis/ana-sales/task-321';
import CooperateReport from '@/pages/analysis/ana-renew/cooperate-report';
import TagList from '@/pages/om/tag/list';
import SaleTagList from '@/pages/om/tag/sale-tag';

const AsyncDemo2Wrapper = React.lazy(() => import('@/pages/demo2'));

const AsyncDemo2GraphQl = React.lazy(() => import('@/pages/demo2/graphql'));
const SettingPage = React.lazy(() => import('@/pages/setting'));
// const HomePage = React.lazy(() => import('@/pages/home/home'));

const AsyncHOC = (
    Com: React.LazyExoticComponent<React.FC<RouteComponentProps<{}>>>,
) => (props: RouteComponentProps) => {
    return (
        <Suspense
            fallback={
                <div>
                    <BlockLoading {...({} as any)} />
                </div>
            }
        >
            <Com {...props} />
        </Suspense>
    );
};
// user-log
// workflow-activity

const comResouceMap: {
    [pageKey: string]: React.FC<RouteComponentProps<{}>>;
} = {
    'mobx-counter': RTKCounter,
    'mobx-counter-outer': RTKCounter,
    'mobx-dummy': Dummy,
    'demo2-loading': LoadingDemo,
    'demo2-wrapper': AsyncHOC(AsyncDemo2Wrapper),
    'demo2-graphql': AsyncHOC(AsyncDemo2GraphQl),
    'demo2-form': FormDemo,
    'demo2-form2': Form2Demo,
    'demo2-grid': GridDemo,
    'demo2-opportunity-step': OpportunityStepDemo,
    'demo2-call': CallDemo,
    'demo2-editalbe-diplay': EditableDisplayDemo,
    'demo2-table-test': TableTest,
    'demo2-crud': CrudDemo,
    'demo2-standard-crud': StandardCrudDemo,
    'demo2-timeline': TimelineDemo,
    'demo2-userselector': UserSelectorDemo,
    'demo2-advanced-filter': AdvancedFilterDemo,
    'demo2-antd': AntdDemo as any, // ...
    'demo2-searchable-tree': SearchableTreeDemo as any, // ...
    'demo2-crud-template': CrudTemplateDemo as any, // ...
    'global-search': GlobalSearch,
    'buy-custom-all': props => (
        <CustomList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'buy-custom-mine': props => (
        <CustomList
            {...{
                ...props,
                type: 'mine',
            }}
        />
    ),
    'buy-custom-to-release': FinalCustomerListToRelease,
    'buy-custom-stars': FinalCustomerStarList,
    'buy-custom-outflow': FinalCustomerListOutflow,
    'call-center-admin-list': CallCenterAdminList,
    'buy-lead-all': props => (
        <LeadList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'buy-lead-mine': props => (
        <LeadList
            {...{
                ...props,
                type: 'mine',
            }}
        />
    ),
    'buy-contact-all': props => (
        <ContactList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'buy-opportunity-all': props => {
        return (
            <OptList
                {...{
                    ...props,
                    type: 'all',
                }}
            />
        );
    },
    'buy-opportunity-product': props => {
        return (
            <OptProductList
                {...{
                    ...props,
                    type: 'all',
                }}
            />
        );
    },
    'activation-record-all': props => (
        <ActivationRecord
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'process-management-all': props => {
        return (
            <ProcessManagementAll
                {...{
                    ...props,
                    type: 'all',
                }}
            />
        );
    },
    'process-management-category': props => {
        return (
            <ProcessManagementCategory
                {...{
                    ...props,
                    type: 'category',
                }}
            />
        );
    },
    'process-management-group': props => {
        return (
            <ProcessManagementCategory2
                {...{
                    ...props,
                    type: 'category',
                }}
            />
        );
    },
    'om-achievement-management': props => {
        return (
            <AchievementMana
                {...{
                    ...props,
                    type: 'all',
                }}
            />
        );
    },
    'job-management-cus-follow': props => {
        return (
            <JobManaggementCate
                {...{
                    ...props,
                    type: 'all',
                    tableCode: 'task_activation',
                }}
            />
        );
    },
    'om-product-all': props => (
        <Product
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'om-product-equality': props => (
        <Equity
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'om-equality-talent-bank-account': props => (
        <EquityTalentBankAccount
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'om-equality-increase-price': props => (
        <EquityIncreasePrice
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'om-equality-contract-sign': props => (
        <EquityContractSign
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'om-contract-all': props => (
        <ContractList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'om-contract-product': props => (
        <ContractProductList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'om-contract-product-execute': props => (
        <ContractProductExecuteList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'om-contract-invoice-apply': props => (
        <ContractInvoiceApplyList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'om-contract-invoice': props => (
        <ContractInvoiceListProcess
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'om-contract-invoice-result': props => (
        <ContractInvoiceListResult
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'om-contract-income': props => (
        <ContractIncomeList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),

    'buy-contract-all': props => (
        <ContractList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'buy-contract-product': props => (
        <ContractProductList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'buy-contract-product-execute': props => (
        <ContractProductExecuteList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'buy-contract-invoice-apply': props => (
        <ContractInvoiceApplyList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'buy-contract-invoice': props => (
        <ContractInvoiceListProcess
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'buy-contract-invoice-result': props => (
        <ContractInvoiceListResult
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),
    'buy-contract-income': props => (
        <ContractIncomeList
            {...{
                ...props,
                type: 'all',
            }}
        />
    ),

    'demo2-crm-drawer': CrmDrawer, // ...
    'demo2-crm-tablecheckbox': CrmTableCheckbox,
    setting: AsyncHOC(SettingPage),
    'customer-grouping': CustomerSettingHSPG,
    'customer-limit': CustomerSettingHSPL,
    'organization-personnel-management': OPM,
    'data-role-management': dataRoleManagement,
    'function-personnel-management': functionRoleManagement,
    'sales-stage-management': salesStageManagement,
    'user-log': userLogPage,
    'login-log': loginLogPage,
    'workflow-activity': WorkflowActivity,
    'customer-automatic-allocation': CustomerSettingHSPAA,
    'customer-receive': CustomerPriorityReceive,
    'regional-customer-limit': RegionalCustomerLimit,
    home: HomePage,
    'om-data-import': DataManageImportPage,
    analysis: AnalysisPage,
    'analysis-lead-channel': ChannelGatherAna,
    'analysis-new-sign-lead-transfer-source': props => (
        <LeadTransfer
            {...{
                ...props,
                type: 'source',
            }}
        />
    ),
    'analysis-new-sign-lead-transfer-belongto': props => (
        <LeadTransfer
            {...{
                ...props,
                type: 'belongTo',
            }}
        />
    ),
    'analysis-new-sign-opt-source': NewOpt,
    'analysis-new-sign-core': NewSign,
    'analysis-lead-sales-detail': SalesDetailAna,
    'analysis-product-rights': AnaProductRightsPage,
    'om-member-data': MemberDataPage,
    'om-member-list': MemberListPage,
    'om-member': MemberPage,
    'om-tag-list': TagList,
    'om-sale-tag': SaleTagList,
    'om-data-customer-distribution': DistributionCustomerPage,
    'composite-business': CompositeBusinessPage,
    'composite-business-live': CompositeLivePage,
    'composite-business-live-new': CompositeLiveNewPage,
    'composite-business-income-board': CompositeIncomePage,
    'composite-business-prediction-board': CompositePredictPage,
    'composite-business-opportunity-board': CompositeOpportunityPage,
    'composite-business-action-board': CompositeActionPage,
    'home-new': HomeNewPage,
    'tl-worktable': HomeTlWorkTable,
    'analysis-sales-achievement-completion': AchievementCompletion,
    'analysis-sales-opportunity-reserve': OpportunityReserve,
    'analysis-sales-lead-follow-up': LeadFollowUp,
    'analysis-sales-new-training': NewTraining,
    'analysis-sales-321-task': Task321,
    'analysis-renew-cooperate-report': CooperateReport,
};

export type IComResouceMap = typeof comResouceMap;
export { comResouceMap };
