// 待处理
const todoLead = [1];

// 有效线索，培育中
const validLeadFollowing = [4, 20, 21, 22, 23];

// 有效线索-已转商机
const validLeadTransformedOpt = [12];

// 无效线索
const invalidLead = [2, 3, 6, 11, 15, 16, 17, 18, 19];

const statusTransMap: Map<Set<number>, Set<number>> = new Map();

statusTransMap.set(
    new Set(todoLead),
    new Set([...todoLead, ...validLeadFollowing, ...invalidLead]),
);
statusTransMap.set(new Set(invalidLead), new Set([]));
statusTransMap.set(
    new Set(validLeadFollowing),
    new Set([...validLeadFollowing, ...invalidLead]),
);
statusTransMap.set(new Set(validLeadTransformedOpt), new Set([]));

export const getFollowStatusesByOriginalFollowStatus = (status: number) => {
    for (const [ks, vs] of Array.from(statusTransMap.entries())) {
        if (ks.has(status)) {
            return vs;
        }
    }
    return new Set<number>([]);
};

export const notFinishedLead = [...todoLead, ...validLeadFollowing];

const invalidLeadSet = new Set(invalidLead);
export const isInValidLead = (status: number) => invalidLeadSet.has(status);
