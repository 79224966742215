import React from 'react';
import DefaultProcess from '../form';
import _ from 'lodash';

import { IProcessComProps, IProcessComs } from './def';
import { ProcessProductGiveOpenTable } from './process_product_give_open_table';
import { ProcessContractEmployerOpenTable } from './process_contract_employer_open_table';
import { ProcessCustomerRenameTable } from './process_customer_rename_table';
import { ProcessContractProductOpenTable } from './process_contract_product_open_table';
import { ProcessEmployerSupportTable } from './process_employer_support_table';
import { ContractInvoiceForm } from '../contract-invoice-form';

const regMap: IProcessComs = {
    process_product_give_open_table: ProcessProductGiveOpenTable,
    process_contract_employer_open_table: ProcessContractEmployerOpenTable,
    process_customer_rename_table: ProcessCustomerRenameTable,
    process_employer_support_table: ProcessEmployerSupportTable,
    process_contract_invoice_table: ContractInvoiceForm,
    process_contract_product_open_table: ProcessContractProductOpenTable,
    __default: DefaultProcess,
};

export const ProcessComHub: React.FC<IProcessComProps> = props => {
    const { tableCode } = props;
    if (!tableCode) {
        return null;
    }
    const ProxiedCom = regMap[tableCode];
    if (_.isNil(ProxiedCom)) {
        const DefauProcess = regMap.__default;
        return <DefauProcess {...props} />;
    }
    return <ProxiedCom {...props} />;
};
