import React, { useState } from 'react';

import { CustomerListStore } from '../store';
import { Modal, Alert } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

export const ActionInvalidBizType: React.FC<{
    customerList: CustomerListStore;
}> = observer(({ customerList }) => {
    const { defaultMSelect } = customerList;
    const { mSeletedData } = defaultMSelect;
    if (mSeletedData === null || mSeletedData.length === 0) {
        return null;
    }
    const visible = customerList.actionType === 'invalid-biztype';
    return (
        <Modal
            visible={visible}
            title={'操作失败'}
            onCancel={() => {
                customerList.resetAction();
            }}
            onOk={async () => {
                customerList.resetAction();
            }}
        >
            <Alert
                type="error"
                message="不可跨业务类型进行批量操作，请选择同一业务类型的客户。"
            />
        </Modal>
    );
});
