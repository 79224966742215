import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AntButton, BlockLoading2 } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { AntDrawer } from '@/components/antd/drawer';
import { defaultAuthDeco } from '@/containers/hoc';
import { IJobStore } from '../../../utils';
import DemoForm from './form';
import { tryRefresh } from '@/utils';

const FormDrawer: React.FC<RouteComponentProps & {
    defaultStore: IJobStore;
    preRequiredReady: boolean;
    onSuccess?: () => void;
    onClose?: () => void;
    isMultipleFollowCus?: boolean;
    customers?: any[];
}> = ({
    defaultStore,
    preRequiredReady,
    onSuccess,
    onClose,
    isMultipleFollowCus,
    customers,
}) => {
    const {
        actionType,
        subActionType,
        bigFormVisible,
        mutatingDataLoading,
    } = defaultStore;

    const [confirmLoading, setConfirmLoading] = useState(false);

    if (!preRequiredReady) {
        return null;
    }

    let title = '';
    if (actionType === 'update') {
        if (subActionType === 'complete') {
            title = '完成任务';
        } else {
            title = '修改任务';
        }
    } else if (actionType === 'create') {
        title = '新建任务';
    }

    const popups = (
        <>
            <AntDrawer
                title={title}
                placement={'right'}
                visible={bigFormVisible}
                onClose={() => {
                    defaultStore.resetAction();
                    onClose && onClose();
                }}
                bodyStyle={{
                    width: 850,
                    overflow: 'scroll',
                }}
                width={850 + 'px'}
            >
                <div className="standard-drawer-form-wrapper">
                    {mutatingDataLoading ? (
                        <BlockLoading2 />
                    ) : (
                        <DemoForm
                            defaultStore={defaultStore}
                            isMultipleFollowCus={isMultipleFollowCus}
                            customers={customers}
                        />
                    )}
                    <div className="fixed-form-btns">
                        <AntButton
                            size="large"
                            type="primary"
                            loading={confirmLoading}
                            onClick={async () => {
                                setConfirmLoading(true);
                                const isSuccess = await defaultStore.dispatchAction();
                                setConfirmLoading(false);
                                if (isSuccess) {
                                    tryRefresh([defaultStore.tableCode]);
                                    onSuccess && onSuccess();
                                }
                            }}
                        >
                            确定
                        </AntButton>
                        <AntButton
                            size="large"
                            onClick={() => {
                                defaultStore.resetAction();
                                onClose && onClose();
                            }}
                        >
                            取消
                        </AntButton>
                    </div>
                </div>
            </AntDrawer>
        </>
    );

    return popups;
};

const FinalFormDrawer = defaultAuthDeco(observer(FormDrawer));
export default FinalFormDrawer;
