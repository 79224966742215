import React, { useContext, useEffect, useState, CSSProperties } from 'react';
import './index.less';
import { observer } from 'mobx-react-lite';
import { defaultAxios, isNetSuccess } from '@/utils';
import _ from 'lodash';
import moment from 'moment';
import { StoreContext } from '@/stores';
import { Spin, Statistic, Popover, Badge } from 'antd';
import { entityCodeToUrl } from '../../store';
import qs from 'querystring';
import { goToOtherPage } from '../home-ana-opt/index';
const HomeMineBehindSaleInfo: React.FC = observer(() => {
    const store = useContext(StoreContext);
    const homeNewStore = store.getDefaultHomeNewStore();
    const { homeNewRightInfoData, homeNewRightInfoLoading } = homeNewStore;
    const homeNewBehindInfoData = homeNewRightInfoData.postSaleAbnormalJumpInfo;
    const statisticValueStyle: CSSProperties = {
        fontSize: '16px',
        color: '#0052ff',
        fontWeight: 500,
    };
    const setLocalStorageUserByKey = (key: string) => {
        localStorage.setItem(
            key,
            JSON.stringify(
                _.map(homeNewRightInfoData.userInfoList, uItem => {
                    return {
                        id: uItem.userId,
                        key: `user-${uItem.userId}`,
                        name: uItem.userName,
                        title: uItem.userName,
                    };
                }),
            ),
        );
    };
    const allCount =
        (homeNewBehindInfoData?.more30dayNotFollowCustomer || 0) +
        (homeNewBehindInfoData?.openNotPayBack || 0) +
        (homeNewBehindInfoData?.payBackNotOpen || 0) +
        (homeNewBehindInfoData?.less90enterpriseBankExpireCustomer || 0) +
        (homeNewBehindInfoData?.less90talentBankExpireCustomer || 0) +
        (homeNewBehindInfoData?.openInvoice || 0);
    return (
        <Popover content={'请及时处理，避免运营执行客户回收策略'}>
            <div className="home-new-right-info-content">
                <Spin spinning={homeNewRightInfoLoading}>
                    <div className="crm-home-behind-info">
                        <p className="home-page-title">
                            <div className="home-page-title-text">售后异常</div>
                            <Badge
                                offset={[6, -3]}
                                count={allCount}
                                overflowCount={999}
                            ></Badge>
                        </p>
                        <div className="home-behind-info-body">
                            <div
                                className="behind-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        '30_recent_activity_record_time_fk',
                                    );
                                    goToOtherPage(
                                        'private_customer',
                                        '30_recent_activity_record_time',
                                        '30_recent_activity_record_time_fk',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewBehindInfoData?.more30dayNotFollowCustomer ||
                                        '0'
                                    }
                                />
                                <p className="behind-info-label">
                                    超30天未跟进
                                </p>
                            </div>
                            <div
                                className="behind-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        'less_90_talent_expired_customer',
                                    );
                                    goToOtherPage(
                                        'private_customer',
                                        undefined,
                                        'less_90_talent_expired_customer',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewBehindInfoData?.less90talentBankExpireCustomer ||
                                        '0'
                                    }
                                />
                                <p className="behind-info-label">
                                    人才银行到期
                                </p>
                            </div>
                            <div
                                className="behind-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        'less_90_enterprise_expired_customer',
                                    );
                                    goToOtherPage(
                                        'private_customer',
                                        undefined,
                                        'less_90_enterprise_expired_customer',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewBehindInfoData?.less90enterpriseBankExpireCustomer ||
                                        '0'
                                    }
                                />
                                <p className="behind-info-label">企业号到期</p>
                            </div>
                            <div
                                className="behind-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        'kaitongweihuikuan_fk',
                                    );
                                    goToOtherPage(
                                        'contract',
                                        'kaitongweihuikuan',
                                        'kaitongweihuikuan_fk',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewBehindInfoData?.openNotPayBack ||
                                        '0'
                                    }
                                />
                                <p className="behind-info-label">
                                    已开通未回款
                                </p>
                            </div>
                            <div
                                className="behind-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        'huikuanweikaitong_fk',
                                    );
                                    goToOtherPage(
                                        'contract',
                                        'huikuanweikaitong',
                                        'huikuanweikaitong_fk',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewBehindInfoData?.payBackNotOpen ||
                                        '0'
                                    }
                                />
                                <p className="behind-info-label">回款未开通</p>
                            </div>
                            <div
                                className="behind-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        'yikaipiaoweihuikuan_fk',
                                    );
                                    goToOtherPage(
                                        'contract',
                                        'yikaipiaoweihuikuan',
                                        'yikaipiaoweihuikuan_fk',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewBehindInfoData?.openInvoice ||
                                        '0'
                                    }
                                />
                                <p className="behind-info-label">开票未回款</p>
                            </div>
                            <div
                                className="behind-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        'published_position',
                                    );
                                    goToOtherPage(
                                        'private_customer',
                                        undefined,
                                        'published_position',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewBehindInfoData?.publishedPosition ||
                                        '0'
                                    }
                                />
                                <p className="behind-info-label">
                                    近30天未发布职位
                                </p>
                            </div>
                            <div
                                className="behind-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        'active_half_recruitment_count',
                                    );
                                    goToOtherPage(
                                        'private_customer',
                                        undefined,
                                        'active_half_recruitment_count',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewBehindInfoData?.activeHalfRecruitmentCount ||
                                        '0'
                                    }
                                />
                                <p className="behind-info-label">
                                    近30天不活跃
                                </p>
                            </div>
                            <div className="behind-info-item"></div>
                            {/* <div
                                className="behind-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        'less_30_talent_expired_customer',
                                    );
                                    goToOtherPage(
                                        'private_customer',
                                        undefined,
                                        'less_30_talent_expired_customer',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewBehindInfoData?.less30talentBankExpireCustomer ||
                                        '0'
                                    }
                                />
                                <p className="behind-info-label">
                                    30天内人才银行到期
                                </p>
                            </div>
                            <div
                                className="behind-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        'less_30_enterprise_expired_customer',
                                    );
                                    goToOtherPage(
                                        'private_customer',
                                        undefined,
                                        'less_30_enterprise_expired_customer',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewBehindInfoData?.less30enterpriseBankExpireCustomer ||
                                        '0'
                                    }
                                />
                                <p className="behind-info-label">
                                    30天内企业号到期
                                </p>
                            </div> */}
                            {/* <div className="behind-info-item"></div> */}
                        </div>
                    </div>
                </Spin>
            </div>
        </Popover>
    );
});
export default HomeMineBehindSaleInfo;
