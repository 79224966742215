import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    FastFilterStore,
} from '@/stores/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import { mutate, mutateCommon, checkCommon } from '@/api/graphql';
import _ from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    getLeads,
    TGetLeadsReturnType,
    xDelete,
    commonQuery,
    TGetCommonReturnType,
    getCommonQueryBody,
} from '@/api/rest';
import {
    defaultAxios,
    defaultWritingDataParser,
    isNetSuccess,
    tryRefresh,
} from '@/utils';
import EE from 'eventemitter3';
import $ from 'jquery';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';
import { getParamBlackListByTargetType } from './utills';

const transformBackEndValidateStateToValidateResult = (
    data: any[],
): ValidateResult<any> => {
    const ret: ValidateResult<any> = {};
    for (const d of data) {
        const { errorMsg, errorType, fieldName } = d;
        if (!errorMsg || !errorType || !fieldName) {
            continue;
        }
        ret[fieldName] = {
            msg: errorMsg,
            status: errorType === 'error' ? 'fail' : 'warning',
        };
    }
    return ret;
};

export class AchievementManaStore {
    // 数据
    public data: any[] = [];
    public setData(data: any[]) {
        this.data = data;
    }

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public get pageTitle() {
        return '业绩目标';
    }

    public get selectedCn() {
        const { defaultFastFilters } = this;
        return defaultFastFilters.selectedFastFilters[0]?.label;
    }
    public get pageAbstract() {
        return `${this.targetYear}年度-${this.selectedCn}`;
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );
        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        ret.filters?.push(...defaultFilters);
        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }
    public setAction(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra.id) {
                this.fetchInstantlyMutatingDataById(extra.id);
            } else {
                message.error('修改必须传id');
            }
        }
        this.actionType = nextAction;
    }
    public resetAction() {
        this.netValidateResult = {};
        this.actionType = '';
    }
    public async dispatchAction() {
        if (this.actionType === 'create') {
            return this.create();
        } else if (this.actionType === 'update') {
            return this.update();
        }
    }

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;
    get mutatingDataParsed() {
        return defaultWritingDataParser(this.mutatingData);
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        const initialFormData = this.defaultMeta.defaultWidgetValues;
        this.mutatingData = _.cloneDeep(initialFormData);
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;

    get fieldAuthCode() {
        return this.listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    }
    get dataAuthCode() {
        return this.listType === 'all' ? 'SEARCH-PUBLIC' : 'SEARCH-PRIVATE';
    }
    public async fetchInstantlyMutatingDataById(id: any) {
        const { fieldAuthCode, dataAuthCode } = this;

        this.mutatingDataLoading = true;
        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: id,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            undefined,
            dataAuthCode,
            undefined,
            this.defaultMeta.tableId
                ? '/bff/api/rest/achievement/list?table=' +
                      this.defaultMeta.tableId
                : '',
        );
        this.mutatingDataLoading = false;

        if (d === null || e !== null) {
            return false;
        }
        const { datas } = d;
        if (datas && datas[0]) {
            this.mutatingData = datas[0];
            return true;
        } else {
            message.error('没有该数据的权限');
            return false;
        }
    }
    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode, dataAuthCode } = this;
        if (!this.defaultMeta.tableId) {
            return [null, new Error('no tableId')];
        }

        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            !_.isEmpty(this.defaultSorter.sorterArgs)
                ? this.defaultSorter.sorterArgs
                : {
                      orderBy: 'target_level',
                      sortStrategy: 1,
                  },
            fieldAuthCode,
            undefined,
            dataAuthCode,
            undefined,
            this.defaultMeta.tableId
                ? '/bff/api/rest/achievement/list?table=' +
                      this.defaultMeta.tableId
                : '',
        );

        if (_.isNil(d) || !_.isNil(e)) {
            return [d, e];
        }
        const leadsData = d;
        const { amount, datas } = leadsData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    public async create() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        delete data.id;
        delete data.key;
        const [ret, err] = await mutate('lead', 'insert', data);
        if (!ret) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('新建成功');
        this.defaultEE.emit('create-success');
        return true;
    }

    public mCreateResult = {
        loading: false,
        isSuccess: false,
        msg: '',
    };

    public async mCreateFromCnJSON(cnJson: any[]) {
        this.mCreateResult.loading = true;
        const [d, e] = await defaultAxios.post(
            '/crm/import/saleTarget',
            cnJson,
        );
        this.mCreateResult.loading = false;
        if (isNetSuccess(d, e)) {
            this.mCreateResult.isSuccess = true;
            this.resetAction();
            tryRefresh(['achievement']);
            return true;
        }
        this.mCreateResult.isSuccess = false;
        this.mCreateResult.msg = e.message;
        return false;
    }

    public async check() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        delete data.key;
        // 后端的check暂时改造起来比较麻烦，前端先搞个简易的check
        const paramsToCheck = [
            'target_money_month1',
            'target_money_month2',
            'target_money_month3',
            'target_money_month4',
            'target_money_month5',
            'target_money_month6',
            'target_money_month7',
            'target_money_month8',
            'target_money_month9',
            'target_money_month10',
            'target_money_month11',
            'target_money_month12',
        ];

        for (const param of paramsToCheck) {
            const paramCn = this.defaultMeta.objectMetaMap?.[param]
                ?.fieldComment;
            if (_.isNil(data[param])) {
                message.error(paramCn + '不能为空');
                return false;
            }
            if (data[param] < 0) {
                message.error(paramCn + '不能为负数');
                return false;
            }
        }

        this.setNetValidateResult({});
        return true;
    }

    public async update() {
        const isPass = await this.check();
        if (!isPass) {
            return false;
        }
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        delete data.key;

        const [ret, errMsg] = await mutateCommon(
            this.defaultMeta.tableId,
            'update',
            data,
        );
        if (!ret) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (+code !== 0) {
            return false;
        }

        this.resetAction();
        message.info('修改成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    get downloadUrl() {
        const { fieldAuthCode, dataAuthCode } = this;
        const { tableId: objectName, tableColsParamsAll } = this.defaultMeta;
        const { amount } = this.defaultPagination;
        if (!amount) {
            return '';
        }
        const pageSize = Math.max(Math.min(Math.ceil(amount / 10), 300), 100);
        const fetchBody = getCommonQueryBody(
            { ...this.defaultPagination.paginationArgs, pageSize },
            this.condListbyFilterData,
            !_.isEmpty(this.defaultSorter.sorterArgs)
                ? this.defaultSorter.sorterArgs
                : {
                      orderBy: 'target_level',
                      sortStrategy: 1,
                  },
            fieldAuthCode,
            _.difference(
                tableColsParamsAll.map(item => item.key),
                getParamBlackListByTargetType(this.targetType),
            ), // undefined,
            dataAuthCode,
            false,
        );
        // 如果是cu_role_user则是流出记录
        fetchBody.objectName = objectName;
        return (
            `${
                window.location.origin
            }/bff/api/rest/export?q=${encodeURIComponent(
                JSON.stringify(fetchBody),
            )}&hash=` + Math.random()
        );
    }

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMeta = new ListMeta(
        '/bff/api/rest/meta/achievement/list?table=lead',
        '/bff/api/rest/meta/achievement/list/filters/save?table=lead',
        '/bff/api/rest/meta/achievement/list/table-cols/save?table=lead',
    );
    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();

    // 这里要改成那个对应的权限
    public defaultPerm: Perm = new Perm(
        '/bff/api/rest/perm?mod=om:sale_target',
    );
    public defaultMSelect: MSelectStore = new MSelectStore();
    public defaultFastFilters = new FastFilterStore();
    public targetType: string = '1';
    public targetYear: string = '2024';
    public setTargetYear(next: string) {
        this.targetYear = next;
    }
    public setDefaultMeta(table: string, targetType: any) {
        this.targetType = '' + targetType;
        const source: any = async () => {
            const [d, e] = await defaultAxios.post(
                `/bff/api/rest/meta/achievement/list?table=${table}`,
            );
            const data = d?.data;

            const tableColsParamsAll = data?.tableColsParamsAll;
            const objectMeta = data?.objectMeta;
            console.log('objectMeta', objectMeta);

            if (data && _.isArray(tableColsParamsAll)) {
                data.tableColsParamsAll = _.differenceBy(
                    tableColsParamsAll,
                    getParamBlackListByTargetType(targetType).map(k => ({
                        key: k,
                    })),
                    'key',
                );
            }
            if (data && _.isArray(objectMeta)) {
                data.objectMeta = _.differenceBy(
                    objectMeta,
                    getParamBlackListByTargetType(targetType).map(k => ({
                        fieldName: k,
                    })),
                    'fieldName',
                );
            }

            return [d, e];
        };
        this.defaultMeta = new ListMeta(
            source,
            `/bff/api/rest/meta/achievement/list/filters/save?table=${table}`,
            `/bff/api/rest/meta/achievement/list/table-cols/save?table=${table}`,
        );
        this.defaultFilter = new FilterStore(
            this.defaultSorter,
            this.defaultMeta,
        );
        this.defaultPagination = new PaginationStore();
        this.defaultMeta.setNoUserSetting(true);
    }

    constructor(table: string) {
        this.defaultMeta = new ListMeta(
            `/bff/api/rest/meta/${table}/list`,
            `/bff/api/rest/meta/${table}/list/filters/save`,
            `/bff/api/rest/meta/${table}/list/table-cols/save`,
        );

        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultFilter: false,
            defaultEE: false,
            defaultPerm: false,
            defaultMSelect: false,
            defaultFastFilters: false,
        });
    }
}

export const getDefaultAchievementManaStore = createDefaultInstanceGetter(
    AchievementManaStore,
);
