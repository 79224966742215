import _ from 'lodash';
import { create, all } from 'mathjs';

const IMath: any = create(all);
IMath.config({
    number: 'BigNumber',
    precision: 64,
});

const allParams = [
    'target_level',
    'target_dept_id',
    'target_city_id',
    'target_area_id',
    'target_sale_id',
];
export const getParamBlackListByTargetType = (targetType: string) => {
    if (+targetType === 1) {
        return _.difference(allParams, ['target_level']);
    } else if (+targetType === 2) {
        return _.difference(allParams, ['target_area_id']);
    } else if (+targetType === 3) {
        return _.difference(allParams, ['target_city_id', 'target_area_id']);
    } else if (+targetType === 4) {
        return _.difference(allParams, [
            'target_dept_id',
            'target_city_id',
            'target_area_id',
        ]);
    } else if (+targetType === 5) {
        return _.difference(allParams, [
            'target_sale_id',
            'target_dept_id',
            'target_city_id',
            'target_area_id',
        ]);
    }
    return [];
};

export const getParamBlackListByTargetTypeForExport = (targetType: string) => {
    if (+targetType === 1) {
        return _.difference(allParams, ['target_level']);
    } else if (+targetType === 2) {
        return _.difference(allParams, ['target_area_id']);
    } else if (+targetType === 3) {
        return _.difference(allParams, ['target_city_id']);
    } else if (+targetType === 4) {
        return _.difference(allParams, ['target_dept_id']);
    } else if (+targetType === 5) {
        return _.difference(allParams, ['target_sale_id']);
    }
    return [];
};

export const getExportParamBlackListByTargetType = (targetType: string) => {
    return [
        // auto params
        'id',
        'is_del',
        'owner_id',
        'biz_type',
        'create_time',
        'create_user',
        'update_time',
        'update_user',
        'target_money_s1',
        'target_money_s2',
        'target_money_s3',
        'target_money_s4',
        'target_money_s5',
        'target_money_s6',
        'target_money_quarter1',
        'target_money_quarter2',
        'target_money_quarter3',
        'target_money_quarter4',
        'target_money_year',

        // appends params
        'target_type',
        'target_year',

        // verbose params
        'target_id',
        'target_name',
        ...getParamBlackListByTargetTypeForExport(targetType),
    ];
};

export const calcuSumMoney = (mutatingData: any) => {
    if (!mutatingData) {
        return {};
    }
    const target_money_month1 = IMath.bignumber(
        +mutatingData.target_money_month1 || 0,
    );
    const target_money_month2 = IMath.bignumber(
        +mutatingData.target_money_month2 || 0,
    );
    const target_money_month3 = IMath.bignumber(
        +mutatingData.target_money_month3 || 0,
    );
    const target_money_month4 = IMath.bignumber(
        +mutatingData.target_money_month4 || 0,
    );
    const target_money_month5 = IMath.bignumber(
        +mutatingData.target_money_month5 || 0,
    );
    const target_money_month6 = IMath.bignumber(
        +mutatingData.target_money_month6 || 0,
    );
    const target_money_month7 = IMath.bignumber(
        +mutatingData.target_money_month7 || 0,
    );
    const target_money_month8 = IMath.bignumber(
        +mutatingData.target_money_month8 || 0,
    );
    const target_money_month9 = IMath.bignumber(
        +mutatingData.target_money_month9 || 0,
    );
    const target_money_month10 = IMath.bignumber(
        +mutatingData.target_money_month10 || 0,
    );
    const target_money_month11 = IMath.bignumber(
        +mutatingData.target_money_month11 || 0,
    );
    const target_money_month12 = IMath.bignumber(
        +mutatingData.target_money_month12 || 0,
    );

    const target_money_s1 = IMath.add(target_money_month4, target_money_month5);
    const target_money_s2 = IMath.add(target_money_month6, target_money_month7);
    const target_money_s3 = IMath.add(target_money_month8, target_money_month9);
    const target_money_s4 = IMath.add(
        target_money_month10,
        target_money_month11,
    );
    const target_money_s5 = IMath.add(
        target_money_month12,
        target_money_month1,
    );
    const target_money_s6 = IMath.add(target_money_month2, target_money_month3);

    const target_money_quarter1 = IMath.add(
        target_money_month1,
        target_money_month2,
        target_money_month3,
    );
    const target_money_quarter2 = IMath.add(
        target_money_month4,
        target_money_month5,
        target_money_month6,
    );
    const target_money_quarter3 = IMath.add(
        target_money_month7,
        target_money_month8,
        target_money_month9,
    );
    const target_money_quarter4 = IMath.add(
        target_money_month10,
        target_money_month11,
        target_money_month12,
    );
    const target_money_year = IMath.add(
        target_money_quarter1,
        target_money_quarter2,
        target_money_quarter3,
        target_money_quarter4,
    );

    return {
        target_money_s1: Number(target_money_s1) || 0,
        target_money_s2: Number(target_money_s2) || 0,
        target_money_s3: Number(target_money_s3) || 0,
        target_money_s4: Number(target_money_s4) || 0,
        target_money_s5: Number(target_money_s5) || 0,
        target_money_s6: Number(target_money_s6) || 0,
        target_money_quarter1: Number(target_money_quarter1) || 0,
        target_money_quarter2: Number(target_money_quarter2) || 0,
        target_money_quarter3: Number(target_money_quarter3) || 0,
        target_money_quarter4: Number(target_money_quarter4) || 0,
        target_money_year: Number(target_money_year) || 0,
    };
};
