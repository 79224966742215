import React, { memo } from 'react';
import { DForm, applyConfToFormItem } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { InvoiceQualificationListStore } from '../store';

const DemoForm: React.FC<{
    defaultStore: InvoiceQualificationListStore;
}> = ({ defaultStore }) => {
    const { actionType } = defaultStore;

    let eventCode = '';
    if (actionType === 'create') {
        eventCode = 'CREATE-EDIT';
    } else if (actionType === 'update') {
        eventCode = 'PUBLIC-EDIT';
    }

    if (eventCode === '') {
        return null;
    }
    const form = (
        <DForm
            eventCode={eventCode}
            defaultStore={defaultStore}
            widgetsTransformer={baiscWidgets => {
                applyConfToFormItem(baiscWidgets, 'inputSecret', {
                    sceneCode: 'crm_contract',
                });
                return baiscWidgets;
            }}
        />
    );
    return form;
};

const FinalDemoForm = memo(observer(DemoForm));
export default FinalDemoForm;
