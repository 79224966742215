import React, { useState, useEffect, CSSProperties } from 'react';
import './index.less';
import moment from 'moment';
import _ from 'lodash';
import { Statistic, Icon, message, Select } from 'antd';
import CompositeLiveChartsComponents from '../../composite-live-charts';
import { EChartsOption } from 'echarts';
import { IOptAnalysisCompositeData } from '../../opt-analysis/index';
import { defaultAxios } from '@/utils';
import {
    IAchievementSituationFilterData,
    areaOptions,
    rangeOptions,
    trans2W,
    trans2WDotTwo,
} from '../../achievement-situation';
export interface IPerformanceNewOldCustomerProps {}
export interface INewOldData {
    update_time?: string | number;
    new_customer_money?: number;
    old_customer_money?: number;
    new_customer_num?: number;
    old_customer_num?: number;
    new_customer_arpu?: number;
    old_customer_arpu?: number;
    [key: string]: any;
}
const newOldCustomerAnaValueMap: {
    title: string;
    key: string;
    formatter: any;
    prodText?: string;
}[] = [
    {
        title: '新客业绩',
        key: 'new_customer_money',
        formatter: (v: any) => `${trans2W(v)}`,
        prodText: 'W',
    },
    {
        title: '老客业绩',
        key: 'old_customer_money',
        formatter: (v: any) => `${trans2W(v)}`,
        prodText: 'W',
    },
    {
        title: '新客客户数',
        key: 'new_customer_num',
        formatter: (v: any) => v,
    },
    {
        title: '老客客户数',
        key: 'old_customer_num',
        formatter: (v: any) => v,
    },
    {
        title: '新客ARPU',
        key: 'new_customer_arpu',
        formatter: (v: any) => `${trans2WDotTwo(v)}`,
        prodText: 'W',
    },
    {
        title: '老客ARPU',
        key: 'old_customer_arpu',
        formatter: (v: any) => `${trans2WDotTwo(v)}`,
        prodText: 'W',
    },
];
interface IPerformanceCustomerData {
    income_month: string;
    new_customer_money: number;
    old_customer_money: number;
    new_customer_num: number;
    old_customer_num: number;
    new_customer_arpu: number;
    old_customer_arpu: number;
}
const testMonthData = [
    {
        month: '一月2021',
        old_customer: '1000',
        new_customer: '1200',
    },
    {
        month: '二月2021',
        old_customer: '2000',
        new_customer: '3200',
    },
    {
        month: '三月2021',
        old_customer: '5000',
        new_customer: '6200',
    },
    {
        month: '四月2021',
        old_customer: '1000',
        new_customer: '1200',
    },
    {
        month: '五月2021',
        old_customer: '8000',
        new_customer: '1200',
    },
    {
        month: '六月2021',
        old_customer: '10100',
        new_customer: '2200',
    },
    {
        month: '七月2021',
        old_customer: '12100',
        new_customer: '4200',
    },
    {
        month: '八月2021',
        old_customer: '14100',
        new_customer: '6200',
    },
    {
        month: '九月2021',
        old_customer: '16100',
        new_customer: '7200',
    },
    {
        month: '十月2021',
        old_customer: '17100',
        new_customer: '8200',
    },
    {
        month: '十一月2021',
        old_customer: '19100',
        new_customer: '8900',
    },
    {
        month: '十二月2021',
        old_customer: '19100',
        new_customer: '9100',
    },
];
const testMonthARPUData = [
    {
        month: '一月2021',
        old_customer: '1',
        new_customer: '2',
    },
    {
        month: '二月2021',
        old_customer: '4',
        new_customer: '5',
    },
    {
        month: '三月2021',
        old_customer: '5',
        new_customer: '8',
    },
    {
        month: '四月2021',
        old_customer: '6',
        new_customer: '10',
    },
    {
        month: '五月2021',
        old_customer: '7',
        new_customer: '12',
    },
    {
        month: '六月2021',
        old_customer: '8',
        new_customer: '13',
    },
    {
        month: '七月2021',
        old_customer: '9',
        new_customer: '15',
    },
    {
        month: '八月2021',
        old_customer: '10',
        new_customer: '16',
    },
    {
        month: '九月2021',
        old_customer: '11',
        new_customer: '19',
    },
    {
        month: '十月2021',
        old_customer: '14',
        new_customer: '21',
    },
    {
        month: '十一月2021',
        old_customer: '15',
        new_customer: '22',
    },
    {
        month: '十二月2021',
        old_customer: '17',
        new_customer: '24',
    },
];
const testOptions: EChartsOption = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
        },
    },
    legend: {
        bottom: '0',
        height: '10%',
        itemWidth: 10,
        itemHeight: 10,
        itemGap: 20,
        textStyle: {
            color: '#6E727A',
        },
    },
    grid: {
        left: '0%',
        right: '0%',
        bottom: '15%',
        width: '100%',
        height: '70%',
        containLabel: true,
    },
    xAxis: {
        type: 'category',
        data: testMonthData.map(item => {
            return item.month;
        }),
    },
    yAxis: {
        type: 'value',
    },
    series: [
        {
            name: '老客',
            type: 'bar',
            stack: 'total',
            color: '#0052FF',
            // label: {
            //     show: true
            // },
            // emphasis: {
            //     focus: 'series'
            // },
            data: testMonthData.map(item => {
                return item.old_customer;
            }),
        },
        {
            name: '新客',
            type: 'bar',
            stack: 'total',
            color: '#FFDA08',
            // label: {
            //     show: true
            // },
            // emphasis: {
            //     focus: 'series'
            // },
            data: testMonthData.map(item => {
                return item.new_customer;
            }),
        },
    ],
};
const testARPUOptions: EChartsOption = {
    tooltip: {
        trigger: 'axis',
    },
    grid: {
        left: '0%',
        right: '0%',
        bottom: '15%',
        width: '100%',
        height: '70%',
        containLabel: true,
    },
    legend: {
        bottom: '0',
        height: '10%',
        itemWidth: 10,
        itemHeight: 10,
        icon: 'rect',
        itemGap: 20,
        textStyle: {
            color: '#6E727A',
        },
    },
    xAxis: {
        type: 'category',
        data: testMonthARPUData.map(item => {
            return item.month;
        }),
    },
    yAxis: {
        type: 'value',
    },
    series: [
        {
            name: '老客',
            type: 'line',
            stack: 'total',
            symbol: 'none',
            color: '#0052FF',
            // label: {
            //     show: true
            // },
            // emphasis: {
            //     focus: 'series'
            // },
            data: testMonthARPUData.map(item => {
                return item.old_customer;
            }),
        },
        {
            name: '新客',
            type: 'line',
            stack: 'total',
            symbol: 'none',
            color: '#F5A623',
            // label: {
            //     show: true
            // },
            // emphasis: {
            //     focus: 'series'
            // },
            data: testMonthARPUData.map(item => {
                return item.new_customer;
            }),
        },
    ],
};
const PerformanceNewOldCustomer: React.FC<{
    asData?: IOptAnalysisCompositeData;
    asLoading: boolean;
    asFilterData: IAchievementSituationFilterData;
    setAsFilterData: (fData: IAchievementSituationFilterData) => void;
}> = props => {
    const { asData, asLoading, asFilterData, setAsFilterData } = props;
    const [performanceNewOldData, setPerformanceNewOldData] = useState<
        IPerformanceCustomerData[]
    >([]);
    const [performanceRateLoading, setPerformanceRateLoading] = useState(false);

    useEffect(() => {
        fetchPerformanceNewOldData();
    }, []);

    const fetchPerformanceNewOldData = async () => {
        const url = '/crm/data/query/list_income_customer_m';
        const payloadData = {};
        setPerformanceRateLoading(true);
        const [d, e] = await defaultAxios.put(url, payloadData);
        setPerformanceRateLoading(false);
        if (d === null || d.data === null) {
            message.error('请求业绩目标数据失败～');
            return [null, e];
        }
        if (d.data && Array.isArray(d.data)) {
            setPerformanceNewOldData(d.data || []);
        }
    };

    const performanceRateOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'line', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        legend: {
            bottom: '0',
            height: '10%',
            itemWidth: 10,
            itemHeight: 10,
            itemGap: 20,
            icon: 'rect',
            textStyle: {
                color: '#6E727A',
            },
        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '15%',
            width: '100%',
            height: '70%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: performanceNewOldData.map(item => {
                return moment(item.income_month, 'YYYYMM').format('MMMYYYY');
            }),
            boundaryGap: false,
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: '老客',
                type: 'line',
                symbol: 'none',
                color: '#0052FF',
                // label: {
                //     show: true
                // },
                // emphasis: {
                //     focus: 'series'
                // },
                data: performanceNewOldData.map(item => {
                    return trans2W(item.old_customer_money);
                }),
            },
            {
                name: '新客',
                type: 'line',
                symbol: 'none',
                color: '#F5A623',
                // label: {
                //     show: true
                // },
                // emphasis: {
                //     focus: 'series'
                // },
                data: performanceNewOldData.map(item => {
                    return trans2W(item.new_customer_money);
                }),
            },
        ],
    };
    const customerNumberChangeOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'line', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        legend: {
            bottom: '0',
            height: '10%',
            itemWidth: 10,
            itemHeight: 10,
            itemGap: 20,
            icon: 'rect',
            textStyle: {
                color: '#6E727A',
            },
        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '15%',
            width: '100%',
            height: '70%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: performanceNewOldData.map(item => {
                return moment(item.income_month, 'YYYYMM').format('MMMYYYY');
            }),
            boundaryGap: false,
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: '老客',
                type: 'line',
                symbol: 'none',
                color: '#0052FF',
                // label: {
                //     show: true
                // },
                // emphasis: {
                //     focus: 'series'
                // },
                data: performanceNewOldData.map(item => {
                    return item.old_customer_num;
                }),
            },
            {
                name: '新客',
                type: 'line',
                symbol: 'none',
                color: '#F5A623',
                // label: {
                //     show: true
                // },
                // emphasis: {
                //     focus: 'series'
                // },
                data: performanceNewOldData.map(item => {
                    return item.new_customer_num;
                }),
            },
        ],
    };
    const arpuChangeOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'line', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        grid: {
            left: '3%',
            right: '0%',
            bottom: '15%',
            width: '100%',
            height: '70%',
            containLabel: true,
        },
        legend: {
            bottom: '0',
            height: '10%',
            itemWidth: 10,
            itemHeight: 10,
            icon: 'rect',
            itemGap: 20,
            textStyle: {
                color: '#6E727A',
            },
        },
        xAxis: {
            type: 'category',
            data: performanceNewOldData.map(item => {
                return moment(item.income_month, 'YYYYMM').format('MMMYYYY');
            }),
            boundaryGap: false,
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: '老客',
                type: 'line',
                symbol: 'none',
                color: '#0052FF',
                // label: {
                //     show: true
                // },
                // emphasis: {
                //     focus: 'series'
                // },
                data: performanceNewOldData.map(item => {
                    return Math.round(item.old_customer_arpu / 100) / 100;
                }),
            },
            {
                name: '新客',
                type: 'line',
                symbol: 'none',
                color: '#F5A623',
                // label: {
                //     show: true
                // },
                // emphasis: {
                //     focus: 'series'
                // },
                data: performanceNewOldData.map(item => {
                    return Math.round(item.new_customer_arpu / 100) / 100;
                }),
            },
        ],
    };
    const statisticValueStyle: CSSProperties = {
        fontSize: '16px',
        // color: '#0052ff',
        fontWeight: 500,
    };
    const statisticCanClickValueStyle: CSSProperties = {
        fontSize: '16px',
        color: '#0052ff',
        fontWeight: 500,
        cursor: 'pointer',
    };
    return (
        <div className="performance-new-old-customer-content">
            <div className="p-n-o-c-header">
                <div>
                    <span className="n-o-c-title">业绩分析-新/老客</span>
                    {/* <span className="n-o-c-time">
                        （更新时间：
                        {moment()
                            .startOf('day')
                            .hour(1)
                            .format('YYYY-MM-DD HH:mm')}
                        ）
                    </span> */}
                </div>
                <div className="a-s-f-filter">
                    <div className="a-s-f-item">
                        <label htmlFor="">区域</label>
                        <Select
                            size="small"
                            value={asFilterData.area}
                            onChange={(value: any) => {
                                setAsFilterData({
                                    ...asFilterData,
                                    area: value,
                                });
                            }}
                            style={{
                                minWidth: '80px',
                            }}
                        >
                            {_.map(areaOptions, option => {
                                return (
                                    <Select.Option value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div className="a-s-f-item">
                        <label htmlFor="">时间</label>
                        <Select
                            size="small"
                            value={asFilterData.range}
                            onChange={(value: any) => {
                                setAsFilterData({
                                    ...asFilterData,
                                    range: value,
                                });
                            }}
                            style={{
                                minWidth: '80px',
                            }}
                        >
                            {_.map(rangeOptions, option => {
                                return (
                                    <Select.Option value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
            </div>
            <div className="p-n-o-c-statics-content">
                {_.map(newOldCustomerAnaValueMap, item => {
                    return (
                        <div className="c-o-a-static-item">
                            {asData?.[item.key] ? (
                                <div className="c-o-a-statics-data">
                                    <Statistic
                                        style={statisticValueStyle}
                                        groupSeparator={','}
                                        suffix={item.prodText}
                                        value={
                                            asData?.[item.key]
                                                ? item.formatter(
                                                      asData?.[item.key],
                                                  )
                                                : '- -'
                                        }
                                    />
                                </div>
                            ) : (
                                <Statistic
                                    style={statisticValueStyle}
                                    groupSeparator={','}
                                    value={'- -'}
                                />
                            )}
                            <p className="c-o-a-static-title">{item.title}</p>
                        </div>
                    );
                })}
            </div>
            <div className="p-n-o-c-charts-content">
                <div className="p-n-o-c-charts-row">
                    <CompositeLiveChartsComponents
                        chartsClassName="p-n-o-c-charts-content"
                        loading={performanceRateLoading}
                        chartsOptions={performanceRateOptions}
                        title={'新老业绩变化趋势'}
                        extraRight={'单位：万元'}
                    />
                    <CompositeLiveChartsComponents
                        chartsClassName="p-n-o-c-charts-content"
                        loading={performanceRateLoading}
                        chartsOptions={customerNumberChangeOptions}
                        title={'客户数量变化趋势'}
                    />
                    <CompositeLiveChartsComponents
                        chartsClassName="p-n-o-c-charts-content"
                        loading={performanceRateLoading}
                        chartsOptions={arpuChangeOptions}
                        extraRight={'单位：万元'}
                        title={'ARPU值变化趋势'}
                    />
                </div>
                <div className="p-n-o-c-charts-row pre">
                    <CompositeLiveChartsComponents
                        chartsClassName="p-n-o-c-charts-content"
                        containerClassName="p-n-o-c-charts-pre"
                        loading={false}
                        chartsOptions={{}}
                        showPre={true}
                        title={'新客成交周期变化趋势'}
                    />
                    <CompositeLiveChartsComponents
                        chartsClassName="p-n-o-c-charts-content"
                        containerClassName="p-n-o-c-charts-pre"
                        loading={false}
                        chartsOptions={{}}
                        showPre={true}
                        title={'续约率变化趋势'}
                    />
                    <CompositeLiveChartsComponents
                        chartsClassName="p-n-o-c-charts-content"
                        containerClassName="p-n-o-c-charts-pre"
                        loading={false}
                        chartsOptions={{}}
                        showPre={true}
                        title={'续费率变化趋势'}
                    />
                </div>
            </div>
        </div>
    );
};
export default PerformanceNewOldCustomer;
