import React, { useEffect, memo, useState } from 'react';

import { OptListStore } from '../store';
import { Modal, Alert } from 'antd';
import { tryRefresh } from '@/utils';
import { observer } from 'mobx-react-lite';

export const ActionDelete: React.FC<{
    defaultStore: OptListStore;
}> = observer(({ defaultStore }) => {
    const { mutatingData } = defaultStore;
    const [loading, setLoading] = useState(false);
    if (mutatingData === null) {
        return null;
    }
    const visible = defaultStore.actionType === 'delete';
    return (
        <Modal
            visible={visible}
            title={'删除商机'}
            onCancel={() => {
                defaultStore.resetAction();
            }}
            confirmLoading={loading}
            onOk={async () => {
                setLoading(true);
                const ok = await defaultStore.delete();
                setLoading(false);
                if (ok) {
                    defaultStore.resetAction();
                    tryRefresh(['opt']);
                }
            }}
        >
            <p>
                是否删除商机【{mutatingData.opportunity_name || 'unknow'}
                】？该商机目前赢率
                <b>
                    {mutatingData?.opportunity_sale_stage
                        ? mutatingData?.opportunity_sale_stage?.relatedObj
                              ?.win_rate + '%'
                        : '- -'}
                </b>
            </p>
            <Alert type="error" message="删除成功之后，该操作无法恢复。" />
        </Modal>
    );
});
