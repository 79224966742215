import React, { useState, useEffect, useContext, CSSProperties } from 'react';
import './index.less';
import { StoreContext } from '@/stores';
import { observer } from 'mobx-react-lite';
import {
    trans2W,
    trans2DotTwo,
    trans2WDotTwo,
} from '@/pages/analysis/dashboard/composite-live/component/achievement-situation';
import { Statistic, Spin, Select, Icon } from 'antd';
import _ from 'lodash';
import qs from 'querystring';
import {
    homeRangeOptions,
    homeTargetLevelOptions,
    entityCodeToUrl,
} from '../../store';
export const goToOtherPage = (key: string, type?: string, keyType?: string) => {
    const body: any = {};
    if (type) {
        body.fastfilter = type;
    }
    if (keyType) {
        body.filterKey = keyType;
    }
    window.open(entityCodeToUrl[key] + '?' + qs.stringify(body), '_blank');
};
const HomeAnaOpt: React.FC = () => {
    const store = useContext(StoreContext);
    const homeNewStore = store.getDefaultHomeNewStore();
    const {
        setHomeNewFilterData,
        homeNewFilterData,
        homeNewSelectedData,
        homeNewAnaOptData,
        setHomeNewAnaOptData,
        homeNewAnaOptDataLoading,
        getHomeNewAnaOptData,
    } = homeNewStore;
    useEffect(() => {
        getHomeNewAnaOptData();
    }, [
        homeNewFilterData.range,
        homeNewFilterData.targetLevel,
        homeNewSelectedData,
    ]);
    const statisticValueStyle: CSSProperties = {
        fontSize: '16px',
        // color: '#0052ff',
        fontWeight: 500,
    };
    const statisticCanClickValueStyle: CSSProperties = {
        fontSize: '16px',
        color: '#0052ff',
        fontWeight: 500,
        cursor: 'pointer',
    };
    const setLocalStorageUserByKey = (key: string) => {
        localStorage.setItem(
            key,
            JSON.stringify(
                _.map(homeNewAnaOptData.userInfoList, uItem => {
                    return {
                        id: uItem.userId,
                        key: `user-${uItem.userId}`,
                        name: uItem.userName,
                        title: uItem.userName,
                    };
                }),
            ),
        );
    };
    const optAnalysisValueMap: {
        title: string;
        key: string;
        formatter: any;
        preText?: string;
        prodText?: string;
        onClick?: () => void;
    }[] = [
        {
            title: '60+80+90商机金额',
            key: 'opportunity_amount_60_80_90',
            formatter: (v: any) => `${trans2W(v)}`,
            prodText: 'w',
            onClick: () => {
                setLocalStorageUserByKey(
                    `mine_608090_opt_${homeNewFilterData.range}`,
                );
                goToOtherPage(
                    'opportunity',
                    '',
                    `mine_608090_opt_${homeNewFilterData.range}`,
                );
            },
        },
        {
            title: '目标GAP',
            key: 'income_gap',
            formatter: (v: any) => `${trans2W(v)}`,
            prodText: 'w',
        },
        {
            title: '商机缺口',
            key: 'opportunity_gap',
            formatter: (v: any) => `${trans2W(v)}`,
            prodText: 'w',
        },
        {
            title: '商机缺口占比',
            key: 'opportunity_gap_rate',
            formatter: (v: any) => `${trans2DotTwo(v)}`,
            prodText: '%',
        },
        {
            title: '20-90商机金额合计',
            key: 'opportunity_amount',
            formatter: (v: any) => `${trans2W(v)}`,
            prodText: 'w',
            onClick: () => {
                setLocalStorageUserByKey(
                    `mine_amount_opt_${homeNewFilterData.range}`,
                );
                goToOtherPage(
                    'opportunity',
                    '',
                    `mine_amount_opt_${homeNewFilterData.range}`,
                );
            },
        },
        // {
        //     title: '80商机金额',
        //     key: 'opportunity_amount_80',
        //     formatter: (v: any) => `${trans2W(v)}`,
        //     prodText: 'W',
        //     onClick: () => {
        //         setLocalStorageUserByKey(`mine_80_opt_${homeNewFilterData.range}`);
        //         goToOtherPage(
        //             'opportunity',
        //             '',
        //             `mine_80_opt_${homeNewFilterData.range}`,
        //         );
        //     },
        // },
    ];
    const renderTipsText = () => {
        const tipsRenderArray = [
            {
                title: '90商机',
                key: 'opportunity_amount_90',
                rateKey: 'opportunity_amount_90_rate',
                increaseKey: null,
                formatter: (v: any) => `${trans2W(v)}`,
                prodText: 'w',
                onClick: () => {
                    setLocalStorageUserByKey(
                        `mine_90_opt_${homeNewFilterData.range}`,
                    );
                    goToOtherPage(
                        'opportunity',
                        '',
                        `mine_90_opt_${homeNewFilterData.range}`,
                    );
                },
            },
            {
                title: '80商机',
                key: 'opportunity_amount_80',
                rateKey: 'opportunity_amount_80_rate',
                increaseKey: null,
                formatter: (v: any) => `${trans2W(v)}`,
                prodText: 'w',
                onClick: () => {
                    setLocalStorageUserByKey(
                        `mine_80_opt_${homeNewFilterData.range}`,
                    );
                    goToOtherPage(
                        'opportunity',
                        '',
                        `mine_80_opt_${homeNewFilterData.range}`,
                    );
                },
            },
            {
                title: '60商机',
                key: 'opportunity_amount_60',
                rateKey: 'opportunity_amount_60_rate',
                formatter: (v: any) => `${trans2W(v)}`,
                increaseKey: null,
                prodText: 'w',
                onClick: () => {
                    setLocalStorageUserByKey(
                        `mine_60_opt_${homeNewFilterData.range}`,
                    );
                    goToOtherPage(
                        'opportunity',
                        '',
                        `mine_60_opt_${homeNewFilterData.range}`,
                    );
                },
            },
            {
                increaseKey: 'opportunity_40_week_increase',
                increaseOnClick: () => {
                    setLocalStorageUserByKey('mine_week_increase_40_opt');
                    goToOtherPage(
                        'opportunity',
                        '',
                        'mine_week_increase_40_opt',
                    );
                },
                key: 'opportunity_amount_40',
                rateKey: 'opportunity_amount_40_rate',
                formatter: (v: any) => `${trans2W(v)}`,
                prodText: 'w',
                title: '40商机',
                onClick: () => {
                    setLocalStorageUserByKey(
                        `mine_40_opt_${homeNewFilterData.range}`,
                    );
                    goToOtherPage(
                        'opportunity',
                        '',
                        `mine_40_opt_${homeNewFilterData.range}`,
                    );
                },
            },
            {
                increaseKey: 'opportunity_20_week_increase',
                increaseOnClick: () => {
                    setLocalStorageUserByKey('mine_week_increase_20_opt');
                    goToOtherPage(
                        'opportunity',
                        '',
                        'mine_week_increase_20_opt',
                    );
                },
                key: 'opportunity_amount_20',
                rateKey: 'opportunity_amount_20_rate',
                formatter: (v: any) => `${trans2W(v)}`,
                prodText: 'w',
                title: '20商机',
                onClick: () => {
                    setLocalStorageUserByKey(
                        `mine_20_opt_${homeNewFilterData.range}`,
                    );
                    goToOtherPage(
                        'opportunity',
                        '',
                        `mine_20_opt_${homeNewFilterData.range}`,
                    );
                },
            },
        ];
        return (
            <span className="c-o-a-tips-all">
                商机赢率占比：
                {_.map(tipsRenderArray, (tipsItem, index) => {
                    return (
                        <span className="h-c-o-a-tips-item" key={tipsItem.key}>
                            <span
                                className="h-c-o-a-tips-title"
                                onClick={tipsItem.onClick}
                            >
                                {tipsItem.title}
                            </span>
                            {homeNewAnaOptData?.[tipsItem.key] ? (
                                <Statistic
                                    value={`${trans2W(
                                        homeNewAnaOptData?.[tipsItem.key],
                                    )}${tipsItem.prodText}`}
                                    style={{
                                        fontSize: '12px',
                                        // color: '#6E727A',
                                        display: 'inline',
                                    }}
                                    // suffix={tipsItem.prodText || ''}
                                ></Statistic>
                            ) : (
                                <Statistic
                                    value={'- -'}
                                    style={{
                                        fontSize: '12px',
                                        color: '#6E727A',
                                        display: 'inline',
                                    }}
                                ></Statistic>
                            )}
                            {/* {tipsItem.rateKey
                                ? `（占比${
                                      homeNewAnaOptData?.[tipsItem.rateKey]
                                          ? trans2DotTwo(
                                                homeNewAnaOptData?.[
                                                    tipsItem.rateKey
                                                ],
                                            )
                                          : '- -'
                                  }%）`
                                : null} */}
                            <span
                                className="h-c-o-a-tips-inc"
                                onClick={tipsItem.increaseOnClick}
                            >
                                {tipsItem.increaseKey &&
                                !_.isNil(
                                    homeNewAnaOptData?.[tipsItem.increaseKey],
                                )
                                    ? `，本周新增${
                                          homeNewAnaOptData?.[
                                              tipsItem.increaseKey
                                          ]
                                      }个`
                                    : null}
                            </span>
                            {tipsRenderArray.length - 1 === index ? '。' : '；'}
                        </span>
                    );
                })}
            </span>
        );
    };
    return (
        <Spin spinning={homeNewAnaOptDataLoading}>
            <div className="composite-opt-analysis-content">
                <div className="c-o-a-header">
                    <span className="c-o-a-title">业绩储备-商机分析</span>
                    {/* <AntButton>生成报告</AntButton> */}
                    <div className="a-s-f-filter">
                        {homeNewSelectedData.selectedLevel === 0 && (
                            <div className="a-s-f-item">
                                <label htmlFor="">目标级别</label>
                                <Select
                                    size="small"
                                    value={homeNewFilterData.targetLevel}
                                    onChange={(value: any) => {
                                        setHomeNewFilterData({
                                            ...homeNewFilterData,
                                            targetLevel: value,
                                        });
                                    }}
                                    style={{
                                        minWidth: '70px',
                                    }}
                                >
                                    {_.map(homeTargetLevelOptions, option => {
                                        return (
                                            <Select.Option value={option.value}>
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        )}
                        <div className="a-s-f-item">
                            <label htmlFor="">时间</label>
                            <Select
                                size="small"
                                value={homeNewFilterData.range}
                                onChange={(value: any) => {
                                    setHomeNewFilterData({
                                        ...homeNewFilterData,
                                        range: value,
                                    });
                                }}
                                style={{
                                    minWidth: '80px',
                                }}
                            >
                                {_.map(homeRangeOptions, option => {
                                    return (
                                        <Select.Option value={option.value}>
                                            {option.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="c-o-a-body">
                    <div className="c-o-a-static-content">
                        {_.map(optAnalysisValueMap, item => {
                            const isJumpItem =
                                item.onClick &&
                                typeof item.onClick === 'function';
                            return (
                                <div className="c-o-a-static-item">
                                    {homeNewAnaOptData?.[item.key] ? (
                                        <div
                                            className={`c-o-a-statics-data`}
                                            onClick={
                                                item.onClick
                                                    ? item.onClick
                                                    : undefined
                                            }
                                        >
                                            <Statistic
                                                style={
                                                    // isJumpItem
                                                    //     ? statisticCanClickValueStyle
                                                    statisticValueStyle
                                                }
                                                groupSeparator={','}
                                                suffix={item.prodText || ''}
                                                value={
                                                    homeNewAnaOptData?.[
                                                        item.key
                                                    ]
                                                        ? item.formatter(
                                                              homeNewAnaOptData?.[
                                                                  item.key
                                                              ],
                                                          )
                                                        : '- -'
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <Statistic
                                            style={statisticValueStyle}
                                            groupSeparator={','}
                                            value={'- -'}
                                        />
                                    )}
                                    <p className="c-o-a-static-title">
                                        {item.title}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    <div className="c-o-a-alert-content">
                        <Icon type="info-circle" style={{ color: '#0052FF' }} />
                        {renderTipsText()}
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default observer(HomeAnaOpt);
