import React, { useState } from 'react';
import './index.less';
import FlexibleContent from '../flexible-content';
import { observer } from 'mobx-react-lite';
import { Rate, Icon, message } from 'antd';
import { AntInputTextArea } from '@/components/antd';
import { AntButton } from '../../antd/index';
import { defaultAxios, netLog } from '@/utils';
export interface IFeedBackModalProps {}
const RateToDesc = ['非常不满意', '不满意', '一般', '满意', '非常满意'];
const RateToImg = [
    `url('${process.env.PUBLIC_URL}/imgs/rate-img/rating-emoji-1.gif')`,
    `url('${process.env.PUBLIC_URL}/imgs/rate-img/rating-emoji-2.gif')`,
    `url('${process.env.PUBLIC_URL}/imgs/rate-img/rating-emoji-3-v2.gif')`,
    `url('${process.env.PUBLIC_URL}/imgs/rate-img/rating-emoji-4.gif')`,
    `url('${process.env.PUBLIC_URL}/imgs/rate-img/rating-emoji-5-v2.gif')`,
];
const defaultRateImg = `url('${process.env.PUBLIC_URL}/imgs/rate-img/rating-default-emoji.png`;
const FeedBackModal: React.FC<IFeedBackModalProps> = props => {
    const [feedbackModalVis, setFeedbackModalVis] = useState(true); // 默认是显示的
    const [feedbackModalStretch, setFeedbackModalStretch] = useState(false);
    const [rateValue, setRateValue] = useState(1);
    const [isAnyChange, setIsAnyChange] = useState(false); // 是否已经点选过
    const [rateHoverValue, setRateHoverValue] = useState<number | null>(null);
    const [feedbackText, setFeedbackText] = useState<string>('');
    const feedbackHandle = async () => {
        if (!rateValue) {
            message.warning('请先评分～');
            return;
        }
        const url = '/crm/system/rating';
        const [d, e] = await defaultAxios.post(url, {
            rating: rateValue,
            content: feedbackText,
            platform: 'pc',
        });
        if (e || !d) {
            netLog({
                linkUrl: '/crm/system/rating',
                method: 'post',
                payload: {
                    rating: rateValue,
                    content: feedbackText,
                    platform: 'pc',
                },
                isError: true,
                errorMsg: e?.message,
            });
            message.error(d.msg || '出错了哦～请稍后再试', 1);
        } else {
            onFeedBackModalClose();
            message.success('您的反馈我们已经收到 :)', 1);
        }
    };
    const renderShrinkComponent = () => {
        return (
            <div className="feedback-shrink">
                <Icon type="message" />
                <div className="feedback-shrink-text">欢迎反馈</div>
            </div>
        );
    };
    const onFeedBackModalClose = () => {
        setIsAnyChange(false);
        setRateValue(1);
        setRateHoverValue(null);
        setFeedbackText('');
        setFeedbackModalStretch(false);
    };
    return (
        <FlexibleContent
            shrink={renderShrinkComponent()}
            isStretch={feedbackModalStretch}
            visible={feedbackModalVis}
            setVisible={setFeedbackModalVis}
            setIsStretch={setFeedbackModalStretch}
            tipClassName={'feedback-shrink-content'}
            contentClassName={'feedback-stretch-content'}
            width={'400px'}
            style={{
                top: 'unset',
                bottom: '10%',
            }}
        >
            <div className="feedback-content">
                <div className="close-icon" onClick={onFeedBackModalClose}>
                    <Icon type="close" />
                </div>
                <p className="feedback-title">您对CRM系统的使用体验满意吗？</p>
                {!isAnyChange ? (
                    <div
                        className="feedback-img"
                        style={{
                            backgroundImage: rateHoverValue
                                ? RateToImg[rateHoverValue - 1]
                                : defaultRateImg,
                        }}
                    ></div>
                ) : null}
                <div className="rate-content">
                    <Rate
                        onHoverChange={setRateHoverValue}
                        // tooltips={RateToDesc}
                        value={rateValue}
                        onChange={val => {
                            if (!val) {
                                // 不能取消
                            } else {
                                setRateValue(val);
                            }
                            if (!isAnyChange) {
                                setIsAnyChange(true);
                            }
                        }}
                    />
                    <div className="feedback-rate-text">
                        {!isAnyChange
                            ? rateHoverValue
                                ? RateToDesc[rateHoverValue - 1]
                                : ''
                            : RateToDesc[rateValue - 1]}
                    </div>
                </div>
                {isAnyChange ? (
                    <>
                        <div
                            className="feedback-text-area"
                            key={rateValue > 3 ? 2 : 1}
                        >
                            <p className="feedback-textarea-title">
                                您觉得我们还有哪些地方需要
                                {rateValue > 3 ? '改善' : '改进'}？
                            </p>
                            <AntInputTextArea
                                autoFocus
                                value={feedbackText}
                                onChange={e => {
                                    setFeedbackText(e.target.value);
                                }}
                                rows={4}
                            />
                        </div>
                        <div className="feedback-action-footer">
                            <AntButton onClick={feedbackHandle} type="primary">
                                提交
                            </AntButton>
                        </div>
                    </>
                ) : null}
            </div>
        </FlexibleContent>
    );
};

export default observer(FeedBackModal);
