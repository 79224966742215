import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    AntButton,
    loadingWrapperOver,
} from '@/components';
import { Icon, Tooltip, message } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, useBeforeFirstDataLoaded } from '@/utils';
import { observer } from 'mobx-react-lite';
import DemoTable from './components/table';
import { ActionDelete } from './components/delete';
import { ActionMDelete } from './components/m-delete';
import { runInAction } from 'mobx';
import {
    defaultAuthDeco,
    dFilterHOC,
    useDefaultDFilterSnippets,
} from '@/containers/hoc';
import './index.scss';
import { MOpResultDisplayContainer } from '@/components';
import FormDrawer from './components/form-drawer';
import classname from 'classname';
import { CtxProvider } from '@/utils/context';
import { FinalProcessFormProxy } from '@/pages/sales-follow-up/process/list/form-proxy';
import { genContractIdParamByContractItemAndParamMeta } from './store';
import { ActionPreOpen } from './components/pre-openproduct';
import { ActionMark } from './components/mark-modal';
import { ActionExport } from '@/pages/sales-follow-up/activation-record/list/components/export';
import { setFilterByUrlKey, useFastFilterByUrl } from '@/stores/utils';

const DemoList: React.FC<RouteComponentProps & {
    type: string;
    dfilters?: any;
}> = ({ type = 'all', dfilters }) => {
    const store = useContext(StoreContext);
    const [defaultStore] = useState(() => new store.ContractListStore());
    const userStore = store.getAuthStore();

    const ack = dtext('crm')('contract-list-message-' + type, '');

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        pageTitle,
        defaultPerm,
        defaultFilter,
        defaultMSelect,
        defaultFastFilters,
        condListbyFilterData,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const listFetch = useCallback(() => defaultStore.fetch(), [defaultStore]);
    const [error, loading, reload] = useNet(listFetch, {
        autoLoad: false,
        refreshKeys: ['contract', 'raw-process'],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            defaultMeta.setTableId('contract');
            defaultMeta.setOpUsername(userStore.userInfo.userId + '-' + type);
            defaultFastFilters.setFilters(dfilters);
            Promise.all([
                defaultMeta.fetch(),
                defaultPerm.fetch(),
                defaultStore.getContractProcessContext(
                    userStore.userInfo.userId as string,
                ),
            ])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {});
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            setFilterByUrlKey(defaultFilter);
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useEffect(() => {
        runInAction(() => {
            defaultFastFilters.setType(type);
            defaultStore.setListType(type);
        });
    }, [type]);

    const [isBeforeFirstLoaded] = useBeforeFirstDataLoaded(defaultStore);

    const { title, fastFilters } = useDefaultDFilterSnippets(defaultStore);

    // if (!preRequiredReady) {
    //     return <BlockLoading2 />;
    // }
    const finalLoading = isBeforeFirstLoaded || !preRequiredReady;

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );
    useFastFilterByUrl(defaultFastFilters);

    const popups = (
        <>
            <MOpResultDisplayContainer defaultStore={defaultStore} />
            <ActionPreOpen defaultStore={defaultStore} />
            <ActionMark defaultStore={defaultStore} />
            <ActionDelete defaultStore={defaultStore} />
            <ActionMDelete defaultStore={defaultStore} />
            <ActionExport defaultStore={defaultStore} />
            <FormDrawer
                defaultStore={defaultStore}
                preRequiredReady={preRequiredReady}
            />

            <CtxProvider
                ctx={{ position: 'list', positionDetail: 'contract-product' }}
            >
                <FinalProcessFormProxy
                    visible={
                        defaultStore.actionType === 'contract_invoice_apply'
                    }
                    onClose={() => {
                        defaultStore.setMutatingData(null);
                        defaultStore.resetAction();
                    }}
                    key={
                        defaultStore.actionType === 'contract_invoice_apply'
                            ? 'opened'
                            : 'nooppened'
                    }
                    processKey={'contractinvoice'}
                    lifeCycle={{
                        onClose: store => {
                            store.setMutatingData(null);
                            defaultStore.setMutatingData(null);
                            defaultStore.resetAction();
                        },
                        onOpen: store => {
                            if (!defaultStore.mutatingData) {
                                return;
                            }

                            store.startNewData();
                            const contractId = genContractIdParamByContractItemAndParamMeta(
                                defaultStore.mutatingData,
                            );
                            console.log('contractIdcontractId', contractId);

                            // 自动触发回填，发票资质
                            _.assign(store.mutatingData, {
                                contract_id: contractId,
                                customer_id:
                                    defaultStore.mutatingData?.customer_id,
                            });
                        },
                    }}
                    preRequestReady={!!defaultStore.mutatingData}
                />
            </CtxProvider>
        </>
    );

    const globalOps = (
        <>
            {defaultPerm.getPermByTypeAndAction(defaultStore.listType)('export')
                .visible && (
                <AntButton
                    onClick={() => {
                        defaultStore.setAction('export');
                    }}
                    size="large"
                >
                    导出
                </AntButton>
            )}
        </>
    );
    // const globalOps = (
    //     <>
    //         {defaultPerm.permMap['om:contract:create']?.visible && (
    //             <AntButton
    //                 onClick={() => {
    //                     runInAction(() => {
    //                         defaultStore.setAction('create');
    //                         defaultStore.startNewData();
    //                     });
    //                 }}
    //                 type="primary"
    //                 size="large"
    //             >
    //                 新建合同
    //             </AntButton>
    //         )}
    //     </>
    // );
    const multipleOps = (
        <div
            className={classname({
                'mselected-ops': true,
                'multiple-mode': defaultMSelect.isMSelectionMode,
                'normal-mode': !defaultMSelect.isMSelectionMode,
            })}
        >
            <Tooltip title="取消多选">
                <span
                    onClick={() => {
                        defaultMSelect.setMSelectedData([]);
                    }}
                    style={{
                        padding: 5,
                        cursor: 'pointer',
                    }}
                >
                    <Icon type="close" />
                </span>
            </Tooltip>
            <div>
                <span>已选{defaultMSelect.mSeletedData.length}条：</span>
            </div>
            {defaultPerm.getPermByTypeAndAction(type)('delete').visible && (
                <AntButton
                    onClick={() => {
                        defaultStore.setAction('m-delete');
                    }}
                    size="large"
                    checkBody={{
                        tableCode: 'contract',
                        body: {
                            dataAuthCode: 'DEL',
                            ids: defaultMSelect.mSeletedData
                                .map(item => item.id)
                                .filter(Boolean),
                        },
                    }}
                >
                    删除
                </AntButton>
            )}
        </div>
    );

    const dataTable = (
        <DemoTable defaultStore={defaultStore} loading={loading} />
    );

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters: filtersDisplay,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay: fastFilters,
    };

    return loadingWrapperOver(finalLoading)(
        <LayoutStandardCrud className="page-contract-list" {...layoutComs} />,
    );
};

const FinalDemoList = dFilterHOC(observer(DemoList), 'contract');
export default FinalDemoList;
