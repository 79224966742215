import React, { useEffect, useContext, useState } from 'react';
import ContractForm from '@/pages/om/contract/list/components/form-drawer';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { tryRefresh } from '@/utils';
import { useAnyContractListStore } from '@/pages/om/contract/list/store';
import { StoreContext } from '@/stores';

export const OptContractCreate: React.FC<{
    trigger: number;
    optInfo?: any;
    outerCtxInfo?: any;
}> = observer(({ trigger, optInfo, outerCtxInfo }) => {
    optInfo = optInfo || {};
    const { id: optId } = optInfo;
    const store = useContext(StoreContext);
    const userStore = store.getAuthStore();

    const [processCtxReady, setProcessCtxReady] = useState(false);
    const [prepared, defaultStore, prepareStore] = useAnyContractListStore({
        opUserSuffix: 'opt-drawer',
        autoLoad: false,
    });
    const { actionType } = defaultStore;

    const [fillOk, setFillOk] = useState(false);
    const [fillLoading, setFillLoading] = useState(false);

    useEffect(() => {
        if (fillLoading) {
            return;
        }

        if (trigger > 0 && userStore.userInfo) {
            prepareStore();
            defaultStore
                .getContractProcessContext(userStore.userInfo.userId as string)
                .then(ok => {
                    if (ok) {
                        setProcessCtxReady(true);
                    }
                });
            runInAction(() => {
                defaultStore.setAction('create');
            });
        }
    }, [fillLoading, trigger, userStore.userInfo]);

    // TODO：没有optId仍然可以支持
    useEffect(() => {
        if (
            fillLoading ||
            !prepared ||
            actionType !== 'create' ||
            trigger <= 0
        ) {
            return;
        }
        setFillLoading(true);
        setFillOk(false);
        defaultStore.startNewData();
        if (optId) {
            defaultStore
                .askAutoFillingDataByOpt({ originalValue: optId })
                .then(ok => {
                    setFillLoading(false);
                    setFillOk(ok);
                });
        } else {
            Promise.resolve().then(() => {
                setFillLoading(false);
                setFillOk(true);
            });
        }
    }, [prepared, trigger, actionType, optId]);

    if (trigger === 0) {
        return null;
    }

    const preRequiredReady = prepared && processCtxReady;

    return (
        <ContractForm
            mutateSuccess={() => {
                tryRefresh(['opt']);
            }}
            outerCancel={() => {
                setFillOk(false);
            }}
            outerVisible={fillOk}
            outerCtxInfo={outerCtxInfo}
            preRequiredReady={preRequiredReady}
            defaultStore={defaultStore}
        />
    );
});
