import { defaultAxios, isNetSuccess } from '@/utils';
interface phoneIpro {
    phone?: string,
    uid?: number
}
export const getCostumerInfo = async (params: phoneIpro) => {
    const [d, e] = await defaultAxios.post(
        `/crm/lead/findSalesCondition`,
        {
            ...params
        },
    );
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = d.data;
    const error = e as Error | null;
    return [data, error];
};