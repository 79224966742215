import React, { useState, useCallback, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { ModalProps } from 'antd/lib/modal';
import { defaultAxios, useNet } from '@/utils';
import { Modal, DatePicker } from 'antd';
import '@/components/common/log-modal/index.scss';
import { TableLv2 } from '@/components';

export interface ISeatBindLogSearchData {
    pageNum: number;
    pageSize: number;
}
export interface ISeatBindLogData {
    license_id: string | number; // 席位id
    u_name: string; // 绑定人
    bind_time: string; // 绑定时间
    u_bind_time: string; // 解绑时间
}
export type TGetCirculationLogReturnType = NetResponse<{
    data: ISeatBindLogData[];
    msg: string;
    code: number;
}>;
export interface ISeatBindLogModalProps extends ModalProps {
    cid?: string | number;
    lid?: string | number;
    modalVis: boolean;
    setModalVis: (bool: boolean) => void;
}
const opTypeToName: {
    [key: string]: any;
} = {
    1: '退回公海池',
    2: '认领',
    3: '分配',
    4: '转移',
    5: '创建',
};
const SeatBindLogModal: React.FC<ISeatBindLogModalProps> = props => {
    const { modalVis, setModalVis, cid, lid } = props;
    const [logCount, setLogCount] = useState(0);
    const [searchLogData, setSearchLogData] = useState<ISeatBindLogSearchData>({
        pageNum: 1,
        pageSize: 20,
    });
    const [seatBindLogData, setSeatBindLogData] = useState<ISeatBindLogData[]>(
        [],
    );
    // const [rangeDateData, setRangeDateData] = useState<
    //     Moment[] | null[] | undefined[]
    // >([
    //     moment()
    //         .subtract(1, 'month')
    //         .startOf('day'),
    //     moment().endOf('day'),
    // ]);
    const fetchBindLog: () => Promise<
        TGetCirculationLogReturnType
    > = async () => {
        if (!modalVis || !cid || !lid) {
            return [null, null];
        }
        const url = '/bff/api/rest/member-list-staging/bind-log';
        const [d, e] = await defaultAxios.get(url, {
            page: searchLogData.pageNum - 1,
            size: searchLogData.pageSize,
            co_id: cid,
            lic_id: lid,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data?.data)) {
            setSeatBindLogData(data?.data);
            if (searchLogData.pageNum === 1) {
                setLogCount(data?.total || 0);
            }
        }
        const error = e as Error | null;
        return [data, error];
    };
    const getLog = useCallback(fetchBindLog, [searchLogData, modalVis]);
    const [logError, logLoading, logReload] = useNet(getLog);
    useEffect(() => {
        if (modalVis && cid && lid) {
            logReload();
        }
    }, [modalVis]);
    useEffect(() => {
        if (modalVis) {
            logReload();
        }
    }, [searchLogData]);
    const hideEntityLogModal = () => {
        setSearchLogData({
            pageNum: 1,
            pageSize: 20,
        });
        setSeatBindLogData([]);
        setModalVis(false);
    };
    const finalModalProps: ModalProps = {
        ...props,
        centered: true,
        width: '600px',
        visible: modalVis,
        onCancel: hideEntityLogModal,
        onOk: hideEntityLogModal,
        title: '席位绑定记录',
        className: `crm-entity-modal-content ${
            props.className ? props.className : ''
        }`,
    };
    // ICirculationLogData {
    //     createTime: string | Moment;
    //     createUser: string;
    //     createUserName: string;
    //     operateType: string;
    //     beforeUserName: string;
    //     userName: string;
    //     becomeReason: string;
    // }
    return (
        <Modal {...finalModalProps}>
            <div className="crm-entity-log-modal-body">
                {/* <div className="crm-entity-log-filter">
                    <span>时间筛选：</span>
                    <DatePicker.RangePicker
                        allowClear={false}
                        value={[...(rangeDateData as [Moment, Moment])]}
                        onChange={(dates, dateString) => {
                            setRangeDateData(dates as Moment[]);
                            // setSearchLogData({
                            //     ...searchLogData,
                            //     beginDate: dates[0] as Moment,
                            //     endDate: dates[1] as Moment
                            // })
                        }}
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder={['Start Time', 'End Time']}
                        onOk={() => {
                            setSearchLogData({
                                ...searchLogData,
                                beginDate: rangeDateData[0] as Moment,
                                endDate: rangeDateData[1] as Moment,
                            });
                        }}
                    />
                </div> */}
                <div className="crm-entity-log-table">
                    <TableLv2<ISeatBindLogData>
                        loading={logLoading}
                        pagination={{
                            pageSize: searchLogData.pageSize,
                            total: logCount,
                            showSizeChanger: false,
                        }}
                        // scroll={{
                        //     y: 350,
                        // }}
                        dataSource={seatBindLogData}
                        onPaginationChange={pagination => {
                            setSearchLogData({
                                ...searchLogData,
                                pageNum:
                                    pagination.current === 0
                                        ? 1
                                        : pagination.current,
                                pageSize: pagination.pageSize as any,
                            });
                        }}
                        closeRowSelection={true}
                        columns={[
                            {
                                key: 'u_name',
                                dataIndex: 'u_name',
                                title: '用户',
                                width: 120,
                            },
                            {
                                key: 'bind_time',
                                dataIndex: 'bind_time',
                                title: '绑定时间',
                                width: 150,
                            },
                            {
                                key: 'u_bind_time',
                                dataIndex: 'u_bind_time',
                                title: '解绑时间',
                                width: 120,
                                render: v => {
                                    return v ? v : '- -';
                                },
                            },
                        ]}
                    ></TableLv2>
                </div>
            </div>
        </Modal>
    );
};
export default SeatBindLogModal;
