import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    AntButton,
    TableLv1,
    getDefaultCellSelector,
} from '@/components';
import { Icon, Tooltip, message, Button } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import {
    useNet,
    delay,
    useQueryAsObject,
    defaultAxios,
    isNetSuccess,
    useRefresh,
    tryRefresh,
} from '@/utils';
import { observer } from 'mobx-react-lite';
import DemoTable from './components/table';
import { ActionDelete } from './components/delete';
import { ActionMDelete } from './components/m-delete';
import { runInAction } from 'mobx';
import { DTitle2 as DTitle2Grouped } from '@/components/common/dtitle2/dtitle2-grouped';
import {
    defaultAuthDeco,
    dFilterHOC,
    useDefaultDFilterSnippets,
} from '@/containers/hoc';
import './index.scss';
import { MOpResultDisplayContainer } from '@/components';
import FormDrawerCreate from './components/form-drawer-create';
import FormDrawerDetail from './components/form-drawer-detail';
import classname from 'classname';
import { ColumnProps } from 'antd/lib/table';
const DemoList: React.FC<RouteComponentProps & {
    type: string;
    dfilters?: any;
}> = ({ type = 'all', dfilters }) => {
    const store = useContext(StoreContext);
    const [defaultStore] = useState(() => new store.ProcessCatStore());
    const userStore = store.getAuthStore();

    const ack = dtext('crm')('process-all-message-' + type, '');

    const {
        tableCode, // 动态列表
        defaultPagination,
        defaultSorter,
        defaultMeta,
        pageTitle,
        defaultPerm,
        defaultFilter,
        defaultMSelect,
        defaultFastFilters,
        condListbyFilterData,
        processDataPagination,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = processDataPagination;

    const { sortersForFetch } = defaultSorter;

    const [fastFilterReady, setFastFilterReady] = useState(false);

    useEffect(() => {
        runInAction(() => {
            defaultFastFilters.setType(type);
            defaultStore.setListType(type);
        });
    }, [type]);

    const { selectedLv4MenuFilters, lv4MenuFilters } = defaultFastFilters;

    // 设置四级菜单
    useEffect(() => {
        defaultFastFilters.setFilters(dfilters);
    }, []);

    const [processStatistics, setProcessStatistics] = useState<{
        [key: string]: any;
    }>({});
    const [
        processStatisticsRefreshTrigger,
        setProcessStatisticsRefreshTrigger,
    ] = useState(0);
    useEffect(() => {
        if (!userStore.userInfo) {
            return;
        }

        (async () => {
            const userId = userStore.userInfo.userId;
            if (userId) {
                const [d, e] = await defaultAxios.get(
                    '/bff/api/rest/home/home-process-data',
                    {
                        currentUser: userId,
                    },
                );
                if (isNetSuccess(d, e)) {
                    const data = d?.data;
                    const nextProcessStatistics = data;
                    setProcessStatistics(nextProcessStatistics);
                }
            }
        })();
    }, [userStore.userInfo, processStatisticsRefreshTrigger]);

    useRefresh(['raw-process'], () => {
        setProcessStatisticsRefreshTrigger(1 + processStatisticsRefreshTrigger);
    });

    // 自动选择第一个四级菜单
    useEffect(() => {
        // 如果没有type或者已经选择了，则无视
        if (
            !defaultFastFilters.type ||
            defaultFastFilters.selectedLv4MenuFilters.length > 0
        ) {
            return;
        }
        const { lv4MenuFilters } = defaultFastFilters;
        if (lv4MenuFilters.length === 0) {
            return;
        }
        defaultFastFilters.setSelectedLv4MenuFilters([
            (lv4MenuFilters as any)[0],
        ]);
        // 首次选择菜单已ready
        setFastFilterReady(true);
    }, [defaultFastFilters.type, selectedLv4MenuFilters, lv4MenuFilters]);

    // 更新刷新方法
    const listFetch = useCallback(() => defaultStore.fetchRawProcess(true), [
        tableCode,
    ]);
    const [error, loading, reload] = useNet(listFetch, {
        autoLoad: false,
        refreshKeys: ['raw-process'],
    });

    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        // 再一次ready，再查询
        if (!fastFilterReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        fastFilterReady,
        selectedLv4MenuFilters,
    ]);

    useEffect(() => {
        if (!userStore.userInfo) {
            return;
        }
        defaultStore.setSysUser(userStore.userInfo.sysUser);
    }, [userStore.userInfo]);

    useQueryAsObject(
        queryObj => {
            const instanceId = queryObj?.id;
            const currentUser = userStore.userInfo?.userId;
            if (!instanceId || !currentUser) {
                return;
            }
            defaultAxios
                .get('/bff/api/rest/process/get-raw-data', {
                    instanceId,
                    currentUser,
                })
                .then(([d, e]) => {
                    if (isNetSuccess(d, e)) {
                        const rawProcessItem = d?.data?.datas?.[0];
                        if (!rawProcessItem) {
                            return;
                        }
                        defaultStore.setAction('see-detail', rawProcessItem);
                    }
                });
        },
        [userStore.userInfo],
    );

    // 快捷筛选
    const dtitleProps: any = {
        title:
            defaultFastFilters.selectedLv4MenuFilters.length === 0
                ? pageTitle
                : defaultFastFilters.selectedLv4MenuFilters[0].label,
    };
    dtitleProps.list = defaultFastFilters.lv4MenuFilters;
    dtitleProps.selected = selectedLv4MenuFilters;
    dtitleProps.onListItemClick = (item: any) => {
        defaultFastFilters.setSelectedLv4MenuFilters([item]);
        defaultStore.setData([]);
        defaultPagination.setPaginationByUI({
            current: 1,
            pageSize: 30,
        });
        defaultPagination.setAmount(0);
    };
    const title = (
        <DTitle2Grouped
            {...dtitleProps}
            processStatistics={processStatistics}
        />
    );

    const popups = (
        <>
            <MOpResultDisplayContainer defaultStore={defaultStore} />
            <ActionDelete defaultStore={defaultStore} />
            <ActionMDelete defaultStore={defaultStore} />
            <FormDrawerCreate
                key={defaultStore.detailTableCode || 'None'}
                defaultStore={defaultStore}
                preRequiredReady={fastFilterReady}
            />
            <FormDrawerDetail
                key={defaultStore.detailTableCode || 'None'}
                defaultStore={defaultStore}
                preRequiredReady={fastFilterReady}
            />
        </>
    );

    const globalOps = (
        <>
            {/* <AntButton
                onClick={() => {
                    tryRefresh(['raw-process']);
                }}
            >
                refresh
            </AntButton> */}
            {defaultPerm !== null &&
                defaultPerm.getPermByTypeAndAction(type)('create').visible &&
                null}
            <AntButton
                onClick={() => {
                    runInAction(() => {
                        defaultStore.setAction('create');
                        defaultStore.startNewData();
                    });
                }}
                type="primary"
                size="large"
            >
                新建流程
            </AntButton>
        </>
    );
    const multipleOps = (
        <div
            className={classname({
                'mselected-ops': true,
                'multiple-mode': defaultMSelect.isMSelectionMode,
                'normal-mode': !defaultMSelect.isMSelectionMode,
            })}
        >
            <Tooltip title="取消多选">
                <span
                    onClick={() => {
                        defaultMSelect.setMSelectedData([]);
                    }}
                    style={{
                        padding: 5,
                        cursor: 'pointer',
                    }}
                >
                    <Icon type="close" />
                </span>
            </Tooltip>
            <div>
                <span>已选{defaultMSelect.mSeletedData.length}条：</span>
            </div>
            {defaultPerm !== null &&
                defaultPerm.getPermByTypeAndAction(type)('delete').visible && (
                    <AntButton
                        onClick={() => {
                            defaultStore.setAction('m-delete');
                        }}
                        size="large"
                    >
                        删除
                    </AntButton>
                )}
        </div>
    );

    const dataTable = (
        <div style={{ display: 'flex', height: '100%' }}>
            {title}
            {!fastFilterReady ? (
                <div
                    style={{
                        flexGrow: 1,
                        paddingBottom: 40,
                    }}
                >
                    <div
                        style={{
                            background: 'white',
                            height: '100%',
                        }}
                    >
                        <BlockLoading2 />
                    </div>
                </div>
            ) : (
                <TableLv1
                    loading={loading}
                    className="table-v-stretch"
                    dataSource={defaultStore.processData}
                    // 0: {key: "process_applicant_id", title: "流程申请人ID"}
                    // 1: {key: "process_instance_name", title: "流程实例名称"}
                    // 2: {key: "create_time", title: "创建时间", readonly: true}
                    // 3: {key: "record_note", title: "跟进内容"}
                    // 4: {key: "owner_id", title: "活动记录所有人"}
                    // 5: {key: "biz_type", title: "业务类型"}
                    // 6: {key: "process_approval_activityname", title: "流程审批活动节点名称"}
                    // 7: {key: "process_approval_userid", title: "流程审批人ID"}
                    // 8: {key: "update_time", title: "修改时间"}
                    // 9: {key: "update_user", title: "修改人"}
                    // 10: {key: "process_instance_id", title: "流程实例ID"}
                    // 11: {key: "is_del", title: "是否删除"}
                    // 12: {key: "create_user", title: "创建人"}
                    // 13: {key: "current_name", title: "联系人名称"}
                    // 14: {key: "current_phone", title: "联系人电话"}
                    // 15: {key: "id", title: "主键"}
                    // 16: {key: "process_approval_username", title: "流程审批人姓名"}
                    // 17: {key: "customer_id", title: "客户ID"}
                    // 18: {key: "process_applicant_name", title: "流程申请人姓名"}
                    // 19: {key: "process_approval_activityid", title: "流程审批活动节点"}
                    // 20: {key: "process_approval_status", title: "流程申请状态"}

                    // appVersion: null
                    // applicant: "shaomingquan"
                    // applicantName: null
                    // businessKey: "31"
                    // completedDate: null
                    // currentTaskBeginTime: 1618216557933
                    // currentTaskUser: "wangli"
                    // currentTaskUsername: null
                    // id: "19f865d8-9b6a-11eb-97af-caf0cd072c0a"
                    // initiator: null
                    // name: "合同HT-123456789测试流程"
                    // parentId: null
                    // processApproveStatus: "APPROVE"
                    // processDefinitionId: "testprocess:2:5cf50027-9b49-11eb-9690-caf0cd072c0a"
                    // processDefinitionKey: "testprocess"
                    // processDefinitionName: "测试流程"
                    // processDefinitionVersion: 2
                    // startDate: 1618216556989
                    // status: "RUNNING"
                    columns={columns}
                    cellsSelector={cellSelectors}
                    onAction={(key, data) => {
                        if (_.startsWith(key, 'reveal-param/')) {
                            if (key.indexOf('/name') !== -1) {
                                defaultStore.setAction('see-detail', data);
                            }
                        }
                    }}
                    onPaginationChange={
                        defaultStore.processDataPagination.setPaginationByUI
                    }
                    pagination={{
                        ...defaultStore.processDataPagination.paginationForUI,
                        total: defaultStore.processDataPagination.amount,
                    }}
                />
            )}
        </div>
    );

    const layoutComs = {
        popups,
        title: '全部流程',
        globalOps,
        filters: null,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay: null,
    };

    return <LayoutStandardCrud className="page-process-list" {...layoutComs} />;
};

// appVersion: null
// applicant: "shaomingquan"
// applicantName: null
// businessKey: "37"
// completedDate: null
// currentTaskBeginTime: 1618218583132
// currentTaskUser: "wangli"
// currentTaskUsername: null
// id: "d1912406-9b6e-11eb-ba2d-525400df0a63"
// initiator: null
// name: "测试流程-1618218567725"
// parentId: null
// processApproveStatus: "APPROVE"
// processDefinitionId: "testprocess:2:5cf50027-9b49-11eb-9690-caf0cd072c0a"
// processDefinitionKey: "testprocess"
// processDefinitionName: "测试流程"
// processDefinitionVersion: 2
// startDate: 1618218582960
// status: "RUNNING"

const columns: ColumnProps<any>[] = [
    {
        title: '流程名称',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
    },

    {
        title: '流程类型',
        dataIndex: 'processDefinitionName',
        key: 'processDefinitionName',
    },
    {
        title: '当前审批人',
        dataIndex: 'currentTaskUsername',
        key: 'currentTaskUsername',
    },
    {
        title: '当前审批节点',
        dataIndex: 'currentTaskName',
        key: 'currentTaskName',
    },
    {
        title: '审批状态',
        dataIndex: 'processApproveStatus',
        key: 'processApproveStatus',
    },
    {
        title: '申请人',
        dataIndex: 'applicantName',
        key: 'applicantName',
    },
    { title: '提交时间', dataIndex: 'startDate', key: 'startDate' },
    {
        title: '到达时间',
        dataIndex: 'currentTaskBeginTime',
        key: 'currentTaskBeginTime',
    },
];

const cellSelectors = _.uniqBy(
    [
        {
            colKey: 'name',
            cell: 'ActionParam',
            inputs: [
                'name',
                {
                    paramName: 'name',
                },
            ],
        },
        {
            colKey: 'processApproveStatus',
            cell: 'ProcessStatus',
            inputs: ['processApproveStatus'],
        },
        {
            colKey: 'currentTaskBeginTime',
            cell: 'DateTimeMoment',
            inputs: ['currentTaskBeginTime'],
        },
        {
            colKey: 'startDate',
            cell: 'DateTimeMoment',
            inputs: ['startDate'],
        },
        ...columns.map(({ key }) => {
            return getDefaultCellSelector(key as string);
        }),
    ],
    'colKey',
) as any;

const FinalDemoList = dFilterHOC(observer(DemoList), 'process');
export default FinalDemoList;
