import React, { useEffect, useState } from 'react';
import { Spin, Steps } from 'antd';
import Form from 'antd/es/form';
import './index.scss'
import { Tabs } from 'antd';
import { getCostumerInfo } from './api';
import moment from 'moment';
import classname from 'classname';
import Item from 'antd/lib/list/Item';
import { followTypesMap } from '../table/cells/latest-activity-record';
import WrapperStatistic from 'antd/lib/statistic/Statistic';

const { Step } = Steps;
const { TabPane } = Tabs;

interface pictureDetail {
    userPhoto?: string,
    lastForhead?: string,
    recently?: string[],
    goodStart?: string
}

interface CcProps {
    style?: any,
    whetherShow: boolean
    phoneNum?: string | undefined | null
    uid?: number
}

const Ccpopover: React.FC<CcProps> = ({ style, whetherShow, phoneNum, uid }) => {
    const [customerInfo, setCustomerInfo] = useState<any>()
    const [isHover, setIsHover] = useState(false)

    useEffect(() => {
        const getInfoDetail = async () => {
            if (phoneNum !== undefined && phoneNum !== null) {
                const res = await getCostumerInfo({ phone: phoneNum })
                setCustomerInfo(res[0])
            }
            if (uid !== undefined) {
                console.log('uiduiduid', uid)
                const res = await getCostumerInfo({ uid: uid })
                setCustomerInfo(res[0])
            }
        }
        getInfoDetail()
    }, [phoneNum, uid])

    const callback = (key: any) => {
        console.log('keykeykey', key)
    }

    return (
        <Spin spinning={customerInfo ? false : true}>
            <Form
                layout="horizontal"
                labelAlign='left'
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 13 }}
                className="detail-info"
                style={style}
                colon={true}
            >
                <Form.Item label="联系人画像">
                    &nbsp;{customerInfo?.contactsPortrait.length !== 0 ? customerInfo?.contactsPortrait?.join(' | ') : '--'}
                </Form.Item>
                <Form.Item label="最后跟进">
                    {customerInfo?.activationRecordDto ? <div className='last-up'>
                        <div><span>{moment(customerInfo?.activationRecordDto?.endActiveTime).format('YYYY-MM-DD')}</span>&nbsp;|&nbsp;
                            <span>{customerInfo?.activationRecordDto?.caterUserName}</span>&nbsp;|&nbsp;
                            <span>{followTypesMap[customerInfo?.activationRecordDto?.followType]?.label}</span></div>
                        <div className='up-content'>跟进内容：{customerInfo?.activationRecordDto?.note}</div>
                    </div> : '未被跟进过'}
                </Form.Item>
                <div style={{
                    fontWeight: 'bold',
                    marginBottom: '8px'
                }} >近期动作(近60天)</div>
                {customerInfo?.recentActions.length !== 0 ?
                    <div className={whetherShow ? "recent-content-calling" : "recent-content"}>
                        <Steps progressDot current={1} direction="vertical">
                            {customerInfo?.recentActions?.map((item: any, index: any) => (
                                <Step description={
                                    <div className='step'>
                                        <span className='step-time' style={{ color: 'gray', paddingRight: '16px' }}>
                                            {moment(item?.date).format('YYYY-MM-DD  hh:mm:ss')}
                                        </span>
                                        <span className='step-content' style={{ color: 'black' }}>
                                            {/* {item?.recentAction +
                                                '  (' +
                                                (item?.jobSalary ? item?.jobSalary?.[0]?.position : '')
                                                + ',  ' +
                                                ((item?.jobSalary ? (Math.round(Number(item?.jobSalary?.[0]?.salaryMin) / 1000) + 'k') : '') || '--') + '-' +
                                                ((item?.jobSalary ? (Math.round(Number(item?.jobSalary[0]?.salaryMax) / 1000) + 'k') : '') || '--') + ') ,'}&nbsp;
                                            查看<a target="view_window" href={`${item.jobSalary ? item?.jobSalary[0]?.jobUrl : ''}`}>职位详情</a> */}
                                            {item?.recentAction}
                                            {
                                                item.jobSalary !== null ?
                                                    <span> {'(' + item.jobSalary?.[0].position + ',  ' + Math.round(Number(item?.jobSalary?.[0]?.salaryMin) / 1000) + 'k' + '-' + Math.round(Number(item?.jobSalary[0]?.salaryMax) / 1000) + 'k' + ') ,'}&nbsp;查看<a target="view_window" href={`${item.jobSalary ? item?.jobSalary[0]?.jobUrl : ''}`}>职位详情</a></span> : ''

                                            }
                                        </span>
                                    </div>} />
                            ))}
                            {/* `${`${Math.round(
                                Number(jobMinSalary) / 1000,
                            )}k` || '--'} ~ ${`${Math.round(
                                Number(jobMaxSalary) / 1000,
                            )}k` || '--'}`; */}
                        </Steps>
                    </div > :
                    <div className='img-wrapper'>
                        <div className='recent-img'></div>
                        <p>近期暂无动作</p>
                    </div>}

                {!whetherShow && <Form.Item label="自我介绍">
                    <div className={isHover ? 'starting-hover' : 'starting'} onMouseEnter={() => { setIsHover(true) }} onMouseLeave={() => { setIsHover(false) }}>
                        {customerInfo?.salesInfos[0]?.salesScript}
                    </div>
                </Form.Item>
                }
                {

                    whetherShow && customerInfo?.salesInfos ? <Tabs size='small' onChange={callback}>
                        {customerInfo?.salesInfos.map((item: any, index: any) => (
                            <TabPane className='tab-content' tab={item?.scriptType} key={index}>
                                {item?.salesScript}
                            </TabPane>
                        ))}
                    </Tabs> : ''
                }
            </Form >
        </Spin >

    );
}


export default Ccpopover;