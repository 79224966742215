import React, { useState, useEffect, CSSProperties } from 'react';
import './index.less';
import { observer } from 'mobx-react-lite';
import { Icon, Select, Statistic, Alert, message, Spin } from 'antd';
import moment from 'moment';
import { AntButton } from '@/components';
import _ from 'lodash';
import BigNumberCard from '../big-number-card';
import CompositeOptAnalysis from '../opt-analysis';
import { defaultAxios } from '@/utils';
import PerformanceAnalysisCompositeContent from '../performance-analysis';
import PerformanceClockItem from './component/clock-item';

export interface IAchievementSituationData {
    update_time?: string | number;
    income_money?: number; // 回款
    income_target?: number; // 目标
    income_gap?: number; // 差距
    day_pass?: number; // 过去时间
    day_total?: number; // 总共时间
    day_remain?: number; // 剩余时间
    speed_day?: number; // 时间进度
    speed_gap?: number; // 进度gap(对比)
    speed_income?: number; // 目标完成率
    completion_rate?: number; // 目标完成率
    opportunity_amount?: number; // 商机金额合计
    opportunity_amount20?: number; // 20商机金额
    opportunity_amount40?: number; // 40商机金额
    opportunity_amount60?: number; // 60商机金额
    opportunity_amount80?: number; // 80商机金额
    opportunity_amount6080?: number; // 6080商机金额
    opportunity_gap?: number; // 商机GAP
    opportunity_amount_rate?: number; // 商机/GAP
    industry_customer_money: {
        [key: string]: number;
    };
    industry_customer_num: {
        [key: string]: number;
    };
    industry_customer_arpu: {
        [key: string]: number;
    };
    [key: string]: any;
}
export interface IAchievementSituationFilterData {
    targetLevel: number;
    area: string;
    range: number;
}
export const targetLevelOptions = [
    {
        label: 'E',
        value: 3,
    },
    {
        label: 'M+',
        value: 2,
    },
    {
        label: 'M',
        value: 1,
    },
];

export const areaOptions = [
    {
        label: '全国',
        value: 'country',
    },
];

export const rangeOptions = [
    {
        label: '本年',
        value: 1,
    },
    {
        label: '本季',
        value: 2,
    },
    {
        label: '本月',
        value: 3,
    },
];
export const trans2W = (v: number) => {
    return Math.round(v / 10000);
};
export const trans2WDotTwo = (v: number) => {
    return Math.round(v / 100) / 100;
};
export const trans2DotTwo = (v: number) => {
    return Math.round(v * 100) / 100;
};
const AchievementSituation: React.FC = () => {
    const [
        achievementSituationFilterData,
        setAchievementSituationFilterData,
    ] = useState<IAchievementSituationFilterData>({
        targetLevel: 2,
        area: 'country',
        range: 1,
    });
    const rangeSelectedOptionItem = _.find(rangeOptions, opt => {
        return opt.value == achievementSituationFilterData.range;
    });
    const statisticValueMap: {
        title: string;
        key: string;
        preText?: string;
        prodText?: string;
        formatter: any;
    }[] = [
        {
            title: '目标',
            key: 'income_target',
            formatter: (v: any) => `${trans2W(v)}`,
            prodText: 'W',
        },
        {
            title: 'GAP',
            key: 'income_gap',
            formatter: (v: any) => `${trans2W(v)}`,
            prodText: 'W',
        },
        {
            title: '目标完成率',
            key: 'speed_income',
            formatter: (v: any) => `${trans2DotTwo(v)}`,
            prodText: '%',
        },
        {
            title: '时间进度',
            key: 'speed_day',
            formatter: (v: any) => `${trans2DotTwo(v)}`,
            prodText: '%',
        },
        {
            title: `${rangeSelectedOptionItem?.label}剩余工作日`,
            key: 'day_remain',
            formatter: (v: any) => v,
        },
    ];
    const [achievementSituationData, setAchievementSituationData] = useState<
        IAchievementSituationData
    >();
    // const [clockTime, setClockTime] = useState<any>();
    const [refreshTime, setRefreshTime] = useState<any>();
    const [
        achievementSituationLoading,
        setAchievementSituationLoading,
    ] = useState(false);
    const fetchAchievementSituationData = async () => {
        const url = '/crm/data/query/income_target';
        const payloadData = {
            time: achievementSituationFilterData.range,
            target: achievementSituationFilterData.targetLevel,
        };
        setAchievementSituationLoading(true);
        const [d, e] = await defaultAxios.put(url, payloadData);
        setAchievementSituationLoading(false);
        if (d === null || d.data === null) {
            message.error('请求业绩目标数据失败～');
            return [null, e];
        }
        setRefreshTime(moment());
        setAchievementSituationData(d.data || {});
    };

    useEffect(() => {
        // setClockTime(moment());
        // const timerClock = setInterval(() => {
        //     setClockTime(moment());
        // }, 1000);
        fetchAchievementSituationData();
        const timer = setInterval(
            fetchAchievementSituationData,
            20 * 60 * 60 * 1000,
        );
        return () => {
            timer && clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        fetchAchievementSituationData();
    }, [achievementSituationFilterData]);
    const statisticValueStyle: CSSProperties = {
        fontSize: '16px',
        // color: '#0052ff',
        fontWeight: 500,
    };
    const statisticCanClickValueStyle: CSSProperties = {
        fontSize: '16px',
        color: '#0052ff',
        fontWeight: 500,
        cursor: 'pointer',
    };
    const renderTipsText = () => {
        if (!achievementSituationData?.speed_gap) {
            return <span className="a-s-tips-all">- -</span>;
        }
        let status = 'error';
        let text = `落后${trans2DotTwo(achievementSituationData?.speed_gap)}%`;
        let tipsText = '预计风险较大';
        if (
            achievementSituationData.speed_gap > 0 &&
            achievementSituationData.speed_gap < 10
        ) {
            status = 'warning';
            text = `落后${trans2DotTwo(achievementSituationData?.speed_gap)}%`;
            tipsText = '存在一定风险';
        }
        if (achievementSituationData.speed_gap < 0) {
            status = 'success';
            text = `领先${trans2DotTwo(-achievementSituationData?.speed_gap)}%`;
            tipsText = '风险较小';
        }
        return (
            <span className="a-s-tips-all">
                解读（供参考）：「目标完成率」对比「时间进度」
                <span className={`a-s-tips-text ${status}`}>{text}</span>，
                {tipsText}
            </span>
        );
    };
    return (
        <>
            <div className="achievement-situation-content">
                <div className="a-s-header">
                    <div className="a-s-time-content">
                        <Icon type="clock-circle" />
                        {/* <span className="a-s-time">
                            {clockTime &&
                                clockTime.format('YYYY-MM-DD A hh:mm:ss')}
                        </span> */}
                        <PerformanceClockItem />
                    </div>
                    <img
                        width="325px"
                        height="21px"
                        src={`${process.env.PUBLIC_URL}/imgs/composite/title.png`}
                        alt="企业招聘-商业大盘实况"
                        className="a-s-header-img"
                    />
                    {/* <div className="a-s-action">
                        <span className="a-s-help">数据说明</span> */}
                    {/* <AntButton>生成报告</AntButton> */}
                    {/* </div> */}
                </div>
                <Spin spinning={achievementSituationLoading}>
                    <div className="a-s-body">
                        <div className="a-s-f-header">
                            <div className="a-s-f-left">
                                <span className="a-s-f-title">
                                    业绩目标达成情况
                                </span>
                                <span className="a-s-f-time">
                                    （更新时间：
                                    {refreshTime
                                        ? refreshTime.format(
                                              'YYYY-MM-DD HH:mm:ss',
                                          )
                                        : '- -'}
                                    ）
                                </span>
                            </div>
                            <div className="a-s-f-filter">
                                <div className="a-s-f-item">
                                    <label htmlFor="">目标级别</label>
                                    <Select
                                        value={
                                            achievementSituationFilterData.targetLevel
                                        }
                                        size="small"
                                        onChange={(value: any) => {
                                            setAchievementSituationFilterData({
                                                ...achievementSituationFilterData,
                                                targetLevel: value,
                                            });
                                        }}
                                        style={{
                                            minWidth: '80px',
                                        }}
                                    >
                                        {_.map(targetLevelOptions, option => {
                                            return (
                                                <Select.Option
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                                <div className="a-s-f-item">
                                    <label htmlFor="">区域</label>
                                    <Select
                                        value={
                                            achievementSituationFilterData.area
                                        }
                                        size="small"
                                        onChange={(value: any) => {
                                            setAchievementSituationFilterData({
                                                ...achievementSituationFilterData,
                                                area: value,
                                            });
                                        }}
                                        style={{
                                            minWidth: '80px',
                                        }}
                                    >
                                        {_.map(areaOptions, option => {
                                            return (
                                                <Select.Option
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                                <div className="a-s-f-item">
                                    <label htmlFor="">时间</label>
                                    <Select
                                        size="small"
                                        value={
                                            achievementSituationFilterData.range
                                        }
                                        onChange={(value: any) => {
                                            setAchievementSituationFilterData({
                                                ...achievementSituationFilterData,
                                                range: value,
                                            });
                                        }}
                                        style={{
                                            minWidth: '80px',
                                        }}
                                    >
                                        {_.map(rangeOptions, option => {
                                            return (
                                                <Select.Option
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="a-s-data">
                            <BigNumberCard
                                title={`${rangeSelectedOptionItem?.label}回款`}
                                number={
                                    achievementSituationData?.income_money || 0
                                }
                            />
                            <div className="a-s-static-content">
                                {_.map(statisticValueMap, item => {
                                    return (
                                        <div className="a-s-static-item">
                                            {achievementSituationData?.[
                                                item.key
                                            ] ? (
                                                <div className="a-s-statics-data">
                                                    <Statistic
                                                        style={
                                                            statisticValueStyle
                                                        }
                                                        groupSeparator={','}
                                                        prefix={
                                                            item.preText || ''
                                                        }
                                                        value={
                                                            achievementSituationData?.[
                                                                item.key
                                                            ]
                                                                ? item.formatter(
                                                                      achievementSituationData?.[
                                                                          item
                                                                              .key
                                                                      ],
                                                                  )
                                                                : '- -'
                                                        }
                                                        suffix={
                                                            item.prodText || ''
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <Statistic
                                                    style={statisticValueStyle}
                                                    groupSeparator={','}
                                                    value={'- -'}
                                                />
                                            )}
                                            <p className="a-s-static-title">
                                                {item.title}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="a-s-alert-content">
                                <Icon
                                    type="info-circle"
                                    style={{ color: '#0052FF' }}
                                />
                                {renderTipsText()}
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
            <CompositeOptAnalysis
                compositeOptAnalysisData={achievementSituationData}
                dataLoading={achievementSituationLoading}
                asFilterData={achievementSituationFilterData}
                setAsFilterData={setAchievementSituationFilterData}
            />
            <span className="c-l-c-tips">
                <span className="warning-text">*注意</span>
                ：以下的分析数据因逻辑复杂，只能展示截止昨日的数据，因此与上方数据存在一定GAP，请知悉！如有疑问，欢迎随时飞书联系蜂巢CRM产品Yanlulu
            </span>
            <PerformanceAnalysisCompositeContent
                asData={achievementSituationData}
                asLoading={achievementSituationLoading}
                asFilterData={achievementSituationFilterData}
                setAsFilterData={setAchievementSituationFilterData}
            />
        </>
    );
};
export default observer(AchievementSituation);
