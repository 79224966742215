import React, { memo, useState, useEffect } from 'react';
import moment from 'moment';
import { AutoEllipsis } from '../../auto-ellipsis';
import { Icon, Popover, Divider, Form, Alert } from 'antd';
import { BlockLoading2 } from '../../block-loading';
import { defaultAxios, isNetSuccess, arrToJson } from '@/utils';
import { AutoLink } from '../../auto-link';

export const MLinks: React.FC<{
    args: [string];
    item: any;
}> = memo(({ args: [text], item }) => {
    if (!text) {
        return null;
    }

    return (
        <>
            <span
                className="no-tooltip"
                style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    lineHeight: '1',
                }}
            >
                <Popover
                    placement={'bottomLeft'}
                    getPopupContainer={() => {
                        return document.body as HTMLElement;
                    }}
                    // autoAdjustOverflow={false}
                    mouseEnterDelay={0.3}
                    content={
                        <div style={{ width: 400 }}>
                            <AutoLink text={text} />
                        </div>
                    }
                    trigger="hover"
                >
                    <Icon
                        style={{
                            color: '#0052ff',
                            cursor: 'pointer',
                        }}
                        type="info-circle"
                    />
                    &nbsp;
                    <span style={{ display: 'inline' }}>{text}</span>
                </Popover>
            </span>
        </>
    );
});
