import React, { memo, useEffect, useState } from 'react';
import {
    DForm,
    applyCustomConfToCellsSelector,
    applyCustomConfToWidget,
    applyConfToFormItem,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ContractIncomeListStore } from '../store';
import { defaultAxios, isNetSuccess } from '@/utils';
import { message } from 'antd';
import moment from 'moment';

const DemoForm: React.FC<{
    defaultStore: ContractIncomeListStore;
}> = ({ defaultStore }) => {
    const [fillingLoading, setFillingLoading] = useState(false);
    const { mutatingData, actionType } = defaultStore;

    let isCreate = true;
    let eventCode = '';
    if (actionType === 'create') {
        eventCode = 'CREATE-EDIT';
    } else if (actionType === 'update') {
        isCreate = false;
        eventCode = 'PUBLIC-EDIT';
    }

    useEffect(() => {
        const contractId = mutatingData?.contract_id?.originalValue;
        // contractId 为合同
        if (!contractId || !isCreate || !actionType) {
            return;
        }
        setFillingLoading(true);
        defaultAxios
            .put('/bff/api/rest/populate?tableCode=sale_income', {
                contract_id: contractId,
            })
            .then(([d, e]) => {
                setFillingLoading(false);
                if (isNetSuccess(d, e)) {
                    const data = d?.data;
                    delete data.contract_id;
                    delete data.customer_id;
                    delete data.income_customer_id;
                    _.assign(mutatingData, data);
                    // 好像有默认回填的代码
                } else {
                    message.error('请求回填出错');
                }
            });
    }, [actionType, isCreate, mutatingData && mutatingData.contract_id]);
    useEffect(() => {
        // customer_id 为 签约客户名称
        const customer_id = mutatingData?.customer_id?.originalValue;
        if (!customer_id || !isCreate || !actionType) {
            return;
        }
        const data = {
            income_customer_id: mutatingData?.customer_id,
            income_split_order: 0,
            income_type: 1,
            income_period: '1',
            income_time: moment()
                .startOf('day')
                .valueOf(),
            income_mode: 1,
            finance_time: moment().valueOf(),
        };
        _.assign(mutatingData, data);
    }, [actionType, isCreate, mutatingData && mutatingData.customer_id]);

    if (eventCode === '') {
        return null;
    }

    const form = (
        <DForm
            onChange={(key, value, widgetKey) => {
                if (key === 'customer_id') {
                    mutatingData.contract_id = null;
                    mutatingData.income_customer_id = null;
                }
                if (key === 'owner_id') {
                    mutatingData.achievement_dept = null;
                    mutatingData.achievement_city = null;
                    defaultStore.populateByIncomeOwnerId(value);
                }
                if (key === 'achievement_dept') {
                    mutatingData.achievement_city = null;
                    defaultStore.polulateByDept(value);
                }
            }}
            eventCode={eventCode}
            widgetsTransformer={widgets => {
                const nextWidgets = [...widgets];
                applyConfToFormItem(nextWidgets, 'inputSecret', {
                    sceneCode: 'crm_contract',
                });
                applyCustomConfToWidget(nextWidgets, 'customer_id', widget => {
                    if (widget.objectOptions) {
                        widget.objectOptions.dataAuthCode = 'SEARCH-PRIVATE';
                    }
                    return widget;
                });

                applyCustomConfToWidget(
                    nextWidgets,
                    'contract_id',
                    prevwidget => {
                        const widget = _.cloneDeep(prevwidget);
                        widget.status = widget.status || {};
                        widget.status.loading = fillingLoading;

                        const cusId = mutatingData?.customer_id?.originalValue;
                        if (!cusId) {
                            widget.status = {
                                ...widget.status,
                                disabled: true,
                            };
                        }
                        if (widget.objectOptions) {
                            widget.objectOptions.defaultFilters = cusId
                                ? [
                                      {
                                          fieldName: 'customer_id',
                                          operateType: 'EQUAL',
                                          fieldValue: cusId,
                                      },
                                  ]
                                : [];
                            widget.objectOptions.reloadTrigger = cusId;
                        }
                        return widget;
                    },
                );

                applyCustomConfToWidget(nextWidgets, 'income_code', widget => {
                    const nextWidget = _.cloneDeep(widget);
                    nextWidget.status = nextWidget.status || {};
                    if (isCreate) {
                        nextWidget.status.hidden = true;
                    } else {
                        nextWidget.status.disabled = true;
                        nextWidget.status.hidden = false;
                    }
                    return nextWidget;
                });

                applyCustomConfToWidget(nextWidgets, 'income_time', widget => {
                    const nextWidget = _.cloneDeep(widget);
                    if (nextWidget.dateOptions) {
                        nextWidget.dateOptions.disabledDate = toCheck => {
                            return (
                                (toCheck?.valueOf() || Infinity) > Date.now()
                            );
                        };
                    }
                    return nextWidget;
                });

                // applyCustomConfToWidget(nextWidgets, 'finance_time', widget => {
                //     const nextWidget = _.cloneDeep(widget);
                //     nextWidget.datetimeOptions =
                //         nextWidget.datetimeOptions || {};
                //     nextWidget.datetimeOptions.disabledDate = toCheck => {
                //         return (toCheck?.valueOf() || Infinity) > Date.now();
                //     };
                //     return nextWidget;
                // });

                return nextWidgets;
            }}
            defaultStore={defaultStore}
        />
    );
    return form;
};

const FinalDemoForm = memo(observer(DemoForm));
export default FinalDemoForm;
