import React, { useState, ReactNode, useEffect } from 'react';
import './index.scss';
import Tree, { TreeProps, AntTreeNodeProps } from 'antd/lib/tree';
import Popconfirm, { PopconfirmProps } from 'antd/lib/popconfirm';
import _ from 'lodash';
import { toJS } from 'mobx';
import Search from 'antd/lib/input/Search';
import { AntButton } from '@/components';
const TreeNode = Tree.TreeNode;
export interface IActionOption {
    actionKey: string;
    actionCn: string | React.ReactNode;
    popconfirm?: boolean;
    status?: {
        disabled?: boolean | ((data: ISearchTreeNodeProps) => boolean);
        hidden?: boolean | ((data: ISearchTreeNodeProps) => boolean);
    };
    popcomfirmConfig?: PopconfirmProps;
    autoHidden?: boolean; // 自动隐藏
}
interface ISearchTreeNodeProps extends AntTreeNodeProps {
    nodeChildren?: ISearchTreeNodeProps[];
    [key: string]: any;
}
export interface ISearchTreeProps extends TreeProps {
    actionOption?: IActionOption[];
    onAction?: (
        actionKey: string,
        data: ISearchTreeNodeProps,
        pTitle: string,
    ) => void;
    treeNodeData: ISearchTreeNodeProps[];
    keyIndex?: string; // 标志数据中哪个是key
    autoExpandDeep?: number; // 默认展开到第几层
    showExpandBtn?: boolean; // 是否展示「展开」按钮
    isLeafSelect?: boolean; // 非叶子节点是否可以选中 true是不可选中 false是可
    autoHiddenRootAction?: boolean; // 自动隐藏根结点acitonbar
}
const SearchTree: React.FC<ISearchTreeProps> = props => {
    const {
        actionOption,
        onAction = (actionKey: string, data: any) => {},
        treeNodeData,
        keyIndex,
        autoExpandDeep,
        isLeafSelect = false,
        autoHiddenRootAction = true,
    } = props;
    const [searchKeyWord, setSearchKeyWord] = useState<string>(''); // 搜索字
    const [hoverKey, setHoverKey] = useState<string>(''); // hover标记
    const [expandKey, setExpandKey] = useState<any[]>([]); // 树展开受控
    const [autoExpand, setAutoExpand] = useState(false); // 是否自动展开父节点
    const [flatList, setFlatList] = useState<any[]>([]); // 打平的元数据

    useEffect(() => {
        console.log(treeNodeData);
        setFlatList(generateList(treeNodeData));
        if (autoExpandDeep) {
            let defaultExpandNode: any[] = [...treeNodeData];
            let expandLevel = autoExpandDeep;
            while (expandLevel--) {
                defaultExpandNode = defaultExpandNode
                    .map(node => {
                        return node?.nodeChildren;
                    })
                    .reduce((t, v, i, a) => {
                        return t.concat(v);
                    }, [])
                    .filter((item: any) => item && !item.isLeaf);
            }
            const defaultExpandKey = defaultExpandNode.map(node => {
                return node?.key ? node?.key : node[keyIndex as string];
            });
            if (defaultExpandKey.length) {
                setAutoExpand(true);
                setExpandKey(defaultExpandKey);
            }
        }
    }, [treeNodeData]);
    // 将结构打平 为搜索做准备
    const generateList = (data: ISearchTreeNodeProps[]) => {
        const dataList: any[] = [];
        const loop = (loopData: ISearchTreeNodeProps[]) => {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < loopData.length; i++) {
                const node = loopData[i];
                const key = node.key ? node.key : node[keyIndex as string];
                dataList.push({ key, title: node.title });
                if (node.nodeChildren) {
                    loop(node.nodeChildren);
                }
            }
        };
        loop(data);
        return dataList;
    };
    // 通过子key搜索父key
    const getParentKey: any = (
        cKey: string,
        tree: ISearchTreeNodeProps[],
        isTitle?: boolean,
    ) => {
        let parentKey;
        let parentTitle;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            const pKey = node.key ? node.key : node[keyIndex as string];
            if (node.nodeChildren) {
                if (
                    node.nodeChildren.some(item => {
                        const nodeKey = item.key
                            ? item.key
                            : item[keyIndex as string];
                        return cKey == nodeKey;
                    })
                ) {
                    if (isTitle) {
                        parentTitle = node.title;
                    } else {
                        parentKey = pKey;
                    }
                } else if (getParentKey(cKey, node.nodeChildren)) {
                    // 递推搜索最直接父key
                    if (isTitle) {
                        parentTitle = getParentKey(
                            cKey,
                            node.nodeChildren,
                            true,
                        );
                    } else {
                        parentKey = getParentKey(cKey, node.nodeChildren);
                    }
                }
            }
        }
        return isTitle ? parentTitle : parentKey;
    };
    const renderTree: (treeNode: ISearchTreeNodeProps[]) => any = (
        treeNode: ISearchTreeNodeProps[],
    ) => {
        const renderTitle = (title: ReactNode, item: ISearchTreeNodeProps) => {
            const key = item.key ? item.key : item[keyIndex as string];
            const isLeaf = item.isLeaf;
            const isRoot = item.isRoot;
            const isHover = hoverKey === key;
            return (
                <div
                    className={`crm-search-tree-node ${
                        isLeaf ? 'leaf' : 'parent'
                    } ${isRoot ? 'root' : ''}`}
                    onMouseEnter={() => {
                        setHoverKey(key as string);
                    }}
                    onMouseLeave={() => {
                        setHoverKey('');
                    }}
                >
                    <div
                        className={`crm-search-tree-node-title ${
                            isLeaf ? 'leaf' : 'parent'
                        } ${isRoot ? 'root' : ''}`}
                    >
                        {title}
                    </div>
                    {((autoHiddenRootAction && !isRoot) ||
                        !autoHiddenRootAction) && (
                        <div className="crm-search-tree-node-handle">
                            {_.map(actionOption, action => {
                                const isPopConfirm = !!action.popconfirm;
                                const {
                                    popcomfirmConfig = { title: '' },
                                    actionKey,
                                    autoHidden = true,
                                    status,
                                } = action;
                                const isOuterHidden = () => {
                                    const hidden = status?.hidden;
                                    if (typeof hidden === 'function') {
                                        return hidden(item);
                                    } else {
                                        return !!hidden;
                                    }
                                };
                                const isOuterDisabled = (() => {
                                    const disabled = status?.disabled;
                                    if (typeof disabled === 'function') {
                                        return disabled(item);
                                    } else {
                                        return !!disabled;
                                    }
                                })();

                                if (isOuterHidden()) {
                                    return null;
                                }
                                const titleEle = isPopConfirm ? (
                                    <Popconfirm
                                        disabled={isOuterDisabled}
                                        {...popcomfirmConfig}
                                        onConfirm={e => {
                                            if (popcomfirmConfig.onConfirm) {
                                                popcomfirmConfig.onConfirm(e);
                                            }
                                            const pTitle = getParentKey(
                                                item.key
                                                    ? item.key
                                                    : item[keyIndex as string],
                                                treeNodeData,
                                                true,
                                            );
                                            onAction(actionKey, item, pTitle);
                                        }}
                                    >
                                        <span
                                            className={`crm-search-tree-node-action ${
                                                isOuterDisabled
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                        >
                                            {action.actionCn}
                                        </span>
                                    </Popconfirm>
                                ) : (
                                    <span
                                        onClick={e => {
                                            e.stopPropagation();
                                            // e.nativeEvent.stopPropagation();
                                            const pTitle = getParentKey(
                                                item.key
                                                    ? item.key
                                                    : item[keyIndex as string],
                                                treeNodeData,
                                                true,
                                            );
                                            if (!isOuterDisabled) {
                                                onAction(
                                                    actionKey,
                                                    item,
                                                    pTitle,
                                                );
                                            }
                                        }}
                                        className={`crm-search-tree-node-action ${
                                            isOuterDisabled ? 'disabled' : ''
                                        }`}
                                    >
                                        {action.actionCn}
                                    </span>
                                );
                                return autoHidden
                                    ? isHover
                                        ? titleEle
                                        : null
                                    : titleEle;
                            })}
                        </div>
                    )}
                </div>
            );
        };
        return _.map(treeNode, node => {
            if (_.isEmpty(node)) {
                return null;
            }
            // 鉴定key的存在
            if (!node.key) {
                if (keyIndex) {
                    if (node[keyIndex as string]) {
                    } else {
                        throw new Error(
                            'treeNode cant without key or wrong keyIndex!',
                        );
                    }
                } else {
                    throw new Error(
                        'treeNode cant without key or wrong keyIndex!',
                    );
                }
            }
            const key = node.key ? node.key : node[keyIndex as string];
            let finalTitle = node.title;
            if (finalTitle && typeof finalTitle === 'string') {
                // 自动标红被搜索项
                const index = finalTitle.indexOf(searchKeyWord);
                const beforeStr = finalTitle.substr(0, index);
                const afterStr = finalTitle.substr(
                    index + searchKeyWord.length,
                );
                finalTitle =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span style={{ color: '#f50' }}>
                                {searchKeyWord}
                            </span>
                            {afterStr}
                        </span>
                    ) : (
                        <span>{finalTitle}</span>
                    );
                finalTitle = renderTitle(finalTitle, node); // 最终构造
            } else {
                throw new Error('title must be string!');
            }
            if (node.nodeChildren) {
                return (
                    <TreeNode
                        {...node}
                        title={finalTitle}
                        key={key}
                        selectable={!isLeafSelect || node.isLeaf}
                    >
                        {renderTree(node.nodeChildren)}
                    </TreeNode>
                );
            }
            return (
                <TreeNode
                    {...node}
                    title={finalTitle}
                    key={key}
                    selectable={node.isLeaf}
                />
            );
        });
    };
    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const expandedKeys = flatList
            .map(item => {
                if (item.title.indexOf(value) > -1) {
                    return getParentKey(item.key, treeNodeData);
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);
        console.log(expandedKeys);
        setSearchKeyWord(value);
        setExpandKey(expandedKeys);
        setAutoExpand(true);
    };

    return (
        <div className="search-tree-content">
            <div className="search-tree-s">
                <Search
                    value={searchKeyWord}
                    style={{ marginBottom: 8 }}
                    placeholder="按部门名称搜索"
                    onChange={onSearchChange}
                />
                <AntButton
                    onClick={() => {
                        const allPKey = flatList
                            .map(item => {
                                return getParentKey(item.key, treeNodeData);
                            })
                            .filter(
                                (item, i, self) =>
                                    item && self.indexOf(item) === i,
                            );
                        setExpandKey(allPKey);
                        setAutoExpand(true);
                    }}
                >
                    展开
                </AntButton>
            </div>
            <div className="search-tree-t">
                <Tree
                    blockNode={true}
                    expandedKeys={expandKey}
                    autoExpandParent={autoExpand}
                    onExpand={selfTreeExpandKey => {
                        setExpandKey(selfTreeExpandKey);
                        setAutoExpand(false);
                    }}
                    {...props}
                >
                    {renderTree(treeNodeData)}
                </Tree>
            </div>
        </div>
    );
};

export default SearchTree;
