import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    AntButton,
    MOpResultDisplayContainer,
    loadingWrapperOver,
} from '@/components';
import { Icon, Spin, Tooltip, message } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, useBeforeFirstDataLoaded, tryRefresh } from '@/utils';
import { observer } from 'mobx-react-lite';
import DemoTable from './components/table';
import { ActionDelete } from './components/delete';
import { ActionMDelete } from './components/m-delete';
import { runInAction } from 'mobx';
import {
    defaultAuthDeco,
    dFilterHOC,
    useDefaultDFilterSnippets,
} from '@/containers/hoc';
import './index.scss';
import FormDrawer from './components/form-drawer';
import classname from 'classname';
import { useFirstLv4Menu, useFastFilterByUrl } from '@/stores/utils';
import { ActionExport } from '../../activation-record/list/components/export';
import { CustomerFollowJobCreate } from '../../job/jobs/lead/components/created-by-cus';
import { setFilterByUrlKey } from '../../../../stores/utils/filter';

const OptList: React.FC<RouteComponentProps & {
    type: string;
    dfilters?: any;
}> = ({ type = 'all', dfilters }) => {
    const store = useContext(StoreContext);
    const [defaultStore] = useState(() => new store.OptListStore());
    const userStore = store.getAuthStore();

    const ack = dtext('crm')('opt-list-message-' + type, '');

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        pageTitle,
        defaultPerm,
        defaultFilter,

        actionType,
        defaultMSelect,
        defaultFastFilters,
        condListbyFilterData,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const LeadListFetch = useCallback(() => defaultStore.fetch(), []);
    const [error, loading, reload] = useNet(LeadListFetch, {
        autoLoad: false,
        refreshKeys: ['opt'],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            defaultMeta.setTableId('opportunity');
            defaultMeta.setOpUsername(userStore.userInfo.userId + '-' + type);
            defaultFastFilters.setFilters(dfilters);
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {}); // 初始化元信息
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            setFilterByUrlKey(defaultFilter);
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useFirstLv4Menu(defaultFastFilters);

    useEffect(() => {
        runInAction(() => {
            defaultStore.setListType(type);
            defaultStore.setDefaultFields([
                'opportunity_sale_stage',
                'contract_id',
            ]);
            defaultFastFilters.setType(type);
        });
    }, [type]);

    const [isBeforeFirstLoaded] = useBeforeFirstDataLoaded(defaultStore);

    const { title, fastFilters } = useDefaultDFilterSnippets(defaultStore);

    // if (!preRequiredReady) {
    //     return <BlockLoading2 />;
    // }
    const finalLoading = isBeforeFirstLoaded || !preRequiredReady;

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const [
        customerFollowJobCreateTrigger,
        setCustomerFollowJobCreateTrigger,
    ] = useState(0);

    useFastFilterByUrl(defaultFastFilters);
    const popups = (
        <>
            <ActionExport defaultStore={defaultStore} />
            <MOpResultDisplayContainer defaultStore={defaultStore} />
            <ActionDelete defaultStore={defaultStore} />
            <ActionMDelete defaultStore={defaultStore} />
            <FormDrawer
                defaultStore={defaultStore}
                preRequiredReady={preRequiredReady}
            />
            <CustomerFollowJobCreate
                customers={defaultStore.selectedCustomers}
                trigger={customerFollowJobCreateTrigger}
                onSuccess={() => {
                    defaultStore.resetAction();
                    setCustomerFollowJobCreateTrigger(0);
                    tryRefresh(['customer']);
                }}
                onClose={() => {
                    defaultStore.resetAction();
                    setCustomerFollowJobCreateTrigger(0);
                }}
            />
        </>
    );

    const globalOps = (
        <>
            {defaultPerm.getPermByTypeAndAction(defaultStore.listType)('export')
                .visible && (
                <AntButton
                    onClick={() => {
                        defaultStore.setAction('export');
                    }}
                    size="large"
                >
                    导出
                </AntButton>
            )}
        </>
    );

    // const globalOps = (
    //     <>
    //         {defaultPerm.getPermByTypeAndAction(defaultStore.listType)('create')
    //             .visible && (
    //             <AntButton
    //                 onClick={() => {
    //                     runInAction(() => {
    //                         defaultStore.setAction('create');
    //                         defaultStore.startNewData();
    //                     });
    //                 }}
    //                 type="primary"
    //                 size="large"
    //             >
    //                 新建商机
    //             </AntButton>
    //         )}
    //     </>
    // );
    const multipleOps = (
        <div
            className={classname({
                'mselected-ops': true,
                'multiple-mode': defaultMSelect.isMSelectionMode,
                'normal-mode': !defaultMSelect.isMSelectionMode,
            })}
        >
            <Tooltip title="取消多选">
                <span
                    onClick={() => {
                        defaultMSelect.setMSelectedData([]);
                    }}
                    style={{
                        padding: 5,
                        cursor: 'pointer',
                    }}
                >
                    <Icon type="close" />
                </span>
            </Tooltip>
            <div>
                <span>已选{defaultMSelect.mSeletedData.length}条：</span>
            </div>
            {/* {defaultPerm.getPermByTypeAndAction(defaultStore.listType)('delete')
                .visible && (
                <AntButton
                    onClick={() => {
                        defaultStore.setAction('delete');
                    }}
                    size="large"
                    checkBody={{
                        tableCode: 'opportunity',
                        body: {
                            dataAuthCode: 'DEL',
                            ids: defaultMSelect.mSeletedData
                                .map(item => item.id)
                                .filter(Boolean),
                        },
                    }}
                >
                    删除
                </AntButton>
            )} */}
            {defaultPerm.getPermByTypeAndAction(defaultStore.listType)(
                'new-task',
            ).visible && (
                <AntButton
                    onClick={() => {
                        defaultStore.setAction('m-newjobs');
                        setCustomerFollowJobCreateTrigger(
                            1 + customerFollowJobCreateTrigger,
                        );
                    }}
                    size="large"
                >
                    新建任务
                </AntButton>
            )}
        </div>
    );

    const dataTable = (
        <DemoTable defaultStore={defaultStore} loading={loading} />
    );

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters: filtersDisplay,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay: fastFilters,
    };

    return loadingWrapperOver(finalLoading)(
        <LayoutStandardCrud
            className="page-opportunity-list"
            {...layoutComs}
        />,
    );
};

const FinalLeadList = dFilterHOC(observer(OptList), 'opportunity');
export default FinalLeadList;
