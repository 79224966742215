import { Spin, Icon } from 'antd';
import React, { useState } from 'react';
import { LoadingComponentProps } from 'react-loadable';
import { CrmLoading2, CrmLoading3 } from '../crm-loading';
import { useQueryAsObject, getQueryAsObject } from '@/utils';

export const BlockLoading: React.FC<LoadingComponentProps> = ({}) => {
    return (
        <div style={{ display: 'flex' }}>
            <Spin style={{ margin: 'auto' }} />
        </div>
    );
};

export const BlockLoading2: React.FC<{ outerStyle?: React.CSSProperties }> = ({
    outerStyle,
}) => {
    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                minHeight: 150,
                borderRadius: 3,
                ...outerStyle,
            }}
        >
            <div style={{ height: 100, width: 100, margin: 'auto' }}>
                <CrmLoading2 />
            </div>
        </div>
    );
};

export const BlockLoading3: React.FC<{ outerStyle?: React.CSSProperties }> = ({
    outerStyle,
}) => {
    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                minHeight: 150,
                borderRadius: 3,
                ...outerStyle,
            }}
        >
            <div style={{ height: 100, width: 100, margin: 'auto' }}>
                <CrmLoading3 />
            </div>
        </div>
    );
};

export const loadingWrapper: (
    loading: boolean,
) => (ele: React.ReactElement) => React.ReactElement = loading => {
    if (!loading) {
        return ele => ele;
    }
    return ele => (
        <span>
            <Spin>{ele}</Spin>
        </span>
    );
};

export const loadingWrapperOver: (
    loading: boolean,
    keep?: boolean,
) => (ele: React.ReactElement) => React.ReactElement = (
    loading,
    keep = false,
) => {
    const queryObj = getQueryAsObject();
    const hasId = !!queryObj.id;
    if (hasId && !keep) {
        return ele => ele;
    }
    if (!loading) {
        return ele => ele;
    }
    return ele => {
        return (
            <>
                {ele}
                <BlockLoading2
                    outerStyle={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        zIndex: 999,
                        width: '100%',
                        background: 'white',
                        transform: 'translate3d(0,0,0)',
                    }}
                />
            </>
        );
    };
};
