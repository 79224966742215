import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    delay,
    isNetSuccess,
    arrToJson,
    arrToJsonPrimary,
    defaultAxios,
} from '@/utils';
import { BlockLoading2, AntTable, AntButton } from '@/components';
import { Alert, Checkbox, Divider, message, Button } from 'antd';

export const WaSettingBody: React.FC<{
    processDefinitionId: string | null;
    activityId: string | null;
}> = ({ processDefinitionId, activityId }) => {
    const isGoodRequest =
        _.isString(processDefinitionId) && _.isString(activityId);

    const [settingOriginalBody, setSettingOriginalBody] = useState<any>(null);
    const [settingBody, setSettingBody] = useState<any>(null);
    const [settingLoading, setSettingLoading] = useState(false);
    const [settingErrored, setSettingErrored] = useState(false);

    const [settingSavingLoading, setSettingSavingLoading] = useState(false);
    const save = async () => {
        setSettingSavingLoading(true);
        const [d, e] = await defaultAxios.put(
            '/crm/workflow/processdef/activity',
            settingBody,
        );
        // await delay(1000);
        // const d = true;
        // const e = null;
        setSettingSavingLoading(false);
        if (isNetSuccess(d, e)) {
            message.success('保存成功');
            return true;
        }
        message.success('保存失败');
        return false;
    };

    useEffect(() => {
        setSettingBody(null);
        if (!isGoodRequest) {
            return;
        }
        (async () => {
            setSettingLoading(true);
            setSettingBody(null);

            const [d, e] = await defaultAxios.get(
                `/crm/workflow/processdef/activity?processDefinitionId=${processDefinitionId}&activityId=${activityId}`,
            );
            // await delay(1000);
            // const d: any = {
            //     code: '0',
            //     msg: '成功',
            //     data: {
            //         processDefinitionId:
            //             'myholiday:1:ce6785e5-863d-11eb-82b2-7e252d9689ba',
            //         activityId: 'UserTask_0eyg4la',
            //         activityName: '测试审批',
            //         activityType: 'userTask',
            //         buttons: ['SUBMIT', 'AGREE'],
            //         activityFields: [
            //             {
            //                 fieldCode: 'id',
            //                 fieldName: '序号',
            //                 isView: '1',
            //                 isEdit: '1',
            //                 isRequired: '1',
            //             },
            //             {
            //                 fieldCode: 'name',
            //                 fieldName: '名称',
            //                 isView: '1',
            //                 isEdit: '1',
            //                 isRequired: '1',
            //             },
            //         ],
            //     },
            // };
            // const e = null;
            if (d === null && e === null) {
                return;
            }

            setSettingLoading(false);
            if (!isNetSuccess(d, e) || !_.isObject(d?.data)) {
                setSettingErrored(true);
                return;
            }
            setSettingErrored(false);
            d.data.activityFields = d.data.activityFields || [];
            d.data.buttons = d.data.buttons || [];
            setSettingBody(d.data);
            setSettingOriginalBody(d.data);
        })();
    }, [processDefinitionId, activityId]);

    if (!isGoodRequest) {
        return null;
    }

    if (settingLoading) {
        return (
            <div style={{ height: 300 }}>
                <BlockLoading2 />
            </div>
        );
    }

    if (!settingBody) {
        return null;
    }

    const { activityFields, buttons } = settingBody || {};

    if (!_.isArray(activityFields) || !_.isArray(buttons)) {
        return (
            <Alert
                type="warning"
                message={'activityFields或buttons不是Array'}
            />
        );
    }
    const btnMap = arrToJsonPrimary(buttons);

    const columns = [
        {
            title: '字段',
            dataIndex: 'fieldName',
            key: 'fieldName',
        },
        {
            title: (
                <span>
                    查看 &nbsp;{' '}
                    <Button
                        size="small"
                        onClick={() => {
                            const nextSettingBody = _.cloneDeep(settingBody);
                            if (!_.isArray(nextSettingBody.activityFields)) {
                                return;
                            }
                            nextSettingBody.activityFields.forEach(
                                (item: any) => {
                                    item.isView = 1;
                                },
                            );
                            setSettingBody(nextSettingBody);
                        }}
                    >
                        全选
                    </Button>
                    &nbsp;
                    <Button
                        size="small"
                        onClick={() => {
                            const nextSettingBody = _.cloneDeep(settingBody);
                            if (!_.isArray(nextSettingBody.activityFields)) {
                                return;
                            }
                            nextSettingBody.activityFields.forEach(
                                (item: any) => {
                                    item.isView = 0;
                                },
                            );
                            setSettingBody(nextSettingBody);
                        }}
                    >
                        取消选择
                    </Button>
                </span>
            ),
            dataIndex: 'isView',
            key: 'isView',
            render: (v: any, item: any) => {
                return (
                    <Checkbox
                        checked={!!Number(v)}
                        onChange={e => {
                            if (!_.isNil(e.target.checked)) {
                                item.onIsViewChange(e.target.checked);
                            }
                        }}
                    />
                );
            },
        },
        {
            title: (
                <span>
                    编辑 &nbsp;{' '}
                    <Button
                        size="small"
                        onClick={() => {
                            const nextSettingBody = _.cloneDeep(settingBody);
                            if (!_.isArray(nextSettingBody.activityFields)) {
                                return;
                            }
                            nextSettingBody.activityFields.forEach(
                                (item: any) => {
                                    item.isEdit = 1;
                                },
                            );
                            setSettingBody(nextSettingBody);
                        }}
                    >
                        全选
                    </Button>
                    &nbsp;
                    <Button
                        size="small"
                        onClick={() => {
                            const nextSettingBody = _.cloneDeep(settingBody);
                            if (!_.isArray(nextSettingBody.activityFields)) {
                                return;
                            }
                            nextSettingBody.activityFields.forEach(
                                (item: any) => {
                                    item.isEdit = 0;
                                },
                            );
                            setSettingBody(nextSettingBody);
                        }}
                    >
                        取消选择
                    </Button>
                </span>
            ),
            dataIndex: 'isEdit',
            key: 'isEdit',
            render: (v: any, item: any) => {
                return (
                    <Checkbox
                        checked={!!Number(v)}
                        onChange={e => {
                            if (!_.isNil(e.target.checked)) {
                                item.onIsEditChange(e.target.checked);
                            }
                        }}
                    />
                );
            },
        },
        {
            title: (
                <span>
                    必须 &nbsp;{' '}
                    <Button
                        size="small"
                        onClick={() => {
                            const nextSettingBody = _.cloneDeep(settingBody);
                            if (!_.isArray(nextSettingBody.activityFields)) {
                                return;
                            }
                            nextSettingBody.activityFields.forEach(
                                (item: any) => {
                                    item.isRequired = 1;
                                },
                            );
                            setSettingBody(nextSettingBody);
                        }}
                    >
                        全选
                    </Button>
                    &nbsp;
                    <Button
                        size="small"
                        onClick={() => {
                            const nextSettingBody = _.cloneDeep(settingBody);
                            if (!_.isArray(nextSettingBody.activityFields)) {
                                return;
                            }
                            nextSettingBody.activityFields.forEach(
                                (item: any) => {
                                    item.isRequired = 0;
                                },
                            );
                            setSettingBody(nextSettingBody);
                        }}
                    >
                        取消选择
                    </Button>
                </span>
            ),
            dataIndex: 'isRequired',
            key: 'isRequired',
            render: (v: any, item: any) => {
                return (
                    <Checkbox
                        checked={!!Number(v)}
                        onChange={e => {
                            if (!_.isNil(e.target.checked)) {
                                item.onIsRequiredChange(e.target.checked);
                            }
                        }}
                    />
                );
            },
        },
    ];

    return (
        <>
            <div className="wa-setting-body">
                <h4>字段配置</h4>
                <div className="wa-setting-item">
                    <AntTable
                        dataSource={activityFields.map((item, index) => {
                            const onIsViewChange = (nextChecked: boolean) => {
                                const nextFields = [...activityFields];
                                const nextItem = { ...item };
                                nextItem.isView = Number(
                                    '' + Number(nextChecked),
                                );
                                nextFields[index] = nextItem;
                                const nextSettingBody = { ...settingBody };
                                nextSettingBody.activityFields = nextFields;
                                setSettingBody(nextSettingBody);
                            };
                            const onIsEditChange = (nextChecked: boolean) => {
                                const nextFields = [...activityFields];
                                const nextItem = { ...item };
                                nextItem.isEdit = Number(
                                    '' + Number(nextChecked),
                                );
                                nextFields[index] = nextItem;
                                const nextSettingBody = { ...settingBody };
                                nextSettingBody.activityFields = nextFields;
                                setSettingBody(nextSettingBody);
                            };
                            const onIsRequiredChange = (
                                nextChecked: boolean,
                            ) => {
                                const nextFields = [...activityFields];
                                const nextItem = { ...item };
                                nextItem.isRequired = Number(
                                    '' + Number(nextChecked),
                                );
                                nextFields[index] = nextItem;
                                const nextSettingBody = { ...settingBody };
                                nextSettingBody.activityFields = nextFields;
                                setSettingBody(nextSettingBody);
                            };
                            return {
                                ...item,
                                onIsViewChange,
                                onIsEditChange,
                                onIsRequiredChange,
                            };
                        })}
                        columns={columns}
                        pagination={false}
                    />
                </div>
                <br />
                <h4>按钮配置</h4>
                <div className="wa-setting-item">
                    {btnCns?.map(({ key, name, desc }) => {
                        const checked = btnMap[key];
                        return (
                            <Checkbox
                                checked={checked}
                                onChange={e => {
                                    if (!_.isUndefined(e.target.checked)) {
                                        const nextSettingBody = {
                                            ...settingBody,
                                        };
                                        if (e.target.checked) {
                                            const nextButtons = [...buttons];
                                            nextButtons.push(key);
                                            nextSettingBody.buttons = nextButtons;
                                        } else {
                                            const nextButtons = [...buttons];
                                            const removedIndex = nextButtons.indexOf(
                                                key,
                                            );
                                            nextButtons.splice(removedIndex, 1);
                                            nextSettingBody.buttons = nextButtons;
                                        }
                                        setSettingBody(nextSettingBody);
                                    }
                                }}
                            >
                                {name}
                                <span style={{ color: '#999' }}>
                                    （{desc}）
                                </span>
                            </Checkbox>
                        );
                    })}
                </div>
                {/* <pre>
                {JSON.stringify(settingBody, ' ' as any, 2)}
            </pre> */}
            </div>
            <div className="wa-setting-body-save-stage">
                <AntButton
                    className="btn"
                    loading={settingSavingLoading}
                    onClick={async () => {
                        await save();
                    }}
                    type="primary"
                >
                    保存
                </AntButton>
            </div>
        </>
    );
};

const btnCns = [
    { key: 'SUBMIT', name: '提交', desc: '申请人提交' },
    { key: 'AGREE', name: '通过', desc: '审批人同意' },
    { key: 'REJECT_BACK', name: '拒绝', desc: '审批人拒绝-退回上一步' },
    { key: 'REJECT_BEGIN', name: '拒绝', desc: '审批人拒绝-退回开始' },
    { key: 'REJECT_APPLY', name: '拒绝', desc: '审批人拒绝-退回申请' },
    { key: 'RESET', name: '撤回', desc: '申请人撤回' },
    { key: 'DELETE', name: '删除', desc: '申请人删除' },
    { key: 'ADD_APPROVE', name: '加签', desc: '审批人添加新的审批人（多人）' },
    { key: 'TRANSFER', name: '转办', desc: '审批人转移任务给其他人' },
];
const btnCnsMap = arrToJson(btnCns, 'key');
