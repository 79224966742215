import { makeAutoObservable } from 'mobx';
import { TPaginationStoreInstance } from '../../../../stores/utils/index';
import moment, { Moment } from 'moment';
import { defaultAxios } from '@/utils';
import _ from 'lodash';
import { PaginationStore } from '../../../../stores/utils/pagination';
import { message } from 'antd';
import { createDefaultInstanceGetterA } from '@/stores/utilsA';
export interface IEntityValueListData {
    value: string;
    id: string;
}
export interface IEntityListData {
    entityCode: string;
    entityName: string;
}
export interface IEntityActionTypeData {
    operatorName: string;
    operatorCode: string;
}
export interface IUserLogSearchData {
    beginDate: string | Moment;
    endDate: string | Moment;
    entityCode?: string; // 对象
    entityDesc?: string; // 对象值
    actionType?: string; // 具体行为
    userId?: string; // 操作者
}
export interface IOpDetailData {
    afterValue: string; // 之后
    beforeValue: string; // 之前
    entityField: string; // 修改方式（key）
    entityFieldDesc: string; // 修改方式（名称）
}
export interface IUserLogData {
    operatorUserName?: string; // 操作者
    operatorCode?: string; // 行为
    operatorName?: string; // 行为名
    entityCode?: string; // 对象（实体）
    entityIdDesc?: string; // 对象值
    entityDesc?: string; // 对象（名称）
    // entityValue?: string; // 对象展示值
    operatorTime?: string; // 操作时间
    operatorIp?: string;
    opLogItemDtoList?: IOpDetailData[]; // 操作内容 TODO:未定义
}
export type TGetUserLogReturnType = NetResponse<{
    data: IUserLogData[];
    msg: string;
    code: number;
}>;
export class UserLogStore {
    constructor() {
        makeAutoObservable(this);
    }
    public userLogData: IUserLogData[] = [];
    public entityListData: IEntityListData[] = []; // 对象列表
    public entityValueListData: IEntityListData[] = []; // 对象-对象值搜索列表
    public entityActionTypeListData: IEntityActionTypeData[] = []; // 对象-行为列表
    public entityActionTypeFetchLoading: boolean = false; // 对象-行为loading
    public entityValueFetchLoading: boolean = false; // 对象-对象值loading
    public entityListLoading: boolean = false; // 对象列表loading
    public countSearch: number = 0; // 时序控制 - 控制对象列表的获取
    public defaultUserLogPagination: TPaginationStoreInstance = new PaginationStore();

    public getUserLogData: (
        searchData: IUserLogSearchData,
    ) => Promise<TGetUserLogReturnType> = async searchData => {
        const finalSearchData = {
            ...searchData,
            beginDate: moment(searchData.beginDate).valueOf(),
            endDate: moment(searchData.endDate).valueOf(),
            pageSize: this.defaultUserLogPagination.pagesize,
            pageNum: this.defaultUserLogPagination.pagenum,
        };
        const url = '/bff/api/rest/log/user-log';
        const [d, e] = await defaultAxios.get(url, finalSearchData);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data?.data)) {
            this.defaultUserLogPagination.amount = data.totalSize || 0;
            this.userLogData = data.data;
        } else {
            message.error('查询失败');
            this.userLogData = [];
        }
        const error = e as Error | null;
        return [data, error];
    };
    public getUserLogEntityList = async () => {
        this.entityListLoading = true;
        const url = '/bff/api/rest/log/entity-list';
        const [d, e] = await defaultAxios.get(url);
        this.entityListLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data)) {
            this.entityListData = data;
        }
        const error = e as Error | null;
        return [data, error];
    };
    public getEntityActionTypeList = async (selectedEntityKey: string) => {
        this.entityActionTypeFetchLoading = true;
        const url = '/bff/api/rest/log/action-type-list';
        const [d, e] = await defaultAxios.get(url, {
            entityCode: selectedEntityKey,
        });
        this.entityActionTypeFetchLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data)) {
            this.entityActionTypeListData = data;
        }
        const error = e as Error | null;
        return [data, error];
    };
    public searchEntityValueList = _.debounce(
        async (selectedEntityKey: string, searchWord: string) => {
            this.entityValueFetchLoading = true;
            const url = '/bff/api/log/entity-value-list';
            this.countSearch = this.countSearch + 1;
            const meCount = this.countSearch;
            const [d, e] = await defaultAxios.get(url, {
                selectedEntityKey,
                searchWord,
            });
            if (this.countSearch != meCount) {
                return;
            }
            this.entityValueFetchLoading = false;
            if (d === null || d.data === null) {
                return [null, e];
            }
            const data = d.data;
            if (data && Array.isArray(data)) {
                this.entityValueListData = data;
            }
            const error = e as Error | null;
            return [data, error];
        },
        800,
    );
}

export const getDefaultUserLogStore = createDefaultInstanceGetterA(
    UserLogStore,
);
