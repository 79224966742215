import React, { useState, useEffect } from 'react';
import './index.less';
import { observer } from 'mobx-react-lite';
import { AntTabs } from '@/components';
import DistributeCustomerTab from './distribute';
import DistributeLogTab from './distribute-log';
const DistributionCustomerPage: React.FC = props => {
    const [selectedTabKey, setSelectedTabKey] = useState('1');
    return (
        <div className="dc-page-content">
            <AntTabs
                onChange={v => {
                    setSelectedTabKey(v);
                }}
                // animated={false}
                activeKey={selectedTabKey}
                tabConfig={[
                    {
                        key: '1',
                        tab: '批量分配客户',
                        content: (
                            <DistributeCustomerTab
                                changeTab={setSelectedTabKey}
                            />
                        ),
                    },
                    {
                        key: '2',
                        tab: '分配记录',
                        content: <DistributeLogTab />,
                    },
                ]}
            ></AntTabs>
        </div>
    );
};

export default observer(DistributionCustomerPage);
