import React, { useContext, useEffect, useState, CSSProperties } from 'react';
import './index.less';
import { observer } from 'mobx-react-lite';
import { defaultAxios, isNetSuccess } from '@/utils';
import _ from 'lodash';
import moment from 'moment';
import { StoreContext } from '@/stores';
import { Spin, Statistic, Badge, Popover } from 'antd';
import { entityCodeToUrl } from '../../store';
import qs from 'querystring';
import { goToOtherPage } from '../home-ana-opt/index';
const HomeMineFrontSaleInfo: React.FC = observer(() => {
    const store = useContext(StoreContext);
    const homeNewStore = store.getDefaultHomeNewStore();
    const { homeNewRightInfoData, homeNewRightInfoLoading } = homeNewStore;
    const homeNewFrontInfoData =
        homeNewRightInfoData.beforeSaleAbnormalJumpInfo;
    const statisticValueStyle: CSSProperties = {
        fontSize: '16px',
        color: '#0052ff',
        fontWeight: 500,
    };
    const setLocalStorageUserByKey = (key: string) => {
        localStorage.setItem(
            key,
            JSON.stringify(
                _.map(homeNewRightInfoData.userInfoList, uItem => {
                    return {
                        id: uItem.userId,
                        key: `user-${uItem.userId}`,
                        name: uItem.userName,
                        title: uItem.userName,
                    };
                }),
            ),
        );
    };
    const allCount =
        (homeNewFrontInfoData?.notFollowLead || 0) +
        (homeNewFrontInfoData?.more30dayNotFollowOpportunity || 0);
    return (
        <Popover content={'请及时处理，避免运营执行客户回收策略'}>
            <div className="home-new-right-info-content">
                <Spin spinning={homeNewRightInfoLoading}>
                    <div className="crm-home-front-info">
                        <p className="home-page-title">
                            <div className="home-page-title-text">售前异常</div>
                            <Badge
                                offset={[6, -3]}
                                count={allCount}
                                overflowCount={999}
                            ></Badge>
                        </p>
                        <div className="home-front-info-body">
                            {/* <div
                                className="front-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        '7_recent_activity_record_time_fk',
                                    );
                                    goToOtherPage(
                                        'private_customer',
                                        '7_recent_activity_record_time',
                                        '7_recent_activity_record_time_fk',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewFrontInfoData?.more7dayNotFollowCustomer ||
                                        '0'
                                    }
                                />
                                <p className="front-info-label">超7天未跟进</p>
                            </div>
                            <div
                                className="front-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        '15_recent_activity_record_time_fk',
                                    );
                                    goToOtherPage(
                                        'private_customer',
                                        '15_recent_activity_record_time',
                                        '15_recent_activity_record_time_fk',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewFrontInfoData?.more15dayNotFollowCustomer ||
                                        '0'
                                    }
                                />
                                <p className="front-info-label">超15天未跟进</p>
                            </div> */}
                            {/* <div
                                className="front-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        '30_recent_activity_record_time_fk',
                                    );
                                    goToOtherPage(
                                        'private_customer',
                                        '30_recent_activity_record_time',
                                        '30_recent_activity_record_time_fk',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewFrontInfoData?.more30dayNotFollowCustomer ||
                                        '0'
                                    }
                                />
                                <p className="front-info-label">超30天未跟进</p>
                            </div> */}
                            <div
                                className="front-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey('undeal-lead-fk');
                                    goToOtherPage(
                                        'leadmine',
                                        'undeal-lead',
                                        'undeal-lead-fk',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewFrontInfoData?.notFollowLead ||
                                        '0'
                                    }
                                />
                                <p className="front-info-label">未处理线索</p>
                            </div>
                            <div
                                className="front-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey('yy-fk');
                                    goToOtherPage('opportunity', 'yy', 'yy-fk');
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewFrontInfoData?.more30dayNotFollowOpportunity ||
                                        '0'
                                    }
                                />
                                <p className="front-info-label">未更新商机</p>
                            </div>
                            <div
                                className="front-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey('stage40-pending');
                                    goToOtherPage(
                                        'opportunity',
                                        '',
                                        'stage40-pending',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewFrontInfoData?.opportunityTobeConfirmed ||
                                        '0'
                                    }
                                />
                                <p className="front-info-label">待确认商机数</p>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </Popover>
    );
});
export default HomeMineFrontSaleInfo;
