import _ from 'lodash';
import { customerDFConfs } from './dfilters/customer';
import { JobCusFollowDFConfs } from './dfilters/jobs/cus-follow';
import { demoDFConfs } from './dfilters/demo';
import { leadDFConfs } from './dfilters/lead';
import { optDFConfs } from './dfilters/opportunity';
import { optProductDFConfs } from './dfilters/opportunity_product';
import { contactsDFConfs } from './dfilters/contacts';
import { processDFConfs } from './dfilters/process';
import { contractDFConfs } from './dfilters/contract';
import { AchieveManaDFConfs } from './dfilters/achievement_mana';

export const dfConfs: { [entityCode: string]: any } = {
    ...customerDFConfs,
    ...optProductDFConfs,
    ...demoDFConfs,
    ...leadDFConfs,
    ...optDFConfs,
    ...contactsDFConfs,
    ...processDFConfs,
    ...JobCusFollowDFConfs,
    ...contractDFConfs,
    ...AchieveManaDFConfs,
};

export const getDFiltersByEntityCode = async (entityCode: string) => {
    const confs = dfConfs[entityCode] || {};
    return confs;
};
