import React, { useEffect } from 'react';
import { Modal, Form, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import UserSelector from '@/components/common/user-selector';
import { useRequest } from 'ahooks';
import _ from 'lodash';
import { getCanAddCustomer } from './api';

export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    initialValues?: any;
    onSubmit: (val: any) => void;
    onClose: () => void;
}

const FormModal: React.FC<IFormModalProps> = ({
    visible,
    initialValues,
    form,
    onSubmit,
    onClose,
}) => {
    const {
        getFieldDecorator,
        setFieldsValue,
        validateFields,
        resetFields,
    } = form;

    const { data: userDocs } = useRequest(getCanAddCustomer);

    useEffect(() => {
        _.isEmpty(initialValues)
            ? resetFields()
            : setFieldsValue(initialValues);
    }, [initialValues]);

    useEffect(() => {
        if (!visible) {
            resetFields();
        }
    }, [visible]);

    const handleSubmit = () => {
        validateFields(async (errs, values) => {
            if (errs) {
                return;
            }
            console.log('values', values);
            onSubmit(values);
        });
    };

    return (
        <Modal
            visible={visible}
            title={`${initialValues?.id ? '修改' : '新增'}优先销售`}
            onCancel={onClose}
            onOk={handleSubmit}
        >
            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                {!!initialValues?.id && (
                    <Form.Item>{getFieldDecorator('id')}</Form.Item>
                )}
                <Form.Item label="选择销售">
                    {getFieldDecorator('earlyUserId', {
                        rules: [
                            {
                                required: true,
                                message: '请选择销售',
                            },
                        ],
                    })(
                        <UserSelector
                            userDocs={userDocs}
                            placeholder="请选择销售"
                        />,
                    )}
                </Form.Item>
                <Form.Item label="提前时长（秒）">
                    {getFieldDecorator('earlyTime', {
                        rules: [
                            {
                                required: true,
                                message: '请输入提前时长',
                            },
                        ],
                    })(
                        <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                            precision={0}
                            placeholder="请输入提前时长"
                        />,
                    )}
                </Form.Item>
                <Form.Item label="领取个数">
                    {getFieldDecorator('earlyCount', {
                        rules: [
                            {
                                required: true,
                                message: '请输入领取个数',
                            },
                        ],
                    })(
                        <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                            precision={0}
                            placeholder="请输入领取个数"
                        />,
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Form.create<IFormModalProps>()(FormModal);
