import React, { memo } from 'react';

export const DuplicateFlag: React.FC<{
    args: any[];
    item: any;
    onAction: (...args: any[]) => void;
}> = memo(({ args, item, onAction }) => {
    const value = +args[0];
    if (!value) {
        return null;
    }
    const style = {
        backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/mis/yellow-alert.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        height: 18,
        width: 18,
        cursor: 'pointer',
    };
    return (
        <div
            onClick={() => {
                onAction('see-dupli-detail', item);
            }}
            style={style}
        />
    );
});
