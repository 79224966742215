import React from 'react';
import _ from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import TableCard, { TableCardProps } from '../../components/table-card';
import TableTitle from '../../components/table-title';

// const RegionMap = {
//     63: '大客销售部',
//     64: '普客销售部'
// }

const RegionMap = new Map([
    [63, '大客销售部'],
    [64, '普客销售部'],
]);

function OpportunityTable<T>({
    dataSource,
    loading,
    customerType = 0,
    orgId,
    ...rest
}: TableCardProps<T> & { customerType?: number; orgId: string | number }) {
    console.log('datasourceee', dataSource)
    const renderNum = (t: any, record: any) => {
        console.log('OpportunityTable', record);
        const [num = '-', range = '', rate = ''] = t?.split('_') || [];
        // console.log('num, range, rate', record, num, range, rate);
        const text = Math.round(Number(num));

        const region = RegionMap.get(Number(orgId));
        const cityName =
            record?.city_name === '全国' ? 'all' : record?.city_name;
        // ?filterKey=opt-(all0 | new1 | old2)-40-today-华北-all-all
        return !_.isNaN(text) ? (
            <a
                href={`/buy/opportunity/all?filterKey=opt-${customerType}-${rate}-${range}-${region ||
                    'all'}-${cityName || 'all'}-${record?.group_name ||
                    'all'}-${record?.sale_id || 'all'}-${record?.sale_name ||
                    'all'}`}
                target="_blank"
            >
                {text}
            </a>
        ) : (
            '-'
        );
    };

    const renderMoney = (t: string) => {
        const [num = ''] = t?.split('_') || [];
        const text = Number(Number(num).toFixed(1));
        return !_.isNaN(text) && text !== 0 ? `${text}万` : '-';
    };

    const columns: ColumnProps<T>[] = [
        {
            title: '指标项',
            dataIndex: 'name',
            width: 130,
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: '今日新增商机情况',
            children: [
                {
                    title: (
                        <TableTitle
                            title="20商机个数"
                            tips="首次推进时间在今天的商机个数，退回20的不计入"
                        />
                    ),
                    dataIndex: 'today_20_count',
                    render: renderNum,
                },
                // {
                //     title: '20商机金额',
                //     dataIndex: 'today_20_amount',
                //     render: renderMoney,
                // },
                {
                    title: (
                        <TableTitle
                            title="40商机个数"
                            tips="首次确认40商机的时间为今天的商机个数之和，退回40的和未确认40商机的不计入"
                        />
                    ),
                    dataIndex: 'today_40_count',
                    render: renderNum,
                },
                {
                    title: (
                        <TableTitle
                            title="40商机金额"
                            tips="统计期内首次确认的40商机对应的预计销售金额之和。
                            1、统计期内首次确认40且当前是40或20：商机预计销售金额之和。
                            2、统计期内首次确认40且是当前已是60/80/90/100商机：商机合同待回款金额+商机合同已回款金额。"
                        />
                    ),
                    dataIndex: 'today_40_amount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="40商机储备金额"
                            tips="统计期内首次确认的40商机对应待回款金额之和。
                            1、统计期内首次确认40且当前依旧是40或20：商机预计销售金额之和。
                            2、统计期内首次确认40且当前已是60/80/90/100商机：商机合同待回款金额。"
                        />
                    ),
                    dataIndex: 'today_40_reserveAmount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="40商机已回款金额"
                            tips="统计期内首次确认40且当前已是90/100商机的合同已回款金额。"
                        />
                    ),
                    dataIndex: 'today_40_receiveAmount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="60商机个数"
                            tips="首次推进60商机的时间为今天的商机个数之和，含未确认但直接推入到60的商机，退回60不计入"
                        />
                    ),
                    dataIndex: 'today_60_count',
                    render: renderNum,
                },
                {
                    title: (
                        <TableTitle
                            title="60商机待回款金额"
                            tips="在今天完成首次推进的60商机对应的合同待回款金额之和"
                        />
                    ),
                    dataIndex: 'today_60_amount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="80商机个数"
                            tips="首次推进80商机的时间为今天的商机个数之和，退回80的不计入"
                        />
                    ),
                    dataIndex: 'today_80_count',
                    render: renderNum,
                },
                {
                    title: (
                        <TableTitle
                            title="80商机待回款金额"
                            tips="在今天完成首次推进的80商机对应的额合同待回款金额之和"
                        />
                    ),
                    dataIndex: 'today_80_amount',
                    render: renderMoney,
                },
            ],
        },
        {
            title: '本周新增商机情况',
            children: [
                {
                    title: (
                        <TableTitle
                            title="20商机个数"
                            tips="首次推进时间在本周的商机个数，退回20的不计入"
                        />
                    ),
                    dataIndex: 'week_20_count',
                    render: renderNum,
                },
                // {
                //     title: '20商机金额',
                //     dataIndex: 'week_20_amount',
                //     render: renderMoney,
                // },
                {
                    title: (
                        <TableTitle
                            title="40商机个数"
                            tips="首次确认40商机的时间为本周的商机个数之和，退回40的和未确认40商机的不计入"
                        />
                    ),
                    dataIndex: 'week_40_count',
                    render: renderNum,
                },
                {
                    title: (
                        <TableTitle
                            title="40商机金额"
                            tips="统计期内首次确认的40商机对应的预计销售金额之和。
                            1、统计期内首次确认40且当前是40或20：商机预计销售金额之和。
                            2、统计期内首次确认40且是当前已是60/80/90/100商机：商机合同待回款金额+商机合同已回款金额。"
                        />
                    ),
                    dataIndex: 'week_40_amount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="40商机储备金额"
                            tips="统计期内首次确认的40商机对应待回款金额之和。
                            1、统计期内首次确认40且当前依旧是40或20：商机预计销售金额之和。
                            2、统计期内首次确认40且当前已是60/80/90/100商机：商机合同待回款金额。"
                        />
                    ),
                    dataIndex: 'week_40_reserveAmount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="40商机已回款金额"
                            tips="统计期内首次确认40且当前已是90/100商机的合同已回款金额。"
                        />
                    ),
                    dataIndex: 'week_40_receiveAmount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="60商机个数"
                            tips="首次推进60商机的时间为本周的商机个数之和，含未确认但直接推入到60的商机，退回60不计入"
                        />
                    ),
                    dataIndex: 'week_60_count',
                    render: renderNum,
                },
                {
                    title: (
                        <TableTitle
                            title="60商机待回款金额"
                            tips="在本周完成首次推进的60商机对应的合同待回款金额之和"
                        />
                    ),
                    dataIndex: 'week_60_amount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="80商机个数"
                            tips="首次推进80商机的时间为本周的商机个数之和，退回80的不计入"
                        />
                    ),
                    dataIndex: 'week_80_count',
                    render: renderNum,
                },
                {
                    title: (
                        <TableTitle
                            title="80商机待回款金额"
                            tips="在本周完成首次推进的80商机对应的额合同待回款金额之和"
                        />
                    ),
                    dataIndex: 'week_80_amount',
                    render: renderMoney,
                },
            ],
        },
        {
            title: '本月新增商机情况',
            children: [
                {
                    title: (
                        <TableTitle
                            title="20商机个数"
                            tips="首次推进时间在本月的商机个数，退回20的不计入"
                        />
                    ),
                    dataIndex: 'month_20_count',
                    render: renderNum,
                },
                // {
                //     title: '20商机金额',
                //     dataIndex: 'month_20_amount',
                //     render: renderMoney,
                // },
                {
                    title: (
                        <TableTitle
                            title="40商机个数"
                            tips="首次确认40商机的时间为本月的商机个数之和，退回40的和未确认40商机的不计入"
                        />
                    ),
                    dataIndex: 'month_40_count',
                    render: renderNum,
                },
                {
                    title: (
                        <TableTitle
                            title="40商机金额"
                            tips="统计期内首次确认的40商机对应的预计销售金额之和。
                            1、统计期内首次确认40且当前是40或20：商机预计销售金额之和。
                            2、统计期内首次确认40且是当前已是60/80/90/100商机：商机合同待回款金额+商机合同已回款金额。"
                        />
                    ),
                    dataIndex: 'month_40_amount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="40商机储备金额"
                            tips="统计期内首次确认的40商机对应待回款金额之和。
                            1、统计期内首次确认40且当前依旧是40或20：商机预计销售金额之和。
                            2、统计期内首次确认40且当前已是60/80/90/100商机：商机合同待回款金额。"
                        />
                    ),
                    dataIndex: 'month_40_reserveAmount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="40商机已回款金额"
                            tips="统计期内首次确认40且当前已是90/100商机的合同已回款金额。"
                        />
                    ),
                    dataIndex: 'month_40_receiveAmount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="60商机个数"
                            tips="首次推进60商机的时间为本月的商机个数之和，含未确认但直接推入到60的商机，退回60不计入"
                        />
                    ),
                    dataIndex: 'month_60_count',
                    render: renderNum,
                },
                {
                    title: (
                        <TableTitle
                            title="60商机待回款金额"
                            tips="在本月完成首次推进的60商机对应的合同待回款金额之和"
                        />
                    ),
                    dataIndex: 'month_60_amount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="80商机个数"
                            tips="首次推进80商机的时间为本月的商机个数之和，退回80的不计入"
                        />
                    ),
                    dataIndex: 'month_80_count',
                    render: renderNum,
                },
                {
                    title: (
                        <TableTitle
                            title="80商机待回款金额"
                            tips="在本月完成首次推进的80商机对应的额合同待回款金额之和"
                        />
                    ),
                    dataIndex: 'month_80_amount',
                    render: renderMoney,
                },
            ],
        },
        {
            title: '本双月新增商机情况',
            children: [
                {
                    title: (
                        <TableTitle
                            title="20商机个数"
                            tips="首次推进时间在本季的商机个数，退回20的不计入"
                        />
                    ),
                    dataIndex: 'session_20_count',
                    render: renderNum,
                },
                // {
                //     title: '20商机金额',
                //     dataIndex: 'quarter_20_amount',
                //     render: renderMoney,
                // },
                {
                    title: (
                        <TableTitle
                            title="40商机个数"
                            tips="首次确认40商机的时间为本季的商机个数之和，退回40的和未确认40商机的不计入"
                        />
                    ),
                    dataIndex: 'session_40_count',
                    render: renderNum,
                },
                {
                    title: (
                        <TableTitle
                            title="40商机金额"
                            tips="统计期内首次确认的40商机对应的预计销售金额之和。
                            1、统计期内首次确认40且当前是40或20：商机预计销售金额之和。
                            2、统计期内首次确认40且是当前已是60/80/90/100商机：商机合同待回款金额+商机合同已回款金额。"
                        />
                    ),
                    dataIndex: 'session_40_amount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="40商机储备金额"
                            tips="统计期内首次确认的40商机对应待回款金额之和。
                            1、统计期内首次确认40且当前依旧是40或20：商机预计销售金额之和。
                            2、统计期内首次确认40且当前已是60/80/90/100商机：商机合同待回款金额。"
                        />
                    ),
                    dataIndex: 'session_40_reserveAmount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="40商机已回款金额"
                            tips="统计期内首次确认40且当前已是90/100商机的合同已回款金额。"
                        />
                    ),
                    dataIndex: 'session_40_receiveAmount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="60商机个数"
                            tips="首次推进60商机的时间为本季的商机个数之和，含未确认但直接推入到60的商机，退回60不计入"
                        />
                    ),
                    dataIndex: 'session_60_count',
                    render: renderNum,
                },
                {
                    title: (
                        <TableTitle
                            title="60商机待回款金额"
                            tips="在本季完成首次推进的60商机对应的合同待回款金额之和"
                        />
                    ),
                    dataIndex: 'session_60_amount',
                    render: renderMoney,
                },
                {
                    title: (
                        <TableTitle
                            title="80商机个数"
                            tips="首次推进80商机的时间为本季的商机个数之和，退回80的不计入"
                        />
                    ),
                    dataIndex: 'session_80_count',
                    render: renderNum,
                },
                {
                    title: (
                        <TableTitle
                            title="80商机待回款金额"
                            tips="在本季完成首次推进的80商机对应的额合同待回款金额之和"
                        />
                    ),
                    dataIndex: 'session_80_amount',
                    render: renderMoney,
                },
            ],
        },
    ];
    return (
        <TableCard
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            className="board-solar-table"
            style={{ boxShadow: 'none', padding: 0 }}
            rowKey="name"
            scroll={{ x: 3500, y: 355 }}
            bodyStyle={{ maxHeight: 355 }}
            {...rest}
        />
    );
}

export default OpportunityTable;
