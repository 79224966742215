import React from 'react';
import _ from 'lodash';
import { getQueryAsObject } from '@/utils';
import {
    Icon,
    message,
    Dropdown,
    Menu,
    Input,
    Button,
    Spin,
    Collapse,
} from 'antd';
import { commonDelete } from '@/api/rest';

export interface ICrmTimelineItemProps {
    id?: string | number;
    time: React.ReactNode;
    background: string;
    icon: React.ReactNode;
    content: React.ReactNode;
    reloadFunc?: () => void;
    orignalPerm?: any;
}

export interface ICrmTimelineItemGroupProps {
    day: string;
    timelineItems: ICrmTimelineItemProps[];
}

export const CrmTimelineItem: React.FC<ICrmTimelineItemProps> = ({
    time,
    icon,
    content,
    background,
}) => {
    return (
        <div className="crm-timeline-item">
            <div style={{ background }} className="icon">
                {icon}
            </div>
            <div className="content">{content}</div>
            <div className="dash-line" />
        </div>
    );
};
export const CrmTimelineItemNew: React.FC<ICrmTimelineItemProps> = ({
    time,
    icon,
    content,
    background,
    id,
    reloadFunc,
    orignalPerm,
}) => {
    // 删除活动记录
    const deleteActionLogNet = async (id: string | number) => {
        const [d, e] = await commonDelete('cu_activation_record', [
            id as number,
        ]);
        if (!e) {
            console.log('reloadFunc', reloadFunc);
            reloadFunc && reloadFunc();
            message.info('删除成功');
            return true;
        }
    };
    let backgroundColor = '#f4f5fa';
    if (id === Number(getQueryAsObject().recordId)) {
        backgroundColor = 'rgba(0, 82, 255, 0.12)';
    }
    return (
        <>
            <div className="crm-timeline-item-new" key={id}>
                <div className="crm-timeline-item-title">
                    <div className="crm-timeline-item-left">
                        <div style={{ background }} className="icon">
                            <Icon type={icon as string} />
                        </div>
                        <div className="time">创建时间：{time}</div>
                    </div>
                    {orignalPerm?.['sales-follow-up:activity:delete'] && (
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item>
                                        <div
                                            onClick={() => {
                                                deleteActionLogNet(
                                                    id as string,
                                                );
                                            }}
                                        >
                                            删除
                                        </div>
                                    </Menu.Item>
                                </Menu>
                            }
                            placement={'bottomRight'}
                        >
                            <span className="delete">
                                <Icon type="down" />
                            </span>
                        </Dropdown>
                    )}
                </div>
                <div className="crm-timeline-item-container">
                    <div className="dash-line" />
                    <div className="content" style={{ backgroundColor }}>
                        {content}
                    </div>
                </div>
            </div>
        </>
    );
};

export const CrmTimelineItemGroup: React.FC<ICrmTimelineItemGroupProps & {
    isNew?: boolean;
}> = ({ day, timelineItems, isNew }) => {
    return (
        <div className="crm-timeline-item-group">
            <div className="day">{day}</div>
            <div className="group">
                {_.map(timelineItems, (timelineItem, key) => {
                    // return <CrmTimelineItem {...timelineItem} key={key} />;
                    return isNew ? (
                        <CrmTimelineItemNew {...timelineItem} key={key} />
                    ) : (
                        <CrmTimelineItem {...timelineItem} key={key} />
                    );
                })}
            </div>
        </div>
    );
};
