import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    makeDefaultInitailingHook,
    FastFilterStore,
} from '@/stores/utils';
import {
    NexusGenFieldTypes,
    NexusGenInputs,
} from '@/definations/graphql/auto-gen';
import {
    TFetchCustomersReturnType,
    fetchCustomers,
    mutate,
} from '@/api/graphql';
import _ from 'lodash';
import {
    serializeFilterData2,
    serializeFilterData3,
} from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    getLeads,
    TGetLeadsReturnType,
    xDelete,
    TGetCommonReturnType,
    commonQuery,
    getCommonQueryBody,
} from '@/api/rest';
import {
    defaultWritingDataParser,
    isAllValidateResultSuccess,
    defaultAxios,
    isZeroValue,
    MenuStatisticEE,
} from '@/utils';
import EE from 'eventemitter3';
import { IMOpResult } from '@/components';
import $ from 'jquery';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';
import FinalLeadList from '../../opt/list';
import { makeDefaultInitailingHookB } from '@/stores/utilsA';
import { createDefaultInstanceGetterA } from '../../../../stores/utilsA';

interface IAutoFilling {
    customerName: string;
    customerStatus: number; // 0 1 2
    ownerName: string;
    leadInfo: any;
}

const transformBackEndValidateStateToValidateResult = (
    data: any[],
): ValidateResult<any> => {
    const ret: ValidateResult<any> = {};
    for (const d of data) {
        const { errorMsg, errorType, fieldName } = d;
        if (!errorMsg || !errorType || !fieldName) {
            continue;
        }
        ret[fieldName] = {
            msg: errorMsg,
            status: errorType === 'error' ? 'fail' : 'warning',
        };
    }
    return ret;
};

export class LeadListStore {
    // 数据
    public data: any[] = [];

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public position: string = '';
    public setPosition(position: string) {
        this.position = position;
    }

    public parentListType: string = '';
    public setParentListType(parentListType: string) {
        this.parentListType = parentListType;
    }

    public get pageTitle() {
        return '线索列表';
    }

    public defaultFilters: any[] = [];

    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    public defaultFields: string[] = [
        'contact_id',
        'opportunity_id',
        'follow_status',
    ];
    public setDefaultFields(fields: string[]) {
        this.defaultFields = fields;
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFastFilters = this.defaultFastFilters;
        const {
            selectedFastFilters,
            selectedLv4MenuFilters,
        } = defaultFastFilters;

        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );

        ret.filters?.push(...defaultFilters);

        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        for (const filterSet of selectedFastFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        for (const filterSet of selectedLv4MenuFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public async clearParamsValidateState(keys: string[]) {
        const nextNetValidateResult = { ...this.netValidateResult };
        for (const key of keys) {
            delete nextNetValidateResult[key];
        }
        this.netValidateResult = nextNetValidateResult;
    }

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }
    public newCustomerFormVisible = false;
    public setNewCustomerFormVisible(nextVisible: boolean) {
        this.newCustomerFormVisible = nextVisible;
    }
    public setAction(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra.id) {
                this.fetchInstantlyMutatingDataById(extra.id);
            } else {
                message.error('修改必须传id');
            }
        }
        this.actionType = nextAction;
    }
    public async setActionAsync(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra) {
                if (extra.id) {
                    const ok = await this.fetchInstantlyMutatingDataById(
                        extra.id,
                    );
                    if (!ok) {
                        return false;
                    }
                } else {
                    message.error('修改必须传id');
                    return false;
                }
            }
        }
        this.actionType = nextAction;
        return true;
    }
    public resetAction() {
        this.clearAutoFillData();
        this.netValidateResult = {};
        this.actionType = '';
    }
    public async dispatchAction() {
        if (this.actionType === 'create') {
            return this.create();
        } else if (this.actionType === 'update') {
            return this.update();
        }
    }

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;
    get mutatingDataParsed() {
        if (this.mutatingData) {
            return defaultWritingDataParser(this.mutatingData);
        }
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        const initialFormData = this.defaultMeta.defaultWidgetValues;
        this.mutatingData = _.cloneDeep(initialFormData);
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;

    // TODO, 需要根据回填信息，判断是否需要转客户，以及展示客户的公海情况
    get shouldGoToNewCustomer() {
        // 如果是从已有客户tab进入，从不转客户
        if (this.isFromExsitCustomerTab) {
            return false;
        }

        // 不是新建，从不转客户
        if (this.actionType !== 'create') {
            return false;
        }

        // 如果未确认用户来源，总是可能转客户（或者总是不转？）
        if (this.autoFillingByCName === null) {
            return true;
        }

        // 不在任何池子，提示转客户
        const customerStatus = this.autoFillingByCName?.customerStatus || 0;
        if (customerStatus === 0) {
            return true;
        }

        // 在池子，不转客户
        return false;
    }

    get companyNameLint() {
        if (this.isFromExsitCustomerTab) {
            return '';
        }
        if (this.actionType !== 'create') {
            return '';
        }
        if (this.autoFillingByCName === null) {
            return '';
        }
        const customerStatus = this.autoFillingByCName?.customerStatus || 0;
        const ownerName = this.autoFillingByCName?.ownerName || '';
        if (customerStatus === 1) {
            return '与公海客户重复，线索将归属到公海客户';
        } else if (customerStatus === 2) {
            return `与【${ownerName ||
                'unknow'}】的私海客户重复，线索将归属到该销售客户`;
        } else if (customerStatus === 0) {
            return `与客户池与关联客户都不重复，可新建客户`;
        }
        return '未知customerStatus';
    }

    public autoFillingByCName: IAutoFilling | null = null;
    public autoFillingLoading: boolean = false;

    public clearAutoFillData() {
        this.autoFillingByCName = null;
    }
    public async autoFillOtherParamsByCustomerName(customerName: string) {
        if (isZeroValue(customerName)) {
            return;
        }
        this.autoFillingLoading = true;
        const [d, e] = await defaultAxios.get(
            '/bff/api/rest/lead/fillLeadInfo',
            {
                customerName,
            },
        );
        this.autoFillingLoading = false;

        const ret = d?.data;

        const mutatingDataSuggestion = ret?.lead?.leadInfo || {};

        const nextMutatingData = _.assign(
            this.mutatingData,
            mutatingDataSuggestion,
        );
        runInAction(() => {
            this.mutatingData = nextMutatingData;
            this.autoFillingByCName = ret?.lead || {};
            if (this.autoFillingByCName) {
                this.autoFillingByCName.customerName = customerName;
            }
            this.clearParamsValidateState(_.keys(this.autoFillingByCName));
        });
    }

    public async create() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        delete data.id;
        delete data.key;
        const [ret, errMsg] = await mutate('lead', 'insert', data);
        if (!ret || errMsg) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('新建成功');
        this.defaultEE.emit('create-success');
        return true;
    }
    public async update(mutatingDataParsed?: any) {
        const data = _.cloneDeep(mutatingDataParsed || this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        delete data.key;
        const [ret, errMsg] = await mutate('lead', 'update', data);
        if (!ret || errMsg) {
            return false;
        }
        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('修改成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    public async transform(customer: any, transferLeadReason: any) {
        if (_.isNil(customer)) {
            message.error('目标用户未选择，转移失败');
            return false;
        }
        const mutatingData = this.mutatingData;

        const [d, e] = await defaultAxios.post('/crm/lead/transfer2Customer', {
            leadId: mutatingData.id,
            customerId: customer.originalValue,
            transferLeadReason,
        });
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('转移成功');
            return true;
        } else {
            this.defaultMSelect.setMopResult('转移结果', d.data);
            if (warningNum !== 0) {
                return true;
            }
            return false;
        }
    }

    get fieldAuthCode() {
        if (this.position === 'customer-drawer') {
            return 'PUBLIC-VIEW';
            // return this.parentListType === 'all'
            //     ? 'PUBLIC-VIEW'
            //     : 'PRIVATE-VIEW';
        }
        return this.listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    }

    get dataAuthCode() {
        if (this.position === 'customer-drawer') {
            return 'SEARCH-ALL';
            // return this.parentListType === 'all'
            //     ? 'SEARCH-PUBLIC'
            //     : 'SEARCH-PRIVATE';
        }
        return this.listType === 'all' ? 'SEARCH-PUBLIC' : 'SEARCH-PRIVATE';
    }

    public async fetchInstantlyMutatingDataById(id: any) {
        this.startNewData();

        this.mutatingDataLoading = true;
        const response = await this.fetchMutatingDataById(id);
        this.mutatingDataLoading = false;

        if (response === false) return false;

        this.mutatingData = response;
    }

    public async fetchMutatingDataById(id: any) {
        const { fieldAuthCode, dataAuthCode } = this;
        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: id,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            undefined,
            dataAuthCode,
        );

        if (d === null || e !== null) {
            return false;
        }
        const { datas } = d;
        if (datas && datas[0]) {
            datas[0]._original_follow_status = datas[0].follow_status;
            return datas[0];
        } else {
            message.error('没有该数据的权限');
            return false;
        }
    }

    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode, dataAuthCode } = this;
        MenuStatisticEE.emit('refresh'); // 如果前置操作认为列表已变化，那么认为菜单统计也有变化
        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            [
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
                ...this.defaultFields,
            ],
            dataAuthCode,
        );

        if (d === null || e !== null) {
            return [d, e];
        }
        const leadsData = d;
        const { amount, datas } = leadsData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    get downloadUrl() {
        const { fieldAuthCode, dataAuthCode } = this;
        const { tableId: objectName } = this.defaultMeta;
        const { amount } = this.defaultPagination;
        if (!amount) {
            return '';
        }
        const pageSize = Math.max(Math.min(Math.ceil(amount / 10), 300), 100);
        const fetchBody = getCommonQueryBody(
            { ...this.defaultPagination.paginationArgs, pageSize },
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            this.defaultMeta.tableColsParamsFromUserSetting.map(
                item => item.key,
            ),
            dataAuthCode,
            false,
        );
        fetchBody.objectName = objectName;
        return (
            `${
                window.location.origin
            }/bff/api/rest/export?q=${encodeURIComponent(
                JSON.stringify(fetchBody),
            )}&hash=` + Math.random()
        );
    }

    public dupDetailLead: any = null;
    public setDupDetailLead(lead: any) {
        this.dupDetailLead = lead;
    }
    public clearDupDetailLead() {
        this.dupDetailLead = null;
    }

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMeta = new ListMeta(
        '/bff/api/rest/meta/lead/list',
        '/bff/api/rest/meta/lead/list/filters/save',
        '/bff/api/rest/meta/lead/list/table-cols/save',
    );
    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();
    public defaultPerm: Perm = new Perm(
        '/bff/api/rest/perm?mod=sales-follow-up:lead',
    );
    public defaultFastFilters = new FastFilterStore();
    public defaultMSelect: MSelectStore = new MSelectStore();
    constructor() {
        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultMeta: false,
            defaultEE: false,
            defaultPerm: false,
            defaultMSelect: false,
        });
    }
}

export const getDefaultLeadListStore = createDefaultInstanceGetterA(
    LeadListStore,
);

export const useAnyLeadStore = makeDefaultInitailingHookB(
    LeadListStore,
    'lead',
);

export const genLeadIdParamByContractItemAndParamMeta = (
    lead: any,
    fieldMeta?: any,
) => {
    // HACK: 先写死
    lead = lead || {};
    if (!lead.id || !lead.name) {
        return null;
    }
    return {
        type: 'obj',
        showFiledName: 'name',
        originalValue: lead.id,
        relatedObj: {
            relation_object_map: {},
            id: lead.id,
            name: lead.name,
        },
        showRelatedObj: {
            relation_object_map: {},
            id: lead.id,
            name: lead.name,
        },
    };
};
