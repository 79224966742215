import 'polyfill-array-includes';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import $ from 'jquery';
import 'jquery.scrollto';
import axios from 'axios';

const nativeRemoveChild = Node.prototype.removeChild as any;
Node.prototype.removeChild = function(oldChild: any) {
    try {
        return nativeRemoveChild.call(this, oldChild);
    } catch (e) {
        console.log(
            'bad node remove: ',
            this,
            oldChild,
            traceNode(this, oldChild),
        );
    }
};

function extraNodeText(node: any) {
    const tagName = (node.nodeName || '--').toLowerCase();
    const classNm = node.getAttribute
        ? node.getAttribute('class') || '--'
        : '--';
    const id = node.getAttribute ? node.getAttribute('id') || '--' : '--';
    return [tagName, classNm, id].join(',');
}

function traceNode(node: Node, oldChild: Node) {
    let text = '';
    const nodes: Node[] = [oldChild];
    while (true) {
        nodes.push(node);
        const next = node.parentNode;
        if (!next) {
            break;
        }
        node = next;
    }
    text = nodes.map(extraNodeText).join('\n');

    axios.post('/bff/api/rest/alert', {
        msg: 'bad node remove, node stack: \n' + text,
    });
    return text;
}
