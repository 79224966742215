import * as React from 'react';
import { useState, useContext, useCallback, useEffect } from 'react';
import _ from 'lodash';
import './index.scss';
import { IHSPGDataTypes } from '../index';
import { StoreContext } from '@/stores';
import {
    IMemberSettingData,
    widgetsMemberSetting,
    memberFormItemOptions,
    memberValidators,
    IMemberExtraData,
    makeMemberActionWidgets,
} from './options';
import {
    getAutoOnChangeHandlerOriginal,
    embedDataToWidgets,
    IFormLV2Props,
    FormLV2,
} from '@/components';
import { message, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { transformAllArrayToString } from '../../../../utils/helpers';

export interface IMemberSettingModalProps {
    visible: boolean;
    setVis: (bool: boolean) => void;
    selectedItems?: IHSPGDataTypes;
    reloadTable: () => void;
}
const MemberSettingModal: React.FC<IMemberSettingModalProps> = props => {
    const store = useContext(StoreContext);
    const SettingCHSPGStore = store.getDefaultSettingCHSPGStore();
    const settingStore = store.getSettingDefaultStore();
    const { customerInfo, departInfo } = settingStore; // 用户选择下拉框数据
    const {
        visible = false,
        setVis = () => {},
        selectedItems,
        reloadTable = () => {},
    } = props;
    const [modalData, setModalData] = useState<IMemberSettingData>({
        description: '',
        dataAdmin: [],
        dataDefaultAdmin: [],
        teamDeparts: [],
        teamUsers: [],
        isHighValue: '',
        autoDistributUsers: [],
    });
    const clearModalData = () => {
        setModalData({
            description: '',
            dataAdmin: [],
            dataDefaultAdmin: [],
            teamDeparts: [],
            teamUsers: [],
            isHighValue: '',
            autoDistributUsers: [],
        });
    };

    // 请求初始modal数据
    useEffect(() => {
        if (selectedItems?.id) {
            SettingCHSPGStore.fetchCHSPGPoolSetting(
                selectedItems?.id,
                'member',
                setModalData,
                departInfo,
            );
        }
    }, [selectedItems]);
    // 获取默认的数据回调
    const onDataChange = (
        key: keyof IMemberSettingData,
        value: any,
        widget: any,
    ) => {
        if (key === 'dataAdmin') {
            // 当分组管理员变化时，检验数据管理员的选择项
            const newDataDefaultAdmin: any[] = [];
            _.forEach(modalData.dataDefaultAdmin, item => {
                if (!!_.find(value, groupAItems => item === groupAItems)) {
                    newDataDefaultAdmin.push(item);
                }
            });
            const newModalData = {
                ...modalData,
            };
            newModalData[key] = value;
            if (
                modalData.dataDefaultAdmin &&
                newDataDefaultAdmin.length === modalData.dataDefaultAdmin.length
            ) {
                // 如果长度不变,则不更新数据管理员
            } else {
                newModalData.dataDefaultAdmin = newDataDefaultAdmin;
            }
            setModalData(newModalData);
        } else {
            getAutoOnChangeHandlerOriginal(
                modalData as IMemberSettingData,
                setModalData,
            )(key, value, widget);
        }
    };
    // 动态配置生成
    const finalWidgets = widgetsMemberSetting(
        customerInfo,
        departInfo,
        modalData,
        false,
    );
    // 灌数据
    embedDataToWidgets<IMemberSettingData>(finalWidgets, modalData);
    const isAllLoading =
        settingStore.isCustomerInfoLoading ||
        SettingCHSPGStore.memberSettingLoading;
    const MemberSetModalProps: IFormLV2Props<
        IMemberSettingData,
        IMemberExtraData
    > = {
        styleLv2: {
            // width: 500,
        },
        classNameLv2: 'chspg-setting-modal',
        data: modalData,
        formOptions: {
            layout: 'vertical',
        },
        catchGroupProps: {
            'member-team': {
                afterNum: 4,
                groupClassName: 'chspg-setting-member-team',
                groupTitle: <p>成员</p>,
            },
        },
        statusExtraData: {
            memberOptionsDataLoading: isAllLoading,
        },
        widgets: finalWidgets,
        actionWidgets: makeMemberActionWidgets(isAllLoading),
        onChange: onDataChange,
        formItemOptions: memberFormItemOptions,
        onInteract: (key, type, value) => {},
        onAction: async (actionType, data, isPass, validateResult) => {
            console.log(
                'actionType, data, isPass, validateResult: ',
                actionType,
                data,
                isPass,
                validateResult,
            );
            if (actionType === 'submit') {
                if (isPass) {
                    const newMemberData = transformAllArrayToString(modalData);
                    newMemberData.teamDeparts = _.map(
                        newMemberData?.teamDeparts || [],
                        item => {
                            return item.value;
                        },
                    ).join(',');
                    if (selectedItems) {
                        const [
                            d,
                            e,
                        ] = await SettingCHSPGStore.setCHSPGPoolSetting(
                            selectedItems.id,
                            newMemberData,
                        );
                        if (!d.msg) {
                            message.success('修改成功', 1);
                            reloadTable();
                            setVis(false);
                        } else {
                            message.error(d.msg || '修改失败', 1);
                        }
                    }
                    // toggleModalVisible()
                    // toggleDrawerVisible()
                } else {
                    message.error('数据不正确，不可提交');
                }
            }

            if (actionType === 'cancel') {
                setVis(false);
            }
        },
        validateMode: 'instantly',
        validators: memberValidators,
    };
    const propsWithModalContainer: IFormLV2Props<
        IMemberSettingData,
        IMemberExtraData
    > = {
        ...MemberSetModalProps,
        container: 'modal',
        containerModalProps: {
            visible,
            title: '分组人员设置',
            onCancel: () => {
                setVis(false);
                clearModalData();
            },
            width: 500,
            wrapClassName: 'chspg-modal-content',
            destroyOnClose: true,
            loading: settingStore.isCustomerInfoLoading,
        },
    };
    return <FormLV2<IMemberSettingData> {...propsWithModalContainer}></FormLV2>;
};

export default observer(MemberSettingModal);
