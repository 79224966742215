import React, { useMemo } from 'react';

import './index.less';

const numberMap: { [key: string]: string } = {
    0: 'https://i9.taou.com/maimai/p/32888/7607_6_21WYKXOpP5VH2s30',
    1: 'https://i9.taou.com/maimai/p/32888/7610_6_5QJ2PE8oMevAfc',
    2: 'https://i9.taou.com/maimai/p/32888/7608_6_32SkrAAzODMIrXha',
    3: 'https://i9.taou.com/maimai/p/32888/7611_6_61FdvpUSLQmaEyXU',
    4: 'https://i9.taou.com/maimai/p/32888/7613_6_82wvTqsbJR5Rtnpe',
    5: 'https://i9.taou.com/maimai/p/32888/7614_6_f4sva8T8dHKHH6',
    6: 'https://i9.taou.com/maimai/p/32888/7612_6_71BQcsGBKpem4BbC',
    7: 'https://i9.taou.com/maimai/p/32888/7617_6_3Q2nwAutPKNTYW',
    8: 'https://i9.taou.com/maimai/p/32888/7615_6_1Jbh8UWBSO5d9m',
    9: 'https://i9.taou.com/maimai/p/32888/7616_6_2b6APOI0QEWnzG',
};

const numberLabel: { [key: string]: string } = {
    4: '千',
    5: '万',
    6: '十万',
    7: '百万',
    8: '千万',
    9: '亿',
    10: '十亿',
};

interface BigNumberCard {
    number?: number | string;
}

interface NumberItem {
    value: string;
    label: string;
}

const BigNumberCard: React.FC<BigNumberCard> = ({ number = 0 }) => {
    const finalArray = useMemo(() => {
        const numberArray = Array.from(number.toString());
        if (numberArray.length < 9) {
            // 不满亿 则前插到亿
            let gapLength = 9 - numberArray.length;
            while (gapLength--) {
                numberArray.unshift('0');
            }
        }
        numberArray.reverse();
        return numberArray
            .reduce((prev, cur, i) => {
                if (i % 3 === 0 && i !== 0) {
                    // 每三位插入一位逗号
                    prev.push({
                        value: 'comma',
                        label: '',
                    });
                }
                prev.push({
                    value: cur,
                    label: numberLabel?.[i + 1] || '',
                });
                return prev;
            }, [] as NumberItem[])
            .reverse();
    }, [number]);

    return (
        <div className="big-number-card">
            <img
                className="big-number-card-title"
                src="https://i9.taou.com/maimai/p/32888/1577_6_32Apd0kuFSdlHKRk"
            />
            <div className="big-number-card-container">
                {finalArray.map((item, i) =>
                    item.value === 'comma' ? (
                        <div className="big-number-comma" key={i}>
                            <img src="https://i9.taou.com/maimai/p/32889/1578_6_4Qv2UEsoOeZAEc" />
                        </div>
                    ) : (
                        <div className="big-number-item" key={i}>
                            {item.label ? (
                                <span className="big-number-item-label">
                                    {item.label}
                                </span>
                            ) : (
                                <span className="big-number-item-label-empty"></span>
                            )}
                            <img src={numberMap[item.value]} />
                        </div>
                    ),
                )}
            </div>
        </div>
    );
};

export default React.memo(BigNumberCard);
