import _ from 'lodash';
import { genDaysAgoFilterFactory } from './helpers';
import { AuthStore } from '@/stores/auth';
import moment from 'moment';

export const contractDFConfs: { [entityCode: string]: any } = {
    contract: {
        lv4MenuFilters: {
            all: [],
            mine: [],
        },
        fastFilters: {
            all: [
                {
                    label: '开通未回款',
                    fastFilterKey: 'kaitongweihuikuan',
                    value: [
                        {
                            condition: {
                                fieldId: 'wait_pay_back_amount',
                                filterValue: '0',
                                operateType: '>',
                                parser: 'string',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'first_open_time',
                                filterValue: '',
                                operateType: 'not_null',
                                parser: 'string',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'start_time',
                                filterValue: '1623686400000',
                                operateType: '>',
                                parser: 'string',
                            },
                        },
                    ],
                },
                {
                    label: '回款未开通',
                    fastFilterKey: 'huikuanweikaitong',
                    value: [
                        {
                            condition: {
                                fieldId: 'whether_open_all_product',
                                filterValue: '0',
                                operateType: '=',
                                parser: 'string',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'first_collect_time',
                                filterValue: '',
                                operateType: 'not_null',
                                parser: 'string',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'start_time',
                                filterValue: '1623686400000',
                                operateType: '>',
                                parser: 'string',
                            },
                        },
                    ],
                },
                {
                    label: '已盖章未归档',
                    fastFilterKey: 'yigaizhangweiguidang',
                    value: [
                        {
                            condition: {
                                fieldId: 'is_need_seal',
                                filterValue: '1',
                                operateType: '=',
                                parser: 'string',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'process_approval_activityid',
                                filterValue: 'bps_operation_archive',
                                operateType: '=',
                                parser: 'string',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'process_approval_status',
                                filterValue: 'APPROVE',
                                operateType: '=',
                                parser: 'string',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'start_time',
                                filterValue: '1623686400000',
                                operateType: '>',
                                parser: 'string',
                            },
                        },
                    ],
                },
                {
                    label: '已开票未回款',
                    fastFilterKey: 'yikaipiaoweihuikuan',
                    value: [
                        {
                            condition: {
                                fieldId: 'income_sub_receipt',
                                filterValue: '0',
                                operateType: '<',
                                parser: 'string',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'start_time',
                                filterValue: '1623686400000',
                                operateType: '>',
                                parser: 'string',
                            },
                        },
                    ],
                },
            ],
            mine: [],
        },
    },
};
