import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { Modal } from 'antd';
import { useRequest } from 'ahooks';
import _ from 'lodash';

import { LayoutStandardCrud, AntButton, TableLv2 } from '@/components';
import { DTitle2 } from '@/components/common/dtitle2';
import { fetchSaleTageList, SaleTagInfo, saveSaleTag } from './api';
import './index.scss';
import SaleTagDrawerSub from './components/sale-tag-drawer-sub';

const SaleTagList: React.FC<RouteComponentProps> = () => {
    // 标签抽屉（新建和修改）
    const [visibleDrawerSub, setVisibleDrawerSub] = useState(false);
    const [saleTagList, setSaleTagList] = useState<any>([]);
    const [initialValues, setInitialValues] = useState<any>(null);
    const [saleTagItem, setSaleTagItem] = useState<any>(null);
    const { data: saleTagItemDetail, loading, refresh } = useRequest(
        () => {
            return fetchSaleTageList({ filedId: saleTagItem?.filedId || 2328 });
        },
        {
            refreshDeps: [saleTagItem?.filedId],
        },
    );

    useEffect(() => {
        setSaleTagList([
            { label: '商机产品', value: 1, filedId: 2328 },
            { label: '合同产品', value: 2, filedId: 2327 },
        ]);
        setSaleTagItem({ label: '商机产品', value: 1, filedId: 2328 });
    }, []);

    useEffect(() => {
        if (!visibleDrawerSub) {
            setInitialValues(null);
        }
    }, [visibleDrawerSub]);

    const handleUpdate = (values: any, type: string) => {
        let updataItemDetail = saleTagItemDetail
            ? saleTagItemDetail['extendValue']
            : [];
        if (type === 'update') {
            updataItemDetail = updataItemDetail.map(item => {
                if (item.value === values.value) {
                    return { ...item, ...values };
                }
                return item;
            });
        } else {
            values.value = updataItemDetail.length + 1;
            updataItemDetail.push(values);
        }
        saveSaleTag({
            id: saleTagItemDetail?.id || 258,
            extendValue: JSON.stringify(updataItemDetail),
        }).then(() => {
            setVisibleDrawerSub(false);
            refresh();
        });
    };

    const popups: any = (
        <div>
            <SaleTagDrawerSub
                visible={visibleDrawerSub}
                initialValues={initialValues}
                onClose={() => {
                    setVisibleDrawerSub(false);
                }}
                onSubmit={(values: any, type: string) => {
                    handleUpdate(values, type);
                }}
            />
        </div>
    );

    const globalOps = (
        <>
            <AntButton
                type="primary"
                size="large"
                onClick={() => {
                    setVisibleDrawerSub(true);
                }}
            >
                新建标签
            </AntButton>
        </>
    );

    const dataSource = _.map(
        saleTagItemDetail?.extendValue || [],
        (item: any) => {
            return {
                id: item.value,
                name: item.label,
                ...item,
            };
        },
    );

    const dtitleProps: any = {
        title: 'saleTag',
        list: saleTagList,
        selected: [saleTagItem],
    };

    dtitleProps.onListItemClick = (item: any) => {
        setSaleTagItem(item);
    };

    const title = <DTitle2 {...dtitleProps} />;

    const handleAction = (actionKey: string, record: any, operator: string) => {
        return Modal.confirm({
            title: `是否要${actionKey}【${record.label}】标签`,
            onOk: () => {
                const extendValues = {
                    choice: !record?.choice,
                    value: record?.value,
                    selected: record?.selected,
                    label: record.label,
                };
                handleUpdate(extendValues, operator);
                Modal.success({
                    content: `${actionKey}【${record.label}】标签成功`,
                });
            },
        });
    };

    const dataTable = (
        <div style={{ display: 'flex', height: '100%' }}>
            {title}
            <TableLv2<SaleTagInfo>
                className="table-v-stretch"
                dataSource={dataSource}
                loading={loading}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        width: 200,
                    },
                ]}
                actions={[
                    {
                        actionKey: 'update',
                        actionCn: '修改',
                    },
                    {
                        actionKey: 'start',
                        actionCn: '启用',
                        isVisible: (record) => !record.choice,
                    },
                    {
                        actionKey: 'delete',
                        actionCn: '禁用',
                        isVisible: (record) => record.choice,
                    },
                ]}
                onAction={(actionKey: string, record: any) => {
                    if (actionKey === 'update') {
                        setVisibleDrawerSub(true);
                        setInitialValues(record);
                    }
                    if (actionKey === 'start') {
                        handleAction('启用', record, 'update');
                    }
                    if (actionKey === 'delete') {
                        handleAction('禁用', record, 'update');
                    }
                }}
                actionsWidth={200}
                childrenColumnName="items"
                closeRowSelection={true}
                isFixedActions={false}
            />
        </div>
    );

    const layoutComs = {
        popups,
        title: '售卖标签分类',
        globalOps,
        filters: null,
        multipleOps: null,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay: null,
    };

    return <LayoutStandardCrud className="page-process-list" {...layoutComs} />;
};

export default observer(SaleTagList);
