import { CCStore } from './store';
import _ from 'lodash';
import { message } from 'antd';
import EE from 'eventemitter3';
import { toJS } from 'mobx';
import axios from 'axios';
import baiChuanCC from './baichuan';

moorCall.moortools.error = (msg: string) => message.error(msg);

export const isLibOk = (store: CCStore) => {
    return true;
};

export const init = (store: CCStore, callback: (success: boolean) => void) => {
    if (store.supplier === 'clink') {
        ClinkAgent.setup(
            {
                debug: true,
                sipPhone: true,
            },
            (token: any) => {
                // 如何验证失败？？
                callback(true);
                _.isFunction(SIP?.WebRTC?.isSupported) &&
                    SIP.WebRTC.isSupported();
                startClinkListeners();
            },
        );
    }
    if (store.supplier === '7moor') {
        // 初始化一个ee，专门接7moor的数据
        window.___7moorEE = new EE();
        const timer = setInterval(() => {
            if (typeof Janus === 'function') {
            }
            callback(true);
            clearInterval(timer);
        }, 500);
    }
    if (store.supplier === 'baichuan') {
        if (!store.context.agent || !store.context.token) {
            message.error('百川智通，配置信息不全');
            callback(false);
            return;
        }
        baiChuanCC.init(store.context);
        callback(true);
    }
};

const clinkEventEE = new EE();
const startClinkListeners = () => {
    ClinkAgent.registerCallback(ClinkAgent.ResponseType.LOGIN, (ret: any) => {
        clinkEventEE.emit('LOGIN', ret);
    });
    ClinkAgent.registerCallback(ClinkAgent.ResponseType.LOGOUT, (ret: any) => {
        clinkEventEE.emit('LOGOUT', ret);
    });

    ClinkAgent.registerCallback(
        ClinkAgent.ResponseType.PREVIEW_OUTCALL,
        (ret: any) => {
            clinkEventEE.emit('PREVIEW_OUTCALL', ret);
        },
    );
    ClinkAgent.registerCallback(
        ClinkAgent.ResponseType.PREVIEW_OUTCALL_CANCEL,
        (ret: any) => {
            clinkEventEE.emit('PREVIEW_OUTCALL_CANCEL', ret);
        },
    );

    // 外呼
    ClinkAgent.registerListener(
        ClinkAgent.EventType.PREVIEW_OUTCALL_START,
        (ret: any) => {
            clinkEventEE.emit('PREVIEW_OUTCALL_START', ret);
        },
    ); // 预览外呼呼叫客户

    ClinkAgent.registerListener(
        ClinkAgent.EventType.PREVIEW_OUTCALL_RINGING,
        (ret: any) => {
            clinkEventEE.emit('PREVIEW_OUTCALL_RINGING', ret);
        },
    ); // 预览外呼客户响铃

    ClinkAgent.registerListener(
        ClinkAgent.EventType.PREVIEW_OUTCALL_BRIDGE,
        (ret: any) => {
            clinkEventEE.emit('PREVIEW_OUTCALL_BRIDGE', ret);
        },
    ); // 预览外呼客户接听

    ClinkAgent.registerListener(ClinkAgent.EventType.BREAK_LINE, (ret: any) => {
        clinkEventEE.emit('BREAK_LINE', ret);
    });

    ClinkAgent.registerListener(
        ClinkAgent.EventType.UNPAUSE_CLIENT,
        (ret: any) => {
            clinkEventEE.emit('UNPAUSE_CLIENT', ret);
        },
    );

    ClinkAgent.registerListener(ClinkAgent.EventType.UNLINK, (ret: any) => {
        clinkEventEE.emit('UNLINK', ret);
    });

    ClinkAgent.registerListener(ClinkAgent.EventType.SIP_UNLINK, (ret: any) => {
        clinkEventEE.emit('SIP_UNLINK', ret);
    });

    ClinkAgent.registerListener(ClinkAgent.EventType.KICKOUT, (ret: any) => {
        clinkEventEE.emit('KICKOUT', ret);
    });

    // kickout
};

// 断线支持
// EVENT_TYPE.BREAK_LINE = "breakLine";

const getDefaultClinkCallback = (resolve: (...args: any[]) => void) => {
    return (ret: any) => {
        ret = ret || {};
        const { code, msg } = ret;
        if (code === 0) {
            resolve(true);
            return;
        }
        if (code === -1) {
            resolve(false);
            message.error(msg || '未知错误');
            return;
        }
        resolve(false);
        message.error('clink api 错误');
    };
};

export const login = async (store: CCStore) => {
    if (store.supplier === 'clink') {
        const isSuccess = await new Promise(resolve => {
            clinkEventEE.once('LOGIN', getDefaultClinkCallback(resolve));
            ClinkAgent.login(toJS(store.context));
        });
        return isSuccess;
    }
    if (store.supplier === '7moor') {
        const { loginName, password, loginType, agentStatus } = store.context;
        if (
            _.some([loginName, password, loginType, agentStatus], v => {
                return !_.isString(v);
            })
        ) {
            return false;
        }
        const isSuccess = await new Promise(resolve => {
            const startTime = Date.now();
            moorCall.moortools.m7BeginLogon(
                loginName,
                password,
                loginType,
                agentStatus,
                (isSuccess: boolean, data: any) => {
                    resolve(isSuccess);
                    if (isSuccess === false && !!data) {
                        try {
                            axios.post('/bff/api/rest/alert', {
                                msg:
                                    '7moor phone call err: \n' +
                                    JSON.stringify({
                                        ...data,
                                        context: store.context,
                                        username: store.username,
                                        step: 'login',
                                        duration: Date.now() - startTime,
                                    }),
                            });
                        } catch (e) {}
                    }
                },
            );
        });
        let isSipSuccess = true;
        if (loginType === 'sip') {
            const pwd = (md5 as any)(password);
            const loginData = {
                username: loginName,
                pwd,
            };
            isSipSuccess = await new Promise(resolve => {
                window.webPhone = initM7WebPhone();
                window.webPhone.init(loginData, (isSuccess: boolean) => {
                    resolve(isSuccess);
                });
            });
        }

        // stDialing
        // 如果7moor登录成功，则一定会有这两个
        if (window.phone === null || window.softphoneBar === null) {
            return false;
        }

        if (window.phone?.registeredEvent) {
            console.log(
                'window.phone?.registeredEvent',
                window.phone?.registeredEvent,
            );
            return isSuccess;
        }

        if (isSuccess === true && isSipSuccess === true) {
            window.___7moorEE.on('notify', () => {
                store.afterClose(true);
            });
            window.___7moorEE.on('dialog-suc', async (data: any) => {
                store.onCallStart(data.ActionID);
            });
            window.___7moorEE.on('hangup', () => {
                console.log('hangup');
                store.afterClose(true);
            });
            // TODO: 启动事件监听
            window.phone.register(
                'EvtPeerToolBarChange',
                {
                    _: (peerState: any) => {
                        if (
                            window.phone === null ||
                            window.softphoneBar === null
                        ) {
                            return;
                        }
                    },
                },
                '_',
            );
            window.phone.register(
                'EvtCallToolBarChange',
                {
                    _: (state: any) => {
                        if (
                            window.phone === null ||
                            window.softphoneBar === null
                        ) {
                            return;
                        }
                        if (state === 'stDialing') {
                            store.handles && store.handles.onRinging({});
                            return;
                        }
                        if (state === 'stDialTalking') {
                            store.handles && store.handles.onBridge({});
                            return;
                        }

                        // 被动close，也就是重新变成空闲
                        if (
                            state === 'stInvalid' &&
                            store.callingStatus !== 0
                        ) {
                            // 不建议使用这个状态去驱动状态流转
                            // 在官方电话条中，仅仅用作状态展示
                            // store.afterClose(true);
                            return;
                        }
                        // 7moorevent:  state stDialing      拨打中
                        // 7moorevent:  state stDialTalking  接电话
                        // 7moorevent:  state stInvalid      重新变成空闲

                        if (state === 'stInvalid') {
                            return;
                        }

                        try {
                            axios.post('/bff/api/rest/alert', {
                                msg:
                                    '7moor phone call err: \n' +
                                    JSON.stringify({
                                        state,
                                        context: store.context,
                                        username: store.username,
                                        step: 'event',
                                    }),
                            });
                        } catch (e) {}
                    },
                },
                '_',
            );
            if (window.phone) {
                window.phone.registeredEvent = true;
            }
        }
        return isSuccess;
    }
    if (store.supplier === 'baichuan') {
        const isSuccess = await baiChuanCC.connect();
        return isSuccess;
    }
    return false;
};

export const logout = async (store: CCStore) => {
    return new Promise<boolean>(resolve => {
        const startTime = Date.now();
        moorCall.moortools.m7logout((isSuccess: boolean, data: any) => {
            resolve(isSuccess);
            if (isSuccess === false && !!data) {
                try {
                    axios.post('/bff/api/rest/alert', {
                        msg:
                            '7moor phone call err: \n' +
                            JSON.stringify({
                                ...data,
                                context: store.context,
                                username: store.username,
                                step: 'logout',
                                duration: Date.now() - startTime,
                            }),
                    });
                } catch (e) {}
            }
        });
    });
};

// 开始打电话，弹出打电话的条
export const call = async (store: CCStore) => {
    const tel = store.currentCallPhoneNum;
    if (!(tel + '')) {
        message.error('tel required');
        return false;
    }
    console.log('call222', store, tel);

    if (store.supplier === 'clink') {
        const isSuccess = await new Promise(resolve => {
            clinkEventEE.once(
                'PREVIEW_OUTCALL',
                getDefaultClinkCallback(resolve),
            );
            ClinkAgent.previewOutcall({ tel: tel + '' });
        });
        return isSuccess;
    }
    if (store.supplier === '7moor') {
        // 如果7moor登录成功，则一定会有这两个
        if (window.phone === null || window.softphoneBar === null) {
            return false;
        }

        const isSuccess = await new Promise(resolve => {
            const startTime = Date.now();
            softphoneBar.dialout(tel, '', (isSuccess: boolean, data: any) => {
                console.log('hnbvghyjnbgh', isSuccess, data);
                store.setCallErrorMessage(data?.response?.Message || '');
                resolve(isSuccess);
                if (isSuccess === false && !!data) {
                    try {
                        axios.post('/bff/api/rest/alert', {
                            msg:
                                '7moor phone call err: \n' +
                                JSON.stringify({
                                    ...data,
                                    context: store.context,
                                    username: store.username,
                                    step: 'call',
                                    duration: Date.now() - startTime,
                                }),
                        });
                    } catch (e) {}
                }
            });
        });

        return isSuccess;
    }
    if (store.supplier === 'baichuan') {
        const ctx: any = await baiChuanCC.call(tel!);
        console.log('baichuan_Call', ctx);
        store.onCallStart(ctx.calluuid);
        return !!ctx;
    }
};

// 收起打电话的条
export const closeCall = async (store: CCStore) => {
    console.log('closeCall', store.supplier);
    if (store.supplier === 'clink') {
        const isSuccess = await new Promise(resolve => {
            clinkEventEE.once(
                'PREVIEW_OUTCALL_CANCEL',
                getDefaultClinkCallback(resolve),
            );
            ClinkAgent.previewOutcallCancel();
        });
        return isSuccess;
    }
    if (store.supplier === '7moor') {
        // 如果7moor登录成功，则一定会有这两个
        if (window.phone === null || window.softphoneBar === null) {
            return false;
        }

        const isSuccess = await new Promise(resolve => {
            phone.hangup((isSuccess: boolean) => {
                resolve(isSuccess);
            });
        });

        return isSuccess;
    }
    if (store.supplier === 'baichuan') {
        const isSuccess = await baiChuanCC.hangup();
        return isSuccess;
    }
};

export const listenCallStatusChange = async (
    store: CCStore,
    handlers: {
        onStart: (ret: any) => void;
        onRinging: (ret: any) => void;
        onBridge: (ret: any) => void;
        onBreak: (ret: any) => void;
    },
) => {
    if (store.supplier === 'clink') {
        clinkEventEE.on('PREVIEW_OUTCALL_START', ret => {
            handlers.onStart(ret);
        });
        clinkEventEE.on('PREVIEW_OUTCALL_RINGING', ret => {
            handlers.onRinging(ret);
        });
        clinkEventEE.on('PREVIEW_OUTCALL_BRIDGE', ret => {
            handlers.onBridge(ret);
        });
        clinkEventEE.on('BREAK_LINE', ret => {
            handlers.onBreak(ret);
        });
        clinkEventEE.on('KICKOUT', ret => {
            message.warning(
                '您的账户从其它地点登录，请退出呼叫中心或者重新登录，如不是您本人操作请及时修改密码或联系管理员！',
            );
            handlers.onBreak(ret);
        });
    }
    if (store.supplier === '7moor') {
        store.handles = handlers;
    }
    if (store.supplier === 'baichuan') {
        baiChuanCC.on('ringing', () => {
            handlers.onRinging({});
        });
        baiChuanCC.on('pickUp', () => {
            handlers.onBridge({});
        });
        baiChuanCC.on('hangup', () => {
            handlers.onBreak({});
        });
    }
};
