import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    AntButton,
    loadingWrapperOver,
} from '@/components';
import { Icon, Tooltip, message } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, delay, useBeforeFirstDataLoaded } from '@/utils';
import { observer } from 'mobx-react-lite';
import DemoTable from './components/table';
import { ActionDelete } from './components/delete';
import { ActionMDelete } from './components/m-delete';
import { runInAction } from 'mobx';
import { DTitle2 } from '@/components/common/dtitle2';
import {
    defaultAuthDeco,
    dFilterHOC,
    useDefaultDFilterSnippets,
} from '@/containers/hoc';
import './index.scss';
import { MOpResultDisplayContainer } from '@/components';
import FormDrawerCreate from './components/form-drawer-create';
import FormDrawerDetail from './components/form-drawer-detail';
import classname from 'classname';
import { DTitle } from '../../custom/list/components/dtitle';
import { useFirstLv4Menu } from '@/stores/utils/fast-filters';
import { ActionExport } from '../../activation-record/list/components/export';

const DemoList: React.FC<RouteComponentProps & {
    type?: string;
    processKey: string;
    dfilters?: any;
    title?: React.ReactChild;
    metaGetter?: any;
}> = ({
    type = 'category',
    dfilters,
    processKey,
    title = null,
    metaGetter = null,
}) => {
    const store = useContext(StoreContext);
    const [defaultStore] = useState(() => new store.ProcessCatStore());
    const userStore = store.getAuthStore();

    const ack = dtext('crm')('process-category-message-' + type, '');

    const {
        tableCode, // 动态列表
        defaultPagination,
        defaultSorter,
        defaultMeta,
        pageTitle,
        defaultPerm,
        defaultFilter,
        defaultMSelect,
        defaultFastFilters,
        condListbyFilterData,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const [preRequiredReady, setPreRequiredReady] = useState(false);

    useEffect(() => {
        runInAction(() => {
            defaultFastFilters.setType(type);
            defaultStore.setListType(type);
        });
    }, [type]);

    const { selectedLv4MenuFilters, lv4MenuFilters } = defaultFastFilters;

    // 设置四级菜单
    useEffect(() => {
        defaultFastFilters.setFilters(dfilters);
    }, []);

    useEffect(() => {
        // 如果没有type或者已经选择了，则无视
        if (
            !defaultFastFilters.type ||
            defaultFastFilters.selectedLv4MenuFilters.length > 0
        ) {
            return;
        }
        const { lv4MenuFilters } = defaultFastFilters;
        if (lv4MenuFilters.length === 0) {
            return;
        }

        const selectedfilter = lv4MenuFilters.find(
            (filter: any) => filter?.originalItem?.key === processKey,
        );
        if (selectedfilter) {
            // 选择一个外部指示的四级菜单
            defaultFastFilters.setSelectedLv4MenuFilters([selectedfilter]);
        } else {
            throw new Error('bad processKey');
        }
    }, [
        defaultFastFilters.type,
        selectedLv4MenuFilters,
        lv4MenuFilters,
        processKey,
    ]);

    // 根据四级菜单的选择，动态更改store的元信息，权限
    const selectedTableCode = selectedLv4MenuFilters[0]?.key;
    useEffect(() => {
        if (selectedTableCode === undefined) {
            return;
        }
        // 当tableCode更新，意味着内部各种东西已经更新
        defaultStore.setTableCodeAny(selectedTableCode, metaGetter);
    }, [selectedTableCode]);

    // 更新刷新方法
    const listFetch = useCallback(() => defaultStore.fetch(), [tableCode]);
    const [error, loading, reload] = useNet(listFetch, {
        autoLoad: false,
        refreshKeys: [tableCode || '', 'raw-process'],
    });

    const [isBeforeFirstLoaded] = useBeforeFirstDataLoaded(defaultStore);

    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        // 再一次ready，再查询
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (!userStore.userInfo) {
            return;
        }
        defaultStore.setSysUser(userStore.userInfo.sysUser);
    }, [userStore.userInfo]);

    // 初始化新的meta和perm和filter
    useEffect(() => {
        if (
            userStore.userInfo &&
            tableCode !== null &&
            defaultMeta !== null &&
            defaultPerm !== null
        ) {
            defaultMeta.setTableId(tableCode);
            defaultMeta.setOpUsername(
                userStore.userInfo.userId + '-any-' + tableCode,
            );
            defaultStore.setSysUser(userStore.userInfo.sysUser);

            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(async () => {
                    setPreRequiredReady(true);
                    defaultFilter.resetFilterData();
                    defaultFilter.confirmFilterData();
                })
                .catch(() => {});
        }
    }, [userStore.userInfo, tableCode]);

    const noReady = !preRequiredReady;
    const finalLoading = isBeforeFirstLoaded || noReady;

    const filtersDisplay = defaultMeta ? (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    ) : null;

    const globalOps = (
        <>
            <AntButton
                onClick={() => {
                    defaultStore.setAction('export');
                }}
                size="large"
            >
                导出
            </AntButton>
        </>
    );

    const popups = (
        <>
            <MOpResultDisplayContainer defaultStore={defaultStore} />
            <ActionDelete defaultStore={defaultStore} />
            <ActionMDelete defaultStore={defaultStore} />
            <ActionExport defaultStore={defaultStore} />
            <FormDrawerCreate
                key={defaultStore.detailTableCode || 'None'}
                defaultStore={defaultStore}
                preRequiredReady={!noReady}
            />
            <FormDrawerDetail
                key={defaultStore.detailTableCode || 'None'}
                defaultStore={defaultStore}
                preRequiredReady={!noReady}
            />
        </>
    );

    const multipleOps = (
        <div
            className={classname({
                'mselected-ops': true,
                'multiple-mode': defaultMSelect.isMSelectionMode,
                'normal-mode': !defaultMSelect.isMSelectionMode,
            })}
        >
            <Tooltip title="取消多选">
                <span
                    onClick={() => {
                        defaultMSelect.setMSelectedData([]);
                    }}
                    style={{
                        padding: 5,
                        cursor: 'pointer',
                    }}
                >
                    <Icon type="close" />
                </span>
            </Tooltip>
            <div>
                <span>已选{defaultMSelect.mSeletedData.length}条：</span>
            </div>
            {defaultPerm !== null &&
                defaultPerm.getPermByTypeAndAction(type)('delete').visible && (
                    <AntButton
                        onClick={() => {
                            defaultStore.setAction('m-delete');
                        }}
                        size="large"
                    >
                        删除
                    </AntButton>
                )}
        </div>
    );

    const dataTable = noReady ? null : (
        <DemoTable
            key={'table-' + selectedTableCode}
            tableCode={selectedTableCode}
            defaultStore={defaultStore}
            loading={loading}
        />
    );

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters: null,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay,
    };

    return loadingWrapperOver(finalLoading)(
        <LayoutStandardCrud className="page-process-list" {...layoutComs} />,
    );
};

const FinalDemoList = dFilterHOC(observer(DemoList), 'process');
export default FinalDemoList;
