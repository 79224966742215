import React, {
    CSSProperties,
    PropsWithChildren,
    Children,
    ReactNode,
    useState,
    useEffect,
} from 'react';
import {
    Widgets,
    WidgetsKeys,
    IWidgetsOptions,
    IWidgetsValueTypes,
    ICommonStatus,
    getCommonStatus,
    InnerInteractCallback,
    ICommonProps,
} from '@/components/common/form/widgets';
import _ from 'lodash';
import { Form, Icon, Tooltip } from 'antd';
import { FormItemProps, FormProps } from 'antd/lib/form';
import { arrToJson } from '@/utils';
import classname from 'classname';
import './index.scss';

import {
    SGrid,
    TSGridLayoutProp,
    TRowGultter,
} from '@/components/layouts/grid';
import { runInAction, toJS } from 'mobx';
import {
    AntCollapse2,
    IAntCollapseProps2,
    IAntCollapseConfig,
} from '@/components/antd';
import { ICell, TCellsSelector, TCell, ICellMultiple } from '../table';
import { WidgetEditable } from './widgets-editable';
import { loadingWrapper } from '../block-loading';
import { TCollpaseLayout, AutoCollapse } from '../form';
import { isMultipleCell } from '@/components/common/table';

const defaultFormItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export interface ICatchGroupKeysItems {
    [key: string]: {
        groupClassName?: string;
        afterNum?: number; // 将会添加到第几个form-items后面
        visible?: boolean;
        groupTitle?: string | any; // 头
    };
}
export interface IItemOptionItem<O, K extends keyof O = keyof O>
    extends FormItemProps {
    key: K;
    className?: string;
    style?: CSSProperties;
}
export interface IWidgetItem<
    O,
    E = any,
    K extends keyof O = keyof O,
    T extends WidgetsKeys = WidgetsKeys
>
    extends IWidgetsOptions,
        IWidgetsValueTypes,
        Pick<
            ICommonProps<O, E>,
            | 'nextlineWidget'
            | 'prevlineWidget'
            | 'beforeLabel'
            | 'afterLabel'
            | 'beforeWidget'
            | 'afterWidget'
            | 'widgetClassName'
        > {
    key: K;
    widgetKey: T;
    status?: ICommonStatus<O, E>;
    onInteract?: InnerInteractCallback;
    collapseKey?: string; // 折叠key
    wrapperKey?: string; // 分组key
    formItemClassName?: string; // 外层items容器上添加的classname
}

export type CommonFormOnInteract<T> = (
    key: keyof T,
    type: string,
    value?: any,
) => void;

export type CommonFormOnChange<
    O,
    K extends keyof O = keyof O,
    KT = O[K],
    T extends WidgetsKeys = WidgetsKeys
> = (key: K, value: KT, widgetKey: T) => void;

type CommonLayout = 'default' | 'inline' | number[][];
type FFormProps = { className?: string; style?: CSSProperties } & FormProps;

export interface IEditableDisplayProps<T, E = any> {
    style?: CSSProperties;
    className?: string;
    // 数据
    data: Nilable<T>;
    // 额外数据
    statusExtraData?: E;
    // 插件配置
    widgets: Array<IWidgetItem<T, E>>;
    // 数据变化回调函数
    onChange: CommonFormOnChange<T>;
    // 组件交互回调函数
    onInteract?: CommonFormOnInteract<T>;
    // antd Form.Item的设置
    formItemOptions?: Array<IItemOptionItem<T>>;
    // antd Form的设置
    formOptions?: FFormProps;
    // label部分宽度
    labelWidth?: 'auto' | number;
    // 数据验证结果
    validateResult?: Nilable<ValidateResult<T>>;
    // 布局
    layout?: 'inline' | TSGridLayoutProp;
    rowGutter?: TRowGultter;

    confirmLoading?: boolean;

    // tableCells
    cellsSelector: TCellsSelector<T>;
    onAction?: (actionKey: string, datum: T) => void;
    editingMap?: { [key: string]: boolean };
    isMultipleEditing?: boolean;

    collapseLayout?: TCollpaseLayout;
    collapseProps?: IAntCollapseProps2;

    // 如果存在，仅白名单内
    editableMap?: { [key: string]: boolean };
    invisibleMap?: { [key: string]: boolean };

    emptyText?: string;

    iconVisibleWhenHover?: boolean;
    hiddenWhenNoBoys?: boolean;

    cellNextLineItems?: { [key: string]: any };
}

// 将数据嵌入插件配置
export const embedDataToWidgets: <T>(
    widgets: Array<IWidgetItem<T>>,
    data: T | undefined | null,
) => void = (widgets, data) => {
    if (_.isNil(data)) {
        return;
    }
    for (const widgetItem of widgets) {
        const dataKey = widgetItem.key;

        // 自动将数据值填入到对应的插件值中
        // 外部有类型限制，内部肮脏点无所谓了
        const wKey = widgetItem.widgetKey;
        (widgetItem as any)[wKey + 'Value'] = (data as any)[dataKey] as any;
    }
};

// 偷偷修改某个key对应的widget
export const applyCustomConfToWidget: <T = any>(
    widgets: Array<IWidgetItem<T>>,
    key: string,
    genNextWidget: (prevWidget: IWidgetItem<T>) => IWidgetItem<T>,
) => void = (widgets, key, genNextWidget) => {
    let index = 0;
    for (const widget of widgets) {
        if (widget.key === key) {
            break;
        }
        index++;
    }
    if (index === widgets.length) {
        return;
    }
    widgets[index] = genNextWidget(widgets[index]);
};

export const getDefaultCellSelector = (key: string): ICell<any> => {
    return {
        colKey: key,
        cell: 'Echo',
        inputs: [key],
    };
};

export const applyCustomConfToDefaultCellSelector: <T = any>(
    cs: TCellsSelector<T>,
    key: string,
) => void = (cs, key) => {
    let index = 0;
    for (const c of cs) {
        if (c.colKey === key) {
            break;
        }
        index++;
    }
    if (index === cs.length) {
        // 默认cellSelector
        const newCellSelector: ICell<any> = getDefaultCellSelector(key);
        cs.push(newCellSelector);
        return;
    }
    cs[index] = getDefaultCellSelector(key);
};

// 偷偷修改某个key对应的cellsSelector
export const applyCustomConfToCellsSelector: <T = any>(
    cs: TCellsSelector<T>,
    key: string,
    genNextCellSelector: (prevWidget: TCell<T>) => TCell<T>,
) => void = (cs, key, genNextCellSelector) => {
    let index = 0;
    for (const c of cs) {
        if (c.colKey === key) {
            break;
        }
        index++;
    }
    if (index === cs.length) {
        // 默认cellSelector
        const newCellSelector: ICell<any> = getDefaultCellSelector(key);
        cs.push(genNextCellSelector(newCellSelector));
        return;
    }
    cs[index] = genNextCellSelector(cs[index]);
};

export const getAutoOnChangeHandler: <T>(
    data: T,
    actualHandler: (d: T) => void,
) => CommonFormOnChange<T> = (data, actualHandler) => {
    return (key, value, widget) => {
        const nextData = _.cloneDeep(data);
        nextData[key] = value;
        actualHandler(nextData);
    };
};

export const EditableDisplay: <T, E = any>(
    props: PropsWithChildren<IEditableDisplayProps<T, E>>,
) => React.ReactElement | null = ({
    data,
    widgets,
    onChange,
    onInteract,
    formItemOptions,
    statusExtraData,
    formOptions,
    validateResult,
    layout,
    style,
    className,
    labelWidth = 'auto',
    rowGutter,
    confirmLoading = false,
    cellsSelector: inputcellsSelector,
    editingMap: inputeditingMap,
    onAction,
    isMultipleEditing,
    collapseLayout,
    collapseProps,
    editableMap,
    emptyText,
    iconVisibleWhenHover,
    invisibleMap,
    hiddenWhenNoBoys,
    cellNextLineItems,
}) => {
    if (_.isNil(data)) {
        return null;
    }

    // cells
    const cellsSelector = inputcellsSelector || [];
    const cellsMap = new Map<string, ICell<any> | ICellMultiple<any>>();
    for (const c of cellsSelector) {
        cellsMap.set(c.colKey as string, c);
    }
    const editingMap: { [key: string]: boolean } = inputeditingMap || {};
    collapseLayout =
        _.isNil(collapseLayout) || collapseLayout.length === 0
            ? undefined
            : collapseLayout;

    const useSGrid = layout !== undefined && layout !== 'inline';
    const formItemPropsMap = arrToJson(formItemOptions || [], 'key');
    const formProps = formOptions || {};
    if (layout === 'inline') {
        formProps.layout = 'inline';
    }

    // key => collpaseKey
    const key2CollpaseKeyMap: { [key: string]: string } = {};
    for (const item of widgets) {
        const { collapseKey, key } = item;
        if (collapseKey) {
            key2CollpaseKeyMap[key as string] = collapseKey;
        }
    }

    return (
        <div
            style={style}
            className={
                classname({
                    'component-widgets-editalbe': true,
                }) +
                ' ' +
                (className || '')
            }
        >
            {(() => {
                const children: ReactNode[] = _.map(widgets, widgetItem => {
                    const wKey = widgetItem.widgetKey;
                    const WCom = Widgets[wKey];
                    const { key, widgetKey, status } = widgetItem;

                    const options =
                        (widgetItem as any)[widgetKey + 'Options'] || {};

                    const value = (widgetItem as any)[widgetKey + 'Value'];
                    const { hidden } = getCommonStatus(
                        status,
                        data,
                        statusExtraData,
                    );
                    if (hidden) {
                        return null;
                    }

                    if (invisibleMap && invisibleMap[key as string]) {
                        return null;
                    }

                    const finalOnChange = (v: any) => {
                        onChange(key, v, widgetKey);
                    };
                    const finalOnInteract = (type: string, value?: any) => {
                        if (onInteract) {
                            onInteract(key, type, value);
                        }
                    };
                    const props = {
                        k: key,
                        key,
                        value,
                        onChange: finalOnChange,
                        onInteract: finalOnInteract,
                        options,
                        status,
                        data,
                        statusExtraData,
                    } as any;

                    let formItemProps = formItemPropsMap[key as string]
                        ? _.cloneDeep(formItemPropsMap[key as string] as any)
                        : undefined;

                    if (!_.isNil(formItemProps)) {
                        // 表单验证样式
                        if (validateResult && validateResult[key]) {
                            const vResultForKey = validateResult[key];
                            if (vResultForKey) {
                                if (vResultForKey.msg) {
                                    formItemProps.help = vResultForKey.msg;
                                }
                                if (vResultForKey.status === 'fail') {
                                    formItemProps.validateStatus = 'error';
                                }
                                if (vResultForKey.status === 'warning') {
                                    formItemProps.validateStatus = 'warning';
                                }
                            }
                        }
                    } else {
                        formItemProps = {
                            colon: false,
                            label: '',
                        };
                    }

                    if (formItemProps.label) {
                        formItemProps.label = (
                            <span className="form-item-inner-label">
                                {formItemProps.label || null}
                            </span>
                        );
                    }

                    const cellItem = cellsMap.get(key as string);
                    if (wKey === 'inputSecret') {
                        // 加密对应CC
                        // 获取其他id
                        if (isMultipleCell(cellItem)) {
                        } else {
                            const sCode = cellItem?.inputs[2];
                            // 把id hack进去
                            if (sCode === 'crm_contract') {
                                // 合同的话就算了
                            } else {
                                if (cellItem?.cell === 'CC') {
                                    let customerItem;
                                    let leadItem;
                                    let contactsItem;
                                    let customerName;
                                    if (sCode === 'crm_customer') {
                                        customerItem = (data as any)?.id;
                                        customerName = (data as any)
                                            ?.customer_name;
                                    } else {
                                        customerItem =
                                            (data as any)?.customer_id
                                                ?.originalValue ||
                                            (data as any)?.follow_customer
                                                ?.originalValue;
                                        customerName =
                                            (data as any)?.customer_name ||
                                            (data as any)?.customer_id
                                                ?.relatedObj?.customer_name ||
                                            (data as any)?.follow_customer
                                                ?.relatedObj?.customer_name;
                                    }
                                    if (sCode === 'crm_lead') {
                                        leadItem = (data as any)?.id;
                                    }
                                    if (sCode === 'crm_contacts') {
                                        contactsItem = (data as any)?.id;
                                    }
                                    if (sCode === 'crm_task_activation') {
                                        contactsItem = (data as any)
                                            ?.customer_contact?.originalValue;
                                    }
                                    if (cellItem.inputs.length < 4) {
                                        cellItem.inputs = [
                                            ...cellItem.inputs,
                                            {
                                                customerItem,
                                                leadItem,
                                                contactsItem,
                                                customerName,
                                            },
                                        ];
                                    } else {
                                        cellItem.inputs[3] = {
                                            customerItem,
                                            leadItem,
                                            contactsItem,
                                            customerName,
                                        };
                                    }
                                }
                            }
                        }
                        console.log(cellItem);
                    }
                    const isEditing = !!editingMap[key as string];
                    const editable = !_.isUndefined(editableMap)
                        ? !!editableMap[key as string]
                        : true;

                    const lw = loadingWrapper(confirmLoading && isEditing);

                    const NextlineWidget: any =
                        cellNextLineItems && cellNextLineItems[key as string]
                            ? cellNextLineItems[key as string]
                            : () => null;
                    return lw(
                        <>
                            <div
                                className={
                                    'form-item ' +
                                    (isEditing ? 'editing ' : 'display ')
                                }
                                onClick={() => {
                                    if (!isEditing && editable) {
                                        finalOnInteract(
                                            'start-editing-display-param',
                                        );
                                    }
                                }}
                                style={editable ? {} : { cursor: 'unset' }}
                            >
                                <div className="label">
                                    {formItemProps.label}
                                    {formItemProps.required ? (
                                        // {true ? ( // 能到这里的都是required
                                        <span className="required-icon">*</span>
                                    ) : null}
                                </div>
                                <div className={'widget '}>
                                    <WidgetEditable
                                        widgetKey={wKey}
                                        record={data}
                                        formWidgetProps={props}
                                        cellItem={cellItem}
                                        isEditing={isEditing}
                                        editable={editable}
                                        onAction={onAction}
                                        isMultipleEditing={isMultipleEditing}
                                        emptyText={emptyText}
                                        iconVisibleWhenHover={
                                            iconVisibleWhenHover
                                        }
                                    />
                                </div>
                            </div>
                            <NextlineWidget />
                        </>,
                    );
                });

                if (collapseLayout) {
                    return (
                        <AutoCollapse
                            collapseProps={collapseProps}
                            validateResult={validateResult}
                            key2CollpaseKeyMap={key2CollpaseKeyMap}
                            guys={children}
                            layouts={collapseLayout || []}
                            hiddenWhenNoBoys={hiddenWhenNoBoys}
                        />
                    );
                }

                if (layout && !_.isString(layout)) {
                    return (
                        <SGrid rowGutter={rowGutter} layout={layout}>
                            {children}
                        </SGrid>
                    );
                }

                return children;
            })()}
        </div>
    );
};
