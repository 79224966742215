import React, { useEffect, useState, useContext, CSSProperties } from 'react';
import { observer } from 'mobx-react-lite';
import { defaultAxios, isNetSuccess } from '@/utils';
import _ from 'lodash';
import moment from 'moment';
import './index.less';
import { StoreContext } from '@/stores';
import { Spin, Statistic } from 'antd';
const HomeProcessInfo: React.FC = observer(props => {
    const store = useContext(StoreContext);
    const homeNewStore = store.getDefaultHomeNewStore();
    const authStore = store.getAuthStore();
    const { userInfo } = authStore;
    const { userId } = userInfo;
    const {
        fetchHomePageProcessInfo,
        homeNewProcessInfoData,
        homeNewProcessInfoLoading,
        goToPage,
    } = homeNewStore;
    useEffect(() => {
        if (userId) {
            fetchHomePageProcessInfo(userId);
        }
    }, [userId]);
    const statisticValueStyle: CSSProperties = {
        fontSize: '16px',
        color: '#0052ff',
        fontWeight: 500,
    };
    return (
        <div className="home-new-right-info-content">
            <Spin spinning={homeNewProcessInfoLoading}>
                <div className="crm-home-process-info">
                    <p className="home-page-title">我的流程</p>
                    <div className="home-process-info-body">
                        <div
                            className="process-info-item"
                            onClick={() => {
                                if (homeNewProcessInfoData.unFinished) {
                                    goToPage('process');
                                }
                            }}
                        >
                            <Statistic
                                valueStyle={statisticValueStyle}
                                value={
                                    // 3000
                                    homeNewProcessInfoData.unFinished || 0
                                }
                            />
                            <p className="process-info-label">待我审批</p>
                        </div>
                        <div
                            className="process-info-item"
                            onClick={() => {
                                if (homeNewProcessInfoData.publishUnfinished) {
                                    goToPage('process');
                                }
                            }}
                        >
                            <Statistic
                                valueStyle={statisticValueStyle}
                                value={
                                    // 3000
                                    homeNewProcessInfoData.publishUnfinished ||
                                    0
                                }
                            />
                            <p className="process-info-label">审批中</p>
                        </div>
                        <div
                            className="process-info-item"
                            onClick={() => {
                                if (homeNewProcessInfoData.publishReject) {
                                    goToPage('process');
                                }
                            }}
                        >
                            <Statistic
                                valueStyle={statisticValueStyle}
                                value={
                                    // 3000
                                    homeNewProcessInfoData.publishReject || 0
                                }
                            />
                            <p className="process-info-label">已驳回</p>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    );
});

export default HomeProcessInfo;
