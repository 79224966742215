import React, { useContext, useEffect } from 'react';
import { Radio, message, Input, Select, Form, TreeSelect } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { AntButton } from '@/components';
import _ from 'lodash';
import { AntDrawer } from '@/components/antd/drawer';
import { StoreContext } from '@/stores';
import { TagItemInfo } from '../api';

export interface IFormDrawerProps extends FormComponentProps {
    visible: boolean;
    initialValues?: TagItemInfo;
    onSubmit: (val: any) => void;
    onClose: () => void;
    loading?: boolean;
}

const FormDrawer: React.FC<IFormDrawerProps> = ({
    visible,
    initialValues,
    loading = false,
    onSubmit = () => {},
    onClose = () => {},
    form,
}) => {
    const {
        getFieldDecorator,
        setFieldsValue,
        getFieldValue,
        validateFields,
        resetFields,
    } = form;

    const store = useContext(StoreContext);
    const settingStore = store.getSettingDefaultStore();
    const { departInfo } = settingStore; // 用户选择下拉框数据

    useEffect(() => {
        settingStore.getDepartInfo();
    }, []);

    useEffect(() => {
        _.isEmpty(initialValues)
            ? resetFields()
            : setFieldsValue(initialValues!);
    }, [initialValues]);

    useEffect(() => {
        if (!visible) {
            resetFields();
        }
    }, [visible]);

    const handleSubmit = () => {
        validateFields((errs, values) => {
            if (errs) {
                return;
            }
            if (_.isArray(values.org)) {
                values.org = values.org.toString();
            }
            onSubmit(values);
        });
    };
    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <AntDrawer
                title={initialValues?.id ? '修改标签组' : '新建标签组'}
                placement={'right'}
                visible={visible}
                onClose={handleClose}
                bodyStyle={{
                    width: 800,
                    overflow: 'scroll',
                }}
                width="800px"
            >
                <div
                    className="standard-drawer-form-wrapper"
                    style={{ paddingTop: '12px' }}
                >
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                        {!!initialValues?.id && (
                            <Form.Item>{getFieldDecorator('id')}</Form.Item>
                        )}
                        <Form.Item label="标签组名称">
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入标签组名称',
                                    },
                                ],
                            })(
                                <Input
                                    maxLength={10}
                                    placeholder="请输入标签组名称"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label="可见人员范围">
                            {getFieldDecorator('auth', {
                                initialValue: 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择可见人员范围',
                                    },
                                ],
                            })(
                                <Select>
                                    <Select.Option value={0}>
                                        仅自己可见
                                    </Select.Option>
                                    <Select.Option value={1}>
                                        仅自己及下属可见
                                    </Select.Option>
                                    <Select.Option value={2}>
                                        自定义权限
                                    </Select.Option>
                                </Select>,
                            )}
                        </Form.Item>
                        {getFieldValue('auth') === 2 && (
                            <Form.Item label="可见部门">
                                {getFieldDecorator('org', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择可见部门',
                                        },
                                    ],
                                    initialValue: initialValues?.org?.split(
                                        ',',
                                    ),
                                })(
                                    <TreeSelect
                                        allowClear
                                        treeDataSimpleMode
                                        treeCheckable
                                        treeNodeFilterProp="title"
                                        placeholder="请选择部门"
                                        treeData={departInfo}
                                        treeDefaultExpandedKeys={['1']}
                                        showCheckedStrategy={'SHOW_ALL'}
                                    />,
                                )}
                            </Form.Item>
                        )}

                        <Form.Item label="是否生效">
                            {getFieldDecorator('effect', {
                                initialValue: 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择是否生效',
                                    },
                                ],
                            })(
                                <Radio.Group>
                                    <Radio value={1}>是</Radio>
                                    <Radio value={0}>否</Radio>
                                </Radio.Group>,
                            )}
                        </Form.Item>
                        <Form.Item label="是否可换绑对象">
                            {getFieldDecorator('changed', {
                                initialValue: 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择是否可换绑对象',
                                    },
                                ],
                            })(
                                <Radio.Group>
                                    <Radio value={1}>是</Radio>
                                    <Radio value={0}>否</Radio>
                                </Radio.Group>,
                            )}
                        </Form.Item>
                        <Form.Item label="是否可以为空">
                            {getFieldDecorator('can_empty', {
                                initialValue: 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择是否可以为空',
                                    },
                                ],
                            })(
                                <Radio.Group>
                                    <Radio value={1}>是</Radio>
                                    <Radio value={0}>否</Radio>
                                </Radio.Group>,
                            )}
                        </Form.Item>
                        <Form.Item label="标签选择">
                            {getFieldDecorator('option', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择标签选择',
                                    },
                                ],
                            })(
                                <Radio.Group>
                                    <Radio value={1}>单选</Radio>
                                    <Radio value={2}>多选</Radio>
                                </Radio.Group>,
                            )}
                        </Form.Item>
                    </Form>
                    <div className="fixed-form-btns">
                        <AntButton
                            size="large"
                            type="primary"
                            loading={loading}
                            onClick={handleSubmit}
                        >
                            保存
                        </AntButton>
                        <AntButton size="large" onClick={handleClose}>
                            取消
                        </AntButton>
                    </div>
                </div>
            </AntDrawer>
        </>
    );
};

export default Form.create<IFormDrawerProps>()(FormDrawer);
