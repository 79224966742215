import _ from 'lodash';
import { AuthStore } from '@/stores/auth';
import moment from 'moment';

// JOBTODO，这里如何通信，以及快捷筛选的具体字段
export const JobCusFollowDFConfs: { [entityCode: string]: any } = {
    'cus-follow': {
        lv4MenuFilters: {
            all: [
                {
                    key: 'job-management-cus-follow-all-mine',
                    label: '全部任务',
                    value: [],
                },
                async (options: { authStore: AuthStore }) => {
                    const { authStore } = options;
                    const userId = authStore?.userInfo?.sysUser?.id;
                    return {
                        key: 'job-management-cus-follow-created-by-me',
                        label: '由我创建的任务',
                        value: [
                            userId
                                ? {
                                      condition: {
                                          fieldId: 'create_user',
                                          filterValue: userId,
                                          operateType: '=',
                                      },
                                  }
                                : null,
                        ].filter(Boolean),
                    };
                },
                async (options: { authStore: AuthStore }) => {
                    const { authStore } = options;
                    const userId = authStore?.userInfo?.sysUser?.id;
                    return {
                        key: 'job-management-cus-follow-my-todo',
                        label: '待我执行的任务',
                        value: [
                            userId
                                ? {
                                      condition: {
                                          fieldId: 'executor',
                                          filterValue: userId,
                                          operateType: '=',
                                      },
                                  }
                                : null,
                        ].filter(Boolean),
                    };
                },
            ],
            mine: [],
        },
        fastFilters: {
            all: [
                {
                    label: '进行中任务',
                    fastFilterKey: 'job-ing',
                    value: [
                        {
                            condition: {
                                fieldId: 'task_state',
                                filterValue: 2,
                                operateType: '=',
                            },
                        },
                    ],
                },
                // end_time
                {
                    label: '今日到期任务',
                    fastFilterKey: 'job-expire-today',
                    value: [
                        {
                            condition: {
                                fieldId: 'end_time',
                                filterValue: moment()
                                    .endOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'end_time',
                                filterValue: moment()
                                    .startOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '>=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'task_state',
                                filterValue: '1,2,3',
                                operateType: 'in',
                            },
                        },
                    ],
                },
                {
                    label: '本周到期任务',
                    fastFilterKey: 'job-expire-thisweek',
                    value: [
                        {
                            condition: {
                                fieldId: 'end_time',
                                filterValue: moment()
                                    .endOf('week')
                                    .add(1, 'day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'end_time',
                                filterValue: moment()
                                    .startOf('week')
                                    .add(1, 'day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '>=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'task_state',
                                filterValue: '1,2,3',
                                operateType: 'in',
                            },
                        },
                    ],
                },
                {
                    label: '已逾期任务',
                    fastFilterKey: 'job-expired',
                    value: [
                        {
                            condition: {
                                fieldId: 'task_state',
                                filterValue: 3,
                                operateType: '=',
                            },
                        },
                    ],
                },
            ],
            mine: [],
        },
    },
};
