import React from 'react';
import './index.scss';
import { Steps, Popover, message } from 'antd';
import { AntButton } from '@/components/antd';
import $ from 'jquery';
import { getScrollParent, getWidthOfValueTitle } from '@/utils';
import { Pie } from '@ant-design/charts';
import _ from 'lodash';
const { Step } = Steps;

export interface IStepItem {
    text: React.ReactNode;
    title: React.ReactNode;
}

export interface IOptStepProps {
    items: IStepItem[];
    current: number;
    active?: number;
    onActiveChange?: (nextActive: number) => void;
    onNextClick?: (submitStep: number, status: string) => void;
    submitStepLoading?: boolean;
    isShudan?: boolean;
}

export const OptStep: React.FC<IOptStepProps> = ({
    items,
    current,
    active,
    onActiveChange,
    onNextClick,
    submitStepLoading,
    isShudan,
}) => {
    isShudan = _.isUndefined(isShudan) ? false : isShudan;

    const customDotShudan = (dot: any, info: any) => {
        const { index, description: winRate } = info;

        let status = 'done';
        if (winRate === 0) {
            status = 'shudan';
        }

        const data = [
            {
                type: 'a',
                value: winRate,
            },
            {
                type: 'b',
                value: 100 - winRate,
            },
        ];

        const config: any = {
            appendPadding: 10,
            data,
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.6,

            label: false,
            legend: false,
            tooltip: false,
            interactions: null,
            statistic: {
                title: false,
                content: {
                    style: {
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: 12,
                        color: status === 'done' ? '#567ef2' : '#eea5af',
                    },
                    formatter: function formatter() {
                        return winRate + '%';
                    },
                },
            },
            height: 64,
            width: 64,
        };

        let wrapper = (ele: React.ReactNode): React.ReactNode => ele;
        if (status === 'done') {
            wrapper = (ele: React.ReactNode) => (
                <Popover
                    autoAdjustOverflow={false}
                    placement="top"
                    content={<span>点击可回退至此阶段</span>}
                >
                    {ele}
                </Popover>
            );
        }

        return wrapper(
            <div
                onClick={() => {
                    if (status === 'done') {
                        return onNextClick && onNextClick(index, status);
                    }
                }}
                className="step-item-outer"
            >
                <Pie
                    animation={false}
                    color={[
                        status === 'done' ? '#567ef2' : '#cccccc',
                        status === 'done' ? '#f3f3f3' : '#eea5af',
                    ]}
                    style={{
                        position: 'absolute',
                        left: -19,
                        top: -17,
                        cursor: 'pointer',
                    }}
                    {...config}
                />
            </div>,
        );
    };

    const customDot = (dot: any, info: any) => {
        const { index, description: winRate } = info;

        let status = 'done';
        let baseStatus = 'done';
        if (index > current) {
            baseStatus = 'todo';
            status = 'cannot';
        }
        if (index - current === 1) {
            baseStatus = 'todo';
            status = 'todo';
        }
        if (index < current) {
            status = 'before';
        }
        if (index === current) {
            status = 'ing';
        }

        let wrapper = (ele: React.ReactNode): React.ReactNode => ele;
        if (status === 'todo' || status === 'before') {
            wrapper = (ele: React.ReactNode) => (
                <Popover
                    autoAdjustOverflow={false}
                    placement="top"
                    content={
                        status === 'before' ? (
                            <span>点击可回退至此阶段</span>
                        ) : (
                            <span>点击可切换至此阶段</span>
                        )
                    }
                >
                    {ele}
                </Popover>
            );
        }

        const data = [
            {
                type: 'a',
                value: winRate,
            },
            {
                type: 'b',
                value: 100 - winRate,
            },
        ];

        const config: any = {
            appendPadding: 10,
            data,
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.6,

            label: false,
            legend: false,
            tooltip: false,
            interactions: null,
            statistic: {
                title: false,
                content: {
                    style: {
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: 12,
                        color: baseStatus === 'done' ? '#567ef2' : '#cccccc',
                    },
                    formatter: function formatter() {
                        return winRate + '%';
                    },
                },
            },
            height: 64,
            width: 64,
        };

        return wrapper(
            // 老样式
            // <div className="step-item-outer">
            //     <div
            //         className={
            //             'step-item ' +
            //             (status + ' ') +
            //             (isActive ? ' active ' : '')
            //         }
            //         style={style}
            //     />
            // </div>,

            // 新样式
            <div
                onClick={() => {
                    if (status === 'todo' || status === 'before') {
                        return onNextClick && onNextClick(index, status);
                    }
                    if (status === 'cannot') {
                        return message.error('不可跨阶段转移');
                    }
                }}
                className="step-item-outer"
            >
                <Pie
                    animation={false}
                    color={[
                        baseStatus === 'done' ? '#567ef2' : '#cccccc',
                        '#f3f3f3',
                    ]}
                    style={{
                        position: 'absolute',
                        left: -19,
                        top: -17,
                        cursor: 'pointer',
                    }}
                    {...config}
                />
            </div>,
        );
    };

    return (
        <div className="component-opt-step">
            <Steps
                current={current}
                progressDot={isShudan ? customDotShudan : customDot}
            >
                {items.map((item, index) => {
                    const text = _.isUndefined(item.text)
                        ? 'unknow'
                        : item.text;
                    const title = _.isUndefined(item.title)
                        ? 'unknow'
                        : item.title;

                    return (
                        <Step
                            key={index}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {}}
                            description={text}
                            title={title}
                        />
                    );
                })}
            </Steps>
        </div>
    );
};
