import React, { memo, useState, useEffect } from 'react';
import moment from 'moment';
import { AutoEllipsis } from '../../auto-ellipsis';
import { Icon, Popover, Divider, Form, Alert } from 'antd';
import { BlockLoading2 } from '../../block-loading';
import { defaultAxios, isNetSuccess, arrToJson } from '@/utils';
import { AutoLink } from '../../auto-link';
import _ from 'lodash';

const followPurposes = [
    { label: '培训工作', value: 1 },
    { label: '培训工作', value: 1 },
    { label: '客情维护', value: 2 },
    { label: '问题处理', value: 3 },
    { label: '售前工作/商务洽谈', value: 4 },
    { label: '拜访建联', value: 5 },
    { label: '高层拜访', value: 6 },
];
export const followPurposesMap = arrToJson(followPurposes, 'value');

const followTypes = [
    { label: '电话', value: 10 },
    { label: '拜访', value: 20 },
    { label: '邮件/微信', value: 30 },
    { label: '备忘', value: 40 },
    { label: '视频', value: 50 },
];
export const followTypesMap = arrToJson(followTypes, 'value');

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const LastestActivityRecord: React.FC<{
    args: [number];
    item: any;
}> = memo(({ args: [ts], item }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(null);
    const inputId = item?.customer_id?.originalValue || item?.id || null;
    const [id, setId] = useState(inputId);
    const [vis, setVis] = useState(false);

    useEffect(() => {
        setId(inputId);
    }, [inputId]);

    useEffect(() => {
        if (vis === false || id === null) {
            return;
        }
        setLoading(true);
        defaultAxios
            .get('/bff/api/rest/customer/lastestActivityRecord', { id })
            .then(([d, e]) => {
                setLoading(false);
                if (isNetSuccess(d, e)) {
                    setData(d?.data || null);
                }
            });
    }, [vis, id]);

    if (!ts) {
        return null;
    }

    const text = moment(ts).format('YYYY-MM-DD HH:mm:ss');
    return (
        <>
            <Popover
                placement={'leftTop'}
                getPopupContainer={() => {
                    return document.body as HTMLElement;
                }}
                // autoAdjustOverflow={false}
                mouseEnterDelay={0.3}
                content={
                    <div style={{ width: 400 }}>
                        {loading && <BlockLoading2 />}
                        {!loading && data && (
                            <div style={{ padding: '5px 8px' }}>
                                <h3>最新活动记录</h3>
                                <Form {...formItemLayout}>
                                    <Form.Item
                                        style={{ marginBottom: -4 }}
                                        label="跟进方式"
                                    >
                                        {data.follow_type
                                            ? followTypesMap[data.follow_type]
                                                  ?.label || '--'
                                            : '--'}
                                    </Form.Item>
                                    {/* JOBTODO，展示的地方替换 */}
                                    <Form.Item
                                        style={{ marginBottom: -4 }}
                                        label="拜访目的"
                                    >
                                        {data.follow_purpose
                                            ? followPurposesMap[
                                                  data.follow_purpose
                                              ]?.label || '--'
                                            : '--'}
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginBottom: -4 }}
                                        label="活动记录"
                                    >
                                        <div
                                            style={{
                                                lineHeight: '26px',
                                                paddingTop: 7,
                                            }}
                                        >
                                            {_.isString(data.note) ? (
                                                <AutoLink text={data.note} />
                                            ) : (
                                                '--'
                                            )}
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginBottom: -4 }}
                                        label="联系人"
                                    >
                                        {data.contacts_name || '--'}
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginBottom: -4 }}
                                        label="下次跟进计划"
                                    >
                                        <div
                                            style={{
                                                lineHeight: '26px',
                                                paddingTop: 7,
                                            }}
                                        >
                                            {data.next_note || '--'}
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginBottom: -4 }}
                                        label="下次跟进时间"
                                    >
                                        {data.next_date
                                            ? moment(data.next_date).format(
                                                  'YYYY-MM-DD HH:mm:ss',
                                              )
                                            : '--'}
                                    </Form.Item>
                                </Form>
                            </div>
                        )}
                        {!loading && !data && (
                            <div
                                style={{
                                    padding: '15px 0',
                                    textAlign: 'center',
                                }}
                            >
                                暂无活动记录
                            </div>
                        )}
                    </div>
                }
                onVisibleChange={vis => {
                    setVis(vis);
                    if (vis) {
                        // request
                        console.log('requset ar');
                    } else {
                        // cancel todo
                        console.log('cancel requset ar');
                    }
                }}
                trigger="hover"
            >
                <Icon
                    style={{
                        color: '#0052ff',
                        cursor: 'pointer',
                    }}
                    type="info-circle"
                />
                &nbsp;
                <AutoEllipsis style={{ display: 'inline' }} text={text} />
            </Popover>
        </>
    );
});
