import React from 'react';
import SwitchButton from '@/components/common/switch-button';

import './index.less';

interface BoardSwitchProps {
    onChange?: (value: number) => void;
}

const BoardSwitch: React.FC<BoardSwitchProps> = props => {
    const { onChange } = props;
    return (
        <div className="board-switch">
            <div>切换销售团队</div>
            <SwitchButton<number>
                style={{ marginLeft: '16px' }}
                buttonOptions={[
                    { value: 63, name: '大客' },
                    { value: 64, name: '普客' },
                ]}
                onChange={onChange}
            />
        </div>
    );
};

export default BoardSwitch;
