import React from 'react';
import { Icon, Dropdown, Menu } from 'antd';
import _ from 'lodash';

export const DTitle: React.FC<{
    title: string;
    list?: Array<{ label: string; value: any }>;
    onListItemClick?: (value: any) => void;
}> = ({ title, list, onListItemClick }) => {
    const oneMoreList = !_.isNil(list) && _.isArray(list) && list.length > 1;

    const titleEle = (
        <span>
            {title}{' '}
            {oneMoreList && (
                <Icon
                    style={{
                        fontSize: 12,
                        color: '#0052ff',
                        transform: 'scaleY(0.9) scaleX(1.1)',
                    }}
                    type="down"
                />
            )}
        </span>
    );
    let wrapper = (ele: React.ReactElement) => ele;
    if (oneMoreList) {
        const menu = (
            <Menu>
                {list?.map(item => {
                    const { label, value } = item;
                    return (
                        <Menu.Item
                            onClick={() => {
                                onListItemClick && onListItemClick(item);
                            }}
                        >
                            <span style={{ fontSize: 13 }}>{label}</span>
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        wrapper = (ele: React.ReactElement) => {
            return (
                <Dropdown overlay={menu}>
                    <span onClick={e => e.preventDefault()}>{ele}</span>
                </Dropdown>
            );
        };
    }

    return wrapper(titleEle);
};
