import React from 'react';
import { DTable, isMultipleCell } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ConfAdvertisingRateListStore } from '../store';
import { runInAction } from 'mobx';
import { applyCustomConfToCellsSelector } from '@/components/common/form/advanced-filters';
import { tryRefresh } from '@/utils';

const DemoTable: React.FC<{
    defaultStore: ConfAdvertisingRateListStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const { defaultMSelect } = defaultStore;
    const { mSeletedData, setMSelectedData } = defaultMSelect;

    const dataTable = (
        <>
            <DTable
                onColumnsUpdated={() =>
                    tryRefresh(['talent_bank_account_gift_conf'])
                }
                defaultStore={defaultStore}
                sceneCode={'crm_om_talent_bank_account_gift_conf'}
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [
                        {
                            actionKey: 'update',
                            actionCn: '修改',
                        },
                        {
                            actionKey: 'delete',
                            actionCn: '删除',
                        },
                    ];
                    props.onAction = (key, data) => {
                        if (key === 'update') {
                            runInAction(() => {
                                defaultStore.setActionAsync('update', {
                                    id: data.id,
                                });
                            });
                        } else if (key === 'delete') {
                            runInAction(() => {
                                defaultStore.setAction('delete');
                                defaultStore.setMutatingData(data);
                            });
                        }
                    };
                    props.closeRowSelection = false;
                    props.selectedItems = mSeletedData;
                    props.onSelectedDataChange = setMSelectedData;
                    props.widthInfoKey =
                        'talent_bank_account_gift_conf-list-default';
                    return props;
                }}
                // cellsSelectorTransformer={baiscCellsSelector => {
                //     // 详情可点击
                //     applyCustomConfToCellsSelector(
                //         baiscCellsSelector,
                //         'name',
                //         cellSelector => {
                //             const nextCellSelector = _.cloneDeep(cellSelector);
                //             if (isMultipleCell(nextCellSelector)) {
                //                 return nextCellSelector;
                //             }
                //             nextCellSelector.cell = 'ActionParam';
                //             nextCellSelector.inputs.push({
                //                 paramName: 'name',
                //             });
                //             return nextCellSelector;
                //         },
                //     );
                //     return baiscCellsSelector;
                // }}
            />
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
