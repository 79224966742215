import React, { useState, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import { TreeNodeNormal } from 'antd/lib/tree/Tree';
import _ from 'lodash';
import './index.scss';
import { ParamsEditor } from '@/components/common/params-editor';
import { Divider, Button } from 'antd';
import { SearchAbleTreeLeafOnlyModal } from '@/components/common/searchable-tree/leaf-only';
import { transformDataToTreeData } from '@/utils';
import { WidgetTreeSingleLeafOnly } from '@/components/common/form/widgets/product/tree-single-leafonly';

const params: IParamItem[] = [
    {
        title: '姓名',
        key: 'name',
        groupKey: 'basic',
        groupTitle: '基础信息',
    },
    {
        title: '性别',
        key: 'gender',
        groupKey: 'basic',
        groupTitle: '基础信息',
    },
    {
        title: '姓名2',
        key: 'name2',
        groupKey: 'other',
        groupTitle: '其他信息',
    },
    {
        title: '性别2',
        key: 'gender2',
        groupKey: 'other',
        groupTitle: '其他信息',
    },
    {
        title: '姓名3',
        key: 'name3',
        groupKey: 'other',
        groupTitle: '其他信息',
    },
    {
        title: '性别3',
        key: 'gender3',
        groupKey: 'other',
        groupTitle: '其他信息',
    },
    {
        title: '姓名4',
        key: 'name4',
        groupKey: 'other',
        groupTitle: '其他信息',
    },
    {
        title: '性别4',
        key: 'gender4',
        groupKey: 'other',
        groupTitle: '其他信息',
    },
    {
        title: '野生',
        key: 'any',
    },
];

const flattenTreeItems: any[] = [
    {
        key: 'group-basic',
        title: '基础信息',
    },
    {
        key: 'param-name',
        title: '姓名',
        parentKey: 'group-basic',
    },
    {
        key: 'param-gender',
        title: '性别',
        parentKey: 'group-basic',
    },
    {
        key: 'group-other',
        title: '其他信息',
    },
    {
        key: 'param-name2',
        title: '姓名2',
        parentKey: 'group-other',
    },
    {
        key: 'param-gender2',
        title: '性别2',
        parentKey: 'group-other',
    },
    {
        key: 'param-name3',
        title: '姓名3',
        parentKey: 'group-other',
    },
    {
        key: 'param-gender3',
        title: '性别3',
        parentKey: 'group-other',
    },
    {
        key: 'param-name4',
        title: '姓名4',
        parentKey: 'group-other',
    },
    {
        key: 'param-gender4',
        title: '性别4',
        parentKey: 'group-other',
    },
    {
        key: 'param-any',
        title: '野生',
    },
];

const treeData: TreeNodeNormal[] = transformDataToTreeData(flattenTreeItems);

const SearchTreeDemo: React.FC<RouteComponentProps> = props => {
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [visible, setVisible] = useState(false);

    const [selectedKey, setSelectedKey] = useState('');
    return (
        <div>
            <ParamsEditor
                params={params}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
            />
            <Divider />
            <Button onClick={() => setVisible(true)}>看看</Button>
            <div style={{ width: 600 }}>
                <SearchAbleTreeLeafOnlyModal
                    inputProps={{
                        placeholder: '查找筛选项',
                    }}
                    treeData={treeData}
                    selectedKeys={selectedKeys}
                    onSelectedChange={keys => {
                        setSelectedKeys([...keys]);
                        setVisible(false);
                    }}
                    visible={visible}
                    onVisibleChange={setVisible}
                />
            </div>
            <Divider />
            <div style={{ width: 600 }}>
                <WidgetTreeSingleLeafOnly
                    options={{
                        treeProps: {
                            treeData,
                            inputProps: {
                                placeholder: '搜索产品目录',
                            },
                        },
                        inputProps: {
                            placeholder: '选择产品目录',
                            autoFocus: false,
                        },
                        flattenData: flattenTreeItems,
                    }}
                    value={selectedKey}
                    onChange={key => {
                        setSelectedKey(key);
                    }}
                />
            </div>
        </div>
    );
};

export default SearchTreeDemo;
