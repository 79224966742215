import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    AntButton,
} from '@/components';
import { Icon, Tooltip, message } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import {
    useNet,
    delay,
    useBeforeFirstDataLoaded,
    defaultAxios,
    isNetSuccess,
    useRefresh,
    useQueryAsObject,
} from '@/utils';
import { observer } from 'mobx-react-lite';
import DemoTable from './components/table';
import { ActionDelete } from './components/delete';
import { ActionMDelete } from './components/m-delete';
import { runInAction } from 'mobx';
import { DTitle2 } from '@/components/common/dtitle2';
import {
    defaultAuthDeco,
    dFilterHOC,
    useDefaultDFilterSnippets,
} from '@/containers/hoc';
import './index.scss';
import { MOpResultDisplayContainer } from '@/components';
import FormDrawerCreate from './components/form-drawer-create';
import FormDrawerDetail from './components/form-drawer-detail';
import classname from 'classname';
import { DTitle } from '../../custom/list/components/dtitle';
import { useFirstLv4Menu } from '@/stores/utils/fast-filters';
import { ProcessTypeSelector } from './components/process-type-selector';
import { ActionExport } from '../../activation-record/list/components/export';

/*
1) 动态param的隔离
2) filter样式
 */

const DemoList: React.FC<RouteComponentProps & {
    type: string;
    dfilters?: any;
}> = ({ type = 'category', dfilters }) => {
    const store = useContext(StoreContext);
    const [defaultStore] = useState(() => {
        const sto = new store.ProcessCatStore();
        sto.setIsCate2True();
        return sto;
    });
    const userStore = store.getAuthStore();

    const ack = dtext('crm')('process-category-message-' + type, '');

    const {
        tableCode, // 动态列表
        defaultPagination,
        defaultSorter,
        defaultMeta,
        pageTitle,
        defaultPerm,
        defaultFilter,
        defaultMSelect,
        defaultFastFilters,
        condListbyFilterData,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const [fastFilterReady, setFastFilterReady] = useState(false);
    const [preRequiredReady, setPreRequiredReady] = useState(false);

    useEffect(() => {
        runInAction(() => {
            defaultFastFilters.setType(type);
            defaultStore.setListType(type);
        });
    }, [type]);

    const { selectedLv4MenuFilters, lv4MenuFilters } = defaultFastFilters;

    // 设置四级菜单
    useEffect(() => {
        defaultFastFilters.setFilters(dfilters);
    }, []);

    // 自动选择第一个四级菜单
    useFirstLv4Menu(defaultFastFilters, () => {
        setFastFilterReady(true);
    });

    // 根据四级菜单的选择，动态更改store的元信息，权限
    const selectedTableCode = selectedLv4MenuFilters[0]?.key;
    useEffect(() => {
        if (selectedTableCode === undefined) {
            return;
        }
        // 当tableCode更新，意味着内部各种东西已经更新
        defaultStore.setTableCode(selectedTableCode);
    }, [selectedTableCode]);

    // 统计，分类
    const [processStatistics, setProcessStatistics] = useState<{
        [key: string]: any;
    }>({});
    const [
        processStatisticsRefreshTrigger,
        setProcessStatisticsRefreshTrigger,
    ] = useState(0);
    useEffect(() => {
        if (selectedTableCode === undefined) {
            return;
        }
        if (!userStore.userInfo) {
            return;
        }

        (async () => {
            const userId = userStore.userInfo.userId;
            if (userId) {
                const [d, e] = await defaultAxios.get(
                    '/bff/api/rest/process/statistic/cate-detail',
                    {
                        tableCode: selectedTableCode,
                    },
                );
                if (isNetSuccess(d, e)) {
                    const data = d?.data;
                    const nextProcessStatistics = data;
                    setProcessStatistics(nextProcessStatistics);
                }
            }
        })();
    }, [
        userStore.userInfo,
        processStatisticsRefreshTrigger,
        selectedTableCode,
    ]);

    useRefresh(['raw-process'], () => {
        setProcessStatisticsRefreshTrigger(1 + processStatisticsRefreshTrigger);
    });

    // 统计，所有
    const [processStatisticsAll, setProcessStatisticsAll] = useState<{
        [key: string]: any;
    }>({});
    const [
        processStatisticsAllRefreshTrigger,
        setProcessStatisticsAllRefreshTrigger,
    ] = useState(0);
    useEffect(() => {
        if (!userStore.userInfo) {
            return;
        }

        (async () => {
            const userId = userStore.userInfo.userId;
            if (userId) {
                const [d, e] = await defaultAxios.get(
                    '/bff/api/rest/process/statistic/all-cate',
                );
                if (isNetSuccess(d, e)) {
                    const data = d?.data;
                    const nextProcessStatisticsAll = data;
                    setProcessStatisticsAll(nextProcessStatisticsAll);
                }
            }
        })();
    }, [userStore.userInfo, processStatisticsAllRefreshTrigger]);

    useRefresh(['raw-process'], () => {
        setProcessStatisticsAllRefreshTrigger(
            1 + processStatisticsAllRefreshTrigger,
        );
    });

    // 流程筛选
    const [processCode, setProcessCode] = useState(() => {
        const defaultCode = 'SUBMIT';
        defaultStore.setProcessCode(defaultCode);
        return defaultCode;
    });
    const processFilters = (
        <ProcessTypeSelector
            selected={processCode}
            onChange={nextProcessCode => {
                defaultStore.setProcessCode(nextProcessCode);
                setProcessCode(nextProcessCode);
            }}
            processStatistics={processStatistics}
            resetTrigger={selectedTableCode}
        />
    );

    // 更新刷新方法
    const listFetch = useCallback(() => defaultStore.fetch2(processCode), [
        tableCode,
        processCode,
    ]);
    const [error, loading, reload] = useNet(listFetch, {
        autoLoad: false,
        refreshKeys: [tableCode || '', 'raw-process'],
    });

    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        // 再一次ready，再查询
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        processCode,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (!userStore.userInfo) {
            return;
        }
        defaultStore.setSysUser(userStore.userInfo.sysUser);
    }, [userStore.userInfo]);

    // 初始化新的meta和perm和filter
    useEffect(() => {
        if (
            userStore.userInfo &&
            tableCode !== null &&
            defaultMeta !== null &&
            defaultPerm !== null
        ) {
            defaultMeta.setTableId(tableCode);
            defaultMeta.setOpUsername(
                userStore.userInfo.userId + '-processcate2-' + tableCode,
            );
            defaultStore.setSysUser(userStore.userInfo.sysUser);

            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(async () => {
                    setPreRequiredReady(true);
                    defaultFilter.resetFilterData();
                    defaultFilter.confirmFilterData();
                })
                .catch(() => {});
        }
    }, [userStore.userInfo, tableCode]);

    useQueryAsObject(
        queryObj => {
            const instanceId = queryObj?.id;
            const currentUser = userStore.userInfo?.userId;
            if (!instanceId || !currentUser) {
                return;
            }
            defaultAxios
                .get('/bff/api/rest/process/get-raw-data', {
                    instanceId,
                    currentUser,
                })
                .then(([d, e]) => {
                    if (isNetSuccess(d, e)) {
                        const rawProcessItem = d?.data?.datas?.[0];
                        if (!rawProcessItem) {
                            message.error('流程实例不存在，或无查看权限');
                            return;
                        }
                        const tableCode = rawProcessItem?.formKey;
                        const targetProcessTab = defaultFastFilters.lv4MenuFilters.find(
                            (item: any) => item.key === tableCode,
                        );
                        if (!targetProcessTab) {
                            message.error('流程类型不存在');
                            return;
                        }
                        defaultFastFilters.setSelectedLv4MenuFilters([
                            targetProcessTab,
                        ]);
                        defaultStore.setAction('see-detail', rawProcessItem);
                    }
                });
        },
        [userStore.userInfo],
    );

    // 快捷筛选
    const dtitleProps: any = {
        title:
            defaultFastFilters.selectedLv4MenuFilters.length === 0
                ? pageTitle
                : defaultFastFilters.selectedLv4MenuFilters[0].label,
    };
    dtitleProps.list = defaultFastFilters.lv4MenuFilters;
    dtitleProps.selected = selectedLv4MenuFilters;
    dtitleProps.onListItemClick = (item: any) => {
        // 在菜单切换的时候，需要开始做各种重新准备工作
        if (item.key === selectedLv4MenuFilters[0]?.key) {
            return;
        }
        setPreRequiredReady(false);
        defaultFastFilters.setSelectedLv4MenuFilters([item]);
    };
    const title = (
        <DTitle2 {...dtitleProps} extra={{ statistic: processStatisticsAll }} />
    );

    const [formK, setFormK] = useState(0);
    useRefresh(
        ['raw-process'],
        () => {
            setFormK(formK + 1);
        },
        [formK],
    );

    const [isBeforeFirstLoaded] = useBeforeFirstDataLoaded(defaultStore);

    const noReady =
        !preRequiredReady || !fastFilterReady || !defaultMeta || !defaultPerm;

    const filtersDisplay = defaultMeta ? (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    ) : null;

    const popups = (
        <>
            <ActionExport defaultStore={defaultStore} />
            <MOpResultDisplayContainer defaultStore={defaultStore} />
            <ActionDelete defaultStore={defaultStore} />
            <ActionMDelete defaultStore={defaultStore} />
            <FormDrawerCreate
                key={(defaultStore.detailTableCode || 'None') + ':' + formK}
                defaultStore={defaultStore}
                preRequiredReady={!noReady}
                autoNext={true}
                keepState={true}
                lifeCycle={{
                    onClose: () => setFormK(formK + 1),
                    onOpen: () => {},
                }}
            />
            <FormDrawerDetail
                key={defaultStore.detailTableCode || 'None'}
                defaultStore={defaultStore}
                preRequiredReady={!noReady}
            />
        </>
    );

    const noCreate =
        selectedTableCode === 'process_contract_product_open_table' ||
        selectedTableCode === 'process_contract_employer_open_table';

    const globalOps = (
        <>
            {defaultPerm !== null &&
                defaultPerm.getPermByTypeAndAction(type)('create').visible &&
                null}
            {!noCreate && (
                <AntButton
                    onClick={() => {
                        runInAction(() => {
                            defaultStore.setAction('create');
                            defaultStore.startNewData();
                        });
                    }}
                    type="primary"
                    size="large"
                >
                    新建流程
                </AntButton>
            )}
            <AntButton
                onClick={() => {
                    defaultStore.setAction('export');
                }}
                size="large"
            >
                导出
            </AntButton>
        </>
    );
    const multipleOps = (
        <div
            className={classname({
                'mselected-ops': true,
                'multiple-mode': defaultMSelect.isMSelectionMode,
                'normal-mode': !defaultMSelect.isMSelectionMode,
            })}
        >
            <Tooltip title="取消多选">
                <span
                    onClick={() => {
                        defaultMSelect.setMSelectedData([]);
                    }}
                    style={{
                        padding: 5,
                        cursor: 'pointer',
                    }}
                >
                    <Icon type="close" />
                </span>
            </Tooltip>
            <div>
                <span>已选{defaultMSelect.mSeletedData.length}条：</span>
            </div>
            {defaultPerm !== null &&
                defaultPerm.getPermByTypeAndAction(type)('delete').visible && (
                    <AntButton
                        onClick={() => {
                            defaultStore.setAction('m-delete');
                        }}
                        size="large"
                    >
                        删除
                    </AntButton>
                )}
        </div>
    );

    const dataTable = (
        <div style={{ display: 'flex', height: '100%' }}>
            {title}
            {isBeforeFirstLoaded || noReady ? (
                <div
                    style={{
                        flexGrow: 1,
                        paddingBottom: 40,
                    }}
                >
                    <div
                        style={{
                            background: 'white',
                            height: '100%',
                        }}
                    >
                        <BlockLoading2 />
                    </div>
                </div>
            ) : (
                <DemoTable
                    key={'table-' + selectedTableCode}
                    tableCode={selectedTableCode}
                    defaultStore={defaultStore}
                    loading={loading}
                />
            )}
        </div>
    );

    const layoutComs = {
        popups,
        title: '流程分组',
        globalOps,
        filters: filtersDisplay,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay: processFilters,
    };

    return <LayoutStandardCrud className="page-process-list" {...layoutComs} />;
};

const FinalDemoList = dFilterHOC(observer(DemoList), 'process');
export default FinalDemoList;
