import React, { useState, useEffect } from 'react';
import classname from 'classname';
import './index.less';

interface ButtonOption<T> {
    value: T;
    name: string;
}

export interface SwitchButtonProps<T extends string | number> {
    loading?: boolean;
    buttonOptions: ButtonOption<T>[];
    value?: T;
    onChange?: (value: T) => void;
    style?: React.CSSProperties;
}

const SwitchButton = <T extends string | number>({
    loading = false,
    buttonOptions,
    value,
    onChange,
    style,
}: SwitchButtonProps<T>) => {
    const [localActiveButtonValue, setLocalActiveButtonValue] = useState<T>(
        buttonOptions[0].value,
    );

    useEffect(() => {
        if (value !== undefined) {
            setLocalActiveButtonValue(value);
        }
    }, [value]);

    const handleButtonClick = (buttonValue: T) => {
        if (loading) return;
        if (onChange) {
            onChange(buttonValue);
        }
        setLocalActiveButtonValue(buttonValue);
    };

    return (
        <div className="switch-button" style={style}>
            {buttonOptions.map((buttonOption, index) => (
                <div
                    key={index}
                    className={classname('switch-button-item', {
                        active: localActiveButtonValue === buttonOption.value,
                    })}
                    onClick={() => handleButtonClick(buttonOption.value)}
                >
                    {buttonOption.name}
                </div>
            ))}
        </div>
    );
};

export default SwitchButton;
