import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    FiltersDisplay,
    AntButton,
    BlockLoading3,
} from '@/components';
import { Icon, Tooltip, message } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, useQueryAsObject } from '@/utils';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { dFilterHOC, useDefaultDFilterSnippets } from '@/containers/hoc';
import './index.scss';
import { setFilterByUrlKey } from '../../../stores/utils/filter';
import {
    getJobStore,
    getJobTable,
    getFormDrawer,
    getActions,
} from './jobs/registry';

const DemoList: React.FC<RouteComponentProps & {
    type: string;
    tableCode: string;
    dfilters?: any;
}> = ({ type = 'all', dfilters, tableCode }) => {
    const store = useContext(StoreContext);

    const [defaultStore] = useState(() => getJobStore(tableCode));
    const [Table] = useState(() => getJobTable(tableCode));
    const [FormDrawer] = useState(() => getFormDrawer(tableCode));
    const [Actions] = useState(() => getActions(tableCode));
    const userStore = store.getAuthStore();

    const ack = dtext('crm')('xx-list-message-' + type, '');

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        pageTitle,
        defaultPerm,
        defaultFilter,
        defaultMSelect,
        defaultFastFilters,
        condListbyFilterData,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const listFetch = useCallback(() => defaultStore.fetch(), []);
    const [error, loading, reload] = useNet(listFetch, {
        autoLoad: false,
        refreshKeys: [defaultStore.tableCode],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
    ]);

    useQueryAsObject(
        (queryObj: any) => {
            if (
                queryObj.type &&
                defaultFastFilters.fastFilters.length &&
                defaultFastFilters.lv4MenuFilters.length
            ) {
                // 选择快速筛选菜单，并且根据快速筛选菜单，选择对应的四级菜单
                const targetFilter = defaultFastFilters.fastFilters.find(
                    (item: any) => item.fastFilterKey === queryObj.type,
                );
                if (targetFilter) {
                    defaultFastFilters.setSelectedFastFilters([targetFilter]);

                    // 目前都是跳到待我执行的
                    // 如果filterKey存在，则跳到全部
                    // 如果后续还需要定制，可以重新写一套
                    if (!queryObj.filterKey) {
                        const lv4Menu = defaultFastFilters.lv4MenuFilters.find(
                            (item: any) =>
                                item.key ===
                                'job-management-cus-follow-my-todo',
                        );
                        if (lv4Menu) {
                            defaultFastFilters.setSelectedLv4MenuFilters([
                                lv4Menu,
                            ]);
                        }
                    }
                }
            }
        },
        [defaultFastFilters.fastFilters, defaultFastFilters.lv4MenuFilters],
    );

    useEffect(() => {
        if (userStore.userInfo) {
            defaultMeta.setTableId(defaultStore.tableCode);
            defaultMeta.setOpUsername(userStore.userInfo.userId + '-' + type);
            defaultFastFilters.setFilters(dfilters);
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {});
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            setFilterByUrlKey(defaultFilter);
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useEffect(() => {
        runInAction(() => {
            defaultFastFilters.setType(type);
            defaultStore.setListType(type);
        });
    }, [type]);

    const { title, fastFilters } = useDefaultDFilterSnippets(defaultStore);

    const [newItemTitle] = useState(() => defaultStore.getNewItemText());

    if (!preRequiredReady) {
        return <BlockLoading3 />;
    }

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = (
        <>
            {/* 一期多选先不用了 */}
            {/* <MOpResultDisplayContainer defaultStore={defaultStore} /> */}
            <Actions defaultStore={defaultStore} />
            <FormDrawer
                defaultStore={defaultStore}
                preRequiredReady={preRequiredReady}
            />
        </>
    );

    const globalOps = (
        <>
            {defaultPerm.getPermByTypeAndAction(type)('create').visible && (
                <AntButton
                    onClick={() => {
                        runInAction(() => {
                            defaultStore.setAction('create');
                            defaultStore.startNewData();
                        });
                    }}
                    type="primary"
                    size="large"
                >
                    {newItemTitle}
                </AntButton>
            )}
            {defaultPerm.getPermByTypeAndAction(type)('export').visible && (
                <AntButton
                    onClick={() => {
                        defaultStore.setAction('export');
                    }}
                    size="large"
                >
                    导出
                </AntButton>
            )}
        </>
    );

    // 一期多选先不用了
    const multipleOps = null;

    const dataTable = <Table defaultStore={defaultStore} loading={loading} />;

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters: filtersDisplay,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay: fastFilters,
    };

    return (
        <LayoutStandardCrud className="page-customer-list" {...layoutComs} />
    );
};

const FinalDemoList = dFilterHOC(observer(DemoList), 'cus-follow');

// JOBTODO：用这个。这个dfilter，要根据上头的tableCode
// const FinalDemoList: React.FC<any> = (props: any) => {
//     const Com = dFilterHOC(observer(DemoList), props.tableCode)
//     return <Com {...props} />
// };

export default FinalDemoList;
