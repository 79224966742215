import React, { useState, useEffect, useContext } from 'react';
import './index.scss';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';

const MemberDataPage: React.FC<RouteComponentProps> = () => {
    const store = useContext(StoreContext);
    const memberDataStore = store.getDefaultMemberDataStore();
    return null;
};

export default observer(MemberDataPage);
