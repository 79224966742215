import React, { memo, useContext, useEffect } from 'react';
import { DForm, isMultipleCell } from '@/components';
import _, { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ProductListStore } from '../store';
import { useAnyEquityStore } from '@/pages/om/equity/list/store';
import { applyCustomConfToWidget } from '@/components/common/form/advanced-filters';
import {
    ISelectProductRightOptions,
    ITreeSingleLeafOnlyOptions,
} from '@/components/common/form/widgets';
import { Alert } from 'antd';
import { StoreContext } from '@/stores';

const DemoForm: React.FC<{
    defaultStore: ProductListStore;
}> = ({ defaultStore }) => {
    const store = useContext(StoreContext);
    const userStore = store.getAuthStore();
    const supportedTypes =
        userStore.userInfo.supportedBizTypes?.['product']?.businessTypeIds ||
        [];
    const supportedTypesSet = new Set(supportedTypes);
    useEffect(() => {
        if (supportedTypes.length === 1 && defaultStore.mutatingData) {
            defaultStore.mutatingData.biz_type = supportedTypes[0];
        }
    }, [supportedTypes, defaultStore.mutatingData]);

    const [prepared, equityStore] = useAnyEquityStore({});
    if (!prepared) {
        return null;
    }

    const { actionType } = defaultStore;

    let eventCode = '';
    if (actionType === 'create') {
        eventCode = 'CREATE-EDIT';
    } else if (actionType === 'update') {
        eventCode = 'PUBLIC-EDIT';
    }

    if (eventCode === '') {
        return null;
    }

    const {
        mutatingData,
        currentProductCateLogFlattenTreeItems,
        currentProductCateLogTreeData,
    } = defaultStore;
    const { biz_type } = mutatingData;
    const { product_type } = mutatingData
    const map = {
        '0': ['互动版', '基础版'],
        '1': ['猎头专业版',
            '猎头高级版',
            '企业高级版',
            '企业专业版',
            '增购',
            '企业尊享版',
            '猎头尊享版',
            '企业中小版',
            '企业增购',
            '猎头增购',
            '猎头中小版'
        ]
    }

    const form = (
        <DForm
            options={{
                grouped: true,
            }}
            eventCode={eventCode}
            defaultStore={defaultStore}
            onChange={(key, value) => {
                if (key === 'product_type') {
                    mutatingData.product_subcategories = null
                    mutatingData.product_version = null;
                }
                if (key === 'biz_type') {
                    mutatingData.equity_detail = null;
                }
            }}
            widgetsTransformer={baiscWidgets => {
                // product_type
                // label: "人才银行",
                // value: 1,
                // label: "企业号",
                // value: 0,
                const versionParent = mutatingData.product_type;
                applyCustomConfToWidget(
                    baiscWidgets,
                    'product_version2',
                    prevWidget => {

                        const nextWidget = _.cloneDeep(prevWidget);
                        if (isMultipleCell(nextWidget)) {
                            return nextWidget;
                        }
                        if (nextWidget.selectOptions) {
                            const options = nextWidget.selectOptions.options;
                            const nextOptions = options.filter(
                                (item: any) =>
                                    item?.item?.parentId === versionParent,
                            );
                            nextWidget.selectOptions.options = nextOptions;
                            if (nextOptions.length === 0) {
                                nextWidget.status = {
                                    ...nextWidget.status,
                                    disabled: true,
                                };
                            }
                        }
                        return nextWidget;
                    },
                );

                // product_catalog
                applyCustomConfToWidget(
                    baiscWidgets,
                    'product_catalog',
                    prevWidget => {
                        const nextWidget = _.cloneDeep(prevWidget);
                        if (isMultipleCell(nextWidget)) {
                            return nextWidget;
                        }
                        nextWidget.widgetKey = 'treeSingleLeafonly';
                        const nextOptions: ITreeSingleLeafOnlyOptions = {
                            treeProps: {
                                treeData: currentProductCateLogTreeData,
                                inputProps: {
                                    placeholder: '搜索产品目录',
                                },
                            },
                            inputProps: {
                                placeholder: '选择产品目录',
                                autoFocus: false,
                                disabled: !biz_type,
                            },
                            flattenData: currentProductCateLogFlattenTreeItems,
                        };
                        // if (!biz_type) {
                        //     nextWidget.nextlineWidget = () => {
                        //         return <Alert type="warning" message="请先选择业务类型" />
                        //     }
                        // }
                        nextWidget.treeSingleLeafonlyOptions = nextOptions;
                        return nextWidget;
                    },
                );

                // equity_detail
                applyCustomConfToWidget(
                    baiscWidgets,
                    'equity_detail',
                    prevWidget => {
                        const nextWidget = _.cloneDeep(prevWidget);
                        if (isMultipleCell(nextWidget)) {
                            return nextWidget;
                        }
                        nextWidget.widgetKey = 'selectProductRights';
                        const nextOptions: ISelectProductRightOptions = {
                            inputProps: {
                                placeholder: '输入数值',
                            },
                            objectOptions: {
                                constraint: equityStore.defaultRelatedContrait,
                                placeholder: '搜索权益',
                                listMeta: defaultStore.defaultMeta,
                                defaultFilters: biz_type
                                    ? [
                                        {
                                            fieldName: 'biz_type',
                                            operateType: 'EQUAL',
                                            fieldValue: biz_type,
                                        },
                                    ]
                                    : [],
                                listClearTrigger: biz_type
                                    ? 'biz_' + biz_type
                                    : 'no_biz',
                            },
                        };
                        nextWidget.status = {
                            ...nextWidget.status,
                            disabled: !biz_type,
                        };
                        nextWidget.selectProductRightsOptions = nextOptions;
                        return nextWidget;
                    },
                );

                applyCustomConfToWidget(
                    baiscWidgets,
                    'biz_type',
                    nextWidget => {
                        if (nextWidget.selectOptions) {
                            nextWidget.selectOptions.options = nextWidget.selectOptions.options.filter(
                                item => {
                                    return supportedTypesSet.has(+item.value);
                                },
                            );
                        }
                        return nextWidget;
                    },
                );

                // product_subcategories 产品小类
                applyCustomConfToWidget(
                    baiscWidgets,
                    'product_subcategories',
                    prevWidget => {
                        const nextWidget = _.cloneDeep(prevWidget);
                        if (isMultipleCell(nextWidget)) {
                            return nextWidget;
                        }

                        console.log('defaultStore', defaultStore, 'prevWidget', prevWidget, 'versionParent', versionParent, 'mutatingData', mutatingData)
                        if (nextWidget.selectOptions) {
                            const options = nextWidget.selectOptions.options;
                            const nextOptions = options.filter(
                                (item: any) =>
                                    item?.item?.parentId === product_type,
                            );
                            nextWidget.selectOptions.options = nextOptions;

                        }
                        nextWidget.status = {
                            disabled: !isNumber(product_type)
                        }
                        return nextWidget
                    }
                );

                return baiscWidgets;
            }}
        />
    );
    return form;
};

const FinalDemoForm = memo(observer(DemoForm));
export default FinalDemoForm;
