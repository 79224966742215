import React, {
    useState,
    useEffect,
    useLayoutEffect,
    useRef,
    useCallback,
} from 'react';
import * as echarts from 'echarts';
import { uuid } from '@/utils';
import { Spin, Icon } from 'antd';
import _ from 'lodash';

import './index.less';

export type HeaderColors = 'blue' | 'cyan' | 'orange';

export interface IChartsComponentsProps {
    containerClassName?: string;
    chartsOptions: echarts.EChartsOption;
    loading: boolean;
    chartsClassName: string;
    title?: string;
    showTitle?: boolean;
    extraRight?: string;
    showPre?: boolean;
    color?: HeaderColors;
}

const colorsMap = {
    blue: 'linear-gradient(180deg, #eaf1ff 2%, rgba(255, 255, 255, 0) 96%)',
    cyan: 'linear-gradient(180deg, #e6f8ff 2%, rgba(255, 255, 255, 0) 96%)',
    orange: 'linear-gradient(180deg, #ffeee9 2%, rgba(255, 255, 255, 0) 96%)',
};

// const domId = `charts-${uuid()}`;
// let chartElement: echarts.ECharts | null = null;
const CompositeLiveChartsComponents: React.FC<IChartsComponentsProps> = props => {
    const {
        containerClassName = '',
        chartsOptions,
        loading,
        chartsClassName,
        showTitle = true,
        showPre = false,
        color,
    } = props;
    const [chartElement, setChartElement] = useState<echarts.ECharts | null>(
        null,
    );
    const boxRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef(null);

    const [domId] = useState<string>(`charts-${uuid()}`);
    const [boxId] = useState<string>(`box-${uuid()}`);

    const resizeFunction = useCallback(
        _.debounce(
            () => {
                const boxDom = document.getElementById(boxId);
                const chartDom = document.getElementById(domId);
                if (chartDom) {
                    chartDom.style.width = `${boxDom?.clientWidth}px` || '0px';
                    chartDom.style.height =
                        `${boxDom?.clientHeight}px` || '0px';
                    // chartDom.setAttribute("style", `width: ${boxDom?.clientWidth}; height: ${boxDom?.clientHeight}`)
                }
                if (chartElement) {
                    chartElement.resize();
                }
            },
            300,
            {
                trailing: true,
            },
        ),
        [chartElement],
    );

    useEffect(() => {
        if (chartElement) {
            chartElement.setOption(chartsOptions);
            resizeFunction();
            chartElement.resize();
            window.addEventListener('resize', resizeFunction);
        }
        return () => {
            window.removeEventListener('resize', resizeFunction);
        };
    }, [chartElement]);

    useLayoutEffect(() => {
        const chartDom = document.getElementById(domId);
        if (chartDom) {
            setChartElement(echarts.init(chartDom));
        }
    }, []);
    useEffect(() => {
        // console.dir(document.getElementById(boxId)?.clientWidth);
        const boxDom = document.getElementById(boxId);
        const chartDom = document.getElementById(domId);
        if (chartDom) {
            chartDom.style.width = `${boxDom?.clientWidth}px` || '0px';
            chartDom.style.height = `${boxDom?.clientHeight}px` || '0px';
            // chartDom.setAttribute("style", `width: ${boxDom?.clientWidth}; height: ${boxDom?.clientHeight}`)
        }
        // console.log('testst', chartsOptions, chartElement);
        if (chartElement && chartsOptions) {
            chartElement.setOption(chartsOptions);
            chartElement.resize();
        }
    }, [chartsOptions]);

    return (
        <div className={chartsClassName} style={{ position: 'relative' }}>
            {showTitle && props.title && (
                <>
                    <div
                        className="composite-live-charts-header-bg"
                        style={
                            color
                                ? {
                                      background: colorsMap[color],
                                  }
                                : undefined
                        }
                    />
                    <div className="composite-live-charts-header">
                        <span className="composite-live-charts-title">
                            <pre>{props.title}</pre>
                        </span>
                        <span className="composite-live-charts-tips">
                            {props.extraRight}
                        </span>
                    </div>
                </>
            )}
            {showPre ? (
                <div
                    className={`composite-live-charts-pre ${containerClassName}`}
                >
                    <span>
                        <Icon type="experiment" />
                        规划中，敬请期待
                    </span>
                </div>
            ) : (
                <div id={boxId} className="composite-live-charts-box">
                    <Spin spinning={loading}>
                        <div
                            ref={containerRef}
                            className={`composite-live-charts-item-container ${containerClassName}`}
                            id={domId}
                        ></div>
                    </Spin>
                </div>
            )}
        </div>
    );
};

export default CompositeLiveChartsComponents;
