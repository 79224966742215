import React, { useEffect, useCallback } from 'react';
import { useState, useContext } from 'react';
import './index.scss';
import CommonDrawer from '@/components/common/drawer/index';
import { IDrawerTitleProps } from '@/components/common/drawer/drawer-title';
import { Icon, message, Menu, Select, DatePicker, Modal } from 'antd';
import { IDrawerCardListProps } from '@/components/common/drawer/drawer-card-list/index';

import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import ItemPhone from '@/components/common/items/phone';
import { IDrawerTabsProps } from '@/components/common/drawer/drawer-tabs/index';
import { IDrawerCollapseProps } from '@/components/common/drawer/drawer-collapse/index';
import { defaultAxios, useNet, useRefresh } from '@/utils';
import _ from 'lodash';
import moment from 'moment';
import { CtxProvider } from '@/utils/context';

import { LeadJobStore, useAnyLeadJobStore } from '../store';
import { opLowerCases } from '../../../utils';
import { runInAction } from 'mobx';
import { LeadActions } from '../components/actions';
import LeadJobFormDrawer from '../components/form-drawer';
import { DDetail } from '@/components/common/editable-display/ddetail';
import {
    applyCustomConfToCellsSelector,
    applyCustomConfToWidget,
} from '@/components/common/editable-display';
import { isMultipleCell, makeObjColClickable } from '@/components';
import { ActionParamEE } from '@/components/common/table/cells/action-param';
import {
    followTypesMap,
    followPurposesMap,
} from '@/components/common/table/cells/latest-activity-record';
import {
    getJobStatusColorByValue,
    jobStatusMap,
} from '@/components/common/table/cells/jobs/job-status';
import LogModal from '@/components/common/log-modal';
import CirculationLogModal from './task-circulation-log';
import { cusFollowOpBlickList } from '../components/utils';
import { JobShortCut } from '../components/shortcut';
import AddFollowLog from '@/components/common/add-follow-log-new';

// 抽屉针对客户业务的二次封装，高度业务定制
interface ICrmLeadDrawer {
    drawerVis: boolean;
    setDrawerVis: (bool: boolean) => void;
    detailId?: string | number;
    destroyOnClose?: boolean;
    onEdit?: (data: any) => void;
    onCreat?: (data: any) => void;
    forceReload?: boolean; // 如果要在外部强制刷新用户详情，则将该值变化为true
}
interface ICrmDetailData {
    detailInfo: IDrawerCollapseProps;
    originData: any;
    // 此处可维护静态字段
    staticData: {
        name: string; // 客户名称
        id: string | number; // 客户id
        owner_id: any; // 关联字段 - 客户所有人
    };
    userField: any;
    actionLogConfig: {
        arType: any[];
        followType: any[];
    }; // 活动记录基础配置
}
export type TGetCrmDetailReturnType = NetResponse<{
    data: ICrmDetailData;
    msg: string;
    code: number;
}>;
const LeadDrawer: React.FC<ICrmLeadDrawer> = props => {
    const {
        drawerVis,
        setDrawerVis,
        detailId,
        destroyOnClose = false,
        forceReload = false,
    } = props;
    const store = useContext(StoreContext);
    const userStore = store.getAuthStore();
    const userId = userStore.userInfo?.sysUser?.id;

    const [followActionModalVis, setFollowActionModalVis] = useState(false);
    const [followActionInitMark, setFollowActionInitMark] = useState(false);

    // 这里一个是搞列表的，一个是搞操作的
    const [listPrepared, defaultStoreList, prepare] = useAnyLeadJobStore({
        autoLoad: false,
    });

    const { mutatingData, defaultPerm, mutatingDataLoading } = defaultStoreList;
    const activation = mutatingData?.activation_id?.relatedObj;
    const taskStatus = mutatingData?.task_state;
    const followCusId = mutatingData?.follow_customer?.originalValue;
    const startTime = mutatingData?.start_time;

    const editableWhiteList = new Set<string>();
    if (taskStatus === 2 || taskStatus === 3) {
        editableWhiteList
            .add('activation_id')
            .add('finish_remark')
            .add('finish_attachments');
    }

    let hiddenOtherInfo = true;
    if (taskStatus === 4 || taskStatus === 7) {
        hiddenOtherInfo = false;
    }

    let invisibleMap: { [k: string]: boolean } = {};
    if (taskStatus === 4) {
        // 已取消，异常关闭相关字段不可见
        invisibleMap = {
            shutdown_time: true,
            shutdown_reason: true,
        };
    } else if (taskStatus === 7) {
        // 已异常关闭，取消相关字段不可见
        invisibleMap = {
            canceler_time: true,
            canceler_reason: true,
            canceler: true,
        };
    } else {
        invisibleMap = {
            shutdown_time: true,
            shutdown_reason: true,
            canceler_time: true,
            canceler_reason: true,
            canceler: true,
        };
    }

    useEffect(() => {
        if (drawerVis) {
            prepare();
        }
    }, [drawerVis]);

    const loadDetail = () => {
        if (drawerVis && listPrepared) {
            if (detailId) {
                defaultStoreList.fetchInstantlyMutatingDataById(detailId);
            } else {
                defaultStoreList.setMutatingData(null);
            }
        } else {
            defaultStoreList.setMutatingData(null);
        }
    };
    useEffect(loadDetail, [detailId, drawerVis, listPrepared]);
    useRefresh(['task_activation'], loadDetail);
    useEffect(() => {
        const handler = () => {
            // 活动记录弹窗
            setActivationVis(true);
        };
        ActionParamEE.on('reveal-param/activation_id', handler);
        return () => {
            ActionParamEE.removeListener('reveal-param/activation_id', handler);
        };
    }, [mutatingData]);

    const [activationVis, setActivationVis] = useState(false);

    const [defaultStoreAction] = useState(() => {
        const store = new LeadJobStore();
        store.defaultMeta.setTableId('task_activation');
        return store;
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    useEffect(() => {
        Promise.all([defaultStoreAction.defaultMeta.fetch()])
            .then(() => {
                setPreRequiredReady(true);
            })
            .catch(() => {});
    }, [defaultStoreAction]);

    const [logModalVis, setLogModalVis] = useState(false);
    const [opLogModalVis, setOpLogModalVis] = useState(false);

    const getPerm = (key: string) => {
        return !defaultPerm.inited || defaultPerm.loading
            ? false
            : defaultPerm.getPermByTypeAndAction('')(key).visible;
    };
    const hasDropMenu =
        getPerm('view-operate-record') || getPerm('view-flow-record');
    const dropMenu = hasDropMenu ? (
        <Menu
            onClick={async e => {
                if (e.key === '1') {
                    setLogModalVis(true);
                } else if (e.key === '7') {
                    setOpLogModalVis(true);
                }
            }}
        >
            {getPerm('view-operate-record') ? (
                <Menu.Item key="1">查看操作记录</Menu.Item>
            ) : null}
            {getPerm('view-flow-record') ? (
                <Menu.Item key="7">查看任务流转记录</Menu.Item>
            ) : null}
        </Menu>
    ) : null;

    const titleExampleConfig: IDrawerTitleProps = {
        title: mutatingData?.task_code,
        icons: [],
        tags: [],
        buttons: [
            ...(mutatingData?.object_ops || [])
                .filter((op: string) => !cusFollowOpBlickList.has(op))
                .filter((op: string) => {
                    return getPerm(op);
                })
                .map((op: string) => {
                    return {
                        type: 'button',
                        buttonConfig: {
                            children: opLowerCases[op],
                            onClick: () => {
                                if (op === 'update') {
                                    runInAction(() => {
                                        defaultStoreAction.setAction('update', {
                                            id: detailId,
                                        });
                                    });
                                } else if (op === 'complete') {
                                    runInAction(() => {
                                        defaultStoreAction.setSubActionType(
                                            'complete',
                                        );
                                        defaultStoreAction.setAction('update', {
                                            id: detailId,
                                        });
                                    });
                                } else if (op === 'cancel') {
                                    runInAction(() => {
                                        defaultStoreAction.setAction('cancel', {
                                            id: detailId,
                                        });
                                    });
                                }
                            },
                        },
                    };
                }),
            hasDropMenu
                ? {
                      type: 'drop',
                      dropButtonConfig: {
                          overlay: dropMenu,
                          buttonConfig: {
                              type: 'default',
                              style: {
                                  padding: 0,
                                  backgroundColor: 'rgb(244, 245, 250)',
                              },
                              children: (
                                  <span
                                      className="iconfont1"
                                      style={{
                                          fontSize: '12px',
                                          transform: 'scale(0.35)',
                                      }}
                                  >
                                      &#xe618;
                                  </span>
                              ),
                          },
                      },
                  }
                : null,
        ].filter(Boolean),
    };
    const cardExampleConfig: IDrawerCardListProps = {
        dataSource: [
            {
                title: '任务状态',
                content: (
                    <span
                        style={{
                            color:
                                getJobStatusColorByValue(taskStatus) ||
                                'inherit',
                        }}
                    >
                        {jobStatusMap[taskStatus]?.label || '- -'}
                    </span>
                ),
            },
            {
                title: '客户名称',
                content:
                    mutatingData?.follow_customer?.relatedObj?.customer_name ||
                    '- -',
            },
        ],
    };

    const tabsExampleConfig: IDrawerTabsProps = {
        tabsContentConfig: {
            defaultActiveKey: '1',
            tabBarGutter: 24,
            tabConfig: [
                {
                    key: '1',
                    tab: '任务信息',
                    content: (
                        <>
                            <DDetail
                                defaultStore={defaultStoreList}
                                formEventCode={'CREATE-EDIT'}
                                outerLoading={
                                    defaultStoreList.mutatingDataLoading
                                }
                                // cellNextLineItems={{
                                //     activation_id: () => {
                                //         return (
                                //             <div style={{ paddingTop: 4 }}>
                                //                 <a
                                //                     onClick={() => {
                                //                         setFollowActionModalVis(
                                //                             true,
                                //                         );
                                //                     }}
                                //                 >
                                //                     新建活动记录
                                //                     <Icon type="plus" />
                                //                 </a>
                                //             </div>
                                //         );
                                //     },
                                // }}
                                editableWhiteList={editableWhiteList}
                                invisibleMap={invisibleMap}
                                hiddenWhenNoBoys={true}
                                cellsSelectorTransformer={baiscCellsSelector => {
                                    _.forEach(baiscCellsSelector, cItem => {
                                        if (isMultipleCell(cItem)) {
                                            return cItem;
                                        }
                                        if (cItem.cell === 'CC') {
                                            if (cItem.inputs.length < 3) {
                                                cItem.inputs = [
                                                    ...cItem.inputs,
                                                    'crm_task_activation',
                                                ];
                                            } else {
                                                cItem.inputs[2] =
                                                    'crm_task_activation';
                                            }
                                            // cItem.inputs.push('crm_lead');
                                        }
                                        // if (cItem.cell === 'CC') {
                                        //     cItem.inputs.push('crm_task_activation');
                                        // }
                                    });
                                    // 根据状态taskStatus，展示不同
                                    applyCustomConfToCellsSelector(
                                        baiscCellsSelector,
                                        'activation_id',
                                        cellSelector => {
                                            const nextCellSelector = _.cloneDeep(
                                                cellSelector,
                                            );
                                            if (
                                                isMultipleCell(nextCellSelector)
                                            ) {
                                                return nextCellSelector;
                                            }
                                            nextCellSelector.cell =
                                                'ActionParam';
                                            nextCellSelector.inputs = [
                                                nextCellSelector.inputs[0],
                                                {
                                                    paramName: 'activation_id',
                                                    preventEvent: true,
                                                },
                                            ];
                                            return nextCellSelector;
                                        },
                                    );

                                    applyCustomConfToCellsSelector(
                                        baiscCellsSelector,
                                        'task_state',
                                        cellSelector => {
                                            const nextCellSelector = _.cloneDeep(
                                                cellSelector,
                                            );
                                            if (
                                                isMultipleCell(nextCellSelector)
                                            ) {
                                                return nextCellSelector;
                                            }
                                            nextCellSelector.cell = 'JobStatus';
                                            return nextCellSelector;
                                        },
                                    );

                                    applyCustomConfToCellsSelector(
                                        baiscCellsSelector,
                                        'priority',
                                        cellSelector => {
                                            const nextCellSelector = _.cloneDeep(
                                                cellSelector,
                                            );
                                            if (
                                                isMultipleCell(nextCellSelector)
                                            ) {
                                                return nextCellSelector;
                                            }
                                            nextCellSelector.cell =
                                                'JobPriority';
                                            return nextCellSelector;
                                        },
                                    );

                                    // 详情可点击
                                    makeObjColClickable(
                                        baiscCellsSelector,
                                        'follow_customer',
                                    );
                                    // 详情可点击
                                    makeObjColClickable(
                                        baiscCellsSelector,
                                        'customer_contact',
                                    );
                                    return baiscCellsSelector;
                                }}
                                widgetsTransformer={widgets => {
                                    const nextWidgets = [...widgets];
                                    applyCustomConfToWidget(
                                        nextWidgets,
                                        'activation_id',
                                        widget => {
                                            if (widget.objectOptions) {
                                                widget.objectOptions.paramsBlackList = {
                                                    id: false,
                                                };
                                                widget.objectOptions.noSkipParams = true;
                                                const constraintObj =
                                                    widget.objectOptions
                                                        ?.constraint
                                                        ?.constraintObj || {};
                                                constraintObj.objFindFieldIdList = [
                                                    'id',
                                                    'create_time',
                                                    'follow_type',
                                                    'note',
                                                ];
                                                widget.objectOptions.defaultFilters = [
                                                    {
                                                        fieldName:
                                                            'customer_id',
                                                        operateType: 'EQUAL',
                                                        fieldValue: followCusId,
                                                    },
                                                    {
                                                        fieldName:
                                                            'create_user',
                                                        operateType: 'EQUAL',
                                                        fieldValue: userId,
                                                    },
                                                    {
                                                        fieldName: 'task_id',
                                                        operateType: 'NULL',
                                                    },
                                                    {
                                                        fieldName:
                                                            'create_time',
                                                        operateType:
                                                            'GREATER_EQUAL',
                                                        fieldValue: moment(
                                                            startTime ||
                                                                Date.now(),
                                                        ).format(
                                                            'YYYY-MM-DD HH:mm:ss',
                                                        ),
                                                    },
                                                ];
                                            }
                                            return widget;
                                        },
                                    );
                                    return nextWidgets;
                                }}
                            />
                        </>
                    ),
                },
            ],
        },
    };

    const shouldAddFollowLog = !!followCusId;

    return (
        <CtxProvider
            ctx={{
                position: 'drawer',
                positionDetail: 'job-drawer',
            }}
        >
            <Modal
                visible={activationVis}
                onOk={() => {
                    setActivationVis(false);
                }}
                onCancel={() => {
                    setActivationVis(false);
                }}
                cancelButtonProps={{ style: { display: 'none' } }}
                title={'活动记录：' + activation?.id}
            >
                {activation?.current_name ? (
                    <div>
                        <b>客户名称：</b>
                        <span>{activation?.current_name || '--'}</span>
                    </div>
                ) : null}
                {activation?.follow_type ? (
                    <div>
                        <b>跟进方式：</b>
                        <span>
                            {followTypesMap[activation?.follow_type]?.label ||
                                '--'}
                        </span>
                    </div>
                ) : null}
                {activation?.follow_purpose ? (
                    <div>
                        <b>跟进目的：</b>
                        <span>
                            {followPurposesMap[activation?.follow_purpose]
                                ?.label || '--'}
                        </span>
                    </div>
                ) : null}
                {activation?.note ? (
                    <div>
                        <b>跟进记录：</b>
                        <span>{activation?.note || '--'}</span>
                    </div>
                ) : null}
                {activation?.contacts_name ? (
                    <div>
                        <b>联系人：</b>
                        <span>{activation?.contacts_name || '--'}</span>
                    </div>
                ) : null}
            </Modal>
            {shouldAddFollowLog && (
                <AddFollowLog
                    entityType={'lead'}
                    cid={followCusId}
                    id={followCusId}
                    hiddenTask={true}
                    drawerVis={followActionModalVis}
                    setDrawerVis={setFollowActionModalVis}
                    initMark={followActionInitMark}
                    withNextJobRadio={false}
                    onCreated={data => {
                        setFollowActionInitMark(true);
                        setTimeout(() => {
                            setFollowActionInitMark(false);
                        }, 0);
                    }}
                />
            )}
            <LogModal
                modalVis={logModalVis}
                setModalVis={setLogModalVis}
                entityCode="task_activation"
                entityValueId={detailId}
            />
            <CirculationLogModal
                modalVis={opLogModalVis}
                setModalVis={setOpLogModalVis}
                taskId={detailId}
            />
            <LeadActions defaultStore={defaultStoreAction} />
            <LeadJobFormDrawer
                defaultStore={defaultStoreAction}
                preRequiredReady={preRequiredReady}
            />
            <CommonDrawer
                destroy={destroyOnClose ? !drawerVis : false}
                loading={mutatingDataLoading}
                titleConfig={titleExampleConfig}
                DrawerContentConfig={{
                    zIndex: 10,
                    placement: 'right',
                    onClose: () => {
                        setDrawerVis(false);
                    },
                    visible: drawerVis,
                }}
                extraRight={<></>}
                DrawerCardListConfig={cardExampleConfig}
                DrawerTabsConfig={tabsExampleConfig}
            />
        </CtxProvider>
    );
};

export default observer(LeadDrawer);
