import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useToggle, useRequest } from 'ahooks';
import classname from 'classname';
import { Button } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { loadingWrapperOver } from '@/components';
import BoardCard from '../components/board-card';
import Selector from '../components/selector';
import OpportunityTable from './components/opportunity-table';
import OpportunityPie from './components/oppotunity-pie';
import OpportunityTrendCharts from './components/opportunity-trend-charts';
import CompositeLiveChartsComponents from './components/composite-live-charts';
import SwitchButton from '@/components/common/switch-button';
import BoardSwitch from '../components/board-switch';
import {
    // queryPreSolarization,
    queryOppSolarization,
    queryTrendAnalysis,
    // exportPreSolarization,
    exportOppSolarization,
    exportTrendAnalysis,
    queryWinRateRatio,
} from './api';
import { useAuthView } from '../hooks';

import './index.less';

import { HeaderColors } from '../components/charts-wrap/index';

const keyToNameMap = new Map([
    ['count20', '20商机个数'],
    ['count40', '40商机个数'],
    ['count60', '60商机个数'],
    ['count80', '80商机个数'],
    ['amount20', '20商机金额'],
    ['amount40', '40商机金额'],
    ['amount60', '60商机待回款金额'],
    ['amount80', '80商机待回款金额'],
]);

// const keyToColorMap = new Map([
//     ['华北', 'cyan' as HeaderColors],
//     ['华东', 'blue' as HeaderColors],
//     ['华南', 'orange' as HeaderColors],
// ]);

const keyToNamePieMap = new Map([
    ['20_count', '20赢率'],
    ['40_count', '40赢率'],
    ['60_count', '60赢率'],
    ['80_count', '80赢率'],
]);

// const typeToKeyMap = new Map([
//     [0, 'all'],
//     [1, 'new'],
//     [2, 'old'],
// ]);

enum TimeDimMap {
    'date',
    'month',
    'quarter',
    'session'

}

export const getDefaultOrganize = (isSaleAndGroup: boolean) => {
    if (isSaleAndGroup) {
        return {
            defaultOrganize: 3,
            defaultOrganizeOption: [
                { title: '展开到团队', value: 2 },
                { title: '展开到组员', value: 3 },
            ],
        };
    } else {
        return {
            defaultOrganize: 1,
            defaultOrganizeOption: [
                // { title: '展开到区域', value: 0 },
                { title: '展开到城市', value: 1 },
                { title: '展开到团队', value: 2 },
                { title: '展开到组员', value: 3 },
            ],
        };
    }
};

const CompositeOpportunityPage: React.FC = () => {
    const [orgId, setOrgId] = useState(63);

    const {
        data,
        loading,
        userRegionsLv2,
        userRegionsLv3,
        authViewWithComponent,
    } = useAuthView(orgId);
    console.log('authViewData', data, userRegionsLv2, userRegionsLv3);
    const isSaleAndGroup = data?.viewOrgType === '3';
    const { defaultOrganize, defaultOrganizeOption } = getDefaultOrganize(
        isSaleAndGroup,
    );

    const [range, { set }] = useToggle<'month', 'session'>('month', 'session');

    const [pieRange, { set: setPieRange }] = useToggle<'month', 'session'>(
        'month',
        // 'quarter',
        'session'
    );

    // 组织筛选
    const [organize, setOrganize] = useState(defaultOrganize);
    // 客户筛选
    const [customerType, setCustomerType] = useState(0);
    // console.log('organize', organize, data?.viewOrgType);
    useEffect(() => {
        let defaultOrganize: number = 0;
        switch (data?.viewOrgType) {
            case '6':
                defaultOrganize = 1;
                break;
            case '5':
                defaultOrganize = 1;
                break;
            case '4':
                defaultOrganize = 2;
                break;
            case '3':
                defaultOrganize = 3;
                break;
            default:
                defaultOrganize = 3;
                break;
        }
        setOrganize(defaultOrganize);
    }, [data]);
    const regionsLv2TrendWrap = useMemo(
        () =>
            (userRegionsLv2?.length || 1) >= 3
                ? 3
                : userRegionsLv2?.length || 1,
        [userRegionsLv2],
    );
    const regionsLv3TrendWrap = useMemo(
        () =>
            (userRegionsLv3?.length || 1) >= 3
                ? 3
                : userRegionsLv3?.length || 1,
        [userRegionsLv3],
    );

    const regionsPieWrap = useMemo(() => {
        const num =
            (userRegionsLv3?.length || 0) + (userRegionsLv3?.length || 0);
        return num >= 4 ? 4 : num;
    }, [userRegionsLv3]);
    // 预估数据通晒
    const { data: opportunityData, loading: opportunityLoading } = useRequest(
        () => queryOppSolarization(customerType, orgId),
        {
            refreshDeps: [customerType, orgId],
            cacheKey: 'customerType' + customerType + orgId,
            staleTime: 60000,
        },
    );
    // 空格：怎么 &nbsp; 没用啊

    console.log('opportunityData', opportunityData);

    // 预估数据通晒导出
    const {
        run: handleExportOppSolarization,
        loading: exportOppLoading,
    } = useRequest(() => exportOppSolarization(customerType, orgId), {
        refreshDeps: [customerType],
        manual: true,
    });

    console.log('timerdimmap', TimeDimMap['session'])
    // 趋势分析
    const { data: trendData, loading: trendLoading } = useRequest(
        () => queryTrendAnalysis(TimeDimMap[range], orgId),
        {
            refreshDeps: [range, orgId],
        },
    );
    console.log('trendData:', trendData);

    const { data: pieData, loading: pieLoading } = useRequest(
        () => queryWinRateRatio(TimeDimMap[pieRange], orgId),
        {
            refreshDeps: [pieRange, orgId],
        },
    );
    console.log('pieData:', pieData);

    const pieDataSource = useCallback(
        region => {
            const data = pieData?.[region] || {};
            const keys = Object.keys(data).filter((key: string) =>
                /^(\d)(0_count)$/.test(key),
            );
            const dataSource: any[] = [];
            keys.forEach((key: string) => {
                dataSource.push({
                    value: data[key],
                    name: keyToNamePieMap.get(key),
                    amount: data[`${key.slice(0, 2)}_amount`],
                    ratio: data[`${key.slice(0, 2)}_count_ratio`],
                });
            });
            return dataSource;
        },
        [pieData],
    );

    const trendDataSource = useCallback(
        region => {
            const data = trendData?.[region] || [];
            // console.log('trendDataSource', data);

            const labels =
                data?.length === 0
                    ? []
                    : Object.keys(data?.[0]).filter(
                        (key: any) => key !== 'date',
                    ) || [];

            const dataSource: any[] = [];
            labels?.forEach((label: string) => {
                dataSource.push({
                    label: keyToNameMap.get(label),
                    // 大概会在这里 filter 一下
                    data: data?.map((item: any) => {
                        return item[label];
                    }),
                });
            });
            return dataSource;
        },
        [trendData],
    );

    const chartsXAxis = useCallback(
        region => {
            return (
                trendData?.[region]?.map((item: any) => {
                    return moment(item.date).format('M.D');
                }) || []
            );
        },
        [trendData],
    );

    // 预估数据通晒数据源
    const opportunityDataSource = useMemo(() => {
        const dataSource = _.cloneDeep(opportunityData);
        const formatter = (obj: any) => {
            Object.keys(obj)
                .filter((key: any) => /^([a-z]+)_(\d+)_([a-zA-Z]+)$/.test(key))
                .forEach((key: any) => {
                    obj[key] = obj[key] + '_' + key;
                });
        };

        // dataSource?.[customerType].map
        return dataSource?.map((info: any) => {
            formatter(info);
            info.children = info.group_list;
            info.name = info.city_name;
            info.children?.map((group: any) => {
                formatter(group);
                group.name = group.group_name;
                group.city_name = info.city_name;
                group.children = group.sale_list;
                group.children?.map((sale: any) => {
                    formatter(sale);
                    sale.city_name = info.city_name;
                    sale.group_name = group.group_name;
                    sale.name = sale.sale_name;
                });
            });
            return info;
        });
        // [opportunityData,customerType]
    }, [opportunityData]);
    console.log('opportunityDataSource', opportunityDataSource);

    const expandedRowKeys = useMemo(() => {
        if (organize === 1) {
            return [];
        }
        if (organize === 2) {
            return opportunityData?.reduce((prev: any, cur: any) => {
                prev.push(cur.city_name);
                return prev;
            }, []);
        }
        if (organize === 3) {
            return opportunityData?.reduce((prev: any, cur: any) => {
                prev.push(cur?.city_name);
                cur?.group_list?.map((group: any) => {
                    prev.push(group?.group_name);
                });
                return prev;
            }, []);
        }
        return [];
    }, [opportunityData, organize]);

    const refreshTime = useMemo(() => {
        return moment().format('YYYY-MM-DD HH:mm:ss');
    }, []);

    return loadingWrapperOver(loading)(
        <div>
            <BoardSwitch onChange={setOrgId} />
            <BoardCard
                title="新增商机数据通晒"
                subtitle={
                    <span>
                        统计口径：{refreshTime} 实时数据，请及时刷新。
                        <a
                            href="https://maimai.feishu.cn/docx/RROxdJ90Jose7jxRYOhcuLmunlg"
                            target="_blank"
                        >
                            详细取数逻辑参见文档
                        </a>
                    </span>
                }
                filters={
                    <>
                        <Selector
                            label="组织筛选"
                            options={defaultOrganizeOption}
                            defaultValue={0}
                            value={organize}
                            onChange={selected => {
                                if (typeof selected === 'number') {
                                    setOrganize(selected);
                                }
                            }}
                        />
                        <Selector
                            label="客户筛选"
                            options={[
                                { title: '全部客户', value: 0 },
                                { title: '新客户', value: 1 },
                                { title: '老客户', value: 2 },
                            ]}
                            defaultValue={0}
                            value={customerType}
                            onChange={selected => {
                                if (typeof selected === 'number') {
                                    setCustomerType(selected);
                                }
                            }}
                        />
                    </>
                }
                extra={
                    <Button
                        className="board-button"
                        loading={exportOppLoading}
                        onClick={handleExportOppSolarization}
                    >
                        导出
                    </Button>
                }
            >
                <OpportunityTable
                    orgId={orgId}
                    customerType={customerType}
                    dataSource={opportunityDataSource}
                    loading={opportunityLoading}
                    expandedRowKeys={expandedRowKeys}
                    expandIcon={() => <span />}
                />
            </BoardCard>

            {!isSaleAndGroup && (
                <>
                    <BoardCard
                        title="新增商机趋势图"
                        subtitle={`统计口径：非实时数据，是截止到昨日24时的单日总新增商机数量，含新客和老客，在数据项全部为0的状态下则不进行展示。`}
                        extra={
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <SwitchButton
                                    loading={trendLoading}
                                    buttonOptions={[
                                        { value: 'month', name: '月度' },
                                        { value: 'session', name: '双月' },
                                    ]}
                                    value={range}
                                    onChange={set}
                                />
                            </div>
                        }
                    >
                        {authViewWithComponent('全国')(
                            <div className="opportunity-card-charts-wrap">
                                <OpportunityTrendCharts
                                    chartsClassName="opportunity-card-charts-content"
                                    loading={trendLoading}
                                    title="全国商机趋势图"
                                    showDays={15}
                                    xAxis={chartsXAxis('全国')}
                                    dataSource={trendDataSource('全国')}
                                />
                            </div>,
                        )}
                        {/* {authViewWithComponent('华北,华东,华南,华中')(
                            <div className="opportunity-card-charts-wrap">
                                {authViewWithComponent('华北')(
                                    <OpportunityTrendCharts
                                        chartsClassName="opportunity-card-charts-content"
                                        // loading={false}
                                        loading={trendLoading}
                                        color="cyan"
                                        title="华北"
                                        showDays={Math.floor(
                                            15 / (userRegionsLv2?.length || 1),
                                        )}
                                        xAxis={chartsXAxis('华北')}
                                        dataSource={trendDataSource('华北')}
                                        // xAxis={tempXAxis()}
                                        // dataSource={tempDataSource()}
                                    />,
                                )}
                                {authViewWithComponent('华东')(
                                    <OpportunityTrendCharts
                                        chartsClassName="opportunity-card-charts-content"
                                        loading={trendLoading}
                                        color="blue"
                                        title="华东"
                                        showDays={Math.floor(
                                            15 / (userRegionsLv2?.length || 1),
                                        )}
                                        xAxis={chartsXAxis('华东')}
                                        dataSource={trendDataSource('华东')}
                                    />,
                                )}
                                {authViewWithComponent('华南')(
                                    <OpportunityTrendCharts
                                        chartsClassName="opportunity-card-charts-content"
                                        loading={trendLoading}
                                        color="orange"
                                        title="华南"
                                        showDays={Math.floor(
                                            15 / (userRegionsLv2?.length || 1),
                                        )}
                                        xAxis={chartsXAxis('华南')}
                                        dataSource={trendDataSource('华南')}
                                    />,
                                )}
                            </div>,
                        )} */}

                        <div className="opportunity-card-charts-wrap wrap">

                            {authViewWithComponent('北京')(
                                <OpportunityTrendCharts
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsLv3TrendWrap}`}
                                    loading={trendLoading}
                                    title="北京"
                                    showDays={Math.floor(
                                        15 / regionsLv3TrendWrap,
                                    )}
                                    xAxis={chartsXAxis('北京')}
                                    dataSource={trendDataSource('北京')}
                                />,
                            )}
                            {authViewWithComponent('上海')(
                                <OpportunityTrendCharts
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsLv3TrendWrap}`}
                                    loading={trendLoading}
                                    title="上海"
                                    showDays={Math.floor(
                                        15 / regionsLv3TrendWrap,
                                    )}
                                    xAxis={chartsXAxis('上海')}
                                    dataSource={trendDataSource('上海')}
                                />,
                            )}
                            {authViewWithComponent('杭州')(
                                <OpportunityTrendCharts
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsLv3TrendWrap}`}
                                    loading={trendLoading}
                                    title="杭州"
                                    showDays={Math.floor(
                                        15 / regionsLv3TrendWrap,
                                    )}
                                    xAxis={chartsXAxis('杭州')}
                                    dataSource={trendDataSource('杭州')}
                                />,
                            )}
                            {authViewWithComponent('广州')(
                                <OpportunityTrendCharts
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsLv3TrendWrap}`}
                                    loading={trendLoading}
                                    title="广州"
                                    showDays={Math.floor(
                                        15 / regionsLv3TrendWrap,
                                    )}
                                    xAxis={chartsXAxis('广州')}
                                    dataSource={trendDataSource('广州')}
                                />,
                            )}
                            {authViewWithComponent('深圳')(
                                <OpportunityTrendCharts
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsLv3TrendWrap}`}
                                    loading={trendLoading}
                                    title="深圳"
                                    showDays={Math.floor(
                                        15 / regionsLv3TrendWrap,
                                    )}
                                    xAxis={chartsXAxis('深圳')}
                                    dataSource={trendDataSource('深圳')}
                                />,
                            )}
                            {authViewWithComponent('成都')(
                                chartsXAxis('成都').length > 0 && (
                                    <OpportunityTrendCharts
                                        chartsClassName={`opportunity-card-charts-content wrap${regionsLv3TrendWrap}`}
                                        loading={trendLoading}
                                        title="成都"
                                        xAxis={chartsXAxis('成都')}
                                        dataSource={trendDataSource('成都')}
                                    />
                                ),
                            )}
                        </div>
                        {/* <div className="opportunity-card-charts-wrap">
                    {authViewWithComponent('广州')(
                        <OpportunityTrendCharts
                            chartsClassName="opportunity-card-charts-content"
                            loading={trendLoading}
                            title="广州"
                            xAxis={chartsXAxis('广州')}
                            dataSource={trendDataSource('广州')}
                        />,
                    )}
                    {authViewWithComponent('深圳')(
                        <OpportunityTrendCharts
                            chartsClassName="opportunity-card-charts-content"
                            loading={trendLoading}
                            title="深圳"
                            xAxis={chartsXAxis('深圳')}
                            dataSource={trendDataSource('深圳')}
                        />,
                    )}
                    {authViewWithComponent('成都')(
                        <OpportunityTrendCharts
                            chartsClassName="opportunity-card-charts-content"
                            loading={trendLoading}
                            title="成都"
                            xAxis={chartsXAxis('成都')}
                            dataSource={trendDataSource('成都')}
                        />,
                    )}
                </div> */}
                        {/* <div className="opportunity-charts-row pre">
                            <CompositeLiveChartsComponents
                                chartsClassName="opportunity-charts-content"
                                containerClassName="opportunity-charts-pre"
                                loading={false}
                                chartsOptions={{}}
                                showPre={true}
                                title={'新客商机趋势图'}
                            />
                            <CompositeLiveChartsComponents
                                chartsClassName="opportunity-charts-content"
                                containerClassName="opportunity-charts-pre"
                                loading={false}
                                chartsOptions={{}}
                                showPre={true}
                                title={'老客商机趋势图'}
                            />
                        </div> */}
                    </BoardCard>

                    <BoardCard
                        title="在库商机赢率分布"
                        subtitle={`统计口径：${refreshTime} 实时数据，请及时刷新。以商机的预计回款时间来看对应周期内的商机赢率分布，含新客和老客。`}
                        extra={
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <SwitchButton
                                    loading={pieLoading}
                                    buttonOptions={[
                                        { value: 'month', name: '月度' },
                                        { value: 'session', name: '双月' },
                                    ]}
                                    value={pieRange}
                                    onChange={setPieRange}
                                />
                            </div>
                        }
                    >
                        <div className="opportunity-card-charts-wrap wrap">
                            {authViewWithComponent('全国')(
                                <OpportunityPie
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsPieWrap} height270`}
                                    loading={pieLoading}
                                    title={`全国: ${pieData?.['全国']
                                        ?.all_count || 0}个商机  ${(
                                            pieData?.['全国']?.all_amount || 0
                                        ).toFixed(2)}w`}
                                    xAxis={[]}
                                    dataSource={pieDataSource('全国')}
                                />,
                            )}
                            {/* {authViewWithComponent('华北')(
                                <OpportunityPie
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsPieWrap} height270`}
                                    loading={pieLoading}
                                    color="cyan"
                                    title={`华北: ${pieData?.['华北']
                                        ?.all_count || 0}个商机  ${(
                                        pieData?.['华北']?.all_amount || 0
                                    ).toFixed(2)}w`}
                                    xAxis={[]}
                                    dataSource={pieDataSource('华北')}
                                />,
                            )}
                            {authViewWithComponent('华东')(
                                <OpportunityPie
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsPieWrap} height270`}
                                    loading={pieLoading}
                                    color="blue"
                                    title={`华东: ${pieData?.['华东']
                                        ?.all_count || 0}个商机  ${(
                                        pieData?.['华东']?.all_amount || 0
                                    ).toFixed(2)}w`}
                                    xAxis={[]}
                                    dataSource={pieDataSource('华东')}
                                />,
                            )}
                            {authViewWithComponent('华南')(
                                <OpportunityPie
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsPieWrap} height270`}
                                    loading={pieLoading}
                                    color="orange"
                                    title={`华南: ${pieData?.['华南']
                                        ?.all_count || 0}个商机  ${(
                                        pieData?.['华南']?.all_amount || 0
                                    ).toFixed(2)}w`}
                                    // title={'华南华南'}
                                    xAxis={[]}
                                    dataSource={pieDataSource('华南')}
                                />,
                            )} */}
                            {authViewWithComponent('北京')(
                                <OpportunityPie
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsPieWrap} height270`}
                                    loading={pieLoading}
                                    title={`北京: ${pieData?.['北京']
                                        ?.all_count || 0}个商机  ${(
                                            pieData?.['北京']?.all_amount || 0
                                        ).toFixed(2)}w`}
                                    xAxis={[]}
                                    dataSource={pieDataSource('北京')}
                                />,
                            )}
                            {authViewWithComponent('上海')(
                                <OpportunityPie
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsPieWrap} height270`}
                                    loading={pieLoading}
                                    title={`上海: ${pieData?.['上海']
                                        ?.all_count || 0}个商机  ${(
                                            pieData?.['上海']?.all_amount || 0
                                        ).toFixed(2)}w`}
                                    xAxis={[]}
                                    dataSource={pieDataSource('上海')}
                                />,
                            )}
                            {authViewWithComponent('杭州')(
                                <OpportunityPie
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsPieWrap} height270`}
                                    loading={pieLoading}
                                    title={`杭州: ${pieData?.['杭州']
                                        ?.all_count || 0}个商机  ${(
                                            pieData?.['杭州']?.all_amount || 0
                                        ).toFixed(2)}w`}
                                    xAxis={[]}
                                    dataSource={pieDataSource('杭州')}
                                />,
                            )}
                            {authViewWithComponent('广州')(
                                <OpportunityPie
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsPieWrap} height270`}
                                    loading={pieLoading}
                                    title={`广州: ${pieData?.['广州']
                                        ?.all_count || 0}个商机  ${(
                                            pieData?.['广州']?.all_amount || 0
                                        ).toFixed(2)}w`}
                                    xAxis={[]}
                                    dataSource={pieDataSource('广州')}
                                />,
                            )}
                            {authViewWithComponent('深圳')(
                                <OpportunityPie
                                    chartsClassName={`opportunity-card-charts-content wrap${regionsPieWrap} height270`}
                                    loading={pieLoading}
                                    title={`深圳: ${pieData?.['深圳']
                                        ?.all_count || 0}个商机  ${(
                                            pieData?.['深圳']?.all_amount || 0
                                        ).toFixed(2)}w`}
                                    xAxis={[]}
                                    dataSource={pieDataSource('深圳')}
                                />,
                            )}
                            {authViewWithComponent('成都')(
                                orgId === 64 && (
                                    <OpportunityPie
                                        chartsClassName={`opportunity-card-charts-content wrap${regionsPieWrap} height270`}
                                        loading={pieLoading}
                                        title={`成都: ${pieData?.['成都']
                                            ?.all_count || 0}个商机  ${(
                                                pieData?.['成都']?.all_amount || 0
                                            ).toFixed(2)}w`}
                                        xAxis={[]}
                                        dataSource={pieDataSource('成都')}
                                    />
                                ),
                            )}
                        </div>
                    </BoardCard>
                </>
            )}
        </div>,
    );
};

export default CompositeOpportunityPage;
