import React, { useMemo, useState } from 'react';

export const useShowMore = ({
    showCount,
    data,
    target,
}: {
    showCount: number;
    data: any[];
    target: (showAll: boolean) => JSX.Element;
}) => {
    const allCount = useMemo(() => data.length, [data]);
    const [showAll, setShowAll] = useState(false);
    const returnData = useMemo(() => {
        return data.slice(0, showAll ? allCount : showCount);
    }, [showAll, data, showCount, allCount]);
    const returnTarget = useMemo(() => {
        return allCount > showCount ? (
            <div
                className="show-all-wrap-div"
                onClick={() => {
                    setShowAll(!showAll);
                }}
            >
                {target(showAll)}
            </div>
        ) : null;
    }, [showAll, allCount, showCount]);

    return { returnData, returnTarget };
};
