/**
 * mix config with net common lib
 */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';
import { withCancel, enableAjaxBar } from './net-common';
import { message } from 'antd';
import axiosRetry from 'axios-retry';
import { getGqlEndpoint } from '@/configs/gql';
import { goToLogin } from './helpers';
import { navigate } from '@reach/router';
// import { getAuthStore } from '@/stores/auth';

const placeHolderInstance = axios.create();
export const net: {
    defaultInstance: AxiosInstance;
} = {
    defaultInstance: placeHolderInstance,
};

export const configNet = (
    isWithAjaxBar: boolean,
    defaultAjaxOptions: { [key: string]: any },
    defaultAxiosConfig: AxiosRequestConfig,
    tokenKey?: string,
) => {
    const defaultInstance = axios.create(defaultAxiosConfig);
    // 可被取消 tofix, 这是一个不好的cancel设计, 暂时先不要了
    // 经过实践发现其实cancel场景并不是一个非常普遍的需求，后续可以改成主动式，而不是自动式。说人话：加个参数控制
    // if (!_.isNil(defaultAjaxOptions['withCancel'])) {
    //     withCancel(defaultInstance, defaultAjaxOptions['withCancel']);
    // }

    // 重试
    if (!_.isNil(defaultAjaxOptions['withRetry'])) {
        const { retryTimes, retryDelay } = defaultAjaxOptions['withRetry'];
        axiosRetry(defaultInstance, {
            retries: Number(retryTimes) || 0,
            retryDelay: () => retryDelay,
        });
    }

    // 顶部ajaxbar
    if (isWithAjaxBar) {
        enableAjaxBar();
    }

    if (!_.isNil(tokenKey)) {
        defaultInstance.interceptors.request.use(
            (config: AxiosRequestConfig) => {
                const token = window.localStorage.getItem(tokenKey) || 'xxx';
                config.headers['Authorization'] = 'Bearer ' + token;
                config.headers['Cache-Control'] = 'no-cache';
                return config;
            },
        );
        defaultInstance.interceptors.response.use(undefined, err => {
            if (err.response) {
                const res = err.response as AxiosResponse;
                if (res.status === 401) {
                    // 捕获401直接送去登录
                    goToLogin();
                }

                if (res.status === 403) {
                    // 捕获401直接送去登录
                    // const authStore = getAuthStore();
                    // authStore.defaultMenuPermStore.setOrignalPerm([]);
                    navigate('/status/403');
                }
            }
            return Promise.reject(err);
        });
    }

    net.defaultInstance = defaultInstance;
};

export { enableAjaxBar, silentAjaxBar } from './net-common/ajax-bar';

export const rawAxios = () => net.defaultInstance;

type MyAxiosRequestConfig = AxiosRequestConfig & { silent?: boolean };
const callWithFlattenAxiosError = (
    aInstance: AxiosInstance,
    config: MyAxiosRequestConfig,
) => {
    const silent = _.isNil(config.silent) ? false : config.silent;

    if (config.method && config.method.toLowerCase() === 'get') {
        if (config.params) {
            config.params.____ha_sh = Date.now();
        } else {
            config.params = { ____ha_sh: Date.now() };
        }
    }

    return aInstance
        .request(config)
        .then((res: AxiosResponse) => {
            if (_.isNil(res)) {
                return [null, null];
            }
            try {
                const code = res.data.code === undefined ? 0 : res.data.code;
                if (code + '' !== '0') {
                    !silent && res.data?.msg && message.error(res.data.msg);
                    return [null, new Error(res.data.msg)];
                }
                return [res.data, null];
            } catch (e) {
                return [null, e];
            }
        })
        .catch(error => {
            let msg = 'Unknow Net Error';
            if (error.response) {
                const res = error.response;
                const serverMsg = res?.data?.msg;
                !silent && !!serverMsg && message.error(serverMsg);
                msg = serverMsg || error.response.statusText;
            } else if (error.message) {
                msg = error.message;
            }

            if (msg === 'canceled') {
                return [null, null];
            }
            console.warn('Net error: ', msg);
            return [null, new Error(msg)];
        });
};

export const defaultAxios = {
    get: (url: string, data?: object, config?: MyAxiosRequestConfig) =>
        callWithFlattenAxiosError(net.defaultInstance, {
            ...config,
            method: 'get',
            url,
            params: data,
        }),
    post: (url: string, data?: object, config?: MyAxiosRequestConfig) =>
        callWithFlattenAxiosError(net.defaultInstance, {
            ...config,
            method: 'post',
            url,
            data,
        }),
    put: (url: string, data?: object, config?: MyAxiosRequestConfig) =>
        callWithFlattenAxiosError(net.defaultInstance, {
            ...config,
            method: 'put',
            url,
            data,
        }),
    delete: (url: string, data?: object, config?: MyAxiosRequestConfig) =>
        callWithFlattenAxiosError(net.defaultInstance, {
            ...config,
            method: 'delete',
            url,
            data,
        }),
    gql: async (data?: object, config?: MyAxiosRequestConfig) => {
        return defaultAxios.post(getGqlEndpoint(), data, config);
    },
};

export const isNetSuccess = (payload: any, error: any) => {
    return !_.isNil(payload) && _.isNil(error);
};

export type IDefaultAxios = typeof defaultAxios;
