import { defaultAxios, isNetSuccess } from '@/utils';
import axios from 'axios';
import { transparentQuery, commonQueryJob } from '@/api/rest';

interface actionLogMetaItem {
    label: string;
    value: string | number;
    selected: boolean;
}

export interface IActionLogConfig {
    arType: actionLogMetaItem[];
    followType: actionLogMetaItem[];
    followTarget: actionLogMetaItem[];
    taskFollowTarget: actionLogMetaItem[];
    outerNetReleaseJob: actionLogMetaItem[];
    futureCusOneYear: actionLogMetaItem[];
    dutyPeriod: actionLogMetaItem[];
    positionType: actionLogMetaItem[];
    salaryRange: actionLogMetaItem[];
    isBuyAct: actionLogMetaItem[];
    isHeadhuntingWork: actionLogMetaItem[];
    maimaiInterest: actionLogMetaItem[];
    relateResults: actionLogMetaItem[];
    isHistoryDataNormal: actionLogMetaItem[];
    companyAndPlatform: actionLogMetaItem[];
    cusRenewalRisk: actionLogMetaItem[];
    isDecision: actionLogMetaItem[];
    demandIntention: actionLogMetaItem[];
}

// 基础配置
export const fetchActionLogConfig: () => Promise<
    IActionLogConfig
> = async () => {
    const [d, e] = await defaultAxios.get(
        `/bff/api/rest/customer/action-log-config`,
    );
    if (d === null || d.data === null) {
        return null;
    }
    return d.data;
};

type TGetFollowLogContactListReturnType = {
    id: number;
    name: string;
    phone: string;
    isDecision?: number;
}[];

// 客户-联系人列表
export const fetchContactPersonList: (
    id?: number,
) => Promise<TGetFollowLogContactListReturnType> = async id => {
    if (!id) {
        return null;
    }
    const [d, e] = await defaultAxios.get(
        `/bff/api/rest/customer/follow-person-list`,
        {
            id,
            arType: 3,
        },
    );
    if (d === null || d.data === null) {
        return null;
    }
    return d.data;
};

// 获取最新客户信息
export const fetchCustomerInfo: (
    customerId?: number,
) => Promise<any> = async customerId => {
    if (!customerId) return null;
    const [d, e] = await defaultAxios.get(`/crm/activationRecord/cusInfo/`, {
        customerId,
    });
    if (d === null || d.data === null) {
        return null;
    }
    return d.data;
};

interface GetCustomerDetailParams {
    id?: number;
    fieldAuthCode: string;
    dataAuthCode: string;
}

//获取客户详情信息
export const fetchCustomerDetail: ({
    id,
    fieldAuthCode,
    dataAuthCode,
}: GetCustomerDetailParams) => Promise<any> = async ({
    id,
    fieldAuthCode,
    dataAuthCode,
}) => {
    if (!id) return null;
    const [d, e] = await defaultAxios.get(`/bff/api/rest/customer/detail`, {
        id,
        fieldAuthCode,
        dataAuthCode,
    });
    if (d === null || d.data === null) {
        return null;
    }

    const originData = d.data?.originData ? d.data?.originData[0] || {} : {};

    return originData;
};

export const uploadFile = async (file: File) => {
    const formdata = new FormData();
    formdata.append('file', file);

    const { data } = await axios({
        url: '/bff/api/rest/upload',
        method: 'post',
        responseType: 'multipart/form-data;charset=UTF-8',
        data: formdata,
    });

    return data;
};

// 客户列表
export const fetchCustomerPersonList: (
    customerSearchText?: string,
) => Promise<
    {
        id: number;
        customer_name: string;
    }[]
> = async customerSearchText => {
    const [d, e] = await transparentQuery(
        {
            dataAuthCode: 'SEARCH-PRIVATE',
            fieldAuthCode: 'PRIVATE-VIEW',
            pageNum: 1,
            pageSize: 50,
            selectiveFields: ['id', 'customer_name'],
            fieldQueries: customerSearchText
                ? [
                      {
                          fieldName: 'customer_name',
                          fieldValue: customerSearchText,
                          operateType: 'SEARCH',
                      },
                  ]
                : undefined,
        },
        'customer',
    );
    return d;
};

export const fetchOpportunityData = async (
    customerId?: string | number,
    opportunityId?: string | number,
) => {
    if (!customerId) return null;
    const fieldQueries = [
        {
            fieldName: 'customer_id',
            operateType: 'EQUAL',
            fieldValue: customerId,
        },
    ];
    if (opportunityId) {
        fieldQueries.push({
            fieldName: 'id',
            operateType: 'EQUAL',
            fieldValue: Number(opportunityId),
        });
    } else {
        fieldQueries.push({
            fieldName: 'win_rate',
            operateType: 'NOT_EQUAL',
            fieldValue: 0,
        });
    }

    const payload = {
        pageNum: 1,
        pageSize: 100,
        fieldQueries: fieldQueries,
        selectiveFields: [
            'id',
            'opportunity_name',
            'customer_id',
            'prediction_pay_back_time',
            'opportunity_sale_stage',
            'owner_id',
        ],
        orderBy: 'prediction_pay_back_time',
        isDesc: true,
    };
    const [d, e] = await transparentQuery(payload, 'opportunity');
    return d;
};

// 活动记录-后端参数
export interface IFollowLogDevSaveData {
    arType?: string | number; // 记录类型
    followType?: string; // 跟进方式
    note?: string; // 记录详情
    nextDate?: any; // 跟进计划
    nextNote?: string; // 下次跟进计划
    followTarget?: number; // 跟进目的
    scheduleNote?: string; // 备忘事宜
    scheduleDate?: any; // 备忘时间
    customerId?: string | number; // 客户id
    leadId?: string | number; // 线索id
    opportunityId?: string | number; // 商机id
    contactsId?: number | string; // 联系人
    taskId?: number | string; // 关联任务
    image?: string; // 图片
    propulsionState?: string;
    callrecordId?: number | string;
}

export const addActionLogNet = async (params: IFollowLogDevSaveData) => {
    const [d, e] = await defaultAxios.post(
        `/bff/api/rest/customer/add-action-log`,
        {
            ...params,
        },
    );
    if (d === null || d.data === null) {
        return null;
    }
    return d.data;
};

// 客户-任务列表
export const fetchActionLogTaskList = async (cid?: string | number) => {
    if (!cid) return null;
    const [d, e] = await commonQueryJob(
        'task_activation',
        {
            pageSize: 30,
            pageNum: 1,
        },
        {
            filters: [
                {
                    fieldId: 'follow_customer',
                    filterValue: cid,
                    operateType: '=',
                },
                {
                    fieldId: 'task_state',
                    filterValue: '2,3',
                    operateType: 'in',
                },
            ],
        },
        {
            orderBy: 'end_time',
            // sortStrategy: 0, // 升
        },
        'PUBLIC-VIEW',
        undefined,
        'SEARCH-PUBLIC',
    );
    if (isNetSuccess(d, e) && d?.datas) {
        return d?.datas;
    }
    return null;
};
