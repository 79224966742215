import { StoreContext } from '@/stores';
import { Select, Spin } from 'antd';
import React, { useContext, useMemo } from 'react';
import DataCard from './components/DataCard';
import EnterCard from './components/EnterCard';
import { useRequest } from 'ahooks';
import { getTodayTeamDate } from './api';
import './index.less';
const Key2Option: {
    [key: string]: {
        header: string;
        unit: string;
    };
} = {
    conservativePredict: {
        header: '保守预估',
        unit: 'w',
    },
    increasesOpportunityCount: {
        header: '新建商机',
        unit: '个',
    },
    actionCount: {
        header: '销售行为',
        unit: '个',
    },
};

const HomeTlWorkTable: React.FC = () => {
    const store = useContext(StoreContext);
    const homeNewStore = store.getDefaultHomeNewStore();
    const { homeNewDataRefreshTime } = homeNewStore;
    const orgId = useMemo(() => {
        return homeNewStore.homeNewSelectedData.selectedLevel === 4
            ? ''
            : homeNewStore.homeNewSelectedData.selectedId || '';
    }, [
        homeNewStore.homeNewSelectedData.selectedLevel,
        homeNewStore.homeNewSelectedData.selectedId,
    ]);

    //
    const { data: todayData, loading: todayDataLoading } = useRequest(
        () => getTodayTeamDate(orgId),
        {
            refreshDeps: [orgId],
        },
    );

    return (
        <div className="new-home-tl-worktable-content achievement-situation-content">
            <Spin spinning={todayDataLoading}>
                <div className="a-s-body">
                    <div className="a-s-f-header">
                        <div className="a-s-f-left">
                            <span className="a-s-f-title">
                                业绩目标达成情况
                            </span>
                            <span className="a-s-f-time">
                                （更新时间：
                                {homeNewDataRefreshTime
                                    ? homeNewDataRefreshTime.format(
                                          'YYYY-MM-DD HH:mm:ss',
                                      )
                                    : '- -'}
                                ）
                            </span>
                        </div>
                    </div>
                    <div className="t-w-c-body">
                        {Object.keys(Key2Option).map(key => {
                            const { header, unit } = Key2Option[key];
                            const { total = 0, newCus = 0, oldCus = 0 } =
                                todayData?.[key] || {};
                            return (
                                <DataCard
                                    key={key}
                                    header={header}
                                    total={total}
                                    unit={unit}
                                    oldCount={oldCus}
                                    newCount={newCus}
                                />
                            );
                        })}
                        <EnterCard url={`/tl-worktable?orgId=${orgId}`} />
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default HomeTlWorkTable;
