import React, { useEffect } from 'react';
import _ from 'lodash';
import { runInAction } from 'mobx';
import OptDrawer from './index';
import OptFormDrawer from '../components/form-drawer';
import { useAnyOptStore, OptListStore } from '../store';
import ParseTrans from '../components/parse-trans';

export const useOptDrawerSnippets = (options: {
    drawerVis: boolean;
    setDrawerVis: any;
    itemId: any;
    type?: string;
    optList?: OptListStore;
}) => {
    const { drawerVis, setDrawerVis, itemId, type = 'all' } = options;
    const { optList: inputOptList } = options;

    const [prepared, optList] = useAnyOptStore({ autoLoad: true });

    useEffect(() => {
        optList.setListType(type);
    }, [type]);

    useEffect(() => {
        if (!prepared || !itemId) {
            return;
        }
        optList.setMutatingData(null);
        optList.fetchInstantlyMutatingDataById(itemId);
    }, [prepared, itemId]);

    if (!prepared) {
        return [optList, null];
    }
    return [
        optList,
        <>
            <OptFormDrawer
                defaultStore={optList}
                // 如果是从这里进来的话，不知道如何选择type
                preRequiredReady={prepared}
                keepData={true}
            />
            <OptDrawer
                optParseTrans={
                    <ParseTrans
                        drawerVis={drawerVis}
                        defaultStore={optList}
                        key={itemId + '-parse-trans'}
                        prepared={prepared}
                    />
                }
                optListStore={optList}
                drawerVis={drawerVis}
                setDrawerVis={setDrawerVis}
                itemId={itemId}
                // destroyOnClose={true}
                onEdit={data => {
                    runInAction(() => {
                        optList && optList.setAction('update', { id: data.id });
                    });
                }}
            />
        </>,
    ];
};
