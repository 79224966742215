// AGREE
// ADD_APPROVE

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Input, message, Form } from 'antd';
import { rejects, btnMap } from './conf';
import './action.scss';
import _ from 'lodash';
import { ProcessCatStore } from '../../store';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const DeleteAction: React.FC<{
    currentAction: string;
    data: any;
    onOk: (extra?: any) => void;
    onCancel: () => void;
    defaultStore: ProcessCatStore | null;
    loading: boolean;
}> = observer(
    ({ currentAction, data, onOk, onCancel, defaultStore, loading }) => {
        if (!defaultStore?.defaultMeta) {
            return null;
        }
        const title = btnMap[currentAction] || '';
        const comment = data?.comment || '';
        const visible = currentAction === 'DELETE' && data !== null;

        return (
            <Modal
                title={title}
                onOk={() => {
                    onOk();
                }}
                confirmLoading={loading}
                onCancel={onCancel}
                visible={visible}
                zIndex={10001}
            >
                <p>流程删除后无法恢复，请确认是否删除？</p>
            </Modal>
        );
    },
);
