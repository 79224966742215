import React, { useRef, useState } from 'react';
import { TableProps } from 'antd/lib/table';
import _ from 'lodash';
import { AntTable } from '@/components/antd';
import { useResizeEffect } from '@/utils/hooks/use-resize-effect';

import './index.scss';

export const TableWithAutoScroll: <T>(
    props: TableProps<T>,
) => React.ReactElement<TableProps<T>> | null = props => {
    // 自适应固定&固底
    // let scrollX = 0;
    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const rootRef = useRef<HTMLDivElement | null>(null);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    useResizeEffect(() => {
        const rootEle = rootRef.current;

        const tableHead = rootEle?.querySelector(
            '.ant-table-thead',
        ) as HTMLDivElement;

        const pagination = rootEle?.querySelector(
            '.ant-table-pagination.ant-pagination',
        ) as HTMLDivElement;

        if (rootEle && tableHead) {
            const nextScrollY =
                rootEle.offsetHeight -
                tableHead.offsetHeight -
                (pagination ? pagination.offsetHeight + 20 : 0) -
                9;
            setScrollY(nextScrollY);
            setScrollX(tableHead.offsetWidth);
        }
    }, wrapperRef);

    let finalScroll: any = { x: scrollX };
    if (props.scroll) {
        finalScroll = props.scroll;
    }
    if (scrollY) {
        finalScroll.y = scrollY;
    }

    const nextProps = {
        ...props,
        scroll: finalScroll,
    };

    return (
        <div ref={rootRef} style={{ width: '100%', height: '100%' }}>
            <div ref={wrapperRef} className={'auto-base-table'}>
                <AntTable {...nextProps} />
            </div>
        </div>
    );
};

export default TableWithAutoScroll;
