import React, { useState } from 'react';
import {
    AntTabs,
    IAntTabProps,
    IAntTabsConfig,
    AntCollapse,
} from '../../../antd/index';
import './index.scss';
import _ from 'lodash';

export interface IDrawerTabsProps {
    tabsContentConfig: IAntTabProps;
}
const DrawerTabs: React.FC<IDrawerTabsProps> = props => {
    const { tabsContentConfig } = props;
    const { tabConfig = [] } = tabsContentConfig;
    const LastTabConfig: IAntTabsConfig[] = _.map(
        tabConfig as any[],
        (item: IAntTabsConfig, idx) => {
            if (item.type === 'collapse') {
                item.content = <AntCollapse {...item.contentConfig} />;
            }
            return item as IAntTabsConfig;
        },
    ) as IAntTabsConfig[];
    console.log(LastTabConfig);
    const LastTabsContentConfig: IAntTabProps = {
        ...tabsContentConfig,
        tabConfig: LastTabConfig,
    };
    return <AntTabs {...LastTabsContentConfig} />;
};

export default DrawerTabs;
