import React, { useEffect, useState } from 'react';
import { CustomerListStore } from '@/pages/sales-follow-up/custom/list/store';
import { AntTable, AntCollapse } from '@/components/antd';
import { defaultAxios, isNetSuccess } from '@/utils';
import moment from 'moment';

const columns = [
    {
        title: '非标客户名称',
        dataIndex: 'notStandardCustomerName',
    },
    {
        title: '非标客户所有人',
        dataIndex: 'notStandardCustomerOwnerName',
    },
    {
        title: '操作时间',
        dataIndex: 'createTime',
        render: (v: any) => {
            return v ? moment(v).format('YYYY-MM-DD HH:mm:ss') : '';
        },
    },
    {
        title: '操作人',
        dataIndex: 'createUser',
    },
];

export const CusTransLog: React.FC<{
    defaultStore: CustomerListStore;
}> = ({ defaultStore }) => {
    const { mutatingData } = defaultStore;
    const [data, setData] = useState<any[] | null>(null);

    useEffect(() => {
        if (!mutatingData) {
            return;
        }
        const { id: cid } = mutatingData;
        if (!cid) {
            return;
        }
        defaultAxios
            .get('/crm/customer/findMergeRecord', {
                standardCustomerId: cid,
            })
            .then(([d, e]) => {
                if (isNetSuccess(d, e)) {
                    const ds = d?.data || [];
                    setData(ds);
                }
            });
    }, [mutatingData]);

    if (mutatingData === null) {
        return null;
    }

    return (
        <div style={{ marginTop: 6 }}>
            <AntCollapse
                collapseConfig={[
                    {
                        key: 'cus-trans-log',
                        header: <span>非标客户关联记录</span>,
                        content: !!data ? (
                            <AntTable columns={columns} dataSource={data} />
                        ) : null,
                    },
                ]}
                defaultActiveKey={['cus-trans-log']}
            />
        </div>
    );
};
