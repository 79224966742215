import { makeAutoObservable } from 'mobx';
import { createDefaultInstanceGetter } from '@/stores/utils';
export class MemberDataStore {
    constructor() {
        makeAutoObservable(this);
    }
}

export const getDefaultMemberDataStore = createDefaultInstanceGetter(
    MemberDataStore,
);
