import React, { useState, useContext, useEffect } from 'react';
import './index.scss';
import { LayoutStandardCrud } from '../../../components/layouts/standard-crud/index';
import SearchTree from '../../../components/common/search-tree/index';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { Spin, Icon, Modal, Input, message, Select } from 'antd';
import { toJS } from 'mobx';
import OPMTable from './opm-table';
import _ from 'lodash';
import { IDepartSettingInfoType } from '@/stores/setting';
import OPMDrawer from './opm-drawer';
import { IOPMTableData } from './store';
import UserSelector from '../../../components/common/user-selector/index';

const departLevelStaticData = [
    {
        label: '小组',
        value: 1,
    },
    {
        label: '城市',
        value: 2,
    },
    {
        label: '地域',
        value: 3,
    },
];
interface IOPMProps {}
const OPM: React.FC<IOPMProps> = () => {
    const store = useContext(StoreContext);
    const SettingStore = store.getSettingDefaultStore();
    const OPMStore = store.getDefaultOPMStore();
    const FRMStore = store.getDefaultFRMStore();
    const DRMStore = store.getDefaultDRMStore();

    const [departModalVis, setDepartModalVis] = useState(false); // 部门编辑/修改modal
    const [isEdit, setIsEdit] = useState(false); // 是否是编辑modal
    const [selectedDepart, setSelectedDepart] = useState<any>({}); // 部门编辑/修改时的目标值
    const [focusDepart, setFocusDepart] = useState<any>({}); // 部门被选中时的选中值
    const {
        departInfo,
        isDepartInfoLoading,
        customerInfo,
        isCustomerInfoLoading,
    } = SettingStore;
    const [complexDepartInfo, setComplexDepartInfo] = useState<any>([{}]);
    const [departInputValue, setDepartInputValue] = useState(''); // 部门修改/编辑input
    const [departAdminSelectValue, setDepartAdminSelectValue] = useState(''); /// 部门负责人
    const [orgAdminSelectValue, setOrgAdminSelectValue] = useState(''); // 部门管理员
    const [departLevel, setDepartLevel] = useState<number>(); // 部门层级
    const [selectedPerson, setSelectedPerson] = useState<IOPMTableData>(); // 人员修改/编辑选中的items
    const [personDrawerVis, setPersonDrawerVis] = useState(false); // 人员编辑/修改的抽屉vis
    useEffect(() => {
        const info = generateComplexDepartInfo(departInfo);
        if (info) {
            setComplexDepartInfo([info]);
        } else {
            setComplexDepartInfo([{}]);
        }
    }, [departInfo]);
    useEffect(() => {
        FRMStore.fetchFRMRoleListData();
        DRMStore.fetchDRMRoleList();
    }, []);
    const tableSelectedItemHandle = (item?: IOPMTableData) => {
        if (item) {
            setSelectedPerson({ ...item, managerId: String(item.managerId) });
        } else {
            setSelectedPerson(item);
        }
        setPersonDrawerVis(true);
    };
    const hidePersonDrawer = () => {
        setSelectedPerson(undefined);
        setPersonDrawerVis(false);
    };
    const generateComplexDepartInfo = (
        departInfo: IDepartSettingInfoType[],
    ) => {
        let map: any = {};
        const cloneDepartInfo = _.cloneDeep(departInfo);

        if (Array.isArray(cloneDepartInfo)) {
            map = cloneDepartInfo.reduce((res, v) => {
                res[v.id as string] = v;
                return res;
            }, {});
            const res = [];
            for (const item of cloneDepartInfo) {
                if (item.pId == 0) {
                    item.isRoot = true;
                    res.push(item);
                    continue;
                }
                if ((item.pId as string) in map) {
                    const parent = map[item.pId as string];
                    parent.nodeChildren = parent.nodeChildren || [];
                    parent.nodeChildren.push(item);
                }
            }
            return res[0];
        } else {
            return {};
        }
    };
    // 树搜索action触发
    const onTreeActionDispatch = (
        actionKey: string,
        data: any,
        pTitle: string,
    ) => {
        switch (actionKey) {
            case 'delete':
                Modal.confirm({
                    content: (
                        <span>
                            该操作将删除
                            {
                                <span style={{ fontWeight: 500 }}>
                                    「{data?.title}」
                                </span>
                            }
                            ，删除后部门数据将不能恢复，请确认
                        </span>
                    ),
                    onOk: async () => {
                        const [d, e] = await OPMStore.deleteDepart(
                            data?.id || '',
                        );
                        if (!d.msg) {
                            message.success('删除成功');
                            SettingStore.getDepartInfo();
                        } else {
                            message.error(d.msg || '删除失败');
                        }
                    },
                });
                break;
            case 'add':
                setIsEdit(false);
                setSelectedDepart({
                    ...data,
                    pTitle,
                });
                setDepartInputValue('');
                setDepartAdminSelectValue('');
                setOrgAdminSelectValue('');
                setDepartLevel(undefined);
                setDepartModalVis(true);
                break;
            case 'edit':
                setIsEdit(true);
                setSelectedDepart({
                    ...data,
                    pTitle,
                });
                setDepartInputValue(data.title);
                setDepartAdminSelectValue(data.area_admin);
                setOrgAdminSelectValue(data.org_admin_user);
                setDepartLevel(data.level);
                setDepartModalVis(true);
                break;
            default:
                break;
        }
    };
    const renderDepartModal = () => {
        console.log(isEdit, selectedDepart);
        const upText = isEdit
            ? selectedDepart?.pTitle || '无'
            : selectedDepart?.title || '无';
        return (
            <Modal
                title={`${isEdit ? '编辑' : '新增'}部门`}
                visible={departModalVis}
                destroyOnClose
                onCancel={() => {
                    setSelectedDepart({});
                    setIsEdit(false);
                    setDepartModalVis(false);
                }}
                onOk={async () => {
                    if (!_.trim(departInputValue)) {
                        message.info('部门名称不能为空');
                        return;
                    }
                    const [d, e] = await OPMStore.updateOrAddDepart({
                        parentDepartId: isEdit
                            ? selectedDepart?.pId
                            : selectedDepart?.id,
                        departName: departInputValue,
                        areaAdmin: departAdminSelectValue,
                        orgAdminUser: orgAdminSelectValue,
                        id: isEdit ? selectedDepart?.id : undefined,
                        level: departLevel,
                    });
                    if (d && !d.msg) {
                        message.success(`${isEdit ? '编辑' : '新增'}部门成功`);
                        setSelectedDepart({});
                        setIsEdit(false);
                        setDepartModalVis(false);
                        SettingStore.getDepartInfo();
                    } else {
                        message.error(
                            d.msg || `${isEdit ? '编辑' : '新增'}部门失败`,
                        );
                    }
                }}
            >
                <div className="opm-action-modal">
                    <p>上级部门：「{upText}」</p>
                    <div className="opm-action-input">
                        <span className="opm-action-title">部门名称：</span>
                        <Input
                            placeholder="请输入部门名称"
                            value={departInputValue}
                            onChange={e => {
                                setDepartInputValue(e.target.value);
                            }}
                        />
                    </div>
                    <div className="opm-action-input">
                        <span className="opm-action-title">区域负责人：</span>
                        <UserSelector
                            style={{ width: '300px' }}
                            name="area_admin"
                            value={
                                departAdminSelectValue
                                    ? String(departAdminSelectValue)
                                    : undefined
                            }
                            mode="default"
                            placeholder="请选择用户"
                            onChange={e => {
                                setDepartAdminSelectValue(e as string);
                            }}
                            loading={isCustomerInfoLoading}
                            userDocs={customerInfo as any}
                        />
                    </div>
                    <div className="opm-action-input">
                        <span className="opm-action-title">部门管理员：</span>
                        <UserSelector
                            style={{ width: '300px' }}
                            name="org_admin_user"
                            value={
                                orgAdminSelectValue
                                    ? String(orgAdminSelectValue)
                                    : undefined
                            }
                            mode="default"
                            placeholder="请选择用户"
                            onChange={e => {
                                setOrgAdminSelectValue(e as string);
                                // setDepartAdminSelectValue(e as string);
                            }}
                            loading={isCustomerInfoLoading}
                            userDocs={customerInfo as any}
                        />
                    </div>
                    <div className="opm-action-input">
                        <span className="opm-action-title">部门层级：</span>
                        <Select
                            style={{ width: '300px' }}
                            value={departLevel}
                            onChange={(e: any) => {
                                setDepartLevel(e as number);
                            }}
                            placeholder="请选择部门层级"
                        >
                            {_.map(departLevelStaticData, item => {
                                return (
                                    <Select.Option value={item.value}>
                                        {item.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
            </Modal>
        );
    };
    const showPersonTable = !_.isEmpty(focusDepart);
    return (
        <div className="opm-content">
            <OPMDrawer
                selectedData={selectedPerson}
                drawerVis={personDrawerVis}
                hideDrawer={hidePersonDrawer}
            />
            {renderDepartModal()}
            <div className="opm-title">组织成员管理</div>
            <div className="opm-body">
                <Spin spinning={isDepartInfoLoading}>
                    <div className="o-tree-content">
                        <SearchTree
                            showIcon={false}
                            treeNodeData={complexDepartInfo}
                            keyIndex="id"
                            onAction={onTreeActionDispatch}
                            autoHiddenRootAction={false}
                            onSelect={(selectedKeys, e) => {
                                if (e.selected) {
                                    setFocusDepart(e?.node?.props || {});
                                } else {
                                    setFocusDepart({});
                                }
                            }}
                            actionOption={[
                                {
                                    actionCn: <Icon type="plus" />,
                                    actionKey: 'add',
                                },
                                {
                                    actionCn: <Icon type="form" />,
                                    actionKey: 'edit',
                                    status: {
                                        disabled: record => {
                                            if (record.isRoot) {
                                                return true;
                                            }
                                            return false;
                                        },
                                    },
                                },
                                // {
                                //     actionCn: <Icon type="delete" />,
                                //     actionKey: 'delete',
                                //     status: {
                                //         disabled: record => {
                                //             if (!record.isLeaf) {
                                //                 return true;
                                //             }
                                //             return false;
                                //         },
                                //     },
                                // },
                            ]}
                            autoExpandDeep={2}
                        />
                    </div>
                </Spin>
                <div className="p-table-content">
                    {showPersonTable && (
                        <OPMTable
                            setSelectedTableItem={tableSelectedItemHandle}
                            selectedDepart={focusDepart}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
export default observer(OPM);
