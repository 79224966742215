import React, { useState, useEffect, useContext, useCallback } from 'react';
import _ from 'lodash';
import { runInAction } from 'mobx';
import { StoreContext } from '@/stores';
import CustomerDrawer from '@/components/common/customer-drawer';
import CustomerFormDrawer from '@/pages/sales-follow-up/custom/list/components/form-drawer';
import {
    CustomerListStore,
    useAnyCustomerStore,
} from '@/pages/sales-follow-up/custom/list/store';
import { CtxProvider } from '@/utils/context';
import { message } from 'antd';
import { defaultAxios, isNetSuccess } from '@/utils';

export const useCustomerDrawerSnippets = (options: {
    drawerVis: boolean;
    setDrawerVis: any;
    cid: any;
    type?: string;
    customerList?: CustomerListStore;
    autoLoad?: boolean;
}) => {
    const stores = useContext(StoreContext);
    const authStore = stores.getAuthStore();
    const highseaCustomerVisible =
        authStore.userInfo.highseaCustomerVisible || false;
    const mineCustomerVisible = authStore.userInfo.mineCustomerVisible || false;
    const { drawerVis, setDrawerVis, cid } = options;

    const [prepared, customerList, prepare] = useAnyCustomerStore({
        autoLoad: false,
    });

    const [type, setType] = useState<string | null>(null);
    const [innerVisible, setInnerVisible] = useState(false);

    // 如果可见，则跟随即可
    useEffect(() => {
        if (highseaCustomerVisible && mineCustomerVisible) {
            setInnerVisible(drawerVis);
        } else {
            if (!drawerVis) {
                setInnerVisible(drawerVis);
            }
        }
    }, [drawerVis]);

    useEffect(() => {
        if (false === drawerVis) {
            customerList.setMutatingData(null);
            setInnerVisible(false);
            setType(null);
            customerList.setListType('');
        }
    }, [drawerVis]);

    const [reqCount] = useState({ v: 0 });
    useEffect(() => {
        if (!cid || !drawerVis) {
            return;
        }

        // 判断一下
        reqCount.v = reqCount.v + 1;
        const count = reqCount.v;
        const closeLoading = message.loading('加载中...');
        defaultAxios
            .get('/bff/api/rest/customer/isCustomerHighsea', {
                id: cid,
                hash: Math.random(),
            })
            .then(([d, e]) => {
                closeLoading();
                if (reqCount.v !== count) {
                    return;
                }
                if (isNetSuccess(d, e)) {
                    if (_.isBoolean(d?.data)) {
                        setType(d?.data ? 'all' : 'mine');
                    } else {
                        message.error('isCustomerHighsea 调用异常!');
                    }

                    // 如果可见，则不判断公海什么的
                    if (highseaCustomerVisible && mineCustomerVisible) {
                        setInnerVisible(true);
                        return;
                    }

                    if (d.data === true && !highseaCustomerVisible) {
                        // 不可见且是在公海
                        message.error('您无权限查看此客户的详情');
                        setDrawerVis(false);
                        setInnerVisible(false);
                    } else if (d.data === false && !mineCustomerVisible) {
                        // 不可见且是在私池
                        message.error('您无权限查看此客户的详情');
                        setDrawerVis(false);
                        setInnerVisible(false);
                    } else {
                        setInnerVisible(true);
                    }
                } else {
                    message.error('isCustomerHighsea 调用异常');
                }
            });
    }, [cid, drawerVis]);

    useEffect(() => {
        if (type === null) {
            return;
        }
        customerList.setListType(type);
        prepare();
    }, [type]);

    if (!prepared || type === null) {
        return [customerList, null];
    }

    return [
        customerList,
        <>
            <CustomerFormDrawer customerList={customerList} type={type} />
            <CustomerDrawer
                customerList={customerList}
                drawerVis={drawerVis && innerVisible}
                setDrawerVis={setDrawerVis}
                cid={cid}
                type={type}
                destroyOnClose={true}
                onEdit={data => {
                    runInAction(() => {
                        customerList &&
                            customerList.setAction('update', { id: data.id });
                    });
                }}
            />
        </>,
    ];
};
