import React, { useEffect, useContext, useState } from 'react';
import {
    DForm,
    IProcessCtx,
    IDFormProps,
    applyCustomConfToWidget,
    AntTable,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CtxContext } from '@/utils/context';
import { StoreContext } from '@/stores';

import { IProcessComProps, IProcessComs } from './def';
import { widgets } from '@/pages/demo2/crud/filter-options';
import { getValidatorByParamMeta } from '@/stores/utils';
import { arrToJson, defaultAxios, isNetSuccess } from '@/utils';
import { commonQuery } from '@/api/rest';
import { seeContractProcess } from '@/pages/om/contract/list/store';
import { message, Icon, Alert } from 'antd';

const DemoForm: React.FC<IProcessComProps> = ({
    defaultStore,
    processCtx,
    tableCode,
    mode,
}) => {
    const ctx = useContext(CtxContext);
    const { position, positionDetail } = ctx;
    const isCreate = mode === 'create';
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const sysId = authStore?.userInfo?.sysUser?.id;

    const { defaultMeta } = defaultStore;
    const { mutatingData } = defaultStore;

    if (!defaultMeta || !mutatingData) {
        return null;
    }
    const { objectMetaMap } = defaultMeta;

    const baiscWidgets = defaultMeta.defaultWidgetsAllWithoutGroupCheck;

    const collapseLayout = defaultMeta.defaultFormCollapseLayout;
    const shouldGroup = collapseLayout.length > 0;

    const layout = new Array(Math.ceil(baiscWidgets.length / 2)).fill([24]);
    if (shouldGroup) {
        for (const colla of collapseLayout) {
            colla.layout = layout;
        }
    }

    if (processCtx === null || !processCtx.currentUser) {
        return null;
    }

    const props: IDFormProps = {
        processCtx,
        defaultStore: defaultStore as any,
        widgetsGenerator: store => {
            return store.defaultMeta.defaultWidgetsAllWithoutGroupCheck;
        },
    };

    if (shouldGroup) {
        props.options = {
            grouped: true,
        };
    } else {
        props.propsTransformer = props => {
            props.layout = layout;
            return props;
        };
    }

    if (tableCode === null) {
        return null;
    }

    props.widgetsTransformer = widgets => {
        applyCustomConfToWidget(widgets, 'customer_id', widget => {
            if (widget.objectOptions) {
                widget.objectOptions.dataAuthCode = 'SEARCH-PRIVATE';
            }
            return widget;
        });
        return widgets;
    };
    const form = <DForm {...props} />;
    return form;
};

export const ProcessCustomerRenameTable = observer(DemoForm);
