import { EventEmitter } from 'eventemitter3';
import { message } from 'antd';

const SocketURL = 'wss://cti.jixinyun.com.cn:9991/free';
const CompanyId = 'maimai';

interface InitContext {
    token: string;
    agent: string;
}

class BaiChuanCC extends EventEmitter {
    webSocket?: WebSocket;
    token?: string;
    agent?: string;
    agentStatusID?: string;
    // 上线标志（off：未上线，online：坐席注册:, on：坐席上线）
    onlineflag = 'off';

    // 开启标识
    socket_open = false;

    // 是否自动重连
    is_reonnect = true;
    // 重连次数
    reconnect_count = 9999;
    // 已发起重连次数
    reconnect_current = 1;
    // 重连timer
    reconnect_timer?: any;
    // 重连频率
    reconnect_interval = 3000;

    constructor() {
        super();
    }
    init(context: InitContext) {
        this.token = context.token;
        this.agent = context.agent;
    }

    connect() {
        if (this.socket_open) return Promise.resolve(true);

        const webSocket = new WebSocket(SocketURL);

        this.webSocket = webSocket;

        webSocket.onerror = event => {
            //异常处理
            console.log('webSocket_异常处理', event);
            message.error('百川服务器无响应！');
        };
        webSocket.onopen = event => {
            // 连接完成;
            console.log('webSocket_连接完成', event);
            this.socket_open = true;
            this.is_reonnect = true;
            // 上线
            this.online();
        };
        webSocket.onclose = event => {
            // 连接关闭;
            console.log('webSocket_连接关闭', event);
            this.socket_open = false;
            this.onlineflag = 'off';
        };
        webSocket.onmessage = event => {
            // 数据接受;
            console.log('webSocket_数据接受', event);
            this.receive(event);
        };

        // return new Promise((resolve, reject) => {
        //     this.once('doLoginOK', () => {
        //         resolve(true);
        //     });
        // });
        return new Promise((resolve, reject) => {
            this.once('agentCallbackLoginOK', () => {
                resolve(true);
            });
        });
    }

    // handleVisibilitychange() {
    //     console.log('visibilitychange', document.hidden);
    //     // 需要重新连接
    //     if (!document.hidden && !this.socket_open && this.is_reonnect) {
    //         this.reconnect_timer = setTimeout(() => {
    //             // 超过重连次数
    //             if (this.reconnect_current > this.reconnect_count) {
    //                 clearTimeout(this.reconnect_timer);
    //                 return;
    //             }

    //             // 记录重连次数
    //             this.reconnect_current++;
    //             this.reconnect();
    //         }, this.reconnect_interval);
    //     }
    // }

    private send(params: (string | number | undefined)[]) {
        if (!this.webSocket) {
            message.error('百川 SDK 未初始化');
            return;
        }
        const command = params.join('|');

        // 开启状态直接发送
        if (this.webSocket.readyState === this.webSocket.OPEN) {
            this.webSocket.send(command);

            // 正在开启状态，则等待1s后重新调用
        } else if (this.webSocket.readyState === this.webSocket.CONNECTING) {
            setTimeout(() => {
                this.send(params);
            }, 1000);
        }
    }

    // 接收消息
    private receive(message: MessageEvent) {
        const allState = message.data;

        if (allState) {
            const superArray = allState.split(/[\r\n]+/).filter(Boolean);

            if (superArray.length === 0) {
                superArray.push(allState);
            }

            const actions: { [key: string]: (msg: string) => void } = {
                doLoginOK: () => {
                    // 坐席注册成功
                    this.handleDoLoginOK();
                },
                HeartBeat: () => this.heartbeat(),
                AgentStatus: state => this.handleAgentStatus(state),
                AgentCallbackLoginOK: () => {
                    this.handleAgentCallbackLoginOK();
                },
                AgentLogoffOK: () => {
                    this.handleAgentLogout();
                },
                QueuePause: msg => {
                    /* QueuePause(msg) */
                },
                MeetMeJoin: msg => {
                    /* MeetMeJoin(msg) */
                },
                MeetMeLeave: msg => {
                    /* MeetMeLeave(msg) */
                },
                NewState: state => {
                    this.handleNewState(state);
                },
                Uniqueid: msg => {
                    /* Uniqueid(msg) */
                },
                Recid: () => {
                    /* updateState('Recid') */
                },
                phonebrphone: msg => {
                    /* phonebrphone(msg) */
                },
                CallUuid: state => {
                    this.handleCall(state);
                },
                CDRextend: msg => {
                    /* CDRextend(msg) */
                },
                '01': this.handleError,
                '02': this.handleError,
                '03': this.handleError,
                '04': this.handleError,
                '05': this.handleError,
                '06': this.handleError,
                '07': this.handleError,
                '08': this.handleError,
            };

            superArray.map((item: string) => {
                const stateArray = item.includes('|')
                    ? item.split('|')
                    : [item];

                actions[stateArray[0]] && actions[stateArray[0]](item);
            });
        }
    }
    private heartbeat() {
        this.send(['HeartBeat']);
    }

    private handleAgentLogout() {
        this.onlineflag = 'off';
        this.send(['doLoginOff']);
    }

    private close() {
        console.log('主动断开连接');
        this.is_reonnect = false;
    }
    private reconnect() {
        console.log('重新连接...');
        if (this.webSocket && this.socket_open) {
            this.webSocket.close();
        }
        this.connect();
    }

    private handleError(code: string) {
        const errorMessages: { [key: string]: string } = {
            '01': '请求失败！参数有误！',
            '02': '请求失败！此坐席已经在其他地方注册！请稍后再试!',
            '03': '请求失败！请先进行请求注册!',
            '04': '请求失败！无效坐席!',
            '05': '请求失败！百川token过期 请刷新页面!',
            '06': '请求失败！超出并发限制!',
            '07': '请求失败！分机不存在!',
            '08': '请求失败！分机已被占用!',
        };
        const msg = errorMessages[code];
        if (msg) {
            message.error(msg);
        }
    }

    private handleDoLoginOK() {
        this.emit('doLoginOK');
        this.onlineflag = 'online';
        this.send(['AgentStatus', this.agent]);
    }
    private handleAgentCallbackLoginOK() {
        this.emit('agentCallbackLoginOK');
        this.onlineflag = 'on';
    }

    // 上线
    online() {
        this.send([
            'doLogin_free',
            this.agent,
            this.token,
            this.agent,
            'sip',
            CompanyId,
        ]);
    }
    // 坐席登录
    agentLogin() {
        this.send([
            'AgentCallbackLogin',
            'agent-login',
            this.agent,
            this.agent,
        ]);
        return new Promise((resolve, reject) => {
            this.once('agentCallbackLoginOK', () => {
                resolve(true);
            });
        });
    }
    // 坐席退出
    agentLogoff(agent: string) {}
    // 坐席状态
    handleAgentStatus(state: string) {
        const stateArray = state.split('|');

        if (stateArray.length > 4 && this.agentStatusID !== stateArray[4]) {
            this.agentStatusID = stateArray[4];
            const pauseFlag = stateArray[1]; // true 表示暂停(小休) false 表示就绪
            const loginStatus = stateArray[2]; // 1 为登录，0 为未登录

            if (loginStatus === '1') {
                this.onlineflag = 'on';
                // updateState("上线成功");
            } else {
                this.agentLogin();
            }
        }
    }
    call(callNumber: string | number) {
        this.send([
            'MakeCall',
            0,
            this.agent,
            callNumber,
            0,
            callNumber,
            ,
            ,
            ,
            ,
            0,
        ]);

        return new Promise((resolve, reject) => {
            this.once('callUuid', ctx => {
                resolve(ctx);
            });
        });
    }

    handleCall(state: string) {
        const stateArray = state.split('|');
        if (stateArray.length > 0) {
            this.emit('callUuid', {
                agent: stateArray[1],
                calluuid: stateArray[2],
                dateTime: stateArray[7],
            });
        }
    }

    // 话机状态
    handleNewState(allState: string) {
        const stateArray = allState?.split('|') || [];
        const state = stateArray[2];
        const number = stateArray[3];

        console.log('NewState', state);
        const stateActionMap: { [key: string]: () => void } = {
            Ringing: () => {
                // 振铃
                this.emit('ringing');
            },
            Up: () => {
                // 通话接起
                if (number === '1') {
                    this.emit('pickUp');
                }
                // if(number === '2') {
                //     this.emit('answer');
                // }
            },
            Ring: () => {
                // 拨号（外呼）
                this.emit('ring');
            },
            Hangup: () => {
                // 挂断
                this.emit('hangup');
            },
        };

        stateActionMap[state]?.();
    }
    // 主动挂断
    hangup() {
        this.send(['Hangup']);
        return new Promise((resolve, reject) => {
            this.once('hangup', () => {
                resolve(true);
            });
        });
    }
    logout() {
        if (this.onlineflag === 'online') {
            // 下线
            this.handleAgentLogout();
        } else {
            // 坐席登出
            this.send(['AgentLogoff', this.agent]);
        }
    }
}

export default new BaiChuanCC();
