import React, { memo, useContext } from 'react';
import { AutoEllipsis } from '../../auto-ellipsis';
import { allCells } from './index';
import EE from 'eventemitter3';
import _ from 'lodash';

export const ActionParamEE = new EE();

export const ActionParam: React.FC<{
    args: any[];
    item: any;
    onAction: (...args: any[]) => void;
}> = memo(({ args, item, onAction }) => {
    const value = args[0];
    const options = args[1] || {};
    const paramName = options.paramName || 'unknow';
    const prependOpt = options.prepend || null;
    const preventEvent = options.preventEvent || false;
    const actionName = 'reveal-param/' + paramName;

    const prepend = prependOpt
        ? (() => {
              const Cell = (allCells as any)[prependOpt.cell];
              if (!Cell) {
                  return null;
              }
              return (
                  <Cell
                      args={(prependOpt.inputs || []).map((k: any) => item[k])}
                      item={item}
                      onAction={onAction}
                  />
              );
          })()
        : null;

    if (value) {
        let text = '';
        if (_.isString(value)) {
            text = value;
        } else {
            text = value?.originalValue + '';
        }
        return (
            <a
                onClick={e => {
                    preventEvent && e.stopPropagation();
                    onAction(actionName, item);
                    ActionParamEE.emit(actionName, item);
                }}
            >
                <AutoEllipsis
                    text={
                        <>
                            {prepend}
                            {text}
                        </>
                    }
                />
            </a>
        );
    }
    return null;
});
