import Input, { InputProps } from 'antd/lib/input';
import React, { useEffect, useState } from 'react';
import { getCommonStatus, ICommonProps } from './common';
import _, { negate } from 'lodash';
import { loadingWrapper } from '@/components/common/block-loading';
import Popover, { PopoverProps } from 'antd/lib/popover';
import { Empty } from 'antd';
import { defaultAxios } from '@/utils';
import './input-searchable.scss';

export type InputSearchableValueType = string;
export interface IInputSearchableOptions extends InputProps {
    tooltipProps?: PopoverProps;
    emptyChild?: React.ReactChild;
    api: string; // 一个标准的接口，输入总是有一个query字段是name，输出总是在固定位置返回一个json数组
    textKey?: string; // 展示字段
    valueKey?: string; // 值字段
    searchQueryKey?: string; // query
    startSearchLength?: number;
    hack?: boolean; // 之前写错了，但是改对了又怕有bug，草
}

export interface IWidgetInputSearchableProps<T = any> extends ICommonProps<T> {
    value: InputSearchableValueType;
    options: IInputSearchableOptions;
    onChange: (val: InputSearchableValueType) => void;
}

const innerFlags = ['lint-list', 'searchable-input'];
const inputFlags = ['searchable-input'];

const deboucneSearchHandler = _.debounce(
    (
        api: string,
        search: string,
        searchQueryKey: string,
        startSearchLength: number,
        startLoading: () => void,
        endLoading: () => void,
        counter: { count: number },
        cb: (ret: any[]) => void,
    ) => {
        if (_.trim(search).length < startSearchLength) {
            return;
        }
        counter.count++;
        const count = counter.count;
        startLoading();
        defaultAxios
            .get(api, {
                [searchQueryKey]: search,
                bizType: 1,
            })
            .then(ret => {
                if (counter.count > count) {
                    return;
                }
                endLoading();
                const [d, e] = ret;
                if (d === null || d.data === null) {
                    return [null, e];
                }
                const nextLints = d?.data || [];
                cb(nextLints);
            });
    },
    300,
    {
        trailing: true,
    },
);

export const WidgetInputSearchable: React.FC<IWidgetInputSearchableProps> = ({
    value,
    options,
    onChange,
    status,
    onInteract = key => void 0,
    data,
    statusExtraData,
}) => {
    const { loading, disabled } = getCommonStatus(
        status,
        data,
        statusExtraData,
    );

    const wrapper = loadingWrapper(loading || false);

    const outerDisabled = options.disabled;
    if (disabled) {
        options.disabled = outerDisabled === undefined ? true : outerDisabled;
    } else {
        options.disabled = outerDisabled === undefined ? false : outerDisabled;
    }

    const {
        textKey = '',
        valueKey = '',
        searchQueryKey = 'name',
        startSearchLength = 4,
    } = options;

    const [visible, setVisible] = useState(false);
    const [lints, setLints] = useState<any[]>([]);
    const [search, setSearch] = useState('');
    const [innerloading, setInnerloading] = useState(false);
    const [counter, setCounter] = useState({ count: 0 });

    useEffect(() => {
        (async () => {
            const startLoading = () => setInnerloading(true);
            const endLoading = () => setInnerloading(false);

            deboucneSearchHandler.cancel();
            deboucneSearchHandler(
                options.api,
                search,
                searchQueryKey,
                startSearchLength,
                startLoading,
                endLoading,
                counter,
                nextLints => {
                    if (_.isArray(nextLints)) {
                        setLints(nextLints);
                    }
                },
            );
        })();
    }, [search]);

    const handleListSelect = (listItem: any) => {
        onInteract('onchange-by-lint', listItem);
        setVisible(false);
        const value = valueKey ? listItem[valueKey] : listItem;
        if (value) {
            onChange(value);
        }
    };

    useEffect(
        () => {
            const handler = (e: any) => {
                const ne = e;
                // safari using composedPath
                const path = ne.path || (ne.composedPath && ne.composedPath());
                if (_.isArray(path)) {
                    setTimeout(() => {
                        const innerClick = !!_.find(path, item => {
                            if (!item.classList) {
                                return false;
                            }
                            const currentClassStringList = [].slice.call(
                                item.classList,
                            ) as string[];
                            return !_.isEmpty(
                                _.intersection(
                                    currentClassStringList,
                                    innerFlags,
                                ),
                            );
                        });
                        if (!innerClick) {
                            setVisible(false);
                        }

                        const innerInputClick = !!_.find(path, item => {
                            if (!item.classList) {
                                return false;
                            }
                            const currentClassStringList = [].slice.call(
                                item.classList,
                            ) as string[];
                            return !_.isEmpty(
                                _.intersection(
                                    currentClassStringList,
                                    inputFlags,
                                ),
                            );
                        });
                        if (!innerInputClick) {
                            onInteract('input-outside-clicked');
                        }
                    }, 0);
                }
            };
            document.body.addEventListener('click', handler);
            return () => {
                document.body.removeEventListener('click', handler);
            };
        },
        !!options.hack ? [onInteract] : [],
    );

    const finalVisible = visible && _.trim(search).length >= startSearchLength;

    return wrapper(
        <Popover
            visible={finalVisible}
            placement={'bottomLeft'}
            autoAdjustOverflow={false}
            overlayClassName="lint-list-popover"
            content={loadingWrapper(innerloading)(
                <div className="lint-list">
                    {lints.length ? (
                        lints.map((item, index) => {
                            const selected =
                                value === (valueKey ? item[valueKey] : item);
                            return (
                                <div
                                    key={index}
                                    onClick={() => {
                                        handleListSelect(item);
                                    }}
                                    className={
                                        'list-item ' +
                                        (selected ? 'selected' : '')
                                    }
                                >
                                    {textKey ? item[textKey] : item}
                                </div>
                            );
                        })
                    ) : options.emptyChild ? (
                        options.emptyChild
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </div>,
            )}
            {...options.tooltipProps}
        >
            {/* TODO: loading 样式 */}
            <Input
                {...options}
                value={value}
                className="searchable-input"
                onFocus={e => {
                    onInteract('focus');
                    onInteract('remove-validate-status');
                    if (
                        lints.length > 0 ||
                        _.trim(search).length >= startSearchLength
                    ) {
                        setVisible(true);
                    }
                }}
                onBlur={e => {
                    onInteract('blur');
                    setTimeout(() => {
                        onInteract('blur2');
                    }, 10);
                    onInteract('validate-instantly');
                }}
                onChange={e => {
                    setVisible(true);
                    if (e.target && !_.isNil(e.target.value)) {
                        const value = e.target.value;
                        onChange(e.target.value);
                        onInteract('remove-validate-status');
                        setSearch(value);
                    }
                }}
            />
        </Popover>,
    );
};
