import React from 'react';
import _ from 'lodash';

export interface ICrmUserTrendItemProps {
    content: React.ReactNode;
}

export interface ICrmUserTrendItemGroupProps {
    day: string;
    userTrendItems: ICrmUserTrendItemProps[];
}

export const CrmUserTrendItem: React.FC<ICrmUserTrendItemProps> = ({
    content,
}) => {
    return (
        <div className="crm-usertrend-item">
            <div className="content">{content}</div>
            <div className="dot" />
            <div className="dash-line" />
        </div>
    );
};

export const CrmUserTrendItemGroup: React.FC<ICrmUserTrendItemGroupProps> = ({
    day,
    userTrendItems,
}) => {
    return (
        <div className="crm-usertrend-item-group">
            <div className="day">{day}</div>
            <div className="group">
                {_.map(userTrendItems, (UserTrendItem, key) => {
                    return <CrmUserTrendItem {...UserTrendItem} key={key} />;
                })}
            </div>
        </div>
    );
};
