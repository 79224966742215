import _ from 'lodash';
import { makeAutoObservable, runInAction, observable } from 'mobx';
import {
    arrToJson,
    defaultAxios,
    makeStringValidator,
    makeNumberValidator,
    makeNetworkValidator,
    composeFeValidatorAndNetValidator,
    makeObjValidator,
    delay,
} from '@/utils';
import { getDefaultParamPerm } from '@/utils/param';
import React, { useEffect, useState } from 'react';
import {
    applyParamListToColumns,
    TSGridLayoutProp,
    IWidgetItem,
    ICommonTableColumnProps,
    TCellsSelector,
    IItemOptionItem,
    TCollpaseLayout,
    getDefaultCellSelector,
    IProcessCtx,
    AntButton,
} from '@/components';
import { applyParamListToWidgets } from '@/components/common/form/helpers';
import { ParamsEditorModal } from '@/components/common/params-editor';
import { Button, message } from 'antd';
import { TableProps } from 'antd/lib/table';
import { Perm } from '../perm';
import Item from 'antd/lib/list/Item';
import moment from 'moment';

export type TPerms = {
    [api: string]: {
        instance?: Perm;
    };
};

const metaDataCache: { [source: string]: any } = {};

function getWidgetItemByMetaItem(
    fieldItem: any,
    store: any,
    groupCheck?: boolean,
) {
    groupCheck = _.isUndefined(groupCheck) ? true : groupCheck;
    const {
        fieldName,
        fieldComment,
        fieldType,
        constraint,
        fieldShowType,
        groupKey: _groupKey,
        newFieldMetaItem,
    } = fieldItem;
    const groupKey = _groupKey; // || 'basic'
    if (groupCheck && (fieldName === 'id' || !groupKey)) {
        return;
    }

    if (!fieldComment || !fieldName || !fieldShowType) {
        return;
    }
    // TODO，根据什么什么的选择类型
    let widgetKey = 'input' as any;
    if (_.isObject(constraint)) {
        const { constraintItemList, fieldConstraintType } = constraint as any;
        if (fieldConstraintType === 'obj') {
            widgetKey = 'object';
        } else if (constraintItemList && fieldConstraintType) {
            widgetKey = 'select';
            if (fieldShowType === 'radio') {
                widgetKey = 'radio';
            }
        }
    }
    if (fieldShowType === 'date') {
        widgetKey = 'date';
    }
    if (fieldShowType === 'datetime') {
        widgetKey = 'datetime';
    }
    if (fieldShowType === 'number') {
        widgetKey = 'number';
    }
    if (fieldShowType === 'input-textarea') {
        widgetKey = 'inputTextArea';
    }
    if (fieldShowType === 'upload') {
        widgetKey = 'upload';
    }
    if (fieldShowType === 'phone' && newFieldMetaItem.secure === 'crypto') {
        widgetKey = 'inputSecret';
    }
    const widgetItem: IWidgetItem<any> = {
        key: fieldName,
        widgetKey,
    };

    if (groupKey) {
        widgetItem.collapseKey = groupKey;
    }

    // TODO，根据什么什么的选择其他配置
    if (widgetKey === 'input') {
        widgetItem.inputOptions = {
            placeholder: fieldComment,
        };
    } else if (widgetKey === 'inputTextArea') {
        widgetItem.inputTextAreaOptions = {
            placeholder: fieldComment,
        };
    } else if (widgetKey === 'number') {
        widgetItem.numberOptions = {
            placeholder: fieldComment,
        };
    } else if (widgetKey === 'select' && _.isObject(constraint)) {
        const { constraintItemList, fieldConstraintType } = constraint as any;
        widgetItem.selectOptions = {
            placeholder: fieldComment,
            options: (constraintItemList || []).map((item: any) => {
                return {
                    name: item.constraintLabel,
                    value: item.constraintValue,
                    item: item.item,
                };
            }),
            mode: fieldConstraintType === 'item' ? 'multiple' : 'default',
        };
    } else if (widgetKey === 'radio') {
        const { constraintItemList } = constraint as any;
        widgetItem.radioOptions = {
            options: (constraintItemList || []).map((item: any) => {
                // {constraintLabel: "自建", constraintValue: 1}
                return {
                    label: item.constraintLabel,
                    value: item.constraintValue,
                    item: item.item,
                };
            }),
        };
    } else if (widgetKey === 'object' && _.isObject(constraint)) {
        widgetItem.objectOptions = {
            constraint,
            placeholder: fieldComment,
            pageSize: 50,
            listMeta: store,
        };
    } else if (widgetKey === 'date') {
        widgetItem.dateOptions = {
            placeholder: fieldComment,
        };
    } else if (widgetKey === 'upload') {
        widgetItem.uploadOptions = {
            multiple: true,
        };
    }
    return widgetItem;
}

// 将用户持久化的设置跟用户可见全集做一个合并
// 作为用户真正展示的
export const composeParamsFromUserAndAll = (
    user: IParamItem[],
    permed: IParamItem[],
    noUserSetting?: boolean,
): IParamItem[] => {
    // 用户可见，且可编辑
    noUserSetting = _.isNil(noUserSetting) ? false : noUserSetting;
    if (noUserSetting) {
        // 如果不需要用户设置，则返回所有用户可见
        return permed;
    }
    const permedMap = arrToJson(permed, 'key');
    const actualUser = user.filter(user => !!permedMap[user.key]);
    return actualUser;
};

export interface IMetaSourceParams {
    filtersParamsAll: IParamItem[];
    filtersParamsFromUserSetting: IParamItem[];
    tableColsParamsAll: IParamItem[];
    tableColsParamsFromUserSetting: IParamItem[];
    objectMeta: any[];
    defaultUserSelectedCols: any[];
    forcedUserSelectedCols: any[];
}

export type IMetaSourceReturn = NetResponse<{
    data: IMetaSourceParams;
}>;

const skipParams = new Set(['id', 'is_del']);

export const getValidatorByParamMeta = (
    fieldItem: any,
    outerRequired?: boolean,
) => {
    let { fieldName, fieldShowType, isServerCheck, constraint } = fieldItem;
    constraint = constraint || {};
    const isObj = constraint.fieldConstraintType === 'obj';
    if (isObj) {
        fieldShowType = 'object'; // DIRTY
    }

    if (
        fieldShowType === 'input' ||
        fieldShowType === 'input-textarea' ||
        fieldShowType === 'phone' ||
        fieldShowType === 'upload' ||
        fieldShowType === 'date' ||
        fieldShowType === 'datetime'
    ) {
        // 字符串类型
        let {
            fieldLength,
            regularExp,
            paramSuggestion,
            isRequired,
        } = fieldItem;

        fieldLength = fieldLength || 100;
        regularExp = regularExp || '';
        paramSuggestion = paramSuggestion || '';
        isRequired = _.isNil(outerRequired)
            ? isRequired || false
            : outerRequired;

        return makeStringValidator(
            fieldName,
            isRequired,
            fieldLength,
            regularExp,
            paramSuggestion,
        );
    }

    if (fieldShowType === 'number') {
        // 数字类型 TODO
        let {
            fieldLength,
            fieldDecimalLength,
            paramSuggestion,
            isRequired,
        } = fieldItem;

        fieldLength = fieldLength || 100;
        fieldDecimalLength = fieldDecimalLength || 2;
        paramSuggestion = paramSuggestion || '';
        isRequired = _.isNil(outerRequired)
            ? isRequired || false
            : outerRequired;

        return makeNumberValidator(
            fieldName,
            isRequired,
            fieldLength,
            fieldDecimalLength,
            paramSuggestion,
        );
    }

    if (fieldShowType === 'object') {
        // 对象类型
        let { isRequired } = fieldItem;
        isRequired = _.isNil(outerRequired)
            ? isRequired || false
            : outerRequired;

        return makeObjValidator(fieldName, isRequired);
    }
};

export class ListMeta {
    public static composeParamsFromUserAndAll = composeParamsFromUserAndAll;
    public inited = false;
    public setInited(next: boolean) {
        this.inited = next;
    }
    public loading = false;
    public filtersParamsAll: IParamItem[] = [];
    public tableId = ''; // TOFIX TODO: 这里要做到动态化？
    public setTableId(tableId: string) {
        this.tableId = tableId;
    }

    public noUserSetting = false;
    public setNoUserSetting(next: boolean) {
        this.noUserSetting = next;
    }

    public objectMeta: any[] = [];
    get objectMetaMap() {
        return arrToJson(this.objectMeta, 'fieldName');
    }

    public defaultResetData(data: any, keys: string[]) {
        const nextData = _.cloneDeep(data);
        for (const key of keys) {
            const fieldMeta = this.objectMetaMap[key];
            if (!fieldMeta) {
                continue;
            }
            nextData[key] = fieldMeta.defaultValue;
        }
        return nextData;
    }

    // 所有字段，供graphql使用
    get defaultAllParams(): string[] {
        const ret: string[] = [];
        for (const fieldItem of this.objectMeta) {
            const { fieldName } = fieldItem;
            if (!fieldName) {
                continue;
            }
            ret.push(fieldName);
        }
        return ret;
    }

    // 所有关联对象字段
    get defaultAllObjectParams(): string[] {
        return _.map(
            _.filter(
                this.objectMeta,
                item =>
                    item.constraint &&
                    item.constraint.fieldConstraintType === 'obj',
            ),
            item => item.fieldName,
        );
    }

    // 用于查询字段在哪个组
    get defaultGroupReverseMap(): { [key: string]: string } {
        const ret: { [key: string]: string } = {};
        for (const fieldItem of this.objectMeta) {
            const { fieldName, groupKey } = fieldItem;
            if (!fieldName) {
                continue;
            }
            ret[fieldName] = groupKey;
        }
        return ret;
    }

    get defaultGroupParams(): { [key: string]: string[] } {
        const ret: { [key: string]: string[] } = {};
        for (const fieldItem of this.objectMeta) {
            const { fieldName, groupKey } = fieldItem;
            if (!fieldName || !groupKey) {
                continue;
            }
            ret[groupKey] = ret[groupKey] || [];
            ret[groupKey].push(fieldName);
        }
        return ret;
    }

    get defaultValidators() {
        const ret: { [key in keyof any]?: Validator<any> } = {};
        for (const fieldItem of this.objectMeta) {
            const { fieldName } = fieldItem;
            const curValidator = getValidatorByParamMeta(fieldItem);
            if (curValidator) {
                ret[fieldName] = curValidator;
            }
        }
        return ret;
    }

    // 折叠面板分组信息
    get defaultFormCollapseLayout() {
        const ret: TCollpaseLayout = [];
        const keys: string[] = _.filter(
            _.map(_.uniqBy(this.objectMeta, 'groupKey'), 'groupKey'),
            Boolean,
        );
        const mp = arrToJson(this.objectMeta, 'groupKey');
        keys.sort((ka, kb) => mp[ka].groupOrder - mp[kb].groupOrder);
        for (const key of keys) {
            const layoutItem: any = {
                groupKey: key,
                groupName: mp[key].groupName,
                groupOrder: mp[key].groupOrder,
            };
            let span = 0;
            for (const fieldItem of this.objectMeta) {
                const { groupKey } = fieldItem;
                if (groupKey === key) {
                    span++;
                }
            }
            if (span !== 0) {
                layoutItem.groupSpan = span;
            }
            ret.push(layoutItem);
        }
        return ret;
    }

    public instantlyDefaultFormCollapseLayout(formParamsAll: any[]) {
        const formParamsAllMap = arrToJson(formParamsAll, 'key');
        const finalObjectMeta = this.objectMeta.filter(item => {
            return !!formParamsAllMap[item.fieldName];
        });
        const ret: TCollpaseLayout = [];
        const keys: string[] = _.filter(
            _.map(_.uniqBy(finalObjectMeta, 'groupKey'), 'groupKey'),
            Boolean,
        );
        const mp = arrToJson(finalObjectMeta, 'groupKey');
        keys.sort((ka, kb) => mp[ka].groupOrder - mp[kb].groupOrder);
        for (const key of keys) {
            const layoutItem: any = {
                groupKey: key,
                groupName: mp[key].groupName,
                groupOrder: mp[key].groupOrder,
            };
            let span = 0;
            for (const fieldItem of finalObjectMeta) {
                const { groupKey } = fieldItem;
                if (groupKey === key) {
                    span++;
                }
            }
            if (span !== 0) {
                layoutItem.groupSpan = span;
            }
            ret.push(layoutItem);
        }
        return ret;
    }

    // 生成创建表单的基础配置项
    get defaultWidgetsAll(): Array<IWidgetItem<any>> {
        const ret: Array<IWidgetItem<any>> = [];
        for (const fieldItem of this.objectMeta) {
            const widgetItem = getWidgetItemByMetaItem(fieldItem, this);
            if (widgetItem) {
                ret.push(widgetItem);
            }
        }
        return ret;
    }

    get defaultWidgetsAllWithoutGroupCheck(): Array<IWidgetItem<any>> {
        const ret: Array<IWidgetItem<any>> = [];
        for (const fieldItem of this.objectMeta) {
            const widgetItem = getWidgetItemByMetaItem(fieldItem, this, false);
            if (widgetItem) {
                ret.push(widgetItem);
            }
        }
        return ret;
    }

    // 生成创建表单的基础默认值
    get defaultWidgetValues(): { [key: string]: any } {
        const ret: { [key: string]: any } = {};
        for (const fieldItem of this.objectMeta) {
            const { fieldName, defaultValue, groupKey } = fieldItem;
            if (!fieldName || skipParams.has(fieldName) || _.isNil(groupKey)) {
                continue;
            }
            ret[fieldName] = defaultValue;
        }
        return ret;
    }

    // 生成filter配置
    get defaultAdvancedFilterWidgetsAll(): Array<IWidgetItem<any>> {
        const ret: Array<IWidgetItem<any>> = [];
        for (const fieldItem of this.objectMeta) {
            const {
                fieldName,
                fieldComment,
                fieldType,
                constraint,
                fieldShowType,
                groupKey,
            } = fieldItem;
            if (fieldName === 'id') {
                continue;
            }

            if (!fieldComment || !fieldName || !fieldShowType) {
                continue;
            }

            let widgetKey = 'input' as any;
            if (_.isObject(constraint)) {
                const {
                    constraintItemList,
                    fieldConstraintType,
                    constraintObjMeta,
                } = constraint as any;
                if (fieldConstraintType === 'obj') {
                    widgetKey = 'object';
                    if (constraintObjMeta.objectCode === 'sys_user') {
                        // 对选择（系统用户）的字段特殊定制
                        widgetKey = 'sysUserSelector';
                    }
                } else if (constraintItemList && fieldConstraintType) {
                    widgetKey = 'filterEnum';
                }
            }
            if (fieldShowType === 'date' || fieldShowType === 'datetime') {
                widgetKey = 'filterDateRange';
            }
            if (fieldShowType === 'number') {
                widgetKey = 'filterNumberRange';
            }

            const widgetItem: IWidgetItem<any> = {
                key: fieldName,
                widgetKey,
            };

            if (groupKey) {
                widgetItem.collapseKey = groupKey;
            }

            if (widgetKey === 'input') {
                widgetItem.inputOptions = {
                    placeholder: fieldComment,
                    autoFocus: true,
                };
            } else if (widgetKey === 'filterNumberRange') {
                widgetItem.filterNumberRangeOptions = {
                    placeholder: fieldComment,
                };
            } else if (widgetKey === 'filterEnum') {
                const { constraintItemList } = constraint as any;
                widgetItem.filterEnumOptions = {
                    options: (constraintItemList || []).map((item: any) => {
                        return {
                            label: _.isNil(item.constraintLabel)
                                ? ''
                                : item.constraintLabel + '',
                            value: _.isNil(item.constraintValue)
                                ? ''
                                : item.constraintValue + '',
                        };
                    }),
                };
            } else if (widgetKey === 'object' && _.isObject(constraint)) {
                widgetItem.objectOptions = {
                    constraint,
                    placeholder: fieldComment,
                    pageSize: 50,
                    listMeta: this,
                    multiple: true,
                };
            } else if (widgetKey === 'sysUserSelector') {
                widgetItem.sysUserSelectorOptions = {
                    constraint,
                    listMeta: this,
                };
            } else if (widgetKey === 'filterDateRange') {
                widgetItem.filterDateRangeOptions = {};
            }
            ret.push(widgetItem);
        }
        return ret;
    }

    // 生成创建表单的基础默认值
    get defaultAdvancedFilterWidgetValues(): { [key: string]: any } {
        const ret: { [key: string]: any } = {};
        const ts = Date.now();
        const dStart = undefined; // moment().startOf('day');
        const dEnd = undefined; // moment().endOf('day');
        for (const fieldItem of this.objectMeta) {
            const {
                fieldName,
                fieldComment,
                fieldType,
                constraint,
                fieldShowType,
                groupKey,
            } = fieldItem;
            if (fieldName === 'id') {
                continue;
            }

            if (!fieldComment || !fieldName || !fieldShowType) {
                continue;
            }

            let widgetKey = 'input' as any;
            if (_.isObject(constraint)) {
                const {
                    constraintItemList,
                    fieldConstraintType,
                    constraintObjMeta,
                } = constraint as any;
                if (fieldConstraintType === 'obj') {
                    widgetKey = 'object';
                    if (constraintObjMeta.objectCode === 'sys_user') {
                        // 对选择（系统用户）的字段特殊定制
                        widgetKey = 'sysUserSelector';
                    }
                } else if (constraintItemList && fieldConstraintType) {
                    widgetKey = 'filterEnum';
                }
            }
            if (fieldShowType === 'date' || fieldShowType === 'datetime') {
                widgetKey = 'filterDateRange';
            }
            if (fieldShowType === 'number') {
                widgetKey = 'filterNumberRange';
            }

            let defaultValue = null;
            if (widgetKey === 'input') {
                defaultValue = undefined;
            } else if (widgetKey === 'filterNumberRange') {
                defaultValue = [undefined, undefined];
            } else if (widgetKey === 'filterEnum') {
                defaultValue = [];
            } else if (
                (widgetKey === 'object' && _.isObject(constraint)) ||
                widgetKey === 'sysUserSelector'
            ) {
                defaultValue = undefined;
            } else if (widgetKey === 'filterDateRange') {
                // defaultValue = [undefined, undefined];
                defaultValue = [dStart, dEnd];
            }
            ret[fieldName] = {
                value: defaultValue,
                emptyValue: defaultValue,
                isEmpty: false,
                isNotEmpty: false,
                widgetKey,
                ts,
            };
        }
        return ret;
    }

    // 生成创建表单的基础label配置
    get defaultWidgetLabels(): Array<IItemOptionItem<any>> {
        const ret: Array<IItemOptionItem<any>> = [];
        for (const fieldItem of this.objectMeta) {
            const {
                fieldName,
                fieldComment,
                fieldType,
                fieldShowType,
                isRequired,
            } = fieldItem;
            if (!fieldComment || !fieldName || !fieldShowType) {
                continue;
            }
            const item: IItemOptionItem<any> = {
                key: fieldName,
                label: fieldComment,
            };
            if (isRequired === 1) {
                item.required = true;
            }
            ret.push(item);
        }
        return ret;
    }

    get defaultWidgetLabelsMap() {
        return arrToJson(this.defaultWidgetLabels, 'key');
    }

    public genProcessWidgetLabelsByDefault(
        defaultLabels: Array<IItemOptionItem<any>>,
        formParamsAllMap: { [key: string]: any },
        keepOldRequired?: boolean,
    ) {
        const nextLabels = _.cloneDeep(defaultLabels);
        for (const labelItem of nextLabels) {
            const oldRequired = labelItem.required;
            delete labelItem.required;
            if (!!formParamsAllMap[labelItem.key as string]) {
                labelItem.required =
                    formParamsAllMap[labelItem.key as string].required;
                if (keepOldRequired && _.isUndefined(labelItem.required)) {
                    labelItem.required = oldRequired;
                }
            }
        }
        return nextLabels;
    }

    // 跟上面的validtor逻辑几乎一样，不过require信息是从流程那边过来的
    public genProcessValidatorsByDefault(formParamsAllMap: {
        [key: string]: any;
    }) {
        const ret: { [key in keyof any]?: Validator<any> } = {};
        for (const fieldItem of this.objectMeta) {
            let { fieldName, fieldShowType, constraint } = fieldItem;
            const item = formParamsAllMap[fieldName];
            const isRequired = item ? item.required || false : false;
            constraint = constraint || {};
            const isObj = constraint.fieldConstraintType === 'obj';
            if (isObj) {
                fieldShowType = 'object';
            }

            if (
                fieldShowType === 'input' ||
                fieldShowType === 'input-textarea' ||
                fieldShowType === 'phone' ||
                fieldShowType === 'upload' ||
                fieldShowType === 'date' ||
                fieldShowType === 'datetime'
            ) {
                let { fieldLength, regularExp, paramSuggestion } = fieldItem;

                fieldLength = fieldLength || 100;
                regularExp = regularExp || '';
                paramSuggestion = paramSuggestion || '';

                ret[fieldName] = makeStringValidator(
                    fieldName,
                    isRequired,
                    fieldLength,
                    regularExp,
                    paramSuggestion,
                );
            }

            if (fieldShowType === 'number') {
                let {
                    fieldLength,
                    fieldDecimalLength,
                    paramSuggestion,
                } = fieldItem;

                fieldLength = fieldLength || 100;
                fieldDecimalLength = fieldDecimalLength || 2;
                paramSuggestion = paramSuggestion || '';

                ret[fieldName] = makeNumberValidator(
                    fieldName,
                    isRequired,
                    fieldLength,
                    fieldDecimalLength,
                    paramSuggestion,
                );
            }

            if (fieldShowType === 'object') {
                // 对象类型
                ret[fieldName] = makeObjValidator(fieldName, isRequired);
            }
        }
        return ret;
    }

    // 生成筛选框的基础插件配置
    get defaultFilterWidgetsAll(): Array<IWidgetItem<any>> {
        const ret: Array<IWidgetItem<any>> = [];
        for (const fieldItem of this.objectMeta) {
            const {
                fieldName,
                fieldComment,
                fieldType,
                constraint,
                fieldShowType,
                groupKey,
            } = fieldItem;
            if (fieldName === 'id') {
                continue;
            }

            if (!fieldComment || !fieldName || !fieldShowType) {
                continue;
            }
            // TODO，根据什么什么的选择类型
            let widgetKey = 'input' as any;
            if (_.isObject(constraint)) {
                const {
                    constraintItemList,
                    fieldConstraintType,
                } = constraint as any;
                if (constraintItemList && fieldConstraintType) {
                    widgetKey = 'select';
                }
            }

            // TODO, 如果是数字类型的，自动变成范围选择器
            if (fieldType === 'int') {
                widgetKey = 'valueRange';
            }
            if (fieldType === 'timestamp') {
                widgetKey = 'dateRange';
            }

            const widgetItem: IWidgetItem<any> = {
                key: fieldName,
                widgetKey,
            };

            if (groupKey) {
                widgetItem.collapseKey = groupKey;
            }

            // TODO，根据什么什么的选择其他配置
            if (widgetKey === 'input') {
                widgetItem.inputOptions = {
                    placeholder: fieldComment,
                };
            } else if (widgetKey === 'select' && _.isObject(constraint)) {
                const {
                    constraintItemList,
                    fieldConstraintType,
                } = constraint as any;
                widgetItem.selectOptions = {
                    placeholder: fieldComment,
                    options: (constraintItemList || []).map((item: any) => {
                        return {
                            name: item.constraintLabel,
                            value: item.constraintValue,
                        };
                    }),
                    mode:
                        fieldConstraintType === 'item' ? 'multiple' : 'default',
                };
            }
            ret.push(widgetItem);
        }
        return ret;
    }

    // 生成筛选框的默认值
    get defaultFilterWidgetValues(): { [key: string]: any } {
        const ret: { [key: string]: any } = {};
        for (const fieldItem of this.objectMeta) {
            const { fieldName, defaultValue, fieldType } = fieldItem;
            if (!fieldName || fieldName === 'id') {
                continue;
            }
            // TODO, 如果是数字类型的，自动变成范围选择器对应的值
            if (fieldType === 'int') {
                ret[fieldName] = ['', ''];
            }
            if (fieldType === 'timestamp') {
                ret[fieldName] = ['', ''];
            }
            ret[fieldName] = defaultValue;
        }
        return ret;
    }

    // 生成筛选框的基础label配置
    get defaultFilterWidgetLabels(): Array<IItemOptionItem<any>> {
        const ret: Array<IItemOptionItem<any>> = [];
        for (const fieldItem of this.objectMeta) {
            const {
                fieldName,
                fieldComment,
                fieldType,
                isRequired,
                fieldShowType,
            } = fieldItem;
            if (!fieldComment || !fieldName || !fieldShowType) {
                continue;
            }
            const item: IItemOptionItem<any> = {
                key: fieldName,
                label: '',
            };
            item.colon = false;
            ret.push(item);
        }
        return ret;
    }

    // 生成col的默认配置
    get defaultColumnsAll(): Array<ICommonTableColumnProps<any>> {
        const ret: Array<ICommonTableColumnProps<any>> = [];
        for (const fieldItem of this.objectMeta) {
            const { fieldName, fieldComment, fieldShowType, fixed } = fieldItem;

            if (
                !fieldComment ||
                !fieldName ||
                skipParams.has(fieldName) ||
                !fieldShowType
            ) {
                continue;
            }

            ret.push({
                key: fieldName,
                title: fieldComment,
                fixed: fixed ? 'left' : undefined,
                ellipsis: true,
            });
        }
        return ret;
    }

    // HACK
    // 生成col的默认配置
    get defaultColumnsNoSkipAll(): Array<ICommonTableColumnProps<any>> {
        const ret: Array<ICommonTableColumnProps<any>> = [];
        let idCol: any = null;
        for (const fieldItem of this.objectMeta) {
            const { fieldName, fieldComment, fieldShowType, fixed } = fieldItem;

            if (!fieldComment || !fieldName || !fieldShowType) {
                continue;
            }

            let f = fixed ? 'left' : undefined;
            if (fieldName === 'id') {
                f = 'left';
                idCol = {
                    key: fieldName,
                    title: fieldComment,
                    fixed: f as any,
                    ellipsis: true,
                };
            } else {
                ret.push({
                    key: fieldName,
                    title: fieldComment,
                    fixed: f as any,
                    ellipsis: true,
                });
            }
        }
        if (idCol) {
            ret.unshift(idCol);
        }
        return ret;
    }

    // 生成col的默认cellsSelector
    get defaultColumnsCellsSelector() {
        const ret: TCellsSelector<any> = [];
        for (const fieldItem of this.objectMeta) {
            const {
                fieldName,
                fieldComment,
                fieldType,
                constraint,
                fieldShowType,
                newFieldMetaItem,
            } = fieldItem;

            if (
                !fieldComment ||
                !fieldName ||
                skipParams.has(fieldName) ||
                !fieldShowType
            ) {
                continue;
            }
            if (fieldShowType === 'date') {
                ret.push({
                    colKey: fieldName,
                    cell: 'DefaultMoment',
                    inputs: [fieldName],
                });
                continue;
            }
            if (fieldShowType === 'datetime') {
                ret.push({
                    colKey: fieldName,
                    cell: 'DateTimeMoment',
                    inputs: [fieldName],
                });
                continue;
            }
            if (fieldShowType === 'phone') {
                ret.push({
                    colKey: fieldName,
                    cell: 'CC',
                    inputs: [fieldName, newFieldMetaItem],
                });
                continue;
            }
            if (fieldShowType === 'upload') {
                ret.push({
                    colKey: fieldName,
                    cell: 'Files',
                    inputs: [fieldName],
                });
                continue;
            }
            if (fieldShowType === 'link') {
                ret.push({
                    colKey: fieldName,
                    cell: 'Link',
                    inputs: [fieldName],
                });
                continue;
            }
            if (constraint) {
                // 可能是一个枚举类型
                const { fieldConstraintType } = constraint as any;

                if (fieldConstraintType === 'obj') {
                    // 展示一个关联的对象类型
                    ret.push({
                        colKey: fieldName,
                        cell: 'ObjItem',
                        inputs: [fieldName, constraint],
                    });
                } else {
                    const { constraintItemList } = constraint as any;

                    const enumMap: { [key: string]: any } = {};
                    for (const item of constraintItemList) {
                        const { constraintLabel, constraintValue } = item;
                        enumMap[constraintValue] = constraintLabel;
                    }

                    ret.push({
                        colKey: fieldName,
                        cell: 'EnumItem',
                        inputs: [fieldName, enumMap],
                    });
                }
                continue;
            }
            ret.push(getDefaultCellSelector(fieldName));
        }
        return ret;
    }

    get filtersParamsAllMap() {
        return arrToJson(this.filtersParamsAll, 'key');
    }

    public filtersParamsFromUserSetting: IParamItem[] = [];
    get filtersParamsFromUserSettingMap() {
        return arrToJson(this.filtersParamsFromUserSetting, 'key');
    }

    public originalTableColsParamsAll: IParamItem[] = [];
    public originalTableColsParamsFromUserSetting: IParamItem[] = [];

    public originalDefaultUserSelectedCols: string[] = [];
    public originalForcedUserSelectedCols: string[] = [];

    /**
     * 同步的现在不能支持了
     */
    get tableColsParamsAll() {
        return this.originalTableColsParamsAll;
    }

    public async asyncTableColsParamsAll(api: string, refresh?: boolean) {
        refresh = _.isUndefined(refresh) ? false : refresh;
        let permInstance = this.perms[api]?.instance;
        if (!permInstance) {
            permInstance = new Perm(api);
            this.perms[api] = {
                instance: permInstance,
            };
        }

        if (!permInstance.inited) {
            await permInstance.fetch(refresh);
        }

        const list = this.originalTableColsParamsAll;
        return list.filter(item => {
            return permInstance && permInstance.getPermByKey(item.key).visible;
        });
    }

    // DIRTY: 暂时复用一个table全集
    public async asyncFormParamsAll(
        api: string,
        refresh?: boolean,
    ): Promise<any[]> {
        refresh = _.isUndefined(refresh) ? false : refresh;
        let permInstance = this.perms[api]?.instance;
        if (!permInstance) {
            permInstance = new Perm(api);
            this.perms[api] = {
                instance: permInstance,
            };
        }

        if (!permInstance.inited) {
            await permInstance.fetch(refresh);
        }

        // DIRTY: 所谓table全集
        // 20211012，果然是有问题了，如果我直接用form，不用table，这边就不行了，所以冒险改造一下
        const list = this.originalTableColsParamsAll;
        return list
            .filter(item => {
                return (
                    permInstance && permInstance.getPermByKey(item.key).visible
                );
            })
            .map(item => {
                if (permInstance) {
                    return {
                        item,
                        ...(permInstance.getPermByKey(item.key) || {}),
                    };
                } else {
                    return item;
                }
            });
    }

    public processBtns: string[] = [];
    public processAllInfo: any = null;
    public processPermAll: any = null;
    public setProcessPermAll(nextProcessPermAll: any) {
        this.processPermAll = nextProcessPermAll;
    }

    public async processFormPerm(processCtx: IProcessCtx) {
        const { definitionId, instanceId, currentUser } = processCtx;
        if (!definitionId && !instanceId) {
            return [];
        }
        if (definitionId && instanceId) {
            return [];
        }

        // 接口：GET http://192.168.1.20:9000/crm/workflow/instance/create?definitionId=myProcess:1:007c6656-9078-11eb-9b1f-160bce5524a5&currentUser=wangli
        // 接口：GET http://192.168.1.20:9000/crm/workflow/instance/detail?instanceId=180cd530-912a-11eb-85ce-160bce5524a5&currentUser=wangli
        const list = this.originalTableColsParamsAll;
        let fields = [];
        if (definitionId) {
            // create mode
            const query: any = {
                definitionId,
            };
            if (currentUser) {
                query.currentUser = currentUser;
            }
            const [d, e] = await defaultAxios.get(
                '/crm/workflow/instance/create',
                query,
            );
            if (_.isNil(d)) {
                return [];
            }
            this.processAllInfo = d?.data;
            this.processBtns = [...(d?.data?.buttons || [])];
            fields = d?.data?.crmActivity?.activityFields || [];
        }

        if (instanceId) {
            // detail mode
            const query: any = {
                instanceId,
            };
            if (currentUser) {
                query.currentUser = currentUser;
            }
            const [d, e] = await defaultAxios.get(
                '/crm/workflow/instance/detail',
                query,
            );
            if (_.isNil(d)) {
                return [];
            }
            this.processAllInfo = d?.data;
            this.processBtns = [...(d?.data?.buttons || [])];
            fields = d?.data?.crmActivity?.activityFields || [];
        }

        // {
        //     fieldCode: "next_date",
        //     fieldName: "下次跟进时间",
        //     isView: 1,
        //     isEdit: 0,
        //     isRequired: 0,
        //     isVariable: 0
        // },
        // {
        //     fieldCode: "lead_phone",
        //     fieldName: "线索手机号",
        //     isView: 1,
        //     isEdit: 0,
        //     isRequired: 0,
        //     isVariable: 0
        // },
        // ------------------- 将上面的格式转化成下面的格式 --------------------
        // {
        //     key: "company_people_num",
        //     visible: true,
        //     editable: true
        // },
        // {
        //     key: "liepin_technology_cnt",
        //     visible: true,
        //     editable: false
        // },
        const permList = fields
            .map((item: any) => {
                const {
                    fieldCode,
                    fieldName,
                    isView,
                    isEdit,
                    isRequired,
                    isVariable,
                } = item;
                if (!fieldCode) {
                    return;
                }
                return {
                    key: fieldCode,
                    visible: _.isNil(isView) ? true : !!isView,
                    editable: _.isNil(isEdit) ? true : !!isEdit,
                    required: _.isNil(isRequired) ? true : !!isRequired,
                };
            })
            .filter(Boolean);
        const permListMap = arrToJson(permList, 'key');

        const processPermAll = list
            .filter(item => {
                const permItem = permListMap[item.key];
                if (permItem) {
                    return permItem.visible;
                }
                return false;
            })
            .map(item => {
                const permItem = permListMap[item.key] || {};
                return {
                    item,
                    ...permItem,
                };
            });
        this.processPermAll = processPermAll;
        return processPermAll;
    }

    get tableColsParamsFromUserSetting() {
        return this.originalTableColsParamsFromUserSetting;
    }
    get tableColsParamsAllMap() {
        return arrToJson(this.tableColsParamsAll, 'key');
    }

    public opUsername = '';
    public setOpUsername(username: string) {
        this.opUsername = username;
    }

    private setMeta(meta: IMetaSourceParams) {
        this.filtersParamsAll = meta.filtersParamsAll.filter(Boolean);
        this.filtersParamsFromUserSetting = meta.filtersParamsFromUserSetting.filter(
            Boolean,
        );

        this.originalTableColsParamsAll = meta.tableColsParamsAll
            .filter(Boolean)
            .filter(item => !skipParams.has(item.key));
        this.originalTableColsParamsFromUserSetting = meta.tableColsParamsFromUserSetting.filter(
            Boolean,
        );

        this.originalDefaultUserSelectedCols = (
            meta.defaultUserSelectedCols || []
        ).filter(Boolean);
        this.originalForcedUserSelectedCols = (
            meta.forcedUserSelectedCols || []
        ).filter(Boolean);

        this.setObjectMeta(meta.objectMeta);
    }

    public setObjectMeta(objectMeta: any[]) {
        this.objectMeta = objectMeta;
    }

    public metaSource:
        | null
        | string
        | ((
              data: any,
              opUsername: string,
          ) => Promise<IMetaSourceReturn>) = null;
    public setMetaSource(
        next: null | string | (() => Promise<IMetaSourceReturn>),
    ) {
        this.metaSource = next;
    }
    public sourceForStoreFiltersParamsFromUserSetting:
        | null
        | string
        | ((input: { params: string[] }) => Promise<IMetaSourceReturn>) = null;
    public sourceForStoreTableColsParamsFromUserSetting:
        | null
        | string
        | ((input: { params: string[] }) => Promise<IMetaSourceReturn>) = null;

    public perms: TPerms = {};

    constructor(
        metaSource?: string | (() => Promise<IMetaSourceReturn>),
        sourceForStoreFiltersParamsFromUserSetting?:
            | string
            | ((input: { params: string[] }) => Promise<IMetaSourceReturn>),
        sourceForStoreTableColsParamsFromUserSetting?:
            | string
            | ((input: { params: string[] }) => Promise<IMetaSourceReturn>),
        perms?: TPerms,
    ) {
        this.metaSource = metaSource || null;
        this.sourceForStoreFiltersParamsFromUserSetting =
            sourceForStoreFiltersParamsFromUserSetting || null;
        this.sourceForStoreTableColsParamsFromUserSetting =
            sourceForStoreTableColsParamsFromUserSetting || null;
        this.perms = perms || this.perms;
        makeAutoObservable(this, {
            metaSource: false,
            sourceForStoreFiltersParamsFromUserSetting: false,
            sourceForStoreTableColsParamsFromUserSetting: false,
        });
    }

    public async fetch() {
        const metaSource = this.metaSource;
        if (metaSource === null) {
            return [null, new Error('no source set')];
        }
        let data: IMetaSourceParams | null = null;
        let error: Error | null = null;

        const { opUsername } = this;
        const cacheKey = metaSource + '@' + opUsername;
        this.loading = true;

        // disable 缓存
        // if (_.isString(metaSource) && !!metaDataCache[cacheKey]) {
        //     await Promise.resolve('verbose');
        //     runInAction(() => {
        //         this.loading = false;
        //         this.inited = true;
        //         this.setMeta(metaDataCache[cacheKey]);
        //     });
        //     return [metaDataCache[cacheKey], null];
        // }

        if (_.isString(metaSource)) {
            const [d, e] = (await defaultAxios.post(metaSource, {
                opUsername: this.opUsername,
            })) as IMetaSourceReturn;
            data = d ? d.data : null;
            error = e;
        } else {
            const [d, e] = await metaSource(null, this.opUsername);
            data = d ? d.data : null;
            error = e;
        }
        runInAction(() => {
            this.loading = false;
            if (data !== null && error === null) {
                this.inited = true;
                this.setMeta(data);
                if (_.isString(metaSource)) {
                    metaDataCache[cacheKey] = data;
                }
            }
        });
        return [data, error] as const;
    }

    public async recoverTableColsParamsFromDefaultSetting() {
        return this.updateTableColsParamsFromUserSetting(
            this.originalDefaultUserSelectedCols,
        );
    }

    public async updateFiltersParamsFromUserSetting(params: string[]) {
        const metaSource = this.sourceForStoreFiltersParamsFromUserSetting;
        if (metaSource === null) {
            return [null, new Error('no source set')];
        }
        let data: IMetaSourceParams | null = null;
        let error: Error | null = null;

        this.loading = true;
        if (_.isString(metaSource)) {
            const [d, e] = (await defaultAxios.post(metaSource, {
                params,
                opUsername: this.opUsername,
            })) as IMetaSourceReturn;
            data = d ? d.data : null;
            error = e;
        } else {
            const [d, e] = await metaSource({
                params,
            });
            data = d ? d.data : null;
            error = e;
        }
        runInAction(() => {
            this.inited = true;
            this.loading = false;

            if (data !== null && error === null) {
                this.filtersParamsFromUserSetting = params
                    .map(key => this.filtersParamsAllMap[key])
                    .filter(Boolean) as any;
            }
        });
        return [data, error] as const;
    }

    public async updateTableColsParamsFromUserSetting(params: string[]) {
        const metaSource = this.sourceForStoreTableColsParamsFromUserSetting;
        if (metaSource === null) {
            return [null, new Error('no source set')];
        }
        let data: IMetaSourceParams | null = null;
        let error: Error | null = null;

        this.loading = true;
        if (_.isString(metaSource)) {
            const [d, e] = (await defaultAxios.post(metaSource, {
                params,
                opUsername: this.opUsername,
            })) as IMetaSourceReturn;
            const cacheKey = this.metaSource + '@' + this.opUsername;
            metaDataCache[cacheKey] = undefined;
            data = d ? d.data : null;
            error = e;
        } else {
            const [d, e] = await metaSource({
                params,
            });
            data = d ? d.data : null;
            error = e;
        }
        runInAction(() => {
            this.inited = true;
            this.loading = false;

            if (data !== null && error === null) {
                this.originalTableColsParamsFromUserSetting = params
                    .map(key => this.tableColsParamsAllMap[key])
                    .filter(Boolean) as any;
            }
        });
        return [data, error] as const;
    }
}

export const getLayoutConfbyWidgets: <T = any>(
    widgets: T[],
) => TSGridLayoutProp = widgets => {
    const l = widgets.length;
    const layout: number[][] = [];

    // 几个整行，比如l=5也就是有一个整行
    const fullLines = Math.floor(l / 4);
    for (let i = 0; i < fullLines; i++) {
        layout.push([6, 6, 6, 6]);
    }
    // 余下的一行有几个，比如l=5也就是余下一个
    const tailCounts = l % 4;
    const tailLine = new Array(tailCounts).fill(6);
    const restLines = [tailLine];
    if (tailCounts <= 3) {
        tailLine.push(6);
    } else {
        restLines.push([6]);
    }
    layout.push(...restLines);

    return layout;
};

export const useListMetaWithReactSnippet = <T, U>(
    defaultMeta: ListMeta,
    tableColsParamsEditable: IParamItem[],
    onColumnsUpdated?: () => void,
) => {
    const {
        tableColsParamsFromUserSetting,

        inited: metaInited,
        loading: metaLoading,
    } = defaultMeta;
    onColumnsUpdated = onColumnsUpdated || (() => void 0);
    // 自定义列表
    const [tableParamsEditorVisible, setTableParamsEditorVisible] = useState(
        false,
    );
    const [editingTableSelectedKeys, setEditingTableSelectedKeys] = useState(
        _.map(tableColsParamsFromUserSetting, item => item.key),
    );
    useEffect(() => {
        const nextEditingTableSelectedKeys = _.map(
            tableColsParamsFromUserSetting,
            item => item.key,
        );
        setEditingTableSelectedKeys(nextEditingTableSelectedKeys);
    }, [tableColsParamsFromUserSetting]);

    // 自定义筛选项
    const snippet = (
        <>
            <ParamsEditorModal
                params={tableColsParamsEditable}
                defaultSelectedKeys={editingTableSelectedKeys}
                visible={tableParamsEditorVisible}
                onSelectedKeysConfirmed={async nextTableSelectedParams => {
                    const [
                        __,
                        e,
                    ] = await defaultMeta.updateTableColsParamsFromUserSetting(
                        nextTableSelectedParams,
                    );
                    if (!e) {
                        setTableParamsEditorVisible(false);
                        onColumnsUpdated && onColumnsUpdated();
                    }
                }}
                title={
                    <span>
                        自定义列表 &nbsp;&nbsp;
                        <AntButton
                            size="small"
                            loading={metaLoading}
                            onClick={async () => {
                                const [
                                    __,
                                    e,
                                ] = await defaultMeta.recoverTableColsParamsFromDefaultSetting();
                                if (!e) {
                                    setTableParamsEditorVisible(false);
                                    onColumnsUpdated && onColumnsUpdated();
                                }
                            }}
                        >
                            恢复默认列表
                        </AntButton>
                    </span>
                }
                okText={'确认'}
                afterClose={() => setTableParamsEditorVisible(false)}
                onCancel={() => setTableParamsEditorVisible(false)}
                confirmLoading={metaLoading}
            />
        </>
    );

    return {
        inited: metaInited,
        loading: metaLoading,

        snippet,
        setTableParamsEditorVisible,
    };
};
