import React, { memo } from 'react';
import { DForm, applyConfToFormItem } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { IJobStore } from '../utils';

const DemoForm: React.FC<{
    defaultStore: IJobStore;
}> = ({ defaultStore }) => {
    const form = (
        <DForm
            defaultStore={defaultStore}
            widgetsTransformer={baiscWidgets => {
                applyConfToFormItem(baiscWidgets, 'inputSecret', {
                    sceneCode: 'crm_activation',
                });
                return baiscWidgets;
            }}
        />
    );
    return form;
};

const FinalDemoForm = memo(observer(DemoForm));
export default FinalDemoForm;
