import React, { useState, useEffect } from 'react';
import './index.scss';
import CommonDrawer from '@/components/common/drawer/index';
import { IDrawerTitleProps } from '@/components/common/drawer/drawer-title';
import { IDrawerCardListProps } from '@/components/common/drawer/drawer-card-list/index';
import { observer } from 'mobx-react-lite';
import { IDrawerTabsProps } from '@/components/common/drawer/drawer-tabs/index';
import { IDrawerCollapseProps } from '@/components/common/drawer/drawer-collapse/index';
import { defaultAxios, useNet, delay, useRefresh } from '@/utils';
import _ from 'lodash';
import CustomerActionLogNew from '@/components/common/customer-drawer/customer-action-log-new';
import CustomerInfoCard from '@/components/common/customer-drawer/customer-info-card';
import OptProductList from './opt-product-table';
import OptContractList from './opt-contract-table';
import { OptListStore, useAnyOptStore } from '../store';
import { CtxProvider } from '@/utils/context';
import AddFollowLog from '@/components/common/add-follow-log-new';
import { Menu, Timeline, Typography, Row, Icon, Collapse } from 'antd';
import LogModal from '../../../../../components/common/log-modal/index';
import moment from 'moment';
import { DDetail } from '@/components/common/editable-display/ddetail';
import { makeObjColClickable, isMultipleCell } from '@/components';
import { OptContractCreate } from './opt-contract-create';
import CollapsePanel, {
    CollapsePanelProps,
} from 'antd/lib/collapse/CollapsePanel';

const { Text, Paragraph } = Typography;

// 抽屉针对客户业务的二次封装，高度业务定制
interface ICrmOptDrawer {
    optListStore: OptListStore;
    drawerVis: boolean;
    setDrawerVis: (bool: boolean) => void;
    itemId?: string | number;
    destroyOnClose?: boolean;
    onEdit?: (data: any) => void;
    onCreat?: (data: any) => void;
    optParseTrans?: React.ReactNode;
}
interface ICrmDetailData {
    detailInfo: IDrawerCollapseProps;
    originData: any;
    // 此处可维护静态字段
    staticData: {
        opportunity_name: string;
    };
    userField: any;
    actionLogConfig: {
        arType: any[];
        followType: any[];
    };
}
export type TGetCrmDetailReturnType = NetResponse<{
    data: ICrmDetailData;
    msg: string;
    code: number;
}>;

export interface IOpDetailData {
    afterValue: string; // 之后
    beforeValue: string; // 之前
    entityField: string; // 修改方式（key）
    entityFieldDesc: string; // 修改方式（名称）
}
export interface IUserLogData {
    operatorUserName?: string; // 操作者
    operatorCode?: string; // 行为
    operatorName?: string; // 行为名
    entityCode?: string; // 对象（实体）
    entityIdDesc?: string; // 对象值
    entityDesc?: string; // 对象（名称）
    // entityValue?: string; // 对象展示值
    operatorTime?: string; // 操作时间
    operatorIp?: string;
    opLogItemDtoList?: IOpDetailData[]; // 操作内容 TODO:未定义
}

export type TGetOpLogReturnType = NetResponse<{
    data: IUserLogData[];
    msg: string;
    code: number;
}>;

async function fetchOpLog(id?: string | number): Promise<TGetOpLogReturnType> {
    if (_.isNil(id)) {
        return [null, null];
    }

    const url = '/bff/api/rest/log/user-log';
    const body = {
        entityId: id,
        entityCode: 'opportunity',
        actionType: 'update_stage',
        pageSize: 100,
        pageNum: 1,
    };
    const [d, e] = await defaultAxios.get(url, body);

    if (d === null || d.data === null) {
        return [null, e];
    }

    const data = d.data;
    const error = e as Error | null;

    return [data, error];
}

const OptDrawer: React.FC<ICrmOptDrawer> = props => {
    const {
        optListStore,
        drawerVis,
        setDrawerVis,
        itemId,
        destroyOnClose = false,
        onEdit,
    } = props;

    const { listType, defaultPerm } = optListStore;
    console.log('optListStore', optListStore);

    // const eventCode = listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    const [eventCode, setEventCode] = useState<string>('');
    const isPrivateCus = eventCode === 'PRIVATE-VIEW';
    const [drawerData, setDrawerData] = useState<any>({});
    const [followActionModalVis, setFollowActionModalVis] = useState(false);
    const [followActionInitMark, setFollowActionInitMark] = useState(false);
    const [logModalVis, setLogModalVis] = useState(false);

    const [prepared, defaultOptListStore] = useAnyOptStore({});
    const [optContractCreateTrigger, setOptContractCreateTrigger] = useState(0);

    const loadDetail = () => {
        if (drawerVis && prepared) {
            if (itemId) {
                defaultOptListStore.fetchInstantlyMutatingDataById(itemId);
            } else {
                defaultOptListStore.setMutatingData(null);
            }
        } else {
            defaultOptListStore.setMutatingData(null);
        }
    };
    useEffect(loadDetail, [drawerVis, itemId, prepared]);
    useRefresh(
        ['opt'],
        () => {
            loadDetail();
        },
        [drawerVis, itemId, prepared],
    );

    const [dataFail, setDataFail] = useState(false);
    const [mustLoading, setMustLoading] = useState(false); // 如果没有cid，就一直loading
    useEffect(() => {
        if (drawerVis) {
            setMustLoading(true);
        }
    }, [drawerVis]);

    const fetchCustomerDetail: () => Promise<
        TGetCrmDetailReturnType
    > = async () => {
        const thisCid = itemId;
        setMustLoading(true);
        if (_.isNil(itemId)) {
            return [null, null];
        }
        const { fieldAuthCode, dataAuthCode } = optListStore;

        const [d, e] = await defaultAxios.get(
            `/bff/api/rest/opt/detail?id=${itemId}&fieldAuthCode=${fieldAuthCode}&dataAuthCode=${dataAuthCode}`,
        );
        if (d === null || d.data === null) {
            setDataFail(true);
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d && d.data) {
            setMustLoading(false);
            if (
                !d.data.originData ||
                _.isEmpty(d.data.originData) ||
                !d.data.originData?.length
            ) {
                setDataFail(true);
                return [null, new Error('no auth')];
            }
            if (thisCid === itemId) {
                try {
                    // 把product折叠干掉了
                    d.data.detailInfo.collapseConfig = d.data.detailInfo.collapseConfig.filter(
                        (item: any) => item.key !== '10_product',
                    );
                } catch (e) { }
                setDataFail(false);
                setDrawerData(d.data);
            }
        }
        return [data, error];
    };
    const [error, loading, reload] = useNet(fetchCustomerDetail, {
        refreshKeys: ['opt'],
    });

    const [, , logReload, logData] = useNet(() => fetchOpLog(itemId), {
        refreshKeys: ['opt'],
    });

    useEffect(() => {
        if (drawerVis) {
            if (itemId) {
                reload();
                logReload();
            }
        } else {
            // 当客户抽屉关闭，将会清空里面的所有数据
            setDrawerData({});
        }
    }, [itemId, drawerVis]);
    const [isNewCustomer, setIsNewCustomer] = useState<number>(1);
    useEffect(() => {
        if (null === defaultOptListStore.mutatingData) {
            return;
        }
        const {
            customer_type,
            customer_status,
        } = defaultOptListStore.mutatingData;
        console.log(
            'mutatingData',
            defaultOptListStore,
            customer_type,
            defaultOptListStore.defaultPerm.getPermByTypeAndActionOriginal(
                'mine',
            )('follow-up'),
        );
        setIsNewCustomer(customer_type);
        // defaultCustomerListStore.mutatingData 里可能有customer类型 isNew
        if (customer_status !== 2) {
            setEventCode('PRIVATE-VIEW');
            // setFormEventCode('PRIVATE-EDIT');
        } else {
            setEventCode('PUBLIC-VIEW');
            // setFormEventCode('PUBLIC-EDIT');
        }
    }, [defaultOptListStore.mutatingData]);

    // const permFollow = !!defaultPerm.getPermByTypeAndAction('')('follow')
    //     .visible;
    // const type = eventCode === 'PUBLIC-VIEW' ? 'all' : 'mine';
    const permFollow = !!defaultOptListStore.defaultPerm.getPermByTypeAndActionOriginal(
        'opportunity',
    )('follow-up').visible;

    const permUpdate = !!defaultPerm.getPermByTypeAndAction('')('update')
        .visible;

    const permViewOpRecord = !!defaultPerm.getPermByTypeAndAction('')(
        'view-operate-record',
    ).visible;

    const staticData = drawerData?.staticData || {};
    const originData = drawerData?.originData
        ? drawerData?.originData[0] || {}
        : {};
    const dropMenu = (
        <Menu
            onClick={e => {
                if (e.key === '1') {
                    setLogModalVis(true);
                }
            }}
        >
            <Menu.Item key="1">查看操作记录</Menu.Item>
        </Menu>
    );
    const titleExampleConfig: IDrawerTitleProps = {
        title: (
            <>
                <span
                    className="iconfont1"
                    style={{
                        color: '#0052ff',
                        fontSize: '18px',
                        marginRight: '8px',
                    }}
                >
                    &#xe616;
                </span>
                {staticData?.opportunity_name}
            </>
        ),
        icons: [],
        tags: [],
        buttons: [],
    };

    if (permUpdate && titleExampleConfig.buttons) {
        titleExampleConfig.buttons.push({
            type: 'button',
            buttonConfig: {
                children: '编辑',
                onClick: () => {
                    // tslint:disable-next-line:no-unused-expression
                    typeof onEdit === 'function' &&
                        onEdit(
                            drawerData?.originData
                                ? drawerData?.originData[0]
                                : {},
                        );
                },
            },
        });
    }

    if (permViewOpRecord && titleExampleConfig.buttons) {
        titleExampleConfig.buttons.push({
            type: 'drop',
            dropButtonConfig: {
                overlay: dropMenu,
                buttonConfig: {
                    type: 'default',
                    style: {
                        padding: 0,
                        backgroundColor: 'rgb(244, 245, 250)',
                    },
                    children: (
                        <span
                            className="iconfont1"
                            style={{
                                fontSize: '12px',
                                transform: 'scale(0.35)',
                            }}
                        >
                            &#xe618;
                        </span>
                    ),
                },
            },
        });
    }

    if (dataFail) {
        titleExampleConfig.buttons = [];
    }

    const cardExampleConfig: IDrawerCardListProps = {
        dataSource: [
            {
                title: '客户名称',
                content: staticData?.customer_id?.relatedObj?.customer_name ? (
                    staticData?.customer_id?.originalValue ? (
                        <a
                            target="_blank"
                            href={
                                '/buy/custom/my-custom?target=opt-drawer-header&id=' +
                                staticData?.customer_id?.originalValue
                            }
                        >
                            {staticData?.customer_id?.relatedObj?.customer_name}
                        </a>
                    ) : (
                        staticData?.customer_id?.relatedObj?.customer_name
                    )
                ) : (
                    '- -'
                ),
            },
            {
                title: '赢率',
                content: staticData?.opportunity_sale_stage
                    ? staticData?.opportunity_sale_stage?.relatedObj?.win_rate +
                    '%'
                    : '- -',
            },
            {
                title: '预计签约时间',
                content: staticData?.prediction_sign_time
                    ? moment(staticData?.prediction_sign_time).format(
                        'YYYY-MM-DD HH:mm:ss',
                    )
                    : '- -',
            },
            {
                title: '预计销售金额',
                content: staticData?.prediction_sale_amount
                    ? staticData?.prediction_sale_amount + '元'
                    : '- -',
            },
            {
                title: '最新活动记录时间',
                content: staticData?.recent_activity_record_time
                    ? moment(staticData?.recent_activity_record_time).format(
                        'YYYY-MM-DD HH:mm:ss',
                    )
                    : '- -',
            },
        ],
    };
    const customExampleCollapseConfig: IDrawerCollapseProps =
        drawerData.detailInfo;
    const tabsExampleConfig: IDrawerTabsProps = {
        tabsContentConfig: {
            defaultActiveKey: '1',
            tabBarGutter: 24,
            tabConfig: [
                {
                    key: '1',
                    tab: '商机信息',
                    content: (
                        <DDetail
                            eventCode={eventCode}
                            defaultStore={defaultOptListStore}
                            cellsSelectorTransformer={originalbaiscCellsSelector => {
                                const baiscCellsSelector = [
                                    ...originalbaiscCellsSelector,
                                ];
                                _.forEach(baiscCellsSelector, cItem => {
                                    if (isMultipleCell(cItem)) {
                                        return cItem;
                                    }
                                    if (cItem.cell === 'CC') {
                                        if (cItem.inputs.length < 3) {
                                            cItem.inputs = [
                                                ...cItem.inputs,
                                                'crm_opportunity',
                                            ];
                                        } else {
                                            cItem.inputs[2] = 'crm_opportunity';
                                        }
                                        // cItem.inputs.push('crm_lead');
                                    }
                                    // if (cItem.cell === 'CC') {
                                    //     cItem.inputs.push('crm_opportunity');
                                    // }
                                });
                                makeObjColClickable(
                                    baiscCellsSelector,
                                    'customer_id',
                                );
                                makeObjColClickable(
                                    baiscCellsSelector,
                                    'lead_id',
                                );
                                return baiscCellsSelector;
                            }}
                        />
                    ),
                },
                {
                    key: '2',
                    tab: '商机产品',
                    content: (
                        <OptProductList
                            drawerData={drawerData}
                            type={'opt-drawer'}
                        />
                    ),
                },
                {
                    key: '3',
                    tab: '合同',
                    content: (
                        <OptContractList
                            onCreate={() => {
                                setOptContractCreateTrigger(
                                    1 + optContractCreateTrigger,
                                );
                            }}
                            drawerData={drawerData}
                            type={'opt-drawer'}
                        />
                    ),
                },
            ],
        },
    };

    const customerObj = staticData?.customer_id;
    const customerId = customerObj?.originalValue;
    let isCustomerInfoEditAble = false;
    if (prepared) {
        if (isPrivateCus && !!originData?.id) {
            isCustomerInfoEditAble = true;
        }
    }

    const CollapsePanel2: React.FC<CollapsePanelProps & {
        isActive?: boolean;
        permFollow?: boolean;
        setFollowLogModalVis: Function;
    }> = props => {
        const renderHeader = () => {
            console.log('actionprops', props);
            const {
                header,
                isActive,
                permFollow,
                setFollowLogModalVis,
            } = props;
            return (
                // <div className="crm-collapse-header">
                //     {props.header}
                //     <Icon
                //         style={{ color: '#D1D3DE', marginLeft: '5px' }}
                //         type="caret-right"
                //         rotate={isActive ? 90 : 0}
                //     />
                // </div>
                <p className="title">
                    <div
                        className="title-content"
                    // onClick={() => SetIsActive(!isActive)}
                    >
                        {header}
                        <Icon
                            style={{
                                color: '#D1D3DE',
                                marginLeft: '5px',
                            }}
                            type="caret-right"
                            rotate={isActive ? 90 : 0}
                        />
                    </div>
                </p>
            );
        };
        return (
            <CollapsePanel
                showArrow={false}
                {...props}
                header={renderHeader()}
            />
        );
    };

    return (
        <CtxProvider
            ctx={{
                position: 'drawer',
                positionDetail: 'opt-drawer',
                data: {
                    cid:
                        defaultOptListStore.mutatingData?.customer_id
                            ?.originalValue,
                },
            }}
        >
            <LogModal
                modalVis={logModalVis}
                setModalVis={setLogModalVis}
                entityCode="opportunity"
                entityValueId={itemId}
            />
            <OptContractCreate
                optInfo={defaultOptListStore.mutatingData}
                trigger={optContractCreateTrigger}
            />
            <CommonDrawer
                isFail={dataFail}
                destroy={destroyOnClose ? !drawerVis : false}
                loading={loading || mustLoading}
                titleConfig={titleExampleConfig}
                DrawerContentConfig={{
                    zIndex: 999,
                    placement: 'right',
                    onClose: () => {
                        setDrawerVis(false);
                    },
                    visible: drawerVis,
                }}
                extraRight={
                    <>
                        <AddFollowLog
                            isOpenPopover={false}
                            entityType={'opportunity'}
                            cid={customerId}
                            id={itemId}
                            drawerVis={followActionModalVis}
                            setDrawerVis={setFollowActionModalVis}
                            initMark={followActionInitMark}
                        />
                        {/* 新推进记录 */}
                        <Collapse
                            className="action-log-collapse"
                            bordered={false}
                            defaultActiveKey={['push-record']}
                            expandIcon={({ isActive }) => (
                                <Icon
                                    type="caret-right"
                                    rotate={isActive ? 90 : 0}
                                />
                            )}
                        >
                            <CollapsePanel2
                                header="推进记录"
                                key="push-record"
                                permFollow={permFollow}
                                setFollowLogModalVis={setFollowActionModalVis}
                            >
                                <Timeline>
                                    {logData?.data.map(item => (
                                        <Timeline.Item>
                                            <Row
                                                style={{
                                                    marginBottom: '4px',
                                                }}
                                            >
                                                <Text>
                                                    {item.operatorTime
                                                        ? moment(
                                                            item.operatorTime,
                                                        ).format(
                                                            'YYYY-MM-DD HH:mm:ss',
                                                        )
                                                        : '- -'}{' '}
                                                    {item.operatorUserName}
                                                </Text>
                                            </Row>
                                            <Row>
                                                <Paragraph
                                                    ellipsis={{
                                                        rows: 6,
                                                        expandable: true,
                                                    }}
                                                >
                                                    {item.opLogItemDtoList?.map(
                                                        log => (
                                                            <Paragraph
                                                                type="secondary"
                                                                style={{
                                                                    fontSize:
                                                                        '12px',
                                                                    marginBottom:
                                                                        '5px',
                                                                }}
                                                            >
                                                                {
                                                                    log.entityFieldDesc
                                                                }
                                                                :
                                                                {log.beforeValue
                                                                    ? `【${log.beforeValue}】更新为`
                                                                    : ''}
                                                                【
                                                                {log.afterValue}
                                                                】
                                                            </Paragraph>
                                                        ),
                                                    )}
                                                </Paragraph>
                                            </Row>
                                        </Timeline.Item>
                                    ))}
                                </Timeline>
                            </CollapsePanel2>
                        </Collapse>

                        {/* 客户信息卡片 */}
                        <CustomerInfoCard
                            entityType={'opportunity'}
                            isCustomerInfoEditAble={isCustomerInfoEditAble}
                            id={itemId}
                            customerId={customerId}
                            isNewCustomer={isNewCustomer}
                            setFollowLogModalVis={bool => {
                                setFollowActionModalVis(bool);
                                setFollowActionInitMark(true);
                                setTimeout(() => {
                                    setFollowActionInitMark(false);
                                }, 0);
                            }}
                            actionLogConfig={drawerData?.actionLogConfig}
                        />
                        {/* 新活动记录 */}
                        <CustomerActionLogNew
                            id={itemId}
                            setFollowLogModalVis={bool => {
                                setFollowActionModalVis(bool);
                                setFollowActionInitMark(true);
                                setTimeout(() => {
                                    setFollowActionInitMark(false);
                                }, 0);
                            }}
                            entityType={'opportunity'}
                            actionLogConfig={drawerData?.actionLogConfig}
                            drawerVis={drawerVis}
                            targetFixed={true}
                            defaultArType={'4'}
                            ArTypeSelectVisible={false}
                            permFollow={permFollow}
                        />
                    </>
                }
                extraLeft={<>{props.optParseTrans || null}</>}
                DrawerCardListConfig={cardExampleConfig}
                DrawerTabsConfig={tabsExampleConfig}
            />
        </CtxProvider>
    );
};

export default observer(OptDrawer);
