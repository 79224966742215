import { defaultAxios } from '@/utils';

export interface FetchSaleTagParams {
    scope?: number;
    name?: string;
    filedId?: number;
}

export interface SaleTagInfo {
    id?: number;
    label: string;
    value: number;
    selected?: boolean;
    choice?: boolean;
} 

export interface SaleTagData{
    id: number;
    fieldId: number;
    extendCode: string;
    extendValue: SaleTagInfo[];
    extendComment: string;
    createTime?: number;
    createUser?: string;
    updateTime?: number;
    updateUser?: string;
}

export interface SaveSaleTagParams {
    id: number;
    extendValue: any;
}

// 获取标签列表
export const fetchSaleTageList: (
    params?: FetchSaleTagParams,
) => Promise<SaleTagData> = async params => {
    const [d, e] = await defaultAxios.get('/crm/admin/getByFiledId', {
        ...params,
    })
    if (d === null || d.data === null) return null; 
    d.data['extendValue'] = JSON.parse(d.data['extendValue']);
    return d.data
}

// 保存标签
export async function saveSaleTag(params?: SaveSaleTagParams): Promise<any> {
    const [d, e] = await defaultAxios.post('/crm/admin/updateFiledExtend', {
        ...params,
    });
    if (d === null || d.data === null) {
        return null;
    }
    return d.data;
}

