import { defaultAxios } from '@/utils';
import { exportFile } from '../api';

// 年度汇总
export const queryAnnualSummary: () => Promise<any> = async () => {
    const [d, e] = await defaultAxios.put('/crm/data/query/annual_summary', {
        version: 2,
    });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
};

// 年度汇总导出
export const exportAnnualSummary = () => {
    return exportFile('/crm/data/query/annual_summary/import', { version: 2 });
};

// 回款数据通晒
export const queryIncomeSolarization: (
    orgId: string | number,
) => Promise<any> = async orgId => {
    const [d, e] = await defaultAxios.put(
        '/crm/data/query/income_solarization',
        { orgId, version: 2 },
    );
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
};

// 导出回款数据通晒
export const exportIncomeSolarization = (orgId: string | number) => {
    return exportFile('/crm/data/query/income_solarization/export', {
        orgId,
        version: 2,
    });
};

interface IncomeCompareParams {
    currentTimeBegin: string;
    currentTimeEnd: string;
    compareTimeBegin: string;
    compareTimeEnd: string;
    region: string;
    compareType: number;
    timeDim: number;
    orgId: string | number;
}

// 业绩对比
export const queryIncomeCompare: (
    params: IncomeCompareParams,
) => Promise<any> = async params => {
    const [d, e] = await defaultAxios.put('/crm/data/query/income_compare', {
        ...params,
        version: 2,
    });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
};

// 导出业绩对比
export const exportIncomeCompare = (params: IncomeCompareParams) => {
    return exportFile('/crm/data/query/income_compare/import', { params });
};
