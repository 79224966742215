import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    AntButton,
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
} from '@/components';
import { Modal, Icon, Tag, Spin, Alert, message, Tooltip, Popover } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, defaultAxios, isNetSuccess } from '@/utils';
import { observer } from 'mobx-react-lite';
import FormDemoTable from './components/table';
import FormDemoCard from './components/card';
import { defaultAuthDeco } from '@/containers/hoc';
import { ActionTransoform } from '@/pages/sales-follow-up/lead/list/components/transform';
import { ActionDedupDetail } from '@/pages/sales-follow-up/lead/list/components/dedup-detail';
import { CC } from '@/components/common/table/cells/cc';
import { tryNewCall } from '@/containers/hoc/call-center';
import FormDrawer from '@/pages/sales-follow-up/lead/list/components/form-drawer';
import { isInValidLead } from '@/pages/sales-follow-up/lead/list/utils';
import { TableLv2 } from '@/components/common/table/level2';
import Ccpopover from '@/components/common/cc-popover';

import './index.scss';

export interface IKPData {
    uid: number;
    username: string; // 姓名
    company: string; // 公司
    realname: string; // 实名
    position: string; // 职位
    phone: string | number; // 手机号(被隐藏)
    loginTime: string; // 最近活跃时间
    call: boolean; // 是否已解密
    memType: string;
    profileUrl: string; // profileUrl
    emobile: string;
    lastActiveDate: number; //  最近活跃时间
}

const LeadListInnerCustomerTab: React.FC<RouteComponentProps & {
    onCreate?: any;
    type?: string; // 公海池，我的线索
    isStandard: boolean; // 公海池，我的线索
    parentType: string;
    drawerData: any;
}> = ({
    onCreate,
    drawerData = {},
    parentType,
    type = 'customer-type',
    isStandard,
}) => {
    const currentCustomer = (drawerData.originData || [])[0] || {};

    const store = useContext(StoreContext);
    const [leadList] = useState(() => new store.LeadListStore());

    const [kpModalVis, setKpModalVis] = useState(false);
    const [kpUserLoading, setKpUserLoading] = useState(false);
    const [kpUserData, setKpUserData] = useState<IKPData[]>([]);
    const [popoverVisable, setPopoverVisable] = useState(false);

    leadList.setPosition('customer-drawer');
    leadList.setParentListType(parentType);
    const userStore = store.getAuthStore();

    const cUserStore = store.getDefaultCUserStore();
    const { remainCount, checkCUserDecoded, decodeCUser } = cUserStore;

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        defaultPerm,
        defaultFilter,
    } = leadList;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const LeadListFetch = useCallback(() => leadList.fetch(), []);
    const [error, loading, reload] = useNet(LeadListFetch, {
        autoLoad: false,
        refreshKeys: ['lead'],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);

    useEffect(() => {
        if (!currentCustomer.id) {
            return;
        }
        leadList.setDefaultFilters([
            {
                fieldId: 'customer_id',
                filterValue: currentCustomer.id,
                operateType: '=',
                parser: 'string',
            },
        ]);
        leadList.setDefaultFields([
            'customer_id',
            'opportunity_id',
            'contact_id',
            'lead_source',
            'lead_sub_source',
            'contacts_source',
            'lead_follow_status',
            'remark',
        ]);
        queryAllKpUser(currentCustomer.customer_name);
    }, [drawerData]);

    const queryAllKpUser = async (companyName: string) => {
        const api = '/crm/customer/mai/maimai/queryAllKpUser';
        const params = {
            companyName: companyName,
        };

        setKpUserLoading(true);
        const [d, e] = await defaultAxios.get(api, params);
        setKpUserLoading(false);
        if (isNetSuccess(d, e) && _.isArray(d?.data)) {
            setKpUserData(d?.data);
        }
    };

    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady || !currentCustomer.id) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        confirmedfilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            defaultMeta.setTableId('lead'); // TOFIX TODO: 动态设置
            defaultMeta.setOpUsername(userStore.userInfo.userId + '-' + type);
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {}); // 初始化元信息
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            // 默认未跟进
            // defaultFilter.assignFilterData({
            //     follow_status: {
            //         value: [1],
            //         isEmpty: false,
            //         widgetKey: 'filterEnum',
            //         ts: 0,
            //     },
            // });
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useEffect(() => {
        leadList.setListType(type);
    }, [type]);
    const onVisibleChange = (visable: any) => {
        setPopoverVisable(visable);
    };

    const decodePhoneHandle = async (id: number, name: string) => {
        const [d, e] = await checkCUserDecoded(id);
        if (d) {
            // 已解密
            const [data, error] = await decodeCUser(id);
            if (data) {
                // 拉起外呼
                tryNewCall(data, true, {
                    showFollowLog: true,
                    customerName: currentCustomer.customer_name,
                    customerId: currentCustomer.id,
                    userName: name,
                });
            }
        } else {
            if (remainCount <= 0) {
                message.warning(
                    '为了防止C端用户信息泄漏，您每天只有200次外呼手机号的机会，目前机会已用完！',
                );
                return;
            }
            // 未解密
            Modal.confirm({
                content: (
                    <div>
                        <p>本次外呼，将消耗1次机会</p>
                        <p>
                            您今天剩余可用机会为
                            <span style={{ color: 'red' }}>{remainCount}</span>
                            次，请确认是否外呼？
                        </p>
                    </div>
                ),
                onOk: async () => {
                    const [data, error] = await decodeCUser(id);
                    if (data) {
                        // 拉起外呼
                        tryNewCall(data, true, {
                            customerName: currentCustomer.customer_name,
                            showFollowLog: true,
                            customerId: currentCustomer.id,
                            userName: name,
                        });
                    }
                },
            });
        }
    };

    if (!preRequiredReady) {
        return <BlockLoading2 />;
    }

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={defaultFilter.confirmedfilterData}
            sorterData={leadList.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = (
        <>
            <ActionTransoform leadList={leadList} />
            <ActionDedupDetail
                leadList={leadList}
                caredLead={leadList.dupDetailLead}
            />
            <FormDrawer
                leadList={leadList}
                preRequiredReady={preRequiredReady}
                type={type}
            />
        </>
    );

    const title = (
        <div style={{ fontSize: '14px', fontWeight: 400, marginBlock: '10px' }}>
            💡提醒：至少还有{kpUserData.length}个优质KP可以跟进，详情请点击“
            <a
                onClick={() => {
                    setKpModalVis(true);
                }}
            >
                更多KP
            </a>
            ”！
        </div>
    );

    const globalOps = (
        <>
            <div>
                {defaultPerm.getPermByTypeAndAction(leadList.listType)('create')
                    .visible && (
                    <AntButton
                        onClick={() => {
                            onCreate && onCreate();
                        }}
                    >
                        新建线索
                    </AntButton>
                )}
                <AntButton
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                        setKpModalVis(true);
                    }}
                >
                    更多KP
                </AntButton>
            </div>
        </>
    );
    const dataTable = (
        <>
            <FormDemoCard isStandard={isStandard} leadList={leadList} />
            {/* <FormDemoTable
                isStandard={isStandard}
                leadList={leadList}
                loading={loading}
            /> */}
        </>
    );

    const multipleOps = (
        <div className="kp-card-small">
            {kpUserData.slice(0, 5).map(kp => (
                <div className="kp-card-small-card">
                    <div>
                        <div>
                            <span className="kp-card-small-realname">
                                <a href={kp.profileUrl} target="_blank">
                                    {kp.realname}
                                </a>
                            </span>{' '}
                            |{' '}
                            <span className="kp-card-small-position">
                                {kp.position}
                            </span>
                        </div>
                        <div className="kp-card-small-company">
                            {kp.company}
                        </div>
                    </div>
                    <Popover
                        getPopupContainer={() => {
                            return document.body as HTMLElement;
                        }}
                        style={{ zIndex: 666666 }}
                        onVisibleChange={onVisibleChange}
                        placement="right"
                        content={
                            <Ccpopover
                                uid={popoverVisable ? kp.uid : undefined}
                                whetherShow={false}
                            />
                        }
                    >
                        <Icon
                            className="kp-card-small-call"
                            type="phone"
                            onClick={() => {
                                decodePhoneHandle(kp.uid, kp.realname);
                            }}
                        />
                    </Popover>
                </div>
            ))}
        </div>
    );

    const isdrawerDataLoading = _.isEmpty(currentCustomer);
    if (isdrawerDataLoading) {
        return <BlockLoading2 />;
    }

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters: null,
        multipleOps,
        dataTable,
        message: null,
        filtersDisplay,
    };

    const handleCloseKpModal = () => {
        setKpModalVis(false);
    };

    return (
        <>
            <LayoutStandardCrud className="customer-tab" {...layoutComs} />
            <Modal
                width={'1200px'}
                visible={kpModalVis}
                onCancel={handleCloseKpModal}
                title={`${currentCustomer.customer_name} - KP推荐`}
                maskClosable={false}
                cancelButtonProps={{
                    hidden: true,
                }}
                okButtonProps={{
                    hidden: true,
                }}
                wrapClassName="cuser-modal-content"
                destroyOnClose
            >
                <Alert
                    showIcon
                    style={{
                        fontSize: '12px',
                    }}
                    type="warning"
                    message={
                        <div>
                            <p style={{ marginBottom: '0px' }}>
                                手机号需加密，无法明文展示。但是您每天有200次外呼机会，目前您的剩余可用机会为{' '}
                                <span style={{ color: 'red' }}>
                                    {remainCount}
                                </span>{' '}
                                次。（注意：同一用户一天之内外呼多次，只消耗1次机会）
                            </p>
                        </div>
                    }
                />

                <div className="cuser-table-content">
                    <TableLv2<IKPData>
                        loading={kpUserLoading}
                        closeRowSelection={true}
                        pagination={false}
                        dataSource={kpUserData}
                        scroll={{
                            y: 500,
                            x: 1000,
                        }}
                        columns={[
                            {
                                title: '姓名',
                                key: 'realname',
                                width: 120,
                                render: (item, record) => {
                                    return (
                                        <span>
                                            {item.call ? (
                                                <Tag color={'orange'}>
                                                    今日已呼
                                                </Tag>
                                            ) : null}
                                            {item.realname}
                                        </span>
                                    );
                                },
                            },
                            {
                                title: '手机号',
                                key: 'phone',
                                width: 140,
                                render: item => {
                                    return (
                                        <CC
                                            args={[item.emobile]}
                                            config={{
                                                customerId: currentCustomer.id,
                                                customerName:
                                                    currentCustomer.customer_name,
                                                userName: item.realname,
                                            }}
                                            isHidden={true}
                                            sceneCode="crm_customer"
                                            outerHiddenNumber={
                                                item.emobile.slice(0, 3) +
                                                '********'
                                            }
                                        />
                                    );
                                },
                            },
                            {
                                title: '会员类型',
                                key: 'memType',
                                dataIndex: 'memType',
                                width: 100,
                            },
                            {
                                title: '职位',
                                key: 'position',
                                dataIndex: 'position',
                                width: 120,
                            },
                            {
                                title: '所属公司',
                                key: 'company',
                                dataIndex: 'company',
                                width: 160,
                            },
                            {
                                title: '最近活跃时间',
                                key: 'lastActiveDate',
                                dataIndex: 'lastActiveDate',
                                width: 160,
                                render: v => {
                                    if (moment('2010-01-01').valueOf() > v) {
                                        return '';
                                    } else {
                                        return v
                                            ? moment(Number(v)).format(
                                                  'YYYY-MM-DD',
                                              )
                                            : '';
                                    }
                                },
                            },
                        ]}
                        // actionsWidth={130}
                        // actions={[
                        //     {
                        //         actionKey: 'transfer-contacts',
                        //         actionCn: '转联系人',
                        //         status: {
                        //             disabled: item => {
                        //                 const invalid = isInValidLead(
                        //                     item?.follow_status,
                        //                 );
                        //                 const transformedContact = !!item
                        //                     ?.contact_id?.originalValue;

                        //                 const transformedOpt = !!item
                        //                     ?.opportunity_id?.originalValue;
                        //                 return (
                        //                     invalid ||
                        //                     transformedContact ||
                        //                     transformedOpt
                        //                 );
                        //             },
                        //         },
                        //     },
                        //     {
                        //         actionKey: 'transfer-opportunity',
                        //         actionCn: '转商机',
                        //         status: {
                        //             disabled: item => {
                        //                 const invalid = isInValidLead(
                        //                     item?.follow_status,
                        //                 );
                        //                 const transformedOpt = !!item
                        //                     ?.opportunity_id?.originalValue;
                        //                 return invalid || transformedOpt;
                        //             },
                        //         },
                        //     },
                        // ]}
                        // onAction={(actionKey, data) => {
                        //     console.log('actionKey', actionKey, data);
                        //     if (actionKey === 'transfer-contacts') {
                        //     } else if (actionKey === 'transfer-opportunity') {
                        //     }
                        // }}
                    />
                </div>
            </Modal>
        </>
    );
};

const FinalLeadList = defaultAuthDeco(observer(LeadListInnerCustomerTab));
export default FinalLeadList;
