import { makeAutoObservable } from 'mobx';
import { SorterResult } from 'antd/lib/table/interface';
import _ from 'lodash';
import {
    NexusGenArgTypes,
    NexusGenInputs,
} from '@/definations/graphql/auto-gen';

export interface IMySorter {
    order: string;
    columnKey: string;
}

export class SorterStore<T = any> {
    // 排序
    public sorter: IMySorter | null = null;
    public setSorter = (sorter: IMySorter) => {
        this.sorter = sorter;
    };
    public clearSorter = () => {
        this.sorter = null;
    };
    get sortersForFetch() {
        const ret: { [key: string]: string } = {};
        if (this.sorter === null) {
            return ret;
        }
        ret[this.sorter.columnKey] = this.sorter.order;
        return ret;
    }

    get sorterArgs(): NexusGenInputs['SorterArgs'] {
        if (this.sorter === null) {
            return {};
        }

        const orderBy = this.sorter.columnKey;
        const order = this.sorter.order;
        if (!orderBy || !order) {
            return {};
        }

        const ret: any = { orderBy };
        if (this.sorter.order === 'descend') {
            ret.sortStrategy = 1;
        }
        return ret;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export const emptySorterStore = new SorterStore();
