import React from 'react';
import _ from 'lodash';
import { getCommonStatus, ICommonProps } from '../common';

export type WidgetMFollowCusGroupValueType = any[];
export interface IWidgetMFollowCusOptions {}

export interface IWidgetWidgetMFollowCusProps<T = any> extends ICommonProps<T> {
    value: WidgetMFollowCusGroupValueType;
    options: IWidgetMFollowCusOptions;
    onChange: (value: WidgetMFollowCusGroupValueType) => void;
}

export const WidgetMFollowCus: React.FC<IWidgetWidgetMFollowCusProps> = ({
    value,
}) => {
    if (!_.isArray(value)) {
        return <span>Error</span>;
    }
    return <div>共选择了{value.length}个客户，将为这些客户批量新建任务</div>;
};
