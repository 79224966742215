import _ from 'lodash';
import { AuthStore } from '@/stores/auth';
import { defaultAxios, isNetSuccess } from '@/utils';

export const processDFConfs: { [entityCode: string]: any } = {
    process: {
        lv4MenuFilters: {
            category: [
                async (options: { authStore: AuthStore }) => {
                    const [d, e] = await defaultAxios.get(
                        '/crm/workflow/processdef/list?hash=' +
                            Date.now() +
                            '-' +
                            Math.random(),
                    );

                    return (d?.data || []).map((item: any) => {
                        return {
                            key: item.formKey,
                            label: item.name,
                            value: [],
                            originalItem: item,
                        };
                    });
                },
            ],
            all: [
                // '/crm/workflow/taskinstances/query'
                // '/crm/workflow/instances/query'
                async (options: { authStore: AuthStore }) => {
                    // let processStatistics: any = {}
                    // const userUd = options.authStore?.userInfo?.userId
                    // if (userUd) {

                    //     const [d, e] = await defaultAxios.get('/bff/api/rest/home/home-process-data', {
                    //         currentUser: userUd
                    //     })
                    //     if (isNetSuccess(d, e)) {
                    //         const data = d?.data
                    //         processStatistics = data
                    //     }
                    // }
                    return [
                        {
                            key: 'my-applicant-todo',
                            label: '待提交',
                            value: [],
                            group: '我提交的',
                            extra: {
                                role: 'applicant',
                                api: '/crm/workflow/instances/query',
                                payload: {
                                    isFinish: false,
                                    isFirst: true,
                                },
                                // statistic: processStatistics.publishWait
                            },
                        },
                        {
                            key: 'my-applicant-ing',
                            label: '审批中',
                            value: [],
                            group: '我提交的',
                            extra: {
                                role: 'applicant',
                                api: '/crm/workflow/instances/query',
                                payload: {
                                    isFinish: false,
                                    isFirst: false,
                                },
                                // statistic: processStatistics.publishUnfinished
                            },
                        },
                        {
                            key: 'my-applicant-done',
                            label: '已完成',
                            value: [],
                            group: '我提交的',
                            extra: {
                                role: 'applicant',
                                api: '/crm/workflow/instances/query',
                                payload: {
                                    isFinish: true,
                                },
                                // statistic: processStatistics.publishFinished
                            },
                        },
                        {
                            key: 'my-deal-todo',
                            label: '待审批',
                            value: [],
                            group: '待我审批的',
                            extra: {
                                role: 'currentUser',
                                api: '/crm/workflow/taskinstances/query',
                                payload: {
                                    isTodo: true,
                                    isDone: false,
                                    isFirst: false,
                                },
                                // statistic: processStatistics.unFinished
                            },
                        },
                        {
                            key: 'my-deal-done',
                            label: '已完成',
                            value: [],
                            group: '待我审批的',
                            extra: {
                                role: 'currentUser',
                                api: '/crm/workflow/taskinstances/query',
                                payload: {
                                    isTodo: false,
                                    isDone: true,
                                    isFirst: false,
                                },
                                // statistic: processStatistics.finished
                            },
                        },
                        {
                            key: 'my-task-all',
                            label: '全部流程',
                            value: [],
                            group: '全部流程',
                            extra: {
                                role: 'currentUser',
                                api: '/crm/workflow/taskinstances/query',
                                payload: {
                                    isTodo: true,
                                    isDone: true,
                                },
                                // statistic: processStatistics.all,
                            },
                        },
                    ];
                },
            ],
        },
        fastFilters: {
            category: [],
            all: [],
        },
    },
};
