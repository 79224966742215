import React from 'react';
import _ from 'lodash';

import './index.less';

export type AchievColors = 'blue' | 'cyan' | 'orange';

interface AchievCardProps {
    title: string;
    color?: AchievColors;
    info: AchievCardInfo;
}

interface AchievCardInfo {
    completion_rate: string;
    income: string;
    link_relative_ratio: string;
    target: string;
    yoy: string;
}

const colorsMap = {
    blue: 'linear-gradient(180deg, #eaf1ff 2%, rgba(255, 255, 255, 0) 96%)',
    cyan: 'linear-gradient(180deg, #e6f8ff 2%, rgba(255, 255, 255, 0) 96%)',
    orange: 'linear-gradient(180deg, #ffeee9 2%, rgba(255, 255, 255, 0) 96%)',
};

const AchievCard: React.FC<AchievCardProps> = props => {
    const { title, color = 'cyan', info } = props;

    const renderRate = (t?: string) => {
        const text = Number(t);

        if (_.isNaN(text)) {
            return <div className="achiev-card-item-value rate">-</div>;
        }

        return Number(text) > 0 ? (
            <div className="achiev-card-item-value rate red">
                +{Number(text).toFixed(2)}%
            </div>
        ) : (
            <div className="achiev-card-item-value rate green">
                {Number(text).toFixed(2)}%
            </div>
        );
    };

    return (
        <div className="achiev-card">
            <div
                className="achiev-card-title"
                style={{
                    background: colorsMap[color],
                }}
            >
                {title}
            </div>
            <div className="achiev-card-list">
                <div className="achiev-card-item">
                    <div className="achiev-card-item-label">目标</div>
                    <div className="achiev-card-item-value">
                        {Math.round(Number(info?.target || 0))}w
                    </div>
                </div>
                <div className="achiev-card-item">
                    <div className="achiev-card-item-label">回款</div>
                    <div className="achiev-card-item-value">
                        {Math.round(Number(info?.income || 0))}w
                    </div>
                </div>
                <div className="achiev-card-item">
                    <div className="achiev-card-item-label">完成率</div>
                    <div className="achiev-card-item-value">
                        {Number(info?.completion_rate || 0).toFixed(1)}%
                    </div>
                </div>
                <div className="achiev-card-item">
                    <div className="achiev-card-item-label">同比</div>
                    {renderRate(info?.yoy)}
                </div>
                <div className="achiev-card-item">
                    <div className="achiev-card-item-label">环比</div>
                    {renderRate(info?.link_relative_ratio)}
                </div>
            </div>
        </div>
    );
};

export default AchievCard;
