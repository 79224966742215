import { callsites } from '@/utils';
import _ from 'lodash';
import { makeAutoObservable } from 'mobx';

export class PaginationStore {
    // 分页
    public pagenum = 1;
    public pagesize = 100;
    public amount = 0;

    public cacheKey = '';
    get paginationForFetch() {
        return {
            offset: (this.pagenum - 1) * this.pagesize,
            limit: this.pagesize,
        };
    }
    get paginationArgs() {
        return {
            pageSize: this.pagesize,
            pageNum: this.pagenum,
        };
    }
    get paginationForUI() {
        return {
            pageSize: this.pagesize,
            current: this.pagenum,
        };
    }
    public setPaginationByUI = ({
        current,
        pageSize,
    }: {
        current?: number;
        pageSize?: number;
    }) => {
        if (!_.isNil(current)) {
            this.pagenum = current;
        }
        if (!_.isNil(pageSize)) {
            this.pagesize = pageSize;
            try {
                if (_.isString(this.cacheKey)) {
                    localStorage.setItem(
                        this.cacheKey,
                        JSON.stringify({ pageSize }),
                    );
                }
            } catch (e) {}
        }
    };

    public setAmount(nextAmount: number) {
        this.amount = nextAmount;
    }

    constructor() {
        try {
            const stacks = callsites();
            let caller: any = null;
            for (const s of stacks.slice(1)) {
                if (s.isConstructor()) {
                    caller = s;
                    break;
                }
            }
            if (caller) {
                this.cacheKey = 'pagination' + ':' + caller.getFunctionName();
                const raw = localStorage.getItem(this.cacheKey);
                if (raw) {
                    const parsed = JSON.parse(raw);
                    const pageSize = parsed?.pageSize;
                    if (pageSize) {
                        this.pagesize = pageSize;
                    }
                }
            }
        } catch (e) {}
        makeAutoObservable(this);
    }
}

const ps = new PaginationStore();
export type TPaginationStoreInstance = typeof ps;
