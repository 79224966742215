import moment from 'moment';
import _ from 'lodash';

export const genDaysAgoFilterFactory = (options: {
    key?: string;
    fastFilterKey?: string;
    label: string;
    fieldId: string;
    days: number;
}): any => {
    const { key, fastFilterKey, label, fieldId, days } = options;
    return async () => {
        return {
            key,
            fastFilterKey,
            label,
            value: [
                {
                    condition: {
                        fieldId,
                        filterValue: moment()
                            .subtract(days, 'days')
                            .startOf('day')
                            .format('YYYY-MM-DD HH:mm:ss'),
                        operateType: '>=',
                    },
                },
            ],
        };
    };
};
