import React, { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import './index.less';

interface DialogProps {
    visible: boolean;
    style?: React.CSSProperties;
    className?: string;
    width?: string | number;
}

export const Dialog = React.forwardRef<
    HTMLDivElement,
    PropsWithChildren<DialogProps>
>(({ visible, className, style, width = 550, children }, ref) => {
    return createPortal(
        visible && (
            <div className="crm-dialog-wrap">
                <div
                    style={{ width, ...style }}
                    ref={ref}
                    className={`crm-dialog ${className || ''}`}
                >
                    {children}
                </div>
            </div>
        ),
        document.body,
    );
});

export default Dialog;
