import React, { useState, useEffect } from 'react';
import './index.less';
import { TableLv2 } from '@/components/common/table/level2';
import moment from 'moment';
import { defaultAxios, netLog } from '@/utils';
import { message } from 'antd';
import { LayoutStandardCrud } from '@/components';
export interface IDistributeLogData {
    operatorName: string;
    operateDateTime: string;
    executeStatus: string;
    importToken: string;
}
const DistributeLogTab: React.FC = () => {
    const [distributeLogData, setDistributeLogData] = useState<
        IDistributeLogData[]
    >();
    const [distributeLogLoading, setDistributeLogLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalSize, setTotalSize] = useState(0);
    const exportLog = async (payloadData: {
        import_token: string | number;
        operate_time: string;
    }) => {
        const url = '/bff/api/rest/distribution-customer/export';
        window.open(
            `${url}?token=${payloadData.import_token}&resultTime=${
                payloadData.operate_time ? payloadData.operate_time : ''
            }`,
        );
    };
    const getLogData = async () => {
        const url = '/crm/import/batch/getAllRecord';
        setDistributeLogLoading(true);
        const tempNumber = pageNumber;
        const [d, e] = await defaultAxios.post(url, {
            pageSize: 30,
            pageNum: pageNumber,
        });
        if (pageNumber != tempNumber) {
            // 时序
            return;
        }
        setDistributeLogLoading(false);
        if (d === null || d.data === null) {
            // netLog({
            //     linkUrl: url,
            //     method: 'post',
            //     payload: {},
            //     isError: true,
            //     errorMsg: e?.msg || '',
            // });
            message.error('获取分配记录失败～');
            return false;
        }
        const data = d.data;
        if (Array.isArray(data.data)) {
            setDistributeLogData(data.data);
            setTotalSize(data.totalSize);
        } else {
            setDistributeLogData([]);
            setTotalSize(0);
        }
    };
    useEffect(() => {
        getLogData();
    }, [pageNumber]);
    return (
        <div className="dc-content distribute-log-tab-content">
            <LayoutStandardCrud
                message={null}
                popups={null}
                title={null}
                globalOps={null}
                filters={null}
                multipleOps={null}
                dataTable={
                    <TableLv2<IDistributeLogData>
                        // style={{ maxHeight: '600px' }}
                        dataSource={distributeLogData}
                        loading={distributeLogLoading}
                        closeRowSelection={true}
                        actions={[
                            {
                                actionCn: '下载执行结果',
                                actionKey: 'download-result',
                                isVisible: data => {
                                    return data.executeStatus !== '执行中';
                                },
                            },
                        ]}
                        pagination={{
                            current: pageNumber,
                            pageSize: 30,
                            showSizeChanger: false,
                            total: totalSize,
                        }}
                        onPaginationChange={({ current }) => {
                            setPageNumber(current);
                        }}
                        onAction={(actionKey, record) => {
                            if (actionKey === 'download-result') {
                                exportLog({
                                    import_token: record.importToken,
                                    operate_time: record.operateDateTime,
                                });
                            }
                        }}
                        isFixedActions={false}
                        actionWidth={120}
                        scroll={{ x: '100%', y: 600 }}
                        columns={[
                            {
                                dataIndex: 'id',
                                title: '序号',
                                key: 'id',
                                render: (t, r, i) => {
                                    return i;
                                },
                            },
                            {
                                dataIndex: 'operatorName',
                                title: '操作人',
                                key: 'operatorName',
                            },
                            {
                                dataIndex: 'operateDateTime',
                                title: '操作时间',
                                key: 'operateDateTime',
                                render: v => {
                                    return v ? v : '';
                                },
                            },
                            {
                                dataIndex: 'executeStatus',
                                title: '执行状态',
                                key: 'executeStatus',
                            },
                        ]}
                    />
                }
            />
        </div>
    );
};

export default DistributeLogTab;
