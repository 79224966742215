import React, { useEffect, useState, useContext, CSSProperties } from 'react';
import './index.less';
import { observer } from 'mobx-react-lite';
import { defaultAxios, isNetSuccess } from '@/utils';
import _ from 'lodash';
import moment from 'moment';
import { StoreContext } from '@/stores';
import { Spin, Statistic } from 'antd';
import { entityCodeToUrl } from '../../store';
import qs from 'querystring';
import CustomerFormDrawer from '@/pages/sales-follow-up/custom/list/components/form-drawer';
import { runInAction } from 'mobx';
const HomeMineCustomerInfo: React.FC = observer(() => {
    const store = useContext(StoreContext);
    const [customerList] = useState(() => new store.CustomerListStore('mine'));
    const { defaultMeta, defaultPerm } = customerList;
    const homeNewStore = store.getDefaultHomeNewStore();
    const authStore = store.getAuthStore();
    const { userInfo } = authStore;
    useEffect(() => {
        if (userInfo) {
            runInAction(() => {
                defaultMeta.setTableId('customer');
                defaultMeta.setOpUsername(userInfo.userId + '-' + 'mine');
                // defaultFastFilters.setFilters(dfilters);
            });
            Promise.all([
                defaultPerm.fetch(),
                defaultMeta.fetch(),
            ]).then(() => {}); // 初始化元信息
        }
    }, [userInfo]);
    const {
        // fetchHomePageCustomerInfo,
        // homeNewCustomerInfoData,
        // homeNewCustomerInfoLoading,
        homeNewSelectedData,
        homeNewRightInfoLoading,
        homeNewRightInfoData,
    } = homeNewStore;
    const homeNewCustomerInfoData = homeNewRightInfoData?.customerJumpInfo;
    const isSelf =
        homeNewSelectedData.selectedLevel === 4
            ? homeNewSelectedData.selectedId === userInfo?.sysUser?.id
            : false;
    // useEffect(() => {
    //     fetchHomePageCustomerInfo();
    // }, []);
    const statisticValueStyle: CSSProperties = {
        fontSize: '16px',
        color: '#0052ff',
        fontWeight: 500,
    };
    const goToCustomerPage = (key: string, type?: string, keyType?: string) => {
        const body: any = {};
        if (type) {
            body.fastfilter = type;
        }
        if (keyType) {
            body.filterKey = keyType;
        }
        window.open(entityCodeToUrl[key] + '?' + qs.stringify(body), '_blank');
    };
    const setLocalStorageUserByKey = (key: string) => {
        localStorage.setItem(
            key,
            JSON.stringify(
                _.map(homeNewRightInfoData.userInfoList, uItem => {
                    return {
                        id: uItem.userId,
                        key: `user-${uItem.userId}`,
                        name: uItem.userName,
                        title: uItem.userName,
                    };
                }),
            ),
        );
    };
    return (
        <>
            <CustomerFormDrawer customerList={customerList} type={'mine'} />
            <div className="home-new-right-info-content">
                <Spin spinning={homeNewRightInfoLoading}>
                    <div className="crm-home-customer-info">
                        <p className="home-page-title">
                            客户管理
                            <span
                                className="home-page-customer-create"
                                onClick={() => {
                                    runInAction(() => {
                                        customerList.setAction('create');
                                        customerList.startNewData();
                                        if (customerList.mutatingData) {
                                            customerList.mutatingData.source = 1;
                                        }
                                    });
                                }}
                            >
                                新建客户
                            </span>
                        </p>
                        <div className="home-customer-info-body">
                            <div
                                className="customer-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        'today-experted-customer-fk',
                                    );
                                    goToCustomerPage(
                                        'to_release_customer',
                                        'today-experted-customer',
                                        'today-experted-customer-fk',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewCustomerInfoData?.todayWaitRecycle ||
                                        '0'
                                    }
                                />
                                <p className="customer-info-label">
                                    今日待收回
                                </p>
                            </div>
                            <div
                                className="customer-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        'toweek-experted-customer-fk',
                                    );
                                    goToCustomerPage(
                                        'to_release_customer',
                                        undefined,
                                        'toweek-experted-customer-fk',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewCustomerInfoData?.day7WaitRecycle ||
                                        '0'
                                    }
                                />
                                <p className="customer-info-label">7日待回收</p>
                            </div>
                            {isSelf && (
                                <div
                                    className="customer-info-item"
                                    onClick={() => {
                                        goToCustomerPage(
                                            'customer',
                                            '3days-gaoqian',
                                        );
                                    }}
                                >
                                    <Statistic
                                        valueStyle={statisticValueStyle}
                                        value={
                                            // 3000
                                            homeNewCustomerInfoData?.publicHighSeaHighPotential ||
                                            '0'
                                        }
                                    />
                                    <p className="customer-info-label">
                                        公海高潜
                                    </p>
                                </div>
                            )}
                            <div
                                className="customer-info-item"
                                onClick={() => {
                                    setLocalStorageUserByKey(
                                        'mine_cooperation_customer',
                                    );
                                    goToCustomerPage(
                                        'private_customer',
                                        '',
                                        'mine_cooperation_customer',
                                    );
                                }}
                            >
                                <Statistic
                                    valueStyle={statisticValueStyle}
                                    value={
                                        // 3000
                                        homeNewCustomerInfoData?.cooperateCustomer ||
                                        '0'
                                    }
                                />
                                <p className="customer-info-label">合作客户</p>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </>
    );
});
export default HomeMineCustomerInfo;
