import React, {
    CSSProperties,
    PropsWithChildren,
    Children,
    ReactNode,
    useState,
    useEffect,
} from 'react';
import {
    Widgets,
    WidgetsKeys,
    IWidgetsOptions,
    IWidgetsValueTypes,
    ICommonStatus,
    getCommonStatus,
    InnerInteractCallback,
    ICommonProps,
} from './widgets';
import _ from 'lodash';
import { Form, Icon, Tooltip, message } from 'antd';
import { FormItemProps, FormProps } from 'antd/lib/form';
import { arrToJson } from '@/utils';
import classname from 'classname';
import './index.scss';

import {
    SGrid,
    TSGridLayoutProp,
    TRowGultter,
} from '@/components/layouts/grid';
import { runInAction } from 'mobx';
import {
    AntCollapse2,
    IAntCollapseProps2,
    IAntCollapseConfig,
} from '@/components/antd';
import { ICell, TCellsSelector, TCell, isMultipleCell } from '../table';
import { observer } from 'mobx-react-lite';

const defaultFormItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export interface ICatchGroupKeysItems {
    [key: string]: {
        groupClassName?: string;
        afterNum?: number; // 将会添加到第几个form-items后面
        visible?: boolean;
        groupTitle?: string | any; // 头
    };
}
export interface IItemOptionItem<O, K extends keyof O = keyof O>
    extends FormItemProps {
    key: K;
    className?: string;
    style?: CSSProperties;
    labelWidth?: number;
}
export interface IWidgetItem<
    O,
    E = any,
    K extends keyof O = keyof O,
    T extends WidgetsKeys = WidgetsKeys
>
    extends IWidgetsOptions,
        IWidgetsValueTypes,
        Pick<
            ICommonProps<O, E>,
            | 'nextlineWidget'
            | 'prevlineWidget'
            | 'beforeLabel'
            | 'afterLabel'
            | 'beforeWidget'
            | 'afterWidget'
            | 'widgetClassName'
            | 'WrapperWidget'
        > {
    key: K;
    widgetKey: T;
    status?: ICommonStatus<O, E>;
    onInteract?: InnerInteractCallback;
    collapseKey?: string; // 折叠key
    wrapperKey?: string; // 分组key
    formItemClassName?: string; // 外层items容器上添加的classname
}

export type CommonFormOnInteract<T> = (
    key: keyof T,
    type: string,
    value?: any,
) => void;

export type CommonFormOnChange<
    O,
    K extends keyof O = keyof O,
    KT = O[K],
    T extends WidgetsKeys = WidgetsKeys
> = (key: K, value: KT, widgetKey: T) => void;

type CommonLayout = 'default' | 'inline' | number[][];
type FFormProps = { className?: string; style?: CSSProperties } & FormProps;
export type TCollpaseLayout = Array<{
    groupKey: string;
    groupName: string;
    groupSpan: number;
    layout?: TSGridLayoutProp;
    hidden?: boolean;
}>;

export interface IFormLV1Props<T, E = any> {
    style?: CSSProperties;
    className?: string;
    // 数据
    data: Nilable<T>;
    // 额外数据
    statusExtraData?: E;
    // 插件配置
    widgets: Array<IWidgetItem<T, E>>;
    // 数据变化回调函数
    onChange: CommonFormOnChange<T>;
    // 组件交互回调函数
    onInteract?: CommonFormOnInteract<T>;
    // antd Form.Item的设置
    formItemOptions?: Array<IItemOptionItem<T>>;
    // antd Form的设置
    formOptions?: FFormProps;
    // label部分宽度
    labelWidth?: 'auto' | number;
    // 数据验证结果
    validateResult?: Nilable<ValidateResult<T>>;
    // 布局
    layout?: 'inline' | TSGridLayoutProp;
    rowGutter?: TRowGultter;
    // collapse布局，因为grid和collapses布局是可以搭配使用的
    // 如果grid触发，那么就是行
    collapseProps?: IAntCollapseProps2;
    collapseLayout?: TCollpaseLayout;
    catchGroupProps?: ICatchGroupKeysItems;
}

// 将数据嵌入插件配置
export const embedDataToWidgets: <T>(
    widgets: Array<IWidgetItem<T>>,
    data: T | undefined | null,
) => void = (widgets, data) => {
    if (_.isNil(data)) {
        return;
    }
    for (const widgetItem of widgets) {
        const dataKey = widgetItem.key;

        // 自动将数据值填入到对应的插件值中
        // 外部有类型限制，内部肮脏点无所谓了
        const wKey = widgetItem.widgetKey;
        (widgetItem as any)[wKey + 'Value'] = (data as any)[dataKey] as any;
    }
};

// 偷偷修改某个key对应的widget
export const applyCustomConfToWidget: <T = any>(
    widgets: Array<IWidgetItem<T>>,
    key: string,
    genNextWidget: (prevWidget: IWidgetItem<T>) => IWidgetItem<T>,
) => void = (widgets, key, genNextWidget) => {
    let index = 0;
    for (const widget of widgets) {
        if (widget.key === key) {
            break;
        }
        index++;
    }
    if (index === widgets.length) {
        return;
    }
    widgets[index] = genNextWidget(widgets[index]);
};

// 将某个widgetKey对应的所有widget的config修改
export const applyConfToFormItem: <T = any>(
    widgets: Array<IWidgetItem<T>>,
    key: WidgetsKeys,
    config: any,
) => void = (widgets, key, config) => {
    _.forEach(widgets, w => {
        if (w.widgetKey === key) {
            (w as any)[key + 'Options'] = config;
        }
    });
};

export const getDefaultCellSelector = (key: string): ICell<any> => {
    return {
        colKey: key,
        cell: 'Echo',
        inputs: [key],
    };
};

export const applyCustomConfToDefaultCellSelector: <T = any>(
    cs: TCellsSelector<T>,
    key: string,
) => void = (cs, key) => {
    let index = 0;
    for (const c of cs) {
        if (c.colKey === key) {
            break;
        }
        index++;
    }
    if (index === cs.length) {
        // 默认cellSelector
        const newCellSelector: ICell<any> = getDefaultCellSelector(key);
        cs.push(newCellSelector);
        return;
    }
    cs[index] = getDefaultCellSelector(key);
};

// 偷偷修改某个key对应的cellsSelector
export const applyCustomConfToCellsSelector: <T = any>(
    cs: TCellsSelector<T>,
    key: string,
    genNextCellSelector: (prevWidget: TCell<T>) => TCell<T>,
) => void = (cs, key, genNextCellSelector) => {
    let index = 0;
    for (const c of cs) {
        if (c.colKey === key) {
            break;
        }
        index++;
    }
    if (index === cs.length) {
        // 默认cellSelector
        const newCellSelector: ICell<any> = getDefaultCellSelector(key);
        cs.push(genNextCellSelector(newCellSelector));
        return;
    }
    cs[index] = genNextCellSelector(cs[index]);
};

export const makeObjColClickable = (
    baiscCellsSelector: TCellsSelector<any>,
    paramName: string,
) => {
    applyCustomConfToCellsSelector(
        baiscCellsSelector,
        paramName,
        cellSelector => {
            const nextCellSelector = _.cloneDeep(cellSelector);
            if (isMultipleCell(nextCellSelector)) {
                return nextCellSelector;
            }
            const inputs = nextCellSelector.inputs;
            if (inputs.length === 2) {
                inputs.push({
                    clickable: true,
                });
            } else if (inputs.length === 3) {
                inputs[2] = _.isObject(inputs[2]) ? inputs[2] : {};
                inputs[2].clickable = true;
            }
            return nextCellSelector;
        },
    );
};

export const getAutoOnChangeHandlerOriginal: <T>(
    data: T,
    actualHandler: (d: T) => void,
) => CommonFormOnChange<T> = (data, actualHandler) => {
    return (key, value, widget) => {
        const nextData = _.cloneDeep(data);
        nextData[key] = value;
        actualHandler(nextData);
    };
};

export const getAutoOnChangeHandler: <T>(
    data: T,
    actualHandler: (d: T) => void,
) => CommonFormOnChange<T> = (data, actualHandler) => {
    return (key, value, widget) => {
        const nextData = data;
        runInAction(() => {
            nextData[key] = value;
        });
        actualHandler(nextData);
    };
};

export const WidgetProxy: React.FC = observer((props: any) => {
    const { WCom, k, data } = props;
    if (!WCom || !k || !data) {
        return null;
    }
    const value = data[k];
    // data 为 defaultStore.mutatingData , k 为 key
    return <WCom value={value} {...props} />;
});

export const FormLV1: <T, E = any>(
    props: PropsWithChildren<IFormLV1Props<T, E>>,
) => React.ReactElement | null = ({
    data,
    widgets,
    onChange,
    onInteract,
    formItemOptions,
    statusExtraData,
    formOptions,
    validateResult,
    layout,
    style,
    className,
    labelWidth = 'auto',
    children: inputChildren,
    rowGutter,
    collapseLayout,
    collapseProps,
    catchGroupProps,
}) => {
    console.log('inner update', 1);

    if (_.isNil(data)) {
        return null;
    }
    collapseLayout =
        (collapseLayout || []).length === 0 ? undefined : collapseLayout;
    const useSGrid = layout !== undefined && layout !== 'inline';
    // formItemOptions 保存 key 与 label(表单项标签) 的对应
    const formItemPropsMap = arrToJson(formItemOptions || [], 'key');
    const formProps = formOptions || {};
    if (layout === 'inline') {
        formProps.layout = 'inline';
    }

    const embededChildren: React.ReactNode[] = [];
    if (_.isArray(inputChildren)) {
        embededChildren.push(...inputChildren);
    } else {
        embededChildren.push(inputChildren);
    }

    // key => collpaseKey
    const key2CollpaseKeyMap: { [key: string]: string } = {};
    for (const item of widgets) {
        const { collapseKey, key } = item;
        console.log('fasfaefafaefa', collapseKey, key);
        if (collapseKey) {
            key2CollpaseKeyMap[key as string] = collapseKey;
        }
    }

    return (
        <div
            style={style}
            className={
                classname({
                    'component-form-lv1': true,
                    'component-form-lv1-grid': useSGrid,
                }) +
                ' ' +
                (className || '')
            }
        >
            <Form {...formProps}>
                {(() => {
                    const children: ReactNode[] = _.map(widgets, widgetItem => {
                        const wKey = widgetItem.widgetKey;
                        const WCom = Widgets[wKey];
                        const {
                            key,
                            widgetKey,
                            status,
                            beforeLabel,
                            afterLabel,
                            beforeWidget,
                            afterWidget,
                            WrapperWidget,
                            nextlineWidget: NextlineWidget,
                            prevlineWidget: PrevlineWidget,
                            widgetClassName,
                            wrapperKey,
                            formItemClassName = '',
                        } = widgetItem;

                        const options =
                            (widgetItem as any)[widgetKey + 'Options'] || {};

                        const { hidden, disabled } = getCommonStatus(
                            status,
                            data,
                            statusExtraData,
                        );
                        if (hidden) {
                            return null;
                        }

                        const finalOnChange = (v: any) => {
                            onChange(key, v, widgetKey);
                        };
                        const finalOnInteract = (type: string, value: any) => {
                            if (onInteract) {
                                onInteract(key, type, value);
                            }
                        };
                        const props = {
                            k: key,
                            key,
                            onChange: finalOnChange,
                            onInteract: finalOnInteract,
                            options,
                            status,
                            data,
                            statusExtraData,
                            WCom,
                        } as any;

                        let formItemProps = formItemPropsMap[key as string]
                            ? _.cloneDeep(
                                  formItemPropsMap[key as string] as any,
                              )
                            : undefined;

                        if (!_.isNil(formItemProps)) {
                            // 表单验证样式
                            if (validateResult && validateResult[key]) {
                                const vResultForKey = validateResult[key];
                                if (vResultForKey) {
                                    if (vResultForKey.msg) {
                                        formItemProps.help = vResultForKey.msg;
                                    }
                                    if (vResultForKey.status === 'fail') {
                                        formItemProps.validateStatus = 'error';
                                    }
                                    if (vResultForKey.status === 'warning') {
                                        formItemProps.validateStatus =
                                            'warning';
                                    }
                                }
                            }
                        } else {
                            formItemProps = {
                                colon: false,
                                label: '',
                            };
                        }

                        if (formItemProps.label) {
                            formItemProps.label = (
                                <span className="form-item-inner-label">
                                    {beforeLabel || null}
                                    {formItemProps.label || null}
                                    {afterLabel || null}
                                </span>
                            );
                        }

                        return (
                            <Form.Item
                                className={`${'form-item-inner-label-width-' +
                                    (formItemProps.labelWidth
                                        ? formItemProps.labelWidth
                                        : labelWidth)} ${formItemClassName} ${
                                    wrapperKey
                                        ? `!!wrapper-${wrapperKey}!!`
                                        : ''
                                } ${disabled ? 'form-item-disabled' : ''}`}
                                {...formItemProps}
                            >
                                {PrevlineWidget ? <PrevlineWidget /> : null}
                                <span
                                    className={`form-item-inner-wrapper ${widgetClassName}`}
                                >
                                    <span>{beforeWidget}</span>
                                    {WrapperWidget ? (
                                        WrapperWidget(
                                            <span className="form-item-inner-main">
                                                <WidgetProxy {...props} />
                                            </span>,
                                        )
                                    ) : (
                                        <span className="form-item-inner-main">
                                            <WidgetProxy {...props} />
                                        </span>
                                    )}
                                    <span>{afterWidget}</span>
                                </span>
                                {NextlineWidget ? <NextlineWidget /> : null}
                            </Form.Item>
                        );
                    });

                    children.push(...embededChildren);
                    if (catchGroupProps) {
                        return (
                            <AutoCatchKeyGroup
                                catchGroup={catchGroupProps}
                                allItems={children}
                            />
                        );
                    }

                    if (layout && !_.isString(layout)) {
                        if (!collapseLayout) {
                            return (
                                <SGrid rowGutter={rowGutter} layout={layout}>
                                    {children}
                                </SGrid>
                            );
                        } else {
                            // 这个分支run的不太对！
                            // message.warn('这个分支run的不太对！')
                            return (
                                <SGrid
                                    rowRender={guys => {
                                        return (
                                            <AutoCollapse
                                                collapseProps={collapseProps}
                                                validateResult={validateResult}
                                                key2CollpaseKeyMap={
                                                    key2CollpaseKeyMap
                                                }
                                                guys={guys}
                                                layouts={collapseLayout || []}
                                            />
                                        );
                                    }}
                                    rowGutter={rowGutter}
                                    layout={layout}
                                >
                                    {children}
                                </SGrid>
                            );
                        }
                    }

                    if (collapseLayout) {
                        return (
                            <AutoCollapse
                                collapseProps={collapseProps}
                                validateResult={validateResult}
                                key2CollpaseKeyMap={key2CollpaseKeyMap}
                                guys={children}
                                layouts={collapseLayout}
                            />
                        );
                    }

                    return children;
                })()}
            </Form>
        </div>
    );
};

const AutoCatchKeyGroup: React.FC<{
    catchGroup: ICatchGroupKeysItems;
    allItems: ReactNode[];
}> = ({ catchGroup, allItems = [] }) => {
    console.log(allItems);
    const allItemsA = [...allItems];

    // 分开不同组
    const wrapperItems: {
        [key: string]: ReactNode[];
    } = {};
    _.forEach(allItems, (item, idx) => {
        if (item && (item as React.ReactElement)?.props?.className) {
            const WrapperClassName = (item as React.ReactElement).props
                .className;
            if (WrapperClassName.indexOf('!!wrapper') !== -1) {
                const wrapperKey = WrapperClassName.split(
                    '!!wrapper-',
                )[1].split('!!')[0];
                if (wrapperItems[wrapperKey]) {
                    console.log(item);
                    wrapperItems[wrapperKey].push(item);
                } else {
                    wrapperItems[wrapperKey] = [item];
                }
                _.remove(allItemsA, item);
            }
        }
    });

    // 将组与原node插入到一起
    const realNode: ReactNode[] = [...allItemsA];
    for (const key in wrapperItems) {
        if (catchGroup[key]) {
            // 存在配置
            if (!_.isNil(catchGroup[key].visible) && !catchGroup[key].visible) {
                // 不展示就不存在
                continue;
            } else {
                if (catchGroup[key].afterNum) {
                    realNode.splice(
                        catchGroup[key].afterNum as number,
                        0,
                        <div
                            className={`lv1-form-wrapper ${catchGroup[key].groupClassName}`}
                        >
                            {catchGroup[key].groupTitle && (
                                <div className="lv1-form-group-title">
                                    {catchGroup[key].groupTitle}
                                </div>
                            )}
                            {wrapperItems[key]}
                        </div>,
                    );
                } else {
                    realNode.push(
                        <div
                            className={`lv1-form-wrapper ${catchGroup[key].groupClassName}`}
                        >
                            {catchGroup[key].groupTitle && (
                                <div className="lv1-form-group-title">
                                    {catchGroup[key].groupTitle}
                                </div>
                            )}
                            {wrapperItems[key]}
                        </div>,
                    );
                }
            }
        } else {
            realNode.push(
                <div className="lv1-form-wrapper">{wrapperItems[key]}</div>,
            );
        }
    }
    return <>{realNode}</>;
    // const groupElement = _.map(catchGroup, (group) => {
    //     return (
    //         <div className={`catchGroup-${group.groupKeys} ${group.groupClassName}`}>
    //             {_.map(allItemsA, (item: ReactNode) => {
    //             })}
    //         </div>
    //     )

    // })
    // return <div>{groupElement}</div>;
};

const ValidateSummary: React.FC<{
    validateResult?: Nilable<ValidateResult<any>>;
}> = ({ validateResult }) => {
    if (!validateResult) {
        return null;
    }
    return <div>todo</div>;
};

export const AutoCollapse: React.FC<{
    guys: ReactNode[];
    validateResult?: Nilable<ValidateResult<any>>;
    key2CollpaseKeyMap?: { [key: string]: string };
    layouts: TCollpaseLayout;
    collapseProps?: IAntCollapseProps2;
    hiddenWhenNoBoys?: boolean;
}> = ({
    guys,
    validateResult,
    key2CollpaseKeyMap,
    layouts,
    collapseProps,
    hiddenWhenNoBoys,
}) => {
    const opens: string[] = (collapseProps?.activeKey || []) as string[];

    let index = 0;
    const getAGuy = () => {
        return guys[index++] || null;
    };

    const groupedValidateResult: {
        [groupKey: string]: ValidateResult<any>;
    } = {};
    const validateKeys = _.keys(validateResult);
    // 从key反查到groupKey
    if (validateResult && key2CollpaseKeyMap) {
        for (const key of validateKeys) {
            const groupKey = key2CollpaseKeyMap[key];
            if (!groupKey) {
                continue;
            }
            groupedValidateResult[groupKey] =
                groupedValidateResult[groupKey] || {};
            if (validateResult[key]?.status !== 'success') {
                groupedValidateResult[groupKey][key] = validateResult[key];
            }
        }
    }

    const props: IAntCollapseProps2 = {
        ...collapseProps,
    };
    const collapseConfig: IAntCollapseConfig[] = [];
    for (const layout of layouts) {
        const {
            groupKey,
            groupName,
            groupSpan,
            layout: innerLayout,
            hidden,
        } = layout;

        const boys = [];
        for (let i = 0; i < groupSpan; i++) {
            const guy = getAGuy();
            if (guy) {
                boys.push(guy);
            }
        }

        const currentValidateResult = groupedValidateResult[groupKey];
        // 如果处于关闭状态，且存在
        const isShowValidateSummary =
            opens.indexOf(groupKey) === -1 && !_.isEmpty(currentValidateResult);

        let header: React.ReactNode = groupName;
        if (isShowValidateSummary) {
            header = (
                <>
                    <Tooltip title="存在未校验通过的字段">
                        <Icon
                            style={{ color: 'red' }}
                            type="exclamation-circle"
                        />
                    </Tooltip>
                    &nbsp;
                    {groupName}
                </>
            );
        }

        if (hiddenWhenNoBoys === true && _.every(boys, boy => !boy)) {
            continue;
        }

        const currentConf: IAntCollapseConfig = {
            key: groupKey,
            header,
            hidden,
            content: innerLayout ? (
                <SGrid layout={innerLayout}>{boys}</SGrid>
            ) : (
                <>{boys}</>
            ),
        };
        collapseConfig.push(currentConf);
    }
    props.collapseConfig = collapseConfig;

    return <AntCollapse2 {...props} />;
};
