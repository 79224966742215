/*
 * @Author: your name
 * @Date: 2020-12-03 14:45:13
 * @LastEditTime: 2020-12-08 17:23:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /maimai_crm/src/components/common/draw/drawer-title/index.ts
 */
import React from 'react';
import _ from 'lodash';
import { CSSProperties } from 'react';
import './index.scss';
import { DropdownButtonProps } from 'antd/lib/dropdown';
import { ButtonProps } from 'antd/lib/button';
import { Icon, Tag } from 'antd';
import {
    AntButton,
    AntDropdownButton,
    IAntDropDownButtonProps,
} from '../../../antd/index';

export interface IIconItem {
    content: string | (() => React.ReactElement) | React.ReactElement;
    onClick?: (event: React.MouseEvent) => void;
    style?: CSSProperties;
}
export interface ITagItem {
    content: (() => React.ReactElement) | string | React.ReactElement;
    onClick?: (event: React.MouseEvent) => void;
    style?: CSSProperties;
    color?: any;
    show?: boolean;
}
export interface ITitleButtonItem {
    type: 'button' | 'drop';
    dropButtonConfig?: IAntDropDownButtonProps;
    overlay?: any[]; // 这里是为了适配dropdown的overlay参数
    buttonConfig?: ButtonProps & {
        anchor?: { target: string; url: string };
        checkBody?: any;
    };
}

export interface ILabel {
    tags: string[];
    action: () => void;
}

export interface IDrawerTitleProps {
    title: React.ReactNode;
    icons?: IIconItem[];
    tags?: ITagItem[];
    afterTags?: React.ReactNode;
    buttons?: ITitleButtonItem[];
    label?: ILabel;
}

const DrawTitle: React.FC<IDrawerTitleProps> = ({
    title = '',
    icons = [],
    tags = [],
    afterTags = '',
    buttons = [],
    label = {},
}) => {
    const renderDrawTitle = () => {
        return <span className="crm-drawer-title">{title}</span>;
    };
    const renderDrawIcons = () => {
        const renderIconContent = (item: IIconItem) => {
            console.log(typeof item.content);
            if (typeof item.content === 'function') {
                // 如果是函数的话，则认为是render
                return item.content();
            } else if (typeof item.content === 'string') {
                return (
                    <img
                        src={item.content}
                        style={item.style}
                        className="drawer-icon"
                    />
                );
            } else {
                return item;
            }
        };
        return icons && icons.length > 0 ? (
            <div className="drawer-icon-bar">
                {_.map(icons, (item: IIconItem, idx: number) => {
                    return (
                        <div
                            className="drawer-icon-wrapper"
                            onClick={item.onClick}
                            key={idx}
                        >
                            {renderIconContent(item)}
                        </div>
                    );
                })}
            </div>
        ) : null;
    };
    const renderDrawTag = () => {
        const renderTagContent = (item: ITagItem) => {
            const { color = '#F2F4F8' } = item;
            if (typeof item.content === 'function') {
                // 如果是函数的话，则认为是render
                return item.content();
            } else if (typeof item.content === 'string') {
                return (
                    <div
                        className="drawer-tag"
                        // tslint:disable-next-line:max-line-length
                        style={
                            item.style
                                ? { ...item.style, backgroundColor: color }
                                : { backgroundColor: color }
                        }
                    >
                        {item.content}
                    </div>
                );
            } else {
                return item.content;
            }
        };
        return (tags && tags.length > 0) || !!afterTags ? (
            <div className="drawer-tag-bar">
                {_.map(tags, (item: ITagItem, idx: number) => {
                    if (!item.show) {
                        return undefined;
                    }
                    return (
                        <div
                            className="drawer-icon-wrapper"
                            onClick={item.onClick}
                            key={idx}
                        >
                            {renderTagContent(item)}
                        </div>
                    );
                })}
                {afterTags}
            </div>
        ) : null;
    };
    const renderButtons = () => {
        return buttons && buttons.length > 0 ? (
            <div className="drawer-title-button-bar">
                {_.map(buttons, (item: ITitleButtonItem, idx: number) => {
                    const aConf = item.buttonConfig?.anchor;
                    return item.type === 'button' ? (
                        !!aConf ? (
                            <a href={aConf.url} target={aConf.target}>
                                <div className="drawer-title-button-wrapper">
                                    <AntButton {...item.buttonConfig} />
                                </div>
                            </a>
                        ) : (
                            <div className="drawer-title-button-wrapper">
                                <AntButton {...item.buttonConfig} />
                            </div>
                        )
                    ) : (
                        <div className="drawer-title-button-wrapper">
                            <AntDropdownButton
                                hasRight={false}
                                overlay={item.overlay}
                                {...item.dropButtonConfig}
                            />
                        </div>
                    );
                })}
            </div>
        ) : null;
    };

    const renderLabels = () => {
        if (!_.isEmpty(label)) {
            const { tags = [], action = () => { } } = label as ILabel;
            return (
                <div className="drawer-customer-title-content-label">
                    {tags?.map(tag => (
                        <Tag color="blue">{tag}</Tag>
                    ))}
                    <Tag
                        onClick={action}
                        style={{
                            cursor: 'pointer',
                            color: '#333',
                            background: '#fff',
                            borderStyle: 'dashed',
                            fontWeight: 400,
                        }}
                    >
                        <Icon type="plus" /> 添加标签
                    </Tag>
                </div>
            );
        }
    };
    return (
        <div className="drawer-customer-title-content">
            {renderDrawTitle()}
            {renderDrawIcons()}
            {renderDrawTag()}
            {renderButtons()}
            {renderLabels()}
        </div>
    );
};
export default DrawTitle;
