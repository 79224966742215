import React, { useState, useEffect, useContext } from 'react';
import './index.less';
import { StoreContext } from '@/stores';
import { observer } from 'mobx-react-lite';
import {
    trans2W,
    trans2DotTwo,
    targetLevelOptions,
} from '@/pages/analysis/dashboard/composite-live/component/achievement-situation';
import { Spin, Select, Statistic } from 'antd';
import _ from 'lodash';
import { homeTargetLevelOptions } from '../../store';
import { goToOtherPage } from '../home-ana-opt';
import { useUpdateEffect } from 'ahooks';
// toDayVisit?: number;
// toDayCall?: number;
// toDayCallValid?: number;
// toDayCallTotalTime?: number;
// toDayCustomerClaim?: number;
// toDayCustomerCreate?: number;
// toDayOpportunityCreate?: number;

// // 城市/全国版
// toWeekNewSignTaskOnTimeFinishRate?: number;
// toWeekNewSignTaskOnTimeFinishRateCompared?: number; // 新签

// toMonthNewSignTaskOnTimeFinishRate?: number;
// toMonthNewSignTaskOnTimeFinishRateCompared?: number;

// toMonthNewTrainTaskOnTimeFinishRateCompared?: number; // 新开
// toMonthNewTrainTaskOnTimeFinishRate?: number;

// toWeekNewTrainTaskOnTimeFinishRate?: number;
// toWeekNewTrainTaskOnTimeFinishRateCompared?: number;

// toMonthRenew321TaskOnTimeFinishRate?: number; // 续约
// toMonthRenew321TaskOnTimeFinishRateCompared?: number;

// toWeekRenew321TaskOnTimeFinishRate?: number;
// toWeekRenew321TaskOnTimeFinishRateCompared?: number;

// cooperateCustomerMonthOverrideRate?: number; // 321
// cooperateCustomerMonthOverrideRateCompared?: number;
const HomePerformanceOpt: React.FC = () => {
    const store = useContext(StoreContext);
    const newHomeStore = store.getDefaultHomeNewStore();
    const {
        homeNewPerformanceOptLoading,
        homeNewPerformanceOptData,
        homeNewFilterData,
        setHomeNewFilterData,
        homeNewSelectedData,
        getHomeNewPerformanceOptData,
    } = newHomeStore;
    const isSaleAndGroup =
        homeNewSelectedData.selectedLevel === 4 ||
        homeNewSelectedData.selectedLevel === 1;
    useUpdateEffect(() => {
        getHomeNewPerformanceOptData();
    }, [homeNewSelectedData, homeNewFilterData]);
    const goToOtherPageWithUserList = (
        pageKey: string,
        filterKey: string,
        momentKey?: string,
    ) => {
        setLocalStorageUserByKey(filterKey);
        goToOtherPage(
            pageKey,
            undefined,
            `${filterKey}${momentKey ? `_${momentKey}` : ''}`,
        );
    };
    const setLocalStorageUserByKey = (key: string) => {
        localStorage.setItem(
            key,
            JSON.stringify(
                _.map(homeNewPerformanceOptData.userInfoList, uItem => {
                    return {
                        id: uItem.userId,
                        key: `user-${uItem.userId}`,
                        name: uItem.userName,
                        title: uItem.userName,
                    };
                }),
            ),
        );
    };
    const homePerformanceOptSalesStaticsData = [
        {
            title: '拜访客户',
            key: 'toDayVisit',
            rateKey: null,
            increaseKey: null,
            formatter: (v: any) => v,
            onClick: () => {
                goToOtherPageWithUserList(
                    'activation_record',
                    'today_visited_customer_record',
                );
            },
        },
        // {
        //     title: '外呼接通记录',
        //     key: 'toDayCall',
        //     rateKey: null,
        //     formatter: (v: any) => v,
        //     increaseKey: null,
        //     onClick: () => {
        //         goToOtherPageWithUserList(
        //             'activation_record',
        //             'call_in_record',
        //         );
        //     },
        // },
        {
            increaseKey: null,
            key: 'toDayCallValid',
            rateKey: null,
            formatter: (v: any) => v,
            title: '外呼有效记录（45秒以上）',
        },
        {
            increaseKey: null,
            key: 'toDayCallTotalTime',
            rateKey: null,
            formatter: (v: any) => v,
            title: '外呼总时长（min）',
            onClick: () => {
                goToOtherPageWithUserList(
                    'activation_record',
                    'call_in_record',
                );
            },
        },
        {
            increaseKey: null,
            key: 'toDayCustomerClaim',
            rateKey: null,
            formatter: (v: any) => v,
            title: '领取客户',
            onClick: () => {
                goToOtherPageWithUserList(
                    'private_customer',
                    'today_fetch_customer',
                );
            },
        },
        {
            increaseKey: null,
            key: 'toDayCustomerCreate',
            rateKey: null,
            formatter: (v: any) => v,
            title: '新建客户',
            onClick: () => {
                goToOtherPageWithUserList(
                    'private_customer',
                    'today_create_customer',
                );
            },
        },
        {
            increaseKey: null,
            key: 'toDayOpportunityCreate',
            rateKey: null,
            formatter: (v: any) => v,
            title: '新建商机',
            onClick: () => {
                goToOtherPageWithUserList('opportunity', 'today_create_opt');
            },
        },
    ];

    const homePerformanceOptWeekStaticsData = [
        {
            title: '新签线索任务',
            key: 'toWeekNewSignTaskOnTimeFinishRate',
            rateKey: null,
            increaseKey: 'toWeekNewSignTaskOnTimeFinishRateCompared',
            formatter: (v: any) => `${Math.round(v)}%`,
            onClick: () => {
                goToOtherPageWithUserList('job', 'new_sign_task_on_time', '4');
            },
        },
        {
            title: '新开培训任务',
            key: 'toWeekNewTrainTaskOnTimeFinishRate',
            rateKey: null,
            increaseKey: 'toWeekNewTrainTaskOnTimeFinishRateCompared',
            formatter: (v: any) => `${Math.round(v)}%`,
            onClick: () => {
                goToOtherPageWithUserList('job', 'new_open_training_task', '4');
            },
        },
        {
            title: '续约321任务',
            key: 'toWeekRenew321TaskOnTimeFinishRate',
            rateKey: null,
            increaseKey: 'toWeekRenew321TaskOnTimeFinishRateCompared',
            formatter: (v: any) => `${Math.round(v)}%`,
            onClick: () => {
                goToOtherPageWithUserList('job', 'renew_321_task', '4');
            },
        },
        {},
    ];

    const homePerformanceOptMonthStaticsData = [
        {
            title: '新签线索任务',
            key: 'toMonthNewSignTaskOnTimeFinishRate',
            rateKey: null,
            increaseKey: 'toMonthNewSignTaskOnTimeFinishRateCompared',
            formatter: (v: any) => `${Math.round(v)}%`,
            onClick: () => {
                goToOtherPageWithUserList('job', 'new_sign_task_on_time', '3');
            },
        },
        {
            title: '新开培训任务',
            key: 'toMonthNewTrainTaskOnTimeFinishRate',
            rateKey: null,
            increaseKey: 'toMonthNewTrainTaskOnTimeFinishRateCompared',
            formatter: (v: any) => `${Math.round(v)}%`,
            onClick: () => {
                goToOtherPageWithUserList('job', 'new_open_training_task', '3');
            },
        },
        {
            title: '续约321任务',
            key: 'toMonthRenew321TaskOnTimeFinishRate',
            rateKey: null,
            increaseKey: 'toMonthRenew321TaskOnTimeFinishRateCompared',
            formatter: (v: any) => `${Math.round(v)}%`,
            onClick: () => {
                goToOtherPageWithUserList('job', 'renew_321_task', '3');
            },
        },
        {
            title: '合作客户月度覆盖率',
            key: 'cooperateCustomerMonthOverrideRate',
            rateKey: null,
            increaseKey: 'cooperateCustomerMonthOverrideRateCompared',
            formatter: (v: any) => `${Math.round(v)}%`,
            onClick: () => {
                // goToOtherPageWithUserList('private_customer', 'mine_cooperation_customer');
                goToOtherPage(
                    'private_customer',
                    '',
                    'mine_cooperation_customer',
                );
            },
        },
    ];
    const renderDayStaticData = () => {
        return (
            <div className="h-p-o-row day-row">
                <div
                    className="h-p-o-row-title"
                    style={{
                        backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/home/staticsRowTitle.png')`,
                    }}
                >
                    <span>今日</span>
                </div>
                <div className="h-p-o-row-data">
                    {_.map(homePerformanceOptSalesStaticsData, item => {
                        return (
                            <div className="h-p-o-row-item">
                                <p className="h-p-o-row-item-title">
                                    {item.title}
                                </p>
                                <div
                                    className={`h-p-o-row-item-data ${
                                        item.onClick ? 'canClick' : ''
                                    }`}
                                    onClick={item?.onClick}
                                >
                                    {!_.isNil(
                                        homeNewPerformanceOptData?.[item.key],
                                    ) ? (
                                        <Statistic
                                            value={item.formatter(
                                                homeNewPerformanceOptData?.[
                                                    item.key
                                                ],
                                            )}
                                            style={{
                                                fontSize: '12px',
                                                // color: '#6E727A',
                                                display: 'inline',
                                                color: item.onClick
                                                    ? '#0052ff'
                                                    : undefined,
                                            }}
                                            // suffix={item || ''}
                                        ></Statistic>
                                    ) : (
                                        <Statistic
                                            value={'- -'}
                                            style={{
                                                fontSize: '12px',
                                                color: '#6E727A',
                                                display: 'inline',
                                            }}
                                        ></Statistic>
                                    )}
                                    {/* <span className = "h-p-o-row-data-rate">
                                            {
                                                (item.increaseKey && homeNewPerformanceOptData?.[item.increaseKey]) ? (
                                                    `较上周${homeNewPerformanceOptData?.[item.increaseKey] > 0 ?(
                                                        <i className="iconfont1 up-icon">&#xe60e;</i>
                                                    ) : (
                                                        <i className="iconfont1 down-icon">&#xe60f;</i>
                                                    )}${Math.round(homeNewPerformanceOptData?.[item.increaseKey])}%`
                                                ) : null
                                            }
                                        </span> */}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };
    const renderMonthStaticData = () => {
        return (
            <div className="h-p-o-row">
                <div
                    className="h-p-o-row-title"
                    style={{
                        backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/home/staticsRowTitle.png')`,
                    }}
                >
                    <span>本月</span>
                </div>
                <div className="h-p-o-row-data">
                    {_.map(homePerformanceOptMonthStaticsData, item => {
                        return (
                            <div className="h-p-o-row-item">
                                <p className="h-p-o-row-item-title">
                                    {item.title}
                                </p>
                                <div
                                    className={`h-p-o-row-item-data canClick`}
                                    onClick={item?.onClick}
                                >
                                    {!_.isNil(
                                        homeNewPerformanceOptData?.[item.key],
                                    ) ? (
                                        <Statistic
                                            value={item.formatter(
                                                homeNewPerformanceOptData?.[
                                                    item.key
                                                ],
                                            )}
                                            style={{
                                                fontSize: '12px',
                                                // color: '#6E727A',
                                                display: 'inline',
                                            }}
                                            // suffix={item || ''}
                                        ></Statistic>
                                    ) : (
                                        <Statistic
                                            value={'- -'}
                                            style={{
                                                fontSize: '12px',
                                                color: '#6E727A',
                                                display: 'inline',
                                            }}
                                        ></Statistic>
                                    )}
                                    <span className="h-p-o-row-data-rate">
                                        {item.increaseKey &&
                                        homeNewPerformanceOptData?.[
                                            item.increaseKey
                                        ] ? (
                                            <span>
                                                较上月
                                                {homeNewPerformanceOptData?.[
                                                    item.increaseKey
                                                ] > 0 ? (
                                                    <i className="iconfont1 up-icon">
                                                        &#xe60e;
                                                    </i>
                                                ) : (
                                                    <i className="iconfont1 down-icon">
                                                        &#xe60f;
                                                    </i>
                                                )}
                                                {Math.abs(
                                                    Math.round(
                                                        homeNewPerformanceOptData?.[
                                                            item.increaseKey
                                                        ],
                                                    ),
                                                )}
                                                %
                                            </span>
                                        ) : homeNewPerformanceOptData?.[
                                              item.increaseKey
                                          ] === 0 ? (
                                            '较上月持平'
                                        ) : null}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };
    const renderWeekStaticData = () => {
        return (
            <div className="h-p-o-row">
                <div
                    className="h-p-o-row-title"
                    style={{
                        backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/home/staticsRowTitle.png')`,
                    }}
                >
                    <span>本周</span>
                </div>
                <div className="h-p-o-row-data">
                    {_.map(homePerformanceOptWeekStaticsData, item => {
                        if (item.key) {
                            return (
                                <div className="h-p-o-row-item">
                                    <p className="h-p-o-row-item-title">
                                        {item.title}
                                    </p>
                                    <div
                                        className={`h-p-o-row-item-data canClick`}
                                        onClick={item.onClick}
                                    >
                                        {!_.isNil(
                                            homeNewPerformanceOptData?.[
                                                item.key
                                            ],
                                        ) ? (
                                            <Statistic
                                                value={item.formatter(
                                                    homeNewPerformanceOptData?.[
                                                        item.key
                                                    ],
                                                )}
                                                style={{
                                                    fontSize: '12px',
                                                    // color: '#6E727A',
                                                    display: 'inline',
                                                }}
                                                // suffix={item || ''}
                                            ></Statistic>
                                        ) : (
                                            <Statistic
                                                value={'- -'}
                                                style={{
                                                    fontSize: '12px',
                                                    color: '#6E727A',
                                                    display: 'inline',
                                                }}
                                            ></Statistic>
                                        )}
                                        <span className="h-p-o-row-data-rate">
                                            {item.increaseKey &&
                                            homeNewPerformanceOptData?.[
                                                item.increaseKey
                                            ] ? (
                                                <span>
                                                    较上周
                                                    {homeNewPerformanceOptData?.[
                                                        item.increaseKey
                                                    ] > 0 ? (
                                                        <i className="iconfont1 up-icon">
                                                            &#xe60e;
                                                        </i>
                                                    ) : (
                                                        <i className="iconfont1 down-icon">
                                                            &#xe60f;
                                                        </i>
                                                    )}
                                                    {Math.abs(
                                                        Math.round(
                                                            homeNewPerformanceOptData?.[
                                                                item.increaseKey
                                                            ],
                                                        ),
                                                    )}
                                                    %
                                                </span>
                                            ) : homeNewPerformanceOptData?.[
                                                  item.increaseKey
                                              ] === 0 ? (
                                                '较上周持平'
                                            ) : null}
                                        </span>
                                    </div>
                                </div>
                            );
                        } else {
                            return <div className="h-p-o-row-item"></div>;
                        }
                    })}
                </div>
            </div>
        );
    };
    return (
        <Spin spinning={homeNewPerformanceOptLoading}>
            <div className="home-new-item-content">
                <div className="home-new-item-header">
                    <span className="h-n-i-title">任务按时完成率</span>
                    {/* <AntButton>生成报告</AntButton> */}
                    {/* <div className="h-n-i-filter">
                        <div className="h-n-i-item">
                            <label htmlFor="">目标级别</label>
                            <Select
                                value={homeNewFilterData.targetLevel}
                                onChange={(value: any) => {
                                    setHomeNewFilterData({
                                        ...homeNewFilterData,
                                        targetLevel: value,
                                    });
                                }}
                                style={{
                                    minWidth: '120px',
                                }}
                            >
                                {_.map(homeTargetLevelOptions, option => {
                                    return (
                                        <Select.Option value={option.value}>
                                            {option.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div> */}
                </div>
                <div className="h-n-i-body">
                    {isSaleAndGroup && renderDayStaticData()}
                    {renderWeekStaticData()}
                    {renderMonthStaticData()}
                </div>
            </div>
        </Spin>
    );
};
export default observer(HomePerformanceOpt);
