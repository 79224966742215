import { RouteComponentProps } from '@reach/router';
import React, { useState } from 'react';
import { OptStep } from '@/components/common';
import { observer } from '@/containers/hoc';

const OptStepDemo: React.FC<RouteComponentProps> = ({}) => {
    const [current, setCurrent] = useState(2);
    const [active, setActive] = useState(3);
    const [ssloading, setSSLoading] = useState(false);

    const submit = (submitStep: number) => {
        // 提交这一步，到下一步
        setSSLoading(true);
        setTimeout(() => {
            setSSLoading(false);
            setCurrent(submitStep + 1);
            setActive(submitStep + 1);
        }, 1000);
    };

    return (
        <div style={{ padding: 200, backgroundColor: 'white' }}>
            <OptStep
                items={[
                    {
                        text: '初步接洽20%',
                        title: '',
                    },
                    {
                        text: '意向沟通30%',
                        title: '',
                    },
                    {
                        text: '意向确认40%',
                        title: '',
                    },
                    {
                        text: '确认合同50%',
                        title: '',
                    },
                    {
                        text: '签约完成80%',
                        title: '',
                    },
                    {
                        text: '收到入款100%',
                        title: '',
                    },
                ]}
                current={current}
                active={active}
                onActiveChange={nextActive => {
                    setActive(nextActive);
                }}
                onNextClick={submitStep => {
                    submit(submitStep);
                }}
                submitStepLoading={ssloading}
            />
        </div>
    );
};

export default observer(OptStepDemo);
