import { makeAutoObservable } from 'mobx';
import { defaultAxios } from '@/utils';
import { createDefaultInstanceGetter } from '../../../stores/utils/index';
import EE from 'eventemitter3';
import { message } from 'antd';

export interface IOPMTableData {
    id?: string | number; // id
    name: string | number;
    personalEmail: string; // 邮箱
    phone: string;
    status: number; // 1是在职 2是离职
    dimDepart: string; // 所属部门的id
    dimDepartName: string; // 所属部门的名称
    parent_depart: string; // 上级部门
    managerId?: string; // 直属上级
    userLevel?: string; // 销售
    rankId: string; // 职级
    dataRoleList: string[]; // 数据角色
    funRoleList: string[]; // 功能角色
    createTime?: number | string; // 创建时间
    updateTime?: number | string; // 更新时间
    positiveAt?: number | string; // 转正日期
    quitAt?: number | string; // 离职日期
    appraiseAt?: number | string; // 考核日期
    joinAt?: number | string; // 入职日期
    avatar: string; // 头像
}
export interface IOPMTableSearchData {
    departId?: string | number; // 部门id
    userNameAndEmail: string | number; // 用户姓名邮箱
    status: string | number; // 状态
    dataRoleId?: string | number; // 数据角色
    funRoleId?: string | number; // 功能角色
    subDepart: boolean; // 是否包含子部门
    [key: string]: any;
}
export type TGetOPMTableReturnType = NetResponse<{
    data: IOPMTableData[];
    msg: string;
    code: number;
}>;
export class OPMStore {
    constructor() {
        makeAutoObservable(this, {
            defaultOPMEE: false,
        });
    }
    public OPMTableData: any[] = [];
    public defaultOPMEE = new EE();

    public getOPMTableData: (
        searchData: IOPMTableSearchData,
        selectedDepart: any,
    ) => Promise<TGetOPMTableReturnType> = async (
        searchData,
        selectedDepart,
    ) => {
        const url = '/bff/api/rest/opm/list';
        const payload = {
            ...searchData,
            operationRole: searchData.funRoleId,
            dataRole: searchData.dataRoleId,
            departId: selectedDepart?.id || 0,
        };
        delete payload.funRoleId;
        delete payload.dataRoleId;
        // 特殊处理全公司
        if (payload.subDepart && payload.departId === 1) {
            delete payload.subDepart;
            delete payload.departId;
        }
        const [d, e] = await defaultAxios.get(url, payload);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d.data && Array.isArray(d.data)) {
            this.OPMTableData = data || [];
        } else {
            message.error(d.msg || '请求失败', 1);
        }
        return [data, error];
    };
    // ---- 部门操作
    // 删除部门
    public deleteDepart = async (id: string | number) => {
        const url = '/bff/api/rest/opm/o-delete';
        const [d, e] = await defaultAxios.post(url, { id });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 修改/新增部门 不传id是新增，传了id是更改
    public updateOrAddDepart = async (updateData: any) => {
        const url = '/bff/api/rest/opm/o-update-create';
        const [d, e] = await defaultAxios.post(url, {
            ...updateData,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // ---- end

    // ---- 人员操作
    // 修改/新增人员 不传id是新增，传了id是更改
    // TODO:功能角色/数据角色数据请求
    public updateOrAddPerson = async (updateData: any) => {
        const url = '/bff/api/rest/opm/person-create-update';
        const [d, e] = await defaultAxios.post(url, {
            ...updateData,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 离职成员
    public resignPerson = async (cid: string) => {
        const url = '/bff/api/rest/opm/person-resign';
        const [d, e] = await defaultAxios.post(url, {
            cid,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // ---- end
}
export const getDefaultOPMStore = createDefaultInstanceGetter(OPMStore);
