import { makeAutoObservable } from 'mobx';
import {
    TPaginationStoreInstance,
    PaginationStore,
} from '../../../../stores/utils/index';
import { defaultAxios } from '@/utils';
import moment from 'moment';
import { createDefaultInstanceGetterA } from '@/stores/utilsA';
export interface ILoginLogData {
    userName?: string;
    loginTime?: string; // 登录时间
    loginWay?: string; // 登录方式
    ip?: string;
    loginPlace?: string; // 登录地点
    deviceType?: string; // 设备类型
    kernel?: string; // 内核
    platform?: string; // 平台
    authStatus?: boolean; // 认证结果 true成功 false失败
}
export type TGetLoginLogReturnType = NetResponse<{
    data: ILoginLogData[];
    msg: string;
    code: number;
}>;
export class LoginLogStore {
    constructor() {
        makeAutoObservable(this);
    }
    public loginLogData: ILoginLogData[] = [];
    public defaultLoginLogPagination: TPaginationStoreInstance = new PaginationStore();
    public getLoginLogData: (
        searchData: any,
    ) => Promise<TGetLoginLogReturnType> = async searchData => {
        const finalSearchData = {
            ...searchData,
            beginDate: moment(searchData.beginDate).valueOf(),
            endDate: moment(searchData.endDate).valueOf(),
            ...this.defaultLoginLogPagination.paginationArgs,
        };
        const url = '/bff/api/rest/log/login-log';
        const [d, e] = await defaultAxios.get(url, finalSearchData);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data?.data)) {
            this.loginLogData = data?.data;
            this.defaultLoginLogPagination.amount = data?.totalSize || 0;
        }
        const error = e as Error | null;
        return [data, error];
    };
}

export const getDefaultLoginLogStore = createDefaultInstanceGetterA(
    LoginLogStore,
);
