import React, { useRef, useCallback, useEffect, useState } from 'react';
import { Icon } from 'antd';
import './index.scss';
import Sortable, { SortableEvent } from 'sortablejs';
import _ from 'lodash';
import { AntEmpty } from '@/components/antd';
import { arrToJson } from '@/utils';

export interface ISortableListProps {
    data: Array<{ title?: React.ReactNode; key: string }>;
    readOnlyData?: Array<{ title?: React.ReactNode; key: string }>;
    onSwitch: (fromIndex: number, toIndex: number) => void;
    onRemove: (fromIndex: number) => void;
}

export const SortableList: React.FC<ISortableListProps> = props => {
    const { data, onSwitch, onRemove, readOnlyData = [] } = props;
    const readOnlyDataMap = arrToJson(readOnlyData, 'key');

    // DIRTY: 数据一定要保证，readonly的在前面！！！！
    // 保证拆开再合并后，顺序是一样的
    // 拆
    const editableData = data.filter(d => !readOnlyDataMap[d.key]);
    const composedData = [...readOnlyData, ...editableData];

    // 在删除，移动等操作时，操作的是原数组，需要忽略readonly data
    const offset = readOnlyData.length;

    const getSortableObjectManager = useCallback(
        (() => {
            let currentSortable: Sortable | null = null;
            return () => {
                return {
                    set(cs: Sortable) {
                        currentSortable = cs;
                    },
                    get() {
                        return currentSortable;
                    },
                    destroy() {
                        if (
                            currentSortable !== null &&
                            _.isFunction(currentSortable.destroy)
                        ) {
                            currentSortable.destroy();
                        }
                    },
                };
            };
        })(),
        [],
    );
    const sortableObjectManager = getSortableObjectManager();

    useEffect(() => {
        const node = ulRef.current;
        const onEnd = (e: SortableEvent) => {
            const { oldIndex: fromIndex, newIndex: toIndex } = e;
            if (_.isNumber(fromIndex) && _.isNumber(toIndex)) {
                onSwitch(fromIndex + offset, toIndex + offset);
            }
        };

        if (node !== null && !node._____________sortable) {
            // initial sortablejs
            const sortableObject = Sortable.create(node, { onEnd });
            node._____________sortable = true;
            sortableObjectManager.destroy();
            sortableObjectManager.set(sortableObject);
        } else {
            const sortableObject = sortableObjectManager.get();
            if (sortableObject !== null) {
                sortableObject.options.onEnd = onEnd;
            }
        }
    }, [onSwitch]);

    const ulRef = useRef<any | null>(null);

    if (
        data.map(item => item.key).join(',') !==
        composedData.map(item => item.key).join(',')
    ) {
        return <span>强制字段配置出错</span>;
    }

    return (
        <div className="component-sortable-list">
            <div className="ul readonly">
                {readOnlyData.length > 0 &&
                    readOnlyData.map((item, index) => {
                        const { key, title } = item;
                        return (
                            <div key={key} className="li">
                                <div className="title">
                                    {title || key}(固定字段)
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div ref={ulRef} className="ul">
                {editableData.length > 0 &&
                    editableData.map((item, index) => {
                        const { key, title } = item;
                        return (
                            <div key={key} className="li">
                                <div className="title">{title || key}</div>
                                <div
                                    onClick={() => {
                                        onRemove(index + offset);
                                    }}
                                    className="remove"
                                >
                                    <Icon type="close" />
                                </div>
                            </div>
                        );
                    })}
                {data.length === 0 && <AntEmpty />}
            </div>
        </div>
    );
};
