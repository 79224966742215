import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { StoreContext } from '@/stores';
import _ from 'lodash';
import { tryRefresh, arrToJson, isNetSuccess } from '@/utils';
import {
    message,
    Select,
    Icon,
    Modal,
    Spin,
    Form,
    Button,
    Divider,
    Steps,
    Tabs,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import {
    AntInputTextArea,
    AntButton,
    AntCollapse,
    AntTable,
} from '@/components/antd';
import querystring from 'querystring';
import { CustomerOptCreate } from '@/components/common/customer-drawer/customer-opt-create';

import moment from 'moment';
import { useRequest, useEventListener } from 'ahooks';
import {
    fetchActionLogConfig,
    fetchContactPersonList,
    fetchCustomerInfo,
    fetchCustomerDetail,
    fetchCustomerPersonList,
    fetchOpportunityData,
    fetchActionLogTaskList,
    addActionLogNet,
} from './api';
import { CCStore } from '@/containers/hoc/call-center/store';
import AddContact from './components/add-contact';
import { UploadImage } from './components/upload';
import Selector from './components/selector';
import CallBar from './components/call-bar';
import {
    IconCall,
    IconHeart,
    IconWechat,
    IconVideo,
    IconMore,
} from './components/icon';
import useDraggable from '@/utils/hooks/use-draggable';
import { Dialog } from '@/components/common';
import { mutate } from '@/api/graphql';
import './index.less';
import { commonQuery, commonQueryJob } from '@/api/rest';
import { defaultAxios } from '@/utils';
import Prompt from './components/teleprompter';

export interface IFollowLogSaveData {
    arType?: number; // 记录类型
    bizId?: number; // 跟进线索/客户
    note?: string; // 记录详情
    followType?: string; // 跟进方式
    nextDate?: any; // 跟进计划
    nextNote?: string; // 下次跟进计划
    contactsId?: number | string; // 联系人
    taskId?: number; // 关联任务
    leadId?: number | string;
    followTarget?: number; // 跟进目的
    outerNetReleaseJob?: number; // 外网发布职位
    futureCusOneYear?: number; // 客户未来一年所需人数
    dutyPeriod?: number; // 岗位周期
    positionType?: number; // 岗位类型
    salaryRange?: number; // 薪资范围
    isBuyAct?: number; // 其他平台（2个以上）有无采买行为
    isHeadhuntingWork?: number; // 是否和猎头合作
    maimaiInterest?: number; // 对脉脉的兴趣点
    relateResults?: number; // 联系结果
    isHistoryDataNormal?: number; // 过往使用数据是否正常
    companyAndPlatform?: number; // 公司猎头和平台预算
    cusRenewalRisk?: number; // 客户续约风险
    isDecision?: number; // 是否决策层
    customerId?: string | number;
}

export interface IAddFollowLogPropsFromEventOrProps {
    id?: string | number; // id (实体对应的id) [实际上对应的也是bizId]
    cid?: number; // 实体客户的id [为了获取相关联系人]
    entityType?: string; // '实体type'
    targetFixed?: boolean; // 禁用联系人修改
    addFollowData?: IFollowLogSaveData; // 初始值
    // --- props打开
    drawerVis?: boolean;
    setDrawerVis?: (bool: boolean) => void;
    initMark?: boolean; // init板机

    // 20211013
    onCreated?: (item: any) => void;
    withNextJobRadio?: boolean;
    // 20220316
    hiddenTask?: boolean;
    callStore?: CCStore;
    isNew?: boolean;
    isOpenPopover?: boolean;
}

export const EntityTypeMap: {
    [key: string]: string;
} = {
    customer: '客户',
    lead: '线索',
    contacts: '联系人',
    opportunity: '商机',
};

const optPushMap = new Map([
    [0, '创建20商机'],
    [20, '推进至40商机'],
    [40, '推进至60商机'],
    [60, '推进至80/90商机'],
    [80, '推进至100商机'],
    [90, '推进至100商机'],
]);

const iconMap: { [key: string]: JSX.Element } = {
    10: <IconCall />,
    20: <IconHeart />,
    30: <IconWechat />,
    50: <IconVideo />,
    60: <IconMore />,
};

const metaFieldMap = [
    'outerNetReleaseJob',
    'futureCusOneYear',
    'dutyPeriod',
    'positionType',
    'salaryRange',
    'isBuyAct',
    'isHeadhuntingWork',
    'maimaiInterest',
    'isHistoryDataNormal',
    'companyAndPlatform',
    'cusRenewalRisk',
    'companyAndPlatform',
    'isHistoryDataNormal',
    'cusRenewalRisk',
];

const AddFollowLog: React.FC<IAddFollowLogPropsFromEventOrProps &
    FormComponentProps> = props => {
    const {
        id,
        cid,
        setDrawerVis,
        drawerVis,
        entityType,
        onCreated,
        withNextJobRadio: outsideWithNextJobRadio,
        hiddenTask = false,
        callStore,
        form,
        isOpenPopover,
    } = props;

    const {
        getFieldDecorator,
        validateFields,
        resetFields,
        setFieldsValue,
        getFieldValue,
    } = form;

    const withNextJobRadio = _.isUndefined(outsideWithNextJobRadio)
        ? true
        : outsideWithNextJobRadio;

    const store = useContext(StoreContext);
    const customerListStore = store.getDefaultCustomerListStore();

    const [actionLogCollapsed, setActionLogCollapsed] = useState(false); // 活动记录modal收缩

    // 外部而来的配置
    const [followLogProps, setFollowLogProps] = useState<
        IAddFollowLogPropsFromEventOrProps
    >();

    // 添加活动记录loading
    const [saveActionLogLoading, setSaveActionLogLoading] = useState(false);

    const [isCreateJob, setIsCreateJob] = useState(0);
    //商机选择
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[] | number[]>(
        [],
    );
    // 客户搜索关键词
    const [customerSearchText, setCustomerSearchText] = useState<string>();

    const debounceSetCustomerSearchText = _.debounce(
        setCustomerSearchText,
        300,
    );

    const [entityName, setEntityName] = useState<string>('客户'); // 实体名称

    const [bizId, setBizId] = useState<number>(); // 实体id
    const [customerId, setCustomerId] = useState<number | undefined>(); // 客户id
    const [showAddContact, setShowAddContact] = useState(false);
    const [customerOptCreateTrigger, setCustomerOptCreateTrigger] = useState(0);

    const dialogRef = useRef<HTMLDivElement>(null);

    const [taskListLoading, setTaskListLoading] = useState(false);

    const [taskId, setTaskId] = useState<string | undefined>(); // 任务id
    // const [customerInfo, setCustomerInfo] = useState<any>();
    const [leftVisible, setLeftVisible] = useState<boolean>(true);

    const [currentCallPhoneNum, setCurrentCallPhoneNum] = useState('');

    useDraggable(dialogRef);

    console.log('followLogProps', followLogProps);

    useEffect(() => {
        setCustomerId(cid);
        setBizId(id as number);
        setEntityName(EntityTypeMap[entityType || 'customer']);
    }, [id, cid, entityType]);

    const { data: customer, loading: customerLoading } = useRequest(
        () =>
            fetchCustomerDetail({
                id: customerId,
                fieldAuthCode: customerListStore.fieldAuthCode,
                dataAuthCode: customerListStore.dataAuthCode,
            }),
        {
            refreshDeps: [customerId],
        },
    );

    // 客户联系人列表
    const {
        data: contactPerson,
        loading: loadingCPL,
        refresh: reloadCPL,
        mutate: setContactPerson,
    } = useRequest(() => fetchContactPersonList(customerId), {
        ready: drawerVis,
        refreshDeps: [customerId],
        onSuccess(res) {
            const contactsId = followLogProps?.addFollowData?.contactsId;
            const currentContact = res.find(item => {
                if (contactsId) {
                    return item.id === contactsId;
                }
                if (callStore) {
                    const contacts_name = callStore?.callActionConfig.userName;
                    const phone = currentCallPhoneNum || '';
                    return (
                        contacts_name === item.name &&
                        phone.slice(0, 3) === item.phone.slice(0, 3)
                    );
                }
            });

            // 重置联系人
            setFieldsValue({ contactsId: undefined });

            if (res?.length && currentContact) {
                setFieldsValue({
                    contactsId: currentContact?.id,
                    isDecision: currentContact?.isDecision,
                });
            }
        },
    });

    const { data: isUserWhite } = useRequest(async () => {
        const [d, e] = await defaultAxios.get(
            '/crm/activationRecord/isUserWhite',
        );
        if (d === null || d.data === null) {
            return null;
        }
        return !!d.data;
    });

    const hasContactByNumber = async (phone: string | number) => {
        const [d, e] = await commonQuery(
            'contacts',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'contacts_phone',
                        filterValue: phone,
                        operateType: '=',
                        parser: 'string',
                        widgetKey: 'input',
                    },
                    {
                        fieldId: 'customer_id',
                        filterValue: customerId,
                        operateType: 'in',
                        parser: 'string',
                        widgetKey: 'object',
                    },
                ],
            },
            undefined,
            '',
            undefined,
            'SEARCH-ALL',
        );

        const hasContact = !!d && d.amount > 0;
        return hasContact;
    };

    const createContact = async (phone: string | number) => {
        const hasContact = await hasContactByNumber(phone);

        const contacts_name = callStore?.callActionConfig.userName;
        const contacts_duty = callStore?.callActionConfig.userPosition;

        if (hasContact || !contacts_name) return;

        const isLeadPage = entityType === 'lead';

        const payload = {
            customer_id: customerId,
            contacts_source: isLeadPage ? 3 : 4, // 线索转化/脉动态用户转化
            sys_create: isLeadPage ? 3 : 4, // 线索转化/脉动态用户转化
            creation_method: 1, //系统创建
            contacts_name,
            contacts_duty: contacts_duty || '-',
            contacts_phone: phone,
        };

        const [ret, errMsg] = await mutate('contacts', 'insert', payload);
        if (!ret || errMsg) {
            return false;
        }
        reloadCPL();
    };

    useEffect(() => {
        if (customer?.id && currentCallPhoneNum) {
            createContact(currentCallPhoneNum);
        }
    }, [customer, callStore]);

    // 活动记录配置
    const { data: actionLogConfig } = useRequest(fetchActionLogConfig);

    //活动记录类型
    const arType = useMemo(() => {
        return actionLogConfig?.arType?.find(item => item.label === entityName)
            ?.value;
    }, [actionLogConfig, entityName]);

    // 商机列表
    const {
        data: optList,
        loading: optListLoading,
        refresh: optsRefresh,
    } = useRequest(
        () => {
            const optId = arType === 4 && bizId ? bizId : undefined;
            return fetchOpportunityData(customerId, optId);
        },
        {
            ready: drawerVis,
            refreshDeps: [customerId],
            onSuccess(res) {
                // 只有一条商机时默认选中 (客户or商机活动记录时)
                if (
                    res?.length === 1 &&
                    ['商机', '客户'].includes(entityName)
                ) {
                    setSelectedRowKeys([res[0].id]);
                }
            },
        },
    );

    // // 客户-任务列表
    // const { data: taskList } = useRequest(
    //     () => {
    //         return fetchActionLogTaskList(customerId);
    //     },
    //     {
    //         ready: drawerVis,
    //         refreshDeps: [customerId],
    //         onSuccess(res) {
    //             if (res?.length) {
    //                 const runTask = res.filter(task => task.task_state === 2);
    //                 if (runTask.length > 0) {
    //                     setFieldsValue({ taskId: runTask?.[0].id });
    //                 } else {
    //                     setFieldsValue({ taskId: res?.[0].id });
    //                 }
    //             }
    //         },
    //     },
    // );

    useEffect(() => {
        if (!hiddenTask) {
            fetchActionLogTaskList();
        }
    }, [customer, drawerVis]);

    const fetchActionLogTaskList = async () => {
        setTaskId(undefined);
        setTaskListLoading(true);
        const [d, e] = await commonQueryJob(
            'task_activation',
            {
                pageSize: 30,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'follow_customer',
                        filterValue: customerId,
                        operateType: '=',
                    },
                    {
                        fieldId: 'task_state',
                        filterValue: '2,3',
                        operateType: 'in',
                    },
                ],
            },
            {
                orderBy: 'end_time',
                // sortStrategy: 0, // 升
            },
            'PUBLIC-VIEW',
            undefined,
            'SEARCH-PUBLIC',
        );
        setTaskListLoading(false);
        if (isNetSuccess(d, e) && d?.datas) {
            const runTask = [];
            const exceedTask = [];
            for (const item of d.datas) {
                if (item.task_state === 3) {
                    exceedTask.push(item);
                    continue;
                }
                if (item.task_state === 2) {
                    runTask.push(item);
                    continue;
                }
            }
            if (runTask.length > 0) {
                setTaskId(runTask?.[0].id);
            } else {
                if (exceedTask.length > 0) {
                    setTaskId(exceedTask?.[0].id);
                }
            }
        }
        return [d, e];
    };

    // 客户信息采集
    useRequest(() => fetchCustomerInfo(customerId), {
        ready: drawerVis,
        refreshDeps: [customerId],
        onSuccess(res) {
            // 采集字段回填
            const finalData = metaFieldMap.reduce((prev: any, key) => {
                prev[key] = res?.[key];
                return prev;
            }, {});
            setFieldsValue(finalData);
        },
    });

    const { data: customerPersonList, loading: loadingUPL } = useRequest(
        () => fetchCustomerPersonList(customerSearchText),
        { ready: !!callStore, refreshDeps: [customerSearchText] },
    );

    // 监听商机推进消息
    useEventListener('message', event => {
        if (event.origin !== window.location.origin || !drawerVis) return;
        const { type, payload } = event.data;

        if (type === 'opt-stage-update') {
            // 当前商机阶段变更
            if (currentOpt.id === payload.opportunityId) {
                const updateStage = payload.winRate;
                const isChange = currentOpt.win_rate !== updateStage;
                Modal.destroyAll();
                if (isChange) {
                    Modal.success({
                        content: `检测到当前商机已变更至${updateStage}，已为您保存`,
                        okText: '确定',
                        zIndex: 1035,
                        onOk: () => {
                            addActionLog(`变更至${updateStage}商机`);
                            optsRefresh();
                        },
                    });
                }
            }
        }
    });

    const taskFollowTargetsMap = arrToJson(
        actionLogConfig?.taskFollowTarget || [],
        'value',
    );

    const hideActionModal = () => {
        callStore?.resetCallActionConfig();
        callStore?.isCalling() && callStore.closeCall();
        setContactPerson();
        setShowAddContact(false);
        setSelectedRowKeys([]);
        resetFields();
        setDrawerVis && setDrawerVis(false);
    };

    // 支持两种方式，一种event打开/一种挂载
    const showModal = (eventProps: IAddFollowLogPropsFromEventOrProps) => {
        if (
            eventProps.id &&
            eventProps.entityType &&
            eventProps.cid &&
            actionLogConfig
        ) {
            const entityName = EntityTypeMap[eventProps.entityType];
            setEntityName(entityName);
            setCustomerId(eventProps.cid);
            setBizId(eventProps.id as number);
            setFollowLogProps({ ...eventProps });

            setDrawerVis && setDrawerVis(true);
        } else {
            message.error('系统发生错误～');
        }
    };

    useEffect(() => {
        return () => {
            customerListStore.defaultEE.removeListener('save-action-log-show');
        };
    }, []);

    useEffect(() => {
        if (!props.initMark) {
            // 重载一次ee
            if (actionLogConfig && !callStore) {
                // 非全局的也可以用event唤起
                customerListStore.defaultEE.on(
                    'save-action-log-show',
                    showModal,
                );
            }
            console.log('follow_props', props);
            setFollowLogProps({ ...props });
        }
    }, [actionLogConfig, props.initMark]);

    useEffect(() => {
        if (drawerVis) {
            const defaultAddData: IFollowLogSaveData = {
                followType: actionLogConfig?.followType?.[0]?.value as string,
            };

            setFieldsValue({
                ...defaultAddData,
            });
            if (callStore?.currentCallPhoneNum) {
                setCurrentCallPhoneNum(callStore?.currentCallPhoneNum);
            }
        } else {
            setCurrentCallPhoneNum('');
        }
    }, [drawerVis]);

    // 添加活动记录
    const addActionLog = async (propulsionState: string) => {
        if (optList?.length && arType === 1 && !currentOpt) {
            message.error('请选择一个商机');
            return;
        }
        if (!contactPerson?.length) {
            message.error('联系人必填');
            return;
        }
        validateFields(async (errs, values) => {
            console.log('errs', errs, values);
            if (errs) return;

            const saveDevData = {
                ...values,
                arType,
                bizId,
                leadId: entityName === '线索' ? bizId : undefined,
                opportunityId: currentOpt?.id,
                customerId: customerId,
                image: values.image ? values.image.join(',') : undefined,
                propulsionState,
                callrecordId: callStore?.recordId,
                taskId,
            };

            setSaveActionLogLoading(true);
            const data = await addActionLogNet(saveDevData);
            if (_.isNumber(data)) {
                const contactObj = _.find(contactPerson, person => {
                    return person?.id === values.contactsId;
                });
                onCreated &&
                    onCreated({
                        ...saveDevData,
                        isCreateJob,
                        contactObj,
                        createdData: { id: data },
                    });
            }
            setSaveActionLogLoading(false);
            if (data && !data.msg) {
                message.success('添加活动记录成功', 1);
                hideActionModal();
                tryRefresh([
                    'customer',
                    'lead',
                    'opt',
                    'contacts',
                    'task_activation',
                ]);
                customerListStore.defaultEE.emit('add-follow-log-success');
            } else {
                message.error(data?.msg || '添加活动记录失败', 1);
            }
        });
    };

    // 当前选中商机
    const currentOpt = useMemo(() => {
        const opt = optList?.find(
            (item: any) => item?.id === selectedRowKeys?.[0],
        );
        return opt;
    }, [optList, selectedRowKeys]);

    // 商机推进
    const handlePushOpt = () => {
        if (optList?.length && arType === 1 && !currentOpt) {
            message.error('请选择一个商机');
            return;
        }
        validateFields(async (errs, values) => {
            console.log('errs', errs, values);
            if (errs) return;
            if (!currentOpt?.win_rate) {
                setCustomerOptCreateTrigger(1 + customerOptCreateTrigger);
                return;
            }
            if (currentOpt?.id) {
                window.open(
                    '/buy/opportunity/all?' +
                        querystring.stringify({
                            id: currentOpt.id,
                            target: 'customer-drawer',
                        }),
                    '__blank',
                );
            }
        });
    };

    // 商机推进按钮展示
    const pushOptButtonVisible = useMemo(() => {
        if (!currentOpt?.win_rate && !contactPerson?.length) {
            return false;
        }
        if (!optList?.length) {
            return true;
        }
        if (!currentOpt?.win_rate && !!optList?.length) {
            return false;
        }
        if ((currentOpt?.win_rate || 0) <= 60) {
            return true;
        }
        return false;
    }, [currentOpt?.win_rate, optList]);

    console.log('actionLogConfig', actionLogConfig, currentOpt);

    const isClosePhone = callStore?.statusText === '空闲中';
    const isNoPhone = callStore?.statusText === undefined;

    return (
        <>
            <CustomerOptCreate
                onPrecheckLoadingChange={(nextLoading: boolean) => {
                    // setPrecheckLoading(nextLoading);
                }}
                trigger={customerOptCreateTrigger}
                customerInfo={customer}
                onSuccess={() => {
                    addActionLog('创建20商机');
                    // reload();
                }}
            />
            <Dialog
                ref={dialogRef}
                visible={!!drawerVis}
                width="auto"
                style={{
                    top: 0,
                    right: 0,
                    display: 'flex',
                    backgroundColor: 'white',
                    boxShadow: 'rgb(21, 22, 31,0.3) 13px 3px 80px 0px',
                    borderRadius: '12px',
                }}
            >
                {!(isOpenPopover || isUserWhite) ? null : (
                    <>
                        {/* <div
                            className="leftVisible-icon"
                            style={{
                                // visibility: !leftVisible ? 'visible' : 'hidden',
                                transform: leftVisible
                                    ? 'rotate(180deg)'
                                    : 'rotate(0)',
                                cursor: 'pointer',
                                boxShadow:
                                    'rgb(179 177 177,0.3) -22px 3px 20px 0px',
                            }}
                            onClick={() => {
                                setLeftVisible(prev => !prev);
                            }}
                        /> */}
                        <div
                            className="popover-wrapper"
                            style={{
                                display: leftVisible ? 'block' : 'none',
                            }}
                        >
                            <Prompt
                                phoneNum={currentCallPhoneNum}
                                customerId={customerId}
                            />
                        </div>
                    </>
                )}

                <div
                    className="add-follow-log-content-new"
                    style={{
                        maxHeight: actionLogCollapsed ? '80px' : undefined,
                    }}
                >
                    <CallBar
                        callStore={callStore}
                        style={{ marginBottom: '25px' }}
                        onCollapse={collapsed => {
                            setActionLogCollapsed(collapsed);
                            setLeftVisible(!collapsed);
                        }}
                    />
                    <p className="add-follow-log-title">
                        <span className="follow-log-title follow-collapse-header">
                            {entityName}活动记录（拖动可调整位置）
                        </span>
                        {(isClosePhone || isNoPhone) && (
                            <div
                                className="add-follow-log-close"
                                onClick={() => {
                                    isClosePhone
                                        ? Modal.confirm({
                                              content: `关闭后，将无法给此次通话创建活动记录，是否关闭？ `,
                                              okText: '确定',
                                              zIndex: 1035,
                                              onOk: () => {
                                                  hideActionModal();
                                                  setLeftVisible(true);
                                              },
                                              onCancel: () => {},
                                          })
                                        : hideActionModal();
                                }}
                            >
                                <Icon style={{ zIndex: 1 }} type="close" />
                            </div>
                        )}
                    </p>

                    <div
                        className="add-follow-log-modal-body"
                        style={{
                            overflowY: 'auto',
                            maxHeight: callStore
                                ? 'calc(100vh - 90px - 81px)'
                                : 'calc(100vh - 90px)',
                        }}
                    >
                        <div
                            style={
                                (optList && !optList.length) || !customerId
                                    ? { display: 'none' }
                                    : { marginBottom: 10 }
                            }
                        >
                            <AntTable
                                className="follow-log-table"
                                loading={optListLoading}
                                rowSelection={{
                                    type: 'radio',
                                    selectedRowKeys,
                                    onChange: seleced => {
                                        setSelectedRowKeys(seleced);
                                    },
                                }}
                                columns={[
                                    {
                                        title: '商机名称',
                                        dataIndex: 'opportunity_name',
                                    },
                                    {
                                        title: '预计回款时间',
                                        dataIndex: 'prediction_pay_back_time',
                                        render: v => {
                                            return v
                                                ? moment(v).format('YYYY-MM-DD')
                                                : null;
                                        },
                                    },
                                    {
                                        title: '赢率',
                                        dataIndex: 'win_rate',
                                        width: 60,
                                    },
                                ]}
                                dataSource={optList}
                                pagination={false}
                                rowKey="id"
                                scroll={{ y: 184 }}
                            />
                        </div>

                        <Form
                            hideRequiredMark
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 19 }}
                        >
                            {callStore && (
                                <Form.Item label="关联客户">
                                    {callStore.callActionConfig.customerName &&
                                    props.id &&
                                    customerId ? (
                                        <span style={{ fontSize: '12px' }}>
                                            {
                                                callStore.callActionConfig
                                                    .customerName
                                            }
                                        </span>
                                    ) : (
                                        getFieldDecorator('customerId', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择关联客户',
                                                },
                                            ],
                                        })(
                                            <Select
                                                showSearch
                                                filterOption={false}
                                                allowClear
                                                style={{
                                                    width: '300px',
                                                }}
                                                disabled={
                                                    !!bizId && !!customerId
                                                }
                                                onChange={(v: any) => {
                                                    setCustomerId(v);
                                                }}
                                                onSearch={value => {
                                                    debounceSetCustomerSearchText(
                                                        value,
                                                    );
                                                }}
                                                showArrow={false}
                                                defaultActiveFirstOption={false}
                                                notFoundContent={null}
                                                loading={loadingUPL}
                                                dropdownStyle={{
                                                    zIndex: 1111,
                                                }}
                                                dropdownRender={menu => {
                                                    if (!menu) {
                                                        return <span />;
                                                    }
                                                    if (loadingUPL) {
                                                        return (
                                                            <Spin>{menu}</Spin>
                                                        );
                                                    }
                                                    return menu;
                                                }}
                                            >
                                                {customerPersonList?.map(
                                                    customer => {
                                                        return (
                                                            <Select.Option
                                                                label={`${customer.customer_name}`}
                                                                key={
                                                                    customer.id
                                                                }
                                                                value={
                                                                    customer.id
                                                                }
                                                            >
                                                                {`${customer.customer_name}`}
                                                            </Select.Option>
                                                        );
                                                    },
                                                )}
                                            </Select>,
                                        )
                                    )}
                                </Form.Item>
                            )}

                            <AntCollapse
                                defaultActiveKey={['followType']}
                                collapseConfig={[
                                    {
                                        key: 'followType',
                                        style: {
                                            marginLeft: -15,
                                        },
                                        header: (
                                            <span className="follow-collapse-header">
                                                跟进方式
                                            </span>
                                        ),
                                        content: (
                                            <>
                                                <Form.Item label="请选择跟进方式">
                                                    {getFieldDecorator(
                                                        'followType',
                                                        {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        '请选择跟进方式',
                                                                },
                                                            ],
                                                        },
                                                    )(
                                                        <Selector
                                                            options={
                                                                actionLogConfig?.followType
                                                                    .filter(
                                                                        item =>
                                                                            item.label !==
                                                                            '备忘',
                                                                    )
                                                                    .map(
                                                                        item => ({
                                                                            label:
                                                                                item.label,
                                                                            value:
                                                                                item.value,
                                                                            icon:
                                                                                iconMap[
                                                                                    item
                                                                                        .value
                                                                                ],
                                                                        }),
                                                                    ) || []
                                                            }
                                                            type="icon"
                                                        />,
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="上传图片">
                                                    {getFieldDecorator('image')(
                                                        <UploadImage
                                                            style={{
                                                                flex: 1,
                                                                lineHeight:
                                                                    'normal',
                                                            }}
                                                            options={{
                                                                multiple: true,
                                                                maxCount: 3,
                                                                fileExtWhitelist: [
                                                                    'jpg',
                                                                    'png',
                                                                    'jpeg',
                                                                ],
                                                                lint:
                                                                    '图片要求:8M以内,支持文件格式为jpg,png,jpeg',
                                                                limit:
                                                                    8 *
                                                                    1024 *
                                                                    1024,
                                                                overlimitLint:
                                                                    '图片大小不能超过8M',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </>
                                        ),
                                    },
                                ]}
                            />
                            <AntCollapse
                                defaultActiveKey={['contacts']}
                                collapseConfig={[
                                    {
                                        key: 'contacts',
                                        style: {
                                            marginLeft: -15,
                                        },
                                        header: (
                                            <span className="follow-collapse-header">
                                                联系人
                                            </span>
                                        ),
                                        content: (
                                            <Spin spinning={loadingCPL}>
                                                <Form.Item label="联系人">
                                                    {Boolean(
                                                        contactPerson?.length,
                                                    ) &&
                                                        getFieldDecorator(
                                                            'contactsId',
                                                            {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            '请选择联系人',
                                                                    },
                                                                ],
                                                            },
                                                        )(
                                                            <Select
                                                                loading={
                                                                    loadingCPL
                                                                }
                                                                size="small"
                                                                style={{
                                                                    width:
                                                                        '120px',
                                                                }}
                                                                disabled={
                                                                    followLogProps?.targetFixed
                                                                }
                                                                showSearch
                                                                filterOption={(
                                                                    inputValue,
                                                                    option: any,
                                                                ) => {
                                                                    if (
                                                                        option?.props?.label.indexOf(
                                                                            inputValue,
                                                                        ) > -1
                                                                    ) {
                                                                        return true;
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                }}
                                                                placeholder="联系人"
                                                                optionFilterProp="label"
                                                                onChange={value => {
                                                                    // 联系人和决策层关联
                                                                    const currentContact = contactPerson?.find(
                                                                        item =>
                                                                            item.id ===
                                                                            value,
                                                                    );
                                                                    setFieldsValue(
                                                                        {
                                                                            isDecision:
                                                                                currentContact?.isDecision,
                                                                        },
                                                                    );
                                                                }}
                                                            >
                                                                {contactPerson?.map(
                                                                    person => {
                                                                        return (
                                                                            <Select.Option
                                                                                label={
                                                                                    person.name
                                                                                }
                                                                                key={
                                                                                    person.id
                                                                                }
                                                                                value={
                                                                                    person.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    person.name
                                                                                }
                                                                            </Select.Option>
                                                                        );
                                                                    },
                                                                )}
                                                            </Select>,
                                                        )}
                                                    {Boolean(
                                                        contactPerson?.length,
                                                    ) && (
                                                        <Button
                                                            type="link"
                                                            style={{
                                                                fontSize: 12,
                                                            }}
                                                            onClick={() => {
                                                                setShowAddContact(
                                                                    true,
                                                                );
                                                            }}
                                                        >
                                                            新建联系人
                                                        </Button>
                                                    )}

                                                    {showAddContact ? (
                                                        <div className="follow-contact-wrap">
                                                            <AddContact
                                                                customerId={
                                                                    customerId
                                                                }
                                                                onSuccess={() => {
                                                                    setShowAddContact(
                                                                        false,
                                                                    );
                                                                    reloadCPL();
                                                                }}
                                                                onClose={() => {
                                                                    setShowAddContact(
                                                                        false,
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {!Boolean(
                                                                contactPerson?.length,
                                                            ) && (
                                                                <div className="follow-contact-wrap">
                                                                    <span className="contact-tips">
                                                                        该实体无对应联系人
                                                                        <a
                                                                            style={{
                                                                                marginLeft: 10,
                                                                            }}
                                                                            onClick={() => {
                                                                                setShowAddContact(
                                                                                    true,
                                                                                );
                                                                            }}
                                                                        >
                                                                            新建联系人
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </Form.Item>
                                                {!!contactPerson?.length && (
                                                    <Form.Item label="是否决策层">
                                                        {getFieldDecorator(
                                                            'isDecision',
                                                            {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            '请选择是否决策层',
                                                                    },
                                                                ],
                                                            },
                                                        )(
                                                            <Selector
                                                                options={
                                                                    actionLogConfig?.isDecision ||
                                                                    []
                                                                }
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                )}
                                            </Spin>
                                        ),
                                    },
                                ]}
                            />

                            <AntCollapse
                                defaultActiveKey={['followResult']}
                                collapseConfig={[
                                    {
                                        key: 'followResult',
                                        style: {
                                            marginLeft: -15,
                                        },
                                        header: (
                                            <span className="follow-collapse-header">
                                                跟进结果
                                            </span>
                                        ),
                                        content: (
                                            <>
                                                <Form.Item label="联系结果">
                                                    {getFieldDecorator(
                                                        'relateResults',
                                                        {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        '请选择联系结果',
                                                                },
                                                            ],
                                                        },
                                                    )(
                                                        <Selector
                                                            options={
                                                                actionLogConfig?.relateResults.filter(
                                                                    item =>
                                                                        item.selected,
                                                                ) || []
                                                            }
                                                            onChange={selected => {
                                                                const noteValue = getFieldValue(
                                                                    'note',
                                                                );
                                                                if (
                                                                    !noteValue &&
                                                                    selected ===
                                                                        4
                                                                ) {
                                                                    setFieldsValue(
                                                                        {
                                                                            note:
                                                                                '电话无法接通、换时间沟通',
                                                                        },
                                                                    );
                                                                }
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="需求意向">
                                                    {getFieldDecorator(
                                                        'demandIntention',
                                                        {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        '请选择需求意向',
                                                                },
                                                            ],
                                                        },
                                                    )(
                                                        <Selector
                                                            options={
                                                                actionLogConfig?.demandIntention ||
                                                                []
                                                            }
                                                        />,
                                                    )}
                                                </Form.Item>
                                                {/* <Form.Item label="推进难点">
                                                    {getFieldDecorator(
                                                        'promoteDifficulty',
                                                        {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        '推进难点不能为空',
                                                                },
                                                            ],
                                                        },
                                                    )(
                                                        <AntInputTextArea
                                                            rows={3}
                                                            // showCount
                                                            maxLength={300}
                                                            placeholder="添加推进难点"
                                                            allowClear
                                                        />,
                                                    )}
                                                </Form.Item> */}
                                                <Form.Item label="跟进内容">
                                                    {getFieldDecorator('note', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '跟进记录不能为空',
                                                            },
                                                        ],
                                                    })(
                                                        <AntInputTextArea
                                                            rows={4}
                                                            showCount
                                                            maxLength={500}
                                                            placeholder="添加跟进内容"
                                                            allowClear
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </>
                                        ),
                                    },
                                ]}
                            />
                        </Form>
                    </div>

                    <div className="add-follow-log-modal-footer">
                        {pushOptButtonVisible && (
                            <AntButton onClick={handlePushOpt} size="small">
                                {optPushMap.get(currentOpt?.win_rate || 0)}
                            </AntButton>
                        )}
                        <AntButton
                            type="primary"
                            onClick={() => {
                                addActionLog('保存');
                            }}
                            size="small"
                            style={{
                                fontSize: '12px',
                                width: '60px',
                            }}
                            loading={saveActionLogLoading}
                        >
                            保存
                        </AntButton>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default Form.create<
    IAddFollowLogPropsFromEventOrProps & FormComponentProps
>()(AddFollowLog);
