import React from 'react';
import { DefaultAction } from './actions/default';
import { RejectAction } from './actions/rejects';
import { TransferAction } from './actions/transfer';
import { SubmiAction } from './actions/submit';
import { AgreeAction } from './actions/agree';
import { AgreeAddApproveAction } from './actions/agree-add-approve';
import { ProcessCatStore } from '../store';
import { DeleteAction } from './actions/delete';

export interface ISubmitActionsProps {
    currentAction: string;
    data: any;
    onOk: (extra?: any) => void;
    onCancel: () => void;
    defaultStore: ProcessCatStore | null;
    loading: boolean;
}

export const SubmitActions: React.FC<ISubmitActionsProps> = ({
    currentAction,
    data,
    onOk,
    onCancel,
    defaultStore,
    loading,
}) => {
    return (
        <>
            <DefaultAction
                {...{ currentAction, data, onOk, onCancel, loading }}
            />
            <SubmiAction
                {...{ currentAction, data, onOk, onCancel, loading }}
            />
            <DeleteAction
                {...{
                    currentAction,
                    data,
                    onOk,
                    onCancel,
                    loading,
                    defaultStore,
                }}
            />
            <RejectAction
                {...{ currentAction, data, onOk, onCancel, loading }}
            />
            <TransferAction
                {...{
                    currentAction,
                    data,
                    onOk,
                    onCancel,
                    defaultStore,
                    loading,
                }}
            />
            <AgreeAction
                {...{
                    currentAction,
                    data,
                    onOk,
                    onCancel,
                    defaultStore,
                    loading,
                }}
            />
            <AgreeAddApproveAction
                {...{
                    currentAction,
                    data,
                    onOk,
                    onCancel,
                    defaultStore,
                    loading,
                }}
            />
        </>
    );
};
