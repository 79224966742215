import React, { useState } from 'react';
import { LayoutStandardCrud } from '@/components/layouts/standard-crud';
import { useRequest } from 'ahooks';
import { AntButton, TableLv2 } from '@/components';
import moment from 'moment';
import { message } from 'antd';
import FormModal from './form-model';
import {
    getCustomerReceive,
    addCustomerReceive,
    updataCustomerReceive,
    deleteCustomerReceive,
} from './api';

const CustomerReceive: React.FC<{}> = () => {
    const [visible, setVisible] = useState(false);
    const [modalData, setModalData] = useState<any>();

    const { data: dataSource, loading, refresh } = useRequest(
        getCustomerReceive,
    );

    const handleConfirm = async (values: any) => {
        const [d, e] = modalData?.id
            ? await updataCustomerReceive(values)
            : await addCustomerReceive(values);

        if (!e) {
            message.success(modalData?.id ? '更新成功' : '添加成功');
            refresh();
            setVisible(false);
            setModalData(null);
        }
    };

    const handleDelete = async (id: number) => {
        const [d, e] = await deleteCustomerReceive(id);
        if (!e) {
            refresh();
        }
    };

    const globalOptions = (
        <>
            <AntButton
                onClick={() => {
                    setVisible(true);
                }}
                type="primary"
            >
                新增优先销售
            </AntButton>
        </>
    );

    return (
        <>
            <FormModal
                visible={visible}
                initialValues={modalData}
                onSubmit={handleConfirm}
                onClose={() => {
                    setVisible(false);
                    setModalData(null);
                }}
            />
            <LayoutStandardCrud
                message={null}
                popups={null}
                title="优先领取"
                multipleOps={null}
                globalOps={globalOptions}
                filters={null}
                className="customer-hsp-aa-content"
                dataTable={
                    <TableLv2
                        loading={loading}
                        dataSource={dataSource}
                        pagination={false}
                        closeRowSelection={true}
                        onAction={(actionKey, actionItem: any) => {
                            if (actionKey === 'delete') {
                                handleDelete(actionItem.id);
                            }

                            if (actionKey === 'update') {
                                setVisible(true);
                                setModalData({
                                    ...actionItem,
                                    earlyUserId: actionItem?.earlyUserId?.toString(),
                                });
                            }
                        }}
                        actions={[
                            {
                                actionKey: 'update',
                                actionCn: '修改',
                            },
                            {
                                actionKey: 'delete',
                                actionCn: '删除',
                                popconfirm: true,
                                popcomfirmConfig: {
                                    title: '是否要删除该条数据？',
                                },
                            },
                        ]}
                        columns={[
                            {
                                title: '姓名',
                                dataIndex: 'earlyUserName',
                                key: 'earlyUserName',
                            },
                            {
                                title: '提前时长（秒）',
                                dataIndex: 'earlyTime',
                                key: 'earlyTime',
                            },
                            {
                                title: '领取个数',
                                dataIndex: 'earlyCount',
                                key: 'earlyCount',
                            },
                            {
                                title: '配置人',
                                dataIndex: 'updateUserName',
                                key: 'updateUserName',
                            },
                            {
                                title: '配置时间',
                                dataIndex: 'createTime',
                                key: 'createTime',
                                render: v =>
                                    moment(v).format('YYYY-MM-DD HH:mm'),
                            },
                            {
                                title: '最后修改时间',
                                dataIndex: 'updateTime',
                                key: 'updateTime',
                                render: v =>
                                    moment(v).format('YYYY-MM-DD HH:mm'),
                            },
                        ]}
                    />
                }
            />
        </>
    );
};

export default CustomerReceive;
