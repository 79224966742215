import React, { useContext, useState } from 'react';
import { observer } from '@/containers/hoc';

import { StoreContext } from '@/stores';

import { RouteComponentProps } from '@reach/router';
import { Divider } from 'antd';

const RTKCounterRaw: React.FC<RouteComponentProps> = ({
    children,
    location,
}) => {
    const store = useContext(StoreContext);
    const [localCounter] = useState(() => new store.Counter());
    const defaultCounter = store.getDefaultCounter();

    return (
        <div>
            <h3>localCounter</h3>
            <div>
                <div>
                    <button
                        aria-label="Increment value"
                        onClick={() => localCounter.increaseTime()}
                    >
                        +
                    </button>
                    <span>{localCounter.times}</span>
                    <button
                        aria-label="Decrement value"
                        onClick={() => localCounter.decreaseTime()}
                    >
                        -
                    </button>
                </div>
            </div>

            <h3>defaultCounter</h3>
            <div>
                <div>
                    <button
                        aria-label="Increment value"
                        onClick={() => defaultCounter.increaseTime()}
                    >
                        +
                    </button>
                    <span>{defaultCounter.times}</span>
                    <button
                        aria-label="Decrement value"
                        onClick={() => defaultCounter.decreaseTime()}
                    >
                        -
                    </button>
                </div>
            </div>
            {children}
        </div>
    );
};

const RTKCounter = observer(RTKCounterRaw);
export default RTKCounter;
export * from './dummy';
