import React, { useEffect, useState } from 'react';

import { ContractListStore } from '../store';
import { Modal, Radio, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { AntTable, BlockLoading2 } from '@/components';
import { commonQuery } from '@/api/rest';
import { isNetSuccess } from '@/utils';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { EquityDetailBase } from '../../contract-product/components/equity-detail';
import { render } from 'react-dom';
import { CtxProvider } from '@/utils/context';
import { FinalProcessFormProxy } from '@/pages/sales-follow-up/process/list/form-proxy';
import _ from 'lodash';
import { useAnyContractProductListStore } from '@/pages/om/contract/contract-product/store';

const columns: Array<ColumnProps<any>> = [
    {
        title: '',
        dataIndex: 'id',
        width: 20,
        render(
            id,
            {
                selectedId,
                setSelectedId,
                execute_status,
                product_type,
                product_version,
            },
        ) {
            //
            const isWorkbench = +product_type === 1 && +product_version === 6;
            const openable = isWorkbench
                ? +execute_status === 0 ||
                  +execute_status === 1 ||
                  +execute_status === 4
                : +execute_status === 0;
            return (
                <Radio
                    style={{ top: -2 }}
                    disabled={!openable}
                    checked={id === selectedId}
                    onChange={e => e.target.checked && setSelectedId(id)}
                />
            );
        },
    },
    {
        title: '产品名称',
        dataIndex: 'product_name',
    },
    {
        title: '权益明细',
        width: 80,
        dataIndex: 'equity_detail',
        render(equityDetailStr) {
            let dataSource: any[] = [];
            try {
                dataSource = JSON.parse(equityDetailStr);
            } catch (e) {}
            return (
                <a
                    onClick={() => {
                        const md = Modal.info({
                            content: (
                                <EquityDetailBase dataSource={dataSource} />
                            ),
                            title: '权益详情',
                            cancelText: '关闭',
                        });
                    }}
                >
                    查看权益
                </a>
            );
        },
    },
    {
        title: '总价',
        dataIndex: 'total_price',
    },
    {
        title: '交付方式',
        dataIndex: 'deliver_method',
        render(d, data) {
            return enumDeliverMethod[d] || null;
        },
    },
    {
        title: '创建时间',
        dataIndex: 'create_time',
        render(ts, data) {
            return moment(ts).format('YYYY-MM-DD HH:mm:ss');
        },
    },
    {
        title: '执行状态',
        dataIndex: 'execute_status',
        render(d, data) {
            return statusMap[d] || null;
        },
    },
];

const statusMap: { [k: string]: string } = {
    '0': '未申请',
    '1': '提交申请',
    '2': '执行完成',
    '3': '已拒绝',
    '4': '部分执行',
};

const enumDeliverMethod: { [k: string]: string } = {
    '1': '线上开通',
    '2': '线下交付',
};

export const ActionPreOpen: React.FC<{
    defaultStore: ContractListStore;
}> = observer(({ defaultStore }) => {
    const { mutatingData } = defaultStore;
    const visible = defaultStore.actionType === 'open_apply';
    const [
        prepared,
        contractProductStore,
        toPrepared,
    ] = useAnyContractProductListStore({ autoLoad: false });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);

    const { fillInContractProductProcessObjLoading } = contractProductStore;

    useEffect(() => {
        if (!visible) {
            return;
        }
        toPrepared();
    }, [visible]);

    useEffect(() => {
        if (!visible || !(mutatingData && mutatingData.id)) {
            return;
        }
        // 去请求合同产品
        setLoading(true);
        commonQuery(
            'contract_product',
            {
                pageSize: 100,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'contract_id',
                        filterValue: mutatingData.id,
                        operateType: '=',
                    },
                ],
            },
        ).then(([d, e]) => {
            setLoading(false);
            if (isNetSuccess(d, e)) {
                setData(d?.datas || []);
                // const id = d?.datas?.[0]?.id;
                // if (id) {
                //     setSelectedId(id);
                // }
            }
        });
    }, [visible, mutatingData]);

    const [selectedId, setSelectedId] = useState<number | null>(null);

    if (mutatingData === null) {
        return null;
    }
    return (
        <>
            <Modal
                visible={visible}
                title={'产品开通'}
                onCancel={() => {
                    defaultStore.resetAction();
                }}
                style={{ width: 800 }}
                onOk={async () => {
                    // nextAction
                    const seletedItem = data.find(
                        item => item.id === selectedId,
                    );
                    if (!seletedItem || !selectedId) {
                        return message.error('请先选择要开通的产品');
                    }

                    // fillInContractProductGuzhuProcessObjLoading
                    // fillInContractProductGuzhuProcessObjData
                    // setFillInContractProductGuzhuProcessObjData
                    // fillInContractProductGuzhuProcessObj

                    if (seletedItem?.deliver_method === 2) {
                        // TODOO
                        contractProductStore
                            .fillInContractProductGuzhuProcessObj(selectedId)
                            .then(ok => {
                                if (ok) {
                                    contractProductStore.setAction(
                                        'start-process-guzhu',
                                    );
                                    defaultStore.resetAction();
                                } else {
                                    message.error('合同产品信息请求出错');
                                }
                            });
                    } else {
                        contractProductStore
                            .fillInContractProductProcessObj(selectedId)
                            .then(ok => {
                                if (ok) {
                                    contractProductStore.setAction(
                                        'start-process',
                                    );
                                    defaultStore.resetAction();
                                } else {
                                    message.error('合同产品信息请求出错');
                                }
                            });
                    }
                }}
                okText={'开通'}
                confirmLoading={
                    loading ||
                    !prepared ||
                    fillInContractProductProcessObjLoading
                }
            >
                {loading || !prepared ? (
                    <BlockLoading2 />
                ) : (
                    <AntTable
                        columns={columns}
                        dataSource={data.map(item => ({
                            ...item,
                            selectedId,
                            setSelectedId,
                        }))}
                        pagination={false}
                    />
                )}
            </Modal>
            <CtxProvider
                ctx={{ position: 'list', positionDetail: 'contract-product' }}
            >
                <FinalProcessFormProxy
                    visible={
                        contractProductStore.actionType === 'start-process'
                    }
                    onClose={() => {
                        contractProductStore.setMutatingData(null);
                        contractProductStore.resetAction();
                    }}
                    key={
                        contractProductStore.actionType === 'start-process'
                            ? 'opened'
                            : 'nooppened'
                    }
                    processKey={'productopen'}
                    lifeCycle={{
                        onClose: store => {
                            store.setMutatingData(null);
                            contractProductStore.setFillInContractProductProcessObjData(
                                null,
                            );
                        },
                        onOpen: store => {
                            if (
                                !contractProductStore.fillInContractProductProcessObjData
                            ) {
                                return;
                            }
                            const {
                                fillInContractProductProcessObjData: data,
                            } = contractProductStore;
                            store.startNewData();
                            store.setMutatingData(
                                _.assign(
                                    store.mutatingData,
                                    { talent_bank_version: 0 },
                                    data,
                                ),
                            );
                        },
                    }}
                    preRequestReady={
                        !contractProductStore.fillInContractProductProcessObjLoading
                    }
                />
            </CtxProvider>
            <CtxProvider
                ctx={{ position: 'list', positionDetail: 'contract-product' }}
            >
                <FinalProcessFormProxy
                    visible={
                        contractProductStore.actionType ===
                        'start-process-guzhu'
                    }
                    onClose={() => {
                        contractProductStore.setMutatingData(null);
                        contractProductStore.resetAction();
                    }}
                    key={
                        contractProductStore.actionType ===
                        'start-process-guzhu'
                            ? 'opened'
                            : 'nooppened'
                    }
                    processKey={'contractemployer'}
                    lifeCycle={{
                        onClose: store => {
                            store.setMutatingData(null);
                            contractProductStore.setFillInContractProductGuzhuProcessObjData(
                                null,
                            );
                        },
                        onOpen: store => {
                            if (
                                !contractProductStore.fillInContractProductGuzhuProcessObjData
                            ) {
                                return;
                            }
                            const {
                                fillInContractProductGuzhuProcessObjData: data,
                            } = contractProductStore;
                            store.startNewData();
                            store.setMutatingData(
                                _.assign(
                                    store.mutatingData,
                                    { talent_bank_version: 0 },
                                    data,
                                ),
                            );
                        },
                    }}
                    preRequestReady={
                        !contractProductStore.fillInContractProductGuzhuProcessObjLoading
                    }
                />
            </CtxProvider>
        </>
    );
});
