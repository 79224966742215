/*
 * @Author: your name
 * @Date: 2020-12-08 14:57:57
 * @LastEditTime: 2020-12-14 14:54:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /maimai_crm/src/components/common/user-selector/index.js
 */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Select } from 'antd';
import { Avatar } from 'antd';
import { SelectProps } from 'antd/lib/select';
import './index.scss';
import { toJS } from 'mobx';

export interface IUserSelectorItems {
    name: string | number; // 用户名
    id?: string | number; // 主键
    userId?: string | number; // 用户id
    avatar?: string; // 头像
}
export interface IUserSelectorProps extends SelectProps {
    optimizeLength?: boolean; // 是否限制首次加载长度
    userDocs: IUserSelectorItems[]; // 数据源
    [key: string]: any;
    showSelectAll?: boolean;
    selectAllText?: string;
}
let sign = true;
const UserSelector: React.FC<IUserSelectorProps> = props => {
    const {
        optimizeLength = true,
        defaultValue,
        userDocs,
        showSelectAll = false,
        selectAllText = '',
        value,
    } = props;
    const [searchValue, setSearchValue] = useState('');
    const [renderDocs, setRenderDocs] = useState([]);
    const [sign, setSign] = useState(true);
    useEffect(() => {
        return () => {
            setSign(true);
        };
    }, []);

    useEffect(() => {
        setSign(true);
    }, [userDocs, value]);

    useEffect(() => {
        setRenderDocs(filterUserDocsLength(userDocs, searchValue) as never[]);
    }, [searchValue, userDocs, value]);

    // 搜索
    const filterUserDocsLength = (
        docs: IUserSelectorItems[] = [],
        search = '',
    ) => {
        // 初次筛选，根据搜索值的不同筛选出不同的选项
        const newDocs =
            search !== ''
                ? _.filter(docs, doc => {
                      const { name: userName = '', id = '', userId = '' } = doc;
                      const stringName = String(userName);
                      const stringId = String(id);
                      const stringUserId = String(userId);
                      return (
                          stringName.indexOf(search) >= 0 ||
                          stringUserId.indexOf(search) >= 0 ||
                          stringId === defaultValue
                      );
                  })
                : _.clone(docs);
        if (value && sign && !Array.isArray(value)) {
            // 适配单选
            docs.forEach(doc => {
                if (value == doc?.id) {
                    newDocs.unshift(doc);
                }
            });
            // sign = false;
            setSign(false);
        }
        if (value && Array.isArray(value) && sign && value.length) {
            docs.forEach(doc => {
                if (!!_.find(value, item => item == doc?.id)) {
                    newDocs.unshift(doc);
                }
            });
            // sign = false;

            setSign(false);
        }
        // 适配多选默认值
        if (
            defaultValue &&
            Array.isArray(defaultValue) &&
            sign &&
            defaultValue.length
        ) {
            docs.forEach(doc => {
                if (!!_.find(defaultValue, item => item == doc?.id)) {
                    newDocs.unshift(doc);
                }
            });
            // sign = false;
            setSign(false);
        }
        // 适配单选及表单下默认值
        if (
            props['data-__meta'] &&
            props['data-__meta'].initialValue &&
            !search &&
            sign
        ) {
            console.log(1);
            const defaultUser = _.filter(
                docs,
                doc => doc.id === props['data-__meta'].initialValue,
            )[0];
            // tslint:disable-next-line:no-unused-expression
            defaultUser && newDocs.unshift(defaultUser);
            // sign = false;
            setSign(false);
        }

        const maxLength = optimizeLength ? 30 : Number.MAX_SAFE_INTEGER;
        return _.union(_.take(newDocs, maxLength));
        // return _.union(newDocs);
    };

    const renderOption = (
        avatar: string,
        value: any,
        text: string,
        subtext: string,
    ) => {
        return (
            <Select.Option key={value} value={value}>
                <div className="crm-user-select-item">
                    <Avatar
                        icon="user"
                        shape="square"
                        size="small"
                        src={avatar}
                    />
                    <div className="user-select-item-right">
                        <span className="text">{text}</span>
                        <span className="subtext">{subtext || ''}</span>
                    </div>
                </div>
            </Select.Option>
        );
    };
    return (
        <Select
            mode="default"
            optionLabelProp="children"
            filterOption={false}
            showSearch={true}
            allowClear={true}
            onSearch={val => setSearchValue(val)}
            onSelect={() => {
                setSearchValue('');
            }}
            style={{ minWidth: '150px' }}
            {...props}
            className="crm-user-selector-content"
        >
            {showSelectAll && (
                <Select.Option value={'all'} key={'all'}>
                    {selectAllText}
                </Select.Option>
            )}
            {_.map(renderDocs, (doc: IUserSelectorItems, idx) => {
                if (!doc) {
                    return null;
                }
                return renderOption(
                    doc.avatar || '',
                    String(doc.id),
                    String(doc.name),
                    '',
                );
            })}
        </Select>
    );
};

export default UserSelector;
