import React, { useContext, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AntButton, BlockLoading2 } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { AntDrawer } from '@/components/antd/drawer';
import { defaultAuthDeco } from '@/containers/hoc';
import { ContactListStore } from '../store';
import DemoForm from './form';
import { tryRefresh } from '@/utils';
import { CtxContext } from '@/utils/context';

const FormDrawer: React.FC<RouteComponentProps & {
    mutateSuccess?: any;
    defaultStore: ContactListStore;
    preRequiredReady: boolean;
    ignoreCtx?: boolean;
}> = ({ defaultStore, preRequiredReady, mutateSuccess, ignoreCtx = false }) => {
    const { actionType, bigFormVisible } = defaultStore;
    const ctx = useContext(CtxContext);
    const { position, positionDetail } = ctx;
    const leadTrans = ignoreCtx
        ? false
        : positionDetail === 'lead-drawer' ||
          positionDetail === 'customer-drawer' ||
          positionDetail === 'lead-table';

    const cuserTrans = ignoreCtx
        ? false
        : positionDetail === 'customer-drawer-maitrend-cuser';

    const [loading, setLoading] = useState(false);

    if (!preRequiredReady) {
        return null;
    }

    const popups = (
        <>
            <AntDrawer
                title={
                    cuserTrans
                        ? 'c端用户转联系人'
                        : leadTrans
                        ? '线索转联系人'
                        : actionType === 'create'
                        ? '新建联系人'
                        : '修改联系人'
                }
                placement={'right'}
                visible={bigFormVisible}
                onClose={() => {
                    defaultStore.resetAction();
                }}
                bodyStyle={{
                    width: 850,
                    overflow: 'scroll',
                }}
                width={850 + 'px'}
            >
                <div className="standard-drawer-form-wrapper">
                    <DemoForm
                        defaultStore={defaultStore}
                        ignoreCtx={ignoreCtx}
                    />
                    <div className="fixed-form-btns">
                        <AntButton
                            size="large"
                            type="primary"
                            loading={loading}
                            onClick={async () => {
                                setLoading(true);
                                const isSuccess = await defaultStore.dispatchAction();
                                setLoading(false);
                                if (isSuccess) {
                                    mutateSuccess && mutateSuccess();
                                    tryRefresh(['contacts']);
                                }
                            }}
                        >
                            {actionType === 'create' ? '新建' : '修改'}
                        </AntButton>
                        <AntButton
                            size="large"
                            onClick={() => defaultStore.resetAction()}
                        >
                            取消
                        </AntButton>
                    </div>
                </div>
            </AntDrawer>
        </>
    );

    return popups;
};

const FinalFormDrawer = defaultAuthDeco(observer(FormDrawer));
export default FinalFormDrawer;
