import React, { useState, useEffect, useMemo } from 'react';
import './index.less';
import CompositeLiveChartsComponents from '../../composite-live-charts';
import { defaultAxios } from '@/utils';
import { message } from 'antd';
import { EChartsOption } from 'echarts/types/dist/shared';
import moment from 'moment';
import _ from 'lodash';
interface IPerformanceCustomerTrend {
    month: number | string;
    customer_industry: number;
    [key: string]: any;
}
const industryKey2Title = [
    { title: '其他', key: 'customer_industry-0' },
    { title: 'IT互联网', key: 'customer_industry-1' },
    { title: '金融行业', key: 'customer_industry-2' },
    { title: '房产建筑', key: 'customer_industry-3' },
    { title: '商务服务', key: 'customer_industry-4' },
    { title: '生活服务', key: 'customer_industry-5' },
    { title: '文化传媒', key: 'customer_industry-6' },
    { title: '快消品', key: 'customer_industry-7' },
    { title: '耐消品', key: 'customer_industry-8' },
    { title: '制造业', key: 'customer_industry-9' },
    { title: '汽车行业', key: 'customer_industry-10' },
    { title: '通信电子', key: 'customer_industry-11' },
    { title: '贸易零售', key: 'customer_industry-12' },
    { title: '医疗医药', key: 'customer_industry-13' },
    { title: '教育培训', key: 'customer_industry-14' },
    { title: '能源电力', key: 'customer_industry-15' },
    { title: '交通物流', key: 'customer_industry-16' },
    { title: '农林牧渔', key: 'customer_industry-17' },
    { title: '政府机构', key: 'customer_industry-18' },
    { title: '学生', key: 'customer_industry-19' },
];
const PerformanceCustomerTrend: React.FC = () => {
    const [pctLoading, setPctLoading] = useState(false);
    const [pctData, setPctData] = useState<IPerformanceCustomerTrend[]>([]);
    const fetchPCTData = async () => {
        const url = '/crm/data/query/list_income_industry_m';
        setPctLoading(true);
        const [d, e] = await defaultAxios.put(url, {});
        setPctLoading(false);
        if (d === null || d.data === null) {
            message.error('请求客户行业业绩数据失败～');
            return [null, e];
        }
        if (d.data && Array.isArray(d.data)) {
            setPctData(d.data || []);
        }
    };
    useEffect(() => {
        fetchPCTData();
    }, []);
    const customerOptionsSeries: any[] = [];
    let sortOptionsOne = [];
    for (const key in pctData[pctData.length - 1] || {}) {
        if (key != 'month') {
            sortOptionsOne.push({
                title: key,
                value: pctData[pctData.length - 1][key],
            });
        }
    }
    sortOptionsOne = _.orderBy(sortOptionsOne, ['value'], ['desc']);

    const customerOptionsLineKey =
        _.map(sortOptionsOne, item => {
            return item.title;
        }) || [];
    _.forEach(customerOptionsLineKey, item => {
        const fTitleItem = _.find(industryKey2Title, industryItem => {
            return item === industryItem.key;
        });
        if (fTitleItem) {
            customerOptionsSeries.push({
                name: fTitleItem?.title,
                type: 'line',
                symbol: 'none',
                data: _.map(pctData, pcItem => {
                    return pcItem?.[fTitleItem.key] || 0;
                }),
            });
        }
    });

    const customerTrendOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
        },
        grid: {
            left: '2.5%',
            right: '2.5%',
            bottom: '15%',
            top: '1%',
            width: '95%',
            height: '90%',
            containLabel: true,
        },
        color: [
            '#5470c6',
            '#91cc75',
            '#fac858',
            '#CC0033',
            '#73c0de',
            '#3ba272',
            '#fc8452',
            '#9a60b4',
            '#ea7ccc',
            '#333 ',
            '#FF2C2C',
        ],
        legend: {
            bottom: '0',
            // height: '10%',
            itemWidth: 10,
            itemHeight: 10,
            icon: 'rect',
            itemGap: 20,
            textStyle: {
                color: '#6E727A',
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: pctData.map(item => {
                return moment(item.month, 'YYYYMM').format('MMMYYYY');
            }),
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: false,
            },
        },
        series: customerOptionsSeries,
    };
    return (
        <div className="p-c-t-composite-content">
            <div className="p-c-t-header">
                <span className="p-c-t-title">
                    新经济行业的合作客户增长趋势
                </span>
            </div>
            <div className="p-c-t-charts-content">
                <div className="p-c-t-charts-row">
                    <CompositeLiveChartsComponents
                        chartsOptions={customerTrendOptions as any}
                        loading={pctLoading}
                        title={''}
                        showTitle={false}
                        chartsClassName="p-c-t-charts-content"
                    />
                </div>
            </div>
        </div>
    );
};

export default PerformanceCustomerTrend;
