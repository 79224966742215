import querystring from 'querystring';
import { History, navigate, WindowLocation } from '@reach/router';
import { REDIRECT_INFO_KEY } from '@/configs/auth';

import { globalHistory, HistoryListener } from '@reach/router';
import { EffectCallback, useEffect } from 'react';
import _ from 'lodash';

// auto detach
export const attachHistory = (handler: HistoryListener) => {
    const detachHistorySub = globalHistory.listen(handler);
    return () => {
        detachHistorySub();
    };
};

export const useListenHistory = (
    handler: HistoryListener,
    withInit: boolean = true,
) => {
    useEffect(() => {
        if (withInit) {
            handler({
                action: 'POP',
                location: globalHistory.location,
            });
        }
        return attachHistory(handler);
    }, []);
};

export const getQueryFromLocationSearch = (l: WindowLocation) => {
    return querystring.parse(l.search.substring(1));
};

export const addQueryToHistory = (
    query: querystring.ParsedUrlQueryInput,
    keysToClear: string[] = [],
    replace: boolean = false,
) => {
    const nextQuery = querystring.parse(
        globalHistory.location.search.substring(1),
    );
    const keys = _.keys(query);
    for (const key of keysToClear) {
        delete nextQuery[key];
    }
    Object.assign(nextQuery, query);
    const nextSearch = '?' + querystring.stringify(nextQuery);
    const nextPage = window.location.pathname;
    globalHistory.navigate(nextPage + nextSearch);
};

export const removeSearchFromLocationSilent = (keys: string[]) => {
    const queries = querystring.parse(window.location.search.substring(1));
    for (const key of keys) {
        delete queries[key];
    }
    const nextSearch = '?' + querystring.stringify(queries);
    const nextPage = window.location.pathname;

    return window.history.replaceState(null, '', nextPage + nextSearch);
};

const removeRedirectPageFromSearch = (search: string) => {
    const queries = querystring.parse(search.substring(1));
    delete queries.redirect_page;
    const nextSearch = '?' + querystring.stringify(queries);
    return nextSearch;
};

export const recoverRedirect = () => {
    const redirectInfo = window.localStorage.getItem(REDIRECT_INFO_KEY);
    window.localStorage.removeItem(REDIRECT_INFO_KEY);

    const queries = querystring.parse(window.location.search.substring(1));
    if (queries.redirect_page !== undefined && queries.redirect_page !== '') {
        const nextPage = queries.redirect_page;
        const nextSearch = removeRedirectPageFromSearch(window.location.search);
        // 如果使用reachRouter，我们不能使用window.history恢复路由
        console.log('redirect from localstorage redirect query: ', {
            nextPage,
            nextSearch,
        });
        navigate(nextPage + nextSearch, {
            replace: true,
        });
    } else if (redirectInfo) {
        try {
            const obj = JSON.parse(redirectInfo);
            const { nextPage = '/' } = obj;
            let { nextSearch } = obj;
            nextSearch = nextSearch || '?';

            const savedQueries = querystring.parse(nextSearch.substring(1));
            const mergedQueries = Object.assign({}, queries, savedQueries);
            nextSearch = '?' + querystring.stringify(mergedQueries);
            nextSearch = removeRedirectPageFromSearch(nextSearch);

            console.log('redirect from localstorage redirect info: ', {
                nextPage,
                nextSearch,
            });
            navigate(nextPage + nextSearch, {
                replace: true,
            });
        } catch (e) {
            console.error(e);
            // pass
        }
    }
};

export const appendLocationSearchWithRedirectPage = (
    str: string,
    setRedirectInfo: boolean = false,
) => {
    if (setRedirectInfo) {
        window.localStorage.setItem(
            REDIRECT_INFO_KEY,
            JSON.stringify({
                nextPage: window.location.pathname,
                nextSearch: window.location.search,
            }),
        );
    }
    return (
        str +
        '&' +
        window.location.search.substring(1) +
        '&redirect_page=' +
        window.location.pathname
    );
};

export const getQueryAsObject = () => {
    const search = window.location.search.substring(1);
    try {
        return querystring.parse(search);
    } catch (e) {
        return {};
    }
};

// 只取一次
export const useQueryAsObject = (
    onGotQuery: (queryObj: any) => void,
    deps?: any[],
) => {
    useEffect(() => {
        // 取url参数
        const queryObj = getQueryAsObject();
        onGotQuery(queryObj);
    }, deps || []);
};
