import moment from 'moment';

export const getCustomerTags = (originData: any) => {
    const isNotStanderCustomer =
        originData?.business_registration === 2 ||
        originData?.business_registration === 0;
    const haveCID = !!originData?.customer_cid;
    const isLock = originData?.lock_expire_time
        ? originData?.lock_expire_time > moment().valueOf()
        : false;
    const isChildrenCustomer = !originData?.relate_type
        ? false
        : originData?.relate_type === 1
        ? true
        : false;

    const isNewEconomyCompany_Toubu = !originData?.new_economy_company
        ? false
        : originData?.new_economy_company === 1
        ? true
        : false;

    const isNewEconomyCompany_Yaobu = !originData?.new_economy_company
        ? false
        : originData?.new_economy_company === 2
        ? true
        : false;

    const isKP = !originData?.gradation
        ? false
        : originData?.gradation === 6
        ? true
        : false;

    const isGaoqian = !originData?.high_potential
        ? false
        : originData?.high_potential === 1
        ? true
        : false;
    return {
        isNotStanderCustomer,
        haveCID,
        isLock,
        isChildrenCustomer,
        isNewEconomyCompany_Toubu,
        isNewEconomyCompany_Yaobu,
        isKP,
        isGaoqian,
    };
};
