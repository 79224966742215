import {
    action,
    computed,
    makeAutoObservable,
    makeObservable,
    observable,
    runInAction,
} from 'mobx';
import {
    PaginationStore,
    SorterStore,
    ListMeta,
    MSelectStore,
    FastFilterStore,
} from '@/stores/utils';
import { useState } from 'react';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import { mutate } from '@/api/graphql';
import _ from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    commonQueryJob,
    getCommonJobQueryBody,
    TGetCommonReturnType,
} from '@/api/rest';
import { defaultAxios, defaultWritingDataParser } from '@/utils';
import EE from 'eventemitter3';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';
// import { getJobStore } from './jobs/registry';
import $ from 'jquery';
// import { LeadJobStore } from './jobs/lead/store';

export const transformBackEndValidateStateToValidateResult = (
    data: any[],
): ValidateResult<any> => {
    const ret: ValidateResult<any> = {};
    for (const d of data) {
        const { errorMsg, errorType, fieldName } = d;
        if (!errorMsg || !errorType || !fieldName) {
            continue;
        }
        ret[fieldName] = {
            msg: errorMsg,
            status: errorType === 'error' ? 'fail' : 'warning',
        };
    }
    return ret;
};

export const getDefaultCondListByFilterData = (store: {
    defaultFastFilters?: FastFilterStore;
    defaultFilters: any[];
    defaultFilter: FilterStore;
    defaultMeta: ListMeta;
}): NexusGenInputs['FiltersArgs'] => {
    const defaultFastFilters = store?.defaultFastFilters;
    if (!defaultFastFilters) {
        return {};
    }
    const { selectedFastFilters, selectedLv4MenuFilters } = defaultFastFilters;

    const defaultFilters = store.defaultFilters;

    if (!store.defaultFilter || !store.defaultMeta) {
        return {};
    }

    const ret = serializeFilterData3(
        store.defaultFilter.confirmedfilterData,
        store.defaultMeta,
    );
    const filters = ret.filters;
    if (_.isNil(filters)) {
        return {
            filters: [],
        };
    }

    for (const filterSet of selectedFastFilters) {
        const { value } = filterSet;
        if (_.isArray(value)) {
            for (const v of value) {
                const { condition } = v;
                if (!condition) {
                    continue;
                }

                filters.push(_.cloneDeep(condition));
            }
        }
    }

    for (const filterSet of selectedLv4MenuFilters) {
        const { value } = filterSet;
        if (_.isArray(value)) {
            for (const v of value) {
                const { condition } = v;
                if (!condition) {
                    continue;
                }

                filters.push(_.cloneDeep(condition));
            }
        }
    }

    ret.filters?.push(...defaultFilters);
    return ret;
};

export const opLowerCases: { [op: string]: string } = {
    update: '修改',
    cancel: '取消',
    close: '关闭',
    complete: '完成',
    transfer: '转办',
};

export const makeDefaultTableOpActionRenders = (): any[] => {
    const ops = ['update', 'cancel', 'close', 'complete', 'transfer'];
    return ops.map(op => {
        return {
            actionKey: op,
            actionCn: opLowerCases[op],
            isVisible(item: any, actionKey: string) {
                const supportedObjectOps = (item.objectOpSet =
                    item.objectOpSet || new Set(item.object_ops || []));
                if (supportedObjectOps.has(actionKey)) {
                    return true;
                }
                return false;
            },
        };
    });
};

export const getOpCn = (opKey: string): string => {
    return opLowerCases[opKey] || '未知';
};

export const useJobStoreJustForForm = (
    jobStore: any,
    tableCode: string,
): [boolean, JobBaseStore, () => void] => {
    const [prepared, setPrepared] = useState(false);
    const [store] = useState(() => {
        // if (tableCode === 'task_activation') {
        //     sto = new LeadJobStore();
        // } else {
        //     sto = new JobBaseStore(tableCode);
        // }
        const sto = jobStore;
        sto.defaultMeta.setTableId(tableCode);
        return sto;
    });
    const preparedStore = () => {
        Promise.all([store.defaultMeta.fetch()])
            .then(() => {
                setPrepared(true);
            })
            .catch(() => {});
    };

    return [prepared, store, preparedStore];
};

export class JobBaseStore {
    public data: any[] = [];

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public getNewItemText() {
        return '新建xx';
    }

    public get pageTitle(): string {
        if (this.listType === 'all') {
            return '全部xx';
        } else if (this.listType === 'mine') {
            return '我的xx';
        }
        return '未知listType';
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFastFilters = this?.defaultFastFilters;
        if (!defaultFastFilters) {
            return {};
        }
        const {
            selectedFastFilters,
            selectedLv4MenuFilters,
        } = defaultFastFilters;

        const defaultFilters = this.defaultFilters;

        if (!this.defaultFilter || !this.defaultMeta) {
            return {};
        }

        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );
        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        for (const filterSet of selectedFastFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        for (const filterSet of selectedLv4MenuFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        ret.filters?.push(...defaultFilters);
        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }
    public setAction(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra.id) {
                this.fetchInstantlyMutatingDataById(extra.id);
            } else {
                message.error('修改必须传id');
            }
        }

        if (nextAction === 'cancel') {
            if (extra.id) {
                this.fetchInstantlyMutatingDataById(extra.id);
            } else {
                message.error('取消必须传id');
            }
        }

        this.actionType = nextAction;
    }
    public resetAction() {
        this.netValidateResult = {};
        this.actionType = '';
        this.subActionType = '';
    }
    public subActionType: string = '';
    public setSubActionType(nextAction: string) {
        this.subActionType = nextAction;
    }
    public async dispatchAction() {
        if (this.actionType === 'create') {
            return this.create();
        } else if (this.actionType === 'update') {
            if (this.subActionType === 'complete') {
                return this.complete();
            }
            return this.update();
        }
    }

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;
    get mutatingDataParsed() {
        return defaultWritingDataParser(this.mutatingData);
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        const initialFormData = this.defaultMeta.defaultWidgetValues;
        this.mutatingData = _.cloneDeep(initialFormData);
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;

    get fieldAuthCode() {
        return this.listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    }

    get dataAuthCode() {
        return this.listType === 'all' ? 'SEARCH-PUBLIC' : 'SEARCH-PRIVATE';
    }

    public async fetchInstantlyMutatingDataById(id: any) {
        const { fieldAuthCode, dataAuthCode } = this;

        this.mutatingDataLoading = true;
        const [d, e] = await commonQueryJob(
            this.defaultMeta.tableId,
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: id,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            undefined,
            dataAuthCode,
        );
        this.mutatingDataLoading = false;

        if (d === null || e !== null) {
            return false;
        }
        const { datas } = d;
        if (datas && datas[0]) {
            this.mutatingData = datas[0];
            return true;
        } else {
            message.error('没有该数据的权限');
            return false;
        }
    }
    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode, dataAuthCode } = this;

        const [d, e] = await commonQueryJob(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            this.defaultMeta.tableColsParamsFromUserSetting.map(
                item => item.key,
            ),
            dataAuthCode,
        );

        if (_.isNil(d) || !_.isNil(e)) {
            return [d, e];
        }
        const leadsData = d;
        const { amount, datas } = leadsData;

        runInAction(() => {
            this.data = datas;
            if (this.defaultPagination) {
                this.defaultPagination.amount = amount;
            }
        });
        return [d, e];
    }

    public async create() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        delete data.id;
        delete data.key;

        const [ret, err] = await defaultAxios.post('/crm/task/create', data);
        if (!ret || err) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (
            _.isArray(innerData) &&
            innerData.length > 0 &&
            typeof innerData[0] === 'object'
        ) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('新建成功');
        this.defaultEE.emit('create-success');
        return true;
    }
    public async update() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        delete data.key;

        const [ret, err] = await defaultAxios.put('/crm/task/update', data);
        if (!ret || err) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (
            _.isArray(innerData) &&
            innerData.length > 0 &&
            typeof innerData[0] === 'object'
        ) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('修改成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    public async saveDDetail(d: any) {
        const data = _.cloneDeep(defaultWritingDataParser(d));
        delete data.key;

        const [ret, err] = await defaultAxios.put('/crm/task/update', data);
        if (!ret || err) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (
            _.isArray(innerData) &&
            innerData.length > 0 &&
            typeof innerData[0] === 'object'
        ) {
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.defaultEE.emit('update-success');
        return true;
    }

    public async complete() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        delete data.key;

        const [ret, err] = await defaultAxios.put('/crm/task/update', data);
        if (!ret || err) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (
            _.isArray(innerData) &&
            innerData.length > 0 &&
            typeof innerData[0] === 'object'
        ) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }

        const [ret2, err2] = await defaultAxios.put('/crm/task/finish', {
            id: data.id,
        });
        if (!ret2 || err2) {
            return false;
        }

        this.setNetValidateResult({});
        this.resetAction();
        message.info('完成成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    public async delete() {
        this.resetAction();
        return true;
    }

    public async cancel(remark: string) {
        const data = _.cloneDeep(this.mutatingDataParsed);
        // data.canceler_reason = remark
        // delete data.key;

        // const [ret, err] = await defaultAxios.put('/crm/task/update', data);
        // if (!ret || err) {
        //     return false;
        // }

        // const { msg, code, data: innerData } = ret;

        // if (
        //     _.isArray(innerData) &&
        //     innerData.length > 0 &&
        //     typeof innerData[0] === 'object'
        // ) {
        //     // 如果是数组就是一堆字段报错
        //     const formTopEle = $('.component-form-lv2')[0];
        //     if (formTopEle) {
        //         (formTopEle as any).___withAutoScroll = true;
        //     }
        //     this.setNetValidateResult(
        //         transformBackEndValidateStateToValidateResult(innerData),
        //     );
        //     message.error('存在校验不通过的字段');
        //     return false;
        // }
        // if (+code !== 0) {
        //     return false;
        // }

        const [ret2, err2] = await defaultAxios.put('/crm/task/cancel', {
            id: data.id,
            cancelReason: remark,
        });
        if (!ret2 || err2) {
            return false;
        }

        this.setNetValidateResult({});
        this.resetAction();
        message.info('取消成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    get downloadUrl() {
        const { tableId: objectName } = this.defaultMeta;
        const { amount } = this.defaultPagination;
        if (!amount) {
            return '';
        }
        const pageSize = Math.max(Math.min(Math.ceil(amount / 10), 300), 100);
        const fetchBody = getCommonJobQueryBody(
            { ...this.defaultPagination.paginationArgs, pageSize },
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            undefined,
            [
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
            ],
            undefined,
            false,
        );
        fetchBody.objectName = objectName;
        return `${
            window.location.origin
        }/bff/api/rest/export?q=${encodeURIComponent(
            JSON.stringify(fetchBody),
        )}`;
    }

    public defaultPagination: PaginationStore = new PaginationStore();
    public defaultSorter: SorterStore = new SorterStore();
    public defaultMeta: ListMeta = new ListMeta();
    public defaultFilter: FilterStore = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE: EE = new EE();
    public defaultPerm: Perm = new Perm('');
    public defaultMSelect: MSelectStore = new MSelectStore();
    public defaultFastFilters: FastFilterStore = new FastFilterStore();
    public tableCode: string = '';

    initByTableCode(tableCode: string) {
        this.tableCode = tableCode;
        const {
            defaultPagination,
            defaultSorter,
            defaultMeta,
            defaultFilter,
            defaultEE,
            defaultPerm,
            defaultMSelect,
            defaultFastFilters,
        } = makeJobDepedStores(tableCode);
        this.defaultPagination = defaultPagination;
        this.defaultSorter = defaultSorter;
        this.defaultMeta = defaultMeta;
        this.defaultFilter = defaultFilter;
        this.defaultEE = defaultEE;
        this.defaultPerm = defaultPerm;
        this.defaultMSelect = defaultMSelect;
        this.defaultFastFilters = defaultFastFilters;
    }

    constructor(tableCode: string) {
        this.initByTableCode(tableCode);

        makeObservable(this, {
            data: observable,
            listType: observable,
            getNewItemText: observable,
            defaultFilters: observable,
            netValidateResult: observable,
            actionType: observable,
            newDataHash: observable,
            mutatingData: observable,
            mutatingDataLoading: observable,
            isFromExsitCustomerTab: observable,

            setListType: action,
            setDefaultFilters: action,
            setNetValidateResult: action,
            setAction: action,
            resetAction: action,
            dispatchAction: action,
            setMutatingData: action,
            startNewData: action,
            startEditing: action,
            clearParamValidateState: action,
            fetchInstantlyMutatingDataById: action,
            fetch: action,
            create: action,
            update: action,
            delete: action,

            pageTitle: computed,
            condListbyFilterData: computed,
            bigFormVisible: computed,
            mutatingDataParsed: computed,
            fieldAuthCode: computed,
            dataAuthCode: computed,
        });
    }
}

export interface IJobStore extends JobBaseStore {}

export const makeJobDepedStores = (tableCode: string) => {
    const defaultPagination = new PaginationStore();
    const defaultSorter = new SorterStore<any>();
    const defaultMeta = new ListMeta(
        '/bff/api/rest/meta/job/list?tableCode=' + tableCode,
        '/bff/api/rest/meta/job/list/filters/save?tableCode=' + tableCode,
        '/bff/api/rest/meta/job/list/table-cols/save?tableCode=' + tableCode,
    );
    const defaultFilter = new FilterStore(defaultSorter, defaultMeta);
    const defaultEE = new EE();
    const defaultPerm: Perm = new Perm(
        '/bff/api/rest/perm/job?mod=sales-follow-up:task:' + tableCode,
    );
    const defaultMSelect: MSelectStore = new MSelectStore();
    const defaultFastFilters = new FastFilterStore();

    return {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        defaultFilter,
        defaultEE,
        defaultPerm,
        defaultMSelect,
        defaultFastFilters,
    };
};
