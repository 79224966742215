import React, { useState, useMemo, useCallback } from 'react';
import { Calendar, Row, Select, Popover, Spin } from 'antd';
import classname from 'classname';
import _ from 'lodash';
import moment from 'moment';
import { MoneyIcon } from '../../Icon';
import './index.less';
import { useRequest, useToggle } from 'ahooks';

interface IncomeCalendarProps {
    orgId: string | number;
    getDataMethod: (
        orgId: number | string,
        startTimeStr: string,
        endTimeStr: string,
    ) => Promise<any>;
}

const isTodayFn = (value: moment.Moment) => {
    return value.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
};

const IncomeCalendar: React.FC<IncomeCalendarProps> = props => {
    const { orgId, getDataMethod } = props;

    const [
        incomeType,
        { setLeft: setIncomeTypeLeft, setRight: setIncomeTypeRight },
    ] = useToggle<'预计', '已'>('预计', '已');
    const [rate, { setLeft: setRateLeft, setRight: setRateRight }] = useToggle<
        40,
        60
    >(40, 60);
    const [chooseMonth, setChooseMonth] = useState<number>(moment().month());
    const [date, setDate] = useState<moment.Moment>(moment());
    const startTimeStr = useMemo(
        () =>
            moment()
                .month(chooseMonth)
                .startOf('month')
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
        [chooseMonth],
    );
    const endTimeStr = useMemo(
        () =>
            moment()
                .month(chooseMonth)
                .endOf('month')
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
        [chooseMonth],
    );
    //
    const { data: incomeData, loading: incomeDataLoading } = useRequest(
        () => getDataMethod(orgId, startTimeStr, endTimeStr),
        {
            refreshDeps: [orgId, startTimeStr, endTimeStr],
        },
    );

    const dateCellRender = useCallback(
        (value: moment.Moment) => {
            const isToday = isTodayFn(value);
            const date = value.date();
            const month = value.month();
            const findNum = () => {
                if (month === chooseMonth) {
                    if (incomeType === '预计') {
                        const payBack =
                            rate === 40
                                ? incomeData?.payBackCalendarList[date - 1]
                                      ?.predictionPayBack_40
                                : incomeData?.payBackCalendarList[date - 1]
                                      ?.predictionPayBack_60;
                        const componyList =
                            rate === 40
                                ? incomeData?.payBackCalendarList[date - 1]
                                      ?.predictionPayBack40CompanyInfo
                                : incomeData?.payBackCalendarList[date - 1]
                                      ?.predictionPayBack60CompanyInfo;
                        return [payBack, componyList];
                    } else {
                        const payBack =
                            incomeData?.payBackCalendarList[date - 1]
                                ?.saleIncome;
                        const componyList =
                            incomeData?.payBackCalendarList[date - 1]
                                ?.saleIncomeCompanyInfo;
                        return [payBack, componyList];
                    }
                }
                return [0, []];
            };
            const [payBack, companyList] = findNum();

            const content =
                _.isNumber(payBack) && payBack !== 0 ? (
                    <div>
                        <div style={{ fontSize: '12px' }}>
                            {moment().format('YYYY-MM-DD')}
                        </div>
                        <div
                            className="cell-content-flex"
                            style={{
                                fontWeight: 500,
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '4px 0',
                            }}
                        >
                            <span>{`${
                                incomeType === '预计' ? '预计' : '已'
                            }回款 ${companyList?.length || 0} 笔`}</span>
                            <span style={{ marginLeft: '32px' }}>{`${(
                                payBack / 10000
                            ).toFixed(2)}w`}</span>
                        </div>
                        {companyList?.map((item: any) => {
                            return (
                                <div
                                    className="cell-content-flex"
                                    style={{
                                        fontSize: '12px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '4px 0',
                                    }}
                                >
                                    <span>{`${item.companyName}`}</span>
                                    <span style={{ marginLeft: '32px' }}>{`${(
                                        item.incomeAmount / 10000
                                    ).toFixed(2)}w`}</span>
                                </div>
                            );
                        })}
                    </div>
                ) : null;
            return (
                <Popover content={content}>
                    <div className="my-date-cell">
                        <div
                            className={classname('my-date-cell-value', {
                                notCurMonth: month !== chooseMonth,
                                today: isToday,
                            })}
                        >
                            {isToday ? '今天' : value.date()}
                        </div>
                        {payBack === 0 ? null : (
                            <span className="my-date-cell-icon">
                                <MoneyIcon />
                            </span>
                        )}
                    </div>
                </Popover>
            );
        },
        [chooseMonth, incomeData, incomeType, rate],
    );

    return (
        <div className="income-calendar-container">
            <Spin spinning={incomeDataLoading}>
                <Calendar
                    value={date}
                    fullscreen={false}
                    headerRender={({ value, type, onChange, onTypeChange }) => {
                        const start = 0;
                        const end = 12;
                        const monthOptions = [];

                        const current = value.clone();
                        const localeData = value.localeData();

                        const months = [];
                        for (let i = 0; i < 12; i++) {
                            current.month(i);
                            months.push(localeData.monthsShort(current));
                        }

                        for (let index = start; index < end; index++) {
                            monthOptions.push(
                                <Select.Option
                                    className="month-item"
                                    key={`${index}`}
                                >
                                    {months[index]}
                                </Select.Option>,
                            );
                        }
                        const month = value.month();

                        const year = value.year();

                        const options = [];
                        for (let i = year - 10; i <= year; i += 1) {
                            options.push(
                                <Select.Option
                                    key={i}
                                    value={i}
                                    className="year-item"
                                >
                                    {i}
                                </Select.Option>,
                            );
                        }
                        return (
                            <div style={{ padding: 10 }}>
                                <Row
                                    style={{
                                        borderBottom: '1px solid #EBECF3',
                                        marginBottom: '10px',
                                    }}
                                    type="flex"
                                    justify="space-between"
                                >
                                    <div style={{ marginBottom: '10px' }}>
                                        <span
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: 500,
                                                marginRight: '10px',
                                            }}
                                        >
                                            回款日历
                                        </span>
                                        <Select
                                            size="small"
                                            dropdownMatchSelectWidth={false}
                                            value={String(chooseMonth)}
                                            onChange={(
                                                selectedMonth: string,
                                            ) => {
                                                const newValue = value.clone();
                                                newValue.month(
                                                    parseInt(selectedMonth, 10),
                                                );
                                                setChooseMonth(
                                                    newValue.month(),
                                                );
                                                if (onChange) {
                                                    onChange(newValue);
                                                }
                                                setDate(newValue);
                                            }}
                                        >
                                            {monthOptions}
                                        </Select>
                                    </div>
                                    <div className="income-calendar-header-extra">
                                        <div
                                            className={classname(
                                                'income-calendar-header-extra-item',
                                                {
                                                    active:
                                                        incomeType === '预计',
                                                },
                                            )}
                                            onClick={() => setIncomeTypeLeft()}
                                        >
                                            预计回款
                                        </div>
                                        <div
                                            className={classname(
                                                'income-calendar-header-extra-item',
                                                { active: incomeType === '已' },
                                            )}
                                            onClick={() => setIncomeTypeRight()}
                                        >
                                            已回款
                                        </div>
                                    </div>
                                </Row>

                                <Row type="flex" justify="end">
                                    <div
                                        style={{
                                            color:
                                                rate === 40
                                                    ? '#15161F'
                                                    : '#6E727A',
                                            marginRight: '10px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setRateLeft()}
                                    >
                                        {'40回款'}
                                    </div>
                                    <span style={{ color: '#6E727A' }}>|</span>
                                    <div
                                        style={{
                                            color:
                                                rate === 60
                                                    ? '#15161F'
                                                    : '#6E727A',
                                            marginLeft: '10px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setRateRight()}
                                    >
                                        {'60回款'}
                                    </div>
                                </Row>
                            </div>
                        );
                    }}
                    // dateCellRender={dateCellRender}
                    dateFullCellRender={dateCellRender}
                />
                <div
                    style={{
                        borderTop: '1px solid #EBECF3',
                        padding: '12px 0',
                        textAlign: 'center',
                        cursor: 'pointer',
                        color: '#6E727A',
                    }}
                    onClick={() => {
                        const newValue = moment();
                        setChooseMonth(newValue.month());
                        setDate(newValue);
                    }}
                >
                    返回今天
                </div>
            </Spin>
        </div>
    );
};

export default IncomeCalendar;
