import React, { useEffect, useState } from 'react';
import classname from 'classname';
import _ from 'lodash';
import './index.less';

interface Option {
    title: string;
    value: string | number;
}

export interface SelectorProps {
    label: string;
    defaultValue?: string | number;
    value?: string | number;
    options: Option[];
    // // 单选 or 多选
    // multiple?: boolean;
    onChange?: (selected: string | number) => void;
}

const Selector: React.FC<SelectorProps> = props => {
    const {
        label,
        options,
        // multiple: fasle,
        onChange = () => {},
        value,
        defaultValue = '',
    } = props;

    const currentValue = _.isNil(value) ? defaultValue : value;

    const [selected, setSelected] = useState<string | number>(currentValue);

    useEffect(() => {
        if (_.isNil(value)) return;
        setSelected(value);
    }, [value]);

    const handleSeleted = (value: string | number) => {
        setSelected(value);
        onChange(value);
    };
    return (
        <div className="composite-selector">
            <div className="composite-selector-label">{label}：</div>
            <div className="composite-selector-options">
                {options.map(option => (
                    <div
                        className={classname(
                            'composite-selector-options-item',
                            { active: option.value === selected },
                        )}
                        key={option.value}
                        onClick={() => handleSeleted(option.value)}
                    >
                        {option.title}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Selector;
