import React, { useContext, useState } from 'react';
import { applyCustomConfToCellsSelector, isMultipleCell } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { OptListStore } from '@/pages/sales-follow-up/opt/list/store';
import { runInAction } from 'mobx';
import { DTable } from '@/components/common/table';
import { tryRefresh } from '@/utils';
import { message } from 'antd';
import { IAddFollowLogPropsFromEventOrProps } from '@/components/common/add-follow-log-new';
import { OptContractCreate } from '@/pages/sales-follow-up/opt/list/drawer/opt-contract-create';
import { newContractBtnRender } from '@/pages/sales-follow-up/opt/list/components/table';

const FormDemoTable: React.FC<{
    defaultStore: OptListStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const store = useContext(StoreContext);
    const customerListStore = store.getDefaultCustomerListStore();

    const authStore = store.getAuthStore();
    const contractCreateVisible = authStore.userInfo.contractCreateVisible;
    const isOm = window.location.pathname.indexOf('/om') > -1;
    const ccV = isOm ? contractCreateVisible?.om : contractCreateVisible?.buy;

    const [optContractCreateTrigger, setOptContractCreateTrigger] = useState(0);

    const dataTable = (
        <>
            <OptContractCreate
                trigger={optContractCreateTrigger}
                optInfo={defaultStore.mutatingData}
            />
            <DTable
                onColumnsUpdated={() => tryRefresh(['opt'])}
                defaultStore={defaultStore}
                sceneCode={'crm_opportunity'}
                cellsSelectorTransformer={baiscCellsSelector => {
                    // 详情可点击
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'opportunity_name',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'Link';
                            nextCellSelector.inputs.push({
                                queryFetcher: (item: any) => ({
                                    id: item.id,
                                    target: 'customer-drawer',
                                }),
                                url: '/buy/opportunity/all',
                            });
                            return nextCellSelector;
                        },
                    );
                    // 自定义cell
                    return baiscCellsSelector;
                }}
                propsTransformer={props => {
                    // 自定义table props
                    props.loading = loading;
                    props.actionsWidth = 50;
                    props.actions = [];
                    props.actions.push({
                        actionKey: 'follow-up',
                        actionCn: '跟进',
                    });

                    if (ccV) {
                        props.actions.push({
                            actionKey: 'new-contract',
                            actionCn: '新建合同',
                            render: newContractBtnRender,
                            force: true,
                        });
                        props.actionsWidth = props.actionsWidth + 100;
                    }

                    props.actionsWidth = props.actionsWidth + 50;
                    props.actions.push({
                        actionKey: 'delete',
                        actionCn: '删除',
                    });

                    props.onAction = (key, data) => {
                        if (key === 'follow-up') {
                            // TODO:这里需要确保cid的获取
                            const cid = data?.customer_id?.originalValue;
                            if (!cid) {
                                return message.error('无cid');
                            }
                            const followConfig: IAddFollowLogPropsFromEventOrProps = {
                                id: data.id,
                                cid,
                                entityType: 'opportunity',
                            };
                            customerListStore.defaultEE.emit(
                                'save-action-log-show',
                                followConfig,
                            );
                        } else if (key === 'new-contract') {
                            // runInAction(() => {
                            //     defaultStore.setAction('update', {
                            //         id: data.id,
                            //     });
                            // });

                            runInAction(() => {
                                setOptContractCreateTrigger(
                                    optContractCreateTrigger + 1,
                                );
                                // defaultStore.fetchInstantlyMutatingDataById(data.id)
                                defaultStore.setMutatingData({ id: data.id });
                            });
                        } else if (key === 'delete') {
                            runInAction(() => {
                                defaultStore.setAction('delete');
                                defaultStore.setMutatingData(data);
                            });
                        } else if (key === 'transform') {
                            // 其他动作
                        }
                    };
                    props.adjustHeight = false;
                    props.closeRowSelection = true;
                    return props;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalFormTable = observer(FormDemoTable);
export default FinalFormTable;
