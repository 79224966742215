import React, { useContext } from 'react';
import { applyCustomConfToCellsSelector, isMultipleCell } from '@/components';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { observer } from 'mobx-react-lite';
import { ContractProductListStore } from '@/pages/om/contract/contract-product/store';
import { runInAction } from 'mobx';
import { DTable, ICell } from '@/components/common/table';
import { tryRefresh } from '@/utils';

const FormDemoTable: React.FC<{
    defaultStore: ContractProductListStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const dataTable = (
        <>
            <DTable
                onColumnsUpdated={() => tryRefresh(['contract_product'])}
                defaultStore={defaultStore}
                sceneCode={'crm_contract'}
                cellsSelectorTransformer={baiscCellsSelector => {
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'equity_detail',
                        () => {
                            const nextCellSelector: ICell<any> = {
                                colKey: 'equity_detail',
                                inputs: ['equity_detail'],
                                cell: 'EquityDetail',
                            };
                            return nextCellSelector;
                        },
                    );

                    return baiscCellsSelector;
                }}
                propsTransformer={props => {
                    // 自定义table props
                    props.loading = loading;
                    props.actions = [
                        {
                            actionKey: 'open_apply',
                            actionCn: '申请开通',
                            render: (data, original) => {
                                if (
                                    +data.product_type === 1 &&
                                    +data.product_version === 6 &&
                                    (+data.execute_status === 4 ||
                                        +data.execute_status === 1)
                                ) {
                                    return original;
                                }
                                if (+data.execute_status === 1) {
                                    return (
                                        <span style={{ color: 'gray' }}>
                                            已提交
                                        </span>
                                    );
                                }
                                if (+data.execute_status === 2) {
                                    return (
                                        <span style={{ color: 'gray' }}>
                                            执行完成
                                        </span>
                                    );
                                }
                                return original;
                            },
                        },
                    ];
                    props.onAction = (key, data) => {
                        if (key === 'see-equity-detail') {
                            // 其他动作
                            runInAction(() => {
                                defaultStore.setAction('see-equity-detail');
                                defaultStore.setMutatingData(data);
                            });
                        }
                        if (key === 'open_apply') {
                            // runInAction(() => {
                            //     defaultStore.setAction('execute');
                            //     defaultStore.setMutatingData(data);
                            // });
                            if (data?.deliver_method === 2) {
                                runInAction(() => {
                                    defaultStore.setAction(
                                        'start-process-guzhu',
                                        {
                                            id: data.id,
                                        },
                                    );
                                });
                            } else {
                                runInAction(() => {
                                    defaultStore.setAction('start-process', {
                                        id: data.id,
                                    });
                                });
                            }
                        }
                    };
                    props.adjustHeight = false;
                    props.closeRowSelection = true;
                    return props;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalFormTable = observer(FormDemoTable);
export default FinalFormTable;
