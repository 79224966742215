import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { LayoutStandardCrud, AntButton, TableLv2 } from '@/components';
import { DatePicker, Input, Popover, Icon, Button } from 'antd';
import './index.less';
import moment, { Moment } from 'moment';
import { StoreContext } from '@/stores';
import { IChannelGatherAnaData } from './store';
import { useNet } from '@/utils';
import UserSelector from '../../../../components/common/user-selector/index';
const RangePicker = DatePicker.RangePicker;
const ChannelGatherAna: React.FC<RouteComponentProps> = props => {
    const {} = props;
    const store = useContext(StoreContext);
    const channelGatherStore = store.getDefaultChannelGatherStore();
    const anaStore = store.getDefaultAnalysisStore();
    const {
        getChannelGatherData,
        exportChannelGatherData,
        channelGatherAnaData,
    } = channelGatherStore;
    const afterDay = moment().subtract(1, 'month');
    const [anaSearchValue, setAnaSearchValue] = useState<{
        beginDate: Moment | string;
        endDate: Moment | string;
    }>({
        beginDate: afterDay.startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        endDate: afterDay.endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    });
    const [error, loading, reload] = useNet(() =>
        getChannelGatherData(anaSearchValue),
    );
    // useEffect(() => {
    //     reload();
    // }, [defaultChannelGatherAnaPagination.pagenum]);
    // 时间选择变更
    const onActionSearchTimeChange = (
        dates: any,
        dateStrings: [string, string],
    ) => {
        setAnaSearchValue({
            ...anaSearchValue,
            beginDate: dateStrings[0],
            endDate: dateStrings[1],
        });
        // reload();
    };
    const renderLoginPageFilter = () => {
        return (
            <div className="ana-filter-content">
                <div className="ana-filter-item">
                    <label htmlFor="time">线索创建时间：</label>
                    <RangePicker
                        name="time"
                        value={[
                            moment(anaSearchValue.beginDate),
                            moment(anaSearchValue.endDate),
                        ]}
                        showTime={true}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={onActionSearchTimeChange}
                        style={{ width: '400px' }}
                        allowClear={false}
                        onOk={reload}
                    />
                </div>
                <Button
                    type="primary"
                    onClick={() => {
                        // defaultChannelGatherAnaPagination.pagenum = 1;s
                        reload();
                    }}
                >
                    查询
                </Button>
                {anaStore.exportVis && (
                    <Button
                        type="primary"
                        onClick={() => {
                            exportChannelGatherData(anaSearchValue);
                            // defaultLoginLogPagination.pagenum = 1;
                            // reload();
                        }}
                    >
                        导出
                    </Button>
                )}
            </div>
        );
    };
    // leadChannel?: string; // 线索渠道
    // originLeadCount?: number; // 原始线索数量
    // noCooperationCount?: number; // 未合作客户
    // inCooperationCount?: number; // 合作中客户
    // followedLeadCount?: number; // 已跟进线索量
    // leadFollowUpRate?: number; // 线索跟进率
    // effectiveLeadCount?: number; // 有效线索量
    // effectiveLeadRate?: number; // 有效线索占比
    // transformOptLeadCount?: number; // 转商机线索量
    // transformOptLeadRate?: number; // 转商机的线索占比
    // winLeadCount?: number; // 赢单线索量
    // winLeadRate?: number; // 线索成交占比
    return (
        <div className="analysis-content">
            <LayoutStandardCrud
                title={'线索转化-渠道汇总表'}
                message={null}
                popups={null}
                multipleOps={null}
                globalOps={null}
                filters={renderLoginPageFilter()}
                dataTable={
                    <TableLv2<IChannelGatherAnaData>
                        loading={loading}
                        dataSource={channelGatherAnaData}
                        closeRowSelection={true}
                        pagination={false}
                        // pagination={{
                        //     ...defaultChannelGatherAnaPagination.paginationForUI,
                        //     total: defaultChannelGatherAnaPagination.amount,
                        // }}
                        // onPaginationChange={
                        //     defaultChannelGatherAnaPagination.setPaginationByUI
                        // }
                        scroll={{ x: '1660' }}
                        columns={[
                            {
                                title: '线索渠道',
                                key: 'leadChannel',
                                dataIndex: 'leadChannel',
                                width: '160px',
                            },
                            {
                                title: '原始线索数量',
                                key: 'originLeadCount',
                                width: '120px',
                                dataIndex: 'originLeadCount',
                            },
                            {
                                title: '原始线索-未合作',
                                key: 'noCooperationCount',
                                dataIndex: 'noCooperationCount',
                                width: '150px',
                            },
                            {
                                title: '原始线索-合作过',
                                key: 'cooperatedCount',
                                dataIndex: 'cooperatedCount',
                                width: '150px',
                            },
                            {
                                title: '已跟进线索',
                                key: 'followedLeadCount',
                                dataIndex: 'followedLeadCount',
                                width: '120px',
                            },
                            {
                                title: (
                                    <Popover
                                        content={
                                            <span>已跟进线索量/原始线索量</span>
                                        }
                                    >
                                        <span>
                                            线索跟进率
                                            <Icon
                                                style={{ marginLeft: '3px' }}
                                                type="question-circle"
                                            />
                                        </span>
                                    </Popover>
                                ),
                                key: 'leadFollowUpRate',
                                width: '120px',
                                render: item => {
                                    return `${
                                        item.originLeadCount
                                            ? (
                                                  Number(
                                                      (
                                                          item.followedLeadCount /
                                                          item.originLeadCount
                                                      ).toFixed(4),
                                                  ) * 100
                                              ).toFixed(2)
                                            : 0
                                    }%`;
                                },
                            },
                            {
                                title: '有效线索量',
                                key: 'effectiveLeadCount',
                                dataIndex: 'effectiveLeadCount',
                                width: '120px',
                            },
                            {
                                title: (
                                    <Popover
                                        content={
                                            <span>有效线索量/已跟进线索量</span>
                                        }
                                    >
                                        <span>
                                            有效线索占比
                                            <Icon
                                                style={{ marginLeft: '3px' }}
                                                type="question-circle"
                                            />
                                        </span>
                                    </Popover>
                                ),
                                key: 'effectiveLeadRate',
                                width: '120px',
                                render: item => {
                                    return `${
                                        item.followedLeadCount
                                            ? (
                                                  Number(
                                                      (
                                                          item.effectiveLeadCount /
                                                          item.followedLeadCount
                                                      ).toFixed(4),
                                                  ) * 100
                                              ).toFixed(2)
                                            : 0
                                    }%`;
                                },
                            },
                            {
                                title: '转商机线索量',
                                key: 'transformOptLeadCount',
                                width: '120px',
                                dataIndex: 'transformOptLeadCount',
                            },
                            {
                                title: (
                                    <Popover
                                        content={
                                            <span>
                                                已转商机的线索量/有效线索量
                                            </span>
                                        }
                                    >
                                        <span>
                                            已转商机的线索占比
                                            <Icon
                                                style={{ marginLeft: '3px' }}
                                                type="question-circle"
                                            />
                                        </span>
                                    </Popover>
                                ),
                                key: 'transformOptLeadRate',
                                width: '160px',
                                render: item => {
                                    return `${
                                        item.effectiveLeadCount
                                            ? (
                                                  Number(
                                                      (
                                                          item.transformOptLeadCount /
                                                          item.effectiveLeadCount
                                                      ).toFixed(4),
                                                  ) * 100
                                              ).toFixed(2)
                                            : 0
                                    }%`;
                                },
                            },
                            {
                                title: '赢单的线索量',
                                key: 'winLeadCount',
                                dataIndex: 'winLeadCount',
                                width: '120px',
                            },
                            {
                                title: (
                                    <Popover
                                        content={
                                            <span>
                                                赢单线索量/已转商机线索量
                                            </span>
                                        }
                                    >
                                        <span>
                                            线索成交占比
                                            <Icon
                                                style={{ marginLeft: '3px' }}
                                                type="question-circle"
                                            />
                                        </span>
                                    </Popover>
                                ),
                                key: 'winLeadRate',
                                width: '120px',
                                render: item => {
                                    return `${
                                        item.transformOptLeadCount
                                            ? (
                                                  Number(
                                                      (
                                                          item.winLeadCount /
                                                          item.transformOptLeadCount
                                                      ).toFixed(4),
                                                  ) * 100
                                              ).toFixed(2)
                                            : 0
                                    }%`;
                                },
                            },
                        ]}
                    />
                }
            />
        </div>
    );
};

export default observer(ChannelGatherAna);
