import { arrToJson } from '@/utils';
import React, { memo } from 'react';
import { AutoEllipsis } from '../../../auto-ellipsis';

export const JobStatus: React.FC<{ args: any[] }> = memo(({ args }) => {
    const value = args[0];
    if (!value) {
        return null;
    }
    const text = jobStatusMap[value]?.label || '';
    return (
        <AutoEllipsis
            style={{ color: colorMap[text] || 'inherit' }}
            text={text}
        />
    );
});

export const colorMap: { [label: string]: string } = {
    未开始: 'rgba(31, 35, 41, 0.7)',
    进行中: 'rgb(36, 91, 219)',
    已逾期: 'rgb(222, 120, 2)',
    已取消: 'black',
    已完成: 'rgb(46, 161, 33)',
    逾期完成: 'rgb(100, 37, 208)',
    异常关闭: 'rgb(216, 57, 49)',
};
const statusEnums = [
    {
        label: '未开始',
        value: 1,
    },
    {
        label: '进行中',
        value: 2,
    },
    {
        label: '已逾期',
        value: 3,
    },
    {
        label: '已取消',
        value: 4,
    },
    {
        label: '已完成',
        value: 5,
    },
    {
        label: '逾期完成',
        value: 6,
    },
    {
        label: '异常关闭',
        value: 7,
    },
];

export const jobStatusMap = arrToJson(statusEnums, 'value');

export const getJobStatusColorByValue = (value: number): string => {
    return colorMap[jobStatusMap[value + '']?.label || ''];
};
