import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import './topMenu.scss';
import _ from 'lodash';
import { IRouteLayout, routeLayout } from '@/configs/route';
import { IRouteItem } from '../../../configs/route';
import { Input, Icon, Tooltip, Badge, Avatar, Popover } from 'antd';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { IPermMap } from '@/stores/perm';
import NoticeDrawer from '../../common/notice-drawer/index';
import { CCToolBar } from '@/containers/hoc/call-center';
import { CCBtn } from '@/components/common';

const cctoolbarWhiteList = ['lichangfeng'];

declare const window: Window & { attachEvent: any };
const TopMenu: React.FC<RouteComponentProps & {
    routeLayout: IRouteLayout;
    menuPerm: IPermMap;
    selectedRoute: string;
}> = ({ children, routeLayout, menuPerm, location, selectedRoute }) => {
    const store = useContext(StoreContext);
    const AuthStore = store.getAuthStore();
    const NoticeStore = store.getDefaultNoticeStore();
    const homeNewStore = store.getDefaultHomeNewStore();
    const gsStore = store.getDefaultGSStore();
    const {
        searchGlobalSearchData,
        globalSearchText,
        setGlobalSearchText,
    } = gsStore;
    const { fetchNoticeNumberInfo, noticeInfo } = NoticeStore;
    const { userInfo, isLogin, isLoginLoading, loginOut, login } = AuthStore;
    const { callCenterConfig } = userInfo;
    const { pathname = '/' } = location || {};

    const isSetting = pathname.indexOf('setting') !== -1;
    const isSettingVisible = (menuPerm['setting'] || { visible: false })
        .visible;
    const isNoShowStatus =
        pathname.indexOf('500') !== -1 || pathname.indexOf('403') !== -1;

    const [selectedKeys, setSelectedKeys] = useState<string>('');
    const [searchWidth, setSearchWidth] = useState(247);

    const [noticeDrawerVis, setNoticeDrawerVis] = useState(false);

    const changeSearchWidth = (width: number) => {
        if (width <= 1200 && width >= 1050) {
            const nextWidth = 247 - (1200 - width);
            setSearchWidth(nextWidth > 100 ? nextWidth : 100);
        } else if (width > 1200) {
            setSearchWidth(247);
        } else {
            setSearchWidth(100);
        }
    };
    useEffect(() => {
        fetchNoticeNumberInfo();
    }, []);
    useEffect(() => {
        const firstBodyWidth = document.body.clientWidth;
        changeSearchWidth(firstBodyWidth);
        document.body.onresize = (e: any) => {
            changeSearchWidth(e.target.innerWidth);
            // if(e.target.innerWidth <= 1200 && e.target.innerWidth >= 1050) {
            //     const nextWidth = 247 - (1200 - e.target.innerWidth);
            //     setSearchWidth( nextWidth > 100 ? nextWidth : 100 );
            // } else if (e.target.innerWidth > 1200) {
            //     setSearchWidth(247);
            // } else {
            //     setSearchWidth(100);
            // }
        };
        return () => {
            document.body.onresize = null;
        };
    }, []);

    useEffect(() => {
        let nextSelectedKeys = '';
        for (const route of routeLayout) {
            // 第一层路由
            if (pathname.split('/')[1].indexOf(route.key) !== -1) {
                console.log(route.key);
                nextSelectedKeys = route.key;
                break;
            }
        }
        setSelectedKeys(nextSelectedKeys);
    }, [selectedRoute]);
    return (
        <>
            <NoticeDrawer
                drawerVis={noticeDrawerVis}
                setDrawerVis={setNoticeDrawerVis}
            />
            <div className="nav-content">
                <div className="left-nav-content">
                    <div
                        className="logo"
                        style={{
                            backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/menus/logo.png')`,
                        }}
                    ></div>
                    {!isSetting && (
                        <div className="inline-menu-bar">
                            {routeLayout.map(item => {
                                const { visible = true, key } = item;
                                const permItem = menuPerm[key] || {
                                    visible: false,
                                };

                                if (visible === false || !permItem.visible) {
                                    return null;
                                }
                                return (
                                    <Link
                                        key={key}
                                        to={
                                            item.defaultSelectedChildrenPath as string
                                        }
                                    >
                                        <div
                                            className={`menu-bar-item ${
                                                selectedKeys === item.key
                                                    ? 'active'
                                                    : ''
                                            }`}
                                        >
                                            <span>{item.cn}</span>
                                            <span className="active-line"></span>
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                    )}
                    {isSetting && (
                        <div className="crm-setting-tag">
                            <span>配置中心</span>
                        </div>
                    )}
                </div>

                <div className="right-nav-content">
                    {isSetting && (
                        <div className="right-handle-bar">
                            <Icon
                                theme="filled"
                                style={{
                                    color: 'rgb(175,177,187)',
                                    fontSize: '20px',
                                    position: 'relative',
                                    top: '2px',
                                }}
                                type="home"
                                onClick={() => {
                                    window.open('/home', '_blank');
                                }}
                            />
                        </div>
                    )}
                    {!isSetting && !isNoShowStatus && (
                        <>
                            <div className="search">
                                {/* 搜索框暂时只做样式 */}
                                <Input
                                    style={{
                                        width: `${searchWidth}px`,
                                        height: '36px',
                                        borderRadius: '8px',
                                    }}
                                    placeholder="搜索"
                                    onChange={e => {
                                        setGlobalSearchText(e.target.value);
                                    }}
                                    onPressEnter={() => {
                                        // navigate('/buy/global-search');
                                        window.open(
                                            `/buy/global-search?searchText=${encodeURIComponent(
                                                globalSearchText,
                                            )}`,
                                            '_blank',
                                        );
                                        // searchGlobalSearchData();
                                    }}
                                    allowClear
                                    prefix={
                                        <div
                                            onClick={() => {
                                                window.open(
                                                    `/buy/global-search?searchText=${encodeURIComponent(
                                                        globalSearchText,
                                                    )}`,
                                                    '_blank',
                                                );
                                                // navigate('/buy/global-search');
                                                // searchGlobalSearchData();
                                            }}
                                            style={{
                                                backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/menus/search.png')`,
                                            }}
                                            className="search-icon"
                                        ></div>
                                    }
                                />
                            </div>
                            <div className="right-handle-bar">
                                {isSettingVisible && (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/imgs/menus/setting.png`}
                                        style={{
                                            width: '18px',
                                            height: '20px',
                                        }}
                                        className="handle-icon"
                                        onClick={() => {
                                            window.open('/setting', '_blank');
                                        }}
                                    />
                                )}
                                {/* <img
                                    src={`${process.env.PUBLIC_URL}/imgs/menus/question.png`}
                                    style={{ width: '20px', height: '20px' }}
                                    className="handle-icon"
                                /> */}
                                <Badge count={noticeInfo.total}>
                                    <img
                                        onClick={() => {
                                            setNoticeDrawerVis(true);
                                        }}
                                        src={`${process.env.PUBLIC_URL}/imgs/menus/notice.png`}
                                        style={{
                                            width: '24.5px',
                                            height: '24.5px',
                                        }}
                                        className="handle-icon"
                                    />
                                </Badge>
                            </div>
                            {/* {userInfo.userId &&
                            cctoolbarWhiteList.indexOf(userInfo.userId) > -1 ? (
                                <CCToolBar />
                                ) : null} */}
                            {!!callCenterConfig && <CCToolBar />}
                        </>
                    )}
                    {isLoginLoading ? null : (
                        <Popover
                            placement="bottomRight"
                            title={undefined}
                            content={
                                <div
                                    onClick={isLogin ? loginOut : () => login()}
                                    style={{
                                        width: '100px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <p style={{ padding: '5px', margin: '0' }}>
                                        {isLogin ? '退出登录' : '前去登录'}
                                    </p>
                                </div>
                            }
                            trigger="hover"
                        >
                            <div className="user-content">
                                <>
                                    <Avatar
                                        size={28}
                                        icon={isLogin ? undefined : 'user'}
                                        src={
                                            isLogin
                                                ? userInfo.userAvatar
                                                : undefined
                                        }
                                        style={{
                                            marginRight: '8px',
                                            marginLeft: '60px',
                                        }}
                                    />
                                    <span>
                                        {isLogin ? userInfo.userName : '未登录'}
                                    </span>
                                </>
                            </div>
                        </Popover>
                    )}
                </div>
            </div>
        </>
    );
};

export default observer(TopMenu);
