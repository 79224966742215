import React from 'react';
import { List, Divider, Row, Col, Alert, Modal, Popover } from 'antd';
import { MSelectStore } from '@/stores/utils';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

export interface IMOpResultItem {
    id: string;
    description: string;
    msg: string;
}

export interface IMOpResult {
    msg: string;
    totalNum: number;
    successNum: number;
    warningNum: number;
    errorNum: number;
    whetherAll: boolean;
    successList: null | IMOpResultItem[];
    errorList: null | IMOpResultItem[];
    warningList: null | IMOpResultItem[];
}

export const MOpResultDisplay: React.FC<{ result: IMOpResult }> = ({
    result,
}) => {
    let {
        msg,
        totalNum,
        successNum,
        warningNum,
        errorNum,
        whetherAll,
        successList,
        errorList,
        warningList,
    } = result;

    totalNum = totalNum || 0;
    successNum = successNum || 0;
    warningNum = warningNum || 0;
    errorNum = errorNum || 0;
    const realMsg = msg;

    const getNode = (item: IMOpResultItem) => {
        const { id, description, msg } = item;
        if (!description || !msg) {
            return null;
        }
        return (
            <span>
                <span style={{ fontWeight: 'bolder' }}>
                    [{item.description || '未知'}]
                </span>
                <span>{item.msg || '未知错误'}</span>
            </span>
        );
    };

    const successListNodes = (successList || []).map(getNode).filter(Boolean);
    const errorListNodes = (errorList || []).map(getNode).filter(Boolean);
    const warningListNodes = (warningList || []).map(getNode).filter(Boolean);

    const renderItem = (item: React.ReactNode) => <List.Item>{item}</List.Item>;
    return (
        <div>
            {_.isString(realMsg) && !!realMsg && (
                <>
                    <Alert
                        type="error"
                        style={{
                            border: 'none',
                            marginBottom: 28,
                            padding: 21,
                            borderRadius: 8,
                        }}
                        message={
                            <span
                                style={{
                                    color: 'red',
                                    fontWeight: 'bolder',
                                }}
                            >
                                {'错误信息：' + realMsg}
                            </span>
                        }
                    />
                    <Divider />
                </>
            )}
            <Row gutter={12}>
                <Col span={6}>
                    <Alert
                        message={
                            <span>
                                共
                                <span
                                    style={{
                                        fontSize: '1.2em',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    {totalNum}
                                </span>
                                条
                            </span>
                        }
                    />
                </Col>
                <Col span={6}>
                    <Alert
                        type="error"
                        message={
                            <span>
                                失败
                                <span
                                    style={{
                                        fontSize: '1.2em',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    {errorNum}
                                </span>
                                条
                            </span>
                        }
                    />
                </Col>
                <Col span={6}>
                    <Alert
                        message={
                            <span>
                                警告
                                <span
                                    style={{
                                        fontSize: '1.2em',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    {warningNum}
                                </span>
                                条
                            </span>
                        }
                    />
                </Col>
                <Col span={6}>
                    <Alert
                        message={
                            <span>
                                成功
                                <span
                                    style={{
                                        fontSize: '1.2em',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    {successNum}
                                </span>
                                条
                            </span>
                        }
                    />
                </Col>
            </Row>
            {!!errorListNodes.length && (
                <>
                    <List
                        style={{ marginBottom: 15, marginTop: 15 }}
                        bordered
                        header="失败详情"
                        size="small"
                        dataSource={errorListNodes}
                        renderItem={renderItem}
                    />
                    {/* <Divider /> */}
                </>
            )}
            {!!warningListNodes.length && (
                <>
                    <List
                        style={{ marginBottom: 15 }}
                        bordered
                        header="警告详情"
                        size="small"
                        dataSource={warningListNodes}
                        renderItem={renderItem}
                    />
                    {/* <Divider /> */}
                </>
            )}
            {!!successListNodes.length && (
                <List
                    bordered
                    header="成功详情"
                    size="small"
                    dataSource={successListNodes}
                    renderItem={renderItem}
                />
            )}
        </div>
    );
};

export interface IMOpRDProps {
    defaultStore: {
        defaultMSelect: MSelectStore;
    };
}

export const MOpResultDisplayContainer: React.FC<IMOpRDProps> = observer(
    ({ defaultStore }) => {
        const { defaultMSelect } = defaultStore;

        const { mopResult, mopResultTitle, mopResultVisiable } = defaultMSelect;

        return (
            <Modal
                visible={mopResultVisiable}
                title={mopResultTitle}
                onCancel={() => {
                    defaultMSelect.resetMopResult();
                }}
                width={710}
                zIndex={10002}
                onOk={async () => {
                    defaultMSelect.resetMopResult();
                }}
            >
                <div>
                    {mopResult && <MOpResultDisplay result={mopResult} />}
                </div>
            </Modal>
        );
    },
);

export const MOpNameDisplayContainer: React.FC<IMOpRDProps & {
    hint?: string;
    nameParam?: string;
}> = observer(({ defaultStore, hint: userHint, nameParam: userNameParam }) => {
    return null;
    // const hint = userHint || '当前选择';
    // const nameParam = userNameParam || 'name';

    // const { defaultMSelect } = defaultStore;
    // const { mSeletedData } = defaultMSelect;

    // if (!_.isArray(mSeletedData)) {
    //     return null;
    // }

    // return (
    //     <Popover
    //         trigger="hover"
    //         content={
    //             <List
    //                 renderItem={(item: string) => <List.Item>{item}</List.Item>}
    //                 size="small"
    //                 dataSource={_.map(
    //                     mSeletedData,
    //                     (item: any) => item[nameParam] || 'unknow',
    //                 )}
    //             ></List>
    //         }
    //     >
    //         &nbsp;&nbsp;
    //         <a
    //             style={{
    //                 fontSize: 14,
    //                 fontWeight: 400,
    //                 textDecoration: 'underline',
    //             }}
    //         >
    //             {hint}
    //         </a>
    //     </Popover>
    // );
});
