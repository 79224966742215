import React, { useState, useEffect } from 'react';
import { Modal, Select, message } from 'antd';
import { defaultAxios } from '@/utils';
import { IMemberListStagingChildData } from '../../store';

interface ILicenseModalProps {
    modalVis: boolean;
    setModalVis: (bool: boolean) => void;
    selectedChild?: IMemberListStagingChildData;
    onSuccess?: () => void;
}

const LicenseModal: React.FC<ILicenseModalProps> = ({
    modalVis,
    setModalVis,
    selectedChild,
    onSuccess,
}) => {
    const [licenseValue, setLicenseValue] = useState<{
        isSubAdmin: number;
        isAi: number;
        reportVersion: number;
    }>({
        isSubAdmin: 0,
        isAi: 0,
        reportVersion: 0,
    });

    useEffect(() => {
        if (selectedChild) {
            setLicenseValue({
                isSubAdmin: selectedChild.is_sub_admin || 0,
                isAi: selectedChild.is_ai || 0,
                reportVersion: selectedChild.report_version || 0,
            });
        }
    }, [selectedChild]);

    const handleSubmit = async () => {
        if (!selectedChild) {
            return;
        }

        const [data, err] = await defaultAxios.post(
            '/crm/member/licensePermission',
            {
                licenseId: selectedChild.id,
                ...licenseValue,
            },
        );

        if (err) {
            message.error(err.msg || '修改权限失败');
            return;
        }

        message.success('修改权限成功');
        setModalVis(false);
        setLicenseValue({
            isSubAdmin: 0,
            isAi: 0,
            reportVersion: 0,
        });
        onSuccess?.();
    };

    return (
        <Modal
            title={`编辑「${selectedChild?.sub_u_name || '未分配'}」的权限`}
            visible={modalVis}
            onOk={handleSubmit}
            onCancel={() => {
                setModalVis(false);
                setLicenseValue({
                    isSubAdmin: 0,
                    isAi: 0,
                    reportVersion: 0,
                });
            }}
        >
            <div className="member-modify-equality-content">
                <div className="member-modify-flex">
                    <label htmlFor="">子管理员：</label>
                    <Select
                        style={{ width: 120 }}
                        value={licenseValue.isSubAdmin}
                        onChange={(value: number) => {
                            setLicenseValue({
                                ...licenseValue,
                                isSubAdmin: value,
                            });
                        }}
                    >
                        <Select.Option value={0}>否</Select.Option>
                        <Select.Option value={1}>是</Select.Option>
                    </Select>
                </div>

                <div className="member-modify-flex">
                    <label htmlFor="">AI账号：</label>
                    <Select
                        style={{ width: 120 }}
                        value={licenseValue.isAi}
                        onChange={(value: number) => {
                            setLicenseValue({
                                ...licenseValue,
                                isAi: value,
                            });
                        }}
                    >
                        <Select.Option value={0}>否</Select.Option>
                        <Select.Option value={1}>是</Select.Option>
                    </Select>
                </div>

                <div className="member-modify-flex">
                    <label htmlFor="">人才情报洞察：</label>
                    <Select
                        style={{ width: 120 }}
                        value={licenseValue.reportVersion}
                        onChange={(value: number) => {
                            setLicenseValue({
                                ...licenseValue,
                                reportVersion: value,
                            });
                        }}
                    >
                        <Select.Option value={0}>无</Select.Option>
                        <Select.Option value={1}>专业版</Select.Option>
                        <Select.Option value={2}>高级版</Select.Option>
                    </Select>
                </div>
            </div>
        </Modal>
    );
};

export default LicenseModal;
