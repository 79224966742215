import React, { useState, useEffect, useMemo, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Badge, message, Spin } from 'antd';
import { useRequest } from 'ahooks';
import './index.less';
import _ from 'lodash';
import { defaultAxios, useNet } from '@/utils';
import moment from 'moment';
import { StoreContext } from '@/stores';

export interface ICommentDataProps {
    commentId: number;
    company: string;
    content: string;
    time: string;
    isClicked: boolean;
    index?: number;
}

const HomeCommentCard: React.FC = observer(() => {
    // const {data:commentData,loading:commentDataLoading}=useRequest
    const showCount = 3;
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const sysUser = authStore.userInfo?.sysUser || {};
    const [showAll, setShowAll] = useState<boolean>(false);

    const [commentClicked, setCommentClicked] = useState<{
        [key: number]: boolean;
    }>(JSON.parse(localStorage.getItem('commentClicked') || '{}'));
    const [activationRecordData, setActivationRecordData] = useState<any>({});
    //好评数
    const wellReceived: number = useMemo(() => {
        return (
            activationRecordData.datas?.filter(
                (item: { is_well_received: number }) =>
                    item.is_well_received === 1,
            ).length || 0
        );
    }, [activationRecordData]);
    //差评数
    const badlyReceived: number = useMemo(() => {
        return (
            activationRecordData.datas?.filter(
                (item: { is_well_received: number }) =>
                    item.is_well_received === 2,
            ).length || 0
        );
    }, [activationRecordData]);
    //评论记录
    const comments: any[] = useMemo(() => {
        return (
            activationRecordData.datas
                ?.filter(
                    (item: { activation_evaluate: string }) =>
                        item.activation_evaluate,
                )
                .filter(
                    (item: { evaluate_id: { originalValue: number } }) =>
                        commentClicked?.[item.evaluate_id.originalValue] !==
                        true,
                )
                .sort(
                    (
                        a: { evaluate_time: number },
                        b: { evaluate_time: number },
                    ) => b.evaluate_time - a.evaluate_time,
                ) || []
        );
    }, [activationRecordData, commentClicked]);

    // 活动记录信息
    const fetchActivationRecordList: () => Promise<any> = async () => {
        // let id = 0;
        // const thisId = id;
        // if (_.isNil(id)) {
        //     return [null, null];
        // }
        const [d, e] = await defaultAxios.post(
            `/bff/api/rest/cu_activation_record/list`,
            {
                filters: {
                    filters: [
                        {
                            fieldId: 'create_user',
                            operateType: 'in',
                            filterValue: sysUser.id,
                            parser: 'string',
                            widgetKey: 'sysUserSelector',
                            extra: {
                                ts: 0,
                                isEmpty: false,
                                isNotEmpty: false,
                                value: [],
                            },
                        },
                        {
                            fieldId: 'evaluate_time',
                            operateType: '>=',
                            filterValue: moment()
                                .startOf('day')
                                .valueOf(),
                            parser: 'string',
                            widgetKey: 'filterDateRange',
                            extra: {
                                ts: 0,
                                isEmpty: false,
                                isNotEmpty: false,
                                value: [],
                            },
                        },
                        {
                            fieldId: 'evaluate_time',
                            operateType: '<=',
                            filterValue: moment()
                                .endOf('day')
                                .valueOf(),
                            parser: 'string',
                            widgetKey: 'filterDateRange',
                            extra: {
                                ts: 0,
                                isEmpty: false,
                                isNotEmpty: false,
                                value: [],
                            },
                        },
                    ],
                },
                sorter: {},
                selectiveFields: [
                    'id',
                    'customer_name',
                    'is_well_received',
                    'activation_evaluate',
                    'evaluate_time',
                    'evaluate_user',
                ],
                fieldAuthCode: 'PRIVATE-VIEW',
                dataAuthCode: 'SEARCH-PRIVATE',
            },
        );
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d && d.data) {
            console.log('activationrecord', d);
            setActivationRecordData(d.data);
        } else {
            if (d.msg) {
                message.error(d.msg);
            }
            // setCusInfo({});
        }
        return [data, error];
    };
    const [error, loading, reload] = useNet(fetchActivationRecordList, {
        defaultLoading: false,
    });

    const clickComment = (
        id: number,
        customer_id: number,
        evaluate_id: number,
    ) => {
        return () => {
            const tempObj = { ...commentClicked, [evaluate_id]: true };
            setCommentClicked(tempObj);
            localStorage.setItem('commentClicked', JSON.stringify(tempObj));
            window.open(
                `/buy/custom/my-custom?id=${customer_id}&recordId=${id}`,
                '_blank',
            );
        };
    };

    console.log('comments', comments);

    return (
        <div className="home-new-right-info-content">
            <Spin spinning={loading}>
                <div className="crm-home-behind-info">
                    <p className="home-page-title">
                        <div className="home-page-title-text">
                            评价提醒
                            <span className="home-page-title-subtext">
                                收到{' '}
                                <a
                                    href={`/buy/activation-record/all?filterKey=${sysUser.id}-${sysUser.name}-well-received`}
                                    target="_blank"
                                >
                                    {wellReceived}
                                </a>{' '}
                                个赞，{' '}
                                <a
                                    href={`/buy/activation-record/all?filterKey=${sysUser.id}-${sysUser.name}-badly-received`}
                                    target="_blank"
                                >
                                    {badlyReceived}
                                </a>{' '}
                                个踩， {comments.length} 条新评论
                            </span>
                        </div>
                    </p>
                    <div className="home-comment-content">
                        {comments
                            .map(item => {
                                return (
                                    <div
                                        key={item.evaluate_id?.originalValue}
                                        className="home-comment-content-item"
                                    >
                                        <div className="item-header">
                                            <span className="item-header-strong">
                                                {item.evaluate_user}
                                            </span>{' '}
                                            评论了{' '}
                                            <span className="item-header-strong">
                                                {item.customer_name}
                                            </span>{' '}
                                            的活动记录
                                        </div>
                                        <div
                                            className="item-comment"
                                            onClick={clickComment(
                                                item.id,
                                                item.customer_id?.originalValue,
                                                item.evaluate_id?.originalValue,
                                            )}
                                        >
                                            {item.activation_evaluate}
                                        </div>
                                        <div className="item-time">
                                            {moment(item.evaluate_time).format(
                                                'YYYY-MM-DD HH:mm:ss',
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                            .slice(0, showAll ? comments.length : 3)}
                        {comments.length > showCount ? (
                            <div
                                className="show-tips-box"
                                onClick={() => {
                                    setShowAll(!showAll);
                                }}
                            >
                                {showAll
                                    ? `收起评论`
                                    : `展开更多${comments.length -
                                          showCount}条评论`}
                            </div>
                        ) : null}
                    </div>
                </div>
            </Spin>
        </div>
    );
});

export default HomeCommentCard;
