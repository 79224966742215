import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    FormLV2,
    embedDataToWidgets,
    getAutoOnChangeHandler,
    IFormLV2Props,
} from '@/components';
import { Alert, message, Modal, Button } from 'antd';
import { OptionsList } from '@/components/common/form/widgets';
import _ from 'lodash';
import {
    IForm2DemoData,
    IForm2DemoExtraData,
    widgets,
    actionWidgets,
    formItemOptions,
    validators,
} from './options';
import { useToggle } from '@/utils/hooks/helpers';

const Form2Demo: React.FC<RouteComponentProps> = ({ children }) => {
    const [genderLoading, setGenderLoading] = useState(true);
    const [data, setData] = useState<IForm2DemoData | null>(null);
    const [genderData, setGenderData] = useState<OptionsList<string>>([]);
    const [modalVisible, toggleModalVisible] = useToggle(false);
    const [drawerVisible, toggleDrawerVisible] = useToggle(false);

    useEffect(() => {
        // 异步数据
        setTimeout(() => {
            setData({
                name: '狗子',
                gender: ['1'],
                accountStatus: false,
            });
        }, 1000);

        // 异步选项
        setGenderLoading(true);
        setTimeout(() => {
            setGenderData([
                {
                    name: '男',
                    value: '1',
                },
                {
                    name: '女',
                    value: '2',
                },
            ]);
            setGenderLoading(false);
        }, 2000);
    }, []);
    if (data === null) {
        return <Alert message="无数据"></Alert>;
    }

    // 获取默认的数据回调
    const onDataChange = getAutoOnChangeHandler(data, setData);
    // 结合插件配置和真实数据
    const finalWidgets = widgets({ genderData });
    embedDataToWidgets<IForm2DemoData>(finalWidgets, data);

    const props: IFormLV2Props<IForm2DemoData, IForm2DemoExtraData> = {
        styleLv2: {
            // width: 500,
        },
        data,
        widgets: finalWidgets,
        actionWidgets,
        actionWidgetsGroupOption: {},
        onChange: onDataChange,
        statusExtraData: { genderLoading },
        formItemOptions,
        onInteract: (key, type, value) => {
            console.log('key, type, value: ', key, type, value);
        },
        onAction: (actionType, data, isPass, validateResult) => {
            console.log(
                'actionType, data, isPass, validateResult: ',
                actionType,
                data,
                isPass,
                validateResult,
            );
            if (actionType === 'submit') {
                if (isPass) {
                    message.success('数据正确，可以提交');
                    // toggleModalVisible()
                    // toggleDrawerVisible()
                } else {
                    message.error('数据不正确，不可提交');
                }
            }

            if (actionType === 'cancel') {
                Modal.confirm({
                    title: '您点击了取消，是否继续？',
                    onOk: () => {
                        message.success('confirmed');
                        // toggleModalVisible()
                        // toggleDrawerVisible()
                    },
                    onCancel: () => {
                        message.error('canceled');
                    },
                });
            }
        },
        validators,
        validateMode: 'instantly',
    };

    const propsWithModalContainer: IFormLV2Props<
        IForm2DemoData,
        IForm2DemoExtraData
    > = {
        container: 'modal',
        containerModalProps: {
            visible: modalVisible,
            title: '看看表单',
            onCancel: toggleModalVisible,
            width: 600,
        },
        ...props,
        layout: 'inline',
        collapseLayout: [
            {
                groupKey: '1',
                groupName: '第一组',
                groupSpan: 2,
            },
            {
                groupKey: '2',
                groupName: '第二组',
                groupSpan: 1,
            },
        ],
    };

    const propsWithDrawerContainer: IFormLV2Props<
        IForm2DemoData,
        IForm2DemoExtraData
    > = {
        container: 'drawer',
        containerDrawerProps: {
            visible: drawerVisible,
            title: '看看表单',
            width: 550,
            onClose: toggleDrawerVisible,
        },
        ...props,
        validateMode: 'lazily',
        labelWidth: 8,
        layout: [[24], [24], [24]],
        collapseLayout: [
            {
                groupKey: '1',
                groupName: '第一组',
                groupSpan: 1,
            },
            {
                groupKey: '2',
                groupName: '第二组',
                groupSpan: 2,
            },
        ],
        collapseProps: {
            defaultActiveKey: '1',
        },
    };

    return (
        <div>
            <Button onClick={() => toggleModalVisible()}>
                看看表单（modal）
            </Button>
            <Button onClick={() => toggleDrawerVisible()}>
                看看表单（drawer）
            </Button>
            <FormLV2<IForm2DemoData, IForm2DemoExtraData>
                {...propsWithModalContainer}
            ></FormLV2>
            <FormLV2<IForm2DemoData, IForm2DemoExtraData>
                {...propsWithDrawerContainer}
            ></FormLV2>
        </div>
    );
};

export default Form2Demo;
