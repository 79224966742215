import { AxiosRequestConfig, AxiosResponse } from 'axios';

const isWithAjaxBar = true;

const cancelWhiteList: { [url: string]: boolean } = {
    '/crm/customer/findCustomerNameAndCode': true,
};

const defaultAjaxOptions = {
    silent: false,
    withMessage: true,
    withRetry: {
        retryTimes: 0,
        retryDelay: 100,
    },
    withCancel: [
        (config: AxiosRequestConfig) => {
            const isGet = config.method?.toLocaleLowerCase() === 'get';
            if (isGet && config.url) {
                if (cancelWhiteList[config.url]) {
                    return true;
                }
                // 如果是get，url和params一样才cancel
                return config.url + '/' + JSON.stringify(config.params);
            }
            // 如果不是get，则不cancel
            return Date.now() + '/' + Math.random();
        },
    ],
};
const tokenKey = 'perf-token';

const defaultAxiosConfig = {
    baseUrl: true,
    timeout: 20000,
} as AxiosRequestConfig;

export { isWithAjaxBar, defaultAjaxOptions, defaultAxiosConfig, tokenKey };
