import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { dFilterHOC } from '@/containers/hoc';
import './index.scss';
import FormDrawerCreate from './components/form-drawer-create';
import { ProcessCatStore } from './store';
const ProcessFormProxy: React.FC<{
    preRequestReady: boolean;
    dfilters?: any;
    visible: boolean;
    onClose: () => void;
    processKey: string;
    lifeCycle?: {
        onOpen: (store: ProcessCatStore) => void;
        onClose: (store: ProcessCatStore) => void;
    };
}> = ({
    dfilters,
    visible,
    onClose,
    processKey,
    lifeCycle,
    preRequestReady: outerPreRequestReady,
}) => {
    const type = 'category';
    const store = useContext(StoreContext);
    const [defaultStore] = useState(() => new store.ProcessCatStore());
    const userStore = store.getAuthStore();

    const { defaultFastFilters } = defaultStore;

    const [fastFilterReady, setFastFilterReady] = useState(false);

    useEffect(() => {
        runInAction(() => {
            defaultFastFilters.setType(type);
            defaultStore.setListType(type);
        });
    }, [type]);

    useEffect(() => {
        if (visible) {
            defaultStore.setAction('create');
        } else {
            defaultStore.resetAction();
        }
    }, [visible]);

    useEffect(() => {
        if (!defaultStore.actionType) {
            onClose();
        }
    }, [defaultStore.actionType]);

    const { selectedLv4MenuFilters, lv4MenuFilters } = defaultFastFilters;

    // 设置四级菜单
    useEffect(() => {
        defaultFastFilters.setFilters(dfilters);
    }, []);

    useEffect(() => {
        // 如果没有type或者已经选择了，则无视
        if (
            !defaultFastFilters.type ||
            defaultFastFilters.selectedLv4MenuFilters.length > 0
        ) {
            return;
        }
        const { lv4MenuFilters } = defaultFastFilters;
        if (lv4MenuFilters.length === 0) {
            return;
        }

        const selectedfilter = lv4MenuFilters.find(
            (filter: any) => filter?.originalItem?.key === processKey,
        );
        if (selectedfilter) {
            // 选择一个外部指示的四级菜单
            defaultFastFilters.setSelectedLv4MenuFilters([selectedfilter]);
        } else {
            // 自动选择第一个四级菜单
            defaultFastFilters.setSelectedLv4MenuFilters([
                (lv4MenuFilters as any)[0],
            ]);
        }

        // 首次选择菜单已ready
        setFastFilterReady(true);
    }, [
        defaultFastFilters.type,
        selectedLv4MenuFilters,
        lv4MenuFilters,
        processKey,
    ]);

    // 根据四级菜单的选择，动态更改store的元信息，权限
    const selectedTableCode = selectedLv4MenuFilters[0]?.key;
    useEffect(() => {
        if (selectedTableCode === undefined) {
            return;
        }
        // 当tableCode更新，意味着内部各种东西已经更新
        defaultStore.setTableCode(selectedTableCode);
    }, [selectedTableCode]);

    useEffect(() => {
        if (!userStore.userInfo) {
            return;
        }
        defaultStore.setSysUser(userStore.userInfo.sysUser);
    }, [userStore.userInfo]);

    const popups = (
        <FormDrawerCreate
            key={defaultStore.detailTableCode || 'None'}
            defaultStore={defaultStore}
            preRequiredReady={fastFilterReady && outerPreRequestReady}
            autoNext={true}
            keepState={true}
            lifeCycle={lifeCycle}
        />
    );

    return popups;
};

export const FinalProcessFormProxy = dFilterHOC(
    observer(ProcessFormProxy),
    'process',
);
