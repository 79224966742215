import React from 'react';
import { DTable } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { AchievementManaStore } from '../store';
import { runInAction } from 'mobx';
import { tryRefresh } from '@/utils';

const DemoTable: React.FC<{
    defaultStore: AchievementManaStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const dataTable = (
        <>
            <DTable
                onColumnsUpdated={() => tryRefresh(['achievement'])}
                defaultStore={defaultStore}
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [
                        {
                            actionKey: 'update',
                            actionCn: '修改',
                        },
                    ];
                    props.onAction = (key, data) => {
                        if (key === 'update') {
                            runInAction(() => {
                                defaultStore.setAction('update', {
                                    id: data.id,
                                });
                            });
                        } else if (key === 'transform') {
                            // 其他动作
                        } else if (_.startsWith(key, 'reveal-obj/')) {
                            // 关联对象都可以点击
                        } else if (_.startsWith(key, 'reveal-param/')) {
                        }
                    };
                    props.closeRowSelection = true;
                    return props;
                }}
                cellsSelectorTransformer={baiscCellsSelector => {
                    return baiscCellsSelector;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
