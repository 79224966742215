import { useState, useEffect } from 'react';

export const useToggle = (defaultValue: boolean) => {
    const [toggle, _setToggle] = useState<boolean>(defaultValue);
    const setToggle = () => _setToggle(!toggle);
    return [toggle, setToggle] as const;
};

export const useBeforeFirstDataLoaded = (store: { data: any[] }) => {
    const [isBeforeFirstLoaded, setIsBeforeFirstLoaded] = useState(true);
    const [initedData, setInitedData] = useState(store.data);
    useEffect(() => {
        if (store.data !== initedData) {
            setIsBeforeFirstLoaded(false);
        }
    }, [store.data]);

    return [isBeforeFirstLoaded];
};

export const isUTF8 = (bytes: any) => {
    let i = 0;
    while (i < bytes.length) {
        if (
            // ASCII
            bytes[i] == 0x09 ||
            bytes[i] == 0x0a ||
            bytes[i] == 0x0d ||
            (0x20 <= bytes[i] && bytes[i] <= 0x7e)
        ) {
            i += 1;
            continue;
        }

        if (
            // non-overlong 2-byte
            0xc2 <= bytes[i] &&
            bytes[i] <= 0xdf &&
            0x80 <= bytes[i + 1] && bytes[i + 1] <= 0xbf
        ) {
            i += 2;
            continue;
        }

        if (
            // excluding overlongs
            (bytes[i] == 0xe0 &&
                0xa0 <= bytes[i + 1] && bytes[i + 1] <= 0xbf &&
                0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xbf) || // straight 3-byte
            (((0xe1 <= bytes[i] && bytes[i] <= 0xec) ||
                bytes[i] == 0xee ||
                bytes[i] == 0xef) &&
                0x80 <= bytes[i + 1] && bytes[i + 1] <= 0xbf &&
                0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xbf) || // excluding surrogates
            (bytes[i] == 0xed &&
                0x80 <= bytes[i + 1] && bytes[i + 1] <= 0x9f &&
                0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xbf)
        ) {
            i += 3;
            continue;
        }

        if (
            // planes 1-3
            (bytes[i] == 0xf0 &&
                0x90 <= bytes[i + 1] && bytes[i + 1] <= 0xbf &&
                0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xbf &&
                0x80 <= bytes[i + 3] && bytes[i + 3] <= 0xbf) || // planes 4-15
            (0xf1 <= bytes[i] &&
                bytes[i] <= 0xf3 &&
                0x80 <= bytes[i + 1] && bytes[i + 1] <= 0xbf &&
                0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xbf &&
                0x80 <= bytes[i + 3] && bytes[i + 3] <= 0xbf) || // plane 16
            (bytes[i] == 0xf4 &&
                0x80 <= bytes[i + 1] && bytes[i + 1] <= 0x8f &&
                0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xbf &&
                0x80 <= bytes[i + 3] && bytes[i + 3] <= 0xbf)
        ) {
            i += 4;
            continue;
        }
        return false;
    }
    return true;
};

export const fixdata = (data: any) => {
    // 文件流转BinaryString
    // tslint:disable-next-line:one-variable-per-declaration
    let o = '',
        l = 0;
    const w = 10240;
    // tslint:disable-next-line:curly
    for (; l < data.byteLength / w; ++l)
        o += String.fromCharCode.apply(
            null,
            (new Uint8Array(
                data.slice(l * w, l * w + w),
            ) as unknown) as number[],
        );
    o += String.fromCharCode.apply(
        null,
        (new Uint8Array(data.slice(l * w)) as unknown) as number[],
    );
    return o;
};
