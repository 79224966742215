import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    FastFilterStore,
    makeDefaultInitailingHook,
} from '@/stores/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import { mutate } from '@/api/graphql';
import _ from 'lodash';
import { serializeFilterData3 } from '@/utils/filters-serializers';
import { message, Modal } from 'antd';
import {
    xDelete,
    commonQuery,
    TGetCommonReturnType,
    getCommonQueryBody,
} from '@/api/rest';
import {
    defaultWritingDataParser,
    defaultAxios,
    isNetSuccess,
    arrToJson,
    executeAllValidatorFor,
    decodePhone as phoneDecode,
} from '@/utils';
import EE from 'eventemitter3';
import $ from 'jquery';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';
import { embedProductParamToOpt } from '@/pages/sales-follow-up/opt/list/utils';
import {
    parseFeProductToBackEndContractProduct,
    embedProductParamToContract,
} from './utils';
import { duration } from 'moment';
import { getProductsTotalPriceByData } from '@/components/common/form/widgets/opt/select-opt-product';
import {
    makeDefaultInitailingHookB,
    createDefaultInstanceGetterA,
} from '@/stores/utilsA';

const endTimeValidator = (input: any): ValidateStatus => {
    const { start_time, end_time } = input;
    if (start_time === null || end_time === null) {
        return {
            status: 'success',
            msg: '',
        };
    }
    if (start_time <= end_time) {
        return {
            status: 'success',
            msg: '',
        };
    }
    return {
        status: 'fail',
        msg: '合同截止时间应大于合同开始时间',
    };
};

const transformBackEndValidateStateToValidateResult = (
    data: any[],
): ValidateResult<any> => {
    const ret: ValidateResult<any> = {};
    for (const d of data) {
        const { errorMsg, errorType, fieldName } = d;
        if (!errorMsg || !errorType || !fieldName) {
            continue;
        }
        ret[fieldName] = {
            msg: errorMsg,
            status: errorType === 'error' ? 'fail' : 'warning',
        };
    }
    return ret;
};

export class ContractListStore {
    // 数据
    public data: any[] = [];

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public position: string = '';
    public setPosition(position: string) {
        this.position = position;
    }

    public get pageTitle() {
        if (this.listType === 'all') {
            return '全部合同';
        } else if (this.listType === 'mine') {
            return '我的合同';
        }
        return '未知listType';
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    get contractValidators() {
        const { defaultValidators } = this.defaultMeta;
        const nextValidator = { ...defaultValidators };
        nextValidator.end_time = endTimeValidator;
        return nextValidator;
    }
    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFastFilters = this.defaultFastFilters;
        const {
            selectedFastFilters,
            selectedLv4MenuFilters,
        } = defaultFastFilters;

        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );
        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        for (const filterSet of selectedFastFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        for (const filterSet of selectedLv4MenuFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        ret.filters?.push(...defaultFilters);
        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public actionType: string = '';
    public actionTs: number = 0;
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }

    public setAction(nextAction: string, extra?: any) {
        this.actionTs = Date.now();
        if (nextAction === 'update') {
            if (extra.id) {
                this.fetchInstantlyMutatingDataById(extra.id);
            } else {
                message.error('修改必须传id');
            }
        }
        this.actionType = nextAction;
    }
    public resetAction() {
        this.netValidateResult = {};
        this.actionType = '';
    }
    public async dispatchAction() {
        if (this.actionType === 'create') {
            return this.create();
        } else if (this.actionType === 'update') {
            return this.update();
        }
    }

    public async createPDF() {
        const data = _.cloneDeep(this.mutatingDataParsed);

        try {
            const opportunityProductList = await parseFeProductToBackEndContractProduct(
                data.product || [],
                data,
            );
            data.contract_amount = getProductsTotalPriceByData(
                data.product || [],
            );
            data.contract_product_list = opportunityProductList;
            data.product = null;
        } catch (e) {
            message.error(e.message);
            return [null, e.message];
        }

        const processPermAll = this.defaultMeta.processPermAll;
        if (_.isArray(processPermAll)) {
            const validators: any = {};
            validators.end_time = endTimeValidator;

            const result = await executeAllValidatorFor(
                data,
                validators,
                _.keys(data),
            );

            if (!result.isPass) {
                message.error('合同截止时间应大于合同开始时间');
                this.setNetValidateResult(result.result);
                return [null, '合同截止时间应大于合同开始时间'];
            }
        }

        delete data.id;
        delete data.key;

        const res = await defaultAxios.post(
            '/bff/api/rest/contract/createPdf',
            data,
        );
        return res;
    }

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;
    get mutatingDataParsed() {
        return defaultWritingDataParser(this.mutatingData);
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public setMutatingDataByKey(key: string, next: any) {
        this.mutatingData[key] = next;
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        const initialFormData = this.defaultMeta.defaultWidgetValues;
        this.mutatingData = _.cloneDeep(initialFormData);
        this.mutatingData.party_b_company = 2;
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;

    get fieldAuthCode() {
        if (this.position === 'customer-drawer') {
            return '';
        }
        return '';
    }
    get dataAuthCode() {
        if (this.position === 'customer-drawer') {
            return 'SEARCH-ALL';
        }
        return '';
    }
    public async fetchInstantlyMutatingDataById(id: any) {
        const { fieldAuthCode, dataAuthCode } = this;

        this.mutatingDataLoading = true;
        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: id,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            undefined,
            dataAuthCode,
        );
        this.mutatingDataLoading = false;

        if (d === null || e !== null) {
            return false;
        }
        const { datas } = d;
        if (datas && datas[0]) {
            const ret = await embedProductParamToContract(datas[0]);
            if (!ret) {
                message.error('获取合同产品失败');
                return false;
            }
            datas[0]._contract_attachments = datas[0].contract_attachments;
            this.mutatingData = datas[0];
            return true;
        } else {
            message.error('没有该数据的权限');
            return false;
        }
    }
    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode, dataAuthCode } = this;

        const [d, e] = await commonQuery(
            this.defaultMeta.tableId,
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            this.defaultMeta.tableColsParamsFromUserSetting.map(
                item => item.key,
            ),
            dataAuthCode,
        );

        if (_.isNil(d) || !_.isNil(e)) {
            return [d, e];
        }
        const { amount, datas } = d;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    get downloadUrl() {
        const { fieldAuthCode, dataAuthCode } = this;
        const { tableId: objectName } = this.defaultMeta;
        const { amount } = this.defaultPagination;
        if (!amount) {
            return '';
        }
        const pageSize = Math.max(Math.min(Math.ceil(amount / 10), 300), 100);
        const fetchBody = getCommonQueryBody(
            { ...this.defaultPagination.paginationArgs, pageSize },
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            this.defaultMeta.tableColsParamsFromUserSetting.map(
                item => item.key,
            ),
            dataAuthCode,
            false,
        );
        fetchBody.objectName = objectName;
        return (
            `${
                window.location.origin
            }/bff/api/rest/export?q=${encodeURIComponent(
                JSON.stringify(fetchBody),
            )}&hash=` + Math.random()
        );
    }

    public async create() {
        const data = _.cloneDeep(this.mutatingDataParsed);

        try {
            const opportunityProductList = await parseFeProductToBackEndContractProduct(
                data.product || [],
                data,
            );
            data.contract_amount = getProductsTotalPriceByData(
                data.product || [],
            );
            data.contract_product_list = opportunityProductList;
            data.product = null;
        } catch (e) {
            message.error(e.message);
            return false;
        }

        const { _contract_code: contractCode } = data;
        if (!contractCode) {
            message.error('无contract_code');
            return false;
        }
        data.contract_watermark = contractCode;

        const processPermAll = this.defaultMeta.processPermAll;
        if (_.isArray(processPermAll)) {
            const validators: any = {};
            validators.end_time = endTimeValidator;

            const result = await executeAllValidatorFor(
                data,
                validators,
                _.keys(data),
            );

            if (!result.isPass) {
                message.error('合同截止时间应大于合同开始时间');
                this.setNetValidateResult(result.result);
                return false;
            }
        }

        const isSuccess = await new Promise<boolean>(resolve => {
            const ret = Modal.confirm({
                title: '提交后将直接进入审批流程，请再次确认合同信息无误。',
                zIndex: 1000000,
                onOk: async () => {
                    delete data.id;
                    delete data.key;
                    const [ret, errMsg] = await mutate(
                        'contract',
                        'insert',
                        data,
                    );
                    if (!ret || errMsg) {
                        return resolve(false);
                    }

                    const { msg, code, data: innerData } = ret;

                    if (_.isArray(innerData) && innerData.length > 0) {
                        // 如果是数组就是一堆字段报错
                        const formTopEle = $('.component-form-lv2')[0];
                        if (formTopEle) {
                            (formTopEle as any).___withAutoScroll = true;
                        }
                        this.setNetValidateResult(
                            transformBackEndValidateStateToValidateResult(
                                innerData,
                            ),
                        );
                        message.error('存在校验不通过的字段');
                        return resolve(false);
                    }
                    if (+code !== 0) {
                        return resolve(false);
                    }
                    this.setNetValidateResult({});
                    this.resetAction();
                    message.info('新建成功');
                    this.defaultEE.emit('create-success');

                    return resolve(true);
                },
                onCancel() {
                    resolve(false);
                },
            });
        });
        return isSuccess;
    }
    public async update() {
        const data = _.cloneDeep(this.mutatingDataParsed);

        try {
            const opportunityProductList = await parseFeProductToBackEndContractProduct(
                data.product || [],
                data,
            );
            data.contract_product_list = opportunityProductList;
            data.product = null;
        } catch (e) {
            message.error(e.message);
            return false;
        }

        const { _contract_code: contractCode } = data;
        if (!contractCode) {
            message.error('无contract_code');
            return false;
        }
        // 需要比较一下是否存在新文件，决定是否使用
        const { _contract_attachments: originalContractAttachmentsStr } = data;
        const { contract_attachments: newContractAttachmentsStr } = data;
        try {
            const originalContractAttachments = JSON.parse(
                originalContractAttachmentsStr,
            );
            const newContractAttachments = JSON.parse(
                newContractAttachmentsStr,
            );
            let hasNewAttachment = false;
            const originalMap = arrToJson(originalContractAttachments, 'url');
            for (const newAtt of newContractAttachments) {
                if (newAtt.url && !originalMap[newAtt.url]) {
                    hasNewAttachment = true;
                    break;
                }
            }
            if (hasNewAttachment) {
                data.contract_watermark = contractCode;
            }
        } catch (e) {
            message.error('文件解析出错');
            return false;
        }

        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        delete data.key;
        const [ret, errMsg] = await mutate('contract', 'update', data);
        if (!ret || errMsg) {
            return false;
        }
        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('修改成功');
        this.defaultEE.emit('update-success');
        return true;
    }

    public async delete() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = [data.id];
        const [d, e] = await xDelete(tableId, operatingIdList);
        if (!e) {
            message.info('删除成功');
            return true;
        }
        return false;
    }

    public async mdelete() {
        const ids = _.map(this.defaultMSelect.mSeletedData, item => item.id);
        if (ids.length === 0) {
            return;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = ids;
        const [d, e] = await xDelete(tableId, operatingIdList);

        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('删除成功');
            return true;
        } else {
            this.defaultMSelect.setMopResult('批量删除结果', d.data);
            return false;
        }
    }

    public assignProductLoading: boolean = false;
    public autoFillingLoading: boolean = false;
    public autoFillingData: any = null;
    public async askAutoFillingDataByOpt(opt: any) {
        const opportunityId = opt?.originalValue;
        if (!opportunityId) {
            return false;
        }
        this.autoFillingLoading = true;
        const hideFill = message.loading('商机数据加载中...', 0);
        const [d, e] = await defaultAxios.get(
            '/bff/api/rest/contract/fullContract',
            {
                opportunityId,
            },
        );
        this.autoFillingLoading = false;
        if (e) {
            hideFill();
            return false;
        }

        const ret = d?.data;
        if (!ret) {
            hideFill();
            return false;
        }
        const { contract } = ret;
        // 如果用户没有提前填写产品，就把商机的产品带给合同
        if (
            !(
                _.isArray(this.mutatingData.product) &&
                this.mutatingData.product.length > 0
            )
        ) {
            this.assignProductLoading = true;
            // 借用模式，第三个参数clone===true
            await embedProductParamToOpt(contract, opportunityId, true);
            this.assignProductLoading = false;
        }
        runInAction(() => {
            this.autoFillingData = contract;
            // 与单纯的修改场景相同，只做副作用，不deepClone
            this.mutatingData = _.assign(this.mutatingData, contract);
            this.clearParamsValidateState(_.keys(this.autoFillingData));
        });
        setTimeout(() => {
            hideFill();
        }, 200);
        return true;
    }

    public contractProcessContext: any = null;
    public async getContractProcessContext(currentUser: string) {
        const [d, e] = await defaultAxios.get('/crm/workflow/processdef/list');
        if (!isNetSuccess(d, e)) {
            return false;
        }
        const processList = d.data;
        if (!_.isArray(processList)) {
            return false;
        }
        const contractProcess = processList.find(
            item => item.key === 'contractapprove',
        );

        if (!contractProcess.id || !currentUser) {
            return false;
        }
        const ctx = { definitionId: contractProcess.id, currentUser };
        this.contractProcessContext = ctx;
        return true;
    }

    public async clearParamsValidateState(keys: string[]) {
        const nextNetValidateResult = { ...this.netValidateResult };
        for (const key of keys) {
            delete nextNetValidateResult[key];
        }
        this.netValidateResult = nextNetValidateResult;
    }

    // 产品字段，确认权益
    public productToConfirmEquity: any = null;
    public setProductToConfirmEquity(equity: any) {
        this.productToConfirmEquity = equity;
    }
    public resetProductToConfirmEquity() {
        this.productToConfirmEquity = null;
    }
    // 更新产品权益
    public setNextParsedEquities(nextEquities: any) {
        const relatedObj = this.productToConfirmEquity?.relatedObj;
        relatedObj.equity_detail_parsed = nextEquities;
    }
    public async decodePhone(phoneNum: string) {
        const url = '/crm/crypto/phone/decrypt';
        // setDecodeLoading(true);
        const [d, e] = await defaultAxios.post(url, {
            phone: phoneNum,
            scene: 'crm_contacts',
        });
        // setDecodeLoading(false);
        const data = phoneDecode(d?.data);
        const error = e as Error | null;
        if (data) {
            return data;
        } else {
            // message.error('解密手机号失败');
            return phoneNum;
        }
    }
    // 用客户联系人回填
    public async fillContactsInfo(contactsId: number) {
        const [d, e] = await defaultAxios.get(
            '/bff/api/rest/contacts/fillContractContactInfo?id=' + contactsId,
        );
        if (isNetSuccess(d, e)) {
            const data = d?.data;
            if (this.mutatingData) {
                const pData = await this.decodePhone(data.contacts_phone);
                this.mutatingData.customer_phone = pData;
            }
        }
    }
    // 用收件联系人回填
    public async fillReceiverContactsInfo(contactsId: number) {
        const [d, e] = await defaultAxios.get(
            '/bff/api/rest/contacts/fillContractContactInfo?id=' + contactsId,
        );
        if (isNetSuccess(d, e)) {
            const data = d?.data;
            if (this.mutatingData) {
                const pData = await this.decodePhone(data.contacts_phone);
                this.mutatingData.receiver_contacts_mobilephone = pData;
            }
        }
    }

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMeta = new ListMeta(
        '/bff/api/rest/meta/contract/list',
        '/bff/api/rest/meta/contract/list/filters/save',
        '/bff/api/rest/meta/contract/list/table-cols/save',
    );
    public setDefaultMeta(next: ListMeta) {
        this.defaultMeta = next;
    }
    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();
    public defaultPerm: Perm = new Perm('/bff/api/rest/perm?mod=om:contract');
    public defaultMSelect: MSelectStore = new MSelectStore();
    public defaultFastFilters = new FastFilterStore();
    constructor() {
        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultMeta: false,
            defaultFilter: false,
            defaultEE: false,
            defaultPerm: false,
            defaultMSelect: false,
            defaultFastFilters: false,
        });

        const isOm = window.location.pathname.indexOf('/om') > -1;
        this.defaultPerm.setFinalApi(
            isOm
                ? '/bff/api/rest/perm?mod=om:contract'
                : '/bff/api/rest/perm?mod=sales-follow-up:contract',
        );
    }
}

export const getDefaultContractListStore = createDefaultInstanceGetterA(
    ContractListStore,
);

export const useAnyContractListStore = makeDefaultInitailingHookB(
    ContractListStore,
    'contract',
);

export const genContractIdParamByContractItemAndParamMeta = (
    contract: any,
    fieldMeta?: any,
) => {
    // HACK: 先写死
    contract = contract || {};
    if (!contract.id || !contract.contract_name) {
        return null;
    }
    return {
        type: 'obj',
        showFiledName: 'contract_name',
        originalValue: contract.id,
        relatedObj: {
            relation_object_map: {},
            id: contract.id,
            contract_name: contract.contract_name,
            customer_id: contract.customer_id?.originalValue,
            customer_name: contract.customer_name,
        },
        showRelatedObj: {
            relation_object_map: {},
            id: contract.id,
            contract_name: contract.contract_name,
            customer_id: contract.customer_id?.originalValue,
            customer_name: contract.customer_name,
        },
    };
};

export const seeContractProcess = (contractId: any) => {
    const close = message.loading('合同信息加载中...');
    commonQuery(
        'contract',
        {
            pageSize: 1,
            pageNum: 1,
        },
        {
            filters: [
                {
                    fieldId: 'id',
                    filterValue: contractId,
                    operateType: '=',
                },
            ],
        },
        undefined,
        undefined,
        undefined,
        'SEARCH-ALL',
    ).then(([d, e]) => {
        close();
        if (isNetSuccess(d, e)) {
            const datas = d?.datas || [];
            if (datas && datas[0] && datas[0].process_instance_id) {
                window.open(
                    `/buy/process-management/all?id=${datas[0].process_instance_id}&target=process`,
                );
            } else {
                message.error('合同信息加载失败!');
            }
        } else {
            message.error('合同信息加载失败');
        }
    });
};
