import React, { useState, useEffect, useContext } from 'react';
import './index.scss';
import { ISpecialUserData, INormalSettingData } from '../store';
import { StoreContext } from '@/stores';
import {
    getAutoOnChangeHandlerOriginal,
    IWidgetItem,
    embedDataToWidgets,
    IFormLV2Props,
    FormLV2,
} from '@/components';
import _ from 'lodash';
import { message } from 'antd';
import { observer } from 'mobx-react-lite';
import { transformAllArrayToString } from '../../../../utils/helpers';
import { defaultAxios } from '@/utils';

export interface ISpecialLimitSettingModalProps {
    setModalVis: (bool: boolean) => void;
    modalVis: boolean;
    modalDefaultData: ISpecialUserData;
    setModalDefaultData: (
        modalDefaultData: ISpecialUserData | undefined,
    ) => void;
    normalSettingData: INormalSettingData;
    reloadTable: () => void;
}
const SpecialLimitSettingModal: React.FC<ISpecialLimitSettingModalProps> = props => {
    const store = useContext(StoreContext);
    const CHSPSLStore = store.getDefaultCHSPSLStore();
    const SettingStore = store.getSettingDefaultStore();
    const { updateOrAddSpecialSales } = CHSPSLStore;
    const { isCustomerInfoLoading, customerInfo } = SettingStore;
    const {
        setModalVis,
        modalVis,
        modalDefaultData,
        setModalDefaultData,
        normalSettingData,
        reloadTable,
    } = props;
    const [modalData, setModalData] = useState<ISpecialUserData>({});
    const [specialUserSelectList, setSpecialUserSelectList] = useState<any[]>(
        [],
    );
    const [
        specialUserSelectListLoading,
        setSpecialUserSelectListLoading,
    ] = useState(true);
    useEffect(() => {
        if (modalVis === false) {
            getCanAddCustomer();
        }
    }, [modalVis]);

    const getCanAddCustomer = async () => {
        setSpecialUserSelectListLoading(true);
        const url = '/bff/api/rest/chspl/sales-list';
        const [d, e] = await defaultAxios.get(url);
        setSpecialUserSelectListLoading(false);
        if (d && d.data) {
            setSpecialUserSelectList(d.data);
        } else {
            setSpecialUserSelectList([]);
        }
    };
    useEffect(() => {
        if (modalDefaultData) {
            const maxNum = modalDefaultData.maxNum || '';
            const userId = modalDefaultData.userId;
            setModalData({
                ...modalDefaultData,
                maxNum,
                userId: Array.isArray(userId) ? userId : [String(userId)],
            });
        }
    }, [modalDefaultData]);
    const isAddMode = !modalData.id;
    const hideModal = () => {
        // 清除组件内与组件外的默认值
        setModalDefaultData({ userId: [] });
        setModalData({
            userId: [],
        });
        setModalVis(false);
    };
    // 获取默认的数据回调
    const onDataChange = getAutoOnChangeHandlerOriginal(
        modalData as ISpecialUserData,
        setModalData,
    );
    const widgets: Array<IWidgetItem<ISpecialUserData>> = [
        {
            key: 'userId',
            widgetKey: 'userSelector',
            userSelectorOptions: {
                // userDocs: specialUserSelectList as any,
                userDocs: customerInfo as any,
                mode: 'multiple',
                placeholder: '请选择销售',
                style: {
                    width: '270px',
                },
            },
            status: {
                loading: specialUserSelectListLoading,
                disabled: !isAddMode,
            },
        },
        {
            key: 'maxNum',
            widgetKey: 'input',
            inputOptions: {
                placeholder: '设置最大客户数',
                type: 'number',
                min: 0,
                style: {
                    width: '270px',
                },
            },
        },
        {
            key: 'isIncludeSign',
            widgetKey: 'switch',
            switchOptions: {
                unCheckedChildren: '关',
                checkedChildren: '开',
            },
        },
        {
            key: 'isIncludeCreate',
            widgetKey: 'switch',
            switchOptions: {
                unCheckedChildren: '关',
                checkedChildren: '开',
            },
        },
    ];
    embedDataToWidgets<ISpecialUserData>(widgets, modalData);
    const SpecialLimitSetModalProps: IFormLV2Props<ISpecialUserData> = {
        data: modalData,
        widgets,
        onChange: onDataChange,
        actionWidgets: [
            {
                key: 'submit',
                option: {
                    btnText: '提交',
                    type: 'primary',
                },
            },
            {
                key: 'cancel',
                option: {
                    btnText: '取消',
                    type: 'default',
                    clickActionType: 'cancel',
                },
            },
        ],
        onAction: async (actionType, data, isPass, validateResult) => {
            if (actionType === 'submit') {
                if (isPass) {
                    const payloadModalData = transformAllArrayToString(
                        modalData,
                    );
                    const [d, e] = await updateOrAddSpecialSales({
                        ...payloadModalData,
                        isIncludeSign: payloadModalData.isIncludeSign ? 1 : 0,
                        isIncludeCreate: payloadModalData.isIncludeCreate
                            ? 1
                            : 0,
                    });
                    if (d) {
                        message.success('修改成功', 1);
                        setModalDefaultData(undefined);
                        hideModal();
                        reloadTable();
                    } else {
                        message.error('修改失败', 1);
                    }
                } else {
                    message.error('数据不正确，不可提交');
                }
            }

            if (actionType === 'cancel') {
                hideModal();
            }
        },
        formItemOptions: [
            {
                key: 'userId',
                label: '选择成员',
                required: true,
                className: 'special-userId-select',
            },
            {
                key: 'maxNum',
                label: '可拥有最大客户数',
                required: true,
                className: 'special-max-limit-items',
            },
            {
                key: 'isIncludeSign',
                label: '包括已签约的客户数',
            },
            {
                key: 'isIncludeCreate',
                label: '包括自建的客户数',
            },
        ],
        validateMode: 'instantly',
        validators: {
            userId: datam => {
                if (_.isNil(datam.userId)) {
                    return {
                        status: 'fail',
                        msg: '请选择销售',
                    };
                } else {
                    if (Array.isArray(datam.userId)) {
                        console.log(datam.userId);
                        if (!(datam.userId as any[]).length) {
                            return {
                                status: 'fail',
                                msg: '请选择销售',
                            };
                        }
                    } else {
                        return {
                            status: 'fail',
                            msg: '数据格式不正确',
                        };
                    }
                    return {
                        status: 'success',
                        msg: '',
                    };
                }
            },
            maxNum: datam => {
                if (_.isNil(datam.maxNum) || datam.maxNum === '') {
                    return {
                        status: 'fail',
                        msg: '请输入最大客户数',
                    };
                } else {
                    if (datam.maxNum < 0) {
                        return {
                            status: 'fail',
                            msg: '客户数不能小于0',
                        };
                    }
                    return {
                        status: 'success',
                        msg: '',
                    };
                }
            },
        },
        container: 'modal',
        containerModalProps: {
            visible: modalVis,
            title: `${isAddMode ? '新增' : '修改'}特殊销售最大私池客户数`,
            onCancel: () => {
                hideModal();
            },
            width: 600,
            wrapClassName: 'chspl-modal-content',
            destroyOnClose: true,
        },
    };
    return (
        <FormLV2<ISpecialUserData> {...SpecialLimitSetModalProps}>
            {/* <div className="special-include-tips">
                <p>
                    {normalSettingData?.includeDeal === 1
                        ? '包含已签约客户'
                        : '不包含已签约客户'}
                </p>
                <p>
                    {normalSettingData?.includeSelfBuild === 1
                        ? '包含自建客户'
                        : '不包含自建客户'}
                </p>
            </div> */}
        </FormLV2>
    );
};

export default observer(SpecialLimitSettingModal);
