import React, {
    memo,
    useEffect,
    useContext,
    useState,
    useCallback,
} from 'react';
import { DForm, IProcessCtx, IDFormProps, AntTable } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ProcessCatStore } from '../../store';
import {
    arrToJsonPrimary,
    defaultAxios,
    isNetSuccess,
    arrToJson,
    makeStringValidator,
    useRefresh,
} from '@/utils';
import { validators } from '@/pages/demo2/form2/options';
import { getValidatorByParamMeta } from '@/stores/utils';
import { applyCustomConfToWidget } from '@/components/common/form/advanced-filters';
import { CtxContext } from '@/utils/context';
import { Alert, message, Icon } from 'antd';
import { seeContractProcess } from '@/pages/om/contract/list/store';
import {
    commonQuery,
    commonQueryProcess,
    commonQueryProcess2,
    transparentQuery,
} from '@/api/rest';
import querystring from 'querystring';
import { StoreContext } from '@/stores';
import { CustomerContactsCreate } from '@/components/common/customer-drawer/customer-contacts-create';

const supportType1 = ['zhuxuelei'];
const supportType2 = ['liruixue'];
const supportType3 = ['gaobin'];
const finishCaseUsers = new Set([
    ...supportType1,
    ...supportType2,
    ...supportType3,
]);

const options3 = [{ name: '高彬', value: 20103 }];
const options2 = [{ name: '李瑞雪', value: 20131 }];
const options1 = [{ name: '朱雪雷', value: 271 }];

const DemoForm: React.FC<{
    defaultStore: ProcessCatStore;
    processCtx: IProcessCtx | null;
    tableCode: string | null;
    mode: 'create' | 'update';
}> = ({ defaultStore, processCtx, tableCode, mode }) => {
    const ctx = useContext(CtxContext);
    const { position, positionDetail } = ctx;
    const isCreate = mode === 'create';
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const userId = authStore?.userInfo?.userId;

    const { defaultMeta, mutatingData: muData } = defaultStore;
    const [fillOptLoading, setFillOptLoading] = useState(false);
    const [fillCusLoading, setFillCusLoading] = useState(false);

    const isFinishCase =
        defaultMeta?.processAllInfo?.crmActivity?.activityId === 'finishcase';

    const isEvaluation =
        defaultMeta?.processAllInfo?.crmActivity?.activityId === 'evaluation';

    // 结案节点，且是结案执行人打开，且没有结案时间，给加一个结案时间
    // 客户端时间会不会有问题？
    useEffect(() => {
        if (!muData) {
            return;
        }
        if (!!muData.end_date) {
            return;
        }
        if (!isFinishCase) {
            return;
        }
        if (!userId || !finishCaseUsers.has(userId)) {
            return;
        }
        muData.end_date = Date.now();
    }, [userId, isFinishCase, muData, muData?.end_date]);

    if (!defaultMeta) {
        return null;
    }
    const baiscWidgets = defaultMeta.defaultWidgetsAllWithoutGroupCheck;

    const collapseLayout = defaultMeta.defaultFormCollapseLayout;
    const shouldGroup = collapseLayout.length > 0;

    const layout = new Array(Math.ceil(baiscWidgets.length / 2)).fill([12, 12]);
    if (shouldGroup) {
        for (const colla of collapseLayout) {
            colla.layout = layout;
        }
    }

    if (processCtx === null || !processCtx.currentUser) {
        return null;
    }

    const props: IDFormProps = {
        processCtx,
        defaultStore: defaultStore as any,
        widgetsGenerator: store => {
            return store.defaultMeta.defaultWidgetsAllWithoutGroupCheck;
        },
    };

    if (shouldGroup) {
        props.options = {
            grouped: true,
        };
    } else {
        props.propsTransformer = props => {
            props.layout = layout;
            return props;
        };
    }

    const { mutatingData } = defaultStore;

    if (mutatingData !== null) {
        const { objectMetaMap } = defaultMeta;
        props.labelsTransformer = originalLabels => {
            const nextLabels = [...originalLabels];
            nextLabels.forEach(label => {
                if (
                    label.key === 'data_purpose' ||
                    label.key === 'data_require_type'
                ) {
                    label.required = true;
                }
            });
            return nextLabels;
        };

        props.validatorsTransformer = validators => {
            for (const k of ['data_purpose', 'data_require_type']) {
                const paramsMeta = _.cloneDeep(objectMetaMap[k]);
                paramsMeta.isRequired = true;
                validators[k] = getValidatorByParamMeta(paramsMeta);
            }
            return validators;
        };

        const originalOnChange = props.onChange;
        props.onChange = (key, value, widgetKey) => {
            const k = key as string;
            if (k === 'opportunity_id') {
                const clear = () =>
                    (mutatingData.prediction_sale_amount = null);
                clear();
                setFillOptLoading(true);
                commonQuery(
                    'opt',
                    {
                        pageSize: 1,
                        pageNum: 1,
                    },
                    {
                        filters: [
                            {
                                fieldId: 'id',
                                filterValue: value?.originalValue,
                                operateType: '=',
                            },
                        ],
                    },
                    undefined,
                    undefined,
                    undefined,
                    'SEARCH-ALL',
                ).then(([d, e]) => {
                    setFillOptLoading(false);
                    if (_.isNil(d) || e !== null) {
                        return;
                    }
                    const { datas } = d;
                    if (datas && datas[0]) {
                        const dt = datas[0];
                        mutatingData.prediction_sale_amount =
                            dt.prediction_sale_amount;
                    } else {
                        clear();
                    }
                });
            }

            if (k === 'customer_id') {
                setFillCusLoading(true);
                transparentQuery(
                    {
                        pageNum: 1,
                        pageSize: 1,
                        selectiveFields: ['id', 'enterprise_desc'],
                        fieldQueries: [
                            {
                                fieldName: 'customer_id',
                                operateType: 'EQUAL',
                                fieldValue: value?.originalValue,
                            },
                        ],
                        orderBy: 'create_time',
                        isDesc: true,
                        dataAuthCode: 'SEARCH-ALL',
                    },
                    'process_employer_support_table',
                ).then(([list, e]) => {
                    setFillCusLoading(false);
                    if (!_.isArray(list) || e !== null) {
                        return;
                    }
                    const datas = list;
                    if (datas && datas[0]) {
                        const dt = datas[0];
                        mutatingData.enterprise_desc = dt.enterprise_desc;
                    }
                });
            }

            if (k === 'support_type') {
                if (value === 1) {
                    mutatingData.demand_receiver = 271; // zhuxuelei
                }
                if (value === 2) {
                    mutatingData.demand_receiver = 20131; // liruixue
                }
                if (value === 3) {
                    mutatingData.demand_receiver = 20103; // gaobin
                }
                if (value === null) {
                    mutatingData.demand_receiver = null;
                }
            }

            if (originalOnChange) {
                return originalOnChange(key, value, widgetKey);
            }
        };

        const { support_type, customer_id } = mutatingData;

        const oldwidgetsTransformer = props.widgetsTransformer;
        props.widgetsTransformer = prevoriginalWidgets => {
            const originalWidgets = oldwidgetsTransformer
                ? oldwidgetsTransformer(prevoriginalWidgets)
                : prevoriginalWidgets;
            const nextWidgets = [...originalWidgets];

            applyCustomConfToWidget(nextWidgets, 'data_purpose', widget => {
                widget.status = {
                    ...widget.status,
                    hidden: support_type !== 3,
                };
                return widget;
            });

            applyCustomConfToWidget(
                nextWidgets,
                'data_require_type',
                widget => {
                    widget.status = {
                        ...widget.status,
                        hidden: support_type !== 3,
                    };
                    return widget;
                },
            );

            applyCustomConfToWidget(nextWidgets, 'customer_id', widget => {
                const cusId = customer_id?.originalValue;
                widget.status = {
                    ...widget.status,
                    loading: fillCusLoading,
                };
                if (widget.objectOptions) {
                    widget.objectOptions.dataAuthCode = 'SEARCH-PRIVATE';
                }
                return widget;
            });

            applyCustomConfToWidget(nextWidgets, 'opportunity_id', widget => {
                const cusId = customer_id?.originalValue;
                const originalDisable = widget.status?.disabled;
                widget.status = {
                    ...widget.status,
                    loading: fillOptLoading,
                    disabled: originalDisable === true ? true : !cusId,
                };
                if (widget.objectOptions) {
                    widget.objectOptions.defaultFilters = [
                        {
                            fieldName: 'win_rate',
                            operateType: 'GREATER_EQUAL',
                            fieldValue: 30,
                        },
                        {
                            fieldName: 'customer_id',
                            operateType: 'EQUAL',
                            fieldValue: cusId,
                        },
                    ];
                    widget.objectOptions.reloadTrigger = cusId;
                }
                widget.nextlineWidget = () => {
                    const optId = mutatingData?.opportunity_id?.originalValue;
                    if (!optId) {
                        return null;
                    }
                    return (
                        <div style={{ paddingTop: 4 }}>
                            <a
                                target="_blank"
                                href={`/buy/opportunity/all?id=${optId}&target=procees_employer_support`}
                            >
                                查看商机
                                <Icon type="link" />
                            </a>
                        </div>
                    );
                };
                return widget;
            });

            applyCustomConfToWidget(nextWidgets, 'enterprise_desc', widget => {
                widget.nextlineWidget = () => {
                    return (
                        <div style={{ paddingTop: 4 }}>
                            <Alert
                                type="info"
                                message="企业简介、企业文化、价值观、近期动作、主张"
                            />
                        </div>
                    );
                };
                return widget;
            });

            applyCustomConfToWidget(nextWidgets, 'cooperation_desc', widget => {
                widget.nextlineWidget = () => {
                    return (
                        <div style={{ paddingTop: 4 }}>
                            <Alert
                                type="info"
                                message="填写排期预占情况或未合作/合作暂停原因"
                            />
                        </div>
                    );
                };
                return widget;
            });

            applyCustomConfToWidget(
                nextWidgets,
                'demand_receiver',
                prevwidget => {
                    const widget = _.cloneDeep(prevwidget);
                    if (isEvaluation) {
                        if (support_type === 2) {
                            widget.status = {
                                ...widget.status,
                                disabled: true,
                            };
                            if (widget.selectOptions) {
                                widget.selectOptions.options = options2;
                            }
                        } else if (support_type === 3) {
                            widget.status = {
                                ...widget.status,
                                disabled: true,
                            };
                            if (widget.selectOptions) {
                                widget.selectOptions.options = options3;
                            }
                        } else {
                            widget.status = {
                                ...widget.status,
                                disabled: true,
                            };
                            if (widget.selectOptions) {
                                widget.selectOptions.options = options1;
                            }
                        }
                    } else {
                        if (widget.selectOptions) {
                            widget.selectOptions.options = [
                                ...options3,
                                ...options2,
                                ...options1,
                            ];
                        }
                    }

                    return widget;
                },
            );

            return nextWidgets;
        };
    }

    if (tableCode === null) {
        return null;
    }

    const form = (
        <>
            <DForm {...props} />
        </>
    );
    return form;
};

export const ProcessEmployerSupportTable = observer(DemoForm);
