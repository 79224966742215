import React, { useMemo } from 'react';
import _ from 'lodash';
import ChartsWrap, { HeaderColors } from '../../components/charts-wrap';
import * as echarts from 'echarts';

type ChartsProps = {
    chartsClassName?: string;
    loading?: boolean;
    color?: HeaderColors;
    title: string;
    xAxis: string[];
    dataSource: DataSource[];
};

type DataSource = {
    label: string;
    data: number[];
};

const TrendCharts: React.FC<ChartsProps> = ({
    chartsClassName = '',
    loading = false,
    color,
    title,
    xAxis = [],
    dataSource = [],
}) => {
    const chartsOptions: echarts.EChartsOption = useMemo(
        () => ({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999',
                    },
                },
            },
            legend: {
                type: 'scroll',
                pageIconSize: 10,
                bottom: '0',
                itemWidth: 10,
                itemHeight: 10,
                // itemGap: 20,
            },
            grid: {
                left: '0%',
                right: '0%',
                bottom: '15%',
                width: '100%',
                height: '70%',
                containLabel: true,
            },
            xAxis: [
                {
                    type: 'category',
                    data: xAxis,
                    axisPointer: {
                        type: 'shadow',
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine: {
                        lineStyle: {
                            type: 'dashed',
                        },
                    },
                    // min: 0,
                    // max: 250,
                    // interval: 50,
                    // alignTicks: true,
                    axisLabel: {
                        formatter: '{value} 万',
                    },
                },
            ],
            series: [
                {
                    name: dataSource[0]?.label,
                    type: 'line',
                    color: '#A74FFF',
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text}万 `;
                        },
                    },
                    symbol: 'circle',
                    data: dataSource[0]?.data,
                },
                {
                    name: dataSource[1]?.label,
                    type: 'bar',
                    stack: 'total',
                    barMaxWidth: 50,
                    color: '#0052FF',
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text}万 `;
                        },
                    },
                    data: dataSource[1]?.data,
                },
                {
                    name: dataSource[2]?.label,
                    type: 'bar',
                    stack: 'total',
                    barMaxWidth: 50,
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text}万 `;
                        },
                    },
                    color: '#FFDA08',
                    symbol: 'circle',
                    data: dataSource[2]?.data,
                },
                {
                    name: dataSource[3]?.label,
                    type: 'line',
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text}万 `;
                        },
                    },
                    color: '#20C7FF',
                    symbol: 'circle',
                    data: dataSource[3]?.data,
                },
                {
                    name: dataSource[4]?.label,
                    type: 'line',
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text}万 `;
                        },
                    },
                    color: '#FF9A1E',
                    symbol: 'circle',
                    data: dataSource[4]?.data,
                },
                {
                    name: dataSource[5]?.label,
                    type: 'line',
                    tooltip: {
                        valueFormatter: (value: any) => {
                            const text = Number(value);
                            if (_.isNaN(text)) return '-';
                            return `${text}万 `;
                        },
                    },
                    color: '#FF6161',
                    symbol: 'circle',
                    data: dataSource[5]?.data,
                },
            ],
        }),
        [xAxis, dataSource],
    );
    return (
        <ChartsWrap
            chartsClassName={chartsClassName}
            loading={loading}
            color={color}
            title={title}
            chartsOptions={chartsOptions}
        />
    );
};

export default TrendCharts;
