import React, { useState, useEffect, useContext } from 'react';
import { dfConfs } from '@/configs/dfilters';
import _ from 'lodash';
import { FastFilterStore } from '@/stores/utils';
import { FastFilter } from '@/components/common/fast-filter';
import { DTitle } from '@/pages/sales-follow-up/custom/list/components/dtitle';
import { StoreContext } from '@/stores';
import { Perm } from '@/stores/perm';
import { AuthStore } from '@/stores/auth';

const execAndFlatten = async (list: any, authStore: AuthStore) => {
    if (!_.isArray(list)) {
        return [];
    }
    const ret = [];
    for (const item of list) {
        if (_.isFunction(item)) {
            const maybelist = await item({
                authStore,
            });
            ret.push(maybelist);
        } else {
            ret.push(item);
        }
    }
    return _.filter(_.flattenDeep(ret), item => {
        return (
            !!item &&
            _.isObject(item) &&
            _.isString((item as any).label) &&
            _.isArray((item as any).value) &&
            _.every((item as any).value, condiItem => {
                condiItem = condiItem || {};
                let { condition } = condiItem;
                condition = condition || {};
                const { fieldId, filterValue, operateType } = condition;
                return (
                    !_.isNil(fieldId) &&
                    !_.isNil(filterValue) &&
                    !_.isNil(operateType)
                );
            })
        );
    });
};

const spreadDFilters = async (dfilters: any, authStore: AuthStore) => {
    const keys = _.keys(dfilters);
    const ret: any = {};
    for (const key of keys) {
        const maybeList = dfilters[key];
        let currentRet: any;
        if (_.isArray(maybeList)) {
            currentRet = await execAndFlatten(maybeList, authStore);
        } else {
            currentRet = await spreadDFilters(maybeList, authStore);
        }
        ret[key] = currentRet;
    }
    return ret;
};

const applyPerm2DFilters = async (dfilters: any, permStore: Perm) => {
    const lv4MenuFilters = dfilters.lv4MenuFilters || {};
    const nextLv4MenuFilters: any = {};
    const keys = _.keys(lv4MenuFilters);
    for (const key of keys) {
        const filters = lv4MenuFilters[key];
        if (!_.isArray(filters)) {
            continue;
        }
        nextLv4MenuFilters[key] = _.filter(filters, item => {
            if (!item.key) {
                return true;
            }
            return permStore.getPermByKey(item.key).visible;
        });
    }
    dfilters.lv4MenuFilters = nextLv4MenuFilters;
};

export const dFilterHOC: <T extends { dfilters?: any }>(
    Com: React.FC<T>,
    entityCode: string,
) => React.FC<T> = (Com, entityCode) => {
    return props => {
        const store = useContext(StoreContext);
        const authStore = store.getAuthStore();
        const menuPermInited = authStore.defaultMenuPermStore.inited;

        const [dfilterLoading, setDFilterLoading] = useState(true);
        const [dfilters, setDfilters] = useState<any>({});

        useEffect(() => {
            if (!menuPermInited) {
                return;
            }
            (async () => {
                const dfilters = await spreadDFilters(
                    dfConfs[entityCode],
                    authStore,
                );

                // TODO: 流程暂时不卡权限
                if (entityCode !== 'process') {
                    applyPerm2DFilters(
                        dfilters,
                        authStore.defaultMenuPermStore,
                    );
                }
                console.log('wmx dfilters', dfilters);

                setDfilters(dfilters);
                setDFilterLoading(false);
            })();
        }, [menuPermInited]);

        if (dfilterLoading) {
            return null;
        }

        return <Com {...{ ...props, dfilters }} />;
    };
};

export interface IStandardDFilterStore {
    defaultFastFilters: FastFilterStore;
    pageTitle?: string;
}

export const useDefaultDFilterSnippets = (
    store: IStandardDFilterStore,
    extra?: {
        onFFSelect?: (ffItem: any) => void;
    },
) => {
    const { defaultFastFilters, pageTitle } = store;
    const onFFSelect = extra?.onFFSelect;
    // 快捷筛选
    const dtitleProps: any = {
        title:
            defaultFastFilters.selectedLv4MenuFilters.length === 0
                ? pageTitle || ''
                : defaultFastFilters.selectedLv4MenuFilters[0].label,
    };
    dtitleProps.list = defaultFastFilters.lv4MenuFilters;
    dtitleProps.onListItemClick = (item: any) => {
        defaultFastFilters.setSelectedLv4MenuFilters([item]);
    };
    const title = <DTitle {...dtitleProps} />;

    const {
        fastFilters: fastFiltersList,
        selectedFastFilters,
    } = defaultFastFilters;
    const activeKey =
        selectedFastFilters.length > 0
            ? selectedFastFilters[0].fastFilterKey
            : '';
    const fastFilters = (
        <FastFilter
            list={fastFiltersList}
            activeKey={activeKey}
            onListItemClick={item => {
                const next = [item].filter(Boolean);
                if (next.length > 0) {
                    onFFSelect && onFFSelect(next[0]);
                }
                defaultFastFilters.setSelectedFastFilters(next);
            }}
        />
    );
    return {
        title,
        fastFilters,
    };
};
