import React from 'react';

const delimiter = /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;

export const AutoLink: React.FC<{ text: string }> = ({ text }) => {
    return (
        <>
            {text.split(delimiter).map(word => {
                const match = word.match(delimiter);
                if (match) {
                    const url = match[0];
                    return (
                        <a
                            href={
                                url.startsWith('http') ? url : `http://${url}`
                            }
                            key={url}
                            target="_blank"
                        >
                            {url}
                        </a>
                    );
                }
                return word;
            })}
        </>
    );
};
