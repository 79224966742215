import React, { useEffect } from 'react';
import LeadForm from './form-drawer';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useJobStoreJustForForm } from '../../../utils';
import _ from 'lodash';
import { genCustomerIdParamByCustomerItemAndParamMeta } from '@/pages/sales-follow-up/custom/list/store';
import { getJobStore } from '../../registry';

interface FollowLog {
    followTarget: number;
}

export const CustomerFollowJobCreate: React.FC<{
    trigger: number;
    customers: any[];
    followLog?: FollowLog;
    onSuccess: () => void;
    onClose: () => void;
}> = observer(({ trigger, customers, followLog, onSuccess, onClose }) => {
    const selectedCus = _.isArray(customers) && customers.length > 0;
    const isMultipleFollowCus = customers.length > 1;
    // 声明一个job store，并初始化
    const [prepared, defaultStore, prepareStore] = useJobStoreJustForForm(
        getJobStore('task_activation'),
        'task_activation',
    );
    const { actionType } = defaultStore;

    useEffect(() => {
        if (trigger > 0 && selectedCus) {
            prepareStore();
            runInAction(() => {
                defaultStore.setAction('create');
            });
        }
    }, [trigger, selectedCus]);

    useEffect(() => {
        if (!prepared || actionType !== 'create') {
            return;
        }
        defaultStore.startNewData();
        if (!selectedCus) {
            return;
        }

        if (followLog?.followTarget) {
            defaultStore.mutatingData.follow_target = followLog.followTarget;
        }

        // 将客户信息转换成字段信息（可多选）
        if (isMultipleFollowCus) {
            defaultStore.mutatingData.follow_customer = customers.map(item => {
                return genCustomerIdParamByCustomerItemAndParamMeta(item);
            });
        } else {
            defaultStore.mutatingData.follow_customer = genCustomerIdParamByCustomerItemAndParamMeta(
                customers[0],
            );
        }
    }, [prepared, customers, actionType, isMultipleFollowCus]);

    if (trigger === 0) {
        return null;
    }

    // 根据外部抽屉数据的状态和内部leadstore准备状态
    const preRequiredReady = prepared;
    // return null
    return (
        <LeadForm
            defaultStore={defaultStore}
            preRequiredReady={preRequiredReady}
            onSuccess={onSuccess}
            isMultipleFollowCus={isMultipleFollowCus}
            onClose={onClose}
            customers={customers}
        />
    );
});
