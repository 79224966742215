import React from 'react';
import {
    CustomerListStore,
    genCustomerIdParamByCustomerItemAndParamMeta,
} from '../store';
import _ from 'lodash';
import './m-drop.scss';
import { observer } from 'mobx-react-lite';
import { FinalProcessFormProxy } from '@/pages/sales-follow-up/process/list/form-proxy';

export const ActionDelay: React.FC<{
    customerList: CustomerListStore;
}> = observer(({ customerList: defaultStore }) => {
    const { mutatingData, actionType } = defaultStore;
    if (mutatingData === null) {
        return null;
    }
    return (
        <FinalProcessFormProxy
            visible={actionType === 'delay'}
            onClose={() => {
                defaultStore.resetAction();
            }}
            key={defaultStore.actionType === 'delay' ? 'opened' : 'nooppened'}
            processKey={'customerdelay'}
            lifeCycle={{
                onClose: store => {
                    store.setMutatingData(null);
                    defaultStore.setMutatingData(null);
                    defaultStore.resetAction();
                },
                onOpen: store => {
                    if (!defaultStore.mutatingData) {
                        return;
                    }
                    store.startNewData();
                    _.assign(store.mutatingData, {
                        customer_id: genCustomerIdParamByCustomerItemAndParamMeta(
                            defaultStore.mutatingData,
                        ),
                    });
                },
            }}
            preRequestReady={!!defaultStore.mutatingData}
        />
    );
});
