import React, { useState } from 'react';
import _ from 'lodash';
import './index.scss';
import {
    ICrmUserTrendItemGroupProps,
    CrmUserTrendItemGroup,
} from './user-trend-items';

export interface ICrmTimelineProps {
    groups: ICrmUserTrendItemGroupProps[];
    showCount?: number; // 默认展示多少条
}

export const CrmUserTrend: React.FC<ICrmTimelineProps> = ({
    groups,
    showCount,
}) => {
    const [showAll, setShowAll] = useState(false);
    let showGroups = [...groups];
    if (showCount && !showAll) {
        // 修改为展示真正的条数而非天数（2021.4.6）
        const finalShowGroups: any[] = [];
        let showTempCount = showCount;
        _.forEach(showGroups, group => {
            if (!showTempCount) {
                return;
            }
            if (group.userTrendItems.length <= showTempCount) {
                finalShowGroups.push(group);
                showTempCount = showTempCount - group.userTrendItems.length;
            } else {
                if (!!showTempCount) {
                    const tempGroup = _.cloneDeep(group);
                    tempGroup.userTrendItems = _.take(
                        group.userTrendItems,
                        showTempCount,
                    );
                    finalShowGroups.push(tempGroup);
                    showTempCount = 0;
                }
            }
        });
        showGroups = finalShowGroups;
    }
    let allLength = 0;
    _.forEach(groups, group => {
        allLength = allLength + group.userTrendItems.length;
    });
    return (
        <div className="crm-usertrend">
            {_.map(showGroups, (usertrendGroup, key) => {
                return <CrmUserTrendItemGroup {...usertrendGroup} key={key} />;
            })}
            {showCount && showCount < allLength && (
                <div className="show-more">
                    {showAll ? (
                        <p
                            onClick={() => {
                                setShowAll(false);
                            }}
                        >
                            收起记录
                        </p>
                    ) : (
                        <p
                            onClick={() => {
                                setShowAll(true);
                            }}
                        >
                            查看更多
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};
