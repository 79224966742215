import React, {
    memo,
    useContext,
    useEffect,
    useState,
    useCallback,
    useMemo,
} from 'react';
import {
    DForm,
    isMultipleCell,
    BlockLoading2,
    IProcessCtx,
    applyConfToFormItem,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { applyCustomConfToWidget } from '@/components/common/form/advanced-filters';
import {
    ISelectOptProductOptions,
    ConfirmEquity,
    getProductsTotalPriceByData,
} from '@/components/common/form/widgets/opt/select-opt-product';
import { useAnyProductStore } from '@/pages/om/product/list/store';
import { runInAction } from 'mobx';
import { ContractListStore } from '../store';
import { ProcessCatStore } from '@/pages/sales-follow-up/process/list/store';
import { Alert, message, Icon, Divider, Button } from 'antd';
import { CtxContext } from '@/utils/context';
import { defaultAxios, isNetSuccess, useRefresh } from '@/utils';
import { StoreContext } from '@/stores';
import querystring from 'querystring';
import { CustomerContactsCreate } from '@/components/common/customer-drawer/customer-contacts-create';
import { getValidatorByParamMeta } from '@/stores/utils';
import moment from 'moment';
import { commonQuery, transparentQuery } from '@/api/rest';

const DemoFormProcess: React.FC<{
    defaultStore: ContractListStore;
    processCtx: any | null;
    outerCtxInfo?: any;
}> = ({ defaultStore, processCtx, outerCtxInfo }) => {
    const stores = useContext(StoreContext);
    const authStore = stores.getAuthStore();
    const userId = authStore.userInfo.userId;
    const menuPermMap = authStore.defaultMenuPermStore.permMap;
    const hasOmContractPerm = !!menuPermMap['om-contract-all'];
    const hasBuyContractPerm = !!menuPermMap['buy-contract-all'];
    const hasContractPerm = hasOmContractPerm || hasBuyContractPerm;

    const ctx = useContext(CtxContext);
    const { position, data } = ctx;
    const underCtx = position === 'drawer' || !!outerCtxInfo;
    const cusdata = outerCtxInfo || data;

    const {
        productToConfirmEquity,
        mutatingDataLoading,
        assignProductLoading,
        contractValidators,
        mutatingData,
        defaultMeta,
    } = defaultStore;

    console.log('系统合同', mutatingData);

    const { objectMetaMap } = defaultMeta;

    const [prepared, productStore] = useAnyProductStore({});

    const [createContactsTrigger, setCreateContactsTrigger] = useState(0);
    const [contactsRefreshTrigger, setContactsRefreshTrigger] = useState(0);
    const updateContactsRefreshTrigger = useCallback(() => {
        setContactsRefreshTrigger(contactsRefreshTrigger + 1);
    }, [contactsRefreshTrigger]);
    useRefresh(['process-contacts'], updateContactsRefreshTrigger);

    const [contractCode, setContractCode] = useState<string | null>(null);
    const [contractCodeLoading, setContractCodeLoading] = useState<boolean>(
        false,
    );

    useEffect(() => {
        if (!mutatingData) {
            return;
        }

        if (
            !mutatingData.contract_watermark ||
            mutatingData.contract_watermark.indexOf('YYYY') > -1
        ) {
            setContractCodeLoading(true);
            defaultAxios
                .get('/crm/metadata/contract/contract_watermark')
                .then(([d, e]) => {
                    setContractCodeLoading(false);
                    if (isNetSuccess(d, e) && d?.data) {
                        setContractCode(d?.data);
                    } else {
                        message.error('获取合同码失败，请联系管理员');
                    }
                });
        } else {
            setContractCode(mutatingData.contract_watermark);
        }
    }, [mutatingData]);

    const [invoiceData, setInvoiceData] = useState<any[]>([]);
    useEffect(() => {
        if (!mutatingData?.id) {
            return;
        }
        transparentQuery(
            {
                pageNum: 1,
                pageSize: 100,
                selectiveFields: [
                    'id',
                    'process_instance_id',
                    'applicant_invoice_code',
                ],
                fieldQueries: [
                    {
                        fieldName: 'contract_id',
                        operateType: 'EQUAL',
                        fieldValue: mutatingData?.id,
                    },
                ],
                orderBy: 'create_time',
                isDesc: true,
                dataAuthCode: 'SEARCH-ALL',
            },
            'process_contract_invoice_table',
        ).then(([d, e]) => {
            if (_.isArray(d)) {
                setInvoiceData(d);
            }
        });
    }, [mutatingData?.id]);

    useEffect(() => {
        if (authStore.userInfo?.sysUser === null || mutatingData === null) {
            return;
        }

        // 如果已经有值了，莫覆盖
        if (!!mutatingData?.party_b_contacts?.originalValue) {
            return;
        }

        const sysUserObj = authStore.genSysUserIdItemAndParamMeta();
        const sysUserPhone = authStore.userInfo?.sysUser?.phone || null;
        if (sysUserObj) {
            mutatingData.party_b_contacts = sysUserObj;
        }
        if (sysUserPhone) {
            mutatingData.party_b_contacts_phone = sysUserPhone;
        }
    }, [
        mutatingData,
        mutatingData?.party_b_contacts,
        authStore.userInfo?.sysUser,
    ]);

    useEffect(() => {
        if (!mutatingData || !contractCode) {
            return;
        }
        mutatingData._contract_code = contractCode;
    }, [mutatingData, contractCode]);

    const handleCreatePdf = async () => {
        const result = await defaultStore.createPDF();

        if (Array.isArray(result)) {
            const [res, err] = result;
            const { msg = '', status = '', url = '' } = res?.data || {};
            if (status === 'done') {
                const data = res?.data;
                mutatingData.contract_attachments = JSON.stringify([
                    {
                        ...data,
                        // uid: 'rc-upload-1689249300106-4',
                        // response: 'Server ok',
                    },
                ]);

                message.success('系统生成合同附件成功');
            } else {
                if (msg) {
                    message.error(msg);
                } else {
                    message.error('系统生成合同附件失败');
                }
            }
        }
    };

    //是否系统创建合同
    const isStandard =
        (mutatingData?.contract_type === 1 ||
            mutatingData?.contract_type === 3) &&
        mutatingData?.contract_template_type === 1 &&
        [2, 16].includes(mutatingData?.party_b_company);

    const contractAttachments = useMemo(() => {
        let urlData: any = null;
        try {
            urlData = JSON.parse(mutatingData?.contract_attachments)?.[0];
        } catch (error) {}
        return urlData;
    }, [mutatingData?.contract_attachments]);

    if (!prepared) {
        return null;
    }

    if (processCtx === null) {
        return <Alert message={'缺少processCtx'}></Alert>;
    }

    const isCreate = !!processCtx?.definitionId;

    const visible = productToConfirmEquity !== null;

    const cusId = mutatingData?.customer_id?.originalValue;
    const cusName = mutatingData?.customer_id?.relatedObj?.customer_name;
    const contractType = mutatingData?.contract_type;
    const contractProductChangeState =
        mutatingData?.contract_product_change_state;
    const isNotKuangjiaHetong = !!contractType && contractType !== 2;

    const currentTaskUser =
        defaultMeta?.processAllInfo?.crmProcessInstance?.currentTaskUser;
    const activityId = defaultMeta?.processAllInfo?.crmActivity?.activityId;
    const isMySubmitNode =
        'contract_apply' !== activityId && userId === currentTaskUser;
    const isCompleted =
        'COMPLETED' ===
        defaultMeta?.processAllInfo?.crmProcessInstance?.processApproveStatus;
    const optBizType = mutatingData?.opportunity_id?.relatedObj?.biz_type;

    const form = (
        <>
            <CustomerContactsCreate
                customerInfo={{
                    id: cusId,
                    customer_name: cusName,
                }}
                trigger={createContactsTrigger}
            />
            <ConfirmEquity
                visible={visible}
                onCancel={() => defaultStore.resetProductToConfirmEquity()}
                onOk={() => defaultStore.resetProductToConfirmEquity()}
                onChange={nextEquities => {
                    defaultStore.setNextParsedEquities(nextEquities);
                }}
                productToConfirmEquity={productToConfirmEquity}
            />
            {(isMySubmitNode || isCompleted) &&
                contractProductChangeState === 1 && (
                    <Alert
                        style={{ marginTop: 10 }}
                        type="warning"
                        showIcon
                        message={
                            <span
                                style={{
                                    color: 'red',
                                    fontWeight: 'bolder',
                                }}
                            >
                                合同产品与已开通产品不一致，审批前请务必与申请人确认此情况！
                            </span>
                        }
                    ></Alert>
                )}
            {(isMySubmitNode || isCompleted) &&
                contractProductChangeState === 2 && (
                    <Alert
                        style={{ marginTop: 10 }}
                        type="warning"
                        showIcon
                        message={
                            <span
                                style={{
                                    color: 'red',
                                    fontWeight: 'bolder',
                                }}
                            >
                                合同产品与已开通产品不一致，产品开通后发生跨月调整，需要与效能产品及财务进行确认，否则将影响财务历史数据
                            </span>
                        }
                    ></Alert>
                )}
            {mutatingDataLoading ? (
                <BlockLoading2 />
            ) : (
                <DForm
                    defaultStore={defaultStore}
                    options={{
                        grouped: true,
                    }}
                    validatorsGenerator={() => {
                        return contractValidators;
                    }}
                    validatorsTransformer={validators => {
                        objectMetaMap['relation_contract_id'].isRequired = true;
                        validators.relation_contract_id = getValidatorByParamMeta(
                            objectMetaMap['relation_contract_id'],
                        );

                        objectMetaMap['contract_sign_type'].isRequired = true;
                        validators.contract_sign_type = getValidatorByParamMeta(
                            objectMetaMap['contract_sign_type'],
                        );

                        if (isNotKuangjiaHetong) {
                            objectMetaMap[
                                'prediction_pay_back_time'
                            ].isRequired = true;
                        } else {
                            objectMetaMap[
                                'prediction_pay_back_time'
                            ].isRequired = false;
                        }
                        validators.relation_contract_id = getValidatorByParamMeta(
                            objectMetaMap['prediction_pay_back_time'],
                        );

                        return validators;
                    }}
                    labelsTransformer={labels => {
                        for (const label of labels) {
                            if (label.key === 'relation_contract_id') {
                                label.required = true;
                            }

                            if (label.key === 'contract_sign_type') {
                                label.required = true;
                            }

                            if (label.key === 'prediction_pay_back_time') {
                                if (isNotKuangjiaHetong) {
                                    label.required = true;
                                } else {
                                    label.required = false;
                                }
                            }
                        }
                        return labels;
                    }}
                    processCtx={processCtx}
                    propsTransformer={props => {
                        const { collapseLayout } = props;
                        if (props.collapseProps && !isCreate) {
                            props.collapseProps.defaultActiveKey = _.isArray(
                                props.collapseProps.defaultActiveKey,
                            )
                                ? [props.collapseProps.defaultActiveKey[0]]
                                : [];
                        }
                        props.validatorRelationship = {
                            start_time: ['end_time'],
                        };
                        if (!_.isUndefined(collapseLayout)) {
                            for (const group of collapseLayout) {
                                const { groupKey } = group;
                                if (
                                    [
                                        '18_base',
                                        '18_opportunity',
                                        '18_other',
                                        '18_expressage',
                                    ].indexOf(groupKey) > -1
                                ) {
                                    group.layout = [
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                    ];
                                }
                                if ('18_pay' === groupKey) {
                                    group.layout = [
                                        [24],
                                        [24],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                        [12, 12],
                                    ];
                                }
                            }
                        }
                        return props;
                    }}
                    onChange={(key, value, k) => {
                        if (key === 'opportunity_id') {
                            if (value?.relatedObj?.biz_type !== optBizType) {
                                mutatingData.product = null;
                            }
                            defaultStore.askAutoFillingDataByOpt(value);
                        }
                        if (key === 'product') {
                            defaultStore.setMutatingDataByKey(
                                'contract_amount',
                                getProductsTotalPriceByData(value),
                            );
                        }
                        if (key === 'customer_contacts_id') {
                            const contactsId = value?.originalValue;
                            if (contactsId) {
                                defaultStore.fillContactsInfo(contactsId);
                            }
                        }
                        if (key === 'receiver_contacts_id') {
                            const contactsId = value?.originalValue;
                            if (contactsId) {
                                defaultStore.fillReceiverContactsInfo(
                                    contactsId,
                                );
                            }
                        }
                        if (key === 'start_time') {
                            mutatingData.end_time = moment(value)
                                .add(1, 'year')
                                .valueOf();
                        }
                        if (key === 'party_b_contacts') {
                            mutatingData.party_b_contacts_phone =
                                value?.relatedObj?.phone;
                        }
                    }}
                    onInteract={(key, type, value) => {
                        if (key === 'product' && type === 'confirm-equity') {
                            runInAction(() => {
                                defaultStore.setProductToConfirmEquity(value);
                            });
                        }
                    }}
                    widgetsTransformer={baiscWidgets => {
                        applyConfToFormItem(baiscWidgets, 'inputSecret', {
                            sceneCode: 'crm_contract',
                        });
                        applyCustomConfToWidget(
                            baiscWidgets,
                            'pay_back_time',
                            prevWidgets => {
                                const widget = _.cloneDeep(prevWidgets);
                                widget.nextlineWidget = () => {
                                    if (invoiceData.length === 0) {
                                        return null;
                                    }
                                    return (
                                        <div
                                            style={{
                                                marginLeft: -70,
                                            }}
                                        >
                                            发票申请：
                                            {invoiceData.map((item, index) => {
                                                return (
                                                    <>
                                                        {index !== 0 && (
                                                            <Divider type="vertical" />
                                                        )}
                                                        <a
                                                            target="_blank"
                                                            href={`/buy/process-management/group?id=${item.process_instance_id}&target=contract_process`}
                                                        >
                                                            {
                                                                item.applicant_invoice_code
                                                            }
                                                        </a>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    );
                                };
                                return widget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'relation_contract_id',
                            prevWidgets => {
                                const widget = _.cloneDeep(prevWidgets);
                                widget.status = {
                                    ...widget.status,
                                    hidden: contractType !== 3,
                                    disabled: !cusId,
                                };
                                if (widget.objectOptions) {
                                    widget.objectOptions.defaultFilters = cusId
                                        ? [
                                              {
                                                  fieldName: 'contract_type',
                                                  operateType: 'IN',
                                                  fieldValue: '2',
                                              },
                                              {
                                                  fieldName: 'customer_id',
                                                  operateType: 'EQUAL',
                                                  fieldValue: cusId,
                                              },
                                          ]
                                        : [
                                              {
                                                  fieldName: 'contract_type',
                                                  operateType: 'IN',
                                                  fieldValue: '2',
                                              },
                                          ];
                                }
                                widget.nextlineWidget = () => {
                                    const rcontractId =
                                        mutatingData?.relation_contract_id
                                            ?.originalValue;
                                    if (!rcontractId) {
                                        return null;
                                    }
                                    return (
                                        <div style={{ paddingTop: 4 }}>
                                            <a
                                                target="_blank"
                                                href={`/buy/contract/all?id=${rcontractId}&target=contract-form`}
                                            >
                                                查看框架合同
                                                <Icon type="link" />
                                            </a>
                                        </div>
                                    );
                                };
                                return widget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'opportunity_id',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                if (nextWidget.objectOptions) {
                                    nextWidget.objectOptions.paramsBlackList = {
                                        customer_id: true,
                                    };
                                    nextWidget.objectOptions.dataAuthCode =
                                        'SEARCH-PRIVATE';
                                    nextWidget.objectOptions.appendFields = [
                                        'biz_type',
                                    ];
                                    if (underCtx && cusdata?.cid) {
                                        nextWidget.objectOptions.defaultFilters = [
                                            {
                                                fieldName: 'customer_id',
                                                operateType: 'EQUAL',
                                                fieldValue: cusdata.cid,
                                            },
                                        ];
                                    }
                                }

                                nextWidget.nextlineWidget = () => {
                                    const optId =
                                        mutatingData?.opportunity_id
                                            ?.originalValue;
                                    if (!optId) {
                                        return null;
                                    }
                                    return (
                                        <div style={{ paddingTop: 4 }}>
                                            <a
                                                target="_blank"
                                                href={`/buy/opportunity/all?id=${optId}&target=contract-form`}
                                            >
                                                查看商机
                                                <Icon type="link" />
                                            </a>
                                        </div>
                                    );
                                };

                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'customer_contacts_id',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                if (nextWidget.objectOptions) {
                                    if (underCtx && cusdata?.cid) {
                                        nextWidget.objectOptions.defaultFilters = [
                                            {
                                                fieldName: 'customer_id',
                                                operateType: 'EQUAL',
                                                fieldValue: cusdata.cid,
                                            },
                                        ];
                                        nextWidget.objectOptions.reloadTrigger =
                                            contactsRefreshTrigger +
                                            ':' +
                                            cusdata.cid;
                                    }
                                }
                                nextWidget.nextlineWidget = () => {
                                    if (cusId && cusName) {
                                        return (
                                            <div
                                                onClick={() =>
                                                    setCreateContactsTrigger(
                                                        1 +
                                                            createContactsTrigger,
                                                    )
                                                }
                                            >
                                                <a>
                                                    新建联系人
                                                    <Icon type="user" />
                                                </a>
                                            </div>
                                        );
                                    }
                                    return null;
                                };
                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'receiver_contacts_id',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                if (nextWidget.objectOptions) {
                                    if (underCtx && cusdata?.cid) {
                                        nextWidget.objectOptions.defaultFilters = [
                                            {
                                                fieldName: 'customer_id',
                                                operateType: 'EQUAL',
                                                fieldValue: cusdata.cid,
                                            },
                                        ];
                                    }
                                }
                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'contract_attachments',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                nextWidget.widgetKey = 'upload';
                                nextWidget.uploadOptions = {
                                    multiple: true,
                                    useWaterMark: true,
                                    // waterMarkStr: contractCode,
                                    fileExtWhitelist: ['pdf'],
                                    lint:
                                        '附件要求:20M以内,支持文件格式为pdf。',
                                    handlePreview: isStandard,
                                };

                                const badCode =
                                    !contractCodeLoading && !contractCode;
                                if (nextWidget.status) {
                                    nextWidget.status.loading = contractCodeLoading;

                                    // 如果在前面disable已经是true了，这边就不允许改了
                                    if (!nextWidget.status.disabled) {
                                        nextWidget.status.disabled = badCode;
                                    }
                                }

                                const lintSnippet = (
                                    <Alert
                                        style={{
                                            marginBottom: 8,
                                        }}
                                        type="warning"
                                        showIcon
                                        message={
                                            <span style={{ color: 'red' }}>
                                                请上传附件：合同清洁版pdf
                                            </span>
                                        }
                                    />
                                );

                                if (Boolean(isStandard)) {
                                    nextWidget.nextlineWidget = () => {
                                        return (
                                            <>
                                                <div>
                                                    <Button
                                                        onClick={() => {
                                                            handleCreatePdf?.();
                                                        }}
                                                    >
                                                        系统生成合同附件
                                                    </Button>
                                                </div>
                                                {contractAttachments?.url &&
                                                    contractAttachments?.type ===
                                                        '标准合同' && (
                                                        <div>
                                                            <Button
                                                                type="link"
                                                                onClick={() =>
                                                                    window.open(
                                                                        contractAttachments.url,
                                                                        '_blank',
                                                                    )
                                                                }
                                                            >
                                                                查看合同附件
                                                            </Button>
                                                        </div>
                                                    )}
                                                <Alert
                                                    style={{
                                                        marginBottom: 8,
                                                    }}
                                                    type="warning"
                                                    showIcon
                                                    message={
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            若系统自动生成的合同不符合实际情况，可将【合同模版类型】选择【非标-有修改】后，手动上传合同附件。
                                                        </span>
                                                    }
                                                />
                                            </>
                                        );
                                    };
                                } else {
                                    if (badCode) {
                                        nextWidget.nextlineWidget = () => {
                                            return (
                                                <>
                                                    {lintSnippet}
                                                    <Alert
                                                        type="error"
                                                        message="获取合同码失败，请联系管理员"
                                                    />
                                                </>
                                            );
                                        };
                                    } else {
                                        nextWidget.nextlineWidget = () => {
                                            return <>{lintSnippet}</>;
                                        };
                                    }
                                }

                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'contract_other_attachments',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                nextWidget.widgetKey = 'upload';
                                nextWidget.uploadOptions = {
                                    multiple: true,
                                    useWaterMark: true,
                                    waterMarkStr: contractCode,
                                    lint: '附件要求:20M以内',
                                };

                                const badCode =
                                    !contractCodeLoading && !contractCode;
                                if (nextWidget.status) {
                                    nextWidget.status.loading = contractCodeLoading;

                                    // 如果在前面disable已经是true了，这边就不允许改了
                                    if (!nextWidget.status.disabled) {
                                        nextWidget.status.disabled = badCode;
                                    }
                                }

                                const lintSnippet = (
                                    <Alert
                                        style={{
                                            marginBottom: 8,
                                        }}
                                        type="warning"
                                        showIcon
                                        message={
                                            <span style={{ color: 'red' }}>
                                                请上传附件：客户营业执照、人力资源许可证（猎头客户）、如有特批邮件上传截图。
                                            </span>
                                        }
                                    />
                                );

                                if (badCode) {
                                    nextWidget.nextlineWidget = () => {
                                        return (
                                            <>
                                                {lintSnippet}
                                                <Alert
                                                    type="error"
                                                    message="获取合同码失败，请联系管理员"
                                                />
                                            </>
                                        );
                                    };
                                } else {
                                    nextWidget.nextlineWidget = () => {
                                        return <>{lintSnippet}</>;
                                    };
                                }

                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'contract_sign_type',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                nextWidget.status = {
                                    ...nextWidget,
                                    hidden:
                                        mutatingData?.is_need_seal === 1
                                            ? false
                                            : true,
                                };
                                return nextWidget;
                            },
                        );

                        // product
                        applyCustomConfToWidget(
                            baiscWidgets,
                            'product',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                if (isMultipleCell(nextWidget)) {
                                    return nextWidget;
                                }
                                nextWidget.widgetKey = 'selectOptProducts';
                                nextWidget.status = nextWidget.status || {};
                                nextWidget.status.loading = assignProductLoading;
                                nextWidget.status.disabled =
                                    !!nextWidget.status.disabled || !optBizType;

                                const nextOptions: ISelectOptProductOptions = {
                                    inputProps: {
                                        placeholder: '输入数值',
                                    },
                                    objectOptions: {
                                        constraint:
                                            productStore.defaultRelatedContrait,
                                        placeholder: '搜索产品',
                                        listMeta: defaultStore.defaultMeta,
                                        defaultFilters: [
                                            {
                                                fieldName: 'biz_type',
                                                operateType: 'EQUAL',
                                                fieldValue: optBizType,
                                            },
                                        ],
                                    },
                                    checkDeleteProduct: async (datum: any) => {
                                        const id =
                                            datum?.relatedObj?._product_id;
                                        if (!id) {
                                            return true;
                                        }
                                        const [
                                            d,
                                            e,
                                        ] = await defaultAxios.get(
                                            '/crm/contract/check/del/product',
                                            { contractProductId: id },
                                        );
                                        if (!isNetSuccess(d, e)) {
                                            return false;
                                        }
                                        return d?.data;
                                    },
                                };
                                nextWidget.selectOptProductsOptions = nextOptions;
                                nextWidget.prevlineWidget = () => {
                                    return (
                                        <>
                                            <Alert
                                                style={{
                                                    width: 900,
                                                    position: 'relative',
                                                    left: -80,
                                                    marginBottom: 8,
                                                }}
                                                type="warning"
                                                showIcon
                                                message="请注意核对，产品信息中「权益」数量，与合同内权益保持一致"
                                            />
                                            <Alert
                                                style={{
                                                    width: 900,
                                                    position: 'relative',
                                                    left: -80,
                                                    marginBottom: 8,
                                                }}
                                                type="warning"
                                                showIcon
                                                message={
                                                    <span>
                                                        人才银行-续约，直接选择1个套餐产品，然后修改权益值。
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            千万不要再加一个【人才银行的增购产品】
                                                        </span>
                                                    </span>
                                                }
                                            />
                                            <Alert
                                                style={{
                                                    width: 900,
                                                    position: 'relative',
                                                    left: -80,
                                                    marginBottom: 8,
                                                }}
                                                type="warning"
                                                showIcon
                                                message="未付费的基础门户版企业号，购买类型选赠送"
                                            />
                                        </>
                                    );
                                };
                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'start_time',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                nextWidget.nextlineWidget = () => {
                                    return (
                                        <Alert
                                            type="warning"
                                            showIcon
                                            message={
                                                <span style={{ color: 'red' }}>
                                                    请注意核对，保证系统内的开始与截止日期需与合同附件日期保持一致
                                                </span>
                                            }
                                        />
                                    );
                                };
                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'party_b_company',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                if (nextWidget.selectOptions) {
                                    const options =
                                        nextWidget.selectOptions.options;
                                    const nextOptions = options?.filter(
                                        (item: any) => item?.item?.choice,
                                    );
                                    nextWidget.selectOptions.options = nextOptions;
                                }

                                if (isStandard) {
                                    nextWidget.nextlineWidget = () => {
                                        return (
                                            <Alert
                                                type="warning"
                                                showIcon
                                                message={
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        <div>
                                                            【单笔/采购+标准-无修改】的合同将由系统自动生成，合同期限默认12个月，且需提前填写发票信息。
                                                        </div>
                                                        <div>
                                                            合同期限非12个月或英文合同，请选择【非标-有修改】类型。
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        );
                                    };
                                }
                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'contract_code',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                nextWidget.nextlineWidget = () => {
                                    // 新建或者没有权限
                                    if (!mutatingData.id || !hasContractPerm) {
                                        return null;
                                    }
                                    return (
                                        <div style={{ paddingTop: 4 }}>
                                            <a
                                                onClick={() => {
                                                    const contractId =
                                                        mutatingData.id;
                                                    if (contractId) {
                                                        // 根据权限判断能跳到哪里
                                                        if (hasOmContractPerm) {
                                                            window.open(
                                                                '/om/contract/all?' +
                                                                    querystring.stringify(
                                                                        {
                                                                            id: contractId,
                                                                            target:
                                                                                'contract_process',
                                                                        },
                                                                    ),
                                                                '__blank',
                                                            );
                                                            return;
                                                        }
                                                        if (
                                                            hasBuyContractPerm
                                                        ) {
                                                            window.open(
                                                                '/buy/contract/all?' +
                                                                    querystring.stringify(
                                                                        {
                                                                            id: contractId,
                                                                            target:
                                                                                'contract_process',
                                                                        },
                                                                    ),
                                                                '__blank',
                                                            );
                                                            return;
                                                        }
                                                    } else {
                                                        message.error(
                                                            '合同id不存在',
                                                        );
                                                    }
                                                }}
                                            >
                                                查看合同
                                                <Icon type="link" />
                                            </a>
                                        </div>
                                    );
                                };
                                return nextWidget;
                            },
                        );

                        return baiscWidgets;
                    }}
                />
            )}
        </>
    );
    return form;
};

export const ContractFormProcess = memo(observer(DemoFormProcess));
