import Input, { InputProps } from 'antd/lib/input';
import React, { useEffect } from 'react';
import { getCommonStatus, ICommonProps } from './common';
import _ from 'lodash';
import { loadingWrapper } from '@/components/common/block-loading';

export type InputValueType = string;
export interface IInputOptions extends InputProps {}

export interface IWidgetInputProps<T = any> extends ICommonProps<T> {
    value: InputValueType;
    options: IInputOptions;
    onChange: (val: InputValueType) => void;
}

export const WidgetInput: React.FC<IWidgetInputProps> = ({
    value,
    options,
    onChange,
    status,
    onInteract = key => void 0,
    data,
    statusExtraData,
}) => {
    const { loading, disabled } = getCommonStatus(
        status,
        data,
        statusExtraData,
    );
    const wrapper = loadingWrapper(loading || false);

    if (disabled) {
        options.disabled = true;
    } else {
        options.disabled = false;
    }

    return wrapper(
        <Input
            {...options}
            value={value}
            onFocus={e => {
                onInteract('focus');
                onInteract('remove-validate-status');
            }}
            onBlur={e => {
                onInteract('blur');
                onInteract('validate-instantly');
            }}
            onChange={e => {
                if (e.target && !_.isNil(e.target.value)) {
                    onChange(e.target.value);
                }
            }}
        />,
    );
};
