import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import TableCheckBox from '../../../components/common/table-checkbox/index';
const CrmTableCheckbox: React.FC<{}> = props => {
    return (
        <div>
            <TableCheckBox<{
                title: string;
                isBase: boolean;
                isOther: boolean;
            }>
                tableCheckBoxColumns={[
                    {
                        isText: true,
                        dataIndex: 'title',
                    },
                    {
                        isText: false,
                        dataIndex: 'isBase',
                        title: '基础数据',
                        titleChecked: false,
                        titleIndeterminate: true,
                    },
                    {
                        isText: false,
                        dataIndex: 'isOther',
                        title: '其他数据',
                        titleChecked: false,
                        titleIndeterminate: true,
                    },
                ]}
                defaultCheckBoxValue={[
                    {
                        title: '测试行1',
                        isBase: false,
                        isOther: false,
                    },
                    {
                        title: '测试行2',
                        isBase: false,
                        isOther: true,
                    },
                    {
                        title: '测试行3',
                        isBase: false,
                        isOther: false,
                    },
                    {
                        title: '测试行4',
                        isBase: true,
                        isOther: false,
                    },
                    {
                        title: '测试行5',
                        isBase: false,
                        isOther: true,
                    },
                    {
                        title: '测试行6',
                        isBase: true,
                        isOther: false,
                    },
                ]}
            />
        </div>
    );
};

export default CrmTableCheckbox;
