import React from 'react';
import { DTable, isMultipleCell, ICell } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { OptProductListStore } from '@/pages/sales-follow-up/opt/opt-product/store';
import { runInAction } from 'mobx';
import { applyCustomConfToCellsSelector } from '@/components/common/form/advanced-filters';
import { tryRefresh } from '@/utils';

const DemoTable: React.FC<{
    defaultStore: OptProductListStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const dataTable = (
        <>
            <DTable
                onColumnsUpdated={() => tryRefresh(['opt'])}
                defaultStore={defaultStore}
                sceneCode={'crm_opt'}
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [];
                    props.onAction = (key, data) => {
                        if (key === 'see-equity-detail') {
                            // 其他动作
                            runInAction(() => {
                                defaultStore.setAction('see-equity-detail');
                                defaultStore.setMutatingData(data);
                            });
                        }
                    };
                    props.adjustHeight = false;
                    props.closeRowSelection = true;
                    return props;
                }}
                cellsSelectorTransformer={baiscCellsSelector => {
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'equity_detail',
                        () => {
                            const nextCellSelector: ICell<any> = {
                                colKey: 'equity_detail',
                                inputs: ['equity_detail'],
                                cell: 'EquityDetail',
                            };
                            return nextCellSelector;
                        },
                    );

                    return baiscCellsSelector;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
