import React, { useState } from 'react';

import { ContactListStore } from '../store';
import { Modal, Form, message } from 'antd';
import _ from 'lodash';
import {
    WidgetCommonObject,
    WidgetSelect,
    WidgetRadio,
} from '@/components/common/form/widgets';
import './merge.scss';
import { runInAction } from 'mobx';
import TextArea from 'antd/lib/input/TextArea';
import { observer } from 'mobx-react-lite';
import { MOpNameDisplayContainer } from '@/components';
import { tryRefresh } from '@/utils';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const ActionMerge: React.FC<{
    defaultStore: ContactListStore;
}> = observer(({ defaultStore }) => {
    const { defaultMSelect } = defaultStore;
    const { mSeletedData } = defaultMSelect;
    const [masterId, setMasterId] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const resetForm = () => {
        setMasterId(null);
    };

    if (mSeletedData.length < 2) {
        return null;
    }
    const visible = defaultStore.actionType === 'merge';

    return (
        <Modal
            visible={visible}
            title={'联系人合并'}
            onCancel={() => {
                defaultStore.resetAction();
                resetForm();
            }}
            confirmLoading={loading}
            onOk={async () => {
                if (masterId === null) {
                    return message.error('主联系人必选');
                }
                setLoading(true);
                const ok = await defaultStore.merge(masterId);
                setLoading(false);
                if (ok) {
                    resetForm();
                    runInAction(() => {
                        defaultStore.resetAction();
                        defaultMSelect.removeAllSelectedData();
                        tryRefresh(['contacts']);
                    });
                }
            }}
        >
            <p>
                合并联系人<b>{mSeletedData.length}个</b>：
                <MOpNameDisplayContainer
                    defaultStore={defaultStore}
                    nameParam="contacts_name"
                />
            </p>
            <div className="customer-m-transform">
                <div className="form-wrapper">
                    <Form className="form" layout={'horizontal'}>
                        <Form.Item
                            {...formItemLayout}
                            required
                            label={<span className="label">主联系人</span>}
                        >
                            <WidgetRadio
                                value={masterId}
                                options={{
                                    options: mSeletedData.map(item => {
                                        return {
                                            label: item.contacts_name,
                                            value: item.id,
                                        };
                                    }),
                                }}
                                onChange={next => {
                                    setMasterId(next);
                                }}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    );
});
