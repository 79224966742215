import React from 'react';
import { makeAutoObservable, toJS } from 'mobx';
import { createDefaultInstanceGetter } from '../utils/index';
import { defaultAxios } from '@/utils';
import _ from 'lodash';
import moment from 'moment';
import { Modal, Alert } from 'antd';
export interface INeedDealtListData {
    entityCode: string; // 实体code
    toUser: number; // 收信人
    fromUser: {
        fromUser: number;
        userAvatar: string;
        userName: string;
    }; // 发起人
    messageType: number; // 1代办 2通知
    sendTime: string; // 待办事项发起时间
    isRead: number; // 0未读，1已读
    message: string; // 消息实体
    bizId: number | string; // 跳转业务的id
    bizUrl: string; // 跳转业务的url（前端似乎无用）
    id: number | string; // 待办事项id
    pcUrl: string[]; // pc-url-list
}
export interface INoticeListData {
    noticeType: 'normal' | 'attention';
    noticeDetail: string;
    id: number | string;
}
export interface INoticeInfoData {
    total: string | number;
    upcoming: string | number;
    notice: string | number;
    heed: string | number;
    opStatus: boolean;
}
export type TGetNoticeDataReturnType = NetResponse<{
    data: INeedDealtListData[];
    msg: string;
    code: number;
}>;
export class NoticeStore {
    constructor() {
        makeAutoObservable(this);
    }
    public setReadableLoading: boolean = false;
    public noticeDataFetchLoading: boolean = false;
    public noticeCount: number = 0;
    get hasMoreNotice() {
        return this.noticeCount > this.noticeData.length;
    }
    public noticeData: INeedDealtListData[] = [];
    public noticeInfo: INoticeInfoData = {
        total: 0,
        upcoming: 0,
        notice: 0,
        heed: 0,
        opStatus: false,
    };
    public initNoticeData = () => {
        this.noticeData = [];
    };
    public fetchNoticeNumberInfo = async () => {
        const url = `/bff/api/rest/notice/info?ts=${moment().valueOf()}`;
        const [d, e] = await defaultAxios.get(url);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            // tslint:disable-next-line:no-unnecessary-initializer
            let modalRef: any = undefined;
            if (data.opStatus) {
                // 直接弹大窗
                modalRef = Modal.info({
                    width: '600px',
                    className: 'global-modal',
                    maskClosable: false,
                    cancelButtonProps: { style: { display: 'none' } },
                    okButtonProps: { style: { display: 'none' } },
                    content: (
                        <Alert
                            message="系统正在维护，请关注飞书群内开放使用通知，或等待该弹窗自行消失"
                            type="warning"
                        />
                    ),
                });
            } else {
                if (modalRef && data.opStatus === false) {
                    modalRef.destroy();
                }
            }
            this.noticeInfo = data || {
                total: null,
                upcoming: null,
                notice: null,
                opStatus: false,
            };
        }
        return [data, error];
    };
    public fetchNoticeData: (
        type: string,
        isShowNoRead: boolean,
        pageNumObj: any,
    ) => Promise<TGetNoticeDataReturnType> = async (
        type,
        isShowNoRead,
        pageNumObj,
    ) => {
        this.noticeDataFetchLoading = true;
        const url = '/bff/api/rest/notice/list';
        const [d, e] = await defaultAxios.get(url, {
            type,
            isShowNoRead,
            pageSize: 20,
            pageNum: pageNumObj.pageNum,
        });
        this.noticeDataFetchLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data && data?.data) {
            this.noticeData = this.noticeData.concat(data?.data || []);
            this.noticeCount = data.totalSize;
        }
        return [data, error];
    };
    public setNoticeReadable = async (
        messageType?: string,
        feedId?: string | number,
    ) => {
        this.setReadableLoading = true;
        const url = '/bff/api/rest/notice/set-readable';
        const [d, e] = await defaultAxios.post(url, {
            messageType,
            feedId,
        });
        if (!messageType) {
            const existingNotice: any = _.find(this.noticeData, data => {
                if (data.id == feedId) {
                    return true;
                }
            });
            if (existingNotice) {
                existingNotice.isRead = 1;
                this.noticeData = [...this.noticeData];
            }
        } else {
            _.map(this.noticeData, data => {
                return {
                    ...data,
                    isRead: 1,
                };
            });
            this.noticeData = [...this.noticeData];
        }
        this.setReadableLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
}

export const getDefaultNoticeStore = createDefaultInstanceGetter(NoticeStore);
