import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { CrmTimeline } from '@/components';
import { Icon, Alert } from 'antd';
import UserSelector, {
    IUserSelectorProps,
} from '../../../components/common/user-selector/index';

const UserSelectorDemo: React.FC<RouteComponentProps> = props => {
    const exampleUserConfig: IUserSelectorProps = {
        userDocs: [
            {
                name: '李陈',
                id: '234252342',
                userId: 'lichen',
                avatar:
                    'https://secure.gravatar.com/avatar/5a13b21c71d1c08e38c0d51e05a28d7c?s=80&d=identicon',
            },
            {
                name: '邵明全',
                id: '1241424131',
                userId: 'shaomingquan',
                avatar:
                    'https://secure.gravatar.com/avatar/5a13b21c71d1c08e38c0d51e05a28d7c?s=80&d=identicon',
            },
            {
                name: '张廷书',
                id: '221421311',
                userId: 'zhangtingshu',
                avatar:
                    'https://secure.gravatar.com/avatar/5a13b21c71d1c08e38c0d51e05a28d7c?s=80&d=identicon',
            },
        ],
        mode: 'multiple',
        defaultValue: ['221421311'],
    };
    return (
        <div>
            <UserSelector {...exampleUserConfig} />
        </div>
    );
};

export default UserSelectorDemo;
