import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import './index.scss';

const StatusWrapper: React.FC<RouteComponentProps> = ({ children }) => {
    return <div className="crm-status-content">{children}</div>;
};

export default StatusWrapper;
export * from './404';
export * from './403';
export * from './500';
