import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Button, Icon, Collapse, Alert } from 'antd';
import './index.scss';
import { IRouteLayout } from '@/configs/route';
import _ from 'lodash';
import DsMenu from './dsmenu';
import TopMenu from './topMenu';
import { IPermMap } from '@/stores/perm';
import { Observer } from 'mobx-react-lite';
import { makeObservable, observable } from 'mobx';
import AudioCom from '../../common/audio/index';
import { voyagerTrack } from '@shared/voyager';
import { StoreContext } from '@/stores';

export const outDateStore = makeObservable(
    { isOutDate: false },
    { isOutDate: observable },
);

const Dashboard: React.FC<RouteComponentProps & {
    routeLayout: IRouteLayout;
    menuPerm: IPermMap;
}> = ({ children, routeLayout, menuPerm, location, navigate }) => {
    const isTest = window.location.hostname !== 'crm.taoum.cn';

    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [topSelectedKeys, setTopSelectedKeys] = useState<string>('');
    const [childRouter, setChildRouter] = useState<IRouteLayout>([]);

    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();

    const { pathname = '/' } = location || {};
    const isHomeNewPage = false; // 注意，不能有其他带home的页面了 // 暂时关闭头部banner
    const isHomeNewPageForHeaderPadding =
        window.location.pathname.indexOf('home-new') > -1;
    const bannerImg = `${process.env.PUBLIC_URL}/imgs/menus/banner1.png`;
    useEffect(() => {
        if (pathname === '/') {
            navigate!('/home');
        }
    }, []);

    useEffect(() => {
        voyagerTrack('crm_page_view', {
            path: pathname,
            userId: authStore?.userInfo?.userId,
            appEnv: process.env.REACT_APP_ENV,
        });
    }, [location]);

    useLayoutEffect(() => {
        let nextSelectedKeys = '';
        for (const route of routeLayout) {
            // // 特殊情况-四层路由（配置中心）
            // if (pathname.split('/')[1] === 'setting') {
            //     // 第一层
            //     if (route.children && pathname.split('/').length > 2) {
            //         // 第二层
            //         for (const childRoute of route.children) {
            //             if (pathname.split('/')[1].indexOf(childRoute.key) !== -1) {
            //                 nextSelectedKeys = childRoute.key;
            //                 // 第三层
            //                 if(childRoute.children && childRoute.children.length > 0) {
            //                     setChildRouter(childRoute.children);
            //                 } else {
            //                     setChildRouter([]);
            //                 }
            //                 break;
            //             }
            //         }
            //     } else {
            //         // 选中最顶层路由
            //         // 不可能出现这种情况
            //     }
            // }
            // 第一层路由
            if (pathname.split('/')[1].indexOf(route.key) !== -1) {
                nextSelectedKeys = route.key;
                // 非顶层路由
                if (route.children && route.children.length > 0) {
                    setChildRouter(route.children);
                } else {
                    setChildRouter([]);
                }
                break;
            }
        }
        setTopSelectedKeys(nextSelectedKeys);
    }, [pathname]);

    useEffect(() => {
        // global tooltip test
        const body = window.document.body;
        if ((body as any).____globalTooltipAssigned) {
            return;
        }
        (body as any).____globalTooltipAssigned = true;

        const tooltipEle = document.getElementById('auto-tooltip');
        const tooltipTextEle = document.getElementById(
            'auto-tooltip-text-content',
        );
        const widthHelperEle = document.getElementById('width-helper');
        const tryRemoveTooltipEle = () => {
            if (!tooltipEle || !tooltipTextEle) {
                return;
            }
            const style = tooltipEle.style;
            style.display = 'none';
            style.left = '0px';
            style.top = '0px';
            tooltipTextEle.innerText = '';
        };
        const moveEleTo = (rect: DOMRect, text: string) => {
            const { x, y, width, height } = rect;
            if (!tooltipEle || !tooltipTextEle) {
                return;
            }
            const style = tooltipEle.style;
            style.display = 'block';
            style.left = x + width + 'px';
            style.top = y - (7.5 + 5 - height / 2) + 'px';
            style.zIndex = '100000';

            tooltipTextEle.innerText = text;
        };

        const handler = _.debounce((e: MouseEvent) => {
            const target = e.target as any;
            if (!target) {
                tryRemoveTooltipEle();
                return;
            }
            const classnm = target.getAttribute('class');
            if (!classnm) {
                tryRemoveTooltipEle();
                return;
            }
            const isEllipsisEle =
                classnm.indexOf('crm-auto-ellipsis') > -1 ||
                classnm.indexOf('ant-table-row-cell-ellipsis') > -1;
            if (!isEllipsisEle) {
                const isTooltip = classnm.indexOf('ant-tooltip') > -1;
                !isTooltip && tryRemoveTooltipEle();
                return;
            }
            const rect = target.getBoundingClientRect();
            if (rect) {
                const text = target.innerText;
                const width = rect.width;
                if (widthHelperEle) {
                    const styles = window.getComputedStyle(target, null);
                    widthHelperEle.style.fontSize = styles.fontSize;
                    widthHelperEle.innerText = text;
                    const textWidth = widthHelperEle.offsetWidth;
                    if (textWidth > width) {
                        moveEleTo(rect, target.innerText);
                    } else {
                        tryRemoveTooltipEle();
                    }
                } else {
                    tryRemoveTooltipEle();
                }
            }
        }, 100);
        body.addEventListener('mousemove', handler);
    }, []);

    return (
        <div onMouseMove={e => {}} className={'mmmm main '}>
            <Observer>
                {() => {
                    const outdated = outDateStore.isOutDate;

                    let className = '';
                    if (isTest || outdated) {
                        className = 'test-should-refresh-main';
                        if (!isTest) {
                            className = 'should-refresh-main';
                        }
                        if (!outdated) {
                            className = 'test-main';
                        }
                    }

                    const mainDom = document.querySelector('.mmmm');
                    if (mainDom && className) {
                        mainDom.classList.remove(
                            'test-should-refresh-main',
                            'should-refresh-main',
                            'test-main',
                            'home-main',
                        );
                        mainDom.classList.add(className);
                    }
                    if (isHomeNewPageForHeaderPadding && mainDom) {
                        mainDom.classList.remove('home-main');
                        mainDom.classList.add('home-main');
                    }

                    let classNameTop = '';
                    if (isTest || outdated) {
                        classNameTop = 'test-should-refresh-top';
                        if (!isTest) {
                            classNameTop = 'should-refresh-top';
                        }
                        if (!outdated) {
                            classNameTop = 'test-top';
                        }
                    }
                    return (
                        <>
                            {(isTest || outdated) && (
                                <>
                                    {isHomeNewPage && (
                                        <div className="crm-new-img-banner-content">
                                            <div className="banner-space left-space"></div>
                                            <img src={bannerImg} alt="" />
                                            <div className="banner-space right-space"></div>
                                        </div>
                                    )}
                                    {isTest && (
                                        <div
                                            style={{
                                                backgroundColor: '#ffa39e',
                                                color: 'white',
                                                fontWeight: 'bold',
                                                position: 'fixed',
                                                top: isHomeNewPage ? 80 : 0,
                                                left: 0,
                                                height: 50,
                                                width: '100%',
                                                lineHeight: '50px',
                                                paddingLeft: 18,
                                                fontSize: 24,
                                            }}
                                        >
                                            当前环境为CRM测试环境，请切换到
                                            <a href="http://crm.taoum.cn/home">
                                                正式环境
                                            </a>
                                            后使用
                                        </div>
                                    )}
                                    {outdated && (
                                        <div
                                            style={{
                                                backgroundColor: '#ffa39e',
                                                color: 'white',
                                                fontWeight: 'bold',
                                                position: 'fixed',
                                                top: 0,
                                                left: 0,
                                                height: 37,
                                                width: '100%',
                                                lineHeight: '50px',
                                                fontSize: 24,
                                            }}
                                        >
                                            <Alert
                                                message={
                                                    <span>
                                                        检测到当前页面未更新，
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            为了保证新功能的正常使用
                                                        </span>
                                                        ，请
                                                        <a
                                                            onClick={() =>
                                                                window.location.reload()
                                                            }
                                                        >
                                                            立即刷新
                                                        </a>
                                                    </span>
                                                }
                                                banner
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            <div
                                className={`top ${classNameTop} ${
                                    isHomeNewPage ? 'top-home' : ''
                                }`}
                            >
                                <div className="top-content">
                                    <TopMenu
                                        {...{
                                            children,
                                            routeLayout,
                                            location,
                                            collapsed,
                                            menuPerm,
                                            selectedRoute: topSelectedKeys,
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    );
                }}
            </Observer>
            <AudioCom />
            <div
                className="left"
                style={{ paddingRight: childRouter.length ? '8px' : '0' }}
            >
                <DsMenu
                    {...{
                        children,
                        routeLayout: childRouter,
                        menuPerm,
                        location,
                        collapsed,
                        parentRouteKey: topSelectedKeys,
                        setCollapsed,
                    }}
                />
            </div>
            {/* {childRouter.length ? <div
                onClick={ () => {
                    setCollapsed(!collapsed)
                }}
                className="trigger-line">
                    {
                        collapsed ? <Icon type="menu-unfold" /> : <Icon type="menu-fold" />
                    }
            </div> : null} */}
            <div className="right">
                <div className="content">{children}</div>
            </div>
        </div>
    );
};
export default Dashboard;
