import { Perm } from './perm';
import { ListMeta } from './utils/list-meta';
import { useState, useContext, useEffect } from 'react';
import { StoreContext } from '@/stores';
export interface IDefaultEntityStore {
    // 目前需要初始化的store可能含有一些需要异步加载的成员
    defaultPerm?: Perm; // 权限api请求
    defaultMeta?: ListMeta; // 元信息api请求
}
export const makeDefaultInitailingHookB = <T extends IDefaultEntityStore>(
    Kls: new () => T, // 一个满足IDefaultEntityStore的class
    entityCode: string, // 实体代码
) => {
    const useAStore = (options: {
        mayBeInitedStore?: T;
        opUserSuffix?: string;
        autoLoad?: boolean;
    }): [boolean, T, () => void] => {
        let {
            mayBeInitedStore,
            opUserSuffix = 'default',
            autoLoad = true,
        } = options;
        const store = useContext(StoreContext);
        const userStore = store.getAuthStore();

        const [newStore] = useState(() => new Kls());
        if (mayBeInitedStore === undefined) {
            mayBeInitedStore = newStore;
        }

        const [loadTrigger, setLoadTrigger] = useState(0);
        const manuallyLoad = () => {
            setLoadTrigger(loadTrigger + 1);
        };
        const [prepared, setPrepared] = useState(false);
        const { defaultMeta, defaultPerm } = mayBeInitedStore;
        const genPromises = () =>
            [
                defaultMeta
                    ? new Promise((resolve, reject) => {
                          if (defaultMeta.inited || defaultMeta.loading) {
                              return resolve();
                          }
                          defaultMeta
                              .fetch()
                              .then(resolve)
                              .catch(reject);
                      })
                    : undefined,
                defaultPerm
                    ? new Promise((resolve, reject) => {
                          if (defaultPerm.inited || defaultPerm.loading) {
                              return resolve();
                          }
                          defaultPerm
                              .fetch()
                              .then(resolve)
                              .catch(reject);
                      })
                    : undefined,
            ].filter(Boolean) as any;

        useEffect(() => {
            if (autoLoad === false && loadTrigger === 0) {
                return;
            }
            if (userStore.userInfo && defaultMeta) {
                defaultMeta.setTableId(entityCode);
                if (!defaultMeta.opUsername) {
                    defaultMeta.setOpUsername(
                        userStore.userInfo.userId + '-' + opUserSuffix,
                    );
                }
            }
            Promise.all(genPromises())
                .then(() => {
                    setPrepared(true);
                })
                .catch(e => {
                    setPrepared(false);
                }); // 初始化元信息
        }, [loadTrigger]);

        return [prepared, mayBeInitedStore, manuallyLoad];
    };
    return useAStore;
};

export function createDefaultInstanceGetterA<T>(
    Class: new (...args: any[]) => T,
): () => T {
    let instance: Nullable<T> = null;
    return function getDefaultInstance(): T {
        if (instance === null) {
            instance = new Class();
        }
        return instance;
    };
}
