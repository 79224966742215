import React, { memo, useEffect, useState } from 'react';
import { DForm, defaultWidgetsGenerator } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { AchievementManaStore } from '../store';
import { calcuSumMoney } from '../utills';

const groupStarts = new Set([
    'target_money_month1',
    'target_money_month4',
    'target_money_month7',
    'target_money_month10',
]);

const DemoForm: React.FC<{
    defaultStore: AchievementManaStore;
}> = ({ defaultStore }) => {
    const { defaultMeta, mutatingData } = defaultStore;

    const [layout, setLayout] = useState<any[]>([]);
    useEffect(() => {
        const widgets = defaultWidgetsGenerator(defaultStore);
        const nextLayout: any[] = [];

        let countDown = 0;
        for (const widget of widgets) {
            if (countDown) {
                countDown--;
                continue;
            }
            if (groupStarts.has(widget.key as string)) {
                countDown = 2;
                nextLayout.push([10, 7, 7]);
                continue;
            }
            nextLayout.push([24]);
        }
        setLayout(nextLayout);
    }, [defaultStore, defaultMeta]);

    const form = (
        <DForm
            onChange={(key, value) => {
                if ((key as string).startsWith('target_money_month')) {
                    _.assign(
                        mutatingData,
                        calcuSumMoney(_.assign(mutatingData, { [key]: value })),
                    );
                }
            }}
            defaultStore={defaultStore}
            labelsTransformer={originalLabels => {
                const nextLabels = [...originalLabels];
                nextLabels.forEach(label => {
                    if (
                        _.startsWith(
                            label.key as string,
                            'target_money_month',
                        ) &&
                        !groupStarts.has(label.key as string)
                    ) {
                        label.labelWidth = 13;
                    }
                });
                return nextLabels;
            }}
            widgetsTransformer={widgets => {
                const nextWidgets = [...widgets];

                for (const widget of nextWidgets) {
                    if (
                        !(widget.key as string).startsWith('target_money_month')
                    ) {
                        widget.status = {
                            ...widget.status,
                            disabled: true,
                        };
                    }
                }

                return nextWidgets;
            }}
            propsTransformer={props => {
                props.layout = layout;
                return props;
            }}
        />
    );
    return form;
};

const FinalDemoForm = memo(observer(DemoForm));
export default FinalDemoForm;
