import React from 'react';
import {
    DTable,
    applyCustomConfToCellsSelector,
    isMultipleCell,
    makeObjColClickable,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { OptListStore } from '../store';
import { tryRefresh } from '@/utils';
import querystring from 'querystring';

const FormDemoTable: React.FC<{
    defaultStore: OptListStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const dataTable = (
        <>
            <DTable
                defaultStore={defaultStore}
                sceneCode={'crm_opt'}
                onColumnsUpdated={() => tryRefresh(['opt'])}
                cellsSelectorTransformer={originalbaiscCellsSelector => {
                    const baiscCellsSelector = [...originalbaiscCellsSelector];
                    // 自定义cell
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'opportunity_name',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'ActionParam';
                            nextCellSelector.inputs.push({
                                paramName: 'opportunity_name',
                            });
                            return nextCellSelector;
                        },
                    );

                    makeObjColClickable(baiscCellsSelector, 'customer_id');
                    return baiscCellsSelector;
                }}
                propsTransformer={props => {
                    // 自定义table props
                    props.loading = loading;
                    props.actions = [];
                    props.onAction = (key, data) => {
                        if (_.startsWith(key, 'reveal-obj/')) {
                            // 关联对象都可以点击
                            if (key.indexOf('/customer_id') !== -1) {
                                window.open(
                                    '/buy/custom/my-custom?' +
                                        querystring.stringify({
                                            id: data.customer_id?.originalValue,
                                            target: 'home_page',
                                        }),
                                    '__blank',
                                );
                            }
                        } else if (_.startsWith(key, 'reveal-param/')) {
                            // 可点击参数，召唤drawer
                            if (key.indexOf('/opportunity_name') !== -1) {
                                window.open(
                                    '/buy/opportunity/all?' +
                                        querystring.stringify({
                                            id: data.id,
                                            target: 'home_page',
                                        }),
                                    '__blank',
                                );
                            }
                        }
                    };
                    props.afterOpSlot = undefined;
                    props.colDefaultWidth = 150;
                    props.closeRowSelection = true;
                    props.widthInfoKey = 'opt-list-home';
                    return props;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalFormTable = observer(FormDemoTable);
export default FinalFormTable;
