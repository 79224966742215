import { defaultAxios } from '@/utils';

export const mutate = async (
    objectName: string,
    optype: string, // insert update
    param: any,
): Promise<[any, Error | null]> => {
    const [r, e] = (await defaultAxios[optype === 'insert' ? 'post' : 'put'](
        '/crm/dml/' + objectName,
        {
            ...param,
        },
    )) as [any, Error | null];

    const data = r;
    return [data, e];
};

export const mutateCommon = async (
    objectName: string,
    optype: string, // insert update
    param: any,
): Promise<[any, Error | null]> => {
    let url = '/crm/common/' + objectName;
    if (optype === 'update') {
        if (!param.id) {
            return [null, new Error('修改必须有id')];
        }
        url += '/' + param.id;
    }
    const [r, e] = (await defaultAxios[optype === 'insert' ? 'post' : 'put'](
        url,
        param,
    )) as [any, Error | null];

    const data = r;
    return [data, e];
};

export const checkCommon = async (
    objectName: string,
    param: any,
): Promise<[any, Error | null]> => {
    let url = '/crm/common/' + objectName + '/check';

    const [r, e] = (await defaultAxios.post(url, param)) as [any, Error | null];

    const data = r;
    return [data, e];
};
