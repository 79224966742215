import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    AntButton,
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    MOpResultDisplayContainer,
    loadingWrapper,
    loadingWrapperOver,
} from '@/components';
import { Menu, Icon, message, Tooltip, Spin, Alert } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, delay, useBeforeFirstDataLoaded } from '@/utils';
import { observer } from 'mobx-react-lite';
import FormDemoTable from './components/table';
import { runInAction } from 'mobx';
import { ListMeta, useFastFilterByKey } from '@/stores/utils';
import moment from 'moment';
import { useDefaultDFilterSnippets, dFilterHOC } from '@/containers/hoc';
import { ActionExport } from '../../activation-record/list/components/export';

const CustomerStarList: React.FC<RouteComponentProps & {
    dfilters?: any;
}> = ({ dfilters }) => {
    const type = 'stars';
    const store = useContext(StoreContext);
    const [customerList] = useState(() => new store.CustomerListStore());
    const userStore = store.getAuthStore();

    const ack = dtext('crm')('customer-list-message-' + type, '');

    const {
        defaultPagination,
        defaultSorter,
        defaultPerm,
        defaultFilter,
        condListbyFilterData,
        defaultFastFilters,
    } = customerList;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const customerListFetch = useCallback(() => customerList.fetchStars(), []);
    const [error, loading, reload] = useNet(customerListFetch, {
        autoLoad: false,
        refreshKeys: ['customer', 'customer-main-list'],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady || !metaReseted) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
    ]);

    const [metaReseted, setMetaReseted] = useState(false);
    useEffect(() => {
        // 修改元信息对象
        customerList.setDefaultMeta(
            new ListMeta(
                '/bff/api/rest/meta/stars/list',
                '/bff/api/rest/meta/stars/list/filters/save',
                '/bff/api/rest/meta/stars/list/table-cols/save',
            ),
        );
        setMetaReseted(true);
    }, []);

    useEffect(() => {
        if (userStore.userInfo && metaReseted) {
            runInAction(() => {
                customerList.defaultMeta.setTableId('customer');
                customerList.defaultMeta.setOpUsername(
                    userStore.userInfo.userId + '-' + type,
                );
                defaultFastFilters.setFilters(dfilters);
            });
            Promise.all([
                defaultPerm.fetch(),
                customerList.defaultMeta.fetch(),
            ]).then(() => {
                setPreRequiredReady(true);
            }); // 初始化元信息
        }
    }, [userStore.userInfo, metaReseted]);

    // 设置filter初始值
    useEffect(() => {
        if (customerList.defaultMeta.inited && metaReseted) {
            defaultFilter.resetFilterData();
            defaultFilter.confirmFilterData();
        }
    }, [customerList.defaultMeta.inited, metaReseted]);

    useEffect(() => {
        if (metaReseted) {
            customerList.setListType(type);
            defaultFastFilters.setType(type);
        }
    }, [type, metaReseted]);

    const [isBeforeFirstLoaded] = useBeforeFirstDataLoaded(customerList);
    const { title, fastFilters } = useDefaultDFilterSnippets(customerList);

    const finalLoading =
        !preRequiredReady || !metaReseted || isBeforeFirstLoaded;

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={customerList.defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={customerList.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = <>{/* <ActionExport defaultStore={customerList} /> */}</>;

    const globalOps = (
        <>
            {/* {defaultPerm.getPermByTypeAndActionOriginal('mine')('export')
                .visible && (
                <AntButton
                    onClick={() => {
                        customerList.setAction('export');
                    }}
                    size="large"
                >
                    导出
                </AntButton>
            )} */}
        </>
    );
    const multipleOps = null;

    const dataTable = (
        <FormDemoTable
            customerList={customerList}
            loading={loading}
            type={type}
            reload={reload}
        />
    );

    const layoutComs = {
        popups,
        title: '关注客户',
        globalOps,
        filters: null,
        multipleOps,
        dataTable,
        message: ack ? <Alert type={'warning'} message={ack} /> : null,
        filtersDisplay, // fastFilters,
    };

    return loadingWrapperOver(finalLoading)(
        <LayoutStandardCrud className="page-customer-list" {...layoutComs} />,
    );
};
const FinalCustomerListToRelease = dFilterHOC(
    observer(CustomerStarList),
    'customer',
);
export default FinalCustomerListToRelease;
