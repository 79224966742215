import React, { useEffect } from 'react';
import ContactsForm from '@/pages/sales-follow-up/contact/list/components/form-drawer';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Modal } from 'antd';
import { useAnyContactsStore } from '@/pages/sales-follow-up/contact/list/store';
import { genCustomerIdParamByCustomerItemAndParamMeta } from '@/pages/sales-follow-up/custom/list/store';
import _ from 'lodash';
import { tryRefresh } from '@/utils';

export const CustomerContactsCreate: React.FC<{
    trigger: number;
    customerInfo: any;
}> = observer(({ trigger, customerInfo }) => {
    customerInfo = customerInfo || {};
    const [prepared, defaultStore, prepareStore] = useAnyContactsStore({
        opUserSuffix: 'customer-drawer',
        autoLoad: false,
    });
    const { actionType } = defaultStore;

    useEffect(() => {
        if (trigger > 0) {
            prepareStore();
            runInAction(() => {
                defaultStore.setAction('create');
            });
        }
    }, [trigger]);

    useEffect(() => {
        if (!prepared || actionType !== 'create') {
            return;
        }
        defaultStore.startNewData();
        const customerId = genCustomerIdParamByCustomerItemAndParamMeta(
            customerInfo,
        );
        if (customerId) {
            const nextMutatingData = _.cloneDeep(defaultStore.mutatingData);
            nextMutatingData.customer_id = customerId;
            defaultStore.setMutatingData(nextMutatingData);
        }
    }, [prepared, customerInfo, actionType]);

    if (trigger === 0) {
        return null;
    }

    const preRequiredReady = prepared;
    return (
        <ContactsForm
            mutateSuccess={() => {
                tryRefresh(['customer', 'process-contacts']);
            }}
            defaultStore={defaultStore}
            preRequiredReady={preRequiredReady}
            ignoreCtx={true}
        />
    );
});
