import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Pagination, Table, Icon, Popover, Input, Tooltip } from 'antd';
import { TableProps } from 'antd/lib/table';
import { allCells, KeysOfAllCells } from './cells';
import _ from 'lodash';
import { arrToJson, arrToJsonPrimary, ADFilterEE } from '@/utils';
import { AdvancedFilter } from '@/components';
import { DefaultTitle } from './title';
import { DEFAULT_TABLE_TITLE_WIDTH } from '@/configs/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { AntTable } from '@/components/antd';
import './index.scss';
import { makeAntTableDraggable } from './draggable';
import { AutoEllipsis } from '../auto-ellipsis';
import { IMySorter } from '@/stores/utils';
import EventEmitter from 'eventemitter3';
import { createSelector } from 'reselect';
import { colunms } from '@/pages/demo2/test/data';
import { CC } from './cells/cc';
import { toJS } from 'mobx';

export const manualResizeEE = new EventEmitter();
export const resizeLv1Table = () => manualResizeEE.emit('resize');

export interface IAdFilterProps {
    onSorterChange: (sortType: string) => void;
    onClose: () => void;
    onCancel: () => void;
    onConfirm: () => void;
}

export interface ICell<T> {
    colKey: string;
    cell: KeysOfAllCells;
    inputs: any[];
}

export interface ICellMultiple<T> {
    colKey: string;
    cells: Array<{
        cell: KeysOfAllCells;
        inputs: any[];
    }>;
}

export const isMultipleCell = (cell: any): cell is ICellMultiple<any> => {
    return !!cell.cells;
};

export interface ICommonTableColumnProps<T> extends ColumnProps<T> {}

export type TCell<T> = ICell<T> | ICellMultiple<T>;

export type TCellsSelector<T> = Array<TCell<T>>;

export interface ITableLv1Props<T> extends TableProps<T> {
    cellsSelector?: TCellsSelector<T>;
    extra?: React.ReactChild;
    onPaginationChange?: (pagi: {
        current: number;
        pageSize: number | undefined;
    }) => void;
    onSorterChange?: (sorter: IMySorter) => void;
    onAction?: (actionKey: string, datum: T) => void;
    onFilterChange?: (filterKey: string, value: any) => void;
    useThFilter?: boolean;
    paramWhiteList?: string[];
    widthInfoKey?: string;
    advancedFilterMap?: { [key: string]: React.ReactElement | null };
    adjustHeight?: boolean;
    // 关闭多选
    closeRowSelection?: boolean;
    fixedTd?: boolean;
    fadeIn?: boolean;
    colDefaultWidth?: number;
    actionWidth?: number;

    lockWidth?: { [key: string]: number };
    sceneCode?: string; // 场景标志
}

const ACTION_KEY = '----------action';
const mergeWidthInfo = (
    target: { [key: string]: number },
    source: Array<ICommonTableColumnProps<any>>,
) => {
    const sourceMap: { [key: string]: number } = {};
    for (const col of source) {
        if (_.isString(col.key)) {
            if (col.key === ACTION_KEY) {
                continue;
            }
            if (target[col.key]) {
                sourceMap[col.key] = target[col.key];
            } else if (_.isNumber(col.width)) {
                sourceMap[col.key] = col.width;
            }
        }
    }
    // remove target keys not in new map
    return sourceMap;
};

const applyWidthInfo = (
    target: Array<ICommonTableColumnProps<any>>,
    widthInfo: { [key: string]: number },
    lockWidth?: { [key: string]: number },
) => {
    for (const col of target) {
        if (_.isString(col.key) && _.isNumber(widthInfo[col.key])) {
            if (lockWidth && lockWidth[col.key]) {
                col.width = lockWidth[col.key];
            } else {
                col.width = widthInfo[col.key];
            }
        }
    }
};

export const TableLv1: <T>(
    props: ITableLv1Props<T>,
) => React.ReactElement<ITableLv1Props<T>> | null = props => {
    const cols = _.cloneDeep(props.columns || []);
    const cellsSelector = props.cellsSelector || [];
    const cellsMap = new Map<string, ICell<any> | ICellMultiple<any>>();
    for (const c of cellsSelector) {
        cellsMap.set(c.colKey as string, c);
    }
    let nextCols: typeof cols = [...(cols || [])];

    // const [start] = useState({v: Date.now()})
    // useEffect(() => {
    //     console.log('mount time 2: ', Date.now() - start.v)
    //     start.v = Date.now()
    // }, [props.dataSource]);

    // 高级filter
    const advancedFilterMap = props.advancedFilterMap;
    const adjustHeight =
        props.adjustHeight === undefined ? true : props.adjustHeight;
    const useAdvancedFilter = !!advancedFilterMap;

    // 自适应固定&固底
    let scrollX = 0;
    const [scrollY, setScrollY] = useState(0);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const hasPagi = !!props.pagination;

    const [adjustHeightTrigger, setAdjustHeightTrigger] = useState(0);
    useEffect(() => {
        if (adjustHeight) {
            const wrapperEle = wrapperRef.current;

            const tableHead = wrapperEle?.querySelector(
                '.ant-table-thead',
            ) as HTMLDivElement;

            if (wrapperEle) {
                // header height / pagi height / one standard padding
                const nextScrollY =
                    wrapperEle.offsetHeight -
                    tableHead.offsetHeight -
                    (hasPagi ? 32 : 0) -
                    9;
                setScrollY(nextScrollY);
            }
        }
    }, [adjustHeightTrigger]);

    useEffect(() => {
        const handlerNoDebounce = () => {
            setAdjustHeightTrigger(1 + adjustHeightTrigger);
        };
        const handler = _.debounce(handlerNoDebounce, 100);
        window.addEventListener('resize', handler);
        manualResizeEE.on('resize', handlerNoDebounce);
        return () => {
            window.removeEventListener('resize', handler);
            manualResizeEE.removeListener('resize', handlerNoDebounce);
        };
    }, [adjustHeightTrigger]);

    const onAction = props.onAction;
    const colDefaultWidth = props.colDefaultWidth;
    const onFilterChange = props.onFilterChange || (() => void 0);
    const useThFilter = props.useThFilter || false;
    const [thFilterVisible, setThFilterVisible] = useState<{
        [key: string]: boolean;
    }>({});
    const setFilterVisible = (key: string) => {
        const next: { [key: string]: boolean } = {};
        next[key] = true;
        setThFilterVisible(next);
    };
    const setFilterInvisible = (key: string) => {
        const next = { ...thFilterVisible };
        next[key] = false;
        setThFilterVisible(next);
    };

    const paramWhiteList = props.paramWhiteList || [];
    const paramWhiteListMap = arrToJsonPrimary(paramWhiteList);
    const isValidParam = (param: string) => {
        // 如果不用paramWhiteList，那么就都是合法的param
        if (paramWhiteList.length === 0) {
            return true;
        }
        return !!paramWhiteListMap[param];
    };

    // 中间的拖拽
    const colsSigntrue =
        '' +
        _.map(nextCols, col => {
            return col.key;
        });

    const widthInfoKey = props.widthInfoKey;
    const widthInfoFinalKey = '____crm-table-width-info-' + widthInfoKey;

    const [widthInfo, setWidthInfo] = useState(() => {
        let cachedWidthInfo = {};
        if (widthInfoKey) {
            const cachedRawWidthInfo = localStorage.getItem(widthInfoFinalKey);
            if (cachedRawWidthInfo) {
                cachedWidthInfo = JSON.parse(cachedRawWidthInfo);
            }
        }
        return mergeWidthInfo(cachedWidthInfo, nextCols);
    });

    useEffect(() => {
        let cachedWidthInfo = {};
        if (widthInfoKey) {
            const cachedRawWidthInfo = localStorage.getItem(widthInfoFinalKey);
            if (cachedRawWidthInfo) {
                cachedWidthInfo = JSON.parse(cachedRawWidthInfo);
            }
        }
        const nextWInfo = mergeWidthInfo(cachedWidthInfo, nextCols);
        setWidthInfo(nextWInfo);
    }, [colsSigntrue]);

    const [basicTableNode, setBasicTableNode] = useState<HTMLDivElement | null>(
        null,
    );
    const updateBaseTableRef = useCallback<
        (Node: HTMLDivElement | null) => void
    >(
        node => {
            if (node !== null && node !== basicTableNode) {
                setBasicTableNode(node);
            }
        },
        [basicTableNode],
    );

    // 如果dom节点更新，
    useEffect(() => {
        if (basicTableNode !== null) {
            const dispose = makeAntTableDraggable(
                basicTableNode,
                (diff, key) => {
                    const nextWidthInfo = _.assign({}, widthInfo);
                    if (_.isNumber(nextWidthInfo[key])) {
                        nextWidthInfo[key] = Math.max(
                            colDefaultWidth || DEFAULT_TABLE_TITLE_WIDTH,
                            nextWidthInfo[key] + diff,
                        );
                    }
                    setWidthInfo(nextWidthInfo);
                    if (widthInfoKey) {
                        localStorage.setItem(
                            widthInfoFinalKey,
                            JSON.stringify(nextWidthInfo),
                        );
                    }
                },
            );
            return dispose;
        }
    }, [basicTableNode, colsSigntrue, widthInfo]);

    const onSorterChange = useCallback(
        (colKey: string, nextSorttype: string) => {
            if (!_.isNil(props.onSorterChange)) {
                props.onSorterChange({
                    columnKey: colKey,
                    order: nextSorttype,
                });
            }
            setFilterInvisible(colKey);
        },
        [setFilterInvisible],
    );
    const onClose = useCallback(
        (colKey: string) => {
            setFilterInvisible(colKey);
        },
        [setFilterInvisible],
    );
    const onCancel = useCallback(
        (colKey: string) => {
            setFilterInvisible(colKey);
        },
        [setFilterInvisible],
    );
    const onConfirm = useCallback(
        (colKey: string) => {
            setFilterInvisible(colKey);
        },
        [setFilterInvisible],
    );
    const adFilterHandlers: { [key: string]: (...args: any[]) => void } = {
        onSorterChange,
        onClose,
        onCancel,
        onConfirm,
    };

    useEffect(() => {
        const handler = (e: any) => {
            const { type, args } = e;
            adFilterHandlers[type](...args);
        };
        ADFilterEE.on('advanced-filter-event', handler);
        return () => {
            ADFilterEE.removeListener('advanced-filter-event', handler);
        };
    }, [adFilterHandlers]);

    const valueGetter = useCallback((record: any, inputs: any) => {
        const args = [
            ...inputs.map((input: any) => {
                if (_.isString(input)) {
                    const v = (record as any)[input];
                    if (v !== undefined || isValidParam(input)) {
                        return v;
                    }
                    return input;
                }
                return input;
            }),
        ];
        return args;
    }, []);

    nextCols = [];

    for (let i = 0; i < cols.length; i++) {
        const col = cols[i];
        const colKey = col.key as string;
        if (col.key !== ACTION_KEY) {
            const titleEle: React.ReactNode = col.title || 'unknow';
            col.title = () => (
                <AutoEllipsis className={'th-title'} text={titleEle} />
            );

            const TitleCom = col.title as any;
            const ContentEle =
                advancedFilterMap && advancedFilterMap[colKey]
                    ? advancedFilterMap[colKey]
                    : null;
            col.title = () => (
                <span>
                    <span
                        className={
                            'super-th ' +
                            (thFilterVisible[colKey]
                                ? 'super-th-highlight'
                                : '')
                        }
                    >
                        <TitleCom />
                        {useThFilter && (
                            <Popover
                                mouseLeaveDelay={400}
                                trigger="hover"
                                onVisibleChange={v => {
                                    if (v === true) {
                                        setFilterVisible(colKey);
                                    }
                                }}
                                visible={!!thFilterVisible[colKey]}
                                content={ContentEle}
                            >
                                {advancedFilterMap &&
                                    advancedFilterMap[colKey] && (
                                        <span className="th-filter-icon">
                                            <Icon type="down-circle" />
                                        </span>
                                    )}
                            </Popover>
                        )}
                        {/* {useThFilter && (
                            <Tooltip title={'fff'}>
                                {advancedFilterMap &&
                                    advancedFilterMap[colKey] && (
                                        <span className="th-filter-icon">
                                            <Icon type="up-circle" />
                                        </span>
                                    )}
                            </Tooltip>
                        )} */}
                    </span>
                    <span className="th-spliter" data-key={colKey}>
                        <span className="th-spliter-inner" />
                    </span>
                </span>
            );
        }
        col.width = col.width || colDefaultWidth || DEFAULT_TABLE_TITLE_WIDTH;
        if (col.render) {
            nextCols.push(col);
            continue;
        }
        const cellItem = cellsMap.get(colKey);
        if (!cellItem) {
            if (!col.dataIndex) {
                col.dataIndex = colKey;
            }
            nextCols.push(col);
            continue;
        }
        if (isMultipleCell(cellItem)) {
            const { cells } = cellItem;
            const clonedCol = { ...col };
            clonedCol.render = (text, record, index) => {
                return (
                    <span>
                        {cells.map(innerCellItem => {
                            const { cell, inputs } = innerCellItem;
                            const Com = allCells[cell] as any;
                            const args = valueGetter(record, inputs);

                            if (cell === 'Echo') {
                                return (
                                    <span className="table-auto-ellipsis">
                                        {args[0]}
                                    </span>
                                );
                            }
                            if (cell === 'CC') {
                                return (
                                    <CC
                                        args={args}
                                        config={{
                                            showFollowLog: true,
                                            customerName:
                                                (record as any)
                                                    ?.customer_name ||
                                                (record as any)?.follow_customer
                                                    ?.relatedObj
                                                    ?.customer_name ||
                                                (record as any)?.customer_id
                                                    ?.relatedObj?.customer_name,
                                            customerId:
                                                (record as any)?.customer_id
                                                    ?.originalValue ||
                                                (record as any)?.follow_customer
                                                    ?.originalValue,
                                            contactsId:
                                                props.sceneCode ===
                                                'crm_contacts'
                                                    ? (record as any)?.id
                                                    : (record as any)
                                                          ?.customer_contact
                                                          ?.originalValue,
                                            leadId:
                                                props.sceneCode === 'crm_lead'
                                                    ? (record as any)?.id
                                                    : undefined,
                                        }}
                                        sceneCode={props.sceneCode || ''}
                                    />
                                );
                            }
                            return (
                                <Com
                                    args={args}
                                    item={record}
                                    onAction={onAction || (() => void 0)}
                                />
                            );
                        })}
                    </span>
                );
            };
            nextCols.push(clonedCol);
        } else {
            const { cell, inputs, colKey } = cellItem;
            const clonedCol = { ...col };
            if (cell !== 'Echo') {
                clonedCol.render = (text, record, index) => {
                    // const argsSelector = argsSelectorGetter(record, colKey);
                    // const args = argsSelector({ record, inputs });

                    const args = valueGetter(record, inputs);
                    const Com = allCells[cell] as any;

                    if (cell === 'CC') {
                        return (
                            <CC
                                args={args}
                                config={{
                                    showFollowLog: true,
                                    customerName:
                                        (record as any)?.customer_name ||
                                        (record as any)?.follow_customer
                                            ?.relatedObj?.customer_name ||
                                        (record as any)?.customer_id?.relatedObj
                                            ?.customer_name,

                                    customerId:
                                        props.sceneCode === 'crm_contract'
                                            ? undefined
                                            : (record as any)?.customer_id
                                                  ?.originalValue ||
                                              (record as any)?.follow_customer
                                                  ?.originalValue,
                                    contactsId:
                                        props.sceneCode === 'crm_contacts'
                                            ? (record as any)?.id
                                            : (record as any)?.customer_contact
                                                  ?.originalValue,
                                    leadId:
                                        props.sceneCode === 'crm_lead'
                                            ? (record as any)?.id
                                            : undefined,
                                }}
                                sceneCode={props.sceneCode || ''}
                            />
                        );
                    }

                    if (cell === 'image') {
                        return <Com record={record} />;
                    }

                    return (
                        <Com
                            args={args}
                            item={record}
                            onAction={onAction || (() => void 0)}
                        />
                    );
                };
            } else {
                clonedCol.dataIndex = colKey;
            }

            nextCols.push(clonedCol);
        }
    }

    // 应用宽度信息
    applyWidthInfo(nextCols, widthInfo, props.lockWidth);
    scrollX = _.reduce<string, number>(
        _.keys(widthInfo),
        (ret, key) => {
            return ret + widthInfo[key];
        },
        (!props.closeRowSelection ? 60 : 0) +
            (props.actionWidth ? props.actionWidth : 120),
    );

    let finalScroll: any = { x: scrollX };
    if (props.scroll) {
        finalScroll = props.scroll;
    }
    if (scrollY) {
        finalScroll.y = scrollY;
    }

    const nextProps = {
        ...props,
        columns: nextCols,
        scroll: finalScroll,
    };

    const fadeIn = _.isUndefined(props.fadeIn) ? false : props.fadeIn;

    return (
        <div
            className={'maimai-crm-basic-table ' + props.className}
            ref={wrapperRef}
            style={{ width: '100%', height: '100%' }}
        >
            <div ref={updateBaseTableRef} className={'base-table'}>
                <div className="col-resizer-cursor-outer">
                    <div className="col-resizer-cursor" />
                </div>
                <AntTable
                    {...nextProps}
                    className={
                        (nextProps.className || '') +
                        (props.fixedTd ? ' maimai-crm-table-fixed-td ' : ' ') +
                        (fadeIn ? ' animated fadeIn' : '')
                    }
                    onChange={(_, __, sorter) => {
                        const { onSorterChange } = nextProps;
                        if (onSorterChange) {
                            onSorterChange(sorter);
                        }
                    }}
                    pagination={false}
                    tableLayout={'fixed'}
                />
            </div>
            {nextProps.pagination && (
                <div className="base-pagi">
                    <div className="left">
                        {/* slot */}
                        {props.extra}
                    </div>
                    <div className="right">
                        <span className="amount">
                            共{nextProps.pagination.total}条
                        </span>

                        {/* TODO: table 外部的onChange想集大成，其实有点恶心  */}
                        <Pagination
                            {...nextProps.pagination}
                            onChange={(current, pageSize) => {
                                if (nextProps.onPaginationChange) {
                                    nextProps.onPaginationChange({
                                        current,
                                        pageSize,
                                    });
                                }
                            }}
                            onShowSizeChange={(current, pageSize) => {
                                if (nextProps.onPaginationChange) {
                                    nextProps.onPaginationChange({
                                        current,
                                        pageSize,
                                    });
                                }
                            }}
                            pageSizeOptions={[
                                '5',
                                '10',
                                '20',
                                '30',
                                '40',
                                '100',
                                '200',
                            ]}
                            showQuickJumper
                            showSizeChanger={
                                nextProps.pagination.showSizeChanger === false
                                    ? false
                                    : true
                            }
                            showLessItems
                        ></Pagination>
                    </div>
                </div>
            )}
        </div>
    );
};

// export const TableLv0Dot5: <T>(
//     props: TableProps<T>,
// ) => React.ReactElement<TableProps<T>> = props => {
//     const {
//         columns,
//         dataSource
//     } = props
//     const [ ds, setDs ] = useState<any[]>([])
//     const [ cos, setCos ] = useState<any[]>([])
//     useEffect(() => {
//         if (dataSource) {
//             const firstRanderCols = columns?.slice(0, 12)
//             if (firstRanderCols) {
//                 const nextDataSource = []
//                 for (const dataItem of dataSource as any[]) {
//                     const nextDataItem: any = { id: dataItem.id, key: dataItem.key }
//                     for (const col of firstRanderCols) {
//                         const k = col.key as string
//                         nextDataItem[k] = dataItem[k]
//                     }

//                     nextDataSource.push(nextDataItem)
//                 }
//                 setDs(nextDataSource)
//             }
//         }
//         requestAnimationFrame(() => {
//             if (dataSource) {
//                 setDs(dataSource)
//             }
//         })
//     }, [ dataSource ])

//     useEffect(() => {
//         const firstRanderCols = columns?.slice(0, 12)
//         if (firstRanderCols) {
//             setCos(firstRanderCols)
//         }
//         requestAnimationFrame(() => {
//             if (columns) {
//                 setCos(columns)
//             }
//         })
//     }, [ columns ])

//     return (
//         <AntTable
//             {...props}
//             dataSource={ds}
//             columns={cos}
//         />
//     );
// };
