import React, { useState, useCallback, useEffect, ReactNode } from 'react';
import { InputNumber, Input, Alert, Spin } from 'antd';
import { getCommonStatus, ICommonProps } from '../common';
import { ADFilterEE } from '@/utils';
import _ from 'lodash';
import './enum.scss';
import Checkbox, {
    CheckboxGroupProps,
    CheckboxProps,
    CheckboxChangeEvent,
} from 'antd/lib/checkbox';
import CheckboxGroup, { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { isObservableValue } from 'mobx/dist/internal';
import { TreeProps, AntTreeNodeProps } from 'antd/lib/tree';
// import { TreeNodeValue } from 'antd/lib/tree-select/interface';
import { SearchAbleTree } from '@/components';
import { defaultAxios } from '@/utils';

export type FilterSysUserSelectorValueType = string[];
export interface IFilterSysUserSelectorOptions extends TreeProps {
    itemProps?: AntTreeNodeProps;
    constraint: any; // magic...
    listMeta: any;
}

export interface IWidgetFilterSysUserSelectorProps<T = any>
    extends ICommonProps<T> {
    value: FilterSysUserSelectorValueType;
    options: IFilterSysUserSelectorOptions;
    onChange: (value: FilterSysUserSelectorValueType) => void;
}

export const WidgetFilterSysUserSelector: React.FC<IWidgetFilterSysUserSelectorProps> = ({
    k: key,
    value,
    options,
    onChange,
    onInteract = key => void 0,
    status,
    data,
    statusExtraData,
}) => {
    const [fetchLoading, setFetchLoading] = useState(false);
    const [sysTreeData, setSysTreeData] = useState<any[]>([]);
    const [clearSearchTreeSearch, setClearSearchTreeSearch] = useState(false);
    const fetchSysDepartInfoWithUser = async () => {
        const url = '/bff/api/rest/sys-user-meta';
        setFetchLoading(true);
        const [d, e] = await defaultAxios.get(url);
        if (!d || !d.data) {
            return [null, e];
        }
        setFetchLoading(false);
        if (d.data) {
            setSysTreeData([d.data]);
        }
    };
    useEffect(() => {
        fetchSysDepartInfoWithUser();
    }, []);
    useEffect(() => {
        if (!value) {
            setSelectedKey([]);
            setClearSearchTreeSearch(true);
            setTimeout(() => {
                setClearSearchTreeSearch(false);
            }, 0);
        }
    }, [value]);
    const { constraint = {}, listMeta = {} } = options;
    const [selectedKey, setSelectedKey] = useState<string[]>([]);
    const currentFieldMeta =
        _.find(listMeta.objectMeta, metaItem => metaItem.fieldName === key) ||
        {};
    return (
        <div className="crm-widgets-sysuser-input">
            <Spin spinning={fetchLoading}>
                <SearchAbleTree
                    clearSearch={clearSearchTreeSearch}
                    defaultExpandParent={true}
                    otherUse={true}
                    treeData={sysTreeData}
                    inputProps={{
                        placeholder: `查找${currentFieldMeta.fieldComment}`,
                    }}
                    selectedKeys={selectedKey}
                    onSelectedChange={(keys, nodes) => {
                        setSelectedKey([...keys]);
                        onChange(
                            _.map(
                                _.filter(nodes, node =>
                                    _.startsWith(node.key, 'user-'),
                                ),
                                node => {
                                    return node?.props?.dataRef || {};
                                },
                            ),
                        );
                    }}
                />
            </Spin>
        </div>
    );
};
