import React, { useState, useEffect, useContext, useCallback } from 'react';
import { DTable, isMultipleCell, makeObjColClickable } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { IJobStore, makeDefaultTableOpActionRenders } from '../../../utils';
import { runInAction } from 'mobx';
import DefaultDrawer from '../drawer';
import { StoreContext } from '@/stores';
import { applyCustomConfToCellsSelector } from '@/components/common/form/advanced-filters';
import { useCustomerDrawerSnippets } from '@/components/common/customer-drawer/hooks';
import { tryRefresh, useQueryAsObject } from '@/utils';
import { cusFollowOpBlickList } from './utils';
import { CtxContext } from '@/utils/context';

const DemoTable: React.FC<{
    defaultStore: IJobStore;
    loading: boolean;
    noHistory?: boolean;
}> = ({ defaultStore, loading, noHistory }) => {
    const store = useContext(StoreContext);
    const ctx = useContext(CtxContext);
    const innerDrawer = ctx.position === 'drawer';

    const [drawerVis, setDrawerVis] = useState(false);
    const [detailId, setDetailId] = useState(undefined);

    const [drawerVisCus, setDrawerVisCus] = useState(false);
    const [cidCus, setCidCus] = useState(undefined);

    const [tableActions] = useState(() => {
        return makeDefaultTableOpActionRenders().filter(
            ({ actionKey }) => !cusFollowOpBlickList.has(actionKey),
        );
    });

    useQueryAsObject(queryObj => {
        if (queryObj.id && !noHistory) {
            setDetailId(queryObj.id);
            setDrawerVis(true);
        }
    });

    const [___, cusDrawerSnippet] = useCustomerDrawerSnippets({
        drawerVis: drawerVisCus,
        setDrawerVis: setDrawerVisCus,
        cid: cidCus,
    });

    const dataTable = (
        <>
            {cusDrawerSnippet}
            <DefaultDrawer
                drawerVis={drawerVis}
                setDrawerVis={setDrawerVis}
                detailId={detailId}
                destroyOnClose={true}
            />
            <DTable
                sceneCode={'crm_activation'}
                onColumnsUpdated={() => tryRefresh(['task_activation'])}
                defaultStore={defaultStore}
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = tableActions;
                    props.withDataAuthCodeCheck = false;
                    props.onAction = (key, data) => {
                        // update
                        // cancel
                        // close
                        // complete
                        // transfer
                        if (key === 'update') {
                            runInAction(() => {
                                defaultStore.setAction('update', {
                                    id: data.id,
                                });
                            });
                        } else if (key === 'cancel') {
                            // 其他动作
                            runInAction(() => {
                                defaultStore.setMutatingData(data);
                                defaultStore.setAction('cancel', {
                                    id: data.id,
                                });
                            });
                        } else if (key === 'close') {
                            // 其他动作
                            defaultStore.setAction('close');
                        } else if (key === 'complete') {
                            // 其他动作
                            runInAction(() => {
                                defaultStore.setSubActionType('complete');
                                defaultStore.setAction('update', {
                                    id: data.id,
                                });
                            });
                        } else if (key === 'transfer') {
                            // 其他动作
                            defaultStore.setAction('transfer');
                        } else if (_.startsWith(key, 'reveal-obj/')) {
                            // 关联对象都可以点击
                            if (key.indexOf('/customer') !== -1) {
                                setDrawerVisCus(true);
                                setCidCus(data.follow_customer?.originalValue);
                            }
                        } else if (_.startsWith(key, 'reveal-param/')) {
                            // 可点击参数，召唤drawer
                            if (key.indexOf('/task_code') !== -1) {
                                if (innerDrawer) {
                                    window.open(
                                        '/buy/job-management/cus-follow?id=' +
                                            data.id,
                                    );
                                } else {
                                    setDetailId(data.id);
                                    setDrawerVis(true);
                                }
                            }
                        }
                    };

                    // 一期多选先不用了
                    // props.closeRowSelection = false;
                    // props.selectedItems = mSeletedData;
                    // props.onSelectedDataChange = setMSelectedData;
                    return props;
                }}
                cellsSelectorTransformer={baiscCellsSelector => {
                    // 详情可点击
                    // JOBTODO：这里要改成【任务编号】字段
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'task_code',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'ActionParam';
                            nextCellSelector.inputs.push({
                                paramName: 'task_code',
                            });
                            return nextCellSelector;
                        },
                    );

                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'task_state',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'JobStatus';
                            return nextCellSelector;
                        },
                    );

                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'priority',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'JobPriority';
                            return nextCellSelector;
                        },
                    );

                    makeObjColClickable(baiscCellsSelector, 'follow_customer');

                    return baiscCellsSelector;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
