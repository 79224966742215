import React from 'react';
import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { createDefaultInstanceGetter } from '../../stores/utils/index';
import { defaultAxios } from '@/utils';
export class AnalysisStore {
    constructor() {
        makeAutoObservable(this);
    }
    public exportVis: boolean = false;
    public fetchAnaAuth = async () => {
        const url = '/bff/api/rest/perm?mod=analysis';
        const [d, e] = await defaultAxios.get(url);
        if (d && d.data && d.data?.length) {
            const exportAuth = _.find(d.data, item => {
                return item.key === 'analysis:export';
            });
            this.exportVis = exportAuth?.visible || false;
        }
    };
}
export const getDefaultAnalysisStore = createDefaultInstanceGetter(
    AnalysisStore,
);
