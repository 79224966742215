import _, { values } from 'lodash';
import Config from '@/configs/default';
import { IRouteItem } from '@/configs/route';
import { TreeNodeNormal } from 'antd/lib/tree/Tree';
import { defaultAxios } from './net';
import { message } from 'antd';

interface IIndexable {
    [key: string]: any;
}

export const arrToJson = <T extends IIndexable>(
    arr: Array<T | undefined | null>,
    key?: string,
): { [key: string]: T | undefined | null } => {
    const map = {} as { [key: string]: T };
    key = key || 'id';
    for (const item of arr) {
        if (_.isNil(item)) {
            continue;
        }
        const k = String(item[key]) as string;
        if (k === '') {
            continue;
        }
        map[k] = item;
    }
    return map;
};

export const arrToSortHelper = <T extends IIndexable>(
    arr: Array<T | undefined | null>,
    key?: string,
): { [key: string]: number } => {
    const map = {} as { [key: string]: number };
    key = key || 'id';
    _.each(arr, (item, index) => {
        if (_.isNil(item) || !key) {
            return;
        }
        const k = String(item[key]) as string;
        if (k === '') {
            return;
        }
        map[k] = index;
    });
    return map;
};

export const arrToSortHelperPrimary = (
    arr: string[],
): { [key: string]: number } => {
    const map = {} as { [key: string]: number };
    _.each(arr, (item, index) => {
        map[item] = index;
    });
    return map;
};

export const sortArrBySortHelper = <T extends IIndexable>(
    arr: Array<T | undefined | null>,
    sortHelper: { [key: string]: number },
    key?: string,
): Array<T | undefined | null> => {
    key = key || 'id';
    return arr.sort((a, b) => {
        if (a && b && key) {
            return (
                (sortHelper[a[key]] === undefined
                    ? 99999
                    : sortHelper[a[key]]) -
                (sortHelper[b[key]] === undefined ? 99999 : sortHelper[b[key]])
            );
        }
        return 0;
    });
};

export const arrToJsonPrimary = (arr: string[]): { [key: string]: boolean } => {
    const map: { [key: string]: boolean } = {};
    for (const str of arr) {
        map[str] = true;
    }
    return map;
};

export const requireKeys = (obj: any, ...keys: string[]): boolean => {
    for (const key of keys) {
        if (_.isUndefined(obj[key])) {
            return false;
        }
    }
    return true;
};

export const firstCom = (str: string, splitor?: string) => {
    splitor = splitor === undefined ? ' ' : splitor;
    return _.first(_.split(str, splitor));
};

export const lastCom = (str: string, splitor?: string) => {
    splitor = splitor === undefined ? ' ' : splitor;
    return _.last(_.split(str, splitor));
};

const dummyEle = document.createElement('div');
export const isGoodRichTextContent = (content: string): boolean => {
    dummyEle.innerHTML = content;
    return _.trim(dummyEle.innerText) !== '';
};

export const delay = (ms: number) =>
    new Promise(resolve => setTimeout(resolve, ms));

export const noop = () => void 0;

export const goToLogin = (url?: string) => {
    // return;
    const businessUrl = window.location.href;
    if (url && url?.indexOf('status') > -1) {
        window.location.href = `${
            Config.login_url
        }&business_url=${encodeURIComponent('/home')}`;
        return;
    }
    if (!url && businessUrl.indexOf('status') > -1) {
        window.location.href = `${
            Config.login_url
        }&business_url=${encodeURIComponent('/home')}`;
        return;
    }
    window.location.href = `${
        Config.login_url
    }&business_url=${encodeURIComponent(url ? url : businessUrl)}`;
};

export const goToStatus = (status: number) => {
    window.location.href = `/status/${status}`;
};

export const isZeroValue = (v: any) => {
    if (_.isNil(v)) {
        return false;
    }
    const tp = typeof v;
    if (tp === 'number') {
        return v === -1;
    } else if (tp === 'string') {
        return v === '';
    }
    return true;
};

export const isZeroValue2 = (v: any) => {
    if (_.isNil(v)) {
        return true;
    }
    const tp = typeof v;
    if (tp === 'number') {
        return v === -1;
    } else if (tp === 'string') {
        return v === '';
    }

    if (tp === 'object' && !_.isNil(v.originalValue)) {
        return false;
    }

    return true;
};

export const isRelatedObjValue = (v: any) => {
    if (!_.isObject(v)) {
        return false;
    }
    const { type, originalValue, relatedObj } = v as any;
    if (relatedObj === null) {
        return true;
    }
    return !_.isNil(type) && !_.isNil(originalValue) && !_.isNil(relatedObj);
};

export const defaultWritingDataParser = (prevobj: any) => {
    return makeEmptyStringNullValue(makeObjectOriginalValue(prevobj), false);
};

export const makeEmptyStringNullValue = (prevobj: any, deep?: boolean) => {
    deep = _.isUndefined(deep) ? true : false;
    let obj = prevobj;
    if (deep) {
        obj = _.cloneDeep(prevobj);
    }

    delete obj.relation_object_map;

    const keys = _.keys(obj);
    for (const key of keys) {
        const value = obj[key];
        if (_.trim(value) === '') {
            obj[key] = null;
        }
    }
    return obj;
};

export const makeObjectOriginalValue = (prevobj: any, deep?: boolean) => {
    deep = _.isUndefined(deep) ? true : false;
    let obj = prevobj;
    if (deep) {
        obj = _.cloneDeep(prevobj);
    }

    delete obj.relation_object_map;

    const keys = _.keys(obj);
    for (const key of keys) {
        const value = obj[key];
        if (isRelatedObjValue(value)) {
            obj[key] = value.originalValue;
        }
    }
    return obj;
};

export const transformAllArrayToString = (obj: any) => {
    const newObj: {
        [key: string]: any;
    } = {};
    // tslint:disable-next-line:forin
    for (const key in obj) {
        const element = obj[key];
        if (Array.isArray(element) && !element.length) {
            newObj[key] = '';
            continue;
        }
        if (
            Array.isArray(element) &&
            (typeof element[0] === 'string' || typeof element[0] === 'number')
        ) {
            newObj[key] = element.join(',');
        } else {
            newObj[key] = element;
        }
    }
    return newObj;
};

export const transformAllStringToArray = (obj: any) => {
    const newObj: {
        [key: string]: any;
    } = {};
    // tslint:disable-next-line:forin
    for (const key in obj) {
        const element = obj[key];
        if (typeof element === 'string' && element.indexOf(',')) {
            newObj[key] = element.split(',');
        } else {
            newObj[key] = element;
        }
    }
    return newObj;
};
// 无副作用
export const transformArrayToTree = (
    obj: any[],
    parentIdIndex: string,
    idIndex: string,
) => {
    // 知道根节点是0,且其叶子只有1个
    let map: any = {};
    const cloneObj = _.cloneDeep(obj);

    if (Array.isArray(cloneObj)) {
        map = cloneObj.reduce((res, v) => {
            if (!v[idIndex]) {
                throw new Error('请检查idIndex是否合法');
            }
            res[v[idIndex]] = v;
            return res;
        }, {});
        const res = [];
        for (const item of cloneObj) {
            if (_.isNil(item[parentIdIndex])) {
                throw new Error('请检查parentIdIndex是否合法');
            }
            if (item[parentIdIndex] == 0) {
                item.isRoot = true;
                res.push(item);
                continue;
            }
            if (item[parentIdIndex] in map) {
                const parent = map[item[parentIdIndex]];
                parent.children = parent.children || [];
                parent.children.push(item);
            }
        }
        return res;
    } else {
        return [];
    }
};

export const getScrollParent = (node: any): any => {
    if (node == null) {
        return null;
    }

    if (node.scrollHeight > node.clientHeight) {
        return node;
    } else if (node.parentNode !== null) {
        return getScrollParent(node.parentNode);
    }
};

export const getFlattenRouter = (treeRouter: IRouteItem[]): any[] => {
    const flattenRouter = [] as any;
    const traverse = (r: IRouteItem[], parentPath: string) => {
        for (const item of r) {
            if (item.key === 'example') {
                continue;
            }
            delete item.icon;
            delete item.selectedRoute;
            delete item.visible;
            delete item.defaultSelectedChildrenPath;
            (item as any).fullSchema = parentPath + item.schema;

            const flattenItem = _.cloneDeep(item);
            delete flattenItem.children;
            flattenRouter.push(flattenItem);
            if (item.children !== undefined) {
                traverse(item.children, parentPath + item.schema);
            }
        }
    };
    traverse(treeRouter, '');
    return flattenRouter;
};

export const inspectRouter = (inputRouter: IRouteItem[]) => {
    const treeRouter = _.cloneDeep(inputRouter);
    const flattenRouter = getFlattenRouter(treeRouter);
    // console.log(
    //     'treeRouter',
    //     JSON.stringify(treeRouter.slice(1), ' ' as any, 4),
    // );
    // console.log('flattenRouter', JSON.stringify(flattenRouter, ' ' as any, 4));
};

export const transformDataToTreeData: (
    data: any[],
    dataKey?: string,
    parentKey?: string,
    titleKey?: string,
) => TreeNodeNormal[] = (
    data,
    dataKey = 'key',
    parentKey = 'parentKey',
    titleKey = 'title',
) => {
    const ret: TreeNodeNormal[] = [];

    const mp: { [key: string]: any } = {};
    for (const item of data) {
        mp[item[dataKey]] = item;
    }

    for (const item of data) {
        if (item[parentKey]) {
            const parent = mp[item[parentKey]];
            parent.children = parent.children || [];
            parent.children.push(item);
        } else {
            ret.push(item);
        }
    }

    return ret;
};

export const makeTicker = (cb: (curS: number) => void) => {
    let curS = 0;
    let startTs = 0;
    let timer: NodeJS.Timeout;
    return {
        start: () => {
            startTs = Date.now();
            cb(curS);
            clearInterval(timer);
            timer = setInterval(() => {
                const nextCurs = Math.floor((Date.now() - startTs) / 1000);
                if (nextCurs > curS) {
                    curS = nextCurs;
                    cb(curS);
                }
            }, 17);
        },
        reset: () => {
            curS = 0;
            startTs = 0;
            clearInterval(timer);
        },
    };
};

export const timeout = <T>(ms: number, v: T): Promise<T> =>
    new Promise(resolve => setTimeout(() => resolve(v), ms));

export function isNumeric(str: string) {
    return !_.isNaN(str) && !_.isNaN(parseFloat(str));
}

export function toNum(str: string) {
    if (isNumeric(str)) {
        return parseFloat(str);
    }
    return NaN;
}

export function getQueryParam(...args: any[]) {
    const matches = decodeURIComponent(
        decodeURIComponent(window.location.search),
    ).match(/([^\?\=\&]+\=[^\&]+)/g);
    if (matches) {
        const querys: any = {};
        // tslint:disable-next-line:only-arrow-functions
        matches.forEach(function(tmp) {
            const kv = tmp.split('=');
            kv[1] &&
                kv[1] !== 'undefined' &&
                kv[1] !== 'null' &&
                (querys[kv[0]] = kv[1]);
        });
        return args.length
            ? args.length === 1
                ? querys[args[0]]
                : pick(querys, args)
            : querys;
    }
}
export function pick(obj: any, keys: any) {
    const returnObj = Object.create(null);
    if (typeof keys === 'string') {
        if (obj[keys]) {
            returnObj[keys] = obj[keys];
        }
    } else if (Object.prototype.toString.call(keys) === '[object Array]') {
        for (const key of keys) {
            if (obj[key]) {
                returnObj[key] = obj[key];
            }
        }
    }
    return returnObj;
}

export const netLog: (obj: {
    linkUrl: string;
    method?: string;
    payload?: any;
    isError?: boolean;
    errorMsg?: string;
}) => Promise<any> = async ({
    linkUrl,
    method,
    payload,
    isError,
    errorMsg,
}) => {
    const url = '/bff/api/rest/alert';
    await defaultAxios.post(url, {
        linkUrl,
        method,
        payload,
        isError,
        errorMsg,
    });
};
export const sendPCDot = async (
    eventKey: string,
    eventMsg: string,
    isPV: boolean,
    eventType?: string,
    eventPage?: string,
    eventTime?: string,
) => {
    const url = '/bff/api/rest/pc-dot';
    await defaultAxios.get(url, {
        eventKey,
        eventMsg,
        isPV,
        eventType,
        eventPage,
        eventTime,
    });
};

export const callsites: any = () => {
    const _prepareStackTrace = Error.prepareStackTrace;
    Error.prepareStackTrace = (_, stack) => stack;
    const dummyE = new Error();
    const stack = dummyE.stack?.slice(1);
    Error.prepareStackTrace = _prepareStackTrace;
    return stack;
};
function S4() {
    // tslint:disable-next-line:no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}
export const uuid = () => {
    return (
        S4() +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        S4() +
        S4()
    );
};

export const fetchUserProfile = async (userPhone: number) => {
    const url = '/crm/contacts/findProfile';
    const [d, e] = await defaultAxios.get(url, {
        phone: userPhone,
    });
    console.log(d);
    if (d === null || d.data === null) {
        // message.error(d?.msg);
        return false;
    }
    if (d.data.profileUrl) {
        return d.data.profileUrl;
    } else {
        // message.error(d.msg);
        return false;
    }
};

export const toHump = (name: string) => {
    return name.replace(/\_(\w)/g, function(all, letter) {
        return letter.toUpperCase();
    });
};

export const toLine = (name: string) => {
    return name.replace(/([A-Z])/g, '_$1').toLowerCase();
};

export const getValueOrDefault = (
    value: string | undefined,
    defaultValue = '-',
) => {
    return value ?? defaultValue;
};

export function convertToUnicode(str: string) {
    let unicodeString = '';
    for (let i = 0; i < str.length; i++) {
        unicodeString +=
            '\\u' +
            str
                .charCodeAt(i)
                .toString(16)
                .toUpperCase()
                .padStart(4, '0');
    }
    return unicodeString;
}
