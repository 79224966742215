import $ from 'jquery';

const isSpliter: <T extends { className: string }>(obj: T) => boolean = obj => {
    if (
        obj.className &&
        obj.className.indexOf &&
        obj.className.indexOf('th-spliter') > -1
    ) {
        return true;
    }
    return false;
};

export const makeAntTableDraggable = (
    basicTable: HTMLDivElement,
    onNewWidthDiff: (diff: number, key: string) => void,
) => {
    const bt = $(basicTable);
    const cursor = bt.find('.col-resizer-cursor-outer');
    const showCursor = () => bt.addClass('cursor-moving');
    const hideCursor = () => bt.removeClass('cursor-moving');
    const updateCursorPosition = (x: number) => {
        cursor.css('left', x - (bt[0]?.getBoundingClientRect()?.x || 0) - 50);
    };

    const body = {
        startWithSpliter: false,
        startKey: '',
        startX: -1,
    };
    const reset = () => {
        console.error('reset called');
        body.startWithSpliter = false;
        body.startKey = '';
        body.startX = -1;
    };

    const handleClick = (e: any) => {
        const isTargetSpliter = isSpliter(e.target);
        if (isTargetSpliter || body.startWithSpliter) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    };
    bt.click(handleClick);

    const handleMouseDown = (e: any) => {
        const isTargetSpliter = isSpliter(e.target);
        body.startWithSpliter = false;
        if (isTargetSpliter) {
            e.preventDefault();
            e.stopPropagation();
            body.startWithSpliter = true;
        } else {
            return;
        }
        updateCursorPosition(e.clientX);
        showCursor();
        body.startX = e.clientX;
        body.startKey = $(e.target).attr('data-key') || '';
    };
    bt.mousedown(handleMouseDown);

    const handleMouseMove = (e: any) => {
        updateCursorPosition(e.clientX);
        return;
    };
    $('body').mousemove(handleMouseMove);

    const handleMouseUp = (e: any) => {
        if (body.startWithSpliter) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            return;
        }
        if (body.startKey === '') {
            return;
        }
        const endX = e.clientX;
        const diff = endX - body.startX;
        onNewWidthDiff(diff, body.startKey);
        // 让click回调也可以正常执行
        hideCursor();
        setTimeout(() => {
            reset();
        }, 10);
    };
    $('body').mouseup(handleMouseUp);

    const dispose = () => {
        $('body').unbind('mouseup', handleMouseUp);
        $('body').unbind('mousemove', handleMouseMove);
        bt.unbind('click', handleClick);
        bt.unbind('mousedown', handleMouseDown);
    };
    return dispose;
};
