import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    AntButton,
    loadingWrapperOver,
} from '@/components';
import { message, Icon } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, useBeforeFirstDataLoaded } from '@/utils';
import { observer } from 'mobx-react-lite';
import DemoTable from './components/table';
import DemoFormDrawer from './components/form-drawer';
import { runInAction } from 'mobx';
import { dFilterHOC, useDefaultDFilterSnippets } from '@/containers/hoc';
import './index.scss';

const DemoList: React.FC<RouteComponentProps & {
    type: string;
}> = ({ type = 'all' }) => {
    const store = useContext(StoreContext);
    const [defaultStore] = useState(
        () => new store.ContractInvoiceApplyListStore(),
    );
    const userStore = store.getAuthStore();

    const ack = dtext('crm')('contract_invoice_apply-list-message-' + type, '');

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        pageTitle,
        defaultPerm,
        defaultFilter,
        defaultMSelect,
        defaultFastFilters,
        condListbyFilterData,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const listFetch = useCallback(() => defaultStore.fetch(), []);
    const [error, loading, reload] = useNet(listFetch, {
        autoLoad: false,
        refreshKeys: ['contract_invoice_apply'],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            defaultMeta.setTableId('contract_invoice_apply');
            defaultStore.setSysUserId(userStore.userInfo?.sysUser?.id);
            defaultMeta.setOpUsername(userStore.userInfo.userId + '-' + type);
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {});
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useEffect(() => {
        runInAction(() => {
            defaultFastFilters.setType(type);
            defaultStore.setListType(type);
        });
    }, [type]);

    const [isBeforeFirstLoaded] = useBeforeFirstDataLoaded(defaultStore);

    const { title, fastFilters } = useDefaultDFilterSnippets(defaultStore);

    // if (!preRequiredReady) {
    //     return <BlockLoading2 />;
    // }
    const finalLoading = isBeforeFirstLoaded || !preRequiredReady;

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = (
        <>
            <DemoFormDrawer
                defaultStore={defaultStore}
                preRequiredReady={preRequiredReady}
            />
        </>
    );

    const globalOps = (
        <>
            {defaultPerm.getPermByTypeAndAction(type)('invoice_execute_apply')
                .visible && (
                <AntButton
                    onClick={() => {
                        runInAction(() => {
                            defaultStore.setAction('create');
                            defaultStore.startNewData();
                        });
                    }}
                    type="primary"
                    size="large"
                >
                    新建合同开发票申请
                </AntButton>
            )}
        </>
    );
    const multipleOps = null;

    const dataTable = (
        <DemoTable
            defaultStore={defaultStore}
            loading={loading}
            reload={reload}
        />
    );

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters: filtersDisplay,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay: fastFilters,
    };

    return loadingWrapperOver(finalLoading)(
        <LayoutStandardCrud className="page-customer-list" {...layoutComs} />,
    );
};

const FinalDemoList = observer(DemoList);
export default FinalDemoList;
