import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
} from '@/components';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet } from '@/utils';
import { observer } from 'mobx-react-lite';
import DemoTable from '@/pages/om/contract/contract-income/components/table';
import { ActionDelete } from '@/pages/om/contract/contract-income/components/delete';
import { runInAction } from 'mobx';
import { useDefaultDFilterSnippets } from '@/containers/hoc';
import './index.scss';
import FormDrawer from '@/pages/om/contract/contract-income/components/form-drawer';

const DemoList: React.FC<RouteComponentProps & {
    type: string;
    drawerData: any;
}> = ({ type = 'all', drawerData }) => {
    const currentContract = (drawerData.originData || [])[0] || {};

    const store = useContext(StoreContext);
    const [defaultStore] = useState(() => new store.ContractIncomeListStore());
    const userStore = store.getAuthStore();

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        pageTitle,
        defaultPerm,
        defaultFilter,
        defaultMSelect,
        defaultFastFilters,
        condListbyFilterData,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const listFetch = useCallback(() => defaultStore.fetch(), []);
    const [error, loading, reload] = useNet(listFetch, {
        autoLoad: false,
        refreshKeys: ['sale_income'],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);

    useEffect(() => {
        if (!currentContract.id) {
            return;
        }
        defaultStore.setDefaultFilters([
            {
                fieldId: 'contract_id',
                filterValue: currentContract.id,
                operateType: '=',
                parser: 'string',
            },
        ]);
    }, [drawerData]);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            defaultMeta.setTableId('sale_income');
            defaultMeta.setOpUsername(
                userStore.userInfo.userId + '-contract-drawer-' + type,
            );
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {});
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useEffect(() => {
        runInAction(() => {
            defaultFastFilters.setType(type);
            defaultStore.setListType(type);
        });
    }, [type]);

    if (!preRequiredReady) {
        return <BlockLoading2 />;
    }

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = (
        <>
            <ActionDelete defaultStore={defaultStore} />
            <FormDrawer
                defaultStore={defaultStore}
                preRequiredReady={preRequiredReady}
            />
        </>
    );

    const globalOps = null;
    const multipleOps = null;

    const dataTable = (
        <DemoTable defaultStore={defaultStore} loading={loading} />
    );

    const layoutComs = {
        popups,
        title: null,
        globalOps,
        filters: null,
        multipleOps,
        dataTable,
        message: null,
        filtersDisplay,
    };

    return <LayoutStandardCrud className="opt-tab" {...layoutComs} />;
};

const FinalDemoList = observer(DemoList);
export default FinalDemoList;
