import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import { Collapse, Icon, message, Spin } from 'antd';
import CollapsePanel, {
    CollapsePanelProps,
} from 'antd/lib/collapse/CollapsePanel';
import { getCusInfo, getCusContactPerson } from './api';
import { useRequest } from 'ahooks';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { IAddFollowLogPropsFromEventOrProps } from '../../add-follow-log';
import { defaultAxios, useNet } from '@/utils';

export interface ICustomerInfoCardProps {
    id?: string | number;
    entityType: string;
    actionLogConfig: any;
    customerId: number;
    setFollowLogModalVis: (bool: boolean) => void; // 单独抽出活动记录
}

export const Key2LabelOfNew: {
    [key: string]: string;
} = {
    relateResults: '联系结果',
    contactsId: 'KP联系人',
    outerNetReleaseJob: '外网发布职位',
    futureCusOneYear: '客户未来一年所需人数',
    dutyPeriod: '到岗周期',
    positionType: '岗位类型',
    salaryRange: '薪资范围',
    isBuyAct: '其他平台(2个以上)有无采买行为',
    isHeadhuntingWork: '是否和猎头合作',
    maimaiInterest: '对脉脉的兴趣点',
};

export const Key2LabelOfOld: {
    [key: string]: string;
} = {
    relateResults: '联系结果',
    contactsId: 'KP联系人',
    dutyPeriod: '到岗周期',
    futureCusOneYear: '客户未来一年所需人数',
    salaryRange: '薪资范围',
    positionType: '岗位类型',
    cusRenewalRisk: '客户续约风险',
    companyAndPlatform: '公司猎头和平台预算',
    isHistoryDataNormal: '过往使用数据是否正常',
};

const MyCollapsePanel: React.FC<CollapsePanelProps & {
    isActive?: boolean;
    // permFollow?: boolean;
    // setFollowLogModalVis: Function;
}> = props => {
    const renderHeader = () => {
        console.log('props', props);
        const { header, isActive } = props;
        return (
            // <div className="crm-collapse-header">
            //     {props.header}
            //     <Icon
            //         style={{ color: '#D1D3DE', marginLeft: '5px' }}
            //         type="caret-right"
            //         rotate={isActive ? 90 : 0}
            //     />
            // </div>
            <p className="title">
                <span
                    className="title-content"
                    // onClick={() => SetIsActive(!isActive)}
                >
                    {header}
                    <Icon
                        style={{
                            color: '#D1D3DE',
                            marginLeft: '5px',
                        }}
                        type="caret-right"
                        rotate={isActive ? 90 : 0}
                    />
                </span>
            </p>
        );
    };
    return (
        <CollapsePanel showArrow={false} {...props} header={renderHeader()} />
    );
};

const CustomerInfoCard: React.FC<ICustomerInfoCardProps & {
    isNewCustomer?: number;
    isCustomerInfoEditAble: boolean;
}> = props => {
    const {
        setFollowLogModalVis,
        customerId,
        actionLogConfig,
        id,
        isNewCustomer,
        isCustomerInfoEditAble,
        entityType,
    } = props;
    const store = useContext(StoreContext);
    const customerListStore = store.getDefaultCustomerListStore();
    // 在这写;
    // const { data: cusInfo, loading: cusInfoLoading } = useRequest(
    //     () => getCusInfo(customerId),
    //     {
    //         refreshDeps: [customerId],
    //     },
    // );
    const [cusInfo, setCusInfo] = useState<any>({});
    useEffect(() => {
        customerListStore.defaultEE.on('add-follow-log-success', reload);
    }, [cusInfo]);
    // 客户信息
    const fetchCustomerInfo: () => Promise<any> = async () => {
        const thisId = id;
        if (_.isNil(id)) {
            return [null, null];
        }
        console.log('cusInfo');

        const [d, e] = await defaultAxios.get(`/crm/activationRecord/cusInfo`, {
            customerId,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d && d.data) {
            console.log('activationRecord/cusInfo', d);

            if (thisId === id) {
                setCusInfo(d?.data || {});
            }
        } else {
            if (d.msg) {
                message.error(d.msg);
            }
            setCusInfo({});
        }
        return [data, error];
    };
    const [error, loading, reload] = useNet(fetchCustomerInfo, {
        defaultLoading: false,
    });

    const {
        data: cusContactPerson,
        loading: cusContactPersonLoading,
    } = useRequest(() => getCusContactPerson(customerId), {
        refreshDeps: [customerId],
    });

    const renderKp = () => {
        // console.log('cusContactPerson', cusContactPerson, cusInfo);

        // _.forEach(cusContactPerson, obj => {
        //     if (cusInfo?.contactsId === obj.id) {
        //         name = obj.name;
        //     }
        // });
        const target = _.find(
            cusContactPerson,
            person => person.id === cusInfo?.contactsId,
        );
        const name = target?.name || '--';
        const value =
            actionLogConfig?.['isDecision']?.[target?.['isDecision'] - 1]
                ?.label || '--';
        return name + ' ' + value;
    };

    const clickForm = () => {
        // setFollowLogModalVis(
        //     true,
        // );
        let addFollowData: {
            [key: string]: any;
        } = {};
        Object.keys(
            isNewCustomer === 1 ? Key2LabelOfNew : Key2LabelOfOld,
        ).forEach((key: string) => {
            addFollowData[key] = cusInfo?.[key];
        });
        const followConfig = {
            id: customerId,
            cid: id,
            entityType: entityType,
            targetFixed: false,
            addFollowData: {
                ...addFollowData,
                contactsId: cusInfo?.contactsId || 0,
            },
        };

        customerListStore.defaultEE.emit('save-action-log-show', followConfig);
    };

    console.log('cusInfo', cusInfo, cusContactPerson);
    return (
        <>
            <Spin spinning={loading || cusContactPersonLoading}>
                <div className="customer-drawer-info-card">
                    <Collapse
                        key={'Collapse_info_card'}
                        className="info-card-collapse"
                        bordered={false}
                        expandIcon={({ isActive }) => (
                            <Icon
                                type="caret-right"
                                rotate={isActive ? 90 : 0}
                            />
                        )}
                    >
                        <MyCollapsePanel
                            header="客户信息"
                            key="info_card"
                            // permFollow={permFollow}
                            // setFollowLogModalVis={setFollowLogModalVis}
                        >
                            <div className="info-card-container">
                                {isNewCustomer === 1 &&
                                    Object.keys(Key2LabelOfNew).map(
                                        (key: string) => {
                                            console.log(
                                                'cusContactPerson',
                                                key,
                                            );

                                            const value =
                                                actionLogConfig?.[key]?.[
                                                    cusInfo?.[key] - 1
                                                ]?.label || '--';
                                            return (
                                                <div className="item" key={key}>
                                                    <label className="item-header">
                                                        {Key2LabelOfNew[key]}
                                                    </label>
                                                    <div className="item-body">
                                                        {key === 'contactsId'
                                                            ? renderKp()
                                                            : value}

                                                        {isCustomerInfoEditAble ? (
                                                            <Icon
                                                                type="form"
                                                                onClick={
                                                                    clickForm
                                                                }
                                                            />
                                                        ) : null}
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}
                                {isNewCustomer === 0 &&
                                    Object.keys(Key2LabelOfOld).map(
                                        (key: string) => {
                                            const value =
                                                actionLogConfig?.[key]?.[
                                                    cusInfo?.[key] - 1
                                                ]?.label || '--';
                                            return (
                                                <div className="item" key={key}>
                                                    <label className="item-header">
                                                        {Key2LabelOfOld[key]}
                                                    </label>
                                                    <div className="item-body">
                                                        {key === 'contactsId'
                                                            ? renderKp()
                                                            : value}
                                                        <Icon
                                                            type="form"
                                                            onClick={clickForm}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}
                            </div>
                        </MyCollapsePanel>
                    </Collapse>
                </div>
            </Spin>
        </>
    );
};

export default CustomerInfoCard;
