import React, { useEffect } from 'react';
import LeadForm from './form-drawer';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Modal } from 'antd';
import { tryRefresh } from '@/utils';
import { useJobStoreJustForForm } from '../../../utils';
import _ from 'lodash';
import { genCustomerIdParamByCustomerItemAndParamMeta } from '@/pages/sales-follow-up/custom/list/store';
import { getJobStore } from '../../registry';

export const CustomerActivityFollowJobCreate: React.FC<{
    trigger: number;
    customers: any[];
    // id
    // customer_name
    followLog: any;
    onSuccess: () => void;
}> = observer(({ trigger, customers, followLog, onSuccess }) => {
    const selectedCus = _.isArray(customers) && customers.length > 0;
    const isMultipleFollowCus = customers.length > 1;
    // 声明一个job store，并初始化
    const [prepared, defaultStore, prepareStore] = useJobStoreJustForForm(
        getJobStore('task_activation'),
        'task_activation',
    );
    const { actionType } = defaultStore;

    useEffect(() => {
        // console.log('bad', trigger, selectedCus, followLog);
        if (trigger > 0 && selectedCus && followLog !== null) {
            prepareStore();
            runInAction(() => {
                defaultStore.setAction('create');
            });
        }
    }, [trigger, selectedCus, followLog]);

    useEffect(() => {
        // console.log(
        //     'bad',
        //     actionType,
        //     prepared,
        //     followLog,
        //     customers,
        //     isMultipleFollowCus,
        // );
        if (!prepared || actionType !== 'create') {
            return;
        }
        defaultStore.startNewData();
        if (!selectedCus || !followLog) {
            return;
        }

        // activation_id

        // arType: 1
        // contactsId: undefined
        // customerId: 374028
        // followPurpose: undefined
        // followType: 10
        // isCreateJob: 1
        // leadId: undefined
        // nextDate: ""
        // nextNote: undefined
        // note: "发发发"
        // opportunityId: undefined
        // scheduleDate: undefined
        // scheduleNote: undefined

        if (followLog.followType) {
            defaultStore.mutatingData.follow_mode = followLog.followType;
        }

        if (followLog.followTarget) {
            defaultStore.mutatingData.follow_target = followLog.followTarget;
        }

        // if (followLog.createdData?.id) {
        //     defaultStore.mutatingData.activation_id = {
        //         originalValue: followLog.createdData?.id,
        //         relatedObj: {
        //             id: followLog.createdData?.id,
        //         },
        //         type: 'obj',
        //     };
        // }

        if (followLog.contactObj?.id) {
            defaultStore.mutatingData.customer_contact = {
                type: 'obj',
                originalValue: followLog.contactObj.id,
                relatedObj: {
                    id: followLog.contactObj.id,
                    contacts_name: followLog.contactObj.name,
                },
            };
        }

        // 将客户信息转换成字段信息（可多选）
        // 将活动记录的信息转化成字段信息
        if (isMultipleFollowCus) {
            defaultStore.mutatingData.follow_customer = customers.map(item => {
                return genCustomerIdParamByCustomerItemAndParamMeta(item);
            });
        } else {
            defaultStore.mutatingData.follow_customer = genCustomerIdParamByCustomerItemAndParamMeta(
                customers[0],
            );
        }
    }, [prepared, followLog, isMultipleFollowCus, customers]);

    if (trigger === 0) {
        return null;
    }

    // 根据外部抽屉数据的状态和内部leadstore准备状态
    const preRequiredReady = prepared;
    // return null
    return (
        <LeadForm
            defaultStore={defaultStore}
            preRequiredReady={preRequiredReady}
            onSuccess={onSuccess}
            isMultipleFollowCus={isMultipleFollowCus}
            customers={_.cloneDeep(customers)}
        />
    );
});
