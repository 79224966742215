import React, { useState, forwardRef, useEffect } from 'react';

import { Tag, Tooltip } from 'antd';
import _ from 'lodash';

interface Option {
    label: string;
    value: string;
    comment?: string;
}

export interface ISelectGroupProps {
    defaultValue?: number[];
    value?: number[];
    option: Option[];
    // 单选 or 多选
    multiple?: boolean;
    // 必选
    required?: boolean;
    onChange?: (value: any) => void;
}

const SelectGroup = ({
    defaultValue = [],
    value,
    option,
    multiple = true,
    required = false,
    onChange,
}: ISelectGroupProps) => {
    const [selector, setSelector] = useState<number[]>(defaultValue);

    const handleChange = (checked: boolean, id: number) => {
        let select = value ? [...value] : [...selector];
        if (!multiple) {
            select = [];
        }

        if (checked) {
            select.push(id);
        } else {
            const newSelect = select.filter(item => id !== item);
            if (required && !newSelect.length) return;
            select = newSelect;
        }

        setSelector(select);
        onChange?.(select);
    };

    return (
        <div>
            {option.map(item => {
                return (
                    <Tooltip title={item.comment} key={item.value}>
                        <Tag.CheckableTag
                            checked={
                                value
                                    ? value.includes(Number(item.value))
                                    : selector.includes(Number(item.value))
                            }
                            onChange={checked =>
                                handleChange(checked, Number(item.value))
                            }
                            style={{ border: '1px solid #ccc' }}
                        >
                            {item.label}
                        </Tag.CheckableTag>
                    </Tooltip>
                );
            })}
        </div>
    );
};

export default forwardRef(SelectGroup);
