import React, { useState } from 'react';
import {
    DTable,
    isMultipleCell,
    ICell,
    makeObjColClickable,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ContractProductListStore } from '../store';
import { runInAction } from 'mobx';
import { applyCustomConfToCellsSelector } from '@/components/common/form/advanced-filters';
import { useContractDrawerSnippets } from '@/pages/om/contract/list/drawer/hooks';

const DemoTable: React.FC<{
    defaultStore: ContractProductListStore;
    loading: boolean;
    reload?: any;
}> = ({ defaultStore, loading, reload }) => {
    const [contractDrawerLid, setContractDrawerCid] = useState(undefined);
    const [contractDrawerVis, setContractDrawerVis] = useState(false);
    const [____, cusContractSnippet] = useContractDrawerSnippets({
        drawerVis: contractDrawerVis,
        setDrawerVis: setContractDrawerVis,
        itemId: contractDrawerLid,
    });

    const dataTable = (
        <>
            {cusContractSnippet}
            <DTable
                onColumnsUpdated={() => reload && reload()}
                defaultStore={defaultStore}
                sceneCode={'crm_contract'}
                propsTransformer={props => {
                    props.loading = loading;

                    props.actions = [
                        {
                            actionKey: 'open_apply',
                            // PERMTODO 这里好像就是要复用合同的？
                            actionCn: '申请开通',
                            render: (data, original) => {
                                if (
                                    +data.product_type === 1 &&
                                    +data.product_version === 6 &&
                                    (+data.execute_status === 4 ||
                                        +data.execute_status === 1)
                                ) {
                                    return original;
                                }
                                if (+data.execute_status === 1) {
                                    return (
                                        <span style={{ color: 'gray' }}>
                                            已提交
                                        </span>
                                    );
                                }
                                if (+data.execute_status === 2) {
                                    return (
                                        <span style={{ color: 'gray' }}>
                                            执行完成
                                        </span>
                                    );
                                }
                                return original;
                            },
                        },
                    ];
                    props.onAction = (key, data) => {
                        if (key === 'see-equity-detail') {
                            // 其他动作
                            runInAction(() => {
                                defaultStore.setAction('see-equity-detail');
                                defaultStore.setMutatingData(data);
                            });
                        }
                        if (key === 'open_apply') {
                            // runInAction(() => {
                            //     defaultStore.setAction('execute');
                            //     defaultStore.setMutatingData(data);
                            // });
                            if (data?.deliver_method === 2) {
                                runInAction(() => {
                                    defaultStore.setAction(
                                        'start-process-guzhu',
                                        {
                                            id: data.id,
                                        },
                                    );
                                });
                            } else {
                                runInAction(() => {
                                    defaultStore.setAction('start-process', {
                                        id: data.id,
                                    });
                                });
                            }
                        }
                        if (_.startsWith(key, 'reveal-obj/')) {
                            // 关联对象都可以点击
                            if (key.indexOf('/contract_id') !== -1) {
                                setContractDrawerCid(
                                    data.contract_id?.originalValue,
                                );
                                setContractDrawerVis(true);
                            }
                        }
                    };

                    props.closeRowSelection = true;
                    props.widthInfoKey = 'contract-product-list-default';
                    return props;
                }}
                cellsSelectorTransformer={baiscCellsSelector => {
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'equity_detail',
                        () => {
                            const nextCellSelector: ICell<any> = {
                                colKey: 'equity_detail',
                                inputs: ['equity_detail'],
                                cell: 'EquityDetail',
                            };
                            return nextCellSelector;
                        },
                    );
                    makeObjColClickable(baiscCellsSelector, 'contract_id');

                    return baiscCellsSelector;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
