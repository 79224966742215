import EE from 'eventemitter3';

export const ManagementEventEmitter = new EE();

export const WorthEditorEventEmitter = new EE();

export const MineEventEmitter = new EE();

// 高级filter专用，前提页面上只有一个正在工作的高级filter
// TOFIX: 在客户list 打开客户详情，在到线索list，同时存在两个，已经可能触发bug了
export const ADFilterEE = new EE();

// 跟进线索，把消息发给活动记录
export const FollowLeadEE = new EE();

// 组织成员管理
export const OPMEE = new EE();

// 菜单数据
export const MenuStatisticEE = new EE();
