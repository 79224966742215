import React, { useState, useEffect } from 'react';
import CompositeLiveChartsComponents from '../../composite-live-charts';
import { EChartsOption } from 'echarts';
import _ from 'lodash';
import './index.less';
import { TableLv2 } from '@/components';
import { Select } from 'antd';
import {
    IAchievementSituationFilterData,
    areaOptions,
    rangeOptions,
    trans2W,
} from '../../achievement-situation';

interface IPerformanceBusinessProductProps {
    asData: {
        product_sale_info?: {
            product_type: string;
            product_money: number; // 回款金额
            product_money_rate: number;
            product_num: number; // 产品单量
            product_num_rate: number;
            product_price: number; // 项目单价
        }[];
    };
    asLoading: boolean;
    asFilterData: IAchievementSituationFilterData;
    setAsFilterData: (fData: IAchievementSituationFilterData) => void;
}
const PerformanceBusinessProduct: React.FC<IPerformanceBusinessProductProps> = props => {
    const { asLoading, asData = {}, asFilterData, setAsFilterData } = props;
    const { product_sale_info = [] } = asData;
    const incomeMoneyChartsData = _.map(product_sale_info, info => {
        return {
            value: trans2W(info.product_money),
            name: info.product_type,
        };
    });
    const customerNumberChangeChartsData = _.map(product_sale_info, info => {
        return {
            value: Math.round(info.product_num),
            name: info.product_type,
        };
    });
    const incomeMoneyOptions: EChartsOption = {
        tooltip: {
            trigger: 'item',
        },
        grid: {
            width: '100%',
            height: '100%',
            containLabel: true,
        },
        color: [
            '#0052FF',
            '#F5A623',
            '#91cc75',
            '#ee6666',
            '#73c0de',
            '#3ba272',
            '#fc8452',
            '#9a60b4',
            '#ea7ccc',
            '#CC9B71 ',
            '#FF2C2C',
        ],
        legend: {
            height: '50%',
            left: '50%',
            top: '25%',
            // width: '30%',
            itemWidth: 10,
            itemHeight: 10,
            icon: 'circle',
            orient: 'vertical',
            itemGap: 30,
            textStyle: {
                overflow: 'truncate',
                width: '200',
                rich: {
                    a: {
                        color: '#6E727A',
                        fontSize: '14',
                    },
                    b: {
                        color: '#6E727A',
                        fontSize: '14',
                        padding: [0, 0, 0, 40],
                    },
                },
            },
            formatter: (name: string) => {
                const rateNum = _.find(product_sale_info, info => {
                    return info.product_type === name;
                })?.product_money_rate;
                if (rateNum) {
                    return [
                        `{a|${name}}`,
                        `{b|${Math.round(rateNum * 100) / 100}%}`,
                    ].join(' ');
                } else {
                    return `{a|${name}}`;
                }
            },
        },
        series: [
            {
                name: '回款金额',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                },
                left: '0',
                right: '100%',
                width: '60%',
                center: ['40%', '50%'],
                emphasis: {
                    label: {
                        show: true,
                        // fontSize: '40',
                        fontWeight: 'bold',
                    },
                },
                labelLine: {
                    show: false,
                },
                data: incomeMoneyChartsData,
            },
        ],
    };
    const customerNumberOptions: EChartsOption = {
        tooltip: {
            trigger: 'item',
        },
        grid: {
            width: '100%',
            height: '100%',
            containLabel: true,
        },
        color: [
            '#0052FF',
            '#F5A623',
            '#91cc75',
            '#ee6666',
            '#73c0de',
            '#3ba272',
            '#fc8452',
            '#9a60b4',
            '#ea7ccc',
            '#CC9B71 ',
            '#FF2C2C',
        ],
        legend: {
            // right: '0',
            left: '50%',
            top: '25%',
            height: '50%',
            // width: '30%',
            itemWidth: 10,
            itemHeight: 10,
            icon: 'circle',
            orient: 'vertical',
            itemGap: 30,
            textStyle: {
                overflow: 'truncate',
                width: '200',
                rich: {
                    a: {
                        color: '#6E727A',
                        fontSize: '14',
                    },
                    b: {
                        color: '#6E727A',
                        fontSize: '14',
                        padding: [0, 0, 0, 40],
                    },
                },
            },
            formatter: (name: string) => {
                const rateNum = _.find(product_sale_info, info => {
                    return info.product_type === name;
                })?.product_num_rate;
                if (rateNum) {
                    return [
                        `{a|${name}}`,
                        `{b|${Math.round(rateNum * 100) / 100}%}`,
                    ].join(' ');
                } else {
                    return `{a|${name}}`;
                }
            },
        },
        series: [
            {
                name: '付费单量',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                },
                left: '0',
                right: '100%',
                width: '60%',
                center: ['40%', '50%'],
                emphasis: {
                    label: {
                        show: true,
                        // fontSize: '40',
                        fontWeight: 'bold',
                    },
                },
                labelLine: {
                    show: false,
                },
                data: customerNumberChangeChartsData,
            },
        ],
    };
    const productPriceOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        // legend: {
        //     bottom: '0',
        //     height: '10%',
        //     itemWidth: 10,
        //     itemHeight: 10,
        //     itemGap: 20,
        //     textStyle: {
        //         color: '#6E727A',
        //     },
        // },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            top: '10%',
            width: '100%',
            height: '90%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: product_sale_info.map(item => {
                return item.product_type;
            }),
        },
        yAxis: {
            type: 'value',
        },
        color: [
            '#0052FF',
            '#F5A623',
            '#91cc75',
            '#ee6666',
            '#73c0de',
            '#3ba272',
            '#fc8452',
            '#9a60b4',
            '#ea7ccc',
            '#CC9B71 ',
            '#FF2C2C',
        ],
        series: [
            {
                name: '项目单价',
                type: 'bar',
                colorBy: 'data',
                barWidth: '30%',
                data: product_sale_info.map(item => {
                    return Math.round(item.product_price / 100) / 100;
                }),
            },
        ],
    };
    return (
        <div className="p-b-p-composite-content">
            <div className="p-b-p-header">
                <span className="p-b-p-title">业绩成分-商业产品</span>
                <div className="a-s-f-filter">
                    <div className="a-s-f-item">
                        <label htmlFor="">区域</label>
                        <Select
                            size="small"
                            value={asFilterData.area}
                            onChange={(value: any) => {
                                setAsFilterData({
                                    ...asFilterData,
                                    area: value,
                                });
                            }}
                            style={{
                                minWidth: '80px',
                            }}
                        >
                            {_.map(areaOptions, option => {
                                return (
                                    <Select.Option value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div className="a-s-f-item">
                        <label htmlFor="">时间</label>
                        <Select
                            size="small"
                            value={asFilterData.range}
                            onChange={(value: any) => {
                                setAsFilterData({
                                    ...asFilterData,
                                    range: value,
                                });
                            }}
                            style={{
                                minWidth: '80px',
                            }}
                        >
                            {_.map(rangeOptions, option => {
                                return (
                                    <Select.Option value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
            </div>
            <span className="p-b-p-tips">
                产品的回款金额与总回款业绩GAP说明：产品回款金额是按100商机的产品销售金额合计，但存在部分分期回款客户的商机已到100，但实际未全部回款
            </span>
            <div className="p-b-p-charts-content">
                <div className="p-b-p-charts-row">
                    <CompositeLiveChartsComponents
                        chartsOptions={incomeMoneyOptions}
                        loading={asLoading}
                        title={'回款金额占比（按大类）'}
                        extraRight={'单位：万元'}
                        chartsClassName="p-b-p-charts-content"
                    />
                    <CompositeLiveChartsComponents
                        chartsOptions={customerNumberOptions}
                        loading={asLoading}
                        title={'付费单量占比（按大类）'}
                        chartsClassName="p-b-p-charts-content"
                    />
                    <CompositeLiveChartsComponents
                        chartsOptions={productPriceOptions}
                        loading={asLoading}
                        title={'项目单价对比（按大类）'}
                        extraRight={'单位：万元'}
                        chartsClassName="p-b-p-charts-content"
                    />
                </div>
            </div>
            {/* product_type: string,
            product_money: number, // 回款金额
            product_money_rate: number,
            product_num: number, // 产品单量
            product_num_rate: number,
            product_price: number // 项目单价 */}
            <div className="p-b-p-table-content">
                <TableLv2
                    loading={asLoading}
                    className={'p-b-p-data-table'}
                    dataSource={product_sale_info}
                    closeRowSelection={true}
                    pagination={false}
                    scroll={{ x: '100%', y: 400 }}
                    size={'middle'}
                    columns={[
                        {
                            title: '产品大类',
                            dataIndex: 'product_type',
                            width: '200px',
                        },
                        {
                            title: '付费单量',
                            dataIndex: 'product_num',
                            width: '200px',
                        },
                        {
                            title: '单量占比',
                            dataIndex: 'product_num_rate',
                            width: '200px',
                            render: v => {
                                return v ? `${Math.round(v * 100) / 100}%` : '';
                            },
                        },
                        {
                            title: '回款金额（万元）',
                            dataIndex: 'product_money',
                            width: '200px',
                            render: v => {
                                return v ? trans2W(v) : '';
                            },
                        },
                        {
                            title: '金额占比',
                            dataIndex: 'product_money_rate',
                            width: '200px',
                            render: v => {
                                return v ? `${Math.round(v * 100) / 100}%` : '';
                            },
                        },
                        {
                            title: '项目单价（万元）',
                            dataIndex: 'product_price',
                            width: '200px',
                            render: v => {
                                return v ? `${Math.round(v / 100) / 100}` : '';
                            },
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default PerformanceBusinessProduct;
