import React, { useState, useEffect } from 'react';
import {
    IAchievementSituationFilterData,
    areaOptions,
    rangeOptions,
    trans2WDotTwo,
    trans2W,
} from '../../achievement-situation';
import _ from 'lodash';
import { Select } from 'antd';
import CompositeLiveChartsComponents from '../../composite-live-charts';
import './index.less';
import { observer } from 'mobx-react-lite';
import { EChartsOption } from 'echarts';

interface IPerformanceSalesAreaProps {
    salesAreaData?: {
        area_income_info: {
            completion_rate: number;
            income_id: number;
            income_money: number;
            income_name: string;
            target_money: number;
        }[];
        city_income_info: {
            completion_rate: number;
            income_id: number;
            income_money: number;
            income_name: string;
            target_money: number;
        }[];
        // industry_customer_money: {
        //     [key: string]: number;
        // };
        // industry_customer_num: {
        //     [key: string]: number;
        // };
        // industry_customer_arpu: {
        //     [key: string]: number;
        // };
    };
    asLoading: boolean;
    asFilterData: IAchievementSituationFilterData;
    setAsFilterData: (fData: IAchievementSituationFilterData) => void;
}
const PerformanceSalesArea: React.FC<IPerformanceSalesAreaProps> = props => {
    const { asFilterData, setAsFilterData, asLoading, salesAreaData } = props;
    const salesAreaOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999',
                },
            },
        },
        legend: {
            data: ['业绩目标', '实际业绩', '完成率'],
            bottom: '0',
            height: '10%',
            itemWidth: 10,
            itemHeight: 10,
            itemGap: 20,
            icon: 'rect',
            textStyle: {
                color: '#6E727A',
            },
        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '15%',
            width: '100%',
            height: '70%',
            containLabel: true,
        },
        // toolbox: {
        //     feature: {
        //         dataView: { show: true, readOnly: false },
        //         magicType: { show: true, type: ['line', 'bar'] },
        //         restore: { show: true },
        //         saveAsImage: { show: true }
        //     }
        // },
        xAxis: [
            {
                type: 'category',
                data: _.map(salesAreaData?.area_income_info || [], item => {
                    return item.income_name;
                }),
                axisPointer: {
                    type: 'shadow',
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
                name: '业绩目标',
                // min: 0,
                // max: 250,
                // interval: 50,
                alignTicks: true,
                axisLabel: {
                    formatter: '{value} 万',
                },
            },
            {
                type: 'value',
                name: '完成率',
                min: 0,
                max: 100,
                interval: 10,
                alignTicks: true,
                // axisLine: {
                //     show: false
                // },
                axisLabel: {
                    formatter: '{value}  %',
                },
            },
        ],
        series: [
            {
                name: '业绩目标',
                type: 'bar',
                color: '#F5A623',
                tooltip: {
                    valueFormatter: (value: any) => {
                        return `${value}万 `;
                    },
                },
                data: _.map(salesAreaData?.area_income_info || [], item => {
                    return trans2W(item.target_money);
                }),
            },
            {
                name: '实际业绩',
                type: 'bar',
                color: '#0052FF',
                tooltip: {
                    valueFormatter: (value: any) => {
                        return `${value}万 `;
                    },
                },
                data: _.map(salesAreaData?.area_income_info || [], item => {
                    return trans2W(item.income_money);
                }),
            },
            {
                name: '完成率',
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    valueFormatter: (value: any) => {
                        return value + ' %';
                    },
                },
                color: '#76D98D',
                data: _.map(salesAreaData?.area_income_info || [], item => {
                    return item.completion_rate;
                }),
            },
        ],
    };
    const salesCountryOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999',
                },
            },
        },
        legend: {
            data: ['业绩目标', '实际业绩', '完成率'],
            bottom: '0',
            height: '10%',
            itemWidth: 10,
            itemHeight: 10,
            itemGap: 20,
            icon: 'rect',
            textStyle: {
                color: '#6E727A',
            },
        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '15%',
            width: '100%',
            height: '70%',
            containLabel: true,
        },
        // toolbox: {
        //     feature: {
        //         dataView: { show: true, readOnly: false },
        //         magicType: { show: true, type: ['line', 'bar'] },
        //         restore: { show: true },
        //         saveAsImage: { show: true }
        //     }
        // },
        xAxis: [
            {
                type: 'category',
                data: _.map(salesAreaData?.city_income_info, item => {
                    return item.income_name;
                }),
                axisPointer: {
                    type: 'shadow',
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
                name: '业绩目标',
                // min: 0,
                // max: 250,
                // interval: 50,
                alignTicks: true,
                axisLabel: {
                    formatter: '{value} 万',
                },
            },
            {
                type: 'value',
                name: '完成率',
                min: 0,
                max: 100,
                interval: 10,
                alignTicks: true,
                // axisLine: {
                //     show: false
                // },
                axisLabel: {
                    formatter: '{value}  %',
                },
            },
        ],
        series: [
            {
                name: '业绩目标',
                type: 'bar',
                color: '#F5A623',
                tooltip: {
                    valueFormatter: (value: any) => {
                        return `${value}万 `;
                    },
                },
                data: _.map(salesAreaData?.city_income_info || [], item => {
                    return trans2W(item.target_money);
                }),
            },
            {
                name: '实际业绩',
                type: 'bar',
                color: '#0052FF',
                tooltip: {
                    valueFormatter: (value: any) => {
                        return `${value}万 `;
                    },
                },
                data: _.map(salesAreaData?.city_income_info || [], item => {
                    return trans2W(item.income_money);
                }),
            },
            {
                name: '完成率',
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    valueFormatter: (value: any) => {
                        return value + ' %';
                    },
                },
                color: '#76D98D',
                data: _.map(salesAreaData?.city_income_info || [], item => {
                    return item.completion_rate;
                }),
            },
        ],
    };
    return (
        <div className="s-a-composite-content">
            <div className="s-a-header">
                <span className="s-a-title">业绩成分-销售组织</span>
                <div className="a-s-f-filter">
                    <div className="a-s-f-item">
                        <label htmlFor="">区域</label>
                        <Select
                            size="small"
                            value={asFilterData.area}
                            onChange={(value: any) => {
                                setAsFilterData({
                                    ...asFilterData,
                                    area: value,
                                });
                            }}
                            style={{
                                minWidth: '80px',
                            }}
                        >
                            {_.map(areaOptions, option => {
                                return (
                                    <Select.Option value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div className="a-s-f-item">
                        <label htmlFor="">时间</label>
                        <Select
                            size="small"
                            value={asFilterData.range}
                            onChange={(value: any) => {
                                setAsFilterData({
                                    ...asFilterData,
                                    range: value,
                                });
                            }}
                            style={{
                                minWidth: '80px',
                            }}
                        >
                            {_.map(rangeOptions, option => {
                                return (
                                    <Select.Option value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
            </div>
            <div className="s-a-charts-content">
                <div className="s-a-charts-row">
                    <CompositeLiveChartsComponents
                        chartsOptions={salesAreaOptions}
                        loading={props.asLoading}
                        // extraRight={'单位：万元'}
                        title={'业绩完成情况（按区域）'}
                        chartsClassName="s-a-charts-content"
                    />
                    <CompositeLiveChartsComponents
                        chartsClassName="s-a-charts-content"
                        loading={props.asLoading}
                        // extraRight={'单位：万元'}
                        chartsOptions={salesCountryOptions}
                        title={'业绩完成情况（按城市）'}
                    />
                </div>
            </div>
        </div>
    );
};

export default PerformanceSalesArea;
