import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutStandardCrud, TableLv2 } from '@/components';
import TitleFilter from '../component/title-filter';
import './index.less';
import { defaultAxios } from '@/utils';
import { message, DatePicker, Button, Icon } from 'antd';
import moment from 'moment';
import _ from 'lodash';

const RangePicker = DatePicker.RangePicker;
const AnaLeadFollowUp: React.FC = () => {
    const [leadFollowUpData, setLeadFollowUpData] = useState<any>([]);
    const [leadFollowUpLoading, setLeadFollowUpLoading] = useState(false);
    const [acMenuSelect, setACMenuSelect] = useState<string>('');
    const afterDay = moment().subtract(1, 'week');
    const [taskEndTime, setTaskEndTime] = useState({
        start: afterDay.startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end: afterDay.endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    });
    const tableColumns: any = [
        {
            title: '有效任务总数',
            dataIndex: 'clue_task_valid_num',
            width: '100px',
        },
        {
            width: '100px',
            title: '按时完成数',
            dataIndex: 'clue_task_finish_num',
        },
        {
            title: '按时完成率',
            width: '100px',
            dataIndex: 'clue_task_finish_rate',
            render: (v: any) => `${v}%`,
        },
        {
            width: '100px',
            title: '逾期完成数',
            dataIndex: 'clue_task_overdue_finish_num',
        },
        {
            width: '100px',
            title: '逾期完成率',
            dataIndex: 'clue_task_overdue_finish_rate',
            render: (v: any) => `${v}%`,
        },
        {
            width: '100px',
            title: '扔回公海异常关闭任务数',
            dataIndex: 'clue_task_close_num',
        },
        {
            width: '100px',
            title: '逾期未完成数',
            dataIndex: 'clue_task_overdue_unfinish_num',
        },
        {
            width: '100px',
            title: '已取消任务数',
            dataIndex: 'clue_task_cancel_num',
        },
    ];
    if (acMenuSelect) {
        switch (acMenuSelect) {
            case 'country':
                // tableColumns.unshift({
                //     title: '目标等级',
                //     dataIndex: 'level',
                // });
                break;
            case 'personal':
                tableColumns.unshift({
                    title: '销售',
                    dataIndex: 'sale_name',
                    fixed: true,
                });
            case 'group':
                tableColumns.unshift({
                    title: '小组',
                    dataIndex: 'sale_dept_name',
                    fixed: true,
                });
            case 'city':
                tableColumns.unshift({
                    title: '城市',
                    dataIndex: 'sale_city_name',
                    fixed: true,
                });
            case 'area':
                tableColumns.unshift({
                    title: '区域',
                    dataIndex: 'sale_area_name',
                    fixed: true,
                });
            default:
                break;
        }
    }
    useEffect(() => {
        if (acMenuSelect) {
            fetchLeadFollowUpData();
        }
    }, [acMenuSelect]);
    const exportLeadFollowUpData = () => {
        const url = `/bff/api/rest/ana/sales/lead-follow-up?begin_time=${moment(
            taskEndTime.start,
        ).format('YYYY-MM-DD')}&end_time=${moment(taskEndTime.end).format(
            'YYYY-MM-DD',
        )}&exportType=${acMenuSelect}`;
        window.open(url, '_blank');
    };
    const fetchLeadFollowUpData = async () => {
        if (!acMenuSelect) {
            // 若无，则代表没权限或权限未加载
            return;
        }
        const url = '/crm/data/query/list_sale_task_statistical_model';
        setLeadFollowUpLoading(true);
        const needFiledKey = _.map(tableColumns, item => {
            return item.dataIndex;
        });
        const [d, e] = await defaultAxios.put(url, {
            begin_time: moment(taskEndTime.start).format('YYYY-MM-DD'),
            end_time: moment(taskEndTime.end).format('YYYY-MM-DD'),
            fields: needFiledKey,
        });
        setLeadFollowUpLoading(false);
        if (d === null || d.data === null) {
            message.error('查询失败～');
            setLeadFollowUpData([]);
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data)) {
            setLeadFollowUpData(data);
        } else {
            setLeadFollowUpData([]);
            message.error(data?.msg || '查询失败～');
        }
    };
    const onActionSearchTimeChange = (
        dates: any,
        dateStrings: [string, string],
    ) => {
        // const limitStart = moment(dateStrings[1])
        //     .subtract('3', 'months')
        //     .startOf('days');
        // const startMoment = moment(dateStrings[0]).startOf('days');
        // if (limitStart.isAfter(startMoment)) {
        //     message.warning('请选择小于或等于三个月的时间区间～');
        //     return;
        // }
        setTaskEndTime({
            start: dateStrings[0],
            end: dateStrings[1],
        });
        // reload();
    };
    const renderFilter = () => {
        return (
            <>
                <div
                    className="ana-filter-item"
                    style={{
                        marginRight: '8px',
                        display: 'inline-block',
                    }}
                >
                    <label htmlFor="time">任务截止日期：</label>
                    <RangePicker
                        name="time"
                        disabled={leadFollowUpLoading}
                        value={[
                            moment(taskEndTime.start),
                            moment(taskEndTime.end),
                        ]}
                        showTime={true}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={onActionSearchTimeChange}
                        style={{ width: '400px' }}
                        allowClear={false}
                        onOk={() => {
                            fetchLeadFollowUpData();
                        }}
                    />
                </div>
                <Button
                    type="primary"
                    disabled={leadFollowUpLoading}
                    onClick={() => {
                        exportLeadFollowUpData();
                    }}
                    style={{
                        display: 'inline-block',
                    }}
                >
                    导出
                </Button>
                <a
                    style={{
                        fontSize: '14px',
                        marginLeft: '12px',
                        fontWeight: 500,
                    }}
                    target="_blank"
                    href="https://maimai.feishu.cn/wiki/wikcnL8WLSYlPMC59IN4tccO9Id"
                >
                    <Icon
                        style={{
                            marginRight: '2px',
                        }}
                        type="question-circle"
                    />
                    口径说明
                </a>
            </>
        );
    };
    return (
        <LayoutStandardCrud
            title={
                <TitleFilter
                    pageKey={'lead-follow-up'}
                    onFilter={filterData => {
                        if (filterData) {
                            setACMenuSelect(filterData?.menu);
                        }
                    }}
                />
            }
            message={null}
            popups={null}
            multipleOps={null}
            globalOps={null}
            filters={renderFilter()}
            dataTable={
                <TableLv2
                    dataSource={leadFollowUpData}
                    loading={leadFollowUpLoading}
                    closeRowSelection={true}
                    pagination={false}
                    scroll={{ x: '1000px' }}
                    columns={tableColumns}
                />
            }
        />
    );
};

export default observer(AnaLeadFollowUp);
