import React, { useState, useEffect } from 'react';
import moment from 'moment';

const PerformanceClockItem: React.FC = () => {
    const [clockTime, setClockTime] = useState<any>();
    useEffect(() => {
        setClockTime(moment());
        const timerClock = setInterval(() => {
            setClockTime(moment());
        }, 1000);
        return () => {
            timerClock && clearInterval(timerClock);
        };
    }, []);
    return (
        <span className="a-s-time">
            {clockTime && clockTime.format('YYYY-MM-DD A hh:mm:ss')}
        </span>
    );
};
export default PerformanceClockItem;
