/*
 * @Author: your name
 * @Date: 2020-12-09 11:08:29
 * @LastEditTime: 2020-12-16 17:31:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /maimai_crm/src/stores/setting-chspg/index.js
 */

import { makeAutoObservable, toJS } from 'mobx';
import { createDefaultInstanceGetter } from '../../../stores/utils/index';
import { IHSPGDataTypes } from './index';
import { SorterStore } from '../../../stores/utils/sorter';
import { defaultAxios } from '@/utils';
import { message } from 'antd';
import _ from 'lodash';
import {
    PaginationStore,
    TPaginationStoreInstance,
} from '../../../stores/utils/pagination';
import moment from 'moment';
import { IRuleSettingData } from './rule-setting-modal/options';
import { IMemberSettingData } from './member-setting-modal/options';
import { IDepartSettingInfoType } from '@/stores/setting';

interface ICHSPGTableData {
    description?: string;
    dataAdmin?: string;
    dataDefaultAdmin?: string;
    isHighValue?: string;
    teamDeparts?: string;
    teamUsers?: string;
    returnCantClaim?: number | string;
    recycleType?: number | string;
    assignType?: number | string;
    recycleGetNotFollow?: number | string;
    recycleGetNotFollowTo?: number | string;
    inCooperationFollowup?: number | string;
    noCooperation20Opportunity?: number | string;
    noCooperation40Opportunity?: number | string;
    recycleGetNoNewOpportunity?: number | string;
    recycleNotDeal?: number | string;
    recycleDeferred?: number | string; // 延期天数

    recycleBreak?: number | string; // 断约客户
    recycleAlert?: number | string[]; // 提醒时间

    // recycleGetDefinedDeal?: string;
    // recycleDealNotFollow?: number | string;
    // recycleDealNotFollowTo?: number | string;
}
export interface ICHSPGActionLogData {
    userName?: string;
    changeDate?: string;
    changeContent?: string;
}
export type TGetCHSPGTableReturnType = NetResponse<{
    data: IHSPGDataTypes[];
    msg: string;
    code: number;
}>;
export type TGetCHSPGActionLogReturnType = NetResponse<{
    data: ICHSPGActionLogData[];
    msg: string;
    code: number;
}>;
export class SettingCHSPGStore {
    public defaultSorter = new SorterStore<IHSPGDataTypes>();
    public tableData: IHSPGDataTypes[] = [];
    public LogData: ICHSPGActionLogData[] = [];
    public ruleSettingLoading = true;
    public memberSettingLoading = true;
    // public defaultActionLogPagination: TPaginationStoreInstance = new PaginationStore();
    public actionLogDateFilter = {
        startTime: moment()
            .subtract(1, 'months')
            .startOf('days'),
        endTimme: moment().endOf('days'),
    };

    // 获得公池组列表数据
    public fetchCHSPGTableData: () => Promise<
        TGetCHSPGTableReturnType
    > = async () => {
        const url = '/bff/api/rest/chspg/list';
        const [d, e] = await defaultAxios.get(url);
        console.log(d);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (Array.isArray(d.data)) {
            this.tableData = d.data;
        }
        return [data, error];
    };

    // 获得公池配置
    public fetchCHSPGPoolSetting: (
        id: any,
        type: 'member' | 'rule',
        setData?: any,
        departInfo?: IDepartSettingInfoType[],
    ) => Promise<any> = async (id, type, setData, departInfo) => {
        console.log(id);
        console.log('请求rule');
        if (type === 'member') {
            this.memberSettingLoading = true;
        } else {
            this.ruleSettingLoading = true;
        }
        const url = '/bff/api/rest/chspg/get-setting';
        const [d, e] = await defaultAxios.post(url, {
            id,
        });
        if (type === 'member') {
            this.memberSettingLoading = false;
        } else {
            this.ruleSettingLoading = false;
        }
        if (d && d.data) {
            const typeData = d.data[type];
            if (type === 'rule') {
                const finalRuleData: IRuleSettingData = {
                    ...typeData,
                    recycleAlertOne: typeData?.recycleAlert?.[0] || '',
                    recycleAlertTwo: typeData?.recycleAlert?.[1] || '',
                    recycleAlertThree: typeData?.recycleAlert?.[2] || '',
                };
                setData(finalRuleData);
            } else {
                const finalMemberData: IMemberSettingData = {
                    ...typeData,
                    teamDeparts: _.map(typeData?.teamDeparts || [], item => {
                        const existingDepart = _.find(departInfo, dData => {
                            return dData.id == item;
                        });
                        console.log(toJS(existingDepart));
                        return {
                            value: existingDepart?.id,
                            label: existingDepart?.title,
                        };
                    }),
                };
                setData(finalMemberData);
            }
            return [typeData, e];
        } else {
            message.error('请求公海池配置错误～', 1.5);
            return [null, e];
        }
    };

    // 修改公池配置
    public setCHSPGPoolSetting: (
        id: string | number,
        updateData: ICHSPGTableData,
    ) => Promise<any> = async (id, updateData) => {
        const url = '/bff/api/rest/chspg/set-setting';
        const [d, e] = await defaultAxios.post(url, {
            id,
            ...updateData,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 新建公池
    public addCHSPG = async (addData: ICHSPGTableData) => {
        const url = '/bff/api/rest/chspg/add';
        const [d, e] = await defaultAxios.post(url, {
            ...addData,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 操作日志修改时间
    public changeCHSPGDate = (dates: any, datasSting: any) => {
        if (dates[0]) {
            this.actionLogDateFilter.startTime = dates[0];
        }
        if (dates[1]) {
            this.actionLogDateFilter.endTimme = dates[1];
        }
    };
    // 获取公池操作日志
    public getCHSPGActionLog: (
        id?: string | number,
    ) => Promise<TGetCHSPGActionLogReturnType> = async id => {
        if (!id) {
            return [null, null];
        }
        const url = '/bff/api/rest/chspg/log';
        const [d, e] = await defaultAxios.post(url, {
            id,
            startTime: this.actionLogDateFilter.startTime.valueOf(),
            endTime: this.actionLogDateFilter.endTimme.valueOf(),
            // ...this.defaultActionLogPagination.paginationArgs,
        });
        if (!d?.data) {
            this.LogData = [];
            // this.defaultActionLogPagination.amount = 0;
            return [null, e];
        }
        const data = d.data;
        if (Array.isArray(d.data)) {
            this.LogData = d.data;
            // this.defaultActionLogPagination.amount =
            //     d.data.totalSize || d.data.data.length;
        } else {
            this.LogData = [];
            // this.defaultActionLogPagination.amount = 0;
        }
        const error = e as Error | null;
        return [data.data, error];
    };

    // 删除公池
    public deleteCHSPGPool = async (id: string | number) => {
        const url = '/bff/api/rest/chspg/delete';
        const [d, e] = await defaultAxios.post(url, {
            id,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 复制公池
    public copyCHSPG = async (id: string | number) => {
        const url = '/bff/api/rest/chspg/copy';
        const [d, e] = await defaultAxios.post(url, {
            id,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    constructor() {
        makeAutoObservable(this);
    }
}

export const getDefaultSettingCHSPGStore = createDefaultInstanceGetter(
    SettingCHSPGStore,
);
