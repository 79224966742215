import React from 'react';
import _ from 'lodash';
import TableCard, { TableCardProps } from '../../components/table-card';
import TableTitle from '../../components/table-title';
import { Key2Tips } from '../../constants';
import { ColumnProps } from 'antd/lib/table';

function SolarTable<T>({ dataSource, loading, ...rest }: TableCardProps<T>) {
    console.log('dataSource', dataSource);
    const columns: ColumnProps<T>[] = [
        {
            title: '区域',
            dataIndex: 'name',
            width: 130,
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: <TableTitle title="今日回款" tips={Key2Tips['今日已回']} />,
            dataIndex: 'today_income',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        {
            title: <TableTitle title="本周回款" tips={Key2Tips['周回款']} />,
            dataIndex: 'week_income',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        {
            title: <TableTitle title="月目标" tips={Key2Tips['月目标']} />,
            dataIndex: 'month_target',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        {
            title: <TableTitle title="月回款" tips={Key2Tips['月回款']} />,
            dataIndex: 'month_income',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        // {
        //     title: <TableTitle title="月Gap" tips={Key2Tips['月Gap']} />,
        //     dataIndex: 'month_gap',
        //     render: (t: string) => {
        //         const text = Math.round(Number(t));
        //         return !_.isNaN(text) ? text : '-';
        //     },
        // },
        {
            title: <TableTitle title="月达成率" tips={Key2Tips['月达成率']} />,
            dataIndex: 'month_completion_rate',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? `${text}%` : '-';
            },
        },
        {
            title: <TableTitle title="月同比" tips={Key2Tips['月同比']} />,
            dataIndex: 'month_yoy',
            render: (t: string) => {
                const text = Math.round(Number(t));
                if (_.isNaN(text)) return '-';
                return text > 0 ? (
                    <span style={{ color: '#ff001f' }}>{`+${text}%`}</span>
                ) : (
                    <span style={{ color: '#09b300' }}>{`${text}%`}</span>
                );
            },
        },
        {
            title: <TableTitle title="月环比" tips={Key2Tips['月环比']} />,
            dataIndex: 'month_link_relative_ratio',
            render: (t: string) => {
                const text = Math.round(Number(t));
                if (_.isNaN(text)) return '-';
                return text > 0 ? (
                    <span style={{ color: '#ff001f' }}>{`+${text}%`}</span>
                ) : (
                    <span style={{ color: '#09b300' }}>{`${text}%`}</span>
                );
            },
        },
        {
            title: <TableTitle title="双月目标" tips={Key2Tips['双月目标']} />,
            dataIndex: 'session_target',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        {
            title: <TableTitle title="双月回款" tips={Key2Tips['双月回款']} />,
            dataIndex: 'session_income',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        // {
        //     title: <TableTitle title="季度Gap" tips={Key2Tips['季度Gap']} />,
        //     dataIndex: 'quarter_gap',
        //     render: (t: string) => {
        //         const text = Math.round(Number(t));
        //         return !_.isNaN(text) ? text : '-';
        //     },
        // },
        {
            title: (
                <TableTitle title="双月达成率" tips={Key2Tips['双月达成率']} />
            ),
            dataIndex: 'session_completion_rate',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? `${text}%` : '-';
            },
        },
        {
            title: <TableTitle title="双月同比" tips={Key2Tips['双月同比']} />,
            dataIndex: 'session_yoy ',
            render: (t: string) => {
                const text = Math.round(Number(t));
                if (_.isNaN(text)) return '-';
                return text > 0 ? (
                    <span style={{ color: '#ff001f' }}>{`+${text}%`}</span>
                ) : (
                    <span style={{ color: '#09b300' }}>{`${text}%`}</span>
                );
            },
        },
        {
            title: <TableTitle title="双月环比" tips={Key2Tips['双月环比']} />,
            dataIndex: 'session_link_relative_ratio',
            render: (t: string) => {
                const text = Math.round(Number(t));
                if (_.isNaN(text)) return '-';
                return text > 0 ? (
                    <span style={{ color: '#ff001f' }}>{`+${text}%`}</span>
                ) : (
                    <span style={{ color: '#09b300' }}>{`${text}%`}</span>
                );
            },
        },
        // {
        //     title: (
        //         <TableTitle title="季度M1环比" tips={Key2Tips['季度M1环比']} />
        //     ),
        //     dataIndex: 'm1',
        //     render: (t: string) => {
        //         const text = Math.round(Number(t));
        //         if (_.isNaN(text)) return '-';
        //         return text > 0 ? (
        //             <span style={{ color: '#ff001f' }}>{`+${text}%`}</span>
        //         ) : (
        //             <span style={{ color: '#09b300' }}>{`${text}%`}</span>
        //         );
        //     },
        // },
        // {
        //     title: (
        //         <TableTitle title="季度M2环比" tips={Key2Tips['季度M2环比']} />
        //     ),
        //     dataIndex: 'm2',
        //     render: (t: string) => {
        //         const text = Math.round(Number(t));
        //         if (_.isNaN(text)) return '-';
        //         return text > 0 ? (
        //             <span style={{ color: '#ff001f' }}>{`+${text}%`}</span>
        //         ) : (
        //             <span style={{ color: '#09b300' }}>{`${text}%`}</span>
        //         );
        //     },
        // },
        // {
        //     title: (
        //         <TableTitle title="季度M3环比" tips={Key2Tips['季度M3环比']} />
        //     ),
        //     dataIndex: 'm3',
        //     render: (t: string) => {
        //         const text = Math.round(Number(t));
        //         if (_.isNaN(text)) return '-';
        //         return text > 0 ? (
        //             <span style={{ color: '#ff001f' }}>{`+${text}%`}</span>
        //         ) : (
        //             <span style={{ color: '#09b300' }}>{`${text}%`}</span>
        //         );
        //     },
        // },
        {
            title: <TableTitle title="年度回款" tips={Key2Tips['年度回款']} />,
            dataIndex: 'year_income',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
    ];
    return (
        <TableCard
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            className="board-solar-table"
            style={{ boxShadow: 'none', padding: 0 }}
            rowKey="name"
            // scroll={{ x: 'max-content' }}
            scroll={{ x: 1700, y: 355 }}
            bodyStyle={{ maxHeight: 355 }}
            {...rest}
        />
    );
}

export default SolarTable;
