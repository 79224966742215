import React, { memo } from 'react';
import { AutoEllipsis } from '../../../auto-ellipsis';

const statusCnMap: { [status: string]: string } = {
    CREATE: '已创建',
    SUBMIT: '待提交',
    APPROVE: '审核中',
    REJECT: '拒绝',
    RESET: '撤回',
    COMPLETED: '已完成',
};

export const ProcessStatus: React.FC<{ args: any[] }> = memo(({ args }) => {
    const value = args[0];
    if (!value) {
        return null;
    }
    const cn = statusCnMap[value];
    if (!cn) {
        return null;
    }
    return <AutoEllipsis text={cn} />;
});
