import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { LayoutStandardCrud, AntButton, TableLv2 } from '@/components';
import { DatePicker, Input, Popover, Icon, Button } from 'antd';
import './index.less';
import moment, { Moment } from 'moment';
import { StoreContext } from '@/stores';
import { arrToJson, useNet } from '@/utils';

const leadSourceEnums = [
    { label: 'Callin线索（客服）', value: 15, selected: false },
    { label: '页面留资', value: 7, selected: false },
    { label: '市场活动', value: 2, selected: false },
    { label: '个人会员', value: 11, selected: false },
    { label: '站内行为', value: 14, selected: false },
    { label: '竞对发职位', value: 16, selected: false },
    { label: '融资', value: 18, selected: false },
    { label: '其他', value: 17, selected: false },
];
const leadSubSourceEnums = [
    { label: 'Callin线索（客服）', value: 1, selected: false },
    { label: '人才数据报告', value: 2, selected: false },
    { label: '人才银行页面', value: 3, selected: false },
    { label: '线上直播', value: 4, selected: false },
    { label: '线下专场', value: 5, selected: false },
    { label: '线下活动', value: 6, selected: false },
    { label: '开通招聘个人会员', value: 7, selected: false },
    { label: '开通商务个人会员', value: 8, selected: false },
    { label: '站内发布职位', value: 9, selected: false },
    { label: '机遇页面搜索人才', value: 10, selected: false },
    { label: '招聘发布职位', value: 11, selected: false },
    { label: '拉勾发布职位', value: 12, selected: false },
    { label: '卓聘发布职位', value: 13, selected: false },
    { label: 'IT桔子有融资', value: 14, selected: false },
    { label: '其他', value: 15, selected: false },
];

const opSourceEnums = [
    {
        label: '线索',
        value: 1,
    },
    {
        label: '自拓',
        value: 2,
    },
    {
        label: '老客户复购',
        value: 3,
    },
    {
        label: '客户转介绍',
        value: 4,
    },
];

const makeEnumRender = (enums: any[], key: string) => {
    const map = arrToJson(enums, 'value');
    return function render(v: any) {
        if (!v) {
            return '';
        }
        const opt = map?.[v];
        if (!opt) {
            return '';
        }
        return opt?.label || null;
    };
};

const sourceCols: any[] = [
    {
        title: '商机来源',
        key: 'opportunity_source',
        dataIndex: 'opportunity_source',
        render: makeEnumRender(opSourceEnums, 'opportunity_source'),
    },
    {
        title: '对应线索一级来源',
        key: 'lead_source',
        dataIndex: 'lead_source',
        render: makeEnumRender(leadSourceEnums, 'lead_source'),
    },

    {
        title: '对应线索二级来源',
        key: 'lead_sub_source',
        dataIndex: 'lead_sub_source',
        render: makeEnumRender(leadSubSourceEnums, 'lead_sub_source'),
    },
];

const commonCols = [
    {
        title: '新签商机量',
        key: 'op_new_count',
        dataIndex: 'op_new_count',
    },
    {
        title: '20商机量',
        key: 'op_20_count',
        dataIndex: 'op_20_count',
    },
    {
        title: '20商机金额',
        key: 'op_20_amount',
        dataIndex: 'op_20_amount',
    },
    {
        title: '40商机量',
        key: 'op_40_count',
        dataIndex: 'op_40_count',
    },
    {
        title: '40商机金额',
        key: 'op_40_amount',
        dataIndex: 'op_40_amount',
    },
    {
        title: '60商机量',
        key: 'op_60_count',
        dataIndex: 'op_60_count',
    },
    {
        title: '60商机金额',
        key: 'op_60_amount',
        dataIndex: 'op_60_amount',
    },
    {
        title: '80商机量',
        key: 'op_80_count',
        dataIndex: 'op_80_count',
    },
    {
        title: '80商机金额',
        key: 'op_80_amount',
        dataIndex: 'op_80_amount',
    },
    {
        title: '100商机量(赢单)',
        key: 'win_oppo_sum',
        dataIndex: 'win_oppo_sum',
    },
    {
        title: '商机赢单转化率',
        key: 'win_oppo_convert_rate',
        dataIndex: 'win_oppo_convert_rate',
    },
    {
        title: '100赢单金额',
        key: 'win_opportunity_sale_amount',
        dataIndex: 'win_opportunity_sale_amount',
    },
];

const RangePicker = DatePicker.RangePicker;
const NewOptAna: React.FC<RouteComponentProps & {
    // type: string;
}> = props => {
    // const { type } = props;
    const [columns] = useState(() => {
        return [...sourceCols, ...commonCols];
    });
    const store = useContext(StoreContext);
    const newOptStore = store.getDefaultNewOptStore();
    const anaStore = store.getDefaultAnalysisStore();
    const { getNewOptData, exportNewOptData, newOptAnaData } = newOptStore;
    const afterDay = moment().subtract(1, 'month');
    const [anaSearchValue, setAnaSearchValue] = useState<{
        feCols: any[];
        fields: string[];
        begin_time: Moment | string;
        end_time: Moment | string;
    }>({
        feCols: columns.map(col => ({ key: col.key, header: col.title })),
        fields: columns.map(col => col.key),
        begin_time: afterDay.startOf('month').format('YYYY-MM-DD'),
        end_time: afterDay.endOf('month').format('YYYY-MM-DD'),
    });
    const [error, loading, reload] = useNet(() =>
        getNewOptData(anaSearchValue),
    );

    const onActionSearchTimeChange = (
        dates: any,
        dateStrings: [string, string],
    ) => {
        setAnaSearchValue({
            ...anaSearchValue,
            begin_time: dateStrings[0].split(' ')[0],
            end_time: dateStrings[1].split(' ')[0],
        });
        // reload();
    };
    const title = '新签商机-来源';
    const renderLoginPageFilter = () => {
        return (
            <div className="ana-filter-content">
                <div className="ana-filter-item">
                    <label htmlFor="time">商机创建时间：</label>
                    <RangePicker
                        name="time"
                        value={[
                            moment(anaSearchValue.begin_time),
                            moment(anaSearchValue.end_time),
                        ]}
                        showTime={true}
                        format="YYYY-MM-DD"
                        onChange={onActionSearchTimeChange}
                        style={{ width: '400px' }}
                        allowClear={false}
                        onOk={reload}
                    />
                </div>
                <Button
                    type="primary"
                    onClick={() => {
                        reload();
                    }}
                >
                    查询
                </Button>
                {anaStore.exportVis && (
                    <Button
                        type="primary"
                        onClick={() => {
                            exportNewOptData(anaSearchValue, title);
                        }}
                    >
                        导出
                    </Button>
                )}
            </div>
        );
    };
    return (
        <div className="analysis-content">
            <LayoutStandardCrud
                title={title}
                message={null}
                popups={null}
                multipleOps={null}
                globalOps={null}
                filters={renderLoginPageFilter()}
                dataTable={
                    <TableLv2<any>
                        loading={loading}
                        dataSource={newOptAnaData}
                        closeRowSelection={true}
                        pagination={false}
                        scroll={{ x: '1660' }}
                        columns={columns}
                    />
                }
            />
        </div>
    );
};

export default observer(NewOptAna);
