import React, { memo, useContext } from 'react';
import _ from 'lodash';
import { AutoEllipsis } from '../../auto-ellipsis';
import { CtxContext } from '@/utils/context';
import { Link } from './link';
import querystring from 'querystring';

const outerLinkWhiteList: { [tableCode: string]: string } = {
    customer: '/buy/custom/my-custom',
    opportunity: '/buy/opportunity/all',
    opt: '/buy/opportunity/all',
    lead: '/buy/lead/my-lead',
    contract: '/buy/contract/all',
    contacts: '/buy/contact/all',
    task_activation: '/buy/job-management/cus-follow',
};

export const ObjItem: React.FC<{
    args: [any, any, any];
    item: any;
    onAction: (...args: any[]) => void;
}> = memo(({ args, item, onAction }) => {
    const ctx = useContext(CtxContext);

    let [d, constrict, options] = args;
    if (!_.isObject(d)) {
        d = {};
    }
    let { clickable } = options || {};
    clickable = _.isNil(clickable) ? false : clickable;
    constrict = constrict || {};
    let { originalValue, relatedObj } = d;
    relatedObj = relatedObj || {};
    originalValue = originalValue || '';
    let {
        objShowField = {},
        constraintObjMeta = {},
        objThisFieldName,
    } = constrict;
    objThisFieldName = objThisFieldName || '';
    objShowField = objShowField || {};
    constraintObjMeta = constraintObjMeta || {};

    let { objectCode } = constraintObjMeta;
    objectCode = objectCode || 'unknow';

    const actionName = 'reveal-obj/' + objectCode + '/' + objThisFieldName;
    const displayName = relatedObj[objShowField.fieldName];

    const innerDrawer = ctx.position === 'drawer';
    const innerProcess =
        ctx.position === 'list' && ctx.positionDetail === 'process-list';
    const useLink =
        (innerProcess || innerDrawer) && !!outerLinkWhiteList[objectCode];
    if (useLink) {
        return (
            <Link
                args={[
                    displayName,
                    {
                        url: outerLinkWhiteList[objectCode],
                        query: {
                            id: originalValue,
                            target: ctx.positionDetail || ctx.position,
                        },
                    },
                ]}
                item={item}
                onAction={onAction}
            />
        );
    }

    if (displayName) {
        return clickable ? (
            <a
                onClick={() => {
                    onAction(actionName, item);
                }}
            >
                <AutoEllipsis text={displayName} />
            </a>
        ) : (
            <AutoEllipsis text={displayName} />
        );
    }

    return null;
});
