import React, { useState, useEffect } from 'react';
import { OptListStore } from '../store';
import { Icon, Spin, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { isNumeric, toNum } from '@/utils';
import _ from 'lodash';

export const OptExtra: React.FC<{
    defaultStore: OptListStore;
}> = observer(({ defaultStore }) => {
    const { extraStatisticResult } = defaultStore;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        defaultStore.setExtraStatisticResuilt(null);
    }, [defaultStore.condListbyFilterData]);

    return (
        <Tooltip
            title={
                extraStatisticResult ? (
                    <div>
                        <div>商机储备金额算法逻辑</div>
                        <div>
                            1.
                            商机未转签约合同时，商机储备金额为【商机预计销售金额】合计
                        </div>
                        <div>
                            2.
                            商机转签约合同后，商机储备金额为【合同待回款金额】合计
                        </div>
                        <div>3. 输单和100商机不计入储备金额</div>
                    </div>
                ) : (
                    '点击获取【商机储备金额】合计'
                )
            }
        >
            <span
                style={{
                    cursor: 'pointer',
                    fontSize: 14,
                    color: '#0052ff',
                    fontWeight: 'bolder',
                }}
                onClick={async () => {
                    setLoading(true);
                    await defaultStore.getExtraStatistic();
                    setLoading(false);
                }}
            >
                <div
                    style={{
                        backgroundImage: `url('${process.env.PUBLIC_URL}/imgs/mis/money.png')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100%',
                        height: 14,
                        width: 14,
                        marginRight: 4,
                        display: 'inline-block',
                        position: 'relative',
                        top: '2px',
                    }}
                />
                商机储备金额
                {!_.isNil(extraStatisticResult) ? (
                    <>
                        （元）：
                        {isNumeric(extraStatisticResult)
                            ? toNum(extraStatisticResult).toLocaleString()
                            : extraStatisticResult}
                    </>
                ) : null}
                &nbsp;{' '}
                {loading ? (
                    <Icon type="loading" style={{ color: 'blue' }} />
                ) : null}
            </span>
        </Tooltip>
    );
});
