import React, { useState, useEffect, useContext } from 'react';
import './index.less';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { EChartsOption } from 'echarts';
import _ from 'lodash';
import { trans2W } from '@/pages/analysis/dashboard/composite-live/component/achievement-situation';
import CompositeLiveChartsComponents from '@/pages/analysis/dashboard/composite-live/component/composite-live-charts';
import { Select } from 'antd';
import { homeRangeOptions } from '../../store';
const HomeSalesArea: React.FC = () => {
    const store = useContext(StoreContext);
    const newHomeStore = store.getDefaultHomeNewStore();
    const {
        homeNewFilterData,
        setHomeNewFilterData,
        homeNewSaleAreaData,
        homeNewSaleLoading,
        homeNewSelectedData,
        setHomeNewSelectedData,
        getHomeSalesAreaData,
    } = newHomeStore;
    useEffect(() => {
        getHomeSalesAreaData();
    }, [homeNewFilterData.range, homeNewSelectedData]);
    const salesAreaOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999',
                },
            },
        },
        legend: {
            data: ['业绩目标', '实际业绩', '完成率'],
            bottom: '0',
            height: '10%',
            itemWidth: 10,
            itemHeight: 10,
            itemGap: 20,
            icon: 'rect',
            textStyle: {
                color: '#6E727A',
            },
        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '15%',
            width: '100%',
            height: '70%',
            containLabel: true,
        },
        // toolbox: {
        //     feature: {
        //         dataView: { show: true, readOnly: false },
        //         magicType: { show: true, type: ['line', 'bar'] },
        //         restore: { show: true },
        //         saveAsImage: { show: true }
        //     }
        // },
        xAxis: [
            {
                type: 'category',
                data: _.map(homeNewSaleAreaData?.areaRankList || [], item => {
                    return item.orgName;
                }),
                axisPointer: {
                    type: 'shadow',
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
                name: '业绩目标',
                // min: 0,
                // max: 250,
                // interval: 50,
                alignTicks: true,
                axisLabel: {
                    formatter: '{value} 万',
                },
            },
            {
                type: 'value',
                name: '完成率',
                min: 0,
                max: 100,
                interval: 20,
                alignTicks: true,
                show: false,
                // axisLine: {
                //     show: false
                // },
                axisLabel: {
                    formatter: '{value}  %',
                },
            },
        ],
        series: [
            {
                name: '业绩目标',
                type: 'bar',
                color: '#F5A623',
                tooltip: {
                    valueFormatter: (value: any) => {
                        return `${value}万 `;
                    },
                },
                data: _.map(homeNewSaleAreaData?.areaRankList || [], item => {
                    return trans2W(item.incomeTarget);
                }),
            },
            {
                name: '实际业绩',
                type: 'bar',
                color: '#0052FF',
                tooltip: {
                    valueFormatter: (value: any) => {
                        return `${value}万 `;
                    },
                },
                data: _.map(homeNewSaleAreaData?.areaRankList || [], item => {
                    return trans2W(item.incomeMoney);
                }),
            },
            {
                name: '完成率',
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    valueFormatter: (value: any) => {
                        return value + ' %';
                    },
                },
                color: '#76D98D',
                data: _.map(homeNewSaleAreaData?.areaRankList || [], item => {
                    return item.achievementFinishRate;
                }),
            },
        ],
    };
    const salesCountryOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999',
                },
            },
        },
        legend: {
            data: ['业绩目标', '实际业绩', '完成率'],
            bottom: '0',
            height: '10%',
            itemWidth: 10,
            itemHeight: 10,
            itemGap: 20,
            icon: 'rect',
            textStyle: {
                color: '#6E727A',
            },
        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '15%',
            width: '100%',
            height: '70%',
            containLabel: true,
        },
        // toolbox: {
        //     feature: {
        //         dataView: { show: true, readOnly: false },
        //         magicType: { show: true, type: ['line', 'bar'] },
        //         restore: { show: true },
        //         saveAsImage: { show: true }
        //     }
        // },
        xAxis: [
            {
                type: 'category',
                data: _.map(homeNewSaleAreaData?.cityRankList, item => {
                    return item.orgName;
                }),
                axisPointer: {
                    type: 'shadow',
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
                name: '业绩目标',
                // min: 0,
                // max: 250,
                // interval: 50,
                alignTicks: true,
                axisLabel: {
                    formatter: '{value} 万',
                },
            },
            {
                type: 'value',
                name: '完成率',
                min: 0,
                max: 100,
                interval: 20,
                alignTicks: true,
                show: false,
                // axisLine: {
                //     show: false
                // },
                axisLabel: {
                    formatter: '{value}  %',
                },
            },
        ],
        series: [
            {
                name: '业绩目标',
                type: 'bar',
                color: '#F5A623',
                tooltip: {
                    valueFormatter: (value: any) => {
                        return `${value}万 `;
                    },
                },
                data: _.map(homeNewSaleAreaData?.cityRankList || [], item => {
                    return trans2W(item.incomeTarget);
                }),
            },
            {
                name: '实际业绩',
                type: 'bar',
                color: '#0052FF',
                tooltip: {
                    valueFormatter: (value: any) => {
                        return `${value}万 `;
                    },
                },
                data: _.map(homeNewSaleAreaData?.cityRankList || [], item => {
                    return trans2W(item.incomeMoney);
                }),
            },
            {
                name: '完成率',
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    valueFormatter: (value: any) => {
                        return value + ' %';
                    },
                },
                color: '#76D98D',
                data: _.map(homeNewSaleAreaData?.cityRankList || [], item => {
                    return item.achievementFinishRate;
                }),
            },
        ],
    };
    return (
        <div className="s-a-composite-content home-s-a-content">
            <div className="s-a-header">
                <span className="s-a-title">
                    业绩目标完成情况
                    <span className="s-a-tips">
                        （注意：此处数据非实时数据，是截止昨日24时的数据）
                    </span>
                </span>
                <div className="a-s-f-filter">
                    {/* <div className="a-s-f-item">
                        <label htmlFor="">区域</label>
                        <Select
                            size="small"
                            value={asFilterData.area}
                            onChange={(value: any) => {
                                setAsFilterData({
                                    ...asFilterData,
                                    area: value,
                                });
                            }}
                            style={{
                                minWidth: '80px',
                            }}
                        >
                            {_.map(areaOptions, option => {
                                return (
                                    <Select.Option value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div> */}
                    <div className="a-s-f-item">
                        <label htmlFor="">时间</label>
                        <Select
                            size="small"
                            value={homeNewFilterData.range}
                            onChange={(value: any) => {
                                setHomeNewFilterData({
                                    ...homeNewFilterData,
                                    range: value,
                                });
                            }}
                            style={{
                                minWidth: '80px',
                            }}
                        >
                            {_.map(homeRangeOptions, option => {
                                return (
                                    <Select.Option value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
            </div>
            <div className="s-a-charts-content">
                <div className="s-a-charts-row">
                    <CompositeLiveChartsComponents
                        chartsOptions={salesAreaOptions}
                        loading={homeNewSaleLoading}
                        // extraRight={'单位：万元'}
                        title={'业绩完成情况（按区域）'}
                        showTitle={false}
                        chartsClassName="s-a-charts-content"
                    />
                    <CompositeLiveChartsComponents
                        chartsClassName="s-a-charts-content"
                        loading={homeNewSaleLoading}
                        // extraRight={'单位：万元'}
                        chartsOptions={salesCountryOptions}
                        title={'业绩完成情况（按城市）'}
                        showTitle={false}
                    />
                </div>
            </div>
        </div>
    );
};
export default observer(HomeSalesArea);
