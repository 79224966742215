import React from 'react';
import { DTable, isMultipleCell, ICell } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { applyCustomConfToCellsSelector } from '@/components/common/form/advanced-filters';
import { tryRefresh } from '@/utils';
import { ContractListStore } from '@/pages/om/contract/list/store';

const DemoTable: React.FC<{
    defaultStore: ContractListStore;
    loading: boolean;
}> = ({ defaultStore, loading }) => {
    const dataTable = (
        <>
            <DTable
                onColumnsUpdated={() => tryRefresh(['contract'])}
                defaultStore={defaultStore}
                sceneCode={'crm_contract'}
                options={
                    {
                        // withActionPermCheck: false,
                    }
                }
                propsTransformer={props => {
                    props.loading = loading;
                    props.actions = [
                        {
                            actionKey: 'contract_invoice_apply',
                            actionCn: '开发票',
                        },
                        {
                            actionKey: 'open_apply',
                            actionCn: '开通',
                        },
                    ];
                    props.onAction = (key, data) => {
                        if (key === 'open_apply') {
                            // 开通预览
                            defaultStore.setAction('open_apply');
                            defaultStore.setMutatingData(data);
                        } else if (key === 'contract_invoice_apply') {
                            defaultStore.setAction('contract_invoice_apply');
                            defaultStore.fetchInstantlyMutatingDataById(
                                data.id,
                            );
                            // 其他动作
                        }
                    };
                    props.adjustHeight = false;
                    props.closeRowSelection = true;
                    return props;
                }}
                cellsSelectorTransformer={baiscCellsSelector => {
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'contract_attachments',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'Files';

                            return nextCellSelector;
                        },
                    );

                    // 详情可点击
                    applyCustomConfToCellsSelector(
                        baiscCellsSelector,
                        'contract_name',
                        cellSelector => {
                            const nextCellSelector = _.cloneDeep(cellSelector);
                            if (isMultipleCell(nextCellSelector)) {
                                return nextCellSelector;
                            }
                            nextCellSelector.cell = 'Link';
                            nextCellSelector.inputs.push({
                                queryFetcher: (item: any) => ({
                                    id: item.id,
                                    target: 'customer-drawer',
                                }),
                                url: '/buy/contract/all',
                            });
                            return nextCellSelector;
                        },
                    );

                    return baiscCellsSelector;
                }}
            />
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
