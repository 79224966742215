import { makeAutoObservable, runInAction } from 'mobx';
import {
    createDefaultInstanceGetter,
    PaginationStore,
    TPaginationStoreInstance,
    SorterStore,
    ListMeta,
    MSelectStore,
    makeDefaultInitailingHook,
    FastFilterStore,
} from '@/stores/utils';
import {
    NexusGenFieldTypes,
    NexusGenInputs,
} from '@/definations/graphql/auto-gen';
import {
    TFetchCustomersReturnType,
    fetchCustomers,
    mutate,
} from '@/api/graphql';
import _ from 'lodash';
import {
    serializeFilterData2,
    serializeFilterData3,
} from '@/utils/filters-serializers';
import { message } from 'antd';
import {
    getLeads,
    TGetLeadsReturnType,
    xDelete,
    commonDelete,
    getOpts,
    TGetOptsReturnType,
    commonQuery,
    TGetCommonReturnType,
    getCommonQueryBody,
} from '@/api/rest';
import {
    defaultWritingDataParser,
    isAllValidateResultSuccess,
    defaultAxios,
    isZeroValue,
    isNetSuccess,
} from '@/utils';
import EE from 'eventemitter3';
import { IMOpResult } from '@/components';
import $ from 'jquery';
import { Perm } from '@/stores/perm';
import { FilterStore } from '@/stores/utils/filter';
import {
    parseFeProductToBackEndOptProduct,
    embedProductParamToOpt,
} from './utils';
import { leadSource2optSourceMapping } from '@/components/common/lead-drawer/lead-opt-create/index.tsx';
import { makeDefaultInitailingHookB } from '@/stores/utilsA';
import { createDefaultInstanceGetterA } from '../../../../stores/utilsA';

interface IAutoFilling {
    customerName: string;
    customerStatus: number; // 0 1 2
    ownerName: string;
    leadInfo: any;
}

const transformBackEndValidateStateToValidateResult = (
    data: any[],
): ValidateResult<any> => {
    const ret: ValidateResult<any> = {};
    for (const d of data) {
        const { errorMsg, errorType, fieldName } = d;
        if (!errorMsg || !errorType || !fieldName) {
            continue;
        }
        ret[fieldName] = {
            msg: errorMsg,
            status: errorType === 'error' ? 'fail' : 'warning',
        };
    }
    return ret;
};

export class OptListStore {
    // 数据
    public data: any[] = [];

    public listType: string = '';
    public setListType(type: string) {
        this.listType = type;
    }

    public position: string = '';
    public setPosition(position: string) {
        this.position = position;
    }

    public get pageTitle() {
        if (this.listType === 'home') {
            return '进行中商机';
        }
        return '商机列表';
    }

    public defaultFilters: any[] = [];
    public setDefaultFilters(filters: any[]) {
        this.defaultFilters = filters;
    }

    public defaultFields: string[] = ['customer_id'];
    public setDefaultFields(fields: string[]) {
        this.defaultFields = ['customer_id', ...fields];
    }

    // 序列化到graphql格式
    public get condListbyFilterData(): NexusGenInputs['FiltersArgs'] {
        const defaultFastFilters = this.defaultFastFilters;
        const {
            selectedFastFilters,
            selectedLv4MenuFilters,
        } = defaultFastFilters;

        const defaultFilters = this.defaultFilters;
        const ret = serializeFilterData3(
            this.defaultFilter.confirmedfilterData,
            this.defaultMeta,
        );
        const filters = ret.filters;
        if (_.isNil(filters)) {
            return {
                filters: [],
            };
        }

        for (const filterSet of selectedFastFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        for (const filterSet of selectedLv4MenuFilters) {
            const { value } = filterSet;
            if (_.isArray(value)) {
                for (const v of value) {
                    const { condition } = v;
                    if (!condition) {
                        continue;
                    }

                    filters.push(_.cloneDeep(condition));
                }
            }
        }

        ret.filters?.push(...defaultFilters);
        return ret;
    }

    public netValidateResult: ValidateResult<any> = {};
    public setNetValidateResult = (result: ValidateResult<any>) => {
        this.netValidateResult = result;
    };

    public autoFillingLoading: boolean = false;
    public autoFillingData: any = null;
    public autoFillingError: string = '';
    public async askAutoFillingDataByCustomer(
        customer: any,
        opportunityType?: any,
    ) {
        if (!customer) {
            return false;
        }
        const { originalValue: customerId } = customer;
        if (!customerId) {
            return false;
        }
        this.autoFillingLoading = true;

        const params: any = {
            customerId,
        };
        if (opportunityType) {
            params.opportunityType = opportunityType;
        }

        const [d, e] = await defaultAxios.get(
            '/bff/api/rest/opt/fillOpportunity',
            params,
        );
        this.autoFillingLoading = false;
        if (e) {
            this.autoFillingError = e.message;
            return false;
        }
        this.autoFillingError = '';

        const ret = d?.data;
        const { opt } = ret;
        await new Promise(resolve => {
            runInAction(() => {
                // 除了招聘新签，都不需要线索推断
                // if (opt && opportunityType !== 1) {
                //     delete opt.lead_id;
                // }
                this.autoFillingData = opt;
                this.mutatingData = _.assign(
                    _.cloneDeep(this.mutatingData),
                    opt,
                );
                // if (!!opt?.lead_id) {
                //     this.mutatingData.opportunity_source = 1;
                // }
                this.clearParamsValidateState(_.keys(this.autoFillingData));
                resolve();
            });
        });
        return true;
    }

    public async fillOptSourceByLead(lead: any, isGaoqian?: boolean) {
        isGaoqian = _.isUndefined(isGaoqian) ? false : isGaoqian;
        if (!lead) {
            return false;
        }
        const { originalValue: leadId } = lead;
        if (!leadId) {
            return false;
        }
        const [d, e] = await commonQuery(
            'lead',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: leadId,
                        operateType: '=',
                    },
                ],
            },
            undefined,
            undefined,
            undefined,
            'PRIVATE-VIEW',
        );
        const leadFollowStatus = d?.datas[0]?.lead_source;
        if (leadFollowStatus) {
            const optSource = leadSource2optSourceMapping[leadFollowStatus];
            if (!optSource) {
                return false;
            }
            if (this.mutatingData && !isGaoqian) {
                this.mutatingData.opportunity_source = optSource;
            }
            return true;
        }
        return false;
    }

    get cannotCreateReason() {
        const { customer_id } = this.mutatingData;
        const { autoFillingError } = this;
        if (!customer_id || !customer_id.originalValue) {
            return '请先选择客户';
        }
        if (autoFillingError !== '') {
            return autoFillingError;
        }
        return '';
    }

    public actionType: string = '';
    get bigFormVisible() {
        return ['create', 'update'].indexOf(this.actionType) > -1;
    }
    public setAction(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra.id) {
                this.fetchInstantlyMutatingDataById(extra.id);
            } else {
                message.error('修改必须传id');
            }
        }
        this.actionType = nextAction;
    }
    public async setActionAsync(nextAction: string, extra?: any) {
        if (nextAction === 'update') {
            if (extra) {
                if (extra.id) {
                    const ok = await this.fetchInstantlyMutatingDataById(
                        extra.id,
                    );
                    if (!ok) {
                        return false;
                    }
                } else {
                    message.error('修改必须传id');
                    return false;
                }
            }
        }
        this.actionType = nextAction;
        return true;
    }
    public resetAction() {
        this.netValidateResult = {};
        this.actionType = '';
    }
    public async dispatchAction() {
        if (this.actionType === 'create') {
            return this.create();
        } else if (this.actionType === 'update') {
            return this.update();
        }
    }

    public newDataHash = '';
    public mutatingData: any = null;
    public mutatingDataLoading: boolean = false;
    get mutatingDataParsed() {
        const hackedData = _.cloneDeep(this.mutatingData);
        delete hackedData.serial_version_u_i_d;
        const d = defaultWritingDataParser(hackedData);
        return d;
    }

    public setMutatingData(next: any) {
        this.mutatingData = next;
    }

    public clearMutatingData() {
        this.mutatingData = null;
    }

    public startNewData() {
        this.newDataHash = '' + Date.now();
        const initialFormData = this.defaultMeta.defaultWidgetValues;
        this.mutatingData = _.cloneDeep(initialFormData);
        this.mutatingData.prediction_sale_amount = '0.00';
    }

    public startEditing(next: any) {
        this.mutatingData = next;
    }

    public async clearParamValidateState(key: string) {
        const nextNetValidateResult = { ...this.netValidateResult };
        delete nextNetValidateResult[key];
        this.netValidateResult = nextNetValidateResult;
    }
    public async clearParamsValidateState(keys: string[]) {
        const nextNetValidateResult = { ...this.netValidateResult };
        for (const key of keys) {
            delete nextNetValidateResult[key];
        }
        this.netValidateResult = nextNetValidateResult;
    }

    public isFromExsitCustomerTab = false;

    get fieldAuthCode() {
        if (this.position === 'customer-drawer') {
            // return 'PRIVATE-VIEW' // ??
            return ''; // 先不给
        }
        const { defaultFastFilters } = this;
        const selectedMine = !!defaultFastFilters.selectedLv4MenuFilters.find(
            item => {
                return item.key === 'opportunity-df-all-mine';
            },
        );
        return !selectedMine ? '' : '';
        // return !selectedMine ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';
    }

    get dataAuthCode() {
        if (this.position === 'customer-drawer') {
            // return 'PRIVATE-VIEW' // ??
            return 'SEARCH-ALL';
            // return 'SEARCH-PUBLIC'; // 先不给
        }
        const { defaultFastFilters } = this;
        const selectedMine = !!defaultFastFilters.selectedLv4MenuFilters.find(
            item => {
                return item.key === 'opportunity-df-all-mine';
            },
        );
        return !selectedMine ? 'SEARCH-PUBLIC' : 'SEARCH-PRIVATE';
    }

    public async fetchInstantlyMutatingDataById(id: any) {
        const { fieldAuthCode, dataAuthCode } = this;

        // this.startNewData();
        this.mutatingDataLoading = true;
        const [d, e] = await commonQuery(
            'opt',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'id',
                        filterValue: id,
                        operateType: '=',
                    },
                ],
            },
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            undefined,
            dataAuthCode,
        );

        if (d === null || e !== null) {
            return false;
        }
        const { datas } = d;
        if (datas && datas[0]) {
            await embedProductParamToOpt(datas[0]);
            this.mutatingDataLoading = false;
            this.mutatingData = datas[0];
            return true;
        } else {
            this.mutatingDataLoading = false;
            message.error('没有该数据的权限');
            return false;
        }
    }

    public async fetch(): Promise<TGetCommonReturnType> {
        const { fieldAuthCode, dataAuthCode } = this;
        let kase = '';
        const activityd7 = this.defaultFastFilters.selectedFastFilters.find(
            item => item.fastFilterKey === 'opt_7_recent_activity_record_time',
        );
        if (!!activityd7) {
            kase = 'opt_7_recent_activity_record_time';
        }
        const [d, e] = await commonQuery(
            'opt',
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            [
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
                ...this.defaultFields,
            ],
            dataAuthCode,
            undefined,
            kase ? '/bff/api/rest/opt/list?kase=' + kase : undefined,
        );

        if (d === null || e !== null) {
            return [d, e];
        }
        const optsData = d;
        const { amount, datas } = optsData;

        runInAction(() => {
            this.data = datas;
            this.defaultPagination.amount = amount;
        });
        return [d, e];
    }

    get downloadUrl() {
        const { fieldAuthCode, dataAuthCode } = this;
        const { tableId: objectName } = this.defaultMeta;
        const { amount } = this.defaultPagination;
        if (!amount) {
            return '';
        }
        const pageSize = Math.max(Math.min(Math.ceil(amount / 10), 300), 100);
        const fetchBody = getCommonQueryBody(
            { ...this.defaultPagination.paginationArgs, pageSize },
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            this.defaultMeta.tableColsParamsFromUserSetting.map(
                item => item.key,
            ),
            dataAuthCode,
            false,
        );
        fetchBody.objectName = objectName;
        return (
            `${
                window.location.origin
            }/bff/api/rest/export?q=${encodeURIComponent(
                JSON.stringify(fetchBody),
            )}&hash=` + Math.random()
        );
    }

    public async create() {
        const { cannotCreateReason } = this;
        if (cannotCreateReason !== '') {
            message.error(cannotCreateReason);
            return false;
        }
        const data = _.cloneDeep(this.mutatingDataParsed);
        const opportunityProductList = await parseFeProductToBackEndOptProduct(
            data.product,
            data,
        );
        data.opportunity_product_list = opportunityProductList;
        data.product = null;

        delete data.id;
        delete data.key;
        const [ret, errMsg] = await mutate('opportunity', 'insert', data);
        if (!ret || errMsg) {
            return false;
        }

        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('新建成功');
        this.defaultEE.emit('create-success');
        return true;
    }

    public dataVer = 0;
    public async update(outerMutatingData?: any) {
        // 方便其他store借用功能
        if (outerMutatingData !== undefined) {
            this.mutatingData = outerMutatingData;
        }
        const data = _.cloneDeep(this.mutatingDataParsed);
        const opportunityProductList = await parseFeProductToBackEndOptProduct(
            data.product,
            data,
        );
        data.opportunity_product_list = opportunityProductList;
        data.product = null;

        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        delete data.key;
        const [ret, errMsg] = await mutate('opportunity', 'update', data);
        if (!ret || errMsg) {
            return false;
        }
        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            this.setNetValidateResult(
                transformBackEndValidateStateToValidateResult(innerData),
            );
            message.error('存在校验不通过的字段');
            const formTopEle = $('.component-form-lv2')[0];
            if (formTopEle) {
                (formTopEle as any).___withAutoScroll = true;
            }
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        this.setNetValidateResult({});
        this.resetAction();
        message.info('修改成功');
        this.defaultEE.emit('update-success', this.mutatingData);
        this.dataVer = 1 + this.dataVer;
        return true;
    }

    // 转移专用，不对状态做变更
    public async update2(outerMutatingData?: any) {
        if (outerMutatingData === undefined) {
            return;
        }
        const hackedData = _.cloneDeep(outerMutatingData);
        delete hackedData.serial_version_u_i_d;
        const data = defaultWritingDataParser(hackedData);

        const opportunityProductList = await parseFeProductToBackEndOptProduct(
            data.product,
            data,
        );

        data.opportunity_product_list = opportunityProductList;
        data.product = null;

        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        delete data.key;
        const [ret, errMsg] = await mutate('opportunity', 'update', data);
        if (!ret || errMsg) {
            return false;
        }
        const { msg, code, data: innerData } = ret;

        if (_.isArray(innerData) && innerData.length > 0) {
            // 如果是数组就是一堆字段报错
            message.error(
                innerData
                    .map(item => item.errorMsg || '')
                    .filter(Boolean)
                    .join('\n'),
            );
            return false;
        }
        if (+code !== 0) {
            return false;
        }
        message.info('修改成功');
        return true;
    }

    public async delete() {
        const data = _.cloneDeep(this.mutatingDataParsed);
        if (!data.id) {
            message.error('数据没有id');
            return false;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = [data.id];
        const [d, e] = await xDelete(tableId, operatingIdList);
        if (!e) {
            message.info('删除成功');
            return true;
        }
        return false;
    }

    public async mdelete() {
        const ids = _.map(this.defaultMSelect.mSeletedData, item => item.id);
        if (ids.length === 0) {
            return;
        }
        const tableId = this.defaultMeta.tableId; // TOFIX
        const operatingIdList = ids;
        const [d, e] = await xDelete(tableId, operatingIdList);
        if (!_.isNil(e) || _.isNil(d)) {
            return false;
        }
        let { warningNum, errorNum } = d.data || {};
        warningNum = warningNum || 0;
        errorNum = errorNum || 0;
        if (warningNum === 0 && errorNum === 0) {
            message.success('删除成功');
            return true;
        } else {
            this.defaultMSelect.setMopResult('批量删除结果', d.data);
            return false;
        }
    }

    public extraStatisticResult: any = null;
    public setExtraStatisticResuilt(next: any) {
        this.extraStatisticResult = next;
    }
    public async getExtraStatistic() {
        const { fieldAuthCode, dataAuthCode } = this;

        const payload = getCommonQueryBody(
            this.defaultPagination.paginationArgs,
            this.condListbyFilterData,
            this.defaultSorter.sorterArgs,
            fieldAuthCode,
            [
                ...this.defaultMeta.tableColsParamsFromUserSetting.map(
                    item => item.key,
                ),
                ...this.defaultFields,
            ],
            dataAuthCode,
        );

        const [d, e] = await defaultAxios.post(
            '/bff/api/rest/opt/extraStatistics',
            payload,
        );
        if (!isNetSuccess(d, e) || _.isNaN(d?.data)) {
            return false;
        }
        this.extraStatisticResult = d?.data;

        return true;
    }

    public dupDetailLead: any = null;
    public setDupDetailLead(opportunity: any) {
        this.dupDetailLead = opportunity;
    }
    public clearDupDetailLead() {
        this.dupDetailLead = null;
    }

    // 产品字段，确认权益
    public productToConfirmEquity: any = null;
    public setProductToConfirmEquity(equity: any) {
        this.productToConfirmEquity = equity;
    }
    public resetProductToConfirmEquity() {
        this.productToConfirmEquity = null;
    }
    // 更新产品权益
    public setNextParsedEquities(nextEquities: any) {
        const relatedObj = this.productToConfirmEquity?.relatedObj;
        relatedObj.equity_detail_parsed = nextEquities;
    }

    get metaloseOrderReasonTypes() {
        const objectMeta = this.defaultMeta.objectMeta;
        const metaItem = objectMeta.find(
            item => item.fieldName === 'lose_order_reason_type',
        );
        return (metaItem?.constraint?.constraintItemList || [])
            .map((item: any) => {
                const { constraintLabel, constraintValue } = item;
                if (constraintLabel && constraintValue) {
                    return {
                        name: constraintLabel,
                        value: constraintValue,
                        choice: item?.item?.choice,
                    };
                }
            })
            .filter(Boolean);
    }

    get selectedCustomers() {
        const opts = this.defaultMSelect.mSeletedData;
        const customers = opts
            .map(item => {
                const obj = _.cloneDeep(item.customer_id?.relatedObj || {});
                // 为了批量创建任务
                obj.owner_id = { originalValue: obj.owner_id };
                return obj;
            })
            .filter(Boolean);
        return _.uniqBy(customers, item => item.id);
    }

    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public defaultSorter = new SorterStore<any>();
    public defaultMeta = new ListMeta(
        '/bff/api/rest/meta/opt/list',
        '/bff/api/rest/meta/opt/list/filters/save',
        '/bff/api/rest/meta/opt/list/table-cols/save',
    );
    public setDefaultMeta(next: ListMeta) {
        this.defaultMeta = next;
    }
    public defaultFilter = new FilterStore(
        this.defaultSorter,
        this.defaultMeta,
    );
    public defaultEE = new EE();
    public defaultPerm: Perm = new Perm(
        '/bff/api/rest/perm?mod=sales-follow-up:opportunity',
    );
    public defaultMSelect: MSelectStore = new MSelectStore();
    public defaultFastFilters = new FastFilterStore();

    constructor() {
        makeAutoObservable(this, {
            defaultPagination: false,
            defaultSorter: false,
            defaultMeta: false,
            defaultFilter: false,
            defaultEE: false,
            defaultPerm: false,
            defaultMSelect: false,
            defaultFastFilters: false,
        });
    }
}

export const getDefaultOptListStore = createDefaultInstanceGetterA(
    OptListStore,
);

export const useAnyOptStore = makeDefaultInitailingHookB(
    OptListStore,
    'opportunity',
);
