import React, { useEffect, useState } from 'react';
import Upload, { UploadProps } from 'antd/lib/upload';
import { message, Button, Icon, Tooltip } from 'antd';
import { getCommonStatus, ICommonProps } from './common';
import _ from 'lodash';
import './upload.scss';
import { Popover } from 'antd';

export type UploadValueType = string | null;
export interface IUploadOptions extends UploadProps {
    useWaterMark?: boolean;
    waterMarkStr?: string | null;
    lint?: string;
    fileExtWhitelist?: string[];
    limit?: number;
    overlimitLint?: string;
    single?: boolean;
    otherUse?: boolean;
    handlePreview?: boolean;
}

export interface IWidgetUploadProps<T = any> extends ICommonProps<T> {
    value: UploadValueType;
    options: IUploadOptions;
    onChange: (value: UploadValueType) => void;
}

const protocol = 'https:';
const clearUrl = (url: string) => {
    if (_.startsWith(url, protocol)) {
        return url;
    }
    return protocol + '//' + url.split('//')[1];
};

export const WidgetUpload: React.FC<IWidgetUploadProps> = ({
    value,
    options,
    onChange,
    status,
    onInteract = key => void 0,
    data,
    statusExtraData,
}) => {
    const [removed, setRemoved] = useState<{ [uid: string]: boolean }>({});
    const [loading, setLoading] = useState(false);
    const [sizeHeight, setSizeHeight] = useState(false);
    const { disabled } = getCommonStatus(status, data, statusExtraData);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }
    let fileList: any[] = [];

    try {
        const nextfileList = JSON.parse(value || '');
        if (_.isArray(nextfileList)) {
            fileList = nextfileList.filter(f => f.status === 'done');
        }
    } catch (e) {}

    let lint =
        '附件要求:20M以内,支持文件格式为jpg,png,jpeg,pdf,doc,xls,xlsx,csv。';
    if (options.lint) {
        lint = options.lint;
    }

    let fileExtWhitelist = [
        'jpg',
        'png',
        'jpeg',
        'pdf',
        'doc',
        'xls',
        'xlsx',
        'csv',
    ];
    if (options.fileExtWhitelist) {
        fileExtWhitelist = options.fileExtWhitelist;
    }

    const isSingle = !!options.single;

    if (options.handlePreview) {
        return null;
    } else {
        return (
            <>
                <Upload
                    name={'file'}
                    action={
                        '/bff/api/rest/upload' +
                        (!!options.useWaterMark ? '?useWaterMark=1' : '')
                    }
                    data={
                        !!options.useWaterMark && !!options.waterMarkStr
                            ? { str: options.waterMarkStr }
                            : {}
                    }
                    beforeUpload={file => {
                        return new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const { size } = file;
                                const limit = options.limit;
                                if (limit && size && size > limit) {
                                    message.error(
                                        options.overlimitLint ||
                                            '文件大小超出限制',
                                    );
                                    return reject();
                                }

                                const isGood = fileExtWhitelist.some(extName =>
                                    _.endsWith(file.name, extName),
                                );
                                if (!isGood) {
                                    message.error(
                                        file.name + ' : 文件格式不支持',
                                    );
                                    return reject();
                                }
                                resolve();
                            }, 1);
                        });
                    }}
                    onChange={info => {
                        if (info.file.status !== 'uploading') {
                            setLoading(false);
                            const parsedInfo = info.fileList
                                .filter(item => !removed[item.uid])
                                .map(item => {
                                    if (item.response === 'Server ok') {
                                        // 原上传文件
                                        return item;
                                    }

                                    // 新上传文件
                                    const response = {
                                        ...(item.response || {}),
                                    };
                                    if (
                                        response.status === 'error' ||
                                        !response.url
                                    ) {
                                        return null;
                                    }
                                    return {
                                        uid:
                                            item.uid ||
                                            (response.name || item.fileName) +
                                                ':' +
                                                Date.now(),
                                        name: response.name || item.fileName,
                                        status: response.status || 'error',
                                        response:
                                            !response.status ||
                                            response.status === 'error'
                                                ? 'Server Error'
                                                : 'Server ok', // custom error message to show
                                        url: clearUrl(response.url || ''),
                                    };
                                })
                                .filter(Boolean);

                            const originalFList = fileList;
                            if (isSingle) {
                                const tempRemoveArray = { ...removed };
                                if (fileList.length >= 1 && fileList[0]) {
                                    tempRemoveArray[fileList[0].uid] = true;
                                }
                                setRemoved(tempRemoveArray);
                                const nextFlist = _.uniqBy(
                                    [...originalFList, ...parsedInfo],
                                    'url',
                                ).filter(item => !tempRemoveArray[item.uid]);
                                onChange(JSON.stringify(nextFlist));
                            } else {
                                const nextFlist = _.uniqBy(
                                    [...originalFList, ...parsedInfo],
                                    'url',
                                ).filter(item => !removed[item.uid]);
                                onChange(JSON.stringify(nextFlist));
                            }
                        } else {
                            setLoading(true);
                        }
                        if (info.file.status === 'done') {
                            if (info.file.response?.status === 'error') {
                                message.error(`${info.file.name} 上传失败`);
                            } else {
                                message.success(`${info.file.name} 上传成功`);
                            }
                        } else if (info.file.status === 'error') {
                            message.error(`${info.file.name} 上传失败`);
                        }
                    }}
                    multiple={false}
                    {...options}
                    className="widgets-upload"
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            onClick={() => {
                                if (!!options.useWaterMark) {
                                }
                            }}
                            loading={loading}
                            disabled={disabled}
                        >
                            <Icon type="upload" />
                            {loading ? '附件上传中' : '上传附件'}
                        </Button>
                        <Tooltip title={lint}>
                            <Icon
                                style={{
                                    marginLeft: 12,
                                    fontSize: 16,
                                    color: '#0052ff',
                                }}
                                type="exclamation-circle"
                            />
                        </Tooltip>
                    </div>
                </Upload>
                <div className="filelist-display">
                    {fileList.map((item: any) => {
                        let imgWidth;
                        let imgHeight;
                        if (options.otherUse && item.url) {
                            const imgItem = new Image();
                            imgItem.src = item.url;
                            imgItem.onload = () => {
                                imgWidth = imgItem.width;
                                imgHeight = imgItem.height;
                                if (imgHeight < imgWidth) {
                                    setSizeHeight(true);
                                } else {
                                    setSizeHeight(false);
                                }
                            };
                        }
                        return (
                            <div>
                                {!disabled && (
                                    <Icon
                                        type="delete"
                                        onClick={() => {
                                            const nextList = fileList.filter(
                                                (fItem: any) =>
                                                    fItem.uid !== item.uid,
                                            );
                                            setRemoved({
                                                ...removed,
                                                [item.uid]: true,
                                            });
                                            onChange(JSON.stringify(nextList));
                                        }}
                                    />
                                )}
                                {options.otherUse ? (
                                    // <>
                                    //     &nbsp;&nbsp;
                                    //     <Popover
                                    //         content={
                                    <div
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            backgroundColor: '#AAA',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                backgroundColor: '#AAA',
                                                overflow: 'hidden',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img
                                                src={`${item.url}`}
                                                style={{
                                                    // backgroundImage: `url(${item.url})`,
                                                    width: sizeHeight
                                                        ? 'auto'
                                                        : '100px',
                                                    height: sizeHeight
                                                        ? '100px'
                                                        : 'auto',
                                                    // height: '100px',
                                                    // borderRadius: '50%',
                                                    // backgroundPosition:
                                                    //     'center',
                                                    // backgroundSize: 'cover',
                                                }}
                                                className="opm-img-content"
                                            />
                                        </div>
                                    </div>
                                ) : //     }
                                // >
                                //     <Icon type="eye" />
                                // </Popover>
                                // </>
                                null}
                                &nbsp;&nbsp;
                                <a href={clearUrl(item.url)} target="_blank">
                                    {item.name}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }
};
