import React, { useEffect, useState, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AntButton, BlockLoading2 } from '@/components';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { message } from 'antd';
import { observer } from 'mobx-react-lite';
import { AntDrawer } from '@/components/antd/drawer';
import CustomerForm from '@/pages/sales-follow-up/custom/list/components/form';
import { defaultAuthDeco } from '@/containers/hoc';
import { LeadListStore } from '../store';
import FormDemoForm from './form';
import { FastFilter } from '@/components/common/fast-filter';
import { tryRefresh } from '@/utils';

const FormDrawer: React.FC<RouteComponentProps & {
    mutateSuccess?: any;
    fromCustomerTab?: boolean;
    leadList: LeadListStore;
    preRequiredReady: boolean;
    type: string;
}> = ({ mutateSuccess, leadList, preRequiredReady, type, fromCustomerTab }) => {
    const store = useContext(StoreContext);
    const [customerList] = useState(() => new store.CustomerListStore());
    const [goToNewCustomerLoading, setGoToNewCustomerLoading] = useState(false);
    const userStore = store.getAuthStore();

    const [loading, setLoading] = useState(false);

    const {
        actionType,
        bigFormVisible,
        autoFillingLoading,
        defaultFilter,
        mutatingDataLoading,
    } = leadList;

    useEffect(() => {
        if (userStore.userInfo) {
            // 客户store初始化
            customerList.defaultMeta.setTableId('customer'); // TOFIX TODO: 动态设置
            customerList.defaultMeta.setOpUsername(
                userStore.userInfo.userId + '-' + type,
            );
        }
    }, [userStore.userInfo]);

    if (!preRequiredReady) {
        // <del>玄学bug，如果return null就没问题</del>
        // 看起来玄学，其实就是个样式bug .cao!
        // return <BlockLoading2 />
        return null;
    }

    const popups = (
        <>
            <AntDrawer
                title={'转客户'}
                onClose={() => {
                    leadList.setNewCustomerFormVisible(false);
                }}
                visible={leadList.newCustomerFormVisible}
                bodyStyle={{
                    width: '850px',
                    overflow: 'scroll',
                }}
                width="850px"
            >
                <div className="standard-drawer-form-wrapper">
                    {mutatingDataLoading ? (
                        <BlockLoading2 />
                    ) : (
                        <CustomerForm customerList={customerList} type={type} />
                    )}
                    <div className="fixed-form-btns">
                        <AntButton
                            size="large"
                            type="primary"
                            loading={loading}
                            onClick={async () => {
                                setLoading(true);
                                const isSuccess = await customerList.dispatchAction();
                                setLoading(false);
                                if (isSuccess) {
                                    customerList.resetAction();
                                    tryRefresh(['lead', 'customer']);
                                    leadList.setNewCustomerFormVisible(false);
                                    leadList.resetAction();
                                }
                            }}
                        >
                            新建
                        </AntButton>
                        <AntButton
                            size="large"
                            onClick={() => {
                                customerList.resetAction();
                                leadList.setNewCustomerFormVisible(false);
                            }}
                        >
                            取消
                        </AntButton>
                    </div>
                </div>
            </AntDrawer>
            <AntDrawer
                title={actionType === 'create' ? '新建线索' : '修改线索'}
                placement={'right'}
                visible={bigFormVisible}
                onClose={() => {
                    leadList.resetAction();
                }}
                bodyStyle={{
                    width: 850 + (leadList.newCustomerFormVisible ? 100 : 0),
                    overflow: 'scroll',
                }}
                width={850 + (leadList.newCustomerFormVisible ? 100 : 0) + 'px'}
            >
                <div className="standard-drawer-form-wrapper">
                    {mutatingDataLoading ? (
                        <BlockLoading2 />
                    ) : (
                        <FormDemoForm
                            leadList={leadList}
                            isEdit={actionType === 'update'}
                            type={type}
                            fromCustomerTab={fromCustomerTab}
                        />
                    )}
                    <div className="fixed-form-btns">
                        {/* {leadList.shouldGoToNewCustomer ? (
                            <AntButton
                                size="large"
                                type="primary"
                                loading={
                                    goToNewCustomerLoading || autoFillingLoading
                                }
                                onClick={async () => {
                                    const { defaultMeta } = customerList;
                                    let errorHappened = false;
                                    if (!defaultMeta.inited) {
                                        setGoToNewCustomerLoading(true);
                                        const [
                                            d,
                                            e,
                                        ] = await defaultMeta.fetch();
                                        setGoToNewCustomerLoading(false);
                                        errorHappened = !!e;
                                        if (!errorHappened) {
                                            defaultFilter.resetFilterData();
                                            defaultFilter.confirmFilterData();
                                        }
                                    }
                                    if (errorHappened) {
                                        return message.error(
                                            '客户表单初始化错误',
                                        );
                                    }
                                    customerList.startNewData();
                                    customerList.setAction('create');

                                    // 同步公司名到客户名
                                    if (leadList.mutatingData?.company_name) {
                                        customerList.mutateDataByKey(
                                            'customer_name',
                                            leadList.mutatingData?.company_name,
                                        );
                                    }
                                    leadList.setNewCustomerFormVisible(true);
                                }}
                            >
                                转客户
                            </AntButton>
                        ) : ( */}
                        <AntButton
                            size="large"
                            type="primary"
                            loading={autoFillingLoading || loading}
                            onClick={async () => {
                                setLoading(true);
                                const isSuccess = await leadList.dispatchAction();
                                setLoading(false);
                                if (isSuccess) {
                                    mutateSuccess && mutateSuccess();
                                    tryRefresh(['lead']);
                                }
                            }}
                        >
                            {actionType === 'create' ? '新建' : '修改'}
                        </AntButton>
                        {/* )} */}
                        <AntButton
                            size="large"
                            onClick={() => leadList.resetAction()}
                        >
                            取消
                        </AntButton>
                    </div>
                </div>
            </AntDrawer>
        </>
    );

    return popups;
};

const FinalFormDrawer = observer(FormDrawer);
export default FinalFormDrawer;
