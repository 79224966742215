import React, {
    useState,
    useEffect,
    useContext,
    CSSProperties,
    useRef,
} from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import FeedbackModal from '@/components/common/feed-back/index';
import './home.scss';
import { FinalActiveRecordListHome } from '../sales-follow-up/activation-record/list/for-home-page';
import { FinalOptListForHomePage } from '../sales-follow-up/opt/list/for-home-page';

import { Alert, Icon, Statistic, Spin, Divider } from 'antd';
import { defaultAxios } from '@/utils';
import { create, all } from 'mathjs';
import querystring from 'querystring';
import { BlockLoading2 } from '../../components/common/block-loading/index';

const bannerImg = `${process.env.PUBLIC_URL}/imgs/menus/banner1.png`;
const entityCodeToUrl: any = {
    customer: '/buy/custom/all-custom',
    contacts: '/buy/contact/all',
    lead: '/buy/lead/all-lead',
    leadmine: '/buy/lead/my-lead',
    opportunity: '/buy/opportunity/all',
    contract: '/buy/contract/all',
    process: '/buy/process-management/all',
    job: '/buy/job-management/cus-follow',
};
const IMath: any = create(all);
IMath.config({
    number: 'BigNumber',
    precision: 64,
});
export interface IHomeProcessCountInfo {
    all?: number;
    finished?: number;
    unFinished?: number;
    publishFinished?: number;
    publishWait?: number;
    publishUnfinished?: number;
    publishReject?: number;
}
export interface IHomePageInfo {
    opportunityGeSixtyForMonth?: number; // 60%以上商机数
    upOpportunityGeSixtyPercent?: number; // 比较上月商机数
    contractTotalAmountForMonth?: number; // 本月合同总金额
    upContractTotalAmountPercent?: number; // 比较上月合同总金额
    paymentForMonth?: number; // 本月已收款金额
    upPaymentMonthPercent?: number; // 比较上月已收款金额
    notPaymentForMonth?: number; // 本月未收款金额（同比）
    upNotPaymentPercent?: number; // 比较本月未收款金额
    disposedLeadNumForDay?: number; // 当天处理线索数
    todoDisposedLeadNum?: number; // 待处理线索数
    customerFollowUpNum?: number; // 客户跟进数
    callNumForDay?: number; // 本人打电话数
}
const HomePage: React.FC<RouteComponentProps> = props => {
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const newHomeStore = store.getDefaultHomeNewStore();
    const { userInfo } = authStore;
    const [homeValue, setHomeValue] = useState<IHomePageInfo>({});
    const [processInfo, setProcessInfo] = useState<IHomeProcessCountInfo>({});
    const [homeValueLoading, setHomeValueLoading] = useState(false);
    const [homeProcessInfoLoading, setHomeProcessInfoLoading] = useState(false);
    const fetchHomePageProcessInfo = async () => {
        setHomeProcessInfoLoading(true);
        const url = '/bff/api/rest/home/home-process-data';
        const [d, e] = await defaultAxios.get(url, {
            currentUser: userInfo.userId,
        });
        setHomeProcessInfoLoading(false);
        if (e || d === null || !d.data) {
            return false;
        } else {
            setProcessInfo(d.data);
        }
    };

    const [jobInfo, setJobInfo] = useState<any>({});
    const [homeJobInfoLoading, setHomeJobInfoLoading] = useState(false);

    const fetchHomePageJobInfo = async () => {
        setHomeJobInfoLoading(true);
        const url = '/bff/api/rest/job/home-job-statistic';
        const [d, e] = await defaultAxios.get(url);
        setHomeJobInfoLoading(false);
        if (e || d === null || !d.data) {
            return false;
        } else {
            setJobInfo(d.data);
        }
    };

    const fetchHomePageData = async () => {
        setHomeValueLoading(true);
        const url = '/bff/api/rest/home/home-info-data';
        const [d, e] = await defaultAxios.get(url);
        setHomeValueLoading(false);
        if (e || d === null || d.data === null || d.data === undefined) {
            return false;
        } else {
            setHomeValue(d.data);
        }
        return d.data;
    };
    useEffect(() => {
        fetchHomePageData();
        fetchHomePageProcessInfo();
        fetchHomePageJobInfo();
        newHomeStore.getHomeNewAuth();
    }, []);

    useEffect(() => {
        if (newHomeStore.showHomeNewPage && !newHomeStore.homeNewDataLoading) {
            navigate('/home-new', {
                replace: true,
            });
        }
    }, [newHomeStore.showHomeNewPage, newHomeStore.homeNewDataLoading]);
    const statisticValueStyle: CSSProperties = {
        fontSize: '24px',
        color: '#0052ff',
        fontWeight: 500,
    };
    const goToPage = (
        key:
            | 'customer'
            | 'contacts'
            | 'lead'
            | 'leadmine'
            | 'opportunity'
            | 'contract'
            | 'process',
    ) => {
        window.open(entityCodeToUrl[key], '_blank');
    };

    const goToJobPage = (key: 'job', type: string, id?: string) => {
        const body: any = {};
        if (type) {
            body.type = type;
        }
        if (id) {
            body.id = id;
        }
        window.open(
            entityCodeToUrl[key] + '?' + querystring.stringify(body),
            '_blank',
        );
    };

    const renderUpItem = (value?: number) => {
        if (value === 0) {
            return '0%';
        }
        if (!value) {
            return '- -';
        }
        return (
            <>
                {value > 0 ? (
                    <i className="iconfont1 up-icon">&#xe60e;</i>
                ) : (
                    <i className="iconfont1 down-icon">&#xe60f;</i>
                )}
                {`${IMath.abs(IMath.multiply(IMath.bignumber(value), 100))}%`}
            </>
        );
    };
    if (newHomeStore.showHomeAuthLoading) {
        return <BlockLoading2 />;
    }
    return (
        <div className="crm-home-page-content">
            {/* <Alert
                message="系统将于5月1日22:00进行功能升级维护，约30分钟不可用"
                banner
                closable
            /> */}
            <div className="crm-img-banner-content">
                <div className="banner-yellow-space left-space"></div>
                <img src={bannerImg} alt="" />
                {/* <div
                    className="crm-img-banner"
                    style={{
                        backgroundImage: bannerImg,
                    }}
                ></div> */}
                <div className="banner-yellow-space right-space"></div>
            </div>
            <Spin spinning={homeValueLoading}>
                <div className="crm-achievement-info">
                    <p className="home-page-title">业绩概况</p>
                    <div className="achievement-body">
                        <div
                            onClick={() => {
                                goToPage('opportunity');
                            }}
                            className="achievement-item opt"
                        >
                            <div className="achievement-icon iconfont1">
                                &#xe610;
                            </div>
                            <p className="achievement-text">
                                本月60%以上商机数
                                <span className="text-highlight">
                                    {homeValue.opportunityGeSixtyForMonth ||
                                        '- -'}
                                </span>
                                <p className="achievement-compare">
                                    同比上月
                                    {renderUpItem(
                                        homeValue.upOpportunityGeSixtyPercent,
                                    )}
                                </p>
                            </p>
                        </div>
                        <div
                            onClick={() => {
                                goToPage('contract');
                            }}
                            className="achievement-item contract"
                        >
                            <div className="achievement-icon iconfont1">
                                &#xe60b;
                            </div>
                            <p className="achievement-text">
                                本月合同总金额
                                <span className="text-highlight">
                                    {homeValue.contractTotalAmountForMonth ||
                                    homeValue.contractTotalAmountForMonth ===
                                        0 ? (
                                        <>
                                            <span className="small-size">
                                                ¥
                                            </span>
                                            <span>
                                                {Math.floor(
                                                    homeValue.contractTotalAmountForMonth /
                                                        10000,
                                                )}
                                                <span className="small-size">
                                                    万
                                                </span>
                                            </span>
                                        </>
                                    ) : (
                                        '- -'
                                    )}
                                </span>
                                <p className="achievement-compare">
                                    同比上月
                                    {renderUpItem(
                                        homeValue.upContractTotalAmountPercent,
                                    )}
                                </p>
                            </p>
                        </div>
                        <div
                            className="achievement-item noReturn"
                            onClick={() => {
                                goToPage('contract');
                            }}
                        >
                            <img
                                className="achievement-img"
                                src={`${process.env.PUBLIC_URL}/imgs/home/noReturnMoney.png`}
                                alt=""
                            />
                            {/* <div className="achievement-icon iconfont1">
                                &#xe60c;
                            </div> */}
                            <p className="achievement-text">
                                本月未收款金额
                                <span className="text-highlight">
                                    {homeValue.notPaymentForMonth ||
                                    homeValue.notPaymentForMonth === 0 ? (
                                        <>
                                            <span className="small-size">
                                                ¥
                                            </span>
                                            <span>
                                                {Math.floor(
                                                    homeValue.notPaymentForMonth /
                                                        10000,
                                                )}
                                                <span className="small-size">
                                                    万
                                                </span>
                                            </span>
                                        </>
                                    ) : (
                                        '- -'
                                    )}
                                </span>
                                <p className="achievement-compare">
                                    同比上月
                                    {renderUpItem(
                                        homeValue.upNotPaymentPercent,
                                    )}
                                </p>
                            </p>
                        </div>
                        <div
                            className="achievement-item return"
                            onClick={() => {
                                goToPage('contract');
                            }}
                        >
                            <img
                                className="achievement-img"
                                src={`${process.env.PUBLIC_URL}/imgs/home/returnMoney.png`}
                                alt=""
                            />
                            {/* <div className="achievement-icon iconfont1">
                                &#xe60d;
                            </div> */}
                            <p className="achievement-text">
                                本月已收款金额
                                <span className="text-highlight">
                                    {homeValue.paymentForMonth ||
                                    homeValue.paymentForMonth === 0 ? (
                                        <>
                                            <span className="small-size">
                                                ¥
                                            </span>
                                            <span>
                                                {Math.floor(
                                                    homeValue.paymentForMonth /
                                                        10000,
                                                )}
                                                <span className="small-size">
                                                    万
                                                </span>
                                            </span>
                                        </>
                                    ) : (
                                        '- -'
                                    )}
                                </span>
                                <p className="achievement-compare">
                                    同比上月
                                    {renderUpItem(
                                        homeValue.upPaymentMonthPercent,
                                    )}
                                </p>
                            </p>
                        </div>
                    </div>
                    <div className="crm-today-info">
                        <span className="today-title">今日业务</span>
                        <span
                            className="today-info-item"
                            onClick={() => {
                                goToPage('lead');
                            }}
                        >
                            线索处理数：
                            <span className="info-highlight">
                                {homeValue.disposedLeadNumForDay || 0}
                            </span>
                        </span>
                        <span
                            className="today-info-item"
                            onClick={() => {
                                goToPage('leadmine');
                            }}
                        >
                            待处理线索数：
                            <span className="info-highlight">
                                {homeValue.todoDisposedLeadNum || 0}
                            </span>
                        </span>
                        <span
                            className="today-info-item"
                            onClick={() => {
                                goToPage('customer');
                            }}
                        >
                            客户跟进数：
                            <span className="info-highlight">
                                {homeValue.customerFollowUpNum || 0}
                            </span>
                        </span>
                        <span
                            className="today-info-item"
                            onClick={() => {
                                goToPage('contacts');
                            }}
                        >
                            拨打电话数：
                            <span className="info-highlight">
                                {homeValue.callNumForDay || 0}
                            </span>
                        </span>
                    </div>
                </div>
            </Spin>
            <div className="crm-home-process-info-wrapper">
                <div style={{ flexGrow: 1, width: 0, marginRight: 16 }}>
                    <Spin spinning={homeProcessInfoLoading}>
                        <div className="crm-home-process-info">
                            <p className="home-page-title">我的流程</p>
                            <div className="home-process-info-body">
                                <div
                                    className="process-info-item"
                                    onClick={() => {
                                        if (processInfo.unFinished) {
                                            goToPage('process');
                                        }
                                    }}
                                >
                                    <Statistic
                                        valueStyle={statisticValueStyle}
                                        value={
                                            // 3000
                                            processInfo.unFinished || 0
                                        }
                                    />
                                    <p className="process-info-label">
                                        待我审批
                                    </p>
                                </div>
                                <div
                                    className="process-info-item"
                                    onClick={() => {
                                        if (processInfo.publishUnfinished) {
                                            goToPage('process');
                                        }
                                    }}
                                >
                                    <Statistic
                                        valueStyle={statisticValueStyle}
                                        value={
                                            // 3000
                                            processInfo.publishUnfinished || 0
                                        }
                                    />
                                    <p className="process-info-label">审批中</p>
                                </div>
                                <div
                                    className="process-info-item"
                                    onClick={() => {
                                        if (processInfo.publishReject) {
                                            goToPage('process');
                                        }
                                    }}
                                >
                                    <Statistic
                                        valueStyle={statisticValueStyle}
                                        value={
                                            // 3000
                                            processInfo.publishReject || 0
                                        }
                                    />
                                    <p className="process-info-label">已拒绝</p>
                                </div>
                                <div
                                    className="process-info-item"
                                    onClick={() => {
                                        if (processInfo.publishFinished) {
                                            goToPage('process');
                                        }
                                    }}
                                >
                                    <Statistic
                                        valueStyle={statisticValueStyle}
                                        value={
                                            // 3000
                                            processInfo.publishFinished || 0
                                        }
                                    />
                                    <p className="process-info-label">已完成</p>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
                <div style={{ flexGrow: 1, width: 0 }}>
                    <Spin spinning={homeJobInfoLoading}>
                        <div className="crm-home-process-info">
                            <p className="home-page-title">我的任务</p>
                            <div className="home-process-info-body">
                                <div
                                    className="process-info-item"
                                    onClick={() => {
                                        goToJobPage('job', 'job-ing');
                                    }}
                                >
                                    <Statistic
                                        valueStyle={statisticValueStyle}
                                        value={
                                            // 3000
                                            jobInfo['job-ing'] || '0'
                                        }
                                    />
                                    <p className="process-info-label">进行中</p>
                                </div>
                                <div
                                    className="process-info-item"
                                    onClick={() => {
                                        goToJobPage('job', 'job-expire-today');
                                    }}
                                >
                                    <Statistic
                                        valueStyle={statisticValueStyle}
                                        value={
                                            // 3000
                                            jobInfo['job-expire-today'] || '0'
                                        }
                                    />
                                    <p className="process-info-label">
                                        今日到期
                                    </p>
                                </div>
                                <div
                                    className="process-info-item"
                                    onClick={() => {
                                        goToJobPage(
                                            'job',
                                            'job-expire-thisweek',
                                        );
                                    }}
                                >
                                    <Statistic
                                        valueStyle={statisticValueStyle}
                                        value={
                                            // 3000
                                            jobInfo['job-expire-thisweek'] ||
                                            '0'
                                        }
                                    />
                                    <p className="process-info-label">
                                        本周到期
                                    </p>
                                </div>
                                <div
                                    className="process-info-item"
                                    onClick={() => {
                                        goToJobPage('job', 'job-expired');
                                    }}
                                >
                                    <Statistic
                                        valueStyle={statisticValueStyle}
                                        value={
                                            // 3000
                                            jobInfo['job-expired'] || '0'
                                        }
                                    />
                                    <p className="process-info-label">已逾期</p>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>

            <div className="home-table-content">
                <div className="table-item home-follow-schedule">
                    <FinalActiveRecordListHome type={'home'} />
                </div>
                <div className="table-item home-opt-inprocess">
                    <FinalOptListForHomePage type="home" />
                </div>
            </div>
            <FeedbackModal />
        </div>
    );
};

export default observer(HomePage);
