import React, { memo } from 'react';
import { AutoEllipsis } from '../../../auto-ellipsis';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
// import { Tooltip } from 'antd';
import './image.less';

export const image: React.FC<{ record: any }> = memo(({ record }) => {
    // console.log('image', record);
    const urls = record?.image?.split(',') || [];
    // console.log('urls', urls);
    // <Tooltip
    //                                     placement="topLeft"
    //                                     title={`查看${urls.length}张图片`}
    //                                 >
    //                                     <img
    //                                         src={item}
    //                                         style={{
    //                                             width: '100px',
    //                                             height: '14px',
    //                                         }}
    //                                     />
    //                                 </Tooltip>
    return (
        <AutoEllipsis
            text={
                record?.image ? (
                    <PhotoProvider>
                        {urls.map((item: string | undefined, index: number) => {
                            return (
                                <PhotoView src={item}>
                                    {index === 0 ? (
                                        <div className="click-line">
                                            点击查看{urls.length}张图片
                                            {/* <img
                                                src={item}
                                                style={{
                                                    width: '100px',
                                                    height: '14px',
                                                }}
                                            /> */}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </PhotoView>
                            );
                        })}
                    </PhotoProvider>
                ) : null
            }
        />
    );
});
