import { NexusGenFieldTypes } from '@/definations/graphql/auto-gen';
import { TCellsSelector, ICommonTableColumnProps } from '@/components';

export const columnsConfig: Array<ICommonTableColumnProps<
    NexusGenFieldTypes['Author']
>> = [
    {
        key: 'id',
        fixed: 'left',
        width: 80,
    },
    {
        key: 'name',
        sorter: true,
    },
    {
        key: 'gender',
        sorter: true,
    },
    // 任意衍生key
    {
        key: 'any',
    },
];

export const cellsSelector: TCellsSelector<NexusGenFieldTypes['Author']> = [
    {
        colKey: 'name',
        cell: 'Echo',
        inputs: ['name'],
    },
    {
        colKey: 'gender',
        cells: [
            {
                cell: 'Echo',
                inputs: ['name'],
            },
            {
                cell: 'Echo',
                inputs: ['gender'],
            },
        ],
    },
    {
        colKey: 'any',
        cells: [
            {
                cell: 'Echo',
                inputs: ['gender', 'name'],
            },
        ],
    },
];
