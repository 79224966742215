import React, { memo } from 'react';
import { AutoEllipsis } from '../../auto-ellipsis';
import _ from 'lodash';
import querystring from 'querystring';
import { CusTag } from './business-registration';
import './action-param-contacts.less';

export const LinkContacts: React.FC<{
    args: any[];
    item: any;
    onAction: (...args: any[]) => void;
}> = memo(({ args, item, onAction }) => {
    const value = args[0];
    const options = args[1] || {};
    const queryFetcher = options.queryFetcher || (() => {});
    const query = options.query || queryFetcher(item);
    const url = options.url || value;
    const target = options.target || '_blank';
    const isKp = item.is_kp === 1;
    if (value) {
        let prepend: any = [];
        let count = 0;
        if (isKp) {
            count++;
            prepend.push(
                <CusTag
                    style={{ width: '24px', marginRight: '0' }}
                    color="#FFA408"
                    text="KP"
                />,
            );
        }

        // const colCount = Math.ceil(count / 2);
        // const w = (colCount ? 2 : 0) + 38 * colCount;
        const href =
            url + (_.isEmpty(query) ? '' : '?' + querystring.stringify(query));
        return (
            <a target={target} href={href}>
                <AutoEllipsis
                    text={
                        <>
                            {count !== 0 && (
                                <span className="action-link-contacts-minitags">
                                    {prepend}
                                </span>
                            )}
                            {value}
                        </>
                    }
                />
            </a>
        );
    }
    return null;
});

console.log('LinkLinkLinkLink', LinkContacts);
