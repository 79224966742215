import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    FormLV1,
    embedDataToWidgets,
    getAutoOnChangeHandler,
} from '@/components';
import { Alert } from 'antd';
import { OptionsList } from '@/components/common/form/widgets';
import _ from 'lodash';
import {
    IFormDemoData,
    IFormDemoExtraData,
    widgets,
    formItemOptions,
} from './options';
import { defaultAuthDeco } from '@/containers/hoc';

const FormDemo: React.FC<RouteComponentProps> = ({ children }) => {
    const [genderLoading, setGenderLoading] = useState(true);
    const [data, setData] = useState<IFormDemoData | null>(null);
    const [genderData, setGenderData] = useState<OptionsList<string>>([]);

    useEffect(() => {
        // 异步数据
        setTimeout(() => {
            setData({
                name: '狗子',
                gender: ['1'],
                accountStatus: false,
                joinDate: '2020-10-10 00:00:00',
                position: '1',
                interest: ['1', '2', '3'],
                authDateRange: ['2020-10-10 00:00:00', '2022-10-10 00:00:00'],
                keyword: '',
                numberRange: ['0', '100'],
            });
        }, 1000);

        // 异步选项
        setGenderLoading(true);
        setTimeout(() => {
            setGenderData([
                {
                    name: '男',
                    value: '1',
                },
                {
                    name: '女',
                    value: '2',
                },
            ]);
            setGenderLoading(false);
        }, 2000);
    }, []);
    if (data === null) {
        return <Alert message="无数据"></Alert>;
    }

    // 获取默认的数据回调
    const onDataChange = getAutoOnChangeHandler(data, setData);
    // 结合插件配置和真实数据
    const finalWidgets = widgets({ genderData });
    embedDataToWidgets<IFormDemoData>(finalWidgets, data);

    return (
        <FormLV1<IFormDemoData, IFormDemoExtraData>
            data={data}
            widgets={finalWidgets}
            onChange={onDataChange}
            statusExtraData={{ genderLoading }}
            formItemOptions={formItemOptions}
            labelWidth={12}
            // layout='inline'
            layout={[[8, 8, 8], [8, 8, 8], [12, 12], [8]]}
            onInteract={(key, type, value) => {
                console.log('key, type, value: ', key, type, value);
            }}
        ></FormLV1>
    );
};

const FinalFormDemo = defaultAuthDeco(FormDemo);
export default FinalFormDemo;
