import React, { useEffect, memo, useState } from 'react';

import { CustomerListStore } from '../store';
import { Modal, Form, Alert, message } from 'antd';
import _ from 'lodash';
import { WidgetSelect } from '@/components/common/form/widgets';
import TextArea from 'antd/lib/input/TextArea';
import './m-drop.scss';
import { runInAction } from 'mobx';
import { MOpNameDisplayContainer } from '@/components';
import { observer } from 'mobx-react-lite';
import { tryRefresh } from '@/utils';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const ActionMDrop: React.FC<{
    customerList: CustomerListStore;
}> = observer(({ customerList }) => {
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState(-1);
    const [remark, setRemark] = useState('');
    const resetForm = () => {
        setReason(-1);
        setRemark('');
    };
    const [requiredDetected, setRequiredDetected] = useState(false);
    const reasonHelps: any = requiredDetected
        ? {
              validateStatus: 'error',
              help: '退回原因必填',
          }
        : {};

    const { defaultMSelect, defaultMeta, mutatingData } = customerList;
    const customerName = mutatingData?.customer_name;
    const { mSeletedData: originMSelectedData } = defaultMSelect;
    const mSeletedData = mutatingData ? [mutatingData] : originMSelectedData;
    if (mSeletedData === null || mSeletedData.length === 0) {
        return null;
    }
    const transferHighSeaReasonTypeMeta = _.find(
        defaultMeta.objectMeta,
        item => item.fieldName === 'transfer_high_sea_reason_type',
    );

    let errorEle = null;
    if (!transferHighSeaReasonTypeMeta) {
        errorEle = <span>transferHighSeaReasonTypeMeta为空</span>;
    }
    const reasonOptions =
        transferHighSeaReasonTypeMeta?.constraint?.constraintItemList;
    if (!_.isArray(reasonOptions)) {
        errorEle = <span>reasonOptions为空</span>;
    }

    const visible = customerList.actionType === 'm-drop';
    return (
        <Modal
            visible={visible}
            title={'退回客户'}
            onCancel={() => {
                resetForm();
                customerList.resetAction();
            }}
            confirmLoading={loading}
            onOk={async () => {
                if (reason === -1) {
                    setRequiredDetected(true);
                    return;
                }
                Modal.confirm({
                    content: '请再次确认是否退回此客户？',
                    async onOk() {
                        setLoading(true);
                        const ok = await customerList.mDrop(reason, remark);
                        setLoading(false);
                        if (ok) {
                            resetForm();
                            runInAction(() => {
                                defaultMSelect.removeAllSelectedData();
                                customerList.resetAction();
                                tryRefresh(['customer', 'close-drawer']);
                            });
                        }
                    },
                });
            }}
        >
            {errorEle ? (
                errorEle
            ) : (
                <>
                    <div
                        className={
                            'crm-customer-drop ' + (loading ? 'loading' : '')
                        }
                    >
                        <h4>
                            {customerName ? (
                                <span>是否将【{customerName}】退回？</span>
                            ) : (
                                <span>
                                    是否将选择的{mSeletedData.length}
                                    个客户退回？
                                </span>
                            )}
                            <MOpNameDisplayContainer
                                defaultStore={customerList}
                                nameParam="customer_name"
                            />
                        </h4>
                        <div className="form-wrapper">
                            <Form className="form" layout={'horizontal'}>
                                <Form.Item
                                    {...reasonHelps}
                                    {...formItemLayout}
                                    required
                                    label={
                                        <span className="label">退回原因</span>
                                    }
                                >
                                    <WidgetSelect
                                        value={reason}
                                        options={{
                                            placeholder: '请选择退回原因',
                                            options: reasonOptions.map(
                                                (option: any) => ({
                                                    name:
                                                        option.constraintLabel,
                                                    value:
                                                        option.constraintValue,
                                                    disabled:
                                                        option.constraintValue ===
                                                        4,
                                                }),
                                            ),
                                        }}
                                        onChange={value => {
                                            setReason(value);
                                            setRequiredDetected(false);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...formItemLayout}
                                    label={<span className="label">备注</span>}
                                >
                                    <TextArea
                                        placeholder="备注信息（选填）"
                                        value={remark}
                                        onChange={e => {
                                            const value = e?.target?.value;
                                            if (!_.isNil(value)) {
                                                setRemark(value);
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="warning">
                            <Alert
                                type="warning"
                                message="退回后，客户将有可能被其他销售认领，你在30天内不可以重新认领。"
                            />
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
});
