import React, { useEffect, useState, useMemo } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    AntButton,
    loadingWrapperOver,
    TableLv2,
} from '@/components';
import { Modal, message, Input, Select, Form, Alert } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useRequest } from 'ahooks';
import FormDrawer from './components/form-drawer';
import FormDrawerSub from './components/form-drawer-sub';
import FormModalUpload from './components/form-modal-upload';
import {
    fetchTagList,
    saveTagGroup,
    saveTagItem,
    deleteTagGroup,
    deleteTagItem,
    bindCustomer,
    TagGroupInfo,
} from './api';

const TagList: React.FC<RouteComponentProps> = () => {
    //标签组抽屉
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    //标签抽屉
    const [visibleDrawerSub, setVisibleDrawerSub] = useState(false);
    //上传客户弹窗
    const [visibleModal, setVisibleModal] = useState(false);

    const [tagScope, setTagScope] = useState(0);
    const [effect, setEffect] = useState(1);
    const [tagName, setTagName] = useState('');

    // 表单回填数据
    const [initialValues, setInitialValues] = useState<any>(null);
    const [tagGroupId, setTagGroupId] = useState('');

    const { data: tagListData = [], loading, refresh } = useRequest(
        () => fetchTagList({ scope: tagScope, effect, name: tagName }),
        {
            refreshDeps: [tagScope, effect, tagName],
        },
    );

    useEffect(() => {
        // 关闭时清理数据
        if (!visibleDrawer) {
            setInitialValues(null);
        }
    }, [visibleDrawer]);

    useEffect(() => {
        // 关闭时清理数据
        if (!visibleDrawerSub) {
            setInitialValues(null);
            setTagGroupId('');
        }
    }, [visibleDrawerSub]);

    useEffect(() => {
        // 关闭时清理数据
        if (!visibleModal) {
            setInitialValues(null);
        }
    }, [visibleModal]);

    const TagGroup = useMemo(() => {
        const group = tagListData.map(tag => {
            return { name: tag.name, id: tag.id };
        });
        return group;
    }, [tagListData]);

    const finalLoading = false;

    const filtersDisplay = (
        <Form layout="inline">
            <Form.Item label="我的标签">
                <Select
                    value={tagScope}
                    style={{ width: 120 }}
                    onChange={(value: number) => {
                        setTagScope(value);
                    }}
                >
                    <Select.Option value={0}>全部标签</Select.Option>
                    <Select.Option value={1}>由我创建的</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="是否生效">
                <Select
                    value={effect}
                    style={{ width: 120 }}
                    onChange={(value: number) => {
                        setEffect(value);
                    }}
                >
                    <Select.Option value={1}>是</Select.Option>
                    <Select.Option value={0}>否</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item>
                <Input.Search
                    placeholder="搜索标签/标签组"
                    onSearch={value => setTagName(value)}
                    style={{ width: 200 }}
                />
            </Form.Item>
        </Form>
    );

    const popups = (
        <>
            <FormModalUpload
                visible={visibleModal}
                initialValues={initialValues}
                tagGroup={TagGroup}
                onClose={() => {
                    setVisibleModal(false);
                }}
                onSubmit={values => {
                    console.log('values1', values);
                    bindCustomer(values).then(() => {
                        setVisibleModal(false);
                        refresh();
                    });
                }}
            />
            <FormDrawer
                visible={visibleDrawer}
                initialValues={initialValues}
                onClose={() => {
                    setVisibleDrawer(false);
                }}
                onSubmit={values => {
                    saveTagGroup(values).then(() => {
                        setVisibleDrawer(false);
                        refresh();
                    });
                }}
            />
            <FormDrawerSub
                visible={visibleDrawerSub}
                initialValues={initialValues}
                tagGroup={TagGroup}
                tagGroupId={tagGroupId}
                onClose={() => {
                    setVisibleDrawerSub(false);
                }}
                onSubmit={values => {
                    saveTagItem(values).then(() => {
                        setVisibleDrawerSub(false);
                        refresh();
                    });
                }}
            />
        </>
    );

    const globalOps = (
        <AntButton
            onClick={() => {
                setVisibleDrawer(true);
            }}
            type="primary"
            size="large"
        >
            新建标签组
        </AntButton>
    );
    const multipleOps = null;
    console.log('tagListData', tagListData);
    const dataTable = (
        <TableLv2<TagGroupInfo>
            dataSource={tagListData}
            loading={loading}
            columns={[
                {
                    title: '名称',
                    dataIndex: 'name',
                    width: 160,
                },
                {
                    title: '标签说明',
                    dataIndex: 'comment',
                    width: 160,
                    render: v => v || '--',
                },
                {
                    title: '是否可换绑客户',
                    dataIndex: 'changed',
                    width: 100,
                    render: v => {
                        return v === 1 ? '是' : '否';
                    },
                },
                {
                    title: '绑定客户数',
                    dataIndex: 'num',
                    width: 80,
                    render: v => (_.isNil(v) ? '--' : v),
                },

                {
                    title: '创建时间',
                    dataIndex: 'create_time',
                    width: 140,
                    render: v => {
                        return moment(v).format('YYYY-MM-DD HH:mm');
                    },
                },
                {
                    title: '创建人',
                    dataIndex: 'create_user_name',
                    width: 80,
                    render: v => (_.isNil(v) ? '--' : v),
                },
                {
                    title: '所有人',
                    dataIndex: 'owner_name',
                    width: 80,
                    render: v => (_.isNil(v) ? '--' : v),
                },
            ]}
            actions={[
                {
                    actionKey: 'update',
                    actionCn: '修改',
                },
                {
                    actionKey: 'delete',
                    actionCn: '删除',
                },
                {
                    actionKey: 'create',
                    actionCn: '新建标签',
                    isVisible: record => {
                        return !record.group_id;
                    },
                },
                {
                    actionKey: 'upload',
                    actionCn: '上传客户',
                    isVisible: record => {
                        return record.group_id;
                    },
                },
            ]}
            onAction={(actionKey, record) => {
                const isGroup = !record?.group_id;
                if (actionKey === 'create') {
                    setVisibleDrawerSub(true);
                    setTagGroupId(record.id);
                }
                if (actionKey === 'update') {
                    isGroup
                        ? setVisibleDrawer(true)
                        : setVisibleDrawerSub(true);

                    setInitialValues(record);
                }
                if (actionKey === 'delete') {
                    Modal.confirm({
                        title: isGroup ? '删除标签组' : '删除标签',
                        content: (
                            <>
                                <p>
                                    {isGroup
                                        ? `是否删除标签组【${
                                              record.name
                                          }】？该标签组目前包含${record.items
                                              ?.length || 0}个标签`
                                        : `是否删除标签【${
                                              record.name
                                          }】？该标签目前绑定了${record.num ||
                                              0}个对象`}
                                </p>
                                <Alert
                                    message="删除成功之后，该操作无法恢复"
                                    type="error"
                                />
                            </>
                        ),
                        icon: null,
                        onOk: async () => {
                            if (isGroup) {
                                await deleteTagGroup(record.id);
                            } else {
                                await deleteTagItem(record.id);
                            }
                            refresh();
                        },
                    });
                }
                if (actionKey === 'upload') {
                    setVisibleModal(true);
                    setInitialValues(record);
                }
            }}
            actionsWidth={180}
            childrenColumnName="items"
            closeRowSelection={true}
            rowKey={record => {
                const isGroup = !record?.group_id;
                return record.id + (isGroup ? '_group' : '_item');
            }}
        />
    );

    const layoutComs = {
        popups,
        title: null,
        globalOps,
        filters: null,
        multipleOps,
        dataTable,
        message: null,
        filtersDisplay: filtersDisplay,
    };

    return loadingWrapperOver(finalLoading)(
        <LayoutStandardCrud {...layoutComs} />,
    );
};
export default observer(TagList);
