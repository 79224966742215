import React, { memo, useContext, useEffect } from 'react';
import {
    applyCustomConfToWidget,
    loadingWrapper,
    DForm,
    applyConfToFormItem,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { LeadListStore } from '../store';
import { IInputSearchableOptions } from '@/components/common/form/widgets';
import { Alert } from 'antd';
import { getFollowStatusesByOriginalFollowStatus } from '../utils';
import { StoreContext } from '@/stores';

const LeadForm: React.FC<{
    leadList: LeadListStore;
    fromCustomerTab?: boolean; // 是否从customer详情的tab里面进入的
    isEdit?: boolean; // 是否为编辑
    type?: string;
}> = ({ leadList, fromCustomerTab, isEdit, type }) => {
    fromCustomerTab = _.isNil(fromCustomerTab) ? false : fromCustomerTab;
    isEdit = _.isNil(isEdit) ? false : isEdit;
    const {
        // 自定义
        companyNameLint, // 公司名提示
        autoFillingLoading,
        actionType,
        mutatingData,
    } = leadList;

    const store = useContext(StoreContext);
    const userStore = store.getAuthStore();

    const supportedTypes =
        userStore.userInfo.supportedBizTypes?.['lead']?.businessTypeIds || [];
    const supportedTypesSet = new Set(supportedTypes);
    useEffect(() => {
        if (supportedTypes.length === 1 && leadList.mutatingData) {
            leadList.mutatingData.biz_type = supportedTypes[0];
        }
    }, [supportedTypes, leadList.mutatingData]);

    let eventCode = '';
    if (actionType === 'create') {
        eventCode = 'CREATE-EDIT';
    } else if (actionType === 'update') {
        if (type === 'all') {
            eventCode = 'PUBLIC-EDIT';
        } else if (type === 'mine') {
            eventCode = 'PRIVATE-EDIT';
        }
    }

    if (eventCode === '') {
        return null;
    }

    const form = (
        <DForm
            defaultStore={leadList}
            eventCode={eventCode}
            onInteract={(key, type, value) => {
                if (
                    key === 'company_name' &&
                    type === 'input-outside-clicked'
                ) {
                    // !!! use instant value
                    const customerName = leadList.mutatingData?.company_name;
                    if (
                        customerName &&
                        leadList.autoFillingByCName?.customerName !==
                            customerName &&
                        leadList.actionType === 'create'
                    ) {
                        leadList.autoFillOtherParamsByCustomerName(
                            customerName,
                        );
                    }
                }
            }}
            widgetsTransformer={baiscWidgets => {
                // 自定义组件
                applyConfToFormItem(baiscWidgets, 'inputSecret', {
                    sceneCode: 'crm_lead',
                });
                applyCustomConfToWidget(
                    baiscWidgets,
                    'company_name',
                    prevWidget => {
                        const nextWidget = _.cloneDeep(prevWidget);
                        nextWidget.widgetKey = 'inputSearchable';

                        nextWidget.status = {
                            loading: () => {
                                return autoFillingLoading;
                            },
                        };

                        const nextOptions: IInputSearchableOptions = _.assign(
                            {},
                            nextWidget.inputOptions,
                            {
                                api: '/crm/customer/findCustomerNameAndCode',
                                textKey: 'customerName',
                                valueKey: 'customerName',
                            },
                        );

                        // if (companyNameLint === '') {
                        //     delete nextWidget.nextlineWidget;
                        // } else {
                        //     nextWidget.nextlineWidget = () => (
                        //         <div style={{ padding: 5 }}>
                        //             {loadingWrapper(autoFillingLoading)(
                        //                 <Alert
                        //                     type="info"
                        //                     message={companyNameLint}
                        //                 />,
                        //             )}
                        //         </div>
                        //     );
                        // }

                        // if (fromCustomerTab) {
                        //     nextOptions.disabled = true;
                        // }
                        nextWidget.inputSearchableOptions = nextOptions;
                        return nextWidget;
                    },
                );

                // 场景化的先做shallow copy
                baiscWidgets = [...baiscWidgets];
                if (fromCustomerTab) {
                    applyCustomConfToWidget(
                        baiscWidgets,
                        'lead_source',
                        prevWidget => {
                            const nextWidget = _.cloneDeep(prevWidget);
                            if (nextWidget.selectOptions) {
                                nextWidget.selectOptions.disabled = true;
                            }
                            if (nextWidget.radioOptions) {
                                nextWidget.radioOptions.disabled = true;
                            }
                            return nextWidget;
                        },
                    );
                }

                if (!isEdit) {
                    applyCustomConfToWidget(
                        baiscWidgets,
                        'follow_status',
                        prevWidget => {
                            const nextWidget = _.cloneDeep(prevWidget);
                            nextWidget.status = nextWidget.status || {};
                            nextWidget.status.hidden = true;
                            return nextWidget;
                        },
                    );
                } else {
                    applyCustomConfToWidget(
                        baiscWidgets,
                        'company_name',
                        prevWidget => {
                            const nextWidget = _.cloneDeep(prevWidget);
                            nextWidget.status = nextWidget.status || {};
                            nextWidget.status.hidden = true;
                            return nextWidget;
                        },
                    );

                    applyCustomConfToWidget(
                        baiscWidgets,
                        'lead_source',
                        prevWidget => {
                            const nextWidget = _.cloneDeep(prevWidget);
                            nextWidget.status = nextWidget.status || {};
                            nextWidget.status.hidden = true;
                            return nextWidget;
                        },
                    );

                    const originalFollowStatus =
                        mutatingData._original_follow_status;
                    const selectableFollowStatuses = getFollowStatusesByOriginalFollowStatus(
                        originalFollowStatus,
                    );
                    if (Array.from(selectableFollowStatuses).length === 0) {
                        applyCustomConfToWidget(
                            baiscWidgets,
                            'follow_status',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                nextWidget.status = nextWidget.status || {};
                                nextWidget.status.disabled = true;
                                return nextWidget;
                            },
                        );
                    } else {
                        applyCustomConfToWidget(
                            baiscWidgets,
                            'follow_status',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                if (nextWidget.selectOptions) {
                                    const options =
                                        nextWidget.selectOptions.options;
                                    nextWidget.selectOptions.options = options.filter(
                                        option =>
                                            selectableFollowStatuses.has(
                                                option.value as number,
                                            ),
                                    );
                                    console.log(
                                        'bnjnbnjhbvghuji',
                                        selectableFollowStatuses,
                                        nextWidget.selectOptions.options,
                                        options,
                                    );
                                }
                                return nextWidget;
                            },
                        );
                    }
                }

                applyCustomConfToWidget(
                    baiscWidgets,
                    'biz_type',
                    nextWidget => {
                        if (nextWidget.selectOptions) {
                            nextWidget.selectOptions.options = nextWidget.selectOptions.options.filter(
                                item => {
                                    return supportedTypesSet.has(+item.value);
                                },
                            );
                        }
                        if (fromCustomerTab) {
                            nextWidget.status = {
                                ...nextWidget.status,
                                disabled: true,
                            };
                        }
                        return nextWidget;
                    },
                );

                return baiscWidgets;
            }}
        />
    );
    return form;
};

const FinalLeadForm = memo(observer(LeadForm));
export default FinalLeadForm;
