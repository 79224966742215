import React from 'react';
import { Input } from 'antd';
import { getCommonStatus, ICommonProps } from './common';
import { RangePickerProps } from 'antd/lib/date-picker/interface';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from './date';
import _ from 'lodash';
import { InputProps } from 'antd/lib/input';

export type ValueRangeValueType = [string, string];
export interface IValueRangeOptions extends InputProps {
    fromInputProps?: InputProps;
    toInputProps?: InputProps;
}

export interface IWidgetValueRangeProps<T = any> extends ICommonProps<T> {
    value: ValueRangeValueType;
    options: IValueRangeOptions;
    onChange: (value: ValueRangeValueType) => void;
}

export const WidgetValueRange: React.FC<IWidgetValueRangeProps> = ({
    value,
    options,
    onChange,
    onInteract = key => void 0,
    status,
    data,
    statusExtraData,
}) => {
    const { disabled } = getCommonStatus(status, data, statusExtraData);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }
    const [fromValue, toValue] = value;
    const { fromInputProps, toInputProps } = options;
    return (
        <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ paddingRight: 5 }}>
                    <Input
                        {...options}
                        {...fromInputProps}
                        value={fromValue}
                        onChange={e => {
                            if (e.target && !_.isNil(e.target.value)) {
                                onChange([e.target.value, toValue]);
                            }
                        }}
                        onFocus={e => {
                            onInteract('focus');
                            onInteract('remove-validate-status');
                        }}
                        onBlur={e => {
                            onInteract('blur');
                            onInteract('validate-instantly');
                        }}
                    />
                </div>
                <span style={{ fontSize: '2em' }}>~</span>
                <div style={{ paddingLeft: 5 }}>
                    <Input
                        {...options}
                        {...toInputProps}
                        value={toValue}
                        onChange={e => {
                            if (e.target && !_.isNil(e.target.value)) {
                                onChange([fromValue, e.target.value]);
                            }
                        }}
                        onFocus={e => {
                            onInteract('focus');
                            onInteract('remove-validate-status');
                        }}
                        onBlur={e => {
                            onInteract('blur');
                            onInteract('validate-instantly');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
