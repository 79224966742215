import { defaultAxios } from '@/utils';
import { NexusGenInputs } from '@/definations/graphql/auto-gen';
import _ from 'lodash';

export type TGetTransparentReturnType = any;

// DIRTY
export const transparentQuery = async (
    body: any,
    tableCode: string,
    api?: string,
    filter?: string,
    appendQuery?: { [key: string]: string },
): Promise<TGetTransparentReturnType> => {
    const [d, e] = await defaultAxios[api ? 'get' : 'post'](
        api ? api : '/bff/api/rest/transparent/list?tableCode=' + tableCode,
        api ? { filter, ...appendQuery } : body,
    );
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = api ? d.data.data : d.data;
    const error = e as Error | null;
    return [data, error];
};

export type TCommonDeleteReturnType = any;

export const xDelete = async (
    tableId: string,
    operatingIdList: number[],
): Promise<TCommonDeleteReturnType> => {
    const [d, e] = await defaultAxios.delete(
        '/crm/dml/' + tableId,
        operatingIdList,
    );
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = d.data;
    const error = e as Error | null;
    return [data, error];
};

export const commonDelete = async (
    tableId: string,
    operatingIdList: number[],
): Promise<TCommonDeleteReturnType> => {
    const idsStr = operatingIdList.map(id => '' + id).join(',');
    const fdata = new FormData();
    fdata.append('ids', idsStr);
    const [d, e] = await defaultAxios.delete('/crm/common/' + tableId, fdata);
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = d.data;
    const error = e as Error | null;
    return [data, error];
};

export type TCommonValidateReturnType = any;

export const commonValidate = async (
    tableId: number,
    param: any,
    checkList: string[],
): Promise<TCommonDeleteReturnType> => {
    const [d, e] = await defaultAxios.post('/crm/dml/checkModel', {
        tableId,
        param,
        checkList,
    });
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = d.data;
    const error = e as Error | null;
    return [data, error];
};

export type TGetCommonReturnType = NetResponse<{
    amount: number;
    datas: any[];
}>;

export const getCommonQueryBody = (
    pagination: NexusGenInputs['PaginationArgs'],
    filters?: NexusGenInputs['FiltersArgs'],
    sorter?: NexusGenInputs['SorterArgs'],
    fieldAuthCode?: string,
    selectiveFields?: string[],
    dataAuthCode?: string,
    withDefaultFields?: boolean,
) => {
    withDefaultFields = _.isUndefined(withDefaultFields)
        ? true
        : withDefaultFields;
    const body: any = {
        pagination,
        filters,
        sorter,
    };
    if (_.isArray(selectiveFields)) {
        if (withDefaultFields) {
            body.selectiveFields = _.uniq([
                'id',
                'biz_type',
                ...selectiveFields,
            ]);
        } else {
            body.selectiveFields = selectiveFields;
        }
    }
    if (_.isString(fieldAuthCode) && fieldAuthCode !== '') {
        body.fieldAuthCode = fieldAuthCode;
    }
    if (_.isString(dataAuthCode) && dataAuthCode !== '') {
        body.dataAuthCode = dataAuthCode;
    }
    return body;
};

export const getCommonProcessQueryBody = (
    pagination: NexusGenInputs['PaginationArgs'],
    filters?: NexusGenInputs['FiltersArgs'],
    sorter?: NexusGenInputs['SorterArgs'],
    fieldAuthCode?: string,
    selectiveFields?: string[],
    dataAuthCode?: string,
    withDefaultFields?: boolean,
) => {
    withDefaultFields = _.isUndefined(withDefaultFields)
        ? true
        : withDefaultFields;
    const body: any = {
        pagination,
        filters,
        sorter,
    };
    if (_.isArray(selectiveFields)) {
        if (withDefaultFields) {
            body.selectiveFields = _.uniq([
                'id',
                'biz_type',
                'process_instance_id',
                ...selectiveFields,
            ]);
        } else {
            body.selectiveFields = selectiveFields;
        }
    }
    if (_.isString(fieldAuthCode) && fieldAuthCode !== '') {
        body.fieldAuthCode = fieldAuthCode;
    }
    if (_.isString(dataAuthCode) && dataAuthCode !== '') {
        body.dataAuthCode = dataAuthCode;
    }
    return body;
};

export const getCommonJobQueryBody = (
    pagination: NexusGenInputs['PaginationArgs'],
    filters?: NexusGenInputs['FiltersArgs'],
    sorter?: NexusGenInputs['SorterArgs'],
    fieldAuthCode?: string,
    selectiveFields?: string[],
    dataAuthCode?: string,
    withDefaultFields?: boolean,
) => {
    withDefaultFields = _.isUndefined(withDefaultFields)
        ? true
        : withDefaultFields;
    const body: any = {
        pagination,
        filters,
        sorter,
    };
    if (_.isArray(selectiveFields)) {
        if (withDefaultFields) {
            body.selectiveFields = _.uniq(['id', ...selectiveFields]);
        } else {
            body.selectiveFields = selectiveFields;
        }
    }
    if (_.isString(fieldAuthCode) && fieldAuthCode !== '') {
        body.fieldAuthCode = fieldAuthCode;
    }
    if (_.isString(dataAuthCode) && dataAuthCode !== '') {
        body.dataAuthCode = dataAuthCode;
    }
    return body;
};

export const commonQuery = async (
    tableCode: string,
    pagination: NexusGenInputs['PaginationArgs'],
    filters?: NexusGenInputs['FiltersArgs'],
    sorter?: NexusGenInputs['SorterArgs'],
    fieldAuthCode?: string,
    selectiveFields?: string[],
    dataAuthCode?: string,
    withDefaultFields?: boolean,
    api?: string,
): Promise<TGetCommonReturnType> => {
    const body: any = getCommonQueryBody(
        pagination,
        filters,
        sorter,
        fieldAuthCode,
        selectiveFields,
        dataAuthCode,
        withDefaultFields,
    );
    const [d, e] = await defaultAxios.post(
        api ? api : '/bff/api/rest/' + tableCode + '/list',
        body,
    );
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = d.data as {
        amount: number;
        datas: any[];
    };
    const error = e as Error | null;
    return [data, error];
};

export const commonStarQuery = async (
    tableCode: string,
    pagination: NexusGenInputs['PaginationArgs'],
    filters?: NexusGenInputs['FiltersArgs'],
    sorter?: NexusGenInputs['SorterArgs'],
    fieldAuthCode?: string,
    selectiveFields?: string[],
    dataAuthCode?: string,
    withDefaultFields?: boolean,
    api?: string,
): Promise<TGetCommonReturnType> => {
    const body: any = getCommonQueryBody(
        pagination,
        filters,
        sorter,
        fieldAuthCode,
        selectiveFields,
        dataAuthCode,
        withDefaultFields,
    );
    const [d, e] = await defaultAxios.post(
        api ? api : '/bff/api/rest/' + tableCode + '/list/stars',
        body,
    );
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = d.data as {
        amount: number;
        datas: any[];
    };
    const error = e as Error | null;
    return [data, error];
};

export const commonQueryOutflow = async (
    tableCode: string,
    pagination: NexusGenInputs['PaginationArgs'],
    filters?: NexusGenInputs['FiltersArgs'],
    sorter?: NexusGenInputs['SorterArgs'],
    fieldAuthCode?: string,
    selectiveFields?: string[],
    dataAuthCode?: string,
    withDefaultFields?: boolean,
    api?: string,
): Promise<TGetCommonReturnType> => {
    const body: any = getCommonQueryBody(
        pagination,
        filters,
        sorter,
        fieldAuthCode,
        selectiveFields,
        dataAuthCode,
        withDefaultFields,
    );
    const [d, e] = await defaultAxios.post(
        api ? api : '/bff/api/rest/' + tableCode + '/list/outflow',
        body,
    );
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = d.data as {
        amount: number;
        datas: any[];
    };
    const error = e as Error | null;
    return [data, error];
};

export const commonQueryCount = async (
    tableCode: string,
    pagination: NexusGenInputs['PaginationArgs'],
    filters?: NexusGenInputs['FiltersArgs'],
    sorter?: NexusGenInputs['SorterArgs'],
    fieldAuthCode?: string,
    selectiveFields?: string[],
    dataAuthCode?: string,
    withDefaultFields?: boolean,
    api?: string,
): Promise<any> => {
    const body: any = getCommonQueryBody(
        pagination,
        filters,
        sorter,
        fieldAuthCode,
        selectiveFields,
        dataAuthCode,
        withDefaultFields,
    );
    const [d, e] = await defaultAxios.post(
        api ? api : '/bff/api/rest/commoncount?tableCode=' + tableCode,
        { ...body, selectiveFields },
    );
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = d.data;
    const error = e as Error | null;
    return [data, error];
};

export const commonQueryProcess = async (
    tableCode: string,
    pagination: NexusGenInputs['PaginationArgs'],
    filters?: NexusGenInputs['FiltersArgs'],
    sorter?: NexusGenInputs['SorterArgs'],
    fieldAuthCode?: string,
    selectiveFields?: string[],
    dataAuthCode?: string,
    withDefaultFields?: boolean,
): Promise<TGetCommonReturnType> => {
    const body: any = getCommonProcessQueryBody(
        pagination,
        filters,
        sorter,
        fieldAuthCode,
        selectiveFields,
        dataAuthCode,
        withDefaultFields,
    );
    const [d, e] = await defaultAxios.post(
        '/bff/api/rest/process/category-list/?tableCode=' + tableCode,
        body,
    );
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = d.data as {
        amount: number;
        datas: any[];
    };
    const error = e as Error | null;
    return [data, error];
};

// 逻辑收口到bff层，后续直接改bff
export const commonQueryJob = async (
    tableCode: string,
    pagination: NexusGenInputs['PaginationArgs'],
    filters?: NexusGenInputs['FiltersArgs'],
    sorter?: NexusGenInputs['SorterArgs'],
    fieldAuthCode?: string,
    selectiveFields?: string[],
    dataAuthCode?: string,
    withDefaultFields?: boolean,
): Promise<TGetCommonReturnType> => {
    const body: any = getCommonJobQueryBody(
        pagination,
        filters,
        sorter,
        fieldAuthCode,
        selectiveFields,
        dataAuthCode,
        withDefaultFields,
    );
    const [d, e] = await defaultAxios.post(
        '/bff/api/rest/job/category-list/?tableCode=' + tableCode,
        body,
    );
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = d.data as {
        amount: number;
        datas: any[];
    };
    const error = e as Error | null;
    return [data, error];
};

export const commonQueryProcess2 = async (
    tableCode: string,
    pagination: NexusGenInputs['PaginationArgs'],
    filters?: NexusGenInputs['FiltersArgs'],
    sorter?: NexusGenInputs['SorterArgs'],
    fieldAuthCode?: string,
    selectiveFields?: string[],
    dataAuthCode?: string,
    withDefaultFields?: boolean,
    processCode?: string,
): Promise<TGetCommonReturnType> => {
    const body: any = getCommonProcessQueryBody(
        pagination,
        filters,
        sorter,
        fieldAuthCode,
        selectiveFields,
        dataAuthCode,
        withDefaultFields,
    );
    const [
        d,
        e,
    ] = await defaultAxios.post(
        '/bff/api/rest/process/category-list-2/?tableCode=' + tableCode,
        { ...body, processCode },
    );
    if (d === null || d.data === null) {
        return [null, e];
    }
    const data = d.data as {
        amount: number;
        datas: any[];
    };
    const error = e as Error | null;
    return [data, error];
};
