import {
    defaultAxios,
    arrToJsonPrimary,
    arrToSortHelper,
    arrToSortHelperPrimary,
    sortArrBySortHelper,
} from '@/utils';
import _ from 'lodash';

export const homePageMetaGetter: () => Promise<any> = async () => {
    const [d, e] = await defaultAxios.post(
        '/bff/api/rest/meta/cu_activation_record/list',
        {
            opUsername: 'anyuser',
        },
    );

    const data = d ? d.data : null;
    if (data === null) {
        return [d, e];
    }

    // 给副作用
    const whiteList = ['customer_id', 'next_date', 'next_note'];
    const whiteListMap = arrToJsonPrimary(whiteList);
    data.objectMeta = data.objectMeta.filter(
        (item: any) => whiteListMap[item.fieldName],
    );
    data.tableColsParamsAll = data.tableColsParamsAll.filter(
        (item: any) => whiteListMap[item.key],
    );
    data.tableColsParamsFromUserSetting = _.cloneDeep(data.tableColsParamsAll);

    const sh = arrToSortHelperPrimary(whiteList);
    sortArrBySortHelper(data.tableColsParamsAll, sh, 'key');
    sortArrBySortHelper(data.tableColsParamsFromUserSetting, sh, 'key');

    return [d, e];
};
