import React, { useEffect, memo, useState } from 'react';

import { CustomerListStore } from '../store';
import { Modal, Alert } from 'antd';
import { runInAction } from 'mobx';
import _ from 'lodash';
import { MOpNameDisplayContainer } from '@/components';
import { observer } from 'mobx-react-lite';
import { tryRefresh } from '@/utils';

export const ActionMDelete: React.FC<{
    customerList: CustomerListStore;
}> = observer(({ customerList }) => {
    const { defaultMSelect } = customerList;
    const { mSeletedData } = defaultMSelect;
    const [loading, setLoading] = useState(false);
    if (mSeletedData === null || mSeletedData.length === 0) {
        return null;
    }
    const visible = customerList.actionType === 'm-delete';
    return (
        <Modal
            visible={visible}
            title={'批量删除客户'}
            onCancel={() => {
                customerList.resetAction();
            }}
            confirmLoading={loading}
            onOk={async () => {
                setLoading(true);
                const ok = await customerList.mdelete();
                setLoading(false);
                if (ok) {
                    runInAction(() => {
                        customerList.resetAction();
                        defaultMSelect.removeAllSelectedData();
                        tryRefresh(['customer']);
                    });
                }
            }}
        >
            <h4>
                是否将选择的{mSeletedData.length}个客户删除？
                <MOpNameDisplayContainer
                    defaultStore={customerList}
                    nameParam="customer_name"
                />
            </h4>
            <Alert type="error" message="删除成功之后，该操作无法恢复。" />
        </Modal>
    );
});
