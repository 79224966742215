import React, { useEffect } from 'react';
import { InputNumber } from 'antd';
import { getCommonStatus, ICommonProps } from '../common';
import _ from 'lodash';
import { InputNumberProps } from 'antd/lib/input-number';
import './number-range.scss';

export type FilterNumberRangeValueType = [
    number | undefined,
    number | undefined,
];
export interface IFilterNumberRangeOptions extends InputNumberProps {
    fromInputProps?: InputNumberProps;
    fromInputText?: string;
    toInputProps?: InputNumberProps;
    toInputText?: string;
}

export interface IWidgetFilterNumberRangeProps<T = any>
    extends ICommonProps<T> {
    value: FilterNumberRangeValueType;
    options: IFilterNumberRangeOptions;
    onChange: (value: FilterNumberRangeValueType) => void;
}

export const WidgetFilterNumberRange: React.FC<IWidgetFilterNumberRangeProps> = ({
    value,
    options,
    onChange,
    onInteract = key => void 0,
    status,
    data,
    statusExtraData,
}) => {
    const { disabled } = getCommonStatus(status, data, statusExtraData);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }
    const [fromValue, toValue] = value;
    const {
        fromInputProps,
        toInputProps,
        fromInputText = '从',
        toInputText = '到',
    } = options;

    const fromPlaceHolder =
        fromInputProps?.placeholder || options.placeholder || '默认从负无穷';
    const toPlaceHolder =
        toInputProps?.placeholder || options.placeholder || '默认到正无穷';

    const invalid = (toValue || Infinity) < (fromValue || -Infinity);
    useEffect(() => {
        if (invalid) {
            onInteract('filter-become-invalid');
        } else {
            onInteract('filter-become-valid');
        }
    }, [invalid]);

    return (
        <div className="crm-widgets-filter-range-input">
            <div className="value-input">
                <div className="label">
                    <span>{fromInputText}</span>
                </div>
                <div className="input">
                    <InputNumber
                        {...options}
                        {...fromInputProps}
                        value={fromValue}
                        onChange={e => {
                            onChange([e, toValue]);
                        }}
                        onFocus={e => {
                            onInteract('focus');
                            onInteract('remove-validate-status');
                        }}
                        onBlur={e => {
                            onInteract('blur');
                            onInteract('validate-instantly');
                        }}
                        placeholder={fromPlaceHolder}
                    />
                </div>
            </div>
            <div className="value-input">
                <div className="label">
                    <span>
                        {toInputText}
                        {invalid ? (
                            <span style={{ color: '#ff001f' }}>
                                （应大于起始值）
                            </span>
                        ) : null}
                    </span>
                </div>
                <div className="input">
                    <InputNumber
                        {...options}
                        {...toInputProps}
                        value={toValue}
                        onChange={e => {
                            onChange([fromValue, e]);
                        }}
                        onFocus={e => {
                            onInteract('focus');
                            onInteract('remove-validate-status');
                        }}
                        onBlur={e => {
                            onInteract('blur');
                            onInteract('validate-instantly');
                        }}
                        placeholder={toPlaceHolder}
                    />
                </div>
            </div>
        </div>
    );
};
