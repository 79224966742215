import { makeAutoObservable, observable } from 'mobx';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useQueryAsObject } from '@/utils';

export const useFirstLv4Menu = (ffStore: FastFilterStore, cb?: () => void) => {
    const { selectedLv4MenuFilters, lv4MenuFilters } = ffStore;
    useEffect(() => {
        // 如果没有type或者已经选择了，则无视
        if (!ffStore.type || ffStore.selectedLv4MenuFilters.length > 0) {
            return;
        }
        const { lv4MenuFilters } = ffStore;
        if (lv4MenuFilters.length === 0) {
            return;
        }
        ffStore.setSelectedLv4MenuFilters([(lv4MenuFilters as any)[0]]);
        // 首次选择菜单已ready
        cb && cb();
    }, [ffStore.type, selectedLv4MenuFilters, lv4MenuFilters]);
};

export const useFastFilterByUrl = (ffStore: FastFilterStore) => {
    useQueryAsObject(
        queryObject => {
            if (!_.isString(queryObject.fastfilter)) {
                return null;
            }

            const selectedFF = ffStore.fastFilters.find(
                (item: any) => item.fastFilterKey === queryObject.fastfilter,
            );
            if (!selectedFF) {
                return null;
            }
            ffStore.setSelectedFastFilters([selectedFF]);
        },
        [ffStore.innerfastFilters],
    );
};

export const useFirstLv4Menu2 = (
    ffStore: FastFilterStore,
    condi: () => boolean,
) => {
    const { selectedLv4MenuFilters, lv4MenuFilters } = ffStore;
    useEffect(() => {
        if (!condi()) {
            return;
        }
        // 如果没有type或者已经选择了，则无视
        if (!ffStore.type || ffStore.selectedLv4MenuFilters.length > 0) {
            return;
        }
        const { lv4MenuFilters } = ffStore;
        if (lv4MenuFilters.length === 0) {
            return;
        }
        ffStore.setSelectedLv4MenuFilters([(lv4MenuFilters as any)[0]]);
    }, [ffStore.type, selectedLv4MenuFilters, lv4MenuFilters]);
};

export const useLv4MenuByKey = (
    ffStore: FastFilterStore,
    key: string,
    condi: () => boolean,
) => {
    // buy-lead-mine-private
    const { selectedLv4MenuFilters, lv4MenuFilters } = ffStore;
    useEffect(() => {
        if (!condi()) {
            return;
        }
        // 如果没有type或者已经选择了，则无视
        if (!ffStore.type || ffStore.selectedLv4MenuFilters.length > 0) {
            return;
        }
        const { lv4MenuFilters } = ffStore;
        const targetFilter = lv4MenuFilters.find(
            (item: any) => item.key === key,
        );
        if (targetFilter) {
            ffStore.setSelectedLv4MenuFilters([targetFilter as any]);
            return;
        }
        if (lv4MenuFilters.length === 0) {
            return;
        }
        ffStore.setSelectedLv4MenuFilters([(lv4MenuFilters as any)[0]]);
        // 首次选择菜单已ready
    }, [ffStore.type, selectedLv4MenuFilters, lv4MenuFilters]);
};

export const useFastFilterByKey = (
    ffStore: FastFilterStore,
    key: string,
    condi: () => boolean,
) => {
    const { selectedFastFilters, fastFilters } = ffStore;
    const [used, setUsed] = useState(false);
    useEffect(() => {
        if (!condi() || used) {
            return;
        }
        // 如果没有type或者已经选择了，则无视
        if (!ffStore.type || ffStore.selectedFastFilters.length > 0) {
            return;
        }
        const { fastFilters } = ffStore;
        const targetFilter = fastFilters.find(
            (item: any) => item.fastFilterKey === key,
        );
        if (targetFilter) {
            setUsed(true);
            ffStore.setSelectedFastFilters([targetFilter as any]);
            return;
        }
    }, [ffStore.type, selectedFastFilters, fastFilters, condi, used]);
};

export class FastFilterStore {
    public type = '';
    public setType(nextType: string) {
        this.type = nextType;
    }

    public innerlv4MenuFilters: { [type: string]: [] } = {};
    public innerfastFilters: { [type: string]: [] } = {};

    get lv4MenuFilters() {
        return this.innerlv4MenuFilters[this.type] || [];
    }
    get fastFilters() {
        return this.innerfastFilters[this.type] || [];
    }

    // 筛选
    public setFilters(nextFilterData: any) {
        nextFilterData = _.isObject(nextFilterData) ? nextFilterData : {};
        let { lv4MenuFilters, fastFilters } = nextFilterData;
        lv4MenuFilters = _.isObject(lv4MenuFilters) ? lv4MenuFilters : {};
        fastFilters = _.isObject(fastFilters) ? fastFilters : {};
        this.innerlv4MenuFilters = lv4MenuFilters;
        this.innerfastFilters = fastFilters;
    }

    public selectedFastFilters: any[] = [];
    public setSelectedFastFilters(filters: any[]) {
        this.selectedFastFilters = filters;
    }

    public selectedLv4MenuFilters: any[] = [];
    public setSelectedLv4MenuFilters(filters: any[]) {
        this.selectedLv4MenuFilters = filters;
    }

    constructor() {
        makeAutoObservable(this, {
            innerlv4MenuFilters: observable.shallow,
            innerfastFilters: observable.shallow,
            selectedFastFilters: observable.shallow,
            selectedLv4MenuFilters: observable.shallow,
        });
    }
}
