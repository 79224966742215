import { useEffect, useRef, MutableRefObject } from 'react';

export default function useDraggable(
    target: MutableRefObject<HTMLDivElement | null>,
) {
    const position = useRef({ x: 0, y: 0 });

    useEffect(() => {
        if (!target.current) return;

        const targetElement = target.current;
        let originalWidth = targetElement.offsetWidth;
        let originalHeight = targetElement.offsetHeight;

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                if (width !== originalWidth || height !== originalHeight) {
                    originalWidth = width;
                    originalHeight = height;
                    updateTransform();
                }
            }
        });

        resizeObserver.observe(targetElement);

        const handleMousedown = (event: MouseEvent) => {
            // event.preventDefault();
            event.stopPropagation();

            const { clientX, clientY } = event;
            const gapX = clientX - position.current.x;
            const gapY = clientY - position.current.y;

            const handleMousemove = (evt: MouseEvent) => {
                let moveX = evt.clientX - gapX;
                let moveY = evt.clientY - gapY;

                // 防止元素超出浏览器窗口边界
                if (moveX >= 0) {
                    moveX = 0;
                } else if (moveX <= originalWidth - window.innerWidth) {
                    moveX = originalWidth - window.innerWidth;
                }
                if (moveY <= 0) {
                    moveY = 0;
                } else if (moveY >= window.innerHeight - originalHeight) {
                    moveY = window.innerHeight - originalHeight;
                }

                position.current = { x: moveX, y: moveY };
                updateTransform();
            };

            const handleMouseup = () => {
                window.removeEventListener('mousemove', handleMousemove);
                window.removeEventListener('mouseup', handleMouseup);
            };

            window.addEventListener('mousemove', handleMousemove);
            window.addEventListener('mouseup', handleMouseup);
        };

        targetElement.addEventListener('mousedown', handleMousedown);

        return () => {
            resizeObserver.disconnect();
            targetElement.removeEventListener('mousedown', handleMousedown);
        };
    }, [target.current]);

    const updateTransform = () => {
        if (target.current) {
            target.current.style.transform = `translate(${position.current.x}px, ${position.current.y}px)`;
        }
    };
}
