import React, { useEffect } from 'react';
import { Radio, message, Input, Select, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { AntButton } from '@/components';
import _ from 'lodash';
import { AntDrawer } from '@/components/antd/drawer';
import { TagItemInfo } from '../api';

interface ITagGroup {
    name: string;
    id: string;
}

export interface IFormDrawerProps extends FormComponentProps {
    visible: boolean;
    initialValues?: TagItemInfo;
    tagGroup: ITagGroup[];
    tagGroupId?: string;
    onSubmit: (val: any) => void;
    onClose: () => void;
    loading?: boolean;
}

const FormDrawer: React.FC<IFormDrawerProps> = ({
    visible,
    initialValues,
    tagGroup,
    tagGroupId,
    loading = false,
    onSubmit = () => {},
    onClose = () => {},
    form,
}) => {
    const {
        getFieldDecorator,
        setFieldsValue,
        getFieldValue,
        validateFields,
        resetFields,
    } = form;

    useEffect(() => {
        _.isEmpty(initialValues)
            ? resetFields()
            : setFieldsValue(initialValues!);
    }, [initialValues]);

    useEffect(() => {
        if (!visible) {
            resetFields();
        }
    }, [visible]);

    const handleSubmit = () => {
        validateFields((errs, values) => {
            if (errs) {
                return;
            }
            console.log('111');
            onSubmit(values);
        });
    };
    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <AntDrawer
                title={initialValues?.id ? '修改标签' : '新建标签'}
                placement={'right'}
                visible={visible}
                onClose={handleClose}
                bodyStyle={{
                    width: 800,
                    overflow: 'scroll',
                }}
                width="800px"
            >
                <div
                    className="standard-drawer-form-wrapper"
                    style={{ paddingTop: '12px' }}
                >
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                        {!!initialValues?.id && (
                            <Form.Item>{getFieldDecorator('id')}</Form.Item>
                        )}
                        <Form.Item label="标签名称">
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入标签名称',
                                    },
                                ],
                            })(
                                <Input
                                    maxLength={5}
                                    placeholder="请输入标签名称"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label="所属标签组">
                            {getFieldDecorator('group_id', {
                                initialValue: tagGroupId,
                            })(
                                <Select disabled>
                                    {tagGroup.map(tag => (
                                        <Select.Option value={tag.id}>
                                            {tag.name}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                        <Form.Item label="是否生效">
                            {getFieldDecorator('effect', {
                                initialValue: 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择是否生效',
                                    },
                                ],
                            })(
                                <Radio.Group>
                                    <Radio value={1}>是</Radio>
                                    <Radio value={0}>否</Radio>
                                </Radio.Group>,
                            )}
                        </Form.Item>
                        <Form.Item label="标签说明">
                            {getFieldDecorator('comment')(
                                <Input.TextArea
                                    allowClear
                                    maxLength={30}
                                    placeholder="请输入标签说明"
                                />,
                            )}
                        </Form.Item>
                    </Form>
                    <div className="fixed-form-btns">
                        <AntButton
                            size="large"
                            type="primary"
                            loading={loading}
                            onClick={handleSubmit}
                        >
                            保存
                        </AntButton>
                        <AntButton size="large" onClick={handleClose}>
                            取消
                        </AntButton>
                    </div>
                </div>
            </AntDrawer>
        </>
    );
};

export default Form.create<IFormDrawerProps>()(FormDrawer);
