import React from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    AntButton,
    BlockLoading2,
    defaultCellsSelectorTransformer,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { AntDrawer } from '@/components/antd/drawer';
import { defaultAuthDeco } from '@/containers/hoc';
import { AchievementManaStore } from '../store';
import DemoForm from './form';
import AchievementImport from './import';
import { tryRefresh } from '@/utils';

const FormDrawer: React.FC<RouteComponentProps & {
    defaultStore: AchievementManaStore;
    preRequiredReady: boolean;
}> = ({ defaultStore, preRequiredReady }) => {
    const {
        actionType,
        bigFormVisible,
        mutatingDataLoading,
        defaultMeta,
        pageAbstract,
    } = defaultStore;

    if (!preRequiredReady) {
        return <BlockLoading2 />;
    }

    const popups = (
        <>
            {actionType === 'create' && (
                <AntDrawer
                    title={`导入业绩目标（${pageAbstract}）`}
                    placement={'right'}
                    visible={bigFormVisible}
                    onClose={() => {
                        defaultStore.resetAction();
                    }}
                    bodyStyle={{
                        width: 1150,
                        overflow: 'scroll',
                    }}
                    width={1150 + 'px'}
                >
                    <div className="standard-drawer-form-wrapper">
                        <AchievementImport
                            entityCode={defaultMeta.tableId}
                            info={{
                                targetYear: defaultStore.targetYear,
                                targetType: defaultStore.targetType,
                                mCreateResult: defaultStore.mCreateResult,
                                typeCn: defaultStore.selectedCn,
                            }}
                            onSubmit={async data => {
                                return defaultStore.mCreateFromCnJSON(data);
                            }}
                        />
                    </div>
                </AntDrawer>
            )}
            {actionType === 'update' && (
                <AntDrawer
                    title={'修改业绩目标'}
                    placement={'right'}
                    visible={bigFormVisible}
                    onClose={() => {
                        defaultStore.resetAction();
                    }}
                    bodyStyle={{
                        width: 850,
                        overflow: 'scroll',
                    }}
                    width={850 + 'px'}
                >
                    <div className="standard-drawer-form-wrapper">
                        {mutatingDataLoading ? (
                            <BlockLoading2 />
                        ) : (
                            <DemoForm defaultStore={defaultStore} />
                        )}
                        <div className="fixed-form-btns">
                            <AntButton
                                size="large"
                                type="primary"
                                onClick={async () => {
                                    const isSuccess = await defaultStore.dispatchAction();
                                    if (isSuccess) {
                                        tryRefresh(['achievement']);
                                    }
                                }}
                            >
                                修改
                            </AntButton>
                            <AntButton
                                size="large"
                                onClick={() => defaultStore.resetAction()}
                            >
                                取消
                            </AntButton>
                        </div>
                    </div>
                </AntDrawer>
            )}
        </>
    );

    return popups;
};

const FinalFormDrawer = defaultAuthDeco(observer(FormDrawer));
export default FinalFormDrawer;
