import React, { useState, useEffect } from 'react';
import { Popover, Select, message } from 'antd';
import _ from 'lodash';
import ChartsComponents from '../charts-components';
import { defaultAxios } from '@/utils';
import qs from 'querystring';
export interface ICompositeBusinessChartsProps {
    title: string;
    showTips?: boolean;
    tipsContent?: any;
    showFilter?: boolean;
    filterLabel?: string;
    filterOptions?: {
        label: string;
        value: any;
    }[];
    showExport?: boolean;
    defaultFilterValue?: string;
    urlConfig: {
        exportUrl?: string;
        chartsUrl: string;
    };
    payload?: any;
    filterKey?: string;
}
const CompositeBusinessCharts: React.FC<ICompositeBusinessChartsProps> = props => {
    const {
        title,
        showTips = true,
        tipsContent,
        showFilter = true,
        showExport = true,
        filterLabel,
        filterOptions,
        urlConfig,
        defaultFilterValue,
        payload,
        filterKey,
    } = props;
    const [chartsFilterData, setChartsFilterData] = useState<any>();
    const [chartsOptions, setChartsOptions] = useState<any>();
    const [chartsLoading, setChartsLoading] = useState(false);
    const getChartsData = async () => {
        if (urlConfig.chartsUrl) {
            setChartsLoading(true);
            const finalPayloadData = {
                ...payload,
            };
            if (filterKey) {
                finalPayloadData[filterKey] = chartsFilterData;
            }
            const [d, e] = await defaultAxios.put(
                urlConfig.chartsUrl,
                finalPayloadData,
            );
            setChartsLoading(false);
            if (d === null || d.data === null) {
                message.error('获取大盘数据失败，请稍后重试～');
                return false;
            }
            const data = d.data;
            if (data) {
                // tslint:disable-next-line:no-eval
                setChartsOptions(eval(data));
                return true;
            } else {
                message.error('获取大盘数据失败，请稍后重试～');
                return false;
            }
        } else {
            return null;
        }
    };
    const exportChartsData = async () => {
        if (showExport && urlConfig.exportUrl) {
            const finalPayloadData = {
                ...payload,
            };
            if (filterKey) {
                finalPayloadData[filterKey] = chartsFilterData;
            }
            const exportUrl = `${urlConfig.exportUrl}?${qs.stringify({
                ...finalPayloadData,
                exportTitle: `${title}-${chartsFilterData}`,
            })}`;
            window.open(exportUrl, '_blank');
        } else {
            return;
        }
    };
    useEffect(() => {
        if (defaultFilterValue) {
            setChartsFilterData(defaultFilterValue);
        } else {
            // 搜索
            getChartsData();
        }
    }, []);
    useEffect(() => {
        // 这里直接搜索
        if (chartsFilterData) {
            getChartsData();
        }
    }, [chartsFilterData]);
    return (
        <div className="composite-item-content composite-charts-content">
            <div className="composite-item-header">
                <div className="composite-item-title">
                    <span className="comp-title">{title}</span>
                    {showTips ? (
                        <Popover
                            placement="bottom"
                            content={<div>{tipsContent}</div>}
                        >
                            <span className="comp-tips">口径说明</span>
                        </Popover>
                    ) : null}
                </div>
                <div className="composite-filter-content">
                    <div className="composite-filter-item">
                        {showFilter ? (
                            <>
                                <label htmlFor="">{filterLabel}</label>
                                <Select
                                    value={chartsFilterData}
                                    onChange={(value: any) => {
                                        setChartsFilterData(value);
                                    }}
                                    style={{
                                        minWidth: '160px',
                                    }}
                                >
                                    {_.map(filterOptions, option => {
                                        return (
                                            <Select.Option value={option.value}>
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </>
                        ) : null}
                        {showExport ? (
                            <span
                                className="comp-export"
                                onClick={exportChartsData}
                            >
                                导出
                            </span>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="composite-item-charts-cont">
                <ChartsComponents
                    loading={chartsLoading}
                    chartsOptions={chartsOptions}
                    containerClassName={'business-composite-charts'}
                />
            </div>
        </div>
    );
};
export default CompositeBusinessCharts;
