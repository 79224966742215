import React, { useState } from 'react';

import { ContractListStore } from '../store';
import { Modal, Alert } from 'antd';
import { observer } from 'mobx-react-lite';
import { tryRefresh } from '@/utils';

export const ActionDelete: React.FC<{
    defaultStore: ContractListStore;
}> = observer(({ defaultStore }) => {
    const [loading, setLoading] = useState(false);
    const { mutatingData } = defaultStore;
    if (mutatingData === null) {
        return null;
    }
    const visible = defaultStore.actionType === 'delete';
    return (
        <Modal
            visible={visible}
            title={'删除合同'}
            onCancel={() => {
                defaultStore.resetAction();
            }}
            confirmLoading={loading}
            onOk={async () => {
                setLoading(true);
                const ok = await defaultStore.delete();
                setLoading(false);
                if (ok) {
                    defaultStore.resetAction();
                    tryRefresh(['contract']);
                }
            }}
        >
            <p>是否删除合同【{mutatingData.contract_name}】？</p>
            <Alert type="error" message="删除成功之后，该操作无法恢复。" />
        </Modal>
    );
});
