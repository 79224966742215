import React, { useEffect, useState, useContext, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
    AntButton,
    loadingWrapperOver,
} from '@/components';
import { Icon, Tooltip, message } from 'antd';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet, useBeforeFirstDataLoaded } from '@/utils';
import { observer } from 'mobx-react-lite';
import DemoTable from './components/table';
import { ActionDelete } from './components/delete';
import { ActionMDelete } from './components/m-delete';
import { runInAction } from 'mobx';
import {
    defaultAuthDeco,
    dFilterHOC,
    useDefaultDFilterSnippets,
} from '@/containers/hoc';
// import './index.scss';
import { MOpResultDisplayContainer } from '@/components';
import FormDrawer from './components/form-drawer';
import classname from 'classname';

const DemoList: React.FC<RouteComponentProps & {
    type: string;
    dfilters?: any;
}> = ({ type = 'all', dfilters }) => {
    const store = useContext(StoreContext);
    const [defaultStore] = useState(
        () => new store.ConfAdvertisingRateListStore(),
    );
    const userStore = store.getAuthStore();

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        pageTitle,
        defaultPerm,
        defaultFilter,
        defaultMSelect,
        defaultFastFilters,
        condListbyFilterData,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const listFetch = useCallback(() => defaultStore.fetch(), []);
    const [error, loading, reload] = useNet(listFetch, {
        autoLoad: false,
        refreshKeys: ['talent_bank_account_gift_conf'],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        condListbyFilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            defaultMeta.setTableId('talent_bank_account_gift_conf');
            defaultMeta.setOpUsername(userStore.userInfo.userId + '-' + type);
            defaultFastFilters.setFilters(dfilters);
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {});
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useEffect(() => {
        runInAction(() => {
            defaultFastFilters.setType(type);
            defaultStore.setListType(type);
        });
    }, [type]);

    const [isBeforeFirstLoaded] = useBeforeFirstDataLoaded(defaultStore);

    const { title, fastFilters } = useDefaultDFilterSnippets(defaultStore);

    // if (!preRequiredReady) {
    //     return <BlockLoading2 />;
    // }
    const finalLoading = isBeforeFirstLoaded || !preRequiredReady;

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = (
        <>
            <MOpResultDisplayContainer defaultStore={defaultStore} />
            <ActionDelete defaultStore={defaultStore} />
            <ActionMDelete defaultStore={defaultStore} />
            <FormDrawer
                defaultStore={defaultStore}
                preRequiredReady={preRequiredReady}
            />
        </>
    );

    const globalOps = (
        <>
            {defaultPerm.getPermByTypeAndAction(type)('create').visible && (
                <AntButton
                    onClick={() => {
                        runInAction(() => {
                            defaultStore.setAction('create');
                            defaultStore.startNewData();
                        });
                    }}
                    type="primary"
                    size="large"
                >
                    新建配置
                </AntButton>
            )}
        </>
    );
    const multipleOps = (
        <div
            className={classname({
                'mselected-ops': true,
                'multiple-mode': defaultMSelect.isMSelectionMode,
                'normal-mode': !defaultMSelect.isMSelectionMode,
            })}
        >
            <Tooltip title="取消多选">
                <span
                    onClick={() => {
                        defaultMSelect.setMSelectedData([]);
                    }}
                    style={{
                        padding: 5,
                        cursor: 'pointer',
                    }}
                >
                    <Icon type="close" />
                </span>
            </Tooltip>
            <div>
                <span>已选{defaultMSelect.mSeletedData.length}条：</span>
            </div>
            {defaultPerm.getPermByTypeAndAction(type)('delete').visible && (
                <AntButton
                    onClick={() => {
                        defaultStore.setAction('m-delete');
                    }}
                    size="large"
                >
                    删除
                </AntButton>
            )}
        </div>
    );

    const dataTable = (
        <DemoTable defaultStore={defaultStore} loading={loading} />
    );

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters: filtersDisplay,
        multipleOps,
        dataTable,
        message: null, // ack ? <Alert closable message={ack} /> : null,
        filtersDisplay: fastFilters,
    };

    return loadingWrapperOver(finalLoading)(
        <LayoutStandardCrud className="page-customer-list" {...layoutComs} />,
    );
};

const FinalDemoList = dFilterHOC(
    observer(DemoList),
    'talent_bank_account_gift_conf',
);
export default FinalDemoList;
