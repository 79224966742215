import _ from 'lodash';
import { genDaysAgoFilterFactory } from './helpers';

export const demoDFConfs: { [entityCode: string]: any } = {
    demo: {
        lv4MenuFilters: {
            all: [
                {
                    key: 'customer-df-all',
                    label: '全部客户',
                    value: [],
                },
                {
                    key: 'customer-df-huabei-sea',
                    label: '招聘华北公海池',
                    value: [
                        {
                            condition: {
                                fieldId: 'high_sea_id',
                                filterValue: '26',
                                operateType: 'in',
                            },
                        },
                    ],
                },
                {
                    key: 'customer-df-shanghai-sea',
                    label: '招聘上海公海池',
                    value: [
                        {
                            condition: {
                                fieldId: 'high_sea_id',
                                filterValue: '29',
                                operateType: 'in',
                            },
                        },
                    ],
                },
                genDaysAgoFilterFactory({
                    label: '30天前创建',
                    key: 'customer-df-fromdaysago-30',
                    fieldId: 'create_time',
                    days: 30,
                }),
            ],
        },
        fastFilters: {
            all: [
                {
                    label: '招聘华北公海池',
                    fastFilterKey: '1612840953029',
                    value: [
                        {
                            condition: {
                                fieldId: 'high_sea_id',
                                filterValue: '26',
                                operateType: 'in',
                            },
                        },
                    ],
                },
                {
                    label: '招聘上海公海池',
                    fastFilterKey: '1612840963276',
                    value: [
                        {
                            condition: {
                                fieldId: 'high_sea_id',
                                filterValue: '29',
                                operateType: 'in',
                            },
                        },
                    ],
                },
            ],
        },
    },
};
