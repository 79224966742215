import React, { useState, useEffect, CSSProperties, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { defaultAxios, useNet, sendPCDot, isNetSuccess } from '@/utils';
import _ from 'lodash';
import {
    Icon,
    Statistic,
    Spin,
    Card,
    Popover,
    message,
    Button,
    Modal,
    Alert,
} from 'antd';
import { Pie } from '@ant-design/charts';
import moment from 'moment';
import './index.scss';
import CUserModal from './cuser-modal';
import { StoreContext } from '@/stores';
import FinanceTable from './finance-table';
import { AntTable } from '@/components/antd';
import { BlockLoading2 } from '../block-loading';
import { tryNewCall } from '@/containers/hoc/call-center';
import { CtxProvider } from '@/utils/context';
import { CUser2Contacts } from './cuser-modal/cuser2contacts';
import { ICUserData } from './store';
import { voyagerTrack } from '@shared/voyager';
import Ccpopover from '@/components/common/cc-popover';

export interface IMaiInnerTrendInfoData {
    maimai_effective_job_cnt: number; // 有效职位数
    maimai_swup_effective_job_cnt: number; // 30k以上有效职位数
    maimai_onemonth_recent_active_hr_cnt: number; // 活跃的hr数
    maimai_onemonth_recent_active_manager_cnt: number; // 活跃的高管
    maimai_onemonth_recent_hr_friend_cnt: number; // hr加好友数
    maimai_onemonth_recent_hr_chat_cnt: number; // hr主动开聊
    maimai_onemonth_recent_hr_contact_cnt: number; // hr急速联系
    maimai_person_member_recharge_money: number; // 会员累计充值
    maimai_enterprise_recharge_money: number; // 企业累计充值
    maimai_member_receipt_money: number; // 会员开票金额
    maimai_update_import_time: string | number; // 更新日期
    maimai_threeday_high_salary_cnt: string | number; // 近三天发布（高薪）
    maimai_threeday_job_cnt: string | number; // 近三天发布（职位数）
    maimai_high_salary_cnt: string | number; // 总高薪职位数
    maimai_effective_job_cnt2: string | number; // 总有效职位数
    maimai_threeday_register_hr_cnt: string | number; // 近三天新注册（hr、猎头）
    maimai_register_hr_cnt: string | number; // 总注册(hr、猎头)
    maimai_register_user_cnt: string | number; // 总注册(用户)
    maimai_threeday_active_hr_cnt: string | number; // 近三天活跃（hr、猎头）
    maimai_threeday_search_cnt: string | number; // 近三天搜索职位
    maimai_threeday_friend_cnt: string | number; // 近三天加好友
    maimai_threeday_contact_cnt: string | number; // 近三天极速联系
    maimai_threeday_reach_cnt: string | number; // 近三天立即沟通数
    maimai_recruit_person_member_money: string | number; // 累计充值金额
    maimai_onemonth_recruit_person_member_cnt: string | number; // 30天会员数
    maimai_recruit_person_member_cnt: string | number; // 有效会员数
    maimai_onemonth_recruit_person_member_money: string | number; // 30天充值金额
}

export interface IMaiCompeteTrendInfoData {
    liepin_job_cnt: number; // lp-职位总数
    liepin_high_salary_cnt: number; // lp-高薪职位总数
    liepin_technology_cnt: number; // lp-技术职位总数
    liepin_product_cnt: number; // lp-产品职位总数
    liepin_operate_cnt: number;
    liepin_industry: string; // lp-行业
    liepin_company_link: string; // lp-职位链接
    liepin_create_import_time: string | number; // lp-首次导入时间
    liepin_update_import_time: string | number; // lp-首次更新时间
    liepin_threeday_ad_cnt: string | number; // lp-3天发布广告数
    liepin_threeday_high_salary_cnt: string | number; // lp-3天发布的高薪职位数
    liepin_threeday_job_cnt: string | number; // lp-发布职位数
    lagou_job_cnt: number; // lg-职位总数
    lagou_high_salary_cnt: number; // lg-高薪职位总数
    lagou_technology_cnt: number; // lg-技术职位总数
    lagou_product_cnt: number; // lg-产品职位总数
    lagou_operate_cnt: number; // lg-运营职位总数
    lagou_industry: string; // lg-行业
    lagou_company_link: string; // lg-职位链接
    lagou_create_import_time: string | number; // lg-首次导入时间
    lagou_update_import_time: string | number; // lg-最近更新时间
    lagou_threeday_ad_cnt: string | number; // lg-近3天发布广告数
    lagou_threeday_high_salary_cnt: string | number; // lg-近3天发布高薪职位数
    lagou_threeday_job_cnt: string | number; // lg-发布职位数
    zhuopin_job_cnt: number; // zp-职位总数
    zhuopin_high_salary_cnt: number; // zp-高薪职位总数
    zhuopin_technology_cnt: number; // zp-技术职位总数
    zhuopin_product_cnt: number; // zp-产品职位总数
    zhuopin_operate_cnt: number; // zp-运营职位总数
    zhuopin_industry: string; // zp-行业
    zhuopin_company_link: string; // zp-职位链接
    zhuopin_create_import_time: string | number; // zp-首次导入时间
    zhuopin_update_import_time: string | number; // zp-最近更新时间
    zhuopin_threeday_ad_cnt: string | number; // zp-近3天发布广告数
    zhuopin_threeday_high_salary_cnt: string | number; // zp-近3天发布高薪职位数
    zhuopin_threeday_job_cnt: string | number; // zp-发布职位数
    boss_job_cnt: number; // bs-职位总数
    boss_high_salary_cnt: number; // bs-高薪职位总数
    boss_technology_cnt: number; // bs-技术职位总数
    boss_product_cnt: number; // bs-产品职位总数
    boss_operate_cnt: number; // bs-运营职位总数
    boss_industry: string; // bs-行业
    boss_company_link: string; // bs-职位链接
    boss_create_import_time: number | string; // bs-首次导入时间
    boss_update_import_time: number | string; // bs-最近更新时间
    boss_threeday_ad_cnt: number | string; // bs-近3天发布广告数
    boss_threeday_high_salary_cnt: number | string; // bs-近3天发布的高薪职位数
    boss_threeday_job_cnt: string | number; // bs-发布职位数
    wyjob_job_cnt: number; // 51-职位总数
    wyjob_high_salary_cnt: number; // 51-高薪职位总数
    wyjob_technology_cnt: number; // 51-技术职位总数
    wyjob_product_cnt: number; // 51-产品职位总数
    wyjob_operate_cnt: number; // 51-运营职位总数
    wyjob_industry: string; // 51-行业
    wyjob_company_link: string; // 51-职位链接
    wyjob_create_import_time: number | string; // 51-首次导入时间
    wyjob_update_import_time: number | string; // 51-最近更新时间
    wyjob_threeday_ad_cnt: number | string; // 51-3天发布广告数
    wyjob_threeday_high_salary_cnt: number | string; // 51-3天发布高薪职位
    wyjob_threeday_job_cnt: string | number; // 51-发布职位数
    zhilian_job_cnt: number; // zl-职位总数
    zhilian_high_salary_cnt: number; // zl-高薪职位总数
    zhilian_technology_cnt: number; // zl-技术职位总数
    zhilian_product_cnt: number; // zl-产品职位总数
    zhilian_operate_cnt: number; // zl-运营职位总数
    zhilian_industry: string; // zl-行业
    zhilian_company_link: string; // zl-职位链接
    zhilian_create_import_time: number | string; // zl-首次导入时间
    zhilian_update_import_time: number | string; // zl-最近更新时间
    zhilian_threeday_ad_cnt: number | string; // zl-3天发布广告数
    zhilian_threeday_high_salary_cnt: number | string; // zl-3天发布高薪职位数
    zhilian_threeday_job_cnt: string | number; // zl-发布职位数
}
export interface IMaiTrendData {
    innerData: IMaiInnerTrendInfoData;
    competeData: IMaiCompeteTrendInfoData;
}
export interface IMaiTrendProps {
    cid?: number | string; // 客户id
    maiTrendData: IMaiInnerTrendInfoData & IMaiCompeteTrendInfoData;
    refreshData: () => void; // 刷新数据
    isPrivateCus: boolean; // 是否是私池客户
    defaultCustomerName: string; // 客户的名称
    customerId: string | number; // 客户站内id
}
export type TGetMaiTrendReturnType = NetResponse<{
    data: IMaiTrendData;
    msg: string;
    code: number;
}>;
const MaiTrend: React.FC<IMaiTrendProps> = props => {
    const {
        cid,
        maiTrendData,
        refreshData,
        isPrivateCus,
        defaultCustomerName,
        customerId,
    } = props;
    const [refreshMaiTrendLoading, setRefreshMaiTrendLoading] = useState(false);
    const [cUserModalVis, setCUserModalVis] = useState(false);
    const [canFetchCUser, setCanFetchCUser] = useState(false);
    const [cUserMode, setCUserMode] = useState(false);
    const [cUserQueryScene, setCUserQueryScene] = useState<
        | 'REGISTER_HR_3D'
        | 'ACTIVE_HR_3D'
        | 'HR_COUNT'
        | 'REGISTRATION_30D'
        | 'ALL_MEMBERS'
        | ''
    >('');
    const [pieData, setPieData] = useState<any>({});
    const HRPieData = [
        {
            type: '搜索人才',
            value: pieData?.searchTalentCnt || 0,
        },
        {
            type: '主动加好友',
            value: pieData?.initiateFriendingCnt || 0,
        },
        {
            type: '主动开聊数',
            value: pieData?.initiateCharCnt || 0,
        },
        {
            type: '极速联系数',
            value: pieData?.fastContactCnt || 0,
        },
    ];
    // 转联系人相关
    const [cuser2contactsTrigger, setCuser2contactsTrigger] = useState(0);
    const [
        cuser2contactsCurrentCuser,
        setCuser2contactsCurrentCuser,
    ] = useState<any>(null);
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const cUserStore = store.getDefaultCUserStore();
    const {
        remainCount,
        getRemainDecodeNumber,
        decodeCUser,
        checkCUserDecoded,
        cUserBuyDataLoading,
        cUserBuyList,
        getPersonBuyList,
        getCUserPositionList,
        maiTrendContactTrigger,
        setMaiTrendContactTrigger,
        maiTrendContactCurrentData,
        setMaiTrendContactCurrentData,
    } = cUserStore;
    const decodePhoneAndT2Contract = async (id: number, oData: any) => {
        const [d, e] = await checkCUserDecoded(id);
        if (d) {
            // 已解密
            const [data, error] = await decodeCUser(id, true);
            if (data) {
                // 拉起转联系人
                const transData = {
                    uid: id,
                    username: oData.hrName,
                    position: oData.hrPosition,
                    realname: oData.hrName,
                    company: defaultCustomerName,
                    call: true,
                    phone: data,
                    loginTime: '',
                    profileUrl: '',
                };
                cUserStore.setMaiTrendContactTrigger(
                    cUserStore.maiTrendContactTrigger + 1,
                );
                cUserStore.setMaiTrendContactCurrentData({
                    ...transData,
                    decodedPhone: data,
                });
            }
        } else {
            if (cUserStore.remainCount <= 0) {
                message.warning(
                    '为了防止C端用户信息泄漏，您每天只有200次解密手机号的机会，目前机会已用完！',
                );
                return;
            }
            // 未解密
            Modal.confirm({
                content: (
                    <div>
                        <p>本次转联系人，将消耗1次机会</p>
                        <p>
                            您今天剩余可用机会为
                            <span style={{ color: 'red' }}>
                                {cUserStore.remainCount}
                            </span>
                            次，请确认是否转联系人？
                        </p>
                    </div>
                ),
                onOk: async () => {
                    const [data, error] = await decodeCUser(id, true);
                    if (data) {
                        // 拉起外呼
                        getRemainDecodeNumber();
                        // 拉起转联系人
                        const transData = {
                            uid: id,
                            username: oData.hrName,
                            position: oData.hrPosition,
                            realname: oData.hrName,
                            company: defaultCustomerName,
                            call: true,
                            phone: data,
                            loginTime: '',
                            profileUrl: '',
                        };
                        cUserStore.setMaiTrendContactTrigger(
                            cUserStore.maiTrendContactTrigger + 1,
                        );
                        cUserStore.setMaiTrendContactCurrentData({
                            ...transData,
                            decodedPhone: data,
                        });
                    }
                },
            });
        }
    };
    const decodePhoneHandle = async (id: number) => {
        const [d, e] = await checkCUserDecoded(id);
        if (d) {
            // 已解密
            const [data, error] = await decodeCUser(id, true);
            if (data) {
                // 拉起外呼
                tryNewCall(data, true, {
                    customerName: defaultCustomerName,
                    customerId: cid,
                    showFollowLog: true,
                });
            }
        } else {
            if (cUserStore.remainCount <= 0) {
                message.warning(
                    '为了防止C端用户信息泄漏，您每天只有200次外呼手机号的机会，目前机会已用完！',
                );
                return;
            }
            // 未解密
            Modal.confirm({
                content: (
                    <div>
                        <p>本次外呼，将消耗1次机会</p>
                        <p>
                            您今天剩余可用机会为
                            <span style={{ color: 'red' }}>
                                {cUserStore.remainCount}
                            </span>
                            次，请确认是否外呼？
                        </p>
                    </div>
                ),
                onOk: async () => {
                    const [data, error] = await decodeCUser(id, true);
                    if (data) {
                        // 拉起外呼
                        getRemainDecodeNumber();
                        tryNewCall(data, true, {
                            customerName: defaultCustomerName,
                            customerId: cid,
                            showFollowLog: true,
                        });
                    }
                },
            });
        }
    };
    const refreshMaiTrendData = async () => {
        fetchThreeDayInfo();
        const url = `/crm/customer/refreshmaimai?customerId=${cid}`;
        setRefreshMaiTrendLoading(true);
        const [d, e] = await defaultAxios.get(url);
        setRefreshMaiTrendLoading(false);
        refreshData();
    };
    // 获取（获取c端用户）的权限
    const fetchCUserAuth = async () => {
        const url = '/bff/api/rest/perm?mod=sales-follow-up:customer';
        const [d, e] = await defaultAxios.get(url);
        if (d && d.data && d.data?.length) {
            const allCUserAuth = _.find(d.data, item => {
                return item.key === 'sales-follow-up:customer:all:get-c-user';
            });
            const mineCUserAuth = _.find(d.data, item => {
                return item.key === 'sales-follow-up:customer:mine:get-c-user';
            });
            if (isPrivateCus) {
                setCanFetchCUser(!!mineCUserAuth?.visible);
            } else {
                setCanFetchCUser(!!allCUserAuth?.visible);
            }
        }
    };
    const fetchThreeDayInfo = async () => {
        const url = `/crm/customer/mai/maimai/queryThreedayInfo/${cid}`;
        const [d, e] = await defaultAxios.get(url);
        if (d && d.data) {
            setPieData(d.data);
        }
    };
    useEffect(() => {
        fetchCUserAuth();
        getRemainDecodeNumber();
        fetchThreeDayInfo();
        return () => {
            cUserStore.setMaiTrendContactCurrentData(null);
            cUserStore.setMaiTrendContactTrigger(0);
        };
    }, []);
    const [popoverVisable, setPopoverVisable] = useState(false);
    const onVisibleChange = (visable: any) => {
        console.log('visablevisable', visable);
        setPopoverVisable(visable);
    };
    const innerJobDetailColumns = [
        {
            title: '职位',
            width: '120px',
            dataIndex: 'jobPosition',
        },
        {
            title: '薪酬范围',
            key: 'salary',
            width: '160px',
            render(v: any, item: any) {
                item = item || {};
                const { jobMaxSalary, jobMinSalary } = item;
                if (!jobMaxSalary && !jobMinSalary) {
                    return '--';
                }
                return `${`${Math.round(Number(jobMinSalary) / 1000)}k` ||
                    '--'} ~ ${`${Math.round(Number(jobMaxSalary) / 1000)}k` ||
                    '--'}`;
            },
        },
        {
            title: '发布人',
            width: '80px',
            dataIndex: 'hrName',
        },
        {
            title: '发布人职位',
            width: '120px',
            dataIndex: 'hrPosition',
        },
        {
            title: '发布人电话',
            width: '120px',
            // dataIndex: 'hrEmobile',
            render: (item: any) => {
                console.log('item.hrUid', item.hrUid);
                return (
                    <span>
                        ************{' '}
                        {isPrivateCus ? (
                            <Popover
                                getPopupContainer={() => {
                                    return document.body as HTMLElement;
                                }}
                                style={{ zIndex: 666666 }}
                                onVisibleChange={onVisibleChange}
                                placement="right"
                                content={
                                    <Ccpopover
                                        uid={
                                            popoverVisable
                                                ? item.hrUid
                                                : undefined
                                        }
                                        whetherShow={false}
                                    />
                                }
                            >
                                <Icon
                                    onClick={() => {
                                        decodePhoneHandle(item.hrUid);
                                        // sendPCDot(
                                        //     '点击【电话】按钮',
                                        //     `userId=${userId},userName=${userName},customerName=${defaultCustomerName}`,
                                        //     false,
                                        //     'click',
                                        //     window.location
                                        //         .pathname,
                                        //     moment().format(
                                        //         'YYYY-MM-DD HH:mm:ss',
                                        //     ),
                                        // );
                                    }}
                                    style={{ cursor: 'pointer' }}
                                    type="phone"
                                    theme="twoTone"
                                    twoToneColor="#0052ff"
                                />
                            </Popover>
                        ) : null}
                    </span>
                );
            },
        },
        {
            title: '发布人profile',
            width: '60px',
            dataIndex: 'hrProfileUrl',
            render(v: any) {
                if (!v) {
                    return null;
                }
                // const url = `https://maimai.cn/contact/detail/${v}?from=crm`;
                return (
                    <a target="_blank" href={v}>
                        查看
                    </a>
                );
            },
        },
        {
            title: '操作',
            width: '60px',
            render: (item: any) => {
                return (
                    <span
                        onClick={() => {
                            decodePhoneAndT2Contract(item.hrUid, item);
                        }}
                        style={{ color: '#0052ff', cursor: 'pointer' }}
                    >
                        转联系人
                    </span>
                );
            },
        },
    ];
    const [jobDetailColumns] = useState(() => {
        return [
            {
                title: '职位title',
                dataIndex: 'title',
            },
            {
                title: '薪资',
                dataIndex: 'maxSalary',
                render(v: any, item: any) {
                    item = item || {};
                    const { maxSalary, minSalary } = item;
                    if (!maxSalary && !minSalary) {
                        return '--';
                    }
                    return `${minSalary || '--'} ~ ${maxSalary || '--'}`;
                },
            },
            {
                title: '分类',
                dataIndex: 'classification',
            },
            {
                title: '职位链接',
                dataIndex: 'jobLink',
                render(v: any) {
                    if (!v) {
                        return null;
                    }
                    return (
                        <a target="_blank" href={v}>
                            查看
                        </a>
                    );
                },
            },
        ];
    });
    const [jobDetailData, setJobDetailData] = useState([]);
    const [isHighSalary, setIsHighSalary] = useState(false);
    const [jobDetailDataType, setJobDetailDataType] = useState('');
    const [jobDetailDataVisible, setJobDetailDataVisible] = useState(false);
    const [jobDetailDataLoading, setJobDetailDataLoading] = useState(false);

    const [innerJobDetailLoading, setInnerJobDetailLoading] = useState(false);
    const [innerJobDetailVis, setInnerJobDetailVis] = useState(false);
    const [innerJobDetailType, setInnerJobDetailType] = useState('');
    const [innerJobData, setInnerJobData] = useState([]);

    const loadInnerJobDetail = async (
        type: string,
        isHighSalary?: boolean,
        isAllJobs?: boolean,
    ) => {
        const api = '/crm/customer/mai/maimai/jobdetails';
        const params = {
            cid: customerId,
            queryScene: isAllJobs
                ? 'ALL_JOBS'
                : isHighSalary
                ? 'HIGH_JOBS_3D'
                : 'JOBS_3D',
        };
        voyagerTrack('crm_mai_trend_inner_job', {
            ...params,
        });
        await getRemainDecodeNumber();
        setInnerJobDetailLoading(true);
        setInnerJobDetailType(type);
        setInnerJobDetailVis(true);
        const [d, e] = await defaultAxios.put(api, params);
        setInnerJobDetailLoading(false);
        if (isNetSuccess(d, e) && _.isArray(d?.data)) {
            setInnerJobData(d?.data);
        }
    };

    const loadJobdetail = async (type: string, isHighSalary?: boolean) => {
        const api = '/crm/customer/mai/compete/jobdetails';
        const params = {
            customerName: defaultCustomerName,
            competeCompany: type,
            highSalary: isHighSalary ? true : false,
        };
        voyagerTrack('crm_mai_trend_compete_job', {
            ...params,
        });
        // if (type === 'LP') {
        //     api = '/crm/customer/mai/liepin/jobdetails';
        // }
        // if (type === 'LG') {
        //     api = '/crm/customer/mai/lagou/jobdetails';
        // }
        setJobDetailDataLoading(true);
        setJobDetailDataType(type);
        setJobDetailDataVisible(true);
        setIsHighSalary(isHighSalary ? true : false);

        const [d, e] = await defaultAxios.put(api, params);
        setJobDetailDataLoading(false);
        if (isNetSuccess(d, e) && _.isArray(d?.data)) {
            setJobDetailData(d?.data);
        }
    };

    // const [maiTrendData, setMaiTrendData] = useState<IMaiTrendData>();
    // const fetchMaiTrendData: () => Promise<
    //     TGetMaiTrendReturnType
    // > = async () => {
    //     const thisCid = cid;
    //     if (_.isNil(cid)) {
    //         return [null, null];
    //     }
    //     const [d, e] = await defaultAxios.get(
    //         `/bff/api/rest/customer/mai-trend?id=${cid}`,
    //     );
    //     if (d === null || d.data === null) {
    //         return [null, e];
    //     }
    //     const data = d.data;
    //     const error = e as Error | null;
    //     if (d && d.data) {
    //         if (thisCid === cid) {
    //             setMaiTrendData(d.data);
    //         }
    //     }
    //     return [data, error];
    // };
    // const [error, loading, reload] = useNet(fetchMaiTrendData);
    // useEffect(() => {
    //     reload();
    // }, [cid]);
    const statisticValueStyle: CSSProperties = {
        fontSize: '16px',
        // color: '#0052ff',
        marginBottom: '5px',
        fontWeight: 500,
    };
    const statisticCanClickValueStyle: CSSProperties = {
        fontSize: '16px',
        color: '#0052ff',
        marginBottom: '5px',
        fontWeight: 500,
        cursor: 'pointer',
    };
    return (
        // <Spin spinning={loading}>
        <>
            <CtxProvider
                ctx={{
                    position: 'drawer',
                    positionDetail: 'customer-drawer-maitrend-cuser',
                    data: { cid: (maiTrendData as any)?.id },
                }}
            >
                <CUser2Contacts
                    trigger={maiTrendContactTrigger}
                    customerInfo={maiTrendData}
                    cuser={maiTrendContactCurrentData}
                />
            </CtxProvider>
            <Modal
                title={`${
                    isHighSalary ? '近3天发布职位（高薪）' : '近三天发布职位'
                }-${defaultCustomerName}-${jobDetailDataType}`}
                visible={jobDetailDataVisible}
                okText="关闭"
                cancelButtonProps={{ style: { visibility: 'hidden' } }}
                onCancel={() => setJobDetailDataVisible(false)}
                onOk={() => setJobDetailDataVisible(false)}
            >
                <>
                    {/* {JSON.stringify(jobDetailData)} */}
                    {jobDetailDataLoading ? (
                        <BlockLoading2 />
                    ) : (
                        <AntTable
                            pagination={false}
                            dataSource={jobDetailData}
                            columns={jobDetailColumns}
                        ></AntTable>
                    )}
                </>
            </Modal>
            <Modal
                title={`${innerJobDetailType}-${defaultCustomerName}`}
                visible={innerJobDetailVis}
                okText={'关闭'}
                width="800px"
                cancelButtonProps={{ style: { visibility: 'hidden' } }}
                onCancel={() => setInnerJobDetailVis(false)}
                onOk={() => setInnerJobDetailVis(false)}
            >
                <>
                    {/* {JSON.stringify(jobDetailData)} */}
                    {innerJobDetailLoading ? (
                        <BlockLoading2 />
                    ) : (
                        <>
                            <Alert
                                showIcon
                                style={{
                                    fontSize: '12px',
                                    marginBottom: '8px',
                                }}
                                type="warning"
                                message={
                                    <div>
                                        <p style={{ marginBottom: '0px' }}>
                                            手机号需加密，无法明文展示。但是您每天有200次外呼机会，目前您的剩余可用机会为{' '}
                                            <span style={{ color: 'red' }}>
                                                {remainCount}
                                            </span>{' '}
                                            次。（注意：同一用户一天之内外呼多次，只消耗1次机会）
                                        </p>
                                    </div>
                                }
                            />
                            <AntTable
                                scroll={{ x: 700, y: 350 }}
                                pagination={false}
                                dataSource={innerJobData}
                                columns={innerJobDetailColumns}
                            ></AntTable>
                        </>
                    )}
                </>
            </Modal>
            <CUserModal
                modalVis={cUserModalVis}
                setModalVis={setCUserModalVis}
                defaultCustomerName={defaultCustomerName}
                customerOriginData={maiTrendData}
                userName={authStore.userInfo.userName || ''}
                userId={authStore.userInfo.sysUser.id}
                showMode={cUserMode}
                isPrivateCus={isPrivateCus}
                cid={customerId}
                crm_cid={cid}
                queryScene={cUserQueryScene}
            />
            <div className="mai-trend-content">
                <div className="mai-inner-content">
                    <p className="mai-inner-title">
                        <span>
                            脉脉站内
                            <Popover
                                placement="bottomRight"
                                content={
                                    <div>脉脉站内公司汇总信息，每晚更新</div>
                                }
                            >
                                <Icon
                                    style={{
                                        marginLeft: '3px',
                                        color: 'rgb(175, 177, 188)',
                                        fontSize: '14px',
                                    }}
                                    type="exclamation-circle"
                                />
                            </Popover>
                            {maiTrendData?.maimai_update_import_time ? (
                                <Popover
                                    content={
                                        <span className="trend-update-time">
                                            更新日期：
                                            {moment(
                                                maiTrendData?.maimai_update_import_time,
                                            ).format('YYYY-MM-DD HH:mm:ss')}
                                        </span>
                                    }
                                >
                                    <Button
                                        style={{ marginLeft: '10px' }}
                                        size={'small'}
                                        onClick={refreshMaiTrendData}
                                        // loading={refreshMaiTrendLoading}
                                        disabled={refreshMaiTrendLoading}
                                    >
                                        刷新
                                        <Icon
                                            type="sync"
                                            spin={refreshMaiTrendLoading}
                                        />
                                    </Button>
                                </Popover>
                            ) : (
                                <Button
                                    style={{ marginLeft: '10px' }}
                                    size={'small'}
                                    onClick={refreshMaiTrendData}
                                    // loading={refreshMaiTrendLoading}
                                    disabled={refreshMaiTrendLoading}
                                >
                                    刷新
                                    <Icon
                                        type="sync"
                                        spin={refreshMaiTrendLoading}
                                    />
                                </Button>
                            )}
                        </span>
                        {canFetchCUser && (
                            <Button
                                type={'primary'}
                                // style={{ marginBottom: '10px' }}
                                size={'small'}
                                onClick={() => {
                                    sendPCDot(
                                        '点击【获取C端会员信息】按钮',
                                        `userId=${authStore.userInfo.sysUser.id},userName=${authStore.userInfo.userName},customerName=${defaultCustomerName}`,
                                        false,
                                        'click',
                                        window.location.pathname,
                                        moment().format('YYYY-MM-DD HH:mm:ss'),
                                    );
                                    setCUserQueryScene('');
                                    setCUserMode(false);
                                    setCUserModalVis(true);
                                }}
                            >
                                获取c端用户信息
                            </Button>
                        )}
                        {/* <div className="mai-inner-refresh">
                            {maiTrendData?.maimai_update_import_time && (
                                <span className="trend-update-time">
                                    更新日期：
                                    {moment(
                                        maiTrendData?.maimai_update_import_time,
                                    ).format('YYYY-MM-DD HH:mm:ss')}
                                </span>
                            )}
                            <Button
                                style={{ marginBottom: '10px' }}
                                size={'small'}
                                onClick={refreshMaiTrendData}
                                // loading={refreshMaiTrendLoading}
                                disabled={refreshMaiTrendLoading}
                            >
                                刷新
                                <Icon
                                    type="sync"
                                    spin={refreshMaiTrendLoading}
                                />
                            </Button>
                        </div> */}
                    </p>
                    <div className="mai-inner-body">
                        <div className="mai-inner-row">
                            {/* <Card
                                // size={'small'}
                                style={{ marginRight: '5px' }}
                                title={'职位'}
                            > */}
                            <div className="mai-inner-item">
                                <p className="mai-inner-item-title">职位</p>

                                <div className="mai-inner-info">
                                    <div
                                        className="inner-info-item"
                                        onClick={() => {
                                            if (
                                                maiTrendData?.maimai_threeday_high_salary_cnt
                                            ) {
                                                loadInnerJobDetail(
                                                    '近三天发布(高薪)',
                                                    true,
                                                );
                                            }
                                        }}
                                    >
                                        <Statistic
                                            valueStyle={
                                                maiTrendData?.maimai_threeday_high_salary_cnt
                                                    ? statisticCanClickValueStyle
                                                    : statisticValueStyle
                                            }
                                            value={
                                                // 4589
                                                maiTrendData?.maimai_threeday_high_salary_cnt ||
                                                '- -'
                                            }
                                        />
                                        <p className="inner-info-label">
                                            近3天高薪
                                        </p>
                                        {/* <p className="inner-info-label">
                                            (高薪)
                                        </p> */}
                                    </div>
                                    <div
                                        className="inner-info-item"
                                        onClick={() => {
                                            if (
                                                maiTrendData?.maimai_threeday_job_cnt
                                            ) {
                                                loadInnerJobDetail(
                                                    '近三天发布',
                                                    false,
                                                );
                                            }
                                        }}
                                    >
                                        <Statistic
                                            valueStyle={
                                                maiTrendData?.maimai_threeday_job_cnt
                                                    ? statisticCanClickValueStyle
                                                    : statisticValueStyle
                                            }
                                            value={
                                                // 4589
                                                maiTrendData?.maimai_threeday_job_cnt ||
                                                '- -'
                                            }
                                        />
                                        <span className="inner-info-label">
                                            近3天发布
                                        </span>
                                    </div>
                                    {/* <div className="inner-info-item">
                                        <Statistic
                                            valueStyle={statisticValueStyle}
                                            value={
                                                // 4589
                                                maiTrendData?.maimai_high_salary_cnt ||
                                                '- -'
                                            }
                                        />
                                        <p className="inner-info-label">
                                            总职位
                                        </p>
                                        <p className="inner-info-label">
                                            (高薪)
                                        </p>
                                    </div> */}
                                    <div
                                        className="inner-info-item"
                                        onClick={() => {
                                            if (
                                                maiTrendData?.maimai_effective_job_cnt2
                                            ) {
                                                loadInnerJobDetail(
                                                    '总职位',
                                                    undefined,
                                                    true,
                                                );
                                            }
                                        }}
                                    >
                                        <Statistic
                                            valueStyle={
                                                maiTrendData?.maimai_effective_job_cnt2
                                                    ? statisticCanClickValueStyle
                                                    : statisticValueStyle
                                            }
                                            value={
                                                // 3000
                                                maiTrendData?.maimai_effective_job_cnt2 ||
                                                '- -'
                                            }
                                        />
                                        <span className="inner-info-label">
                                            总职位
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* </Card> */}
                            {/* <Card
                                // size={'small'}
                                title={'员工'}
                            > */}
                            <div className="mai-inner-item">
                                <p className="mai-inner-item-title with-button">
                                    <span className="title-text">招聘身份</span>
                                </p>
                                <div className="mai-inner-info">
                                    <div
                                        className="inner-info-item"
                                        onClick={() => {
                                            if (
                                                maiTrendData?.maimai_threeday_register_hr_cnt
                                            ) {
                                                setCUserMode(true);
                                                setCUserQueryScene(
                                                    'REGISTER_HR_3D',
                                                );
                                                setCUserModalVis(true);
                                                voyagerTrack(
                                                    'crm_mai_trend_register_hr',
                                                    {
                                                        queryScene:
                                                            'REGISTER_HR_3D',
                                                    },
                                                );
                                            }
                                        }}
                                    >
                                        <Statistic
                                            valueStyle={
                                                maiTrendData?.maimai_threeday_register_hr_cnt
                                                    ? statisticCanClickValueStyle
                                                    : statisticValueStyle
                                            }
                                            value={
                                                maiTrendData?.maimai_threeday_register_hr_cnt ||
                                                '- -'
                                            }
                                        />
                                        <p className="inner-info-label">
                                            近3天注册
                                        </p>
                                        {/* <p className="inner-info-label">(HR)</p> */}
                                    </div>
                                    <div
                                        className="inner-info-item"
                                        onClick={() => {
                                            if (
                                                maiTrendData?.maimai_threeday_active_hr_cnt
                                            ) {
                                                setCUserMode(true);
                                                setCUserQueryScene(
                                                    'ACTIVE_HR_3D',
                                                );
                                                voyagerTrack(
                                                    'crm_mai_trend_register_hr',
                                                    {
                                                        queryScene:
                                                            'ACTIVE_HR_3D',
                                                    },
                                                );
                                                setCUserModalVis(true);
                                            }
                                        }}
                                    >
                                        <Statistic
                                            valueStyle={
                                                maiTrendData?.maimai_threeday_active_hr_cnt
                                                    ? statisticCanClickValueStyle
                                                    : statisticValueStyle
                                            }
                                            value={
                                                maiTrendData?.maimai_threeday_active_hr_cnt ||
                                                '- -'
                                            }
                                        />
                                        <p className="inner-info-label">
                                            近3天活跃
                                        </p>
                                        {/* <p className="inner-info-label">(HR)</p> */}
                                    </div>
                                    <div
                                        className="inner-info-item"
                                        onClick={() => {
                                            if (
                                                maiTrendData?.maimai_register_hr_cnt
                                            ) {
                                                setCUserMode(true);
                                                setCUserQueryScene('HR_COUNT');
                                                voyagerTrack(
                                                    'crm_mai_trend_register_hr',
                                                    {
                                                        queryScene: 'HR_COUNT',
                                                    },
                                                );
                                                setCUserModalVis(true);
                                            }
                                        }}
                                    >
                                        <Statistic
                                            valueStyle={
                                                maiTrendData?.maimai_register_hr_cnt
                                                    ? statisticCanClickValueStyle
                                                    : statisticValueStyle
                                            }
                                            value={
                                                maiTrendData?.maimai_register_hr_cnt ||
                                                '- -'
                                            }
                                        />
                                        <p className="inner-info-label">
                                            总注册
                                        </p>
                                        {/* <p className="inner-info-label">(HR)</p> */}
                                    </div>
                                    {/* <div className="inner-info-item">
                                        <Statistic
                                            valueStyle={statisticValueStyle}
                                            value={
                                                maiTrendData?.maimai_register_user_cnt ||
                                                '- -'
                                            }
                                        />
                                        <p className="inner-info-label">
                                            总注册
                                        </p>
                                        <p className="inner-info-label">
                                            (全部用户)
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                            {/* </Card> */}
                        </div>
                        <div className="mai-inner-row">
                            {/* <Card
                                // size={'small'}
                                style={{ marginRight: '5px' }}
                                title={'招聘动作'}
                            > */}
                            <div className="mai-inner-item">
                                <p className="mai-inner-item-title">
                                    招聘动作（近3天）
                                </p>
                                <div className="mai-inner-info mai-inner-info-pie">
                                    <Pie
                                        style={{
                                            position: 'relative',
                                            left: '-10%',
                                            width: '330px',
                                        }}
                                        appendPadding={[10, 0]}
                                        data={HRPieData}
                                        angleField="value"
                                        colorField="type"
                                        radius={1}
                                        pieStyle={{
                                            width: 60,
                                        }}
                                        innerRadius={0.6}
                                        height={145}
                                        legend={{
                                            marker: {
                                                style: {
                                                    r: 8,
                                                },
                                            },
                                            itemMarginBottom: 20,
                                            offsetX: -30,
                                            // itemName: {
                                            //     formatter: function formatter(text: string, item: any, index: number) {
                                            //         return `${text} ${HRPieData[index].value}`
                                            //     }
                                            // },
                                            // itemWidth: 300
                                        }}
                                        label={{
                                            type: 'inner',
                                            offset: '-50%',
                                            content: '{value}',
                                            style: {
                                                textAlign: 'center',
                                                fontSize: 14,
                                            },
                                            formatter: function formatter() {
                                                return '{value}';
                                            },
                                        }}
                                        interactions={[
                                            { type: 'element-selected' },
                                            { type: 'element-active' },
                                        ]}
                                        statistic={{
                                            title: false,
                                            content: false,
                                        }}
                                    />
                                    <div className="inner-info-static">
                                        <Statistic
                                            valueStyle={statisticValueStyle}
                                            value={
                                                // 4999
                                                pieData?.searchTalentCnt ||
                                                '- -'
                                            }
                                        />
                                        <Statistic
                                            valueStyle={statisticValueStyle}
                                            value={
                                                // 2030
                                                pieData?.initiateFriendingCnt ||
                                                '- -'
                                            }
                                        />
                                        <Statistic
                                            valueStyle={statisticValueStyle}
                                            value={
                                                // 1500
                                                pieData?.initiateCharCnt ||
                                                '- -'
                                            }
                                        />
                                        <Statistic
                                            valueStyle={statisticValueStyle}
                                            value={
                                                // 1500
                                                pieData?.fastContactCnt || '- -'
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mai-inner-item mai-inner-recharge">
                                <p className="mai-inner-item-title">
                                    会员充值（招聘个人会员）
                                </p>
                                <div className="mai-inner-info-flex-content">
                                    <div className="mai-inner-info">
                                        <div
                                            className="inner-info-item"
                                            onClick={() => {
                                                if (
                                                    maiTrendData?.maimai_onemonth_recruit_person_member_cnt
                                                ) {
                                                    setCUserMode(true);
                                                    setCUserQueryScene(
                                                        'REGISTRATION_30D',
                                                    );
                                                    voyagerTrack(
                                                        'crm_mai_trend_recruit_person',
                                                        {
                                                            queryScene:
                                                                'REGISTRATION_30D',
                                                        },
                                                    );
                                                    setCUserModalVis(true);
                                                }
                                            }}
                                        >
                                            <Statistic
                                                valueStyle={
                                                    maiTrendData?.maimai_onemonth_recruit_person_member_cnt
                                                        ? statisticCanClickValueStyle
                                                        : statisticValueStyle
                                                }
                                                value={
                                                    maiTrendData?.maimai_onemonth_recruit_person_member_cnt ||
                                                    '- -'
                                                }
                                            />
                                            <span className="inner-info-label">
                                                近30天新开会员数
                                            </span>
                                        </div>
                                        <div className="inner-info-item">
                                            <Statistic
                                                valueStyle={statisticValueStyle}
                                                value={
                                                    maiTrendData?.maimai_onemonth_recruit_person_member_money ||
                                                    '- -'
                                                }
                                            />
                                            <span className="inner-info-label">
                                                近30天充值金额
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mai-inner-info">
                                        <div
                                            className="inner-info-item"
                                            onClick={() => {
                                                if (
                                                    maiTrendData?.maimai_recruit_person_member_cnt
                                                ) {
                                                    setCUserMode(true);
                                                    setCUserQueryScene(
                                                        'ALL_MEMBERS',
                                                    );
                                                    voyagerTrack(
                                                        'crm_mai_trend_recruit_person',
                                                        {
                                                            queryScene:
                                                                'ALL_MEMBERS',
                                                        },
                                                    );
                                                    setCUserModalVis(true);
                                                }
                                            }}
                                        >
                                            <Statistic
                                                valueStyle={
                                                    maiTrendData?.maimai_onemonth_recruit_person_member_cnt
                                                        ? statisticCanClickValueStyle
                                                        : statisticValueStyle
                                                }
                                                value={
                                                    maiTrendData?.maimai_recruit_person_member_cnt ||
                                                    '- -'
                                                }
                                            />
                                            <span className="inner-info-label">
                                                当前有效会员数
                                            </span>
                                        </div>
                                        <div className="inner-info-item">
                                            <Statistic
                                                valueStyle={statisticValueStyle}
                                                value={
                                                    maiTrendData?.maimai_recruit_person_member_money ||
                                                    '- -'
                                                }
                                            />
                                            <span className="inner-info-label">
                                                累计充值金额
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </Card> */}
                        </div>
                    </div>
                </div>
                <div className="mai-compete-content">
                    <p className="mai-compete-title">
                        <span>
                            竞品数据
                            <Popover
                                placement="bottomRight"
                                content={
                                    <div>竞品站外公司汇总信息，不定时更新</div>
                                }
                            >
                                <Icon
                                    style={{
                                        marginLeft: '3px',
                                        color: 'rgb(175, 177, 188)',
                                        fontSize: '14px',
                                    }}
                                    type="exclamation-circle"
                                />
                            </Popover>
                        </span>
                    </p>
                    <div className="mai-compete-body">
                        <div className="mai-compete-items-row">
                            <div className="mai-compete-items">
                                {/* <Card title="LP"> */}
                                <div className="mai-compete-info-items">
                                    <span className="mai-compete-items-title">
                                        LP
                                    </span>
                                    {/* {maiTrendData?.lagou_update_import_time && (
                                        <span className="trend-update-time">
                                            更新日期：
                                            {moment(
                                                maiTrendData?.lagou_update_import_time,
                                            ).format('YYYY-MM-DD')}
                                        </span>
                                    )} */}
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布广告数</label>
                                    <span>
                                        {maiTrendData?.liepin_threeday_ad_cnt ||
                                            '- -'}
                                    </span>
                                    {/* <span>1830</span> */}
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布职位数</label>
                                    <span>
                                        {maiTrendData?.liepin_threeday_job_cnt ? (
                                            <a
                                                onClick={() => {
                                                    loadJobdetail(
                                                        'LIEPIN',
                                                        false,
                                                    );
                                                }}
                                            >
                                                {
                                                    maiTrendData?.liepin_threeday_job_cnt
                                                }
                                            </a>
                                        ) : (
                                            '- -'
                                        )}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>
                                        {/* {maiTrendData?.liepin_threeday_high_salary_cnt ? (
                                            <a
                                                onClick={() =>
                                                    loadJobdetail('LP')
                                                }
                                            >
                                                近3天发布职位（高薪）
                                            </a>
                                        ) : (
                                            '近3天发布职位（高薪）'
                                        )} */}
                                        近3天发布职位（高薪）
                                    </label>
                                    <span>
                                        {maiTrendData?.liepin_threeday_high_salary_cnt ? (
                                            <a
                                                onClick={() =>
                                                    loadJobdetail(
                                                        'LIEPIN',
                                                        true,
                                                    )
                                                }
                                            >
                                                {
                                                    maiTrendData?.liepin_threeday_high_salary_cnt
                                                }
                                            </a>
                                        ) : (
                                            '- -'
                                        )}
                                    </span>
                                </div>
                                {/* <div className="mai-compete-info-items">
                                    <label>有效职位总数</label>
                                    <span>
                                        {maiTrendData?.liepin_job_cnt || '- -'}
                                    </span>
                                </div> */}
                                {/* <div className="mai-compete-info-items">
                                    <label>L产品职位总数</label>
                                    <span>
                                        {maiTrendData?.liepin_product_cnt ||
                                            '- -'}
                                    </span>
                                </div> */}
                                <div className="mai-compete-info-items">
                                    <label>行业</label>
                                    <span>
                                        {maiTrendData?.liepin_industry || '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>职位链接</label>
                                    <span
                                        onClick={() => {
                                            const link =
                                                maiTrendData?.liepin_company_link;
                                            if (link) {
                                                window.open(link, '_blank');
                                            } else {
                                                message.warning(
                                                    '暂无链接，无法跳转职位',
                                                );
                                            }
                                        }}
                                        className={`mai-compete-info-items-link ${
                                            !maiTrendData?.liepin_company_link
                                                ? 'no-link'
                                                : ''
                                        }`}
                                    >
                                        <Icon type="global" />
                                    </span>
                                </div>
                                {/* </Card> */}
                            </div>
                            <div className="mai-compete-items">
                                {/* <Card title="LG"> */}
                                <div className="mai-compete-info-items">
                                    <span className="mai-compete-items-title">
                                        LG
                                    </span>
                                    {/* {maiTrendData?.lagou_update_import_time && (
                                        <span className="trend-update-time">
                                            更新日期：
                                            {moment(
                                                maiTrendData?.lagou_update_import_time,
                                            ).format('YYYY-MM-DD')}
                                        </span>
                                    )} */}
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布广告数</label>
                                    <span>
                                        {maiTrendData?.lagou_threeday_ad_cnt ||
                                            '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布职位数</label>
                                    <span>
                                        {maiTrendData?.lagou_threeday_job_cnt ? (
                                            <a
                                                onClick={() => {
                                                    loadJobdetail(
                                                        'LAGOU',
                                                        false,
                                                    );
                                                }}
                                            >
                                                {
                                                    maiTrendData?.lagou_threeday_job_cnt
                                                }
                                            </a>
                                        ) : (
                                            '- -'
                                        )}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>
                                        {/* {maiTrendData?.lagou_threeday_high_salary_cnt ? (
                                            <a
                                                onClick={() =>
                                                    loadJobdetail('LG')
                                                }
                                            >
                                                近3天发布职位（高薪）
                                            </a>
                                        ) : (
                                            '近3天发布职位（高薪）'
                                        )} */}
                                        近3天发布职位（高薪）
                                    </label>
                                    <span>
                                        {maiTrendData?.lagou_threeday_high_salary_cnt ? (
                                            <a
                                                onClick={() =>
                                                    loadJobdetail('LAGOU', true)
                                                }
                                            >
                                                {
                                                    maiTrendData?.lagou_threeday_high_salary_cnt
                                                }
                                            </a>
                                        ) : (
                                            '- -'
                                        )}
                                    </span>
                                </div>
                                {/* <div className="mai-compete-info-items">
                                    <label>有效职位总数</label>
                                    <span>
                                        {maiTrendData?.lagou_job_cnt || '- -'}
                                    </span>
                                </div> */}
                                {/* <div className="mai-compete-info-items">
                                    <label>G产品职位总数</label>
                                    <span>
                                        {maiTrendData?.lagou_product_cnt ||
                                            '- -'}
                                    </span>
                                </div> */}
                                <div className="mai-compete-info-items">
                                    <label>行业</label>
                                    <span>
                                        {maiTrendData?.lagou_industry || '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>职位链接</label>
                                    <span
                                        onClick={() => {
                                            const link =
                                                maiTrendData?.lagou_company_link;
                                            if (link) {
                                                window.open(link, '_blank');
                                            } else {
                                                message.warning(
                                                    '暂无链接，无法跳转职位',
                                                );
                                            }
                                        }}
                                        className={`mai-compete-info-items-link ${
                                            !maiTrendData?.lagou_company_link
                                                ? 'no-link'
                                                : ''
                                        }`}
                                    >
                                        <Icon type="global" />
                                    </span>
                                </div>
                                {/* </Card> */}
                            </div>
                            <div className="mai-compete-items">
                                <div className="mai-compete-info-items">
                                    <span className="mai-compete-items-title">
                                        ZP
                                    </span>
                                    {/* {maiTrendData?.zhuopin_update_import_time && (
                                        <span className="trend-update-time">
                                            更新日期：
                                            {moment(
                                                maiTrendData?.zhuopin_update_import_time,
                                            ).format('YYYY-MM-DD')}
                                        </span>
                                    )} */}
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布广告数</label>
                                    <span>
                                        {maiTrendData?.zhuopin_threeday_ad_cnt ||
                                            '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布职位数</label>
                                    <span>
                                        {maiTrendData?.zhuopin_threeday_job_cnt ? (
                                            <a
                                                onClick={() => {
                                                    loadJobdetail(
                                                        'ZHUOPIN',
                                                        false,
                                                    );
                                                }}
                                            >
                                                {
                                                    maiTrendData?.zhuopin_threeday_job_cnt
                                                }
                                            </a>
                                        ) : (
                                            '- -'
                                        )}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布职位（高薪）</label>
                                    <span>
                                        {maiTrendData?.zhuopin_threeday_high_salary_cnt ? (
                                            <a
                                                onClick={() =>
                                                    loadJobdetail(
                                                        'ZHUOPIN',
                                                        true,
                                                    )
                                                }
                                            >
                                                {
                                                    maiTrendData?.zhuopin_threeday_high_salary_cnt
                                                }
                                            </a>
                                        ) : (
                                            '- -'
                                        )}
                                    </span>
                                </div>
                                {/* <div className="mai-compete-info-items">
                                    <label>有效职位总数</label>
                                    <span>
                                        {maiTrendData?.zhuopin_job_cnt || '- -'}
                                    </span>
                                </div> */}
                                <div className="mai-compete-info-items">
                                    <label>行业</label>
                                    <span>
                                        {maiTrendData?.zhuopin_industry ||
                                            '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>职位链接</label>
                                    <span
                                        onClick={() => {
                                            const link =
                                                maiTrendData?.zhuopin_company_link;
                                            if (link) {
                                                window.open(link, '_blank');
                                            } else {
                                                message.warning(
                                                    '暂无链接，无法跳转职位',
                                                );
                                            }
                                        }}
                                        className={`mai-compete-info-items-link ${
                                            !maiTrendData?.zhuopin_company_link
                                                ? 'no-link'
                                                : ''
                                        }`}
                                    >
                                        <Icon type="global" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="mai-compete-items-row">
                            <div className="mai-compete-items">
                                <div className="mai-compete-info-items">
                                    <span className="mai-compete-items-title">
                                        51
                                    </span>
                                    {maiTrendData?.wyjob_update_import_time && (
                                        <span className="trend-update-time">
                                            更新日期：
                                            {moment(
                                                maiTrendData?.wyjob_update_import_time,
                                            ).format('YYYY-MM-DD')}
                                        </span>
                                    )}
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布广告数</label>
                                    <span>
                                        {maiTrendData?.wyjob_threeday_ad_cnt ||
                                            '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布职位数</label>
                                    <span>
                                        {maiTrendData?.wyjob_threeday_job_cnt ||
                                            '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布职位（高薪）</label>
                                    <span>
                                        {maiTrendData?.wyjob_threeday_high_salary_cnt ||
                                            '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>有效职位总数</label>
                                    <span>
                                        {maiTrendData?.wyjob_job_cnt || '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>行业</label>
                                    <span>
                                        {maiTrendData?.wyjob_industry || '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>职位链接</label>
                                    <span
                                        onClick={() => {
                                            const link =
                                                maiTrendData?.wyjob_company_link;
                                            if (link) {
                                                window.open(link, '_blank');
                                            } else {
                                                message.warning(
                                                    '暂无链接，无法跳转职位',
                                                );
                                            }
                                        }}
                                        className={`mai-compete-info-items-link ${
                                            !maiTrendData?.wyjob_company_link
                                                ? 'no-link'
                                                : ''
                                        }`}
                                    >
                                        <Icon type="global" />
                                    </span>
                                </div>
                            </div>
                            <div className="mai-compete-items">
                                <div className="mai-compete-info-items">
                                    <span className="mai-compete-items-title">
                                        ZL
                                    </span>
                                    {maiTrendData?.zhilian_update_import_time && (
                                        <span className="trend-update-time">
                                            更新日期：
                                            {moment(
                                                maiTrendData?.zhilian_update_import_time,
                                            ).format('YYYY-MM-DD')}import { tryNewCall } from '@/containers/hoc/call-center';
import { tryNewCall } from '../../../containers/hoc/call-center/index';
import { CUser2Contacts } from './cuser-modal/cuser2contacts';

                                        </span>
                                    )}
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布广告数</label>
                                    <span>
                                        {maiTrendData?.zhilian_threeday_ad_cnt ||
                                            '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布职位数</label>
                                    <span>
                                        {maiTrendData?.zhilian_threeday_job_cnt ||
                                            '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>近3天发布职位（高薪）</label>
                                    <span>
                                        {maiTrendData?.zhilian_threeday_high_salary_cnt ||
                                            '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>有效职位总数</label>
                                    <span>
                                        {maiTrendData?.zhilian_job_cnt || '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>行业</label>
                                    <span>
                                        {maiTrendData?.zhilian_industry ||
                                            '- -'}
                                    </span>
                                </div>
                                <div className="mai-compete-info-items">
                                    <label>职位链接</label>
                                    <span
                                        onClick={() => {
                                            const link =
                                                maiTrendData?.zhilian_company_link;
                                            if (link) {
                                                window.open(link, '_blank');
                                            } else {
                                                message.warning(
                                                    '暂无链接，无法跳转职位',
                                                );
                                            }
                                        }}
                                        className={`mai-compete-info-items-link ${
                                            !maiTrendData?.zhilian_company_link
                                                ? 'no-link'
                                                : ''
                                        }`}
                                    >
                                        <Icon type="global" />
                                    </span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="mai-it-content">
                    <p className="mai-it-title">
                        <span>
                            融资信息（IT桔子）
                            {/* <Popover
                                placement="bottomRight"
                                content={
                                    <div>竞品站外公司汇总信息，不定时更新</div>
                                }
                            >
                                <Icon
                                    style={{
                                        marginLeft: '3px',
                                        color: 'rgb(175, 177, 188)',
                                        fontSize: '14px',
                                    }}
                                    type="exclamation-circle"
                                />
                            </Popover> */}
                        </span>
                    </p>
                    <div className="mai-it-body">
                        <FinanceTable cName={defaultCustomerName} />
                    </div>
                </div>
            </div>
        </>
        // </Spin>
    );
};
export default observer(MaiTrend);
