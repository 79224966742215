import React, { useState, ReactNodeArray } from 'react';
import _ from 'lodash';

import UserSelector, { IUserSelectorProps } from '../../user-selector/index';
import { ICommonProps, getCommonStatus } from './common';
import { loadingWrapper } from '@/components';
import TeamSelector, { ITeamSelectorProps } from '../../team-selector';
export type IWidgetTeamSelectorValue = any[] | any;
export interface IWidgetTeamSelectorProps<T = any> extends ICommonProps<T> {
    options: ITeamSelectorProps<any[] | any>;
    value: any[] | any;
    onChange: (value: any[]) => void;
}

export const WidgetTeamSelector: React.FC<IWidgetTeamSelectorProps> = ({
    value,
    options,
    onChange,
    status,
    onInteract = key => void 0,
    data,
    statusExtraData,
}) => {
    // 非常恶心，每次focus都会触发focus-blur-focus 怀疑是因为下拉框导致的
    // blur会第一次触发blur-focus-blur 之后都触发blur
    // clear会触发blur-focus-blur onchange在所有触发器最后
    let focusCount: number = 0;
    const { loading, disabled } = getCommonStatus(
        status,
        data,
        statusExtraData,
    );
    const wrapper = loadingWrapper(loading || false);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }
    return wrapper(
        <TeamSelector
            style={{ width: '100%', minWidth: 150 }}
            {...options}
            value={value}
            defaultValue={value}
            onFocus={() => {
                if (focusCount !== 1) {
                    focusCount++;
                    setTimeout(() => {
                        // 200ms 此处大概率跟电脑性能有关系
                        focusCount = 0;
                    }, 150);
                } else {
                    focusCount = 0;
                    console.log('focus');
                    onInteract('focus');
                    onInteract('remove-validate-status');
                }
            }}
            onChange={(v: any, label: any, extra: any) => {
                onInteract('validate-instantly', v);
                if (options.onChange) {
                    options.onChange(v, label, extra);
                }
                onChange(v);
            }}
            // onBlur={() => {
            //     onInteract('blur');
            //     onInteract('validate-instantly');
            // }}
        />,
    );
};
