import React, { useState, useEffect } from 'react';
import { Input, Table, Icon, ConfigProvider } from 'antd';
import { ICommonProps } from '../common';
import _ from 'lodash';
import { ICommonObjectOptions, WidgetCommonObject } from '../object';
import { InputProps } from 'antd/lib/input';
import { arrToJson } from '@/utils';
import { toJS } from 'mobx';
import { AntTable } from '@/components/antd';
import { getCommonStatus } from '..';

export type SelectProductRightValueType = any[];
export interface ISelectProductRightOptions {
    objectOptions?: ICommonObjectOptions;
    inputProps: InputProps;
}

export interface IWidgetSelectProductRightProps<T = any>
    extends ICommonProps<T> {
    value: SelectProductRightValueType;
    options: ISelectProductRightOptions;
    onChange: (value: SelectProductRightValueType) => void;
}

export const equityBasicCols = [
    {
        title: '权益名',
        key: 'equity_name',
        render: (text: any, record: any) => {
            const { datum } = record;
            const value = datum?.relatedObj?.equity_name || '';
            return value;
        },
    },
    // 得分肯定不是关联对象的一个字段，这个字段很特殊
    // 其实跟relatedObject关联很弱
    {
        title: '标准权益值',
        key: 'value',
        width: 100,
        render: (text: any, record: any) => {
            const { datum, onChange } = record;
            const value = datum?.relatedObj?.value || '';
            return (
                <Input
                    onChange={e => {
                        const nextDatum = _.cloneDeep(datum);
                        const obj = nextDatum?.relatedObj;
                        if (obj) {
                            obj.value = obj.value || '';
                            obj.value = e.target.value;
                        }
                        onChange(nextDatum);
                    }}
                    value={value}
                />
            );
        },
    },
    {
        title: '赠送兑换折扣',
        key: 'convertibility_discount',
        width: 100,
        render: (text: any, record: any) => {
            const { datum, onChange } = record;
            const value = datum?.relatedObj?.convertibility_discount || '';
            return (
                <Input
                    onChange={e => {
                        const nextDatum = _.cloneDeep(datum);
                        const obj = nextDatum?.relatedObj;
                        if (obj) {
                            obj.convertibility_discount =
                                obj.convertibility_discount || '';
                            obj.convertibility_discount = e.target.value;
                        }
                        onChange(nextDatum);
                    }}
                    value={value}
                />
            );
        },
    },
];

const columns = [
    ...equityBasicCols,
    {
        title: '',
        key: 'op',
        render: (text: any, record: any) => {
            const { onDelete } = record;
            return <a onClick={onDelete}>删除</a>;
        },
    },
];

const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
        <Icon type="smile" style={{ fontSize: 20 }} />
        <p>请选择权益</p>
    </div>
);

export const WidgetSelectProductRight: React.FC<IWidgetSelectProductRightProps> = ({
    k,
    value,
    options,
    onChange,
    onInteract = key => void 0,
    status,
    data,
    statusExtraData,
}) => {
    const [modalVisibleTrigger, setModalVisibleTrigger] = useState(0);
    value = _.isArray(value) ? value : [];
    const { disabled } = getCommonStatus(status, data, statusExtraData);

    if (_.isNil(options.objectOptions)) {
        return null;
    }

    const openInnerModal = () =>
        setModalVisibleTrigger(1 + modalVisibleTrigger);

    // originalValue,
    // relatedObj,
    // type,

    const dataSource = value.map((datum, index) => {
        const innerOnChange = (nextDatum: any) => {
            const nextData = [...value];
            nextData[index] = nextDatum;
            onChange(nextData);
        };
        const onDelete = () => {
            const nextData = [...value];
            nextData.splice(index, 1);
            onChange(nextData);
        };

        return {
            onChange: innerOnChange,
            onDelete,
            datum,
        };
    });

    return (
        <div
            style={{
                width: 450,
                minWidth: 150,
                display: 'inline-block',
                position: 'relative',
                padding: '10px 15px',
                backgroundColor: '#f7f8fa',
                left: -80,
                ...(disabled
                    ? {
                          cursor: 'not-allowed',
                      }
                    : {}),
            }}
            className="crm-widgets-multiple-obj"
        >
            <div
                style={{
                    ...(disabled
                        ? {
                              pointerEvents: 'none',
                              opacity: 0.7,
                          }
                        : {}),
                }}
                className="display-table"
            >
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                    <AntTable
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                    />
                </ConfigProvider>
            </div>
            <div
                style={{
                    ...(disabled
                        ? {
                              pointerEvents: 'none',
                              opacity: 0.7,
                          }
                        : {}),
                }}
                className="selector-display"
            >
                <div style={{ display: 'none' }}>
                    <WidgetCommonObject
                        k={k}
                        value={value}
                        onChange={nextValue => {
                            const valueMap = arrToJson(value, 'originalValue');
                            for (const item of nextValue) {
                                if (valueMap[item.originalValue]) {
                                    item.relatedObj =
                                        valueMap[item.originalValue].relatedObj;
                                }
                            }
                            onChange(nextValue);
                        }}
                        options={{
                            ...options.objectOptions,
                            multiple: true,
                            modalVisibleTrigger,
                            paramsBlackList: { equity_detail: true },
                        }}
                    />
                </div>
                <a onClick={() => openInnerModal()}>+ 添加权益</a>
            </div>
        </div>
    );
};
