import React from 'react';
import Checkbox, { CheckboxProps, CheckboxGroupProps } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { getCommonStatus, ICommonProps } from './common';
import _ from 'lodash';

const CheckboxGroup = Checkbox.Group;

export type CheckboxGroupValueType = CheckboxValueType[];
export interface ICheckboxOptions extends CheckboxGroupProps {}

export interface IWidgetCheckboxProps<T = any> extends ICommonProps<T> {
    value: CheckboxGroupValueType;
    options: ICheckboxOptions;
    onChange: (value: CheckboxGroupValueType) => void;
}

export const WidgetCheckbox: React.FC<IWidgetCheckboxProps> = ({
    value,
    options,
    onChange,
    status,
    onInteract = key => void 0,
    data,
    statusExtraData,
}) => {
    const { disabled } = getCommonStatus(status, data, statusExtraData);
    if (!_.isNil(disabled)) {
        options.disabled = disabled;
    }
    return (
        <CheckboxGroup
            value={value}
            onChange={v => {
                onChange(v);
                onInteract('validate-instantly', v);
            }}
            {...options}
        ></CheckboxGroup>
    );
};
