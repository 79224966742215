import React, { memo } from 'react';
import { AutoEllipsis } from '../../auto-ellipsis';
import { CCBtn } from '../../cc-btn';
import { toJS } from 'mobx';

export const CC: React.FC<{
    args: any[];
    config: {
        customerName?: string;
        customerId?: string | number;
        contactsId?: string | number;
        showFollowLog?: boolean;
        leadId?: string | number;
        [key: string]: any;
    };
    isHidden?: boolean;
    outerHiddenNumber?: string;
    sceneCode: string;
}> = memo(({ args, config, isHidden, sceneCode, outerHiddenNumber }) => {
    const value = args[0];
    const hackConfig = args[3] || {};
    if (!value) {
        return null;
    }
    const finalHidden =
        typeof isHidden === 'boolean' ? isHidden : args[1]?.secure === 'crypto';
    const finalSceneCode = sceneCode || args[2] || '';
    const tempConfig = {
        ...config,
    };
    console.log(hackConfig);
    if (!tempConfig.leadId) {
        tempConfig.leadId = hackConfig.leadItem;
    }
    if (!tempConfig.contactsId) {
        tempConfig.contactsId = hackConfig.contactsItem;
    }
    if (!tempConfig.customerId) {
        tempConfig.customerId = hackConfig.customerItem;
    }
    if (!tempConfig.customerName) {
        tempConfig.customerName = hackConfig.customerName;
    }
    // if(finalSceneCode === 'crm_customer') {
    //     // customer
    //     if(!tempConfig.customerId) {
    //         tempConfig.leadId = record?.id;
    //     }
    // }
    return (
        <AutoEllipsis
            text={
                <CCBtn
                    isHidden={finalHidden}
                    sceneCode={finalSceneCode}
                    callConfig={{ ...tempConfig }}
                    phoneNum={value}
                    outerHiddenNumber={outerHiddenNumber}
                />
            }
        />
    );
});
