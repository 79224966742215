import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AntButton, BlockLoading2 } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { AntDrawer } from '@/components/antd/drawer';
import IQFormDrawer from '@/pages/om/contract/contract-invoice-qualification/components/form-drawer';
import { defaultAuthDeco } from '@/containers/hoc';
import { ContractInvoiceApplyListStore } from '../store';
import DemoForm from './form';
import { tryRefresh } from '@/utils';
import { useAnyInvoiceQualificationListStore } from '../../contract-invoice-qualification/store';

const FormDrawer: React.FC<RouteComponentProps & {
    defaultStore: ContractInvoiceApplyListStore;
    preRequiredReady: boolean;
}> = ({ defaultStore, preRequiredReady }) => {
    const [
        iqStorePrepared,
        iqStore,
        prepareIqStore,
    ] = useAnyInvoiceQualificationListStore({
        autoLoad: false,
    });
    useEffect(() => {
        if (!defaultStore.sysUserId) {
            return;
        }
        iqStore.setSysUserId(defaultStore.sysUserId);
        iqStore.defaultMeta.setTableId('invoice_qualification');
    }, [defaultStore.sysUserId]);

    const { actionType: iqActiontype, defaultEE: iqEE } = iqStore;
    const iqOpened = ['create', 'update'].indexOf(iqActiontype) > -1;

    const {
        actionType,
        bigFormVisible,
        mutatingDataLoading,
        mutatingData,
        invoiceQualificationLoading,
        hasInvoiceQualification,
    } = defaultStore;
    const { contract_id } = mutatingData || {};
    const [selectedContract, setSelectedContract] = useState<any>(null);
    useEffect(() => {
        if (!contract_id) {
            return;
        }
        if (
            selectedContract === null ||
            contract_id.originalValue !== selectedContract.originalValue
        ) {
            setSelectedContract(_.cloneDeep(contract_id));
        }
    }, [contract_id]);

    useEffect(() => {
        if (_.isNil(selectedContract)) {
            return;
        }
        defaultStore.askInvoiceQualifyByContactId(selectedContract);
    }, [selectedContract]);

    useEffect(() => {
        const handler = () => {
            defaultStore.askInvoiceQualifyByContactId(selectedContract);
        };
        iqEE.on('mutate-success', handler);
        return () => {
            iqEE.removeListener('mutate-success', handler);
        };
    }, [selectedContract]);

    if (!preRequiredReady) {
        return null;
    }

    const popups = (
        <>
            <IQFormDrawer
                defaultStore={iqStore}
                preRequiredReady={preRequiredReady && iqStorePrepared}
            />
            <AntDrawer
                title={
                    actionType === 'create'
                        ? '新建合同开发票申请'
                        : '修改合同开发票申请'
                }
                placement={'right'}
                visible={bigFormVisible}
                onClose={() => {
                    defaultStore.resetAction();
                    defaultStore.invoiceQuilificationBad();
                    setSelectedContract(null);
                }}
                bodyStyle={{
                    width: 850 + (iqOpened ? 100 : 0),
                    overflow: 'scroll',
                }}
                width={850 + (iqOpened ? 100 : 0) + 'px'}
            >
                <div className="standard-drawer-form-wrapper">
                    {mutatingDataLoading ? (
                        <BlockLoading2 />
                    ) : (
                        <DemoForm
                            iqStoreInfo={{
                                iqStorePrepared,
                                iqStore,
                                prepareIqStore,
                            }}
                            defaultStore={defaultStore}
                        />
                    )}
                    <div className="fixed-form-btns">
                        <AntButton
                            size="large"
                            type="primary"
                            disabled={!hasInvoiceQualification}
                            loading={invoiceQualificationLoading}
                            onClick={async () => {
                                const isSuccess = await defaultStore.dispatchAction();
                                if (isSuccess) {
                                    tryRefresh(['contract_invoice_apply']);
                                }
                            }}
                        >
                            {actionType === 'create' ? '新建' : '修改'}
                        </AntButton>
                        <AntButton
                            size="large"
                            onClick={() => {
                                defaultStore.resetAction();
                                defaultStore.invoiceQuilificationBad();
                                setSelectedContract(null);
                            }}
                        >
                            取消
                        </AntButton>
                    </div>
                </div>
            </AntDrawer>
        </>
    );

    return popups;
};

const FinalFormDrawer = defaultAuthDeco(observer(FormDrawer));
export default FinalFormDrawer;
