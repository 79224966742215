import React, { CSSProperties, PropsWithChildren } from 'react';
import {
    Widgets,
    WidgetsKeys,
    IWidgetsOptions,
    IWidgetsValueTypes,
    ICommonStatus,
    InnerInteractCallback,
    ICommonProps,
} from './widgets';
import _ from 'lodash';
import { FormItemProps, FormProps } from 'antd/lib/form';
import './index.scss';

import { ICell, TCellsSelector, TCell } from '../table';
import { AdvancedFilter } from '../advanced-filter';
import { observer } from 'mobx-react-lite';

export const WidgetProxyADF: React.FC = observer((props: any) => {
    const { WCom, k, data } = props;
    if (!WCom || !k || !data) {
        return null;
    }
    // filter 字段的
    const value = data[k].value;
    return <WCom value={value} {...props} />;
});

export interface ICatchGroupKeysItems {
    [key: string]: {
        groupClassName?: string;
        afterNum?: number; // 将会添加到第几个form-items后面
        visible?: boolean;
        groupTitle?: string | any; // 头
    };
}
export interface IItemOptionItem<O, K extends keyof O = keyof O>
    extends FormItemProps {
    key: K;
    className?: string;
    style?: CSSProperties;
    labelWidth?: number;
}
export interface IWidgetItem<
    O,
    E = any,
    K extends keyof O = keyof O,
    T extends WidgetsKeys = WidgetsKeys
>
    extends IWidgetsOptions,
        IWidgetsValueTypes,
        Pick<
            ICommonProps<O, E>,
            | 'nextlineWidget'
            | 'prevlineWidget'
            | 'beforeLabel'
            | 'afterLabel'
            | 'beforeWidget'
            | 'afterWidget'
            | 'widgetClassName'
        > {
    key: K;
    widgetKey: T;
    status?: ICommonStatus<O, E>;
    onInteract?: InnerInteractCallback;
    wrapperKey?: string; // 分组key
    formItemClassName?: string; // 外层items容器上添加的classname
}

export type CommonFormOnInteract<T> = (
    key: keyof T,
    type: string,
    value?: any,
) => void;

export type CommonFormOnChange<
    O,
    K extends keyof O = keyof O,
    KT = O[K],
    T extends WidgetsKeys = WidgetsKeys
> = (key: K, value: KT, widgetKey: T) => void;

type CommonLayout = 'default' | 'inline' | number[][];
type FFormProps = { className?: string; style?: CSSProperties } & FormProps;
export type TCollpaseLayout = Array<{
    groupKey: string;
    groupName: string;
    groupSpan: number;
}>;

export interface IAdFiltersProps<T, E = any> {
    style?: CSSProperties;
    className?: string;
    // 数据
    data: Nilable<T>;
    // 插件配置
    widgets: Array<IWidgetItem<T, E>>;
    // 数据变化回调函数
    onChange: CommonFormOnChange<T>;
    // 组件交互回调函数
    onInteract?: CommonFormOnInteract<T>;
    // 排序变化
    onSorterChange?: (key: string, sorttype: string) => void;

    onCancel?: (key: string) => void;
    onConfirm?: (key: string) => void;
}

// 将数据嵌入插件配置
export const embedDataToWidgets: <T>(
    widgets: Array<IWidgetItem<T>>,
    data: T | undefined | null,
) => void = (widgets, data) => {
    if (_.isNil(data)) {
        return;
    }
    for (const widgetItem of widgets) {
        const dataKey = widgetItem.key;

        // 自动将数据值填入到对应的插件值中
        // 外部有类型限制，内部肮脏点无所谓了
        const wKey = widgetItem.widgetKey;
        (widgetItem as any)[wKey + 'Value'] = (data as any)[dataKey] as any;
    }
};

// 偷偷修改某个key对应的widget
export const applyCustomConfToWidget: <T = any>(
    widgets: Array<IWidgetItem<T>>,
    key: string,
    genNextWidget: (prevWidget: IWidgetItem<T>) => IWidgetItem<T>,
) => void = (widgets, key, genNextWidget) => {
    let index = 0;
    for (const widget of widgets) {
        if (widget.key === key) {
            break;
        }
        index++;
    }
    if (index === widgets.length) {
        return;
    }
    widgets[index] = genNextWidget(widgets[index]);
};

export const getDefaultCellSelector = (key: string): ICell<any> => {
    return {
        colKey: key,
        cell: 'Echo',
        inputs: [key],
    };
};

export const applyCustomConfToDefaultCellSelector: <T = any>(
    cs: TCellsSelector<T>,
    key: string,
) => void = (cs, key) => {
    let index = 0;
    for (const c of cs) {
        if (c.colKey === key) {
            break;
        }
        index++;
    }
    if (index === cs.length) {
        // 默认cellSelector
        const newCellSelector: ICell<any> = getDefaultCellSelector(key);
        cs.push(newCellSelector);
        return;
    }
    cs[index] = getDefaultCellSelector(key);
};

// 偷偷修改某个key对应的cellsSelector
export const applyCustomConfToCellsSelector: <T = any>(
    cs: TCellsSelector<T>,
    key: string,
    genNextCellSelector: (prevWidget: TCell<T>) => TCell<T>,
) => void = (cs, key, genNextCellSelector) => {
    let index = 0;
    for (const c of cs) {
        if (c.colKey === key) {
            break;
        }
        index++;
    }
    if (index === cs.length) {
        // 默认cellSelector
        const newCellSelector: ICell<any> = getDefaultCellSelector(key);
        cs.push(genNextCellSelector(newCellSelector));
        return;
    }
    cs[index] = genNextCellSelector(cs[index]);
};

export const getAutoOnChangeHandler: <T>(
    data: T,
    actualHandler: (d: T) => void,
) => CommonFormOnChange<T> = (data, actualHandler) => {
    return (key, value, widget) => {
        const nextData = _.cloneDeep(data);
        nextData[key] = value;
        actualHandler(nextData);
    };
};

const widthMap: { [key: string]: number } = {
    object: 450,
    filterDateRange: 600,
    filterNumberRange: 300,
    filterEnum: 300,
    input: 300,
    sysUserSelector: 450,
};

export const getAdvancedFilterWidgets: <T, E = any>(
    props: PropsWithChildren<IAdFiltersProps<T, E>>,
) => { [key: string]: React.ReactElement | null } = ({
    data,
    widgets,
    onChange,
    onInteract,
    onSorterChange: outerSorterChange = () => {},
    onCancel = () => {},
    onConfirm = () => {},
}) => {
    const ret: { [key: string]: React.ReactElement | null } = {};
    if (_.isNil(data)) {
        return ret;
    }

    _.each(widgets, widgetItem => {
        const wKey = widgetItem.widgetKey;
        const WCom = Widgets[wKey];
        const { key, widgetKey } = widgetItem;

        const options = (widgetItem as any)[widgetKey + 'Options'] || {};

        const finalOnChange = (v: any, rawV: any) => {
            let isEmpty: any = false;
            if (rawV && 'isEmpty' in rawV) {
                isEmpty = (rawV as any).isEmpty;
            }

            let isNotEmpty: any = false;
            if (rawV && 'isNotEmpty' in rawV) {
                isNotEmpty = (rawV as any).isNotEmpty;
            }

            // TODO, 如果v存在值，isEmpty应该是false吧
            let nextIsEmpty = isEmpty;
            if (!_.isEqual((rawV as any).emptyValue, v)) {
                nextIsEmpty = false;
            }

            let nextIsNotEmpty = isNotEmpty;
            if (!_.isEqual((rawV as any).emptyValue, v)) {
                nextIsNotEmpty = false;
            }
            onChange(
                key,
                _.assign({}, rawV, {
                    value: v,
                    isEmpty: nextIsEmpty,
                    isNotEmpty: nextIsNotEmpty,
                } as any),
                widgetKey,
            );
        };
        const finalEmptyChange = (nextIsEmpty: boolean, rawV: any) => {
            let value: any = rawV;
            if (rawV && 'value' in rawV) {
                value = (rawV as any).value;
            }
            const emptyValue = _.cloneDeep((rawV as any).emptyValue);
            onChange(
                key,
                _.assign({}, rawV, {
                    // 如果选择空，则值就清空
                    value: nextIsEmpty ? emptyValue : value,
                    isEmpty: nextIsEmpty,
                    isNotEmpty: false,
                } as any),
                widgetKey,
            );
        };
        const finalNotEmptyChange = (nextIsNotEmpty: boolean, rawV: any) => {
            let value: any = rawV;
            if (rawV && 'value' in rawV) {
                value = (rawV as any).value;
            }
            const emptyValue = _.cloneDeep((rawV as any).emptyValue);
            onChange(
                key,
                _.assign({}, rawV, {
                    // 如果选择空，则值就清空
                    value: nextIsNotEmpty ? emptyValue : value,
                    isNotEmpty: nextIsNotEmpty,
                    isEmpty: false,
                } as any),
                widgetKey,
            );
        };
        const finalOnInteract = (type: string, value: any) => {
            if (onInteract) {
                onInteract(key, type, value);
            }
        };

        const props = {
            k: key,
            key,
            onChange: finalOnChange,
            onInteract: finalOnInteract,
            options,
            status: {},
            data,
            statusExtraData: {},
            WCom,
        } as any;

        ret[key as string] = (
            <div
                key={'adfwrap-' + key}
                style={{ width: widthMap[widgetKey] || 300 }}
            >
                <AdvancedFilter
                    k={key as string}
                    widget={{ WCom: WidgetProxyADF, props }}
                    onIsEmptyValueChange={finalEmptyChange}
                    onIsNotEmptyValueChange={finalNotEmptyChange}
                    onSorterChange={filterType => {
                        outerSorterChange(key as string, filterType);
                    }}
                    onClose={() => {}}
                    onCancel={() => {
                        onCancel(key as string);
                    }}
                    onConfirm={() => {
                        onConfirm(key as string);
                    }}
                />
            </div>
        );
    });

    return ret;
};
