import React, { memo } from 'react';
import { AutoEllipsis } from '../../auto-ellipsis';
import _ from 'lodash';
import querystring from 'querystring';
import { Popover, Icon } from 'antd';

const rt2Hover: any = {
    1: '首次认领客户后，7天内未新建活动记录，系统将自动回收',
    2: '最近一次活动记录之后，31天内未再次跟进客户并新建活动记录，系统将自动回收',
    3: '领取后150天未成交（至少一个付费产品已开通且在线），系统将自动回收',
    4: '最晚一个产品到期后，30天内未成功续约并完成产品开通，系统将自动回收',
    5: '最晚一个产品到期后，30天内未成功续约并完成产品开通，系统将自动回收',
    6: '延期申请通过后，系统自动将回收时间推迟30天，30天后延期保护失效，系统将按规则执行自动回收策略',
    7: '首次认领非标客户后，3天内未转为标准客户，系统将自动回收',
};
export const RecycleType: React.FC<{
    args: any[];
    item: any;
    onAction: (...args: any[]) => void;
}> = memo(({ args, item, onAction }) => {
    const value = args[0];
    const options = args[1] || {};
    if (value && options?.[value]) {
        return (
            <Popover
                getPopupContainer={() => {
                    return document.body as HTMLElement;
                }}
                placement={'leftTop'}
                content={<p>{rt2Hover?.[value]}</p>}
                trigger="hover"
            >
                <Icon
                    style={{
                        color: '#0052ff',
                        cursor: 'pointer',
                    }}
                    type="info-circle"
                />
                &nbsp;
                <AutoEllipsis
                    style={{ display: 'inline' }}
                    text={options?.[value]}
                />
            </Popover>
        );
    }
    return null;
});
