import { Icon, message, Spin, Tooltip } from 'antd';
import React, { useState, useEffect, CSSProperties } from 'react';
import { CustomerListStore } from '../store';
const starstyle: CSSProperties = {
    fontSize: 14,
    color: 'gray',
    cursor: 'pointer',
    position: 'relative',
    top: 2,
};
export const StarCus: React.FC<{
    customerList: CustomerListStore;
    cus: any;
    defaultStared: boolean;
    onOk?: () => void;
}> = ({ customerList, cus, defaultStared, onOk }) => {
    const [loading, setLoading] = useState(false);
    const [stared, setStared] = useState(defaultStared);
    useEffect(() => setStared(defaultStared), [defaultStared]);

    const onChange = async (next: boolean) => {
        setLoading(true);
        const close = message.loading('请稍候...');
        const ok = await customerList.star(cus, next);
        close();
        setLoading(false);

        if (ok) {
            onOk && onOk();
        } else {
            return;
        }

        // success
        if (next) {
            message.success('关注成功');
        } else {
            message.success('取消关注成功');
        }
        setStared(next);
    };
    let loadingWrapper = (ele: any) => <span>{ele}</span>;
    if (loading) {
        loadingWrapper = (ele: any) => (
            <span style={{ pointerEvents: 'none', opacity: 0.5 }}>{ele}</span>
        );
    }

    if (stared) {
        return loadingWrapper(
            <Tooltip title={'取消关注'} placement="right">
                <Icon
                    onClick={() => onChange(false)}
                    type="star"
                    style={{ ...starstyle, color: '#fadb14' }}
                    theme="filled"
                />
            </Tooltip>,
        );
    }
    return loadingWrapper(
        <Tooltip title={'关注客户'} placement="right">
            <Icon
                onClick={() => onChange(true)}
                style={{ ...starstyle }}
                type="star"
            />
        </Tooltip>,
    );
};

export const StarCusUnStarOnly: React.FC<{
    customerList: CustomerListStore;
    cus: any;
    onOk?: () => void;
}> = ({ customerList, cus, onOk }) => {
    const [loading, setLoading] = useState(false);

    const onUnStar = async () => {
        const next = false;
        setLoading(true);
        const close = message.loading('请稍候...');
        const ok = await customerList.star(cus, next);
        close();
        setLoading(false);

        if (ok) {
            onOk && onOk();
        } else {
            message.success('error occur');
            return;
        }
        // success
        message.success('取消关注成功');
    };
    let loadingWrapper = (ele: any) => <span>{ele}</span>;
    if (loading) {
        loadingWrapper = (ele: any) => (
            <span style={{ pointerEvents: 'none', opacity: 0.5 }}>{ele}</span>
        );
    }

    return loadingWrapper(
        <a onClick={() => onUnStar()} type="star">
            取消关注
        </a>,
    );
};
