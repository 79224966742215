import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AntButton, BlockLoading2 } from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { AntDrawer } from '@/components/antd/drawer';
import { defaultAuthDeco } from '@/containers/hoc';
import { ContractIncomeListStore } from '../store';
import DemoForm from './form';
import { tryRefresh } from '@/utils';

const FormDrawer: React.FC<RouteComponentProps & {
    defaultStore: ContractIncomeListStore;
    preRequiredReady: boolean;
}> = ({ defaultStore, preRequiredReady }) => {
    const { actionType, bigFormVisible, mutatingDataLoading } = defaultStore;
    const [loading, setLoading] = useState(false);

    if (!preRequiredReady) {
        return <BlockLoading2 />;
    }

    const popups = (
        <>
            <AntDrawer
                title={
                    actionType === 'create' ? '新建回款记录' : '修改回款记录'
                }
                placement={'right'}
                visible={bigFormVisible}
                onClose={() => {
                    defaultStore.resetAction();
                }}
                bodyStyle={{
                    width: 850,
                    overflow: 'scroll',
                }}
                width={850 + 'px'}
            >
                <div className="standard-drawer-form-wrapper">
                    {mutatingDataLoading ? (
                        <BlockLoading2 />
                    ) : (
                        <DemoForm defaultStore={defaultStore} />
                    )}
                    <div className="fixed-form-btns">
                        <AntButton
                            size="large"
                            type="primary"
                            loading={loading}
                            onClick={async () => {
                                setLoading(true);
                                const isSuccess = await defaultStore.dispatchAction();
                                setLoading(false);
                                if (isSuccess) {
                                    tryRefresh(['sale_income']);
                                }
                            }}
                        >
                            {actionType === 'create' ? '新建' : '修改'}
                        </AntButton>
                        <AntButton
                            size="large"
                            onClick={() => defaultStore.resetAction()}
                        >
                            取消
                        </AntButton>
                    </div>
                </div>
            </AntDrawer>
        </>
    );

    return popups;
};

const FinalFormDrawer = defaultAuthDeco(observer(FormDrawer));
export default FinalFormDrawer;
