import React, { useState, useContext, useEffect } from 'react';
import './index.scss';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { LayoutStandardCrud } from '../../../components/layouts/standard-crud/index';
import { AntButton, AntInputTextArea } from '../../../components/antd/index';
import { TableLv2 } from '@/components';
import { defaultAxios } from '@/utils';
import _ from 'lodash';
import { ICityInfoType, IStateInfoType } from '../../../stores/setting/index';
import { Modal, Select, message } from 'antd';
import TeamSelector from '@/components/common/team-selector';
import { number } from 'mathjs';

export interface IRCLDataType {
    id?: string | number;
    stateId: string | number;
    cityId: string | number;
    dimDepartId: (string | number)[] | (string | number);
    remark: string;
}
const RegionalCustomerLimit: React.FC = props => {
    const store = useContext(StoreContext);
    const settingStore = store.getSettingDefaultStore();
    const settingCHSPGStore = store.getDefaultSettingCHSPGStore();
    const { tableData: highSeasData, fetchCHSPGTableData } = settingCHSPGStore;
    const { isAreaInfoLoading, areaInfo, departInfo } = settingStore;

    const [RCLDataSource, setRCLDataSource] = useState<IRCLDataType[]>([]);
    const [RCLLoading, setRCLLoading] = useState(false);

    const [addRCLModalVis, setAddRCLModalVis] = useState(false);
    const [addRCLData, setAddRCLData] = useState<IRCLDataType>({
        stateId: '',
        cityId: '',
        dimDepartId: [],
        remark: '',
    });
    const [addRCLValidateData, setAddRCLValidateData] = useState<{
        [key: string]: {
            status: 'error' | 'success';
            msg: string;
        };
    }>({
        stateId: {
            status: 'success',
            msg: '',
        },
        dimDepartId: {
            status: 'success',
            msg: '',
        },
    });
    const fetchRCLList = async () => {
        setRCLLoading(true);
        const url = '/bff/api/rest/rcl/list';
        const [d, e] = await defaultAxios.get(url);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (Array.isArray(d.data)) {
            setRCLDataSource(d.data);
            setRCLLoading(false);
        }
        return [data, error];
    };
    const deleteCHSPGAA = async (id: string | number) => {
        setRCLLoading(true);
        const url = '/bff/api/rest/rcl/delete';
        const [d, e] = await defaultAxios.post(url, {
            id,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d.data) {
            fetchRCLList();
        }
        return [data, error];
    };
    const addCHSPGAA = async () => {
        let isError = false;
        let tempAddRCLValidateData = {
            ...addRCLValidateData,
        };
        if (!addRCLData.stateId) {
            // message.warning('输入不正确，请检查后再提交', 1);
            tempAddRCLValidateData = {
                ...tempAddRCLValidateData,
                stateId: {
                    status: 'error',
                    msg: '省份不能为空',
                },
            };
            isError = true;
        }
        if (
            !addRCLData.dimDepartId ||
            (Array.isArray(addRCLData.dimDepartId) &&
                !addRCLData.dimDepartId.length)
        ) {
            tempAddRCLValidateData = {
                ...tempAddRCLValidateData,
                dimDepartId: {
                    status: 'error',
                    msg: '部门不能为空',
                },
            };
            isError = true;
        }
        if (isError) {
            setAddRCLValidateData(tempAddRCLValidateData);
            message.warning('输入不正确，请检查后再提交', 1);
            return;
        }
        const url = '/bff/api/rest/rcl/add';
        const payloadData: any = {
            ...addRCLData,
            dimDepartIdList: addRCLData.dimDepartId,
        };
        delete payloadData.dimDepartId;
        const [d, e] = await defaultAxios.post(url, payloadData);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d.data) {
            setAddRCLModalVis(false);
            setAddRCLData({
                stateId: '',
                cityId: '',
                dimDepartId: [],
                remark: '',
            });
            fetchRCLList();
        }
        return [data, error];
    };
    useEffect(() => {
        fetchCHSPGTableData();
        fetchRCLList();
    }, []);

    const renderRCLAddModal = () => {
        const selectedState = _.find(areaInfo.stateRelation, state => {
            return state.id === addRCLData.stateId;
        });
        console.log(selectedState);
        return (
            <Modal
                title="新建区域限制"
                okText="提交"
                visible={addRCLModalVis}
                onCancel={() => {
                    setAddRCLModalVis(false);
                    setAddRCLData({
                        stateId: '',
                        cityId: '',
                        dimDepartId: [],
                        remark: '',
                    });
                    setAddRCLValidateData({
                        stateId: {
                            status: 'success',
                            msg: '',
                        },
                        dimDepartId: {
                            status: 'success',
                            msg: '',
                        },
                    });
                }}
                onOk={addCHSPGAA}
            >
                <div className="chsp-aa-add-modal-content">
                    <div>
                        <div className="chsp-aa-add-modal-items">
                            <div className="chsp-aa-add-select">
                                <label>
                                    省<span className="required-mark">*</span>
                                </label>
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    placeholder="选择省份"
                                    value={addRCLData.stateId}
                                    filterOption={(searchValue, option) => {
                                        const children =
                                            (option.props.children as string) ||
                                            '';
                                        return (
                                            children.indexOf(searchValue) != -1
                                        );
                                    }}
                                    onSelect={(value: any) => {
                                        setAddRCLData({
                                            ...addRCLData,
                                            stateId: value,
                                            cityId: '',
                                        });
                                    }}
                                    onFocus={() => {
                                        setAddRCLValidateData({
                                            ...addRCLValidateData,
                                            stateId: {
                                                status: 'success',
                                                msg: '',
                                            },
                                        });
                                    }}
                                >
                                    {_.map(areaInfo.stateRelation, state => {
                                        return (
                                            <Select.Option
                                                value={state.id}
                                                key={state.id}
                                            >
                                                {state.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>
                            {addRCLValidateData.stateId.status === 'error' && (
                                <p className="aa-error-msg">
                                    {addRCLValidateData.stateId.msg}
                                </p>
                            )}
                        </div>
                        <div className="chsp-aa-add-modal-items">
                            <div className="chsp-aa-add-select">
                                <label>市</label>
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    placeholder="选择城市"
                                    value={addRCLData.cityId}
                                    onSelect={(value: any) => {
                                        setAddRCLData({
                                            ...addRCLData,
                                            cityId: value,
                                        });
                                    }}
                                    filterOption={(searchValue, option) => {
                                        const children =
                                            (option.props.children as string) ||
                                            '';
                                        return (
                                            children.indexOf(searchValue) != -1
                                        );
                                    }}
                                    disabled={!addRCLData.stateId}
                                >
                                    {_.map(selectedState?.citys || [], city => {
                                        return (
                                            <Select.Option
                                                value={city.id}
                                                key={city.id}
                                            >
                                                {city.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="chsp-aa-add-modal-items">
                            <div className="chsp-aa-add-select">
                                <label>
                                    新建限制部门
                                    <span className="required-mark">*</span>
                                </label>
                                {/* key: 'dimDepart',
                                widgetKey: 'teamSelector',
                                teamSelectorOptions: {
                                    multiple: false,
                                    treeCheckable: false,
                                    treeData: departInfo,
                                    showCheckedStrategy: 'SHOW_PARENT',
                                    // treeCheckStrictly: true,
                                    onChange: v => {
                                        console.log(v);
                                    },
                                }, */}
                                <TeamSelector
                                    style={{ width: '200px' }}
                                    multiple={true}
                                    treeCheckable={false}
                                    treeData={departInfo}
                                    showCheckedStrategy={'SHOW_PARENT'}
                                    value={addRCLData.dimDepartId as string[]}
                                    onChange={val => {
                                        setAddRCLData({
                                            ...addRCLData,
                                            dimDepartId: val,
                                        });
                                    }}
                                    // onSelect={(value: any) => {
                                    //     const temp
                                    //     setAddRCLData({
                                    //         ...addRCLData,
                                    //         dimDepartId: addRCLData.dimDepartId.push(value),
                                    //     });
                                    // }}
                                    onFocus={() => {
                                        setAddRCLValidateData({
                                            ...addRCLValidateData,
                                            dimDepartId: {
                                                status: 'success',
                                                msg: '',
                                            },
                                        });
                                    }}
                                />
                            </div>
                            {addRCLValidateData.dimDepartId.status ===
                                'error' && (
                                <p className="aa-error-msg">
                                    {addRCLValidateData.dimDepartId.msg}
                                </p>
                            )}
                        </div>
                        <div className="chsp-aa-add-modal-items">
                            <div className="chsp-aa-add-select">
                                <label>备注</label>
                                <AntInputTextArea
                                    style={{ width: '200px' }}
                                    value={addRCLData.remark}
                                    placeholder="备注"
                                    onChange={e => {
                                        setAddRCLData({
                                            ...addRCLData,
                                            remark: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };
    const globalOptions = (
        <>
            <AntButton
                onClick={() => {
                    setAddRCLModalVis(true);
                }}
                type="primary"
            >
                新建
            </AntButton>
        </>
    );
    const title = (
        <span>
            客户新建区域限制&nbsp;
            {/* {metaLoading ? <Spin /> : null} */}
        </span>
    );
    return (
        <>
            {renderRCLAddModal()}
            <LayoutStandardCrud
                message={null}
                popups={null}
                title={title}
                multipleOps={null}
                globalOps={globalOptions}
                filters={null}
                className="customer-hsp-aa-content"
                dataTable={
                    <TableLv2<IRCLDataType>
                        loading={RCLLoading}
                        dataSource={RCLDataSource}
                        pagination={false}
                        closeRowSelection={true}
                        onAction={(actionKey, actionItem) => {
                            if (actionKey === 'delete') {
                                // deleteCHSPGAA(actionItem.id);
                                const existingCity = _.find(
                                    areaInfo.dictCity,
                                    oCity => {
                                        return oCity.id === actionItem.cityId;
                                    },
                                );
                                const existingState = _.find(
                                    areaInfo.dictState,
                                    oState => {
                                        return oState.id === actionItem.stateId;
                                    },
                                );
                                const existingDepart = _.find(
                                    departInfo,
                                    oDepart => {
                                        return _.includes(
                                            actionItem.dimDepartId as string[],
                                            oDepart.id,
                                        );
                                    },
                                );
                                Modal.confirm({
                                    title: '删除区域限制',
                                    content: (
                                        <div>
                                            该操作将删除「
                                            {existingState?.state_name || ''}
                                            {existingCity?.city_name || ''}」 「
                                            {existingDepart?.depart_name}
                                            」的客户新建区域限制，删除后，创建客户将去除该限制，请问是否删除？
                                        </div>
                                    ),
                                    onOk: () =>
                                        deleteCHSPGAA(actionItem.id as number),
                                });
                            }
                        }}
                        actions={[
                            {
                                actionKey: 'delete',
                                actionCn: '删除',
                            },
                        ]}
                        columns={[
                            {
                                title: '客户省市',
                                key: 'customer_state_city',
                                width: '150px',
                                render: (item: IRCLDataType) => {
                                    const existingCity = _.find(
                                        areaInfo.dictCity,
                                        oCity => {
                                            return oCity.id === item.cityId;
                                        },
                                    ) as ICityInfoType;
                                    const existingState = _.find(
                                        areaInfo.dictState,
                                        oState => {
                                            return oState.id === item.stateId;
                                        },
                                    ) as IStateInfoType;
                                    return `${existingState?.state_name ||
                                        ''}${`${
                                        existingCity && existingState ? '-' : ''
                                    }${existingCity?.city_name || ''}`}`;
                                },
                            },
                            {
                                title: '限制部门',
                                width: '200px',
                                key: 'dimDepartId',
                                render: (item: IRCLDataType) => {
                                    if (!item.dimDepartId) {
                                        return null;
                                    }
                                    const existingDepart = _.find(
                                        departInfo,
                                        oDepart => {
                                            return (
                                                oDepart.id === item.dimDepartId
                                            );
                                        },
                                    );
                                    return existingDepart?.depart_name;
                                },
                            },
                            {
                                title: '备注',
                                width: '200px',
                                key: 'remark',
                                render: (item: IRCLDataType) => {
                                    return item.remark;
                                },
                            },
                        ]}
                    />
                }
            />
        </>
    );
};

export default observer(RegionalCustomerLimit);
