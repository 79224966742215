import { privKey } from '@/configs/decrypt';
import JSEncrypt from 'jsencrypt';

const verify = new JSEncrypt();

verify.setPrivateKey(privKey);

export function decodePhone(str: string) {
    if (!str) return;
    if (str.length < 21) return str;
    const phone = verify.decrypt(str);
    if (phone) {
        return phone.replace(/"/g, '');
    }
    return phone;
}
