import Input, { InputProps } from 'antd/lib/input';
import React, { useEffect, useState } from 'react';
import { getCommonStatus, ICommonProps } from './common';
import _ from 'lodash';
import { loadingWrapper } from '@/components/common/block-loading';
import { defaultAxios, decodePhone as phoneDecode } from '@/utils';
import { message, Icon } from 'antd';

export type InputSecretValueType = string;
export interface IInputSecretOptions extends InputProps {
    sceneCode: string;
}

export interface IWidgetInputSecretProps<T = any> extends ICommonProps<T> {
    value: InputSecretValueType;
    options: IInputSecretOptions;
    onChange: (val: InputSecretValueType) => void;
}

export const WidgetInputSecret: React.FC<IWidgetInputSecretProps> = ({
    value,
    options,
    onChange,
    status,
    onInteract = key => void 0,
    data,
    statusExtraData,
}) => {
    const { loading, disabled } = getCommonStatus(
        status,
        data,
        statusExtraData,
    );
    const [hiddenPhone, setHiddenPhone] = useState(true);
    // const [phoneNumberInner, setPhoneNumberInner] = useState('');
    const [decodeLoading, setDecodeLoading] = useState(false);
    const wrapper = loadingWrapper(loading || decodeLoading || false);

    useEffect(() => {
        if (!value) {
            // 没有初始值，则不隐藏
            setHiddenPhone(false);
        }
    }, []);

    useEffect(() => {
        if (
            value &&
            value.length > 11 &&
            value.substr(value.length - 2) === '=='
        ) {
            // 加密字符串
            setHiddenPhone(true);
        }
    }, [value]);

    if (disabled) {
        options.disabled = true;
    } else {
        options.disabled = false;
    }

    const frontEncode = (phoneNumber: string | number) => {
        if (!phoneNumber) {
            return undefined;
        }
        let phoneStr = '';
        if (typeof phoneNumber === 'number') {
            phoneStr = String(phoneNumber);
        } else {
            phoneStr = phoneNumber;
        }
        return `${phoneStr.slice(0, 3)}${new Array(8).fill('*').join('')}`;
    };
    const decodePhone = async () => {
        const url = '/crm/crypto/phone/decrypt';
        setDecodeLoading(true);
        const [d, e] = await defaultAxios.post(url, {
            phone: value,
            scene: options.sceneCode ? `${options.sceneCode}_view` : undefined,
        });
        setDecodeLoading(false);
        const data = phoneDecode(d?.data);
        const error = e as Error | null;
        if (data) {
            onChange(data);
            // setPhoneNumberInner(data);
            setHiddenPhone(false);
            return data;
        } else {
            message.error('线路问题请联系管理员');
            return false;
        }
    };

    return wrapper(
        <Input
            {...options}
            value={hiddenPhone ? frontEncode(value) : value}
            disabled={options.disabled || hiddenPhone}
            suffix={
                hiddenPhone ? (
                    <Icon
                        onClick={() => {
                            decodePhone();
                        }}
                        type="eye"
                        style={{
                            fontSize: '14px',
                            marginRight: '2px',
                            cursor: 'pointer',
                        }}
                    />
                ) : null
            }
            onFocus={e => {
                onInteract('focus');
                onInteract('remove-validate-status');
            }}
            onBlur={e => {
                onInteract('blur');
                onInteract('validate-instantly');
            }}
            onChange={e => {
                if (e.target && !_.isNil(e.target.value)) {
                    onChange(e.target.value);
                }
            }}
        />,
    );
};
