import React, { useState, useEffect } from 'react';
import Table, { TableProps, ColumnProps } from 'antd/lib/table';
import _ from 'lodash';
import { Checkbox } from 'antd';

interface ITableCheckBoxColumns<T> extends ColumnProps<T> {
    isText?: boolean; // 是否是文本
    disabled?: boolean; // 该行是否全体禁用
    titleChecked?: boolean;
    titleIndeterminate?: boolean;
}
interface ITableCheckBoxProps<T> extends TableProps<T> {
    defaultCheckBoxValue?: T[]; // 初始值
    checkBoxValue?: T[]; // 这个参数指定后，该组件受控
    setCheckBoxValue?: (value: T[]) => void;
    tableCheckBoxColumns: ITableCheckBoxColumns<T>[];
}
const TableCheckBox: <T>(
    props: ITableCheckBoxProps<T>,
) => React.ReactElement<ITableCheckBoxProps<T>> = props => {
    const {
        defaultCheckBoxValue,
        checkBoxValue,
        setCheckBoxValue,
        tableCheckBoxColumns,
    } = props;
    const [innerCheckBoxValue, setInnerCheckBoxValue] = useState<
        {
            [key: string]: any;
        }[]
    >([]);
    const [innerColumns, setInnerColumns] = useState<
        {
            [key: string]: any;
        }[]
    >([]);
    const isNotControl = !checkBoxValue && !setCheckBoxValue;
    useEffect(() => {
        if (isNotControl && defaultCheckBoxValue) {
            setInnerCheckBoxValue(defaultCheckBoxValue);
        }
    }, [defaultCheckBoxValue]);
    useEffect(() => {
        if (tableCheckBoxColumns) {
            // 初始检查一次头部checkbox状态
            // 此刻可能数据源是空的(受控模式),非受控下必然有值
            const finalColumns = _.map(tableCheckBoxColumns, col => {
                const checkedOption = checkAllCheckBoxByKey(
                    col.dataIndex as string,
                );
                const cCol = { ...col };
                cCol.titleChecked = checkedOption.isAllCheck;
                cCol.titleIndeterminate = checkedOption.isIndeterminate;
                return cCol;
            });
            console.log(finalColumns);
            setInnerColumns(finalColumns);
        }
    }, [tableCheckBoxColumns]);
    useEffect(() => {
        if (checkBoxValue) {
            // 受控模式下检测头部checkbox状态
            const finalColumns = _.map(tableCheckBoxColumns, col => {
                const checkedOption = checkAllCheckBoxByKey(
                    col.dataIndex as string,
                );
                const cCol = { ...col };
                cCol.titleChecked = checkedOption.isAllCheck;
                cCol.titleIndeterminate = checkedOption.isIndeterminate;
                return cCol;
            });
            setInnerColumns(finalColumns);
        }
    }, [checkBoxValue]);
    const checkAllCheckBoxByKey = (key: string) => {
        let isAllCheck = true;
        let isAllNotCheck = true;
        let isIndeterminate = false;
        const originValue = isNotControl
            ? innerCheckBoxValue
            : checkBoxValue || [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < originValue.length; i++) {
            const element = originValue[i];
            if (element[key]) {
                isAllNotCheck = false;
            }
            if (!element[key]) {
                isAllCheck = false;
            }
        }
        isIndeterminate = !isAllCheck && !isAllNotCheck;
        return {
            isAllCheck,
            isAllNotCheck,
            isIndeterminate,
        };
    };

    const generateColumns = () => {
        const finalColumns = _.map(innerColumns, (col, colIdx) => {
            if (col.isText) {
                return col;
            } else {
                if (!col.dataIndex) {
                    throw new Error('must use dataIndex!');
                }
                if (typeof col.title !== 'string') {
                    throw new Error('「title」must be string!');
                }
                return {
                    ...col,
                    title: (options: any) => {
                        return (
                            <div>
                                {col.title}
                                <Checkbox
                                    style={{ marginLeft: '5px' }}
                                    checked={col.titleChecked || false}
                                    indeterminate={
                                        col.titleIndeterminate || false
                                    }
                                    disabled={col.disabled}
                                    onChange={e => {
                                        const val = e.target.checked;
                                        const cInnerColumns = [...innerColumns];
                                        if (isNotControl) {
                                            // 非受控模式下跟随change而改变头部checkbox状态
                                            // 受控模式下则是根据value的变化而每次演算
                                            cInnerColumns[
                                                colIdx
                                            ].titleChecked = val;
                                            cInnerColumns[
                                                colIdx
                                            ].titleIndeterminate = false;
                                            setInnerColumns(cInnerColumns);
                                        }
                                        if (isNotControl) {
                                            const cInnerValueArray = _.map(
                                                innerCheckBoxValue,
                                                items => {
                                                    const cItems = { ...items };
                                                    cItems[
                                                        col.dataIndex as string
                                                    ] = val;
                                                    return cItems;
                                                },
                                            );
                                            console.log(cInnerValueArray);
                                            setInnerCheckBoxValue(
                                                cInnerValueArray,
                                            );
                                        } else {
                                            if (checkBoxValue) {
                                                const cOuterValueArray = _.map(
                                                    checkBoxValue,
                                                    (items: any) => {
                                                        const cItems = {
                                                            ...items,
                                                        };
                                                        cItems[
                                                            col.dataIndex as string
                                                        ] = val;
                                                        return cItems;
                                                    },
                                                );
                                                if (setCheckBoxValue) {
                                                    setCheckBoxValue(
                                                        cOuterValueArray,
                                                    );
                                                } else {
                                                    throw new Error(
                                                        'setCheckBoxValue is not defined',
                                                    );
                                                }
                                            } else {
                                                throw new Error(
                                                    'checkBoxValue is not defined',
                                                );
                                            }
                                        }
                                    }}
                                />
                            </div>
                        );
                    },
                    render: (v: any, r: any, i: number) => {
                        return (
                            <Checkbox
                                checked={v}
                                disabled={col.disabled}
                                onChange={e => {
                                    console.log(e);
                                    const val = e.target.checked;
                                    if (isNotControl) {
                                        // clone
                                        const cInnerValueArray = _.map(
                                            innerCheckBoxValue,
                                            v => {
                                                return { ...v };
                                            },
                                        );
                                        cInnerValueArray[i][
                                            col.dataIndex as string
                                        ] = val;
                                        console.log(cInnerValueArray);
                                        setInnerCheckBoxValue(cInnerValueArray);
                                    } else {
                                        if (checkBoxValue) {
                                            // clone
                                            const cOuterValueArray = _.map(
                                                checkBoxValue,
                                                v => {
                                                    return { ...v };
                                                },
                                            ) as any[];
                                            cOuterValueArray[i][
                                                col.dataIndex as string
                                            ] = val;
                                            if (setCheckBoxValue) {
                                                setCheckBoxValue(
                                                    cOuterValueArray,
                                                );
                                            } else {
                                                throw new Error(
                                                    'setCheckBoxValue is not defined',
                                                );
                                            }
                                        } else {
                                            throw new Error(
                                                'checkBoxValue is not defined',
                                            );
                                        }
                                    }
                                    if (isNotControl) {
                                        const cInnerColumns = [...innerColumns];
                                        const checkedOption = checkAllCheckBoxByKey(
                                            col.dataIndex,
                                        );
                                        console.log(checkedOption);
                                        cInnerColumns[colIdx].titleChecked =
                                            checkedOption.isAllCheck;
                                        cInnerColumns[
                                            colIdx
                                        ].titleIndeterminate =
                                            checkedOption.isIndeterminate;
                                        setInnerColumns(cInnerColumns);
                                    }
                                }}
                            />
                        );
                    },
                };
            }
        });
        return finalColumns;
    };
    console.log(isNotControl, checkBoxValue);
    return (
        <Table<any>
            pagination={false}
            columns={generateColumns()}
            dataSource={isNotControl ? innerCheckBoxValue : checkBoxValue}
            {...props}
        />
    );
};
export default TableCheckBox;
