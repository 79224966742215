import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Tree, Input, Icon, Radio, Modal } from 'antd';
import { TreeProps } from 'antd/lib/tree';
import { TreeNodeNormal } from 'antd/lib/tree/Tree';
import _ from 'lodash';
import { utimes } from 'fs';
import { arrToJsonPrimary } from '@/utils';
import './leaf-only.scss';
import { InputProps } from 'antd/lib/input';
const { TreeNode } = Tree;

export interface ISearchAbleTreeProps extends TreeProps {
    selectedKeys?: string[];
    onSelectedChange?: (nextSelected: string[]) => void;
    inputProps?: InputProps;
}

export interface IMyTreeNodeNormal extends TreeNodeNormal {
    readonly?: boolean;
}

// 递归渲染
const renderTreeNodes = (
    data: IMyTreeNodeNormal[] | undefined,
    visibleKeysMap: { [key: string]: boolean },
    selectedKeysMap: { [key: string]: boolean },
) => {
    const allVisible = _.isEmpty(visibleKeysMap);
    const isVisible = (key: string) => {
        return allVisible || visibleKeysMap[key];
    };
    return data
        ? data.map(item => {
              if (item.children) {
                  return (
                      <TreeNode
                          className={
                              isVisible(item.key) ? '' : 'invisible-tree-node'
                          }
                          title={item.title}
                          key={item.key}
                          dataRef={item}
                          disabled={true}
                      >
                          {
                              renderTreeNodes(
                                  item.children,
                                  visibleKeysMap,
                                  selectedKeysMap,
                              ) as any
                          }
                      </TreeNode>
                  );
              }
              const { key } = item;
              const selected = selectedKeysMap[key];
              return (
                  <TreeNode
                      className={
                          isVisible(item.key) ? '' : 'invisible-tree-node'
                      }
                      disabled={!!item.readonly}
                      title={<Radio checked={selected}>{item.title}</Radio>}
                      key={item.key}
                      dataRef={item}
                  />
              );
          })
        : null;
};

const getAllKeysShouldExpands = (data: TreeNodeNormal[] | undefined) => {
    const keys: string[] = [];
    if (!data) {
        return keys;
    }
    const traverse = (d: TreeNodeNormal[]) => {
        for (const item of d) {
            if (item.children) {
                keys.push(item.key);
                traverse(item.children);
            }
        }
    };
    traverse(data);
    return keys;
};

const getAllKeysShouldExpandsBySearch = (
    data: TreeNodeNormal[] | undefined,
    search: string,
) => {
    const result: string[] = [];
    if (search === '' || data === undefined) {
        return result;
    }
    const traverse = (d: TreeNodeNormal[]): string[] => {
        const keys: string[] = [];
        for (const item of d) {
            if (item.children) {
                const innerKeys = traverse(item.children);
                if (innerKeys.length > 0) {
                    keys.push(...innerKeys);
                    keys.push(item.key);
                }
            } else if (_.isString(item.title)) {
                const matched = item.title.indexOf(search) > -1;
                if (matched) {
                    keys.push(item.key);
                }
            }
        }
        return keys;
    };
    result.push(...traverse(data));
    return result;
};

export const SearchAbleTreeLeafOnly: React.FC<ISearchAbleTreeProps> = props => {
    const {
        treeData,
        selectedKeys = [],
        onSelectedChange = () => void 0,
        inputProps = {},
    } = props;

    const selectedKeysMap = arrToJsonPrimary(selectedKeys);

    const [search, _setSearch] = useState('');
    const setSearch = _.debounce(_setSearch, 200);

    const [userExpandKeys, setUserExpandKeys] = useState<string[]>(
        getAllKeysShouldExpands(treeData),
    );
    const [searchExpandKeys, setSearchExpandKeys] = useState<string[]>(
        getAllKeysShouldExpandsBySearch(treeData, _.trim(search)),
    );
    useLayoutEffect(() => {
        const nextSearchExpandKeys = getAllKeysShouldExpandsBySearch(
            treeData,
            search,
        );
        setSearchExpandKeys(nextSearchExpandKeys);
    }, [treeData, search]);
    const expandedKeys = searchExpandKeys.length
        ? searchExpandKeys
        : userExpandKeys;
    // 空的话就是全部visible
    const visibleKeys = searchExpandKeys.length ? searchExpandKeys : [];
    const visibleKeysMap = arrToJsonPrimary(visibleKeys);

    return (
        <div className="component-searchable-tree-leafonly">
            <div className="search">
                <Input
                    allowClear
                    onChange={e => {
                        if (e.target) {
                            setSearch(e.target.value);
                        }
                    }}
                    suffix={<Icon type="search" />}
                    {...inputProps}
                ></Input>
            </div>
            <div className="tree">
                <Tree
                    expandedKeys={expandedKeys}
                    onExpand={nextExpandedKeys => {
                        setUserExpandKeys(nextExpandedKeys);
                    }}
                    checkedKeys={selectedKeys}
                    defaultCheckedKeys={[]}
                    onSelect={keys => {
                        if (!_.isArray(keys)) {
                            keys = [keys];
                        }
                        onSelectedChange(keys);
                    }}
                    {...props}
                >
                    {renderTreeNodes(treeData, visibleKeysMap, selectedKeysMap)}
                </Tree>
            </div>
        </div>
    );
};

// 非受控模式
export const SearchAbleTreeLeafOnlyModal: React.FC<ISearchAbleTreeProps & {
    visible: boolean;
    onVisibleChange: (nextVisible: boolean) => void;
}> = props => {
    const {
        selectedKeys: inputSelectedKeys,
        onSelectedChange: inputOnSelectedChange,
        inputProps,
        visible,
        onVisibleChange,
    } = props;
    // 半受控，恶心又熟悉
    const [selectedKeys, setSelectedKeys] = useState(inputSelectedKeys || []);
    useEffect(() => {
        setSelectedKeys(inputSelectedKeys || []);
    }, [(inputSelectedKeys || '').toString(), visible]);

    const nextProps = {
        ...props,
        selectedKeys,
        onSelectedChange: setSelectedKeys,
        inputProps,
    };
    return (
        <Modal
            onOk={() => {
                inputOnSelectedChange &&
                    inputOnSelectedChange([...selectedKeys]);
            }}
            onCancel={() => {
                onVisibleChange(false);
            }}
            visible={visible}
            width={400}
            title={'选择产品目录'}
            zIndex={10001}
        >
            <SearchAbleTreeLeafOnly {...nextProps} />
        </Modal>
    );
};
