import { RouteComponentProps } from '@reach/router';
import React, { useState } from 'react';
import { Button, Table } from 'antd';
import { observer } from '@/containers/hoc';
import { AntTable } from '@/components';
import { dataSource, colunms } from './data';
import _ from 'lodash';

const TableTest: React.FC<RouteComponentProps> = ({}) => {
    const [ds, setDS] = useState(dataSource);
    const [loading, setLoading] = useState(false);
    return (
        <div>
            <Button
                onClick={() => {
                    setLoading(true);
                    setTimeout(() => {
                        setDS(_.cloneDeep(dataSource));
                        setLoading(false);
                    });
                }}
            >
                change
            </Button>
            <Table
                dataSource={ds}
                columns={colunms}
                loading={loading}
                scroll={{
                    x: 'max-content',
                    y: 'max-content',
                }}
                pagination={false}
            />
        </div>
    );
};

export default observer(TableTest);
