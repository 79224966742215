import { RouteComponentProps } from '@reach/router';
import React, { useState } from 'react';
import { Input, Calendar } from 'antd';
import { observer } from '@/containers/hoc';
import { CCBtn } from '@/components';

const destroyElement = () => {
    const md = window.document.getElementById('my-div');
    if (md) {
        window.document.getElementById('app')?.removeChild(md);
    }
};

const Apppp = () => {
    const [elementShown, updateElement] = React.useState(true);

    return (
        <div id="app">
            <button onClick={() => destroyElement()}>
                Delete element via querySelector
            </button>
            <button onClick={() => updateElement(!elementShown)}>
                Update element and state
            </button>
            {elementShown ? <div id="my-div">I am the element</div> : null}
        </div>
    );
};

// const Appppp = () => {
//     return (
//         <Calendar />
//     );
// };

const CallDemo: React.FC<RouteComponentProps> = ({}) => {
    const [phoneNum, setPhoneNum] = useState('18741133436');
    return (
        <div>
            <Apppp />
            <Input
                value={phoneNum}
                onChange={e => setPhoneNum(e.target.value)}
            />
            {/* <CCBtn phoneNum={phoneNum} /> */}
            {/* <Appppp /> */}
        </div>
    );
};

export default observer(CallDemo);
