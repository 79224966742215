import React, { useEffect, memo } from 'react';
import moment from 'moment';
import { arrToJson } from '@/utils';
import _ from 'lodash';

export const DefaultGender: React.FC<{ args: [string[]] }> = memo(
    ({ args: [genders] }) => {
        const map = arrToJson([{ name: '', value: '' }], 'value');
        return (
            <span>
                {genders
                    .map(genderValue => {
                        const option = map[genderValue];
                        if (!_.isNil(option)) {
                            return option.name;
                        }
                        return genderValue;
                    })
                    .filter(Boolean)
                    .join(', ')}
            </span>
        );
    },
);
