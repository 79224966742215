/*
 * @Author: your name
 * @Date: 2020-12-09 11:01:13
 * @LastEditTime: 2020-12-16 17:34:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /maimai_crm/src/pages/setting/customer-hsp-grouping/index.js
 */

import * as React from 'react';
import { useState } from 'react';
import './index.scss';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { StoreContext } from '@/stores';
import { TableLv2 } from '@/components';
import { TCellsSelector } from '@/components';
import { LayoutFilterTable } from '../../../components/layouts/filterTable/index';
import { LayoutStandardCrud } from '../../../components/layouts/standard-crud/index';
import { AntButton } from '@/components';
import RuleSettingModal from './rule-setting-modal';
import MemberSettingModal from './member-setting-modal';
import ActionLogModal from './log-chspg-modal';
import AddCHSPGModal from './add-chspg-modal';
import { useCallback, useEffect } from 'react';
import { useNet } from '@/utils';
import moment from 'moment';
import { Modal, Input } from 'antd';
import { message } from 'antd';
import { Icon } from 'antd';
import Popconfirm from 'antd/lib/popconfirm';
export interface IHSPGDataTypes {
    description: string;
    createUser: string;
    createTime: string;
    id: string | number;
}
// 配置中心-客户公海池分组
const CustomerHSPGrouping: React.FC<RouteComponentProps> = () => {
    const store = useContext(StoreContext);

    const [ruleSettingModalVis, setRuleSettingModalVis] = useState(false); // 是否展示规则设置modal
    const [memberSettingModalVis, setMemberSettingModalVis] = useState(false); // 是否展示成员设置modal
    const [addModalVis, setAddModalVis] = useState(false); // 展示添加的modal
    const [actionLogVis, setActionLogVis] = useState(false); // 展示活动记录的modal
    const [popVisKey, setPopVisKey] = useState(0); // 标志第几个popover会显示，0则代表没有
    const [changeNameItems, setChangeNameItems] = useState<{
        [key: string]: any;
    }>({}); // 修改公海池对象

    const [selectedRuleItems, setSelectedRuleItems] = useState<
        IHSPGDataTypes
    >();
    const [selectedMemberItems, setSelectedMemberItems] = useState<
        IHSPGDataTypes
    >();
    const [selectedActionLogItems, setSelectedActionLogItems] = useState<
        IHSPGDataTypes
    >();

    const SettingHSPGStore = store.getDefaultSettingCHSPGStore();
    const {
        tableData,
        setCHSPGPoolSetting,
        deleteCHSPGPool,
        fetchCHSPGTableData,
        copyCHSPG,
    } = SettingHSPGStore;
    const listFetch = useCallback(fetchCHSPGTableData, []);
    const [error, loading, reload] = useNet(listFetch);
    const HSPGCellSelector: TCellsSelector<IHSPGDataTypes> = [
        {
            colKey: 'description',
            cell: 'Echo',
            inputs: ['description'],
        },
        {
            colKey: 'createTime',
            cell: 'DefaultMoment',
            inputs: ['createTime'],
        },
    ];
    const globalOptions = (
        <>
            <AntButton onClick={() => setAddModalVis(true)} type="primary">
                新建分组
            </AntButton>
        </>
    );
    const title = (
        <span>
            客户公海池&nbsp;
            {/* {metaLoading ? <Spin /> : null} */}
        </span>
    );
    const onActionDispatch = async (
        actionKey: string,
        data: IHSPGDataTypes,
    ) => {
        switch (actionKey) {
            case 'copy':
                const [d, e] = await copyCHSPG(data.id);
                if (d) {
                    reload();
                    message.success('复制成功', 1);
                } else {
                    message.error('复制失败', 1);
                }
                break;
            case 'userSetting':
                setSelectedMemberItems(data);
                setMemberSettingModalVis(true);
                break;
            case 'ruleSetting':
                setSelectedRuleItems(data);
                setRuleSettingModalVis(true);
                break;
            case 'delete':
                Modal.confirm({
                    title: '提示',
                    content: `请确认是否要删除${data.description}`,
                    onOk: async () => {
                        const [d, e] = await deleteCHSPGPool(data.id);
                        if (d) {
                            message.success('删除成功', 1);
                            reload();
                        } else {
                            console.log(e);
                            message.error('删除失败', 1);
                        }
                    },
                });
                break;
            case 'actionLog':
                setSelectedActionLogItems(data);
                setActionLogVis(true);
                break;
            default:
                break;
        }
    };
    return (
        <div className="crm-setting-chspg">
            <AddCHSPGModal
                modalVis={addModalVis}
                setModalVis={setAddModalVis}
                reloadTable={reload}
            />
            <RuleSettingModal
                visible={ruleSettingModalVis}
                setVis={setRuleSettingModalVis}
                selectedItems={selectedRuleItems}
                reloadTable={reload}
            />
            <MemberSettingModal
                visible={memberSettingModalVis}
                setVis={setMemberSettingModalVis}
                selectedItems={selectedMemberItems}
                reloadTable={reload}
            />
            <ActionLogModal
                visible={actionLogVis}
                setVis={setActionLogVis}
                selectedItems={selectedActionLogItems}
            />
            <LayoutStandardCrud
                message={null}
                popups={null}
                title={title}
                multipleOps={null}
                globalOps={globalOptions}
                filters={null}
                dataTable={
                    <TableLv2<IHSPGDataTypes>
                        loading={loading}
                        dataSource={tableData}
                        onSorterChange={
                            SettingHSPGStore.defaultSorter.setSorter
                        }
                        columns={[
                            {
                                key: 'name',
                                title: '分组名称',
                                width: 150,
                                render: item => {
                                    return (
                                        <span>
                                            {item?.description}
                                            <Popconfirm
                                                overlayClassName={
                                                    'chspg-popconfirm-name'
                                                }
                                                visible={popVisKey === item?.id}
                                                icon={
                                                    <span>
                                                        修改公海池名称：
                                                    </span>
                                                }
                                                title={
                                                    <div>
                                                        <Input
                                                            placeholder="输入公海池名称"
                                                            value={
                                                                changeNameItems?.description
                                                            }
                                                            onChange={e => {
                                                                setChangeNameItems(
                                                                    {
                                                                        ...changeNameItems,
                                                                        description:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    },
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                onConfirm={async () => {
                                                    if (
                                                        !changeNameItems?.description
                                                    ) {
                                                        message.error(
                                                            '公海池名称不能为空',
                                                        );
                                                        return false;
                                                    }
                                                    const tempDescriptionItem = {
                                                        description:
                                                            changeNameItems.description,
                                                    };
                                                    const [
                                                        d,
                                                        e,
                                                    ] = await SettingHSPGStore.setCHSPGPoolSetting(
                                                        changeNameItems.id,
                                                        tempDescriptionItem,
                                                    );
                                                    if (!d.msg) {
                                                        message.success(
                                                            '修改成功',
                                                            1,
                                                        );
                                                        reload();
                                                        setPopVisKey(0);
                                                    } else {
                                                        message.error(
                                                            d.msg || '修改失败',
                                                            1,
                                                        );
                                                    }
                                                }}
                                                onCancel={() => {
                                                    setPopVisKey(0);
                                                    setChangeNameItems({});
                                                }}
                                            >
                                                <Icon
                                                    type="edit"
                                                    style={{
                                                        marginLeft: '5px',
                                                        color: '#0052ff',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setPopVisKey(item?.id);
                                                        setChangeNameItems(
                                                            item,
                                                        );
                                                    }}
                                                />
                                            </Popconfirm>
                                        </span>
                                    );
                                },
                            },
                            {
                                key: 'creator',
                                title: '创建人',
                                dataIndex: 'createUser',
                                width: 100,
                            },
                            {
                                key: 'creat_time',
                                title: '创建时间',
                                dataIndex: 'createTime',
                                width: 150,
                                render: v => {
                                    return moment(v).format('YYYY-MM-DD HH:mm');
                                },
                            },
                            {
                                key: 'recycle_alert',
                                title: '系统提前提醒天数',
                                dataIndex: 'recycleAlert',
                                width: 100,
                                render: v => {
                                    if (Array.isArray(v)) {
                                        return v.join(',');
                                    } else {
                                        return v;
                                    }
                                },
                            },
                            {
                                key: 'recycle_deferred',
                                title: '延期天数',
                                dataIndex: 'recycleDeferred',
                                width: 100,
                            },
                            {
                                key: 'recycle_lock_end_time',
                                title: '锁定期截止时间',
                                dataIndex: 'recycleLockEndTime',
                                width: 150,
                                // render: v => {
                                //     return v
                                //         ? moment(v).format('YYYY-MM-DD HH:mm')
                                //         : '';
                                // },
                            },
                            {
                                key: 'recycle_time',
                                title: '回收时间',
                                dataIndex: 'recycleTime',
                                width: 150,
                                // render: v => {
                                //     return v
                                //         ? moment(v).format('YYYY-MM-DD HH:mm')
                                //         : '';
                                // },
                            },
                            {
                                key: 'isHighValue',
                                title: '是否高价值',
                                dataIndex: 'isHighValue',
                                width: 100,
                                render: v => {
                                    return String(v) === '6' ? '是' : '否';
                                },
                            },
                        ]}
                        pagination={false}
                        cellsSelector={HSPGCellSelector}
                        closeRowSelection={true}
                        onAction={onActionDispatch}
                        actionsWidth={'350px'}
                        isFixedActions={false}
                        actions={[
                            {
                                actionKey: 'copy',
                                actionCn: '复制',
                                popconfirm: true,
                                popcomfirmConfig: {
                                    title: '是否要复制该公海池？',
                                },
                            },
                            {
                                actionKey: 'userSetting',
                                actionCn: '人员设置',
                            },
                            {
                                actionKey: 'ruleSetting',
                                actionCn: '规则设置',
                            },
                            {
                                actionKey: 'delete',
                                actionCn: '删除',
                            },
                            {
                                actionKey: 'actionLog',
                                actionCn: '操作日志',
                            },
                        ]}
                    />
                }
            />
        </div>
    );
};

export default observer(CustomerHSPGrouping);
