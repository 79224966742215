import React, { useEffect, useRef, useState, useCallback, memo } from 'react';

import { ITableLv1Props, TableLv1, ICommonTableColumnProps } from './level1';
import _ from 'lodash';
import { ICommonStatus, getCommonStatus } from '../form/widgets';
import { Divider, Dropdown, Icon, Menu, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import Popconfirm, { PopconfirmProps } from 'antd/lib/popconfirm';
import { PreCheckA } from '../pre-check-a';
import { ICheckBody } from '@/utils/hooks/auth-precheck';

export interface ITableActionItem<T = any, E = any> {
    actionKey: string;
    actionCn: string;
    status?: ICommonStatus<T, E>;
    popconfirm?: boolean;
    popcomfirmConfig?: PopconfirmProps;
    isVisible?: (data: T, actionKey: string) => boolean;
    render?: (
        data: T,
        original: React.ReactElement | null,
    ) => React.ReactElement | null;
    force?: boolean; // 强制按钮，不过权限
}

export interface ITableLv2Props<T, E = any> extends ITableLv1Props<T> {
    // 选中回调
    onSelectedDataChange?: (data: T[]) => void;
    selectedItems?: T[];
    // 不允许选中的条目，也就是不能多选
    disabledSelectionItems?: {
        [id: string]: true;
    };
    // actionsWidth存在的时候，关闭自适应
    actionsWidth?: number | string;
    needMoreNum?: number;
    // actions是否fixed
    isFixedActions?: boolean;
    // 表单条目动作标准
    actions?: Array<ITableActionItem>;
    afterOpSlot?: React.ReactElement;

    // tableCode
    tableCode?: string;
    withDataAuthCodeCheck?: boolean;
    sceneCode?: string; // 场景code
}

const genObjectCacheGetter = () => {
    const cache: { [key: string]: any } = {};
    return () => {
        return {
            set(key: string, v: any) {
                cache[key] = v;
            },
            get(key: string) {
                return cache[key];
            },
            delete(key: string) {
                return delete cache[key];
            },
        };
    };
};

const dataAuthCodeMap: { [actionKey: string]: string } = {
    update: 'EDIT',
    delete: 'DEL',
    transform: 'TRANSFER',
};

export const TableLv2: <T>(props: ITableLv2Props<T>) => React.ReactElement<ITableLv1Props<T>> | null = memo(props => {
    // diff keys:
    // columns, pagination, disabledSelectionItems, actions, onAction
    // const [start] = useState({v: Date.now()})
    // useEffect(() => {
    //     console.log('mount time 2.2: ', Date.now() - start.v)
    //     start.v = Date.now()
    // }, [props.dataSource]);

    const {
        dataSource,
        selectedItems = [],
        onSelectedDataChange,
        disabledSelectionItems = {},
        columns = [],
        closeRowSelection = false,
        isFixedActions = true,
        actionsWidth,
        tableCode,
        withDataAuthCodeCheck = true,
        sceneCode,
    } = props;

    /**
     * antd的多选功能需要一个key，这里就把id作为这个key
     */
    const selectedRowKeys = _.map(
        _.filter(selectedItems, Boolean),
        (item: any) => {
            const key = item.id;
            if (disabledSelectionItems[key]) {
                return undefined;
            }
            return key;
        },
    );
    const dataSourceMap: { [key: string]: any } = {};
    const dataSourceWithKey = _.map(dataSource, (datum: any) => {
        dataSourceMap[datum.id as string] = datum;
        return {
            key: datum.id,
            ...datum,
        };
    });

    const cacheGetter = useCallback(genObjectCacheGetter(), []);
    const cache = cacheGetter();
    const rowSelection = {
        selectedRowKeys,
        fixed: true,
        getCheckboxProps: (record: any) => {
            const key = record.id;
            if (!key || disabledSelectionItems[key]) {
                return { disabled: true };
            }
            return {};
        },
        onChange: (nextkeys: any[]) => {
            if (!_.isNil(onSelectedDataChange)) {
                const removedKeys = _.difference(selectedRowKeys, nextkeys);
                const addedKeys = nextkeys;
                _.map(addedKeys, key => {
                    dataSourceMap[key] && cache.set(key, dataSourceMap[key]);
                });
                _.map(removedKeys, cache.delete);
                onSelectedDataChange(_.map(nextkeys, cache.get) as any);
            }
        },
    };

    // 加入标准的操作列

    const { actions = [], onAction = () => { } } = props;
    const needMoreNum = props.needMoreNum || 3;
    const width = Math.max(2, Math.min(needMoreNum, actions.length)) * 51;
    const nextColumns: typeof columns = [...columns]?.map(item => {
        item.key = item.key || item.dataIndex;
        return item;
    });
    const nextActionWidth: number =
        actions.length > 0 ? (actionsWidth ? Number(actionsWidth) : width) : 38;

    if ((_.isArray(actions) && actions.length > 0) || !!props.afterOpSlot) {
        nextColumns.push({
            key: '----------action',
            title: () => (
                <span>
                    <span>{actions.length > 0 ? '操作' : ''}</span>
                    {props.afterOpSlot && (
                        <span className="after-op-slot">
                            {props.afterOpSlot}
                        </span>
                    )}
                </span>
            ),
            fixed: isFixedActions ? 'right' : undefined,
            width: nextActionWidth,
            render(_, item) {
                // 20211012
                // 根据每一条数据的状态，展示不同的actions
                const nextActions: any[] = actions
                    .map(actionItem => {
                        if (actionItem.isVisible) {
                            const visible = actionItem.isVisible(
                                item,
                                actionItem.actionKey,
                            );
                            if (visible) {
                                return actionItem;
                            }
                            return null;
                        }
                        return actionItem;
                    })
                    .filter(Boolean);

                const needMore = nextActions.length > needMoreNum;
                const outsideActions = needMore
                    ? nextActions.slice(0, needMoreNum - 1)
                    : nextActions;
                const innerActions = needMore
                    ? nextActions.slice(needMoreNum - 1)
                    : [];
                return actionsWidth ? (
                    <span>
                        {nextActions.map((actionItem, index) => {
                            const {
                                actionCn,
                                actionKey,
                                popconfirm,
                                popcomfirmConfig = { title: 'popconfirm' },
                                status,
                                render,
                            } = actionItem;
                            const finalStatus = getCommonStatus(
                                status,
                                item,
                                {},
                            );
                            if (finalStatus.hidden) {
                                return null;
                            }
                            let checkBody: ICheckBody | undefined = undefined;
                            if (
                                tableCode !== undefined &&
                                dataAuthCodeMap[actionKey] !== undefined &&
                                !!(item as any).id &&
                                withDataAuthCodeCheck
                            ) {
                                checkBody = {
                                    tableCode,
                                    body: {
                                        dataAuthCode:
                                            dataAuthCodeMap[actionKey],
                                        ids: [(item as any).id].filter(Boolean),
                                    },
                                };
                            }
                            const original = (
                                <>
                                    {popconfirm ? (
                                        <Popconfirm
                                            disabled={finalStatus.disabled}
                                            {...popcomfirmConfig}
                                            onConfirm={e => {
                                                if (finalStatus.disabled) {
                                                    return;
                                                }
                                                if (
                                                    popcomfirmConfig.onConfirm
                                                ) {
                                                    popcomfirmConfig.onConfirm(
                                                        e,
                                                    );
                                                }
                                                onAction(actionKey, item);
                                            }}
                                        >
                                            <a
                                                className={
                                                    finalStatus.disabled
                                                        ? 'disabled'
                                                        : ''
                                                }
                                            >
                                                {actionCn}
                                            </a>
                                        </Popconfirm>
                                    ) : (
                                        <PreCheckA
                                            checkBody={checkBody}
                                            onClick={() => {
                                                if (finalStatus.disabled) {
                                                    return;
                                                }
                                                onAction(actionKey, item);
                                            }}
                                            className={
                                                finalStatus.disabled
                                                    ? 'disabled'
                                                    : ''
                                            }
                                        >
                                            {actionCn}
                                        </PreCheckA>
                                    )}
                                </>
                            );
                            if (render) {
                                return (
                                    <>
                                        {index !== 0 ? (
                                            <Divider type="vertical" />
                                        ) : null}
                                        {render(item, original)}
                                    </>
                                );
                            }
                            return (
                                <>
                                    {index !== 0 ? (
                                        <Divider type="vertical" />
                                    ) : null}
                                    {original}
                                </>
                            );
                        })}
                    </span>
                ) : (
                    <span>
                        {outsideActions.map((actionItem, index) => {
                            const {
                                actionCn,
                                actionKey,
                                popconfirm,
                                popcomfirmConfig = { title: 'popconfirm' },
                                status,
                                render,
                            } = actionItem;
                            const finalStatus = getCommonStatus(
                                status,
                                item,
                                {},
                            );
                            let checkBody: ICheckBody | undefined = undefined;
                            if (
                                tableCode !== undefined &&
                                dataAuthCodeMap[actionKey] !== undefined &&
                                !!(item as any).id &&
                                withDataAuthCodeCheck
                            ) {
                                checkBody = {
                                    tableCode,
                                    body: {
                                        dataAuthCode:
                                            dataAuthCodeMap[actionKey],
                                        ids: [(item as any).id].filter(Boolean),
                                    },
                                };
                            }
                            const original = (
                                <>
                                    {popconfirm ? (
                                        <Popconfirm
                                            disabled={finalStatus.disabled}
                                            {...popcomfirmConfig}
                                            onConfirm={e => {
                                                if (finalStatus.disabled) {
                                                    return;
                                                }
                                                if (
                                                    popcomfirmConfig.onConfirm
                                                ) {
                                                    popcomfirmConfig.onConfirm(
                                                        e,
                                                    );
                                                }
                                                onAction(actionKey, item);
                                            }}
                                        >
                                            <a
                                                className={
                                                    finalStatus.disabled
                                                        ? 'disabled'
                                                        : ''
                                                }
                                            >
                                                {actionCn}
                                            </a>
                                        </Popconfirm>
                                    ) : (
                                        <PreCheckA
                                            checkBody={checkBody}
                                            onClick={() => {
                                                if (finalStatus.disabled) {
                                                    return;
                                                }
                                                onAction(actionKey, item);
                                            }}
                                            className={
                                                finalStatus.disabled
                                                    ? 'disabled'
                                                    : ''
                                            }
                                        >
                                            {actionCn}
                                        </PreCheckA>
                                    )}
                                </>
                            );

                            if (render) {
                                return (
                                    <>
                                        {index !== 0 ? (
                                            <Divider type="vertical" />
                                        ) : null}
                                        {render(item, original)}
                                    </>
                                );
                            }
                            return (
                                <>
                                    {index !== 0 ? (
                                        <Divider type="vertical" />
                                    ) : null}
                                    {original}
                                </>
                            );
                        })}
                        {needMore && (
                            <>
                                <Divider type="vertical" />
                                <Dropdown
                                    overlay={
                                        <Menu
                                            onClick={e => {
                                                onAction(e.key, item);
                                            }}
                                            style={{ width: 100 }}
                                        >
                                            {innerActions.map(
                                                (actionItemInner, index) => {
                                                    const {
                                                        actionCn: actionCnInner,
                                                        actionKey: actionKeyInner,
                                                    } = actionItemInner;
                                                    return (
                                                        <Menu.Item
                                                            key={actionKeyInner}
                                                        >
                                                            {index !== 0 && (
                                                                <Menu.Divider />
                                                            )}
                                                            {actionCnInner}
                                                        </Menu.Item>
                                                    );
                                                },
                                            )}
                                        </Menu>
                                    }
                                >
                                    <Icon type="ellipsis" />
                                </Dropdown>
                            </>
                        )}
                    </span>
                );
            },
        });
    }

    return (
        <TableLv1
            {...props}
            className={props.className}
            dataSource={dataSourceWithKey}
            rowSelection={closeRowSelection ? undefined : rowSelection}
            columns={nextColumns}
            actionWidth={nextActionWidth}
            sceneCode={sceneCode}
        />
    );
});
