import React from 'react';
import { RouteComponentProps } from '@reach/router';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import './index.scss';
import ProcessCatListAnyForTab from '@/pages/sales-follow-up/process/list/category-any-for-tab.tsx';

const DemoList: React.FC<RouteComponentProps & {
    type: string;
}> = () => {
    return (
        <ProcessCatListAnyForTab processKey="contractinvoice" title="发票" />
    );
};

const FinalDemoList = observer(DemoList);
export default FinalDemoList;
