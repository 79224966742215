import { AntButton, TableLv2 } from '@/components';
import { StoreContext } from '@/stores';
import { useNet, OPMEE } from '@/utils';
import { Checkbox, Input, Select, message, Popover } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LayoutStandardCrud } from '../../../../components/layouts/standard-crud/index';
import { IOPMTableData, IOPMTableSearchData } from '../store';
import './index.scss';
const Option = Select.Option;

const rankIdMap: any = {
    0: '其他',
    1: 'KA销售',
    2: '精英销售',
    3: '小组TL',
    4: '城市负责人',
    5: '区域负责人',
    6: '商业化负责人',
    7: 'DKA销售',
    8: 'SKA销售',
    9: '小组长',
    10: '总部运营',
    11: '区域运营BP',
    12: '清洗客服',
};
interface IOPMTable {
    setSelectedTableItem: (data?: IOPMTableData) => void;
    selectedDepart: any;
}
const OPMTable: React.FC<IOPMTable> = props => {
    const { setSelectedTableItem, selectedDepart } = props;
    const store = useContext(StoreContext);
    const OPMStore = store.getDefaultOPMStore();
    const SettingStore = store.getSettingDefaultStore();
    const FRMStore = store.getDefaultFRMStore();
    const DRMStore = store.getDefaultDRMStore();
    const {
        getOPMTableData,
        OPMTableData,
        defaultOPMEE,
        resignPerson,
    } = OPMStore;
    const { departInfo, customerInfo, getCustomerInfo } = SettingStore;
    const { FRMRoleListData } = FRMStore;
    const { DRMRoleListData } = DRMStore;
    const [OPMTableSearchData, setOPMTableSearchData] = useState<
        IOPMTableSearchData
    >({
        userNameAndEmail: '',
        status: '1', // 默认在职
        subDepart: true, // 默认包含子部门
    });
    // 更新成功事件
    const updateSucEEHandle = () => {
        getCustomerInfo();
        reload();
    };
    const clearOPMTableSearchData = () => {
        setOPMTableSearchData({
            userNameAndEmail: '',
            status: '1',
            subDepart: true,
        });
    };
    useEffect(() => {
        OPMEE.on('update-suc', updateSucEEHandle);
        // OPMEE.on('update-suc', () => {
        //     console.log(12141);
        // })
        return () => {
            OPMEE.removeListener('update-suc');
        };
    }, [updateSucEEHandle]);
    useEffect(() => {
        console.log(selectedDepart);
        if (!_.isEmpty(selectedDepart)) {
            clearOPMTableSearchData();
            reload();
        }
    }, [selectedDepart]);
    const dataFetch = useCallback(
        () => getOPMTableData(OPMTableSearchData, selectedDepart),
        [OPMTableSearchData, selectedDepart],
    );
    const [error, loading, reload] = useNet(dataFetch);
    const changeFilters = (
        changeKey: keyof IOPMTableSearchData,
        changeValue: any,
    ) => {
        const nextOPMSearchData = {
            ...OPMTableSearchData,
        };
        nextOPMSearchData[changeKey] = changeValue;
        setOPMTableSearchData(nextOPMSearchData);
    };

    const renderFilters = () => {
        // 固定的筛选项
        return (
            <div className="opm-table-filter">
                <Input
                    placeholder="按姓名搜索"
                    style={{ width: 130 }}
                    onChange={e => {
                        const val = e.target.value;
                        changeFilters('userNameAndEmail', val);
                    }}
                    onPressEnter={reload}
                    value={OPMTableSearchData.userNameAndEmail}
                />
                <Select
                    value={OPMTableSearchData.status}
                    style={{ width: 130 }}
                    onChange={(val: any) => {
                        changeFilters('status', val);
                    }}
                >
                    <Option value="1">在职</Option>
                    <Option value="2">离职</Option>
                    <Option value="3">禁用</Option>
                    <Option value="">全部</Option>
                </Select>
                <Select
                    style={{ width: 150 }}
                    placeholder="请选择数据角色"
                    onChange={(val: any) => {
                        changeFilters('dataRoleId', val);
                    }}
                    value={OPMTableSearchData.dataRoleId}
                >
                    <Select.Option value={''}>未选择</Select.Option>
                    {_.map(DRMRoleListData, roleData => {
                        return (
                            <Select.Option value={roleData.id}>
                                {roleData.dataRoleName}
                            </Select.Option>
                        );
                    })}
                </Select>
                <Select
                    style={{ width: 150 }}
                    placeholder="请选择功能角色"
                    onChange={(val: any) => {
                        changeFilters('funRoleId', val);
                    }}
                    value={OPMTableSearchData.funRoleId}
                >
                    <Select.Option value={''}>未选择</Select.Option>
                    {_.map(FRMRoleListData, roleData => {
                        return (
                            <Select.Option value={roleData.id}>
                                {roleData.functionRoleName}
                            </Select.Option>
                        );
                    })}
                </Select>
                <Checkbox
                    onChange={e => {
                        const checked = e.target.checked;
                        changeFilters('subDepart', checked);
                    }}
                    checked={OPMTableSearchData.subDepart}
                >
                    包含子部门
                </Checkbox>
                <AntButton onClick={reload} type="primary">
                    查询
                </AntButton>
            </div>
        );
    };
    const onTableActionDispatch = async (
        actionKey: string,
        data: IOPMTableData,
    ) => {
        const existing = _.find(departInfo, depart => {
            return depart.id == data.dimDepart;
        });
        if (actionKey === 'edit') {
            // 编辑
            setSelectedTableItem({
                ...data,
                dimDepartName: existing?.title as string,
            });
        } else if (actionKey === 'resign') {
            const [d, e] = await resignPerson(data.id as string);
            if (d) {
                message.success('离职成功');
                reload();
            } else {
                message.error('离职失败');
            }
        }
    };
    return (
        <LayoutStandardCrud
            className={'opm-table-content'}
            globalOps={null}
            message={null}
            popups={null}
            title={null}
            filters={renderFilters()}
            multipleOps={
                <div className="opm-ops-content">
                    <AntButton
                        type="primary"
                        onClick={() => {
                            setSelectedTableItem({
                                name: '',
                                phone: '',
                                personalEmail: '',
                                status: 1,
                                dimDepart: selectedDepart?.id || '',
                                dimDepartName:
                                    selectedDepart?.depart_name || '',
                                parent_depart: '',
                                userLevel: '',
                                rankId: '',
                                dataRoleList: [],
                                funRoleList: [],
                                managerId: '',
                                avatar: '',
                            });
                        }}
                    >
                        添加人员
                    </AntButton>
                </div>
            }
            dataTable={
                <TableLv2<IOPMTableData>
                    scroll={{ x: '100%' }}
                    className={'table-v-stretch'}
                    dataSource={OPMTableData}
                    pagination={false}
                    loading={loading}
                    actionsWidth="100px"
                    closeRowSelection={true}
                    onAction={onTableActionDispatch}
                    adjustHeight={true}
                    actions={[
                        {
                            actionKey: 'edit',
                            actionCn: '编辑',
                        },
                        {
                            actionKey: 'resign',
                            actionCn: '离职',
                            status: {
                                disabled: record => {
                                    if (record.status == 2) {
                                        return true;
                                    }
                                    return false;
                                },
                            },
                            popconfirm: true,
                            popcomfirmConfig: {
                                title: '是否将该成员状态改为离职？',
                            },
                        },
                    ]}
                    columns={[
                        {
                            dataIndex: 'name',
                            key: 'name',
                            width: '150px',
                            title: '姓名',
                        },
                        {
                            dataIndex: 'personalEmail',
                            key: 'personalEmail',
                            width: '200px',
                            title: '邮箱',
                        },
                        {
                            dataIndex: 'phone',
                            key: 'phone',
                            width: '200px',
                            title: '手机号码',
                        },
                        {
                            key: 'status',
                            dataIndex: 'status',
                            width: '60px',
                            title: '状态',
                            render: v => {
                                switch (v) {
                                    case 1:
                                        return '在职';
                                    case 3:
                                        return '禁用';
                                    case 2:
                                        return '离职';
                                    default:
                                        return v;
                                }
                            },
                        },
                        {
                            key: 'dimDepart',
                            dataIndex: 'dimDepart',
                            width: '150px',
                            title: '所属部门',
                            render: v => {
                                const existing = _.find(departInfo, depart => {
                                    return depart.id == v;
                                });
                                if (existing) {
                                    return existing.title;
                                } else {
                                    return v;
                                }
                            },
                        },
                        {
                            key: 'rankId',
                            dataIndex: 'rankId',
                            width: '120px',
                            title: '职级',
                            render: v => {
                                return rankIdMap[v] ? rankIdMap[v] : v;
                            },
                        },
                        {
                            key: 'managerId',
                            width: '150px',
                            title: '直属上级',
                            dataIndex: 'managerId',
                            render: v => {
                                if (v === -1) {
                                    return '';
                                } else {
                                    const existing = _.find(
                                        customerInfo,
                                        customer => {
                                            return customer.id == v;
                                        },
                                    );
                                    return existing ? existing.name : v;
                                }
                            },
                        },
                        {
                            key: 'userLevel',
                            width: '150px',
                            title: '销售级别',
                            dataIndex: 'userLevel',
                            render: v => {
                                return v;
                            },
                        },
                        {
                            key: 'dataRoleList',
                            dataIndex: 'dataRoleList',
                            width: '150px',
                            title: '数据角色',
                            render: v => {
                                if (!v) {
                                    return null;
                                }
                                const drmIdArray = _.isArray(v)
                                    ? v
                                    : v.split(',');
                                const groupDataRole = _.groupBy(
                                    DRMRoleListData,
                                    'id',
                                );
                                return _.map(drmIdArray, dId => {
                                    const dDataRoleArray =
                                        groupDataRole[dId] || [];
                                    const dDataRole = dDataRoleArray[0];
                                    if (dDataRole) {
                                        return (
                                            <p key={dDataRole.id}>
                                                {dDataRole.dataRoleName}
                                            </p>
                                        );
                                    } else {
                                        return null;
                                    }
                                });
                            },
                        },
                        {
                            key: 'funRoleList',
                            dataIndex: 'funRoleList',
                            width: '150px',
                            title: '功能角色',
                            render: v => {
                                if (!v) {
                                    return null;
                                }
                                const frmIdArray = _.isArray(v)
                                    ? v
                                    : v.split(',');
                                const groupFunctionRole = _.groupBy(
                                    FRMRoleListData,
                                    'id',
                                );
                                return _.map(frmIdArray, fId => {
                                    const fRoleArray =
                                        groupFunctionRole[fId] || [];
                                    const fRole = fRoleArray[0];
                                    if (fRole) {
                                        return (
                                            <p key={fRole.id}>
                                                {fRole.functionRoleName}
                                            </p>
                                        );
                                    } else {
                                        return null;
                                    }
                                });
                            },
                        },
                        {
                            key: 'createTime',
                            dataIndex: 'createTime',
                            title: '创建时间',
                            width: '200px',
                            render: v => {
                                return v
                                    ? moment(v).format('YYYY-MM-DD HH:mm:ss')
                                    : '- -';
                            },
                        },
                        {
                            key: 'updateTime',
                            dataIndex: 'updateTime',
                            title: '更新时间',
                            width: '200px',
                            render: v => {
                                return v
                                    ? moment(v).format('YYYY-MM-DD HH:mm:ss')
                                    : '- -';
                            },
                        },
                        {
                            key: 'quitAt',
                            dataIndex: 'quitAt',
                            title: '离职日期',
                            width: '200px',
                            render: v => {
                                return v
                                    ? moment(v).format('YYYY-MM-DD HH:mm:ss')
                                    : '- -';
                            },
                        },
                        {
                            key: 'positiveAt',
                            dataIndex: 'positiveAt',
                            title: '转正日期',
                            width: '200px',
                            render: v => {
                                return v
                                    ? moment(v).format('YYYY-MM-DD HH:mm:ss')
                                    : '- -';
                            },
                        },
                        {
                            key: 'joinAt',
                            dataIndex: 'joinAt',
                            title: '入职日期',
                            width: '200px',
                            render: v => {
                                return v
                                    ? moment(v).format('YYYY-MM-DD HH:mm:ss')
                                    : '- -';
                            },
                        },
                        {
                            key: 'appraiseAt',
                            dataIndex: 'appraiseAt',
                            title: '考核日期',
                            width: '200px',
                            render: v => {
                                return v
                                    ? moment(v).format('YYYY-MM-DD HH:mm:ss')
                                    : '- -';
                            },
                        },
                        {
                            key: 'avatar',
                            dataIndex: 'avatar',
                            title: '头像',
                            width: '160px',
                            render: v => {
                                if (v) {
                                    try {
                                        return JSON.parse(v)?.[0]?.url ? (
                                            <Popover
                                                content={
                                                    <div
                                                        style={{
                                                            width: '100px',
                                                            height: '100px',
                                                            backgroundColor:
                                                                '#AAA',
                                                            overflow: 'hidden',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                        }}
                                                    >
                                                        <img
                                                            src={`${
                                                                JSON.parse(
                                                                    v,
                                                                )?.[0]?.url
                                                            }`}
                                                            style={{
                                                                // backgroundImage: `url(${item.url})`,
                                                                width: '100px',
                                                                // height: '100px',
                                                                // borderRadius: '50%',
                                                                // backgroundPosition:
                                                                //     'center',
                                                                // backgroundSize: 'cover',
                                                            }}
                                                            className="opm-img-content"
                                                        />
                                                    </div>
                                                }
                                            >
                                                <a
                                                    href={
                                                        JSON.parse(v)?.[0]?.url
                                                    }
                                                >
                                                    查看头像
                                                </a>
                                            </Popover>
                                        ) : (
                                            '-'
                                        );
                                    } catch (error) {
                                        return '-';
                                    }
                                } else {
                                    return '-';
                                }
                            },
                        },
                    ]}
                />
            }
        />
    );
};
export default observer(OPMTable);
