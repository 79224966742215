import React, { useState, useRef, useContext } from 'react';
import { TableLv2 } from '@/components';
import moment from 'moment';
import { defaultAxios } from '@/utils';
import { useRequest } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { Button, Icon, Input, Modal, Popover, message } from 'antd';
import { StoreContext } from '@/stores';
import {
    ICUserData,
    IPersonBuyData,
} from '@/components/common/mai-trend/store';
import { tryNewCall } from '@/containers/hoc/call-center';
import { CtxProvider } from '@/utils/context';
import { CUser2Contacts } from '@/components/common/mai-trend/cuser-modal/cuser2contacts';
import { getValueOrDefault } from '@/utils/helpers';
import { SearchOutlined } from '@ant-design/icons';
import Ccpopover from '@/components/common/cc-popover';

export interface IActiveTableProps {
    company: string;
    queryScene: string;
    crm_cid?: string | number;
    isPrivateCus: boolean;
    customerOriginData: any;
}

interface SearchDataParams {
    position?: string[];
    company?: string;
    name?: string;
    start?: string;
    end?: string;
    queryScene?: any;
    companyId?: string | number;
    lastLogin?: number;
}

// 搜索c端用户列表
async function searchCUserDataList(searchData: SearchDataParams) {
    const url = `/bff/api/rest/c-user/search-c-user-list`;
    const [d] = await defaultAxios.post(url, {
        positions: searchData.position,
        company: searchData.company,
        username: searchData.name,
        queryScene: searchData.queryScene,
        companyId: searchData.companyId,
        lastLogin: searchData.lastLogin,
    });
    if (d === null || d.data === null) {
        return;
    }
    return d.data as ICUserData[];
}

const ActiveTable: React.FC<IActiveTableProps> = ({
    company,
    queryScene,
    isPrivateCus,
    crm_cid,
    customerOriginData,
}) => {
    const store = useContext(StoreContext);
    const cUserStore = store.getDefaultCUserStore();
    const {
        remainCount,
        decodeCUser,
        checkCUserDecoded,
        getPersonBuyList,
        cUserBuyList,
        cUserBuyDataLoading,
    } = cUserStore;

    // 转联系人相关
    const [cuser2contactsTrigger, setCuser2contactsTrigger] = useState(0);
    const [
        cuser2contactsCurrentCuser,
        setCuser2contactsCurrentCuser,
    ] = useState<any>(null);
    const [cUserBuyModalVis, setCUserBuyModalVis] = useState(false);
    const [popoverVisable, setPopoverVisable] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<any>(null);

    const { data, loading } = useRequest(
        () =>
            searchCUserDataList({
                company,
                queryScene,
                companyId: customerOriginData?.customer_cid,
            }),
        {
            refreshDeps: [company, queryScene],
            ready:
                !!customerOriginData?.customer_cid &&
                customerOriginData?.customer_cid > 0,
        },
    );
    console.log('得到的data', data);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: any) => void,
        dataIndex: any,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }: any) => (
            <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex,
                        )
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() =>
                        handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex,
                        )
                    }
                    // icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    搜索
                </Button>
                <Button
                    onClick={() => clearFilters && handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    重置
                </Button>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{ color: filtered ? '#1677ff' : undefined }}
            />
        ),
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <div>
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape textToHighlight={text ? text.toString() : ''}
                </div>
            ) : (
                text
            ),
    });
    const decodePhoneHandle = async (
        id: string,
        name: string,
        position: string,
    ) => {
        const [d, e] = await checkCUserDecoded(id);

        if (d) {
            // 已解密
            const [data, error] = await decodeCUser(id);
            if (data) {
                // 拉起外呼
                tryNewCall(data, true, {
                    showFollowLog: true,
                    customerName: company,
                    customerId: crm_cid,
                    userName: name,
                    userPosition: position,
                });
            }
        } else {
            if (remainCount <= 0) {
                message.warning(
                    '为了防止C端用户信息泄漏，您每天只有200次外呼手机号的机会，目前机会已用完！',
                );
                return;
            }
            // 未解密
            Modal.confirm({
                content: (
                    <div>
                        <p>本次外呼，将消耗1次机会</p>
                        <p>
                            您今天剩余可用机会为
                            <span style={{ color: 'red' }}>{remainCount}</span>
                            次，请确认是否外呼？
                        </p>
                    </div>
                ),
                onOk: async () => {
                    const [data, error] = await decodeCUser(id);
                    if (data) {
                        // 拉起外呼
                        tryNewCall(data, true, {
                            customerName: company,
                            showFollowLog: true,
                            customerId: crm_cid,
                            userName: name,
                            userPosition: position,
                        });
                    }
                },
            });
        }
    };
    const onVisibleChange = (visable: any) => {
        setPopoverVisable(visable);
    };
    return (
        <>
            <CtxProvider
                ctx={{
                    position: 'drawer',
                    positionDetail: 'customer-drawer-maitrend-cuser',
                    data: { cid: customerOriginData?.id },
                }}
            >
                <CUser2Contacts
                    trigger={cuser2contactsTrigger}
                    customerInfo={customerOriginData}
                    cuser={cuser2contactsCurrentCuser}
                />
            </CtxProvider>
            <Modal
                width={'800px'}
                visible={cUserBuyModalVis}
                onCancel={() => {
                    setCUserBuyModalVis(false);
                }}
                onOk={() => {
                    setCUserBuyModalVis(false);
                }}
                title="会员购买记录"
            >
                <TableLv2<IPersonBuyData>
                    loading={cUserBuyDataLoading}
                    closeRowSelection={true}
                    pagination={false}
                    dataSource={cUserBuyList}
                    scroll={{
                        y: 350,
                        x: 1000,
                    }}
                    columns={[
                        {
                            title: 'UID',
                            key: 'uid',
                            width: 120,
                            dataIndex: 'uid',
                        },
                        {
                            title: '订单ID',
                            key: 'orderId',
                            width: 120,
                            dataIndex: 'orderId',
                        },
                        {
                            title: '购买时间',
                            key: 'buyTime',
                            dataIndex: 'buyTime',
                            width: 120,
                            render: item => {
                                return moment(Number(item)).format(
                                    'YYYY-MM-DD HH:mm',
                                );
                            },
                        },
                        {
                            title: '订单金额',
                            key: 'money',
                            dataIndex: 'money',
                            width: 100,
                        },
                        {
                            title: '会员类型',
                            key: 'memType',
                            dataIndex: 'memType',
                            width: 100,
                        },
                        {
                            title: '会员月数',
                            key: 'month',
                            dataIndex: 'month',
                            width: 100,
                        },
                        {
                            title: '购买渠道',
                            key: 'channel',
                            dataIndex: 'channel',
                            width: 100,
                        },
                        {
                            title: '购买方式',
                            key: 'buyType',
                            dataIndex: 'buyType',
                            width: 100,
                        },
                        {
                            title: '是否勾选自动续费',
                            key: 'autoRenew',
                            dataIndex: 'autoRenew',
                            width: 160,
                            render: v => {
                                return v ? '是' : '否';
                            },
                        },
                    ]}
                />
            </Modal>
            <TableLv2<any>
                loading={loading}
                closeRowSelection={true}
                pagination={false}
                dataSource={data}
                // scroll={{
                //     y: 500,
                //     x: 1000,
                // }}
                onAction={(actionKey, data) => {
                    if (actionKey === 'transferContact') {
                        // 转联系人
                        // 拿手机号
                        decodeCUser(data.uid).then(([d, e]) => {
                            const phone = d;
                            if (!phone) {
                                return message.error('获取用户手机失败');
                            }
                            setCuser2contactsTrigger(cuser2contactsTrigger + 1);
                            setCuser2contactsCurrentCuser({
                                ...data,
                                decodedPhone: phone,
                            });
                        });
                    } else if (actionKey === 'viewBuyRecord') {
                        // 查看购买记录

                        getPersonBuyList(data.uid);
                        setCUserBuyModalVis(true);
                    }
                }}
                actions={[
                    {
                        actionKey: 'transferContact',
                        actionCn: '转联系人',
                        status: {
                            disabled: item => {
                                return !item.call;
                            },
                        },
                    },
                    {
                        actionKey: 'viewBuyRecord',
                        actionCn: '购买记录',
                    },
                ]}
                actionsWidth={140}
                isFixedActions={false}
                columns={[
                    {
                        title: '姓名',
                        key: 'realname',
                        dataIndex: 'realname',
                        fixed: true,
                        // ...getColumnSearchProps('realname'),
                        render: (text, record) => {
                            return (
                                <span>
                                    <a href={record.profileUrl} target="_blank">
                                        {text}
                                    </a>{' '}
                                    {isPrivateCus ? (
                                        <Popover
                                            getPopupContainer={() => {
                                                return document.body as HTMLElement;
                                            }}
                                            style={{ zIndex: 666666 }}
                                            onVisibleChange={onVisibleChange}
                                            placement="right"
                                            content={
                                                <Ccpopover
                                                    uid={
                                                        popoverVisable
                                                            ? record.uid
                                                            : undefined
                                                    }
                                                    whetherShow={false}
                                                />
                                            }
                                        >
                                            <Icon
                                                onClick={() => {
                                                    decodePhoneHandle(
                                                        record.uid,
                                                        record.realname,
                                                        record.position,
                                                    );
                                                }}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                type="phone"
                                                theme="twoTone"
                                                twoToneColor="#0052ff"
                                            />
                                        </Popover>
                                    ) : null}
                                </span>
                            );
                        },
                    },
                    {
                        title: '会员类型',
                        key: 'memType',
                        dataIndex: 'memType',
                        width: 100,
                        render: text => getValueOrDefault(text),
                    },
                    {
                        title: '职位',
                        key: 'position',
                        dataIndex: 'position',
                        width: 120,
                        // ...getColumnSearchProps('position'),
                        render: text => <span>{text}</span>,
                    },
                    {
                        title: '最近活跃时间',
                        key: 'lastActiveDate',
                        dataIndex: 'lastActiveDate',
                        width: 120,
                        render: v => {
                            if (moment('2010-01-01').valueOf() > v) {
                                return '-';
                            } else {
                                return v
                                    ? moment(Number(v)).format('YYYY-MM-DD')
                                    : '-';
                            }
                        },
                        sorter: (a, b) => a.lastActiveDate - b.lastActiveDate,
                    },
                    {
                        title: '近30日招聘活跃次数',
                        key: 'oneMonthActiveCnt',
                        dataIndex: 'oneMonthActiveCnt',
                        width: 139,
                        render: text => getValueOrDefault(text),
                        sorter: (a, b) =>
                            a.oneMonthActiveCnt - b.oneMonthActiveCnt,
                    },
                    {
                        title: '有效职位数',
                        key: 'validPositionCnt',
                        dataIndex: 'validPositionCnt',
                        width: 100,
                        render: text => getValueOrDefault(text),
                        sorter: (a, b) =>
                            a.validPositionCnt - b.validPositionCnt,
                    },
                    {
                        title: '搜索人才数',
                        key: 'searchTalentCnt',
                        dataIndex: 'searchTalentCnt',
                        width: 80,
                        render: text => getValueOrDefault(text),
                    },
                    {
                        title: '主动加好友数',
                        key: 'initiateFriendingCnt',
                        dataIndex: 'initiateFriendingCnt',
                        width: 90,
                        render: text => getValueOrDefault(text),
                    },
                    {
                        title: '主动开聊数',
                        key: 'initiateCharCnt',
                        dataIndex: 'initiateCharCnt',
                        width: 80,
                        render: text => getValueOrDefault(text),
                    },
                    {
                        title: '极速联系数',
                        key: 'fastContactCnt',
                        dataIndex: 'fastContactCnt',
                        width: 80,
                        render: text => getValueOrDefault(text),
                    },
                ]}
            />
        </>
    );
};

export default observer(ActiveTable);
