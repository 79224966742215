import React, { useState, useCallback, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { ModalProps } from 'antd/lib/modal';
import { defaultAxios, useNet } from '@/utils';
import { Modal, DatePicker } from 'antd';
import { TableLv2 } from '@/components/common/table/level2';
import '@/components/common/log-modal/index.scss';

export interface ICirculationLogSearchData {
    beginDate: Moment;
    endDate: Moment;
    pageNum?: number;
    pageSize?: number;
}
export interface ICirculationLogData {
    createTime: string | Moment; // 操作日期
    createUser: string; // 操作人
    createUserName: string;
    becomeOperateType: string;
    beforeUserName: string; // 原所有人
    userName: string; // 新所有人
    becomeReason: string; // 备注
}
export type TGetCirculationLogReturnType = NetResponse<{
    data: ICirculationLogData[];
    msg: string;
    code: number;
}>;
export interface ILogModalProps extends ModalProps {
    taskId?: number | string;
    modalVis: boolean;
    setModalVis: (bool: boolean) => void;
}
const opTypeToName: {
    [key: string]: any;
} = {
    1: '退回公海池',
    2: '认领',
    3: '分配',
    4: '转移',
    5: '创建',

    8: '新建',
    9: '修改',
    10: '删除',
    11: '变更执行人',
};
const CirculationLogModal: React.FC<ILogModalProps> = props => {
    const { modalVis, setModalVis, taskId } = props;
    const [logCount, setLogCount] = useState(0);
    const [searchLogData, setSearchLogData] = useState<
        ICirculationLogSearchData
    >({
        beginDate: moment()
            .subtract(1, 'month')
            .startOf('day'),
        endDate: moment().endOf('day'),
        pageNum: 1,
        pageSize: 30,
    });
    const [circulationLogData, setCirculationLogData] = useState<
        ICirculationLogData[]
    >([]);
    const [rangeDateData, setRangeDateData] = useState<
        Moment[] | null[] | undefined[]
    >([
        moment()
            .subtract(1, 'month')
            .startOf('day'),
        moment().endOf('day'),
    ]);
    const fetchEntityLog: () => Promise<
        TGetCirculationLogReturnType
    > = async () => {
        if (!modalVis || !taskId) {
            return [null, null];
        }
        const url = '/crm/task/findRoleUserByTaskId';
        const [d, e] = await defaultAxios.post(url, {
            ...searchLogData,
            start: searchLogData.beginDate.valueOf(),
            end: searchLogData.endDate.valueOf(),
            taskId,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data?.data)) {
            setCirculationLogData(data?.data);
            setLogCount(data?.totalSize || 0);
        }
        const error = e as Error | null;
        return [data, error];
    };
    const getLog = useCallback(fetchEntityLog, [searchLogData, modalVis]);
    const [logError, logLoading, logReload] = useNet(getLog);
    useEffect(() => {
        if (modalVis && taskId) {
            logReload();
        }
    }, [modalVis]);
    useEffect(() => {
        if (modalVis) {
            logReload();
        }
    }, [searchLogData]);
    const hideEntityLogModal = () => {
        setRangeDateData([
            moment()
                .subtract(1, 'month')
                .startOf('day'),
            moment().endOf('day'),
        ]);
        setSearchLogData({
            beginDate: moment()
                .subtract(1, 'month')
                .startOf('day'),
            endDate: moment().endOf('day'),
            pageNum: 1,
            pageSize: 30,
        });
        setCirculationLogData([]);
        setModalVis(false);
    };
    const finalModalProps: ModalProps = {
        ...props,
        centered: true,
        width: '900px',
        visible: modalVis,
        onCancel: hideEntityLogModal,
        onOk: hideEntityLogModal,
        className: `crm-entity-modal-content ${
            props.className ? props.className : ''
        }`,
    };
    // ICirculationLogData {
    //     createTime: string | Moment;
    //     createUser: string;
    //     createUserName: string;
    //     operateType: string;
    //     beforeUserName: string;
    //     userName: string;
    //     becomeReason: string;
    // }
    return (
        <Modal {...finalModalProps}>
            <div className="crm-entity-log-modal-body">
                <div className="crm-entity-log-filter">
                    <span>时间筛选：</span>
                    <DatePicker.RangePicker
                        allowClear={false}
                        value={[...(rangeDateData as [Moment, Moment])]}
                        onChange={(dates, dateString) => {
                            setRangeDateData(dates as Moment[]);
                            // setSearchLogData({
                            //     ...searchLogData,
                            //     beginDate: dates[0] as Moment,
                            //     endDate: dates[1] as Moment
                            // })
                        }}
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder={['Start Time', 'End Time']}
                        onOk={() => {
                            setSearchLogData({
                                ...searchLogData,
                                beginDate: rangeDateData[0] as Moment,
                                endDate: rangeDateData[1] as Moment,
                            });
                        }}
                    />
                </div>
                <div className="crm-entity-log-table">
                    <TableLv2<ICirculationLogData>
                        loading={logLoading}
                        pagination={{
                            pageSize: searchLogData.pageSize,
                            total: logCount,
                        }}
                        scroll={{
                            y: 350,
                        }}
                        dataSource={circulationLogData}
                        onPaginationChange={pagination => {
                            setSearchLogData({
                                ...searchLogData,
                                pageNum:
                                    pagination.current === 0
                                        ? 1
                                        : pagination.current,
                                pageSize: pagination.pageSize,
                            });
                        }}
                        closeRowSelection={true}
                        columns={[
                            {
                                key: 'createTime',
                                dataIndex: 'createTime',
                                title: '操作时间',
                                render: v => {
                                    return moment(v).format('YYYY-MM-DD HH:mm');
                                },
                                width: 150,
                            },
                            {
                                key: 'createUserName',
                                dataIndex: 'createUserName',
                                title: '操作人',
                                width: 70,
                            },
                            {
                                key: 'becomeOperateType',
                                dataIndex: 'becomeOperateType',
                                title: '操作类型',
                                width: 120,
                                render: v => {
                                    return opTypeToName?.[v] || '';
                                },
                            },
                            {
                                key: 'beforeUserName',
                                dataIndex: 'beforeUserName',
                                title: '原所有人',
                                width: 120,
                            },
                            {
                                key: 'userName',
                                dataIndex: 'userName',
                                title: '新所有人',
                                width: 120,
                            },
                            {
                                key: 'becomeReason',
                                dataIndex: 'becomeReason',
                                title: '备注说明',
                                width: 150,
                            },
                        ]}
                    ></TableLv2>
                </div>
            </div>
        </Modal>
    );
};
export default CirculationLogModal;
