import React, {
    CSSProperties,
    useState,
    PropsWithChildren,
    useEffect,
} from 'react';
import { IFormLV1Props, FormLV1, IWidgetItem } from './level1';
import _ from 'lodash';
import { executeAllValidatorFor, getScrollParent } from '@/utils';
import {
    getCommonStatus,
    getTypes,
    IButtonOptions,
    WidgetButton,
} from './widgets';
import { Button, Drawer, Spin } from 'antd';
import ButtonGroup, { ButtonGroupProps } from 'antd/lib/button/button-group';
import Modal, { ModalProps } from 'antd/lib/modal';
import { DrawerProps } from 'antd/lib/drawer';
import { CollapseProps } from 'antd/lib/collapse';
import $ from 'jquery';
import 'jquery.scrollto';

const Group = Button.Group;

export type TFormValidators<T> = { [key in keyof T]?: Validator<T> };

// export type TValidatorsRelationship<T> = {
//     [key in keyof T]?: [keyof T]
// };

// action btn
export interface IActionWidgetItem<T, E>
    extends Pick<IWidgetItem<T, E>, 'status' | 'onInteract'> {
    option: IButtonOptions;
    key: string;
}

export interface IActionWidgetsGroupOptionProp extends ButtonGroupProps {}

export interface IContainerModalProps extends ModalProps {
    loading?: boolean;
    key?: any;
}

export interface IContainerDrawerProps extends DrawerProps {}

export interface IFormLV2Props<T, E = any> extends IFormLV1Props<T, E> {
    validators?: TFormValidators<T>;
    validateMode?: 'instantly' | 'lazily';
    actionWidgets?: Array<IActionWidgetItem<T, E>>;
    actionWidgetsGroupOption?: IActionWidgetsGroupOptionProp;
    onAction?: (
        actionType: string,
        data: T,
        isPass: boolean,
        validateResult: Nilable<ValidateResult<T>>,
    ) => void;
    styleLv2?: CSSProperties;
    classNameLv2?: string;
    container?: 'modal' | 'drawer';
    containerModalProps?: IContainerModalProps;
    containerDrawerProps?: IContainerDrawerProps;
    onAutoValidateResultChange?: (result: ValidateResult<T>) => void;
    validatorRelationship?: {
        [key: string]: any;
    };
    groupReverseMap?: {
        [key: string]: string;
    };
    isAutoScrollFirstInvalidateItem?: boolean;
    paramsToClearValidateState?: string[];
}

export const FormLV2: <T, E = any>(
    props: PropsWithChildren<IFormLV2Props<T, E>>,
) => React.ReactElement | null = props => {
    const {
        onInteract = () => void 0,
        validators,
        validateMode,
        data,
        onAction = () => void 0,
        styleLv2,
        classNameLv2,
        container,
        containerModalProps,
        containerDrawerProps,
        children,
        validatorRelationship = {},
        validateResult: outerValidateResult,
        onAutoValidateResultChange,
        collapseProps = {},
        groupReverseMap,
        isAutoScrollFirstInvalidateItem = false,
        paramsToClearValidateState,
    } = props;

    const lv1Props = { ...props };
    const omits = ['validateResult', 'collapseProps'];
    for (const key of omits) {
        delete (lv1Props as any)[key];
    }

    const [validateResult, setValidateResultBasic] = useState<
        Nilable<ValidateResult<any>>
    >(undefined);

    const setValidateResult: typeof setValidateResultBasic = v => {
        setValidateResultBasic(v);
        if (!_.isNil(v) && !_.isNil(onAutoValidateResultChange)) {
            onAutoValidateResultChange(v as ValidateResult<any>);
        }
    };

    // 根据校验结果自动展开
    // 加一个受控层，目前如果使用form + collapse 仅支持 defaultActiveKey
    // make initial state
    const collapsePropsTmp = { ...collapseProps };
    const { defaultActiveKey = [] } = collapsePropsTmp;
    delete collapsePropsTmp.defaultActiveKey;

    const onActiveKeysChange: (key: string | string[]) => void = keys => {
        setClProps(makeCollpaseProps(keys));
    };
    const makeCollpaseProps = (keys: string | string[]): CollapseProps => {
        return {
            activeKey: keys,
            onChange: onActiveKeysChange,
            ...collapsePropsTmp,
        };
    };
    const initialCollapseProps: CollapseProps = makeCollpaseProps(
        defaultActiveKey as string[],
    );
    const [clProps, setClProps] = useState(initialCollapseProps);
    // 自动展开
    useEffect(() => {
        const vs: ValidateResult<any> = {
            ...validateResult,
            ...outerValidateResult,
        };
        if (_.isNil(vs) || _.isNil(groupReverseMap)) {
            return;
        }
        const keys = _.keys(vs);
        const invalidKeys = _.filter(keys, key => {
            const vr = vs[key];
            if (!vr) {
                return false;
            }
            return vr.status === 'warning' || vr.status === 'fail';
        });
        const nextActiveKeys = _.uniq([
            ...(_.isArray(clProps.activeKey)
                ? clProps.activeKey
                : [clProps.activeKey]),
            ...invalidKeys.map(k => groupReverseMap[k]),
        ]) as string[];
        setClProps(makeCollpaseProps(nextActiveKeys));
    }, [validateResult, outerValidateResult]);

    useEffect(() => {
        if (isAutoScrollFirstInvalidateItem) {
            const itemsWithError = $('.ant-form-item-control.has-error');
            const firstDomEle = itemsWithError.eq(0)[0];
            if (!firstDomEle) {
                return;
            }
            const formTopEle = $(firstDomEle).closest('.component-form-lv2')[0];
            if (!formTopEle || !(formTopEle as any).___withAutoScroll) {
                return;
            }
            (formTopEle as any).___withAutoScroll = false;
            const offsetEle = $(firstDomEle).closest(
                '.ant-collapse-content-box > .ant-row',
            )[0];
            if (!offsetEle) {
                return;
            }
            const scrollableEle = getScrollParent(formTopEle);
            if (!scrollableEle) {
                return;
            }
            if (_.isFunction(($(scrollableEle) as any).scrollTo)) {
                ($(scrollableEle) as any).scrollTo($(offsetEle), {
                    duration: 500,
                });
            }
        }
    }, [clProps]);
    lv1Props.collapseProps = clProps;

    useEffect(() => {
        if (
            !paramsToClearValidateState ||
            paramsToClearValidateState.length === 0
        ) {
            return;
        }
        if (!validateResult) {
            return;
        }
        const nextValidateState = { ...validateResult };
        for (const key of paramsToClearValidateState) {
            delete nextValidateState[key];
        }
        setValidateResult(nextValidateState);
    }, [paramsToClearValidateState]);

    useEffect(() => {
        setValidateResult(undefined);
    }, [containerModalProps?.visible, containerDrawerProps?.visible]);
    const getIsPassFromValidateResult = (
        vResult: Nilable<ValidateResult<any>>,
    ) =>
        vResult
            ? _.every(_.keys(vResult), key => {
                  const validateItem = vResult[key];
                  if (!_.isNil(validateItem)) {
                      return validateItem.status !== 'fail';
                  }
                  // 如果不care，那就是pass
              })
            : true;

    let shouldValidate = false;
    if (!_.isNil(validators) && !_.isNil(validateMode)) {
        shouldValidate = true;
    }
    // 表单验证逻辑
    if (shouldValidate && _.isNil(lv1Props.validateResult)) {
        // 表单验证结果
        // 不同于半受控，这种模式绑定弱一些
        lv1Props.validateResult = { ...validateResult, ...outerValidateResult };

        // 原交互回调
        const prevOnInteract = onInteract;
        // 新交互回调
        let nextOnInteract = prevOnInteract;
        // 移除表单验证状态
        const tryRemoveValidateStatus: typeof nextOnInteract = async (
            key: any,
            type,
            value,
        ) => {
            if (type === 'remove-validate-status') {
                if (!_.isNil(validateResult)) {
                    const nextValidateState = { ...validateResult };
                    if (
                        !nextValidateState[key] ||
                        nextValidateState[key]?.status === 'success'
                    ) {
                        return;
                    }
                    delete nextValidateState[key];
                    setValidateResult(nextValidateState);
                }
            }
        };
        // 验证全部
        const validateAll = async (
            key: any,
            type: string,
            value: any,
            allkey: boolean,
        ) => {
            if (!_.isNil(data) && !_.isNil(validators)) {
                let validateData = data;
                if (key && value !== undefined) {
                    validateData = { ...data, [key]: value };
                }
                // 验证单个、全部、带有key关系的key
                const { result, isPass } = await executeAllValidatorFor(
                    validateData,
                    validators,
                    allkey
                        ? (Object.keys(data) as any)
                        : validatorRelationship[key]
                        ? validatorRelationship[key]
                        : [key],
                );
                if (!isPass) {
                    setValidateResult(_.assign({}, validateResult, result));
                }
                return { result, isPass, data: validateData };
            }
        };
        // 实时验证回调
        const tryValidateInstantly: typeof nextOnInteract = async (
            key: any,
            type,
            value,
        ) => {
            if (type === 'validate-instantly') {
                const ret = await validateAll(key, type, value, false);
            }
        };
        // 全部验证回调
        const tryValidateAllAndSubmit: typeof nextOnInteract = async (
            key: any,
            type,
            value,
        ) => {
            const types = getTypes(type);
            const isValidateAll = types.indexOf('validate-all') > -1;
            if (isValidateAll) {
                const ret = await validateAll(key, type, value, true);
                // 如果是一边验证一边提交，则提交的回调使用最新数据以及其验证状态
                if (!_.isNil(ret) && onAction && types.indexOf('submit') > -1) {
                    const { result, isPass, data: nextData } = ret;
                    onAction('submit', nextData, isPass, result);
                }
                // 如果只是提交，使用当前状态就行
            } else if (
                onAction &&
                !_.isNil(data) &&
                !_.isNil(validateResult) &&
                types.indexOf('submit') > -1
            ) {
                const isPass = getIsPassFromValidateResult(validateResult);
                onAction('submit', data, isPass, validateResult);
            }
        };
        if (validateMode === 'instantly') {
            nextOnInteract = async (key: any, type, value) => {
                tryRemoveValidateStatus(key, type, value);
                tryValidateInstantly(key, type, value);
                tryValidateAllAndSubmit(key, type, value);
                prevOnInteract(key, type, value);
            };
        } else if (validateMode === 'lazily') {
            nextOnInteract = async (key: any, type, value) => {
                tryRemoveValidateStatus(key, type, value);
                tryValidateAllAndSubmit(key, type, value);
                prevOnInteract(key, type, value);
            };
        }
        lv1Props.onInteract = nextOnInteract;
    }

    // action 相关
    const { actionWidgets, actionWidgetsGroupOption, statusExtraData } = props;

    const hasAction = actionWidgets && actionWidgets.length > 0;
    let useActionLayout = hasAction;
    const shouldGroup = hasAction && !!actionWidgetsGroupOption;

    if (container === 'modal' && !!containerModalProps) {
        useActionLayout = false;
    }

    const actionElement =
        hasAction && actionWidgets ? (
            <>
                {actionWidgets.map(widget => {
                    const { key, option, status } = widget;
                    let hidden = false;
                    if (status) {
                        const { hidden: culcHidden } = getCommonStatus(
                            status,
                            data,
                            statusExtraData,
                        );
                        hidden = _.isNil(culcHidden) ? hidden : culcHidden;
                    }
                    if (hidden) {
                        return null;
                    }

                    return (
                        <WidgetButton
                            options={option}
                            data={data}
                            value={undefined}
                            status={status}
                            onInteract={(type, value) => {
                                if (lv1Props.onInteract && !_.isNil(data)) {
                                    const isPass = getIsPassFromValidateResult(
                                        validateResult,
                                    );
                                    onAction(
                                        type,
                                        data,
                                        isPass,
                                        validateResult,
                                    );

                                    lv1Props.onInteract(
                                        key as any,
                                        type,
                                        value,
                                    );
                                }
                            }}
                            onChange={() => void 0}
                        />
                    );
                })}
            </>
        ) : (
            <></>
        );

    let groupWrapper = (ele: React.ReactElement) => ele;
    if (shouldGroup) {
        groupWrapper = ele => (
            <ButtonGroup {...actionWidgetsGroupOption}>{ele}</ButtonGroup>
        );
    }

    // 载体相关
    // 后续可以根据交互需求对defaultVisible做统一管控。。。
    let containerWrapper = (ele: React.ReactElement) => ele;
    if (container === 'modal' && !!containerModalProps) {
        // 如果有action，且用户没有制定footer，把这部分element放到
        if (hasAction && _.isNil(containerModalProps.footer)) {
            containerModalProps.footer = groupWrapper(actionElement);
        }
        const modalHasLoading = !_.isNil(containerModalProps.loading);
        containerWrapper = (ele: React.ReactElement) => {
            return (
                <Modal
                    {...containerModalProps}
                    key={containerModalProps.key || undefined}
                >
                    {modalHasLoading ? (
                        <Spin spinning={containerModalProps.loading}>
                            {ele}
                        </Spin>
                    ) : (
                        ele
                    )}
                </Modal>
            );
        };
    }
    if (container === 'drawer' && !!containerDrawerProps) {
        containerWrapper = (ele: React.ReactElement) => {
            return (
                <Drawer {...containerDrawerProps}>
                    <>{ele}</>
                </Drawer>
            );
        };
    }

    return containerWrapper(
        <div style={styleLv2} className={'component-form-lv2 ' + classNameLv2}>
            <FormLV1 {...lv1Props} />
            {useActionLayout && (
                <div className="component-form-lv2-actions">
                    {groupWrapper(actionElement)}
                </div>
            )}
        </div>,
    );
};
