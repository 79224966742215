import React, { useState, useEffect, useContext } from 'react';
import './index.scss';
import { INormalSettingData } from '../store';
import { StoreContext } from '@/stores';
import {
    getAutoOnChangeHandlerOriginal,
    IWidgetItem,
    embedDataToWidgets,
    IFormLV2Props,
    FormLV2,
} from '@/components';
import _ from 'lodash';
import { message } from 'antd';
import { observer } from 'mobx-react-lite';

export interface INormalLimitSettingModalProps {
    setModalVis: (bool: boolean) => void;
    modalVis: boolean;
    modalDefaultData: INormalSettingData;
    setModalDefaultData: (
        modalDefaultData: INormalSettingData | undefined,
    ) => void;
    reloadTable: () => void;
}
const NormalLimitSettingModal: React.FC<INormalLimitSettingModalProps> = props => {
    const store = useContext(StoreContext);
    const CHSPSLStore = store.getDefaultCHSPSLStore();
    const { setNormalLimitData } = CHSPSLStore;
    const {
        setModalVis,
        modalVis,
        modalDefaultData,
        setModalDefaultData,
        reloadTable,
    } = props;
    const [modalData, setModalData] = useState<INormalSettingData>({});
    // tslint:disable-next-line:prefer-const
    useEffect(() => {
        if (modalDefaultData) {
            setModalData({
                ...modalDefaultData,
            });
        }
    }, [modalDefaultData]);
    const hideModal = () => {
        setModalVis(false);
    };
    // 获取默认的数据回调
    const onDataChange = getAutoOnChangeHandlerOriginal(
        modalData as INormalSettingData,
        setModalData,
    );
    const widgets: Array<IWidgetItem<INormalSettingData>> = [
        {
            key: 'userLevel',
            widgetKey: 'input',
            status: {
                disabled: true,
            },
        },
        {
            key: 'maxNum',
            widgetKey: 'input',
            inputOptions: {
                type: 'number',
                min: 0,
                width: '300',
            },
        },
        {
            key: 'isIncludeSign',
            widgetKey: 'switch',
            switchOptions: {
                unCheckedChildren: '关',
                checkedChildren: '开',
            },
        },
        {
            key: 'isIncludeCreate',
            widgetKey: 'switch',
            switchOptions: {
                unCheckedChildren: '关',
                checkedChildren: '开',
            },
        },
    ];
    embedDataToWidgets<INormalSettingData>(widgets, modalData);
    const NomalLimitSetModalProps: IFormLV2Props<INormalSettingData> = {
        data: modalData,
        widgets,
        onChange: onDataChange,
        actionWidgets: [
            {
                key: 'submit',
                option: {
                    btnText: '提交',
                    type: 'primary',
                },
            },
            {
                key: 'cancel',
                option: {
                    btnText: '取消',
                    type: 'default',
                    clickActionType: 'cancel',
                },
            },
        ],
        onAction: async (actionType, data, isPass, validateResult) => {
            if (actionType === 'submit') {
                if (isPass) {
                    const payloadModalData = {
                        ...modalData,
                        isIncludeSign: modalData.isIncludeSign ? 1 : 0,
                        isIncludeCreate: modalData.isIncludeCreate ? 1 : 0,
                    };
                    const [d, e] = await setNormalLimitData(payloadModalData);
                    if (d) {
                        message.success('修改成功', 1);
                        setModalDefaultData(undefined);
                        reloadTable();
                        setModalVis(false);
                    } else {
                        message.error('修改失败', 1);
                    }
                } else {
                    message.error('数据不正确，不可提交');
                }
            }

            if (actionType === 'cancel') {
                setModalVis(false);
            }
        },
        formItemOptions: [
            {
                key: 'userLevel',
                label: '销售级别',
            },
            {
                key: 'maxNum',
                label: '为所有销售设置可拥有的最大客户数',
                required: true,
                className: 'normal-max-limit-items',
            },
            {
                key: 'isIncludeSign',
                label: '包括已签约的客户数',
            },
            {
                key: 'isIncludeCreate',
                label: '包括自建的客户数',
            },
        ],
        validateMode: 'instantly',
        validators: {
            maxNum: datam => {
                if (
                    _.isNil(datam.maxNum) ||
                    _.trim(datam.maxNum as string) === ''
                ) {
                    return {
                        status: 'fail',
                        msg: '请输入客户数',
                    };
                } else {
                    if (datam.maxNum < 0) {
                        return {
                            status: 'fail',
                            msg: '客户数不能小于0',
                        };
                    }
                    return {
                        status: 'success',
                        msg: '',
                    };
                }
            },
        },
        container: 'modal',
        containerModalProps: {
            visible: modalVis,
            title: '设置普通销售最大私池客户数',
            onCancel: () => {
                setModalVis(false);
            },
            width: 600,
            wrapClassName: 'chspl-modal-content',
            destroyOnClose: true,
        },
    };
    return <FormLV2<INormalSettingData> {...NomalLimitSetModalProps}></FormLV2>;
};

export default observer(NormalLimitSettingModal);
