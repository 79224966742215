import React from 'react';
import _ from 'lodash';
import TableCard, { TableCardProps } from '../../components/table-card';
import TableTitle from '../../components/table-title';
import { ColumnProps } from 'antd/lib/table';
import { Key2Tips } from '../../constants';

function PredictTable<T>({ dataSource, loading, ...rest }: TableCardProps<T>) {
    const columns: ColumnProps<T>[] = [
        {
            title: '区域',
            dataIndex: 'name',
            width: 130,
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: <TableTitle title="今日预估" tips={Key2Tips['今日预估']} />,
            dataIndex: 'today_predict',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        {
            title: <TableTitle title="明日预估" tips={Key2Tips['明日预估']} />,
            dataIndex: 'tomorrow_predict',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        {
            title: (
                <TableTitle title="周保守预估" tips={Key2Tips['周保守预估']} />
            ),
            dataIndex: 'week_conservative_predict',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        // {
        //     title: (
        //         <TableTitle
        //             title="周保守预估Gap"
        //             tips={Key2Tips['周保守预估Gap']}
        //         />
        //     ),
        //     dataIndex: 'week_conservative_predict_gap',
        //     render: (t: string) => {
        //         const text = Math.round(Number(t));
        //         return !_.isNaN(text) ? text : '-';
        //     },
        // },
        {
            title: (
                <TableTitle title="周冲刺预估" tips={Key2Tips['周冲刺预估']} />
            ),
            dataIndex: 'week_spurt_predict',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        // {
        //     title: (
        //         <TableTitle
        //             title="周冲刺预估Gap"
        //             tips={Key2Tips['周冲刺预估Gap']}
        //         />
        //     ),
        //     dataIndex: 'week_spurt_predict_gap',
        //     render: (t: string) => {
        //         const text = Math.round(Number(t));
        //         return !_.isNaN(text) ? text : '-';
        //     },
        // },
        {
            title: <TableTitle title="月目标" tips={Key2Tips['月目标']} />,
            dataIndex: 'month_target',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        {
            title: (
                <TableTitle title="月保守预估" tips={Key2Tips['月保守预估']} />
            ),
            dataIndex: 'month_conservative_predict',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        // {
        //     title: (
        //         <TableTitle
        //             title="月保守预估Gap"
        //             tips={Key2Tips['月保守预估Gap']}
        //         />
        //     ),
        //     dataIndex: 'month_conservative_predict_gap',
        //     render: (t: string) => {
        //         const text = Math.round(Number(t));
        //         return !_.isNaN(text) ? text : '-';
        //     },
        // },
        {
            title: (
                <TableTitle
                    title="月保守预估达成率"
                    tips={Key2Tips['月保守预估达成率']}
                />
            ),
            dataIndex: 'month_conservative_predict_completion_rate',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? `${text}%` : '-';
            },
        },
        {
            title: (
                <TableTitle title="月冲刺预估" tips={Key2Tips['月冲刺预估']} />
            ),
            dataIndex: 'month_spurt_predict',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        // {
        //     title: (
        //         <TableTitle
        //             title="月冲刺预估GAP"
        //             tips={Key2Tips['月冲刺预估Gap']}
        //         />
        //     ),
        //     dataIndex: 'month_spurt_predict_gap',
        //     render: (t: string) => {
        //         const text = Math.round(Number(t));
        //         return !_.isNaN(text) ? text : '-';
        //     },
        // },
        {
            title: (
                <TableTitle
                    title="月冲刺预估达成率"
                    tips={Key2Tips['月冲刺预估达成率']}
                />
            ),
            dataIndex: 'month_spurt_predict_completion_rate',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? `${text}%` : '-';
            },
        },
        {
            title: <TableTitle title="月度稳回" tips={Key2Tips['月度稳回']} />,
            dataIndex: 'month_steady',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        {
            title: (
                <TableTitle
                    title="月度大概率回"
                    tips={Key2Tips['月度大概率回']}
                />
            ),
            dataIndex: 'month_large_probability',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        {
            title: <TableTitle title="双月目标" tips={Key2Tips['双月目标']} />,
            dataIndex: 'session_target',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        {
            title: (
                <TableTitle
                    title="双月保守预估"
                    tips={Key2Tips['双月保守预估']}
                />
            ),
            dataIndex: 'session_conservative_predict',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        // {
        //     title: (
        //         <TableTitle
        //             title="季度保守预估Gap"
        //             tips={Key2Tips['季度保守预估Gap']}
        //         />
        //     ),
        //     dataIndex: 'quarter_conservative_predict_gap',
        //     render: (t: string) => {
        //         const text = Math.round(Number(t));
        //         return !_.isNaN(text) ? text : '-';
        //     },
        // },
        {
            title: (
                <TableTitle
                    title="双月保守预估达成率"
                    tips={Key2Tips['双月保守预估达成率']}
                />
            ),
            dataIndex: 'session_conservative_predict_completion_rate',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? `${text}%` : '-';
            },
        },
        {
            title: (
                <TableTitle
                    title="双月冲刺预估"
                    tips={Key2Tips['双月冲刺预估']}
                />
            ),
            dataIndex: 'session_spurt_predict',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        // {
        //     title: (
        //         <TableTitle
        //             title="季冲刺预估GAP"
        //             tips={Key2Tips['季冲刺预估Gap']}
        //         />
        //     ),
        //     dataIndex: 'quarter_spurt_predict_gap',
        //     render: (t: string) => {
        //         const text = Math.round(Number(t));
        //         return !_.isNaN(text) ? text : '-';
        //     },
        // },
        {
            title: (
                <TableTitle
                    title="双月冲刺预估达成率"
                    tips={Key2Tips['双月冲刺预估达成率']}
                />
            ),
            dataIndex: 'session_spurt_predict_completion_rate',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? `${text}%` : '-';
            },
        },
        {
            title: <TableTitle title="双月稳回" tips={Key2Tips['双月稳回']} />,
            dataIndex: 'session_steady',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
        {
            title: (
                <TableTitle
                    title="双月大概率回"
                    tips={Key2Tips['双月大概率回']}
                />
            ),
            dataIndex: 'session_large_probability',
            render: (t: string) => {
                const text = Math.round(Number(t));
                return !_.isNaN(text) ? text : '-';
            },
        },
    ];

    return (
        <TableCard
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            className="board-solar-table"
            style={{ boxShadow: 'none', padding: 0 }}
            rowKey="name"
            scroll={{ x: 3200, y: 355 }}
            // scroll={{ x: 'max-content'}}
            bodyStyle={{ maxHeight: 355 }}
            {...rest}
        />
    );
}

export default PredictTable;
