import React, { useEffect, useCallback } from 'react';
import { useState, useContext } from 'react';
import './index.scss';
import CommonDrawer from '@/components/common/drawer/index';
import { IDrawerTitleProps } from '@/components/common/drawer/drawer-title';
import { Icon, message, Menu, Select, DatePicker, Popover } from 'antd';
import { IDrawerCardListProps } from '@/components/common/drawer/drawer-card-list/index';
import ItemUser, {
    IItemsUserProps,
} from '@/components/common/items/user/index';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import ItemPhone from '@/components/common/items/phone';
import { IDrawerTabsProps } from '@/components/common/drawer/drawer-tabs/index';
import DrawCollapse from '@/components/common/drawer/drawer-collapse';
import { IDrawerCollapseProps } from '@/components/common/drawer/drawer-collapse/index';
import DataReview from '@/components/common/items/data-review/index';
import { defaultAxios, useNet, useRefresh } from '@/utils';
import _ from 'lodash';
import moment from 'moment';
import CustomerActionLog from '@/components/common/customer-drawer/customer-action-log';
import { ContactListStore, useAnyContactsStore } from '../store';
import AddFollowLog from '@/components/common/add-follow-log-new';
import LogModal from '../../../../../components/common/log-modal/index';
import { CC } from '@/components/common/table/cells/cc';
import { DDetail } from '@/components/common/editable-display/ddetail';
import { makeObjColClickable, isMultipleCell } from '@/components';
import { CtxProvider } from '@/utils/context';
import { CustomerActivityFollowJobCreate } from '@/pages/sales-follow-up/job/jobs/lead/components/created-by-cus-activity';

// 抽屉针对客户业务的二次封装，高度业务定制
interface ICrmContactDrawer {
    contactsListStore: ContactListStore;
    drawerVis: boolean;
    setDrawerVis: (bool: boolean) => void;
    detailId?: string | number;
    cid?: string | number;
    destroyOnClose?: boolean;
    onEdit?: (data: any) => void;
    onCreat?: (data: any) => void;
}
interface ICrmDetailData {
    detailInfo: IDrawerCollapseProps;
    originData: any;
    // 此处可维护静态字段
    staticData: {
        name: string; // 客户名称
        id: string | number; // 客户id
        owner_id: any; // 关联字段 - 客户所有人
    };
    userField: any;
    actionLogConfig: {
        arType: any[];
        followType: any[];
    }; // 活动记录基础配置
}
export type TGetCrmDetailReturnType = NetResponse<{
    data: ICrmDetailData;
    msg: string;
    code: number;
}>;
const ContactDrawer: React.FC<ICrmContactDrawer> = props => {
    const {
        contactsListStore,
        drawerVis,
        setDrawerVis,
        detailId,
        cid,
        destroyOnClose = false,
        onCreat,
        onEdit,
    } = props;

    const { listType, defaultPerm } = contactsListStore;
    const eventCode = listType === 'all' ? 'PUBLIC-VIEW' : 'PRIVATE-VIEW';

    const [
        customerActivityFollowJobCreateTrigger,
        setCustomerActivityFollowJobCreateTrigger,
    ] = useState(0);
    const [createdFollowLog, setCreatedFollowLog] = useState<any>(null);

    const [drawerData, setDrawerData] = useState<any>({});
    const [followActionModalVis, setFollowActionModalVis] = useState(false);
    const [followActionInitMark, setFollowActionInitMark] = useState(false);
    const [logModalVis, setLogModalVis] = useState(false);

    const [prepared, defaultContactsListStore] = useAnyContactsStore({});

    const loadDetail = () => {
        if (drawerVis && prepared) {
            if (detailId) {
                defaultContactsListStore.fetchInstantlyMutatingDataById(
                    detailId,
                );
            } else {
                defaultContactsListStore.setMutatingData(null);
            }
        } else {
            defaultContactsListStore.setMutatingData(null);
        }
    };
    useEffect(loadDetail, [drawerVis, detailId, prepared]);
    useRefresh(
        ['contacts'],
        () => {
            loadDetail();
        },
        [drawerVis, detailId, prepared],
    );

    const [dataFail, setDataFail] = useState(false);

    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const fetchCustomerDetail: () => Promise<
        TGetCrmDetailReturnType
    > = async () => {
        const thisCid = detailId;
        if (_.isNil(detailId)) {
            return [null, null];
        }

        const { fieldAuthCode, dataAuthCode } = contactsListStore;

        const [d, e] = await defaultAxios.get(
            `/bff/api/rest/contacts/detail?id=${detailId}&fieldAuthCode=${fieldAuthCode}&dataAuthCode=${dataAuthCode}`,
        );
        if (d === null || d.data === null) {
            setDataFail(true);
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d && d.data) {
            if (
                !d.data.originData ||
                _.isEmpty(d.data.originData) ||
                !d.data.originData?.length
            ) {
                setDataFail(true);
                return [null, new Error('no auth')];
            }
            if (thisCid === detailId) {
                setDataFail(false);
                setDrawerData(d.data);
            }
        }
        return [data, error];
    };
    const [error, loading, reload] = useNet(fetchCustomerDetail, {
        refreshKeys: ['contacts'],
    });
    useEffect(() => {
        if (drawerVis) {
            if (detailId) {
                reload();
            }
        } else {
            // 当客户抽屉关闭，将会清空里面的所有数据
            setDrawerData({});
        }
    }, [detailId, drawerVis]);
    const dropMenu = (
        <Menu
            onClick={e => {
                if (e.key === '1') {
                    setLogModalVis(true);
                }
            }}
        >
            <Menu.Item key="1">查看操作记录</Menu.Item>
        </Menu>
    );

    const permFollow = !!defaultPerm.getPermByTypeAndAction('')('follow')
        .visible;

    const permUpdate = !!defaultPerm.getPermByTypeAndAction('')('update')
        .visible;

    const permViewOpRecord = !!defaultPerm.getPermByTypeAndAction('')(
        'view-operate-record',
    ).visible;

    const staticData = drawerData?.staticData || {};
    const originData = drawerData?.originData
        ? drawerData?.originData[0] || {}
        : {};
    const titleExampleConfig: IDrawerTitleProps = {
        title: (
            <>
                <span
                    className="iconfont1"
                    style={{
                        color: '#0052ff',
                        fontSize: '18px',
                        marginRight: '8px',
                    }}
                >
                    &#xe614;
                </span>
                {staticData?.contacts_name}
            </>
        ),
        icons: [],
        tags: [],
        buttons: [],
    };

    if (_.isArray(titleExampleConfig.buttons) && permUpdate) {
        titleExampleConfig.buttons.push({
            type: 'button',
            buttonConfig: {
                children: '编辑',
                onClick: () => {
                    // tslint:disable-next-line:no-unused-expression
                    typeof onEdit === 'function' &&
                        onEdit(
                            drawerData?.originData
                                ? drawerData?.originData[0]
                                : {},
                        );
                },
            },
        });
    }

    if (_.isArray(titleExampleConfig.buttons) && permViewOpRecord) {
        titleExampleConfig.buttons.push({
            type: 'drop',
            dropButtonConfig: {
                overlay: dropMenu,
                buttonConfig: {
                    type: 'default',
                    style: {
                        padding: 0,
                        backgroundColor: 'rgb(244, 245, 250)',
                    },
                    children: (
                        <span
                            className="iconfont1"
                            style={{
                                fontSize: '12px',
                                transform: 'scale(0.35)',
                            }}
                        >
                            &#xe618;
                        </span>
                    ),
                },
            },
        });
    }

    if (dataFail) {
        titleExampleConfig.buttons = [];
    }
    const customerObj = staticData?.customer_id;
    const customerId = customerObj?.originalValue;
    const cardExampleConfig: IDrawerCardListProps = {
        dataSource: [
            {
                title: '客户名称',
                content: originData?.customer_name ? (
                    <a
                        target="_blank"
                        href={
                            '/buy/custom/my-custom?target=contacts-drawer-header&id=' +
                            originData?.customer_id?.originalValue
                        }
                    >
                        {originData?.customer_name || '- -'}
                    </a>
                ) : (
                    '- -'
                ),
            },
            {
                title: '手机',
                // content: staticData?.contacts_phone || '- -',
                content: staticData?.contacts_phone ? (
                    <CC
                        args={[staticData?.contacts_phone]}
                        config={{
                            customerName: originData?.customer_name,
                            customerId,
                            contactsId: detailId ? Number(detailId) : undefined,
                            showFollowLog: true,
                        }}
                        isHidden={true}
                        sceneCode={'crm_contacts'}
                    />
                ) : (
                    '- -'
                ),
            },
            {
                title: '职务',
                content: staticData?.contacts_duty || '- -',
            },
            {
                title: '工作邮箱',
                content: staticData?.contacts_work_email || '- -',
            },
            {
                title: '最近活动记录时间',
                content: staticData?.recent_activity_record_time
                    ? moment(staticData?.recent_activity_record_time).format(
                          'YYYY-MM-DD HH:mm:ss',
                      )
                    : '- -',
            },
        ],
    };
    const customExampleCollapseConfig: IDrawerCollapseProps =
        drawerData.detailInfo;
    const tabsExampleConfig: IDrawerTabsProps = {
        tabsContentConfig: {
            defaultActiveKey: '1',
            tabBarGutter: 24,
            tabConfig: [
                {
                    key: '1',
                    tab: '联系人信息',
                    content: (
                        <DDetail
                            eventCode={eventCode}
                            defaultStore={defaultContactsListStore}
                            cellsSelectorTransformer={originalbaiscCellsSelector => {
                                const baiscCellsSelector = [
                                    ...originalbaiscCellsSelector,
                                ];
                                _.forEach(baiscCellsSelector, cItem => {
                                    if (isMultipleCell(cItem)) {
                                        return cItem;
                                    }
                                    // if (cItem.cell === 'CC') {
                                    //     cItem.inputs.push('crm_contact');
                                    // }
                                    if (cItem.cell === 'CC') {
                                        if (cItem.inputs.length < 3) {
                                            cItem.inputs = [
                                                ...cItem.inputs,
                                                'crm_contacts',
                                            ];
                                        } else {
                                            cItem.inputs[2] = 'crm_contacts';
                                        }
                                        // cItem.inputs.push('crm_lead');
                                    }
                                });
                                // 详情可点击
                                makeObjColClickable(
                                    baiscCellsSelector,
                                    'customer_id',
                                );
                                _.forEach(baiscCellsSelector, cItem => {
                                    if (isMultipleCell(cItem)) {
                                        return cItem;
                                    }
                                    if (cItem.cell === 'CC') {
                                        cItem.inputs.push('crm_contacts');
                                    }
                                });
                                makeObjColClickable(
                                    baiscCellsSelector,
                                    'lead_id',
                                );
                                return baiscCellsSelector;
                            }}
                        />
                    ),
                },
            ],
        },
    };
    return (
        <CtxProvider
            ctx={{ position: 'drawer', positionDetail: 'contacts-drawer' }}
        >
            <LogModal
                modalVis={logModalVis}
                setModalVis={setLogModalVis}
                entityCode="contacts"
                entityValueId={detailId}
            />
            <CommonDrawer
                isFail={dataFail}
                destroy={destroyOnClose ? !drawerVis : false}
                loading={loading}
                titleConfig={titleExampleConfig}
                DrawerContentConfig={{
                    zIndex: 999,
                    placement: 'right',
                    onClose: () => {
                        setDrawerVis(false);
                    },
                    visible: drawerVis,
                }}
                extraRight={
                    <>
                        {originData?.customer_id?.originalValue && (
                            <CustomerActivityFollowJobCreate
                                customers={[
                                    originData?.customer_id?.relatedObj,
                                ]}
                                followLog={createdFollowLog}
                                trigger={customerActivityFollowJobCreateTrigger}
                                onSuccess={() => {}}
                            />
                        )}
                        <AddFollowLog
                            isOpenPopover={false}
                            entityType={'contacts'}
                            cid={customerId}
                            id={detailId}
                            drawerVis={followActionModalVis}
                            setDrawerVis={setFollowActionModalVis}
                            targetFixed={true}
                            addFollowData={{
                                contactsId: detailId as number,
                            }}
                            initMark={followActionInitMark}
                            onCreated={data => {
                                // 这里检查这条活动数据，满足一定条件就弹任务窗口
                                if (data.isCreateJob === 1) {
                                    setCreatedFollowLog(data);
                                    setCustomerActivityFollowJobCreateTrigger(
                                        1 +
                                            customerActivityFollowJobCreateTrigger,
                                    );
                                }
                            }}
                        />
                        <CustomerActionLog
                            setFollowLogModalVis={bool => {
                                setFollowActionModalVis(bool);
                                setFollowActionInitMark(true);
                                setTimeout(() => {
                                    setFollowActionInitMark(false);
                                }, 0);
                            }}
                            entityType={'contacts'}
                            id={detailId}
                            actionLogConfig={drawerData?.actionLogConfig}
                            drawerVis={drawerVis}
                            targetFixed={true}
                            defaultArType={'3'}
                            ArTypeSelectVisible={false}
                            permFollow={permFollow}
                        />
                    </>
                }
                DrawerCardListConfig={cardExampleConfig}
                DrawerTabsConfig={tabsExampleConfig}
            />
        </CtxProvider>
    );
};

export default observer(ContactDrawer);
