import { makeAutoObservable } from 'mobx';
import React from 'react';
import _ from 'lodash';
import { message } from 'antd';
import { login, logout, call, closeCall, listenCallStatusChange } from './api';
import { makeTicker, timeout, defaultAxios, delay } from '@/utils';

// 两种状态
// 空闲 通话
// 空闲 -> 通话：登录,判错  外呼,判错
// 通话 -> 空闲：挂断,不判错,超时认为成功   登出,不判错,超时认为成功,循环检测(未锁, 非外呼, 已登录)

export class CCStore {
    public actionId = '';
    public setActionId = (id: any) => {
        this.actionId = id;
    };
    public recordId = '';
    public getRecordId = async () => {
        const url =
            this.supplier === 'baichuan'
                ? '/crm/callRecord/addBaiChuan'
                : '/crm/callRecord/add';
        const idKey = this.supplier === 'baichuan' ? 'thirdId' : 'actionId';
        const { customerId, contactsId } = this.callActionConfig;
        const [d, e] = await defaultAxios.get(url, {
            [idKey]: this.actionId,
            customerId,
            contactsId,
        });

        const data = d?.data;
        const error = e as Error | null;
        if (data) {
            this.recordId = data;
        }
        return [data, error];
    };
    public actionLogConfig: {
        arType: {
            label: string;
            value: string | number;
        }[];
        followType: {
            label: string;
            value: string | number;
        }[];
        followTarget: {
            label: string;
            value: string | number;
        }[];
        taskFollowTarget: {
            label: string;
            value: string | number;
        }[];
    } | null = null;

    public fetchActionLogConfig = async (cb?: any) => {
        const [d, e] = await defaultAxios.get(
            `/bff/api/rest/customer/action-log-config`,
        );
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d && d.data) {
            this.actionLogConfig = d.data;
            cb && typeof cb === 'function' && cb(d.data);
            // const phoneFollowType = _.find(d.data?.followType, item => {
            //     return item.label === '电话';
            // });
            // setActionLogValue({
            //     ...actionLogValue,
            //     followType: phoneFollowType.value || 0,
            // });
        }
        return [data, error];
    };

    public supplier = 'clink';
    public get supplierDesc() {
        const desc = {
            supplierCn: '',
            no: '',
        };
        if (this.supplier === 'clink') {
            desc.supplierCn = '天润';
            desc.no = this.context.cno + '';
        }
        if (this.supplier === '7moor') {
            desc.supplierCn = '七陌';
            desc.no = (this.context.loginName + '').split('@')[0];
        }
        if (this.supplier === 'baichuan') {
            desc.supplierCn = '百川';
            desc.no = this.context.agent + '';
        }
        return desc;
    }
    public context: any = null;
    public setContext(supplier: string, context: any) {
        this.supplier = supplier;
        this.context = context;
    }

    public username: string = '';
    public setUsername(nextUsername: string) {
        this.username = nextUsername;
    }

    public get noLogout() {
        return this.supplier === '7moor';
    }

    // public callingTime2 = 0
    // public updateCallingTime2 = (nextCallingTime: number) => {
    //     this.callingTime2 = nextCallingTime
    // }
    // public ticker2 = makeTicker(this.updateCallingTime2)

    public callingTime = 0;
    public updateCallingTime = (nextCallingTime: number) => {
        this.callingTime = nextCallingTime;
    };
    public ticker = makeTicker(this.updateCallingTime);
    public get showCallingTime() {
        return this.callingTime != 0;
    }

    public get isLogined() {
        return this.status !== 0;
    }
    public status = 0;
    public get isInCall() {
        const { status, callingStatus } = this;
        if (status === 99) {
            if (callingStatus === 3) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    public get statusText() {
        const {
            status,
            callingStatus,
            loginLoading,
            logoutLoading,
            handleNewCallLoading,
            closeCallLoading,
        } = this;

        if (status === 0) {
            if (loginLoading) {
                return '登陆中...';
            } else {
                return '空闲中';
            }
        }

        if (logoutLoading) {
            return '注销中...';
        }

        if (handleNewCallLoading) {
            return '通话处理中...';
        }

        if (closeCallLoading) {
            return '通话挂断中...';
        }

        // 状态暂时精简一下
        if (status === 1) {
            return '退出中...';
            // return '已登录';
            // return '已登录-空闲'
        }

        if (status === 99) {
            // 泛指一众通话状态：
            // 开始
            // 响铃
            // 对话
            if (callingStatus === 1) {
                return '呼叫中...';
            }
            if (callingStatus === 2) {
                return '呼叫中...';
            }
            if (callingStatus === 3) {
                return '通话中';
            }
            return '通话处理中...';
        }
    }

    public callingStatus = 0;

    public get somethingIsLoading() {
        const {
            loginLoading,
            logoutLoading,
            handleNewCallLoading,
            closeCallLoading,
        } = this;
        return (
            loginLoading ||
            logoutLoading ||
            handleNewCallLoading ||
            closeCallLoading
        );
    }

    public loginLoading = false;
    public async login() {
        this.loginLoading = true;
        const isSuccess = await login(this);
        if (!isSuccess) {
            message.error(
                '呼叫中心登录失败，可能是坐席配置有误，联系销售运营-胥言处理',
            );
        }
        this.loginLoading = false;
        if (isSuccess) {
            this.status = 1;
            listenCallStatusChange(this, {
                onStart: ret => {
                    this.callingStatus = 1;
                    this.updateCallingTime(0);
                },
                onRinging: ret => {
                    this.callingStatus = 2;
                },
                onBridge: ret => {
                    this.callingStatus = 3;
                    this.ticker.start();
                },
                // 断开，重连
                onBreak: ret => {
                    this.afterClose(true);
                    this.afterLogout(true);
                },
                // ！！！！其实没有被动挂断这回事，对方挂断了，这边仍然会有声音
                // 还是自己手动挂断，其实跟日常打电话是一样的
                // TODO 对方终止外呼叫
                // TODO 断线
            });
        }
        return isSuccess;
    }

    locked = false;
    public lock() {
        this.locked = true;
    }
    public unlock() {
        this.locked = false;
    }

    get isLogin() {
        return this.status !== 0;
    }

    public logoutLoading = false;
    public async logout() {
        this.logoutLoading = true;
        const isSuccess = await Promise.race([
            logout(this),
            // 给个超时，销售等不及
            // 如果不logout，会提示会话不对，但是能打电话。。
            timeout<boolean>(500, true),
        ]);
        this.logoutLoading = false;
        // 如果不成功，还需要程序尝试登出
        this.afterLogout(true);
    }

    public async afterLogout(isSuccess: boolean) {
        if (isSuccess) {
            this.status = 0;
        }
    }

    public isCalling() {
        return this.handleNewCallLoading || this.status === 99;
    }
    public currentCallPhoneNum: string | null = null;
    public isHiddenCallPhoneNum: boolean = false;
    public handleNewCallLoading = false;

    public callErrorMessage = '';
    public setCallErrorMessage = (msg: string) => {
        this.callErrorMessage = msg;
    };

    public callActionConfig: {
        customerName?: string;
        customerId?: string | number;
        contactsId?: string | number;
        leadId?: string | number;
        showFollowLog?: boolean;
        sceneCode?: string;
        [key: string]: any;
    } = {};
    public resetCallActionConfig = () => {
        this.callActionConfig = {};
    };
    public async handleNewCall(
        phoneNum: string,
        isHidden?: boolean,
        config?: {
            customerName?: string;
            customerId?: string | number;
            contactsId?: string | number;
            showFollowLog?: boolean;
            sceneCode?: string;
            leadId?: string | number;
            userName?: string;
        },
    ) {
        this.isHiddenCallPhoneNum = !!isHidden;
        this.handleNewCallLoading = true;
        this.currentCallPhoneNum = phoneNum;

        if (config) {
            this.callActionConfig = config;
        } else {
            this.callActionConfig = {};
        }

        const isSuccess = await call(this);
        if (isSuccess) {
            // 如果成了，delay 1s，为啥呢？
            // 因为要阻止快速挂断，太快挂断会让话机与电话条不同步
            await delay(1200);
        }
        this.handleNewCallLoading = false;
        if (isSuccess) {
            this.status = 99;

            // 这里打开活动记录
        } else {
            this.currentCallPhoneNum = null;
            if (this.callErrorMessage === 'limited by rms') {
                message.error(
                    '外呼失败，当前号码已被风控标记，请稍后在试！联系销售运营-胥言处理',
                );
            } else {
                message.error(
                    <div style={{ textAlign: 'left' }}>
                        <div>外呼失败，原因可能是：</div>
                        <div style={{ color: 'red' }}>1）话机未挂断</div>
                        <div>2）网络不通</div>
                        <div>3）手机号格式错误</div>
                    </div>,
                );
            }
        }
    }

    // 注意，挂断操作可以打断正在链接的通话
    public closeCallLoading = false;
    public async closeCall() {
        this.closeCallLoading = true;
        let isSuccess = await Promise.race([
            closeCall(this),
            timeout<boolean>(800, false), // 如果对方挂断，这边接收不到，认为通话结束成功
        ]);

        if (!isSuccess) {
            isSuccess = await Promise.race([
                closeCall(this),
                timeout<boolean>(800, false), // 如果对方挂断，这边接收不到，认为通话结束成功
            ]);
        }

        if (!isSuccess) {
            isSuccess = await Promise.race([
                closeCall(this),
                timeout<boolean>(800, true), // 如果对方挂断，这边接收不到，认为通话结束成功
            ]);
        }

        this.closeCallLoading = false;
        this.afterClose(true);
        // this.afterClose(isSuccess as boolean);
    }

    public onCallStart(actionId: any) {
        if (this.actionId === actionId) {
            return;
        }
        this.updateCallingTime(0);
        this.setActionId(actionId);
        this.recordId = '';
        if (actionId) {
            this.getRecordId();
        }
    }

    public afterClose(isSuccess: boolean) {
        if (isSuccess) {
            this.currentCallPhoneNum = null;
            this.status = 1;
            this.callingStatus = 0;
            this.ticker.reset();
            // this.updateCallingTime(0);
        } else {
            message.error('外呼取消失败，联系销售运营-胥言处理');
        }
    }

    public handles: null | {
        onStart: (ret: any) => void;
        onRinging: (ret: any) => void;
        onBridge: (ret: any) => void;
        onBreak: (ret: any) => void;
    } = null;

    constructor() {
        makeAutoObservable(this, {});
    }
}
