import React, { useEffect, useCallback } from 'react';
import { useState } from 'react';
import { Alert, Divider } from 'antd';
import { arrToJson, isNetSuccess, useRefresh } from '@/utils';
import _ from 'lodash';
import moment from 'moment';
import { CtxProvider } from '@/utils/context';

import { LeadJobStore, useAnyLeadJobStore } from '../store';
import { opLowerCases } from '../../../utils';
import { runInAction } from 'mobx';
import { LeadActions } from '../components/actions';
import LeadJobFormDrawer from '../components/form-drawer';
import { loadingWrapper } from '@/components';
import { cusFollowOpBlickList } from '../components/utils';
import { observer } from 'mobx-react-lite';
import { commonQueryJob } from '@/api/rest';

// 抽屉针对客户业务的二次封装，高度业务定制
interface IJobShortCutProps {
    detailId?: string | number;
    trigger?: number;
}

export const CustomerLastestJobShortCut: React.FC<{
    cusId?: string | number;
}> = props => {
    const { cusId } = props;

    const [detailId, setDetailId] = useState<any>(null);
    const [trigger, setTrigger] = useState(0);
    useRefresh(['task_activation'], () => setTrigger(1 + trigger));

    useEffect(() => {
        if (!cusId) {
            return;
        }
        commonQueryJob(
            'task_activation',
            {
                pageSize: 1,
                pageNum: 1,
            },
            {
                filters: [
                    {
                        fieldId: 'follow_customer',
                        filterValue: cusId,
                        operateType: '=',
                    },
                    {
                        fieldId: 'task_state',
                        filterValue: '1,2,3',
                        operateType: 'in',
                    },
                ],
            },
            {
                orderBy: 'end_time',
                sortStrategy: 0, // 升
            },
            'PUBLIC-VIEW',
            undefined,
            'SEARCH-PUBLIC',
        ).then(([d, e]) => {
            if (isNetSuccess(d, e)) {
                if (d?.datas[0]) {
                    setDetailId(d?.datas[0].id);
                } else {
                    setDetailId(undefined);
                }
            } else {
                setDetailId(undefined);
            }
        });
    }, [cusId, trigger]);

    if (!cusId || !detailId) {
        return null;
    }
    return <JobShortCut detailId={detailId} trigger={trigger} />;
};

export const JobShortCut: React.FC<IJobShortCutProps> = observer(props => {
    const { detailId, trigger } = props;

    // 这里一个是搞列表的，一个是搞操作的
    const [listPrepared, defaultStoreList, prepare] = useAnyLeadJobStore({
        autoLoad: false,
    });

    const { mutatingData, defaultPerm, mutatingDataLoading } = defaultStoreList;

    useEffect(() => {
        if (detailId) {
            prepare();
        }
    }, [detailId]);

    const loadDetail = () => {
        if (listPrepared) {
            if (detailId) {
                defaultStoreList.fetchInstantlyMutatingDataById(detailId);
            } else {
                defaultStoreList.setMutatingData(null);
            }
        } else {
            defaultStoreList.setMutatingData(null);
        }
    };
    useEffect(loadDetail, [trigger, detailId, listPrepared]);
    useRefresh(['task_activation'], loadDetail);

    const [defaultStoreAction] = useState(() => {
        const store = new LeadJobStore();
        store.defaultMeta.setTableId('task_activation');
        return store;
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);
    useEffect(() => {
        Promise.all([defaultStoreAction.defaultMeta.fetch()])
            .then(() => {
                setPreRequiredReady(true);
            })
            .catch(() => {});
    }, [defaultStoreAction]);

    const getPerm = (key: string) => {
        return !defaultPerm.inited || defaultPerm.loading
            ? false
            : defaultPerm.getPermByTypeAndAction('')(key).visible;
    };

    const buttons = [
        ...(mutatingData?.object_ops || [])
            .filter((op: string) => !cusFollowOpBlickList.has(op))
            .filter((op: string) => {
                return getPerm(op);
            })
            .map((op: string) => {
                return {
                    text: opLowerCases[op],
                    onClick: () => {
                        if (op === 'update') {
                            runInAction(() => {
                                defaultStoreAction.setAction('update', {
                                    id: mutatingData.id,
                                });
                            });
                        } else if (op === 'complete') {
                            runInAction(() => {
                                defaultStoreAction.setSubActionType('complete');
                                defaultStoreAction.setAction('update', {
                                    id: mutatingData.id,
                                });
                            });
                        } else if (op === 'cancel') {
                            runInAction(() => {
                                defaultStoreAction.setAction('cancel', {
                                    id: mutatingData.id,
                                });
                            });
                        }
                    },
                };
            }),
    ].filter(Boolean);

    return (
        <CtxProvider
            ctx={{
                position: 'drawer',
                positionDetail: 'job-drawer',
            }}
        >
            <LeadActions defaultStore={defaultStoreAction} />
            <LeadJobFormDrawer
                defaultStore={defaultStoreAction}
                preRequiredReady={preRequiredReady}
            />
            {loadingWrapper(mutatingDataLoading)(
                <Alert
                    message={
                        <>
                            <div>
                                {mutatingData?.end_time ? (
                                    <>
                                        最近一次任务到期时间为
                                        <span style={{ color: '#FFA408' }}>
                                            {moment(
                                                mutatingData?.end_time,
                                            ).format('YYYY-MM-DD HH:mm:ss')}
                                            {(() => {
                                                // http://crm-test.in.taou.com/buy/job-management/cus-follow?id=87
                                                const daysDiff = moment(
                                                    mutatingData?.end_time,
                                                ).diff(moment(), 'day');
                                                if (daysDiff >= 1) {
                                                    return (
                                                        <span>
                                                            （剩余{daysDiff}天）
                                                        </span>
                                                    );
                                                }
                                                if (daysDiff <= -1) {
                                                    return (
                                                        <span>
                                                            （已逾期{-daysDiff}
                                                            天）
                                                        </span>
                                                    );
                                                }
                                                return <span />;
                                            })()}
                                        </span>
                                        ，
                                    </>
                                ) : null}
                                {mutatingData?.follow_target ? (
                                    <>
                                        拜访目的是
                                        <span style={{ color: '#FFA408' }}>
                                            {
                                                followTargetsMap[
                                                    mutatingData?.follow_target
                                                ]?.label
                                            }
                                        </span>
                                    </>
                                ) : null}
                                {mutatingData?.task_contents
                                    ? ` - ${mutatingData?.task_contents}`
                                    : null}
                            </div>
                            <div>
                                <a
                                    target="__blank"
                                    href={
                                        '/buy/job-management/cus-follow?id=' +
                                        detailId
                                    }
                                >
                                    {'任务详情'}
                                </a>
                                {!!buttons.length && (
                                    <Divider type="vertical" />
                                )}
                                {buttons.map((btnConf, index) => {
                                    const btn = (
                                        <a onClick={btnConf.onClick}>
                                            {btnConf.text + '任务'}
                                        </a>
                                    );
                                    if (index === 0) {
                                        return btn;
                                    }
                                    return (
                                        <>
                                            <Divider type="vertical" />
                                            {btn}
                                        </>
                                    );
                                })}
                            </div>
                        </>
                    }
                    type="warning"
                    showIcon
                />,
            )}
        </CtxProvider>
    );
});

const followTargets = [
    {
        label: '拜访联建',
        value: 1,
    },
    {
        label: '客情维护',
        value: 2,
    },
    {
        label: '高层拜访',
        value: 3,
    },
    {
        label: '售前商务洽谈',
        value: 4,
    },
    {
        label: '培训工作',
        value: 5,
    },
    {
        label: '问题处理',
        value: 6,
    },
    {
        label: '售后使用报告',
        value: 7,
    },
    {
        label: '售后续约跟进',
        value: 8,
    },
];

const followTargetsMap = arrToJson(followTargets, 'value');
