import React, { useEffect } from 'react';
import {
    useAnyLeadStore,
    genLeadIdParamByContractItemAndParamMeta,
} from '@/pages/sales-follow-up/lead/list/store';
import OptForm from '@/pages/sales-follow-up/opt/list/components/form-drawer';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Modal, message } from 'antd';
import { useAnyOptStore } from '@/pages/sales-follow-up/opt/list/store';
import _ from 'lodash';
import { arrToJson, tryRefresh } from '@/utils';

// http://crm-test.in.taou.com/crm/metadata/opportunity
// http://crm-test.in.taou.com/crm/metadata/lead

export const leadSource2optSourceMapping: any = {
    2: 1,
    7: 1,
    11: 1,
    14: 1,
    15: 1,
    16: 1,
    17: 1,
};

const leadSourceEnums = [
    {
        label: '市场活动',
        value: 2,
    },
    {
        label: '页面留资',
        value: 7,
    },
    {
        label: '个人会员',
        value: 11,
    },
    {
        label: '端内行为',
        value: 14,
    },
    {
        label: 'Callin线索（客服）',
        value: 15,
    },
    {
        label: '竞品',
        value: 16,
    },
    {
        label: '其他',
        value: 17,
    },
];
export const leadSourceEnumsMap = arrToJson(leadSourceEnums, 'value');

export const LeadOptCreate: React.FC<{
    trigger: number;
    leadInfo: any;
}> = observer(({ trigger, leadInfo }) => {
    leadInfo = leadInfo || {};
    const customerId = leadInfo.customer_id;
    const leadId = leadInfo.id;

    const [prepared, defaultStore, prepareStore] = useAnyOptStore({
        opUserSuffix: 'customer-drawer',
        autoLoad: false,
    });
    const { actionType } = defaultStore;

    useEffect(() => {
        if (trigger > 0) {
            prepareStore();
            runInAction(() => {
                defaultStore.setAction('create');
            });
        }
    }, [trigger]);

    useEffect(() => {
        if (!prepared || actionType !== 'create') {
            return;
        }
        defaultStore.startNewData();
        if (!customerId || !leadId) {
            return;
        }
        const hideLoading = message.loading('客户数据加载中');
        defaultStore
            .askAutoFillingDataByCustomer({
                originalValue: customerId.originalValue,
            })
            .then(ok => {
                hideLoading();
                if (!ok) {
                    defaultStore.resetAction();
                }
                const { mutatingData } = defaultStore;
                mutatingData.customer_id = customerId;
                mutatingData.customer_short_name =
                    customerId?.relatedObj?.customer_name;
                mutatingData.lead_id = genLeadIdParamByContractItemAndParamMeta(
                    leadInfo,
                );
                mutatingData.opportunity_source = 1;
                // mutatingData.source_remark =
                //     leadSourceEnumsMap[leadInfo.lead_source]?.label || null;
                // const optSource =
                //     leadSource2optSourceMapping[leadInfo.lead_source];
                // if (optSource) {
                //     mutatingData.opportunity_source = optSource;
                // }
            });
    }, [prepared, customerId, actionType, leadId]);

    if (trigger === 0) {
        return null;
    }

    // 根据外部抽屉数据的状态和内部leadstore准备状态
    const preRequiredReady = prepared;
    // return null
    return (
        <OptForm
            mutateSuccess={() => {
                tryRefresh(['lead']);
            }}
            defaultStore={defaultStore}
            preRequiredReady={preRequiredReady}
        />
    );
});
