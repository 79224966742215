import React, { useEffect } from 'react';
import _ from 'lodash';
import { runInAction } from 'mobx';
import ContractDrawer from './index';
import ContractFormDrawer from '../components/form-drawer';
import {
    useAnyContractListStore,
    ContractListStore,
    genContractIdParamByContractItemAndParamMeta,
} from '../store';
import { CtxProvider } from '@/utils/context';
import { FinalProcessFormProxy } from '@/pages/sales-follow-up/process/list/form-proxy';

export const useContractDrawerSnippets = (options: {
    drawerVis: boolean;
    setDrawerVis: any;
    itemId: any;
    type?: string;
    contractList?: ContractListStore;
}) => {
    const { drawerVis, setDrawerVis, itemId, type = 'all' } = options;
    const { contractList: inputContractList } = options;

    const [prepared, contractList] = useAnyContractListStore({
        mayBeInitedStore: inputContractList,
    });

    useEffect(() => {
        contractList.setListType(type);
    }, [type]);

    if (!prepared) {
        return [contractList, null];
    }
    const defaultStore = contractList;
    return [
        contractList,
        <>
            <ContractFormDrawer
                defaultStore={contractList}
                preRequiredReady={prepared}
            />
            <CtxProvider
                ctx={{ position: 'list', positionDetail: 'contract-product' }}
            >
                <FinalProcessFormProxy
                    visible={
                        defaultStore.actionType === 'contract_invoice_apply'
                    }
                    onClose={() => {
                        defaultStore.setMutatingData(null);
                        defaultStore.resetAction();
                    }}
                    key={
                        defaultStore.actionType === 'contract_invoice_apply'
                            ? 'opened'
                            : 'nooppened'
                    }
                    processKey={'contractinvoice'}
                    lifeCycle={{
                        onClose: store => {
                            store.setMutatingData(null);
                            defaultStore.setMutatingData(null);
                            defaultStore.resetAction();
                        },
                        onOpen: store => {
                            if (!defaultStore.mutatingData) {
                                return;
                            }

                            store.startNewData();
                            const contractId = genContractIdParamByContractItemAndParamMeta(
                                defaultStore.mutatingData,
                            );
                            console.log('contractIdcontractId', contractId);

                            // 自动触发回填，发票资质
                            _.assign(store.mutatingData, {
                                contract_id: contractId,
                                customer_id:
                                    defaultStore.mutatingData?.customer_id,
                            });
                        },
                    }}
                    preRequestReady={!!defaultStore.mutatingData}
                />
            </CtxProvider>
            <ContractDrawer
                drawerVis={drawerVis}
                contractListStore={contractList}
                setDrawerVis={setDrawerVis}
                detailId={itemId}
                destroyOnClose={true}
                onEdit={data => {
                    runInAction(() => {
                        contractList &&
                            contractList.setAction('update', { id: data.id });
                    });
                }}
            />
        </>,
    ];
};
