/*
 * @Author: your name
 * @Date: 2020-12-14 16:58:12
 * @LastEditTime: 2020-12-16 16:31:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /maimai_crm/src/pages/setting/customer-hsp-grouping/add-chspg-modal/index.ts
 */

import React, { useState } from 'react';
import { useEffect, useContext } from 'react';
import _ from 'lodash';
import {
    IMemberSettingData,
    IMemberExtraData,
    makeMemberActionWidgets,
    memberFormItemOptions,
} from '../member-setting-modal/options';
import {
    IRuleSettingData,
    widgetsRuleSetting,
    IRuleExtraData,
    makeRuleActionWidgets,
    ruleFormItemOptions,
} from '../rule-setting-modal/options';
import { FormLV2, getAutoOnChangeHandlerOriginal } from '@/components';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { widgetsMemberSetting } from '../member-setting-modal/options';
import { embedDataToWidgets } from '@/components';
import { IFormLV2Props } from '@/components';
import { message, Modal } from 'antd';
import { memberValidators } from '../member-setting-modal/options';
import { ruleValidators } from '../rule-setting-modal/options';
import { transformAllArrayToString } from '../../../../utils/helpers';

const AddCHSPGModal: React.FC<{
    modalVis: boolean;
    setModalVis: (bool: boolean) => void;
    reloadTable: () => void;
}> = ({ modalVis, setModalVis, reloadTable }) => {
    const store = useContext(StoreContext);
    const settingStore = store.getSettingDefaultStore();
    const settingCHSPGStore = store.getDefaultSettingCHSPGStore();
    const { customerInfo, departInfo } = settingStore; // 用户选择下拉框数据
    const [modalStep, setModalStep] = useState(1); // 1是member 2是rule
    const [memberData, setMemberData] = useState<IMemberSettingData>({
        description: '',
        dataAdmin: [],
        dataDefaultAdmin: [],
        isHighValue: '',
        teamDeparts: [],
        teamUsers: [],
        autoDistributUsers: [],
    });
    const [ruleData, setRuleData] = useState<IRuleSettingData>({
        returnCantClaim: '',
        recycleType: '',
        assignType: '',
        recycleGetNotFollow: '',
        recycleGetNotFollowTo: '',
        inCooperationFollowup: '',
        noCooperation20Opportunity: '',
        noCooperation40Opportunity: '',
        // recycleGetNoNewOpportunity: '',
        recycleNotDeal: '',
        recycleBreak: '',
        recycleDeferred: '',
        recycleAlertOne: '',
        recycleAlertTwo: '',
        recycleAlertThree: '',
        // recycleGetDefinedDeal: [],
        // recycleDealNotFollow: '',
        // recycleDealNotFollowTo: '',
    });
    const clearModalData = () => {
        setModalStep(1);
        setRuleData({
            returnCantClaim: '',
            recycleType: '',
            assignType: '',
            recycleGetNotFollow: '',
            recycleGetNotFollowTo: '',
            inCooperationFollowup: '',
            noCooperation20Opportunity: '',
            noCooperation40Opportunity: '',
            // recycleGetNoNewOpportunity: '',
            recycleNotDeal: '',
            recycleBreak: '',
            recycleDeferred: '',
            recycleAlertOne: '',
            recycleAlertTwo: '',
            recycleAlertThree: '',
            // recycleGetDefinedDeal: [],
            // recycleDealNotFollow: '',
            // recycleDealNotFollowTo: '',
        });
        setMemberData({
            description: '',
            dataAdmin: [],
            dataDefaultAdmin: [],
            isHighValue: '',
            teamDeparts: [],
            teamUsers: [],
            autoDistributUsers: [],
        });
    };

    // 成员管理数据变更回调
    const onMemberDataChange = (
        key: keyof IMemberSettingData,
        value: any,
        widget: any,
    ) => {
        if (key === 'dataAdmin') {
            // 当分组管理员变化时，检验数据管理员的选择项
            let newDataDefaultAdmin: any[] | any = '';
            const oldDataDefaultAdmin = memberData.dataDefaultAdmin;
            if (Array.isArray(oldDataDefaultAdmin)) {
                _.forEach(oldDataDefaultAdmin, item => {
                    newDataDefaultAdmin = [];
                    if (!!_.find(value, groupAItems => item === groupAItems)) {
                        newDataDefaultAdmin.push(item);
                    }
                });
            } else {
                if (
                    !!_.find(
                        value,
                        groupAItems => oldDataDefaultAdmin === groupAItems,
                    )
                ) {
                    newDataDefaultAdmin = oldDataDefaultAdmin;
                }
            }
            const newMemberModalData = {
                ...memberData,
            };
            newMemberModalData[key] = value;
            if (Array.isArray(oldDataDefaultAdmin)) {
                if (
                    newDataDefaultAdmin.length ===
                    memberData.dataDefaultAdmin.length
                ) {
                    // 如果长度不变,则不更新数据管理员
                } else {
                    newMemberModalData.dataDefaultAdmin = newDataDefaultAdmin;
                }
            } else {
                // 如果没被清除，则不更新数据管理员
                if (newDataDefaultAdmin) {
                } else {
                    newMemberModalData.dataDefaultAdmin = '';
                }
            }
            setMemberData(newMemberModalData);
        } else {
            getAutoOnChangeHandlerOriginal(
                memberData as IMemberSettingData,
                setMemberData,
            )(key, value, widget);
        }
    };

    // 规则管理数据变更回调
    const onRuleDataChange = getAutoOnChangeHandlerOriginal(
        ruleData as IRuleSettingData,
        setRuleData,
    );
    // 动态配置生成
    const finalRuleWidgets = widgetsRuleSetting();
    const finalMemberWidgets = widgetsMemberSetting(
        customerInfo,
        departInfo,
        memberData,
        true,
    );
    const modalLoading = settingStore.isCustomerInfoLoading;
    embedDataToWidgets<IMemberSettingData>(finalMemberWidgets, memberData);
    embedDataToWidgets<IRuleSettingData>(finalRuleWidgets, ruleData);
    const MemberSetModalProps: IFormLV2Props<
        IMemberSettingData,
        IMemberExtraData
    > = {
        styleLv2: {
            // width: 500,
        },
        classNameLv2: 'chspg-setting-modal',
        data: memberData,
        formOptions: {
            layout: 'vertical',
        },
        catchGroupProps: {
            'member-team': {
                afterNum: 4,
                groupClassName: 'chspg-setting-member-team',
                groupTitle: <p>成员</p>,
            },
        },
        statusExtraData: {
            memberOptionsDataLoading: false,
        },
        widgets: finalMemberWidgets,
        actionWidgets: makeMemberActionWidgets(modalLoading, true),
        onChange: onMemberDataChange,
        formItemOptions: memberFormItemOptions,
        onInteract: (key, type, value) => {},
        onAction: (actionType, data, isPass, validateResult) => {
            console.log(
                'actionType, data, isPass, validateResult: ',
                actionType,
                data,
                isPass,
                validateResult,
            );
            if (actionType === 'submit') {
                if (isPass) {
                    setModalStep(2);
                } else {
                    message.error('输入不正确，请检查输入');
                }
            }

            if (actionType === 'cancel') {
                setModalVis(false);
            }
        },
        validateMode: 'instantly',
        validators: memberValidators,
        container: 'modal',
        containerModalProps: {
            visible: modalVis,
            title: '分组成员设置',
            onCancel: () => {
                setModalVis(false);
                clearModalData();
            },
            width: 600,
            wrapClassName: 'chspg-modal-content',
            destroyOnClose: true,
            loading: false,
        },
    };
    const ruleSetModalProps: IFormLV2Props<IRuleSettingData, IRuleExtraData> = {
        styleLv2: {
            // width: 500,
        },
        classNameLv2: 'chspg-setting-modal',
        data: ruleData,
        formOptions: {
            layout: 'vertical',
        },
        catchGroupProps: {
            'gc-rule': {
                afterNum: 2,
                groupClassName: 'chspg-setting-gc-rule',
                visible: ruleData.recycleType === 2,
            },
            'recycle-alert': {
                groupClassName: 'chspg-setting-recycle-alert-content',
            },
        },
        widgets: finalRuleWidgets,
        actionWidgets: makeRuleActionWidgets(modalLoading, true),
        onChange: onRuleDataChange,
        formItemOptions: ruleFormItemOptions,
        onInteract: (key, type, value) => {
            console.log('key, type, value: ', key, type, value);
        },
        onAction: async (actionType, data, isPass, validateResult) => {
            console.log(
                'actionType, data, isPass, validateResult: ',
                actionType,
                data,
                isPass,
                validateResult,
            );
            if (actionType === 'submit') {
                if (isPass) {
                    const newMemberData = transformAllArrayToString(memberData);
                    newMemberData.teamDeparts = _.map(
                        newMemberData?.teamDeparts || [],
                        item => {
                            return item.value;
                        },
                    ).join(',');
                    const finalRuleData: any = {
                        ...ruleData,
                        recycleAlert: _.filter(
                            [
                                ruleData.recycleAlertOne,
                                ruleData.recycleAlertTwo,
                                ruleData.recycleAlertThree,
                            ],
                            data => {
                                return !!data;
                            },
                        ),
                    };

                    delete finalRuleData.recycleAlertOne;
                    delete finalRuleData.recycleAlertTwo;
                    delete finalRuleData.recycleAlertThree;
                    delete finalRuleData.createUser;
                    const payloadData = {
                        ...newMemberData,
                        ...finalRuleData,
                    };
                    const [d, e] = await settingCHSPGStore.addCHSPG(
                        payloadData,
                    );
                    if (d) {
                        message.success('添加客户公海池分组成功', 1);
                        clearModalData();
                        reloadTable();
                        setModalVis(false);
                    } else {
                        message.error('添加客户公海池失败', 1);
                    }
                    // toggleModalVisible()
                    // toggleDrawerVisible()
                } else {
                    message.error('数据不正确，不可提交');
                }
            }
            if (actionType === 'other') {
                setModalStep(1);
            }

            if (actionType === 'cancel') {
                setModalVis(false);
                clearModalData();
            }
        },
        validators: ruleValidators,
        validateMode: 'instantly',
        container: 'modal',
        containerModalProps: {
            visible: modalVis,
            title: '分组规则设置',
            onCancel: () => {
                setModalVis(false);
                clearModalData();
            },
            width: 600,
            wrapClassName: 'chspg-modal-content',
            destroyOnClose: true,
            loading: false,
        },
    };
    return modalStep === 1 ? (
        <FormLV2<IMemberSettingData> {...MemberSetModalProps}></FormLV2>
    ) : (
        <FormLV2<IRuleSettingData> {...ruleSetModalProps}> </FormLV2>
    );
};
export default observer(AddCHSPGModal);
