import React from 'react';
import { navigate } from '@reach/router';
export interface EnterCardProps {
    url: string;
}

const WorkTableIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill="none"
            version="1.1"
            width="42"
            height="38"
            viewBox="0 0 42 38"
        >
            <g>
                <g>
                    <path
                        d="M0,4L0,26C0,28.2091,1.79086,30,4,30L38,30C40.2091,30,42,28.2091,42,26L42,4C42,1.79086,40.2091,0,38,0L4,0C1.79086,0,0,1.79086,0,4Z"
                        fill="#D1D3DE"
                        fillOpacity="1"
                    />
                </g>
                <g>
                    <rect
                        x="8"
                        y="34"
                        width="26"
                        height="4"
                        rx="1"
                        fill="#D1D3DE"
                        fillOpacity="1"
                    />
                </g>
                <g>
                    <rect
                        x="16"
                        y="30"
                        width="10"
                        height="4"
                        rx="0"
                        fill="#D1D3DE"
                        fillOpacity="1"
                    />
                </g>
                <g>
                    <path
                        d="M24.151581640625,16.20010794921875L21.565811640625,18.78587794921875C21.253391640625,19.09829794921875,21.253391640625,19.60479794921875,21.565811640625,19.91729794921875L22.131491640625,20.48289794921875C22.443911640625,20.79539794921875,22.950451640624998,20.79539794921875,23.262871640625,20.48289794921875L28.180081640624998,15.565767949218749C28.492481640625,15.25334794921875,28.492481640625,14.74681794921875,28.180081640624998,14.43439794921875L23.262871640625,9.51722494921875C22.950451640624998,9.20480474921875,22.443911640625,9.20480474921875,22.131491640625,9.51722494921875L21.565811640625,10.082911949218751C21.253391640625,10.39532794921875,21.253391640625,10.901867949218751,21.565811640625,11.21428794921875L24.151581640625,13.800097949218749L14.386199640625,13.800097949218749C13.944371640625,13.800097949218749,13.586197853125,14.15826794921875,13.586197853125,14.60009794921875L13.586197853125,15.40009794921875C13.586197853125,15.84192794921875,13.944371640625,16.20010794921875,14.386199640625,16.20010794921875L24.151581640625,16.20010794921875Z"
                        fillRule="evenodd"
                        fill="#FFFFFF"
                        fillOpacity="1"
                    />
                </g>
            </g>
        </svg>
    );
};

const EnterCard: React.FC<EnterCardProps> = props => {
    const { url } = props;
    return (
        <div
            className="card-container"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div
                className="card-icon"
                onClick={() => {
                    navigate(url);
                }}
            >
                <WorkTableIcon />
            </div>
            <div style={{ textAlign: 'center', fontWeight: 500 }}>
                进入我的工作台
            </div>
        </div>
    );
};

export default EnterCard;
