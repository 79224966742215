import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Input, message, Form } from 'antd';
import { rejects, btnMap } from './conf';
import './action.scss';
import _ from 'lodash';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export const SubmiAction: React.FC<{
    currentAction: string;
    data: any;
    onOk: (extra?: any) => void;
    onCancel: () => void;
    loading: boolean;
}> = observer(({ currentAction, data, onOk, onCancel, loading }) => {
    const visible = currentAction === 'SUBMIT' && data !== null;
    useEffect(() => {
        if (visible) {
            onOk();
        }
    }, [visible]);
    return null;
});
