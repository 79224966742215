import React, { useEffect, memo } from 'react';

import { ProductListStore } from '../store';
import { Modal, Alert, Button } from 'antd';

import { runInAction } from 'mobx';
import _ from 'lodash';
import { MOpNameDisplayContainer, AntTable, BlockLoading2 } from '@/components';
import { observer } from 'mobx-react-lite';

export const EquityDetail: React.FC<{
    defaultStore: ProductListStore;
}> = observer(({ defaultStore }) => {
    const {
        currentEquityDetailsFocusedData,
        currentEquityDetailsTableData,
        currentEquityDetailsColunms,
        currentEquityDetailsLoading,
    } = defaultStore;

    if (currentEquityDetailsFocusedData === null) {
        return null;
    }

    const visible = defaultStore.actionType === 'see-equity-detail';

    return (
        <Modal
            visible={visible}
            title={'权益详情'}
            cancelText={null}
            onCancel={() => {
                defaultStore.resetAction();
            }}
            footer={[
                <Button
                    onClick={async () => {
                        defaultStore.resetAction();
                    }}
                    type="primary"
                >
                    关闭
                </Button>,
            ]}
            zIndex={10001}
        >
            {currentEquityDetailsLoading ? (
                <div style={{ height: 200 }}>
                    <BlockLoading2 />
                </div>
            ) : (
                <>
                    <h3>
                        产品：{currentEquityDetailsFocusedData.product_name}
                    </h3>
                    <AntTable
                        columns={currentEquityDetailsColunms}
                        dataSource={currentEquityDetailsTableData}
                        pagination={false}
                    />
                </>
            )}
        </Modal>
    );
});
