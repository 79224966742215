import React, { useState, useEffect, useRef } from 'react';
import { Icon } from 'antd';
import './index.scss';
import _ from 'lodash';
import $ from 'jquery';
import { useEventListener } from 'ahooks';

export const AutoScrollable: React.FC<{
    className?: string;
}> = ({ children, className }) => {
    const refParent = useRef<HTMLDivElement | null>(null);
    const refContent = useRef<HTMLDivElement | null>(null);

    const [topWidth, sertTopWidth] = useState(0);
    const [overflow, setOverflow] = useState(false);

    const handle = () => {
        if (refParent.current !== null && refContent.current !== null) {
            const nextOverflow =
                refContent.current.offsetWidth > refParent.current.offsetWidth;

            if (nextOverflow !== overflow) {
                setOverflow(nextOverflow);
            }
            const nextTopWidth = refParent.current.offsetWidth;
            if (nextTopWidth !== topWidth) {
                sertTopWidth(nextTopWidth);
            }
        }
    };

    useEffect(() => {
        handle();
    }, [refParent.current, refContent.current, children]);

    useEventListener('resize', handle);

    const scrollToRight = () => {
        const current = refParent.current;
        if (!current) {
            return;
        }

        ($(current) as any).scrollTo(`+${topWidth - 50}px`, {
            duration: 500,
        });
    };
    const scrollToLeft = () => {
        const current = refParent.current;
        if (!current) {
            return;
        }

        ($(current) as any).scrollTo(`-${topWidth - 50}px`, {
            duration: 500,
        });
    };

    const hasList = _.isArray(children) ? children.length > 0 : true;

    return (
        <div
            ref={refParent}
            className={
                'com-filters-display ' +
                (className ? className : '') +
                (!hasList ? ' nolist' : '') +
                (overflow ? ' overflow' : '')
            }
        >
            {overflow && (
                <div onClick={scrollToLeft} className="arrow left">
                    <Icon type="double-left" />
                </div>
            )}
            <div ref={refContent} className="content-wrapper">
                <div className="content">{children}</div>
            </div>
            {overflow && (
                <div onClick={scrollToRight} className="arrow right">
                    <Icon type="double-right" />
                </div>
            )}
        </div>
    );
};
