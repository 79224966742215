import React from 'react';
import { IItemOptionItem, IWidgetItem } from '@/components';
import { OptionsList } from '@/components/common/form/widgets';
import { Tooltip, Icon } from 'antd';

export interface IFormDemoData {
    name: string;
    gender: string[];
    accountStatus: boolean;
    joinDate: string;
    position: string;
    interest: string[];
    authDateRange: [string, string];
    keyword: string;
    numberRange: [string, string];
}

export interface IFormDemoExtraData {
    genderLoading: boolean;
}

export const widgets = ({
    genderData,
}: {
    genderData: OptionsList<string>;
}): Array<IWidgetItem<IFormDemoData, IFormDemoExtraData>> => {
    return [
        {
            key: 'name',
            widgetKey: 'input',
            inputOptions: {
                placeholder: '并输入姓名',
            },
            afterLabel: (
                <Tooltip title="名字">
                    <Icon type="question-circle" />
                </Tooltip>
            ),
            beforeLabel: (
                <Tooltip title="名字">
                    <Icon type="question-circle" />
                </Tooltip>
            ),
        },
        {
            key: 'gender',
            widgetKey: 'select',
            selectOptions: {
                mode: 'multiple',
                placeholder: '请输入性别',
                options: genderData,
            },
            status: {
                loading: (data, extra) => {
                    return !!extra.genderLoading;
                },
            },
            afterWidget: (
                <Tooltip title="性别">
                    <Icon type="question-circle" />
                </Tooltip>
            ),
            beforeWidget: (
                <Tooltip title="性别">
                    <Icon type="question-circle" />
                </Tooltip>
            ),
        },
        {
            key: 'accountStatus',
            widgetKey: 'switch',
            switchOptions: {
                checkedChildren: '开启',
                unCheckedChildren: '关闭',
            },
        },
        {
            key: 'joinDate',
            widgetKey: 'date',
            dateOptions: {},
        },
        {
            key: 'position',
            widgetKey: 'radio',
            radioOptions: {
                options: [
                    {
                        label: '研发',
                        value: '1',
                    },
                    {
                        label: '产品',
                        value: '2',
                    },
                    {
                        label: '运营',
                        value: '3',
                    },
                ],
            },
        },
        {
            key: 'interest',
            widgetKey: 'checkbox',
            checkboxOptions: {
                options: [
                    {
                        label: '唱',
                        value: '1',
                    },
                    {
                        label: '跳',
                        value: '2',
                    },
                    {
                        label: 'rap',
                        value: '3',
                    },
                ],
            },
        },
        {
            key: 'authDateRange',
            widgetKey: 'dateRange',
            dateRangeOptions: {},
        },
        {
            key: 'keyword',
            widgetKey: 'searchInput',
            searchInputOptions: {
                enterButton: true,
                placeholder: '看看他干了什么',
            },
        },
        {
            key: 'numberRange',
            widgetKey: 'valueRange',
            valueRangeOptions: {
                fromInputProps: {
                    placeholder: '大于等于',
                },
                toInputProps: {
                    placeholder: '小于等于',
                },
            },
        },
    ];
};

export const formItemOptions: Array<IItemOptionItem<IFormDemoData>> = [
    {
        key: 'name',
        label: '姓名',
        required: true,
    },
    {
        key: 'gender',
        label: '性别',
        required: true,
    },
    {
        key: 'accountStatus',
        label: '账户状态',
    },
    {
        key: 'joinDate',
        label: '加入时间',
    },
    {
        key: 'position',
        label: '职位',
    },
    {
        key: 'interest',
        label: '兴趣',
    },
    {
        key: 'authDateRange',
        label: '授权时间范围',
    },
    {
        key: 'keyword',
        label: '搜索他的日志',
    },
    {
        key: 'numberRange',
        label: '值范围',
    },
];
