/*
 * @Author: your name
 * @Date: 2020-12-03 14:32:23
 * @LastEditTime: 2020-12-08 17:31:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /maimai_crm/src/components/common/draw/index.js
 */

import { AntDrawer } from '../../antd/drawer';
import React, { useState, useEffect } from 'react';
import { DrawerProps } from 'antd/lib/drawer';
import DrawerTitle, { IDrawerTitleProps } from './drawer-title';
import './index.scss';
import DrawerCardList from './drawer-card-list/index';
import { IDrawerCardListProps } from './drawer-card-list/index';
import DrawerTabs from './drawer-tabs';
import { IDrawerTabsProps } from './drawer-tabs/index';
import { AntInputTextArea } from '@/components/antd';
import { CrmTimeline } from '@/components';
import { Icon, Alert } from 'antd';
import { Spin } from 'antd';
import './index.scss';
import { Result } from 'antd';
import { CrmLoading2 } from '../crm-loading';

interface ICommonDrawerProps {
    titleConfig: IDrawerTitleProps;
    DrawerContentConfig: DrawerProps;
    DrawerCardListConfig?: IDrawerCardListProps;
    DrawerTabsConfig: IDrawerTabsProps;
    destroy?: boolean;
    loading?: boolean;
    extraRight?: React.ReactElement | (() => React.ReactElement);
    extraLeft?: React.ReactElement | (() => React.ReactElement);
    hideRightContent?: boolean;
    isFail?: boolean; // 是否展示无数据
}
const CommonDrawer: React.FC<ICommonDrawerProps> = props => {
    const [firstLoading, setFirstLoading] = useState(true);
    const {
        titleConfig,
        DrawerContentConfig,
        DrawerCardListConfig,
        DrawerTabsConfig,
        loading = false,
        destroy = false,
        children,
        extraLeft,
        extraRight,
        hideRightContent = false,
        isFail,
    } = props;
    useEffect(() => {
        if (!props.DrawerContentConfig.visible) {
            setFirstLoading(true);
        }
    }, [props.DrawerContentConfig.visible]);
    useEffect(() => {
        if (!loading && firstLoading) {
            setFirstLoading(false);
        }
    }, [loading]);
    return (
        <AntDrawer
            width="85%"
            title={
                firstLoading && loading ? null : (
                    <DrawerTitle {...titleConfig} />
                )
            }
            bodyStyle={{
                padding: '0 30px',
                overflow: 'scroll',
            }}
            drawerStyle={{
                minWidth: '750px',
            }}
            {...DrawerContentConfig}
        >
            {firstLoading && loading ? (
                <div className="drawer-first-loading">
                    <CrmLoading2 />
                </div>
            ) : (
                <Spin spinning={loading}>
                    {isFail ? (
                        <div
                            style={{ position: 'relative', top: '200px' }}
                            className="no-data"
                        >
                            <Result title="您没有查看该详情的权限" />
                        </div>
                    ) : destroy ? null : (
                        <>
                            <div className="crm-customer-drawer-content">
                                {DrawerCardListConfig && <DrawerCardList {...DrawerCardListConfig} />}
                                <div className="crm-drawer-body">
                                    <div
                                        className={`left ${hideRightContent ? 'full' : ''
                                            }`}
                                    >
                                        {extraLeft &&
                                            typeof extraLeft === 'function'
                                            ? extraLeft()
                                            : extraLeft}
                                        {DrawerTabsConfig && <DrawerTabs {...DrawerTabsConfig} />}
                                    </div>
                                    {!hideRightContent && (
                                        <div className="right">
                                            {extraRight &&
                                                typeof extraRight === 'function'
                                                ? extraRight()
                                                : extraRight}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {children}
                        </>
                    )}
                </Spin>
            )}
        </AntDrawer>
    );
};

export default CommonDrawer;
