import React, { useState, useEffect } from 'react';
import { AntButton } from '@/components/antd';
import { message } from 'antd';
import { defaultAxios } from '@/utils';

interface IRecordBtnProps {
    call_file_id: string | number;
    style?: React.CSSProperties;
}
const RecordBtn: React.FC<IRecordBtnProps> = props => {
    const { call_file_id, style } = props;
    const [recordPause, setRecordPause] = useState(true);
    const [recordUrl, setRecordUrl] = useState('');
    const getRecordFileUrl = async (callFileId: string | number) => {
        const url = '/crm/callRecord/fileLink';
        const [d, e] = await defaultAxios.get(url, {
            callFileId,
        });
        if (d === null || d.data === null) {
            message.error('获取录音失败');
            return false;
        }
        const error = e as Error | null;
        return d.data;
    };
    useEffect(() => {
        (window as any).audioEE.on('audio_url_change', (id: any) => {
            if (call_file_id && id && call_file_id != id) {
                setRecordPause(true);
            }
        });
    }, []);
    return call_file_id ? (
        <AntButton
            onClick={async () => {
                if (!recordPause) {
                    (window as any).audioEE.emit('audio_end');
                    setRecordPause(true);
                    return;
                }
                if (call_file_id) {
                    message.loading('获取录音文件中', 1);
                    const url = await getRecordFileUrl(call_file_id);
                    if (url) {
                        message.destroy();
                        (window as any).audioEE.emit(
                            'audio_start',
                            url,
                            call_file_id,
                        );
                        setRecordUrl(url);
                        setRecordPause(false);
                    } else {
                        message.error('播放失败');
                    }
                }
            }}
            style={{
                // position: 'absolute',
                // bottom: '5px',
                // left: '5px',
                top: '-3px',
                float: 'left',
                color: '#0052ff',
                fontWeight: 400,
                ...style,
            }}
            size="small"
        >
            {recordPause ? '播放录音' : '终止播放'}
        </AntButton>
    ) : null;
};

export default RecordBtn;
