import React, { useContext, useEffect, useState, CSSProperties } from 'react';
import './index.less';
import { observer } from 'mobx-react-lite';
import { defaultAxios, isNetSuccess } from '@/utils';
import _ from 'lodash';
import moment from 'moment';
import { StoreContext } from '@/stores';
import { Spin, Statistic } from 'antd';
import { entityCodeToUrl } from '../../store';
import qs from 'querystring';
const HomeMineTaskInfo: React.FC = observer(() => {
    const store = useContext(StoreContext);
    const homeNewStore = store.getDefaultHomeNewStore();
    const { homeNewRightInfoData, homeNewRightInfoLoading } = homeNewStore;
    const homeNewTaskInfoData = homeNewRightInfoData.taskJumpInfo;
    const statisticValueStyle: CSSProperties = {
        fontSize: '16px',
        color: '#0052ff',
        fontWeight: 500,
    };
    const goToJobPage = (key: 'job', type: string, filterKey?: string) => {
        const body: any = {};
        if (type) {
            body.type = type;
        }
        if (filterKey) {
            body.filterKey = filterKey;
        }
        window.open(entityCodeToUrl[key] + '?' + qs.stringify(body), '_blank');
    };
    const setLocalStorageUserByKey = (key: string) => {
        localStorage.setItem(
            key,
            JSON.stringify(
                _.map(homeNewRightInfoData.userInfoList, uItem => {
                    return {
                        id: uItem.userId,
                        key: `user-${uItem.userId}`,
                        name: uItem.userName,
                        title: uItem.userName,
                    };
                }),
            ),
        );
    };
    return (
        <div className="home-new-right-info-content">
            <Spin spinning={homeNewRightInfoLoading}>
                <div className="crm-home-task-info">
                    <p className="home-page-title">任务管理</p>
                    <div className="home-task-info-body">
                        {/* <div
                            className="task-info-item"
                            onClick={() => {
                                setLocalStorageUserByKey('job-ing-fk');
                                goToJobPage('job', 'job-ing', 'job-ing-fk');
                            }}
                        >
                            <Statistic
                                valueStyle={statisticValueStyle}
                                value={
                                    // 3000
                                    homeNewTaskInfoData?.running || '0'
                                }
                            />
                            <p className="task-info-label">进行中</p>
                        </div> */}
                        <div
                            className="task-info-item"
                            onClick={() => {
                                setLocalStorageUserByKey('job-expire-today-fk');
                                goToJobPage(
                                    'job',
                                    'job-expire-today',
                                    'job-expire-today-fk',
                                );
                            }}
                        >
                            <Statistic
                                valueStyle={statisticValueStyle}
                                value={
                                    // 3000
                                    homeNewTaskInfoData?.todayExpire || '0'
                                }
                            />
                            <p className="task-info-label">今日到期</p>
                        </div>
                        <div
                            className="task-info-item"
                            onClick={() => {
                                setLocalStorageUserByKey(
                                    'job-expire-thisweek-fk',
                                );
                                goToJobPage(
                                    'job',
                                    'job-expire-thisweek',
                                    'job-expire-thisweek-fk',
                                );
                            }}
                        >
                            <Statistic
                                valueStyle={statisticValueStyle}
                                value={
                                    // 3000
                                    homeNewTaskInfoData?.toWeekExpire || '0'
                                }
                            />
                            <p className="task-info-label">本周到期</p>
                        </div>
                        <div
                            className="task-info-item"
                            onClick={() => {
                                setLocalStorageUserByKey('job-expired-fk');
                                goToJobPage(
                                    'job',
                                    'job-expired',
                                    'job-expired-fk',
                                );
                            }}
                        >
                            <Statistic
                                valueStyle={statisticValueStyle}
                                value={
                                    // 3000
                                    homeNewTaskInfoData?.overdue || '0'
                                }
                            />
                            <p className="task-info-label">已逾期</p>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    );
});
export default HomeMineTaskInfo;
