// 设置网络
import {
    isWithAjaxBar,
    defaultAjaxOptions,
    defaultAxiosConfig,
    tokenKey,
} from '@/configs/net';
import { configNet } from '@/utils';

configNet(isWithAjaxBar, defaultAjaxOptions, defaultAxiosConfig, tokenKey);
