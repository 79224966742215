import React, { useState, useEffect, useContext, CSSProperties } from 'react';
import './index.less';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { Popover, Select, Cascader, Statistic, Spin, message } from 'antd';
import { CascaderOptionType } from 'antd/lib/cascader';
import _ from 'lodash';
import CompositeBusinessCharts from './composite-business-charts';
import { defaultAxios } from '@/utils';

const CompositeCascaderOptions: CascaderOptionType[] = [
    {
        value: '2021',
        label: '2021年',
        children: [
            {
                value: '7',
                label: '全年',
            },
            {
                value: '1',
                label: 'S1',
            },
            {
                value: '2',
                label: 'S2',
            },
            {
                value: '3',
                label: 'S3',
            },
            {
                value: '4',
                label: 'S4',
            },
            {
                value: '5',
                label: 'S5',
            },
            {
                value: '6',
                label: 'S6',
            },
        ],
    },
    {
        value: '2020',
        label: '2020年',
        children: [
            {
                value: '7',
                label: '全年',
            },
            {
                value: '1',
                label: 'S1',
            },
            {
                value: '2',
                label: 'S2',
            },
            {
                value: '3',
                label: 'S3',
            },
            {
                value: '4',
                label: 'S4',
            },
            {
                value: '5',
                label: 'S5',
            },
            {
                value: '6',
                label: 'S6',
            },
        ],
    },
    {
        value: '2019',
        label: '2019年',
        children: [
            {
                value: '7',
                label: '全年',
            },
            {
                value: '1',
                label: 'S1',
            },
            {
                value: '2',
                label: 'S2',
            },
            {
                value: '3',
                label: 'S3',
            },
            {
                value: '4',
                label: 'S4',
            },
            {
                value: '5',
                label: 'S5',
            },
            {
                value: '6',
                label: 'S6',
            },
        ],
    },
];

const staticsValueConf: {
    title: string;
    key: string;
    preText?: string;
    prodText?: string;
}[][] = [
    [
        {
            title: 'E 目标回款（万元）',
            key: 'income_targete',
            preText: '¥',
        },
        {
            title: 'E 目标GAP（万元）',
            key: 'income_targete_gap',
            preText: '¥',
        },
        {
            title: 'E 目标完成率',
            key: 'income_targete_completion',
            prodText: '%',
        },
        {
            title: '实际回款（万元）',
            key: 'income_money',
            preText: '¥',
        },
        {
            title: '时间进度',
            key: 'day_speed',
            prodText: '%',
        },
    ],
    [
        {
            title: 'M+ 目标回款（万元）',
            key: 'income_targetmp',
            preText: '¥',
        },
        {
            title: 'M+ 目标GAP（万元）',
            key: 'income_targetmp_gap',
            preText: '¥',
        },
        {
            title: 'M+ 目标完成率',
            key: 'income_targetmp_completion',
            prodText: '%',
        },
        {
            title: '储备商机（万元）',
            key: 'opportunity_reserve_money',
            preText: '¥',
        },
        {
            title: 'ARPU值 (万)',
            key: 'income_arpu',
        },
    ],
    [
        {
            title: 'M 目标回款（万元）',
            key: 'income_targetm',
            preText: '¥',
        },
        {
            title: 'M 目标GAP（万元）',
            key: 'income_targetm_gap',
            preText: '¥',
        },
        {
            title: 'M 目标完成率',
            key: 'income_targetm_completion',
            prodText: '%',
        },
        {
            title: '储备商机-加权（万元）',
            key: 'opportunity_reserve_weighting_money',
            preText: '¥',
        },
        {
            title: '销售人效（万）',
            key: 'sales_effect',
        },
    ],
];
const CompositeBusiness: React.FC<RouteComponentProps> = props => {
    const store = useContext(StoreContext);
    const anaStore = store.getDefaultAnalysisStore();
    const cbStore = store.getDefaultCompositeBusinessStore();
    const [compositeFilterData, setCompositeFilterData] = useState<string[]>([
        '2021',
        '7',
    ]);
    const [compositeStaticsLoading, setCompositeStaticsLoading] = useState(
        false,
    );
    const [compositeStaticsData, setCompositeStaticsData] = useState<any>({});
    const fetchStaticsData = async () => {
        const condition = [
            {
                dimension: 'market_year',
                operate: 1,
                value: compositeFilterData[0],
            },
        ];
        // if (compositeFilterData[1] === '全年') {
        //     condition.push({
        //         dimension: 'market_assessment',
        //         operate: 1,
        //         value: '7',
        //     });
        // } else {
        condition.push({
            dimension: 'market_assessment',
            operate: 1,
            value: compositeFilterData[1],
        });
        // }
        const staticsQuery = {
            businessModelId: 3,
            indices: [
                'income_targete',
                'income_targetm',
                'income_targetmp',
                'income_money',
                'income_targete_gap',
                'income_targetm_gap',
                'income_targetmp_gap',
                'income_targete_completion',
                'income_targetm_completion',
                'income_targetmp_completion',
                'income_customer_num',
                'opportunity_reserve_money',
                'opportunity_reserve_weighting_money',
                'day_total',
                'day_passed',
                'day_speed',
                'income_arpu',
                'sales_num',
                'sales_effect',
            ],
            queryScene: 'INDICES',
            queryConditions: condition,
        };
        const url = '/crm/data/map';
        setCompositeStaticsLoading(true);
        const [d, e] = await defaultAxios.put(url, staticsQuery);
        setCompositeStaticsLoading(false);
        if (d === null || d.data === null) {
            message.error('获取大盘数据失败，请稍后重试～');
            return false;
        }
        const data = d.data;
        console.log(data);
        if (data) {
            setCompositeStaticsData(data);
            return true;
        } else {
            message.error('获取大盘数据失败，请稍后重试～');
            return false;
        }
    };
    useEffect(() => {
        // 变更时搜索
        fetchStaticsData();
    }, [compositeFilterData]);
    const statisticValueStyle: CSSProperties = {
        fontSize: '16px',
        // color: '#0052ff',
        fontWeight: 500,
    };
    const statisticCanClickValueStyle: CSSProperties = {
        fontSize: '16px',
        color: '#0052ff',
        fontWeight: 500,
        cursor: 'pointer',
    };
    return (
        <div className="composite-content">
            <div className="composite-item-content composite-statics-content">
                <div className="composite-item-header">
                    <div className="composite-item-title">
                        <span className="comp-title">商业大盘</span>
                        <Popover
                            placement="bottom"
                            content={<div>testetst</div>}
                        >
                            <span className="comp-tips">口径说明</span>
                        </Popover>
                    </div>
                    <div className="composite-filter-content">
                        <div className="composite-filter-item">
                            <label htmlFor="">周期</label>
                            <Cascader
                                disabled={compositeStaticsLoading}
                                options={CompositeCascaderOptions}
                                onChange={v => {
                                    setCompositeFilterData(v);
                                }}
                                allowClear={false}
                                value={compositeFilterData}
                            ></Cascader>
                        </div>
                    </div>
                </div>
                <Spin spinning={compositeStaticsLoading}>
                    <div className="composite-statics-body">
                        {_.map(staticsValueConf, staticRow => {
                            return (
                                <div className="composite-statics-row">
                                    {_.map(staticRow, item => {
                                        return (
                                            <div className="composite-statics-item">
                                                <p className="composite-statics-title">
                                                    {item.title}
                                                </p>
                                                {compositeStaticsData?.[
                                                    item.key
                                                ] ? (
                                                    <div className="composite-statics-data">
                                                        {/* <span style = {statisticValueStyle}>{item.preText || ''}</span> */}
                                                        {/* {
                                                                item.preText ? (
                                                                    <Statistic
                                                                        style={statisticValueStyle}
                                                                        groupSeparator={','}
                                                                        value={
                                                                            item.preText || ''
                                                                        }
                                                                    />
                                                                ) : null
                                                            } */}
                                                        <Statistic
                                                            style={
                                                                statisticValueStyle
                                                            }
                                                            groupSeparator={','}
                                                            prefix={
                                                                item.preText ||
                                                                ''
                                                            }
                                                            value={
                                                                compositeStaticsData?.[
                                                                    item.key
                                                                ]
                                                                    ? `${
                                                                          compositeStaticsData?.[
                                                                              item
                                                                                  .key
                                                                          ]
                                                                      }`
                                                                    : '- -'
                                                            }
                                                            suffix={
                                                                item.prodText ||
                                                                ''
                                                            }
                                                        />
                                                        {/* {
                                                                item.prodText ? (
                                                                    <Statistic
                                                                        style={statisticValueStyle}
                                                                        groupSeparator={','}
                                                                        value={
                                                                            item.prodText || ''
                                                                        }
                                                                    />
                                                                ) : null
                                                            } */}
                                                    </div>
                                                ) : (
                                                    <Statistic
                                                        style={
                                                            statisticValueStyle
                                                        }
                                                        groupSeparator={','}
                                                        value={'- -'}
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </Spin>
            </div>
            <CompositeBusinessCharts
                title={'业绩增长趋势（万元）'}
                filterLabel={'周期'}
                tipsContent={
                    <div className="cb-charts-tips">
                        <p>按月体现</p>
                        <p>前后2年每月的实际回款金额</p>
                    </div>
                }
                payload={{
                    chart: '1',
                }}
                filterKey={'year'}
                urlConfig={{
                    chartsUrl: '/crm/data/market/display',
                    exportUrl: '/bff/api/rest/composite/export',
                }}
                filterOptions={[
                    {
                        label: '2021年',
                        value: '2021',
                    },
                    {
                        label: '2020年',
                        value: '2020',
                    },
                ]}
                defaultFilterValue={'2021'}
            />
            <CompositeBusinessCharts
                title={'客户增长趋势（月度回款客户）'}
                filterLabel={'周期'}
                payload={{
                    chart: '2',
                }}
                filterKey={'year'}
                tipsContent={
                    <div className="cb-charts-tips">
                        <p>
                            当月有回款的签约客户（取客户数量，不是回款纪录数量）
                        </p>
                    </div>
                }
                urlConfig={{
                    chartsUrl: '/crm/data/market/display',
                    exportUrl: '/bff/api/rest/composite/export',
                }}
                filterOptions={[
                    {
                        label: '2021年',
                        value: '2021',
                    },
                    {
                        label: '2020年',
                        value: '2020',
                    },
                ]}
                defaultFilterValue={'2021'}
            />
            <CompositeBusinessCharts
                title={'客户贡献-ARPU值（万元）'}
                filterLabel={'周期'}
                tipsContent={
                    <div className="cb-charts-tips">
                        <p>
                            按月体现，每月实际回款金额/回款的公司数（按签约客户排重）
                        </p>
                    </div>
                }
                payload={{
                    chart: '3',
                }}
                urlConfig={{
                    chartsUrl: '/crm/data/market/display',
                    exportUrl: '/bff/api/rest/composite/export',
                }}
                filterKey={'year'}
                filterOptions={[
                    {
                        label: '2021年',
                        value: '2021',
                    },
                    {
                        label: '2020年',
                        value: '2020',
                    },
                ]}
                defaultFilterValue={'2021'}
            />
            <CompositeBusinessCharts
                title={'销售人效'}
                payload={{
                    chart: '4',
                }}
                filterKey={'year'}
                tipsContent={
                    <div className="cb-charts-tips">
                        <p>整体人效：当月实际回款金额/当月在职过的一线销售</p>
                    </div>
                }
                // showTips={false}
                // showExport={false}
                urlConfig={{
                    chartsUrl: '/crm/data/market/display',
                    exportUrl: '/bff/api/rest/composite/export',
                }}
                filterLabel={'周期'}
                filterOptions={[
                    {
                        label: '2021年',
                        value: '2021',
                    },
                    {
                        label: '2020年',
                        value: '2020',
                    },
                ]}
                defaultFilterValue={'2021'}
            />
        </div>
    );
};

export default observer(CompositeBusiness);
