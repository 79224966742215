import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Result } from 'antd';

const P404: React.FC<RouteComponentProps> = ({ location }) => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="该页面不存在哦，请确认链接是否有效～"
        />
    );
};

export { P404 };
