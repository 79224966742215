import React from 'react';
import { message, ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

message.config({
    maxCount: 2,
    // duration: 20000,
});

export const AntdProvider: React.FC<{}> = ({ children }) => {
    return (
        <ConfigProvider
            locale={zhCN}
            getPopupContainer={() => {
                const target = document.querySelector(
                    '.main > .right > .content > div',
                ) as HTMLElement;
                console.info('getPopupContainer target: ', target);
                if (!target) {
                    return document.body as HTMLElement;
                }
                return target;
            }}
        >
            {children}
        </ConfigProvider>
    );
};
