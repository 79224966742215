import React, { memo } from 'react';
import _ from 'lodash';
import { AutoEllipsis } from '../../auto-ellipsis';

const regex = /^(\d+,)*\d+$/;

const isCommaSeparatedNumbers = (value: string | number) => {
    return typeof value === 'string' && regex.test(value);
};

export const EnumItem: React.FC<{ args: [string | number, any] }> = memo(
    ({ args }) => {
        // 检查args是否存在和长度是否正确
        if (!args || args.length !== 2) {
            console.error('Invalid arguments for EnumItem');
            return null;
        }

        let [key, enumValues] = args;

        // 检查key是否为字符串或数字
        if (typeof key !== 'string' && typeof key !== 'number') {
            console.error('Invalid key type for EnumItem');
            return null;
        }

        if (!_.isObject(enumValues)) {
            enumValues = {};
        }

        if (!isCommaSeparatedNumbers(key)) {
            if (!enumValues[key]) {
                return <AutoEllipsis text={key.toString()} />;
            }
            return <AutoEllipsis text={enumValues[key]} />;
        } else {
            const keys = (key as string).split(',');
            const values = keys.map(k => enumValues[k] || k);
            const text = values.join('、');
            return <AutoEllipsis text={text} />;
        }
    },
);
