import { defaultAxios } from '@/utils';
import { message } from 'antd';
import { exportFile } from '../api';

// 新增商机数据通晒
export const queryOppSolarization: (
    customerType: number,
    orgId: string | number,
) => Promise<any> = async (customerType, orgId) => {
    const [d, e] = await defaultAxios.put(
        '/crm/data/query/opportunity_solarization',
        {
            customerType,
            orgId,
            version:2
        },
        { timeout: 100000 }, //可能要删
    );
    if (d === null || d.data === null || e) {
        if (e.message.includes('timeout')) {
            message.error('请求超时，请刷新页面');
        }
        return null;
    }
    return d.data;
};

// 新增商机数据通晒导出
export const exportOppSolarization = (
    customerType: number,
    orgId: string | number,
) => {
    return exportFile('/crm/data/query/opportunity_solarization/export', {
        customerType,
        orgId,
        version:2
    });
};

// 趋势分析
export const queryTrendAnalysis: (
    timeDim: number,
    orgId: string | number,
) => Promise<any> = async (timeDim, orgId) => {
    const [d, e] = await defaultAxios.put(
        '/crm/data/query/opportunity_trend',
        {
            timeDim,
            orgId,
            version:2
        },
        { timeout: 50000 }, //可能要删
    );
    if (d === null || d.data === null || e) {
        if (e.message.includes('timeout')) {
            message.error('请求超时，请刷新页面');
        }
        return null;
    }
    return d.data;
};

// 趋势分析导出
export const exportTrendAnalysis = (
    timeDim: number,
    orgId: string | number,
) => {
    return exportFile('/crm/data/query/trend_analysis/import', {
        timeDim,
        orgId,
        version:2
    });
};

// 赢率分布
export const queryWinRateRatio: (
    timeDim: number,
    orgId: string | number,
) => Promise<any> = async (timeDim, orgId) => {
    const [d, e] = await defaultAxios.put(
        '/crm/data/query/opportunity_win_rate_ratio',
        {
            timeDim,
            orgId,
            version:2
        },
        { timeout: 50000 }, //可能要删
    );
    if (d === null || d.data === null || e) {
        if (e.message.includes('timeout')) {
            message.error('请求超时，请刷新页面');
        }
        return null;
    }
    return d.data;
};
