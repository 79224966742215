import React, { useContext, useMemo, useState } from 'react';
import { Spin } from 'antd';
import moment from 'moment';
import { StoreContext } from '@/stores';
import { useRequest, useToggle } from 'ahooks';
import classname from 'classname';
import {
    HiIcon,
    OptIcon,
    Opt2Icon,
    LeadIcon,
    CusIcon,
    ActionIcon,
    AchieveIcon,
} from './Icon';
import WarningInfoCard from './components/warning-info-card';
import IncomeCalendar from './components/income-calendar';
import {
    getTodayMessage,
    getPayBackCalendar,
    setTodayMessageRead,
} from './api';
import TableCard, {
    TableCardProps,
} from '@/pages/analysis/dashboard/components/table-card';
import './index.less';
import { ColumnProps } from 'antd/lib/table';
import _ from 'lodash';
import { getQueryAsObject } from '@/utils';

const bannerImg = `${process.env.PUBLIC_URL}/imgs/menus/banner1.png`;

const noOpportunityWithin7dayMessageRender = (
    item: any,
    clickState: any,
    setClickState: (data: any) => void,
) => {
    const itemName = 'noOpportunityWithin7dayMessageClick';
    const key = `${moment().format('YYYY-MM-DD')}-${item.primaryKey}`;
    const isClicked = !!clickState[key];
    return (
        <div
            key={item.primaryKey}
            className={classname('card-item', { isClicked })}
        >
            <div className="card-item-div w84 m0">{item.userName}</div>
            <div style={{ marginLeft: '18px' }}>{`7日内未创建20商机`}</div>
            <a
                style={{ marginLeft: '18px' }}
                href={`/buy/opportunity/all?filterKey=opt-${item.userId}-${item.userName}`}
                target="_blank"
                onClick={() => {
                    const clicked = {
                        ...clickState,
                        [key]: true,
                    };
                    localStorage.setItem(itemName, JSON.stringify(clicked));
                    setClickState(clicked);
                }}
            >{`查看商机`}</a>
        </div>
    );
};
const noActionWithin7dayMessageRender = (
    item: any,
    clickState: any,
    setClickState: (data: any) => void,
) => {
    const itemName = 'noActionWithin7dayMessageClick';
    const key = `${moment().format('YYYY-MM-DD')}-${item.primaryKey}`;
    const isClicked = !!clickState[key];
    return (
        <div
            key={item.primaryKey}
            className={classname('card-item', { isClicked })}
        >
            <div className="card-item-div w84 m0">{item.userName}</div>
            <a
                href={`/buy/custom/my-custom?id=${item.customerId}`}
                target="_blank"
                className="card-item-div w154"
                onClick={() => {
                    const clicked = {
                        ...clickState,
                        [key]: true,
                    };
                    localStorage.setItem(itemName, JSON.stringify(clicked));
                    setClickState(clicked);
                }}
            >{`${item.customerName}`}</a>
            <div
                style={{ marginLeft: '18px' }}
            >{`上次沟通时间：${item.lastFollowTime}`}</div>
            <div className="card-item-div w70">{`${item.lastFollowType}`}</div>
            <a
                href={`/buy/custom/my-custom?id=${item.customerId}&newjobs=true`}
                target="_blank"
                style={{ marginLeft: '18px' }}
                onClick={() => {
                    const clicked = {
                        ...clickState,
                        [key]: true,
                    };
                    localStorage.setItem(itemName, JSON.stringify(clicked));
                    setClickState(clicked);
                }}
            >{`去创建跟进任务`}</a>
        </div>
    );
};

const unsettledaLeadMessageRender = (
    item: any,
    clickState: any,
    setClickState: (data: any) => void,
) => {
    const itemName = 'unsettledaLeadMessageClick';
    const key = `${moment().format('YYYY-MM-DD')}-${item.primaryKey}`;
    const isClicked = !!clickState[key];
    return (
        <div
            key={item.primaryKey}
            className={classname('card-item', { isClicked })}
        >
            <div className="card-item-div w84 m0">{item.userName}</div>
            <div className="card-item-div w98">{`${item.webCount}条页面留资`}</div>
            <div className="card-item-div w126">{`${item.callInCount}条call in线索`}</div>
            <a
                style={{ marginLeft: '18px' }}
                href={`/buy/lead/my-lead?filterKey=lead-web-callin&fastfilter=none&lv4MenuFilter=buy-lead-mine-private`}
                target="_blank"
                onClick={() => {
                    const clicked = {
                        ...clickState,
                        [key]: true,
                    };
                    localStorage.setItem(itemName, JSON.stringify(clicked));

                    localStorage.setItem(
                        'lead-web-callin',
                        JSON.stringify([
                            {
                                id: item.userId,
                                key: `user-${item.userId}`,
                                name: item.userName,
                                title: item.userName,
                            },
                        ]),
                    );
                    setClickState(clicked);
                }}
            >
                查看线索
            </a>
        </div>
    );
};

const no40OpportunityWithin7dayMessageRender = (
    item: any,
    clickState: any,
    setClickState: (data: any) => void,
) => {
    const itemName = 'no40OpportunityWithin7dayMessageClick';
    const key = `${moment().format('YYYY-MM-DD')}-${item.primaryKey}`;
    const isClicked = !!clickState[key];
    return (
        <div
            key={item.primaryKey}
            className={classname('card-item', { isClicked })}
        >
            <div className="card-item-div w84 m0">{item.userName}</div>
            <div
                style={{ marginLeft: '18px' }}
            >{`超过7天未推进40商机，请关注`}</div>
            <a
                href={`/buy/opportunity/all?filterKey=opt-${item.userId}-${item.userName}`}
                target="_blank"
                style={{ marginLeft: '18px' }}
                onClick={() => {
                    const clicked = {
                        ...clickState,
                        [key]: true,
                    };
                    localStorage.setItem(itemName, JSON.stringify(clicked));
                    setClickState(clicked);
                }}
            >{`查看商机`}</a>
        </div>
    );
};

const customerRenewalRiskMessageRender = (
    item: any,
    clickState: any,
    setClickState: (data: any) => void,
) => {
    const itemName = 'customerRenewalRiskMessageClick';
    const key = `${moment().format('YYYY-MM-DD')}-${item.primaryKey}`;
    const isClicked = !!clickState[key];
    return (
        <div
            key={item.primaryKey}
            className={classname('card-item', { isClicked })}
        >
            <div className="card-item-div w84 m0">{item.userName}</div>
            <a
                href={`/buy/custom/my-custom?id=${item.customerId}`}
                target="_blank"
                className="card-item-div w154"
                onClick={() => {
                    const clicked = {
                        ...clickState,
                        [key]: true,
                    };
                    localStorage.setItem(itemName, JSON.stringify(clicked));
                    setClickState(clicked);
                }}
            >{`${item.customerName}`}</a>
            <div
                style={{ marginLeft: '18px' }}
            >{`续约风险：${item.renewalRisk}级`}</div>
            <div
                style={{ marginLeft: '18px' }}
            >{`上次沟通时间：${item.lastFollowTime}`}</div>
            <div className="card-item-div w70">{`${item.lastFollowType}`}</div>
            <a
                href={`/buy/custom/my-custom?id=${item.customerId}&newjobs=true`}
                target="_blank"
                style={{ marginLeft: '18px' }}
                onClick={() => {
                    const clicked = {
                        ...clickState,
                        [key]: true,
                    };
                    localStorage.setItem(itemName, JSON.stringify(clicked));
                    setClickState(clicked);
                }}
            >{`去创建跟进任务`}</a>
        </div>
    );
};

const teamAchievedRenderWrap = (status: number) => {
    return function teamAchievedRender<T>({ dataSource }: TableCardProps<T>) {
        const Columns: ColumnProps<T>[] = [
            {
                title: '姓名',
                dataIndex: 'userName',
            },
            {
                title: '目标',
                dataIndex: 'target',
                render: text => <span>{text !== null ? `${text}` : `0`}</span>,
            },
            {
                title: '业绩',
                dataIndex: 'income',
            },
            {
                title: '完成率',
                dataIndex: 'completionRate',
                render: text => {
                    const perc = _.isNumber(text) ? `${text}%` : `-`;
                    return <span>{`${perc}`}</span>;
                },
            },
            {
                title: 'Gap',
                dataIndex: 'gap',
            },
            {
                title: '预估金额',
                dataIndex: 'predictAmount',
            },
            {
                title: '20商机个数',
                dataIndex: 'opportunity20Count',
            },
            {
                title: '40商机个数',
                dataIndex: 'opportunity40Count',
            },
            {
                title: '40商机金额',
                dataIndex: 'opportunity40Amount',
            },
            {
                title: '覆盖客户数',
                dataIndex: 'customerUV',
            },
            {
                title: '覆盖次数',
                dataIndex: 'customerPV',
            },
            {
                title: '有效电话数',
                dataIndex: 'vaildCallCount',
            },
        ];
        return (
            <div style={{ paddingTop: '12px' }}>
                <TableCard
                    columns={Columns}
                    dataSource={dataSource}
                    className={classname('team-achieved-table', {
                        readed: status === 1,
                    })}
                    style={{ boxShadow: 'none', padding: 0 }}
                    rowKey="userName"
                    scroll={{ x: 'max-content' }}
                    bodyStyle={{ maxHeight: 355 }}
                />
            </div>
        );
    };
};

enum TimeDimMap {
    'week',
    'month',
}

const getOrgId = () => {
    const getQuery = getQueryAsObject();
    return Number(getQuery.orgId);
};

const HomeTlWorkTable: React.FC = () => {
    const store = useContext(StoreContext);
    const homeNewStore = store.getDefaultHomeNewStore();
    const authStore = store.getAuthStore();
    const sysUser = authStore.userInfo?.sysUser || {};
    const [orgId, setOrgId] = useState<number>(getOrgId());

    const [range, { setLeft, setRight }] = useToggle<'week', 'month'>(
        'week',
        'month',
    );
    const [
        noOpportunityWithin7dayMessageClick,
        setNoOpportunityWithin7dayMessageClick,
    ] = useState(
        JSON.parse(
            localStorage.getItem('noOpportunityWithin7dayMessageClick') || '{}',
        ),
    );
    const [
        noActionWithin7dayMessageClick,
        setNoActionWithin7dayMessageClick,
    ] = useState(
        JSON.parse(
            localStorage.getItem('noActionWithin7dayMessageClick') || '{}',
        ),
    );
    const [
        unsettledaLeadMessageClick,
        setUnsettledaLeadMessageClick,
    ] = useState(
        JSON.parse(localStorage.getItem('unsettledaLeadMessageClick') || '{}'),
    );
    const [
        no40OpportunityWithin7dayMessageClick,
        setNo40OpportunityWithin7dayMessageClick,
    ] = useState(
        JSON.parse(
            localStorage.getItem('no40OpportunityWithin7dayMessageClick') ||
                '{}',
        ),
    );
    const [
        customerRenewalRiskMessageClick,
        setCustomerRenewalRiskMessageClick,
    ] = useState(
        JSON.parse(
            localStorage.getItem('customerRenewalRiskMessageClick') || '{}',
        ),
    );

    const Key2Option: {
        [key: string]: {
            icon: React.ReactNode;
            renderChildren: (
                data: any,
                clickState?: any,
                setClickState?: any,
            ) => any;
            subheader?: string;
            title: string;
            clickState?: any;
            setClickState?: (data: any) => void;
        };
    } = useMemo(() => {
        return {
            unsettledaLeadMessage: {
                icon: <LeadIcon />,
                renderChildren: unsettledaLeadMessageRender,
                title: '高优线索未完成处理',
                clickState: unsettledaLeadMessageClick,
                setClickState: setUnsettledaLeadMessageClick,
            },
            noOpportunityWithin7dayMessage: {
                icon: <OptIcon />,
                renderChildren: noOpportunityWithin7dayMessageRender,
                title: '商机创建预警信息',
                clickState: noOpportunityWithin7dayMessageClick,
                setClickState: setNoOpportunityWithin7dayMessageClick,
            },
            noActionWithin7dayMessage: {
                icon: <ActionIcon />,
                renderChildren: noActionWithin7dayMessageRender,
                title: '跟进情况预警信息',
                subheader: '7天未覆盖的40商机客户：',
                clickState: noActionWithin7dayMessageClick,
                setClickState: setNoActionWithin7dayMessageClick,
            },
            no40OpportunityWithin7dayMessage: {
                icon: <Opt2Icon />,
                renderChildren: no40OpportunityWithin7dayMessageRender,
                title: '商机预警信息',
                clickState: no40OpportunityWithin7dayMessageClick,
                setClickState: setNo40OpportunityWithin7dayMessageClick,
            },
            customerRenewalRiskMessage: {
                icon: <CusIcon />,
                renderChildren: customerRenewalRiskMessageRender,
                title: '老客续约状态有异常',
                clickState: customerRenewalRiskMessageClick,
                setClickState: setCustomerRenewalRiskMessageClick,
            },
            achievement: {
                icon: <AchieveIcon />,
                // renderChildren: teamAchievedRender,
                renderChildren: teamAchievedRenderWrap,
                title: '团队达成情况',
            },
        };
    }, [
        noOpportunityWithin7dayMessageClick,
        noActionWithin7dayMessageClick,
        unsettledaLeadMessageClick,
        no40OpportunityWithin7dayMessageClick,
        customerRenewalRiskMessageClick,
        setNoOpportunityWithin7dayMessageClick,
        setNoActionWithin7dayMessageClick,
        setUnsettledaLeadMessageClick,
        setNo40OpportunityWithin7dayMessageClick,
        setCustomerRenewalRiskMessageClick,
    ]);
    //
    const {
        data: todayMessage,
        loading: todayMessageLoading,
        refresh: todayMessageRefresh,
    } = useRequest(() => getTodayMessage(orgId), {
        refreshDeps: [orgId],
    });
    const readInfoMethod = (
        key: string,
        data: any[],
        orgId: number | string,
        clickState: any,
        setClickState: (data: any) => void,
    ) => {
        return (messageType: number) => {
            setTodayMessageRead(orgId, messageType)
                .then(() => {
                    const itemName = `${key}Click`;
                    data.forEach((item: any) => {
                        const key = `${moment().format('YYYY-MM-DD')}-${
                            item.primaryKey
                        }`;
                        clickState[key] = true;
                    });
                    localStorage.setItem(itemName, JSON.stringify(clickState));
                    setClickState(clickState);
                    todayMessageRefresh();
                })
                .catch(err => {});
        };
    };
    const readTableMethod = (orgId: number | string) => {
        return (messageType: number) => {
            setTodayMessageRead(orgId, messageType)
                .then(() => {
                    todayMessageRefresh();
                })
                .catch(err => {});
        };
    };

    return (
        <>
            <div className="home-new-banner-content">
                <div className="banner-space left-space"></div>
                <img src={bannerImg} alt="" />
                <div className="banner-space right-space"></div>
            </div>
            <div className="home-new-content">
                <div className="home-new-content-left">
                    <div className="left-header">
                        <HiIcon />
                        <span className="left-header-title">
                            欢迎 {`${sysUser.name}`} 进入专属工作台{' '}
                        </span>
                        {/* <span className="left-header-subtext">
                            上次登录时间：
                            {moment(sysUser.last_login_at).format(
                                'YYYY-MM-DD HH:mm:ss',
                            )}
                        </span> */}
                    </div>
                    <Spin spinning={todayMessageLoading}>
                        {Object.keys(Key2Option)
                            .sort((a, b) => {
                                const statusA = _.isNil(
                                    todayMessage?.[a]?.status,
                                )
                                    ? 0
                                    : todayMessage?.[a]?.status;
                                const statusB = _.isNil(
                                    todayMessage?.[b]?.status,
                                )
                                    ? 0
                                    : todayMessage?.[b]?.status;

                                return statusA - statusB;
                            })
                            .map((key: string) => {
                                const {
                                    icon,
                                    title,
                                    subheader,
                                    renderChildren,
                                    clickState = {},
                                    setClickState = () => {},
                                } = Key2Option[key];
                                if (key === 'achievement') {
                                    const data =
                                        todayMessage?.achievement?.[
                                            `${range}TeamAchieved`
                                        ] || [];
                                    const isRead = todayMessage?.[key]?.status;
                                    return (
                                        <WarningInfoCard
                                            key={key}
                                            cardKey={key}
                                            icon={icon}
                                            title={title}
                                            subtext={`截止当前达成情况：${moment().format(
                                                'YYYY-MM-DD HH:mm:ss',
                                            )}`}
                                            extraRight={
                                                <div className="warning-card-header-extra-btn">
                                                    <div
                                                        className={classname(
                                                            'warning-card-header-extra-btn-item',
                                                            {
                                                                active:
                                                                    range ===
                                                                    'week',
                                                            },
                                                        )}
                                                        onClick={() =>
                                                            setLeft()
                                                        }
                                                    >
                                                        本周
                                                    </div>
                                                    <div
                                                        className={classname(
                                                            'warning-card-header-extra-btn-item',
                                                            {
                                                                active:
                                                                    range ===
                                                                    'month',
                                                            },
                                                        )}
                                                        onClick={() =>
                                                            setRight()
                                                        }
                                                    >
                                                        本月
                                                    </div>
                                                </div>
                                            }
                                            // data={todayMessage?.achievement}
                                            data={data}
                                            childrenType="table"
                                            renderChildren={renderChildren(
                                                todayMessage?.[key]?.status,
                                            )}
                                            showCount={5}
                                            messageType={
                                                todayMessage?.[key]?.type
                                            }
                                            readMethod={readTableMethod(orgId)}
                                            isRead={isRead}
                                        />
                                    );
                                }
                                const data = todayMessage?.[key]?.list || [];
                                const isRead = todayMessage?.[key]?.status;
                                return (
                                    <WarningInfoCard
                                        key={key}
                                        cardKey={key}
                                        icon={icon}
                                        title={title}
                                        subtext={moment().format(
                                            'YYYY-MM-DD HH:mm:ss',
                                        )}
                                        subheader={subheader}
                                        clickState={clickState}
                                        setClickState={setClickState}
                                        data={data}
                                        renderChildren={renderChildren}
                                        messageType={todayMessage?.[key]?.type}
                                        readMethod={readInfoMethod(
                                            key,
                                            data,
                                            orgId,
                                            clickState,
                                            setClickState,
                                        )}
                                        isRead={isRead}
                                    />
                                );
                            })}
                    </Spin>
                </div>
                <div
                    className="home-new-content-right"
                    style={{ marginTop: '0px' }}
                >
                    <IncomeCalendar
                        orgId={orgId}
                        getDataMethod={getPayBackCalendar}
                    />
                </div>
            </div>
        </>
    );
};

export default HomeTlWorkTable;
