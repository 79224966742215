import moment from 'moment';
import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import { defaultAxios } from '@/utils';
import { Modal } from 'antd';
import React from 'react';
import { createDefaultInstanceGetterA } from '@/stores/utilsA';

export type TLeadTransReturnType = NetResponse<{
    data: any[];
    msg: string;
    code: number;
}>;

const api = '/crm/data/query/list_lead_transform';
export class LeadTransferStore {
    constructor() {
        makeAutoObservable(this);
    }
    public leadTransferAnaData: any[] = [];
    // public defaultChannelGatherAnaPagination: TPaginationStoreInstance = new PaginationStore();
    public getLeadTransData: (
        searchData: any,
    ) => Promise<TLeadTransReturnType> = async searchData => {
        const finalSearchData = {
            ...searchData,
            api,
        };
        const url = '/bff/api/rest/ana/common/fetch';
        const [d, e] = await defaultAxios.post(url, finalSearchData);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data)) {
            this.leadTransferAnaData = data;
        }
        const error = e as Error | null;
        return [data, error];
    };
    public exportLeadTransData = async (searchData: any, fileName: string) => {
        if (this.leadTransferAnaData.length > 10000) {
            Modal.error({
                title: '导出提示',
                content: (
                    <p>
                        最多支持导出10000条（当前条件共
                        {this.leadTransferAnaData.length}
                        条，请更换筛选条件再导出
                    </p>
                ),
            });
            return;
        }
        const url = `${
            window.location.origin
        }/bff/api/rest/ana/common/export?q=${encodeURIComponent(
            JSON.stringify({ ...searchData, fileName, api }),
        )}`;
        window.open(url, '_blank');
        return;
    };
}

export const getDefaultLeadTransStore = createDefaultInstanceGetterA(
    LeadTransferStore,
);
