// business_registration
import React, { memo, CSSProperties } from 'react';

export const BusinessRegistration: React.FC<{
    args: any[];
    item: any;
    onAction: (...args: any[]) => void;
}> = memo(({ args, item, onAction }) => {
    const value = +args[0];
    if (!value) {
        return null;
    }

    return (
        <BusinessRegistrationDisplay isBusinessRegistration={+value === 1} />
    );
});

export const BusinessRegistrationDisplay: React.FC<{
    isBusinessRegistration: boolean;
    size?: 'small' | 'big';
}> = ({ isBusinessRegistration, size = 'small' }) => {
    if (!isBusinessRegistration) {
        return null;
    }
    const style: CSSProperties = {
        width: '32px',
        height: '16px',
        lineHeight: '14px',
        fontSize: '12px',
        borderRadius: '4px',
        border: '1px solid #FFA408',
        display: 'inline-block',
        marginRight: 2,
        color: '#FFA408',
        textAlign: 'center',
        transform: size === 'small' ? 'scale(0.9)' : 'scale(1.1)',
    };
    return <div style={style}>标准</div>;
};

export const CusTag: React.FC<{
    color: string;
    text: string;
    style?: CSSProperties;
}> = ({ color, text, style: sty }) => {
    const style: CSSProperties = {
        height: '16px',
        width: '42px',
        lineHeight: '14px',
        fontSize: '12px',
        borderRadius: '4px',
        border: '1px solid ' + color,
        display: 'inline-block',
        marginRight: 2,
        color,
        textAlign: 'center',
        transform: 'scale(0.7)',
        transformOrigin: 'left',
        ...sty,
    };
    return <div style={style}>{text}</div>;
};
