import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { Echo, Echo2 } from '@/components/common/table/cells/echo';

const codeA = 'a'.charCodeAt(0);
const chars = [];
for (let i = 0; i < 26; i++) {
    chars.push(String.fromCharCode(codeA + i));
}

const dataItemKeys = [];

for (let i = 0; i < 10; i++) {
    for (let j = 0; j < 10; j++) {
        dataItemKeys.push(chars[i] + j);
    }
}

const dataItem = dataItemKeys.reduce<{ [key: string]: string }>((ret, key) => {
    ret[key] = key;
    return ret;
}, {});

export const dataSource: Array<{ [key: string]: string }> = [];
export const colunms = dataItemKeys.map((key, index) => {
    const ret: ColumnProps<any> = {
        key,
        title: key,
        dataIndex: key,
        ellipsis: true,
        // render(text) {
        //     // return <Echo args={[text]} /> // 890  4279
        //     return Echo2({ text }) // 482  2458
        // }
        // no render                478 1685
        // no render with ellipsis
    };
    if (index < 3) {
        ret.fixed = 'left';
    }
    return ret;
});

for (let i = 0; i < 30; i++) {
    dataSource.push(dataItem);
}
