import { DefaultMoment } from './moment';
import { DateTimeMoment } from './datetime-moment';
import { DefaultGender } from './gender';
import { Echo } from './echo';
import { EnumItem } from './enum';
import { ObjItem } from './obj';
import { ActionParam } from './action-param';
import { ActionParamCustomer } from './action-param-customer';
import { ActionParamContacts } from './action-param-contacts';
import { Link } from './link';
import { LinkContacts } from './link-contacts';
import { MLinks } from './mlinks';
import { DuplicateFlag } from './duplicate-flag';
import { CC } from './cc';
import { BusinessRegistration } from './business-registration';
import { EquityDetail } from './product/equity-detail';
import { ProcessStatus } from './process/process-status';
import { Files } from './files';
import { LastestActivityRecord } from './latest-activity-record';
import { RecycleType } from './recycleType';
import { JobStatus } from './jobs/job-status';
import { JobPriority } from './jobs/job-priority';
import { image } from './activation-record/image';

export const allCells = {
    DefaultMoment,
    DateTimeMoment,
    DefaultGender,
    Echo,
    EnumItem,
    ObjItem,
    ActionParam,
    ActionParamCustomer,
    ActionParamContacts,
    Link,
    LinkContacts,
    MLinks,
    DuplicateFlag,
    BusinessRegistration,
    EquityDetail,
    ProcessStatus,
    CC,
    Files,
    LastestActivityRecord,
    RecycleType,
    JobStatus,
    JobPriority,
    image,
} as const;

export type KeysOfAllCells = keyof typeof allCells;
