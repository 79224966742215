import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { Select, Spin, Alert, Radio } from 'antd';
import _ from 'lodash';
import './index.less';
import { AntButton } from '@/components';
export interface IAnaSalesTitleFilterProps {
    pageKey:
        | 'achievement-completion'
        | 'opportunity-reserve'
        | 'lead-follow-up'
        | 'new-training'
        | '321-task';
    onFilter: (
        filterData:
            | {
                  year: string;
                  menu: string;
              }
            | false,
    ) => void;
    onExport?: () => void;
    hiddenYear?: boolean;
}
const yearSelectConfig = [
    {
        label: '2020',
        value: '2020',
    },
    {
        label: '2021',
        value: '2021',
    },
    {
        label: '2022',
        value: '2022',
    },
    {
        label: '2023',
        value: '2023',
    },
    {
        label: '2024',
        value: '2024',
    },
    {
        label: '2025',
        value: '2025',
    },
    {
        label: '2026',
        value: '2026',
    },
    {
        label: '2027',
        value: '2027',
    },
];
const menuFilterKey = [
    {
        label: '全国',
        key: 'country',
    },
    {
        label: '区域',
        key: 'area',
    },
    {
        label: '城市',
        key: 'city',
    },
    {
        label: '小组',
        key: 'group',
    },
    {
        label: '销售',
        key: 'personal',
    },
];
export const menuKeyToDevType: any = {
    country: 1,
    area: 2,
    city: 3,
    group: 4,
    personal: 5,
};
const AnaSalesTitleFilter: React.FC<IAnaSalesTitleFilterProps> = props => {
    const { pageKey, onFilter, hiddenYear = true, onExport } = props;
    const [menuFilterData, setMenuFilterData] = useState<{
        year: string;
        menu: string;
    }>({
        year: '2024',
        menu: 'country',
    });
    const [menuFilterOptions, setMenuFilterOptions] = useState<
        {
            label: string;
            key: string;
        }[]
    >([]);
    const store = useContext(StoreContext);
    const menuStore = store.getAuthStore();
    const { defaultMenuPermStore } = menuStore;
    useEffect(() => {
        const finalMenuFilterOptions: {
            label: string;
            key: string;
        }[] = [];
        menuFilterKey.forEach(item => {
            const menuKey = `analysis-sales-${pageKey}-${item.key}`;
            if (defaultMenuPermStore.getPermByKey(menuKey).visible) {
                finalMenuFilterOptions.push({
                    ...item,
                });
            }
        });
        setMenuFilterOptions(finalMenuFilterOptions);
    }, [pageKey, defaultMenuPermStore.originalPerm]);
    useEffect(() => {
        if (menuFilterOptions?.[0]) {
            setMenuFilterData({
                ...menuFilterData,
                menu: menuFilterOptions?.[0].key,
            });
            onFilter({
                ...menuFilterData,
                menu: menuFilterOptions?.[0].key,
            });
        } else {
            onFilter(false);
        }
    }, [menuFilterOptions]);
    const hasAuth = !!menuFilterOptions?.[0];
    return (
        <div className="ana-sales-title-filter">
            <div className="ana-sales-title-content">
                {!defaultMenuPermStore.loading && !hasAuth ? (
                    <Alert message={'无权限'} type="warning" />
                ) : (
                    <>
                        {hiddenYear ? null : (
                            <>
                                <span className="title-text">年度</span>
                                <Select
                                    value={menuFilterData.year}
                                    style={{
                                        marginRight: '16px',
                                        minWidth: 80,
                                    }}
                                    onChange={(value: any) => {
                                        setMenuFilterData({
                                            ...menuFilterData,
                                            year: value,
                                        });
                                        onFilter({
                                            ...menuFilterData,
                                            year: value,
                                        });
                                    }}
                                    loading={defaultMenuPermStore.loading}
                                >
                                    {_.map(yearSelectConfig, options => {
                                        return (
                                            <Select.Option
                                                value={options.value}
                                            >
                                                {options.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </>
                        )}
                        <Radio.Group
                            buttonStyle="solid"
                            value={menuFilterData.menu}
                            onChange={e => {
                                setMenuFilterData({
                                    ...menuFilterData,
                                    menu: e.target.value,
                                });
                                onFilter({
                                    ...menuFilterData,
                                    menu: e.target.value,
                                });
                            }}
                        >
                            {_.map(menuFilterOptions, item => {
                                return (
                                    <Radio.Button value={item.key}>
                                        {item.label}
                                    </Radio.Button>
                                );
                            })}
                        </Radio.Group>
                        {onExport ? (
                            <AntButton
                                type="primary"
                                className="ana-sales-export-btn"
                                onClick={onExport}
                                // size='small'
                            >
                                导出
                            </AntButton>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};
export default observer(AnaSalesTitleFilter);
