import React, { useEffect, useCallback } from 'react';
import { useState, useContext } from 'react';
import './index.scss';
import CommonDrawer from '@/components/common/drawer/index';
import { IDrawerTitleProps } from '@/components/common/drawer/drawer-title';
import { Icon, message, Menu, Select, DatePicker } from 'antd';
import { IDrawerCardListProps } from '@/components/common/drawer/drawer-card-list/index';
import ItemUser, {
    IItemsUserProps,
} from '@/components/common/items/user/index';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import ItemPhone from '@/components/common/items/phone';
import { IDrawerTabsProps } from '@/components/common/drawer/drawer-tabs/index';
import DrawCollapse from '@/components/common/drawer/drawer-collapse';
import { IDrawerCollapseProps } from '@/components/common/drawer/drawer-collapse/index';
import DataReview from '@/components/common/items/data-review/index';
import { defaultAxios, useNet } from '@/utils';
import _ from 'lodash';
import moment from 'moment';

// 抽屉针对客户业务的二次封装，高度业务定制
interface ICrmLeadDrawer {
    drawerVis: boolean;
    setDrawerVis: (bool: boolean) => void;
    detailId?: string | number;
    destroyOnClose?: boolean;
    onEdit?: (data: any) => void;
    onCreat?: (data: any) => void;
    forceReload?: boolean; // 如果要在外部强制刷新用户详情，则将该值变化为true
}
interface ICrmDetailData {
    detailInfo: IDrawerCollapseProps;
    originData: any;
    // 此处可维护静态字段
    staticData: {
        name: string; // 客户名称
        id: string | number; // 客户id
        owner_id: any; // 关联字段 - 客户所有人
    };
    userField: any;
    actionLogConfig: {
        arType: any[];
        followType: any[];
    }; // 活动记录基础配置
}
export type TGetCrmDetailReturnType = NetResponse<{
    data: ICrmDetailData;
    msg: string;
    code: number;
}>;
const LeadDrawer: React.FC<ICrmLeadDrawer> = props => {
    const {
        drawerVis,
        setDrawerVis,
        detailId,
        destroyOnClose = false,
        onCreat,
        onEdit,
        forceReload = false,
    } = props;
    const [drawerData, setDrawerData] = useState<any>({});

    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const fetchCustomerDetail: () => Promise<
        TGetCrmDetailReturnType
    > = async () => {
        const thisCid = detailId;
        if (_.isNil(detailId)) {
            return [null, null];
        }
        const [d, e] = await defaultAxios.get(
            `/bff/api/rest/sale_income/detail?id=${detailId}`,
        );
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d && d.data) {
            if (thisCid === detailId) {
                setDrawerData(d.data);
            }
        }
        return [data, error];
    };
    const [error, loading, reload] = useNet(fetchCustomerDetail, {
        refreshKeys: ['sale_income'],
    });
    useEffect(() => {
        if (drawerVis) {
            if (detailId) {
                reload();
            }
        } else {
            // 当客户抽屉关闭，将会清空里面的所有数据
            setDrawerData({});
        }
    }, [detailId, drawerVis]);

    const staticData = drawerData?.staticData || {};
    const titleExampleConfig: IDrawerTitleProps = {
        title: staticData?.name,
        icons: [],
        tags: [],
        buttons: [
            {
                type: 'button',
                buttonConfig: {
                    type: 'primary',
                    children: '转化',
                    onClick: () => {
                        // tslint:disable-next-line:no-unused-expression
                        message.info('todo');
                    },
                },
            },
            {
                type: 'button',
                buttonConfig: {
                    children: '编辑',
                    onClick: () => {
                        // tslint:disable-next-line:no-unused-expression
                        typeof onEdit === 'function' &&
                            onEdit(
                                drawerData?.originData
                                    ? drawerData?.originData[0]
                                    : {},
                            );
                    },
                },
            },
        ],
    };
    const cardExampleConfig: IDrawerCardListProps = {
        dataSource: [
            {
                title: '公司名称',
                content: staticData?.company_name || '- -',
            },
            {
                title: '回款记录来源',
                content: staticData?.lead_source?.constraintLabel || '- -',
            },
            {
                title: '跟进结果',
                content: staticData?.follow_status?.constraintLabel || '- -',
            },
            {
                title: '创建时间',
                content: staticData?.create_time
                    ? moment(staticData?.create_time).format(
                          'YYYY-MM-DD HH:mm:ss',
                      )
                    : '- -',
            },
        ],
    };
    const customExampleCollapseConfig: IDrawerCollapseProps =
        drawerData.detailInfo;
    const tabsExampleConfig: IDrawerTabsProps = {
        tabsContentConfig: {
            defaultActiveKey: '1',
            tabBarGutter: 24,
            tabConfig: [
                {
                    key: '1',
                    tab: '回款记录信息',
                    type: 'collapse',
                    contentConfig: customExampleCollapseConfig,
                },
            ],
        },
    };

    return (
        <CommonDrawer
            destroy={destroyOnClose ? !drawerVis : false}
            loading={loading}
            titleConfig={titleExampleConfig}
            DrawerContentConfig={{
                zIndex: 999,
                placement: 'right',
                onClose: () => {
                    setDrawerVis(false);
                },
                visible: drawerVis,
            }}
            extraRight={<></>}
            DrawerCardListConfig={cardExampleConfig}
            DrawerTabsConfig={tabsExampleConfig}
        />
    );
};

export default observer(LeadDrawer);
