import React, { useEffect, useState } from 'react';
import {
    Modal,
    Form,
    Select,
    Input,
    Upload,
    Button,
    Row,
    Col,
    message,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import _ from 'lodash';
import XLSX from 'xlsx';
import jschardet from 'jschardet';
import { TagItemInfo } from '../api';

interface ITagGroup {
    name: string;
    id: string;
}
export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    initialValues?: TagItemInfo;
    tagGroup: ITagGroup[];
    onSubmit: (val: any) => void;
    onClose: () => void;
}

const FormModalUpload: React.FC<IFormModalProps> = ({
    visible,
    initialValues,
    tagGroup,
    form,
    onSubmit,
    onClose,
}) => {
    const {
        getFieldDecorator,
        setFieldsValue,
        getFieldValue,
        validateFields,
        resetFields,
    } = form;

    const [fileList, setFileList] = useState<any[]>([]);

    useEffect(() => {
        _.isEmpty(initialValues)
            ? resetFields()
            : setFieldsValue(initialValues!);
    }, [initialValues]);

    useEffect(() => {
        if (!visible) {
            resetFields();
        }
    }, [visible]);

    const readSheet = (file: File) => {
        const TitleMapToKey: {
            [key: string]: any;
        } = {
            客户名称: 'customerName',
            业务类型: 'bizType',
        };
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsBinaryString(file);
            fileReader.onerror = e => {
                message.error('解析表格失败，请联系技术同学～');
                reject(e);
            };

            fileReader.onload = async event => {
                try {
                    const result = event.target?.result;
                    let workbook: any;
                    const checkUTF8 = jschardet.detect(result as string);
                    if (checkUTF8.encoding === 'UTF-8') {
                        workbook = XLSX.read(result, { type: 'binary' });
                    } else {
                        workbook = XLSX.read(result, {
                            type: 'binary',
                            codepage: 936,
                        });
                    }
                    // 以二进制流方式读取得到整份excel表格对象
                    // 存储获取到的数据
                    let data: any[] = [];
                    let header: any[] = [];
                    // 遍历每张工作表进行读取（这里默认只读取第一张表）
                    for (const sheet in workbook.Sheets) {
                        // esline-disable-next-line
                        if (workbook.Sheets.hasOwnProperty(sheet)) {
                            header = header.concat(
                                XLSX.utils
                                    .sheet_to_csv(workbook.Sheets[sheet])
                                    .split(/[\s\n]/)[0]
                                    .split(','),
                            );
                            // 利用 sheet_to_json 方法将 excel 转成 json 数据
                            data = data.concat(
                                XLSX.utils.sheet_to_json(
                                    workbook.Sheets[sheet],
                                ),
                            );
                            // break; // 如果只取第一张表，就取消注释这行
                        }
                    }
                    if (data.length > 500) {
                        message.error('上传表格的行数不能超过500');
                        reject('上传表格的行数不能超过500');
                        return;
                    }
                    // 最终获取到并且格式化后的 json 数据
                    const finalJson = data.map(item => {
                        const finalRow: any = {};
                        for (const key in item) {
                            const element = item[key];
                            if (TitleMapToKey[key]) {
                                finalRow[TitleMapToKey[key]] = element;
                            } else {
                                finalRow[key] = element;
                            }
                        }
                        return finalRow;
                    });
                    console.log('finalJson', finalJson, data, header);
                    resolve(finalJson);
                } catch (e) {
                    console.log(e);
                    // 这里可以抛出文件类型错误不正确的相关提示
                    message.error('解析文件流失败，请联系技术同学～');
                    reject(e);
                }
            };
        });
    };

    const handleSubmit = () => {
        validateFields(async (errs, values) => {
            if (errs) {
                return;
            }
            console.log('values', values);
            const result = {
                labelId: values.id,
                body: values.files,
            };
            onSubmit(result);
        });
    };

    return (
        <Modal
            visible={visible}
            title="上传客户"
            width={'600px'}
            onCancel={onClose}
            onOk={handleSubmit}
        >
            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                {!!initialValues?.id && (
                    <Form.Item>{getFieldDecorator('id')}</Form.Item>
                )}
                <Form.Item label="绑定标签">
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: true,
                                message: '请输入标签组名称',
                            },
                        ],
                    })(
                        <Input
                            disabled
                            maxLength={10}
                            placeholder="请输入标签组名称"
                        />,
                    )}
                </Form.Item>
                <Form.Item label="所属标签组">
                    {getFieldDecorator('group_id')(
                        <Select disabled>
                            {tagGroup.map(tag => (
                                <Select.Option value={tag.id}>
                                    {tag.name}
                                </Select.Option>
                            ))}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="上传附件">
                    {getFieldDecorator('files')(
                        <Upload
                            beforeUpload={file => {
                                readSheet(file).then(res => {
                                    setFieldsValue({ files: res });
                                });
                                return false;
                            }}
                            onChange={({ fileList }) => {
                                const file = {
                                    ...fileList.pop(),
                                };
                                setFileList([file]);
                            }}
                            showUploadList={{ showRemoveIcon: false }}
                            fileList={fileList}
                            accept=".xlsx, .xls, .csv"
                        >
                            <Button>选择文件</Button>
                        </Upload>,
                    )}
                </Form.Item>
                <Row>
                    <Col span={6}></Col>
                    <Col span={16}>
                        <Button
                            onClick={() => {
                                window.open(`/tag-sample.csv`, '_blank');
                            }}
                        >
                            下载模版
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Row
                style={{
                    color: '#333',
                    fontSize: '12px',
                    margin: '30px 40px 0',
                }}
            >
                <p>
                    导入只支持编码为GB
                    18030与UTF-8的文件，无特殊需求时，请勿修改文件模版编码
                </p>
                <p>单次上传的文件行数不能超过3000条,大小不能超过3M</p>
            </Row>
        </Modal>
    );
};

export default Form.create<IFormModalProps>()(FormModalUpload);
