import React, { memo, useContext, useEffect, useState } from 'react';
import {
    DForm,
    isMultipleCell,
    BlockLoading2,
    applyConfToFormItem,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { OptListStore } from '../store';
import { applyCustomConfToWidget } from '@/components/common/form/advanced-filters';
import {
    ISelectOptProductOptions,
    ConfirmEquity,
    getProductsTotalPriceByData,
} from '@/components/common/form/widgets/opt/select-opt-product';
import { useAnyProductStore } from '@/pages/om/product/list/store';
import { runInAction } from 'mobx';
import { CtxContext } from '@/utils/context';
import { Alert } from 'antd';
import { notFinishedLead } from '@/pages/sales-follow-up/lead/list/utils';
import { commonQuery } from '@/api/rest';
import { isNetSuccess } from '@/utils';
import { StoreContext } from '@/stores';
import { getValidatorByParamMeta } from '@/stores/utils';

const paramWhiteListWhenWithoutAdvancedPerm = new Set([
    'prediction_sign_time',
    'prediction_sale_amount',
    'prediction_pay_back_time',
    'remark',
]);

const blackListOnlyDetail = new Set([
    'create_time',
    'update_time',
    'stage_update_time',
]);

const OptForm: React.FC<{
    defaultStore: OptListStore;
    ignoreCtx?: boolean;
}> = ({ defaultStore, ignoreCtx }) => {
    const store = useContext(StoreContext);
    const userStore = store.getAuthStore();
    const supportedTypes =
        userStore.userInfo.supportedBizTypes?.['customer']?.businessTypeIds ||
        [];
    const supportedTypesSet = new Set(supportedTypes);
    useEffect(() => {
        if (supportedTypes.length === 1 && defaultStore.mutatingData) {
            defaultStore.mutatingData.biz_type = supportedTypes[0];
        }
    }, [supportedTypes, defaultStore.mutatingData]);

    const ctx = useContext(CtxContext);
    const { position, positionDetail } = ctx;
    const leadTrans = ignoreCtx
        ? false
        : positionDetail === 'lead-drawer' ||
          positionDetail === 'lead-table' ||
          positionDetail === 'customer-drawer';

    const {
        actionType,
        productToConfirmEquity,
        mutatingDataLoading,
        dataAuthCode,
        defaultPerm,
        mutatingData,
        defaultMeta,
    } = defaultStore;

    const hasAdvancePerm = defaultPerm.getPermByTypeAndAction(
        defaultStore.listType,
    )('change-stage-more60').visible;

    // 根据产品信息更新prediction_sale_amount
    useEffect(() => {
        if (!_.isArray(mutatingData?.product)) {
            return;
        }
        mutatingData.prediction_sale_amount =
            getProductsTotalPriceByData(mutatingData.product) || 0;
    }, [mutatingData?.product]);

    const bizType = mutatingData?.biz_type;
    const contractId = mutatingData?.contract_id;
    const opportunityType = mutatingData?.opportunity_type;
    const wr = mutatingData?.opportunity_sale_stage?.relatedObj?.win_rate;
    const up60 = wr >= 60;
    const editWhiteListOnly = up60 && !hasAdvancePerm;

    let eventCode = '';
    let isCreate = false;
    if (actionType === 'create') {
        isCreate = true;
        eventCode = 'CREATE-EDIT';
    } else if (actionType === 'update') {
        if (dataAuthCode === 'SEARCH-PUBLIC') {
            eventCode = 'PUBLIC-EDIT';
        } else if (dataAuthCode === 'SEARCH-PRIVATE') {
            eventCode = 'PRIVATE-EDIT';
        }
    }

    // 高潜逻辑整个丢弃
    // const isGaoqian = leadTrans
    //     ? false
    //     : mutatingData?.opportunity_source === 5;
    // useEffect(() => {
    //     const id = mutatingData?.customer_id?.originalValue;
    //     if (leadTrans || !mutatingData) {
    //         return;
    //     }
    //     const reset = () => {
    //         if (isCreate) {
    //             mutatingData.opportunity_source = null;
    //             mutatingData.source_remark = null;
    //         }
    //     };
    //     reset();
    //     if (!id) {
    //         return;
    //     }
    //     commonQuery(
    //         'customer',
    //         {
    //             pageSize: 1,
    //             pageNum: 1,
    //         },
    //         {
    //             filters: [
    //                 {
    //                     fieldId: 'id',
    //                     filterValue: id,
    //                     operateType: '=',
    //                 },
    //             ],
    //         },
    //         undefined,
    //         undefined,
    //         undefined,
    //         'SEARCH-ALL',
    //     ).then(([d, e]) => {
    //         if (isNetSuccess(d, e) && d !== null) {
    //             const { datas } = d;
    //             if (datas && datas[0]) {
    //                 const dt = datas[0];
    //                 const isGaoqian = !dt?.high_potential
    //                     ? false
    //                     : dt?.high_potential === 1
    //                     ? true
    //                     : false;

    //                 if (isGaoqian && isCreate) {
    //                     mutatingData.opportunity_source = 5;
    //                     mutatingData.source_remark = dt?.high_potential_reason;
    //                 }
    //             } else {
    //             }
    //         }
    //     });
    // }, [mutatingData, mutatingData?.customer_id, isCreate, leadTrans]);

    const [prepared, productStore] = useAnyProductStore({});
    if (!prepared) {
        return null;
    }

    const visible = productToConfirmEquity !== null;
    const form = (
        <>
            <ConfirmEquity
                visible={visible}
                onCancel={() => defaultStore.resetProductToConfirmEquity()}
                onOk={() => defaultStore.resetProductToConfirmEquity()}
                onChange={nextEquities => {
                    defaultStore.setNextParsedEquities(nextEquities);
                }}
                productToConfirmEquity={productToConfirmEquity}
            />
            {mutatingDataLoading ? (
                <BlockLoading2 />
            ) : (
                <DForm
                    eventCode={eventCode}
                    defaultStore={defaultStore}
                    onInteract={(key, type, value) => {
                        if (key === 'product' && type === 'confirm-equity') {
                            runInAction(() => {
                                defaultStore.setProductToConfirmEquity(value);
                            });
                        }
                    }}
                    options={{
                        grouped: true,
                    }}
                    labelsTransformer={nextLabels => {
                        nextLabels.forEach(label => {
                            if (label.key === 'agency_customer_id') {
                                label.required = true;
                            }
                        });
                        return nextLabels;
                    }}
                    validatorsTransformer={valdators => {
                        const paramMeta =
                            defaultMeta.objectMetaMap['agency_customer_id'];
                        valdators.agency_customer_id = getValidatorByParamMeta(
                            paramMeta,
                            true,
                        );
                        return valdators;
                    }}
                    widgetsTransformer={baiscWidgets => {
                        applyConfToFormItem(baiscWidgets, 'inputSecret', {
                            sceneCode: 'crm_opportunity',
                        });
                        applyCustomConfToWidget(
                            baiscWidgets,
                            'customer_id',
                            prevWidget => {
                                if (prevWidget.objectOptions) {
                                    prevWidget.objectOptions.fieldAuthCode =
                                        'PRIVATE-VIEW';
                                    prevWidget.objectOptions.dataAuthCode =
                                        'SEARCH-PRIVATE';
                                }
                                return prevWidget;
                            },
                        );

                        // product
                        applyCustomConfToWidget(
                            baiscWidgets,
                            'product',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                if (isMultipleCell(nextWidget)) {
                                    return nextWidget;
                                }
                                nextWidget.widgetKey = 'selectOptProducts';
                                const nextOptions: ISelectOptProductOptions = {
                                    inputProps: {
                                        placeholder: '输入数值',
                                    },
                                    objectOptions: {
                                        constraint:
                                            productStore.defaultRelatedContrait,
                                        placeholder: '搜索产品',
                                        listMeta: defaultStore.defaultMeta,
                                        defaultFilters: [
                                            {
                                                fieldName: 'biz_type',
                                                operateType: 'EQUAL',
                                                fieldValue: bizType,
                                            },
                                        ],
                                    },
                                    removeTotal: true,
                                };
                                nextWidget.selectOptProductsOptions = nextOptions;
                                nextWidget.prevlineWidget = () => {
                                    return (
                                        <>
                                            <Alert
                                                style={{
                                                    width: 900,
                                                    position: 'relative',
                                                    left: -80,
                                                    marginBottom: 8,
                                                }}
                                                type="warning"
                                                showIcon
                                                message="请注意核对「权益」信息，确保与最终签约合同内约定的权益值一致。"
                                            />
                                            <Alert
                                                style={{
                                                    width: 900,
                                                    position: 'relative',
                                                    left: -80,
                                                    marginBottom: 8,
                                                }}
                                                type="warning"
                                                showIcon
                                                message="一旦创建合同后，商机产品不支持修改or删除。如有需要请调整合同产品信息"
                                            />
                                            <Alert
                                                style={{
                                                    width: 900,
                                                    position: 'relative',
                                                    left: -80,
                                                    marginBottom: 8,
                                                }}
                                                type="warning"
                                                showIcon
                                                message={
                                                    <span>
                                                        人才银行-续约，直接选择1个套餐产品，然后修改权益值。
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            千万不要再加一个【人才银行的增购产品】
                                                        </span>
                                                    </span>
                                                }
                                            />
                                        </>
                                    );
                                };

                                nextWidget.status = {
                                    ...nextWidget.status,
                                    disabled: !!contractId?.originalValue,
                                };

                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'contacts_kp_id',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                const cusId =
                                    mutatingData?.customer_id?.originalValue;
                                if (!cusId) {
                                    nextWidget.status = {
                                        ...nextWidget.status,
                                        disabled: true,
                                    };
                                }
                                if (nextWidget.objectOptions) {
                                    nextWidget.objectOptions.reloadTrigger =
                                        mutatingData?.customer_id;
                                    nextWidget.objectOptions.defaultFilters = cusId
                                        ? [
                                              {
                                                  fieldName: 'customer_id',
                                                  operateType: 'EQUAL',
                                                  fieldValue: cusId,
                                              },
                                          ]
                                        : [];
                                    nextWidget.objectOptions.dataAuthCode =
                                        'SEARCH-PRIVATE';
                                }
                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'lead_id',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                if (leadTrans && bizType === 1) {
                                    nextWidget.status = {
                                        ...nextWidget.status,
                                        disabled: true,
                                    };
                                } else {
                                    // 原逻辑，如果是直接建商机，则不允许用户手动关联逻辑
                                    // nextWidget.status = {
                                    //     ...nextWidget.status,
                                    //     hidden: true,
                                    // };

                                    // 新逻辑
                                    const cusId =
                                        mutatingData?.customer_id
                                            ?.originalValue;
                                    if (!cusId) {
                                        nextWidget.status = {
                                            ...nextWidget.status,
                                            disabled: true,
                                        };
                                    }
                                    if (nextWidget.objectOptions) {
                                        nextWidget.objectOptions.reloadTrigger =
                                            mutatingData?.customer_id;
                                        nextWidget.objectOptions.defaultFilters = cusId
                                            ? [
                                                  {
                                                      fieldName: 'customer_id',
                                                      operateType: 'EQUAL',
                                                      fieldValue: cusId,
                                                  },
                                                  // 非终态线索
                                                  {
                                                      fieldName:
                                                          'follow_status',
                                                      operateType: 'IN',
                                                      fieldValue: notFinishedLead.join(
                                                          ',',
                                                      ),
                                                  },
                                              ]
                                            : [];
                                        nextWidget.objectOptions.dataAuthCode =
                                            'SEARCH-PRIVATE';
                                    }
                                }
                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'opportunity_source',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                const whiteList = new Set([2, 3, 4]);
                                // 如果不是线索转化的，不能选择线索转化那一条
                                if (
                                    !leadTrans &&
                                    nextWidget.selectOptions &&
                                    isCreate
                                ) {
                                    const leadId =
                                        mutatingData?.lead_id?.originalValue;
                                    if (!leadId) {
                                        // 如果没有选lead，按原逻辑的白名单
                                        nextWidget.selectOptions.options = nextWidget.selectOptions.options.filter(
                                            item => whiteList.has(+item.value),
                                        );
                                    } else {
                                        // <del>如果选了lead则不允许选择来源</del>
                                        // <del>默认使用与该lead来源对应的来源</del>

                                        // <del>且如果是gaoqian，也不允许选择了</del>
                                        nextWidget.status = {
                                            ...nextWidget.status,
                                            disabled: true,
                                        };
                                    }
                                }
                                if (!isCreate) {
                                    nextWidget.status = {
                                        ...nextWidget.status,
                                        disabled: true,
                                    };
                                }
                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'biz_type',
                            nextWidget => {
                                if (nextWidget.selectOptions) {
                                    nextWidget.selectOptions.options = nextWidget.selectOptions.options.filter(
                                        item => {
                                            return supportedTypesSet.has(
                                                +item.value,
                                            );
                                        },
                                    );
                                }
                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'opportunity_type',
                            nextWidget => {
                                if (nextWidget.selectOptions) {
                                    const originalOptions = (nextWidget.selectOptions.originalOptions =
                                        nextWidget.selectOptions
                                            .originalOptions ||
                                        nextWidget.selectOptions.options);
                                    nextWidget.selectOptions.options = originalOptions.filter(
                                        item => {
                                            if (bizType === 1) {
                                                return (
                                                    item.value === 1 ||
                                                    item.value === 2
                                                );
                                            } else if (bizType === 2) {
                                                return (
                                                    item.value === 3 ||
                                                    item.value === 4
                                                );
                                            }
                                            return true;
                                        },
                                    );
                                }
                                return nextWidget;
                            },
                        );

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'agency_customer_id',
                            nextWidget => {
                                if (nextWidget.objectOptions) {
                                    nextWidget.objectOptions.dataAuthCode =
                                        'SEARCH-CHOOSE';
                                    nextWidget.objectOptions.defaultFilters = [
                                        {
                                            fieldName: 'biz_type',
                                            operateType: 'EQUAL',
                                            fieldValue: 2,
                                        },
                                    ];
                                }
                                nextWidget.status = {
                                    ...nextWidget.status,
                                    hidden: +opportunityType !== 4,
                                };
                                console.log(
                                    nextWidget.status,
                                    'agency_customer_id',
                                );
                                return nextWidget;
                            },
                        );

                        if (leadTrans) {
                            for (const key of [
                                'opportunity_source',
                                'customer_id',
                                'customer_short_name',
                                // 'lead_id',
                            ]) {
                                applyCustomConfToWidget(
                                    baiscWidgets,
                                    key,
                                    prevWidget => {
                                        const nextWidget = _.cloneDeep(
                                            prevWidget,
                                        );
                                        if (isMultipleCell(nextWidget)) {
                                            return nextWidget;
                                        }
                                        const status = (nextWidget.status =
                                            nextWidget.status || {});
                                        status.disabled = true;
                                        nextWidget.status = status;
                                        return nextWidget;
                                    },
                                );
                            }
                        }

                        for (const widget of baiscWidgets) {
                            widget.status = widget.status || {};
                            if (editWhiteListOnly) {
                                if (
                                    paramWhiteListWhenWithoutAdvancedPerm.has(
                                        widget.key as string,
                                    )
                                ) {
                                    widget.status.disabled = false;
                                } else {
                                    widget.status.disabled = true;
                                }
                            }

                            if (blackListOnlyDetail.has(widget.key as string)) {
                                widget.status.hidden = true;
                            }
                        }

                        applyCustomConfToWidget(
                            baiscWidgets,
                            'prediction_sale_amount',
                            prevWidget => {
                                const nextWidget = _.cloneDeep(prevWidget);
                                nextWidget.status = {
                                    ...nextWidget.status,
                                    disabled: true,
                                };
                                return nextWidget;
                            },
                        );

                        return baiscWidgets;
                    }}
                    onChange={(key, value, k) => {
                        if (key === 'customer_id') {
                            defaultStore.askAutoFillingDataByCustomer(
                                value,
                                mutatingData?.opportunity_type,
                            );
                            // 只有续约清理字段
                            if (
                                mutatingData &&
                                mutatingData?.opportunity_type === 2
                            ) {
                                mutatingData.lead_id = null;
                            }
                        }

                        if (key === 'opportunity_type' && value !== null) {
                            defaultStore.askAutoFillingDataByCustomer(
                                mutatingData?.customer_id,
                                value,
                            );
                            if (value !== 4) {
                                mutatingData.agency_customer_id = null;
                            }
                            // 只有续约清理字段
                            if (mutatingData && value === 2) {
                                mutatingData.lead_id = null;
                            }
                        }

                        // if (key === 'lead_id' && value !== null) {
                        //     defaultStore.fillOptSourceByLead(value, isGaoqian);
                        //     if (mutatingData && !isGaoqian) {
                        //         mutatingData.opportunity_source = null;
                        //     }
                        // }

                        if (key === 'lead_id') {
                            mutatingData.opportunity_source =
                                value === null ? null : 1;
                        }
                    }}
                />
            )}
        </>
    );
    return form;
};

const FinalOptForm = memo(observer(OptForm));
export default FinalOptForm;
