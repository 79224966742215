import React, { PropsWithChildren } from 'react';
import _ from 'lodash';
import { FormLV2, IFormLV2Props } from './level2';
import { Button } from 'antd';
import './standard-crud-filter.scss';

export interface FormStandardCrudFilter<T, E = any>
    extends IFormLV2Props<T, E> {}

export const FormStandardCrudFilter: <T, E = any>(
    props: PropsWithChildren<FormStandardCrudFilter<T, E>>,
) => React.ReactElement | null = props => {
    return (
        <div className="component-standard-crud-filter">
            <FormLV2 {...props} rowGutter={[12, 12]} />
        </div>
    );
};
