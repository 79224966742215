import React from 'react';
import { makeAutoObservable, toJS } from 'mobx';
import { defaultAxios, decodePhone } from '@/utils';
import _ from 'lodash';
import { createDefaultInstanceGetterA } from '@/stores/utilsA';
export interface ICUserData {
    uid: number;
    username: string; // 姓名
    realname: string; // 实名
    phone: string | number; // 手机号(被隐藏)
    position: string; // 职位
    company: string; // 公司
    loginTime: string; // 最近活跃时间
    call: boolean; // 是否已解密
    profileUrl: string; // profileUrl
    lastActiveDate: number;
    oneMonthActiveCnt: number; // 近30日活跃次数
    validPositionCnt: number; // 有效职位数
    searchTalentCnt: number; // 搜索人才数
}
export interface IPersonBuyData {
    uid: string | number; // uid
    orderId: string | number; // 订单id
    buyTime: string; // 购买时间
    money: string | number; // 订单金额
    memType: string; // 会员类型
    month: number; // 会员月数
    channel: string; // 购买渠道
    buyType: string; // 购买方式
    autoRenew: boolean; // 是否自动续费
}
export class CUserStore {
    constructor() {
        makeAutoObservable(this);
    }
    public remainCount: number = 0; // 剩余次数
    public maiTrendContactTrigger: number = 0;
    public maiTrendContactCurrentData: any = null;
    public cUserPositionList: string[] = []; // 职位列表
    public fetchPositionListLoading = false; // 职位列表loading
    public searchCUserLoading = false; // 搜索c端用户列表loading
    public cUserBuyDataLoading = false; // c端用户购买记录loading
    public cUserDataList: ICUserData[] = []; // c端用户信息列表
    public cUserBuyList: IPersonBuyData[] = []; // c端用户购买记录

    public setMaiTrendContactTrigger = (num: number) => {
        this.maiTrendContactTrigger = num;
    };
    public setMaiTrendContactCurrentData = (data: any) => {
        this.maiTrendContactCurrentData = data;
    };
    // 搜索c端用户列表
    public searchCUserDataList = async (searchData: {
        position?: string[];
        company?: string;
        name?: string;
        start?: string;
        end?: string;
        queryScene?: any;
        companyId?: string | number;
        lastLogin?: number;
    }) => {
        this.searchCUserLoading = true;
        const url = `/bff/api/rest/c-user/search-c-user-list`;
        const [d, e] = await defaultAxios.post(url, {
            positions: searchData.position,
            company: searchData.company,
            username: searchData.name,
            queryScene: searchData.queryScene,
            companyId: searchData.companyId,
            lastLogin: searchData.lastLogin,
        });
        this.searchCUserLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        console.log(d);
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            this.cUserDataList = data || [];
        }
        return [data, error];
    };
    // 获取职位列表
    public getCUserPositionList = async (searchText: string) => {
        this.fetchPositionListLoading = true;
        const url = '/bff/api/rest/c-user/c-user-position-list';
        const [d, e] = await defaultAxios.post(url, {
            searchText,
        });
        this.fetchPositionListLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            this.cUserPositionList = data || [];
        }
        return [data, error];
    };
    // 获取剩余解密次数
    public getRemainDecodeNumber = async () => {
        const url = '/bff/api/rest/c-user/user-decode-remain';
        const [d, e] = await defaultAxios.post(url);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            this.remainCount = data.leave || 0;
        }
        return [data, error];
    };
    // 检测某条联系人是否已被解密
    public checkCUserDecoded = async (id: any) => {
        const url = '/bff/api/rest/c-user/check-c-user-decode';
        const [d, e] = await defaultAxios.post(url, {
            id,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 解密某条联系人
    public decodeCUser = async (id: any, otherUse?: boolean) => {
        console.log(this.remainCount);
        const url = '/bff/api/rest/c-user/decode-c-user';
        const [d, e] = await defaultAxios.post(url, {
            id,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = decodePhone(d.data);
        const error = e as Error | null;
        if (data) {
            if (!otherUse) {
                const exItem = _.find(this.cUserDataList, item => {
                    return item.uid === id;
                });
                if (exItem) {
                    exItem.call = true;
                    _.extend(exItem, this.cUserDataList);
                    this.cUserDataList = [...this.cUserDataList];
                }
                this.getRemainDecodeNumber();
                // this.remainCount = this.remainCount - 1;
            }
        }
        return [data, error];
    };
    // 个人购买记录
    public getPersonBuyList = async (id: any) => {
        const url = '/bff/api/rest/c-user/c-user-buy-list';
        this.cUserBuyDataLoading = true;
        const [d, e] = await defaultAxios.post(url, {
            id,
        });
        this.cUserBuyDataLoading = false;
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (data) {
            this.cUserBuyList = data || [];
        }
        return [data, error];
    };
}

export const getDefaultCUserStore = createDefaultInstanceGetterA(CUserStore);
