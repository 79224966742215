import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import './index.scss';
import { Icon, Modal, Checkbox, message, Popconfirm } from 'antd';
import ItemUser from '../../items/user';
import { WidgetCommonObject } from '@/components/common/form/widgets';
import TableCheckBox from '../../table-checkbox/index';
import { defaultAxios } from '@/utils';
export interface ICustomerAuthData {
    id: string | number;
    objCode: string | number;
    objName: string;
    readable: boolean;
    writeable: boolean;
}
export interface ICustomerTeamMemberData {
    auths?: ICustomerAuthData[];
    userId?: string | number;
    userName?: string;
    updateTime?: string | number;
    customerId?: string | number;
}
export interface ICustomerTeamMemberProps {
    dataSource: ICustomerTeamMemberData[];
    userField: any;
    defaultAuthData: ICustomerAuthData[];
    createCustomId: string | number;
    reloadAll: () => void;
}
const CustomerTeamMember: React.FC<ICustomerTeamMemberProps> = props => {
    const {
        dataSource = [],
        userField = {},
        createCustomId = '',
        reloadAll,
        defaultAuthData = [],
    } = props;
    const [teamMemberModalVis, setTeamMemberModalVis] = useState(false);
    const [memberId, setMemberId] = useState<any>(null); // 用于变更的成员id
    const [canWrite, setCanWrite] = useState(true); // 是否可以变更写选项
    const [authKey, setAuthKey] = useState<{
        delete: boolean;
        add: boolean;
        edit: boolean;
        show: boolean;
    }>({
        delete: false,
        add: false,
        edit: false,
        show: false,
    });
    const [selectedTeamMember, setSelectedTeamMember] = useState<
        ICustomerTeamMemberData
    >({});
    const [validError, setValidError] = useState<{
        [key: string]: {
            status: 'error' | 'success';
            msg: string;
        };
    }>({
        memberId: {
            status: 'success',
            msg: '',
        },
        auth: {
            status: 'success',
            msg: '',
        },
    }); // 前端验证数据
    useEffect(() => {
        fetchTeamMemberAuth();
    }, []);
    const isAdd = !selectedTeamMember.customerId;
    // 获取团队成员的权限
    const fetchTeamMemberAuth = async () => {
        const url = '/bff/api/rest/perm?mod=sales-follow-up:team-member';
        const [d, e] = await defaultAxios.get(url);
        if (d && d.data && d.data?.length) {
            const finalAuth: any = {};
            _.forEach(d.data, item => {
                if (item.key === 'sales-follow-up:team-member:customer-mine') {
                    if (!item.visible) {
                        finalAuth.show = false;
                    } else {
                        finalAuth.show = true;
                    }
                    return;
                }
                if (item.key.indexOf('add') > -1) {
                    if (!item.visible) {
                        finalAuth.add = false;
                    } else {
                        finalAuth.add = true;
                    }
                    return;
                }
                if (item.key.indexOf('edit') > -1) {
                    if (!item.visible) {
                        finalAuth.edit = false;
                    } else {
                        finalAuth.edit = true;
                    }
                    return;
                }
                if (item.key.indexOf('delete') > -1) {
                    if (!item.visible) {
                        finalAuth.delete = false;
                    } else {
                        finalAuth.delete = true;
                    }
                    return;
                }
            });
            setAuthKey(finalAuth);
        }
    };
    const addTeamMember = async () => {
        const url = '/bff/api/rest/customer/cou-team-member';
        let isReturn = false;
        if (!memberId || !memberId?.relatedObj?.id) {
            message.error('团队成员不能为空～', 1);
            isReturn = true;
            setValidError({
                ...validError,
                memberId: {
                    status: 'error',
                    msg: '团队成员不能为空',
                },
            });
        }
        // TODO: 此处可能还需要验证权限是否有一个被分配
        if (isReturn) {
            return;
        }
        const updateData: ICustomerTeamMemberData = {
            userId: memberId?.relatedObj?.id,
            userName: memberId?.relatedObj?.name,
            customerId: selectedTeamMember.customerId || createCustomId,
            auths: selectedTeamMember.auths,
        };
        // todo:支持多选
        const [d, e] = await defaultAxios.post(url, {
            updateData: [updateData],
        });
        // tslint:disable-next-line:triple-equals
        if (d && d?.data && !d?.data?.code) {
            message.success(`${isAdd ? '新增' : '修改'}成功`);
            reloadAll();
            hideTeamMemberModal();
        }
    };
    const deleteTeamMember = async (item: any) => {
        const { customerId, userId } = item;
        const url = '/bff/api/rest/customer/delete-team-member';
        const [d, e] = await defaultAxios.post(url, {
            customerId,
            userId,
        });
        // tslint:disable-next-line:triple-equals
        if (d && d?.data && !d?.data?.code) {
            message.success(`删除成功`);
            reloadAll();
            hideTeamMemberModal();
        } else {
            message.error('删除失败');
        }
    };
    const hideTeamMemberModal = () => {
        setTeamMemberModalVis(false);
        // 清空所有数据
        setSelectedTeamMember({});
        setValidError({
            memberId: {
                status: 'success',
                msg: '',
            },
            auth: {
                status: 'success',
                msg: '',
            },
        });
        setMemberId(null);
        setCanWrite(true);
    };
    const showTeamMemberModal = (item: any) => {
        const finalMemberId = item?.userId
            ? {
                originalValue: item?.userId || '',
                relatedObj: {
                    id: item?.userId || '',
                    name: item?.userName || '',
                },
                type: 'obj',
            }
            : {};
        setMemberId(finalMemberId);
        setTeamMemberModalVis(true);
        const finalSelectdTeamMember = item?.auths
            ? {
                ...item,
            }
            : {
                auths: defaultAuthData,
            };
        let isWriteSelected = false;
        // if(finalSelectdTeamMember.auths) {
        _.forEach(finalSelectdTeamMember.auths || [], auth => {
            if (auth.writeable) {
                isWriteSelected = true;
            }
        });
        // }
        if (isWriteSelected) {
            setCanWrite(true);
        }
        setSelectedTeamMember(finalSelectdTeamMember);
    };
    return (
        <>
            {authKey.show ? (
                <div className="customer-drawer-team-member-content">
                    <p className="team-member-title">
                        团队成员
                        {authKey.add ? (
                            <Icon
                                onClick={() => {
                                    showTeamMemberModal({});
                                }}
                                className="edit"
                                type="plus"
                            />
                        ) : null}
                    </p>
                    <div className="team-member-body">
                        {_.map(dataSource, item => {
                            return (
                                <div className="team-member-item">
                                    <ItemUser
                                        name={item.userName}
                                        avatarConfig={{
                                            src: '',
                                            size: 24,
                                        }}
                                    />
                                    <div className="team-member-item-handle-bar">
                                        {authKey.edit ? (
                                            <Icon
                                                style={{ marginRight: '5px' }}
                                                type="form"
                                                onClick={() => {
                                                    showTeamMemberModal(item);
                                                }}
                                            />
                                        ) : null}
                                        {authKey.delete ? (
                                            <Popconfirm
                                                title={`是否删除「${item.userName}」成员？`}
                                                onConfirm={() => {
                                                    deleteTeamMember(item);
                                                }}
                                            >
                                                <Icon type="close" />
                                            </Popconfirm>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : null}
            <Modal
                width="60%"
                visible={teamMemberModalVis}
                onCancel={hideTeamMemberModal}
                onOk={addTeamMember}
                title={`${isAdd ? '新增' : '修改'}团队成员`}
            >
                <div className="customer-team-member-modal-content">
                    <div className="team-member-choose">
                        <span className="team-member-choose-title">
                            团队成员：
                        </span>
                        <WidgetCommonObject
                            k={'owner_id'}
                            value={memberId}
                            onChange={val => {
                                setMemberId(val);
                            }}
                            onFocus={() => {
                                setValidError({
                                    ...validError,
                                    memberId: {
                                        status: 'success',
                                        msg: '',
                                    },
                                });
                            }}
                            options={{
                                constraint: userField?.constraint || {},
                                listMeta: { objectMeta: [userField] },
                                pageSize: 100,
                                className: `customer-team-member-select ${validError.memberId.status === 'error'
                                    ? 'has-error'
                                    : ''
                                    }`,
                            }}
                            status={{
                                disabled: !isAdd,
                            }}
                        />
                    </div>
                    {validError.memberId.status === 'error' && (
                        <span className="error-text">
                            {validError.memberId.msg}
                        </span>
                    )}
                    <div className="team-member-writeable">
                        <Checkbox
                            onChange={e => {
                                let isWriteSelected = false;
                                _.forEach(
                                    selectedTeamMember.auths || [],
                                    auth => {
                                        if (auth.writeable) {
                                            isWriteSelected = true;
                                        }
                                    },
                                );
                                if (isWriteSelected) {
                                    return;
                                    // setCanWrite(true);
                                } else {
                                    setCanWrite(e.target.checked);
                                }
                            }}
                            checked={canWrite}
                        />
                        <span>分配修改权限</span>
                    </div>
                    <div className="team-member-auth-table">
                        <TableCheckBox<ICustomerAuthData>
                            className={`customer-team-member-select ${validError.auth.status}`}
                            checkBoxValue={selectedTeamMember.auths}
                            setCheckBoxValue={val => {
                                setValidError({
                                    ...validError,
                                    auth: {
                                        status: 'success',
                                        msg: '',
                                    },
                                });
                                const finalAuths = _.map(
                                    val,
                                    (authItems, idx) => {
                                        // tslint:disable-next-line:no-unused-expression
                                        selectedTeamMember.auths &&
                                            console.log(
                                                JSON.stringify(
                                                    selectedTeamMember.auths[
                                                    idx
                                                    ],
                                                ),
                                            );
                                        // 能写时，必然能读
                                        if (authItems.writeable) {
                                            // 对比变更
                                            if (
                                                selectedTeamMember.auths &&
                                                !selectedTeamMember.auths[idx]
                                                    .readable
                                            ) {
                                                authItems.readable = true;
                                            }
                                        }
                                        // 不能读时，必然不能写
                                        if (!authItems.readable) {
                                            // 对比变更
                                            if (
                                                selectedTeamMember.auths &&
                                                selectedTeamMember.auths[idx]
                                                    .writeable
                                            ) {
                                                authItems.writeable = false;
                                            }
                                        }
                                        return authItems;
                                    },
                                );
                                setSelectedTeamMember({
                                    ...selectedTeamMember,
                                    auths: finalAuths,
                                });
                            }}
                            tableCheckBoxColumns={[
                                {
                                    isText: true,
                                    dataIndex: 'objName',
                                    title: '相关业务对象名称',
                                },
                                {
                                    dataIndex: 'readable',
                                    title: '业务对象查看',
                                },
                                {
                                    dataIndex: 'writeable',
                                    title: '分配相关对象修改权限',
                                    disabled: !canWrite,
                                },
                            ]}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CustomerTeamMember;
