import React, { useEffect, useMemo, useState } from 'react';
import {
    Modal,
    Form,
    Button,
    Row,
    Col,
    message,
    Alert,
    Icon,
    Input,
    Tag,
    Popconfirm,
    Empty,
} from 'antd';
import _ from 'lodash';
import { useRequest } from 'ahooks';
import { FormComponentProps } from 'antd/lib/form';
import {
    fetchBindTagList,
    getLabelPlan,
    saveLabelPlan,
    labelPlanList,
    deleteLabelPlan,
    IPlanItem,
} from '@/pages/om/tag/list/api';
import SelectGroup from '@/components/common/customer-drawer/customer-labels-modal/select-group';

export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    initialValues?: any;
    planList: IPlanItem[];
    refresh?: () => void;
    onSubmit: (v: any) => void;
    onClose: () => void;
}

const FormModalLabel: React.FC<IFormModalProps> = ({
    visible,
    initialValues,
    planList,
    refresh,
    onSubmit,
    onClose,
    form,
}) => {
    const { getFieldDecorator, validateFields } = form;

    const [selector, setSelector] = useState<any>({});
    const [visiblePlan, setVisiblePlan] = useState(false);
    const [planCheckedId, setPlanCheckedId] = useState(0);

    const { data: tagListData = [], loading } = useRequest(() =>
        fetchBindTagList(),
    );

    const { data: tagPlan, mutate } = useRequest(
        () => getLabelPlan(planCheckedId),
        {
            ready: !!planCheckedId,
            onSuccess() {
                mutate(data => {
                    if (!data) return data;
                    const label = data?.label.map(group => {
                        group.items = group.items?.filter(tag => tag.selected);
                        return group;
                    });
                    return { ...data, label };
                });
            },
            refreshDeps: [planCheckedId],
        },
    );

    useEffect(() => {
        if (initialValues) {
            setSelector(initialValues);
        }
    }, [initialValues]);

    useEffect(() => {
        if (tagPlan) {
            const select = tagPlan.label.reduce((prev, current) => {
                prev[current.id] = current.items?.map(tag => tag.id);
                return prev;
            }, {} as any);

            setSelector(select);
        }
    }, [tagPlan]);

    useEffect(() => {
        if (!visible) {
            setPlanCheckedId(0);
        }
    }, [visible]);

    const handleChangeTag = (value: any, id: string) => {
        const select = { ...selector, [id]: value };
        if (_.isEmpty(value)) {
            delete select[id];
        }
        setSelector(select);
    };

    const handleSubmit = async () => {
        const labels = _.cloneDeep(tagListData);

        const result = labels
            .filter(group => selector[group.id])
            .map(group => {
                group.items = group.items?.filter(tag =>
                    selector[group.id].includes(tag.id),
                );
                return group;
            });

        onSubmit(result);
    };

    // 提交方案
    const handlePlanModelSubmit = () => {
        validateFields(async (errs, values) => {
            if (errs) {
                return;
            }

            let label_id_list: number[] = [];
            if (_.isEmpty(selector)) {
                message.error('未选择标签');
                return;
            }
            Object.keys(selector).map((item: any) => {
                if (_.isEmpty(selector[item])) return;
                label_id_list.push(...selector[item]);
            });
            const params = { ...values, label_id_list };

            await saveLabelPlan(params);
            setVisiblePlan(false);
            message.success('保存标签方案成功');
            refresh?.();
        });
    };

    const handleDeletePlan = async () => {
        if (!planCheckedId) return;
        await deleteLabelPlan(planCheckedId);
        message.success('方案删除成功');
        refresh?.();
    };

    return (
        <Modal
            visible={visible}
            title="筛选标签"
            width={'600px'}
            bodyStyle={{ maxHeight: '350px', overflowY: 'auto' }}
            destroyOnClose
            onCancel={onClose}
            onOk={handleSubmit}
            footer={[
                <Button
                    key="plan"
                    type="primary"
                    disabled={_.isEmpty(selector) || !!planCheckedId}
                    onClick={() => setVisiblePlan(true)}
                >
                    保存方案
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    确定
                </Button>,
                <Button key="back" onClick={onClose}>
                    取消
                </Button>,
            ]}
        >
            {!_.isEmpty(planList) && (
                <div>
                    <Row>⭐️ 常用方案</Row>
                    <Alert
                        style={{ marginTop: '8px' }}
                        message={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <div>
                                    {planList?.map(plan => {
                                        return (
                                            <Button
                                                key={plan.id}
                                                type="link"
                                                size="small"
                                                style={
                                                    planCheckedId === plan.id
                                                        ? undefined
                                                        : { color: '#000' }
                                                }
                                                onClick={() => {
                                                    const id =
                                                        planCheckedId ===
                                                        plan.id
                                                            ? 0
                                                            : plan.id;
                                                    setPlanCheckedId(id);
                                                }}
                                            >
                                                {plan.plan_name}
                                            </Button>
                                        );
                                    })}
                                </div>
                                {!!planCheckedId && (
                                    <Popconfirm
                                        title="是否删除当前方案？"
                                        onConfirm={handleDeletePlan}
                                    >
                                        <div
                                            style={{
                                                fontSize: '12px',
                                                color: '#5d5b5b',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <Icon type="delete" />
                                            删除
                                        </div>
                                    </Popconfirm>
                                )}
                            </div>
                        }
                        type="warning"
                    />
                </div>
            )}

            <Form layout="horizontal">
                {tagListData?.length > 0 ? (
                    tagListData.map((group, index) => {
                        return group.items?.length ? (
                            <Form.Item
                                key={index}
                                label={`${group.name}${
                                    group.option === 2 ? '（多选）' : ''
                                }`}
                            >
                                <SelectGroup
                                    value={selector[group.id] || []}
                                    multiple={group.option === 2}
                                    option={group.items?.map(tag => {
                                        return {
                                            value: tag.id,
                                            label: tag.name,
                                            comment: tag.comment,
                                        };
                                    })}
                                    onChange={v => {
                                        handleChangeTag(v, group.id);
                                    }}
                                />
                            </Form.Item>
                        ) : null;
                    })
                ) : (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span>暂无标签数据</span>}
                    />
                )}
            </Form>

            {/* 保存常用筛选 */}
            <Modal
                visible={visiblePlan}
                title="保存常用筛选"
                width={'400px'}
                destroyOnClose
                onCancel={() => setVisiblePlan(false)}
                onOk={handlePlanModelSubmit}
            >
                <Form labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}>
                    <Form.Item label="名称">
                        {getFieldDecorator('plan_name', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入方案名称',
                                },
                            ],
                        })(
                            <Input
                                maxLength={10}
                                placeholder="请输入方案名称"
                            />,
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        </Modal>
    );
};

export default Form.create<IFormModalProps>()(FormModalLabel);
