import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutStandardCrud, TableLv2 } from '@/components';
import TitleFilter, { menuKeyToDevType } from '../component/title-filter';
import './index.less';
import { defaultAxios } from '@/utils';
import { message } from 'antd';
import _ from 'lodash';
import {
    trans2WDotTwo,
    trans2DotTwo,
} from '../../dashboard/composite-live/component/achievement-situation/index';

const OpportunityReserve: React.FC = () => {
    const [opportunityReserveData, setOpportunityReserveData] = useState<any>(
        [],
    );
    const [opportunityReserveLoading, setOpportunityReserveLoading] = useState(
        false,
    );
    const [acMenuSelect, setACMenuSelect] = useState<string>('');
    const renderMoney2W = (v: number) => {
        return v ? `${trans2WDotTwo(v)}w` : '-';
    };
    const renderPercent = (v: number) => {
        return v ? `${trans2DotTwo(v)}%` : '-';
    };
    const tableColumns: any = [
        {
            title: '本季目标GAP',
            dataIndex: 'income_gap_quarter',
            render: renderMoney2W,
        },
        {
            title: '本季业绩储备（60+80+90）',
            dataIndex: 'opportunity_amount6090_quarter',
            render: renderMoney2W,
        },
        // {
        //     title: '本季业绩储备（60+80）',
        //     dataIndex: 'opportunity_amount6080_quarter',
        //     render: renderMoney2W,
        // },
        {
            title: '本季商机缺口',
            dataIndex: 'opportunity_gap_quarter',
            render: renderMoney2W,
        },
        {
            title: '本季商机缺口占比',
            dataIndex: 'opportunity_amount_rate_quarter',
            render: renderPercent,
        },
        {
            title: '本季90商机',
            dataIndex: 'opportunity_amount90_quarter',
            render: renderMoney2W,
        },
        {
            title: '本季80商机',
            dataIndex: 'opportunity_amount80_quarter',
            render: renderMoney2W,
        },
        {
            title: '本季60商机',
            dataIndex: 'opportunity_amount60_quarter',
            render: renderMoney2W,
        },
        {
            title: '本季40商机',
            dataIndex: 'opportunity_amount40_quarter',
            render: renderMoney2W,
        },
        {
            title: '本季20商机',
            dataIndex: 'opportunity_amount20_quarter',
            render: renderMoney2W,
        },
        {
            title: '本月目标GAP',
            dataIndex: 'income_gap_month',
            render: renderMoney2W,
        },
        {
            title: '本月业绩储备（60+80+90）',
            dataIndex: 'opportunity_amount6090_month',
            render: renderMoney2W,
        },
        // {
        //     title: '本月业绩储备（60+80）',
        //     dataIndex: 'opportunity_amount6080_month',
        //     render: renderMoney2W,
        // },
        {
            title: '本月商机缺口',
            dataIndex: 'opportunity_gap_month',
            render: renderMoney2W,
        },
        {
            title: '本月商机缺口占比',
            dataIndex: 'opportunity_amount_rate_month',
            render: renderPercent,
        },
        {
            title: '本月90商机',
            dataIndex: 'opportunity_amount90_month',
            render: renderMoney2W,
        },
        {
            title: '本月80商机',
            dataIndex: 'opportunity_amount80_month',
            render: renderMoney2W,
        },
        {
            title: '本月60商机',
            dataIndex: 'opportunity_amount60_month',
            render: renderMoney2W,
        },
        {
            title: '本月40商机',
            dataIndex: 'opportunity_amount40_month',
            render: renderMoney2W,
        },
        {
            title: '本月20商机',
            render: renderMoney2W,
            dataIndex: 'opportunity_amount20_month',
        },
    ];
    if (acMenuSelect) {
        switch (acMenuSelect) {
            case 'country':
                tableColumns.unshift({
                    title: '目标等级',
                    dataIndex: 'target_level_name',
                    fixed: true,
                });
                break;
            case 'personal':
                tableColumns.unshift({
                    title: '销售',
                    dataIndex: 'sale_name',
                    fixed: true,
                });
            case 'group':
                tableColumns.unshift({
                    title: '小组',
                    dataIndex: 'sale_dept_name',
                    fixed: true,
                });
            case 'city':
                tableColumns.unshift({
                    title: '城市',
                    dataIndex: 'sale_city_name',
                    fixed: true,
                });
            case 'area':
                tableColumns.unshift({
                    title: '区域',
                    dataIndex: 'sale_area_name',
                    fixed: true,
                });
            default:
                break;
        }
    }
    const fetchOpportunityReserveData = async () => {
        // const { year, menu } = filterData;
        if (!acMenuSelect) {
            // 若无，则代表没权限或权限未加载
            return;
        }
        const url = '/crm/data/query/list_sale_income_oppty_target_model';
        const needFiledKey: string[] = [];
        _.forEach(tableColumns, item => {
            if (item.children) {
                _.forEach(item.children, cItem => {
                    needFiledKey.push(cItem.dataIndex);
                });
            } else {
                needFiledKey.push(item.dataIndex);
            }
        });
        setOpportunityReserveLoading(true);
        const [d, e] = await defaultAxios.put(url, {
            query_object_type_eq: menuKeyToDevType[acMenuSelect],
            fields: needFiledKey,
        });
        setOpportunityReserveLoading(false);
        if (d === null || d.data === null) {
            message.error('查询失败～');
            setOpportunityReserveData([]);
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data)) {
            setOpportunityReserveData(data);
        } else {
            setOpportunityReserveData([]);
            message.error(data?.msg || '查询失败～');
        }
    };
    useEffect(() => {
        fetchOpportunityReserveData();
    }, [acMenuSelect]);
    const exportOptReserveData = () => {
        const url = `/bff/api/rest/ana/sales/opportunity-reserve-export?object_type=${menuKeyToDevType[acMenuSelect]}&export_type=${acMenuSelect}`;
        window.open(url, '_blank');
    };
    return (
        <LayoutStandardCrud
            title={
                <TitleFilter
                    pageKey={'opportunity-reserve'}
                    onFilter={filterData => {
                        if (filterData) {
                            setACMenuSelect(filterData?.menu);
                            // fetchOpportunityReserveData(filterData);
                        }
                    }}
                    onExport={exportOptReserveData}
                />
            }
            message={null}
            popups={null}
            multipleOps={null}
            globalOps={null}
            filters={null}
            dataTable={
                <TableLv2
                    dataSource={opportunityReserveData}
                    loading={opportunityReserveLoading}
                    closeRowSelection={true}
                    pagination={false}
                    scroll={{ x: '1500px' }}
                    columns={tableColumns}
                />
            }
        />
    );
};

export default observer(OpportunityReserve);
