import _ from 'lodash';
import { genDaysAgoFilterFactory } from './helpers';
import { AuthStore } from '@/stores/auth';
import moment from 'moment';

export const customerDFConfs: { [entityCode: string]: any } = {
    customer: {
        lv4MenuFilters: {
            all: [
                {
                    key: 'buy-custom-all-public',
                    label: '全部公海客户',
                    value: [],
                },
                async (options: { authStore: AuthStore }) => {
                    const { authStore } = options;
                    const highseaList = authStore?.userInfo?.highseaList || [];

                    return highseaList
                        .map((highsea: any) => {
                            const { description, id } = highsea || {};
                            if (!description || !id) {
                                return;
                            }
                            return {
                                label: description + '客户',
                                value: [
                                    {
                                        condition: {
                                            fieldId: 'high_sea_id',
                                            filterValue: id,
                                            operateType: '=',
                                        },
                                    },
                                ],
                            };
                        })
                        .filter(Boolean);
                },
            ],
            mine: [
                {
                    key: 'buy-custom-mine-private',
                    label: '全部私池客户',
                    value: [],
                },
                async (options: { authStore: AuthStore }) => {
                    const { authStore } = options;
                    const ownerId = authStore?.userInfo?.sysUser?.id;
                    return {
                        key: 'buy-custom-mine-my',
                        label: '我负责的客户',
                        value: [
                            ownerId
                                ? {
                                      condition: {
                                          fieldId: 'owner_id',
                                          filterValue: ownerId,
                                          operateType: '=',
                                      },
                                  }
                                : null,
                        ].filter(Boolean),
                    };
                },
                async (options: { authStore: AuthStore }) => {
                    const { authStore } = options;
                    const subIds = (authStore?.userInfo?.subUsers || []).map(
                        (item: any) => item.id,
                    );

                    return {
                        key: 'buy-custom-mine-subordinate',
                        label: '我下属的客户',
                        value: [
                            subIds.length > 0
                                ? {
                                      condition: {
                                          fieldId: 'owner_id',
                                          filterValue: subIds.join(','),
                                          operateType: 'in',
                                      },
                                  }
                                : null,
                        ].filter(Boolean),
                    };
                },
            ],
        },
        fastFilters: {
            all: [
                // async () => {
                //     const fc = genDaysAgoFilterFactory({
                //         label: '今日系统退回客户',
                //         fieldId: 'back_high_sea_time',
                //         days: 0,
                //     });
                //     const filter = await fc();
                //     const filterValue = filter.value;
                //     if (_.isArray(filterValue)) {
                //         filterValue.push({
                //             condition: {
                //                 // 0: {constraintLabel: "维护不过来", constraintValue: 1}
                //                 // 1: {constraintLabel: "不想维护了", constraintValue: 2}
                //                 // 2: {constraintLabel: "离职退回", constraintValue: 3}
                //                 // 3: {constraintLabel: "系统退回", constraintValue: 4}
                //                 fieldId: 'transfer_high_sea_reason_type',
                //                 filterValue: 4,
                //                 operateType: '=',
                //             },
                //         });
                //     }
                //     filter.fastFilterKey = 'today-sys-back';
                //     return filter;
                // },
                // {
                //     fastFilterKey: 'gaoqian-cus',
                //     label: '高潜客户',
                //     value: [
                //         {
                //             condition: {
                //                 fieldId: 'high_potential',
                //                 filterValue: 1,
                //                 operateType: '=',
                //             },
                //         },
                //     ],
                // },
                // {
                //     label: '近3天新增高潜',
                //     fastFilterKey: '3days-gaoqian',
                //     value: [
                //         {
                //             condition: {
                //                 // TODO
                //                 fieldId: 'high_potential_create_time',
                //                 filterValue: moment()
                //                     .subtract(3, 'days')
                //                     .startOf('day')
                //                     .format('YYYY-MM-DD HH:mm:ss'),
                //                 operateType: '>=',
                //             },
                //         },
                //     ],
                // },
                {
                    label: '近2日新增优质客户',
                    fastFilterKey: '2days-high-quality-customer',
                    value: [
                        {
                            condition: {
                                // TODO
                                fieldId: 'newHighScoreTwoDay',
                                filterValue: 1,
                                operateType: '=',
                            },
                        },
                    ],
                },
                {
                    label: '今日待跟进线索',
                    fastFilterKey: 'today-not-follow-lead',
                    value: [
                        {
                            condition: {
                                fieldId: 'follow_status',
                                filterValue: 2,
                                operateType: '=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'recent_lead_time',
                                filterValue: moment()
                                    .subtract(0, 'days')
                                    .endOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'recent_lead_time',
                                filterValue: moment()
                                    .subtract(0, 'days')
                                    .startOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '>=',
                            },
                        },
                    ],
                },
                genDaysAgoFilterFactory({
                    label: '今日新增',
                    fastFilterKey: 'today-new',
                    fieldId: 'create_time',
                    days: 0,
                }),
                // genDaysAgoFilterFactory({
                //     label: '今日有线索',
                //     fastFilterKey: 'today-new-lead',
                //     fieldId: 'recent_lead_time',
                //     days: 0,
                // }),
                {
                    label: '今日系统回收',
                    fastFilterKey: 'today-sys-recycle',
                    value: [
                        {
                            condition: {
                                fieldId: 'back_high_sea_time',
                                filterValue: moment()
                                    .startOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '>=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'transfer_high_sea_reason_type',
                                filterValue: '4',
                                operateType: 'in',
                            },
                        },
                    ],
                },
            ],
            mine: [
                // {
                //     label: '近3天新增高潜',
                //     fastFilterKey: '3days-gaoqian',
                //     value: [
                //         {
                //             condition: {
                //                 // TODO
                //                 fieldId: 'high_potential_create_time',
                //                 filterValue: moment()
                //                     .subtract(3, 'days')
                //                     .startOf('day')
                //                     .format('YYYY-MM-DD HH:mm:ss'),
                //                 operateType: '>=',
                //             },
                //         },
                //     ],
                // },
                {
                    label: '近2日新增优质客户',
                    fastFilterKey: '2days-high-quality-customer',
                    value: [
                        {
                            condition: {
                                // TODO
                                fieldId: 'newHighScoreTwoDay',
                                filterValue: 1,
                                operateType: 'in',
                            },
                        },
                    ],
                },
                {
                    label: '今日待跟进线索',
                    fastFilterKey: 'today-not-follow-lead',
                    value: [
                        {
                            condition: {
                                fieldId: 'follow_status',
                                filterValue: 2,
                                operateType: '=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'recent_lead_time',
                                filterValue: moment()
                                    .subtract(0, 'days')
                                    .endOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'recent_lead_time',
                                filterValue: moment()
                                    .subtract(0, 'days')
                                    .startOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '>=',
                            },
                        },
                    ],
                },
                {
                    fastFilterKey: 'today-expire',
                    label: '今日到期',
                    value: [
                        {
                            condition: {
                                fieldId: 'expire_time',
                                filterValue: moment()
                                    .subtract(0, 'days')
                                    .endOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        {
                            condition: {
                                fieldId: 'customer_status',
                                filterValue: '1',
                                operateType: 'in',
                                parser: 'string',
                            },
                        },
                    ],
                },
                // async () => {
                //     return {
                //         label: '7天内未跟进',
                //         fastFilterKey: '7_recent_activity_record_time',
                //         value: [
                //             {
                //                 condition: {
                //                     code: 'orA',
                //                     fieldId: 'recent_activity_record_time',
                //                     filterValue: moment()
                //                         .subtract(7, 'days')
                //                         .startOf('day')
                //                         .format('YYYY-MM-DD HH:mm:ss'),
                //                     operateType: '<=',
                //                 },
                //             },
                //             {
                //                 condition: {
                //                     code: 'orB',
                //                     fieldId: 'recent_activity_record_time',
                //                     filterValue: '',
                //                     operateType: 'null',
                //                 },
                //             },
                //         ],
                //     };
                // },
                {
                    fastFilterKey: '7_recent_activity_record_time',
                    label: '7天未跟进',
                    value: [
                        {
                            condition: {
                                code: 'r2',
                                fieldId: 'recent_activity_record_time',
                                filterValue: moment()
                                    .subtract(7, 'days')
                                    .endOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        {
                            condition: {
                                code: 'c1',
                                fieldId: 'claim_time',
                                filterValue: moment()
                                    .subtract(7, 'days')
                                    .endOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        {
                            condition: {
                                code: 'r1',
                                fieldId: 'recent_activity_record_time',
                                filterValue: '',
                                operateType: 'null',
                            },
                        },
                    ],
                },
                {
                    fastFilterKey: '15_recent_activity_record_time',
                    label: '15天未跟进',
                    value: [
                        {
                            condition: {
                                code: 'r2',
                                fieldId: 'recent_activity_record_time',
                                filterValue: moment()
                                    .subtract(15, 'days')
                                    .endOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        {
                            condition: {
                                code: 'c1',
                                fieldId: 'claim_time',
                                filterValue: moment()
                                    .subtract(15, 'days')
                                    .endOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        {
                            condition: {
                                code: 'r1',
                                fieldId: 'recent_activity_record_time',
                                filterValue: '',
                                operateType: 'null',
                            },
                        },
                    ],
                },
                {
                    fastFilterKey: '30_recent_activity_record_time',
                    label: '30天未跟进',
                    value: [
                        {
                            condition: {
                                code: 'r2',
                                fieldId: 'recent_activity_record_time',
                                filterValue: moment()
                                    .subtract(30, 'days')
                                    .endOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        {
                            condition: {
                                code: 'c1',
                                fieldId: 'claim_time',
                                filterValue: moment()
                                    .subtract(30, 'days')
                                    .endOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                        // {
                        //     condition: {
                        //         code: 'r1',
                        //         fieldId: 'recent_activity_record_time',
                        //         filterValue: '',
                        //         operateType: 'null',
                        //     },
                        // },
                    ],
                },
                // genDaysAgoFilterFactory({
                //     label: '今日有线索',
                //     fastFilterKey: 'today-new-lead',
                //     fieldId: 'recent_lead_time',
                //     days: 0,
                // }),
            ],
            'to-release': [
                {
                    label: '今日到期',
                    fastFilterKey: 'today-experted-customer',
                    value: [
                        {
                            condition: {
                                fieldId: 'expire_time',
                                filterValue: moment()
                                    .endOf('day')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                operateType: '<=',
                            },
                        },
                    ],
                },
            ],
        },
    },
};
