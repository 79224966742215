import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import { Icon } from 'antd';
export interface IFlexibleContentProps {
    style?: React.CSSProperties;
    contentClassName?: string;
    tipClassName?: string; // 展开标志的className
    visible: boolean; // 完全受控
    setVisible: (bool: boolean) => void;
    isStretch: boolean; // 是否伸展
    setIsStretch: (bool: boolean) => void;
    shrink?: React.ReactElement;
    flexibleClassName?: string; // 最外部容器的classname
    width: string | number; // 必须填写width
    canDrag?: boolean; // 是否可以拖拽
}
const FlexibleContent: React.FC<IFlexibleContentProps> = props => {
    const {
        children,
        style,
        contentClassName = '',
        tipClassName = '',
        visible = false,
        setVisible,
        width,
        isStretch = true,
        setIsStretch,
        shrink,
        flexibleClassName = '',
        canDrag,
    } = props;
    const [anyDrag, setAnyDrag] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!visible && canDrag) {
            setAnyDrag(false);
            contentRef.current && contentRef.current.removeAttribute('style');
        }
    }, [visible]);
    return (
        <div
            className={` crm-flexible ${visible ? 'visible' : 'hidden'}  ${
                isStretch ? 'stretch' : 'shrink'
            } ${flexibleClassName} ${anyDrag ? 'drag' : ''}`}
            style={style}
            ref={contentRef}
        >
            <div
                // tslint:disable-next-line:max-line-length
                className={`crm-flexible-tip ${tipClassName} ${
                    visible ? (isStretch ? 'hidden' : 'stretch') : 'hidden'
                }`}
                onClick={() => {
                    setIsStretch(true);
                }}
            >
                {shrink ? (
                    shrink
                ) : (
                    <>
                        继续记录
                        <Icon type="double-left" />
                    </>
                )}
            </div>
            <div
                style={{ width }}
                className={`crm-flexible-content ${contentClassName} ${
                    visible ? (isStretch ? 'stretch' : 'shrink') : 'hidden'
                }`}
                onMouseDown={(e: any) => {
                    if (!canDrag) {
                        return;
                    }
                    if (
                        e.target &&
                        (e.target.tagName === 'TEXTAREA' ||
                            e.target.tagName === 'INPUT')
                    ) {
                        e.stopPropagation();
                        return;
                    }

                    e.stopPropagation();
                    const dom: any = contentRef.current;
                    if (dom) {
                        if (!anyDrag) {
                            const firstY =
                                window.innerHeight / 2 - dom.offsetHeight / 2;
                            const firstX = dom.offsetLeft;
                            dom.style.left = firstX + 'px';
                            dom.style.top = firstY + 'px';
                        }
                        setAnyDrag(true);
                        const diffX = e.clientX - dom.offsetLeft;
                        const diffY = e.clientY - dom.offsetTop;

                        if (typeof dom.setCapture !== 'undefined') {
                            dom.setCapture();
                        }
                        // tslint:disable-next-line:only-arrow-functions
                        // document.addEventListener('touchmove', function(e: any) {
                        //     console.log(e);
                        //     const target = e.target;
                        //     if (target && target.tagName === 'TEXTAREA') {
                        //         e.stopPropagation();
                        //     }
                        // }, true)
                        // tslint:disable-next-line:only-arrow-functions
                        document.onmousemove = function(event: any) {
                            let moveX = event.clientX - diffX;
                            let moveY = event.clientY - diffY;
                            if (moveX < 0) {
                                moveX = 0;
                            } else if (
                                moveX >
                                window.innerWidth - dom.offsetWidth
                            ) {
                                moveX = window.innerWidth - dom.offsetWidth;
                            }
                            if (moveY < 0) {
                                moveY = 0;
                            } else if (
                                moveY >
                                window.innerHeight - dom.offsetHeight
                            ) {
                                moveY = window.innerHeight - dom.offsetHeight;
                            }
                            dom.style.left = moveX + 'px';
                            dom.style.top = moveY + 'px';
                        };
                        document.onmouseup = function(event) {
                            this.onmousemove = null;
                            this.onmouseup = null;
                            if (typeof dom.releaseCapture != 'undefined') {
                                dom.releaseCapture();
                            }
                        };
                    }
                }}
            >
                {children}
            </div>
        </div>
    );
};
export default FlexibleContent;
