import React from 'react';
import _ from 'lodash';
import './index.scss';
import { arrToJson } from '@/utils';
import { Badge } from 'antd';

export const statisticKeyMap: { [key: string]: string } = {
    'my-applicant-todo': 'publishWait',
    'my-applicant-ing': 'publishUnfinished',
    // 'my-applicant-done': 'publishFinished',
    'my-deal-todo': 'unFinished',
    // 'my-deal-done': 'finished',
    // 'my-task-all': 'all',
};

export const DTitle2: React.FC<{
    title: string;
    list?: Array<any>;
    selected?: Array<{ label: string; value: any }>;
    onListItemClick?: (value: any) => void;
    processStatistics: { [key: string]: any };
}> = ({ title, list, selected, onListItemClick, processStatistics }) => {
    const hasList = !_.isNil(list) && _.isArray(list) && list.length > 0;
    selected = selected || [];

    if (!hasList) {
        return null;
    }

    const groups = [
        {
            groupName: '我提交的',
            children: [] as any[],
        },
        {
            groupName: '待我审批的',
            children: [] as any[],
        },
        {
            groupName: '全部流程',
            children: [] as any[],
        },
    ];
    const groupsMap = arrToJson(groups, 'groupName');
    for (const item of list || []) {
        if (!item.group || !groupsMap[item.group]) {
            continue;
        }
        const children = groupsMap[item.group]?.children;
        children?.push(item);
    }

    return (
        <div className="process-cat-dtitle2">
            <div className="process-cat-dtitle2-list-outer">
                <div className="process-cat-dtitle2-list">
                    {groups.map(item => {
                        const { groupName, children } = item;
                        if (!groupName || !children || children.length === 0) {
                            return null;
                        }
                        const list = children;
                        return (
                            <>
                                <h4
                                    style={{
                                        paddingTop: '0.5em',
                                        paddingBottom: '0.5em',
                                        marginBottom: 0,
                                    }}
                                >
                                    {groupName + ''}
                                </h4>
                                <div className="process-cat-dtitle2-grouped-list">
                                    {list
                                        ? list.map(item => {
                                              const {
                                                  label,
                                                  value,
                                                  key,
                                              } = item;
                                              const statistic =
                                                  processStatistics[
                                                      statisticKeyMap[key]
                                                  ];
                                              let isActive = false;
                                              if (_.isArray(selected)) {
                                                  isActive = !!selected.find(
                                                      item =>
                                                          item.value === value,
                                                  );
                                              }

                                              return (
                                                  <div
                                                      className={
                                                          'process-cat-dtitle2-item ' +
                                                          (isActive
                                                              ? 'active'
                                                              : '')
                                                      }
                                                      onClick={() => {
                                                          onListItemClick &&
                                                              onListItemClick(
                                                                  item,
                                                              );
                                                      }}
                                                  >
                                                      <span>
                                                          {label + ''}&nbsp;
                                                          {!!+statistic ? (
                                                              <Badge
                                                                  count={
                                                                      +statistic
                                                                  }
                                                              />
                                                          ) : null}
                                                      </span>
                                                  </div>
                                              );
                                          })
                                        : null}
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
