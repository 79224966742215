import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Input,
    Table,
    Icon,
    ConfigProvider,
    Modal,
    InputNumber,
    Select,
    message,
} from 'antd';
import { ICommonProps, getCommonStatus } from '../common';
import _ from 'lodash';
import { ICommonObjectOptions, WidgetCommonObject } from '../object-hack';
import { InputProps } from 'antd/lib/input';
import { arrToJson, toNum } from '@/utils';
import { toJS } from 'mobx';
import { AntTable, AntButton } from '@/components/antd';
import { fetchFeEquityDetailByBackEndFormat } from '@/pages/om/product/list/utils';
import {
    BlockLoading2,
    loadingWrapper,
} from '@/components/common/block-loading';
import { observer } from 'mobx-react-lite';
import { defaultAxios } from '@/utils';

const equityBasicCols = [
    {
        title: '权益名',
        key: 'equity_name',
        render: (text: any, record: any) => {
            const { datum } = record;
            const value = datum?.relatedObj?.equity_name || '';
            return value;
        },
    },
    {
        title: '标准权益值',
        // original_value
        key: 'original_value',
        render: (text: any, record: any) => {
            const { datum } = record;
            const value = _.isNil(datum?.relatedObj?.original_value)
                ? ''
                : datum?.relatedObj?.original_value;

            return value;
        },
    },
    // 得分肯定不是关联对象的一个字段，这个字段很特殊
    // 其实跟relatedObject关联很弱
    {
        title: '兑换后权益值',
        key: 'value',
        width: 140,
        render: (text: any, record: any) => {
            const { datum, onChange } = record;
            const value = _.trim(
                _.isNil(datum?.relatedObj?.value)
                    ? ''
                    : datum?.relatedObj?.value,
            );
            const hasError = datum?.hasError || false;
            return (
                <div className={hasError ? 'has-error' : ''}>
                    <Input
                        style={{ width: 100 }}
                        onChange={e => {
                            const nextDatum = _.cloneDeep(datum);
                            const obj = nextDatum?.relatedObj;
                            if (obj) {
                                obj.value = obj.value || '';
                                obj.value = e.target.value;
                                if (!_.trim(obj.value)) {
                                    nextDatum.hasError = true;
                                } else {
                                    delete nextDatum.hasError;
                                }
                            }
                            onChange(nextDatum);
                        }}
                        value={value}
                    />
                    {hasError && (
                        <div
                            style={{ marginTop: 6 }}
                            className="ant-form-explain"
                        >
                            该字段为必填项
                        </div>
                    )}
                </div>
            );
        },
    },
    // {
    //     title: '赠送值',
    //     key: 'equity_gift_num',
    //     render: (text: any, record: any) => {
    //         const { datum, onChange } = record;
    //         const value = _.isNil(datum?.relatedObj?.equity_gift_num)
    //             ? 0
    //             : datum?.relatedObj?.equity_gift_num;
    //         const hasError = datum?.hasError || false;

    //         return (
    //             <div className={hasError ? 'has-error' : ''}>
    //                 <InputNumber
    //                     style={{ width: 100 }}
    //                     min={0}
    //                     onChange={v => {
    //                         const nextDatum = _.cloneDeep(datum);
    //                         const obj = nextDatum?.relatedObj;
    //                         if (obj) {
    //                             obj.equity_gift_num = obj.equity_gift_num || 0;
    //                             obj.equity_gift_num = v;
    //                             if (!_.trim(obj.equity_gift_num)) {
    //                                 nextDatum.hasError = true;
    //                             } else {
    //                                 delete nextDatum.hasError;
    //                             }
    //                         }
    //                         onChange(nextDatum);
    //                     }}
    //                     value={value}
    //                 />
    //                 {hasError && (
    //                     <div
    //                         style={{ marginTop: 6 }}
    //                         className="ant-form-explain"
    //                     >
    //                         该字段为必填项
    //                     </div>
    //                 )}
    //             </div>
    //         );
    //     },
    // },
];

export type SelectOptProductValueType = any[];
export interface ISelectOptProductOptions {
    objectOptions?: ICommonObjectOptions;
    inputProps: InputProps;
    removeTotal?: boolean;
    checkDeleteProduct?: (data: any) => Promise<Boolean>;
}

export interface IWidgetSelectOptProductProps<T = any> extends ICommonProps<T> {
    value: SelectOptProductValueType;
    options: ISelectOptProductOptions;
    onChange: (value: SelectOptProductValueType) => void;
}

export const ConfirmEquity: React.FC<{
    productToConfirmEquity: any;
    visible: boolean;
    onChange: (data: any) => void;
    onOk: () => void;
    onCancel: () => void;
}> = observer(
    ({ productToConfirmEquity, visible, onOk, onCancel, onChange }) => {
        // 有可能是新建的，也有可能是修改的
        // 如果说拿不到data，通过str请求一个data
        const equityDetailStr =
            productToConfirmEquity?.relatedObj?.equity_detail || '';
        const equityDetailParser: any[] =
            productToConfirmEquity?.relatedObj?.equity_detail_parsed || [];

        const [data, setData] = useState<any[]>([]);
        useEffect(() => {
            if (visible) {
                setData(equityDetailParser);
            }
        }, [visible]);

        const [loading, setLoading] = useState(false);

        const dataSource = data.map((datum, index) => {
            const innerOnChange = (nextDatum: any) => {
                const nextData = [...data];
                nextData[index] = nextDatum;
                setData(nextData);
            };
            return {
                onChange: innerOnChange,
                datum,
            };
        });

        useEffect(() => {
            (async () => {
                // 新建的case
                if (equityDetailParser.length === 0 && equityDetailStr !== '') {
                    setLoading(true);
                    const data = await fetchFeEquityDetailByBackEndFormat(
                        equityDetailStr,
                    );
                    setLoading(false);
                    onChange(data);
                    setData(data);
                }
            })();
        }, [equityDetailStr]);

        return (
            <div>
                <Modal
                    visible={visible}
                    title={'确定权益'}
                    onOk={() => {
                        if (
                            _.some(data, datum => {
                                return !!datum.hasError;
                            })
                        ) {
                            message.error('存在权益值未填写');
                            return;
                        }
                        onChange(data);
                        onOk();
                    }}
                    onCancel={() => {
                        onCancel();
                    }}
                    zIndex={10001}
                >
                    {loading ? (
                        <BlockLoading2 />
                    ) : (
                        <div>
                            <p>总账号数，请填写管理员+拓客子账号数量的总和</p>
                            <AntTable
                                columns={equityBasicCols}
                                dataSource={dataSource}
                            />
                        </div>
                    )}
                </Modal>
            </div>
        );
    },
);

const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
        <Icon type="smile" style={{ fontSize: 20 }} />
        <p>请选择权益</p>
    </div>
);

export const getProductsTotalPriceByData = (data: any[]) => {
    let total: string | null = null;
    if (
        _.every(data, (cur: any) => {
            return !_.isNil(cur?.relatedObj?.total_price);
        })
    ) {
        total = data
            .reduce((prev, cur) => {
                return prev + toNum(cur?.relatedObj?.total_price);
            }, 0)
            .toFixed(2);
    }
    return total;
};

const InnerFilterChanger: React.FC<{
    objectMeta: any;
    filterValue: any;
    onFilterValueChange: any;
    data: any;
}> = ({ objectMeta, filterValue, onFilterValueChange, data }) => {
    const bizType = data?.biz_type;
    console.log('bizType', bizType, data);
    const [productType, setProductType] = useState<number>(99);
    const badMeta = !_.isArray(objectMeta) || objectMeta.length === 0;
    const objMetaMap = useMemo(() => {
        if (badMeta) {
            return null;
        }
        return arrToJson(objectMeta, 'fieldName');
    }, [badMeta, objectMeta]);
    if (badMeta || bizType === 2) {
        return null;
    }

    const productTypeMeta = objMetaMap?.product_type;
    const productVersionMeta = objMetaMap?.product_version2;
    const productSubcategoriesMeta = objMetaMap?.product_subcategories;
    console.log('objMetaMapppppp', objMetaMap);

    // const { constraintItemList } = constraint as any;
    // widgetItem.filterEnumOptions = {
    //     options: (constraintItemList || []).map((item: any) => {
    //         return {
    //             label: _.isNil(item.constraintLabel)
    //                 ? ''
    //                 : item.constraintLabel + '',
    //             value: _.isNil(item.constraintValue)
    //                 ? ''
    //                 : item.constraintValue + '',
    //         };
    //     }),
    // };
    const productTypeOriginalList = (
        productTypeMeta?.constraint?.constraintItemList || []
    ).filter((item: any) => item.constraintValue !== 21);
    const productVersionOriginalList = (
        productVersionMeta?.constraint?.constraintItemList || []
    ).filter((item: any) => item.constraintValue !== 7);
    const productSubcategoriesOriginalList =
        productSubcategoriesMeta?.constraint?.constraintItemList || [];

    return (
        <div
            style={{
                padding: '8px 18px',
                backgroundColor: '#f6f6fb',
                borderRadius: 4,
            }}
        >
            <span>产品类型：</span>
            <Select
                size={'small'}
                style={{ width: 180 }}
                placeholder={'选择产品类型'}
                getPopupContainer={() => document.body}
                defaultValue={99}
                dropdownStyle={{ zIndex: 100050 }}
                onChange={(v: any) => {
                    const nextFilterValue =
                        v === 99
                            ? []
                            : [
                                  {
                                      fieldName: 'product_type',
                                      operateType: 'EQUAL',
                                      fieldValue: v,
                                  },
                              ];
                    if (_.isArray(filterValue)) {
                        for (const filterItem of filterValue) {
                            if (filterItem.fieldName === 'product_type') {
                                continue;
                            }
                            nextFilterValue.push(filterItem);
                        }
                    }
                    onFilterValueChange(nextFilterValue);
                    setProductType(v);
                    console.log('vvvvvvvv', v);
                }}
            >
                <Select.Option value={99}>不限</Select.Option>
                {productTypeOriginalList.map((item: any) => {
                    return (
                        <Select.Option value={item.constraintValue}>
                            {item.constraintLabel}
                        </Select.Option>
                    );
                })}
            </Select>
            <span>&nbsp;&nbsp;产品小类：</span>
            <Select
                size={'small'}
                style={{ width: 180 }}
                placeholder={'选择产品小类'}
                getPopupContainer={() => document.body}
                defaultValue={99}
                dropdownStyle={{ zIndex: 100050 }}
                onChange={(v: any) => {
                    const nextFilterValue =
                        v === 99
                            ? []
                            : [
                                  {
                                      fieldName: 'product_subcategories',
                                      operateType: 'EQUAL',
                                      fieldValue: v,
                                  },
                              ];
                    if (_.isArray(filterValue)) {
                        for (const filterItem of filterValue) {
                            if (
                                filterItem.fieldName === 'product_subcategories'
                            ) {
                                continue;
                            }
                            nextFilterValue.push(filterItem);
                        }
                    }
                    onFilterValueChange(nextFilterValue);
                }}
            >
                <Select.Option value={99}>不限</Select.Option>
                {productType === 99
                    ? productSubcategoriesOriginalList.map((item: any) => {
                          if (productType === 99) {
                              return (
                                  <Select.Option value={item.constraintValue}>
                                      {item.constraintLabel}
                                  </Select.Option>
                              );
                          }
                          // const subcategories = productSubcategoriesOriginalList.filter((item: any) => {
                          //     item.parentId === productType
                          // })
                      })
                    : productSubcategoriesOriginalList
                          .filter((item: any) => {
                              return item.item.parentId === productType;
                          })
                          .map((item: any) => {
                              return (
                                  <Select.Option value={item.constraintValue}>
                                      {item.constraintLabel}
                                  </Select.Option>
                              );
                          })}
            </Select>
            <span>&nbsp;&nbsp;产品版本：</span>
            <Select
                size={'small'}
                style={{ width: 180 }}
                placeholder={'选择产品版本'}
                getPopupContainer={() => document.body}
                defaultValue={99}
                dropdownStyle={{ zIndex: 100050 }}
                onChange={(v: any) => {
                    const nextFilterValue =
                        v === 99
                            ? []
                            : [
                                  {
                                      fieldName: 'product_version2',
                                      operateType: 'EQUAL',
                                      fieldValue: v,
                                  },
                              ];
                    if (_.isArray(filterValue)) {
                        for (const filterItem of filterValue) {
                            if (filterItem.fieldName === 'product_version2') {
                                continue;
                            }
                            nextFilterValue.push(filterItem);
                        }
                    }
                    onFilterValueChange(nextFilterValue);
                }}
            >
                <Select.Option value={99}>不限</Select.Option>
                {productVersionOriginalList.map((item: any) => {
                    return (
                        <Select.Option value={item.constraintValue}>
                            {item.constraintLabel}
                        </Select.Option>
                    );
                })}
            </Select>
        </div>
    );
};

export const WidgetSelectOptProduct: React.FC<IWidgetSelectOptProductProps> = ({
    k,
    value,
    options,
    onChange,
    onInteract = key => void 0,
    status,
    data,
    statusExtraData,
}) => {
    const { loading, disabled } = getCommonStatus(
        status,
        data,
        statusExtraData,
        k,
    );

    const [saleTagOptions, setSaleTagOptions] = useState<any[]>([]);
    const [openTypeOptions, setOpenTypeOptions] = useState<any[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const url = '/crm/metadata/opportunity_product';
                const [d, e] = await defaultAxios.get(url);
                if (!d || !d.data) {
                    return [null, e];
                }
                const options = d.data.fieldMetadatas?.sale_tag?.options || [];
                const openOptions =
                    d.data.fieldMetadatas?.open_type?.options || [];
                console.log('dddd', d);
                setSaleTagOptions(options);
                setOpenTypeOptions(openOptions);
            } catch (error) {
                console.error(`Error when fetching data: ${error}`);
                return [null, error];
            }
        })();
    }, []);

    const [innerMetaMap, setInnerMetaMap] = useState<any>({});
    const onGotMeta = useCallback(
        (newMeta: any[]) => setInnerMetaMap(arrToJson(newMeta, 'fieldName')),
        [setInnerMetaMap],
    );
    const [modalVisibleTrigger, setModalVisibleTrigger] = useState(0);
    value = _.isArray(value) ? value : [];

    let { removeTotal, checkDeleteProduct } = options;
    removeTotal = false;
    if (_.isNil(options.objectOptions)) {
        return null;
    }

    const openInnerModal = () =>
        setModalVisibleTrigger(1 + modalVisibleTrigger);

    const columns = [
        {
            title: '产品',
            key: 'product_name',
            width: 120,
            render: (text: any, record: any) => {
                const { datum } = record;
                const value = datum?.relatedObj?.product_name || '';
                return value;
            },
        },
        {
            title: '产品描述',
            key: 'product_describe',
            width: 120,
            render: (text: any, record: any) => {
                const { datum } = record;
                const value = datum?.relatedObj?.product_describe || '';
                return value;
            },
        },
        {
            title: '产品类型',
            key: 'product_type',
            render: (text: any, record: any) => {
                const { datum, innerMetaMap } = record;
                const options =
                    innerMetaMap['product_type']?.constraint
                        ?.constraintItemList || [];
                const value = datum?.relatedObj?.product_type;
                const optionItem = options.find(
                    (item: any) => item.constraintValue === value,
                );
                return optionItem?.constraintLabel || '';
            },
        },
        {
            title: '产品小类',
            key: 'product_subcategories',
            render: (text: any, record: any) => {
                const { datum, innerMetaMap } = record;
                const options =
                    innerMetaMap['product_subcategories']?.constraint
                        ?.constraintItemList || [];
                const value = datum?.relatedObj?.product_subcategories;
                const optionItem = options.find(
                    (item: any) => item.constraintValue === value,
                );
                return optionItem?.constraintLabel || '';
            },
        },
        {
            title: '产品版本',
            key: 'product_version2',
            render: (text: any, record: any) => {
                const { datum, innerMetaMap } = record;
                const options =
                    innerMetaMap['product_version2']?.constraint
                        ?.constraintItemList || [];

                const value = datum?.relatedObj?.product_version2;
                const optionItem = options.find(
                    (item: any) => item.constraintValue === value,
                );
                return optionItem?.constraintLabel || '';
            },
        },
        // 得分肯定不是关联对象的一个字段，这个字段很特殊
        // 其实跟relatedObject关联很弱
        {
            title: '产品表价格',
            key: 'product_price',
            render: (text: any, record: any) => {
                const { datum } = record;
                const value = datum?.relatedObj?.product_price || '';
                return value;
            },
        },
        // buy_type
        {
            title: '购买类型',
            key: 'buy_type',
            render: (text: any, record: any) => {
                const { datum, onChange, disabled } = record;
                const value = _.isNil(datum?.relatedObj?.buy_type)
                    ? undefined
                    : datum?.relatedObj?.buy_type;
                if (!!disabled) {
                    if (value === 0) {
                        return '赠送';
                    } else if (value === 1) {
                        return '付费';
                    } else {
                        return '';
                    }
                }
                return (
                    <Select<number>
                        value={value}
                        onChange={v => {
                            const nextDatum = _.cloneDeep(datum);
                            const obj = nextDatum?.relatedObj;
                            if (obj) {
                                obj.buy_type = v;

                                if (v === 0) {
                                    obj.original_discount = obj.discount;
                                    obj.discount = '0';
                                } else if (v === 1) {
                                    obj.discount =
                                        obj.original_discount || '100';
                                }
                            }
                            onChange(nextDatum);
                        }}
                    >
                        <Select.Option value={1}>付费</Select.Option>
                        <Select.Option value={0}>赠送</Select.Option>
                    </Select>
                );
            },
        },
        {
            title: (
                <span>
                    <span style={{ color: 'red' }}>*</span>售卖标签
                </span>
            ),
            key: 'sale_tag',
            width: 150,
            render: (text: any, record: any) => {
                const { datum, onChange, disabled } = record;

                // const options = [
                //     { label: '常规策略', value: 1 },
                //     { label: '领英专项促销', value: 11 },
                //     { label: '618活动', value: 12 },
                //     // { label: 'TL促单', value: 2 },
                //     // { label: '春招赠资源包', value: 3 },
                //     // { label: '春招企业版套餐', value: 4 },
                //     // { label: '春招猎头版套餐', value: 5 },
                //     // { label: '春招增购折扣', value: 6 },
                //     // { label: '春招账号电话版', value: 7 },
                //     // { label: '春招大客折扣', value: 8 },
                //     // { label: '个人猎头转化项目', value: 9 },
                // ];
                // const options =
                //     datum?.objectMeta[
                //         'sale_tag'
                //     ]?.constraint?.constraintItemList.map((item: any) => ({
                //         label: item.constraintLabel,
                //         value: item.constraintValue,
                //     })) || [];

                const value = _.isNil(datum?.relatedObj?.sale_tag)
                    ? undefined
                    : datum?.relatedObj?.sale_tag;
                if (!!disabled) {
                    return (
                        saleTagOptions.find((item: any) => item.value === value)
                            ?.label || '-'
                    );
                }
                return (
                    <Select<number>
                        value={value}
                        onChange={v => {
                            const nextDatum = _.cloneDeep(datum);
                            const obj = nextDatum?.relatedObj;
                            if (obj) {
                                obj.sale_tag = v;
                            }
                            onChange(nextDatum);
                        }}
                    >
                        {saleTagOptions
                            .filter((item: any) => item.choice)
                            .map((item: any) => (
                                <Select.Option
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </Select.Option>
                            ))}
                    </Select>
                );
            },
        },
        {
            title: (
                <span>
                    <span style={{ color: 'red' }}>*</span>签约类型
                </span>
            ),
            key: 'open_type',
            width: 150,
            render: (text: any, record: any) => {
                const { datum, onChange, disabled } = record;
                console.log('rrrrecord', record);
                const value = _.isNil(datum?.relatedObj?.open_type)
                    ? undefined
                    : datum?.relatedObj?.open_type;
                // if (!!disabled) {
                //     return (
                //         openTypeOptions.find((item: any) => item.value === value)
                //             ?.label || '-'
                //     );
                // }
                return (
                    <Select<number>
                        value={value}
                        onChange={v => {
                            const nextDatum = _.cloneDeep(datum);
                            const obj = nextDatum?.relatedObj;
                            if (obj) {
                                obj.open_type = v;
                            }
                            onChange(nextDatum);
                        }}
                    >
                        {openTypeOptions
                            ?.filter((item: any) => item.choice)
                            .map((item: any) => (
                                <Select.Option
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </Select.Option>
                            ))}
                    </Select>
                );
            },
        },
        {
            title: (
                <span>
                    <span style={{ color: 'red' }}>*</span>销售单价
                </span>
            ),
            key: 'sale_price',
            width: 80,
            render: (text: any, record: any) => {
                const { datum, onChange, disabled } = record;
                const value = _.isNil(datum?.relatedObj?.sale_price)
                    ? ''
                    : datum?.relatedObj?.sale_price;
                if (!!disabled) {
                    return value;
                }
                return (
                    <Input
                        onChange={e => {
                            const nextDatum = _.cloneDeep(datum);
                            const obj = nextDatum?.relatedObj;
                            if (obj) {
                                obj.sale_price = obj.sale_price || '';
                                obj.sale_price = e.target.value;
                            }
                            onChange(nextDatum);
                        }}
                        value={value}
                    />
                );
            },
        },
        {
            title: (
                <span>
                    <span style={{ color: 'red' }}>*</span>数量
                </span>
            ),
            key: 'sale_num',
            width: 80,
            render: (text: any, record: any) => {
                const { datum, onChange, disabled } = record;
                const value = _.isNil(datum?.relatedObj?.sale_num)
                    ? ''
                    : datum?.relatedObj?.sale_num;

                if (!!disabled) {
                    return value;
                }
                return (
                    <Input
                        disabled={true}
                        onChange={e => {
                            const nextDatum = _.cloneDeep(datum);
                            const obj = nextDatum?.relatedObj;
                            if (obj) {
                                obj.sale_num = obj.sale_num || '';
                                obj.sale_num = e.target.value;
                            }
                            onChange(nextDatum);
                        }}
                        value={value}
                    />
                );
            },
        },
        {
            title: (
                <span>
                    <span style={{ color: 'red' }}>*</span>服务月份
                </span>
            ),
            key: 'service_month',
            width: 80,
            render: (text: any, record: any) => {
                const { datum, onChange, disabled } = record;
                const value = _.isNil(datum?.relatedObj?.service_month)
                    ? ''
                    : datum?.relatedObj?.service_month;

                if (!!disabled) {
                    return value;
                }
                return (
                    <InputNumber
                        min={0}
                        precision={0}
                        onChange={v => {
                            const nextDatum = _.cloneDeep(datum);
                            const obj = nextDatum?.relatedObj;
                            if (obj) {
                                obj.service_month = obj.service_month || '';
                                obj.service_month = v;
                            }
                            onChange(nextDatum);
                        }}
                        value={value}
                    />
                );
            },
        },
        {
            title: (
                <span>
                    <span style={{ color: 'red' }}>*</span>折扣
                </span>
            ),
            key: 'discount',
            width: 70,
            render: (text: any, record: any) => {
                const { datum, onChange, disabled } = record;
                const value = _.isNil(datum?.relatedObj?.discount)
                    ? ''
                    : datum?.relatedObj?.discount;

                const buyType = datum?.relatedObj?.buy_type;
                if (!!disabled) {
                    return value + '%';
                }
                return (
                    <InputNumber
                        value={value}
                        min={0}
                        max={100}
                        disabled={buyType === 0}
                        formatter={value => `${value}%`}
                        parser={value => (value ? value.replace('%', '') : '')}
                        onChange={v => {
                            const nextDatum = _.cloneDeep(datum);
                            const obj = nextDatum?.relatedObj;
                            if (obj) {
                                obj.discount = obj.discount || '';
                                obj.discount = v + '';
                            }
                            onChange(nextDatum);
                        }}
                    />
                );
            },
        },
        {
            title: '权益',
            key: 'equity_detail',
            render: (text: any, record: any) => {
                const { datum, onChange, onInteract } = record;
                // 这个是产品那边拿到的权益列表，只是一个string，这里用个临时组件，实现一下它的解析和编辑功能
                // 如果这里没有编辑过，在写入的时候，需要转化一下

                return (
                    <AntButton
                        type="link"
                        size="small"
                        disabled={disabled}
                        style={{ fontSize: 12 }}
                        onClick={() => onInteract('confirm-equity')}
                    >
                        权益
                    </AntButton>
                );
            },
        },
        {
            title: '总价',
            key: 'total_price',
            render: (text: any, record: any) => {
                const { datum, onChange } = record;
                const value = datum?.relatedObj?.total_price;
                return <div>{!_.isNil(value) ? value : '--'}</div>;
            },
        },
        {
            title: '',
            key: 'op',
            render: (text: any, record: any) => {
                const { onDelete } = record;
                return (
                    <AntButton
                        type="link"
                        size="small"
                        disabled={disabled}
                        style={{ fontSize: 12 }}
                        onClick={onDelete}
                    >
                        删除
                    </AntButton>
                );
            },
        },
    ];

    const dataSource = value.map((datum, index) => {
        const innerOnChange = (nextDatum: any) => {
            // 计算总价
            if (nextDatum.relatedObj) {
                const {
                    product_price,
                    sale_price,
                    sale_num,
                    discount,
                } = nextDatum.relatedObj;
                nextDatum.relatedObj.total_price =
                    ((Number.isNaN(toNum(sale_price))
                        ? toNum(product_price)
                        : toNum(sale_price)) *
                        toNum(sale_num) *
                        toNum(discount)) /
                    100;

                nextDatum.relatedObj.total_price = !_.isNaN(
                    nextDatum.relatedObj.total_price,
                )
                    ? nextDatum.relatedObj.total_price.toFixed(2)
                    : null;
            }
            const nextData = [...value];
            nextData[index] = nextDatum;
            onChange(nextData);
        };

        const innerOnInteract = (interStr: string) => {
            onInteract(interStr, datum);
        };

        const onDelete = async () => {
            if (checkDeleteProduct) {
                const ok = await checkDeleteProduct(datum);
                if (!ok) {
                    return;
                }
            }
            const nextData = [...value];
            nextData.splice(index, 1);
            onChange(nextData);
        };

        return {
            onDelete,
            onChange: innerOnChange,
            onInteract: innerOnInteract,
            datum,
            disabled,
            innerMetaMap,
        };
    });

    const total: string | null = removeTotal
        ? null
        : getProductsTotalPriceByData(value);

    return loadingWrapper(!!loading)(
        <div
            style={{
                width: 900,
                minWidth: 150,
                display: 'inline-block',
                position: 'relative',
                padding: '10px 15px',
                backgroundColor: '#f7f8fa',
                left: -80,
                ...(disabled
                    ? {
                          cursor: 'not-allowed',
                      }
                    : {}),
            }}
            className="crm-widgets-multiple-obj"
        >
            <div
                style={{
                    ...(disabled
                        ? {
                              //   pointerEvents: 'none',
                              opacity: 0.7,
                          }
                        : {}),
                }}
                className="display-table"
            >
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                    <AntTable
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                    />
                </ConfigProvider>
            </div>
            <div
                style={{
                    ...(disabled
                        ? {
                              pointerEvents: 'none',
                              opacity: 0.7,
                          }
                        : {}),
                }}
                className="selector-display"
            >
                <div style={{ display: 'none' }}>
                    <WidgetCommonObject
                        k={k}
                        value={null}
                        data={data}
                        onChange={nextValue => {
                            const valueMap = arrToJson(value, 'originalValue');
                            for (const item of nextValue) {
                                // 20210812 BREAKING CHANGE
                                // 改动1，没有选择过的概念，一切皆是追加
                                // 改动2，不向下游指示value值
                                // 改动3，下游只带回新值
                                // if (valueMap[item.originalValue]) {
                                //     // 已经选择过的
                                //     item.relatedObj =
                                //         valueMap[item.originalValue].relatedObj;
                                // } else {
                                // 新选择
                                item.relatedObj.discount = '100';
                                item.relatedObj.sale_price =
                                    item.relatedObj.product_price;
                                item.relatedObj.sale_num = '1';
                                item.relatedObj.buy_type = 1;

                                const {
                                    sale_price,
                                    product_price,
                                    sale_num,
                                    discount,
                                } = item.relatedObj;

                                // TODOO 这里是产品的字段，商机产品以及合同产品叫什么这里需要转一下
                                // 这里先按这两个做
                                // product_type: 1
                                // product_version2: 2

                                item.relatedObj.total_price =
                                    ((Number.isNaN(toNum(sale_price))
                                        ? toNum(product_price)
                                        : toNum(sale_price)) *
                                        toNum(sale_num) *
                                        toNum(discount)) /
                                    100;
                                // }
                            }
                            onChange([...value, ...nextValue]);
                        }}
                        options={{
                            ...options.objectOptions,
                            multiple: true,
                            modalVisibleTrigger,
                            paramsBlackList: {
                                equity_detail: true,
                                talent_lib_version: true,
                                product_deliver_method: true,
                            },
                            innerFilterChanger: InnerFilterChanger,
                            onGotMeta,
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <a onClick={() => openInnerModal()}>+ 添加产品</a>
                    {removeTotal ? null : (
                        <div>汇总：{!_.isNil(total) ? total : '--'}</div>
                    )}
                </div>
            </div>
        </div>,
    );
};
