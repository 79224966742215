import React, { useEffect } from 'react';
import { ProcessCatStore } from '../store';
import { useAnyContractListStore } from '@/pages/om/contract/list/store';
import { BlockLoading2, IProcessCtx } from '@/components';
import _ from 'lodash';
import { ContractFormProcess } from '@/pages/om/contract/list/components/form-process';
import { embedProductParamToContract } from '@/pages/om/contract/list/utils';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';

export const ContractFormProxy: React.FC<{
    defaultStore: ProcessCatStore;
    processCtx: IProcessCtx | null;
}> = observer(({ defaultStore, processCtx }) => {
    const [prepared, contractListStore] = useAnyContractListStore({});
    useEffect(() => {
        if (defaultStore.mutatingDataLoading) {
            return;
        }
        // 故意的副作用，让新表单接管数据
        contractListStore.setMutatingData(defaultStore.mutatingData);
        if (defaultStore.mutatingData) {
            embedProductParamToContract(defaultStore.mutatingData);
            defaultStore.mutatingData._contract_attachments =
                defaultStore.mutatingData.contract_attachments;
        }
    }, [defaultStore.mutatingData, defaultStore.mutatingDataLoading]);

    // for dep
    const { netValidateResult } = defaultStore;
    useEffect(() => {
        contractListStore.setNetValidateResult(defaultStore.netValidateResult);
    }, [defaultStore.netValidateResult, contractListStore]);

    useEffect(() => {
        if (defaultStore.defaultMeta !== null) {
            contractListStore.setDefaultMeta(defaultStore.defaultMeta);
        }
    }, [defaultStore.defaultMeta]);

    // // 同步这个数据
    // useEffect(() => {
    //     if (defaultStore.defaultMeta) {
    //         defaultStore.defaultMeta.setProcessPermAll(contractListStore.defaultMeta.processPermAll)
    //     }
    // }, [ contractListStore.defaultMeta.processPermAll ])

    if (!prepared || !processCtx) {
        return null;
    }

    return (
        <ContractFormProcess
            defaultStore={contractListStore}
            processCtx={processCtx}
        />
    );
});
