import React, {
    useLayoutEffect,
    useContext,
    useEffect,
    Component,
    useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import {
    Router,
    Location,
    globalHistory,
    Redirect,
    RouteComponentProps,
} from '@reach/router';
import Dashboard, { outDateStore } from '@/components/layouts/dashboard';
import StatusWrapper, { P404, P403, P500 } from '@/pages/status';

import { IComResouceMap } from '@/configs/resource-map';
import { IRouteLayout } from '@/configs/route';

import _ from 'lodash';
import { BlockLoading3 } from './components/common';
import { StoreContext } from '@/stores';
import { Alert, Modal, Result } from 'antd';
import { IPermMap, PermHOC } from './stores/perm';
import Axios from 'axios';
import * as Sentry from '@sentry/react';
import { AntButton } from './components/antd/index';
import { Typography, Icon } from 'antd';
import { defaultAxios } from '@/utils';
const { Paragraph, Text } = Typography;

let outDateCheckInterval = 10 * 1000; // 测试 10s
if (process.env.REACT_APP_ENV === 'pro') {
    outDateCheckInterval = 12 * 60 * 60 * 1000; // 线上 12hours
}

let updateCheckInterval = 10 * 1000; // 测试 10s
if (process.env.REACT_APP_ENV === 'pro') {
    updateCheckInterval = 1000 * 60 * 5; // 线上 5min
}

// globalHistory.listen((e) => {
//     debugger
// })
const DefaultRoute: React.FC<RouteComponentProps & {
    defaultSelectedChildrenPath?: string;
    routeKey: string;
}> = ({
    children,
    location,
    navigate,
    path,
    defaultSelectedChildrenPath = '',
    routeKey,
}) => {
    // 默认空路由，自动跳转到默认路由
    // 非空顶层路由，需要自行编写跳转的方法
    // Redirect失效
    // 当默认路由没有权限的时候，向下寻找到有权限的为止
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    // listened permChange
    const menuPermMap = authStore.defaultMenuPermStore.permMap;
    const menuPermInited = authStore.defaultMenuPermStore.inited;

    const permItem = menuPermMap[routeKey] || { visible: false };
    const visible = permItem.visible || routeKey.startsWith('demo2');
    useEffect(() => {
        if (
            location?.pathname === path &&
            defaultSelectedChildrenPath !== path
        ) {
            navigate!(defaultSelectedChildrenPath);
        }
    }, []);
    return <>{children}</>;
};

export class ErrorBoundaryComponent extends Component<
    RouteComponentProps & {
        isAll?: boolean;
    },
    {
        hasError: boolean;
    }
> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false,
        };
    }
    public static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }
    public sendErrorToLog = async (error: Error, errorInfo: any) => {
        if ('pro' !== process.env.REACT_APP_ENV) {
            // 只在线上记录
            return;
        }
        const url = '/bff/api/rest/alert';
        let errorString = '';
        let errorInfoString = '';
        try {
            errorInfoString = JSON.stringify(errorInfo);
            errorString = JSON.stringify(error);
        } catch (error) {}
        // 接入Sentry
        // tslint:disable-next-line:only-arrow-functions
        Sentry.withScope(function(scope) {
            scope.setTag('CRM-PC', 'Error-Boundary');
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            scope.setExtra('errorStack', error.stack);
            // 如果不新生成error并吞掉stack，会传递一个不带tag的错误出去
            const errorTemp = new Error(error.name);
            errorTemp.message = error.message;
            Sentry.captureException(errorTemp);
        });
        await defaultAxios.post(url, {
            msg: `error: ${errorString}\nerrorInfo: ${errorInfoString}\nerrorPage: ${window.location.href}`,
        });
    };
    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.sendErrorToLog(error, errorInfo);
        console.group();
        console.log('ErrorBoundary catch a error:');
        console.info('error', error);
        console.info('error info', errorInfo);
        console.groupEnd();
    }
    public componentWillReceiveProps(nextProps: any) {
        if (this.props.isAll) {
            return;
        }
        if (this.state.hasError) {
            this.setState({
                hasError: false,
            });
        }
    }
    private renderErrorPage = () => {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#FFF',
                }}
                className="crm-error-page"
            >
                <Result
                    status={'error'}
                    title={'啊哦～页面出错了'}
                    subTitle={'请联系平台效能反馈问题 ;)'}
                    extra={[
                        <AntButton
                            type="primary"
                            key="console"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            刷新页面
                        </AntButton>,
                    ]}
                >
                    <div className="desc">
                        <Paragraph>
                            <Text
                                strong
                                style={{
                                    fontSize: 16,
                                }}
                            >
                                当您看到这个页面的时候，您应当:
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Icon
                                style={{ color: '#0052ff' }}
                                type="check-circle"
                            />{' '}
                            联系平台效能开发同学
                            <a>@shaomingquan@lichen</a>反馈问题
                        </Paragraph>
                        <Paragraph>
                            <Icon
                                style={{ color: '#0052ff' }}
                                type="check-circle"
                            />{' '}
                            请尽量保留现场<a>完整截图</a>并描述问题的发生的
                            <a>前序操作</a>
                        </Paragraph>
                    </div>
                </Result>
            </div>
        );
    };
    public render() {
        return (
            <>
                {this.state.hasError
                    ? this.renderErrorPage()
                    : this.props.children}
            </>
        );
    }
}

const AppRaw: React.FC<{
    comResouceMap: IComResouceMap;
    routeLayout: IRouteLayout;
}> = ({ comResouceMap, routeLayout }) => {
    const appMounted = true;
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const compNizr = store.getDefaultCompatibilitySniffer();
    const isCompatible = compNizr.isCompatible;
    const sniffing = compNizr.sniffing;

    // listened permChange
    const menuPermMap = authStore.defaultMenuPermStore.permMap;
    const menuPermInited = authStore.defaultMenuPermStore.inited;

    // 监听前端发布
    // 轮询变更
    useLayoutEffect(() => {
        let firstHTML = '';
        let secondHTML = '';
        window.localStorage.removeItem('pageoutdatetime');
        let timer: any = null;
        const next = () => {
            timer && clearInterval(timer);
            timer = setTimeout(handler, updateCheckInterval);
        };
        const handler = () => {
            console.log('test', window.location.origin);
            try {
                Axios('/?hash=' + Math.random())
                    .then(data => {
                        // 不为200 证明在发布
                        if (data.status != 200) {
                            next();
                            return;
                        }
                        if (!firstHTML) {
                            firstHTML = data.data;
                            next();
                        } else {
                            secondHTML = data.data;
                            if (secondHTML !== firstHTML) {
                                const checkForceUpdate =
                                    process.env.REACT_APP_ENFORCE === 'true'; // 存在enforce强制更新特殊标记
                                // 刷新
                                clearInterval(timer);
                                Modal.confirm({
                                    title: '系统更新',
                                    content: (
                                        <span>
                                            检测到当前系统存在更新，
                                            <span
                                                style={{
                                                    color: 'red',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                为了保证新功能的正常使用
                                            </span>
                                            ，请点击确定进行刷新或自行刷新浏览器
                                            {checkForceUpdate
                                                ? ',本次更新为「强制更新」'
                                                : ''}
                                        </span>
                                    ),
                                    okText: '确定',
                                    cancelText: '取消',
                                    maskClosable: false,
                                    zIndex: 1050,
                                    onOk: () => {
                                        window.location.reload();
                                    },
                                    onCancel: () => {
                                        // 添加outdate状态

                                        outDateStore.isOutDate = true;
                                        window.localStorage.setItem(
                                            'pageoutdatetime',
                                            Date.now() + '',
                                        );

                                        // 这里不next，已经确定未刷新，其他事情交给下面的循环
                                        // next()
                                    },
                                    cancelButtonProps: {
                                        disabled: checkForceUpdate,
                                    },
                                });
                            } else {
                                // 取消outdate状态
                                next();
                                window.localStorage.removeItem(
                                    'pageoutdatetime',
                                );
                            }
                        }
                    })
                    .catch(e => {});
            } catch (error) {}
        };
        next();
        return () => {
            timer && clearInterval(timer);
        };
    }, []);

    useLayoutEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            let timer: any = null;
            const next = () => {
                timer && clearInterval(timer);
                timer = setTimeout(handler, 10 * 1000);
            };
            const handler = () => {
                const pageoutdatetime = Number(
                    window.localStorage.getItem('pageoutdatetime'),
                );
                if (!!pageoutdatetime) {
                    if (Date.now() - pageoutdatetime >= outDateCheckInterval) {
                        Modal.confirm({
                            title: '系统更新',
                            content: (
                                <span>
                                    检测到当前页面未更新 ，
                                    <span
                                        style={{
                                            color: 'red',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        为了保证新功能的正常使用
                                    </span>
                                    ，请点击确定进行刷新
                                </span>
                            ),
                            okText: '确定',
                            cancelText: '取消',
                            maskClosable: false,
                            onOk: () => {
                                window.localStorage.removeItem(
                                    'pageoutdatetime',
                                );
                                window.location.reload();
                            },
                            onCancel() {
                                next();
                            },
                        });
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            };
            next();
            return () => {
                timer && clearInterval(timer);
            };
        }
    }, []);

    useLayoutEffect(() => {
        compNizr.check();
        if (isCompatible) {
            // 尝试获取用户信息，不一定需要登录
            authStore.getUserInfo(true);
        }
    }, [isCompatible]);

    if (sniffing) {
        return null;
    }

    if (!isCompatible) {
        return (
            <div style={{ padding: 15 }}>
                <Alert
                    type="warning"
                    message={<span>浏览器不兼容</span>}
                    description={
                        <a
                            target="_blank"
                            href="https://www.google.com/search?q=chrome+download"
                        >
                            请下载Google Chrome~
                        </a>
                    }
                />
            </div>
        );
    }

    if (!appMounted || !menuPermInited) {
        return <BlockLoading3 />;
    }

    const traverse = (ml: IRouteLayout, menuPerm: IPermMap) => {
        return (
            <ErrorBoundaryComponent path="/">
                {_.map(ml, menu => {
                    const {
                        key,
                        schema,
                        children,
                        defaultSelectedChildrenPath,
                        forceShow,
                    } = menu;
                    let Com = comResouceMap[key];
                    if (!Com) {
                        if (!children || children.length === 0) {
                            return (
                                <DefaultRoute
                                    routeKey={key}
                                    key={key}
                                    path={schema}
                                    defaultSelectedChildrenPath={
                                        defaultSelectedChildrenPath
                                    }
                                />
                            );
                        } else {
                            return (
                                <DefaultRoute
                                    routeKey={key}
                                    key={key}
                                    path={schema}
                                    defaultSelectedChildrenPath={
                                        defaultSelectedChildrenPath
                                    }
                                >
                                    {traverse(children, menuPerm)}
                                </DefaultRoute>
                            );
                        }
                    }
                    Com = PermHOC(
                        comResouceMap[key],
                        !forceShow
                            ? menuPerm
                            : { ...menuPerm, [key]: { visible: true } },
                        key,
                    );
                    if (!children || children.length === 0) {
                        return <Com key={key} path={schema} />;
                    }
                    return (
                        <Com key={key} path={schema}>
                            {traverse(children, menuPermMap)}
                        </Com>
                    );
                })}
            </ErrorBoundaryComponent>
        );
    };
    return (
        <>
            <Router>
                <Dashboard
                    routeLayout={routeLayout}
                    menuPerm={menuPermMap}
                    path={'/'}
                >
                    {traverse(routeLayout, menuPermMap)}
                    <StatusWrapper path="/status">
                        <P404 path="/404" />
                        <P403 path="/403" />
                        <P500 path="/500" />
                    </StatusWrapper>
                </Dashboard>
            </Router>
        </>
    );
};

const App = observer(AppRaw);

export default Sentry.withProfiler(App);
