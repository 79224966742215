import _ from 'lodash';
import { genDaysAgoFilterFactory } from './helpers';
import { AuthStore } from '@/stores/auth';
import moment from 'moment';

export const optProductDFConfs: { [entityCode: string]: any } = {
    opportunity_product: {
        lv4MenuFilters: {
            all: [
                {
                    key: 'opportunity-product-all',
                    label: '全部商机明细',
                    value: [],
                },
                async (options: { authStore: AuthStore }) => {
                    const { authStore } = options;
                    const ownerId = authStore?.userInfo?.sysUser?.id;
                    return {
                        key: 'buy-opportunity-product-mine',
                        label: '我负责的商机明细',
                        value: [
                            ownerId
                                ? {
                                      condition: {
                                          fieldId: 'owner_id',
                                          filterValue: ownerId,
                                          operateType: '=',
                                      },
                                  }
                                : null,
                        ].filter(Boolean),
                    };
                },
            ],
            mine: [],
        },
        fastFilters: {
            all: [],
            mine: [],
        },
    },
};
