import React, { useState, useCallback, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { ModalProps } from 'antd/lib/modal';
import { defaultAxios, useNet } from '@/utils';
import { Modal, DatePicker } from 'antd';
import { TableLv2 } from '../table/level2';
import './index.scss';

export interface IEntityLogSearchData {
    beginDate: Moment;
    endDate: Moment;
    pageNum?: number;
    pageSize?: number;
}
export interface IEntityLogData {
    binLogTime: string | Moment;
    afterValue: any;
    beforeValue: any;
    operateType: string;
    updateTime: string;
    updateUser: string;
    operateField: string;
}
export type TGetEntityLogReturnType = NetResponse<{
    data: IEntityLogData[];
    msg: string;
    code: number;
}>;
export interface ILogModalProps extends ModalProps {
    entityCode: string;
    entityValueId: any;
    modalVis: boolean;
    setModalVis: (bool: boolean) => void;
}
const LogModal: React.FC<ILogModalProps> = props => {
    const { entityCode, modalVis, setModalVis, entityValueId } = props;
    const [logCount, setLogCount] = useState(0);
    const [searchLogData, setSearchLogData] = useState<IEntityLogSearchData>({
        beginDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
        pageNum: 1,
        pageSize: 30,
    });
    const [entityLogData, setEntityLogData] = useState<IEntityLogData[]>([]);
    const [rangeDateData, setRangeDateData] = useState<
        Moment[] | null[] | undefined[]
    >([moment().startOf('day'), moment().endOf('day')]);
    const fetchEntityLog: () => Promise<TGetEntityLogReturnType> = async () => {
        if (!modalVis || !entityCode) {
            return [null, null];
        }
        const url = '/bff/api/rest/log/entity-log';
        const [d, e] = await defaultAxios.get(url, {
            ...searchLogData,
            beginDate: searchLogData.beginDate.valueOf(),
            endDate: searchLogData.endDate.valueOf(),
            tableName: entityCode,
            bizId: entityValueId,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data?.data)) {
            setEntityLogData(data?.data);
            setLogCount(data?.totalSize || 0);
        }
        const error = e as Error | null;
        return [data, error];
    };
    const getLog = useCallback(fetchEntityLog, [searchLogData, modalVis]);
    const [logError, logLoading, logReload] = useNet(getLog);
    useEffect(() => {
        if (modalVis) {
            logReload();
        }
    }, [modalVis]);
    useEffect(() => {
        if (entityCode && modalVis) {
            logReload();
        }
    }, [searchLogData]);
    const hideEntityLogModal = () => {
        setSearchLogData({
            beginDate: moment().startOf('day'),
            endDate: moment().endOf('day'),
            pageNum: 1,
            pageSize: 30,
        });
        setEntityLogData([]);
        setModalVis(false);
    };
    const finalModalProps: ModalProps = {
        ...props,
        centered: true,
        width: '900px',
        visible: modalVis,
        onCancel: hideEntityLogModal,
        onOk: hideEntityLogModal,
        className: `crm-entity-modal-content ${
            props.className ? props.className : ''
        }`,
    };
    return (
        <Modal {...finalModalProps}>
            <div className="crm-entity-log-modal-body">
                <div className="crm-entity-log-filter">
                    <span>时间筛选：</span>
                    <DatePicker.RangePicker
                        allowClear={false}
                        value={[...(rangeDateData as [Moment, Moment])]}
                        onChange={(dates, dateString) => {
                            setRangeDateData(dates as Moment[]);
                            // setSearchLogData({
                            //     ...searchLogData,
                            //     beginDate: dates[0] as Moment,
                            //     endDate: dates[1] as Moment
                            // })
                        }}
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder={['Start Time', 'End Time']}
                        onOk={() => {
                            setSearchLogData({
                                ...searchLogData,
                                beginDate: rangeDateData[0] as Moment,
                                endDate: rangeDateData[1] as Moment,
                            });
                        }}
                    />
                </div>
                <div className="crm-entity-log-table">
                    <TableLv2<IEntityLogData>
                        loading={logLoading}
                        pagination={{
                            pageSize: searchLogData.pageSize,
                            total: logCount,
                        }}
                        scroll={{
                            y: 350,
                        }}
                        dataSource={entityLogData}
                        onPaginationChange={pagination => {
                            setSearchLogData({
                                ...searchLogData,
                                pageNum:
                                    pagination.current === 0
                                        ? 1
                                        : pagination.current,
                                pageSize: pagination.pageSize,
                            });
                        }}
                        closeRowSelection={true}
                        columns={[
                            {
                                key: 'updateUserName',
                                dataIndex: 'updateUserName',
                                title: '操作人',
                                width: 70,
                            },
                            {
                                key: 'binLogTime',
                                dataIndex: 'binLogTime',
                                title: '操作时间',
                                render: v => {
                                    return moment(v).format('YYYY-MM-DD HH:mm');
                                },
                                width: 150,
                            },
                            {
                                key: 'operateField',
                                dataIndex: 'operateField',
                                title: '对象值',
                                width: 120,
                            },
                            {
                                key: 'beforeValue',
                                dataIndex: 'beforeValue',
                                title: '原字段值',
                                width: 150,
                            },
                            {
                                key: 'afterValue',
                                dataIndex: 'afterValue',
                                title: '新字段值',
                                width: 150,
                            },
                        ]}
                    ></TableLv2>
                </div>
            </div>
        </Modal>
    );
};
export default LogModal;
