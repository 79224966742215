import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import './index.scss';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { LayoutStandardCrud, TableLv2 } from '@/components';
import {
    Input,
    message,
    DatePicker,
    Button,
    Modal,
    InputNumber,
    Select,
    Radio,
    Popover,
    Icon,
    Switch,
} from 'antd';
import moment from 'moment';
import { IMemberListStagingData, IMemberListStagingChildData } from './store';
import { defaultAxios } from '@/utils';
import _ from 'lodash';
import { detailFieldsMap } from '../member-list';
import ExpandedMemberStagingRow from './component/ExpandedMemberStagingRow';
import SeatBindLogModal from './component/BindLogModal';
import LicenseModal from './component/LicenseModal';
const RangePicker = DatePicker.RangePicker;
export const MEMBER_IDENTITY_MAP: {
    [key: string]: any;
} = {
    1: '新版正式',
    2: '老版迁移',
    3: '新版试用',
    6: '人才银行v3.0',
    7: '工作台',
    10: '老版付费',
};
export interface IMemberListStagingSearchData {
    mobile?: number | string; // 管理员手机号
    start?: number | string; // 有效期开始时间
    end?: number | string; // 有效期结束时间
    cid?: string; // 公司cid
}
const MemberListStagingPage: React.FC<RouteComponentProps> = props => {
    const [searchData, setSearchData] = useState<IMemberListStagingSearchData>({
        mobile: '',
        start: '',
        end: '',
        cid: '',
    });
    const [timeRangeValue, setTimeRangeValue] = useState<{
        end: any;
        start: any;
    }>({
        start: '',
        end: '',
    });
    const [authMap, setAuthMap] = useState<{
        createBlank: boolean;
        delay: boolean;
        modifyEquality: boolean;
        switchTalent: boolean;
        retrieveSeat: boolean;
        enabledSeat: boolean;
        disabledSeat: boolean;
        exportMemberData: boolean;
        updateCid: boolean;
        distributeEquality: boolean;
    }>({
        createBlank: false,
        delay: false,
        modifyEquality: false,
        switchTalent: false,
        enabledSeat: false,
        disabledSeat: false,
        retrieveSeat: false,
        exportMemberData: false,
        updateCid: false,
        distributeEquality: false,
    });
    const [selectedRow, setSelectedRow] = useState<IMemberListStagingData>();
    const [talentSeat, setTalentSeat] = useState<number>();
    const [departSeat, setDepartSeat] = useState<number>();
    const [addEmptyAccountModalVis, setAddEmptyAccountModalVis] = useState(
        false,
    );
    const [delayAccountModalVis, setDelayAccountModalVis] = useState(false);
    const [modifyEqualityModalVis, setModifyEqualityModalVis] = useState(false);

    const [
        distributeEqualityModalVis,
        setDistributeEqualityModalVis,
    ] = useState(false);
    const [phoneCallNum, setPhoneCallNum] = useState<number>();
    const [addFrNum, setAddFrNum] = useState<number>();
    const [distributeReason, setDistributeReason] = useState('');

    const [updateCidModalVis, setUpdateCidModalVis] = useState(false);
    const [updateCidNum, setUpdateCidNum] = useState<number>();

    const [showSwitchTalentModalVis, setShowSwitchTalentModalVis] = useState(
        false,
    );
    const [childBindLogModalVis, setChildBindLogModalVis] = useState(false);
    const [talentSwitchValue, setTalentSwitchValue] = useState(false);
    const [selectedChild, setSelectedChild] = useState<
        IMemberListStagingChildData
    >();
    const [selectedChildCid, setSelectedChildCid] = useState<number>();
    const [shouldRefreshChildMark, setShouldRefreshChildMark] = useState<any>(
        undefined,
    );

    const [modifyEqualityValue, setModifyEqualityValue] = useState<{
        talentLibVersion: string | number;
        attentionCompanyNum: string | number;
        coIdentity: string | number;
        reachLimit: string | number;
        directOppo: string | number;
        directOppoMonthBalance: string | number;
        addFrMonthBalance: string | number;
        teamName: string | number;
        proNum: string | number;
        recruitLicProNum: string | number;
        subAdmNum: string | number;
        super_exposure: string | number;
        mappings_and_dynamic_switch: string | number;
        jobs_limit: string | number;
        old_ordinary_position: string | number;
        license_gold_medal_position: string | number;
        admin_gold_medal_position: string | number;
        aiTicketNum: string | number;
        aiAccountNum: string | number;
        reportProNum: string | number;
        reportNum: string | number;
        goldAccountNum: string | number;
        goldAccountMonthlyBalance: string | number;
    }>({
        talentLibVersion: 2, // 人才库版本
        attentionCompanyNum: 0, // 关注公司数量
        coIdentity: 7, // 系统版本
        reachLimit: 0, // 剩余电话
        directOppo: 0, // 剩余沟通
        directOppoMonthBalance: 0, // 立即沟通月额度
        addFrMonthBalance: 0, // 加好友月额度
        teamName: '', // 团队名称
        proNum: 0, // 项目可分配剩余
        recruitLicProNum: 0, // 招聘席位项目数
        subAdmNum: 0, // 关注公司剩余
        super_exposure: 0, // 超级曝光数量
        mappings_and_dynamic_switch: 0, // 人才分析与动态
        jobs_limit: 0, // 在招职位数
        old_ordinary_position: 0, // 老普通职位SLOT
        license_gold_medal_position: 0, // 金牌职位SLOT(子账号配额)
        admin_gold_medal_position: 0, // 金牌职位SLOT(管理员分配)
        aiTicketNum: 0,
        aiAccountNum: 0,
        reportProNum: 0,
        reportNum: 0,
        goldAccountNum: 0,
        goldAccountMonthlyBalance: 0,
    });
    const [delayModalValue, setDelayModalValue] = useState<{
        dueType?: string | number;
        dueReason?: string;
        dueDate?: any;
    }>({
        dueType: '1',
        dueReason: '',
    });
    const store = useContext(StoreContext);
    const memberListStagingStore = store.getDefaultMemberListStagingStore();
    const memberStore = store.getDefaultMemberStore();
    const { configData } = memberStore;
    const {
        getMemberListStagingData,
        memberListStagingData,
        defaultMemberListStagingPagination,
        memberListStagingLoading,
        exportMemberListStagingData,
        createEmptyAccount,
        modifyEquality,
        dueDataSubmit,
        switchTalentPool,
        clearStore,
        modifyCid,
        distributeChildEquality,
    } = memberListStagingStore;
    const [licenseModalVis, setLicenseModalVis] = useState(false);
    const [selectedLicenseChild, setSelectedLicenseChild] = useState<
        IMemberListStagingChildData
    >();
    useEffect(() => {
        fetchMemberStagingAuth();
        defaultMemberListStagingPagination.pagesize = 20;
        return () => {
            clearStore();
        };
    }, []);
    useEffect(() => {
        if (searchData.cid || searchData.mobile) {
            getMemberListStagingData(searchData);
        }
    }, [defaultMemberListStagingPagination.pagenum]);
    // 获取客户的权限
    const fetchMemberStagingAuth = async () => {
        const url = '/bff/api/rest/perm?mod=om:member-staging';
        const [d, e] = await defaultAxios.get(url);
        if (d && d.data && d.data?.length) {
            setAuthMap({
                createBlank:
                    _.find(d.data, item => {
                        return item.key === 'om:member-staging:create-blank';
                    })?.visible || false,
                delay:
                    _.find(d.data, item => {
                        return item.key === 'om-member-staging:delay';
                    })?.visible || false,
                modifyEquality:
                    _.find(d.data, item => {
                        return item.key === 'om-member-staging:modify-rights';
                    })?.visible || false,
                switchTalent:
                    _.find(d.data, item => {
                        return (
                            item.key === 'om-member-staging:talent-pool-switch'
                        );
                    })?.visible || false,
                disabledSeat:
                    _.find(d.data, item => {
                        return item.key === 'om-member-staging:disabled-seat';
                    })?.visible || false,
                enabledSeat:
                    _.find(d.data, item => {
                        return item.key === 'om-member-staging:enabled-seat';
                    })?.visible || false,
                retrieveSeat:
                    _.find(d.data, item => {
                        return item.key === 'om-member-staging:retrieve-seat';
                    })?.visible || false,
                exportMemberData:
                    _.find(d.data, item => {
                        return (
                            item.key === 'om-member-staging:export-member-list'
                        );
                    })?.visible || false,
                updateCid:
                    _.find(d.data, item => {
                        return item.key === 'om-member-staging:update-cid';
                    })?.visible || false,
                distributeEquality:
                    _.find(d.data, item => {
                        return (
                            item.key === 'om:member-staging:distribute-equality'
                        );
                    })?.visible || false,
            });
        }
    };
    const searchHandle = async () => {
        if (searchData.cid || searchData.mobile) {
            defaultMemberListStagingPagination.pagenum = 1;
            getMemberListStagingData(searchData);
        } else {
            message.warning('请输入手机号或cid查询');
        }
    };
    const renderDistributeChildEqualityModal = (
        selectedChild?: IMemberListStagingChildData,
    ) => {
        // if(selectedChild.length === 1) {
        //     setEquityCount(selectedChild[0].report.reach_nbr)
        // }
        return (
            <Modal
                visible={distributeEqualityModalVis}
                title={`分配权益`}
                onOk={async () => {
                    if (
                        phoneCallNum ||
                        phoneCallNum === 0 ||
                        addFrNum ||
                        addFrNum === 0
                    ) {
                        if (phoneCallNum && phoneCallNum <= 0) {
                            message.warning('请正确填写电话沟通券');
                            return;
                        }
                        if (addFrNum && addFrNum <= 0) {
                            message.warning('请正确填写不过期好友券');
                            return;
                        }
                    } else {
                        message.warning('请输入电话沟通券或不过期好友券');
                        return;
                    }
                    if (selectedChild) {
                        const res = await distributeChildEquality(
                            selectedChild,
                            3,
                            {
                                addFrNum,
                                phoneCallNum,
                                reason: distributeReason,
                            },
                        );
                        if (res === true) {
                            setDistributeEqualityModalVis(false);
                            setAddFrNum(undefined);
                            setPhoneCallNum(undefined);
                            setDistributeReason('');
                            setShouldRefreshChildMark(selectedChildCid);
                            setTimeout(() => {
                                setShouldRefreshChildMark(undefined);
                            }, 0);
                            getMemberListStagingData(searchData);
                            message.success(`已分配子账号权益`);
                        } else {
                            message.error(res?.msg || '分配子账号权益失败');
                        }
                    }
                }}
                onCancel={() => {
                    setDistributeEqualityModalVis(false);
                    setAddFrNum(undefined);
                    setPhoneCallNum(undefined);
                    setDistributeReason('');
                }}
            >
                <div className="member-modify-equality-content">
                    <div className="member-modify-flex">
                        <label htmlFor="">
                            电话沟通券
                            {/* <span className="member-required-mark">*</span> */}
                        </label>
                        <InputNumber
                            style={{
                                width: '200px',
                            }}
                            onChange={value => {
                                setAddFrNum(undefined);
                                setPhoneCallNum(value);
                                // setEquityCount(value);
                            }}
                            placeholder={'请输入额度'}
                            value={phoneCallNum}
                            maxLength={2}
                            min={0}
                            max={99999}
                            type="number"
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">
                            不过期加好友券
                            {/* <span className="member-required-mark">*</span> */}
                        </label>
                        <InputNumber
                            width="200px"
                            onChange={value => {
                                setPhoneCallNum(undefined);
                                setAddFrNum(value);
                                // setEquityCount(value);
                            }}
                            style={{
                                width: '200px',
                            }}
                            placeholder={'请输入额度'}
                            value={addFrNum}
                            maxLength={5}
                            min={0}
                            max={99999}
                            type="number"
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">
                            原因
                            {/* <span className="member-required-mark">*</span> */}
                        </label>
                        <Input
                            onChange={e => {
                                setDistributeReason(e.target.value || '');
                                // setEquityCount(value);
                            }}
                            style={{
                                width: '300px',
                                display: 'inline-block',
                            }}
                            placeholder={'请输入分配原因'}
                            value={distributeReason}
                            maxLength={20}
                        />
                    </div>
                </div>
            </Modal>
        );
    };
    const renderUpdateCidModal = () => {
        // if(selectedChild.length === 1) {
        //     setEquityCount(selectedChild[0].report.reach_nbr)
        // }
        return (
            <Modal
                visible={updateCidModalVis}
                title={`更新cid`}
                onOk={async () => {
                    if (!updateCidNum || updateCidNum <= 0) {
                        message.warning('请输入正确的cid');
                        return;
                    }
                    if (selectedRow) {
                        const res = await modifyCid(selectedRow, updateCidNum);
                        if (res === true) {
                            message.success(
                                `已为「${selectedRow.co_short_name}」修改cid`,
                            );
                            const searchDataTemp = {
                                ...searchData,
                            };
                            if (searchData.cid) {
                                searchDataTemp.cid = updateCidNum as any;
                                setSearchData(searchDataTemp);
                            }
                            setUpdateCidNum(undefined);
                            setUpdateCidModalVis(false);
                            getMemberListStagingData(searchDataTemp);
                        } else {
                            message.error(
                                typeof res === 'string' ? res : '修改cid失败',
                            );
                        }
                    }
                }}
                onCancel={() => {
                    setUpdateCidModalVis(false);
                    setUpdateCidNum(undefined);
                }}
            >
                <label htmlFor="">
                    cid
                    <span className="member-required-mark">*</span>
                </label>
                <InputNumber
                    onChange={value => {
                        setUpdateCidNum(value);
                    }}
                    value={updateCidNum}
                    min={0}
                    maxLength={20}
                    type="number"
                />
            </Modal>
        );
    };
    const changeDistributeEqualityModalVis = (
        bool: boolean,
        selectedItem: IMemberListStagingChildData,
        cid: number,
    ) => {
        setDistributeEqualityModalVis(bool);
        setSelectedChild(selectedItem);
        setAddFrNum(undefined);
        setPhoneCallNum(undefined);
        setSelectedChildCid(cid);
        // 这里需要填充
    };
    const renderMemberListPageFilter = () => {
        const rangeTimeValue: any = [
            searchData.start ? moment(searchData.start) : undefined,
            searchData.end ? moment(searchData.end) : undefined,
        ];
        return (
            <div className="member-filter-content">
                <div className="member-filter-row">
                    <div className="member-filter-item">
                        <label htmlFor="cid">cid：</label>
                        <Input
                            name="cid"
                            onChange={e => {
                                setSearchData({
                                    ...searchData,
                                    cid: e.target.value,
                                    mobile: '',
                                });
                            }}
                            value={searchData.cid}
                            onPressEnter={searchHandle}
                        />
                    </div>
                    <div className="member-filter-item">
                        <label htmlFor="mobile">管理员或子账号手机：</label>
                        <Input
                            name="mobile"
                            onChange={e => {
                                setSearchData({
                                    ...searchData,
                                    mobile: e.target.value,
                                    cid: '',
                                });
                            }}
                            value={searchData.mobile}
                            onPressEnter={searchHandle}
                        />
                    </div>
                    <div className="member-filter-item">
                        <label htmlFor="time">有效期范围：</label>
                        <RangePicker
                            name="time"
                            showTime={true}
                            format="YYYY-MM-DD"
                            onChange={values => {
                                setSearchData({
                                    ...searchData,
                                    start: values[0]
                                        ? moment(values[0])
                                              .startOf('day')
                                              .valueOf()
                                        : '',
                                    end: values[1]
                                        ? moment(values[1])
                                              .endOf('day')
                                              .valueOf()
                                        : '',
                                });
                            }}
                            value={rangeTimeValue}
                            // onOk={}
                            style={{ width: '300px' }}
                            allowClear={true}
                        />
                    </div>
                    <div className="member-staging-button-row">
                        <Button
                            type="primary"
                            loading={memberListStagingLoading}
                            onClick={() => {
                                searchHandle();
                            }}
                        >
                            查询
                        </Button>
                        {authMap.exportMemberData ? (
                            <Button
                                type="primary"
                                disabled={memberListStagingLoading}
                                onClick={() => {
                                    if (searchData.mobile || searchData.cid) {
                                        exportMemberListStagingData(searchData);
                                    } else {
                                        message.warning('请输入cid或者手机号');
                                    }
                                }}
                            >
                                导出
                            </Button>
                        ) : null}
                    </div>
                    {/* <div className="member-filter-item">
                        <label htmlFor="cid">cid：</label>
                        <Input
                            name="cid"
                            onChange={e => {
                                setSearchData({
                                    ...searchData,
                                    cid: e.target.value,
                                });
                            }}
                            value={searchData.cid}
                            onPressEnter={searchHandle}
                        />
                    </div> */}
                </div>
            </div>
        );
    };
    const renderModifyEquality = () => {
        return (
            <Modal
                title={`修改「${selectedRow?.co_short_name}」权益`}
                visible={modifyEqualityModalVis}
                onCancel={() => {
                    setModifyEqualityValue({
                        talentLibVersion: 2, // 人才库版本
                        attentionCompanyNum: 0, // 关注公司数量
                        coIdentity: 7, // 系统版本
                        reachLimit: 0, // 剩余电话
                        directOppo: 0, // 剩余沟通
                        directOppoMonthBalance: 0, // 立即沟通月额度
                        addFrMonthBalance: 0, // 加好友月额度
                        teamName: '', // 团队名称
                        proNum: 0, // 项目可分配剩余
                        recruitLicProNum: 0, // 招聘席位项目数
                        subAdmNum: 0, // 关注公司剩余
                        super_exposure: 0,
                        mappings_and_dynamic_switch: 0,
                        jobs_limit: 0,
                        old_ordinary_position: 0,
                        license_gold_medal_position: 0,
                        admin_gold_medal_position: 0,
                        aiTicketNum: 0,
                        aiAccountNum: 0,
                        reportProNum: 0,
                        reportNum: 0,
                        goldAccountNum: 0,
                        goldAccountMonthlyBalance: 0,
                    });
                    setModifyEqualityModalVis(false);
                }}
                onOk={async () => {
                    if (selectedRow) {
                        const res = await modifyEquality(selectedRow, {
                            ...modifyEqualityValue,
                        });
                        if (res === true) {
                            message.success(
                                `已为「${selectedRow.co_full_name}」修改权益`,
                            );
                            setModifyEqualityValue({
                                talentLibVersion: 2, // 人才库版本
                                attentionCompanyNum: 0, // 关注公司数量
                                coIdentity: 7, // 系统版本
                                reachLimit: 0, // 剩余电话
                                directOppo: 0, // 剩余沟通
                                directOppoMonthBalance: 0, // 立即沟通月额度
                                addFrMonthBalance: 0, // 加好友月额度
                                teamName: '', // 团队名称
                                proNum: 0, // 项目可分配剩余
                                recruitLicProNum: 0, // 招聘席位项目数
                                subAdmNum: 0, // 关注公司剩余
                                super_exposure: 0,
                                mappings_and_dynamic_switch: 0,
                                jobs_limit: 0,
                                old_ordinary_position: 0,
                                license_gold_medal_position: 0,
                                admin_gold_medal_position: 0,
                                aiTicketNum: 0,
                                aiAccountNum: 0,
                                reportProNum: 0,
                                reportNum: 0,
                                goldAccountNum: 0,
                                goldAccountMonthlyBalance: 0,
                            });
                            setModifyEqualityModalVis(false);
                            getMemberListStagingData(searchData);
                        } else {
                            message.error(
                                typeof res === 'string' ? res : '修改权益失败',
                            );
                        }
                    }
                }}
            >
                <div className="member-modify-equality-content">
                    <div className="member-modify-flex">
                        <label htmlFor="">系统版本：</label>
                        <Select
                            disabled={true}
                            // defaultValue={String(selectedRow?.co_identity)}
                            style={{ width: 120 }}
                            onChange={(value: any) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    coIdentity: value,
                                });
                            }}
                            value={String(modifyEqualityValue.coIdentity)}
                        >
                            {_.map(MEMBER_IDENTITY_MAP, (item, idx) => {
                                return (
                                    <Select.Option key={idx} value={idx}>
                                        {item}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">人才库版本：</label>
                        <Select
                            // defaultValue={selectedRow?.talent_lib_version}
                            style={{ width: 120 }}
                            onChange={(value: any) => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    talentLibVersion: value,
                                });
                            }}
                            value={modifyEqualityValue.talentLibVersion}
                        >
                            {configData.talent_lib_version &&
                                configData.talent_lib_version.map(item => (
                                    <Select.Option
                                        key={item.name}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">团队名称：</label>
                        <Input
                            onChange={e => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    teamName: e.target.value as any,
                                });
                            }}
                            value={modifyEqualityValue.teamName as any}
                            defaultValue={selectedRow?.team_name as any}
                            // max={1000}
                            min={0}
                            style={{ width: 160 }}
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">关注公司数量：</label>
                        <InputNumber
                            onChange={value => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    attentionCompanyNum: value as any,
                                });
                            }}
                            value={
                                modifyEqualityValue.attentionCompanyNum as any
                            }
                            defaultValue={
                                selectedRow?.attention_company_nbr as number
                            }
                            type="number"
                            // max={1000}
                            min={0}
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">项目可分配数：</label>
                        <InputNumber
                            onChange={value => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    proNum: value as any,
                                });
                            }}
                            value={modifyEqualityValue.proNum as any}
                            defaultValue={selectedRow?.pro_num as number}
                            type="number"
                            // max={1000}
                            min={0}
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">招聘席位项目数：</label>
                        <InputNumber
                            onChange={value => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    recruitLicProNum: value as any,
                                });
                            }}
                            value={modifyEqualityValue.recruitLicProNum as any}
                            defaultValue={
                                selectedRow?.recruit_lic_pro_num as number
                            }
                            type="number"
                            // max={1000}
                            min={0}
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">子管理员剩余：</label>
                        <InputNumber
                            onChange={value => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    subAdmNum: value as any,
                                });
                            }}
                            value={modifyEqualityValue.subAdmNum as any}
                            defaultValue={selectedRow?.remain_sub_lic as number}
                            type="number"
                            // max={1000}
                            min={0}
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">虚拟电话（管理员）：</label>
                        <InputNumber
                            onChange={value => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    reachLimit: value as any,
                                });
                            }}
                            value={modifyEqualityValue.reachLimit as any}
                            defaultValue={
                                selectedRow?.reach_nbr_new_left as number
                            }
                            type="number"
                            // max={1000}
                            min={0}
                        />
                    </div>
                    <div className="member-modify-flex">
                        <label htmlFor="">立即沟通（管理员）：</label>
                        <InputNumber
                            onChange={value => {
                                setModifyEqualityValue({
                                    ...modifyEqualityValue,
                                    directOppo: value as any,
                                });
                            }}
                            value={modifyEqualityValue.directOppo as any}
                            defaultValue={
                                selectedRow?.direct_oppo_left as number
                            }
                            type="number"
                            // max={1000}
                            min={0}
                        />
                    </div>
                    {selectedRow?.co_identity == 1 ||
                    selectedRow?.co_identity == 2 ||
                    selectedRow?.co_identity == 3 ? null : (
                        <>
                            <div className="member-modify-flex">
                                <label htmlFor="">立即沟通（月配额）：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            directOppoMonthBalance: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.directOppoMonthBalance as any
                                    }
                                    defaultValue={
                                        selectedRow?.direct_oppo_monthly as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">加好友（月配额）：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            addFrMonthBalance: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.addFrMonthBalance as any
                                    }
                                    defaultValue={
                                        selectedRow?.add_fr_monthly as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">超级曝光数量：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            super_exposure: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.super_exposure as any
                                    }
                                    // defaultValue={
                                    //     selectedRow?.add_fr_monthly as number
                                    // }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">数据报告：</label>
                                <Select
                                    // defaultValue={
                                    //     selectedRow?.mappings_and_dynamic_switch
                                    // }
                                    allowClear
                                    style={{ width: 160 }}
                                    value={
                                        modifyEqualityValue.mappings_and_dynamic_switch
                                    }
                                    onChange={(value: any) => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            mappings_and_dynamic_switch: value,
                                        });
                                    }}
                                >
                                    {[
                                        {
                                            label: 0,
                                            text: '否',
                                        },
                                        {
                                            label: 1,
                                            text: '是',
                                        },
                                    ].map(item => (
                                        <Select.Option
                                            key={item.label}
                                            value={item.label}
                                        >
                                            {item.text}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">AI点数：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            aiTicketNum: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.aiTicketNum as any
                                    }
                                    defaultValue={
                                        selectedRow?.ai_ticket_num as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">AI账号数：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            aiAccountNum: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.aiAccountNum as any
                                    }
                                    defaultValue={
                                        selectedRow?.ai_account_num as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            {/* <div className="member-modify-flex">
                                <label htmlFor="">新普通职位SLOT：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            jobs_limit: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.jobs_limit as any
                                    }
                                    // defaultValue={
                                    //     selectedRow?.add_fr_monthly as number
                                    // }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">老普通职位SLOT：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            old_ordinary_position: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.old_ordinary_position as any
                                    }
                                    // defaultValue={
                                    //     selectedRow?.add_fr_monthly as number
                                    // }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                                */}
                            <div className="member-modify-flex">
                                <label htmlFor="">
                                    金牌职位SLOT
                                    <br />
                                    (子账号配额)：
                                </label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            license_gold_medal_position: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.license_gold_medal_position as any
                                    }
                                    defaultValue={
                                        selectedRow?.priority_position_balance as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">
                                    金牌职位SLOT
                                    <br />
                                    (管理员分配)：
                                </label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            admin_gold_medal_position: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.admin_gold_medal_position as any
                                    }
                                    defaultValue={
                                        selectedRow?.admin_gold_medal_position as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">数据报告专业版：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            reportProNum: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.reportProNum as any
                                    }
                                    defaultValue={
                                        selectedRow?.report_pro_num as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">数据报告高级版：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            reportNum: value as any,
                                        });
                                    }}
                                    value={modifyEqualityValue.reportNum as any}
                                    defaultValue={
                                        selectedRow?.report_num as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">金牌账号：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            goldAccountNum: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.goldAccountNum as any
                                    }
                                    defaultValue={
                                        selectedRow?.gold_account_num as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                            <div className="member-modify-flex">
                                <label htmlFor="">推广点数：</label>
                                <InputNumber
                                    onChange={value => {
                                        setModifyEqualityValue({
                                            ...modifyEqualityValue,
                                            goldAccountMonthlyBalance: value as any,
                                        });
                                    }}
                                    value={
                                        modifyEqualityValue.goldAccountMonthlyBalance as any
                                    }
                                    defaultValue={
                                        selectedRow?.gold_account_monthly_balance as number
                                    }
                                    type="number"
                                    // max={1000}
                                    min={0}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        );
    };
    const renderCreateEmptyModal = () => {
        return (
            <Modal
                visible={addEmptyAccountModalVis}
                title={`为「${selectedRow?.co_short_name}」新增空席位`}
                onOk={async () => {
                    if (
                        _.isNil(talentSeat) ||
                        talentSeat < 0 ||
                        _.isNil(departSeat) ||
                        departSeat < 0
                    ) {
                        message.warning('请输入正确的开通席位个数');
                        return;
                    }
                    if (selectedRow) {
                        const res = await createEmptyAccount(
                            selectedRow,
                            talentSeat,
                            departSeat,
                        );
                        if (res === true) {
                            message.success(
                                `已为「${
                                    selectedRow.co_full_name
                                }」添加了「${talentSeat +
                                    departSeat}」个空账号`,
                            );
                            setShouldRefreshChildMark(selectedRow.id);
                            setTimeout(() => {
                                setShouldRefreshChildMark(undefined);
                            }, 0);
                            setTalentSeat(undefined);
                            setDepartSeat(undefined);
                            setAddEmptyAccountModalVis(false);
                        } else {
                            message.error(
                                typeof res === 'string' ? res : '添加失败',
                            );
                        }
                    }
                }}
                onCancel={() => {
                    setTalentSeat(undefined);
                    setDepartSeat(undefined);
                    setAddEmptyAccountModalVis(false);
                }}
            >
                <div>
                    <label htmlFor="">
                        招聘席位<span className="member-required-mark">*</span>
                    </label>
                    <InputNumber
                        onChange={value => {
                            setTalentSeat(value);
                        }}
                        value={talentSeat}
                        min={0}
                        max={10000}
                        type="number"
                    />
                </div>
                <div style={{ marginTop: '12px' }}>
                    <label htmlFor="">
                        部门席位<span className="member-required-mark">*</span>
                    </label>
                    <InputNumber
                        onChange={value => {
                            setDepartSeat(value);
                        }}
                        value={departSeat}
                        min={0}
                        max={10000}
                        type="number"
                    />
                </div>
            </Modal>
        );
    };
    const renderDelayModal = () => {
        return (
            <Modal
                title={`为「${selectedRow?.co_short_name}」延期`}
                visible={delayAccountModalVis}
                onOk={async () => {
                    if (!delayModalValue.dueDate) {
                        message.warning('延期时间不能为空');
                        return;
                    }
                    if (!delayModalValue.dueReason) {
                        message.warning('延期原因不能为空');
                        return;
                    }
                    if (selectedRow) {
                        const res = await dueDataSubmit(selectedRow, {
                            ...delayModalValue,
                        });
                        if (res === true) {
                            message.success(
                                `已为「${selectedRow.co_full_name}」延期`,
                            );
                            setDelayModalValue({
                                dueReason: '',
                                dueType: '1',
                                dueDate: undefined,
                            });
                            setDelayAccountModalVis(false);
                            getMemberListStagingData(searchData);
                        } else {
                            message.error(
                                typeof res === 'string' ? res : '延期失败',
                            );
                        }
                    }
                }}
                onCancel={() => {
                    setDelayModalValue({
                        dueReason: '',
                        dueType: '1',
                        dueDate: undefined,
                    });
                    setDelayAccountModalVis(false);
                }}
            >
                <div className="member-delay-modal-content">
                    <div className="member-delay-flex">
                        <label style={{ marginRight: '12px' }} htmlFor="">
                            延期类型
                        </label>
                        <Radio.Group
                            onChange={e => {
                                setDelayModalValue({
                                    ...delayModalValue,
                                    dueType: e.target.value,
                                });
                            }}
                            defaultValue="1"
                            buttonStyle="solid"
                        >
                            <Radio.Button value="1">赠送</Radio.Button>
                            <Radio.Button value="2">其他</Radio.Button>
                        </Radio.Group>
                        <Popover
                            content={
                                <div>
                                    <p>赠送：正常原因延期</p>
                                    <p>
                                        其它：非正常延期情况，如配合销售签单等操作
                                    </p>
                                </div>
                            }
                        >
                            <Icon
                                type="question-circle"
                                style={{ marginLeft: '5px' }}
                            />
                        </Popover>
                    </div>
                    <div className="member-delay-flex">
                        <label htmlFor="">
                            延期日期
                            <span className="member-required-mark">*</span>
                        </label>
                        <DatePicker
                            style={{ marginBottom: '10px' }}
                            onChange={date => {
                                setDelayModalValue({
                                    ...delayModalValue,
                                    dueDate: date,
                                });
                            }}
                            value={delayModalValue.dueDate}
                        />
                    </div>
                    <div className="member-delay-flex">
                        <label
                            htmlFor=""
                            style={{ marginBottom: '5px', display: 'block' }}
                        >
                            延期原因
                            <span className="member-required-mark">*</span>
                        </label>
                        <Input.TextArea
                            onChange={e => {
                                setDelayModalValue({
                                    ...delayModalValue,
                                    dueReason: e.target.value,
                                });
                            }}
                            value={delayModalValue.dueReason}
                        />
                    </div>
                </div>
            </Modal>
        );
    };
    const renderTalentSwitchModal = () => {
        return (
            <Modal
                title={`人才库开关`}
                visible={showSwitchTalentModalVis}
                onOk={async () => {
                    if (selectedRow) {
                        const res = await switchTalentPool(
                            selectedRow,
                            talentSwitchValue,
                        );
                        if (res === true) {
                            message.success(
                                `已${talentSwitchValue ? '打开' : '关闭'}「${
                                    selectedRow.co_full_name
                                }」人才库开关`,
                            );
                            setTalentSwitchValue(false);
                            setShowSwitchTalentModalVis(false);
                            getMemberListStagingData(searchData);
                        } else {
                            message.error(
                                typeof res === 'string'
                                    ? res
                                    : '人才库开关修改失败',
                            );
                        }
                    }
                }}
                onCancel={() => {
                    setTalentSwitchValue(false);
                    setShowSwitchTalentModalVis(false);
                }}
            >
                <div className="member-delay-modal-content">
                    <div className="member-delay-flex">
                        <label style={{ marginRight: '12px' }} htmlFor="">
                            人才库开关：
                        </label>
                        <Switch
                            onChange={bool => {
                                setTalentSwitchValue(bool);
                            }}
                            checked={talentSwitchValue}
                            checkedChildren="开"
                            unCheckedChildren="关"
                        ></Switch>
                    </div>
                </div>
            </Modal>
        );
    };
    const renderMemberListTable = () => {
        const renderItemDetail = (
            fields: {
                field: string;
                label: string;
                suffix?: string;
                // tslint:disable-next-line:align
            }[],
            values: any,
        ) => {
            return (
                <div className="flex-column">
                    {fields.map(item => (
                        <span style={{ marginBottom: '6px' }} key={item.field}>
                            <span
                                style={{ width: '60px' }}
                                className="color-dilution no-break display-inline-block"
                            >
                                {item.label}:
                            </span>
                            <span className="no-break">
                                {`${values[item.field] || '- -'}${item.suffix ||
                                    ''}`}
                            </span>
                        </span>
                    ))}
                </div>
            );
        };
        return (
            <TableLv2<IMemberListStagingData>
                loading={memberListStagingLoading}
                dataSource={memberListStagingData}
                closeRowSelection={true}
                pagination={{
                    ...defaultMemberListStagingPagination.paginationForUI,
                    total: defaultMemberListStagingPagination.amount,
                    showSizeChanger: false,
                }}
                onPaginationChange={
                    defaultMemberListStagingPagination.setPaginationByUI
                }
                expandedRowRender={record => {
                    return memberListStagingLoading ? null : (
                        <ExpandedMemberStagingRow
                            authMap={authMap}
                            cid={record.id}
                            shouldRefreshCid={shouldRefreshChildMark}
                            distributeChildEqualityModalVis={
                                changeDistributeEqualityModalVis
                            }
                            changeChildBindLogModalVis={(
                                bool,
                                selectedChildData,
                                childCid,
                            ) => {
                                setChildBindLogModalVis(bool);
                                setSelectedChild(selectedChildData);
                                setSelectedChildCid(childCid as number);
                            }}
                            onEditLicense={child => {
                                console.log('onEditLicense', child);
                                setSelectedLicenseChild(child);
                                setLicenseModalVis(true);
                            }}
                        />
                    );
                }}
                scroll={{ x: '2200' }}
                actions={[
                    {
                        actionKey: 'create-empty',
                        actionCn: '新增空席位',
                        status: {
                            hidden: !authMap.createBlank,
                        },
                    },
                    {
                        actionKey: 'delay',
                        actionCn: '更改到期日',
                        status: {
                            hidden: !authMap.delay,
                        },
                    },
                    {
                        actionKey: 'talent-switch',
                        actionCn: '人才库开关',
                        status: {
                            hidden: !authMap.switchTalent,
                        },
                    },
                    {
                        actionKey: 'update-equality',
                        actionCn: '修改权益',
                        status: {
                            hidden: !authMap.modifyEquality,
                        },
                    },
                    {
                        actionKey: 'update-cid',
                        actionCn: '更新cid',
                        status: {
                            hidden: !authMap.updateCid,
                        },
                    },
                ]}
                onAction={async (actionKey: string, record) => {
                    setSelectedRow(record);
                    if (actionKey === 'talent-switch') {
                        // 人才库开关
                        setTalentSwitchValue(
                            record.talent_lib_switch === 1 ? true : false,
                        );
                        setShowSwitchTalentModalVis(true);
                    }
                    if (actionKey === 'create-empty') {
                        setAddEmptyAccountModalVis(true);
                    }
                    if (actionKey === 'delay') {
                        setDelayAccountModalVis(true);
                    }
                    if (actionKey === 'update-equality') {
                        setModifyEqualityValue({
                            ...modifyEqualityValue,
                            addFrMonthBalance: record.add_fr_monthly,
                            directOppoMonthBalance: record.direct_oppo_monthly,
                            talentLibVersion: record.talent_lib_version,
                            attentionCompanyNum: record.attention_company_nbr,
                            coIdentity: record.co_identity,
                            proNum: record.pro_num,
                            recruitLicProNum: record.recruit_lic_pro_num,
                            subAdmNum: record.remain_sub_lic,
                            reachLimit: record.reach_nbr_new_left,
                            directOppo: record.direct_oppo_left,
                            teamName: record.team_name,
                            super_exposure: record.super_exposure,
                            mappings_and_dynamic_switch:
                                record.mappings_and_dynamic_switch,
                            jobs_limit: record.jobs_limit,
                            old_ordinary_position: record.old_ordinary_position,
                            license_gold_medal_position:
                                record.license_gold_medal_position,
                            admin_gold_medal_position:
                                record.priority_position_balance,
                            aiTicketNum: record.ai_ticket_num,
                            aiAccountNum: record.ai_account_num,
                            reportNum: record.report_num,
                            reportProNum: record.report_pro_num,
                            goldAccountNum: record.gold_account_num,
                            goldAccountMonthlyBalance:
                                record.gold_account_monthly_balance,
                        });
                        setModifyEqualityModalVis(true);
                    }
                    if (actionKey === 'update-cid') {
                        setUpdateCidNum(record.cid);
                        setUpdateCidModalVis(true);
                    }
                }}
                actionsWidth={300}
                isFixedActions={false}
                columns={[
                    {
                        title: '企业名称',
                        key: 'co_full_name',
                        dataIndex: 'co_full_name',
                        width: '160px',
                    },
                    {
                        title: '销售',
                        key: 'bd_name',
                        width: '80px',
                        dataIndex: 'bd_name',
                    },
                    {
                        title: '账号类型',
                        dataIndex: 'co_identity',
                        key: 'co_identity',
                        width: '80px',
                        render: (
                            text: string,
                            row: IMemberListStagingData,
                            index: number,
                        ) => {
                            return MEMBER_IDENTITY_MAP?.[text]
                                ? MEMBER_IDENTITY_MAP?.[text]
                                : null;
                        },
                    },
                    {
                        title: '人才库版本',
                        dataIndex: 'talent_lib_version',
                        width: 100,
                        render: d => {
                            const { talent_lib_version } = configData;
                            if (
                                talent_lib_version &&
                                talent_lib_version.length
                            ) {
                                return (
                                    _.find(talent_lib_version, v => {
                                        return v.id === d;
                                    })?.name || ''
                                );
                            }
                        },
                    },
                    {
                        title: '团队id',
                        dataIndex: 'id',
                        key: 'id',
                        width: '80px',
                    },
                    {
                        title: '团队名称',
                        dataIndex: 'team_name',
                        key: 'team_name',
                        width: '120px',
                    },
                    {
                        title: '管理员',
                        key: 'adm_name',
                        dataIndex: 'adm_name',
                        width: '120px',
                        render: (
                            text: string,
                            row: IMemberListStagingData,
                            index: number,
                        ) => {
                            return (
                                <span className="flex-column">
                                    <span className="np-break">{text}</span>
                                    <span className="np-break">
                                        {row.adm_mobile}
                                    </span>
                                </span>
                            );
                        },
                    },
                    {
                        title: '有效期至',
                        key: 'mbr_end_time',
                        dataIndex: 'mbr_end_time',
                        width: '120px',
                        render: v => {
                            return v ? moment(v).format('YYYY-MM-DD') : null;
                        },
                    },
                    {
                        title: '招聘人员席位数',
                        key: 'talent_seat_num',
                        render: (v, item) => {
                            return renderItemDetail(
                                detailFieldsMap.talentSeatNum,
                                item,
                            );
                        },
                    },
                    {
                        title: '用人经理席位数',
                        key: 'depart_seat_num',
                        render: (v, item) => {
                            return renderItemDetail(
                                detailFieldsMap.departSeatNum,
                                item,
                            );
                        },
                    },
                    {
                        title: '子管理员数',
                        key: 'child_admin_num',
                        render: (v, item) => {
                            return renderItemDetail(
                                detailFieldsMap.childAdminNum,
                                item,
                            );
                        },
                    },
                    {
                        title: '立即沟通（团队）',
                        key: 'direct_oppo_left',
                        dataIndex: 'direct_oppo_left',
                    },
                    {
                        title: '虚拟电话（团队）',
                        key: 'reach_nbr_new_left',
                        dataIndex: 'reach_nbr_new_left',
                    },
                    {
                        title: '立即沟通(月配额)',
                        dataIndex: 'direct_oppo_monthly',
                        key: 'direct_oppo_monthly',
                        width: 150,
                    },
                    {
                        title: '加好友(月配额)',
                        dataIndex: 'add_fr_monthly',
                        key: 'add_fr_monthly',
                        width: 150,
                    },
                    {
                        title: 'cid',
                        dataIndex: 'cid',
                        key: 'cid',
                        width: 120,
                    },
                ]}
            />
        );
    };
    return (
        <>
            {renderCreateEmptyModal()}
            {renderModifyEquality()}
            {renderDelayModal()}
            {renderTalentSwitchModal()}
            {renderUpdateCidModal()}
            {renderDistributeChildEqualityModal(selectedChild)}
            <SeatBindLogModal
                modalVis={childBindLogModalVis}
                setModalVis={bool => {
                    if (!bool) {
                        // 关闭
                        setSelectedChild(undefined);
                        setSelectedChildCid(undefined);
                    }
                    setChildBindLogModalVis(bool);
                }}
                cid={selectedChildCid}
                lid={selectedChild?.id}
            />
            <LicenseModal
                modalVis={licenseModalVis}
                setModalVis={setLicenseModalVis}
                selectedChild={selectedLicenseChild}
                onSuccess={() => {
                    // 刷新数据
                    getMemberListStagingData(searchData);
                }}
            />
            <div className="member-content om-member-list">
                <LayoutStandardCrud
                    title={'会员列表（工作台）'}
                    message={null}
                    popups={null}
                    multipleOps={null}
                    globalOps={null}
                    filters={renderMemberListPageFilter()}
                    dataTable={renderMemberListTable()}
                />
            </div>
        </>
    );
};

export default observer(MemberListStagingPage);
