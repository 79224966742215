import React, { useEffect, memo } from 'react';

import { DemoListStore } from '../store';
import { Modal, Alert } from 'antd';
import { observer } from 'mobx-react-lite';
import { tryRefresh } from '@/utils';

export const ActionDelete: React.FC<{
    defaultStore: DemoListStore;
}> = observer(({ defaultStore }) => {
    const { mutatingData } = defaultStore;
    if (mutatingData === null) {
        return null;
    }
    const visible = defaultStore.actionType === 'delete';
    return (
        <Modal
            visible={visible}
            title={'删除线索'}
            onCancel={() => {
                defaultStore.resetAction();
            }}
            onOk={async () => {
                const ok = await defaultStore.delete();
                if (ok) {
                    defaultStore.resetAction();
                    tryRefresh(['lead']);
                }
            }}
        >
            <p>是否删除线索【{mutatingData.name}】？</p>
            <Alert type="error" message="删除成功之后，该操作无法恢复。" />
        </Modal>
    );
});
