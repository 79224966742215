import _ from 'lodash';
export type CommonStatusCallback<T, E = any> = (item: T, extra: E) => boolean;

export interface ICommonStatus<T, E = any> {
    loading?: boolean | CommonStatusCallback<T, E>;
    disabled?: boolean | CommonStatusCallback<T, E>;
    hidden?: boolean | CommonStatusCallback<T, E>;
}

export const getTypes = (type: string) => {
    return _.split(type, '|');
};

export const getCommonStatus: (
    status: ICommonStatus<any> | undefined,
    data: any,
    statusExtraData: any,
    k?: string,
) => {
    loading: boolean | undefined;
    disabled: boolean | undefined;
    hidden: boolean | undefined;
} = (status, data, statusExtraData, k) => {
    let loading: boolean | undefined;
    let disabled: boolean | undefined;
    let hidden: boolean | undefined;

    if (k === 'product') {
        // debugger
    }

    if (status) {
        if (!_.isNil(status.loading)) {
            if (_.isBoolean(status.loading)) {
                loading = status.loading;
            } else if (_.isFunction(status.loading)) {
                loading = status.loading(data, statusExtraData);
            }
        }

        if (!_.isNil(status.disabled)) {
            if (_.isBoolean(status.disabled)) {
                disabled = status.disabled;
            } else if (_.isFunction(status.disabled)) {
                disabled = status.disabled(data, statusExtraData);
            }
        }

        if (!_.isNil(status.hidden)) {
            if (_.isBoolean(status.hidden)) {
                hidden = status.hidden;
            } else if (_.isFunction(status.hidden)) {
                hidden = status.hidden(data, statusExtraData);
            }
        }
    }
    return {
        loading,
        disabled,
        hidden,
    };
};

export interface ICommonProps<T = any, U = any> {
    k?: string;
    status?: ICommonStatus<T, U>;
    onInteract?: InnerInteractCallback;
    data?: T;
    statusExtraData?: U;
    beforeLabel?: React.ReactChild;
    afterLabel?: React.ReactChild;
    beforeWidget?: React.ReactChild;
    afterWidget?: React.ReactChild;
    nextlineWidget?: any;
    prevlineWidget?: any;
    WrapperWidget?: (ele: React.ReactElement) => React.ReactElement;
    widgetClassName?: string;
}

export type InnerInteractCallback = (type: string, value?: any) => void;
