import _ from 'lodash';
const defaultParamPerm: IPermItem = {
    visible: true,
    disable: false,
    editable: true,
};

export const getDefaultParamPerm = (perm: IPermItem | undefined) => {
    if (perm === undefined) {
        return _.assign({}, defaultParamPerm);
    }
    return _.assign({}, defaultParamPerm, perm);
};

export const getDefaultParam = (param: IParamItem) => {
    if (_.isNil(param.perm)) {
        param.perm = _.assign({}, defaultParamPerm);
    } else {
        param.perm = getDefaultParamPerm(param.perm);
    }
    return param;
};
