import React from 'react';
import {
    DTable,
    applyCustomConfToCellsSelector,
    isMultipleCell,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ProcessCatStore } from '../store';
import { tryRefresh } from '@/utils';
import { contractCellsSelectorTransformer } from '@/pages/om/contract/list/components/table';
import { CtxProvider } from '@/utils/context';
import querystring from 'querystring';

const DemoTable: React.FC<{
    defaultStore: ProcessCatStore;
    loading: boolean;
    tableCode: string;
}> = ({ defaultStore, loading, tableCode }) => {
    // const { defaultMSelect } = defaultStore;
    // const { mSeletedData, setMSelectedData } = defaultMSelect;

    console.log(tableCode);

    let scCode = '';
    if (tableCode === 'contract') {
        scCode = 'crm_contract';
    } else if (tableCode === 'process_customer_admin_change_table') {
        scCode = 'crm_admin_change';
    } else if (tableCode === 'process_product_give_open_table') {
        scCode = 'crm_enterprise_give';
    }

    const dataTable = (
        <>
            <CtxProvider
                ctx={{ position: 'list', positionDetail: 'process-list' }}
            >
                <DTable
                    sceneCode={scCode || 'crm_process'}
                    onColumnsUpdated={() =>
                        tryRefresh([defaultStore.tableCode || ''])
                    }
                    options={{
                        withActionPermCheck: false,
                    }}
                    withEventCode={false}
                    defaultStore={defaultStore as any}
                    propsTransformer={props => {
                        props.loading = loading;
                        props.actions = [
                            // {
                            //     actionKey: 'see-detail',
                            //     actionCn: '查看详情',
                            // },
                        ];
                        props.onAction = (key, data) => {
                            if (key === 'see-detail') {
                                defaultStore.setAction('see-detail', data);
                            } else if (_.startsWith(key, 'reveal-param/')) {
                                if (
                                    key.indexOf('/process_instance_name') !== -1
                                ) {
                                    defaultStore.setAction('see-detail', data);
                                }
                            }

                            if (defaultStore.tableCode === 'contract') {
                                if (_.startsWith(key, 'reveal-param/')) {
                                    if (key.indexOf('/contract_name') !== -1) {
                                        window.open(
                                            '/buy/contract/all?' +
                                                querystring.stringify({
                                                    id: data.id,
                                                    target: 'process-list',
                                                }),
                                            '__blank',
                                        );
                                    }
                                }
                            }

                            if (
                                defaultStore.tableCode ===
                                'process_contract_invoice_table'
                            ) {
                                if (_.startsWith(key, 'reveal-param/')) {
                                    if (
                                        key.indexOf(
                                            '/applicant_invoice_code',
                                        ) !== -1
                                    ) {
                                        window.open(
                                            '/buy/process-management/all?' +
                                                querystring.stringify({
                                                    id:
                                                        data.process_instance_id,
                                                    target:
                                                        'process_contract_invoice_table',
                                                }),
                                            '__blank',
                                        );
                                    }
                                }
                            }
                        };
                        props.actionsWidth = 38;
                        props.closeRowSelection = true;
                        props.widthInfoKey = 'process-list-default';
                        return props;
                    }}
                    cellsSelectorTransformer={baiscCellsSelector => {
                        let nextbaiscCellsSelector = [...baiscCellsSelector];
                        if (defaultStore.tableCode === 'contract') {
                            nextbaiscCellsSelector = contractCellsSelectorTransformer(
                                nextbaiscCellsSelector,
                            );
                        }

                        if (
                            defaultStore.tableCode ===
                            'process_contract_invoice_table'
                        ) {
                            // 详情可跳转
                            applyCustomConfToCellsSelector(
                                baiscCellsSelector,
                                'applicant_invoice_code',
                                cellSelector => {
                                    const nextCellSelector = _.cloneDeep(
                                        cellSelector,
                                    );
                                    if (isMultipleCell(nextCellSelector)) {
                                        return nextCellSelector;
                                    }
                                    nextCellSelector.cell = 'ActionParam';
                                    nextCellSelector.inputs.push({
                                        paramName: 'applicant_invoice_code',
                                    });
                                    return nextCellSelector;
                                },
                            );
                        }

                        // TODO，可能需要区分场景
                        applyCustomConfToCellsSelector(
                            baiscCellsSelector,
                            'process_instance_name',
                            cellSelector => {
                                const nextCellSelector = _.cloneDeep(
                                    cellSelector,
                                );
                                if (isMultipleCell(nextCellSelector)) {
                                    return nextCellSelector;
                                }
                                nextCellSelector.cell = 'ActionParam';
                                nextCellSelector.inputs.push({
                                    paramName: 'process_instance_name',
                                });
                                return nextCellSelector;
                            },
                        );

                        applyCustomConfToCellsSelector(
                            baiscCellsSelector,
                            'process_approval_status',
                            cellSelector => {
                                if (isMultipleCell(cellSelector)) {
                                    return cellSelector;
                                }
                                cellSelector.cell = 'ProcessStatus';
                                return cellSelector;
                            },
                        );

                        return nextbaiscCellsSelector;
                    }}
                />
            </CtxProvider>
        </>
    );

    return dataTable;
};

const FinalDemoTable = observer(DemoTable);
export default FinalDemoTable;
