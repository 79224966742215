import React, { useState, useContext, useEffect } from 'react';
import './index.less';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, navigate } from '@reach/router';
import { StoreContext } from '@/stores';
import {
    Input,
    Tabs,
    Badge,
    List,
    Pagination,
    message,
    Button,
    Modal,
    Tag,
    Alert,
} from 'antd';
import moment from 'moment';
import { IODataItem, IGlobalSearchCustomerData } from './store';
import { getQueryParam, defaultAxios, delay } from '@/utils';
import _ from 'lodash';
import { ActionMTransoform } from '../sales-follow-up/custom/list/components/m-transform';
import { ActionMDispatch } from '../sales-follow-up/custom/list/components/m-dispatch';
import { ActionMFetch } from '../sales-follow-up/custom/list/components/m-fetch';
import { MOpResultDisplayContainer } from '@/components';
import { getCustomerTags } from '../sales-follow-up/custom/list/utils';
import { StarCus } from '../sales-follow-up/custom/list/components/star';

const entityCodeToUrl: any = {
    customer: '/buy/custom/all-custom',
    contacts: '/buy/contact/all',
    contract: '/buy/contract/all',
    lead: '/buy/lead/all-lead',
    opportunity: '/buy/opportunity/all',
};
const GlobalSearch: React.FC<RouteComponentProps> = props => {
    const { navigate = () => {} } = props;
    const store = useContext(StoreContext);
    const gsStore = store.getDefaultGSStore();
    const {
        customerLoading,
        leadLoading,
        opportunityLoading,
        contactsLoading,
        contractLoading,
        customerGlobalSearchData,
        leadGlobalSearchData,
        opportunityGlobalSearchData,
        contactsGlobalSearchData,
        contractGlobalSearchData,
        customerCount,
        leadCount,
        opportunityCount,
        contactsCount,
        contractCount,
        fetchCustomerData,
        fetchLeadData,
        fetchOptData,
        checkDetailAuth,
        fetchContactData,
        fetchContractData,
        searchGlobalSearchData,
        activeTabsKey,
        setActiveTabsKey,
        globalSearchText,
        setGlobalSearchText,
        defaultPagination,
    } = gsStore;
    const [canFetch, setCanFetch] = useState(false);
    const [canTransform, setCanTransform] = useState(false);
    const [canDispatch, setCanDispatch] = useState(false);
    const [canStarAll, setCanStarAll] = useState(false);
    const [canStarMine, setCanStarMine] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [customerList] = useState(() => new store.CustomerListStore());

    const [reloadAuthTrigger, setReloadAuthTrigger] = useState(0);
    const reloadAuth = () => {
        setReloadAuthTrigger(reloadAuthTrigger + 1);
    };
    const [authLoading, setAuthLoading] = useState(false);
    const [authErrored, setAuthErrored] = useState(false);
    const renderWithAuthCheck = (ele: React.ReactElement) => {
        if (authLoading) {
            return null;
        }
        if (authErrored) {
            return (
                <Alert
                    type="warning"
                    message={
                        <span>
                            权限请求失败，点击重试&nbsp;&nbsp;
                            <Button size="small" onClick={() => reloadAuth()}>
                                重试
                            </Button>
                        </span>
                    }
                />
            );
        }
        return ele;
    };

    useEffect(() => {
        fetchCustomerAuth();
    }, [reloadAuthTrigger]);

    useEffect(() => {
        const searchTextFromQuery = getQueryParam('searchText');
        if (searchTextFromQuery) {
            customerList.defaultMeta.fetch();
            setGlobalSearchText(searchTextFromQuery);
            searchGlobalSearchData();
        }
    }, []);

    useEffect(() => {
        defaultPagination.amount = 10000;
        // switch (activeTabsKey) {
        // case 'customer':
        //     defaultPagination.amount = customerCount;
        //     break;
        // case 'lead':
        //     defaultPagination.amount = leadCount;
        //     break;
        // case 'opportunity':
        //     defaultPagination.amount = opportunityCount;
        //     break;
        // case 'contacts':
        //     defaultPagination.amount = contactsCount;
        //     break;
        // case 'contract':
        //     defaultPagination.amount = contractCount;
        //     break;
        // default:
        //     defaultPagination.amount = 0;
        //     break;
        // }
    }, [activeTabsKey, customerCount, leadCount, opportunityCount]);
    useEffect(() => {
        if (!globalSearchText || globalSearchText.length < 2) {
            return;
        }
        switch (activeTabsKey) {
            case 'customer':
                fetchCustomerData();
                break;
            case 'lead':
                fetchLeadData();
                break;
            case 'opportunity':
                fetchOptData();
                break;
            case 'contacts':
                fetchContactData();
                break;
            case 'contract':
                fetchContractData();
                break;
            default:
                break;
        }
    }, [activeTabsKey, defaultPagination.pagenum, defaultPagination.pagesize]);
    // 获取客户的权限
    const fetchCustomerAuth = async () => {
        const url = '/bff/api/rest/perm?mod=sales-follow-up:customer';
        setAuthLoading(true);
        const [d, e] = await defaultAxios.get(url);
        setAuthLoading(false);
        if (d && d.data && d.data?.length) {
            setAuthErrored(false);
            const fetchAuth = _.find(d.data, item => {
                return item.key === 'sales-follow-up:customer:all:fetch';
            });
            const dispatchAuth = _.find(d.data, item => {
                return item.key === 'sales-follow-up:customer:all:dispatch';
            });
            const transformAuth = _.find(d.data, item => {
                return item.key === 'sales-follow-up:customer:mine:transform';
            });
            const starAuthAll = _.find(d.data, item => {
                return item.key === 'sales-follow-up:customer:all:star';
            });
            const starAuthMine = _.find(d.data, item => {
                return item.key === 'sales-follow-up:customer:mine:star';
            });
            setCanTransform(!!transformAuth?.visible);
            setCanDispatch(!!dispatchAuth?.visible);
            setCanStarAll(!!starAuthAll?.visible);
            setCanStarMine(!!starAuthMine?.visible);
            if (!fetchAuth?.visible) {
                setCanFetch(false);
            } else {
                setCanFetch(true);
            }
        } else {
            setAuthErrored(true);
        }
    };
    const jumpToDetail = (
        entityCode:
            | 'customer'
            | 'lead'
            | 'opportunity'
            | 'contract'
            | 'contacts',
        id: string | number,
    ) => {
        window.open(
            entityCodeToUrl[entityCode] + `?id=${id}&target=search`,
            '_blank',
        );
    };
    const renderObjToData = (key: string, finalDetail?: IODataItem) => {
        if (!finalDetail) {
            return null;
        }
        if (typeof finalDetail === 'object') {
            if (finalDetail.constraintLabel) {
                // 非关联
                return finalDetail.constraintLabel;
            }
            if (finalDetail.showRelatedObj && finalDetail.showFiledName) {
                // 关联
                return finalDetail.showRelatedObj[finalDetail.showFiledName];
            }
            return '';
        } else {
            if (key?.indexOf('time') != -1) {
                return moment((finalDetail as unknown) as string).format(
                    'YYYY-MM-DD HH:mm:ss',
                );
            }
            return finalDetail;
        }
    };

    const fetchCustomer = async (item: IGlobalSearchCustomerData) => {
        const fetchFunc = async () => {
            setFetchLoading(true);
            const [d, e] = await defaultAxios.post('/crm/customer/get', {
                customerIds: [item.id],
            });
            setFetchLoading(false);
            if (!_.isNil(e) || _.isNil(d)) {
                message.error('领取失败，请检查网络');
                return false;
            }
            // tslint:disable-next-line:prefer-const
            let { warningNum, errorNum, errorList, warningList } = d.data || {};
            warningNum = warningNum || 0;
            errorNum = errorNum || 0;
            fetchCustomerData();
            if (warningNum === 0 && errorNum === 0) {
                message.success('领取成功');
            } else {
                if (errorNum && errorList.length) {
                    message.error(`领取失败-${errorList[0].msg}`, 2);
                } else if (warningNum && warningList.length) {
                    message.error(`领取成功-${warningList[0].msg}`, 2);
                    return true;
                } else {
                    message.error('领取失败', 1);
                }
                return false;
            }

            return true;
        };
        Modal.confirm({
            title: '领取客户',
            content: `是否领取${item.customer_name}`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                return await fetchFunc();
            },
        });
    };

    const renderCustomerList = () => {
        return (
            <>
                <ActionMDispatch
                    customerList={customerList}
                    refreshFunc={fetchCustomerData}
                />
                <ActionMTransoform
                    customerList={customerList}
                    refreshFunc={fetchCustomerData}
                />
                <ActionMFetch
                    customerList={customerList}
                    // refreshFunc={fetchCustomerData}
                />
                <MOpResultDisplayContainer defaultStore={customerList} />
                <List
                    itemLayout="vertical"
                    size={'large'}
                    pagination={false}
                    loading={customerLoading}
                    dataSource={customerGlobalSearchData}
                    renderItem={item => {
                        const {
                            isNotStanderCustomer,
                            haveCID,
                            isLock,
                            isChildrenCustomer,
                            isNewEconomyCompany_Toubu,
                            isNewEconomyCompany_Yaobu,
                            isKP,
                            isGaoqian,
                        } = getCustomerTags(item);
                        console.log(
                            JSON.stringify({
                                isNotStanderCustomer,
                                haveCID,
                                isLock,
                                isChildrenCustomer,
                                isNewEconomyCompany_Toubu,
                                isNewEconomyCompany_Yaobu,
                                isKP,
                                isGaoqian,
                            }),
                        );

                        // 是否是公海客户
                        const isPublicHighSea =
                            typeof item.customer_status === 'object' &&
                            item.customer_status.constraintLabel === '未领取';
                        return (
                            <List.Item
                                key={item.id as string}
                                className="gs-item-content"
                            >
                                <div className="gs-item-title-content">
                                    <p
                                        className="gs-item-title"
                                        onClick={async () => {
                                            const res = await checkDetailAuth(
                                                'customer',
                                                item.id as string,
                                            );
                                            if (res) {
                                                jumpToDetail(
                                                    'customer',
                                                    item.id as string,
                                                );
                                            } else {
                                                message.error(
                                                    '您没有查看该详情的权限～',
                                                );
                                            }
                                        }}
                                    >
                                        {item.customer_name}
                                    </p>
                                    {canFetch && !isChildrenCustomer ? (
                                        typeof item.customer_status ===
                                            'object' &&
                                        item.customer_status.constraintLabel ===
                                            '未领取' ? (
                                            <Button
                                                onClick={async () => {
                                                    await customerList.setAction(
                                                        'm-fetch',
                                                    );
                                                    customerList.setMutatingData(
                                                        item,
                                                    );
                                                }}
                                                type="primary"
                                                size="small"
                                                // loading={fetchLoading}
                                            >
                                                领取
                                            </Button>
                                        ) : null
                                    ) : (
                                        undefined
                                    )}
                                    {/* {canDispatch && isPublicHighSea ? (
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                customerList.setAction(
                                                    'm-dispatch',
                                                );
                                                customerList.setMutatingData(
                                                    item,
                                                );
                                            }}
                                        >
                                            分配
                                        </Button>
                                    ) : null} */}
                                    {/* {canTransform && !isPublicHighSea ? (
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                customerList.setAction(
                                                    'm-transform',
                                                );
                                                customerList.defaultMSelect.setMSelectedData(
                                                    [item],
                                                );
                                            }}
                                        >
                                            转移
                                        </Button>
                                    ) : null} */}
                                    {/* 关注&取关 */}
                                    {canStarAll && isPublicHighSea && !!item && (
                                        <span
                                            style={{
                                                position: 'relative',
                                                left: 6,
                                                top: -2,
                                            }}
                                        >
                                            <StarCus
                                                customerList={customerList}
                                                defaultStared={
                                                    item?.focus || false
                                                }
                                                cus={item}
                                            />
                                        </span>
                                    )}
                                    {canStarMine && !isPublicHighSea && !!item && (
                                        <span
                                            style={{
                                                position: 'relative',
                                                left: 6,
                                                top: -2,
                                            }}
                                        >
                                            <StarCus
                                                customerList={customerList}
                                                defaultStared={
                                                    item?.focus || false
                                                }
                                                cus={item}
                                            />
                                        </span>
                                    )}
                                </div>
                                <div className="gs-tags-content">
                                    {isLock && (
                                        <Tag
                                            style={{
                                                border: '1px solid #ff001f',
                                                backgroundColor: '#FFF',
                                                color: '#FF001f',
                                            }}
                                        >
                                            锁定
                                        </Tag>
                                    )}
                                    {isNotStanderCustomer && (
                                        <Tag
                                            style={{
                                                border: '1px solid #ff001f',
                                                backgroundColor: '#FFF',
                                                color: '#FF001f',
                                            }}
                                        >
                                            非标
                                        </Tag>
                                    )}
                                    {/* {!haveCID && (
                                        <Tag color="#FFA408">无CID</Tag>
                                    )} */}
                                    {isChildrenCustomer && (
                                        <Tag
                                            style={{
                                                border: '1px solid #FFA408',
                                                backgroundColor: '#FFF',
                                                color: '#FFA408',
                                            }}
                                        >
                                            子客户
                                        </Tag>
                                    )}
                                    {!!item.cross_region_tag && (
                                        <Tag
                                            style={{
                                                border: '1px solid #FFA408',
                                                backgroundColor: '#FFF',
                                                color: '#FFA408',
                                            }}
                                        >
                                            跨区
                                        </Tag>
                                    )}
                                    {isNewEconomyCompany_Toubu && (
                                        <Tag
                                            style={{
                                                border:
                                                    '1px solid rgb(44, 163, 31)',
                                                backgroundColor: '#FFF',
                                                color: 'rgb(44, 163, 31)',
                                            }}
                                        >
                                            新经济
                                        </Tag>
                                    )}
                                    {isNewEconomyCompany_Yaobu && (
                                        <Tag
                                            style={{
                                                border:
                                                    '1px solid rgb(44, 163, 31)',
                                                backgroundColor: '#FFF',
                                                color: 'rgb(44, 163, 31)',
                                            }}
                                        >
                                            新经济
                                        </Tag>
                                    )}
                                    {isKP && (
                                        <Tag
                                            style={{
                                                border:
                                                    '1px solid rgb(44, 163, 31)',
                                                backgroundColor: '#FFF',
                                                color: 'rgb(44, 163, 31)',
                                            }}
                                        >
                                            高价值
                                        </Tag>
                                    )}
                                    {isGaoqian && (
                                        <Tag
                                            style={{
                                                border:
                                                    '1px solid rgb(44, 163, 31)',
                                                backgroundColor: '#FFF',
                                                color: 'rgb(44, 163, 31)',
                                            }}
                                        >
                                            高潜
                                        </Tag>
                                    )}
                                </div>
                                <div className="gs-item-body">
                                    <div className="gs-item-line">
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                客户所有人：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'owner_id',
                                                    item.owner_id,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                所属公海：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'high_sea_id',
                                                    item.high_sea_id,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                状态：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'customer_status',
                                                    item.customer_status,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                合作状态：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'cooperation_status',
                                                    item.cooperation_status,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                一级部门：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'first_department',
                                                    item.first_department,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="gs-item-line">
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                cid：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'customer_cid',
                                                    item.customer_cid,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                上级客户：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'parent_customer_id',
                                                    item.parent_customer_id,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                创建日期：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'create_time',
                                                    item.create_time,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                到期回收时间：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'expire_time',
                                                    item.expire_time,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                注册地址：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'company_address',
                                                    item.company_address,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        );
                    }}
                ></List>
                <div className="list-pagination">
                    <Pagination
                        total={defaultPagination.amount}
                        {...defaultPagination.paginationForUI}
                        onChange={(page, pageSize) => {
                            defaultPagination.setPaginationByUI({
                                current: page,
                                pageSize,
                            });
                        }}
                        onShowSizeChange={(current, pageSize) => {
                            defaultPagination.setPaginationByUI({
                                current,
                                pageSize,
                            });
                        }}
                        pageSizeOptions={['5', '10', '20', '30', '40']}
                        showQuickJumper
                        showSizeChanger
                        showLessItems
                    />
                </div>
            </>
        );
    };
    const renderLeadList = () => {
        return (
            <>
                <List
                    itemLayout="vertical"
                    size={'large'}
                    pagination={false}
                    loading={leadLoading}
                    dataSource={leadGlobalSearchData}
                    renderItem={item => {
                        return (
                            <List.Item
                                key={item.id as string}
                                className="gs-item-content"
                            >
                                <p
                                    className="gs-item-title"
                                    onClick={async () => {
                                        const res = await checkDetailAuth(
                                            'lead',
                                            item.id as string,
                                        );
                                        if (res) {
                                            jumpToDetail(
                                                'lead',
                                                item.id as string,
                                            );
                                        } else {
                                            message.error(
                                                '您没有查看该详情的权限～',
                                            );
                                        }
                                    }}
                                >
                                    {renderObjToData('name', item.name) ||
                                        '- -'}
                                </p>
                                <div className="gs-item-body">
                                    <div className="gs-item-line">
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                线索所有人：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'owner_id',
                                                    item.owner_id,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                创建日期：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'create_time',
                                                    item.create_time,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="gs-item-line">
                                        <div className="gs-item customer-name">
                                            <span className="gs-item-label">
                                                客户名称：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'customer_name',
                                                    item.customer_name,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        );
                    }}
                ></List>
                <div className="list-pagination">
                    <Pagination
                        total={defaultPagination.amount}
                        {...defaultPagination.paginationForUI}
                        onChange={(page, pageSize) => {
                            defaultPagination.setPaginationByUI({
                                current: page,
                                pageSize,
                            });
                        }}
                        onShowSizeChange={(current, pageSize) => {
                            defaultPagination.setPaginationByUI({
                                current,
                                pageSize,
                            });
                        }}
                        pageSizeOptions={['5', '10', '20', '30', '40']}
                        showQuickJumper
                        showSizeChanger
                        showLessItems
                    />
                </div>
            </>
        );
    };
    const renderOptList = () => {
        return (
            <>
                <List
                    itemLayout="vertical"
                    size={'large'}
                    pagination={false}
                    loading={opportunityLoading}
                    dataSource={opportunityGlobalSearchData}
                    renderItem={item => {
                        return (
                            <List.Item
                                key={item.id as string}
                                className="gs-item-content"
                            >
                                <p
                                    className="gs-item-title"
                                    onClick={async () => {
                                        const res = await checkDetailAuth(
                                            'opportunity',
                                            item.id as string,
                                        );
                                        if (res) {
                                            jumpToDetail(
                                                'opportunity',
                                                item.id as string,
                                            );
                                        } else {
                                            message.error(
                                                '您没有查看该详情的权限～',
                                            );
                                        }
                                    }}
                                >
                                    {item.opportunity_name}
                                </p>
                                <div className="gs-item-body">
                                    <div className="gs-item-line">
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                商机所有人：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'owner_id',
                                                    item.owner_id,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                创建日期：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'create_time',
                                                    item.create_time,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="gs-item-line">
                                        <div className="gs-item customer-name">
                                            <span className="gs-item-label">
                                                客户名称：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'customer_name',
                                                    item.customer_name,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        );
                    }}
                ></List>
                <div className="list-pagination">
                    <Pagination
                        total={defaultPagination.amount}
                        {...defaultPagination.paginationForUI}
                        onChange={(page, pageSize) => {
                            defaultPagination.setPaginationByUI({
                                current: page,
                                pageSize,
                            });
                        }}
                        onShowSizeChange={(current, pageSize) => {
                            defaultPagination.setPaginationByUI({
                                current,
                                pageSize,
                            });
                        }}
                        pageSizeOptions={['5', '10', '20', '30', '40']}
                        showQuickJumper
                        showSizeChanger
                        showLessItems
                    />
                </div>
            </>
        );
    };
    // 联系人
    const renderContactList = () => {
        return (
            <>
                <List
                    itemLayout="vertical"
                    size={'large'}
                    pagination={false}
                    loading={contactsLoading}
                    dataSource={contactsGlobalSearchData}
                    renderItem={item => {
                        return (
                            <List.Item
                                key={item.id as string}
                                className="gs-item-content"
                            >
                                <p
                                    className="gs-item-title"
                                    onClick={async () => {
                                        const res = await checkDetailAuth(
                                            'contacts',
                                            item.id as string,
                                        );
                                        if (res) {
                                            jumpToDetail(
                                                'contacts',
                                                item.id as string,
                                            );
                                        } else {
                                            message.error(
                                                '您没有查看该详情的权限～',
                                            );
                                        }
                                    }}
                                >
                                    {item.contacts_name}
                                </p>
                                <div className="gs-item-body">
                                    <div className="gs-item-line">
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                联系人所有人：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'owner_id',
                                                    item.owner_id,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                创建日期：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'create_time',
                                                    item.create_time,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="gs-item-line">
                                        <div className="gs-item customer-name">
                                            <span className="gs-item-label">
                                                客户名称：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'customer_name',
                                                    item.customer_name,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        );
                    }}
                ></List>
                <div className="list-pagination">
                    <Pagination
                        total={defaultPagination.amount}
                        {...defaultPagination.paginationForUI}
                        onChange={(page, pageSize) => {
                            defaultPagination.setPaginationByUI({
                                current: page,
                                pageSize,
                            });
                        }}
                        onShowSizeChange={(current, pageSize) => {
                            defaultPagination.setPaginationByUI({
                                current,
                                pageSize,
                            });
                        }}
                        pageSizeOptions={['5', '10', '20', '30', '40']}
                        showQuickJumper
                        showSizeChanger
                        showLessItems
                    />
                </div>
            </>
        );
    };
    // 合同
    const renderContractList = () => {
        return (
            <>
                <List
                    itemLayout="vertical"
                    size={'large'}
                    pagination={false}
                    loading={contractLoading}
                    dataSource={contractGlobalSearchData}
                    renderItem={item => {
                        return (
                            <List.Item
                                key={item.id as string}
                                className="gs-item-content"
                            >
                                <p
                                    className="gs-item-title"
                                    onClick={async () => {
                                        const res = await checkDetailAuth(
                                            'contract',
                                            item.id as string,
                                        );
                                        if (res) {
                                            jumpToDetail(
                                                'contract',
                                                item.id as string,
                                            );
                                        } else {
                                            message.error(
                                                '您没有查看该详情的权限～',
                                            );
                                        }
                                    }}
                                >
                                    {item.contract_name}
                                </p>
                                <div className="gs-item-body">
                                    <div className="gs-item-line">
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                合同所有人：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'owner_id',
                                                    item.owner_id,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                        <div className="gs-item">
                                            <span className="gs-item-label">
                                                创建日期：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'create_time',
                                                    item.create_time,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="gs-item-line">
                                        <div className="gs-item customer-name">
                                            <span className="gs-item-label">
                                                客户名称：
                                            </span>
                                            <span className="gs-item-value">
                                                {renderObjToData(
                                                    'customer_name',
                                                    item.customer_name,
                                                ) || '- -'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        );
                    }}
                ></List>
                <div className="list-pagination">
                    <Pagination
                        total={defaultPagination.amount}
                        {...defaultPagination.paginationForUI}
                        onChange={(page, pageSize) => {
                            defaultPagination.setPaginationByUI({
                                current: page,
                                pageSize,
                            });
                        }}
                        onShowSizeChange={(current, pageSize) => {
                            defaultPagination.setPaginationByUI({
                                current,
                                pageSize,
                            });
                        }}
                        pageSizeOptions={['5', '10', '20', '30', '40']}
                        showQuickJumper
                        showSizeChanger
                        showLessItems
                    />
                </div>
            </>
        );
    };
    return (
        <div className="gs-content">
            <div className="gs-search">
                <Input.Search
                    placeholder="搜索客户/线索/商机/合同/联系人"
                    enterButton="搜索"
                    size="large"
                    width="80%"
                    value={globalSearchText}
                    onChange={e => setGlobalSearchText(e.target.value)}
                    onSearch={searchGlobalSearchData}
                />
            </div>
            <div className="gs-tabs">
                <Tabs
                    size={'large'}
                    activeKey={activeTabsKey}
                    onChange={key => {
                        setActiveTabsKey(key);
                        defaultPagination.pagenum = 1;
                        defaultPagination.pagesize = 30;
                    }}
                >
                    <Tabs.TabPane
                        tab={
                            // <Badge
                            //     count={customerCount}
                            //     offset={[customerCount > 99 ? 12 : 0, 0]}
                            // >
                            <span>客户</span>
                            // </Badge>
                        }
                        key={'customer'}
                        disabled={customerLoading}
                    >
                        {renderWithAuthCheck(renderCustomerList())}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            // <Badge
                            //     count={opportunityCount}
                            //     offset={[opportunityCount > 99 ? 12 : 0, 0]}
                            // >
                            <span>商机</span>
                            // </Badge>
                        }
                        key={'opportunity'}
                        disabled={opportunityLoading}
                    >
                        {renderOptList()}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            // <Badge
                            //     count={leadCount}
                            //     offset={[leadCount > 99 ? 12 : 0, 0]}
                            // >
                            <span>线索</span>
                            // </Badge>
                        }
                        key={'lead'}
                        disabled={leadLoading}
                    >
                        {renderLeadList()}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            // <Badge
                            //     count={contactsCount}
                            //     offset={[contactsCount > 99 ? 12 : 0, 0]}
                            // >
                            <span>联系人</span>
                            // </Badge>
                        }
                        key={'contacts'}
                        disabled={contactsLoading}
                    >
                        {renderContactList()}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            // <Badge
                            //     count={contractCount}
                            //     offset={[contractCount > 99 ? 12 : 0, 0]}
                            // >
                            <span>合同</span>
                            // </Badge>
                        }
                        key={'contract'}
                        disabled={contractLoading}
                    >
                        {renderContractList()}
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default observer(GlobalSearch);
