import { createDefaultInstanceGetter } from '../../stores/utils/index';
import { defaultAxios } from '@/utils';
import {
    PaginationStore,
    TPaginationStoreInstance,
} from '../../stores/utils/pagination';
import { makeAutoObservable } from 'mobx';
import { ReactElement } from 'react';
import { message } from 'antd';
export type IODataItem =
    | string
    | (() => ReactElement)
    | number
    | {
          constraintLabel?: string | number; // 无关联转换实际展示
          constraintValue?: any; // 无关联转换元数据
          type?: string; // 关联转换type
          originalValue?: any; // 关联转换元数据
          showFiledName?: string; // 展示的字段名
          showRelatedObj?: {
              [key: string]: any;
          };
          [key: string]: any;
      };
// 字段固定 - 客户
export interface IGlobalSearchCustomerData {
    id: IODataItem; // 客户id
    customer_name: IODataItem; // 客户名称
    owner_id: IODataItem; // 客户所有人
    high_sea_id: IODataItem; // 所属公海
    customer_status: IODataItem; // 状态
    first_department: IODataItem; // 一级部门
    parent_customer_id: IODataItem; // 上级客户
    parent_owner_name: IODataItem; // 上级客户所有人（在上级客户里找到所有人）
    create_time: IODataItem; // 创建日期
    company_address: IODataItem; // 注册地址（详细地址）
    lock_expire_time: number; // 锁定到期时间
    expire_time: number; // 到期时间
    new_economy_company: number; // 新经济字段
    gradation: number; // KP
    high_potential: number; // 高潜力
    customer_cid: number; // 客户cid
    cross_region_tag: number; // 跨区标志
    relate_type: number; // 客户关联类型
    business_registration: number; // 工商注册
    cooperation_status: IODataItem; // 合作状态
    focus: boolean; // 关注状态
}
// 字段固定 - 商机
export interface IGlobalSearchOpportunityData {
    id: IODataItem; // 商机id
    opportunity_name: IODataItem; // 商机名称
    create_time: IODataItem; // 创建时间
    owner_id: IODataItem; // 商机所有人
    customer_name: IODataItem; // 客户名称
}

// 线索
export interface IGlobalSearchLeadData {
    name: IODataItem; // 线索名称
    id: IODataItem; // 线索id
    create_time: IODataItem; // 创建日期
    owner_id: IODataItem; // 线索所有人（？）
    customer_name: IODataItem; // 客户名称
}

// 联系人
export interface IGlobalSearchContactData {
    contacts_name: IODataItem;
    owner_id: IODataItem;
    create_time: IODataItem;
    id: IODataItem;
    customer_name: IODataItem; // 客户名称
}

// 合同
export interface IGlobalSearchContractData {
    create_time: IODataItem;
    contract_name: IODataItem;
    owner_id: IODataItem;
    id: IODataItem;
    customer_name: IODataItem; // 客户名称
}

export type TGetGlobalSearchDataReturnType = NetResponse<{
    data: any[];
    msg: string;
    code: number;
}>;
export class GlobalSearchStore {
    public globalSearchText: string = '';
    public setGlobalSearchText = (text: string) => {
        console.log(text);
        this.globalSearchText = text;
    };

    public activeTabsKey: string = 'customer';
    public setActiveTabsKey = (key: string) => {
        this.activeTabsKey = key;
    };
    public defaultPagination: TPaginationStoreInstance = new PaginationStore();
    public customerGlobalSearchData: IGlobalSearchCustomerData[] = [];
    public opportunityGlobalSearchData: IGlobalSearchOpportunityData[] = [];
    public leadGlobalSearchData: IGlobalSearchLeadData[] = [];
    public contactsGlobalSearchData: IGlobalSearchContactData[] = [];
    public contractGlobalSearchData: IGlobalSearchContractData[] = [];

    public customerLoading: boolean = false;
    public leadLoading: boolean = false;
    public opportunityLoading: boolean = false;
    public contactsLoading: boolean = false;
    public contractLoading: boolean = false;

    public customerCount: number = 0;
    public leadCount: number = 0;
    public opportunityCount: number = 0;
    public contractCount: number = 0;
    public contactsCount: number = 0;
    [key: string]: any;

    public initStore = async () => {
        this.customerCount = 0;
        this.leadCount = 0;
        this.opportunityCount = 0;
        this.contactsCount = 0;
        this.contractCount = 0;
        this.defaultPagination.amount = 10000;
        this.defaultPagination.pagenum = 1;

        this.customerGlobalSearchData = [];
        this.leadGlobalSearchData = [];
        this.opportunityGlobalSearchData = [];
        this.contactsGlobalSearchData = [];
        this.contractGlobalSearchData = [];
    };
    // 验证详情页的权限
    public checkDetailAuth = async (
        entityCode: string,
        entityId: string | number,
    ) => {
        const url = '/bff/api/rest/global-search/checkDetailAuth';
        const [d, e] = await defaultAxios.get(url, {
            entityCode,
            entityId,
        });
        if (e) {
            return false;
        }
        return d.data;
    };
    public fetchCustomerData = async () => {
        return await this.fetchGlobalSearchData('customer');
    };
    public fetchLeadData = async () => {
        return await this.fetchGlobalSearchData('lead');
    };
    public fetchOptData = async () => {
        return await this.fetchGlobalSearchData('opportunity');
    };
    public fetchContractData = async () => {
        return await this.fetchGlobalSearchData('contract');
    };
    public fetchContactData = async () => {
        return await this.fetchGlobalSearchData('contacts');
    };
    // 搜索数据
    public searchGlobalSearchData = async () => {
        if (!this.globalSearchText || this.globalSearchText.length < 2) {
            message.warning('请至少输入二个字符后查询～');
            return;
        }
        this.initStore();
        await this.fetchGlobalSearchData(this.activeTabsKey as any);
        // await Promise.all([
        //     this.fetchCustomerData(),
        //     // this.fetchOptData(),
        //     // this.fetchLeadData(),
        //     // this.fetchContactData(),
        //     // this.fetchContractData(),
        // ]);
    };
    // 获取数据
    public fetchGlobalSearchData = async (
        entityCode:
            | 'lead'
            | 'opportunity'
            | 'customer'
            | 'contacts'
            | 'contract',
    ): Promise<TGetGlobalSearchDataReturnType> => {
        const dataName = `${entityCode}GlobalSearchData`;
        const loadingName = `${entityCode}Loading`;
        const countName = `${entityCode}Count`;
        if (!this.globalSearchText || this.globalSearchText.length < 2) {
            return [null, null];
        } else {
            this[loadingName] = true;
            const url = '/bff/api/rest/global-search/search';
            const [d, e] = await defaultAxios.post(url, {
                entityCode,
                word: this.globalSearchText,
                ...this.defaultPagination.paginationArgs,
            });
            this[loadingName] = false;
            if (
                d === null ||
                d.data === null ||
                !Array.isArray(d?.data?.data)
            ) {
                return [null, e];
            } else {
                this[countName] = d.data.total;
                this[dataName] = d.data.data;
            }
            return [d, e];
        }
    };
    constructor() {
        makeAutoObservable(this);
    }
}

export const getDefaultGSStore = createDefaultInstanceGetter(GlobalSearchStore);
