import React, { useEffect } from 'react';
import ContactsForm from '@/pages/sales-follow-up/contact/list/components/form-drawer';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Modal } from 'antd';
import { useAnyContactsStore } from '@/pages/sales-follow-up/contact/list/store';
import _ from 'lodash';
import { tryRefresh } from '@/utils';
import { genLeadIdParamByContractItemAndParamMeta } from '@/pages/sales-follow-up/lead/list/store';
import { genCustomerIdParamByCustomerItemAndParamMeta } from '@/pages/sales-follow-up/custom/list/store';

export const CUser2Contacts: React.FC<{
    trigger: number;
    customerInfo: any;
    cuser: any;
}> = observer(({ trigger, customerInfo, cuser }) => {
    customerInfo = customerInfo || {};

    const [prepared, defaultStore, prepareStore] = useAnyContactsStore({
        opUserSuffix: 'customer-drawer',
        autoLoad: false,
    });
    const { actionType } = defaultStore;

    useEffect(() => {
        if (trigger > 0) {
            prepareStore();
            runInAction(() => {
                defaultStore.setAction('create');
            });
        }
    }, [trigger]);

    useEffect(() => {
        if (!prepared || actionType !== 'create') {
            return;
        }
        defaultStore.startNewData();
        if (!customerInfo || !cuser) {
            return;
        }
        const { mutatingData } = defaultStore;
        const nextMutatingData = _.cloneDeep(mutatingData);
        nextMutatingData.customer_id = genCustomerIdParamByCustomerItemAndParamMeta(
            customerInfo,
        );
        nextMutatingData.contacts_name = cuser.realname;
        nextMutatingData.contacts_phone = cuser.decodedPhone;
        nextMutatingData.contacts_duty = cuser.position;
        nextMutatingData.contacts_source = 4; // 脉动态用户转化

        // 点击，直接打开【新建联系人】弹窗，自动将【姓名】【客户名称】【职务】【手机号】信息带过去，支持再修改
        // 客户名称：取客户详情页上对应的客户名
        // 手机号：全部加密
        // TODO, 联系人来源：设置为【脉动态用户转化】，不支持修改

        defaultStore.setMutatingData(nextMutatingData);
    }, [prepared, actionType, customerInfo, cuser]);

    if (trigger === 0) {
        return null;
    }

    const preRequiredReady = prepared;
    return (
        <ContactsForm
            mutateSuccess={() => {
                tryRefresh(['lead']);
            }}
            defaultStore={defaultStore}
            preRequiredReady={preRequiredReady}
        />
    );
});
