import React, { ReactNode, useState, useRef, useEffect } from 'react';
import './index.scss';
import { Checkbox, Icon, Button } from 'antd';
import _ from 'lodash';
import ButtonGroup from 'antd/lib/button/button-group';
import { ADFilterEE } from '@/utils';
import { observer } from 'mobx-react-lite';

const innerFlags = [
    'th-filter-icon',
    'crm-advanced-filter',
    'ant-time-picker-panel-select',
    'ant-select-dropdown',
    'ant-modal-root',
    'ant-popover-inner-content',
];

export const AdvancedFilter: React.FC<{
    k: string;
    widget: { WCom: any; props: any };
    onIsEmptyValueChange: (isEmpty: boolean, rawV: any) => void;
    onIsNotEmptyValueChange: (isNotEmpty: boolean, rawV: any) => void;
    onSorterChange: (sorttype: string) => void;
    onClose?: () => void;
    descText?: string;
    ascText?: string;
    onCancel?: () => void;
    onConfirm?: () => void;
}> = observer(
    ({
        k,
        widget,
        onIsEmptyValueChange,
        onIsNotEmptyValueChange,
        onSorterChange: rawonSorterChange,
        onClose: rawonClose = () => void 0,
        onCancel: rawonCancel = () => void 0,
        onConfirm: rawonConfirm = () => void 0,
        descText = '降序',
        ascText = '升序',
    }) => {
        const { WCom, props } = widget;
        const { onInteract, onChange, data } = props;
        const rawV = data[k];
        let isEmpty = false;
        if (rawV && 'isEmpty' in rawV) {
            isEmpty = rawV.isEmpty;
        }
        let isNotEmpty = false;
        if (rawV && 'isNotEmpty' in rawV) {
            isNotEmpty = rawV.isNotEmpty;
        }

        const [disabled, setDisabled] = useState(false);
        const nextOnInteract = (type: string, value: any) => {
            if (type === 'filter-become-invalid') {
                setDisabled(true);
            }
            if (type === 'filter-become-valid') {
                setDisabled(false);
            }
            onInteract(type, value);
        };
        const nextOnChange = (v: any) => {
            onChange(v, rawV);
        };

        const onSorterChange = (sorttype: string) => {
            ADFilterEE.emit('advanced-filter-event', {
                type: 'onSorterChange',
                args: [k, sorttype],
            });
            rawonSorterChange(sorttype);
        };
        const onClose = () => {
            ADFilterEE.emit('advanced-filter-event', {
                type: 'onClose',
                args: [k],
            });
            rawonClose();
        };
        const onCancel = () => {
            ADFilterEE.emit('advanced-filter-event', {
                type: 'onCancel',
                args: [k],
            });
            rawonCancel();
        };
        const onConfirm = () => {
            ADFilterEE.emit('advanced-filter-event', {
                type: 'onConfirm',
                args: [k],
            });
            rawonConfirm();
        };
        useEffect(() => {
            const handler = (e: any) => {
                const ne = e;
                // safari using composedPath
                const path = ne.path || (ne.composedPath && ne.composedPath());
                if (_.isArray(path)) {
                    setTimeout(() => {
                        const innerClick = !!_.find(path, item => {
                            if (!item.classList) {
                                return false;
                            }
                            const currentClassStringList = [].slice.call(
                                item.classList,
                            ) as string[];
                            return !_.isEmpty(
                                _.intersection(
                                    currentClassStringList,
                                    innerFlags,
                                ),
                            );
                        });
                        if (!innerClick) {
                            onCancel();
                        }
                    }, 0);
                }
            };
            document.body.addEventListener('click', handler);
            return () => {
                document.body.removeEventListener('click', handler);
            };
        }, []);

        // const [ timer, setTimer ] = useState<NodeJS.Timer | null>(null)
        return (
            <div
                // onMouseEnter={() => {
                //     if (timer === null) {
                //         return
                //     }
                //     clearTimeout(timer)
                //     setTimer(null)
                // }} onMouseLeave={() => {
                //     const nextTimer = setTimeout(() => {
                //         onClose()
                //         clearTimeout(nextTimer)
                //         setTimer(null)
                //     }, 600)
                //     setTimer(nextTimer)
                // }}
                className="crm-advanced-filter"
            >
                {/* sorter */}
                <div className="sorter-warpper">
                    <div
                        onClick={() => onSorterChange('ascend')}
                        className="sort-type asc"
                    >
                        <Icon type="sort-ascending" />
                        <span className="text">{ascText}</span>
                    </div>
                    <div
                        onClick={() => onSorterChange('descend')}
                        className="sort-type desc"
                    >
                        <Icon type="sort-descending" />
                        <span className="text">{descText}</span>
                    </div>
                </div>
                <div className="filter-wrapper">
                    <div className="filter-title">
                        <Icon type="filter" />
                        <span className="text">筛选</span>
                    </div>
                    <div className="filters">
                        <div className="widget">
                            <WCom
                                {...props}
                                onInteract={nextOnInteract}
                                onChange={nextOnChange}
                            />
                        </div>
                        <div className="empty-value-wrapper">
                            <div className="empty-value">
                                <Checkbox
                                    checked={isEmpty}
                                    onChange={e => {
                                        const checked = e.target.checked;
                                        onIsEmptyValueChange(checked, rawV);
                                    }}
                                >
                                    空（未填写）
                                </Checkbox>
                            </div>
                            <div className="empty-value">
                                <Checkbox
                                    checked={isNotEmpty}
                                    onChange={e => {
                                        const checked = e.target.checked;
                                        onIsNotEmptyValueChange(checked, rawV);
                                    }}
                                >
                                    非空（存在值）
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-stage">
                    <ButtonGroup>
                        <Button onClick={onCancel}>取消</Button>
                        <Button
                            disabled={disabled}
                            onClick={onConfirm}
                            type="primary"
                        >
                            确定
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        );
    },
);
