import React, { useEffect, useState } from 'react';
import classname from 'classname';
import _ from 'lodash';
import './index.less';

interface Option {
    icon?: React.ReactSVGElement | JSX.Element;
    label: string;
    value: string | number;
}

type NumberOrString = string | number;

export interface SelectorProps {
    label?: string;
    defaultValue?: NumberOrString;
    value?: NumberOrString | NumberOrString[];
    options: Option[];
    type?: 'tag' | 'icon';
    onChange?: (selected: NumberOrString) => void;
}

const Selector: React.FC<SelectorProps> = props => {
    const {
        label,
        options,
        type = 'tag',
        onChange = () => {},
        value: controlledValue,
        defaultValue,
    } = props;

    const [selected, setSelected] = useState<NumberOrString | NumberOrString[]>(
        controlledValue || defaultValue || '',
    );

    useEffect(() => {
        if (controlledValue !== undefined) {
            setSelected(controlledValue);
        }
    }, [controlledValue]);

    const handleSeleted = (value: string | number) => {
        setSelected(value);
        onChange(value);
    };
    return (
        <div className="follow-selector">
            {label && <div className="follow-selector-label">{label}:</div>}
            <div className="follow-selector-options">
                {options.map(option => (
                    <div
                        className={classname('follow-selector-options-item', {
                            active: [
                                ...(Array.isArray(selected)
                                    ? selected
                                    : [selected]),
                            ].includes(option.value),
                            icon: type === 'icon',
                        })}
                        key={option.value}
                        onClick={() => handleSeleted(option.value)}
                    >
                        {type === 'icon' && option.icon}
                        <span>{option.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Selector;
