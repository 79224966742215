import axios, { AxiosRequestConfig } from 'axios';
import { message } from 'antd';
import { defaultAxios } from '@/utils';

export const exportFile = async (
    url: string,
    params?: any,
    config?: AxiosRequestConfig,
) => {
    try {
        const response = await axios({
            url: url,
            method: 'put',
            responseType: 'blob',
            data: params,
            ...config,
        });

        const a = window.document.createElement('a');
        const downUrl = window.URL.createObjectURL(response.data);
        let filename = 'download.xls';
        if (
            response.headers['content-disposition'] &&
            response.headers['content-disposition'].indexOf('filename=') !== -1
        ) {
            filename = response.headers['content-disposition'].split(
                'filename=',
            )[1];
            a.href = downUrl;
            a.download = `${decodeURI(filename)}` || 'download.xls';
            a.click();
            window.URL.revokeObjectURL(downUrl);
        }
    } catch (error) {
        message.error('文件导出失败');
    }
};

export interface DataBoardOrg {
    managerId?: number;
    orgId?: number;
    orgName?: string;
    orgType?: string;
    parentOrgId?: number;
}

export interface BoardAuthResponse {
    // 可见组织列表
    dataBoardOrgList?: DataBoardOrg[];
    userId?: number;
    // 6全国，5区域，4城市
    viewOrgType?: string;
}

// 获取看板权限
export const getBoardAuth: (
    orgId: string | number,
) => Promise<BoardAuthResponse> = async orgId => {
    const [d, e] = await defaultAxios.get('/crm/data/getBoardAuth', { orgId });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
};
