import { defaultAxios } from '@/utils';

export interface FetchTagListParams {
    // 1 我创建的、2 我可见的
    scope?: number;
    // 是否生效 1是，0否
    effect?: number;
    // 标签名
    name?: string;
}

export interface TagItemInfo {
    comment: string;
    create_time: number;
    effect: number;
    // 是否可换绑对象，1是，0否
    changed?: number;
    group_id?: string;
    id: string;
    name: string;
    num: number | null;
    selected?: boolean | null;
    org?: string | null;
}

export interface TagGroupInfo extends TagItemInfo {
    auth: number;
    entity_code: string | null;
    option: number;
    items?: TagItemInfo[];
    can_empty: number;
}

// 查询标签列表（标签管理页）
export const fetchTagList: (
    params?: FetchTagListParams,
) => Promise<TagGroupInfo[]> = async params => {
    const [d, e] = await defaultAxios.post('/crm/label/find', {
        ...params,
    });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
};

export interface SaveTagGroupParams {
    id?: string;
    name: string;
    auth: number;
    comment?: string;
    changed: number;
    option: number;
    num?: number;
    effect: number;
    org?: string;
    can_empty: number;
}

// 保存标签组
export const saveTagGroup: (
    params?: SaveTagGroupParams,
) => Promise<any> = async params => {
    const [d, e] = await defaultAxios.put('/crm/label/group/save', {
        ...params,
    });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
};

export interface SaveTagItemParams {
    id?: string;
    group_id: string;
    name: string;
    comment?: string;
    num?: number;
    effect: number;
}

// 保存标签
export async function saveTagItem(params?: SaveTagItemParams): Promise<any> {
    const [d, e] = await defaultAxios.put('/crm/label/item/save', {
        ...params,
    });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
}

// 删除标签组
export async function deleteTagGroup(id: string | number): Promise<any> {
    const [d, e] = await defaultAxios.delete(
        `/crm/label/group/delete?id=${id}`,
    );
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
}

// 删除标签
export async function deleteTagItem(id: string | number): Promise<any> {
    const [d, e] = await defaultAxios.delete(`/crm/label/item/delete?id=${id}`);
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
}

export interface BindCustomerBody {
    customerName: string;
    bizType: string;
}
export interface BindCustomerParams {
    labelId: number;
    body: BindCustomerBody[];
}
// 客户标签绑定
export async function bindCustomer(params: BindCustomerParams): Promise<any> {
    const { labelId, body } = params;
    const [d, e] = await defaultAxios.post(
        `/crm/label/bind/customer?labelId=${labelId}`,
        body,
    );
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
}

export interface bindCustomerForPageParams {
    entity_id_list: (number | string | undefined)[];
    entity_code: string;
    label_list: (number | string)[];
}

// 客户标签绑定-页面
export async function bindCustomerForPage(
    params: bindCustomerForPageParams,
): Promise<any> {
    const [d, e] = await defaultAxios.post(`/crm/label/bind/customerForPage`, {
        ...params,
    });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
}

export interface ILabelPlanResponse {
    id: number;
    plan_name: string;
    label: TagGroupInfo[];
}

// 获取标签方案
export async function getLabelPlan(
    id?: number | string,
): Promise<ILabelPlanResponse | null> {
    const [d, e] = await defaultAxios.get(`/crm/label/plan/get`, {
        id,
    });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
}

export interface ISaveLabelPlanParams {
    id: number;
    plan_name: string;
    label_id_list: number[];
}

// 保存标签方案
export async function saveLabelPlan(
    params: ISaveLabelPlanParams,
): Promise<any> {
    const [d, e] = await defaultAxios.put(`/crm/label/plan/save`, {
        ...params,
    });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
}

// 删除标签方案
export async function deleteLabelPlan(id?: number | string): Promise<any> {
    const [d, e] = await defaultAxios.delete(`/crm/label/plan/delete?id=${id}`);
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
}

export interface IPlanItem {
    id: number;
    plan_name: string;
    relation_object_map: object;
}

// 方案列表
export async function labelPlanList(): Promise<IPlanItem[]> {
    const [d, e] = await defaultAxios.put(
        `/crm/common/label_plan/query`,
        {
            pagination: {
                pageSize: 100,
                pageNum: 1,
            },
            filters: {
                filters: [],
            },
            sorter: {},
            selectiveFields: ['id', 'plan_name'],
        },
        { silent: true },
    );
    if (d === null || d.data === null || d.data.data === null || e) {
        return [];
    }
    return d.data.data;
}

// 查询标签列表（可绑定标签）
export const fetchBindTagList: (
    params?: FetchTagListParams,
) => Promise<TagGroupInfo[]> = async params => {
    const [d, e] = await defaultAxios.post('/crm/label/bind/find', {
        ...params,
    });
    if (d === null || d.data === null || e) {
        return null;
    }
    return d.data;
};
