import { makeAutoObservable } from 'mobx';
import { defaultAxios } from '@/utils';
import { createDefaultInstanceGetter } from '../../../stores/utils/index';

export interface ISpecialUserData {
    userId?: number | string | any[];
    userName?: string | number;
    maxNum?: number | string;
    updateTime?: number | string;
    isIncludeCreate?: number | string; // 包括自建
    isIncludeSign?: number | string; // 包括签约
    id?: number | string;
}
export interface INormalSettingData {
    type?: number | string;
    maxNum?: number | string; // 最大库容量
    isIncludeCreate?: number | string; // 包括自建
    isIncludeSign?: number | string; // 包括签约
    userLevel?: string; // 销售级别
}
export interface ICHSPSLTableData extends INormalSettingData {
    specialUsers?: ISpecialUserData[]; // 特殊用户列表
}
export type TGetCHSPSLTableReturnType = NetResponse<{
    data: ICHSPSLTableData;
    msg: string;
    code: number;
}>;
export class CHSPSLStore {
    constructor() {
        makeAutoObservable(this);
    }
    public specialLimitTableData: ISpecialUserData[] = [];
    public normalLimitTableData: INormalSettingData[] = [];

    public getCHSPLimitData: () => Promise<
        TGetCHSPSLTableReturnType
    > = async () => {
        const url = '/bff/api/rest/chspl/list';
        const [d, e] = await defaultAxios.get(url);
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        if (d.data) {
            this.normalLimitTableData = data?.levelLimitList || [];
            // setNormalSettingData({
            //     type: data?.type || 1,
            //     generalMaxLimit: data?.generalMaxLimit || '',
            //     includeSelfBuild: data?.includeSelfBuild || '',
            //     includeDeal: data?.includeDeal || '',
            // });
            this.specialLimitTableData = data?.specialUsers || [];
        }
        return [data, error];
    };
    public setNormalLimitData = async (updateData: INormalSettingData) => {
        const url = '/bff/api/rest/chspl/setting-normal-limit';
        const [d, e] = await defaultAxios.post(url, {
            ...updateData,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    // 不传id是新增，传了id是更改
    public updateOrAddSpecialSales = async (updateData: ISpecialUserData) => {
        const url = '/bff/api/rest/chspl/set-special-sales';
        const [d, e] = await defaultAxios.post(url, {
            ...updateData,
        });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
    public deleteSpecialSales = async (id: string | number) => {
        const url = '/bff/api/rest/chspl/delete-special-sales';
        const [d, e] = await defaultAxios.post(url, { id });
        if (d === null || d.data === null) {
            return [null, e];
        }
        const data = d.data;
        const error = e as Error | null;
        return [data, error];
    };
}
export const getDefaultCHSPSLStore = createDefaultInstanceGetter(CHSPSLStore);
