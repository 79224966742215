import React, { useState, useEffect } from 'react';
import { Icon, Button, Steps, Alert, message } from 'antd';
import './index.less';
import _ from 'lodash';
import { WidgetInputUpload } from '@/components/common/form/widgets';
import { defaultAxios, netLog } from '@/utils';
import jschardet from 'jschardet';

import XLSX from 'xlsx';
import { TableLv2 } from '@/components';
const { Step } = Steps;

export interface IPreviewData {
    customerName: string;
    customerCooperationStatus: number | string;
    currentOwnerName: string;
    distributeOwnerEmailSuffix: string;
    distributeOwnerName: string;
    isResetClaimTime: boolean;
    errMessages: string;
}
const TitleMapToKey: {
    [key: string]: any;
} = {
    客户名称: 'customerName',
    分入销售邮箱前缀: 'distributeOwnerEmailSuffix',
    是否重置认领时间: 'isResetClaimTime',
};
const DistributeCustomerTab: React.FC<{
    changeTab: (tabKey: string) => void;
}> = props => {
    const { changeTab } = props;
    const [selectedFileData, setSelectedFileData] = useState<FileList>();
    const [nextLoading, setNextLoading] = useState(false);
    const [stepCurrent, setStepCurrent] = useState(0);
    const [sheetData, setSheetData] = useState<any>();
    const [sheetPreviewData, setSheetPreviewData] = useState<IPreviewData[]>(
        [],
    );

    const nextStep = () => {
        setStepCurrent(stepCurrent + 1);
    };
    const prevStep = () => {
        setStepCurrent(stepCurrent - 1);
    };
    const clearFile = () => {
        setSelectedFileData(undefined);
    };
    // 预览上传
    const uploadSheetFirst = async (uData: any) => {
        const url = '/crm/import/batch/preview';
        const [d, e] = await defaultAxios.post(url, uData);
        setNextLoading(false);
        if (d === null || d.data === null) {
            netLog({
                linkUrl: url,
                method: 'post',
                payload: {},
                isError: true,
                errorMsg: e?.msg || '',
            });
            message.error('上传文件失败，请稍后重试');
            return false;
        }
        const data = d.data;
        console.log(data);
        if (Array.isArray(data)) {
            setSheetPreviewData(data);
            nextStep();
            message.success('上传文件成功');
            return true;
        } else {
            netLog({
                linkUrl: url,
                method: 'post',
                payload: {},
                isError: true,
                errorMsg: d.msg || '',
            });
            message.error(d.msg || '上传文件失败，请稍后重试');
            return false;
        }
    };
    const uploadSheetFinal = async () => {
        setNextLoading(true);
        const url = '/crm/import/batch/execution';
        const [d, e] = await defaultAxios.post(url, sheetData);
        setNextLoading(false);
        if (d === null || d.data === null) {
            netLog({
                linkUrl: url,
                method: 'post',
                payload: {},
                isError: true,
                errorMsg: e?.msg || '',
            });
            message.error('上传文件失败，请稍后重试');
            return false;
        }
        if (d.data) {
            message.success('上传文件成功');
            nextStep();
            return true;
        } else {
            netLog({
                linkUrl: url,
                method: 'post',
                payload: {},
                isError: true,
                errorMsg: d.msg || '',
            });
            message.error(d.msg || '上传文件失败，请稍后重试');
            return false;
        }
    };
    const readSheet = async (cb?: any) => {
        if (selectedFileData && selectedFileData.length) {
            setNextLoading(true);
            const fileReader = new FileReader();
            const isCSV =
                selectedFileData?.[0].name.split('.').reverse()[0] == 'csv';
            fileReader.readAsBinaryString(selectedFileData?.[0]);
            fileReader.onerror = () => {
                message.error('解析表格失败，请联系技术同学～');
                setNextLoading(false);
            };

            fileReader.onload = async event => {
                try {
                    const result = event.target?.result;
                    let workbook: any;
                    const checkUTF8 = jschardet.detect(result as string);
                    if (checkUTF8.encoding === 'UTF-8') {
                        workbook = XLSX.read(result, { type: 'binary' });
                    } else {
                        workbook = XLSX.read(result, {
                            type: 'binary',
                            codepage: 936,
                        });
                    }
                    // 以二进制流方式读取得到整份excel表格对象
                    // 存储获取到的数据
                    let data: any[] = [];
                    let header: any[] = [];
                    // 遍历每张工作表进行读取（这里默认只读取第一张表）
                    for (const sheet in workbook.Sheets) {
                        // esline-disable-next-line
                        if (workbook.Sheets.hasOwnProperty(sheet)) {
                            header = header.concat(
                                XLSX.utils
                                    .sheet_to_csv(workbook.Sheets[sheet])
                                    .split(/[\s\n]/)[0]
                                    .split(','),
                            );
                            // 利用 sheet_to_json 方法将 excel 转成 json 数据
                            data = data.concat(
                                XLSX.utils.sheet_to_json(
                                    workbook.Sheets[sheet],
                                ),
                            );
                            // break; // 如果只取第一张表，就取消注释这行
                        }
                    }
                    if (data.length > 500) {
                        message.error('上传表格的行数不能超过500');
                        return;
                    }
                    // 最终获取到并且格式化后的 json 数据
                    const finalJson = _.forEach(data, item => {
                        const finalRow: any = {};
                        // tslint:disable-next-line:forin
                        for (const key in item) {
                            const element = item[key];
                            if (TitleMapToKey[key]) {
                                finalRow[TitleMapToKey[key]] = element;
                            } else {
                                finalRow[key] = element;
                            }
                        }
                        return finalRow;
                    });
                    setSheetData(finalJson);
                    const uploadRes = await uploadSheetFirst(finalJson);
                    // this.sheetJSON = data;
                    // this.sheetHeader = header;
                    if (uploadRes && cb && typeof cb === 'function') {
                        cb();
                    }
                } catch (e) {
                    console.log(e);
                    setNextLoading(false);
                    // 这里可以抛出文件类型错误不正确的相关提示
                    message.error('解析文件流失败，请联系技术同学～');
                }
            };
        }
    };
    const renderUploadCustomerDataStep = () => {
        return (
            <div className="dc-upload-customer-step">
                <Alert
                    type="warning"
                    showIcon
                    message={
                        <span>
                            1.
                            请严格按照模版完善信息后上传，否则会导致上传失败。2.
                            单次上传数据量不能超过
                            <span style={{ color: 'red' }}>500</span>
                            条，文件大小不能超过
                            <span style={{ color: 'red' }}>3M</span>
                        </span>
                    }
                />
                <Alert
                    style={{ marginTop: '12px' }}
                    type="info"
                    showIcon
                    message={
                        <span>
                            填写须知：1. 模版内4个字段都是必填。 2.
                            是否重置认领时间，请基于业务需要输入【是】or【否】。3.业务类型，请基于业务输入【招聘业务】or【广告业务】
                        </span>
                    }
                />
                <div className="dc-upload-customer-items">
                    <div className="dc-uc-item">
                        <span>1、下载模版</span>
                        <Button
                            style={{ marginLeft: '8px' }}
                            onClick={() => {
                                window.open(
                                    `${process.env.PUBLIC_URL}/dc-sample.csv`,
                                    '_blank',
                                );
                            }}
                        >
                            下载模版
                        </Button>
                    </div>
                    <div className="dc-uc-item">
                        <span>2、按模版填写信息</span>
                    </div>
                    <div className="dc-uc-item dc-uc-upload-content">
                        <span>3、上传填写好的文件</span>
                        <div className="dc-data-choose-upload-content">
                            <WidgetInputUpload
                                value={selectedFileData as FileList}
                                onChange={v => {
                                    setSelectedFileData(v);
                                }}
                                options={{
                                    className: 'dc-data-choose-upload',
                                    accept: '.csv',
                                }}
                            />
                            <>
                                <span className="dc-data-choose-upload-btn">
                                    选择文件
                                </span>
                                {selectedFileData && selectedFileData.length ? (
                                    <div className="dc-data-choose-upload-text">
                                        <span>
                                            {selectedFileData?.[0].name}
                                        </span>
                                        <Icon
                                            onClick={clearFile}
                                            type="close"
                                        />
                                    </div>
                                ) : null}
                            </>
                        </div>
                    </div>
                    <Button
                        style={{ width: '120px', marginTop: '20px' }}
                        type="primary"
                        onClick={() => {
                            readSheet(nextStep);
                        }}
                        loading={nextLoading}
                        disabled={
                            !selectedFileData || selectedFileData.length <= 0
                        }
                    >
                        下一步
                    </Button>
                </div>
            </div>
        );
    };
    // id: string | number,
    // customer_name: string,
    // cooperation_status: number | string,
    // now_sales_name: string,
    // sales_email: string,
    // next_sales_name: string,
    // is_reset: boolean,
    // error_text: string
    const renderPreviewDataStep = () => {
        let isError = false;
        for (const item of sheetPreviewData) {
            if (item.errMessages) {
                isError = true;
                break;
            }
        }
        return (
            <>
                <TableLv2<IPreviewData>
                    dataSource={sheetPreviewData}
                    style={{ marginTop: '16px' }}
                    scroll={{ x: 1000, y: 600 }}
                    className="dc-preview-customer-table"
                    closeRowSelection={true}
                    columns={[
                        {
                            key: 'id',
                            title: '序号',
                            width: '60px',
                            render: (v, r, i) => {
                                return i;
                            },
                        },
                        {
                            key: 'customerName',
                            dataIndex: 'customerName',
                            title: '客户名称',
                            width: '160px',
                        },
                        {
                            dataIndex: 'bizType',
                            key: 'bizType',
                            title: '业务类型',
                            width: '120px',
                        },
                        {
                            key: 'customerCooperationStatus',
                            dataIndex: 'customerCooperationStatus',
                            title: '客户合作状态',
                            width: '120px',
                        },
                        {
                            key: 'currentOwnerName',
                            dataIndex: 'currentOwnerName',
                            title: '当前归属销售姓名',
                            width: '120px',
                        },
                        {
                            key: 'distributeOwnerEmailSuffix',
                            dataIndex: 'distributeOwnerEmailSuffix',
                            title: '分入销售邮箱前缀',
                            width: '120px',
                        },
                        {
                            key: 'distributeOwnerName',
                            dataIndex: 'distributeOwnerName',
                            title: '分入销售姓名',
                            width: '120px',
                        },
                        {
                            key: 'isResetClaimTime',
                            dataIndex: 'isResetClaimTime',
                            width: '120px',
                            title: '是否重置认领时间',
                            render: v => {
                                return v;
                            },
                        },
                        {
                            key: 'errMessages',
                            dataIndex: 'errMessages',
                            width: '160px',
                            title: '错误提示',
                            render: text => {
                                return (
                                    <span style={{ color: 'red' }}>{text}</span>
                                );
                            },
                        },
                    ]}
                />
                <div className="dc-preview-btn-row">
                    <Button
                        style={{ width: '120px' }}
                        onClick={() => {
                            setSheetPreviewData([]);
                            prevStep();
                        }}
                    >
                        上一步
                    </Button>
                    <Button
                        style={{ width: '120px' }}
                        type="primary"
                        onClick={uploadSheetFinal}
                        loading={nextLoading}
                        disabled={isError}
                    >
                        执行分配
                    </Button>
                </div>
            </>
        );
    };
    const stepRenderData = [
        {
            title: '上传数据',
            key: 'data',
            icon: <Icon type="file" />,
            content: renderUploadCustomerDataStep(),
        },
        {
            title: '预览',
            key: 'preview',
            icon: <Icon type="exception" />,
            content: renderPreviewDataStep(),
        },
        {
            title: '执行',
            key: 'import',
            icon: <Icon type="cloud-upload" />,
            content: (
                <div className="final-import-tips">
                    <p>导入文件成功，请在5至10分钟后前往分配记录查看导入结果</p>
                    <div className="dc-final-tips-btn-row">
                        <Button
                            onClick={() => {
                                setStepCurrent(0);
                            }}
                            style={{ marginRight: '12px' }}
                        >
                            继续导入
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                changeTab('2');
                            }}
                        >
                            查看分配记录
                        </Button>
                    </div>
                </div>
            ),
        },
    ];
    return (
        <div className="dc-content distribute-customer-tab-content">
            <div className="dc-step-content">
                <Steps current={stepCurrent}>
                    {_.map(stepRenderData, step => {
                        return (
                            <Step
                                key={step.key}
                                icon={step.icon}
                                title={step.title}
                            />
                        );
                    })}
                </Steps>
            </div>
            <div className="dc-action-content">
                {stepRenderData[stepCurrent].content || null}
            </div>
        </div>
    );
};

export default DistributeCustomerTab;
