import React, { useEffect, useState, useContext, useCallback } from 'react';
import { message } from 'antd';
import { RouteComponentProps } from '@reach/router';
import {
    LayoutStandardCrud,
    BlockLoading2,
    FiltersDisplay,
} from '@/components';
import _ from 'lodash';
import { StoreContext } from '@/stores';
import { useNet } from '@/utils';
import { observer } from 'mobx-react-lite';
import FormDemoTable from './components/table';
import { defaultAuthDeco } from '@/containers/hoc';
import './index.scss';
import { EquityDetail } from '@/pages/om/contract/contract-product/components/equity-detail';
import { FinalProcessFormProxy } from '@/pages/sales-follow-up/process/list/form-proxy';
import { CtxProvider } from '@/utils/context';

const ContractProductList: React.FC<RouteComponentProps & {
    drawerData: any;
    type: string;
}> = ({ type = 'all', drawerData = {} }) => {
    const currentContract = (drawerData.originData || [])[0] || {};

    const store = useContext(StoreContext);
    const [defaultStore] = useState(() => new store.ContractProductListStore());
    const userStore = store.getAuthStore();

    const {
        defaultPagination,
        defaultSorter,
        defaultMeta,
        defaultPerm,
        defaultFilter,
    } = defaultStore;

    const { confirmedfilterData } = defaultFilter;

    const { paginationForFetch } = defaultPagination;

    const { sortersForFetch } = defaultSorter;

    const ListFetch = useCallback(() => defaultStore.fetch(), []);
    const [error, loading, reload] = useNet(ListFetch, {
        autoLoad: false,
        refreshKeys: ['contract', 'contract_product'],
    });

    const [preRequiredReady, setPreRequiredReady] = useState(false);

    useEffect(() => {
        if (!currentContract.id) {
            return;
        }
        defaultStore.setDefaultFilters([
            {
                fieldId: 'contract_id',
                filterValue: currentContract.id,
                operateType: '=',
                parser: 'string',
            },
        ]);
    }, [drawerData]);

    // 翻页
    useEffect(() => {
        // 元信息，准备用户可查询的
        if (!preRequiredReady || !currentContract.id) {
            return;
        }
        reload();
    }, [
        paginationForFetch,
        sortersForFetch,
        confirmedfilterData,
        preRequiredReady,
    ]);

    useEffect(() => {
        if (userStore.userInfo) {
            defaultMeta.setTableId('contract_product'); // TOFIX TODO: 动态设置
            defaultMeta.setOpUsername(userStore.userInfo.userId + '-' + type);
            Promise.all([defaultMeta.fetch(), defaultPerm.fetch()])
                .then(() => {
                    setPreRequiredReady(true);
                })
                .catch(() => {}); // 初始化元信息
        }
    }, [userStore.userInfo]);

    // 设置filter初始值
    useEffect(() => {
        if (defaultMeta.inited) {
            defaultFilter.resetFilterData();
            defaultFilter.confirmFilterData();
        }
    }, [defaultMeta.inited]);

    useEffect(() => {
        defaultStore.setListType(type);
    }, [type]);

    if (!preRequiredReady) {
        return <BlockLoading2 />;
    }

    const filtersDisplay = (
        <FiltersDisplay
            objectMeta={defaultMeta.objectMeta}
            filterData={confirmedfilterData}
            sorterData={defaultStore.defaultSorter}
            onClose={(key: string) => {
                defaultFilter.onFilterClear(key);
            }}
        />
    );

    const popups = (
        <>
            <EquityDetail defaultStore={defaultStore} />
            <CtxProvider
                ctx={{ position: 'list', positionDetail: 'contract-product' }}
            >
                <FinalProcessFormProxy
                    visible={defaultStore.actionType === 'start-process'}
                    onClose={() => {
                        defaultStore.setMutatingData(null);
                        defaultStore.resetAction();
                    }}
                    key={
                        defaultStore.actionType === 'start-process'
                            ? 'opened'
                            : 'nooppened'
                    }
                    processKey={'productopen'}
                    lifeCycle={{
                        onClose: store => {
                            store.setMutatingData(null);
                            defaultStore.setFillInContractProductProcessObjData(
                                null,
                            );
                        },
                        onOpen: store => {
                            if (
                                !defaultStore.fillInContractProductProcessObjData
                            ) {
                                return;
                            }
                            const {
                                fillInContractProductProcessObjData: data,
                            } = defaultStore;
                            store.startNewData();
                            store.setMutatingData(
                                _.assign(
                                    store.mutatingData,
                                    { talent_bank_version: 0 },
                                    data,
                                ),
                            );
                        },
                    }}
                    preRequestReady={
                        !defaultStore.fillInContractProductProcessObjLoading
                    }
                />
            </CtxProvider>
            <CtxProvider
                ctx={{ position: 'list', positionDetail: 'contract-product' }}
            >
                <FinalProcessFormProxy
                    visible={defaultStore.actionType === 'start-process-guzhu'}
                    onClose={() => {
                        defaultStore.setMutatingData(null);
                        defaultStore.resetAction();
                    }}
                    key={
                        defaultStore.actionType === 'start-process-guzhu'
                            ? 'opened'
                            : 'nooppened'
                    }
                    processKey={'contractemployer'}
                    lifeCycle={{
                        onClose: store => {
                            store.setMutatingData(null);
                            defaultStore.setFillInContractProductGuzhuProcessObjData(
                                null,
                            );
                        },
                        onOpen: store => {
                            if (
                                !defaultStore.fillInContractProductGuzhuProcessObjData
                            ) {
                                return;
                            }
                            const {
                                fillInContractProductGuzhuProcessObjData: data,
                            } = defaultStore;
                            store.startNewData();
                            store.setMutatingData(
                                _.assign(
                                    store.mutatingData,
                                    { talent_bank_version: 0 },
                                    data,
                                ),
                            );
                        },
                    }}
                    preRequestReady={
                        !defaultStore.fillInContractProductGuzhuProcessObjLoading
                    }
                />
            </CtxProvider>
        </>
    );

    const title = null;
    const globalOps = null;
    const multipleOps = null;

    const dataTable = (
        <FormDemoTable defaultStore={defaultStore} loading={loading} />
    );

    const layoutComs = {
        popups,
        title,
        globalOps,
        filters: null,
        multipleOps,
        dataTable,
        message: null,
        filtersDisplay,
    };

    return <LayoutStandardCrud className="opt-tab" {...layoutComs} />;
};

const FinalContractProductList = defaultAuthDeco(observer(ContractProductList));
export default FinalContractProductList;
