import React from 'react';

import { CustomerJobStore } from './customer/store';

import CommonTable from '../components/table';

import CommonForm from '../components/form';

import CommonFormDrawer from '../components/form-drawer';

import CommonDrawer from '../drawer';

import { LeadJobStore } from './lead/store';

import LeadJobTable from './lead/components/table';

import LeadJobFormDrawer from './lead/components/form-drawer';

import { IJobStore, JobBaseStore } from '../utils';
import { LeadActions } from './lead/components/actions';

export const getJobStore = (tableCode: string): IJobStore => {
    if (tableCode === 'task_activation') {
        return new LeadJobStore();
    }
    return new JobBaseStore(tableCode);
};

export const getJobTable = (tableCode: string) => {
    if (tableCode === 'task_activation') {
        return LeadJobTable;
    }
    return CommonTable;
};

export const getFormDrawer = (tableCode: string) => {
    if (tableCode === 'task_activation') {
        return LeadJobFormDrawer;
    }
    return CommonFormDrawer;
};

const NoActions: React.FC<{ defaultStore: JobBaseStore }> = () => {
    return <></>;
};

export const getActions = (tableCode: string) => {
    if (tableCode === 'task_activation') {
        return LeadActions;
    }

    return NoActions;
};
