import React, { useState, useContext, useCallback, useEffect } from 'react';
import './index.scss';
import { AntDrawer } from '@/components/antd/drawer';
import { AntTabs } from '../../antd/index';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '@/stores';
import { useNet } from '@/utils';
import { List, Alert, Checkbox, Select, Avatar, Badge, Icon } from 'antd';
import { INeedDealtListData } from '@/stores/notice';
import InfiniteScroll from 'react-infinite-scroller';
import _, { has } from 'lodash';
import moment from 'moment';

export interface INoticeDrawerProps {
    drawerVis: boolean;
    setDrawerVis: (bool: boolean) => void;
}
let scrollFetchLock = false;
const entityCodeToUrl: any = {
    customer: '/buy/custom/all-custom',
    contacts: '/buy/contact/all',
    lead: '/buy/lead/my-lead',
    opportunity: '/buy/opportunity/all',
    process_entity_table_code: '/buy/process-management/all',
    contract: '/buy/contract/all',
    cu_activation_record: '',
    task_activation: '/buy/job-management/cus-follow',
};
const entityCodeToName: any = {
    customer: '客户',
    sys_user: '用户',
    sys_highsea: '公海池',
    sys_organization: '组织',
    process_entity_table_code: '流程',
    contract: '合同',
    lead: '线索',
    opportunity: '商机',
    contacts: '联系人',
    cu_activation_record: '活动记录',
    task_activation: '任务',
};
const NoticeDrawer: React.FC<INoticeDrawerProps> = props => {
    const { drawerVis, setDrawerVis } = props;
    const [activeTab, setActiveTab] = useState('1');
    const [showNoReadNotice, setShowNoReadNotice] = useState(true);
    // const [noticeType, setNoticeType] = useState('0');
    const store = useContext(StoreContext);
    const { getDefaultNoticeStore } = store;
    const noticeStore = getDefaultNoticeStore();
    const {
        fetchNoticeData,
        noticeData,
        setNoticeReadable,
        setReadableLoading,
        fetchNoticeNumberInfo,
        initNoticeData,
        noticeDataFetchLoading,
        hasMoreNotice,
        noticeInfo,
    } = noticeStore;
    useEffect(() => {
        const timer = setInterval(() => {
            fetchNoticeNumberInfo();
        }, 60000);
        return () => {
            clearInterval(timer);
        };
    }, []);
    // tslint:disable-next-line:prefer-const
    let [loadPage, setLoadPage] = useState<any>({
        pageNum: 1,
    });
    // const getNoticeData = useCallback(() => fetchNoticeData(activeTab, showNoReadNotice, loadPage), [activeTab, showNoReadNotice, loadPage]);
    // const [noticeDataError, noticeDataLoading, noticeDataReload] = useNet(getNoticeData);
    const noticeDataReload = async () => {
        fetchNoticeData(activeTab, showNoReadNotice, loadPage);
    };
    const hideDrawer = () => {
        setDrawerVis(false);
    };
    const tabsChangeHandle = (tabsKey: string) => {
        setActiveTab(tabsKey);
    };
    const onNeedDealtJumperClickHandle = async (id: string | number) => {
        await setNoticeReadable(undefined, id);
        fetchNoticeNumberInfo();
    };
    const setAllNoticeReadable = async () => {
        await setNoticeReadable(activeTab);
        fetchNoticeNumberInfo();
        initNoticeData();
        setLoadPage({
            pageNum: 1,
        });
    };
    useEffect(() => {
        if (drawerVis) {
            initNoticeData();
            setLoadPage({
                pageNum: 1,
            });
        }
    }, [drawerVis]);
    useEffect(() => {
        initNoticeData();
        setLoadPage({
            pageNum: 1,
        });
        setShowNoReadNotice(true);
    }, [activeTab]);
    useEffect(() => {
        initNoticeData();
        setLoadPage({
            pageNum: 1,
        });
    }, [showNoReadNotice]);
    // useEffect(() => {
    //     initNoticeData();
    //     setLoadPage({
    //         pageNum: 1,
    //     });
    // }, [noticeType]);
    useEffect(() => {
        if (drawerVis) {
            noticeDataReload();
        }
    }, [loadPage]);
    const renderNeedDealtList = () => {
        return (
            <>
                <div className="notice-list-header">
                    <Checkbox
                        checked={showNoReadNotice}
                        onChange={e => {
                            setShowNoReadNotice(e.target.checked);
                        }}
                    >
                        仅显示未读
                    </Checkbox>
                    <span
                        className="set-all-readable-btn"
                        onClick={setAllNoticeReadable}
                    >
                        <Icon type="check" style={{ marginRight: '5px' }} />
                        全部标为已读
                    </span>
                </div>
                <div style={{ overflow: 'auto', height: '100%' }}>
                    <InfiniteScroll
                        threshold={250}
                        initialLoad={false}
                        pageStart={loadPage.pageNum}
                        loadMore={page => {
                            if (!noticeData.length) {
                                return;
                            }
                            if (!hasMoreNotice) {
                                return;
                            }
                            if (!scrollFetchLock) {
                                scrollFetchLock = true;
                                setTimeout(() => {
                                    scrollFetchLock = false;
                                }, 1000);
                                setLoadPage({
                                    pageNum: loadPage.pageNum + 1,
                                });
                            }
                        }}
                        hasMore={!noticeDataFetchLoading && hasMoreNotice}
                        useWindow={false}
                    >
                        <List
                            loading={noticeDataFetchLoading}
                            className={'notice-need-dealt-list'}
                            dataSource={noticeData}
                            renderItem={(item, index) => {
                                if (item.pcUrl) {
                                    const slicePreMessageList: string[] = item.message.split(
                                        '{urlBegan}',
                                    );
                                    if (slicePreMessageList.length) {
                                        let messageFragment: string[] = [];
                                        messageFragment.push(
                                            slicePreMessageList.shift() as string,
                                        );
                                        slicePreMessageList.forEach(sText => {
                                            const sliceProdMessageList = sText.split(
                                                '{urlEnd}',
                                            );
                                            messageFragment = [
                                                ...messageFragment,
                                                ...sliceProdMessageList,
                                            ];
                                        });
                                        return (
                                            <div
                                                className="nnd-item-content"
                                                onClick={() => {
                                                    onNeedDealtJumperClickHandle(
                                                        item.id,
                                                    );
                                                }}
                                            >
                                                {item.isRead === 0 && (
                                                    <span className="red-dot"></span>
                                                )}

                                                {
                                                    <div className="nnd-item-body">
                                                        {/* <sp className="notice-item-description"> */}
                                                        {messageFragment.map(
                                                            (mItem, idx) => {
                                                                if (
                                                                    (idx + 1) %
                                                                        2 ===
                                                                    0
                                                                ) {
                                                                    return (
                                                                        <span
                                                                            className="nnd-item-jumper"
                                                                            onClick={() => {
                                                                                const url =
                                                                                    item
                                                                                        .pcUrl?.[
                                                                                        (idx +
                                                                                            1) /
                                                                                            2 -
                                                                                            1
                                                                                    ];
                                                                                onNeedDealtJumperClickHandle(
                                                                                    item.id,
                                                                                );
                                                                                window.open(
                                                                                    url,
                                                                                    '_blank',
                                                                                );
                                                                            }}
                                                                            // target='_blank'
                                                                            // href={
                                                                            //     item.pcUrl?.[
                                                                            //         ((idx + 1) / 2) - 1
                                                                            //     ]
                                                                            // }
                                                                        >
                                                                            {
                                                                                mItem
                                                                            }
                                                                        </span>
                                                                    );
                                                                } else {
                                                                    return mItem;
                                                                }
                                                            },
                                                        )}
                                                        {/* </span> */}
                                                        <div className="nnd-item-extra">
                                                            <p>
                                                                提交人：
                                                                {
                                                                    item
                                                                        .fromUser
                                                                        ?.userName
                                                                }
                                                            </p>
                                                        </div>
                                                        <p className="nnd-item-info">
                                                            {/* <span>
                                                            {
                                                                item.fromUser
                                                                    ?.userName
                                                            }
                                                        </span> */}
                                                            {moment(
                                                                item.sendTime,
                                                            ).fromNow()}
                                                        </p>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    } else {
                                        return undefined;
                                    }
                                } else {
                                    const sliceMessageJumpItemPre = item.message.split(
                                        '{urlBegan}',
                                    );
                                    const preMessage =
                                        sliceMessageJumpItemPre[0];
                                    const oneJumpMessage =
                                        sliceMessageJumpItemPre[1] || '';
                                    const sliceMessageJumpItemProd = oneJumpMessage.split(
                                        '{urlEnd}',
                                    );
                                    const finalJumpMessage =
                                        sliceMessageJumpItemProd[0];
                                    const prodMessage =
                                        sliceMessageJumpItemProd[1] || '';
                                    return (
                                        <div
                                            className="nnd-item-content"
                                            onClick={() => {
                                                onNeedDealtJumperClickHandle(
                                                    item.id,
                                                );
                                            }}
                                        >
                                            {/* {
                                                <div className="nnd-item-avatar">
                                                    <Badge dot={item.isRead !== 1}>
                                                        <Avatar
                                                            shape="square"
                                                            icon="user"
                                                            src={
                                                                item.fromUser
                                                                    ?.userAvatar
                                                            }
                                                        />
                                                    </Badge>
                                                </div>
                                            } */}
                                            {item.isRead === 0 && (
                                                <span className="red-dot"></span>
                                            )}
                                            {
                                                <div className="nnd-item-body">
                                                    {/* {item.fromUser?.userName}{' '} */}
                                                    {preMessage}{' '}
                                                    <span
                                                        className="nnd-item-jumper"
                                                        onClick={() => {
                                                            onNeedDealtJumperClickHandle(
                                                                item.id,
                                                            );
                                                            window.open(
                                                                entityCodeToUrl[
                                                                    item
                                                                        .entityCode
                                                                ] +
                                                                    `?id=${item.bizId}&target=notice`,
                                                                '_blank',
                                                            );
                                                        }}
                                                    >
                                                        {finalJumpMessage}
                                                    </span>{' '}
                                                    {prodMessage}
                                                    <div className="nnd-item-extra">
                                                        <p>
                                                            提交人：
                                                            {
                                                                item.fromUser
                                                                    ?.userName
                                                            }
                                                        </p>
                                                    </div>
                                                    <p className="nnd-item-info">
                                                        {/* <span>
                                                            {
                                                                item.fromUser
                                                                    ?.userName
                                                            }
                                                        </span> */}
                                                        {moment(
                                                            item.sendTime,
                                                        ).fromNow()}
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    );
                                }
                            }}
                        />
                    </InfiniteScroll>
                </div>
            </>
        );
    };
    const renderNoticeList = () => {
        return (
            <>
                <div className="notice-list-header">
                    <Checkbox
                        checked={showNoReadNotice}
                        onChange={e => {
                            setShowNoReadNotice(e.target.checked);
                        }}
                    >
                        仅显示未读
                    </Checkbox>
                    <span
                        className="set-all-readable-btn"
                        onClick={setAllNoticeReadable}
                    >
                        <Icon type="check" style={{ marginRight: '5px' }} />
                        全部标为已读
                    </span>
                </div>
                <div style={{ overflow: 'auto', height: '100%' }}>
                    <InfiniteScroll
                        threshold={250}
                        initialLoad={false}
                        pageStart={loadPage.pageNum}
                        loadMore={page => {
                            if (!noticeData.length) {
                                return;
                            }
                            if (!hasMoreNotice) {
                                return;
                            }
                            if (!scrollFetchLock) {
                                scrollFetchLock = true;
                                setTimeout(() => {
                                    scrollFetchLock = false;
                                }, 1000);
                                setLoadPage({
                                    pageNum: loadPage.pageNum + 1,
                                });
                            }
                        }}
                        hasMore={!noticeDataFetchLoading && hasMoreNotice}
                        useWindow={false}
                    >
                        <List
                            loading={
                                noticeDataFetchLoading || setReadableLoading
                            }
                            className={'notice-list-content'}
                            dataSource={noticeData}
                            renderItem={(item, index) => {
                                if (item.pcUrl) {
                                    const slicePreMessageList: string[] = item.message.split(
                                        '{urlBegan}',
                                    );
                                    if (slicePreMessageList.length) {
                                        let messageFragment: string[] = [];
                                        messageFragment.push(
                                            slicePreMessageList.shift() as string,
                                        );
                                        slicePreMessageList.forEach(sText => {
                                            const sliceProdMessageList = sText.split(
                                                '{urlEnd}',
                                            );
                                            messageFragment = [
                                                ...messageFragment,
                                                ...sliceProdMessageList,
                                            ];
                                        });
                                        return (
                                            <div className="notice-item-content">
                                                <div
                                                    className="notice-item-body"
                                                    onClick={() => {
                                                        onNeedDealtJumperClickHandle(
                                                            item.id,
                                                        );
                                                    }}
                                                >
                                                    {
                                                        <div className="notice-item-avatar">
                                                            <Badge
                                                                dot={
                                                                    item.isRead !==
                                                                    1
                                                                }
                                                            >
                                                                <Avatar
                                                                    size={40}
                                                                    icon="user"
                                                                    src={
                                                                        item
                                                                            .fromUser
                                                                            ?.userAvatar
                                                                    }
                                                                />
                                                            </Badge>
                                                            {/* <span className="notice-item-name">
                                                                {
                                                                    item.fromUser
                                                                        .userName
                                                                }
                                                            </span> */}
                                                        </div>
                                                    }

                                                    {
                                                        <div className="notice-item-text">
                                                            <p className="notice-item-title">{`${
                                                                entityCodeToName[
                                                                    item
                                                                        .entityCode
                                                                ]
                                                            }${
                                                                messageFragment?.[1]
                                                                    ? `【${messageFragment?.[1]}】`
                                                                    : ''
                                                            }`}</p>
                                                            <span className="notice-item-description">
                                                                {messageFragment.map(
                                                                    (
                                                                        mItem,
                                                                        idx,
                                                                    ) => {
                                                                        if (
                                                                            (idx +
                                                                                1) %
                                                                                2 ===
                                                                            0
                                                                        ) {
                                                                            return (
                                                                                <span
                                                                                    className="notice-item-jumper"
                                                                                    onClick={() => {
                                                                                        onNeedDealtJumperClickHandle(
                                                                                            item.id,
                                                                                        );
                                                                                        window.open(
                                                                                            item
                                                                                                .pcUrl?.[
                                                                                                (idx +
                                                                                                    1) /
                                                                                                    2 -
                                                                                                    1
                                                                                            ],
                                                                                            '_blank',
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        mItem
                                                                                    }
                                                                                </span>
                                                                            );
                                                                        } else {
                                                                            return mItem;
                                                                        }
                                                                    },
                                                                )}
                                                            </span>
                                                            <p className="notice-item-info">
                                                                <span className="notice-item-name">
                                                                    {
                                                                        item
                                                                            .fromUser
                                                                            .userName
                                                                    }
                                                                </span>
                                                                <span>
                                                                    {moment(
                                                                        item.sendTime,
                                                                    ).fromNow()}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return undefined;
                                    }
                                } else {
                                    const sliceMessageJumpItemPre = item.message.split(
                                        '{urlBegan}',
                                    );
                                    const preMessage =
                                        sliceMessageJumpItemPre[0];
                                    const oneJumpMessage =
                                        sliceMessageJumpItemPre[1] || '';
                                    const sliceMessageJumpItemProd = oneJumpMessage.split(
                                        '{urlEnd}',
                                    );
                                    const finalJumpMessage =
                                        sliceMessageJumpItemProd[0];
                                    const prodMessage =
                                        sliceMessageJumpItemProd[1] || '';
                                    return (
                                        <div className="notice-item-content">
                                            <div
                                                className="notice-item-body"
                                                onClick={() => {
                                                    onNeedDealtJumperClickHandle(
                                                        item.id,
                                                    );
                                                }}
                                            >
                                                {
                                                    <div className="notice-item-avatar">
                                                        <Badge
                                                            dot={
                                                                item.isRead !==
                                                                1
                                                            }
                                                        >
                                                            <Avatar
                                                                size={40}
                                                                icon="user"
                                                                src={
                                                                    item
                                                                        .fromUser
                                                                        ?.userAvatar
                                                                }
                                                            />
                                                        </Badge>
                                                        {/* <span className="notice-item-name">
                                                            {
                                                                item.fromUser
                                                                    .userName
                                                            }
                                                        </span> */}
                                                    </div>
                                                }

                                                {
                                                    <div className="notice-item-text">
                                                        <p className="notice-item-title">{`${
                                                            entityCodeToName[
                                                                item.entityCode
                                                            ]
                                                        }${
                                                            finalJumpMessage
                                                                ? `【${finalJumpMessage}】`
                                                                : ''
                                                        }`}</p>
                                                        <span className="notice-item-description">
                                                            {preMessage}{' '}
                                                            <span
                                                                className="notice-item-jumper"
                                                                onClick={() => {
                                                                    onNeedDealtJumperClickHandle(
                                                                        item.id,
                                                                    );
                                                                    window.open(
                                                                        entityCodeToUrl[
                                                                            item
                                                                                .entityCode
                                                                        ] +
                                                                            `?id=${item.bizId}&target=notice`,
                                                                        '_blank',
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    finalJumpMessage
                                                                }
                                                            </span>{' '}
                                                            {prodMessage}
                                                        </span>
                                                        <p className="notice-item-info">
                                                            <span className="notice-item-name">
                                                                {
                                                                    item
                                                                        .fromUser
                                                                        .userName
                                                                }
                                                            </span>
                                                            <span>
                                                                {moment(
                                                                    item.sendTime,
                                                                ).fromNow()}
                                                            </span>
                                                        </p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    );
                                }
                            }}
                        />
                    </InfiniteScroll>
                </div>
            </>
        );
    };
    return (
        <AntDrawer
            visible={drawerVis}
            onClose={hideDrawer}
            destroyOnClose={true}
            width={'480px'}
            placement={'right'}
            title={'通知'}
            className={'notice-drawer-content'}
        >
            <AntTabs
                animated={true}
                activeKey={activeTab}
                onChange={tabsChangeHandle}
                tabConfig={[
                    {
                        tab: (
                            <div
                                style={{
                                    width: '60px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                className="notice-drawer-tabs"
                            >
                                <Badge
                                    offset={[10, -2]}
                                    count={noticeInfo.upcoming}
                                >
                                    待办
                                </Badge>
                            </div>
                        ),
                        key: '1',
                        content: renderNeedDealtList(),
                    },
                    {
                        tab: (
                            <div
                                style={{
                                    width: '60px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                className="notice-drawer-tabs"
                            >
                                <Badge
                                    offset={[10, -2]}
                                    count={noticeInfo.notice}
                                >
                                    通知
                                </Badge>
                            </div>
                        ),
                        key: '2',
                        content: renderNoticeList(),
                    },
                    {
                        tab: (
                            <div
                                style={{
                                    width: '60px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                className="notice-drawer-tabs"
                            >
                                <Badge
                                    offset={[10, -2]}
                                    count={noticeInfo.heed}
                                >
                                    提醒
                                </Badge>
                            </div>
                        ),
                        key: '3',
                        content: renderNoticeList(),
                    },
                ]}
            />
        </AntDrawer>
    );
};

export default observer(NoticeDrawer);
